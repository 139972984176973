import { useMutation } from '@apollo/client';

import { ResendInviteMutation, ResendInviteMutationVariables } from '../generated/graphql';

import { resendInviteMutation } from './queries';

export function useResendInviteMutation() {
  const [resendInvite] = useMutation<ResendInviteMutation, ResendInviteMutationVariables>(
    resendInviteMutation
  );
  return (
    inviteeUserID: UUID,
    projectID?: UUID,
    message?: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onSuccess?: (data: any) => void
  ) => {
    resendInvite({
      variables: {
        inviteeUserID,
        projectID,
        message,
      },
    }).then((res) => {
      if (onSuccess) onSuccess(res?.data?.resendInvite);
    });
  };
}
