import { useState } from 'react';

import { DeleteOutline } from '@material-ui/icons';

import { Org, OrgNode } from '../../../../generated/graphql';
import { IconButton, MultiSelect, Select } from '../../../scales';
import useMemoWrapper from '../../../useMemoWrapper';

import {
  convertOrgToSelectEntries,
  convertOrgsToSelectEntries,
  filterPublished,
  getIDs,
} from './utils';

export type Props = {
  disabled?: boolean;
  isHiddenForNoOrgs?: boolean;
  isMultiSelect?: boolean;
  label?: string;
  orgNodes?: OrgNode[];
  orgs: Org[] | undefined;
  onChange: (value: UUID[]) => void;
};

export default function OrgSelector({
  isHiddenForNoOrgs,
  isMultiSelect = false,
  orgNodes,
  orgs,
  onChange,
  label,
  ...props
}: Props) {
  const publishedOrgs = useMemoWrapper(filterPublished, orgs) ?? [];
  const entries = useMemoWrapper(convertOrgsToSelectEntries, publishedOrgs);
  const values = useMemoWrapper(getIDs, orgNodes);
  const [selectedOrgNodeIDs, setSelectedOrgNodeIDs] = useState<Record<UUID, UUID[]>>(() =>
    publishedOrgs.reduce(
      (previousValue, org) => ({
        ...previousValue,
        [org.id]: org.nodes.map((node) => node.id).filter((id) => values?.includes(id)),
      }),
      {}
    )
  );

  const handleChange = (org: Org, previousSelected: Record<UUID, UUID[]>, newSelected: UUID[]) => {
    const updated = {
      ...previousSelected,
      [org.id]: newSelected,
    };
    setSelectedOrgNodeIDs(updated);
    onChange(
      publishedOrgs.reduce<UUID[]>(
        (previousValue, org) => [...previousValue, ...updated[org.id]],
        []
      )
    );
  };

  if (isHiddenForNoOrgs && !publishedOrgs.length) return null;

  if (isMultiSelect) {
    return (
      <div className="flex w-full flex-col gap-2">
        <MultiSelect
          data-cy="organization-multi-select"
          defaultValue={values}
          entries={entries}
          isAllSelectable
          isClearable
          isDisabled={props.disabled}
          isSearchable
          label={label}
          onChange={onChange}
          placeholder={`Select ${publishedOrgs[0].name}`}
          value={getIDs(orgNodes)}
        />
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-2">
      {publishedOrgs.map((org) => {
        const selectedIDs = selectedOrgNodeIDs[org.id] ?? [];
        return (
          <div key={org.id} className="flex flex-col gap-2">
            {[...selectedIDs, null].map((id, index) => {
              if (props.disabled && id === null) return null;
              return (
                <div key={org.id + id} className="flex items-end">
                  <Select
                    entries={convertOrgToSelectEntries(org, selectedIDs)}
                    isDisabled={props.disabled}
                    label={index === 0 ? org.name : undefined}
                    onChange={(value: UUID | null) => {
                      if (value === null) return;
                      if (index === selectedIDs.length) {
                        const nodes = [...selectedIDs, value];
                        handleChange(org, selectedOrgNodeIDs, nodes);
                      } else {
                        const nodes = [...selectedIDs];
                        nodes[index] = value;
                        handleChange(org, selectedOrgNodeIDs, nodes);
                      }
                    }}
                    placeholder={index === 0 ? `Set ${org.name}` : `Set another ${org.name}`}
                    value={id}
                  />
                  {id && !props.disabled && (
                    <div className="flex h-10 items-center">
                      <IconButton
                        aria-label="delete-org-node"
                        disabled={props.disabled}
                        icon={<DeleteOutline />}
                        onClick={() => {
                          const nodes = [
                            ...selectedIDs.slice(0, index),
                            ...selectedIDs.slice(index + 1),
                          ];
                          handleChange(org, selectedOrgNodeIDs, nodes);
                        }}
                        type="secondary"
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
