import { FC } from 'react';

import { useTypesIdsToNames } from '../../hooks/useTypesIdsToNames';
import { Chip } from '../scales';
import useMemoWrapper from '../useMemoWrapper';

import { ProjectFilterManager, getFilterSummaryString } from './ProjectsListUtils';

type TextWithWidthProps = {
  filterManager: ProjectFilterManager;
  includeLabels?: boolean;
};

const FilterSummaryText: FC<TextWithWidthProps> = ({ filterManager, includeLabels = false }) => {
  const filterState = useTypesIdsToNames(filterManager.filterState, 'types');
  const filters = { ...filterManager, filterState };
  const width = 2000;
  const summary = useMemoWrapper(getFilterSummaryString, filters, width, includeLabels);

  return (
    <div className="flex gap-2">
      {Array.isArray(summary) ? (
        summary.map((item) => <Chip key={item} text={item} />)
      ) : (
        <Chip text={summary} />
      )}
    </div>
  );
};

export default FilterSummaryText;
