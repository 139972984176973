import { FC } from 'react';

import { BULK_EDIT, PREVIEW_ROLE } from '../../../constants';
import useUserQuery from '../../../hooks/useUserQuery';

import InputsSelectAssignee from './InputsSelectAssignee';

type InputsSelectAssigneeDataProps = {
  defaultLabel?: string;
  disabled: boolean;
  isDraft?: boolean;
  isReset?: boolean;
  itemID?: UUID;
  onChange: (email: string | undefined) => void;
  projectId: UUID;
  selected?: string;
  selectedAssignee?: User | Option['assignee'];
  variant?: typeof BULK_EDIT | typeof PREVIEW_ROLE;
};

const InputsSelectAssigneeData: FC<InputsSelectAssigneeDataProps> = ({
  defaultLabel,
  disabled,
  isDraft = false,
  isReset = false,
  itemID,
  onChange,
  projectId,
  selected,
  selectedAssignee,
  variant,
}) => {
  const { data, loading } = useUserQuery(selectedAssignee ? undefined : selected);
  if (loading) return null;

  let selectedCollab: Collaborator | undefined;
  const selectedUser = selectedAssignee || data?.user;
  if (!isReset && selectedUser) selectedCollab = { user: selectedUser } as Collaborator;

  return (
    <InputsSelectAssignee
      defaultLabel={defaultLabel}
      disabled={disabled}
      isDraft={isDraft}
      isReset={isReset}
      itemID={itemID}
      onChange={onChange}
      projectId={projectId}
      selectedCollaborator={selectedCollab}
      variant={variant}
    />
  );
};

export default InputsSelectAssigneeData;
