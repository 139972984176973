import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { JoinCompanyRoutes } from '../../../api/gqlEnums';
import { LOCALSTORAGE_COMPANY_USERS_SHOW } from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { SortManager, Table } from '../../scales';
import AddEmployee from '../AddEmployee/AddEmployee';
import CompanyTabSearch from '../CompanyTabSearch';
import CompanyTabStyles from '../CompanyTabStyles';
import CompanyTabUserSidebarFilter from '../CompanyTabUserSidebarFilter';
import {
  CompanyUserSearchManager,
  companyTabSidebarWidth,
  getStatusColor,
  gridRowHeight,
  sidebarHeaderContent,
} from '../CompanyTabUtils';

type CompanyTabMembersSidebarProps = {
  classes: Classes<typeof CompanyTabStyles>;
  company: Company;
  companyProjectRoles: CompanyProjectRoles[];
  companyRoles: PartialRole[];
  companyUsers: CompanyUser[];
  countTotalMembers: number;
  loading: boolean;
  sortManager: SortManager;
  filterStatus: string;
  setFilterStatus: (s: string) => void;
  userCounts: number[];
  searchManager: CompanyUserSearchManager;
};

const CompanyTabMembersSidebar: FC<CompanyTabMembersSidebarProps> = ({
  classes,
  company,
  companyProjectRoles,
  companyRoles,
  companyUsers,
  countTotalMembers,
  loading,
  sortManager,
  filterStatus,
  setFilterStatus,
  userCounts,
  searchManager,
}) => {
  const sendAnalytics = useSendAnalytics();

  const searchStorage = LOCALSTORAGE_COMPANY_USERS_SHOW;

  const countSearchedCompanyUsers = companyUsers.length ?? 0;

  const entries = useMemo(
    () =>
      companyUsers.map(({ user }) => {
        if (user) {
          const path = generateSharedPath(JoinCompanyRoutes.COMPANY_MEMBERS_PROFILE, {
            userId: user.id,
          });
          const link = (
            <Link
              className={`${classes.linkTitle} ${classes.textOverflow}`}
              onClick={() => {
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_VIEW_CLICK, {
                    view: CompanyAdminView.MEMBER_SIDEBAR,
                  })
                );
              }}
              to={path}
            >
              {user?.name}
            </Link>
          );
          const status = (
            <>
              <div style={{ color: getStatusColor(user.status) }}>{'\u2B24'}&nbsp;</div>
              <div className={classes.text}>{user.status}</div>
            </>
          );
          return [
            { component: link, key: user.id },
            { component: status, key: user.id },
          ];
        }
        return [];
      }),
    [companyUsers, classes, sendAnalytics]
  );

  return (
    <>
      <div className={classes.memberSidebar} data-cy="company-members-sidebar">
        <div className={classes.sidebarFirst}>
          <AddEmployee
            company={company}
            companyProjectRoles={companyProjectRoles}
            companyRoles={companyRoles}
            companyUsers={companyUsers}
            fullWidth
            onClick={() => {
              sendAnalytics(
                companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_NEW_CTA, {
                  view: CompanyAdminView.MEMBER_SIDEBAR,
                })
              );
            }}
          />
        </div>
        <div className={classes.sidebarFilter}>
          <CompanyTabUserSidebarFilter
            filterStatus={filterStatus}
            searchStorage={searchStorage}
            setFilterStatus={setFilterStatus}
            userCounts={userCounts}
            view={CompanyAdminView.MEMBER_SIDEBAR}
          />
        </div>
        <div className={classes.sidebarFilter}>
          <CompanyTabSearch page={CompanyAdminView.MEMBER_SIDEBAR} searchManager={searchManager} />
        </div>
        <div className={classes.sidebarHeight}>
          <Table
            columnWidths={companyTabSidebarWidth}
            entries={entries}
            headerContent={sidebarHeaderContent}
            loading={loading}
            rowHeight={gridRowHeight}
            sortManager={sortManager}
          />
        </div>
        <Typography className={classes.sidebarCount}>
          {countSearchedCompanyUsers} of {countTotalMembers} total employees
        </Typography>
      </div>
    </>
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabMembersSidebar);
