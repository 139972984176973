import { FC } from 'react';
import * as React from 'react';

import { Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Person } from '@material-ui/icons';

import UserAvatarStyles from './UserAvatarStyles';
import { getUserPicture } from './UserUtils';

type UserAvatarProps = {
  assignee?: Parameters<typeof getUserPicture>[0];
  classes: Classes<typeof UserAvatarStyles>;
  imgSettings?: React.ImgHTMLAttributes<HTMLImageElement> | null;
  deactivated?: boolean;
};

const UserAvatar: FC<UserAvatarProps> = ({
  assignee = null,
  classes,
  imgSettings = undefined,
  deactivated,
}) => (
  <Avatar
    className={
      deactivated
        ? `${classes.avatar} ${classes.noPrint} ${classes.deactivated}`
        : `${classes.avatar} ${classes.noPrint}`
    }
    data-cy="UserAvatar"
    imgProps={imgSettings ?? undefined}
    src={getUserPicture(assignee)}
  >
    {!(assignee && 'picture' in assignee) && <Person />}
  </Avatar>
);

export default withStyles(UserAvatarStyles)(UserAvatar);
