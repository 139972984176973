import InAppNotificationIconCompleteFlag from '../../Icons/InAppNotificationIconCompleteFlag';
import InAppNotificationIconNoUnread from '../../Icons/InAppNotificationIconGeneral';
import { ScrollContainer } from '../../scales';
import NotificationEntryData from '../InAppNotificationEntry/InAppNotificationEntryData';
import InAppNotificationsPlaceholder from '../InAppNotificationEntry/InAppNotificationsPlaceholder';

type Props = {
  notificationsData: InAppNotificationWithTime[][] | null;
  showAllProjectNotifications: boolean;
  showUnreadOnly: boolean;
};

export default function InAppNotificationsList(props: Props) {
  if (!props.notificationsData?.length) {
    if (props.showUnreadOnly) {
      return (
        <InAppNotificationsPlaceholder
          detail="You have no unread notifications."
          icon={<InAppNotificationIconNoUnread />}
          title="Great Job!"
        />
      );
    }
    if (!props.showAllProjectNotifications) {
      return (
        <InAppNotificationsPlaceholder
          detail="Tip: Check All Projects tab to see notifications on all projects."
          icon={<InAppNotificationIconCompleteFlag />}
          title={`That's all your notifications for this project.`}
        />
      );
    }

    return (
      <InAppNotificationsPlaceholder
        detail="When notifications are generated they will be displayed here."
        icon={<InAppNotificationIconCompleteFlag />}
        title="No notifications to display."
      />
    );
  }

  return (
    <ScrollContainer className="max-h-[400px]" direction="vertical">
      {props.notificationsData.map((notificationGroup) => (
        <div key={notificationGroup[0].id}>
          <div className="px-4 pt-2 type-body3">{notificationGroup[0].timeDateDisplay}</div>
          {notificationGroup.map((notification) => (
            <NotificationEntryData
              key={notification.id}
              notification={notification}
              showAllProjectNotifications={props.showAllProjectNotifications}
            />
          ))}
        </div>
      ))}
    </ScrollContainer>
  );
}
