import { useRef } from 'react';

import { CircularProgress } from '@material-ui/core';
import { AddAPhoto } from '@material-ui/icons';

type Props = {
  accept?: string;
  addFile: (file: File) => void;
  disabled?: boolean;
  height: number;
  loading?: boolean;
  thumbnail?: string;
  title?: string;
  width: number;
};

export default function UploadIcon(props: Props) {
  const ref = useRef<HTMLInputElement>(null);

  const addFiles = (files: FileList) => props.addFile(files[0]);
  let buttonContent = <AddAPhoto className="!icon-lg" fontSize="default" />;
  if (props.loading) {
    buttonContent = (
      <div className="py-4">
        <CircularProgress size={props.height / 2} />
      </div>
    );
  } else if (props.thumbnail) {
    buttonContent = (
      <img alt="thumbnail" className="h-full w-full object-cover" src={props.thumbnail} />
    );
  }

  return (
    <>
      <div
        className="flex shrink-0 flex-col gap-0.5"
        onDragEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();

          addFiles(e.dataTransfer.files);
        }}
      >
        {props.title && <div className="text-type-primary type-label">{props.title}</div>}
        <div>
          <button
            aria-haspopup="true"
            aria-label="Thumbnail"
            aria-owns="thumbnail"
            className="flex shrink-0 items-center justify-center overflow-hidden rounded-full border-2 border-dashed outline-none focus-visible:outline"
            disabled={props.disabled}
            onClick={() => ref.current?.click()}
            style={{ height: props.height, width: props.width }}
          >
            {buttonContent}
          </button>
          <input
            ref={ref}
            accept={props.accept}
            className="hidden"
            onChange={(e) => {
              if (e.target.files) {
                addFiles(e.target.files);
              }
            }}
            type="file"
          />
        </div>
      </div>
    </>
  );
}

UploadIcon.defaultProps = {
  accept: '.png, .jpg, .gif, .svg',
};
