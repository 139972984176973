import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { Search } from '@material-ui/icons';

import { Switch, TextInput } from '../../scales';
import NewProjectButton from '../../shared-widgets/NewProjectButton/NewProjectButton';

import SearchToggle, { SearchToggleParams } from './SearchToggle';

type Props = {
  activeFiltersCount: number;
  isFilterMenuOpen: boolean;
  onChangeSearch: (search: string) => void;
  onToggleFilterMenu: (open: boolean) => void;
  search: string;
  toggleParams?: SearchToggleParams;
};

export default function SearchHeader(props: Props) {
  // Maintain a second store for the search string. We use this local state
  // which is updated immediately for displaying the value to the user.
  const [search, setSearch] = useState(props.search);
  useEffect(() => {
    setSearch(props.search);
  }, [props.search]);

  // At the same time, we have a debounced function which calls the event handler
  // we received in props. The debouncing is to prevent excessive backend searches.
  const { onChangeSearch } = props;

  // We need to memoize the callback for referential equality not to prevent
  // excessive React re-renders (we're not super worried about that here since
  // it's a small component tree). We memoize it so that the debounce function
  // always has the same timer reference internally and works properly.
  const debouncedOnChangeSearch = useMemo(
    () =>
      debounce(onChangeSearch, 200, {
        leading: false,
        trailing: true,
      }),
    [onChangeSearch]
  );

  // Always use this function locally to update the search value so that the local
  // and parent `search` values stay synced.
  const handleChangeSearch = (value: string) => {
    setSearch(value);
    debouncedOnChangeSearch(value);
  };

  return (
    <div className="flex items-center gap-4 p-4">
      <div className="w-100">
        <TextInput
          aria-label="search projects"
          // TODO CT-1036: make/use a SearchInput scales component, use a more specific data-cy
          data-cy="search-input"
          onChange={handleChangeSearch}
          onClear={() => handleChangeSearch('')}
          placeholder="Search projects"
          startAdornment={<Search />}
          value={search}
        />
      </div>
      {props.toggleParams && (
        <SearchToggle
          counts={props.toggleParams.counts}
          labels={props.toggleParams.labels}
          onChange={props.toggleParams.onChange}
          value={props.toggleParams.value}
        />
      )}
      <Switch
        checked={props.isFilterMenuOpen}
        label={`Filters${props.activeFiltersCount !== 0 ? ` (${props.activeFiltersCount})` : ''}`}
        onChange={props.onToggleFilterMenu}
      />
      <div className="ml-auto">
        <NewProjectButton />
      </div>
    </div>
  );
}
