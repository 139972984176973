import { useNavigate } from 'react-router-dom';

import {
  inAppNotificationsAnalyticsEvent,
  inAppNotificationsEventTypes,
  settingNotificationEventTypes,
  settingsNotificationsAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { generateSharedPath } from '../../../utilities/routes/links';
import Settings from '../../Icons/Settings';
import {
  Button,
  ButtonBar,
  IconButton,
  Tab,
  TabList,
  Tabs,
  useActiveOverlayState,
} from '../../scales';

const THIS_PROJECT = 'This Project';
const ALL_PROJECTS = 'All Projects';

const UNREAD = 'Unread';
const ALL = 'All';

type Props = {
  loadInAppNotificationsCount?: LoadInAppNotificationsCount;
  onChangeShowAllProjectNotifications: (value: boolean) => void;
  onChangeShowUnreadOnly: (value: boolean) => void;
  onMarkAllRead: () => void;
  projectID?: UUID;
  showAllProjectNotifications: boolean;
  showUnreadOnly: boolean;
};

export default function InAppNotificationsHeader(props: Props) {
  const navigate = useNavigate();
  const {
    allNotificationsCount,
    unreadNotificationsCount,
    allProjectNotificationsCount,
    unreadProjectNotificationsCount,
  } = props.loadInAppNotificationsCount ?? {};

  const sendAnalytics = useSendAnalytics();

  const activeOverlayState = useActiveOverlayState();

  // Counts, depending on tab
  const allCount = props.showAllProjectNotifications
    ? allNotificationsCount
    : allProjectNotificationsCount;

  const unreadCount = props.showAllProjectNotifications
    ? unreadNotificationsCount
    : unreadProjectNotificationsCount;

  return (
    <div className="bg-background-primary text-type-primary">
      <div className="flex gap-2 p-4">
        <div className="mr-auto type-heading2">Notifications</div>
        {props.projectID && (
          <IconButton
            aria-label="open notification settings"
            data-cy="in-app-notificiations-settings-button"
            icon={<Settings />}
            onClick={() => {
              navigate({
                pathname: generateSharedPath(JoinProjectRoutes.SETTINGS, {
                  projectId: props.projectID,
                }),
                hash: '#Notifications',
              });

              sendAnalytics(
                settingsNotificationsAnalyticsEvent(
                  settingNotificationEventTypes.SETTINGS_NOTIFICATIONS_CTA
                )
              );
              activeOverlayState?.close();
            }}
            type="secondary"
          />
        )}
      </div>
      {props.projectID && (
        <Tabs
          onChange={(key) => {
            props.onChangeShowAllProjectNotifications(key === ALL_PROJECTS);

            sendAnalytics(
              inAppNotificationsAnalyticsEvent(
                key === ALL_PROJECTS
                  ? inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_ALL_PROJECT_CLICK
                  : inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_THIS_PROJECT_CLICK
              )
            );
          }}
          value={props.showAllProjectNotifications ? ALL_PROJECTS : THIS_PROJECT}
        >
          <TabList isFullWidth>
            <Tab data-cy="IAN-Tab-All-Projects" id={ALL_PROJECTS}>
              <div className="flex items-center justify-center gap-2">
                {ALL_PROJECTS}
                {unreadNotificationsCount ? (
                  <div className="h-2 w-2 rounded-full bg-notification-fill" />
                ) : null}
              </div>
            </Tab>
            <Tab data-cy="IAN-Tab-This-Project" id={THIS_PROJECT}>
              <div className="flex items-center justify-center gap-2">
                {THIS_PROJECT}
                {unreadProjectNotificationsCount ? (
                  <div className="h-2 w-2 rounded-full bg-notification-fill" />
                ) : null}
              </div>
            </Tab>
          </TabList>
        </Tabs>
      )}
      <div className="flex justify-between p-4 pr-0">
        <ButtonBar
          data-cy="notifications-button-bar"
          onChange={(value) => {
            props.onChangeShowUnreadOnly(value === UNREAD);

            sendAnalytics(
              inAppNotificationsAnalyticsEvent(
                inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_TOGGLE_VIEW,
                {
                  view: value ? UNREAD : ALL,
                }
              )
            );
          }}
          options={[
            { value: ALL, label: `All ${allCount}` },
            { value: UNREAD, label: `Unread ${unreadCount}` },
          ]}
          value={props.showUnreadOnly ? UNREAD : ALL}
        />
        {unreadCount ? (
          <Button
            data-cy="mark-all-as-read-button"
            label="Mark all as read"
            onClick={() => {
              props.onMarkAllRead();

              sendAnalytics(
                inAppNotificationsAnalyticsEvent(
                  inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_MARK_ALL_READ_CLICK
                )
              );
            }}
            type="tertiary"
          />
        ) : null}
      </div>
    </div>
  );
}
