import { DesignPhaseTypesQuery, DesignPhaseTypesQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';

import { getDesignPhaseTypes } from './queries';

export const useDesignPhaseTypes = () => {
  const { data } = useQuery<DesignPhaseTypesQuery, DesignPhaseTypesQueryVariables>(
    getDesignPhaseTypes,
    {},
    MountPolicy.SKIP_ON_MOUNT
  );

  return data?.designPhaseTypes ?? [];
};
