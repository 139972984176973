import { FC, useRef } from 'react';
import * as React from 'react';

import { Typography } from '@material-ui/core';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

import { withStyles } from '../../../theme/komodo-mui-theme';
import FilesAddUploadSVG from '../../DocumentMagic/FileExplorer/FilesAddUploadSVG';
import { Button } from '../../scales';

import styles from './AddUploadDragStyles';

type AddUploadDragProps = {
  acceptedFileTypeComponent: JSX.Element;
  addFiles: (files: FileList | null) => void;
  classes: Classes<typeof styles>;
  dataCy?: string;
  showDraggingInputs?: boolean;
};

const AddUploadDrag: FC<AddUploadDragProps> = ({
  acceptedFileTypeComponent,
  addFiles,
  classes,
  dataCy,
  showDraggingInputs,
}) => {
  // Handle file upload window
  const fileUpload = useRef<HTMLInputElement | null>(null);
  const showFileUpload = (fileUpload: React.RefObject<HTMLInputElement>) => {
    if (fileUpload.current) {
      fileUpload.current.click();
    }
  };

  const selectButton = (
    <Button
      aria-label="Select a File"
      label="Select a File"
      onClick={() => showFileUpload(fileUpload)}
      startIcon={
        <div>
          <InsertDriveFileOutlinedIcon className={classes.uploadFileIcon} />
          <input
            ref={(input) => {
              fileUpload.current = input;
            }}
            className={classes.hidden}
            data-cy="input-file"
            id="upload-file"
            onChange={(evt) => {
              addFiles(evt.target.files);
            }}
            type="file"
          />
        </div>
      }
      type="secondary"
    />
  );

  // Styles
  const getSectionContainerClass = () =>
    `${showDraggingInputs ? classes.draggingInputs : ''} ${classes.containerGrow}`;

  const getSVGContainerClass = () => {
    const uploadAreaClasses = `${classes.fileExplorerBackground} ${classes.uploadAreaFileExplorer}`;
    const svgClass = classes.uploadAreaPadded;
    return `${uploadAreaClasses} ${svgClass}`;
  };

  return (
    <div className={getSectionContainerClass()} data-cy={dataCy}>
      <div className={getSVGContainerClass()}>
        <FilesAddUploadSVG />
        <div className="flex flex-col items-center justify-center ">
          <Typography className={classes.uploadText}>Drag & drop a file here, or</Typography>
          {selectButton}
          <br />
          <br />
        </div>
      </div>
      {acceptedFileTypeComponent}
    </div>
  );
};

export default withStyles(styles)(AddUploadDrag);
