import { FC, useContext, useMemo, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';
import { Add, Bookmark, Loop } from '@material-ui/icons';

import { userReportEvent } from '../../../analytics/analyticsEventProperties';
import { currentUserReportVar, setReactiveLocal } from '../../../api/apollo/reactiveVars';
import { USER_REPORT_VAR } from '../../../constants';
import { NS_MSR_FOLLOWING_ACTIVE_MILESTONE, NS_OWNER_COSTS } from '../../../features';
import { PermissionResource, UserReportType } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { makeDefaultLevelNames } from '../../../utilities/categorization';
import { getDefaultReportCategorizations } from '../../../utilities/categorization/categorizationUtils';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import { SetSettingsFunctionType } from '../../../utilities/urlState';
import DialogsReportsData from '../../Dialogs/DialogsReports/DialogsReportsData';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';
import { DefaultReports, reportForAnalytics } from '../config';
import { useListProjectUserReports } from '../ReportHooks';

import CurrentReportBar from './CurrentReportBar';
import styles from './ReportManagerMenuStyles';
import { MapReportManagerPagesToValidReports, ReportManagerPages } from './utils';

type ReportManagerMenu = {
  classes: Classes<typeof styles>;
  parentInputReport?: UserReportInput;
  setParentInputReport?: (newInput: UserReportInput) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any;
  setSettings: SetSettingsFunctionType;
  variant: ReportManagerPages;
  isViewOnly?: boolean;
  setShowReportDistributionDialog?: (value: boolean) => void;
  reportModalOpenOverride?: boolean;
  setReportModalOpenOverride?: (value: boolean) => void;
};

// used to set or find the anchor element for the report bar
export const ReportManagerAnchors = (key: 'breakdowns' | 'app'): string => `${key}-anchor`;

const ReportsManagerMenu: FC<ReportManagerMenu> = ({
  classes,
  parentInputReport,
  setParentInputReport,
  settings,
  setSettings,
  variant,
  isViewOnly,
  setShowReportDistributionDialog,
  reportModalOpenOverride,
  setReportModalOpenOverride,
}) => {
  const hasMSRFollowActiveMilestone = useHasFeature(NS_MSR_FOLLOWING_ACTIVE_MILESTONE);
  const t = useContext(ProjectTermStore);
  const projectID = getProjectIdFromUrl();
  const milestoneID = getMilestoneIdFromUrl();
  const sendAnalytics = useSendAnalytics();
  const currentReport = useReactiveVar(currentUserReportVar);

  const [reportModalOpen, setReportModalOpen] = useState(false);

  // Used due to the report distribution experiment
  // if we have a value passed in we use that, otherwise we use the local state
  const setReportModalOpenHelper = (value: boolean) => {
    if (setReportModalOpenOverride) {
      setReportModalOpenOverride(value);
    } else {
      setReportModalOpen(value);
    }
  };
  const reportModalOpenValue = reportModalOpenOverride || reportModalOpen;

  let validReportTypes = MapReportManagerPagesToValidReports[variant];
  if (
    currentReport &&
    validReportTypes.some((reportType) => reportType === currentReport.reportType)
  ) {
    validReportTypes = [currentReport.reportType];
  }
  const getNewReport = () => {
    const newReport: UserReportInput = {
      reportType: validReportTypes[0],
      shared: false,
      name: '',
      description: '',
      settings: JSON.stringify(settings),
    };
    if (milestoneID) newReport.milestoneID = milestoneID;
    if (hasMSRFollowActiveMilestone && variant === 'msr') newReport.milestoneID = undefined;
    return newReport;
  };
  const [inputReport, setInputReport] = useState<UserReportInput>(getNewReport());
  const handleSetReportInput = (newInput: UserReportInput) => {
    if (setParentInputReport) {
      setParentInputReport(newInput);
    } else {
      setInputReport(newInput);
    }
  };

  const {
    data: { project },
    loading: loadingProjectProps,
  } = useProjectPropsQuery(projectID, MountPolicy.SKIP_ON_MOUNT);
  const name = project?.activeMilestone.name ?? '';

  const {
    data: { userReports } = {
      userReports: [],
    },
    loading: loadingUserReports,
  } = useListProjectUserReports(projectID);
  const loading = loadingProjectProps || loadingUserReports;

  const filteredCustomReports: UserReport[] = useMemo(() => {
    const isValidMSR = (r: UserReport) => {
      if (r.reportType !== UserReportType.USER_REPORT_MSR) return true;
      if (r.milestoneID && milestoneID && r.milestoneID === milestoneID) return true;
      return false;
    };
    if (!loading) {
      return userReports.filter((r) => validReportTypes.includes(r.reportType) && isValidMSR(r));
    }
    return [];
  }, [loading, userReports, validReportTypes, milestoneID]);

  const { canView } = usePermissions();
  const canViewMarkups = canView(PermissionResource.MARKUPS);

  const { data: catData, loading: catzLoading } = useProjectCategorizationsQuery(projectID);
  const categorizations = getCategorizationsForProjectFromQueryData(catData);
  const defaultCategorizations = getDefaultReportCategorizations(categorizations);
  const defaultGroupBy = makeDefaultLevelNames(defaultCategorizations);

  const hasOwnerCost = useHasFeature(NS_OWNER_COSTS);

  const defaultReports = useMemo(
    () =>
      DefaultReports(
        name,
        t.titleCase,
        {
          showContingencyReport: canViewMarkups,
          showProjectCostBreakdownReport: hasOwnerCost,
          showMarkupsInTooltip: canViewMarkups,
        },
        defaultGroupBy
      ),
    [name, t, canViewMarkups, defaultGroupBy, hasOwnerCost]
  );

  if (loading || catzLoading) return <LinearProgress hidden={!loading} />;

  const viewSavedSuboptions: MenuOption[] = [];

  const switchReport = (r: UserReport): void => {
    setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, r);
    setSettings(JSON.parse(r.settings));
  };

  const sendSwitchEvent = (r: UserReport) =>
    sendAnalytics(
      userReportEvent('userReportMenu_applySwitchReport', {
        page: variant,
        report: reportForAnalytics(r),
      })
    );
  filteredCustomReports.forEach((r) =>
    viewSavedSuboptions.push({
      key: r.id,
      name: r.name,
      callback: () => switchReport(r),
      cy: `button-report-${r.name}`,
      onClick: () => sendSwitchEvent(r),
    })
  );

  defaultReports
    .filter(
      (r) =>
        validReportTypes.includes(r.reportType) &&
        r.reportType !== UserReportType.USER_REPORT_ITEMS_LIST_DETAILS
    )
    .forEach((r) => {
      viewSavedSuboptions.push({
        key: r.id,
        name: r.name,
        callback: () => switchReport(r),
        onClick: () => sendSwitchEvent(r),
        cy: `button-report-${r.name}`,
      });
    });

  const options: MenuOption[] = [];
  if (viewSavedSuboptions.length > 0)
    options.push({
      icon: <Loop />,
      name: 'Switch to saved report',
      callback: () => undefined,
      subOptions: viewSavedSuboptions,
      onClick: () =>
        sendAnalytics(userReportEvent('userReportMenu_switchReport', { page: variant })),
    });
  if (!isViewOnly)
    options.push({
      icon: <Add />,
      name: 'Create new report',
      callback: () => {
        handleSetReportInput(getNewReport());
        setReportModalOpenHelper(true);
      },
      onClick: () => sendAnalytics(userReportEvent('userReportMenu_saveReport', { page: variant })),
      cy: 'button-saveReport',
    });
  return (
    <div className={classes.containerOuter}>
      {(viewSavedSuboptions.length > 0 || !isViewOnly) && (
        <IconMenu
          cy="button-reportsManager"
          icon={<Bookmark />}
          isBottomOriented
          onClick={() => sendAnalytics(userReportEvent('userReportMenu_open', { page: variant }))}
          options={options}
        />
      )}
      <DialogsReportsData
        allowableReportTypes={validReportTypes}
        inputReport={parentInputReport || inputReport}
        isFollowingActiveMilestone={currentReport?.isFollowingActiveMilestone ?? false}
        onClose={() => {
          setReportModalOpenHelper(false);
        }}
        onSuccess={(r) => setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, r)}
        open={reportModalOpenValue}
        setInputReport={handleSetReportInput}
        setShowReportDistributionDialog={setShowReportDistributionDialog}
        variant="reports-manager"
      />
      <CurrentReportBar
        currentMilestoneID={parentInputReport ? parentInputReport.milestoneID : milestoneID}
        isViewOnly={isViewOnly}
        onCreate={() => setReportModalOpenHelper(true)}
        setSettings={setSettings}
        settings={settings}
        variant={variant}
      />
    </div>
  );
};

export default withStyles(styles)(ReportsManagerMenu);
