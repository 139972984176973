import { ForecastingReportsSortKey } from '../../../generated/graphql';
import { SortManager, Table } from '../../scales';

import AttachedProjectCell from './cells/AttachedProjectCell';
import AverageCostCell from './cells/AverageCostCell';
import IconMenuCell from './cells/IconMenuCell';
import LastUpdatedCell from './cells/LastUpdatedCell';
import NameCell from './cells/NameCell';
import ProjectsCountCell from './cells/ProjectsCountCell';
import QuantityMagnitudeCell from './cells/QuantityMagnitudeCell';

const ForecastingReportsList = (props: {
  loading: boolean;
  onAddToProject: (report: ForecastingReport) => void;
  onDelete: (report: ForecastingReport) => void;
  onEdit: (report: ForecastingReport) => void;
  onLink: (report: ForecastingReport) => void;
  reports: ForecastingReport[];
  searchText: string;
  sortManager: SortManager;
}) => {
  const entries = props.reports.map((report) => {
    const key = report.id;
    return [
      { key, component: <NameCell report={report} searchText={props.searchText} /> },
      { key, component: <QuantityMagnitudeCell report={report} /> },
      { key, component: <AverageCostCell report={report} /> },
      { key, component: <ProjectsCountCell report={report} /> },
      { key, component: <AttachedProjectCell report={report} /> },
      { key, component: <LastUpdatedCell report={report} /> },
      {
        key,
        component: (
          <IconMenuCell
            onAddToProject={props.onAddToProject}
            onDelete={props.onDelete}
            onEdit={props.onEdit}
            onLink={props.onLink}
            report={report}
          />
        ),
      },
    ];
  });

  return (
    <Table
      columnWidths={[
        'minmax(300px, 5fr)',
        'minmax(120px, 1fr)',
        'minmax(160px, 1fr)',
        'minmax(120px, 1fr)',
        'minmax(160px, 2fr)',
        'minmax(160px, 1fr)',
        'minmax(64px, 0.5fr)',
      ]}
      entries={entries}
      headerContent={[
        { copy: 'Reports', key: 'name', headerSortKey: ForecastingReportsSortKey.NAME },
        {
          copy: 'All metrics',
          key: 'pinned-unit-quantity-magnitude',
          headerSortKey: ForecastingReportsSortKey.PINNED_UNIT_QUANTITY_MAGNITUDE,
        },
        {
          copy: 'Avg $/Metric',
          key: 'average-quantity-cost',
          headerSortKey: ForecastingReportsSortKey.AVERAGE_QUANTITY_COST,
        },
        {
          copy: 'Projects',
          key: 'projects-count',
          headerSortKey: ForecastingReportsSortKey.PROJECTS_COUNT,
        },
        { copy: ' ', key: 'attached-project' },
        {
          copy: 'Last updated',
          key: 'updated-at',
          headerSortKey: ForecastingReportsSortKey.UPDATED_AT,
        },
        {
          copy: ' ',
          key: 'icon-menu',
          rightAligned: true,
        },
      ]}
      loading={props.loading}
      sortManager={props.sortManager}
    />
  );
};

export default ForecastingReportsList;
