import { FC, memo } from 'react';

import JoinPreloaderCell from './JoinGridPreloaderCell';
import GridRowCheckbox from './JoinGridRowCheckbox';
import { Column } from './types';

type TableRowProps = {
  linePrefix: string;
  columns: Column[];
  startCellWidth: number;
  isTotalCellArr: boolean[];
  widths: number[];
  height: number;
  i: number;
  id: UUID;
  hasCheckBox?: boolean;
};

const TableRow: FC<TableRowProps> = memo(
  ({ height, startCellWidth, linePrefix, columns, widths, i, id, isTotalCellArr, hasCheckBox }) => (
    <tr key={id}>
      {hasCheckBox && (
        <th
          className="join-grid-td join-grid-td-left-border "
          scope="row"
          style={{ left: 0, minWidth: startCellWidth, borderRight: 'none' }}
        >
          <GridRowCheckbox checked={false} onClick={() => {}} />
        </th>
      )}
      <th
        key="number"
        className="join-grid-td join-grid-td-left-border  join-grid-line-number-cell "
        scope="row"
        style={{ left: hasCheckBox ? startCellWidth : 0, minWidth: startCellWidth }}
      >
        <div className="column-cell-border">
          <span className="column-cell-content">{`${linePrefix}${i + 1}`}</span>
        </div>
      </th>
      {columns.map((column, j) => {
        const { errors } = column;
        const [{ errorsPresence = [] } = {}] = errors || [];
        const isError = errorsPresence[i];

        const isTotalCell = isTotalCellArr[j];

        let cell;
        if (!isTotalCell) {
          cell = (
            // eslint-disable-next-line react/no-array-index-key
            <td key={j} className="join-grid-preloader-td">
              <JoinPreloaderCell height={height} isError={isError} width={widths[j]} />
            </td>
          );
        } else {
          cell = (
            <td
              // eslint-disable-next-line react/no-array-index-key
              key={j}
              className="join-grid-preloader-td"
              data-cy="grid-loadingCells"
              // eslint-disable-next-line jsx-a11y/scope
              scope={isTotalCell ? 'row-total' : ''}
              style={{ right: isTotalCell ? '1px' : 'auto' }}
            >
              <JoinPreloaderCell height={height} isError={isError} width={widths[j]} />
            </td>
          );
        }
        return cell;
      })}
    </tr>
  )
);

export default TableRow;
