import './MenuBar.scss';

import { FC, Fragment, useState } from 'react';
import { useDebounce } from 'react-use';

import {
  Code,
  FormatBold,
  FormatClear,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatStrikethrough,
  FormatUnderlined,
  Highlight,
  Redo,
  Undo,
} from '@material-ui/icons';
import { Editor } from '@tiptap/core';

import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import theme from '../../../../theme/komodo-mui-theme';
import Checklist from '../../../Icons/Checklist';
import { HorizontalRuleIcon } from '../../../Icons/HorizontalRuleIcon';
import {
  MenuBarAction,
  getTextStylingMenuAnalyticsEvent,
} from '../TipTapTextArea/TipTapTextAreaUtils';

import MenuItem, { MenuItemProps } from './MenuItem/MenuItem';

const EXTRA_TOP_MARGIN = 0.5;

const FOCUS_DELAY = 100;

const DIVIDER = 'divider';

type MenuBarProps = {
  editor: Editor;
};

const MenuBar: FC<MenuBarProps> = ({ editor }) => {
  const isEditorFocused = editor?.isFocused;
  const sendAnalytics = useSendAnalytics();
  const getOnClick = (action: () => void, analyticsName: MenuBarAction) => () => {
    action();
    sendAnalytics(getTextStylingMenuAnalyticsEvent(analyticsName));
  };

  const [isFocused, setIsFocused] = useState<boolean>(isEditorFocused);
  useDebounce(() => setIsFocused(isEditorFocused), FOCUS_DELAY, [isEditorFocused]);
  const isDisabled = !isFocused;

  const divider = {
    icon: <></>,
    isDisabled: () => true,
    onClick: () => {},
    title: DIVIDER,
  };

  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const commandIcon = isMac ? '⌘' : 'Ctrl';
  const optionIcon = isMac ? '⌥' : 'Alt';
  const getTitle = (name: string, keyString: string, commandString: string) =>
    `${name} (${keyString}+${commandString})`;

  const items: MenuItemProps[] = [
    {
      icon: <FormatBold style={{ marginTop: EXTRA_TOP_MARGIN }} />,
      isActive: () => editor.isActive(MenuBarAction.BOLD),
      isDisabled: () => isDisabled,
      onClick: getOnClick(() => editor.chain().focus().toggleBold().run(), MenuBarAction.BOLD),
      title: getTitle('Bold', commandIcon, 'B'), // (⌘+B)
    },
    {
      icon: <FormatItalic style={{ marginTop: EXTRA_TOP_MARGIN }} />,
      isActive: () => editor.isActive(MenuBarAction.ITALIC),
      isDisabled: () => isDisabled,
      onClick: getOnClick(() => editor.chain().focus().toggleItalic().run(), MenuBarAction.ITALIC),
      title: getTitle('Italic', commandIcon, 'I'), // (⌘+I)
    },
    {
      icon: <FormatStrikethrough style={{ marginTop: EXTRA_TOP_MARGIN }} />,
      isActive: () => editor.isActive(MenuBarAction.STRIKE),
      isDisabled: () => isDisabled,
      onClick: getOnClick(() => editor.chain().focus().toggleStrike().run(), MenuBarAction.STRIKE),
      title: getTitle('Strikethrough', `${commandIcon}+Shift`, 'X'), // (⌘+Shift+X)
    },
    {
      icon: <FormatUnderlined style={{ marginTop: EXTRA_TOP_MARGIN }} />,
      isActive: () => editor.isActive(MenuBarAction.UNDERLINE),
      isDisabled: () => isDisabled,
      onClick: getOnClick(
        () => editor.chain().focus().toggleUnderline().run(),
        MenuBarAction.UNDERLINE
      ),
      title: getTitle('Underline', commandIcon, 'U'), // (⌘+U)
    },
    divider,
    {
      icon: <Code />,
      isActive: () => editor.isActive(MenuBarAction.CODE),
      isDisabled: () => isDisabled,
      onClick: getOnClick(() => editor.chain().focus().toggleCode().run(), MenuBarAction.CODE),
      title: getTitle('Code', commandIcon, 'E'), // (⌘+E)
    },
    {
      icon: <Highlight />,
      isActive: () => editor.isActive(MenuBarAction.HIGHLIGHT),
      isDisabled: () => isDisabled,
      onClick: getOnClick(
        () => editor.chain().focus().toggleHighlight().run(),
        MenuBarAction.HIGHLIGHT
      ),
      title: getTitle('Highlight', `${commandIcon}+Shift`, 'H'), // (⌘+Shift+H)
    },
    divider,
    {
      icon: <div style={{ ...theme.typography.h4newBold }}>H1</div>,
      isActive: () => editor.isActive(MenuBarAction.HEADING, { level: 1 }),
      isDisabled: () => isDisabled,
      onClick: getOnClick(
        () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
        MenuBarAction.HEADING_1
      ),
      title: getTitle('Heading 1', `${commandIcon}+${optionIcon}`, '1'), // (⌘+Alt+1)
    },
    {
      icon: <div style={{ ...theme.typography.h4newBold }}>H2</div>,
      isActive: () => editor.isActive(MenuBarAction.HEADING, { level: 2 }),
      isDisabled: () => isDisabled,
      onClick: getOnClick(
        () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
        MenuBarAction.HEADING_2
      ),
      title: getTitle('Heading 2', `${commandIcon}+${optionIcon}`, '2'), // (⌘+Alt+2)
    },
    divider,
    {
      icon: <FormatListBulleted />,
      isActive: () => editor.isActive(MenuBarAction.BULLET_LIST),
      isDisabled: () => isDisabled,
      onClick: getOnClick(
        () => editor.chain().focus().toggleBulletList().run(),
        MenuBarAction.BULLET_LIST
      ),
      title: getTitle('Bullet List', `${commandIcon}+Shift`, '8'), // (⌘+Shift+8)
    },
    {
      icon: <FormatListNumbered />,
      isActive: () => editor.isActive(MenuBarAction.ORDERED_LIST),
      isDisabled: () => isDisabled,
      onClick: getOnClick(
        () => editor.chain().focus().toggleOrderedList().run(),
        MenuBarAction.ORDERED_LIST
      ),
      title: getTitle('Numbered List', `${commandIcon}+Shift`, '7'), // (⌘+Shift+7)
    },
    {
      icon: <Checklist />,
      isActive: () => editor.isActive(MenuBarAction.TASK_LIST),
      isDisabled: () => isDisabled,
      onClick: getOnClick(
        () => editor.chain().focus().toggleTaskList().run(),
        MenuBarAction.TASK_LIST
      ),
      title: getTitle('Task List', `${commandIcon}+Shift`, '9'), // (⌘+Shift+9)
    },
    {
      icon: <FormatIndentDecrease />,
      isDisabled: () => isDisabled,
      onClick: getOnClick(() => editor.chain().focus().outdent().run(), MenuBarAction.OUTDENT),
      title: 'Outdent (Shift+Tab)', // (Shift+Tab)
    },
    {
      icon: <FormatIndentIncrease />,
      isDisabled: () => isDisabled,
      onClick: getOnClick(() => editor.chain().focus().indent().run(), MenuBarAction.INDENT),
      title: 'Indent (Tab)', // (Tab)
    },
    divider,
    {
      icon: <FormatQuote />,
      isActive: () => editor.isActive(MenuBarAction.BLOCKQUOTE),
      isDisabled: () => isDisabled,
      onClick: getOnClick(
        () => editor.chain().focus().toggleBlockquote().run(),
        MenuBarAction.BLOCKQUOTE
      ),
      title: 'Blockquote', // (⌘+Shift+B) doesn't work
    },
    {
      icon: <HorizontalRuleIcon />,
      isDisabled: () => isDisabled,
      onClick: getOnClick(
        () => editor.chain().focus().setHorizontalRule().run(),
        MenuBarAction.HORIZONTAL_RULE
      ),
      title: 'Horizontal Rule (---)', // (---)
    },
    divider,
    {
      icon: <Undo />,
      isDisabled: () => isDisabled || !editor.can().undo(),
      onClick: getOnClick(() => editor.chain().focus().undo().run(), MenuBarAction.UNDO),
      title: getTitle('Undo', commandIcon, 'Z'), // (⌘+Z)
    },
    {
      icon: <Redo />,
      isDisabled: () => isDisabled || !editor.can().redo(),
      onClick: getOnClick(() => editor.chain().focus().redo().run(), MenuBarAction.REDO),
      title: getTitle('Redo', commandIcon, 'Y'), // (⌘+Y)
    },
    divider,
    {
      icon: <FormatClear />,
      isDisabled: () => isDisabled,
      onClick: getOnClick(
        () => editor.chain().focus().clearNodes().unsetAllMarks().run(),
        MenuBarAction.CLEAR_FORMAT
      ),
      title: 'Clear Formatting', // (⌘+Alt+0) doesn't work
    },
  ];

  return (
    <div className="editor__header">
      {items.map((item, i) => (
        <Fragment key={`${i.toString()}-${item.title}`}>
          {item.title === DIVIDER ? (
            <div className={DIVIDER} />
          ) : (
            <MenuItem
              icon={item.icon}
              isActive={item.isActive}
              isDisabled={item.isDisabled}
              onClick={item.onClick}
              title={item.title}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default MenuBar;
