import { useQuery } from '@apollo/client';

import {
  autodeskPotentialChangeOrderFields,
  gql,
  procoreChangeEventFields,
} from '../api/graphqlFragments';
import {
  ItemIntegrationObjectsQuery,
  ItemIntegrationObjectsQueryVariables,
} from '../generated/graphql';

export const itemIntegrationObjectsQuery = gql`
  query ItemIntegrationObjects(
    $sourceSystem: SourceSystem!
    $sourceID: UUID!
    $projectID: UUID!
    $itemID: UUID!
  ) {
    itemIntegrationObjects(
      sourceSystem: $sourceSystem
      sourceID: $sourceID
      projectID: $projectID
      itemID: $itemID
    ) {
      procoreChangeEvents {
        ...procoreChangeEventFields
      }
      autodeskPotentialChangeOrders {
        ...autodeskPotentialChangeOrderFields
      }
      refetchedAt
      error
    }
  }
  ${procoreChangeEventFields}
  ${autodeskPotentialChangeOrderFields}
`;

export default function useItemIntegrationObjectsQuery(
  variables: ItemIntegrationObjectsQueryVariables,
  onCompleted: (data: ItemIntegrationObjectsQuery) => void
) {
  return useQuery<ItemIntegrationObjectsQuery, ItemIntegrationObjectsQueryVariables>(
    itemIntegrationObjectsQuery,
    {
      variables: {
        ...variables,
      },
      skip:
        !variables.itemID || !variables.sourceID || !variables.projectID || !variables.sourceSystem,
      onCompleted,
      notifyOnNetworkStatusChange: true,
    }
  );
}
