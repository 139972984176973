import { FC, useEffect, useState } from 'react';

import { useEstimateQuery } from '../../../hooks/useEstimateQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { getItemIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import { usePrintItemHistory } from '../../Events/EventsItemHistoryList/EvenItemsHistoryListUtils';
import { useItemQuery } from '../../Items/hooks/useItemQuery';
import usePrintWindow from '../PrintHooks/usePrintWindow';

import { PrintItemDetails } from './PrintItemDetails';

const PrintViewItemDetails: FC = () => {
  const projectId = getProjectIdFromUrl();
  const itemId = getItemIdFromUrl();
  const withHistory = usePrintItemHistory();

  const {
    data: { project },
  } = useProjectPropsQuery(projectId);
  const itemLike = useItemQuery(itemId)?.data?.item;

  // PERMISSIONS
  const permissions = usePermissions({ trades: itemLike?.categories || [] });

  const estimateID = itemLike?.activeEstimate?.id ?? '';
  const { data: { estimate } = {} } = useEstimateQuery(estimateID);

  const itemIsReady = Boolean(itemLike?.id);
  const estimateIsReady = Boolean(estimate?.id);
  const [renderComplete, setRenderComplete] = useState(false);
  const triggerOnComplete = () => setRenderComplete(true);

  usePrintWindow(itemIsReady, estimateIsReady, renderComplete);

  useEffect(() => {
    if (project && project.name) document.title = `${project.name} - Item Details`;
  }, [project]);

  return itemLike ? (
    <div>
      <PrintItemDetails
        estimate={estimate ?? undefined}
        itemLike={itemLike}
        permissions={permissions}
        projectId={projectId}
        triggerOnComplete={triggerOnComplete}
        withHistory={withHistory}
      />
    </div>
  ) : null;
};

export default PrintViewItemDetails;
