import { FC } from 'react';

import { ProjectCostBreakdown } from '../../generated/graphql';
import { formatCost } from '../../utilities/currency';
import { renderPercentString } from '../../utilities/string';
import { Table } from '../scales';

import { projectBreakdownCostColorsKey } from './utils';

type Props = {
  projectCostBreakdown: ProjectCostBreakdown[];
};

const ProjectCostBreakdownReportTable: FC<Props> = ({ projectCostBreakdown }) => {
  const entries = projectCostBreakdown.map(({ type, cost, percent }) => {
    return [
      {
        component: (
          <div className="flex items-center type-heading3">
            <div className={`m-3 h-4 w-4 ${projectBreakdownCostColorsKey.get(type)}`} />
            {type.replace(/([A-Z])/g, ' $1').trim() /* Add spaces between capital letters */}
          </div>
        ),
        key: `${type}-name`,
      },
      {
        component: (
          <div className="type-number">{renderPercentString({ value: percent * 100 })}</div>
        ),
        key: `${type}-percent`,
      },
      {
        component: <div className="type-number">{formatCost(cost, { rounded: true })}</div>,
        key: `${type}-percent`,
      },
    ];
  });

  const header = [
    { copy: 'Costs', key: 'header-costs' },
    { copy: 'Percent', key: 'header-percent', rightAligned: true },
    { copy: 'Total', key: 'header-total', rightAligned: true },
  ];

  // Add + 1 for the header
  const tableHeight = 41 * (entries.length + 1);

  return (
    <div style={{ height: tableHeight }}>
      <Table columnWidths={['2fr', '1fr', '1fr']} entries={entries} headerContent={header} />
    </div>
  );
};

export default ProjectCostBreakdownReportTable;
