import { useCallback, useEffect, useState } from 'react';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { ProjectType } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import ProjectTypeSelector from '../../../Dialogs/DialogsNewProject/ProjectTypeSelector';
import { SelectLevelsEntry } from '../../../frame/AdvancedFiltersSidebar/FilterGroupLevels';
import { ProjectProp } from '../../../ProjectProperties/ProjectPropSelector';
import { Button, Dialog, DialogContent, TextInput } from '../../../scales';
import useMemoWrapper from '../../../useMemoWrapper';
import { useCompanyRoutesData } from '../../CompanyTabUtils';
import useCreateProjectTypeMutation from '../ProjectTypes/hooks/useCreateProjectTypeMutation';

import { TYPE_NAME_MAX_LENGTH, filterParentTypes, filterSubTypes } from './utils';

export const AlreadyExistText = 'Project Subtype name already exists. Try a different one.';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const hasName = (values: any[] | undefined, value: string) =>
  (values || []).some(({ name }) => name.trim().toLowerCase() === value.trim().toLowerCase());

export type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  parentType?: ProjectType;
  types?: ProjectType[];
};

export default function AddProjectSubtypeDialog(props: Props) {
  const [parentTypeID, setParentTypeID] = useState<UUID | undefined>(props.parentType?.id);
  const [name, setName] = useState('');
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  // Hooks
  const { companyID } = useCompanyRoutesData();
  const createProjectType = useCreateProjectTypeMutation();
  const sendAnalytics = useSendAnalytics();
  // Filter Type Lists
  const parentTypes = useMemoWrapper(filterParentTypes, props.types || []);
  const subTypes = useMemoWrapper(filterSubTypes, props.types || [], parentTypeID || '');
  const disabled = Boolean(!name || errorMsg || isLoading || !parentTypeID);

  const parentStart = parentTypes.find((x) => x.parentID === props.parentType?.id)?.name;
  const parentEnd = parentTypes.find((x) => x.parentID === parentTypeID)?.name;

  const onCreate = () => {
    setIsLoading(true);
    const onSuccess = () => {
      setIsLoading(false);
      setName('');
      setErrorMsg(undefined);
      sendAnalytics(
        companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TYPES_ADD_CREATE, {
          parentStart,
          parentEnd,
          typeName: name,
        })
      );
      props.onClose?.();
    };
    const onFail = (error: string | undefined) => {
      setIsLoading(false);
      setErrorMsg(error || 'Something went wrong');
    };
    createProjectType(name, companyID || '', parentTypeID, onSuccess, onFail);
  };

  const onChange = useCallback(
    (newValue: string) => {
      if (errorMsg) setErrorMsg(undefined);
      const isAlreadyExist = hasName(subTypes, newValue);
      if (isAlreadyExist) setErrorMsg(AlreadyExistText);
      setName(newValue.substring(0, TYPE_NAME_MAX_LENGTH));
    },
    [errorMsg, subTypes]
  );

  const onClose = () => {
    sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TYPES_ADD_CLOSE_CANCEL));
    props.onClose?.();
  };

  // Error checking
  useEffect(() => {
    // Check for error at each name change or parent selection change
    onChange(name);
  }, [name, subTypes, parentTypeID, onChange]);

  return (
    <Dialog
      {...props}
      footerRight={
        <Button
          data-cy="create-button"
          disabled={disabled}
          label="Create"
          onClick={onCreate}
          type="primary"
        />
      }
      isNotDismissable={isLoading}
      onClose={onClose}
      title="Add Project Subtype"
    >
      <DialogContent className="flex flex-col gap-2">
        <ProjectTypeSelector
          editable
          label="Project Type Related To*"
          name="type"
          search
          selectedValue={parentTypeID}
          setProjectProp={(p: ProjectProp | SelectLevelsEntry | null) => {
            if (!p) return;
            setParentTypeID(p.id);
          }}
          values={parentTypes}
        />
        <TextInput
          data-cy="project-subtype-text-input"
          errorMessage={errorMsg}
          label="Project Subtype Name*"
          onChange={onChange}
          placeholder="Project Subtype Name"
          value={name}
        />
      </DialogContent>
    </Dialog>
  );
}
