import * as d3 from 'd3';

import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import TimelineContainer from '../TimelineContainer';
import TimelinePath from '../TimelinePath';
import TimelineSvg from '../TimelineSvg';

import { getMilestoneIcon } from './icons';
import { InsightsMilestone } from './types';
import { getDaysTo, useGmps } from './utils';

const LINE_HEIGHT = 10;

type Props = {
  data: InsightsMilestone[];
  height: number;
  hoverDate?: Date | undefined;
  today: Date;
  totalRange: [string, string];
};

export default function InsightsBaseLine(props: Props) {
  const { data, height, hoverDate, today, totalRange } = props;
  const { ref, dimensions } = useChartDimensions({
    height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  };

  const { activeGmp, activeMilestone, comingGmp } = useGmps(data, today);

  // x domain
  const xMin = new Date(totalRange[0]);
  const xMax = new Date(totalRange[1]);
  const xDomain = [xMin, xMax];
  const xRange: [number, number] = [margin.left, width - margin.right];
  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  // y domain
  const [yMin, yMax] = [0 - (height / 30) * 100, (height / 70) * 100];
  const yDomain = [yMin, yMax];
  const yRange: [number, number] = [height - margin.bottom, margin.top];
  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  const baseData = [
    { date: xMin, value: 0 },
    { date: xMax, value: 0 },
  ];

  const todayData = [
    { date: today, value: yMin },
    { date: today, value: yMax },
  ];

  const bounds = {
    right: x(xMax),
    left: x(xMin),
  };

  const hoverData = hoverDate
    ? [
        { date: hoverDate, value: 0 },
        { date: hoverDate, value: yMax },
      ]
    : undefined;

  const getTipContent = (data: InsightsMilestone) => {
    if (data.designPhase) {
      return (
        <g x="0" y="0">
          <text className="type-primary" x="0" y="0">
            {`${getDaysTo(data.date, today)} days to`}
          </text>
          <text className="type-primary" x="80" y="0">
            {data.name}
          </text>
        </g>
      );
    }
    return (
      <g x="0" y="0">
        <text className="type-primary" x="0" y="0">
          {data.name}
        </text>
      </g>
    );
  };

  return (
    <SVGWithDimensions ref={ref} data-cy="line-chart" dimensions={dimensions}>
      {/* Hover Line */}
      {hoverData && (
        <TimelinePath
          // current hover date
          data={hoverData}
          stroke="stroke-chart-axis"
          strokeWidth={1.5}
          x={x}
          y={y}
        />
      )}
      {/* Base */}
      <TimelinePath
        data={baseData}
        stroke="stroke-entities-phase"
        strokeWidth={LINE_HEIGHT}
        x={x}
        y={y}
      />
      {/* Tip icons */}
      {data.map((data) => (
        <TimelineSvg
          key={`${data.date}-${totalRange[0]}-${totalRange[1]}`}
          data={{ date: data.date, value: 0 }}
          icon={getMilestoneIcon(data)}
          iconHeight={8}
          iconWidth={8}
          x={x}
          y={y}
        />
      ))}
      {/* Today */}
      <TimelinePath
        data={todayData}
        stroke="stroke-selection-focus-fill"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Tips info */}
      {data.map((data) => (
        <TimelineContainer
          key={`tip-content-${data.date}-${totalRange[0]}-${totalRange[1]}`}
          bounds={bounds}
          content={getTipContent(data)}
          data={{ date: data.date, value: 15 }}
          disabled={
            !(activeGmp === data.id || comingGmp === data.id || activeMilestone === data.id)
          }
          height={24}
          placement={data.designPhase ? 'right' : 'left'}
          width={data.designPhase ? 130 : 174}
          x={x}
          y={y}
        />
      ))}
    </SVGWithDimensions>
  );
}
