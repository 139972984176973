import { ImageDimension } from '../../../../generated/graphql';
import { getTextColor } from '../../../../utilities/colors';
import { SortManager } from '../../../scales';
import Thumbnail from '../../../shared-widgets/Thumbnail/Thumbnail';
import { getApplyChanges } from '../../ApplyScenarioDialog/ApplyScenarioUtils';
import CostSummary from '../../common/CostSummary';
import { getScenarioName } from '../../common/ScenariosUtils';
import ScenarioItemSummarySection from '../../ScenarioItemSummarySection';
import { Scenario } from '../../types';

import PresentationColumn from './PresentationColumn';

type Props = {
  onLoad: () => void;
  scenario: Scenario;
  shouldGrow: boolean;
  sortManager?: SortManager;
};

function SandboxPresentationColumn(props: Props) {
  const { updatedItems, newItems } = getApplyChanges(props.scenario.items);
  const hasItems = updatedItems.length || newItems.length;

  const backgroundColor = props.scenario.color ?? '';
  const textColor = getTextColor(backgroundColor);

  return (
    <PresentationColumn shouldGrow={props.shouldGrow}>
      <div
        className="flex h-16 flex-shrink-0 items-center justify-between gap-2 overflow-hidden rounded pl-2 pr-[10px]"
        style={{ backgroundColor }}
      >
        <div className={`min-w-0 flex-shrink truncate type-heading2 ${textColor}`}>
          {getScenarioName(props.scenario)}
        </div>
        {props.scenario.thumbnailAssetID && (
          <Thumbnail
            dimension={ImageDimension._144}
            onLoad={props.onLoad}
            padding={0}
            size={64} // kinda fussy height, based on the above field heights
            thumbnail={props.scenario.thumbnailAssetID}
          />
        )}
      </div>
      {hasItems ? (
        <div className="grow overflow-auto print:overflow-visible ">
          <ScenarioItemSummarySection
            itemEntries={updatedItems}
            sortManager={props.sortManager}
            title="Updated Items"
          />
          <ScenarioItemSummarySection
            itemEntries={newItems}
            sortManager={props.sortManager}
            title="New Items"
          />
        </div>
      ) : (
        <div className="flex flex-grow items-center justify-center text-type-muted type-body1">
          No new or updated items
        </div>
      )}
      <div className="grow-0">
        <CostSummary costReports={props.scenario.costReports} />
      </div>
    </PresentationColumn>
  );
}

export default SandboxPresentationColumn;
