import { useState } from 'react';
import * as React from 'react';

import MilestonesDescription from './MilestonesDescription';

type MilestoneDescriptionProps = {
  editable: boolean;
  milestone: Milestone;
};

const MilestoneDescription: React.FC<MilestoneDescriptionProps> = ({ milestone, editable }) => {
  const [milestoneValue] = useState<Milestone>(milestone);

  return (
    <div className="flex flex-col gap-0.5">
      {(editable || milestoneValue.description) && <div className="type-label">Description</div>}
      <MilestonesDescription editable={editable} milestone={milestone} />
    </div>
  );
};

export default MilestoneDescription;
