import useCompanyAssetBlobUrlQuery from '../../assets/hooks/useCompanyAssetBlobUrlQuery';

type Props = {
  asset: Pick<Asset, 'id'>;
  name: string;
};

export default function CompanyLogo(props: Props) {
  const { url } = useCompanyAssetBlobUrlQuery(props.asset.id);
  return (
    <img alt={`${props.name} logo`} className="max-h-[50px] w-[144px] object-contain" src={url} />
  );
}
