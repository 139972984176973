import InAppNotificationsFooter from '../InAppNotificationsFooter/InAppNotificationsFooter';
import InAppNotificationsHeader from '../InAppNotificationsHeader/InAppNotificationsHeader';
import InAppNotificationsList from '../InAppNotificationsList/InAppNotificationsList';

type Props = {
  currentPage: number;
  loadInAppNotificationsCount?: LoadInAppNotificationsCount;
  notificationsData: InAppNotificationWithTime[][] | null;
  onChangeShowAllProjectNotifications: (value: boolean) => void;
  onChangeShowUnreadOnly: (value: boolean) => void;
  onMarkAllRead: () => void;
  onViewNextPage?: () => void;
  onViewPreviousPage?: () => void;
  projectID?: UUID;
  showAllProjectNotifications: boolean;
  showUnreadOnly: boolean;
};

export default function InAppNotificationsPanel(props: Props) {
  let totalNotificationsCount = props.showAllProjectNotifications
    ? props.loadInAppNotificationsCount?.allNotificationsCount
    : props.loadInAppNotificationsCount?.allProjectNotificationsCount;
  if (props.showUnreadOnly) {
    totalNotificationsCount = props.showAllProjectNotifications
      ? props.loadInAppNotificationsCount?.unreadNotificationsCount
      : props.loadInAppNotificationsCount?.unreadProjectNotificationsCount;
  }

  return (
    <>
      <InAppNotificationsHeader
        loadInAppNotificationsCount={props.loadInAppNotificationsCount}
        onChangeShowAllProjectNotifications={props.onChangeShowAllProjectNotifications}
        onChangeShowUnreadOnly={props.onChangeShowUnreadOnly}
        onMarkAllRead={props.onMarkAllRead}
        projectID={props.projectID}
        showAllProjectNotifications={props.showAllProjectNotifications}
        showUnreadOnly={props.showUnreadOnly}
      />
      <InAppNotificationsList
        notificationsData={props.notificationsData}
        showAllProjectNotifications={props.showAllProjectNotifications}
        showUnreadOnly={props.showUnreadOnly}
      />
      <InAppNotificationsFooter
        currentPage={props.currentPage}
        onViewNextPage={props.onViewNextPage}
        onViewPreviousPage={props.onViewPreviousPage}
        totalNotificationsCount={totalNotificationsCount ?? 0}
      />
    </>
  );
}
