import 'react-vis/dist/style.css';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import {
  reportChart,
  setItemsListNewItemDialogOpen,
  setMilestoneBudgetExpanded,
  setMilestoneEstimateExpanded,
} from '../../../../analytics/analyticsEventProperties';
import { hasTrendVar, newItemDialogOpenVar } from '../../../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../../../api/gqlEnums';
import { YC_COST_TRENDLINE_V2 } from '../../../../features';
import { CostReportColumnType, PermissionResource } from '../../../../generated/graphql';
import { useHasFeature } from '../../../../hooks/useHasFeature';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../../routes/paths';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import LegacyChartsCostTrendline from '../../../Charts/ChartsCostTrendline/ChartsCostTrendline';
import {
  CHART_HEIGHT,
  allDataFilteredMessage,
  allPointsHiddenMessage,
} from '../../../Charts/ChartsUtils';
import DialogsHideCostTrendlineEvents from '../../../Dialogs/DialogsHide/DialogsHideCostTrendlineEvents/DialogsHideCostTrendlineEvents';
import ChartsCostTrendline from '../../../dragon-scales/TimelineCharts/ChartsCostTrendline/ChartsCostTrendline';
import { getNonZeroQuantities } from '../../../Inputs/UnitToggle/UnitToggleUtils';
import useMilestoneQuantitiesQuery from '../../../Milestone/hooks/useMilestoneQuantitiesQuery';
import { useMilestonesQuery } from '../../../Milestones/hooks';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import CTALink from '../../../shared-widgets/CTALink';
import useMemoWrapper from '../../../useMemoWrapper';
import { DASHBOARD_DEFAULTS } from '../../DashboardUtils';
import DashboardChartClearButton from '../DashboardChartsClearButton';
import DashboardChartPlaceholder from '../DashboardChartsPlaceholder';

import styles from './DashboardChartsTrendStyles';
import { CostTrendlineEvent, computeCostTrendData } from './DashboardChartsTrendUtils';
import { useCostTrendlineEventsQuery } from './hooks/useCostTrendlineEventsQuery';

type DashboardChartsTrendActiveMilestoneProps = {
  activeMilestoneId: UUID;
  categorizations?: Categorization[];
  classes: Classes<typeof styles>;
  clearFilters?: () => void;
  isFiltered?: boolean;
  isPrint?: boolean;
  onClose: () => void;
  setIsLoading?: (loading: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setSetting?: (param: any, value: any) => void;
  settings: typeof DASHBOARD_DEFAULTS;
  showDialogsHide: boolean;
  viewFilter: ViewFilterInput;
  unitID?: UUID;
};

export const dashboardColumnTypes: CostReportColumnType[] = [
  CostReportColumnType.ACCEPTED_REPORT,
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.TARGET_REPORT,
  CostReportColumnType.RUNNINGTOTAL_REPORT,
  CostReportColumnType.REMAINING_REPORT,
];

const DashboardChartsTrendActiveMilestone: FC<DashboardChartsTrendActiveMilestoneProps> = ({
  activeMilestoneId,
  classes,
  clearFilters,
  isFiltered = false,
  isPrint = false,
  onClose,
  showDialogsHide,
  setIsLoading = () => {},
  settings,
  viewFilter,
  unitID,
}) => {
  const hasCostTrendlineV2 = useHasFeature(YC_COST_TRENDLINE_V2);
  const navigate = useNavigate();
  const t = useContext(ProjectTermStore);
  const projectId = getProjectIdFromUrl(window.location.pathname);
  const { trendUnit } = settings;

  // get quantities
  const { data: quantitiesData } = useMilestoneQuantitiesQuery(activeMilestoneId);
  const quantityMagnitude = getNonZeroQuantities(quantitiesData?.quantities)?.find(
    (q) => trendUnit === q.unit.abbreviationSingular
  )?.magnitude;

  const dashboardColumns: CostReportColumnInput[] = dashboardColumnTypes.map((type) => ({
    type,
    unitID,
  }));

  // MILESTONES
  const { data: { milestones = [] } = {}, loading: milestoneLoading } = useMilestonesQuery(
    projectId,
    false
  );
  const activeMilestone = useMemo(
    () => milestones.filter((m: { id: string }) => m.id === activeMilestoneId)[0],
    [milestones, activeMilestoneId]
  );

  // FOR COST TREND EVENTS
  const {
    data: costTrendlineEvents,
    refetch: refetchCostTrendlineEvents,
    loading: costTrendlineEventsLoading,
  } = useCostTrendlineEventsQuery(
    projectId,
    activeMilestoneId,
    viewFilter,
    dashboardColumns,
    quantityMagnitude
  );
  const hasEvents =
    costTrendlineEvents?.costTrendlineEvents.events?.length &&
    costTrendlineEvents?.costTrendlineEvents.events?.length > 0;

  const hooksLoading = milestoneLoading || costTrendlineEventsLoading;

  useEffect(() => {
    setIsLoading(hooksLoading);
  }, [hooksLoading, setIsLoading]);

  // PERMISSIONS
  const { canEdit } = usePermissions();
  const canEditEstimate = canEdit(PermissionResource.MILESTONE_LINES);
  const canEditItems = canEdit(PermissionResource.ITEM_DETAILS);

  // ANALYTICS
  const sendAnalytics = useSendAnalytics();
  const reportDashboardAnalytics = (chart: string, mouseEvent: string) =>
    sendAnalytics(reportChart(chart, mouseEvent));

  const costTrendData = useMemoWrapper(
    computeCostTrendData,
    t,
    quantityMagnitude,
    costTrendlineEvents?.costTrendlineEvents
  );

  // COST TREND
  const { estimate = 0, target = 0, trend = [] } = costTrendData;
  const hasActiveMilestone = activeMilestone && activeMilestone.id;
  const hasEstimate = estimate !== 0;
  const hasBudget = target !== 0;
  const hasTrend = trend && trend.length > 0;
  useEffect(() => {
    hasTrendVar(hasTrend);
  }, [hasTrend]);

  const allDataFiltered = !hooksLoading && isFiltered && !hasEstimate && !hasBudget;
  const allEventsFiltered = !hooksLoading && isFiltered && !hasTrend && !allDataFiltered;

  const [selectedEvent, setSelectedEvent] = useState<CostTrendlineEvent | null>(null);
  const onHoverEventTrendline = (v: CostTrendlineEvent) => {
    if (selectedEvent !== v && !isPrint) {
      setSelectedEvent(v);
      reportDashboardAnalytics('CostTrend Item', 'hover');
    }
  };

  // set the size of the dot back to it's original value
  const onMouseLeaveTrendline = () => {
    if (selectedEvent) {
      setSelectedEvent(null);
    }
  };

  const onClick = () => {
    if (!isPrint) {
      reportDashboardAnalytics('CostTrend Item', 'click');
      navigate(
        generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
          projectId,
          itemId: selectedEvent?.item?.id,
        })
      );
    }
  };

  const emptyMessage = useMemo(() => {
    const allHidden = !(costTrendData.trend && costTrendData.trend.some((event) => event.visible));

    // If there's filtering, try to turn that off.
    if (allDataFiltered) {
      return allDataFilteredMessage;
    }
    if (allHidden) {
      // If there were points, but they are filtered, prompt editing.
      return allPointsHiddenMessage;
    }
    return '';
  }, [allDataFiltered, costTrendData]);

  const renderTrendline = (
    height: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    trendlineOnClick: (clicked: any) => void,
    trendlineOnHoverEvent: (v: CostTrendlineEvent) => void,
    trendlineOnMouseLeave: () => void,
    trendlineSelectedEvent: CostTrendlineEvent | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    labels?: any[] | undefined
  ) => {
    if (emptyMessage || hooksLoading)
      return (
        <DashboardChartPlaceholder
          clearFilters={isFiltered ? clearFilters : undefined}
          emptyMessage={emptyMessage}
          height={height}
          loading={hooksLoading}
        />
      );
    return hasCostTrendlineV2 ? (
      <ChartsCostTrendline
        estimate={costTrendData.estimate}
        height={height}
        isPrint={isPrint}
        labels={labels}
        legendLabels={costTrendData.legendLabels}
        milestoneID={activeMilestoneId}
        onClick={trendlineOnClick}
        onHoverEvent={trendlineOnHoverEvent}
        onMouseLeave={trendlineOnMouseLeave}
        target={costTrendData.target}
        trend={costTrendData.trend}
        xDomain={costTrendData.xDomain?.map((d) => new Date(d))}
        yDomain={costTrendData.yDomain}
      />
    ) : (
      <LegacyChartsCostTrendline
        onClick={trendlineOnClick}
        onHoverEvent={trendlineOnHoverEvent}
        onMouseLeave={trendlineOnMouseLeave}
        selectedEvent={trendlineSelectedEvent}
        {...costTrendData}
        height={height}
        labels={labels}
        milestoneID={activeMilestoneId}
      />
    );
  };
  const dialog = () => (
    <DialogsHideCostTrendlineEvents
      activeMilestone={activeMilestone}
      loading={hooksLoading}
      onClose={onClose}
      open={showDialogsHide}
      refetchCostTrendlineEvents={refetchCostTrendlineEvents}
      renderTrendline={renderTrendline}
      trendlineEvents={costTrendData.trend}
      {...costTrendData}
    />
  );

  const content = () => {
    if (!hasActiveMilestone && !hooksLoading)
      return (
        <CTALink
          linkText="Set the active milestone to view trendlines."
          onClick={() => {
            sendAnalytics(setMilestoneEstimateExpanded(true));
            navigate(generateSharedPath(JoinProjectRoutes.MILESTONES, { projectId }));
          }}
        />
      );

    return (
      <>
        {allEventsFiltered && (
          <Typography variant="subheading"> No events matching your filters</Typography>
        )}
        {!isPrint && !hasEstimate && !allDataFiltered && canEditEstimate && !hooksLoading && (
          <div className={classes.flexMenu}>
            <div className={classes.flexMessages}>
              <CTALink
                linkText={`Set the ${!hasEstimate ? `estimate` : ''}${
                  !hasEstimate && !hasBudget ? ` and ` : ''
                }${!hasBudget ? `budget` : ''} for this milestone to visualize.`}
                onClick={() => {
                  if (!hasEstimate) {
                    sendAnalytics(setMilestoneEstimateExpanded(true));
                  }

                  if (!hasBudget) {
                    sendAnalytics(setMilestoneBudgetExpanded(true));
                  }
                  if (activeMilestone)
                    navigate(
                      generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
                        projectId,
                        milestoneId: activeMilestone.id,
                      })
                    );
                }}
              />
              {!hasEvents && !allDataFiltered && !hooksLoading && hasEstimate && canEditItems && (
                <CTALink
                  linkText="Add Items to this milestone to see progress."
                  onClick={() => {
                    newItemDialogOpenVar(true);
                    sendAnalytics(setItemsListNewItemDialogOpen(true));
                    navigate(generateSharedPath(RouteKeys.PROJECT_ITEMS, { projectId }));
                  }}
                />
              )}
              {allEventsFiltered && (
                <Typography variant="subheading">
                  <span>No events matching your filters.</span>
                  <span>
                    <DashboardChartClearButton clearFilters={clearFilters} />
                  </span>
                </Typography>
              )}
            </div>
          </div>
        )}
        {renderTrendline(
          CHART_HEIGHT,
          onClick,
          onHoverEventTrendline,
          onMouseLeaveTrendline,
          selectedEvent,
          costTrendData.labels
        )}
      </>
    );
  };

  return (
    <>
      {dialog()}
      {content()}
    </>
  );
};

export default withStyles(styles)(DashboardChartsTrendActiveMilestone);
