import { FC } from 'react';

import { TimelineActivityType } from '../../../api/gqlEnumsBe';
import useSendAnalytics, { AnalyticsEvent } from '../../../hooks/useSendAnalytics';
import { useTimelineQuery } from '../../Timeline/hooks/TimelineHook';
import { useItemActivityAdd, useItemActivityRemove } from '../../ve-item-details/ItemDetailsHooks';

import SelectEvent from './SelectEvent';

type SelectEventItemDataProps = {
  analyticsEvent?: AnalyticsEvent;
  disabled?: boolean;
  item: ItemLike | DraftItem;
  maxChips?: number;
  onChange?: (selected: UUID[]) => void;
  projectID: UUID;
  onItemMutated?: () => void;
};

const SelectEventItemData: FC<SelectEventItemDataProps> = ({
  analyticsEvent,
  disabled,
  item,
  maxChips = 2,
  onChange: onChangeOuter,
  projectID,
  onItemMutated,
}) => {
  const sendAnalytics = useSendAnalytics();
  const { data: timelineEvents, loading: timelineLoading } = useTimelineQuery({
    projectID,
    types: [TimelineActivityType.EVENT],
  });
  const updateItemActivity = useItemActivityAdd({ onCompleted: onItemMutated });
  const removeItemActivity = useItemActivityRemove({ onCompleted: onItemMutated });

  const allActivities = timelineEvents?.timeline?.activities ?? [];
  const selectedActivityIds = item.activityIDs ?? [];

  const onChange = (selected: UUID[]) => {
    if (onChangeOuter) {
      onChangeOuter(selected);
    } else if ('id' in item) {
      const addID = selected.filter((id) => selectedActivityIds.indexOf(id) === -1)[0];
      const removeID = selectedActivityIds.filter((id: UUID) => selected?.indexOf(id) === -1)[0];
      if (addID) {
        updateItemActivity(projectID, item.id, addID, item);
        if (analyticsEvent) sendAnalytics(analyticsEvent);
      } else if (removeID) {
        removeItemActivity(projectID, item.id, removeID, item);
      }
    }
  };

  if (timelineLoading) return null;

  return (
    <SelectEvent
      disabled={disabled}
      events={allActivities}
      labelFn={(id) => allActivities.find((a) => a.id === id)?.name || id}
      maxChips={maxChips}
      onChange={onChange}
      selected={selectedActivityIds}
    />
  );
};

export default SelectEventItemData;
