import { FC } from 'react';
import { Link } from 'react-router-dom';

import { AssistantPhoto, AssistantPhotoOutlined } from '@material-ui/icons';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { RouteKeys } from '../../../routes/paths';
import theme from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { isNullID } from '../../../utilities/utilities';
import { useSourceItemQuery } from '../../Items/hooks/useItemQuery';
import { styleItemNumber } from '../../Items/ItemsListItem/ItemsListItemUtils';
import ItemsIconsMap, { SMALL } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import { useSourceMilestoneQuery } from '../../Milestone/hooks/useMilestoneQuery';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';

const MEDIUM_ICON = theme.iconSizes.small;
const BLUE = theme.palette.blue;
const getMilestoneIcon = (isActive: boolean) => {
  if (isActive) {
    return (
      <AssistantPhoto
        className="push-down-2"
        style={{ color: BLUE, width: MEDIUM_ICON, height: MEDIUM_ICON }}
      />
    );
  }
  return (
    <AssistantPhotoOutlined
      className="push-down-2"
      style={{ color: BLUE, width: MEDIUM_ICON, height: MEDIUM_ICON }}
    />
  );
};

interface SourceRendererProps {
  value: SourceCell;
}

const SourceRenderer: FC<SourceRendererProps> = ({ value }) => {
  const projectID = getProjectIdFromUrl();
  const activeMilestoneID = useProjectPropsQuery(projectID, MountPolicy.SKIP_ON_MOUNT).data.project
    ?.activeMilestone?.id;

  const { itemID, milestoneID } = value?.sourceIDs || {};

  const isItem = !isNullID(itemID);

  // MILESTONE
  const { data: { milestone } = {} } = useSourceMilestoneQuery(
    isNullID(milestoneID) ? undefined : milestoneID
  );
  const milestoneName = (milestone || { name: undefined }).name;

  // ITEM
  const { data: { item } = {} } = useSourceItemQuery(
    isNullID(itemID) ? undefined : itemID ?? undefined
  );
  const itemNumber = (item || { number: '' }).number;
  const itemName = (item || { name: '' }).name;
  const itemStatus = (item || { status: '' }).status;
  const itemNumberName = item ? `${styleItemNumber(itemNumber)} ${itemName}` : 'deleted item';

  const name = isItem ? itemNumberName : milestoneName;

  if (!itemName && !milestoneName) return null;

  const pathname = isItem
    ? generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
        projectId: projectID,
        itemId: itemID ?? undefined,
      })
    : generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
        projectId: projectID,
        milestoneId: milestoneID,
      });

  const conditionalLinkWrapper = (condition: boolean, pathname: string, component: JSX.Element) => {
    return condition ? <Link to={pathname}>{component}</Link> : <>{component}</>;
  };

  return conditionalLinkWrapper(
    !!item,
    pathname,
    <NormalTooltip title={name}>
      <div className="join-grid-renderer join-grid-category">
        {isItem ? (
          <ItemsIconsMap
            disableLoading
            stateAvailabilityClass="push-down-2"
            status={itemStatus}
            variant={SMALL}
          />
        ) : (
          getMilestoneIcon(activeMilestoneID === milestoneID)
        )}
        <span className="join-grid-trim-text join-grid-link" title={name}>
          {name}
        </span>
      </div>
    </NormalTooltip>
  );
};

export default SourceRenderer;
