import { extent } from 'd3-array';
import { ScaleTime, scaleTime } from 'd3-scale';

import { isNonNullable } from '../../../utilities/types';

import { TimelineData } from './types';

export const getRange = (data: TimelineData[]) => {
  const set1 = data.map((d) => new Date(d.start));
  const set2 = data.map((d) => new Date(d.end || d.start));
  const ext = [...set1, ...set2].filter((date) => date);
  const dateRange = extent(ext);
  return dateRange;
};

export const getRangeToday = (data: TimelineData[], today?: string | Date | undefined) => {
  const dataRange = getRange(data);
  const todayDate = new Date(today || data[0]?.start || 0);
  const ext = [...dataRange, todayDate].filter(isNonNullable);
  const dateRange = extent(ext);
  return dateRange;
};

export const getRangeTodayCentered = (data: TimelineData[], today: string | Date | undefined) => {
  const dataRange = getRangeToday(data, today) as Date[];
  const todayDate = new Date(today || data[0]?.start || 0);
  const dStart = todayDate.getTime() - dataRange[0]?.getTime();
  const dEnd = dataRange[1]?.getTime() - todayDate.getTime();
  const delta = Math.abs(dStart > dEnd ? dStart : dEnd);
  const dateRange = [new Date(todayDate.getTime() - delta), new Date(todayDate.getTime() + delta)];
  return dateRange;
};

/**
 * Calculates the [min, max] date range in ISO format.
 *
 * @param data timeline data set for range calculation or single [td] value
 * @param today date to include in range calculation
 *
 */
export const getTodayRangeStr = (data: TimelineData[], today: string | Date | undefined) => {
  const [minExt, maxExt] = getRangeTodayCentered(data, today);
  if (!minExt || !maxExt) return ['0', '0'];
  const newMin = minExt.toISOString();
  const newMax = maxExt.toISOString();
  return [newMin, newMax];
};

export const createXScale = (range: [string, string], width: number): ScaleTime<number, number> => {
  const rangeDates = [new Date(range?.[0] ?? '0'), new Date(range?.[1] ?? '0')];
  return scaleTime()
    .domain(rangeDates as Iterable<Date>)
    .range([16, width - 16]);
};
