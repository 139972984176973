import { FC, useEffect } from 'react';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconError from '@material-ui/icons/ErrorOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { cellErrorSelectedCTA } from '../../../analytics/analyticsEventProperties';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { Column } from '../../JoinGrid/types';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { SelectVariants } from '../../Select/SelectMenu/SelectStyles';
import useMemoWrapper from '../../useMemoWrapper';

import EstimateHeaderIconMenu from './EstimateHeaderIconMenu';
import EstimateHeaderStyles from './EstimateHeaderStyles';
import { MenuOptionsProps, alignments } from './EstimateHeaderUtils';

type EstimateHeaderErrorProps = {
  menuOptionsData: MenuOptionsProps;
  onClose: () => void;
  align?: 'LEFT' | 'RIGHT';
  classes: Classes<typeof EstimateHeaderStyles>;
  column: Column;
  errorIndex: number;
  index: number;
  enableColumnMenu?: boolean;
  reportMenuOpenedAnalytics: () => void;
  setEditing?: (b: boolean) => void;
  setErrorIndex: (index: number) => void;
  width?: number;
  scrollToCell: (error: number, col: number) => void;
  selectCell: (row: number, col: number) => void;
};

const errorsCountText = (
  errorIndex: number,
  totalErrors: number,
  displaySingleErrorWord: boolean,
  displayCurrentError: boolean,
  displayErrorWord: boolean
) => {
  if (totalErrors === 1) {
    return !displaySingleErrorWord ? `${errorIndex + 1} ...` : `${errorIndex + 1} error`;
  }
  if (displayCurrentError) {
    return `${errorIndex + 1} of ...`;
  }
  return !displayErrorWord
    ? `${errorIndex + 1} of ${totalErrors}`
    : `${errorIndex + 1} of ${totalErrors} errors`;
};

const getMinWidth = (total: number) => {
  const charsCount = (total || 0).toString().length;
  const icMinWidth = 152;
  const difference = 15;
  const minWidth = icMinWidth + charsCount * difference;
  return minWidth;
};
const ERROR_WORD_WIDTH = 36;
const ERROR_NO_DESCRIPTION_WIDTH = 136;

const EstimateHeaderError: FC<EstimateHeaderErrorProps> = ({
  align,
  classes,
  column,
  enableColumnMenu,
  errorIndex,
  index,
  menuOptionsData,
  onClose,
  reportMenuOpenedAnalytics,
  scrollToCell,
  selectCell,
  setEditing = () => {},
  setErrorIndex,
  width = 0,
}) => {
  const { errors, isErrorsMode } = column;
  const [{ errorsIndices = [] } = {}] = errors || [];
  const totalErrors = (errorsIndices || []).length;

  const isLeft = align === alignments.LEFT;
  const isRight = align === alignments.RIGHT;

  const hasErrors = totalErrors !== 0;
  useEffect(() => {
    if (isErrorsMode) {
      scrollToCell(errorsIndices[errorIndex], index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorsMode]);

  // handles Escape
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const handleKeyUp = (event: any) => {
    const { key } = event;
    if (key === 'Escape') {
      if (isErrorsMode) {
        // Close error header
        onClose();
      }
    }
  };

  // watch for keyup events
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  });

  const minWidth = useMemoWrapper(getMinWidth, totalErrors);

  const { sendAnalytics } = menuOptionsData;

  const displayCurrentError = width < minWidth;
  const displayErrorWord = width - minWidth > ERROR_WORD_WIDTH;
  const displaySingleErrorWord = width - minWidth > 0;
  const displayErrorDescription = width > ERROR_NO_DESCRIPTION_WIDTH;
  const closeButton = (
    <NormalTooltip title="Close error navigator">
      <IconButton className="join-grid-error-nav-close-button" onClick={onClose}>
        <CloseIcon className="join-grid-error-nav-close" />
      </IconButton>
    </NormalTooltip>
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const textDetails = (children: any[], title: string, onClick?: () => void) => (
    <NormalTooltip title={title}>
      <div
        className={`${isRight ? classes.right : ''} ${classes.crop} join-grid-error-nav-text`}
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={-1}
      >
        {children}
      </div>
    </NormalTooltip>
  );
  const optionsMenu = (
    <div className={classes.iconWhite}>
      <EstimateHeaderIconMenu
        onClick={() => {
          selectCell(-1, -1);
          setEditing(false);
          reportMenuOpenedAnalytics();
        }}
        optionsData={menuOptionsData}
        variant={SelectVariants.GRID}
      />
    </div>
  );
  if (!hasErrors) {
    return (
      <>
        {closeButton}
        {textDetails(
          [<div key="errorIconPlaceholder" className="join-grid-error-nav-icon" />, 'No errors.'],
          'All errors resolved.'
        )}
        {isLeft && enableColumnMenu && optionsMenu}
      </>
    );
  }
  return (
    <>
      {closeButton}
      {displayErrorDescription ? (
        textDetails(
          [
            <IconError key="errorIcon" className="join-grid-error-nav-icon" />,
            errorsCountText(
              errorIndex,
              totalErrors,
              displaySingleErrorWord,
              displayCurrentError,
              displayErrorWord
            ),
          ],
          `Go to error ${errorIndex + 1} of ${totalErrors}`,
          () => scrollToCell(errorsIndices[errorIndex], index)
        )
      ) : (
        <div className="join-grid-error-no-description" />
      )}
      <NormalTooltip title="Go to previous error">
        <IconButton
          className="join-grid-error-nav-icon-button"
          onClick={() => {
            if (errorIndex > 0) {
              setErrorIndex(errorIndex - 1);
              scrollToCell(errorsIndices[errorIndex - 1], index);
            } else {
              setErrorIndex(totalErrors - 1);
              scrollToCell(errorsIndices[totalErrors - 1], index);
            }
            sendAnalytics(cellErrorSelectedCTA('previous'));
          }}
        >
          <ExpandMoreIcon className="join-grid-error-nav-prev" />
        </IconButton>
      </NormalTooltip>
      <NormalTooltip title="Go to next error">
        <IconButton
          className="join-grid-error-nav-icon-button"
          onClick={() => {
            if (errorIndex < totalErrors - 1) {
              setErrorIndex(errorIndex + 1);
              scrollToCell(errorsIndices[errorIndex + 1], index);
            } else {
              setErrorIndex(0);
              scrollToCell(errorsIndices[0], index);
            }
            sendAnalytics(cellErrorSelectedCTA('next'));
          }}
        >
          <ExpandMoreIcon className="join-grid-error-nav-next" />
        </IconButton>
      </NormalTooltip>
      {isLeft && enableColumnMenu && optionsMenu}
    </>
  );
};

export default withStyles(EstimateHeaderStyles)(EstimateHeaderError);
