import { useState } from 'react';

import FullLogo from '../logo/FullLogo.svg';
import { Button } from '../scales';

import { useAcceptTOSMutation } from './hooks/useTOSHooks';

type Props = {
  children: JSX.Element;
  dateEffective: string;
  isAccepted: boolean;
};

const TOSCheckLayer = (props: Props) => {
  const skip = localStorage.getItem('skip_signup_flow');
  const [acceptTOS] = useAcceptTOSMutation();
  const [localIsAccepted, setLocalIsAccepted] = useState(false);

  if (skip || localIsAccepted || props.isAccepted) {
    return props.children;
  }

  return (
    <div
      className="flex h-screen w-screen items-center justify-center bg-cover"
      style={{ backgroundImage: 'url(/img/signup/onboardingBG.svg)' }}
    >
      <img alt="joinlogo" className="fixed left-0 top-10 w-40" src={FullLogo} />

      <div className="m-12 flex w-full min-w-[300px] flex-col items-center gap-4 border bg-background-primary p-6 sm:w-1/2 lg:max-w-[600px] ">
        <div className="w-full type-heading2">
          We&apos;ve updated our Terms of Service and Privacy Policy.
        </div>
        <div className="flex flex-col gap-2 type-body1">
          {props.dateEffective && <div>These terms took effect on {props.dateEffective}.</div>}
          <div>
            Please take a moment to note the changes, and accept the updated terms to continue to
            the app. You can access our current Terms of Service at any time at{' '}
            <a href="https://join.build/terms" rel="noreferrer" target="_blank">
              join.build/terms
            </a>
            .
          </div>
        </div>
        <Button
          aria-label="accept terms and continue"
          label="Accept and Continue to Join"
          onClick={() => {
            setLocalIsAccepted(true);
            acceptTOS();
          }}
          type="primary"
        />
      </div>
    </div>
  );
};

export default TOSCheckLayer;
