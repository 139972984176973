import { FC, useState } from 'react';

import { FormControl, IconButton, Input, MenuItem, Select } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';

import {
  CUSTOM_BANNER,
  NO_LOGO,
  SETTINGS_PRINT_HEADER_SELECTOR,
  SETTINGS_REMOVE_BANNER_ACTION,
  TEAM_LOGOS,
} from '../../../actions/actionTypes';
import { settingsPrintHeaderSelector } from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import theme from '../../../theme/design-system-mui-theme';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useRemoveProjectBanner } from '../../ProjectProperties/PrintHeader/PrintHeaderHooks';
import DropDownArrow from '../DropDownArrow/DropDownArrow';
import { menuPropsDefault } from '../JoinSelect/JoinSelectStyles';

import ActiveBanner from './ActiveBanner';
import CompanyLogo from './CompanyLogo';
import styles from './HeaderSelectorStyles';
import TeamImage from './TeamImage';
import TeamLogo from './TeamLogo';

type HeaderSelectorProps = {
  banners: ProjectBanner[];
  canEdit: boolean;
  classes: Classes<typeof styles>;
  companies: ProjectCompany[];
  onChange: (assetID: UUID | null) => void;
  orgLogoIDs: string[];
  projectID: UUID;
  selected: ActiveProjectBanner | null;
};

const HeaderSelector: FC<HeaderSelectorProps> = ({
  banners,
  canEdit,
  classes,
  companies,
  onChange,
  orgLogoIDs,
  projectID,
  selected,
}) => {
  // Hooks
  const sendAnalytics = useSendAnalytics();
  const removeBanner = useRemoveProjectBanner();
  const setRemoveBanner = (id: UUID) => removeBanner(id, projectID);

  // State
  const [open, setOpen] = useState(false);

  // Components
  const teamImages = {
    component: orgLogoIDs.map((logoID: UUID) => <TeamImage key={logoID} assetID={logoID} />),
    value: TEAM_LOGOS,
  };

  const emptyPlaceholder = {
    component: <div className={classes.emptyOption}>No Print Header</div>,
    value: NO_LOGO,
  };

  // Active banner
  const activeBanner = selected && selected.asset && <ActiveBanner assetID={selected.asset.id} />;

  // Selected
  const isEmptyTeamLogos = !banners.length;
  const nullOption = (isEmptyTeamLogos && teamImages) || emptyPlaceholder;
  const bannerSelected = activeBanner || nullOption.component;
  const selectedOrPlaceholder = <div className={classes.imagesContainer}>{bannerSelected}</div>;

  // Options
  const optionNone = (
    <MenuItem
      key="none"
      classes={{
        root: classes.rootItem,
      }}
      className={classes.subheader}
      disableRipple
      value={nullOption.value}
    >
      {nullOption.component}
    </MenuItem>
  );

  const optionsBanners = banners
    .map(
      ({ id, bannerName, asset }) =>
        id &&
        asset && (
          <MenuItem
            key={JSON.stringify(id)}
            classes={{
              root: classes.rootItem,
            }}
            className={classes.subheader}
            disableRipple
            title={bannerName || undefined}
            value={id}
          >
            <div className={classes.contentContainer}>
              {asset && <TeamLogo assetID={asset.id} bannerName={bannerName} />}
              <IconButton
                className={classes.deleteButton}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                onClick={(evt: any) => {
                  evt.stopPropagation();
                  setRemoveBanner(id);
                  sendAnalytics(
                    settingsPrintHeaderSelector(SETTINGS_REMOVE_BANNER_ACTION, id, CUSTOM_BANNER)
                  );
                  setOpen(false);
                }}
              >
                <DeleteOutline nativeColor={theme.palette.ds.button.destructive} />
              </IconButton>
            </div>
          </MenuItem>
        )
    )
    .filter((c) => !!c);

  const optionsCompanies = companies
    .map(({ company: { asset, name } }) => {
      if (!asset) return null;
      const { id } = asset;
      return (
        <MenuItem
          key={id}
          classes={{
            root: classes.rootItem,
          }}
          className={classes.subheader}
          disableRipple
          value={id}
        >
          <CompanyLogo asset={asset} name={name} />
        </MenuItem>
      );
    })
    .filter((c) => !!c);

  const options = [optionNone, ...optionsBanners, ...optionsCompanies];

  // if null, the correct option is first, and remains unsorted.
  // otherwise, we match
  options.sort((a) =>
    a && a.key && selected && selected.asset && selected.asset.location === a.key ? -1 : 0
  );

  // Select component
  const selectComponent = (
    <Select
      classes={{ selectMenu: classes.selectMenu }}
      disabled={!canEdit}
      disableUnderline
      displayEmpty
      IconComponent={() => (!canEdit ? null : <DropDownArrow onClick={() => setOpen(true)} />)}
      input={<Input value={(selected && selected.id) || ''} />}
      inputProps={{ 'aria-label': 'Print Header Selector ' }} // fix
      MenuProps={{
        ...menuPropsDefault,
        PopoverClasses: {
          paper: classes.location,
        },
      }}
      onChange={(e) => {
        const selectedID = e.target.value || null;
        onChange(selectedID);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
        sendAnalytics(settingsPrintHeaderSelector(SETTINGS_PRINT_HEADER_SELECTOR));
      }}
      open={open}
      renderValue={() => selectedOrPlaceholder}
    >
      {options}
    </Select>
  );

  return (
    <div className={classes.selectContainer}>
      <FormControl fullWidth>{selectComponent}</FormControl>
    </div>
  );
};

export default withStyles(styles)(HeaderSelector);
