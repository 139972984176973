import * as d3 from 'd3';

import { formatCost } from '../../../../utilities/currency';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import useMemoWrapper from '../../../useMemoWrapper';
import CostFillPattern from '../ChartsCostTrendline/CostFillPattern';
import CostLabelBlur from '../ChartsCostTrendline/CostLabelBlur';
import TimelineArea from '../TimelineArea';
import TimelineHoverSections from '../TimelineHoverSections';
import TimelinePath from '../TimelinePath';
import TimelinePointTooltip from '../TimelinePointTooltip';

import { CostFields, CostTimeSeries } from './types';
import { getAreaData, getFieldData, getYDomain } from './utils';

const costFormat = { short: true, showCurrencySymbol: false };

type Props = {
  costData: CostTimeSeries[];
  height: number;
  hoverDate?: Date | undefined;
  isPrint?: boolean;
  setHoverDate: (date: Date | undefined) => void;
  today: Date;
  totalRange: [string, string];
};

export default function InsightsCostTrendline(props: Props) {
  const { costData, height, hoverDate, isPrint, setHoverDate, today, totalRange } = props;
  const { ref, dimensions } = useChartDimensions({
    height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  };
  // Last data index, the first point for horizontal lines after today
  const lastIndex = costData.length - 1;

  // x domain
  const xMin = new Date(totalRange[0]);
  const xMax = new Date(totalRange[1]);
  const xDomain = [xMin, xMax];
  const xRange: [number, number] = [margin.left, width - margin.right];
  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  // y domain
  const [yDataMin, yDataMax] = useMemoWrapper(getYDomain, costData);
  const yDelta = yDataMax - yDataMin;

  // Add vertical padding
  const yMin = yDataMin - (6 * yDelta) / 100;
  const yMax = yDataMax + (18 * yDelta) / 100;
  const yDomain = [yMin, yMax];
  const yRange: [number, number] = [height - margin.bottom, margin.top];
  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  // Get cost series data separated
  const pending = useMemoWrapper(getAreaData, costData);
  const budget = useMemoWrapper(getFieldData, costData, CostFields.budget);
  const estimate = useMemoWrapper(getFieldData, costData, CostFields.estimate);
  const runningTotal = useMemoWrapper(getFieldData, costData, CostFields.runningTotal);

  const todayData = [
    { date: today, value: yMin },
    { date: today, value: yMax },
  ];

  const hoverData = hoverDate
    ? [
        { date: hoverDate, value: yMin },
        { date: hoverDate, value: yMax },
      ]
    : undefined;

  const bounds = {
    right: x(xMax),
    left: x(xMin),
    top: y(yMin),
    bottom: y(yMax),
  };

  return (
    <SVGWithDimensions ref={ref} data-cy="line-chart" dimensions={dimensions}>
      {/* Pending Area */}
      <TimelineArea
        data={pending}
        fill="url(#stripes)"
        stroke="var(--colors-chart-pending-cost-area)"
        x={x}
        y={y}
      />
      {/* Estimate */}
      <TimelinePath
        // Before today
        data={estimate}
        stroke="stroke-entities-estimate"
        strokeDasharray="1, 2"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      <TimelinePath
        // After today
        data={[estimate[lastIndex], { date: xMax, value: estimate[lastIndex].value }]}
        stroke="stroke-chart-axis"
        strokeDasharray="1, 2"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Running Total */}
      <TimelinePath
        // Before today
        data={runningTotal}
        stroke="stroke-entities-estimate"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      <TimelinePath
        // After today
        data={[runningTotal[lastIndex], { date: xMax, value: runningTotal[lastIndex].value }]}
        stroke="stroke-chart-axis"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Budget */}
      <TimelinePath
        // Before today
        data={budget}
        stroke="stroke-entities-milestone"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      <TimelinePath
        // After today
        data={[budget[lastIndex], { date: xMax, value: budget[lastIndex].value }]}
        stroke="stroke-chart-axis"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Hover Line */}
      {hoverData && (
        <TimelinePath
          // current hover date
          data={hoverData}
          stroke="stroke-chart-axis"
          strokeWidth={1.5}
          x={x}
          y={y}
        />
      )}
      {/* Today */}
      <TimelinePath
        data={todayData}
        stroke="stroke-selection-focus-fill"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Hover Points */}
      {!isPrint &&
        estimate.map((data) => (
          <TimelinePointTooltip
            key={`${data.date}-${data.value}`}
            content={formatCost(data.value, costFormat)}
            data={data}
            fill="fill-type-primary"
            placement="right"
            type="slim"
            x={x}
            y={y}
          />
        ))}
      {!isPrint &&
        runningTotal.map((data) => (
          <TimelinePointTooltip
            key={`${data.date}-${data.value}`}
            backgroundColor="var(--colors-button-primary)"
            content={formatCost(data.value, costFormat)}
            data={data}
            fill="fill-type-primary"
            isOpen={data.date === hoverDate}
            placement="right"
            type="slim"
            x={x}
            y={y}
          />
        ))}
      {!isPrint &&
        budget.map((data) => (
          <TimelinePointTooltip
            key={`${data.date}-${data.value}`}
            backgroundColor="var(--colors-entities-milestone)"
            content={formatCost(data.value, costFormat)}
            data={data}
            fill="fill-entities-milestone"
            isOpen={data.date === hoverDate}
            placement="right"
            type="slim"
            x={x}
            y={y}
          />
        ))}
      <TimelineHoverSections
        bounds={bounds}
        data={budget}
        onHoverIndex={(index) =>
          index === -1 ? setHoverDate(undefined) : setHoverDate(budget[index]?.date)
        }
        x={x}
      />
      <CostFillPattern />
      <CostLabelBlur />
    </SVGWithDimensions>
  );
}
