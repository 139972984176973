import { updateItemVisibilityAnalytics } from '../../../analytics/analyticsEventProperties';
import { Visibility } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { Button, Dialog, DialogContent } from '../../scales';
import { useSetItemPublished } from '../../ve-item-details/ItemDetailsHooks';

type ItemPublishDialogProps = {
  isOpen: boolean;
  itemCreatedByID?: UUID;
  itemID: UUID;
  projectID: UUID;
  setIsOpen: (setShowPublishItemDialog: boolean) => void;
  onItemMutated?: () => void;
};

export default function ItemPublishDialog(props: ItemPublishDialogProps) {
  const { isOpen, itemCreatedByID, itemID, projectID, setIsOpen, onItemMutated } = props;
  const sendAnalytics = useSendAnalytics();

  const dialogTitle = 'Publish draft?';
  const dialogContent =
    'Publishing this item will make it public and visible to all your teammates on the project. This action cannot be undone.';

  const updateVisibility = useSetItemPublished({ onCompleted: onItemMutated });
  const publishItem = () => {
    sendAnalytics(
      updateItemVisibilityAnalytics({
        id: itemID,
        visibility: Visibility.PUBLISHED,
      })
    );
    updateVisibility(projectID, itemID, itemCreatedByID, undefined);
    setIsOpen(false);
  };

  if (!isOpen) return null;

  return (
    <Dialog
      {...props}
      footerLeft={
        <Button
          data-cy="cancel-button"
          disabled={false}
          label="Cancel"
          onClick={() => setIsOpen(false)}
          type="secondary"
        />
      }
      footerRight={
        <Button
          data-cy="publish-draft-item-button"
          disabled={false}
          label="Publish draft"
          onClick={() => publishItem()}
          type="primary"
        />
      }
      isNotDismissable={false}
      onClose={() => setIsOpen(false)}
      title={dialogTitle}
    >
      <DialogContent className="flex flex-col gap-2">
        <div className="type-body1">{dialogContent}</div>
      </DialogContent>
    </Dialog>
  );
}
