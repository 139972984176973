import { ReactNode } from 'react';

type Props = {
  columnsCount: number;
  component: ReactNode;
};

export default function StickyBottomRow(props: Props) {
  const key = 'sticky-bottom';
  const blank = Array(props.columnsCount - 1).fill(() => ({
    key,
    component: null,
  }));
  const stickyBottomEntry = [
    ...blank,
    {
      key,
      component: props.component,
    },
  ];

  return stickyBottomEntry;
}
