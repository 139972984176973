import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ACCEPTED, PENDING, REJECTED, RUNNING, TARGET } from '../../../constants';
import { ItemsTreeBranchNodeCosts } from '../../../generated/graphql';
import theme from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { renderCostString, statusDescription } from '../../CostReport/CostReportUtils';
import ItemsIcons from '../../ItemsList/ItemsIcons/ItemsIcons';
import {
  SMALL,
  TRENDING_DOWN,
  TRENDING_FLAT,
  TRENDING_UP,
} from '../../ItemsList/ItemsIcons/ItemsIconsMap';

import getRangeStyle from './ItemsListHeadersUtils';

// TYPES

type ItemsListHeadersCostProps = {
  compress?: boolean;
  node: { display?: string; id: UUID; nodeCosts?: ItemsTreeBranchNodeCosts };
  status: string;
};

type CostData = {
  cost?: Cost;
  segmented?: AddDeductCost;
};

const getSegmentedCostData = (costData: CostData, status: string) => {
  const { cost, segmented } = costData;
  const { adds, deducts } = segmented || {};
  const showSegmented = status === PENDING || status === REJECTED;
  return { cost, adds, deducts, showSegmented };
};

const getCostData = (status: string, costs?: ItemsTreeBranchNodeCosts) => {
  if (!costs) return undefined;
  switch (status) {
    case PENDING: {
      const cost = costs.pendingAddsDeductsCost;
      if (!Number(cost.adds) && !Number(cost.deducts)) return undefined;
      return { segmented: cost };
    }
    case REJECTED: {
      const cost = costs.rejectedAddsDeductsCost;
      if (!Number(cost.adds) && !Number(cost.deducts)) return undefined;
      return { segmented: cost };
    }
    case ACCEPTED: {
      const cost = costs.acceptedCost;
      if ('value' in cost && !Number(cost.value)) return undefined;
      return { cost };
    }
    case RUNNING: {
      const cost = costs.runningTotalCost;
      if ('value' in cost && !Number(cost.value)) return undefined;
      return { cost };
    }
    case TARGET: {
      const cost = costs.targetCost;
      if ('value' in cost && !Number(cost.value)) return undefined;
      return { cost };
    }
    default:
      break;
  }
  return undefined;
};

const renderExactCost = (cost: Cost) =>
  renderCostString({
    cost,
    isExact: true,
    isWide: true,
    showCents: true,
  });

// HELPER FUNCTIONS
export const getDescription = (status: string, costData?: CostData) => {
  let description =
    status === RUNNING
      ? 'Revised estimate, including accepted items, for this category:\n'
      : statusDescription(status);
  if (costData) {
    const { adds, deducts, cost, showSegmented } = getSegmentedCostData(costData, status);
    if (showSegmented) {
      const hasAdds = adds && adds > 0;
      const hasDeducts = deducts && deducts < 0;
      if (hasAdds) {
        description += `up to +${renderExactCost({
          value: String(adds),
        })} adds`;
      }
      if (hasAdds && hasDeducts) {
        description += ',\n';
      }
      if (hasDeducts) {
        description += `up to ${renderExactCost({
          value: String(deducts),
        })} deducts`;
      }
    } else {
      description += renderExactCost(cost);
    }
  }
  return description;
};

export const getIcon = (status: string, accepted?: CostData) => {
  if (status === TARGET) return status;
  if (status === RUNNING) {
    if (accepted) {
      const value = Number((accepted.cost as CostScalar).value);
      if (value > 0) return TRENDING_UP;
      if (value < 0) return TRENDING_DOWN;
    }
    return TRENDING_FLAT;
  }
  return '';
};

const getContent = (
  status: string,
  costData?: CostData,
  isWide?: boolean,
  isSigned?: boolean,
  settingsOverride?: ProjectSettingStore // primarily used for testing
) => {
  if (!costData) return <p className="type-table-text">--</p>;
  const { adds, deducts, cost, showSegmented } = getSegmentedCostData(costData, status);
  const hasAdds = adds && adds > 0;
  const hasDeducts = deducts && deducts < 0;
  const isRange = hasAdds && hasDeducts;
  const costStyle = {
    ...theme.typography.number,
    fontSize: '0.9rem',
  };
  const rangeStyle = {
    ...theme.typography.number,
    fontSize: '0.75rem',
  };
  const segmentedStyle = isRange ? rangeStyle : costStyle;
  const costFormatting = {
    showCents: true,
    rounded: isWide,
    short: !isWide,
    signed: isSigned,
    settingsOverride,
  };
  return (
    <div>
      {showSegmented && hasAdds && (
        <p style={segmentedStyle}>{formatCost(adds.toString(), costFormatting)}</p>
      )}
      {showSegmented && hasDeducts && (
        <p style={segmentedStyle}>{formatCost(deducts.toString(), costFormatting)}</p>
      )}
      {(!showSegmented || (!hasAdds && !hasDeducts)) && (
        <p style={costStyle}>{formatCost((cost as CostScalar).value.toString(), costFormatting)}</p>
      )}
    </div>
  );
};

const ItemsListHeadersCost: FC<ItemsListHeadersCostProps> = ({
  compress = false,
  node,
  status,
}) => {
  // CONSTANTS
  const minWidth = theme.breakpoints.values.lg;
  const isWideMedia = useMediaQuery({ minWidth });
  const { isWide, isSigned } = getRangeStyle({
    compress,
    status,
    isWideMedia,
  });
  const costData = getCostData(status, node.nodeCosts);
  const accepted = { cost: node.nodeCosts?.acceptedCost };

  // COMPONENT LOGIC

  const content = getContent(status, costData, isWide, isSigned);

  const icon = getIcon(status, accepted);

  return (
    <div
      className={`flex flex-shrink-0 flex-wrap-reverse items-center justify-end text-right ${
        isWide ? 'basis-32' : 'basis-20'
      }`}
      data-cy={`itemsListHeadersCost-${status}`}
      title={getDescription(status, costData)}
    >
      {costData && icon && (
        <div className="pr-1">
          <ItemsIcons status={icon} variant={SMALL} />
        </div>
      )}
      {content}
    </div>
  );
};

export default ItemsListHeadersCost;
