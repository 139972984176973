import { HTMLAttributes, ReactNode, forwardRef } from 'react';

import { useScrollToHashOnce } from '../../../utilities/scrolling';

type Props = {
  color?: string;
  shadow?: boolean;
  children: ReactNode;
} & Omit<HTMLAttributes<HTMLDivElement>, 'className' | 'color' | 'style'>;

export default forwardRef<HTMLDivElement, Props>(function Card(props, ref) {
  const { id, children, color, shadow, ...divProps } = props;
  useScrollToHashOnce(window.location.hash, 0, true);

  return (
    <div
      ref={ref}
      className={`flex w-[500px] flex-none  cursor-default flex-col items-stretch overflow-auto rounded outline-none ${
        color ? `border-t-[10px] pt-[6px]` : 'border pt-[15px]'
      } ${shadow ? 'shadow' : ''}`}
      id={id}
      style={{ borderColor: color }}
      {...divProps}
    >
      {children}
    </div>
  );
});
