import { FC } from 'react';
import * as React from 'react';

import { FormulaDisplayType } from '../../../generated/graphql';

interface FormulaCellEditorInputProps {
  formulas: FormulaDisplay[];
  index: number;
  onKeyDown: (event: React.KeyboardEvent<HTMLElement>, form: FormulaDisplay, index: number) => void;
  refs: React.MutableRefObject<HTMLInputElement[]>;
  setFocused: (f: number) => void;
  setFormulas: (f: FormulaDisplay[]) => void;
}

// I have to manually set the size of each input
// to match the size of the content since input
// components aren't meant to change size (especially in a flex box).
// This size seems to work well at .925 ch per character.
const textScaleFactor = 0.925;

const FormulaCellEditorInput: FC<FormulaCellEditorInputProps> = ({
  formulas,
  index,
  onKeyDown,
  refs,
  setFocused,
  setFormulas,
}) => {
  const formula = formulas[index];
  const { text, type, error } = formula;
  // if this is a reference without an error, then the text is blue
  // if the reference has an error then show red
  let className = '';
  if (type === FormulaDisplayType.FORMULA_DISPLAY_REFERENCE) {
    className = error
      ? 'join-grid-text-input-formula-reference-error'
      : 'join-grid-text-input-formula-reference';
  } else {
    className = 'join-grid-text-input-formula-text';
  }
  return (
    <input
      ref={(ref) => {
        // eslint-disable-next-line no-return-assign, no-param-reassign
        if (ref) refs.current[index] = ref;
      }}
      className={className}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      onChange={(event: any) => {
        // do nothing if the value is the same
        if (formula.text === event.target.value) return;

        // update the formulas
        const newFormula = formulas.map((f, i) => {
          return { ...f, index: i };
        });
        newFormula[index].text = event.target.value;
        setFormulas(newFormula);
      }}
      onFocus={() => setFocused(index)}
      onKeyDown={(event) => {
        onKeyDown(event, formula, index);
      }}
      style={{
        width: `${textScaleFactor * text.length}ch`,
      }}
      title={error}
      value={text}
    />
  );
};

export default FormulaCellEditorInput;
