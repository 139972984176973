import { Button } from '../../scales';

export default function CompanyOrganizationsPlaceholder(props: {
  disabled?: boolean;
  onAdd: () => void;
}) {
  return (
    <div className="flex h-full w-full flex-col gap-4 p-4">
      <div className="flex pt-4">
        Add your organizational structure -- regions, offices, business units, or all of the above!
      </div>
      <div className="flex">
        Employees will be able to add this information when they start new projects and use it as a
        filter in the Projects list as well as Forecasting and Insights.
      </div>
      <div className="flex justify-center p-4">
        <img
          alt="No scenarios add new static"
          src="/img/Company_Settings/Organizations_Placeholder.gif"
          width={800}
        />
      </div>
      <div className="flex justify-center">
        <Button
          disabled={props.disabled}
          label="Add an organization"
          onClick={props.onAdd}
          type="primary"
        />
      </div>
    </div>
  );
}
