import { ProjectType } from '../../../generated/graphql';
import { Select, SelectEntry } from '../../scales';

export type Props = {
  editable?: boolean;
  name: string;
  label: string;
  search?: boolean;
  selectedValue?: string | null;
  setProjectProp: (p: ProjectType | null) => void;
  values: ProjectType[];
};

export default function ProjectTypeSelector({
  editable,
  name,
  label,
  search,
  selectedValue,
  setProjectProp,
  values,
}: Props) {
  const entries: SelectEntry[] = values.map(({ id, name, parentID }) => ({
    id,
    label: name,
    parentID,
  }));

  const selector = (
    <Select
      data-cy="project-type-select"
      entries={entries}
      isDisabled={!editable}
      isSearchable={search}
      label={label}
      onChange={(newID: UUID | null) => {
        const newProp = (values as ProjectType[]).find((p) => p.id === newID) || null;
        setProjectProp(newProp);
      }}
      placeholder={`Set project ${name}`}
      value={selectedValue}
    />
  );

  return selector;
}
