import { FC, useEffect, useMemo } from 'react';

import isEqual from 'lodash/isEqual';

import { reportItemsList } from '../../analytics/analyticsEventProperties';
import { ScheduleImpactFilter } from '../../api/gqlEnumsBe';
import {
  ALL_MILESTONES,
  CREATOR,
  FILTER_ITEM_CREATOR_ALL,
  FILTER_ITEM_CREATOR_NONE,
  FILTER_ITEM_INTEGRATIONS_ALL,
  FILTER_ITEM_SHARE_SETTINGS_ALL,
  FILTER_ITEM_SHARE_SETTINGS_NONE,
  FILTER_ITEM_STATUS_ALL,
  FILTER_ITEM_STATUS_NONE,
  ITEM,
  ITEMS,
  ITEM_WITH_OPTIONS,
  NULL_ID,
  SHOW,
  STATUS,
  VISIBILITY,
} from '../../constants';
import { NS_PROCORE_CHANGE_EVENT_INTEGRATION } from '../../features';
import { useHasFeature } from '../../hooks/useHasFeature';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { getItemStatusLabel } from '../../utilities/item-status';
import { statuses } from '../../utilities/sorting';
import { getProjectIdFromUrl } from '../../utilities/url';
import { SetSettingsFunctionType } from '../../utilities/urlState';
import { visibilityDisplayStrings } from '../../utilities/visibility/constants';
import FilterPanelWrapper from '../FilterPanel/FilterPanelWrapper';
import { FilterManager } from '../FilterPanel/filterUtils';
import FilterSelect from '../Inputs/InputsFilterSelect/InputsFilterSelect';
import { isScheduleSettingsDisabled } from '../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import { Select } from '../scales';
import { renderTextValue } from '../Select/JoinSelect/JoinSelectUtils';

import { useMilestoneContingenciesQuery } from './hooks/useMilestoneContingenciesQuery';
import {
  ALL_STATUSES,
  CONTINGENCY_DRAW_FILTER_KEY,
  INTEGRATIONS_FILTERS,
  INTEGRATIONS_FILTER_KEY,
  INTEGRATIONS_FILTER_NAMES,
  ITEMLIST_FILTER_DEFAULTS,
  ItemsListSettings,
  SCHEDULE_IMPACT_FILTERS,
  SCHEDULE_IMPACT_FILTER_KEY,
  SCHEDULE_IMPACT_FILTER_NAMES,
  VISIBILITIES,
} from './ItemsListUtils';

const valueStyle = 'flex items-center py-[7px] pr-4 ml-2 type-body1';

type ItemsListFilterPanelProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  creators: any;
  filterManager: FilterManager;
  setSettings: SetSettingsFunctionType;
  setShowFilterPanel: (value: boolean) => void;
  settings: ItemsListSettings;
  showFilterPanel: boolean;
  showContingencies: boolean;
};

const ItemsListFilterPanel: FC<ItemsListFilterPanelProps> = ({
  creators,
  filterManager,
  setSettings,
  setShowFilterPanel,
  settings,
  showFilterPanel,
  showContingencies,
}) => {
  const projectID = getProjectIdFromUrl();
  const {
    creator = [],
    show,
    status = [],
    visibility = [],
    scheduleImpacts = [],
    contingencyDraws = [],
    integrations,
    currentMilestone,
  } = settings;
  // we don't want to use the "current milestone" if it's set to ALL_MILESTONES
  const currentMilestoneID =
    currentMilestone[0] && currentMilestone[0] !== ALL_MILESTONES ? currentMilestone[0] : undefined;
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();
  const contingencyFilterOptions = useMilestoneContingenciesQuery(
    projectID,
    currentMilestoneID,
    !showContingencies // skips the query if the flag is off
  );

  const milestoneContingencyFilters = contingencyFilterOptions.data?.getMilestoneContingencies;
  const availableContingencyFilters = useMemo(
    () =>
      milestoneContingencyFilters?.length
        ? [{ id: NULL_ID, name: 'Items without Draws' }, ...milestoneContingencyFilters]
        : undefined,
    [milestoneContingencyFilters]
  );
  const labelMap =
    availableContingencyFilters &&
    new Map<string, string>(availableContingencyFilters.map((a) => [a.id, a.name]));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const creatorOptions = creators.map((c: any) => c.id);

  const displayedCreators =
    creator.length !== 0
      ? creator.filter((id: UUID) => creatorOptions.includes(id))
      : creatorOptions;

  const displayedContingencyDraws =
    contingencyDraws.length !== 0
      ? contingencyDraws
      : availableContingencyFilters?.map((a) => a.id) ?? [];

  const sendAnalytics = useSendAnalytics();

  const setCreator = (creatorSetting: { [CREATOR]: string[] }) => {
    sendAnalytics(reportItemsList(creatorSetting[CREATOR], CREATOR, settings));
    const newCreatorIds = creatorSetting[CREATOR];
    if (newCreatorIds.length === 0) {
      // Some -> none
      setSettings({ [CREATOR]: [''] });
    } else if (newCreatorIds[0] === '') {
      // None -> some
      setSettings({ [CREATOR]: [newCreatorIds[1]] });
    } else if (newCreatorIds.length === creators.length) {
      // Some -> all
      setSettings({ [CREATOR]: ITEMLIST_FILTER_DEFAULTS[CREATOR] });
    } else {
      // Some -> some
      setSettings(creatorSetting);
    }
  };

  // Update the persistent state when all of a creator's items are deleted.
  useEffect(() => {
    if (
      creator.length > 0 &&
      creator[0] !== '' &&
      creatorOptions.length > 0 &&
      !creatorOptions.includes(creator[0])
    ) {
      setSettings({ creator: [''] });
    }
  }, [creator, creatorOptions, setSettings]);

  const setScheduleImpact = (setting: { [SCHEDULE_IMPACT_FILTER_KEY]: string[] }) => {
    const scheduleImpacts: ScheduleImpactFilter[] = setting[SCHEDULE_IMPACT_FILTER_KEY].map((s) =>
      Number(s)
    );
    sendAnalytics(
      reportItemsList(
        scheduleImpacts.map((s) => SCHEDULE_IMPACT_FILTER_NAMES[s]),
        SCHEDULE_IMPACT_FILTER_KEY,
        settings
      )
    );
    setSettings({ [SCHEDULE_IMPACT_FILTER_KEY]: scheduleImpacts });
  };

  const hasIntegrationsFeature = useHasFeature(NS_PROCORE_CHANGE_EVENT_INTEGRATION);

  const setContingencyDraws = (setting: { [CONTINGENCY_DRAW_FILTER_KEY]: string[] }) => {
    let contingencyDraws: string[] = setting[CONTINGENCY_DRAW_FILTER_KEY];
    // if every filter value is selected, set it back to the default
    if (contingencyDraws.length === availableContingencyFilters?.length) {
      contingencyDraws = ITEMLIST_FILTER_DEFAULTS[CONTINGENCY_DRAW_FILTER_KEY];
    }
    sendAnalytics(reportItemsList(contingencyDraws, CONTINGENCY_DRAW_FILTER_KEY, settings));
    setSettings({ [CONTINGENCY_DRAW_FILTER_KEY]: contingencyDraws });
  };

  const setStatus = (statusSetting: { [STATUS]: string[] }) => {
    sendAnalytics(reportItemsList(statusSetting[STATUS], STATUS, settings));
    setSettings(statusSetting);
  };

  const setVisibility = (statusSetting: { [VISIBILITY]: string[] }) => {
    sendAnalytics(reportItemsList(statusSetting[VISIBILITY], VISIBILITY, settings));
    setSettings(statusSetting);
  };

  const setShow = (showSetting: string) => {
    sendAnalytics(reportItemsList(showSetting, 'show items', settings));
    setSettings({ [SHOW]: showSetting });
  };

  const setIntegrations = (integrationSetting: { [INTEGRATIONS_FILTER_KEY]: string[] }) => {
    sendAnalytics(
      reportItemsList(
        integrationSetting[INTEGRATIONS_FILTER_KEY],
        INTEGRATIONS_FILTER_KEY,
        settings
      )
    );
    setSettings(integrationSetting);
  };

  const resetNonCategoryFilters = () => {
    sendAnalytics(reportItemsList('reset', 'reset', settings));
    setSettings({
      viewFilter: JSON.stringify({}),
      [SCHEDULE_IMPACT_FILTER_KEY]: ITEMLIST_FILTER_DEFAULTS[SCHEDULE_IMPACT_FILTER_KEY],
      [SHOW]: ITEMLIST_FILTER_DEFAULTS[SHOW],
      [STATUS]: ITEMLIST_FILTER_DEFAULTS[STATUS],
      [CREATOR]: ITEMLIST_FILTER_DEFAULTS[CREATOR],
      [CONTINGENCY_DRAW_FILTER_KEY]: ITEMLIST_FILTER_DEFAULTS[CONTINGENCY_DRAW_FILTER_KEY],
      [VISIBILITY]: ITEMLIST_FILTER_DEFAULTS[VISIBILITY],
      [INTEGRATIONS_FILTER_KEY]: ITEMLIST_FILTER_DEFAULTS[INTEGRATIONS_FILTER_KEY],
    });
  };

  const compareSettings = (key: keyof typeof ITEMLIST_FILTER_DEFAULTS): number => {
    // statuses start filtered by default
    if (key === STATUS) {
      return isEqual(settings[key].sort(), statuses.sort()) ? 0 : 1;
    }
    return isEqual(settings[key], ITEMLIST_FILTER_DEFAULTS[key]) ? 0 : 1;
  };

  const numAdditionalFilters =
    compareSettings(SHOW) +
    compareSettings(STATUS) +
    compareSettings(CREATOR) +
    compareSettings(INTEGRATIONS_FILTER_KEY);

  const renderStatusValue = useMemo(
    () =>
      renderTextValue(
        status.length === ALL_STATUSES.length,
        FILTER_ITEM_STATUS_ALL,
        status.length === 0,
        FILTER_ITEM_STATUS_NONE,
        (text: string) => () => <div className={valueStyle}>{text}</div>
      ),
    [status.length]
  );

  const visilityAllText = FILTER_ITEM_SHARE_SETTINGS_ALL;
  const visilityNoneText = FILTER_ITEM_SHARE_SETTINGS_NONE;
  const renderVisibilityValue = useMemo(
    () =>
      renderTextValue(
        visibility.length === VISIBILITIES.length,
        visilityAllText,
        visibility.length === 0,
        visilityNoneText,
        (text: string) => () => <div className={valueStyle}>{text}</div>
      ),
    [visibility.length, visilityAllText, visilityNoneText]
  );

  const renderIntegrationValue = useMemo(
    () =>
      renderTextValue(
        integrations?.length === INTEGRATIONS_FILTERS.length,
        FILTER_ITEM_INTEGRATIONS_ALL,
        integrations?.length === 0,
        FILTER_ITEM_INTEGRATIONS_ALL,
        (text: string) => () => <div className={valueStyle}>{text}</div>
      ),
    [integrations]
  );

  const renderCreatorValue = useMemo(
    () =>
      renderTextValue(
        creator.length === 0,
        FILTER_ITEM_CREATOR_ALL,
        displayedCreators.length === 0,
        FILTER_ITEM_CREATOR_NONE,
        (text: string) => () => <div className={valueStyle}>{text}</div>
      ),
    [creator.length, displayedCreators.length]
  );

  const renderScheduleImpactValue = useMemo(
    () =>
      renderTextValue(
        scheduleImpacts.length === SCHEDULE_IMPACT_FILTERS.length,
        'All schedule impact types',
        scheduleImpacts.length === 0,
        'No schedule impact types selected',
        (text: string) => () => <div className={valueStyle}>{text}</div>
      ),
    [scheduleImpacts.length]
  );

  const renderContingencyDrawValue = useMemo(
    () =>
      renderTextValue(
        contingencyDraws.length === 0,
        'All draws',
        displayedContingencyDraws.length === 0,
        'No draws selected',
        (text: string) => () => <div className={valueStyle}>{text}</div>
      ),
    [contingencyDraws.length, displayedContingencyDraws.length]
  );

  const canClearFilters =
    !isEqual(settings[SHOW], ITEMLIST_FILTER_DEFAULTS[SHOW]) ||
    !isEqual(settings[STATUS].sort(), ITEMLIST_FILTER_DEFAULTS[STATUS].sort()) ||
    !isEqual(settings[CREATOR], ITEMLIST_FILTER_DEFAULTS[CREATOR]) ||
    !isEqual(settings[VISIBILITY], ITEMLIST_FILTER_DEFAULTS[VISIBILITY]) ||
    settings[SCHEDULE_IMPACT_FILTER_KEY].length !==
      ITEMLIST_FILTER_DEFAULTS[SCHEDULE_IMPACT_FILTER_KEY].length ||
    !isEqual(
      settings[CONTINGENCY_DRAW_FILTER_KEY],
      ITEMLIST_FILTER_DEFAULTS[CONTINGENCY_DRAW_FILTER_KEY]
    ) ||
    !isEqual(settings[INTEGRATIONS_FILTER_KEY], ITEMLIST_FILTER_DEFAULTS[INTEGRATIONS_FILTER_KEY]);
  const nonCategoryFilters = (
    <>
      <div className="flex flex-col gap-0.5">
        <div className="type-label">Show</div>
        <Select
          data-cy="item-type-select"
          entries={[
            {
              id: ITEM_WITH_OPTIONS,
              label: 'Items and Options',
              'data-cy': 'select-Items and Options',
            },
            { id: ITEM, label: 'Items', 'data-cy': 'select-Items' },
          ]}
          onChange={(type: string | null) => setShow(type || '')}
          value={show}
        />
      </div>
      <div className="flex flex-col gap-0.5" data-cy="filterSelect-Status">
        <div className="type-label">Status</div>
        <FilterSelect
          defaultLabel="No Items"
          filterKey={STATUS}
          labelFn={getItemStatusLabel}
          maxChipsShown={3}
          onChange={setStatus}
          options={statuses}
          renderValue={renderStatusValue}
          selected={status}
        />
      </div>
      <div className="flex flex-col gap-0.5" data-cy="filterSelect-Visibility">
        <div className="type-label">Share Settings</div>
        <FilterSelect
          defaultLabel="No Items"
          filterKey={VISIBILITY}
          labelFn={(o) => o && visibilityDisplayStrings.get(o)}
          maxChipsShown={3}
          onChange={setVisibility}
          options={VISIBILITIES}
          renderValue={renderVisibilityValue}
          selected={visibility}
        />
      </div>
      <div className="flex flex-col gap-0.5" data-cy="filterSelect-Creator">
        <div className="type-label">Creator</div>
        <FilterSelect
          defaultLabel="All Creators"
          displaySelectAll
          filterKey={CREATOR}
          labelFn={(id) =>
            (creators &&
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              creators.find((c: any) => c.id === id) &&
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              creators.find((c: any) => c.id === id).name) ||
            ''
          }
          onChange={setCreator}
          options={creatorOptions}
          renderValue={renderCreatorValue}
          selected={displayedCreators}
        />
      </div>
      {isScheduleImpactEnabled && (
        <div className="flex flex-col gap-0.5" data-cy="filterSelect-ScheduleImpact">
          <div className="type-label">Schedule impact</div>
          <FilterSelect
            defaultLabel="All schedule impact types"
            displaySelectAll
            filterKey={SCHEDULE_IMPACT_FILTER_KEY}
            labelFn={(s) => {
              const n: ScheduleImpactFilter = Number(s);
              return SCHEDULE_IMPACT_FILTER_NAMES[n];
            }}
            onChange={setScheduleImpact}
            options={SCHEDULE_IMPACT_FILTERS.map((s) => s.toString())}
            renderValue={renderScheduleImpactValue}
            selected={scheduleImpacts.map((s) => s.toString())}
          />
        </div>
      )}
      {hasIntegrationsFeature && (
        <div className="flex flex-col gap-0.5" data-cy="filterSelect-Integrations">
          <div className="type-label">Integrations</div>
          <FilterSelect
            defaultLabel="All Items"
            filterKey={INTEGRATIONS_FILTER_KEY}
            labelFn={(s) => {
              return INTEGRATIONS_FILTER_NAMES.get(s) ?? '';
            }}
            maxChipsShown={2}
            onChange={setIntegrations}
            options={INTEGRATIONS_FILTERS.map((f) => f.toString())}
            renderValue={renderIntegrationValue}
            selected={integrations}
          />
        </div>
      )}
      {showContingencies && availableContingencyFilters && labelMap && (
        <div className="flex flex-col gap-0.5" data-cy="filterSelect-ContingencyDraws">
          <div className="type-label">Contingencies &amp; Allowance Draws</div>
          <FilterSelect
            defaultLabel="All draws"
            displaySelectAll
            filterKey={CONTINGENCY_DRAW_FILTER_KEY}
            labelFn={(s) => labelMap.get(s) ?? ''}
            onChange={setContingencyDraws}
            options={availableContingencyFilters.map((a) => a.id)}
            renderValue={renderContingencyDrawValue}
            selected={displayedContingencyDraws}
          />
        </div>
      )}
    </>
  );

  const additionalFilterManager = {
    canClear: canClearFilters,
    clearFiltersCTA: 'reset',
    filters: nonCategoryFilters,
    numFilters: numAdditionalFilters,
    clearFilters: resetNonCategoryFilters,
  };
  return (
    <FilterPanelWrapper
      additionalFilters={additionalFilterManager}
      filterManager={filterManager}
      page={ITEMS}
      setShow={setShowFilterPanel}
      show={showFilterPanel}
    />
  );
};

export default ItemsListFilterPanel;
