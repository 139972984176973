export const getCommonDomain = (commonDomain: string[], domain: string[]) => {
  const start = new Date(domain[0]);
  const end = new Date(domain[1]);
  if (start.getTime() < 0 || end.getTime() < 0) {
    return commonDomain;
  }
  const commonStart = new Date(commonDomain[0]);
  const commonEnd = new Date(commonDomain[1]);
  const newCommonDomain = [commonDomain[0], commonDomain[1]];
  if (commonStart > start) {
    newCommonDomain[0] = domain[0];
  }
  if (commonEnd < end) {
    newCommonDomain[1] = domain[1];
  }
  return newCommonDomain;
};
