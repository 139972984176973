import { DeleteOutline } from '@material-ui/icons';

import { DesignPhaseType } from '../../../../generated/graphql';
import MilestoneDesignPhaseSelector from '../../../Milestone/MilestoneDesignPhaseSelector/MilestoneDesignPhaseSelector';
import { IconButton, Switch, TextInput } from '../../../scales';

type AlertProps = {
  id: number;
  onDelete: (id: number) => void;
  designPhaseTypes: DesignPhaseType[];
};

const PathToBudgetAlert = ({ id, onDelete, designPhaseTypes }: AlertProps) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <Switch checked={false} data-cy="switch-gap" label="" onChange={() => {}} />
        <div className="type-body2">Best case gap greater than</div>
        <div className="w-20">
          <TextInput
            aria-label="Gap Value"
            endAdornment={<div>%</div>}
            onChange={() => {}}
            value=""
          />
        </div>
        <div className="type-body2">during</div>
        <div>
          <MilestoneDesignPhaseSelector
            disabled={false}
            selectedValue=""
            setSelection={() => {}}
            values={designPhaseTypes}
          />
        </div>
      </div>
      <IconButton
        aria-label="delete node"
        icon={<DeleteOutline color="error" />}
        onClick={() => onDelete(id)}
        type="secondary"
      />
    </div>
  );
};

export default PathToBudgetAlert;
