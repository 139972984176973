import { FC } from 'react';

import ShareGroup from '../../Icons/ShareGroup';
import { Tooltip } from '../../scales';

import SharedUsersTooltipContent from './SharedUsersTooltipContent';

type SharedUsersStateProps = {
  onTooltip?: () => void;
  sharedUsers?: Pick<User, 'id' | 'name'>[];
};

const SharedUsersState: FC<SharedUsersStateProps> = ({ onTooltip, sharedUsers }) => {
  if (!sharedUsers || sharedUsers.length < 2) return null;
  const tooltipContent = (
    <SharedUsersTooltipContent hasPrivateParent={false} sharedUsers={sharedUsers} />
  );
  return (
    <Tooltip content={tooltipContent} onOpen={onTooltip}>
      <div className="flex items-center" data-cy="share-icon">
        <ShareGroup />
        <div className="pl-1 pr-2" data-cy="share-count">
          {sharedUsers.length}
        </div>
      </div>
    </Tooltip>
  );
};

export default SharedUsersState;
