import {
  ScenarioShareEvent,
  ScenarioShareKey,
  scenarioShareEvent,
} from '../../../analytics/analyticsEventProperties';
import { ResourceType } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import SharedUsersState from '../../dragon-scales/SharedUsersState/SharedUsersState';
import { useGetSharedResources } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';

type Props = {
  analyticsKey: ScenarioShareKey;
  scenarioID: UUID;
};

export default function SharedScenarioIcon(props: Props) {
  const sendAnalytics = useSendAnalytics();

  const { data, loading } = useGetSharedResources([props.scenarioID], ResourceType.SCENARIO, true);
  const users = data?.getSharedResources?.resources?.[0]?.users;
  const isShared = !!(users?.length && users?.length >= 2);

  const onShareTooltip = () => {
    sendAnalytics(
      scenarioShareEvent(props.analyticsKey, ScenarioShareEvent.TOOLTIP, { type: 'shared' })
    );
  };
  const sharingBadge = <SharedUsersState onTooltip={onShareTooltip} sharedUsers={users} />;

  if (loading) return null;
  return isShared ? sharingBadge : null;
}
