import { FC, useMemo, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Edit } from '@material-ui/icons';

import {
  LeadEvent,
  LeadKey,
  SettingsEvent,
  leadEvent,
  settingsEvent,
  updateProjectDeliveryTypeAnalytics,
  updateProjectDescriptionAnalytics,
  updateProjectNameAnalytics,
  updateProjectShortNameAnalytics,
  updateProjectStatusAnalytics,
} from '../../analytics/analyticsEventProperties';
import { projectCurrencyOpenVar, projectSettingsVar } from '../../api/apollo/reactiveVars';
import {
  NULL_ID,
  PROJECT_LOCATION_CITY,
  PROJECT_SHORTNAME,
  SET_PROJECT_LOCATION_PLACEHOLDER,
  UNTITLED_PROJECT,
} from '../../constants';
import { YC_GROUPS, YC_PROJ_LEAD } from '../../features';
import { CompanyProjectRole, LocationDetailsInput, ProjectType } from '../../generated/graphql';
import useUpdateProject from '../../hooks/ProjectHooks';
import useCollaboratorsQuery from '../../hooks/useCollaboratorsQuery';
import { useCompanyUserQuery } from '../../hooks/useCompanyUserQuery';
import { useGetProjectTypesQuery } from '../../hooks/useGetProjectTypesQuery';
import { useHasFeature } from '../../hooks/useHasFeature';
import { useProjectDeliveryTypes } from '../../hooks/useProjectDeliveryTypesQuery';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { showCurrencyDescription } from '../../utilities/currency';
import { isNonNullable } from '../../utilities/types';
import AssigneeSelectData from '../AssigneeSelect/AssigneeSelectData';
import { useRemoveProjectLead, useSetProjectLead } from '../Collaborators/hooks/projectLead';
import { useCurrentCompanyProjectRoleQuery } from '../CompanyTab/CompanyHooks';
import useOrganizationsQuery from '../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import OrgSelector from '../CompanyTab/CompanyTabOrganizations/OrgSelector/OrgSelector';
import { useCompanyUsersQuery } from '../CompanyTab/useCompanyUsersQuery';
import {
  computeClearInactive,
  computeProjectTypes,
} from '../Dialogs/DialogsNewProject/DialogsNewProjectUtils';
import ProjectTypeSelector from '../Dialogs/DialogsNewProject/ProjectTypeSelector';
import { SelectLevelsEntry } from '../frame/AdvancedFiltersSidebar/FilterGroupLevels';
import InputsTextAreaStyled from '../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';
import useProjectStatusesQuery from '../ProjectsList/hooks/useProjectStatusesQuery';
import { BabyButton, TextInput } from '../scales';
import PlacesAutocompleteWrapper from '../shared-widgets/PlacesAutocomplete/PlacesAutocompleteWrapper';
import useMemoWrapper from '../useMemoWrapper';

import ChangeProjectCurrencyModal from './ProjectCurrency/ChangeProjectCurrencyModal';
import { useSetProjectType } from './ProjectPropertiesHooks';
import ProjectPropSelector, { ProjectProp } from './ProjectPropSelector';
import Thumbnail from './ProjectThumbnail';

export const MAX_PROJECT_CODE_LENGTH = 5;

type ProjectDetailsProps = {
  canEditCurrency: boolean;
  editable?: boolean;
  project: ProjectProps;
};

const ProjectDetails: FC<ProjectDetailsProps> = ({
  canEditCurrency,
  editable = false,
  project,
}) => {
  // Logic
  const {
    id: projectID,
    location,
    description,
    name,
    type,
    status,
    code,
    projectDeliveryType,
    orgNodes,
  } = project;
  const hasType = type && type.id !== NULL_ID;

  // Hooks
  const updateProjectProps = useUpdateProject();
  const [projectChanges, setProjectChanges] = useState(project);
  const setProjectType = useSetProjectType();
  const sendAnalytics = useSendAnalytics();
  const settings = useReactiveVar(projectSettingsVar);
  const showProjectCurrency = useReactiveVar(projectCurrencyOpenVar);
  const isGroupsFeature = useHasFeature(YC_GROUPS);
  const isLeadsFeature = useHasFeature(YC_PROJ_LEAD);

  // Organizations
  const companyID = useCompanyUserQuery().data?.companyUser?.company?.id;
  const organizationsQueryResult = useOrganizationsQuery(companyID);
  const orgs = organizationsQueryResult.data?.organizations;

  // Project Leads
  const { data } = useCollaboratorsQuery(projectID, {
    onCompleted: (data) => {
      if (data.collaborators.length > 0) {
        const lead = collaborators.find((c) => c.isProjectLead)?.user?.email;
        setCurrentProjLead(lead);
      }
    },
  });
  const collaborators = useMemo(() => data?.collaborators || [], [data?.collaborators]);
  const [setProjectLead] = useSetProjectLead();
  const [removeProjectLead] = useRemoveProjectLead();
  const [currentProjLead, setCurrentProjLead] = useState(
    collaborators.find((c) => c.isProjectLead)?.user?.email
  );
  const isAdministratingCompany =
    useCurrentCompanyProjectRoleQuery({
      variables: { projectID },
    }).data?.currentCompanyProjectRole === CompanyProjectRole.ADMINISTRATOR;
  const companyUsers =
    useCompanyUsersQuery({
      variables: { companyID },
      skip: !companyID,
    }).data?.companyUsers ?? [];

  // Project Statuses
  const projectStatuses = useProjectStatusesQuery()?.data?.projectStatuses || [];
  const statuses = useMemoWrapper(computeClearInactive, projectStatuses);

  // Project Types
  const projectTypesResult = useGetProjectTypesQuery(projectID);
  const { data: typesData, loading } = projectTypesResult;
  const projectTypes = typesData?.getProjectTypes || [];
  const loadingTypes = loading;
  const types: ProjectType[] = useMemoWrapper(computeProjectTypes, projectTypes, type);

  // Project Delivery Types
  const projectDeliveryTypes = useProjectDeliveryTypes();

  const closeDialog = () => {
    // Note: Add analytics here in separate PR
    projectCurrencyOpenVar(false);
  };

  return (
    <div className="max-w-[1000px] p-6">
      <div className="flex flex-col gap-2">
        <div className="flex gap-24">
          <div className="flex flex-grow flex-col gap-2">
            <div className="flex flex-col gap-0.5">
              <TextInput
                aria-label="project name"
                data-cy="project-name-text-input"
                disabled={!editable}
                errorMessage={!projectChanges.name?.trim() ? 'Project name is required' : undefined}
                label="Project Name*"
                onBlur={() => {
                  if (projectChanges.name !== project.name && projectChanges.name?.trim()) {
                    sendAnalytics(updateProjectNameAnalytics(project.name, projectChanges.name));
                    updateProjectProps({ id: projectID, name: projectChanges.name });
                  } else {
                    setProjectChanges(project);
                  }
                }}
                onChange={(value) => {
                  setProjectChanges({ ...projectChanges, name: value });
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey && e.target instanceof HTMLElement) {
                    e.target.blur();
                  }
                }}
                placeholder="Set project name"
                value={editable ? projectChanges.name : name || UNTITLED_PROJECT}
              />
            </div>
            <div className="flex flex-col gap-0.5">
              <div className="type-label">Status*</div>
              <ProjectPropSelector
                data-cy="project-status-select"
                editable={editable}
                name="status"
                selectedValue={status?.id}
                setProjectProp={(p) => {
                  if (project.status && p && project.status.id !== p?.id) {
                    const { id, name, description = '' } = p;
                    sendAnalytics(updateProjectStatusAnalytics(project.status.name, name));
                    updateProjectProps({ id: projectID, statusID: id });
                    setProjectChanges({
                      ...projectChanges,
                      status: { __typename: 'ProjectStatus', id, name, description, type: p.name },
                    });
                  }
                }}
                values={statuses}
              />
            </div>
            {!loadingTypes && (
              <>
                {(editable || hasType) && (
                  <ProjectTypeSelector
                    editable={editable}
                    label="Type*"
                    name="type"
                    search
                    selectedValue={type?.id}
                    setProjectProp={(p: ProjectProp | SelectLevelsEntry | null) => {
                      // if the is equals null id this means the user selected
                      // their old existing type that isn't part of our default list
                      if (!p || p?.id === NULL_ID) return;
                      sendAnalytics(updateProjectStatusAnalytics(project?.type?.name, type?.name));
                      setProjectType(projectID, p.id);
                    }}
                    values={types}
                  />
                )}

                {(editable || projectDeliveryType) && (
                  <div className="flex flex-col gap-0.5">
                    <div className="type-label">Project Delivery Method *</div>
                    <ProjectPropSelector
                      data-cy="project-delivery-select"
                      editable={editable}
                      name="delivery method"
                      search
                      selectedValue={projectDeliveryType?.id}
                      setProjectProp={(p: ProjectProp | null) => {
                        if (!p || p?.id === NULL_ID) return;
                        sendAnalytics(
                          updateProjectDeliveryTypeAnalytics(
                            project?.projectDeliveryType?.name || '',
                            p.name
                          )
                        );
                        updateProjectProps({
                          id: projectID,
                          projectDeliveryTypeID: p.id,
                        });
                      }}
                      values={projectDeliveryTypes}
                    />
                  </div>
                )}
                {isLeadsFeature && isAdministratingCompany && (
                  <AssigneeSelectData
                    includeUnassigned
                    isDisabled={!editable}
                    label="Project Lead"
                    onChange={(value) => {
                      const projectLead = collaborators.find((c) => c.user.email === value);
                      const prevProjLead = collaborators.find(
                        (c) => c.user.email === currentProjLead
                      );
                      // Setting project lead to unassigned by remove the current lead
                      if (!projectLead && prevProjLead) {
                        removeProjectLead({
                          variables: { projectID, userID: prevProjLead.user.id },
                        }).then((e) => {
                          if (e.data?.removeProjectLead) setCurrentProjLead(undefined);
                        });
                      } else if (projectLead?.user.id) {
                        setProjectLead({
                          variables: {
                            projectID,
                            userID: projectLead?.user.id,
                          },
                        }).then((e) => {
                          if (e.data?.setProjectLead) setCurrentProjLead(projectLead?.user.email);
                        });
                        sendAnalytics(
                          leadEvent(LeadKey.COMPANY_ADMIN_STATS, LeadEvent.UpdateProjectLead, {
                            userID: projectLead?.user.id,
                            userType: 'team',
                          })
                        );
                      }
                    }}
                    projectID={projectID}
                    validUserIDs={companyUsers.map((c) => c.user?.id).filter(isNonNullable)}
                    value={currentProjLead ?? undefined}
                  />
                )}
                {isGroupsFeature && (
                  <OrgSelector
                    disabled={!editable}
                    isHiddenForNoOrgs
                    label="Organizations"
                    onChange={(orgNodeIDs: string[]) => {
                      updateProjectProps({ id: projectID, orgNodeIDs });
                      sendAnalytics(
                        settingsEvent(SettingsEvent.UPDATE_ORGANIZATIONS, { orgNodeIDs })
                      );
                    }}
                    orgNodes={orgNodes}
                    orgs={orgs}
                  />
                )}
                {(editable || location) && (
                  <div className="flex flex-col gap-0.5">
                    <PlacesAutocompleteWrapper
                      defaultValue={location}
                      disabled={!editable}
                      label={`${PROJECT_LOCATION_CITY} *`}
                      locationRequired
                      onChange={(locationDetails?: LocationDetailsInput) => {
                        if (locationDetails)
                          updateProjectProps({
                            id: projectID,
                            location: locationDetails.name,
                            lat: locationDetails.lat || undefined,
                            lon: locationDetails.lon || undefined,
                            locationDetails,
                          });
                      }}
                      placeholder={SET_PROJECT_LOCATION_PLACEHOLDER}
                    />
                  </div>
                )}
              </>
            )}

            <div className="flex flex-grow gap-2">
              <div className="flex flex-grow flex-col gap-0.5">
                <TextInput
                  aria-label="open change currency dialog"
                  data-cy="project-currency-text-input"
                  disabled={!canEditCurrency}
                  endAdornment={
                    canEditCurrency && (
                      <BabyButton
                        aria-label="currency edit icon"
                        icon={<Edit />}
                        onClick={() => projectCurrencyOpenVar(true)}
                      />
                    )
                  }
                  label="Project Currency"
                  onFocus={(e) => {
                    projectCurrencyOpenVar(true);
                    if (e.target instanceof HTMLElement) e.target.blur();
                  }}
                  value={showCurrencyDescription(settings.CURRENCY)}
                />
                {showProjectCurrency && canEditCurrency && (
                  <ChangeProjectCurrencyModal
                    canEditCurrency
                    onCancel={closeDialog}
                    onClose={closeDialog}
                    open={showProjectCurrency}
                    projectID={projectID}
                  />
                )}
              </div>
              {(editable || code) && (
                <div className="flex flex-grow flex-col gap-0.5">
                  <TextInput
                    aria-label="project shortname"
                    data-cy="project-shortname-text-input"
                    disabled={!editable}
                    label="Project Shortname"
                    maxLength={MAX_PROJECT_CODE_LENGTH}
                    onBlur={() => {
                      if (projectChanges.code !== project.code) {
                        sendAnalytics(
                          updateProjectShortNameAnalytics(project?.code, projectChanges?.code)
                        );
                        updateProjectProps({ id: projectID, code: projectChanges.code || '' });
                      }
                    }}
                    onChange={(value) => {
                      setProjectChanges({ ...projectChanges, code: value });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey && e.target instanceof HTMLElement) {
                        e.target.blur();
                      }
                    }}
                    placeholder={`Set ${PROJECT_SHORTNAME.toLowerCase()}`}
                    value={projectChanges.code || ''}
                  />
                </div>
              )}
            </div>
          </div>
          <Thumbnail editable={editable} />
        </div>
        {(editable || description) && (
          <div className="flex flex-col gap-0.5">
            <div className="type-label">Description</div>
            <InputsTextAreaStyled
              data-cy="input-projectDescription"
              editable={editable}
              onChangeComplete={(description: string, descriptionStyled: string) => {
                if (
                  description !== project.description ||
                  descriptionStyled !== project.descriptionStyled
                ) {
                  sendAnalytics(
                    updateProjectDescriptionAnalytics(project.description, description)
                  );
                  updateProjectProps({
                    id: projectID,
                    description,
                    descriptionStyled,
                  });
                }
              }}
              placeholder="Describe this project..."
              textStyled={projectChanges.descriptionStyled || ''}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectDetails;
