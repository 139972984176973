import { FC, useMemo } from 'react';

import { ProjectCostBreakdownReport } from '../../generated/graphql';
import { formatCost } from '../../utilities/currency';
import { isNonNullable } from '../../utilities/types';
import ChartsPieGraph from '../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';

import ProjectCostBreakdownReportTable from './ProjectCostBreakdownReportTable';
import {
  breakdownSorting,
  projectBreakdownChartOrder,
  projectBreakdownCostColors,
  projectBreakdownTableOrder,
} from './utils';

type Props = {
  milestoneName: string;
  projectCostBreakdownReport: ProjectCostBreakdownReport;
};

const ProjectCostBreakdownReportChart: FC<Props> = ({
  milestoneName,
  projectCostBreakdownReport,
}) => {
  const { breakdown, total } = projectCostBreakdownReport;

  // sort and format the data for the chart
  // note: this table has a different sort order from the table
  const data = useMemo(() => {
    const sortedBreakdown = [...breakdown].sort(breakdownSorting(projectBreakdownChartOrder));
    return sortedBreakdown.map(({ type, percent }) => {
      return { name: type, share: percent };
    });
  }, [breakdown]);

  // sort the table data and remove zero values
  // note: this table has a different sort order from the chart
  const tableBreakdown = useMemo(() => {
    return [...breakdown]
      .filter((p) => p.cost.toString() !== '0')
      .sort(breakdownSorting(projectBreakdownTableOrder));
  }, [breakdown]);

  return (
    <div className="mt-10 flex flex-wrap justify-center gap-10">
      <ChartsPieGraph
        centerLabel={{ label: formatCost(total, { rounded: true }), sublabel: milestoneName }}
        chartSize={{
          diameter: 300,
          insideDiameter: 216,
        }}
        colors={data.map((b) => projectBreakdownCostColors.get(b.name)).filter(isNonNullable)}
        data={data}
        options={{ spaceBetweenWedges: breakdown.length > 1 }} // if there is only a single wedge then we don't need gaps
      />
      <ProjectCostBreakdownReportTable projectCostBreakdown={tableBreakdown} />
    </div>
  );
};

export default ProjectCostBreakdownReportChart;
