import * as React from 'react';

import { IconButton, TextField } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import Search from '@material-ui/icons/Search';

import { withStyles } from '../../../theme/komodo-mui-theme';

import SearchBarStyles from './SearchBarStyles';

type Props = {
  autoFocus?: boolean;
  classes: Classes<typeof SearchBarStyles>;
  onClear?: () => void;
  label?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onFocused?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>; // this prop can't be named onFocus, otherwise it gets overritten by material-ui
  placeholder: string;
  searchTerm?: string;
};

const SearchBar: React.FC<Props> = ({
  classes,
  autoFocus,
  label,
  onBlur,
  onChange,
  onClear,
  onClick,
  onFocused,
  placeholder,
  searchTerm,
}) => (
  <span onBlur={onBlur} onFocus={onFocused}>
    {label && <p className={classes.label}>{label}</p>}
    <TextField
      className={classes.searchBox}
      // TODO CT-1036: make a SearchInput scales component
      data-cy="search-input"
      InputProps={{
        autoFocus,
        autoComplete: 'off',
        classes: {
          focused: classes.focused,
          root: classes.root,
        },
        disableUnderline: true,
        startAdornment: <Search className={classes.searchIcon} />,
        endAdornment:
          searchTerm && searchTerm.length > 0 ? (
            <IconButton classes={{ root: classes.iconButton }} disableRipple onClick={onClear}>
              <Clear data-cy="button-clear" />
            </IconButton>
          ) : null,
      }}
      onChange={onChange}
      onClick={onClick}
      placeholder={placeholder}
      value={searchTerm || ''}
    />
  </span>
);

export default withStyles(SearchBarStyles)(SearchBar);
