/* eslint-disable */
import { RUNNING, TARGET } from '../../../constants';

type InputProps = {
  compress: boolean;
  isWideMedia: boolean;
  status: string;
};

type OutputProps = {
  isSigned: boolean;
  isWide: boolean;
};

const getRangeStyle: (input: InputProps) => OutputProps = ({ compress, status, isWideMedia }) => {
  const isSigned = status !== RUNNING && status !== TARGET;
  const isWide = isWideMedia || !compress || !isSigned;
  return { isSigned, isWide };
};

export default getRangeStyle;
