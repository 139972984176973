import { createRef, useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { TimelineEvent } from '../../../analytics/analyticsEventProperties';

import {
  Timeline,
  TimelineData,
  TimelineExpandedMap,
  TimelineItemData,
  TimelineSettings,
} from './timeline/timeline.types';
import { ITEMS_HEIGHT } from './timeline/utils';
import { getDueDateHeight, initDueDateTimeline } from './TimelineChartUtils';

type TimelineChartProps = {
  activities?: TimelineActivity[];
  data: TimelineData[];
  items?: TimelineItemData[];
  endDate?: string;
  expandedMap: TimelineExpandedMap;
  isPrint?: boolean;
  height?: number;
  maxItemsBar: number;
  onAnalytics?: (event: TimelineEvent) => void;
  onExpand: (id: UUID) => void;
  onZoom?: (domain: string[]) => void;
  settings: TimelineSettings;
  startDate?: string;
  today?: string;
  width?: number;
  withoutDueDateCount?: number;
  zoomIn?: TimelineData;
  zoomLineCompressed?: boolean;
};

const TimelineDueDateChart = (props: TimelineChartProps) => {
  const {
    activities = [],
    data: dataOuter = [],
    height,
    items = [],
    endDate,
    expandedMap,
    isPrint,
    maxItemsBar,
    onAnalytics = () => {},
    onExpand: onExpandOuter,
    onZoom: onZoomOuter = () => {},
    settings,
    startDate,
    today,
    width,
    withoutDueDateCount = 0,
    zoomIn,
    zoomLineCompressed = false,
  } = props;
  const timelineRef = createRef<HTMLDivElement>();

  const [data, setData] = useState<TimelineData[]>(dataOuter);

  const [timelineChart, setTimelineChart] = useState<Timeline<TimelineData>>();

  useDeepCompareEffect(() => {
    setData(dataOuter);
  }, [dataOuter]);

  const onExpand = ({ id }: TimelineData) => onExpandOuter(id);

  const onZoom = (domain: Date[]) => {
    const newMin = domain[0].toISOString();
    const newMax = domain[1].toISOString();
    onZoomOuter([newMin, newMax]);
  };
  useEffect(() => {
    if (!timelineRef || !timelineRef.current) return;
    if (!width) return;

    const chartProps = {
      width,
      withoutDueDateCount,
      height: getDueDateHeight(height ?? ITEMS_HEIGHT),
      data,
      items,
      today,
      expandedMap,
      zoomLineCompressed,
      onExpand,
      onZoom,
      onAnalytics,
      isPrint,
      settings,
      range: [props.startDate ?? '0', props.endDate ?? '0'],
      maxItemsBar,
    };
    setTimelineChart(initDueDateTimeline(timelineRef.current, chartProps, activities));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [timelineRef?.current, width, data, startDate, endDate, maxItemsBar]);

  useEffect(() => {
    if (zoomIn) timelineChart?.onZoomIn(zoomIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [zoomIn]);

  // useEffectOnce(() => () => unload());

  if (startDate === '0' || endDate === '0') return null;

  return (
    <div className="relative block w-full">
      <div ref={timelineRef} className="overflow-hidden" id="timeline-chart" />
    </div>
  );
};

export default TimelineDueDateChart;
