import { Typography } from '@material-ui/core';

import { ITEM_WITH_OPTIONS } from '../../constants';
import { getProjectIdFromUrl } from '../../utilities/url';
import { FilterManager } from '../FilterPanel/filterUtils';
import useMemoWrapper from '../useMemoWrapper';

import { ItemsListSettingsManager } from './ItemsListSettingsUtils';
import { VisibleItemInformation, getSummaryText, getSummaryTooltipText } from './ItemsListUtils';
import { useItemsListTotalCostQuery } from './useItemsListTotalCostQuery';

type Props = {
  shouldDisplayCosts: boolean;
  filterManager: FilterManager;
  itemsLikeIDsSelectedCount: number;
  loading: boolean;
  settingsManager: ItemsListSettingsManager;
  visibleItemsInfo: VisibleItemInformation;
};

export default function ItemsListSummary({
  shouldDisplayCosts,
  filterManager,
  itemsLikeIDsSelectedCount,
  loading: loadingOuter,
  settingsManager,
  visibleItemsInfo,
}: Props) {
  const projectID = getProjectIdFromUrl();
  const { settings } = settingsManager;
  const { show } = settings;
  const displayItemsAndOptions = show === ITEM_WITH_OPTIONS;

  // Items Total Cost
  const { filterQueryInput: viewFilter } = filterManager;
  const costItemIDs = visibleItemsInfo.costItemIDs;
  const { data, loading: costLoading } = useItemsListTotalCostQuery(
    costItemIDs,
    projectID,
    viewFilter,
    settings,
    loadingOuter
  );
  const itemsCost = data?.itemsListTotalCost?.cost;
  const loading = loadingOuter || costLoading;

  // eslint-disable-next-line no-param-reassign
  visibleItemsInfo.visibleCost = itemsCost ?? visibleItemsInfo.visibleCost;

  let summary = useMemoWrapper(
    getSummaryText,
    displayItemsAndOptions,
    shouldDisplayCosts,
    itemsLikeIDsSelectedCount,
    visibleItemsInfo,
    loading
  );
  let tooltipText = useMemoWrapper(
    getSummaryTooltipText,
    displayItemsAndOptions,
    itemsLikeIDsSelectedCount,
    visibleItemsInfo,
    loading
  );

  summary = loading ? 'Loading...' : summary;
  tooltipText = loading ? 'Loading...' : tooltipText;

  if (loading) return <Typography className="pl-3">Loading...</Typography>;

  return (
    <Typography className="pl-3" data-cy="text-headerCosts" title={tooltipText}>
      {summary}
    </Typography>
  );
}
