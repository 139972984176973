import { FC, ReactNode, useMemo, useState } from 'react';

import { FormControl, Input, ListSubheader, MenuItem, Select, Typography } from '@material-ui/core';
import { MenuProps } from '@material-ui/core/Menu';

import { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import SearchBar from '../../Search/SearchBar/SearchBar';
import DropDownArrow from '../DropDownArrow/DropDownArrow';

import styles, { menuPropsDefault } from './JoinSelectStyles';

export type SelectEntry = {
  disabled?: boolean;
  id: UUID;
  leftIcon?: ReactNode;
  name: string;
  rightIcon?: ReactNode;
  rightText?: string;
  selected?: boolean; // only matters to JoinMultiSelect
  subName?: string;
  title?: string;
  type?: string;
};

export const getEntry = (
  id: string,
  name: string,
  disabled?: boolean,
  leftIcon?: ReactNode,
  title?: string,
  subName?: string,
  selected?: boolean,
  type?: string,
  rightText?: string,
  rightIcon?: ReactNode
): SelectEntry => {
  return {
    disabled,
    id,
    leftIcon,
    name,
    rightIcon,
    rightText,
    selected,
    subName,
    title,
    type,
  };
};

export const getEntryObj = ({
  disabled,
  id,
  leftIcon,
  name,
  rightIcon,
  rightText,
  selected,
  subName,
  title,
  type,
}: SelectEntry): SelectEntry => {
  return {
    disabled,
    id,
    leftIcon,
    name,
    rightIcon,
    rightText,
    selected,
    subName,
    title,
    type,
  };
};

export type JoinSelectProps = {
  classNameRightText?: string;
  classNameSelect?: string;
  classNameSelectText?: string;
  classes: Classes<typeof styles>;
  cySelect?: string;
  disabled?: boolean;
  entries: SelectEntry[];
  entryToolTip?: (entry: SelectEntry) => ReactNode;
  groupingKey?: 'type';
  hidePrint?: boolean;
  isCompact?: boolean;
  menuProps?: Partial<MenuProps>;
  nameFunc?: (name: string, i: number) => ReactNode;
  noFormControl?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onChange: (value: any) => void;
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  search?: boolean;
  searchPlaceholder?: string;
  showRightIcon?: boolean;
  style?: React.CSSProperties;
  value?: string;
  valuePlaceholder?: ReactNode;
};

// Link to stories:
// http://localhost:9009/?path=/story/components-select-joinselect--item-status
const JoinSelect: FC<JoinSelectProps> = ({
  classNameRightText,
  classNameSelect,
  classNameSelectText,
  classes,
  cySelect,
  disabled = false,
  entries: rawEntries = [],
  entryToolTip,
  groupingKey,
  hidePrint = true,
  isCompact = true,
  menuProps,
  nameFunc,
  noFormControl = false,
  onChange,
  onClose,
  onOpen,
  open: icOpen = false,
  search = false,
  searchPlaceholder = '',
  showRightIcon,
  style,
  value,
  valuePlaceholder = '',
}) => {
  const rootItemPaddingStyle = disabled
    ? `${isCompact ? classes.disabledItemCompact : classes.disabledItem}`
    : `${classes.rootItem} ${isCompact ? classes.rootItemCompact : ''}`;
  const selectedStyle = classes.selected;

  const [open, setOpen] = useState(icOpen);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [tooltipID, setTooltipID] = useState<string | null>(null);
  let lastGroup: string | number | undefined;

  const handleOpen = () => {
    setOpen(true);
    onOpen?.();
  };

  const entries = useMemo(() => {
    if (!searchTerm) return rawEntries;
    return rawEntries.filter((e) => e.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [searchTerm, rawEntries]);

  const renderVal = (
    entry: SelectEntry,
    index: number,
    isSelect?: boolean,
    valClass?: string,
    selectClass?: string
  ) => {
    const {
      disabled: entryDisabled,
      id,
      name,
      rightText,
      rightIcon,
      leftIcon,
      title: entryTitle,
      subName,
    } = entry;
    const item = (
      <div className={classes.row}>
        {leftIcon && leftIcon}
        <div className={classes.fullWidth}>
          <div className={classes.horizontal}>
            <Typography
              className={`${classes.name} ${leftIcon ? classes.marginLeft : ''} ${valClass}`}
              title={entryTitle}
            >
              {nameFunc ? nameFunc(name, index) : name}
            </Typography>
            {rightText && (
              <Typography className={`${classes.name} ${valClass} ${classNameRightText}`}>
                {rightText}
              </Typography>
            )}
            {(!isSelect || showRightIcon) && rightIcon}
          </div>
          {subName && (
            <Typography
              className={`${classes.name} ${classes.subName} ${leftIcon ? classes.marginLeft : ''}`}
              data-cy={`subtext-${subName}`}
            >
              {subName}
            </Typography>
          )}
        </div>
      </div>
    );
    const wrapSelection = (item: ReactNode) => {
      if (isSelect) {
        return (
          <div className={`${rootItemPaddingStyle} ${selectClass} ${classes.row}`}>{item}</div>
        );
      }
      return (
        <MenuItem
          key={id}
          classes={{
            root: `${rootItemPaddingStyle} ${selectClass}`,
            selected: selectedStyle,
          }}
          data-cy={isSelect ? undefined : `select-${name}`}
          disabled={entryDisabled}
          disableRipple
          value={id}
        >
          {item}
        </MenuItem>
      );
    };
    const title = entryToolTip?.(entry);
    if (title)
      return wrapSelection(
        <NormalTooltip
          onClose={() => setTooltipID(null)}
          onOpen={() => {
            setTooltipID(id);
          }}
          open={
            ((open && !isSelect) || (!open && isSelect)) && tooltipID !== null && tooltipID === id
          }
          placement="right-start"
          title={title}
        >
          {item}
        </NormalTooltip>
      );
    return wrapSelection(item);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const renderSelectedValue = (value: any) => {
    return renderVal(
      {
        ...(entries.find(({ id }) => id === value) || ({ name: valuePlaceholder } as SelectEntry)),
        subName: undefined,
      },
      0,
      true,
      !value ? classes.defaultText : classNameSelectText,
      classes.selectValue
    );
  };

  const select = (
    <Select
      aria-disabled={disabled} // needed for integration tests.  Disable doesn't appear to be discoverable
      classes={{
        icon: !disabled ? classes.hideBorder : classes.hide,
        root: disabled ? undefined : classes.bgnWhite,
        select: !disabled ? undefined : classes.select,
        selectMenu: classes.selectMenu,
      }}
      className={classNameSelect}
      data-cy={cySelect}
      disabled={disabled}
      disableUnderline
      displayEmpty
      IconComponent={() => (!disabled ? <DropDownArrow onClick={handleOpen} /> : null)}
      input={<Input className={`${disabled ? '' : classes.bgnWhite}`} />}
      MenuProps={{
        ...menuPropsDefault,
        ...menuProps,
        PopoverClasses: {
          paper: isCompact ? classes.locationCompact : classes.location,
          ...menuProps?.PopoverClasses,
        },
        MenuListProps: {
          ...menuProps?.MenuListProps,
        },
      }}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      onClose={() => {
        if (isSearchOpen) {
          return;
        }
        setOpen(false);
        onClose?.();
      }}
      onOpen={handleOpen}
      open={open}
      renderValue={renderSelectedValue}
      style={style}
      value={value}
    >
      {search && (
        <div className={classes.search}>
          <SearchBar
            onBlur={() => setIsSearchOpen(false)}
            onChange={(evt) => {
              const term = evt.target.value;
              setSearchTerm(term);
            }}
            onClear={() => {
              setSearchTerm('');
              setIsSearchOpen(false);
            }}
            onFocused={() => {
              setIsSearchOpen(true);
            }}
            placeholder={searchPlaceholder}
            searchTerm={searchTerm}
          />
        </div>
      )}
      {entries.map((entry, index) => {
        const currentGroup = groupingKey && entry[groupingKey];
        const isNewGroup = currentGroup !== lastGroup;
        if (isNewGroup) lastGroup = currentGroup;
        return [
          isNewGroup && currentGroup !== '' && (
            <MenuItem
              key={lastGroup}
              className={classes.subheader}
              disabled
              disableRipple
              value={lastGroup}
            >
              <ListSubheader className={classes.listSubheader}>
                <Typography className={classes.listSubheaderText}>{lastGroup}</Typography>
              </ListSubheader>
            </MenuItem>
          ),
          renderVal(entry, index),
        ];
      })}
    </Select>
  );

  const selectComponent = select;
  return noFormControl ? (
    selectComponent
  ) : (
    <FormControl className={hidePrint ? classes.hidePrint : ''} fullWidth>
      {selectComponent}
    </FormControl>
  );
};

/** @deprecated in favor of design system component, please use scales/Select */
export default withStyles(styles)(JoinSelect);
