import { FC } from 'react';

import { MenuItem } from '@material-ui/core';

import { Checkbox } from '../../scales';

import { MarkupSelectors, toggleReferenceCheckbox } from './utils';

type SourceFilterMenuItemProps = {
  selectors: MarkupSelectors;
  setSelectors: (newSelectors: MarkupSelectors) => void;
  source: ItemLink;
  hasRemoveS2ItemMarkupFeature: boolean;
};

const SourceFilterMenuItem: FC<SourceFilterMenuItemProps> = ({
  selectors,
  setSelectors,
  source,
  hasRemoveS2ItemMarkupFeature,
}) => {
  const { id, name, number } = source;
  const { sources, sourceFilterIDsWithoutS2Reference } = selectors;
  const isSelected = sources.some((id) => id === source.id);
  const includeItemMarkups = !sourceFilterIDsWithoutS2Reference.includes(id);

  return (
    <>
      <MenuItem
        key={`${id}-${name}`}
        data-cy={`source-filter-menu-item-${name}`}
        id={id}
        onClick={() => {
          toggleReferenceCheckbox(isSelected, source.id, sources, (newSources: UUID[]) =>
            setSelectors({ ...selectors, sources: newSources })
          );
          // if the user is deselecting this source, then ensure that includeItemMarkups is also deselected
          if (isSelected && !includeItemMarkups) {
            const newOptions = [...sourceFilterIDsWithoutS2Reference];
            setSelectors({
              ...selectors,
              sourceFilterIDsWithoutS2Reference: newOptions.filter((o) => o !== id),
            });
          }
        }}
        title={name}
        value={name}
      >
        <Checkbox
          aria-label="Filter Source"
          isDisabled={false}
          isIndeterminate={!includeItemMarkups}
          isSelected={isSelected}
        />
        <div className="ml-2 w-full overflow-hidden overflow-ellipsis whitespace-nowrap type-body1">{`#${number}: ${name}`}</div>
      </MenuItem>

      {/* Submenu with checkbox to include / exclude item markups */}
      {hasRemoveS2ItemMarkupFeature && (
        <MenuItem
          key={`${id}-${name}-item-markups`}
          className="!pl-10"
          disabled={!isSelected}
          id={`${id}-item-markups`}
          onClick={() => {
            let newOptions = [...sourceFilterIDsWithoutS2Reference];
            if (includeItemMarkups) {
              // if the user wants to exlude item markups then we need to add the item id
              // to exclude that reference
              newOptions.push(id);
            } else {
              // if the user wants to include item markups then
              // remove the sourceID from the list of filters, because it's currently excluded
              newOptions = newOptions.filter((e) => e !== id);
            }
            setSelectors({ ...selectors, sourceFilterIDsWithoutS2Reference: newOptions });
          }}
        >
          <Checkbox
            aria-label="Include Item Markups"
            isDisabled={!isSelected}
            isSelected={includeItemMarkups && isSelected}
          />
          <div className="ml-2 type-body1">Include Item Markups</div>
        </MenuItem>
      )}
    </>
  );
};

export default SourceFilterMenuItem;
