import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const styles = (theme: KomodoTheme) =>
  createStyles({
    body: {
      fontSize: '0.8rem',
      fontWeight: 400,
    },
    buttonLarge: {
      background: theme.palette.joinPrimary,
      borderRadius: 35,
      color: 'white',
      fontSize: 17,
      height: 70,
      padding: '12px 42px',
      margin: '24px 24px 24px 0px',
      textTransform: 'none',
      '&:hover': {
        background: theme.palette.backgroundGrey,
      },
    },
    icon_info: {
      color: theme.palette.disabledGrey,
      fontSize: 16,
      lineHeight: 0,
    },
    paper: {
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      marginTop: 7,
      maxHeight: 'calc(100vh - 100px)',
      minHeight: 100,
      padding: 12,
      overflowY: 'auto',
      width: 360,
    },
    popper: {
      boxSizing: 'border-box',
      zIndex: 1,
      '& *': {
        boxSizing: 'border-box',
      },
    },
    templateLabel: {
      display: 'flex',
      gap: '5px',
    },
  });
