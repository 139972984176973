type PartialGroupByOption = { id?: string; name?: string; variant?: string; levelNames?: string[] };

const matchCategorizationLevel = (groupByName: string, groupByOptions: PartialGroupByOption[]) => {
  let categorization;
  let level;
  groupByOptions.forEach((option) => {
    if (option.levelNames) {
      const lod = option.levelNames.findIndex((levelName) => levelName === groupByName);
      if (lod > -1) {
        categorization = option;
        level = lod + 1;
      }
    }
  });
  return { categorization, level };
};

export const returnGroupByOptions = (
  groupBys: string[],
  groupByOptions: PartialGroupByOption[]
) => {
  return groupBys.flatMap((groupByName) => {
    // Bucket, Status, Creator, Assignee, Custom Categorizations
    // Match based on name.
    const matchedOption = groupByOptions.find((o) => o.name === groupByName);
    if (matchedOption) {
      return {
        id: matchedOption.id,
        name:
          matchedOption.levelNames && matchedOption.levelNames.length > 0
            ? matchedOption.levelNames[0]
            : matchedOption.name ?? '',
        variant: matchedOption?.variant ?? '',
        level: 1,
        categorization: matchedOption.id ? matchedOption : undefined,
      };
    }
    // Default Categorizations
    const { categorization, level } = matchCategorizationLevel(groupByName, groupByOptions);
    if (categorization) {
      const { id, name } = categorization;
      return {
        id,
        name: groupByName,
        level,
        categorization: {
          id,
          name,
        },
      };
    }
    return [];
  });
};
