import { useState } from 'react';
import { Link } from 'react-router-dom';

import { MoreVert } from '@material-ui/icons';

import { SortDirection } from '../../../generated/graphql';
import DistributionHistoryTable from '../../dragon-scales/ShareDialog/DistributionHistoryTable';
import {
  Button,
  Dialog,
  DialogContent,
  SortBy,
  SortManager,
  TableComponents,
  TableHeader,
} from '../../scales';
import { useReportDistributionHistory } from '../ReportHooks';

import {
  ActionMenuCell,
  ReportLinksCell,
  SentByCell,
  SentToCell,
} from './DistributionHistoryCells';
import {
  DistributionDialogsState,
  DistributionHistoryEvent,
  transformDistributionHistoryData,
} from './DistributionUtils';

const tableHeader: TableHeader[] = [
  { copy: 'Date', key: 'date', headerSortKey: 'date' },
  { copy: 'Report', key: 'Report' },
  { copy: 'Sent By', key: 'Sent By' },
  { copy: 'Report Link', key: 'Report Link' },
  { copy: 'Sent To', key: 'Sent To' },
  { copy: '', key: 'Actions' },
];

type Props = {
  isOpen: boolean;
  projectID: UUID;
  activeCollaborators: Collaborator[];
  onClose: () => void;
  setDistributionDialogsState: (info: DistributionDialogsState) => void;
};

export default function DistributionHistoryDialog(props: Props) {
  const [entries, setEntries] = useState<DistributionHistoryEvent[]>();
  const [sortState, setSortState] = useState<SortBy>({
    sortDirection: SortDirection.SORT_DESCENDING,
    sortKey: 'date',
  });

  useReportDistributionHistory(props.projectID, {
    onCompleted: (data) => {
      const entries = transformDistributionHistoryData(
        data?.reportDistributionHistory,
        props.activeCollaborators
      );
      setEntries(entries);
    },
  });

  const sortManager: SortManager = {
    sortState,
    setSort: (state: SortBy) => {
      setSortState(state);
      setEntries([...(entries ?? [])].reverse());
    },
  };

  if (!entries) {
    return null;
  }

  return (
    <Dialog
      aria-describedby="dialog for viewing distribution history"
      aria-labelledby="distribution-history-dialog"
      footerRight={<Button label="Close" onClick={props.onClose} type="primary" />}
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="lg"
      title="History of Report Distributions"
    >
      <DialogContent>
        <DistributionHistoryTable
          entries={entries.map((entry) => {
            return [
              {
                component: (
                  <TableComponents.TextCell
                    compact
                    text={new Date(entry.sentAt).toLocaleString()}
                  />
                ),
              },
              { component: <TableComponents.TextCell compact text={entry.reportName} /> },
              {
                component: (
                  <SentByCell
                    isDeprecated={entry.isDeprecated}
                    isScheduled={entry.isScheduled}
                    sentBy={entry.sentBy}
                  />
                ),
              },
              {
                component: <ReportLinksCell reports={entry.reports} />,
              },
              { component: <SentToCell reports={entry.reports} /> },
              {
                component: entry.isEditable ? (
                  <ActionMenuCell
                    openEditDistributionDialog={() => {
                      props.setDistributionDialogsState({
                        distributionDialogDataIsOpen: true,
                        distributionHistoryDialogIsOpen: false,
                        isEditingDistribution: true,
                        reportID: entry.reportID,
                        reportName: entry.reportName,
                      });
                    }}
                  />
                ) : null,
              },
            ];
          })}
          headerContent={tableHeader}
          sortManager={sortManager}
        />
        {!entries.length && (
          <div className="mt-6 flex h-96 w-full justify-center  bg-background-1">
            <div className="my-auto">
              <div>No Distributions sent yet! To get started,</div>
              <ul className="my-2 ml-6 list-disc">
                <li>Create a Variance, Items List, or Milestone Summary Report</li>
                <li>Save and Publish the Report</li>
                <li>
                  Select the Report&#39;s <MoreVert style={{ width: 20, height: 20 }} /> icon to
                  Send or Schedule a Report Distribution
                </li>
              </ul>
              <Link
                className="type-link"
                rel="noreferrer"
                target="_blank"
                to="https://success.join.build/en/knowledge/scheduling-a-report-distribution"
              >
                Learn More about Report Distribution
              </Link>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
