import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import 'react-vis/dist/style.css';
import {
  reportChart,
  setItemChartViewBy,
  setItemsListNewItemDialogOpen,
} from '../../../../analytics/analyticsEventProperties';
import { newItemDialogOpenVar } from '../../../../api/apollo/reactiveVars';
import { GROUPBY, OPTION, SORT_STATUS, STATUS } from '../../../../constants';
import { useMilestoneCostReportsQueryGrouped } from '../../../../hooks/useMilestoneCostReportsQueryGrouped';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../../routes/paths';
import { makeGroupByWithCategory } from '../../../../utilities/categorization';
import { getCategoriztionMap } from '../../../../utilities/dashboard';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { capitalizeString, removeYear } from '../../../../utilities/string';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import ChartsItems from '../../../Charts/ChartsItems/ChartsItems';
import { ChartItem, isZeroCost } from '../../../Charts/ChartsItems/ChartsItemsUtils';
import { allDataFilteredMessage } from '../../../Charts/ChartsUtils';
import { FilterManager } from '../../../FilterPanel/filterUtils';
import ItemsListItemData from '../../../Items/ItemsListItem/ItemsListItemData';
import {
  getItemHasAcceptedIncorporatedOption,
  getStatusIsAcceptedIncorporated,
} from '../../../Items/ItemsUtils';
import { getItemListLink, useItemsList } from '../../../ItemsList/ItemsListUtils';
import CTALink from '../../../shared-widgets/CTALink';
import useMemoWrapper from '../../../useMemoWrapper';
import DashboardAnchor from '../../DashboardAnchor';
import DashboardFilterDescription from '../../DashboardFilterDescription/DashboardFilterDescription';
import { DashboardGroupBySelect } from '../../DashboardGroupBySelect';
import DashboardChartPlaceholder from '../DashboardChartsPlaceholder';

import styles from './DashboardChartsItemsStyles';

type DashboardChartsItemsProps = {
  activeMilestoneId: UUID;
  categorizations: Categorization[];
  classes: Classes<typeof styles>;
  clearFilters?: () => void;
  filterManager?: FilterManager | null;
  isFiltered?: boolean;
  isPrint?: boolean;
  setIsLoading?: (loading: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setSetting?: (param: any, value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any;
  viewFilter: ViewFilterInput;
};
const DashboardChartsItems: FC<DashboardChartsItemsProps> = ({
  activeMilestoneId,
  categorizations,
  classes,
  clearFilters,
  filterManager,
  isFiltered = false,
  isPrint = false,
  setIsLoading = () => {},
  setSetting = () => {},
  settings,
  viewFilter,
}) => {
  const navigate = useNavigate();
  const sendAnalytics = useSendAnalytics();
  const projectId = getProjectIdFromUrl();
  const {
    itemsList,
    loading: itemsLoading,
    itemsListMap,
  } = useItemsList({ ...filterManager, filterQueryInput: viewFilter }, settings, true, true);

  const reportDashboardAnalytics = (chart: string, trigger: string) =>
    sendAnalytics(reportChart(chart, trigger));

  const { itemsGroupBy } = settings;
  const setViewByAll = (newValue: string) => {
    setSetting('itemsGroupBy', newValue);
    sendAnalytics(setItemChartViewBy(newValue));
  };

  const categorizationMap = useMemoWrapper(getCategoriztionMap, categorizations);
  const itemsCategorization = categorizationMap[itemsGroupBy];
  const itemsCategorizationName = (itemsCategorization && itemsCategorization.name) || '';

  const viewByForQuery = itemsGroupBy === SORT_STATUS ? null : itemsCategorization;
  const { data: costReportsData, loading: costReportsLoading } =
    useMilestoneCostReportsQueryGrouped({
      groupByCategorization: viewByForQuery,
      milestoneID: activeMilestoneId,
      projectID: projectId,
      shouldSkip: !viewByForQuery,
      viewFilter,
    });
  const costReport =
    costReportsData?.milestoneCostReports && costReportsData.milestoneCostReports[0];
  const categorizationNameMap: { [key: string]: string } = {};
  categorizations.forEach((categorization) => {
    if (categorization && categorization.id && categorization.name)
      categorizationNameMap[categorization.id] = categorization.name;
  });

  const items = useMemo(() => itemsList.filter((item) => item.itemType !== OPTION), [itemsList]);

  const allDataFiltered = useMemo(
    () => isFiltered && items.every((item: ItemsListItem) => isZeroCost(item)),
    [isFiltered, items]
  );
  const hooksLoading = itemsLoading || costReportsLoading;

  useEffect(() => {
    setIsLoading(hooksLoading);
  }, [hooksLoading, setIsLoading]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const [value, setValue] = useState<any>(null);

  const onMouseOver = (newValue: ChartItem) => {
    reportDashboardAnalytics(`Item in ${itemsGroupBy}`, 'hover');
    setValue(newValue);
  };

  const onMouseLeave = () => {
    setValue(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const onClick = (item: any) => {
    if (item && !isPrint) {
      reportDashboardAnalytics(`Item in ${itemsGroupBy}`, 'click');
      navigate(generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, { projectId, itemId: item.id }));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const onGroupClick = (groupValue: any) => {
    if (groupValue) {
      const val = makeGroupByWithCategory(groupValue);
      const itemsListLink = getItemListLink(projectId, { [GROUPBY]: [val] });
      navigate(itemsListLink);
    }
  };

  const onSpecificStatus = (statusValue: string) => {
    const itemsListLink = getItemListLink(projectId, {
      [GROUPBY]: ['Status'],
      [STATUS]: [statusValue],
    });

    if (statusValue && !isPrint) {
      navigate(itemsListLink);
    }
  };

  const itemHasAcceptedOption = getItemHasAcceptedIncorporatedOption(value) && value.status;
  const rejectedOptionsNote =
    itemHasAcceptedOption && !getStatusIsAcceptedIncorporated(value.status) ? (
      <Typography className={classes.rejectedOptionsNote} variant="caption">
        Note: when you accept an option for an item, the other options will automatically display in
        the rejected column
      </Typography>
    ) : (
      <></>
    );

  const hasItems =
    items && items.length > 0 && items.some((item: ItemsListItem) => !isZeroCost(item));

  const placeholdder = (
    <DashboardChartPlaceholder
      clearFilters={clearFilters}
      emptyMessage={allDataFilteredMessage}
      loading={hooksLoading}
    />
  );

  const activeMilestoneChart = (
    <>
      {!hasItems && !isPrint && (
        <CTALink
          linkText="Add Items to the milestone to view item graph."
          onClick={() => {
            newItemDialogOpenVar(true);
            sendAnalytics(setItemsListNewItemDialogOpen(true));
            navigate(generateSharedPath(RouteKeys.PROJECT_ITEMS, { projectId }));
          }}
        />
      )}
      <>
        <ChartsItems
          costReport={costReport}
          isPrint={isPrint}
          itemMap={itemsListMap}
          onClick={onClick}
          onFocus={onMouseOver}
          onGroupClick={onGroupClick}
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
          onSpecificStatus={onSpecificStatus}
          rawItems={items}
          value={value}
          viewBy={itemsCategorization ? itemsCategorization.name : SORT_STATUS}
        />
        {!isPrint && (
          <div className={classes.items}>
            {value && (
              <>
                <div>
                  <ItemsListItemData
                    key={value.id}
                    disabled={false}
                    filteredMilestoneID={activeMilestoneId}
                    item={value}
                    link=""
                    sharedUsers={[]}
                    showCategories={itemsGroupBy === SORT_STATUS}
                    showCheckbox={false}
                    showPartialCost={false}
                    showSimpleSchedule
                  />
                </div>
                {rejectedOptionsNote}
              </>
            )}
          </div>
        )}
      </>
    </>
  );

  const showPlaceholder = hooksLoading || allDataFiltered;

  return (
    <div
      className={`${classes.root} ${isPrint ? classes.printArea : classes.itemArea}`}
      data-cy="dashboard-ItemsChart"
    >
      <div className={classes.header}>
        <div className={classes.description}>
          {!isPrint && <DashboardAnchor anchor="Items" />}
          {isPrint && <div className={classes.printChartTitle}>Items</div>}
          {isPrint && (
            <div className={classes.printChartSetting}>{`Grouped by ${
              itemsGroupBy === SORT_STATUS
                ? capitalizeString(STATUS)
                : removeYear(itemsCategorizationName)
            }`}</div>
          )}
          {filterManager && (
            <DashboardFilterDescription
              categorizations={categorizations}
              filterManager={filterManager}
              isFiltered={isFiltered}
            />
          )}
        </div>
        {!isPrint && (
          <div className={classes.topBar}>
            <DashboardGroupBySelect
              categorizations={categorizations}
              includeStatus
              onChange={setViewByAll}
              viewBy={itemsGroupBy}
            />
          </div>
        )}
      </div>
      {showPlaceholder && placeholdder}
      {!showPlaceholder && activeMilestoneChart}
    </div>
  );
};

export default withStyles(styles)(DashboardChartsItems);
