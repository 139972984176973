import { ReactNode } from 'react';

import { Button } from '../../scales';

type Props = {
  actions?: ReactNode;
  children: ReactNode;
  disableBack?: boolean;
  disableNext?: boolean;
  navBackText?: string;
  navNextText?: string;
  onNext?: (() => void) | undefined;
  onBack?: (() => void) | undefined;
  title: string;
};

export default function OnboardingContentBox({
  actions = undefined,
  children,
  disableBack = false,
  disableNext = false,
  navBackText = 'Back',
  navNextText = 'Continue',
  onBack = undefined,
  onNext = undefined,
  title,
}: Props) {
  return (
    <div className="border bg-background-primary">
      <div className="flex h-full min-h-[360px] flex-col gap-4 p-6">
        <div className="type-heading2">{title}</div>
        {children}
        {actions || (
          <div className="mt-auto flex items-center justify-end gap-2">
            {onBack && (
              <Button
                data-cy="onboarding-content-box--back-button"
                disabled={disableBack}
                label={navBackText}
                onClick={onBack}
                type="primary"
              />
            )}
            {onNext && (
              <Button
                data-cy="onboarding-content-box--next-button"
                disabled={disableNext}
                label={navNextText}
                onClick={onNext}
                type="primary"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
