import { FieldFunctionOptions, FieldPolicy, Reference } from '@apollo/client/cache';

import { LIMIT, NULL_ID } from '../../constants';
import { insert } from '../../utilities/utilities';

import { paginationHasMoreVar } from './reactiveVars';

type KeyArgs = FieldPolicy['keyArgs'];

function getDefaultKeyArgs(keyArgs: KeyArgs): KeyArgs {
  // We shouldn't allow different queries with different `limit` values to be
  // cached to the same location. If we do, it's confusing because a query with
  // limit of 5 can get back a cached list of 25, or vice-versa.

  if (!keyArgs) {
    return ['pagination', ['limit']];
  }

  if (Array.isArray(keyArgs)) {
    return [...keyArgs, 'pagination', ['limit']];
  }

  return keyArgs;
}

// These implementations are based off of cribbed from Apollo's reference version.
// https://github.com/apollographql/apollo-client/blob/a0ef4138478fb556b5f5f65c5ad7a1f8ac0274b6/src/utilities/policies/pagination.ts#L28-L55

/**
 * A tweaked version of Apollo's `offsetLimitPagination` helper that conforms
 * to how we use `pagination` input structs.
 *
 * This version is useful if your endpoint returns an array at the top level. Use
 * `offsetLimitObjectPagination` if your endpoint returns an object containing a `data` array.
 *
 * @param keyArgs
 * New query values for these field names will cache a separate list of results. The identity
 * check made by Apollo is a shallow JavaScript equality (==), so if you have arrays/objects
 * ensure they have a stable identity and are memoized as needed.
 */
export function offsetLimitArrayPagination<T = Reference>(
  keyArgs: KeyArgs = false
): FieldPolicy<T[]> {
  return {
    keyArgs: getDefaultKeyArgs(keyArgs),
    merge(existing = [], incoming, options) {
      return mergePaginationArrays(existing, incoming, options);
    },
  };
}
interface ObjectWithPaginatedArray<T> extends Object {
  data: T[];
}

/**
 * A tweaked version of Apollo's `offsetLimitPagination` helper that conforms
 * to how we use `pagination` input structs.
 *
 * This version is useful if your endpoint returns an object at the top level that contains
 * a `data` array. This shape is useful if you need to return other things as well, such as
 * aggregations or total data counts.
 *
 * Use `offsetLimitArrayPagination` if your endpoint returns an array.
 *
 * @param keyArgs new query values for these field names will cache a separate list of results
 *
 */
export function offsetLimitObjectPagination<T = Reference>(
  keyArgs: KeyArgs = false
): FieldPolicy<ObjectWithPaginatedArray<T>> {
  return {
    keyArgs: getDefaultKeyArgs(keyArgs),
    merge(existing = { data: [] }, incoming, options) {
      return {
        ...incoming,
        data: mergePaginationArrays(existing.data, incoming?.data, options),
      };
    },
  };
}

function mergePaginationArrays<T = Reference>(
  existing: Readonly<T[]> = [],
  incoming: Readonly<T[]>,
  { variables }: FieldFunctionOptions
): T[] {
  if (Array.isArray(incoming) === false) {
    return [...existing];
  }

  const pagination = parsePaginationVariables(variables?.pagination);
  if (!pagination) {
    return [...existing];
  }

  const { offset, offsetID, limit } = pagination;

  if (incoming.length < limit) paginationHasMoreVar(false);

  if (offset === 0 || offsetID === NULL_ID) {
    return [...incoming];
  }

  if (typeof offset === 'number') {
    return insert(existing, offset, incoming);
  }

  return insert(existing, existing.length - 1, incoming);
}

const parsePaginationVariables = (
  pagination?: Record<string, unknown>
): { offset: number | undefined; offsetID: UUID | undefined; limit: number } | undefined => {
  if (!pagination || typeof pagination !== 'object') {
    throw new Error('A paginated field must have a `pagination` object variable.');
  }

  let offset: number | undefined;
  let offsetID: UUID | undefined;
  let limit: number | undefined;

  if ('offset' in pagination && typeof pagination.offset === 'number') {
    offset = pagination.offset ?? 0;
  }

  if ('offsetID' in pagination && typeof pagination.offsetID === 'string') {
    offsetID = pagination.offsetID ?? NULL_ID;
  }

  if (offset === undefined && offsetID === undefined) {
    throw new Error(
      'A paginated field must have a `pagination.offset` number or `pagination.offsetID` id variable.'
    );
  }

  if ('limit' in pagination && typeof pagination.limit === 'number') {
    limit = pagination.limit ?? LIMIT;
  }

  if (limit === undefined) {
    throw new Error('A paginated field must have a `pagination.limit` number variable.');
  }

  return { offset, offsetID, limit };
};
