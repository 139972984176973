import { ReactNode, useEffect, useRef } from 'react';
import { Key, useComboBox } from 'react-aria';
import { useComboBoxState } from 'react-stately';

import { TextInput } from '../../scales';

import ComboBoxList from './ComboBoxList';
import { ComboBoxPopover } from './ComboBoxPopover';

export type Props = {
  children: JSX.Element[];
  'data-cy'?: string;
  defaultValue?: string;
  disabled?: boolean;
  endAdornment?: ReactNode;
  errorMessage?: string;
  label?: string;
  onBlur?: () => void;
  onClear?: () => void;
  onTextInputChange?: (value: string) => void;
  onSelectionChange?: (key: string) => void;
  placeholder?: string;
  startAdornment?: ReactNode;
  value: string;
};

export default function InputAutocomplete(props: Props) {
  const onSelectionChange = (key: Key) => props.onSelectionChange?.(key.toString());
  const comboProps = { ...props, onSelectionChange };
  const state = useComboBoxState(comboProps);

  // Setup refs and get props for child elements.
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const listBoxRef = useRef(null);
  const popoverRef = useRef(null);

  const { inputProps, listBoxProps } = useComboBox(
    {
      ...comboProps,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
    },
    state
  );

  useEffect(() => {
    if (props.children.length) state.setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.children.length]);

  return (
    <div ref={containerRef} className="relative flex w-full flex-col gap-0.5">
      <TextInput
        {...inputProps}
        ref={inputRef}
        aria-label="location"
        data-cy={props['data-cy']}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        endAdornment={props.endAdornment}
        errorMessage={props.errorMessage}
        label={props.label}
        onBlur={() => {
          state.setOpen(false);
          props.onBlur?.();
        }}
        onChange={(value: string) => {
          props.onTextInputChange?.(value);
          state.setOpen(true);
        }}
        onClear={props.onClear}
        onFocus={() => state.setOpen(true)}
        placeholder={props.placeholder}
        startAdornment={props.startAdornment}
        value={props.value}
      />
      {state.isOpen && !!props.children.length && (
        <ComboBoxPopover
          // Place below the input field
          placement="bottom"
          popoverRef={popoverRef}
          state={state}
          triggerRef={containerRef}
        >
          <ComboBoxList
            // AriaListBoxOptions
            {...listBoxProps}
            listBoxRef={listBoxRef}
            state={state}
          />
        </ComboBoxPopover>
      )}
    </div>
  );
}
