import { ReactNode } from 'react';

export default function ChartSection({
  children,
  id,
  title,
}: {
  children: ReactNode;
  id: string;
  title: string;
}) {
  return (
    <div className="mb-5 mt-5" id={id}>
      <div className="ml-2 type-heading2">{title}</div>
      {children}
    </div>
  );
}
