import { createRef, useEffect, useLayoutEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import {
  insightsMaxItemsBar,
  insightsTimeDomain,
  projectsSummaryVar,
} from '../../../api/apollo/reactiveVars';
import { TimelineGroups } from '../../../api/gqlEnums';
import { TimelineActivityType } from '../../../api/gqlEnumsBe';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { getTodayAtUtcNoon } from '../../../utilities/dates';
import InsightsBaseLine from '../../dragon-scales/TimelineCharts/InsightsCost/InsightsBaseLine';
import { milestones } from '../../dragon-scales/TimelineCharts/InsightsCost/insightsCost.mocks';
import { getCommonDomain } from '../../ExecutiveDashboard/utilities';
import useMilestonesQuery from '../../Milestones/hooks/useMilestonesQuery';
import { TimelineData } from '../../shared-widgets/TimelineChart/timeline/timeline.types';
import TimelineDueDateChart from '../../shared-widgets/TimelineChart/TimelineDueDateChart';
import { useItemsTimeline } from '../../Timeline/hooks/ItemsHook';
import { useTimelineQuery } from '../../Timeline/hooks/TimelineHook';
import { ACTIVITIES_DEFAULT, buildTree } from '../../Timeline/Timeline';
import { ActivityNode } from '../../Timeline/Timeline.types';
import { computeItems } from '../../Timeline/TimelineUtils';
import useMemoWrapper from '../../useMemoWrapper';
import { getTodayRangeStr } from '../Charts/utils';
import TableChartPlaceholder from '../TableChartPlaceholder';

const DATE_STR_INIT = '0';
const PADDING = 32;
const CHART_HEIGHT = 56 + PADDING;
const BASE_HEIGHT = 42 + PADDING;

export default function ProjectTimeline(props: {
  projectID: UUID;
  startStr: string;
  endStr: string;
  onZoom: (domain: string[]) => void;
}) {
  const projectID = props.projectID;
  const milestonesQueryResult = useMilestonesQuery(projectID || '', false);
  const projectPropsQueryResult = useProjectPropsQuery(projectID);
  // Activities
  const [activityFilter] = useState<TimelineActivityType[]>(ACTIVITIES_DEFAULT);
  const {
    data: recentData,
    loading: timelineLoading,
    previousData,
  } = useTimelineQuery({ projectID, types: activityFilter });
  const data = recentData || previousData;
  const activities = data?.timeline?.activities ?? [];

  const inTimeDomain = useReactiveVar(insightsTimeDomain);
  const inMaxItemsBar = useReactiveVar(insightsMaxItemsBar);

  const tree = useMemoWrapper(buildTree, activities);

  const mapChildren = (a: ActivityNode): TimelineData => ({
    id: a.id,
    name: a.name,
    type: a.type,
    start: a.startDate,
    end: a.endDate ?? undefined,
    children: a.children.length === 0 ? undefined : a.children.map(mapChildren),
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const computeTimelineData = (tree: any) => tree.map(mapChildren);
  const timelineData: TimelineData[] = useMemoWrapper(computeTimelineData, tree);

  const { data: itemsData, loading: itemsListLoading } = useItemsTimeline(projectID);
  const itemsList = itemsData?.itemsList?.items ?? [];

  const today = getTodayAtUtcNoon();
  const itemsListAll = itemsList;
  const items = useMemoWrapper(computeItems, itemsListAll, today);
  const withoutDueDateCount = itemsListAll.length - items.length;

  // Min and Max date range values for zoom domain and pickers
  const [startStr, setStartStr] = useState<string>(props.startStr);
  const [endStr, setEndStr] = useState<string>(props.endStr);

  useEffect(() => {
    if (startStr !== props.startStr) setStartStr(props.startStr);
    if (endStr !== props.endStr) setEndStr(props.endStr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startStr, props.endStr]);

  const [, setProjectRange] = useState<string[]>([DATE_STR_INIT, DATE_STR_INIT]);

  const containerRef = createRef<HTMLDivElement>();
  const [width, setWidth] = useState<number | undefined>(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      const newWidth = containerRef.current?.offsetWidth;
      if (!newWidth) return;
      if (width === newWidth) return;
      setWidth(newWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef, width]);

  const loading =
    timelineLoading ||
    itemsListLoading ||
    milestonesQueryResult.loading ||
    projectPropsQueryResult.loading;

  const setStartEnd = ([start, end]: string[]) => {
    setStartStr(start);
    setEndStr(end);
  };

  useEffect(() => {
    if (loading) return;
    const domain = getTodayRangeStr(
      [
        ...timelineData,
        ...items.map((d) => ({
          start: d.dueDate,
        })),
      ],
      today
    );
    setProjectRange(domain);
    // Update projects summary map, it is used for summary zoom chart
    projectsSummaryVar(
      new Map(projectsSummaryVar().set(projectID, [new Date(domain[0]), new Date(domain[1])]))
    );
    const commonDomain = inTimeDomain;
    if (commonDomain[0] === '0') {
      insightsTimeDomain(domain);
      setStartEnd(domain);
    } else {
      const newCommonDomain = getCommonDomain(commonDomain, domain);
      if (newCommonDomain[0] !== commonDomain[0] || newCommonDomain[1] !== commonDomain[1]) {
        insightsTimeDomain(newCommonDomain);
      }
      setStartEnd(newCommonDomain);
    }
  }, [items, loading, inTimeDomain, today, timelineData, projectID]);

  const onZoom = (domain: string[]) => {
    setStartEnd(domain);
    props.onZoom(domain);
  };

  if (loading) return <TableChartPlaceholder />;

  const chart = (
    <TimelineDueDateChart
      activities={activities}
      data={timelineData}
      endDate={endStr}
      expandedMap={{}}
      height={CHART_HEIGHT}
      items={items}
      maxItemsBar={inMaxItemsBar}
      onAnalytics={() => {}}
      onExpand={() => {}}
      onZoom={onZoom}
      settings={{
        ...{ collapse: [TimelineGroups.TIMELINE], expand: [TimelineGroups.ITEMS] },
        projectID,
      }}
      startDate={startStr}
      today={today}
      width={width}
      withoutDueDateCount={withoutDueDateCount}
      zoomLineCompressed
    />
  );

  const base = (
    <InsightsBaseLine
      data={milestones}
      height={BASE_HEIGHT}
      today={new Date(today)}
      totalRange={[startStr ?? '0', endStr ?? '0']}
    />
  );

  return (
    <div ref={containerRef} className="relative block h-[162px] w-full bg-background-primary">
      {chart}
      {base}
    </div>
  );
}
