import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import {
  previewEventTypes,
  previewPermissionsAnalytics,
  previewTypes,
  removeCollaboratorAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { setPreviewSettingsVar } from '../../../api/apollo/reactiveVars';
import {
  SetCollaboratorTradesMutation,
  SetCollaboratorTradesMutationVariables,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import {
  useCollaboratorResponsibility,
  useDeleteCollaborator,
  useUpdateCollaboratorRole,
} from '../../Collaborators/hooks';
import { setCollaboratorTradesMutation } from '../../Collaborators/hooks/queries';
import { useCurrentCollaborator } from '../../contexts/current-collaborator';

export default function useTeammateMutations(projectID: UUID) {
  const sendAnalytics = useSendAnalytics();

  const updateCollaboratorRole = useUpdateCollaboratorRole();
  const onUpdateCollaboratorRole = useCallback(
    (collaboratorID: UUID, roleID: UUID) => {
      updateCollaboratorRole(projectID, collaboratorID, roleID);
    },
    [projectID, updateCollaboratorRole]
  );

  const [updateCollaboratorResponsibility] = useCollaboratorResponsibility();
  const onUpdateCollaboratorResponsibility = useCallback(
    (collaboratorID: UUID, responsibility: string) => {
      updateCollaboratorResponsibility(projectID, collaboratorID, responsibility);
    },
    [projectID, updateCollaboratorResponsibility]
  );

  const [setCollaboratorTrades] = useMutation<
    SetCollaboratorTradesMutation,
    SetCollaboratorTradesMutationVariables
  >(setCollaboratorTradesMutation);
  const onUpdateCollaboratorTrades = useCallback(
    (variables: { allTrades: boolean; collaboratorID: UUID; categoryIDs: UUID[] }) =>
      setCollaboratorTrades({ variables: { ...variables, projectID } }),
    [setCollaboratorTrades, projectID]
  );

  const onPreviewUser = useCallback(
    (userID: UUID) => {
      setPreviewSettingsVar({ userID, roleID: undefined });
      sendAnalytics(
        previewPermissionsAnalytics(previewEventTypes.PREVIEW_USER_CTA, previewTypes.USER)
      );
    },
    [sendAnalytics]
  );

  const deleteCollaborator = useDeleteCollaborator();
  const currentCollaborator = useCurrentCollaborator();
  const navigate = useNavigate();
  const onRemoveUser = useCallback(
    (collaboratorID: UUID) => {
      deleteCollaborator(projectID, collaboratorID, () => {
        sendAnalytics(removeCollaboratorAnalytics(collaboratorID, currentCollaborator?.user.email));
        if (collaboratorID === currentCollaborator?.id) {
          navigate('/');
        }
      });
    },
    [
      currentCollaborator?.id,
      currentCollaborator?.user.email,
      deleteCollaborator,
      navigate,
      projectID,
      sendAnalytics,
    ]
  );

  return {
    onUpdateCollaboratorResponsibility,
    onUpdateCollaboratorRole,
    onUpdateCollaboratorTrades,
    onPreviewUser,
    onRemoveUser,
  };
}
