import { FC } from 'react';

import { DeleteOutline } from '@material-ui/icons';

import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import DialogsConfirm from '../DialogsConfirm/DialogsConfirm';

const deleteToast = 'Report deleted.';

type DialogReportsProps = {
  onClose: () => void;
  onDelete: (reportID: UUID, onSuccess?: () => void, onFailure?: () => void) => void;
  open: boolean;
  report: UserReport | ForecastingReport;
};

const DialogsReportsDelete: FC<DialogReportsProps> = ({ onClose, onDelete, open, report }) => {
  const onConfirm = () => {
    onDelete(
      report.id,
      () => {
        setToast({ message: deleteToast, icon: <DeleteOutline /> });
        onClose();
      },
      onClose
    );
  };

  return (
    <DialogsConfirm
      acceptCtaCopy="Delete"
      body={`Your report "${report?.name ?? ''}" will be deleted`}
      destructiveConfirm
      onClose={onClose}
      onConfirm={onConfirm}
      open={open}
      title="Delete Report"
    />
  );
};

export default DialogsReportsDelete;
