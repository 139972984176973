import { TableComponents } from '../../../../scales';

type PermissionTextCellProps = {
  disabled: boolean;
  label: string;
};

const PermissionTextCell = (props: PermissionTextCellProps) => {
  return (
    <TableComponents.Cell>
      <div className={props.disabled ? 'text-type-inactive' : ''}>{props.label}</div>
    </TableComponents.Cell>
  );
};

export default PermissionTextCell;
