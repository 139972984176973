import { FC, useState } from 'react';

import { DIRECT_COSTS, ESTIMATE_CONTENT } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';

import ItemSidebarCollapseHeader from './ItemSidebarCollapseHeader';
import ItemSidebarStyles from './ItemSidebarStyles';

type ItemSidebarCollapseEstimateHeaderProps = {
  cost: JSX.Element;
  classes: Classes<typeof ItemSidebarStyles>;
  disabled: boolean;
  lines: JSX.Element;
  name: string;
  subtotal: JSX.Element;
};

const ItemSidebarCollapseEstimateHeader: FC<ItemSidebarCollapseEstimateHeaderProps> = ({
  cost,
  classes,
  disabled,
  lines,
  name,
  subtotal,
}) => {
  const [expanded, setExpanded] = useState<string[]>([DIRECT_COSTS]);
  const isExpanded = expanded.includes(name);

  return (
    <>
      <div className={classes.estimateHeaderFooter}>
        <ItemSidebarCollapseHeader
          content={cost}
          disabled={disabled}
          expanded={expanded}
          isExpanded={isExpanded}
          name={name}
          sectionID={name}
          setExpanded={(name: string[]) => setExpanded(name)}
          variant={ESTIMATE_CONTENT}
        />
      </div>
      {isExpanded && lines}
      {isExpanded && <div className={classes.estimateTotalDivider} />}
      {isExpanded && subtotal}
    </>
  );
};
export default withStyles(ItemSidebarStyles)(ItemSidebarCollapseEstimateHeader);
