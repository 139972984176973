import { FC, useContext, useState } from 'react';

import { COST_OF_CONSTRUCTION_REF } from '../../../constants';
import { EstimateTotalType, MarkupDisplayType, MarkupType } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import SubtotalIcon from '../../Icons/Subtotals';
import { ReferenceDisplay } from '../../JoinGrid/types';
import { formatRefsToNames } from '../../JoinGrid/utilities/cell';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';

import { generateReferencesMenuItems } from './generateReferencesMenuItems';
import InputsSelectReferenceStyles from './InputsSelectReferenceStyles';
import ReferencesMenu from './ReferencesMenu';
import { MarkupSelectors, ReferenceSelectorOptions } from './utils';

type Props = {
  classes: Classes<typeof InputsSelectReferenceStyles>;
  options: ReferenceSelectorOptions;
  error?: string;
  hasFilters: boolean;
  canViewDirectCosts: boolean;
  markups: SelectableMarkup[];
  s1RefShouldIncludeS2: boolean;
  selectors: MarkupSelectors;
  setSelectors: (newSelectors: MarkupSelectors) => void;
  sourceFilterMenuOptions?: ItemLink[];
  totalType?: EstimateTotalType;
};

const InputsSelectReferenceOptions: FC<Props> = ({
  classes,
  error = undefined,
  hasFilters,
  options,
  markups,
  s1RefShouldIncludeS2,
  sourceFilterMenuOptions,
  totalType,
  canViewDirectCosts,
  selectors,
  setSelectors,
}) => {
  const termStore = useContext(ProjectTermStore);
  const { isFixedMarkup, isItem, isOwnerCost } = options;
  const { references, costTypes, sources, sourceFilterIDsWithoutS2Reference } = selectors;

  // STATE VARIABLES
  const [newError, setNewErrorState] = useState(error);

  // VARIABLES
  const hasSourceFilterMenuOptions = sourceFilterMenuOptions && sourceFilterMenuOptions.length > 0;

  const referenceDisplayNames = formatRefsToNames(
    markups,
    {
      type: isFixedMarkup ? MarkupType.FIXED : MarkupType.PERCENT,
      displayType: MarkupDisplayType.MARKUP,
      markupReference: {
        appliesTo: references,
      },
      costTypeFilters: costTypes,
    },
    false,
    !!hasSourceFilterMenuOptions,
    sources,
    sourceFilterIDsWithoutS2Reference
  );

  let subtotalDescription = '';
  if (references.length || isFixedMarkup) {
    let reference = referenceDisplayNames;
    if (isFixedMarkup && references.includes(COST_OF_CONSTRUCTION_REF)) {
      subtotalDescription = `Applied to ${ReferenceDisplay.COST_OF_CONSTRUCTION}`;
    } else if (isFixedMarkup)
      reference = s1RefShouldIncludeS2 ? ReferenceDisplay.S1_S2 : ReferenceDisplay.S1;
    subtotalDescription = `Applied to ${reference}`;
  } else {
    subtotalDescription = ReferenceDisplay.NOT_APPLIED;
  }

  return (
    <ReferencesMenu
      icon={<SubtotalIcon />}
      subMenuItems={
        isFixedMarkup
          ? undefined
          : generateReferencesMenuItems({
              classes,
              isFixedMarkup,
              markups,
              canViewDirectCosts,
              s1RefShouldIncludeS2,
              selectors,
              setSelectors,
              setNewErrorState,
              termStore,
              totalType,
              isItem,
              newError,
              hasFilters,
              isOwnerCost,
            })
      }
      subTitle={subtotalDescription}
      title="Subtotals and markups"
    />
  );
};

export default withStyles(InputsSelectReferenceStyles)(InputsSelectReferenceOptions);
