import { ProjectCostBreakdown, ProjectCostBreakdownType } from '../../generated/graphql';

export const generateProjectCostBreakdownReportStorageParam = (milestoneID: string) =>
  `Milestone ${milestoneID} Project Cost Breakdown Report `;

export const projectBreakdownCostColors = new Map<ProjectCostBreakdownType, string>([
  [ProjectCostBreakdownType.ALLOWANCES, 'fill-theme-bauhaus-kandinsky'],
  [ProjectCostBreakdownType.CONTINGENCIES, 'fill-theme-bauhaus-marcelbreuer'],
  [ProjectCostBreakdownType.DIRECTCOSTS, 'fill-background-2'], // Either Direct costs or Cost of construction should be visible, not both
  [ProjectCostBreakdownType.DIRECTCOSTSANDALLOCATEDMARKUPS, 'fill-background-2'],
  [ProjectCostBreakdownType.COSTOFCONSTRUCTION, 'fill-background-2'], // Either Direct costs or Cost of construction should be visible, not both
  [ProjectCostBreakdownType.MARKUPS, 'fill-theme-bauhaus-laszlo'],
  [ProjectCostBreakdownType.OWNERCOSTS, 'fill-theme-bauhaus-seagram'],
]);

export const projectBreakdownTableOrder = new Map<ProjectCostBreakdownType, number>([
  [ProjectCostBreakdownType.DIRECTCOSTS, 0], // Either Direct costs or Cost of construction should be visible, not both
  [ProjectCostBreakdownType.DIRECTCOSTSANDALLOCATEDMARKUPS, 1],
  [ProjectCostBreakdownType.COSTOFCONSTRUCTION, 2], // Either Direct costs or Cost of construction should be visible, not both
  [ProjectCostBreakdownType.MARKUPS, 3],
  [ProjectCostBreakdownType.CONTINGENCIES, 4],
  [ProjectCostBreakdownType.ALLOWANCES, 5],
  [ProjectCostBreakdownType.OWNERCOSTS, 6],
]);

export const projectBreakdownChartOrder = new Map<ProjectCostBreakdownType, number>([
  [ProjectCostBreakdownType.MARKUPS, 0],
  [ProjectCostBreakdownType.CONTINGENCIES, 1],
  [ProjectCostBreakdownType.ALLOWANCES, 2],
  [ProjectCostBreakdownType.OWNERCOSTS, 3],
  [ProjectCostBreakdownType.DIRECTCOSTS, 4], // Either Direct costs or Cost of construction should be visible, not both
  [ProjectCostBreakdownType.DIRECTCOSTSANDALLOCATEDMARKUPS, 5],
  [ProjectCostBreakdownType.COSTOFCONSTRUCTION, 6], // Either Direct costs or Cost of construction should be visible, not both
]);

export const projectBreakdownCostColorsKey = new Map<ProjectCostBreakdownType, string>([
  [ProjectCostBreakdownType.ALLOWANCES, 'bg-theme-bauhaus-kandinsky'],
  [ProjectCostBreakdownType.CONTINGENCIES, 'bg-theme-bauhaus-marcelbreuer'],
  [ProjectCostBreakdownType.DIRECTCOSTS, 'bg-background-2'],
  [ProjectCostBreakdownType.DIRECTCOSTSANDALLOCATEDMARKUPS, 'bg-background-2'],
  [ProjectCostBreakdownType.COSTOFCONSTRUCTION, 'bg-chart-axis'],
  [ProjectCostBreakdownType.MARKUPS, 'bg-theme-bauhaus-laszlo'],
  [ProjectCostBreakdownType.OWNERCOSTS, 'bg-theme-bauhaus-seagram'],
]);

export const breakdownSorting = (order: Map<ProjectCostBreakdownType, number>) => {
  return (a: ProjectCostBreakdown, b: ProjectCostBreakdown) => {
    const i = order.get(a.type) ?? 0;
    const j = order.get(b.type) ?? 0;
    return i - j;
  };
};
