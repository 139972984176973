import { useParams } from 'react-router-dom';

export default function ProgramDashboard() {
  const { programID } = useParams();

  return (
    <div className="flex w-full ">
      <div className="flex-grow type-giant">Program Dashboard (ID: {programID})</div>
    </div>
  );
}
