import { FC, useState } from 'react';

import { YC_GROUPS, YC_PROJ_LEAD } from '../../../features';
import { ForecastingProjectsSortKey } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { formatNumber } from '../../../utilities/string';
import { future, past } from '../../dragon-scales/TimelineCharts/InsightsCost/insightsCost.mocks';
import ProjectCell from '../../ForecastingRoute/ForecastingExplore/projects/cells/ProjectCell';
import { SortManager, Table } from '../../scales';
import ProjectCost from '../ProjectCost';
import { InsightsTabId } from '../types';

import StickyBottomRow from './StickyBottomRow';
import ZoomTimeline from './ZoomTimeline';

type Props = {
  counts: { current: number; total: number };
  loading: boolean;
  onFetchMore: () => void;
  projects: InsightsProjects[];
  searchText: string;
  sortManager: SortManager;
};

const ProjectsCost: FC<Props> = (props) => {
  const isGroupsEnabled = useHasFeature(YC_GROUPS);
  const isProjectLeadEnabled = useHasFeature(YC_PROJ_LEAD);

  const [startStr, setStartStr] = useState<string>(past);
  const [endStr, setEndStr] = useState<string>(future);
  const setStartEnd = ([start, end]: string[]) => {
    setStartStr(start);
    setEndStr(end);
  };
  const onZoom = (domain: string[]) => {
    setStartEnd(domain);
  };

  const stickyFooterEntry = StickyBottomRow({
    columnsCount: 5,
    component: <ZoomTimeline onZoom={onZoom} projects={props.projects} zoom={[startStr, endStr]} />,
  });

  if (!props.projects) return null;

  return (
    <>
      <Table
        columnWidths={[
          'minmax(400px, 3fr)',
          'minmax(100px, 0.5fr)',
          'minmax(100px, 0.5fr)',
          'minmax(100px, 0.5fr)',
          'minmax(600px, 6fr)',
        ]}
        entries={props.projects.map((project) => {
          const key = project.id;
          const to = {
            pathname: generateSharedPath(RouteKeys.INSIGHTS_PROJECT, {
              projectId: project.id,
            }),
            hash: `#${InsightsTabId.Costs}`,
          };
          return [
            {
              key,
              component: (
                <ProjectCell
                  isSingleLineHeader
                  project={project}
                  searchText={props.searchText}
                  showOrgs={isGroupsEnabled}
                  showProjectLead={isProjectLeadEnabled}
                  to={to}
                />
              ),
            },
            {
              key,
              centered: true,
              component: <div>$32,813,626</div>,
            },
            {
              key,
              centered: true,
              component: <div>$32,319,922</div>,
            },
            {
              key,
              centered: true,
              component: <div>-$493,704</div>,
            },
            {
              key,
              component: <ProjectCost endStr={endStr} projectID={project.id} startStr={startStr} />,
            },
          ];
        })}
        headerContent={[
          {
            component: <div className="h-9" />,
            copy: `Projects (${formatNumber(props.counts.current)} of ${formatNumber(
              props.counts.total
            )})`,
            key: 'projects',
            headerSortKey: ForecastingProjectsSortKey.NAME,
          },
          {
            copy: 'Running Total',
            key: 'Running Total',
          },
          { copy: 'Budget', key: 'Budget' },
          {
            copy: 'Gap',
            key: 'Gap',
          },
          { copy: '', key: 'cost' },
        ]}
        loading={props.loading}
        onNeedMoreData={props.onFetchMore}
        sortManager={props.sortManager}
        stickyFooterEntry={stickyFooterEntry}
      />
    </>
  );
};

export default ProjectsCost;
