import { FC } from 'react';

import { BULK_EDIT, PREVIEW_ROLE, REMOVE_FILTER, RESET } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';

import PanelSelectHeaderStyles from './PanelSelectHeaderStyles';

type PanelSelectHeaderProps = {
  classes: Classes<typeof PanelSelectHeaderStyles>;
  clearAllText?: string;
  clearFilter: () => void;
  title: string;
  selectAll?: () => void;
  showRemove?: boolean;
  variant?: typeof BULK_EDIT | typeof PREVIEW_ROLE;
};

const PanelSelectHeader: FC<PanelSelectHeaderProps> = ({
  classes,
  clearAllText = REMOVE_FILTER,
  clearFilter,
  title,
  selectAll,
  showRemove,
  variant,
}) => {
  if (!title) return null;
  const clearText = variant === BULK_EDIT ? RESET : clearAllText;
  return (
    <div className={classes.componentHeader}>
      <div className="type-label">{title}</div>
      <div className={classes.linkContainer}>
        {showRemove && (
          <div
            className={`${classes.link} ${variant === BULK_EDIT && classes.linkPadding}`}
            data-cy={`removeFilters-${title.replace(' ', '')}`}
            onClick={clearFilter}
            onKeyPress={clearFilter}
            role="button"
            tabIndex={0}
          >
            {clearText}
          </div>
        )}
        {selectAll && (
          <div
            className={`${classes.link} ${classes.marginRight}`}
            data-cy={`selectAll-${title.replace(' ', '')}`}
            onClick={selectAll}
            onKeyPress={selectAll}
            role="button"
            tabIndex={0}
          >
            select all
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(PanelSelectHeaderStyles)(PanelSelectHeader);
