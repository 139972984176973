import { FC } from 'react';
import * as React from 'react';
import { isEmpty } from 'validator';

import { TextField, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import CreateCategorizationDialogStyles from './CreateCategorizationDialogStyles';

type ChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;

type CreateCategorizationProps = {
  classes: Classes<typeof CreateCategorizationDialogStyles>;
  handleChange: (e: ChangeEvent, inputType: 'name' | 'description') => void;
  handleSubmit: (k: React.FormEvent<HTMLFormElement> | undefined) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  handleBlur?: (e: any) => void;
  loading?: boolean;
  setErrorMessage: (message: string) => void;
  values: { name: string; description?: string };
  disableOnReplace?: boolean;
};

const CreateCategorization: FC<CreateCategorizationProps> = ({
  classes,
  handleChange,
  handleBlur,
  handleSubmit,
  loading = false,
  setErrorMessage,
  values,
  disableOnReplace,
}) => {
  return (
    <div>
      <Typography variant="caption">Name*</Typography>
      <TextField
        data-cy="CreateCategorization-nameInput"
        disabled={disableOnReplace}
        fullWidth
        InputProps={{ disableUnderline: true, autoComplete: 'off' }}
        name="name"
        onBlur={handleBlur}
        onChange={(evt) => {
          handleChange(evt, 'name');
          if (isEmpty(evt.target.value, { ignore_whitespace: true })) {
            setErrorMessage('Name cannot be empty');
          } else {
            setErrorMessage('');
          }
        }}
        onKeyPress={(evt) => {
          if (evt.key === 'Enter') {
            evt.currentTarget.blur();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            handleSubmit(evt as any);
            evt.stopPropagation();
            evt.preventDefault();
          }
        }}
        placeholder={loading ? 'Loading...' : 'Enter a name...'}
        value={values.name}
      />
      <>
        <div className={classes.padding}>
          <Typography variant="caption">Description</Typography>
          <TextField
            disabled={disableOnReplace}
            fullWidth
            InputProps={{ disableUnderline: true, autoComplete: 'off' }}
            name="description"
            onBlur={handleBlur}
            onChange={(evt) => {
              handleChange(evt, 'description');
            }}
            onKeyPress={(evt) => {
              if (evt.key === 'Enter') {
                evt.currentTarget.blur();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                handleSubmit(evt as any);
                evt.stopPropagation();
                evt.preventDefault();
              }
            }}
            placeholder={loading ? 'Loading...' : 'Enter a description...'}
            value={values.description}
          />
        </div>
      </>
    </div>
  );
};

export default withStyles(CreateCategorizationDialogStyles)(CreateCategorization);
