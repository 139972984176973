import {
  ExecutiveDashboardOpenItemsDueSorting,
  ItemDueDateTypes,
  JoinRoutes,
  PermissionGroup,
  ProjectStatuses,
  ProjectTypes,
  TermKey,
  UserActivity,
} from '../../api/gqlEnums';
import {
  OTHER,
  OTHER_PROJECT_TYPES,
  PROJECT_STATUS,
  PROJECT_TYPE,
  UNASSIGNED,
} from '../../constants';
import {
  GetActiveUsersQuery,
  GetItemsOwnershipQuery,
  GetProjectActiveUsersQuery,
  GetProjectsOverviewQuery,
  ListCurrentItemsBreakdownQuery,
  ListDashboardProjectsQuery,
  Status,
} from '../../generated/graphql';
import theme from '../../theme/komodo-mui-theme';
import { formatCommas, formatCost } from '../../utilities/currency';
import { parseDate, parseMonthYear } from '../../utilities/dates';
import { getItemStatusLabel } from '../../utilities/item-status';
import { generateSharedPath } from '../../utilities/routes/links';
import { noPermissionTooltip, percentFormatterSimple } from '../../utilities/string';
import { LineDatum } from '../Charts/ChartsD3/ChartsLineGraph/ChartsLineGraphUtils';
import {
  ChartStackMap,
  ProjectCostRange,
  VerticalBarGraphData,
} from '../Charts/ChartsD3/ChartsVerticalBarGraph/ChartsVerticalBarGraphUtils';
import {
  LegendData,
  LegendElementType,
  getColorMapItem,
} from '../Charts/ChartsLegend/ChartsLegendUtils';
import {
  MAX_PIE_CHART_ELEMS,
  PieChartData,
  getPieChartSectionPercentage,
} from '../Charts/ChartsPieChart/ChartsPieChartUtils';
import { ToggleOption } from '../Inputs/InputsToggle/InputsToggle';
import ItemsIconsMap, { SMALL } from '../ItemsList/ItemsIcons/ItemsIconsMap';
import { TableHeader, TableRow } from '../Tables/DataTable/DataTable';

export const TILE_LARGE_WIDTH = 674;
export const TILE_SMALL_WIDTH = 331;
export const BUDGET_GAPS_OFFSET = 47;
export const CHART_BORDER = 16;
export const CHART_SMALL = 'SMALL';
export const CHART_LARGE = 'LARGE';
export const MOST_RECENTLY_CREATED_HEIGHT = 520;
export const CHART_BAR_LENGTH = TILE_LARGE_WIDTH - 124;
export const CHART_DEFAULT_BAR_HEIGHT = 18;
export const MAX_ACTIVE_USERS_DISPLAYED = 10;
export const MAX_SHOWN_PROJECTS = 10;
export const PROJECT_NAME_CHAR_LIMIT = 5;

export const CHART_FRAME_TITLE_HEIGHT = 40;
export const CHART_PADDING = 12;
export const PROJECTS_OVERVIEW_HEIGHT = 372;
export const BUDGET_GAPS_HEIGHT = 372;
export const PROJECT_MOVEMENT_HEIGHT = 381;
export const ITEMS_OWNERSHIP_HEIGHT = 381;
export const ACTIVE_USERS_HEIGHT = 381;
export const PROJECT_ACTIVE_USERS_HEIGHT = 381;
export const DECISION_SUMMARY_HEIGHT = 214;
export const CURRENT_ITEMS_BREAKDOWN_HEIGHT = 382;
export const CURRENT_ITEMS_BREAKDOWN_HINT_HEIGHT = 436;
export const CURRENT_ITEMS_BREAKDOWN_HINT_WIDTH = 360;
export const ITEM_DUE_DATE_HEIGHT = 608;
export const PROJECT_MAP_HEIGHT = 381;

export const VERTICAL_BAR_CHART_BAR_WIDTH = 0.75;
export const VERTICAL_BAR_CHART_BOTTOM_PADDING = 37;
export const VERTICAL_BAR_CHART_HEIGHT =
  BUDGET_GAPS_HEIGHT - CHART_FRAME_TITLE_HEIGHT - BUDGET_GAPS_OFFSET;
export const VERTICAL_BAR_CHART_LABEL_OFFSET = -12;
export const VERTICAL_BAR_CHART_LEFT_MARGIN = 68;
export const VERTICAL_BAR_CHART_RIGHT_PADDING = 12;

export const VERTICAL_BAR_CHART_RANGE = [VERTICAL_BAR_CHART_HEIGHT - BUDGET_GAPS_OFFSET, 14];

export const ACTIVITY = 'Activity';
export const BUDGET_GAP = 'Budget Gap';
export const COST_CHANGE = 'Cost Change';
export const COST_IMPACT = 'Cost Impact';
export const DECISIONS = 'Decisions';
export const ITEM_CREATOR = 'Item Creator';
export const ITEM_STATUS = 'Item Status';
export const NA = 'N/A';
export const PERCENT_CHANGE = '% Change';
export const PORTFOLIO_INSIGHTS = 'Portfolio Insights';
export const PROJECT = 'Project';
export const PROJECT_DECREASES = 'Project Decreases';
export const PROJECT_INCREASES = 'Project Increases';
export const PROJECTS_SOURCE = 'projects';
export const STATUS = 'Status';
export const STATUSES_CHANGED_THIS_WEEK = 'Items with status changes this week';
export const USER = 'User';

export const TOTAL_CONSTRUCTION_VOLUME = 'Total Construction Volume';
export const TOTAL_PROJECTS = 'Total Projects';
export const BY_PROJECT_TYPE = 'By Project Type';
export const BY_PROJECT_STATUS = 'By Project Status';

export const NUM_ITEMS = '# of Items';
export const NUM_PROJECTS = '# of Projects';
export const COST_PROJECTS = 'Cost of Projects';

// WIDGET TOOLTIPS
export const ACTIVE_ITEMS_SUMMARY_TOOLTIP =
  'View a summary of decisions being made, summarized by items that have been updated from one status to another.';
export const BUDGET_GAP_BY_PROJECT_TOOLTIP =
  'View the top 10 selected projects with the largest budget gap. The budget gap is calculated as the active milestone budget minus the active milestone running total. Projects that have met their target budget will not be displayed in this chart.';
export const CURRENT_ITEMS_BREAKDOWN_BY_COST_IMPACT_TOOLTIP =
  'View a cost breakdown of items across all your selected projects. This shows the top 10 projects with the largest cost impact of all items.';
export const ITEMS_OWNERSHIP_TOOLTIP =
  'View a breakdown of items that are assigned to different roles across all your selected projects.';
export const LARGEST_MOVEMENT_TOOLTIP =
  "View the projects with the largest cost increases or decreases in the last 30 days. The cost trend is calculated by comparing each project's running total between today and 30 days ago.";
export const MOST_RECENTLY_CREATED_PROJECTS_TOOLTIP = 'View the top 10 newest projects.';
export const OPEN_ITEMS_DUE_TOOLTIP =
  'View which projects have the most pending items. You can also focus in on projects with the most items past due or due this week.';
export const PROJECT_ACTIVE_USERS_TOOLTIP =
  'View the total number of unique users in the past 6 months.';
export const PROJECTS_MAP_TOOLTIP =
  "View a map of the selected projects, clustered by location. The location can be set or updated in each project's settings.";
export const PROJECTS_OVERVIEW_TOOLTIP =
  'The Total Construction Volume (TCV) is the sum of the construction volume for all selected projects. Construction volume is calculated as the current milestone estimate total or target budget total, whichever is greater. Please note: the TCV is unrelated to billing.';
export const TOP_USERS_TOOLTIP =
  'View the people who have added the most items and comments across all your selected projects. This includes all teammates on your projects, regardless of their role or company.';
export const BY_PROJECT_TYPE_TOOLTIP =
  'View a detailed breakdown of your selected projects by Project Type (e.g. Healthcare, Education).';
export const BY_PROJECT_STATUS_TOOLTIP =
  'View a breakdown of your selected projects by Project Status (e.g. Active, In Pursuit).';

export type ExecutiveDashboardDataProps = {
  dashboardLoading?: boolean;
  projectIDs: UUID[];
  onHover?: () => void;
  projectMap?: ProjectMap;
};

export type ProjectMap = Map<
  string,
  {
    code?: string;
    hasAccess: boolean;
    milestoneName: string;
    name: string;
  }
>;

export const createProjectMap = (
  projects: ListDashboardProjectsQuery['dashboardProjects']['projects']
) => {
  return new Map(
    projects.map((project) => {
      const { id, code, hasAccess, milestoneName, name } = project;
      return [id, { code, hasAccess, milestoneName, name }];
    })
  ) as ProjectMap;
};

// Active Users
// Shorter alias.
export type ActiveUser = GetActiveUsersQuery['activeUsers'][number];

export const activityMap = new Map([
  [UserActivity.ITEMS_CREATED, { text: 'Items', header: 'Items Created' }],
  [UserActivity.COMMENTS_ADDED, { text: 'Comments', header: 'Comments' }],
]);

export const makeActivityToggleOption = (activity: UserActivity) => {
  const value = activityMap.get(activity);
  return {
    key: activity,
    text: (value && value.text) || '',
  };
};

export const getActivityHeader = (activity: UserActivity) => {
  const value = activityMap.get(activity);
  return value && value.header;
};

export const getActivityValue = (user: ActiveUser | null, activity: UserActivity) => {
  if (!user) return '-';
  return formatCommas(Number(user[activity as keyof ActiveUser]));
};

export const formatActiveUsersTableHeaderData = (activity: UserActivity): TableHeader => ({
  dataHeaders: [
    { label: activity === UserActivity.COMMENTS_ADDED ? USER : ITEM_CREATOR, alignLeft: true },
    { label: getActivityHeader(activity) || '' },
  ],
});

export const formatActiveUsersTableData = (
  user: ActiveUser,
  activity: UserActivity,
  getAvatar: (user: ActiveUser) => JSX.Element
): TableRow => ({
  data: [getActivityValue(user, activity)],
  disabled: !user,
  name: user ? user.name : '-',
  icon: getAvatar(user),
  tooltip: user ? user.name : '',
});

// Project Active Users

export type ProjectActiveUsers = GetProjectActiveUsersQuery['projectActiveUsers'][number];

export const projectActiveUsersColors = Object.values(theme.palette.bauhaus);

export const projectActiveUsersChartSize = {
  height: PROJECT_ACTIVE_USERS_HEIGHT - 142,
  width: 650,
  padding: {
    // note padding is added to overall width unlike in a div
    top: 12,
    right: 24,
    bottom: 12,
    left: 10,
  },
  xAxisLayout: {
    padding: 4, // width alloted for the the x axis labels
    numberOfTicks: 0,
    showLabels: true,
  },
  yAxisLayout: {
    padding: 6, // width alloted for the the y axis labels
    numberOfTicks: 0,
    showLabels: true,
  },
};

export const formatProjectActiveUsersLineChartData = (
  projectActiveUsers: ProjectActiveUsers[],
  projectMap?: ProjectMap
) => {
  const data =
    projectActiveUsers &&
    projectActiveUsers.map((projectUsers) => {
      const dateString = parseDate(projectUsers.month) ?? '';
      const monthString = parseMonthYear(dateString)[0];
      const yearNumber = Number(parseMonthYear(dateString)[1]);
      const monthNumber = Number(monthString);

      const currentYear = new Date().getFullYear();
      const x = yearNumber && yearNumber < currentYear ? monthNumber - 12 : monthNumber;

      const { projectID: id, userCount } = projectUsers;
      const { code: abbreviation = '', name = '' } = projectMap?.get(id) || {};

      return {
        project: {
          id,
          name,
          abbreviation,
        } as ProjectInfo,
        dateString,
        x,
        y: userCount,
      };
    });

  const mapData = new Map<UUID, LineDatum[]>();
  if (data) {
    data.forEach((elem) => {
      const arr: LineDatum[] = mapData.get(elem.project.id) || [];
      arr.push({ x: elem.x, y: elem.y });
      mapData.set(elem.project.id, arr);
    });
  }

  mapData.forEach((value, key, map) => {
    if (value.length < 2) map.delete(key);
  });

  return { data, mapData };
};

export const formatProjectActiveUsersLegendData = (
  data: ProjectActiveUsers[] | null,
  mapData: Map<UUID, LineDatum[]>,
  projectMap: ProjectMap | undefined
): LegendElementType[] =>
  mapData &&
  Array.from(mapData.keys()).map((key, i) => {
    const projectUsers = data?.find((elem) => elem.projectID === key);
    if (!projectUsers) return { text: '' };
    const { projectID } = projectUsers;
    const {
      code = null,
      hasAccess = false,
      name = '',
      milestoneName = '',
    } = projectMap?.get(projectID) || {};
    return {
      index: i,
      text: getProjectDisplayName(code, name),
      title: hasAccess ? `${name} \n${milestoneName}` : noPermissionTooltip(name),
      link: hasAccess
        ? generateSharedPath(JoinRoutes.PROJECT, { projectId: projectID })
        : undefined,
      projectID,
    };
  });

// Items Ownership
export type ItemsOwnership =
  | Omit<GetItemsOwnershipQuery['itemsOwnership'][number], '__typename'>[]
  | null;

export const itemsOwnershipColorMap = new Map([
  [PermissionGroup.ADMIN, theme.palette.chartBlue],
  [PermissionGroup.DESIGNTEAM, theme.palette.chartMediumBlue],
  [PermissionGroup.GC, theme.palette.chartLightBlue],
  [PermissionGroup.OWNER, theme.palette.chartBlack],
  [PermissionGroup.SUB, theme.palette.chartLightGrey],
  [UNASSIGNED, theme.palette.chartBeige],
  [NA, theme.palette.chartGrey],
  [OTHER, theme.palette.chartGrey],
]);

export const customItemsOwnershipColors = [
  theme.palette.chartLightGreen,
  theme.palette.chartMediumGreen,
  theme.palette.chartPink,
  theme.palette.chartMagenta,
  theme.palette.chartDarkGreen,
];

export const groupItemsOwnership = (itemsOwnership: ItemsOwnership) => {
  if (itemsOwnership === null) return null;
  const includeOther = itemsOwnership.length > MAX_PIE_CHART_ELEMS;
  const topRoles = itemsOwnership.slice(0, MAX_PIE_CHART_ELEMS - 1);
  if (!includeOther) {
    return topRoles;
  }
  const otherCount = itemsOwnership
    .slice(MAX_PIE_CHART_ELEMS - 1)
    .reduce((accumulator, { itemCount }) => accumulator + itemCount, 0);
  const otherPercentage = itemsOwnership
    .slice(MAX_PIE_CHART_ELEMS - 1)
    .reduce((accumulator, { itemPercentage }) => accumulator + itemPercentage, 0);
  const otherAssigned: ItemsOwnership = [
    { assigneeRoleName: OTHER, itemCount: otherCount, itemPercentage: otherPercentage },
  ];
  return [...topRoles, ...otherAssigned];
};

export const getOtherItemsOwnershipData = (itemsOwnership: ItemsOwnership) => {
  if (itemsOwnership === null) return [];
  const includeOther = itemsOwnership.length > MAX_PIE_CHART_ELEMS;
  const filtered = itemsOwnership.filter((assigned) => assigned.assigneeRoleName !== UNASSIGNED);
  return includeOther ? filtered.slice(MAX_PIE_CHART_ELEMS - 1) : [];
};

export const formatItemsOwnershipPieChartData = (
  data: ItemsOwnership,
  otherData?: PieChartData[]
) => {
  if (!data) return [];
  return data.map((itemOwnership, i) => {
    const { itemCount, itemPercentage, assigneeRoleName } = itemOwnership;
    return {
      angle: itemCount,
      color:
        getColorMapItem(i, assigneeRoleName, itemsOwnershipColorMap, customItemsOwnershipColors) ||
        '',
      percentage: getPieChartSectionPercentage(itemPercentage),
      text: assigneeRoleName,
      otherData,
    };
  });
};

const formatItemsOwnershipLegendElementsData = (data: ItemsOwnership) => {
  if (!data) return [];
  return data.map((itemOwnership, i) => ({
    index: i,
    percentage: getPieChartSectionPercentage(itemOwnership.itemPercentage),
    text: itemOwnership.assigneeRoleName,
  }));
};

export const formatItemsOwnershipLegendData = (data: ItemsOwnership): LegendData => ({
  colorMap: itemsOwnershipColorMap,
  customColors: customItemsOwnershipColors,
  legendElements: formatItemsOwnershipLegendElementsData(data),
});

// Decision Summary

export const formatDecisionSummaryTableRow = (
  status: string,
  count: number,
  cost: number,
  iconClass: string
): TableRow => ({
  data: [
    count === 0 ? '-' : formatCommas(count),
    Number(cost) === 0 ? NA : formatCost(cost, { showCents: false, signed: true }),
  ],
  name: getItemStatusLabel(status),
  icon: (
    <div className={iconClass}>
      <ItemsIconsMap status={status} variant={SMALL} />
    </div>
  ),
  bold: false,
});

export const decisionSummaryTableHeaderData: TableHeader = {
  dataHeaders: [{ label: ITEM_STATUS, alignLeft: true }, { label: '#' }, { label: COST_CHANGE }],
};

// Item Due Dates
export type ProjectInfoBarChartData = {
  total: number;
  values: number[];
  isPlaceholder: boolean;
  placeholderText: string;
  project: ProjectInfo;
  activeMilestone?: string;
};

// chart colors
export const dueDateChartColors = [
  theme.palette.chartPink,
  theme.palette.chartBlue,
  theme.palette.chartLightGrey,
  theme.palette.chartGrey,
];

export const dueDateChartMaxItems = 16;
export const dueDateChartPlaceholderColor = theme.palette.chartMediumGrey;
const dueDateChartPlaceholderText = 'No Open Items';

export const itemsDueDateColorMap = new Map([
  [ItemDueDateTypes.PAST_DUE, theme.palette.chartPink],
  [ItemDueDateTypes.DUE_THIS_WEEK, theme.palette.chartBlue],
  [ItemDueDateTypes.UPCOMING, theme.palette.chartLightGrey],
  [ItemDueDateTypes.NO_DUE_DATE, theme.palette.chartGrey],
]);

export const getProjectDisplayName = (
  projectCode: string | null | undefined,
  projectName: string | null | undefined
) => {
  if (projectCode) return projectCode;
  if (!projectName) return '';
  if (projectName.length <= PROJECT_NAME_CHAR_LIMIT) return projectName;
  return projectName.slice(0, PROJECT_NAME_CHAR_LIMIT).trim().concat('...');
};

const emptyItemDueDateProject = {
  values: [1],
  total: 1,
  isPlaceholder: true,
  placeholderText: dueDateChartPlaceholderText,
  project: {
    abbreviation: '-',
    name: '-',
    id: '',
  },
} as ProjectInfoBarChartData;

// convert the item due date type to ProjectInfoBarChartData
const formatItemDueDateProject = (d: ItemDueDate, projectMap?: ProjectMap) => {
  const { dueThisWeekCount, noDueDateCount, openCount, pastDueCount, projectID, upcomingCount } = d;
  const { name = '', code, milestoneName } = projectMap?.get(projectID) || {};
  return {
    total: openCount,
    values: openCount !== 0 ? [pastDueCount, dueThisWeekCount, upcomingCount, noDueDateCount] : [1],
    // show the placeholder item bar if there are no due items
    isPlaceholder: openCount === 0,
    placeholderText: openCount === 0 ? dueDateChartPlaceholderText : '',
    activeMilestone: milestoneName,
    project: { id: projectID, name, abbreviation: name && getProjectDisplayName(code, name) },
  };
};

// format item due dates and ensure that are at least 16 entries
// if there are fewer than 16 then add some empty entries
export const formatDataForItemDueDate = (data: ItemDueDate[] | null, projectMap?: ProjectMap) => {
  let formattedData: ProjectInfoBarChartData[] = [];
  if (data) formattedData = data.map((d) => formatItemDueDateProject(d, projectMap));
  // add placeholders if there are not enough projects
  while (formattedData.length < dueDateChartMaxItems) {
    formattedData.push(emptyItemDueDateProject);
  }
  // if there were more than 16 projects from the input data only return 16
  return formattedData.slice(0, dueDateChartMaxItems);
};

export const sortDataForItemDueDateChart = (
  inputData: ItemDueDate[] | null,
  setData: (newData: ProjectInfoBarChartData[]) => void,
  toggleOption: ToggleOption,
  projectMap?: ProjectMap
) => {
  // shortcurcuit if data is null
  if (!inputData) {
    setData([]);
    return;
  }
  // inputData is immutable so we need to make a copy to sort it
  const data = [...inputData];
  if (toggleOption.key === ExecutiveDashboardOpenItemsDueSorting.MOST_OPEN) {
    data.sort((a, b) => (a.openCount > b.openCount ? -1 : 1));
  }
  if (toggleOption.key === ExecutiveDashboardOpenItemsDueSorting.MOST_DUE_THIS_WEEK) {
    data.sort((a, b) => (a.dueThisWeekCount > b.dueThisWeekCount ? -1 : 1));
  }
  if (toggleOption.key === ExecutiveDashboardOpenItemsDueSorting.MOST_OVERDUE) {
    data.sort((a, b) => (a.pastDueCount > b.pastDueCount ? -1 : 1));
  }
  setData(formatDataForItemDueDate(data, projectMap));
};

// Projects Overview

export type ProjectsOverview = Omit<
  GetProjectsOverviewQuery['projectsOverview']['projectStatusData'][number],
  '__typename'
>;

export type ChartType = typeof PROJECT_TYPE | typeof PROJECT_STATUS;
export type ChartView = typeof NUM_PROJECTS | typeof COST_PROJECTS;

export const projectsStatusOverviewColorMap = new Map<string, string>([
  [ProjectStatuses.IN_PURSUIT, theme.palette.chartBlue],
  [ProjectStatuses.ACTIVE, theme.palette.chartLightGreen],
  [ProjectStatuses.COMPLETED, theme.palette.chartBlack],
  [ProjectStatuses.ON_HOLD, theme.palette.chartMagenta],
  [ProjectStatuses.ABANDONED, theme.palette.chartDarkGrey],
  [ProjectStatuses.LOST, theme.palette.chartGrey],
  [ProjectStatuses.TEST_PROJECT, theme.palette.chartLightGrey],
  [NA, theme.palette.chartGrey],
]);

export const projectsTypeOverviewColorMap = new Map<string, string>([
  [ProjectTypes.RESIDENTIAL, theme.palette.chartPeach],
  [ProjectTypes.PUBLIC, theme.palette.chartMediumGreen],
  [ProjectTypes.EDUCATION, theme.palette.chartBlue],
  [ProjectTypes.HEALTHCARE, theme.palette.chartFuscia],
  [ProjectTypes.INFRASTRUCTURE, theme.palette.chartBrown],
  [ProjectTypes.MIXED_USE, theme.palette.chartLightBlue],
  [ProjectTypes.WASTEWATER, theme.palette.chartDarkGreen],
  [ProjectTypes.MULTIFAMILY, theme.palette.chartBlack],
  [ProjectTypes.COMMERCIAL, theme.palette.chartLightBlueGrey],
  [ProjectTypes.CORPORATE, theme.palette.chartGold],
  [UNASSIGNED, theme.palette.chartBeige],
  [NA, theme.palette.chartGrey],
  [OTHER_PROJECT_TYPES, theme.palette.chartGrey],
]);

export const customProjectsTypeOverviewColors = [
  theme.palette.chartRed,
  theme.palette.chartOrange,
  theme.palette.chartYellow,
  theme.palette.chartMediumGreen,
  theme.palette.chartRoyalBlue,
  theme.palette.chartMagenta,
];

export const sortOverviewData = (
  data: ProjectsOverview[] | null | undefined,
  chartView: ChartView
) => {
  if (!data) return null;
  const toSort = [...data];
  switch (chartView) {
    case NUM_PROJECTS:
      return toSort.sort((a, b) => (a.count > b.count ? -1 : 1));
    case COST_PROJECTS:
    default:
      return toSort.sort((a, b) => (Number(a.apv) > Number(b.apv) ? -1 : 1));
  }
};

export const getProjectsOverviewColorMap = (chartType: ChartType) =>
  chartType === PROJECT_TYPE ? projectsTypeOverviewColorMap : projectsStatusOverviewColorMap;

const getProjectsOverviewPercentageValue = (
  chartView: string,
  currentOverview: ProjectsOverview
): number =>
  chartView === COST_PROJECTS ? currentOverview.apvPercentage : currentOverview.countPercentage;

const groupProjectsOverview = (projectTypeData: ProjectsOverview[] | null) => {
  if (projectTypeData === null) return null;
  const includeOther = projectTypeData.length > MAX_PIE_CHART_ELEMS;
  const topTypes = projectTypeData.slice(0, MAX_PIE_CHART_ELEMS - 1);
  if (!includeOther) {
    return topTypes;
  }
  const otherCount = projectTypeData
    .slice(MAX_PIE_CHART_ELEMS - 1)
    .reduce((accumulator, { count }) => accumulator + count, 0);
  const otherCountPercentage = projectTypeData
    .slice(MAX_PIE_CHART_ELEMS - 1)
    .reduce((accumulator, { countPercentage }) => accumulator + countPercentage, 0);
  const otherAPV = projectTypeData
    .slice(MAX_PIE_CHART_ELEMS - 1)
    .reduce((accumulator, { apv }) => accumulator + Number(apv), 0);
  const otherAPVPercentage = projectTypeData
    .slice(MAX_PIE_CHART_ELEMS - 1)
    .reduce((accumulator, { apvPercentage }) => accumulator + Number(apvPercentage), 0);

  const otherTypes: ProjectsOverview[] = [
    {
      name: OTHER_PROJECT_TYPES,
      count: otherCount,
      countPercentage: otherCountPercentage,
      apv: otherAPV,
      apvPercentage: otherAPVPercentage,
    },
  ];
  return [...topTypes, ...otherTypes];
};

export const getOtherProjectsOverviewData = (projectTypeData: ProjectsOverview[] | null) => {
  if (projectTypeData === null) return [];
  const includeOther = projectTypeData.length > MAX_PIE_CHART_ELEMS;
  return includeOther ? projectTypeData.slice(MAX_PIE_CHART_ELEMS - 1) : [];
};

export const formatProjectsOverviewPieChartData = (
  data: ProjectsOverview[] | null | undefined,
  chartType: ChartType,
  chartView: ChartView,
  otherData?: PieChartData[]
) => {
  if (!data) return [];
  return data.map((elem, i) => {
    const { name } = elem;
    const percentValue = getProjectsOverviewPercentageValue(chartView, elem);
    return {
      angle: calculateProjectsOverviewRatio(chartView, elem),
      color:
        getColorMapItem(
          i,
          name,
          getProjectsOverviewColorMap(chartType),
          customProjectsTypeOverviewColors
        ) || '',
      percentage: getPieChartSectionPercentage(percentValue),
      text: name,
      otherData,
    };
  });
};

export const populatePieChartInputData = (
  data: ProjectsOverview[] | null,
  chartType: ChartType,
  chartView: ChartView
) => {
  let groupedData = data;
  let formattedOtherData: PieChartData[] | undefined;
  if (chartType === PROJECT_TYPE) {
    groupedData = groupProjectsOverview(data);
    const otherData = getOtherProjectsOverviewData(data);
    formattedOtherData = formatProjectsOverviewPieChartData(otherData, chartType, chartView);
  }

  return { groupedData, formattedOtherData };
};

export const calculateProjectsOverviewRatio = (
  chartView: string,
  currentOverview: ProjectsOverview
): number => (chartView === COST_PROJECTS ? currentOverview.apv / 100 : currentOverview.count);

const formatProjectsOverviewLegendElementsData = (
  data: ProjectsOverview[] | null | undefined,
  chartView: ChartView
) => {
  if (!data) return [];
  return data.map((projectOverview, i) => ({
    index: i,
    percentage: getPieChartSectionPercentage(
      getProjectsOverviewPercentageValue(chartView, projectOverview)
    ),
    text: projectOverview.name,
  }));
};

export const formatProjectsOverviewLegendData = (
  data: ProjectsOverview[] | null | undefined,
  chartType: ChartType,
  chartView: ChartView
): LegendData => ({
  colorMap: getProjectsOverviewColorMap(chartType),
  customColors: customProjectsTypeOverviewColors,
  legendElements: formatProjectsOverviewLegendElementsData(data, chartView),
});

// Budget Gaps
export const simpleCost = (v: number) => v / 100;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const getTopProjects = (projects: any[], index = MAX_SHOWN_PROJECTS - 1) =>
  projects.slice(0, index);

export const projectBudgetGapsColorMap = (t: TermStore) =>
  new Map<string, string>([
    [BUDGET_GAP, theme.palette.primaryGrey],
    [t.titleCase(TermKey.RUNNING_TOTAL), theme.palette.primaryGrey],
    [t.titleCase(TermKey.ESTIMATE), theme.palette.primaryGrey],
    [t.titleCase(TermKey.TARGET), theme.palette.budget],
  ]);

export const formatBudgetGapLegendInput = (
  text: string,
  colorMap: Map<string, string>,
  t: TermStore
) => {
  switch (text) {
    case BUDGET_GAP:
      return { component: <div>%</div> };
    case t.titleCase(TermKey.ESTIMATE):
      return { borderStyle: `2px dotted ${colorMap.get(text || '')}` };
    default:
      return { borderStyle: `2px solid ${colorMap.get(text || '')}` };
  }
};

export const formatBudgetGapsLegendElementsData = (data: string[], t: TermStore) => {
  const colorMap = projectBudgetGapsColorMap(t);
  return data.map((text) => {
    const { borderStyle, component } = formatBudgetGapLegendInput(text, colorMap, t);
    return { borderStyle, component, key: text, text };
  });
};

// Current Items Breakdown Chart

export const currentItemsBreakdownTableHeaderData: TableHeader = {
  dataHeaders: [
    { label: STATUS, alignLeft: true },
    { label: `%` },
    { label: NUM_ITEMS },
    { label: COST_IMPACT },
  ],
};

export const formatCurrentItemsBreakdownTableData = (total: StatusBreakdown): TableRow => ({
  data: [
    total.percent ? percentFormatterSimple.format(total.percent) : '-',
    total.itemCount ? total.itemCount.toString() : '-',
    total.cost && total.cost.toString() !== '0' ? formatCost(total.cost, { signed: true }) : NA,
  ],
  name: getItemStatusLabel(total.status),
  bold: false,
});

export const currentItemsBreakdownTableFooterData = (
  totalItemCount: number,
  totalCost: number
): string[] => [totalItemCount.toString(), formatCost(totalCost, { signed: true })];

// an alias to shorten the type name
export type ItemBreakdowns = ListCurrentItemsBreakdownQuery['currentItemsBreakdown'];
export type StatusBreakdown = ProjectTotal['statusBreakdown'][number];
export type ProjectTotal = ItemBreakdowns['projectTotals'][number];
export type MonthlyBreakdown = ProjectTotal['monthlyBreakdown'][number];

export const currentItemBreakdownChartSize = {
  height: CURRENT_ITEMS_BREAKDOWN_HEIGHT - 114,
  width: 653,
  padding: {
    // note padding is added to overall width unlike in a div
    top: 0,
    right: 8,
    bottom: 20,
    left: 13,
  },
  xAxisLayout: {
    padding: 4, // width alloted for the the x axis labels
    numberOfTicks: 0,
    showLabels: true,
  },
  yAxisLayout: {
    padding: 30, // width alloted for the the y axis labels
    numberOfTicks: 7,
    showLabels: true,
  },
};

export const currentItemBreakdownHintChartSize = {
  height: 180,
  width: CURRENT_ITEMS_BREAKDOWN_HINT_WIDTH - CHART_PADDING,
  padding: {
    // note padding is added to overall width unlike in a div
    top: 4,
    right: 4,
    bottom: 18,
    left: 4,
  },
  xAxisLayout: {
    padding: 3, // width alloted for the the x axis labels
    numberOfTicks: 12,
    showLabels: true,
  },
  yAxisLayout: {
    padding: 0, // width alloted for the the y axis labels
    numberOfTicks: 1,
    showLabels: false,
  },
};

export const getCurrentItemsBreakdownTooltipText = (
  project: { name: string; milestoneName: string },
  pcr: ProjectCostRange
) => {
  if (!project) {
    return noPermissionTooltip(pcr.project.name);
  }
  return pcr ? (
    <>
      <div>{pcr.project.name}</div>
      <div>{project.milestoneName}</div>
    </>
  ) : (
    ''
  );
};

// item status color map
// Please Note: the order here is important
export const itemStatusColorMap = new Map([
  [getItemStatusLabel(Status.ACCEPTED), theme.palette.accepted],
  [getItemStatusLabel(Status.INCORPORATED), theme.palette.incorporated],
  [getItemStatusLabel(Status.PENDING), theme.palette.pending],
  [getItemStatusLabel(Status.REJECTED), theme.palette.rejected],
  [getItemStatusLabel(Status.NOTAPPLICABLE), theme.palette.notapplicable],
]);

export const formatItemStatusStackMap = (colorMap: Map<string, string>): ChartStackMap[] =>
  Array.from(itemStatusColorMap.keys()).map((key) => ({
    stack: key,
    color: colorMap.get(key) || '',
  }));

// if there is no data we'll use this single project
// to define a cost range so we can show a few
// placeholder lines on the y-axis
const defaultProjectTotal: ProjectTotal = {
  __typename: 'ProjectItemTotalCostBreakdown',
  project: { __typename: 'ProjectChartInfo', id: '', name: '', code: '' },
  projectStatus: 'Active',
  statusDuration: 0,
  positiveCost: 210000000,
  negativeCost: -210000000,
  totalCost: 420000000,
  totalItemCount: 5,
  statusBreakdown: [],
  monthlyBreakdown: [],
};

export const calculateCurrentItemsBreakdown = (breakdowns: ItemBreakdowns | null) => {
  if (!breakdowns || breakdowns.itemBreakdownsByProject.length === 0)
    return { data: [], projectCostRanges: [defaultProjectTotal] };

  // convert the data to the correct type
  const chartdata =
    breakdowns && breakdowns.itemBreakdownsByProject
      ? breakdowns.itemBreakdownsByProject.map((b) => {
          const {
            project: { id, name, code },
          } = b;
          return {
            project: { name: getProjectDisplayName(code, name), id },
            stack: getItemStatusLabel(b.status),
            value: b.cost,
          } as VerticalBarGraphData;
        })
      : [];

  // We only want to show 10 projects
  // since a project can be listed several times in the item
  // breakdowns we need to find the last index of the 10th project
  // and filter out all other projects
  const lastProjectIndex =
    breakdowns && breakdowns.projectTotals.length > MAX_SHOWN_PROJECTS
      ? chartdata.length -
        1 -
        [...chartdata]
          .reverse()
          .findIndex(
            (d) => d.project.id === breakdowns.projectTotals[MAX_SHOWN_PROJECTS - 1].project.id
          )
      : chartdata.length;

  // only take the top 10 projects
  const data = getTopProjects(chartdata, lastProjectIndex);

  // get the top 10 projects from cost ranges
  const projectCostRanges =
    breakdowns && breakdowns.projectTotals
      ? getTopProjects(breakdowns.projectTotals, MAX_SHOWN_PROJECTS)
      : [];

  return { data, projectCostRanges };
};

export const calculateMonthlyItemsBreakdown = (breakdowns: MonthlyBreakdown[]) => {
  // convert the data to the correct type
  const data = [] as VerticalBarGraphData[];
  const projectCostRanges = [] as ProjectCostRange[];
  breakdowns.forEach((b: MonthlyBreakdown, i: number) => {
    const { positiveCost, negativeCost, totalCost } = b;
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const month = ((currentMonth + i) % 12) + 1;
    const year = i >= 12 - currentMonth ? currentYear : currentYear - 1;
    const project = {
      name: `${month}/${year % 100}`,
      id: `{b.month}${i}`,
    };
    projectCostRanges.push({ project, positiveCost, negativeCost, totalCost });

    // if there is no data for this month because the project wasn't yet created
    // add a zero valued entry
    if (b.itemBreakdown.length === 0) {
      data.push({ project: { ...project }, stack: '', value: 0 });
    }
    b.itemBreakdown.forEach((i) => {
      const { status, cost } = i;
      data.push({ project: { ...project }, stack: getItemStatusLabel(status), value: cost });
    });
  });

  return { data, projectCostRanges };
};

export const locationToGeoJSONFeature = (location: ProjectLocationData, maxAPV: number) =>
  ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [location.lon, location.lat],
    },
    properties: {
      location: location.location,
      projects: JSON.stringify(location.projects),
      apvFraction: Number(location.totalAPV) / maxAPV,
    },
  } as GeoJSON.Feature<GeoJSON.Point>);
