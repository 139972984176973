import { FC } from 'react';

interface DropdownRendererProps {
  onSelect: () => void;
  value: string;
  disabled: boolean;
  linesReadOnly: boolean;
}

const DropdownRenderer: FC<DropdownRendererProps> = ({
  value,
  disabled,
  onSelect,
  linesReadOnly,
}) => {
  const disabledMarkupStyle = disabled ? `join-grid-line-disabled` : '';
  return (
    <div className={`join-grid-renderer join-grid-string ${disabledMarkupStyle}`}>
      <div className="join-grid-trim-text">{value}</div>
      {linesReadOnly ? (
        <div />
      ) : (
        <div className="join-grid-dropdown-arrow" onClick={onSelect} onKeyDown={onSelect} />
      )}
    </div>
  );
};

export default DropdownRenderer;
