import { FC, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Typography } from '@material-ui/core';

import { costReportExpandCollapse } from '../../../analytics/analyticsEventProperties';
import { STATE_LIST_HOVER_SELECTOR_ARRAY, VIEW_OPTIONS } from '../../../constants';
import { MilestoneEstimateInfo } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import { SetSettingsFunctionType } from '../../../utilities/urlState';
import ChartsReportMilestone from '../../Charts/ChartsReport/ChartsReportMilestone';
import ChartsReportVariance from '../../Charts/ChartsReport/ChartsReportVariance';
import { FilterManager, getNumFilters } from '../../FilterPanel/filterUtils';
import { VarianceReportComments } from '../../ReportsTab/ReportHooks';
import {
  ColumnDescription,
  VarianceColumnDescription,
} from '../CostReportColumns/CostReportColumns';
import { NodeData } from '../CostReportList/CostReportCategoriesTree';
import CostReportList from '../CostReportList/CostReportList/CostReportList';

import styles from './CostReportBodyStyles';

type CostReportProps = {
  activeMilestoneID?: UUID;
  categorizations: Categorization[];
  displayGroupBy: DisplayGroupBy[];
  classes: Classes<typeof styles>;
  costReports: MilestoneCostReports | VarianceReports;
  reportComments?: VarianceReportComments;
  displayColumnDescriptions: ColumnDescription[];
  error?: ApolloError;
  filterManager: FilterManager;
  headerDescriptions: ColumnDescription[] | VarianceColumnDescription[];
  isVariance?: boolean;
  loading: boolean;
  settings: CostReportSettings;
  scroll?: boolean;
  setIsLoading?: (loading: boolean) => void;
  setNavigationData?: (itemNodes: NodeData[]) => void;
  setSettings: SetSettingsFunctionType;
  milestoneEstimates: MilestoneEstimateInfo[];
};

const CostReportBody: FC<CostReportProps> = ({
  activeMilestoneID,
  displayGroupBy,
  categorizations,
  classes,
  costReports,
  reportComments,
  displayColumnDescriptions,
  error,
  filterManager,
  headerDescriptions,
  isVariance = false,
  loading,
  settings,
  scroll,
  setIsLoading = () => {},
  setNavigationData = () => {},
  setSettings,
  milestoneEstimates,
}) => {
  // Read our project and milestone ids from the URL to populate queries
  const milestoneID = activeMilestoneID || getMilestoneIdFromUrl();
  const projectId = getProjectIdFromUrl();
  // Import Estimate
  const sendAnalytics = useSendAnalytics();

  const { clearFilters, filters: rawFilters, filterQueryInput: viewFilter } = filterManager;

  const { viewMode } = settings;
  const { status } = settings as MilestoneCostReportSettings;

  const varianceStatuses = STATE_LIST_HOVER_SELECTOR_ARRAY;
  const itemsStatuses = isVariance ? varianceStatuses : status;
  // Loading Logic
  const [listIsLoading, setListIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(loading || listIsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, listIsLoading]);

  // TODO: When might the chart flip to units? When the estimate columns is only $/UO

  if (milestoneID === null || error)
    return (
      <Typography className={classes.costReportChart}>
        Something went wrong. Please reload.
      </Typography>
    );

  const chart = () =>
    milestoneID &&
    projectId &&
    (isVariance ? (
      <ChartsReportVariance
        categorizations={categorizations}
        classes={{
          root: classes.costReportChart,
        }}
        clearFilters={clearFilters}
        costReports={costReports as VarianceReports}
        groupBy={displayGroupBy}
        headerDescriptions={headerDescriptions as VarianceColumnDescription[]}
        isFiltered={getNumFilters(rawFilters) > 0}
        loading={loading}
        projectId={projectId}
      />
    ) : (
      <ChartsReportMilestone
        categorizations={categorizations}
        classes={{
          root: classes.costReportChart,
        }}
        clearFilters={clearFilters}
        costReports={costReports as MilestoneCostReports}
        groupBy={displayGroupBy}
        isFiltered={getNumFilters(rawFilters) > 0}
        loading={loading}
        milestoneId={milestoneID}
        projectId={projectId}
      />
    ));

  const list = () => (
    <CostReportList
      costReports={costReports}
      displayColumnDescriptions={displayColumnDescriptions}
      groupBys={displayGroupBy}
      headerDescriptions={headerDescriptions}
      isVariance={isVariance}
      itemStatuses={itemsStatuses}
      loading={loading}
      milestoneEstimates={milestoneEstimates}
      milestoneID={milestoneID}
      reportAnalyticsExpandCollapse={(collapse: boolean) =>
        sendAnalytics(costReportExpandCollapse(collapse))
      }
      reportComments={reportComments}
      scroll={scroll}
      setIsLoading={setListIsLoading}
      setNavigationData={setNavigationData}
      setSettings={setSettings}
      settings={settings}
      viewFilter={viewFilter}
    />
  );
  const hasCategorizations = categorizations && categorizations.length > 0;
  const emptyMessage = !hasCategorizations
    ? 'Add categorizations to your project to group costs here.'
    : 'Group by at least one categorization to see a chart.';

  return (
    <>
      {viewMode !== VIEW_OPTIONS.LIST && (
        <>
          {displayGroupBy && displayGroupBy.length > 0 ? (
            chart()
          ) : (
            <div className={classes.costReportChart}>
              <Typography variant="subheading">{emptyMessage}</Typography>
            </div>
          )}
        </>
      )}
      {viewMode !== VIEW_OPTIONS.CHART && list()}
    </>
  );
};

export default withStyles(styles)(CostReportBody);
