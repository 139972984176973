import queryString from 'query-string';
import { FC, useMemo } from 'react';

import { ApolloError, useReactiveVar } from '@apollo/client';

import { currentUserReportVar } from '../../api/apollo/reactiveVars';
import { EstimateType } from '../../api/gqlEnums';
import { TYPE } from '../../constants';
import { NS_MSR_FOLLOWING_ACTIVE_MILESTONE } from '../../features';
import { useHasFeature } from '../../hooks/useHasFeature';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../utilities/url';
import { SetSettingsFunctionType } from '../../utilities/urlState';
import { FilterManager } from '../FilterPanel/filterUtils';
import { getNonZeroQuantities } from '../Inputs/UnitToggle/UnitToggleUtils';
import useMilestoneQuantitiesQuery, {
  emptyQuantities,
} from '../Milestone/hooks/useMilestoneQuantitiesQuery';
import { useLoadUserReportComments } from '../ReportsTab/ReportHooks';

import CostReportBody from './CostReportBody/CostReportBody';
import { ColumnDescription, getCostReportColumns } from './CostReportColumns/CostReportColumns';
import { NodeData } from './CostReportList/CostReportCategoriesTree';
import useMilestoneCostReportQuery from './hooks/useMilestoneCostReportQuery';

type MilestoneCostReportProps = {
  activeMilestoneID?: UUID;
  categorizations: Categorization[];
  displayGroupBy: DisplayGroupBy[];
  error?: ApolloError;
  filterManager: FilterManager;
  headerDescriptions?: ColumnDescription[];
  milestoneDescriptions: ColumnDescription[];
  settings: MilestoneCostReportSettings;
  scroll?: boolean;
  setIsLoading?: (loading: boolean) => void;
  setNavigationData?: (itemNodes: NodeData[]) => void;
  setSettings: SetSettingsFunctionType;
};

const MilestoneCostReport: FC<MilestoneCostReportProps> = ({
  activeMilestoneID,
  displayGroupBy,
  categorizations,
  error,
  filterManager,
  headerDescriptions,
  milestoneDescriptions,
  settings,
  scroll,
  setIsLoading = () => {},
  setNavigationData = () => {},
  setSettings,
}) => {
  const hasMSRFollowActiveMilestone = useHasFeature(NS_MSR_FOLLOWING_ACTIVE_MILESTONE);

  // Read our project and milestone ids from the URL to populate queries
  const { search } = window.location;
  const projectID = getProjectIdFromUrl();
  const milestoneID = activeMilestoneID || getMilestoneIdFromUrl();

  // Import Estimate
  const estimateType = queryString.parse(search, { arrayFormat: 'index' })[TYPE];

  const { filterQueryInput: viewFilter } = filterManager;

  // Quanitites - get'em
  const { data: { quantities = emptyQuantities } = {} } = useMilestoneQuantitiesQuery(milestoneID);
  const nonZeroQuantities = getNonZeroQuantities(quantities);
  // Columns for Report Query
  const costReportColumns = useMemo(
    () => getCostReportColumns(nonZeroQuantities, settings),
    [nonZeroQuantities, settings]
  );
  // Import Estimate
  const useDraftEstimate = estimateType === EstimateType.ACTIVE_ESTIMATE;
  const useDraftBudget = estimateType === EstimateType.BUDGET;

  // Here's where to extract columns
  const { data: { milestoneCostReports = null } = {}, loading: costReportsLoading } =
    useMilestoneCostReportQuery(
      milestoneID,
      displayGroupBy,
      costReportColumns,
      projectID,
      viewFilter,
      useDraftEstimate,
      useDraftBudget,
      true,
      false
    );

  const milestoneEstimates = hasMSRFollowActiveMilestone
    ? [
        {
          milestoneID,
          estimateType: EstimateType.ACTIVE_ESTIMATE,
        },
      ]
    : [];

  const currentReport = useReactiveVar(currentUserReportVar);
  const { data: commentData, loading: loadingComments } = useLoadUserReportComments(
    currentReport?.id,
    {
      groupBys: displayGroupBy.map((gb) => gb.id),
      milestoneEstimates,
    }
  );
  const comments = commentData?.varianceReportComments;

  return (
    <CostReportBody
      categorizations={categorizations}
      costReports={milestoneCostReports || []}
      displayColumnDescriptions={milestoneDescriptions}
      displayGroupBy={displayGroupBy}
      error={error}
      filterManager={filterManager}
      headerDescriptions={headerDescriptions || []}
      loading={costReportsLoading || loadingComments}
      milestoneEstimates={milestoneEstimates}
      reportComments={comments}
      scroll={scroll}
      setIsLoading={setIsLoading}
      setNavigationData={setNavigationData}
      setSettings={setSettings}
      settings={settings}
    />
  );
};

export default MilestoneCostReport;
