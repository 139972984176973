import { get } from 'lodash';
import { useMemo } from 'react';

import { UsersSortKey } from '../components/dragon-scales/ShareDialog/types';

// SEARCH
// All functionality related to SEARCHING the collaborators list
export function useSearchCollaborators<T extends Collaborator>(
  collaborators: T[],
  searchTerm: string
): T[] {
  return useMemo(() => {
    const isSearching = searchTerm.length > 0;
    if (isSearching) {
      const generateFilterText = (c: T) =>
        [
          c.user.name.toLocaleLowerCase(),
          c.user.email.toLocaleLowerCase(),
          (c.responsibility ?? '').toLocaleLowerCase(),
          c.role.name.toLocaleLowerCase(),
          c.trades.map((t) => t.name.toLocaleLowerCase()).join(' '),
          (get(c, UsersSortKey.COMPANY) ?? '').toLocaleLowerCase(),
        ].join(' ');

      const newCollaborators = isSearching
        ? collaborators
            .slice()
            .filter((c: T) => generateFilterText(c).includes(searchTerm.toLocaleLowerCase()))
        : collaborators;
      return newCollaborators;
    }
    return collaborators;
  }, [searchTerm, collaborators]);
}
