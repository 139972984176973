import { createStyles } from '@material-ui/core/styles';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const MilestonesDescriptionStyles = (theme: KomodoTheme) =>
  createStyles({
    outerContainer: {
      marginTop: `0px`,
      marginBottom: `${theme.spacing.generic[5]}px`,
    },
  });

export default MilestonesDescriptionStyles;
