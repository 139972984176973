import { FC, KeyboardEvent } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { pluralizeCountString } from '../../../utilities/string';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  ProjectSearchManager,
  projectFilterStateHasFilters,
} from '../ProjectsListUtils';

import styles from './ProjectsShownAndClearStyles';

type ProjectsShownAndClearProps = {
  classes: Classes<typeof styles>;
  projectsCounts: ProjectsCounts;
  projectsFilteredCounts: ProjectsCounts;
  includeCompanyProjects: boolean;
  filterManager: ProjectFilterManager;
  searchManager: ProjectSearchManager;
  setSettings: ProjectFilterSetSettings;
  showClearFilters?: boolean;
};

const ProjectsShownAndClear: FC<ProjectsShownAndClearProps> = ({
  classes,
  projectsCounts,
  projectsFilteredCounts,
  includeCompanyProjects,
  filterManager,
  searchManager,
  setSettings,
  showClearFilters = true,
}) => {
  // COUNTS
  const filteredProjectsCount = includeCompanyProjects
    ? projectsFilteredCounts.allProjectsCount
    : projectsFilteredCounts.myProjectsCount;
  const totalProjectsCount = includeCompanyProjects
    ? projectsCounts.allProjectsCount
    : projectsCounts.myProjectsCount;

  // FILTERS
  const { filterState } = filterManager;
  const { searchTerm } = searchManager;

  if (!totalProjectsCount) return null;

  return (
    <>
      <div
        className={`${classes.filterText} ${classes.showingFiltersText}`}
        data-cy="ProjectsList-Showing"
      >
        {`${filteredProjectsCount} of ${pluralizeCountString('project', totalProjectsCount)} shown`}
      </div>
      {showClearFilters && !!projectFilterStateHasFilters(filterState, searchTerm) && (
        <div
          className={`${classes.filterText} ${classes.clearFiltersText}`}
          onClick={() => {
            filterManager.clearFilters(setSettings);
            searchManager.clearSearch();
          }}
          onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
            if (event.key === 'Enter') {
              filterManager.clearFilters(setSettings);
              searchManager.clearSearch();
            }
          }}
          role="link"
          tabIndex={0}
        >
          clear filters
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(ProjectsShownAndClear);
