import { ReactNode } from 'react';

import { Status } from '../../../generated/graphql';
import Accepted from '../../Icons/Accepted';
import Incorporated from '../../Icons/Incorporated';
import NotApplicable from '../../Icons/NotApplicable';
import NotChosen from '../../Icons/NotChosen';
import Pending from '../../Icons/Pending';
import Rejected from '../../Icons/Rejected';

const iconSizeClassNames = {
  sm: 'icon-sm',
  md: 'icon-md',
  lg: 'icon-lg',
};

const iconColorClassNames: Record<Status, string> = {
  [Status.ACCEPTED]: 'text-item-status-accepted',
  [Status.INCORPORATED]: 'text-item-status-incorporated',
  [Status.NOTAPPLICABLE]: 'text-item-status-not-applicable',
  [Status.NOTCHOSEN]: 'text-item-status-not-chosen',
  [Status.PENDING]: 'text-item-status-pending',
  [Status.REJECTED]: 'text-item-status-rejected',
};

const icons: Record<Status, ReactNode> = {
  [Status.ACCEPTED]: <Accepted />,
  [Status.INCORPORATED]: <Incorporated />,
  [Status.NOTAPPLICABLE]: <NotApplicable />,
  [Status.NOTCHOSEN]: <NotChosen />,
  [Status.PENDING]: <Pending />,
  [Status.REJECTED]: <Rejected />,
};

type ItemStatusIconProps = {
  disabled?: boolean;
  /**
   * Sizes are sm=16px, md=24px, and lg=40px
   * @default md
   * */
  size?: 'sm' | 'md' | 'lg';
  value: Status;
};

const ItemStatusIcon = (props: ItemStatusIconProps) => {
  return (
    <div
      className={[
        'flex items-center',
        iconSizeClassNames[props.size ?? 'md'],
        props.disabled ? 'text-type-inactive' : iconColorClassNames[props.value],
      ].join(' ')}
    >
      {icons[props.value]}
    </div>
  );
};

export default ItemStatusIcon;
