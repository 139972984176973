import { FC, useContext } from 'react';

import { Typography } from '@material-ui/core';

import { TermKey } from '../../../api/gqlEnums';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';

const getHeader1 = (t: TermStore, isOwnerCost: boolean) => `This setting affects how ${
  isOwnerCost ? 'owner costs' : t.lowerCase(TermKey.MARKUP)
} in reports are displayed, only when the "Show costs" mode
in the left sidebar is set to "Markups Allocated".`;

const getHeader2 = (t: TermStore, isOwnerCost: boolean) => `the ${
  isOwnerCost ? 'owner' : t.lowerCase(TermKey.MARKUP)
} cost will be allocated across ${t.lowerCase(TermKey.DIRECT_COST)} set in the 'Applies
to' column`;

const getHeader3 = (t: TermStore, isOwnerCost: boolean) => `the ${
  isOwnerCost ? 'owner' : t.lowerCase(TermKey.MARKUP)
} cost will not be allocated across ${t.lowerCase(TermKey.DIRECT_COST)} and instead
      will be displayed as a separate ${
        isOwnerCost ? 'owner cost' : t.lowerCase(TermKey.MARKUP)
      }. The ${
  isOwnerCost ? 'owner cost' : t.lowerCase(TermKey.MARKUP)
} details (name, subtotal, etc.) will be
      visible to all users in reports`;

type Props = {
  isOwnerCost?: boolean;
};

const AllocateTooltip: FC<Props> = ({ isOwnerCost = false }) => {
  const termStore = useContext(ProjectTermStore);

  return (
    <div>
      <Typography>{getHeader1(termStore, isOwnerCost)}</Typography>
      <br />
      <Typography>
        <b>If selected:</b> {getHeader2(termStore, isOwnerCost)}
      </Typography>
      <br />
      <Typography>
        <b>If unselected:</b> {getHeader3(termStore, isOwnerCost)}
      </Typography>
    </div>
  );
};

export default AllocateTooltip;
