import { FC, useRef } from 'react';
import * as React from 'react';

import {
  addItemCategoryCellAnalytics,
  removeItemCategoryCellAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { InputVariant, SelectVariant } from '../../../api/gqlEnums';
import { Uncategorized } from '../../../constants';
import { categoryLabel } from '../../../utilities/string';
import SelectCategory from '../../Select/SelectCategory/SelectCategory';
import { uncategorizedOption } from '../../Select/SelectCategory/SelectCategoryUtils';
import useFocusHandler from '../hooks/useFocusHandler';
import { CELL_BORDER } from '../style/styleConstants';
import { EditorPosition, GridController, KeyBufferState, Position } from '../types';

interface CategoryEditorProps {
  gridType: { gridType: string; model: string };
  grid: GridController;
  defaultValue: GridCategoryCell;
  categorization?: Categorization;
  updateCell: (newValue: GridCategoryCellInputs) => void;
  position: EditorPosition;
  editingCell: Position;
  stopEditing: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  sendAnalytics: any;
}

const CategoryEditor: FC<CategoryEditorProps> = ({
  gridType,
  grid,
  editingCell,
  updateCell,
  categorization,
  defaultValue,
  stopEditing,
  position,
  sendAnalytics,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { category, search: defaultValueSearch } = defaultValue;
  const { name = '', number = '' } = category || {};
  const categoryString = (category && categoryLabel(name, number, categorization)) || '';
  const search = category ? categoryString : defaultValueSearch;
  const previousFocusedElement = document.activeElement;

  useFocusHandler(
    inputRef,
    previousFocusedElement,
    () => {
      grid.getKeyBufferString(editingCell);
      // eslint-disable-next-line no-param-reassign
      grid.isRenderingEditor = KeyBufferState.CLEAR;
    },
    () => {
      stopEditing();
    }
  );

  const onKeyDown = (event: React.KeyboardEvent) => {
    const stopEvent = () => {
      event.stopPropagation();
      event.preventDefault();
    };
    if (event.key === 'Escape') {
      stopEvent();
      stopEditing();
    }
    if (
      event.key === 'Enter' ||
      event.key === 'ArrowRight' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      event.key === 'Tab'
    ) {
      stopEvent();
    }
  };

  const editorPosition = position;
  // This is to get the category select's input to match up with our normal selected input.
  editorPosition.height -= CELL_BORDER * 2;
  editorPosition.width -= CELL_BORDER * 2;

  // bail if categorization isn't defined
  if (!categorization) {
    return null;
  }

  const updateCategories = (newCategories: Category[]) => {
    // "Uncategorized" categorization doesn't have a number
    // Uniformat "Introduction" categorization doesn't have a name
    // All other categorizations have both name and number
    const newCategory = newCategories[0];
    const isUncategorized = !newCategory.name && !newCategory.number;
    if (isUncategorized) {
      updateCell({
        search: '',
      });
    } else {
      // Some categories do not have a number but do have a name (ex: Uniformat Introduction)
      const searchValue = newCategory.number === '' ? newCategory.name : newCategory.number;
      updateCell({
        name: newCategory.name,
        number: newCategory.number,
        id: newCategory.id,
        search: searchValue,
      });
    }
    stopEditing();
  };

  const handleAnalytics = (newCategories: Category[]) => {
    const { estimateID } = grid;
    const { gridType: gridTyped, model } = gridType;
    const updatedCategory =
      category && category.id && category.name && category.number
        ? { ...category, level: 1 }
        : uncategorizedOption;
    // bail if there isn't a change in categories
    if (updatedCategory.id === newCategories[0].id || !estimateID) {
      return;
    }
    // otherwise, report what happened
    if (newCategories[0].id === uncategorizedOption.id) {
      const props: CategorySelectAnalytics = {
        id: estimateID,
        category: updatedCategory,
        itemType: model,
        categorization,
        location: gridTyped,
      };
      sendAnalytics(removeItemCategoryCellAnalytics(props));
    } else {
      const props: CategorySelectAnalytics = {
        id: estimateID,
        category: newCategories[0],
        itemType: model,
        categorization,
        location: gridTyped,
      };
      sendAnalytics(addItemCategoryCellAnalytics(props));
    }
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className="join-grid-category-cell-editor" onKeyDown={onKeyDown} style={editorPosition}>
      <SelectCategory
        addCategories={(newCategories: Category[]) => {
          updateCategories(newCategories);
          handleAnalytics(newCategories);
        }}
        categorizationID={categorization.id}
        includeUncategorizedCategory={false}
        inputValue={search}
        inputVariant={InputVariant.GRID}
        noun="category"
        onSubmit={stopEditing}
        placeholder={Uncategorized}
        removeCategories={(newCategories: Category[]) => {
          updateCategories(newCategories);
          handleAnalytics(newCategories);
        }}
        selectedCategories={[]}
        selectVariant={SelectVariant.SINGLE}
      />
    </div>
  );
};

export default CategoryEditor;
