import { ComponentProps } from 'react';

import { DD_NAV_SUPPORT_MENU_ITEMS } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { IconButton, ScrollContainer, Tooltip } from '../../scales';
import { CollapseSidebarIcon } from '../icons';
import SearchButton from '../SearchButton';
import { Link } from '../types';

import CompanySection from './CompanySection';
import useLinks from './hooks/useLinks';
import HubSpotChat from './HubSpotChat';
import JoinLogoNavLink from './JoinLogoNavLink';
import NavItem from './NavItem';
import NotificationsButton from './NotificationsButton';
import ProjectDetails from './ProjectDetails';
import SupportMenuButton from './SupportMenuButton';
import UserMenuButton from './UserMenuButton';

type Props = {
  company: ComponentProps<typeof CompanySection>['company'];
  lastViewedProjectIDs: UUID[];
  onCollapse: () => void;
  user: ComponentProps<typeof UserMenuButton>['user'];
};

export default function SidebarExpanded(props: Props) {
  const hasSupportMenuButton = useHasFeature(DD_NAV_SUPPORT_MENU_ITEMS);
  const lastViewedProjectID = props.lastViewedProjectIDs[0];

  const { companyLinks, projectLinks } = useLinks(lastViewedProjectID);

  return (
    <div className="flex h-full w-[250px] flex-col">
      <header className="flex items-center p-4">
        <JoinLogoNavLink size="lg" />
        <SearchButton />
        <NotificationsButton projectID={lastViewedProjectID} />
        <Tooltip content="Collapse sidebar">
          <IconButton
            aria-label="collapse sidebar"
            icon={<CollapseSidebarIcon />}
            onClick={props.onCollapse}
            type="secondary"
          />
        </Tooltip>
      </header>
      <ScrollContainer direction="vertical">
        <div className="flex flex-col gap-4">
          <ProjectSection lastViewedProjectID={lastViewedProjectID} links={projectLinks} />
          <CompanySection
            company={props.company}
            expanded
            lastViewedProjectIDs={props.lastViewedProjectIDs}
            links={companyLinks}
          />
        </div>
      </ScrollContainer>
      <footer className="mt-auto flex items-center justify-between border-t border-border-separator p-2">
        <UserMenuButton expanded user={props.user} />
        {hasSupportMenuButton ? (
          <SupportMenuButton position={{ left: 250 }} />
        ) : (
          <HubSpotChat position={{ left: 250 }} />
        )}
        {/* 250px is width of sidebar */}
      </footer>
    </div>
  );
}

type ProjectSectionProps = {
  lastViewedProjectID: UUID;
  links: Link[];
};
function ProjectSection(props: ProjectSectionProps) {
  return (
    <div className="flex flex-col gap-2" data-cy="project">
      <ProjectDetails projectID={props.lastViewedProjectID} />
      <div className="flex flex-col items-center" data-cy="project--links">
        {props.links.map((link) => (
          <NavItem key={link.name} icon={link.icon} label={link.name} path={link.path} />
        ))}
      </div>
    </div>
  );
}
