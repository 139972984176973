import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKeyPress } from 'react-use';

import { Link as LinkIcon } from '@material-ui/icons';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
  userReportEvent,
} from '../../../analytics/analyticsEventProperties';
import {
  projectCompsSetInputVar,
  projectCompsSettingsInputDefault,
} from '../../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { EDIT } from '../../../constants';
import { FORECASTED_COST_REPORT, NS_OWNER_COSTS, PROJECT_COMPS } from '../../../features';
import {
  ForecastingReportsSortKey,
  PermissionResource,
  SortDirection,
  UserReportType,
} from '../../../generated/graphql';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { hasModuleEntitlement } from '../../../hooks/useModuleEntitlementsQuery';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { FORECASTING } from '../../../moduleEntitlementFlagsList';
import { RouteKeys } from '../../../routes/paths';
import { makeDefaultLevelNames } from '../../../utilities/categorization';
import { getDefaultReportCategorizations } from '../../../utilities/categorization/categorizationUtils';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { generateSharedPath } from '../../../utilities/routes/links';
import { pluralizeCountString, pluralizeString } from '../../../utilities/string';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useCurrentUser } from '../../contexts/current-user';
import { useModuleEntitlements } from '../../contexts/module-entitlements';
import DialogsReportsData from '../../Dialogs/DialogsReports/DialogsReportsData';
import DialogsReportsDelete from '../../Dialogs/DialogsReportsDelete/DialogsReportsDelete';
import DialogsReportsEdit from '../../Dialogs/DialogsReportsEdit/DialogsReportsEdit';
import DialogsSelectionProjects from '../../Dialogs/DialogsSelectionProjects/DialogsSelectionProjects';
import { useDeleteForecastingReportMutation } from '../../ForecastingRoute/ForecastingReports/hooks/useDeleteForecastingReportMutation';
import { useProjectComparisonReportsQuery } from '../../ForecastingRoute/ForecastingReports/hooks/useProjectComparisonReportsQuery';
import { navigateToProjectComps } from '../../ProjectComps/ProjectCompsSetUtils';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import useMemoWrapper from '../../useMemoWrapper';
import { DefaultReports, MapUserReportTypeToConfig, reportForAnalytics } from '../config';
import DistributionDialogData from '../DistributionDialog/DistributionDialogData';
import DistributionHistoryDialog from '../DistributionDialog/DistributionHistoryDialog';
import { DistributionDialogsState } from '../DistributionDialog/DistributionUtils';
import { useDeleteUserReportMutation, useUserReportsAndProjectPropsQuery } from '../ReportHooks';
import { ReportsListEntryDataType } from '../ReportsList/ReportsList';
import { ReportsListEntrySettingsType } from '../ReportsListEntry/ReportsListEntrySettings/ReportsListEntrySettings';
import { groupUserReports } from '../utils';

import ReportsTab from './ReportsTab';
import {
  emptyUserReportInput,
  formatTimeForReportsTab,
  getExportPath,
  getPath,
  getPrintPath,
  getReportsDefaults,
} from './ReportsUtils';

const linkToast = 'Report link copied to clipboard.';

const ReportsTabData: FC = () => {
  const projectId = getProjectIdFromUrl();
  const navigate = useNavigate();
  const sendAnalytics = useSendAnalytics();
  const t = useContext(ProjectTermStore);

  const isCtrlPressed = useKeyPress(
    ({ key }: KeyboardEvent) => key === 'Control' || key === 'Meta'
  )[0];

  const [userReportInputToEdit, setUserReportInputToEdit] = useState<UserReportInput | undefined>(
    undefined
  );
  const [forecastingReportToEdit, setForecastingReportToEdit] = useState<
    ForecastingReport | undefined
  >(undefined);
  const [reportToDelete, setReportToDelete] = useState<UserReport | ForecastingReport | undefined>(
    undefined
  );

  const [distributionDialogsState, setDistributionDialogsState] =
    useState<DistributionDialogsState>({
      distributionDialogDataIsOpen: false,
      distributionHistoryDialogIsOpen: false,
      reportID: '',
      reportName: '',
      isEditingDistribution: false,
    });
  const [dialogsSelectionProjectsOpen, setDialogsSelectionProjectsOpen] = useState(false);
  const [hasDistribution, setHasDistribution] = useState(false);
  const [onDeleteForecastingReport] = useDeleteForecastingReportMutation();
  const [onDeleteUserReport] = useDeleteUserReportMutation();

  // DATA
  const {
    data: { userReports = [], project } = {},
    loading: userReportsLoading,
    refetch: refetchUserReports,
  } = useUserReportsAndProjectPropsQuery(projectId);
  const {
    data: { projectComparisonReports = [] } = {},
    loading: projectComparisonReportsLoading,
    refetch: refetchProjectComparisonReports,
  } = useProjectComparisonReportsQuery(
    {
      projectID: projectId,
      search: '',
      sortBy: {
        sortDirection: SortDirection.SORT_ASCENDING,
        sortKey: ForecastingReportsSortKey.UPDATED_AT,
      },
    },
    !projectId
  );

  const activeCollaboratorsData = useCollaboratorsQuery(projectId);

  const userReportFollowActiveMilestoneMap = new Map<string, boolean>();
  userReports.forEach((userReport) => {
    userReportFollowActiveMilestoneMap.set(
      userReport.id,
      userReport.isFollowingActiveMilestone ?? false
    );
  });

  // PERMISSIONS
  const { canView, canEdit, canDelete, inPreviewMode } = usePermissions();
  const canViewMarkups = canView(PermissionResource.MARKUPS);
  const canEditSharedReports = canEdit(PermissionResource.SHARED_USER_REPORTS);
  const canDeleteSharedReports = canDelete(PermissionResource.SHARED_USER_REPORTS);
  const canViewMilestoneCosts = canView(PermissionResource.MILESTONE_LINES);
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const hasProjectCompsPermission = canView(PermissionResource.PROJECT_COMPS_ACCESS);
  const hasReportDistributionPermission = canView(PermissionResource.REPORT_DISTRIBUTION);

  const moduleEntitlementFlags = useModuleEntitlements();
  const hasProjectCompsFeature =
    useHasFeature(PROJECT_COMPS) ||
    (hasProjectCompsPermission && hasModuleEntitlement(moduleEntitlementFlags, FORECASTING));
  const hasTrendingCostReportFeature = useHasFeature(FORECASTED_COST_REPORT);

  const projectName = project?.name ?? '';
  const activeMilestoneID = project?.activeMilestone.id ?? '';
  const activeMilestoneName = project?.activeMilestone.name ?? '';

  useEffect(() => {
    if (projectName) document.title = `${projectName} - Reports`;
  }, [projectName]);

  const userEmail = useCurrentUser().email;
  const [myUserReports, sharedUserReports] = groupUserReports(
    userReports,
    userEmail,
    inPreviewMode
  );

  const { data: catData, loading: catzLoading } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(catData);
  const defaultCategorizations = getDefaultReportCategorizations(categorizations);
  const defaultGroupBy = makeDefaultLevelNames(defaultCategorizations);
  const hasOwnerCost = useHasFeature(NS_OWNER_COSTS);

  const builtInUserReports = useMemoWrapper(
    DefaultReports,
    activeMilestoneName,
    t.titleCase,
    {
      showContingencyReport: canViewMarkups,
      showProjectCostBreakdownReport: hasOwnerCost,
      showMarkupsInTooltip: canViewMarkups,
    },
    defaultGroupBy
  );

  const computeReportSettings = (
    report: UserReport,
    milestoneID: UUID
  ): ReportsListEntrySettingsType => {
    // paths
    const exportPath = getExportPath(report, projectId, milestoneID, canViewMilestoneCosts);
    const path = getPath(report, projectId, milestoneID);
    const printPath = getPrintPath(report, projectId, milestoneID);
    // functions
    const onExcel =
      exportPath && shouldDisplayCosts
        ? () => {
            window.open(exportPath, '_blank');
            sendAnalytics(
              userReportEvent('userReportTab_xlsx', {
                report: reportForAnalytics(report),
              })
            );
          }
        : undefined;
    const onGo = path
      ? () => {
          if (isCtrlPressed) window.open(path, '_blank');
          else navigate(path);
          sendAnalytics(
            userReportEvent('userReportTab_go', {
              report: reportForAnalytics(report),
            })
          );
        }
      : undefined;
    const onLink = path
      ? () => {
          navigator.clipboard.writeText(`${window.location.origin}${path}`);
          setToast({ message: linkToast, icon: <LinkIcon /> });
          sendAnalytics(
            userReportEvent('userReportTab_copy', {
              report: reportForAnalytics(report),
            })
          );
        }
      : undefined;
    const onPrint = printPath
      ? () => {
          window.open(printPath, '_blank');
          sendAnalytics(
            userReportEvent('userReportTab_print', {
              report: reportForAnalytics(report),
            })
          );
        }
      : undefined;

    return {
      onExcel,
      onGo,
      onLink,
      onPrint,
    };
  };

  const filterUserReport = useCallback(
    (userReport: UserReport) =>
      shouldDisplayCosts ||
      [
        UserReportType.USER_REPORT_ITEMS_LIST,
        UserReportType.USER_REPORT_ITEMS_LIST_DETAILS,
      ].includes(userReport.reportType),
    [shouldDisplayCosts]
  );

  const computeUserReports = (
    userReports: UserReport[],
    milestoneID: UUID,
    filterFn: (userReport: UserReport) => boolean
  ): ReportsListEntryDataType[] =>
    userReports.filter(filterFn).map((userReport) => {
      const {
        reportType,
        name,
        description,
        owner,
        shared,
        updatedAt,
        hasDistributionScheduled,
        isFollowingActiveMilestone,
        id,
      } = userReport;
      const type = MapUserReportTypeToConfig[reportType]?.typeDisplayName ?? '';
      const canCreateDistribution =
        hasReportDistributionPermission &&
        shared &&
        (reportType === UserReportType.USER_REPORT_ITEMS_LIST ||
          reportType === UserReportType.USER_REPORT_ITEMS_LIST_DETAILS ||
          reportType === UserReportType.USER_REPORT_MSR ||
          reportType === UserReportType.USER_REPORT_VARIANCE);
      const onDelete =
        owner || (shared && canDeleteSharedReports)
          ? () => {
              setReportToDelete(userReport);
              sendAnalytics(
                userReportEvent('userReportTab_delete', {
                  report: reportForAnalytics(userReport),
                })
              );
            }
          : undefined;
      const onEdit =
        owner || (shared && canEditSharedReports)
          ? () => {
              let milestoneID = userReport.milestoneID ?? undefined;
              if (userReport.isFollowingActiveMilestone) milestoneID = undefined;
              setUserReportInputToEdit({
                ...userReport,
                milestoneID,
              });
              setHasDistribution(hasDistributionScheduled ?? true);
              sendAnalytics(
                userReportEvent('userReportTab_edit', {
                  report: reportForAnalytics(userReport),
                })
              );
            }
          : undefined;

      const onDistribute = canCreateDistribution
        ? () => {
            setDistributionDialogsState({
              ...distributionDialogsState,
              distributionDialogDataIsOpen: true,
              reportID: id,
              reportName: name,
              isEditingDistribution: hasDistributionScheduled ?? false,
            });
            sendAnalytics(
              userReportEvent('userReportTab_distributeNow', {
                report: reportForAnalytics(userReport),
              })
            );
          }
        : undefined;

      return {
        name,
        description,
        type,
        shared: shared ? 'Shared' : 'Private to me',
        owner: owner ? 'Me' : 'Team',
        last: formatTimeForReportsTab(updatedAt),
        settings: {
          ...computeReportSettings(userReport, milestoneID),
          onDelete,
          onEdit,
          onDistribute,
        },
        hasDistributionScheduled: hasDistributionScheduled ?? true,
        isFollowingActiveMilestone: isFollowingActiveMilestone ?? false,
      };
    });

  const computeForecastingReports = (
    forecastingReports: ForecastingReport[]
  ): ReportsListEntryDataType[] => {
    if (!shouldDisplayCosts) return [];
    return forecastingReports.map((forecastingReport) => {
      const { id, name, description, updatedAt } = forecastingReport;
      const printPath = generateSharedPath(RouteKeys.FORECASTING_PROJECT_COMPS_SAVED_PRINT, {
        reportID: id,
      });
      const path = generateSharedPath(RouteKeys.FORECASTING_PROJECT_COMPS_SAVED, {
        reportID: id,
      });
      return {
        name,
        description,
        type: 'Project Comparisons',
        shared: 'Private to me',
        owner: 'Me',
        last: formatTimeForReportsTab(updatedAt),
        settings: {
          onDelete: () => setReportToDelete(forecastingReport),
          onEdit: () => setForecastingReportToEdit(forecastingReport),
          onGo: () => {
            projectCompsSetInputVar(projectCompsSettingsInputDefault);
            if (isCtrlPressed) window.open(path, '_blank');
            else navigate(path);
          },
          onLink: () => {
            navigator.clipboard.writeText(`${window.location.origin}${path}`);
            setToast({ message: linkToast, icon: <LinkIcon /> });
          },
          onPrint: printPath
            ? () => {
                window.open(printPath, '_blank');
              }
            : undefined,
        },
      };
    });
  };

  const computeBuiltInReports = (
    builtInReports: UserReport[],
    milestoneID: UUID,
    filterFn: (userReport: UserReport) => boolean
  ): ReportsListEntryDataType[] =>
    builtInReports.filter(filterFn).map((builtInReports) => {
      const { reportType, name, description } = builtInReports;
      const type = MapUserReportTypeToConfig[reportType]?.typeDisplayName ?? '';
      return {
        name,
        description,
        type,
        settings: computeReportSettings(builtInReports, milestoneID),
        isBuiltIn: true,
      };
    });

  // Actions
  const onCloseCompsSelectDialog = () => {
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_SELECT_PROJECTS_MODAL_CANCEL)
    );
    setDialogsSelectionProjectsOpen(false);
  };

  const onDoneCompsSelectDialog = (selectedProjectIDs: UUID[]) => {
    setDialogsSelectionProjectsOpen(false);
    navigateToProjectComps(navigate, selectedProjectIDs, projectId);
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_SELECT_PROJECTS_MODAL_CTA, {
        selectedProjects: selectedProjectIDs,
        numberSelectedProjects: selectedProjectIDs.length,
      })
    );
  };

  const computeStartReports = (
    hasProjectCompsFeature: boolean,
    hasTrendingCostReportFeature: boolean,
    activeMilestoneID: UUID | undefined,
    canViewReportsWithCosts: boolean
  ) => {
    if (!canViewReportsWithCosts) return [];
    const reportsStartReport = [
      {
        name: MapUserReportTypeToConfig[UserReportType.USER_REPORT_VARIANCE]?.typeDisplayName ?? '',
        description: 'Compare costs between two milestones',
        type: MapUserReportTypeToConfig[UserReportType.USER_REPORT_VARIANCE]?.typeDisplayName ?? '',
        settings: {
          onStart: () =>
            navigate({
              pathname: generateSharedPath(JoinProjectRoutes.VARIANCE, { projectId }),
              hash: `#${EDIT}`,
            }),
        },
      },
    ];
    if (hasTrendingCostReportFeature) {
      const TrendingCostReport = {
        name: 'Trending Cost Report - Active Milestone',
        description: 'View Trending Cost',
        type: 'Trending Cost Report',
        settings: {
          onStart: () =>
            navigate({
              pathname: generateSharedPath(JoinProjectRoutes.TRENDING_COST_REPORT, {
                projectId,
                milestoneId: activeMilestoneID,
              }),
            }),
        },
      };
      reportsStartReport.push(TrendingCostReport);
    }
    if (hasProjectCompsFeature) {
      const compReport = {
        name: 'Project Comparisons',
        isBuiltIn: true,
        description: 'Compare costs across Projects',
        type: 'Project Comparisons',
        settings: {
          onStart: () => {
            projectCompsSetInputVar(projectCompsSettingsInputDefault);
            setDialogsSelectionProjectsOpen(true);
            sendAnalytics(
              projectCompsAnalyticsEvent(
                projectCompsEventTypes.PROJECT_COMPS_SELECT_PROJECTS_MODAL_VIEW,
                {
                  location: 'project reports',
                }
              )
            );
          },
        },
      };
      reportsStartReport.push(compReport);
    }
    return reportsStartReport;
  };

  const reportsMy = useMemoWrapper(
    computeUserReports,
    myUserReports,
    activeMilestoneID,
    filterUserReport
  );
  const reportsForecasting = useMemoWrapper(computeForecastingReports, projectComparisonReports);
  const reportsShared = useMemoWrapper(
    computeUserReports,
    sharedUserReports,
    activeMilestoneID,
    filterUserReport
  );
  const reportsBuiltIn = useMemoWrapper(
    computeBuiltInReports,
    builtInUserReports,
    activeMilestoneID,
    filterUserReport
  );
  const reportsStartReport = useMemoWrapper(
    computeStartReports,
    hasProjectCompsFeature,
    hasTrendingCostReportFeature,
    activeMilestoneID,
    shouldDisplayCosts
  );

  const accordionHeaders = [
    (count: number) =>
      `My ${pluralizeString('Report', count)} (${pluralizeCountString('Report', count)})`,
    (count: number) => `Shared With Me (${pluralizeCountString('Report', count)})`,
    (count: number) =>
      `Built-In ${pluralizeString('Report', count)} (${pluralizeCountString('Report', count)})`,
  ];
  const accordionReports = [
    [...reportsMy, ...reportsForecasting],
    [...reportsShared],
    [...reportsBuiltIn, ...reportsStartReport],
  ];
  const reportsDefaults = getReportsDefaults(
    Boolean(reportsMy.length) || Boolean(reportsForecasting.length),
    Boolean(reportsShared.length)
  );

  if (userReportsLoading || catzLoading || projectComparisonReportsLoading) return null;
  return (
    <>
      <ReportsTab
        accordionHeaders={accordionHeaders}
        accordionReports={accordionReports}
        reportsDefaults={reportsDefaults}
        setDistributionHistoryDialogIsOpen={() =>
          setDistributionDialogsState({
            ...distributionDialogsState,
            distributionHistoryDialogIsOpen: true,
          })
        }
      />
      <DialogsReportsData
        allowableReportTypes={
          userReportInputToEdit ? [userReportInputToEdit.reportType] : [UserReportType.NONE]
        }
        hasDistribution={hasDistribution}
        inputReport={userReportInputToEdit ?? emptyUserReportInput}
        isFollowingActiveMilestone={
          userReportFollowActiveMilestoneMap.get(userReportInputToEdit?.id ?? '') ?? false
        }
        onClose={() => setUserReportInputToEdit(undefined)}
        open={!!userReportInputToEdit}
        setInputReport={setUserReportInputToEdit}
        variant="reports-tab"
      />
      <DialogsReportsEdit
        onClose={() => setForecastingReportToEdit(undefined)}
        onSuccess={refetchProjectComparisonReports}
        open={!!forecastingReportToEdit}
        report={forecastingReportToEdit}
      />
      {reportToDelete && (
        <DialogsReportsDelete
          onClose={() => {
            refetchProjectComparisonReports();
            setReportToDelete(undefined);
          }}
          onDelete={'reportType' in reportToDelete ? onDeleteUserReport : onDeleteForecastingReport}
          open={!!reportToDelete}
          report={reportToDelete}
        />
      )}
      {dialogsSelectionProjectsOpen && (
        <DialogsSelectionProjects
          canViewCompanyProjects
          confirmButtonLabel={(count) =>
            `Create report with ${pluralizeCountString('project', count)}`
          }
          isOpen
          onClose={onCloseCompsSelectDialog}
          onDone={onDoneCompsSelectDialog}
          selectionMode="multiple"
          title="Create report"
        />
      )}
      {distributionDialogsState.distributionDialogDataIsOpen && (
        <DistributionDialogData
          distributionDialogsState={distributionDialogsState}
          onClose={() => {
            setDistributionDialogsState({
              ...distributionDialogsState,
              distributionDialogDataIsOpen: false,
            });
          }}
          projectID={projectId}
          refetchUserReports={refetchUserReports}
        />
      )}
      {distributionDialogsState.distributionHistoryDialogIsOpen && (
        <DistributionHistoryDialog
          activeCollaborators={activeCollaboratorsData.data?.collaborators ?? []}
          isOpen={distributionDialogsState.distributionHistoryDialogIsOpen}
          onClose={() =>
            setDistributionDialogsState({
              ...distributionDialogsState,
              distributionHistoryDialogIsOpen: false,
            })
          }
          projectID={projectId}
          setDistributionDialogsState={setDistributionDialogsState}
        />
      )}
    </>
  );
};

export default ReportsTabData;
