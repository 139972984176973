// Don't you wish JS Array.prototype.sort was stable already?
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const DEFAULT_COMP = (a: any, b: any) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default function stableSort(arr: any, cmp: any = DEFAULT_COMP, displayMilestone?: UUID) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const stabilizedArr = arr.map((el: any, index: number) => [el, index]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const stableCmp = (a: any, b: any) => {
    const order = cmp(a[0], b[0], displayMilestone);
    if (order !== 0) return order;
    return a[1] - b[1];
  };
  stabilizedArr.sort(stableCmp);
  for (let i = 0; i < stabilizedArr.length; i += 1) {
    [stabilizedArr[i]] = stabilizedArr[i];
  }
  return stabilizedArr;
}
