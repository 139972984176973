import { FC } from 'react';

import { Typography } from '@material-ui/core';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { LOCALSTORAGE_COMPANY_USERS_SHOW } from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { SortManager } from '../../scales';
import AddEmployee from '../AddEmployee/AddEmployee';
import CompanyTabRoleInfo from '../CompanyTabRoleInfo/CompanyTabRoleInfo';
import CompanyTabSearch from '../CompanyTabSearch';
import CompanyTabStyles from '../CompanyTabStyles';
import CompanyTabUsersFilterMenu from '../CompanyTabUsersFilterMenu';
import {
  CompanyHeadingsTabs,
  CompanyUserSearchManager,
  searchCompanyUsers,
} from '../CompanyTabUtils';
import CompanyTabHeader from '../Standards/CompanyTabHeader';

import CompanyMembersTable from './CompanyMembersTable';

type CompanyTabMembersProps = {
  classes: Classes<typeof CompanyTabStyles>;
  companyRoles: PartialRole[];
  company: Company;
  companyProjectRoles: CompanyProjectRoles[];
  companyUsers: CompanyUser[];
  loading: boolean;
  sortManager: SortManager;
  searchManager: CompanyUserSearchManager;
  countTotalMembers: number;
  filterStatus: string;
  setFilterStatus: (s: string) => void;
  userCounts: number[];
};

const CompanyTabMembers: FC<CompanyTabMembersProps> = ({
  classes,
  companyRoles,
  company,
  companyProjectRoles,
  companyUsers,
  loading,
  sortManager,
  countTotalMembers,
  filterStatus,
  setFilterStatus,
  userCounts,
  searchManager,
}) => {
  const { searchTerm } = searchManager;

  const searchedCompanyUsers = searchCompanyUsers(companyUsers, searchTerm);
  const countSearchedCompanyUsers = searchedCompanyUsers.length ?? 0;
  const sendAnalytics = useSendAnalytics();

  const searchStorage = LOCALSTORAGE_COMPANY_USERS_SHOW;

  return (
    <>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <CompanyTabHeader title={CompanyHeadingsTabs.MEMBERS} />
        <CompanyTabUsersFilterMenu
          filterStatus={filterStatus}
          searchStorage={searchStorage}
          setFilterStatus={setFilterStatus}
          userCounts={userCounts}
          view={CompanyAdminView.MEMBERS_LIST}
        />
        <div className={classes.tableSearchBar}>
          <CompanyTabSearch page={CompanyAdminView.MEMBERS_LIST} searchManager={searchManager} />
        </div>
        <Typography className={classes.memberCount}>
          {countSearchedCompanyUsers} of {countTotalMembers} total employees
        </Typography>
        <div className="flex pr-4">
          <CompanyTabRoleInfo
            onClick={() => {
              sendAnalytics(
                companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_COMPANY_ROLE_MODAL, {
                  view: CompanyAdminView.MEMBERS_LIST,
                })
              );
            }}
          />
          <AddEmployee
            company={company}
            companyProjectRoles={companyProjectRoles}
            companyRoles={companyRoles}
            companyUsers={companyUsers}
            onClick={() => {
              sendAnalytics(
                companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_NEW_CTA, {
                  view: CompanyAdminView.MEMBERS_LIST,
                })
              );
            }}
          />
        </div>
      </div>
      <CompanyMembersTable
        companyRoles={companyRoles}
        companyUsers={searchedCompanyUsers}
        loading={loading}
        searchTerm={searchTerm}
        sortManager={sortManager}
      />
    </>
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabMembers);
