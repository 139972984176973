import { FC, useState } from 'react';

import { Avatar } from '@material-ui/core';
import { Person } from '@material-ui/icons';

import {
  execDashboardEvent,
  execDashboardEventTypes,
} from '../../../analytics/analyticsEventProperties';
import { UserActivity } from '../../../api/gqlEnums';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getUserPicture } from '../../Collaborators/UserUtils';
import InputsToggle, { ToggleOption } from '../../Inputs/InputsToggle/InputsToggle';
import DataTable, { TableHeader, TableRow } from '../../Tables/DataTable/DataTable';
import {
  ActiveUser,
  MAX_ACTIVE_USERS_DISPLAYED,
  activityMap,
  formatActiveUsersTableData,
  formatActiveUsersTableHeaderData,
  makeActivityToggleOption,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardActiveUsersStyles from './ExecutiveDashboardActiveUsersStyles';

type ExecutiveDashboardActiveUserProps = {
  activeUsers: ActiveUser[];
  classes: Classes<typeof ExecutiveDashboardActiveUsersStyles>;
};

const ExecutiveDashboardActiveUsers: FC<ExecutiveDashboardActiveUserProps> = ({
  activeUsers = [],
  classes,
}) => {
  const [activity, setActivity] = useState<UserActivity>(UserActivity.ITEMS_CREATED);
  const sortedUsers: { [key: string]: ActiveUser[] } = {};
  activityMap.forEach((_, key: keyof ActiveUser) => {
    const filtered = activeUsers.filter((a: ActiveUser) => Number(a[key]) !== 0);
    sortedUsers[key] = Array(MAX_ACTIVE_USERS_DISPLAYED).fill(null);
    sortedUsers[key].splice(
      0,
      filtered.length,
      ...[...filtered]
        .sort((a: ActiveUser, b: ActiveUser) => Math.abs(Number(b[key])) - Math.abs(Number(a[key])))
        .slice(0, MAX_ACTIVE_USERS_DISPLAYED)
    );
  });

  const sendAnalytics = useSendAnalytics();

  const getAvatar = (user: ActiveUser) => (
    <>
      {user ? (
        <Avatar className={classes.picture} src={getUserPicture(user)} />
      ) : (
        <Avatar className={classes.picture}>
          <Person fontSize="inherit" />
        </Avatar>
      )}
    </>
  );

  const tableHeaderData: TableHeader = formatActiveUsersTableHeaderData(activity);

  const tableRowData: TableRow[] = sortedUsers[activity].map((user) =>
    formatActiveUsersTableData(user, activity, getAvatar)
  );

  return (
    <div className={classes.flexContainer}>
      <div className={classes.toggleContainer}>
        <InputsToggle
          isWide
          options={Array.from(activityMap.keys()).map(makeActivityToggleOption)}
          selected={[makeActivityToggleOption(activity)]}
          setSelected={(toggleOption: ToggleOption) => {
            sendAnalytics(
              execDashboardEvent(execDashboardEventTypes.EXEC_DASHBOARD_CHART_VIEW, {
                chart: {
                  toggleOption: toggleOption.text,
                },
              })
            );
            setActivity(toggleOption.key as UserActivity);
          }}
        />
      </div>
      <DataTable tableHeaderData={tableHeaderData} tableRowData={tableRowData} />
    </div>
  );
};

export default withStyles(ExecutiveDashboardActiveUsersStyles)(ExecutiveDashboardActiveUsers);
