import './TipTapTextArea.scss';

import { FC } from 'react';

import { Slice } from '@tiptap/pm/model';
import { EditorProps, EditorView } from '@tiptap/pm/view';
import { EditorContent, useEditor } from '@tiptap/react';

import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { useUploadImage } from '../../../assets/hooks/AssetsMutationHook';
import MenuBar from '../MenuBar/MenuBar';

import { addImage } from './TipTapTextAreaImageUtils';
import {
  ImageAction,
  getEditorContent,
  getExtensions,
  getTextStylingImageAnalyticsEvent,
  useHandleDOMEvents,
} from './TipTapTextAreaUtils';

export enum TipTapTextAreaVariant {
  DEFAULT,
  DESCRIPTION,
}

type TipTapTextAreaProps = {
  dataCy?: string;
  editable: boolean;
  flexGrow?: boolean;
  hideBorder?: boolean;
  hasMinHeight: boolean;
  onAttachAsset?: (asset: Asset) => void;
  onChangeComplete?: (value: string, valueStyled: string) => void;
  placeholder: string;
  textStyled: string;
  variant?: TipTapTextAreaVariant;
};

const TipTapTextArea: FC<TipTapTextAreaProps> = ({
  dataCy,
  editable,
  flexGrow,
  hideBorder = false,
  hasMinHeight,
  onAttachAsset,
  onChangeComplete,
  placeholder,
  textStyled,
  variant = TipTapTextAreaVariant.DEFAULT,
}) => {
  const sendAnalytics = useSendAnalytics();
  const uploadImage = useUploadImage();
  const isUploadImageFeature = !!onAttachAsset;

  const handlePaste = (view: EditorView, event: ClipboardEvent): boolean => {
    const file = (event.clipboardData?.files || [])[0];
    if (!file) return false; // not handled use default behaviour
    const position = view.state.selection.$anchor.pos || 0;
    const { left: x, top: y } = view.coordsAtPos(position);
    const onSuccess = (asset: Asset) => {
      if (onAttachAsset) onAttachAsset(asset);
      sendAnalytics(getTextStylingImageAnalyticsEvent(ImageAction.PASTE));
    };
    return addImage(view, file, x, y, uploadImage, onSuccess);
  };

  const handleDrop = (view: EditorView, event: DragEvent, _: Slice, moved: boolean): boolean => {
    const file = (event?.dataTransfer?.files || [])[0];
    if (moved || !file) return false; // not handled use default behaviour
    const { clientX: x, clientY: y } = event || {};
    const onSuccess = (asset: Asset) => {
      if (onAttachAsset) onAttachAsset(asset);
      sendAnalytics(getTextStylingImageAnalyticsEvent(ImageAction.DROP));
    };
    return addImage(view, file, x, y, uploadImage, onSuccess);
  };

  const handleDOMEvents = useHandleDOMEvents();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  let editorProps: EditorProps<any> = {
    handleDOMEvents,
  };

  if (isUploadImageFeature) {
    editorProps = {
      ...editorProps,
      handleDrop,
      handlePaste,
    };
  }

  const editor = useEditor({
    editable,
    editorProps,
    extensions: getExtensions(placeholder, isUploadImageFeature),
    content: getEditorContent(textStyled),
    onBlur: ({ editor }) => {
      if (onChangeComplete) onChangeComplete(editor.getText(), editor.getHTML());
    },
  });

  const isHistory = variant === TipTapTextAreaVariant.DESCRIPTION;
  const editorClasses = `editor${isHistory ? ' editor_history' : ''} mb-2 ${
    flexGrow ? 'flex-grow' : ''
  } ${hideBorder ? 'border-0' : ''}
  ${!hasMinHeight ? 'min-h-0' : ''}`;
  const contentClasses = `editor__content${isHistory ? ' editor_content_history' : ''}`;

  return (
    <div className={editorClasses}>
      {editable && editor && <MenuBar editor={editor} />}
      <EditorContent
        className={contentClasses}
        data-cy={dataCy}
        editor={editor}
        onClick={() => editor?.commands.focus()}
      />
    </div>
  );
};

export default TipTapTextArea;
