import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { JoinCompanyRoutes } from '../../../api/gqlEnums';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { isNonNullable } from '../../../utilities/types';
import UserAvatar from '../../Collaborators/UserAvatar';
import { SortManager, Table } from '../../scales';
import SearchText from '../../Search/SearchText/SearchText';
import CompanyTabRoleSelector from '../CompanyTabRoleSelector/CompanyTabRoleSelector';
import CompanyTabStyles from '../CompanyTabStyles';
import {
  companyMembersGridWidth,
  getIsDeactivated,
  getStatusColor,
  gridRowHeight,
  membersHeaderContent,
} from '../CompanyTabUtils';

type CompanyMembersTableProps = {
  classes: Classes<typeof CompanyTabStyles>;
  companyRoles: PartialRole[];
  companyUsers: CompanyUser[];
  sortManager: SortManager;
  searchTerm: string;
  loading: boolean;
};

const CompanyMembersTable: FC<CompanyMembersTableProps> = ({
  classes,
  companyUsers,
  sortManager,
  searchTerm,
  companyRoles,
  loading,
}) => {
  const sendAnalytics = useSendAnalytics();
  const entries = useMemo(
    () =>
      companyUsers
        .map(({ user, company, role }) => {
          if (user) {
            const thumbnail = (
              <div className={classes.avatarTable}>
                <UserAvatar
                  assignee={user}
                  deactivated={getIsDeactivated(user)}
                  imgSettings={{ loading: 'lazy' }}
                />
              </div>
            );
            const path = generateSharedPath(JoinCompanyRoutes.COMPANY_MEMBERS_PROFILE, {
              userId: user.id,
            });
            const link = (
              <Link
                className={`${classes.linkTitle} ${classes.textOverflow}`}
                onClick={() => {
                  sendAnalytics(
                    companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_VIEW_CLICK, {
                      view: CompanyAdminView.MEMBERS_LIST,
                    })
                  );
                }}
                to={path}
              >
                <SearchText searchTerm={searchTerm} text={user.name} />
              </Link>
            );
            const status = (
              <>
                <div style={{ color: getStatusColor(user.status) }}>{'\u2B24'}&nbsp;</div>
                <div className={classes.text}>{user.status} </div>
              </>
            );

            const jobTitle = (
              <div className={classes.text}>
                <SearchText searchTerm={searchTerm} text={user.jobTitle || ' '} />
              </div>
            );

            const email = (
              <div className={classes.text}>
                <SearchText searchTerm={searchTerm} text={user.email} />
              </div>
            );

            const latestActivity = (
              <div className={classes.text}>
                {user.activeAt ? new Date(user.activeAt).toLocaleDateString() : ''}
              </div>
            );
            return [
              { component: thumbnail, key: user.id },
              { component: link, key: user.id },
              { component: jobTitle, key: user.id },
              { component: email, key: user.email },
              {
                component: (
                  <div data-cy="company-role-select" style={{ width: '100%' }}>
                    <CompanyTabRoleSelector
                      key={role?.id}
                      analyticsView={CompanyAdminView.MEMBERS_LIST}
                      companyID={company?.id}
                      currentRole={role?.id}
                      roles={companyRoles}
                      userID={user.id}
                    />
                  </div>
                ),
                key: user.id,
              },
              { component: status, key: user.id },
              { component: latestActivity, key: user.id },
            ];
          }
          return undefined;
        })
        .filter(isNonNullable),
    [classes, companyRoles, companyUsers, searchTerm, sendAnalytics]
  );

  return (
    <Table
      columnWidths={companyMembersGridWidth}
      entries={entries}
      headerContent={membersHeaderContent}
      loading={loading}
      rowHeight={gridRowHeight}
      sortManager={sortManager}
    />
  );
};

export default withStyles(CompanyTabStyles)(CompanyMembersTable);
