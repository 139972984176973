import { FC, memo, useMemo } from 'react';

import { ProjectCompSectionType } from '../../../api/gqlEnums';
import {
  PcMarkup,
  ProjectCompsCostTableCategoryLine,
  ProjectCompsCostTableMarkupLine,
  ProjectCompsCostTableSummaryLine,
} from '../../../generated/graphql';
import {
  PROJECT_COMP_MARKUPS_PERCENT_FIELD,
  PROJECT_COMP_MARKUPS_QUANTITY_TOTAL_FIELD,
  PROJECT_COMP_MARKUPS_TOTAL_FIELD,
  PROJECT_COMP_PERCENT_FIELD,
  PROJECT_COMP_QUANTITY_TOTAL_FIELD,
  PROJECT_COMP_SUBTOTAL,
  PROJECT_COMP_SUBTOTAL_PERCENT_FIELD,
  PROJECT_COMP_SUBTOTAL_QUANTITY_TOTAL_FIELD,
  PROJECT_COMP_SUBTOTAL_TOTAL_FIELD,
  PROJECT_COMP_TOTAL_FIELD,
} from '../../../tagConstants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { ProjectCompSectionVariant } from '../constants/projectCompTypes';
import { LineInputUpdateFunctions } from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import { useIsProjectCompSectionCollapsed } from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';

import ProjectCompsCostTableHeader from './ProjectCompsCostTableHeader';
import ProjectCompsCostTableRow from './ProjectCompsCostTableRow';
import ProjectCompsCostTableSummaryRow from './ProjectCompsCostTableSummaryRow';

type CommonCostTableProps = {
  categories: ProjectCompsSet['categories'];
  classes: Classes<typeof styles>;
  costTableColumnInputs: CostTableColumnInputs;
  hasMarkups: boolean;
  lineInputUpdateFunctions: LineInputUpdateFunctions;
  markups: PcMarkup[];
  projectCompsCostTable: ProjectCompsCostTable;
  selectedUnits?: Unit[];
  showMinMax: boolean;
  variant: ProjectCompSectionVariant;
};

const ProjectCompsCostTable: FC<CommonCostTableProps> = ({
  categories,
  classes,
  costTableColumnInputs,
  hasMarkups,
  lineInputUpdateFunctions,
  markups,
  projectCompsCostTable,
  selectedUnits,
  showMinMax,
  variant,
}) => {
  const { categoryLines, markupLines, summaryLines } = projectCompsCostTable ?? {};
  const { subtotalLine, markupsTotalLine, totalLine } = summaryLines ?? {};
  // HOOKS
  const isMarkupsCollapsed = useIsProjectCompSectionCollapsed(
    ProjectCompSectionType.SUBSECTION_MARKUPS
  );

  // CATEGORY COST ROWS
  const isAverage = variant === ProjectCompSectionVariant.AVERAGE_COMP;

  const categoryValuesMap = useMemo(() => {
    const map = new Map<string, ProjectCompsCostTableCategoryLine>();
    if (categoryLines) {
      categoryLines.forEach((categoryLine) => {
        map.set(categoryLine.category.number.toLowerCase(), categoryLine);
      });
    }
    return map;
  }, [categoryLines]);

  const categoryCostRows = categories.map((category) => {
    const { description, columnValues } =
      categoryValuesMap.get(category.number.toLowerCase()) ?? {};
    const key = `${category.number}-${category.name}`;
    const updateCategoryLineInput =
      lineInputUpdateFunctions.getCategoryLineInputUpdateFunction(category);

    return (
      <ProjectCompsCostTableRow
        key={key}
        columnValues={columnValues}
        costTableColumnInputs={costTableColumnInputs}
        description={description ?? ''}
        id={key}
        isAverage={isAverage}
        selectedUnits={selectedUnits}
        showMinMax={showMinMax}
        updateLineInput={updateCategoryLineInput}
      />
    );
  });

  // MARKUP COST ROWS
  const markupValuesMap = useMemo(() => {
    const map = new Map<string, ProjectCompsCostTableMarkupLine>();
    if (markupLines) {
      markupLines.forEach((markupLine) => {
        map.set(markupLine.markupName, markupLine);
      });
    }
    return map;
  }, [markupLines]);

  const markupCostRows = markups.map((markup) => {
    const { description, columnValues } = markupValuesMap.get(markup.name) ?? {};
    const updateMarkupLineInput = lineInputUpdateFunctions.getMarkupLineInputUpdateFunction(
      markup.name
    );
    return (
      <ProjectCompsCostTableRow
        key={markup.name}
        columnValues={columnValues}
        costTableColumnInputs={costTableColumnInputs}
        description={description ?? ''}
        id={markup.name}
        isAverage={isAverage}
        selectedUnits={selectedUnits}
        showMinMax={showMinMax}
        updateLineInput={updateMarkupLineInput}
      />
    );
  });

  // SUMMARY COST ROWS
  const getProjectCompsCostTableSummaryRow = (
    summaryLine: ProjectCompsCostTableSummaryLine,
    args: {
      cyQuantityTotalID: string;
      cyTotalID: string;
      cyPercentID: string;
      hasBorder?: boolean; // displays black border above row
      shouldObserve?: boolean; // indicates row is being used to trigger sticky header/footer
    }
  ) => {
    const { cyPercentID, cyQuantityTotalID, cyTotalID, hasBorder, shouldObserve } = args;
    return (
      <ProjectCompsCostTableSummaryRow
        columnValues={summaryLine.columnValues}
        costTableColumnInputs={costTableColumnInputs}
        cyPercentId={cyPercentID}
        cyQuantityTotalId={cyQuantityTotalID}
        cyTotalId={cyTotalID}
        hasBorder={hasBorder}
        shouldObserve={shouldObserve}
        showMinMax={showMinMax}
      />
    );
  };
  const subtotalCostRow =
    hasMarkups &&
    subtotalLine &&
    getProjectCompsCostTableSummaryRow(subtotalLine, {
      cyPercentID: PROJECT_COMP_SUBTOTAL_PERCENT_FIELD,
      cyQuantityTotalID: PROJECT_COMP_SUBTOTAL_QUANTITY_TOTAL_FIELD,
      cyTotalID: PROJECT_COMP_SUBTOTAL_TOTAL_FIELD,
      hasBorder: true,
    });
  const markupsCostRow =
    hasMarkups &&
    markupsTotalLine &&
    getProjectCompsCostTableSummaryRow(markupsTotalLine, {
      cyQuantityTotalID: PROJECT_COMP_MARKUPS_QUANTITY_TOTAL_FIELD,
      cyTotalID: PROJECT_COMP_MARKUPS_TOTAL_FIELD,
      cyPercentID: PROJECT_COMP_MARKUPS_PERCENT_FIELD,
      hasBorder: true,
    });
  const totalCostRow = getProjectCompsCostTableSummaryRow(totalLine, {
    cyPercentID: PROJECT_COMP_PERCENT_FIELD,
    cyQuantityTotalID: PROJECT_COMP_QUANTITY_TOTAL_FIELD,
    cyTotalID: PROJECT_COMP_TOTAL_FIELD,
    hasBorder: true,
    shouldObserve: true,
  });

  return (
    <div className={classes.fieldsGroup} data-cy={PROJECT_COMP_SUBTOTAL}>
      <div
        className={
          variant === ProjectCompSectionVariant.AVERAGE_COMP
            ? classes.fieldsAverageCompCosts
            : classes.fieldsProjectCompCosts
        }
      >
        <ProjectCompsCostTableHeader
          costTableColumnInputs={costTableColumnInputs}
          isAverage={isAverage}
          selectedUnits={selectedUnits}
          shouldObserve
        />
        {categoryCostRows}
        {subtotalCostRow}
        {markupsCostRow}
        {hasMarkups && !isMarkupsCollapsed && markupCostRows}
        {totalCostRow}
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(ProjectCompsCostTable));
