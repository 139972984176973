import { FC } from 'react';

import { Checkbox, MenuItem, Typography } from '@material-ui/core';

import { NULL_ID } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { categoryLabel } from '../../../utilities/string';

import InputsSelectReferenceCategoryMenuItemStyles from './InputsSelectReferenceCategoryMenuItemStyles';

type CategoryMenuItemProps = {
  classes: Classes<typeof InputsSelectReferenceCategoryMenuItemStyles>;
  category: Category;
  checked: boolean;
  onClick: () => void;
};

const CategoryMenuItem: FC<CategoryMenuItemProps> = ({ classes, category, checked, onClick }) => {
  const isUncategorized = (categoryID: UUID) => categoryID === NULL_ID;
  return (
    <MenuItem
      key={`${category.id}:${category.categorization && category.categorization.id}`}
      className={classes.choice}
      data-cy={category.name}
      onClick={onClick}
    >
      <div className={classes.choiceContainer}>
        <Checkbox checked={checked} />
        <Typography
          className={
            isUncategorized(category.id) ? classes.uncategorizedLabel : classes.categoryLabel
          }
          noWrap
        >
          {isUncategorized(category.id)
            ? 'Uncategorized'
            : categoryLabel(category.name, category.number, category.categorization)}
        </Typography>
      </div>
    </MenuItem>
  );
};

export default withStyles(InputsSelectReferenceCategoryMenuItemStyles)(CategoryMenuItem);
