import { FC, MouseEvent, ReactNode } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { ItemShareKey } from '../../../analytics/analyticsEventProperties';
import { withStyles } from '../../../theme/komodo-mui-theme';
import ItemsListItemPrivateBadge from '../../Items/ItemsListItem/ItemsListItemPrivateBadge/ItemsListItemPrivateBadge';

import EntryLinkStyles from './EntryLinkStyles';
import { useShowTextWithClamp } from './EntryLinkUtils';

type EntryLinkProps = {
  classes: Classes<typeof EntryLinkStyles>;
  ariaLabel: string;
  clickHandler: (e: MouseEvent, navigate: NavigateFunction) => void;
  dataCy: string;
  dataCyDisplayName: string;
  disableClick?: boolean;
  displayData?: ReactNode;
  isPrivate: boolean;
  setIsInitialTextClamped?: (clamped: boolean) => void;
  showTextAsClamped?: boolean;
  styleClass?: string;
  title: string;
  sharedPrivateItemUsers?: Pick<User, 'id' | 'name'>[];
};

const EntryLink: FC<EntryLinkProps> = ({
  ariaLabel,
  classes,
  clickHandler,
  dataCy,
  dataCyDisplayName,
  displayData,
  disableClick = false,
  isPrivate,
  setIsInitialTextClamped,
  showTextAsClamped = false,
  styleClass,
  title,
  sharedPrivateItemUsers,
}) => {
  const navigate = useNavigate();
  const { ref } = useShowTextWithClamp(setIsInitialTextClamped);
  return (
    <div>
      <div className={classes.linkContainer}>
        {isPrivate && (
          <ItemsListItemPrivateBadge
            analyticsKey={ItemShareKey.ITEMS}
            hideBadge={false}
            sharedPrivateItemUsers={sharedPrivateItemUsers}
          />
        )}
        <button
          aria-label={ariaLabel}
          className={`${disableClick ? classes.linkTitleDisabled : classes.linkTitle}`}
          data-cy={dataCy}
          onClick={disableClick ? undefined : (e) => clickHandler(e, navigate)}
          onMouseDown={disableClick ? undefined : (e) => clickHandler(e, navigate)}
          type="button"
        >
          <div
            ref={ref}
            className={`${classes.linkText} ${styleClass} ${
              showTextAsClamped ? classes.linkTextClamp : ''
            }`}
            data-cy={dataCyDisplayName}
            title={title}
          >
            {displayData || title}
          </div>
        </button>
      </div>
    </div>
  );
};

export default withStyles(EntryLinkStyles)(EntryLink);
