import { FC } from 'react';

import KeyboardOutlined from '@material-ui/icons/KeyboardOutlined';

import { EstimateTotalType } from '../../generated/graphql';
import NormalTooltip from '../NormalTooltip/NormalTooltip';

// eslint-disable-next-line import/no-cycle
import HeaderCells from './HeaderCells';
import GridRowCheckbox from './JoinGridRowCheckbox';
import { ROW_HEADER_CLASS, startCellWidth } from './style/styleConstants';
import { GridController, GridType, GridVariant } from './types';
import { DEFAULT_ROW_HEIGHT, getLeftFixedLocation } from './utilities/size';

type HeaderProps = {
  grid: GridController;
  onCheckBoxClick: (isUnchecked: boolean) => void;
  setNewColumnIndex: (index: number) => void;
  setDisplayDialog: (display: boolean) => void;
  setEditing: (editing: boolean) => void;
  scrollToCell: (error: number, col: number) => void;
};

const OVER_Z_INDEX = 3;

const GridHeader: FC<HeaderProps> = ({
  grid,
  onCheckBoxClick,
  setNewColumnIndex,
  setDisplayDialog,
  setEditing,
  scrollToCell,
}) => {
  const START_CELL_WIDTH = startCellWidth(grid.data.lines.length);
  const hasCheckBox = !grid.linesReadOnly && !grid.isInherited;
  const isChecked =
    grid.isRowSelectedArr.length > 0 && grid.numSelectedRows === grid.isRowSelectedArr.length;
  const isUnchecked = !isChecked && grid.numSelectedRows === 0;
  const isReadOnlyVariant = grid.variant === GridVariant.READ_ONLY;

  const headerCellClass = `join-grid-td ${
    !isReadOnlyVariant
      ? 'join-grid-td-left-border'
      : 'join-grid-td-read-only-border join-grid-td-read-only-right-border'
  }  ${ROW_HEADER_CLASS}`;

  const isTotalType =
    grid.totalType === EstimateTotalType.TOTAL_TYPE_COST_TYPES &&
    grid.type === GridType.ESTIMATE_GRID;
  const topOffset = isTotalType ? DEFAULT_ROW_HEIGHT : 0;

  const totalHeaderStart = (
    <>
      <th
        className={headerCellClass}
        style={{
          width: START_CELL_WIDTH,
          top: topOffset,
        }}
      >
        <div className={headerCellClass} />
      </th>
      {hasCheckBox && (
        <th
          className={headerCellClass}
          style={{
            width: START_CELL_WIDTH,
            top: topOffset,
            left: START_CELL_WIDTH,
            zIndex: OVER_Z_INDEX,
          }}
        >
          <div className="root-cell-border column-cell-header-right" />
        </th>
      )}
    </>
  );

  const firstRowHeaderStart = (
    <>
      {hasCheckBox && (
        <th className={headerCellClass} style={{ width: START_CELL_WIDTH }}>
          <GridRowCheckbox
            checked={isChecked}
            onClick={() => {
              onCheckBoxClick(isUnchecked);
            }}
            unchecked={isUnchecked}
          />
        </th>
      )}
      <th
        key="line-number"
        className={headerCellClass}
        style={{
          left: getLeftFixedLocation(hasCheckBox, START_CELL_WIDTH),
          width: START_CELL_WIDTH,
          zIndex: OVER_Z_INDEX,
        }}
      >
        <div className={!isReadOnlyVariant ? `root-cell-border column-cell-header-right` : ''}>
          {!isReadOnlyVariant && (
            <NormalTooltip title="View keyboard shortcuts">
              <a
                className="join-grid-keyboard-button join-grid-keyboard-icon"
                href="https://success.join.build/en/knowledge/keyboard-shortcuts"
                rel="noopener noreferrer"
                target="_blank"
              >
                {grid.type === GridType.ESTIMATE_GRID && <KeyboardOutlined />}
              </a>
            </NormalTooltip>
          )}
        </div>
      </th>
    </>
  );

  const headerStart = isTotalType ? totalHeaderStart : firstRowHeaderStart;

  const addColumn = (index: number) => {
    setNewColumnIndex(index);
    setDisplayDialog(true);
  };

  return (
    <thead>
      {isTotalType && (
        <tr>
          {firstRowHeaderStart}
          <HeaderCells
            addColumn={addColumn}
            grid={grid}
            hasGroups
            scrollToCell={scrollToCell}
            setEditing={setEditing}
            topOffset={topOffset}
          />
        </tr>
      )}
      <tr>
        {headerStart}
        <HeaderCells
          addColumn={addColumn}
          grid={grid}
          scrollToCell={scrollToCell}
          setEditing={setEditing}
          topOffset={topOffset}
        />
      </tr>
    </thead>
  );
};

export default GridHeader;
