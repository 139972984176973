import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { insightsTimeDomain } from '../../api/apollo/reactiveVars';
import { getTodayUtcNoon } from '../../utilities/dates';
import InsightsBaseLine from '../dragon-scales/TimelineCharts/InsightsCost/InsightsBaseLine';
import {
  costData,
  future,
  milestones,
  past,
} from '../dragon-scales/TimelineCharts/InsightsCost/insightsCost.mocks';
import InsightsCostTrendline from '../dragon-scales/TimelineCharts/InsightsCost/InsightsCostTrendline';

const PADDING = 32;
const CHART_HEIGHT = 56 + PADDING;
const BASE_HEIGHT = 42 + PADDING;

export default function ProjectCost(props: { projectID: UUID; startStr: string; endStr: string }) {
  const today = getTodayUtcNoon();

  useEffectOnce(() => {
    insightsTimeDomain([past, future]);
  });

  const [hoverDate, setHoverDate] = useState<Date | undefined>(undefined);

  costData[costData.length - 1].date = today;

  const totalRange = [props.startStr, props.endStr] as [string, string];

  const chart = (
    <InsightsCostTrendline
      costData={costData}
      height={CHART_HEIGHT}
      hoverDate={hoverDate}
      setHoverDate={setHoverDate}
      today={today}
      totalRange={totalRange}
    />
  );
  const base = (
    <InsightsBaseLine
      data={milestones}
      height={BASE_HEIGHT}
      hoverDate={hoverDate}
      today={today}
      totalRange={totalRange}
    />
  );
  return (
    <div key={props.projectID} className="flex h-[162px] min-w-full flex-col bg-background-primary">
      {chart}
      {base}
    </div>
  );
}
