import { gql } from '../../api/graphqlFragments';

export const getProjectCostBreakdownReportQuery = gql`
  query projectCostBreakdownReport(
    $projectID: UUID!
    $milestoneID: UUID!
    $estimateType: EstimateType!
    $costMode: CostMode!
  ) {
    projectCostBreakdownReport(
      input: {
        projectID: $projectID
        milestoneID: $milestoneID
        estimateType: $estimateType
        costMode: $costMode
      }
    ) {
      total
      breakdown {
        type
        cost
        percent
      }
    }
  }
`;
