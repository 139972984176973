import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Callout, Select, TextInput } from '../../../../scales';
import { CreateEstimateParameters } from '../CreateEstimateDialogFlowUtils';

type Props = {
  errorMessage?: string;
  params: CreateEstimateParameters;
};

export default function CategorizationSelectionContent(props: Props) {
  // State
  const {
    errorMessage,
    params: {
      categorizationEntries,
      isNewCategorization,
      displayMilestoneName,
      onSetCategorizationID,
      onSetCategorizationName,
      variables: { categorizationID, categorizationName },
    },
  } = props;

  return (
    <div className="flex flex-col gap-4">
      <Callout fullWidth>
        <div className="mb-2 type-heading3">Set Categorization</div>
        <div className="type-body1">
          When you add the comparison average to the milestone{' '}
          <strong>{displayMilestoneName}</strong>, it will reuse an existing categorization or
          create a new categorization in the project for the cost line categories.
        </div>
      </Callout>
      <Select
        data-cy="categorization-select"
        entries={categorizationEntries}
        label="Categorization *"
        onChange={onSetCategorizationID}
        placeholder="Select an existing or new Categorization"
        value={categorizationID}
      />
      {isNewCategorization && (
        <>
          <TextInput
            data-cy="new-categorization-name-text-input"
            errorMessage={errorMessage}
            id="name"
            label="Name the categorization in your new estimate *"
            onChange={(value) => onSetCategorizationName(value)}
            placeholder="eg. 'Bid Package' or 'Bid Category"
            startAdornment={!!errorMessage && <ErrorOutlineIcon />}
            value={categorizationName}
          />
          <div className="type-label">
            Note: You can modify the categorization name in the settings at any time
          </div>
        </>
      )}
    </div>
  );
}
