import { FC, MouseEvent } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import {
  ItemEstimateHistoryTypes,
  itemEstimateHistoryAnalyticsEvent,
  itemNewWindowOpen,
} from '../../../analytics/analyticsEventProperties';
import { itemEstimateHistoryHistoricalMilestoneID } from '../../../api/apollo/reactiveVars';
import { ESTIMATE, VIEW_FILTER } from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { clickQuicklinkHandler } from '../../../utilities/clickHandler';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useFilterManager } from '../../FilterPanel/filterUtils';
import { useItemsListSettings } from '../../ItemsList/ItemsListSettingsUtils';
import { useItemCostQuery } from '../../ItemsList/useItemCostQuery';
import CostImpact from '../../shared-widgets/CostImpact';

import ItemsListItemInfoStyles from './ItemsListItemInfo/ItemsListItemInfoStyles';
import { generateTitle, isGroupedByCategory } from './ItemsListItemUtils';

type ItemsListItemEstimateLinkProps = {
  classes: Classes<typeof ItemsListItemInfoStyles>;
  costImpact?: Cost;
  filteredMilestoneID?: UUID;
  item: ItemLike | ItemsListItem;
  showNoEstimate?: boolean;
  totalValue?: Cost;
};

const ItemsListItemEstimateLink: FC<ItemsListItemEstimateLinkProps> = ({
  classes,
  costImpact,
  filteredMilestoneID,
  item,
  showNoEstimate = false,
  totalValue: totalValueOuter,
}) => {
  const navigate = useNavigate();
  const { cost, id: itemId, name } = item;
  const itemHasEstimate = 'hasEstimate' in item ? item.hasEstimate : true;
  const IWOhasOptions = 'options' in item ? !!item.options.length : false;
  const hasEstimateOuter = itemHasEstimate || IWOhasOptions;

  const projectId = getProjectIdFromUrl();

  const sendAnalytics = useSendAnalytics();

  const itemNewWindowAnalytics = () => {
    sendAnalytics(itemNewWindowOpen(itemId));
  };

  const clickHandler = (e: MouseEvent, navigate: NavigateFunction) => {
    // if we are displaying the cost of this item in a previous milestone
    // then open the item estimate history dialog when navigating to the item
    if (
      filteredMilestoneID &&
      'currentMilestone' in item &&
      item.currentMilestone?.id !== filteredMilestoneID
    ) {
      sendAnalytics(
        itemEstimateHistoryAnalyticsEvent(
          ItemEstimateHistoryTypes.ITEM_ESTIMATE_HISTORY_ITEM_LIST_LINK
        )
      );
      itemEstimateHistoryHistoricalMilestoneID(filteredMilestoneID);
    }

    const link = generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
      projectId,
      itemId,
    });
    clickQuicklinkHandler(
      e,
      navigate,
      link,
      itemNewWindowAnalytics,
      undefined,
      undefined,
      `#${ESTIMATE}`
    );
  };

  // STATE
  const settingsManager = useItemsListSettings();
  const { settings, persistSettingsChange } = settingsManager;
  const filterManager = useFilterManager(settings[VIEW_FILTER], (newValue: string) =>
    persistSettingsChange(VIEW_FILTER, newValue)
  );
  const { filterQueryInput: viewFilter } = filterManager;
  const isCategoryFiltering = !!viewFilter?.categories?.length;
  const isCategoryGrouping = isGroupedByCategory(settings.groupBy);
  const skipItemCostQuery = hasEstimateOuter && !IWOhasOptions;
  const { data: itemCostData, loading: itemCostLoading } = useItemCostQuery(
    itemId,
    projectId,
    viewFilter,
    settings,
    skipItemCostQuery
  );
  const itemCost = itemCostData?.itemCost?.cost;

  const hasEstimate = itemHasEstimate || !!itemCost;

  const finalCost = isCategoryGrouping ? costImpact : itemCost;
  const finalTotalCost = isCategoryGrouping ? itemCost : totalValueOuter;

  if (itemCostLoading) return null;

  return (
    <div className={`${classes.rowContainer} ${classes.flexEnd}`}>
      <button
        aria-label={`view ${generateTitle(item)}`}
        className={classes.linkTitle}
        data-cy={`button-item-row-cost-${name}`}
        onClick={(e) => clickHandler(e, navigate)}
        onMouseDown={(e) => clickHandler(e, navigate)}
        style={!hasEstimate ? { textDecorationColor: theme.palette.disabledGrey } : undefined}
        type="button"
      >
        {hasEstimate || !showNoEstimate ? (
          <CostImpact
            expanded
            fullWidth
            totalValue={isCategoryFiltering ? undefined : finalTotalCost ?? undefined}
            value={finalCost || cost || undefined}
          />
        ) : (
          <Typography className={classes.disabled} data-cy="display-itemLikeCostImpact">
            No estimate
          </Typography>
        )}
      </button>
    </div>
  );
};

export default withStyles(ItemsListItemInfoStyles)(ItemsListItemEstimateLink);
