import { useParams } from 'react-router-dom';

import { COMPANY_DASHBOARD } from '../../constants';
import { useUserProfile } from '../../utilities/userProfile';

import InsightsProjectDetails from './InsightsProjectDetails';
import InsightsProjectsList from './InsightsProjectsList';

const ExecutiveDashboardV2 = () => {
  const { projectId } = useParams();
  const profile = useUserProfile();
  document.title = `${COMPANY_DASHBOARD} ${profile ? ` - ${profile.nickname}` : ''}`;

  if (projectId) {
    return <InsightsProjectDetails projectID={projectId} />;
  }
  return <InsightsProjectsList />;
};

export default ExecutiveDashboardV2;
