import { FC, ReactNode, useState } from 'react';
import MediaQuery from 'react-responsive';

import { Checkbox, ListItem, Typography } from '@material-ui/core';

import { ITEM_WITH_OPTIONS } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { checkCostModeIncludesMarkups, getCostMode } from '../../../utilities/costMode';
import UserAvatarData from '../../Collaborators/UserAvatarData';
import CommentsChip from '../../Comments/CommentsChip';
import { AddCategoriesToFilter } from '../../FilterPanel/filterUtils';
import ItemSidebarIcon from '../../Icons/ItemSidebarIcon/ItemSidebarIcon';
import { isScheduleSettingsDisabled } from '../../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import CostImpact from '../../shared-widgets/CostImpact';
import ScheduleImpact, { ScheduleImpactVariant } from '../../shared-widgets/ScheduleImpact';
import ItemsStatusHoverSelectorData from '../ItemsStatusHoverSelector/ItemsStatusHoverSelectorData';

import ItemslistItemEstimateLink from './ItemslistItemEstimateLink';
import ItemsListItemInfo from './ItemsListItemInfo/ItemsListItemInfo';
import ItemsListItemStyles from './ItemsListItemStyles';

type ItemFlag = {
  parentIsChosen?: boolean;
};

type CategorizedItem = (ItemLike | ItemsListItem) & CategorizedLine & ItemFlag;

type Props = {
  addCategoriesToFilter?: AddCategoriesToFilter;
  canViewAttachments: boolean;
  canViewCheckbox: boolean;
  canViewComments: boolean;
  shouldDisplayCosts: boolean;
  canViewEstimateCostSubtotals: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  children?: any;
  classes: Classes<typeof ItemsListItemStyles>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  costImpact: any;
  disabled?: boolean;
  filteredMilestoneID?: UUID;
  filteredMilestoneState: PreviousMilestoneStatus | undefined;
  groupBy: number;
  hasCtaButton?: boolean;
  hasGroupings: boolean;
  isCollapseHeader?: boolean;
  isHighlightedItem?: boolean;
  isListWithOptions: boolean;
  isNested: boolean;
  isSelected?: boolean;
  item: CategorizedItem;
  link: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onMouseEnter?: (event: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onMouseLeave?: (event: any) => void;
  options?: Option[] | ItemsTreeOption[]; // if the display options differ than the listed Items due to filtering...
  sharedUsers: Pick<User, 'id' | 'name'>[];
  show: string;
  showCategories?: boolean;
  showCheckbox?: boolean;
  showParent?: boolean;
  showSimpleSchedule?: boolean;
  showNoEstimate?: boolean;
  toggleCheckbox?: (s: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  totalValue: any;
};

const ItemsListItem: FC<Props> = ({
  addCategoriesToFilter,
  canViewAttachments,
  canViewCheckbox,
  canViewComments,
  shouldDisplayCosts,
  canViewEstimateCostSubtotals,
  children,
  classes,
  costImpact,
  disabled = false,
  filteredMilestoneID,
  filteredMilestoneState,
  groupBy,
  hasCtaButton,
  hasGroupings,
  isCollapseHeader = false,
  isHighlightedItem,
  isListWithOptions,
  isNested,
  isSelected,
  item,
  link,
  onMouseEnter,
  onMouseLeave,
  options,
  sharedUsers,
  show,
  showCategories,
  showCheckbox,
  showParent = false,
  showSimpleSchedule = false,
  showNoEstimate = false,
  toggleCheckbox = () => {},
  totalValue,
}) => {
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();
  const { commentCount, cost, id: itemID, number } = item;
  const assigneeID = (item as ItemsListItem).assigneeID ?? (item as ItemLike).assignee?.id;
  const itemHasEstimate = 'hasEstimate' in item ? item.hasEstimate : true;
  const IWOhasOptions = 'options' in item ? !!item.options.length : false;
  const hasEstimate = itemHasEstimate || IWOhasOptions;

  const { parent } = item as Option;
  const { parentID } = item as ItemsListItem;

  const isIWO = 'parent' in item && !!item.parent;
  const hasContingencyDrawTotal =
    'activeEstimate' in item && item.activeEstimate?.drawSubtotal?.toString() !== '0';
  const canViewContingencySummary =
    isIWO &&
    canViewEstimateCostSubtotals &&
    hasContingencyDrawTotal &&
    checkCostModeIncludesMarkups(getCostMode());

  // CLASSES
  const disableClass = disabled ? classes.disabled : '';
  // Sizing constants: adjust padding to fit different nesting scenarios
  let itemClass = isCollapseHeader ? classes.collapse : classes.itemPadding;
  if (
    ((parent || parentID) && isNested) ||
    (!!item &&
      !isListWithOptions &&
      show === ITEM_WITH_OPTIONS &&
      groupBy === 0 &&
      !isCollapseHeader)
  )
    itemClass = '';
  let adjustItemLikePadding = hasGroupings && !isCollapseHeader ? classes.itemLikeContainer : '';
  if (!!item && !isListWithOptions && show === ITEM_WITH_OPTIONS && groupBy === 0)
    adjustItemLikePadding = '';
  const collapseContentPadding =
    isNested && !isCollapseHeader ? classes.nestedItemLikeContainer : '';

  // HOVER STATE
  const [isHovering, setIsHovering] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const mouseEnter = (event: any) => {
    if (onMouseEnter) onMouseEnter(event);
    setIsHovering(true);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const mouseLeave = (event: any) => {
    if (onMouseLeave) onMouseLeave(event);
    setIsHovering(false);
  };

  // GENERATORS
  // this function wraps all of the content besides the hover selector in a button if there
  // isn't any cta button in column3 children
  function generateContent(): ReactNode {
    const hasComments = commentCount !== 0;
    const hasAssignee = !!assigneeID;
    // This is coming from items list, we can reuse showCheckbox to indicate it is items list
    const isItemsList = !!showCheckbox && !hasCtaButton;
    const getCostImpact = () => {
      if (!shouldDisplayCosts) return null;
      if (isItemsList)
        return (
          <ItemslistItemEstimateLink
            costImpact={costImpact}
            filteredMilestoneID={filteredMilestoneID}
            item={item}
            showNoEstimate={showNoEstimate}
            totalValue={totalValue}
          />
        );
      if (!hasEstimate && showNoEstimate)
        return (
          <Typography
            className={`${classes.disabled} ${classes.noEstimate}`}
            data-cy="display-itemLikeCostImpact"
          >
            No estimate
          </Typography>
        );

      return (
        <CostImpact
          key="cost"
          expanded
          fullWidth
          totalValue={totalValue}
          value={costImpact || cost}
        />
      );
    };

    // if this is an option with a contingency draw then show the draw amount
    const getContingencyImpact = () => {
      if (canViewContingencySummary && shouldDisplayCosts) {
        return (
          <div className={classes.drawContainer}>
            <div className={classes.drawText}>C&A Draws:</div>
            <CostImpact value={{ value: item?.activeEstimate?.drawSubtotal ?? 0 }} />
          </div>
        );
      }
      return null;
    };

    return (
      <div
        className="flex flex-grow break-inside-avoid items-center gap-1 border-none bg-none outline-none"
        data-cy="itemLikeList-rowContainer"
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        <div className={classes.column1}>
          <ItemsStatusHoverSelectorData
            disabled={disabled}
            isCollapseHeader={isCollapseHeader}
            isFilteredMilestone={Boolean(filteredMilestoneState)}
            isHighlightedItem={isHighlightedItem}
            item={item}
          />
          <ItemsListItemInfo
            addCategoriesToFilter={addCategoriesToFilter}
            canViewAttachments={canViewAttachments}
            filteredMilestoneId={filteredMilestoneID}
            hasDoubleClick={isItemsList}
            item={item as ItemsListItem}
            link={link}
            options={options as ItemsTreeOption[]}
            sharedUsers={sharedUsers}
            showCategories={showCategories}
            showParent={showParent}
          />
        </div>
        <div className={classes.column2}>{getCostImpact()}</div>
        {!isItemsList && <div className={classes.column3}>{getContingencyImpact()}</div>}
        {isScheduleImpactEnabled && item.scheduleImpact && (
          <div
            className={isItemsList ? classes.column4Wide : classes.columnSchedule}
            data-cy="schedule-impact"
          >
            <ScheduleImpact
              link={isItemsList ? link : undefined}
              scheduleImpact={item.scheduleImpact}
              tooltip={isItemsList}
              variant={
                isItemsList || showSimpleSchedule
                  ? ScheduleImpactVariant.MINIMAL
                  : ScheduleImpactVariant.LIST_OPTION
              }
            />
          </div>
        )}
        <div className={hasCtaButton ? classes.column4WithCtaButton : classes.column4}>
          {children || (
            <div className={classes.iconsContainer}>
              <MediaQuery className={classes.noPrint} print={false}>
                {!hasCtaButton && canViewComments && hasComments && (
                  <CommentsChip commentCount={commentCount} />
                )}
                {!hasCtaButton && hasAssignee && (
                  <>
                    &nbsp;
                    <UserAvatarData itemID={itemID} userID={assigneeID} />
                  </>
                )}
              </MediaQuery>
            </div>
          )}
        </div>
        {isItemsList && <ItemSidebarIcon isHovering={isHovering} itemID={itemID} />}
      </div>
    );
  }

  return (
    <ListItem
      className={` 
            ${classes.root} 
            ${disableClass} 
            ${itemClass}
            ${isHighlightedItem ? classes.sidebarItem : classes.itemBackground}
          `}
      data-cy={`itemsListItem-outerContainer-${item.name}`}
      disableGutters
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={`${classes.leftContainer} ${adjustItemLikePadding} ${collapseContentPadding}`}
      >
        {canViewCheckbox && showCheckbox && !hasCtaButton && (
          <Checkbox
            checked={isSelected}
            classes={{ root: classes.checkbox }}
            data-cy={`checkbox-${number || 'Draft'}`}
            disableRipple
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            onClick={(evt: any) => {
              evt.stopPropagation();
              toggleCheckbox(!isSelected);
            }}
          />
        )}
      </div>
      {generateContent()}
    </ListItem>
  );
};

export default withStyles(ItemsListItemStyles)(ItemsListItem);
