import { Checkbox, MenuItem } from '@material-ui/core';

import InputsSelectReferenceStyles from './InputsSelectReferenceStyles';

type ReferencesSubMenuItemProps = {
  backgroundColor?: string;
  classes: Classes<typeof InputsSelectReferenceStyles>;
  id: string;
  isChecked: boolean;
  key: string;
  text: string;
  title: string;
  value: string;
  onClick: (newRef: string) => void;
};

// Note:
// Material-ui doesn't let us use a functional component to wrap
// a MenuItem component.  If we wrap it then the MenuItems aren't interactive
export const getReferencesSubMenuItem = ({
  backgroundColor,
  classes,
  id,
  isChecked,
  key,
  text,
  title,
  value,
  onClick,
}: ReferencesSubMenuItemProps) => (
  <MenuItem
    key={key}
    classes={{ selected: classes.selected }}
    className={classes.choice}
    id={id}
    onClick={() => onClick(value)}
    title={title}
    value={value}
  >
    <div className={classes.referenceContainer}>
      <div className={classes.markupSpacer} />
      <Checkbox checked={isChecked} />
      <span
        className={`
        ${isChecked ? `${classes.choiceText} ${classes.selectedChoiceText}` : classes.choiceText}
      `}
      >
        {text}
      </span>
      {backgroundColor && <div className={classes.colorChip} style={{ backgroundColor }} />}
    </div>
  </MenuItem>
);
