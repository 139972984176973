/* eslint-disable react/button-has-type */
import { FC, useMemo } from 'react';
import * as React from 'react';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

interface GridRowCheckboxProps {
  checked: boolean;
  disabled?: boolean;
  unchecked?: boolean;
  onClick: (e: React.MouseEvent) => void;
  width?: number;
  height?: number;
  position?: 'none' | 'center';
  includeBorder?: boolean;
  showHoverBackground?: boolean;
  cy?: string;
}

const GridRowCheckbox: FC<GridRowCheckboxProps> = ({
  checked,
  disabled = false,
  unchecked = true,
  onClick,
  width = 19,
  height = 19,
  position = 'center',
  includeBorder = true,
  showHoverBackground = true,
  cy = 'GridRowCheckbox-button',
}) => {
  const checkBoxIcon = useMemo(() => {
    if (checked)
      return (
        <CheckBoxIcon
          className="join-grid-checkbox-icon"
          color={disabled ? 'disabled' : undefined}
          style={{ width, height, alignSelf: 'center' }}
        />
      );
    if (unchecked)
      return (
        <CheckBoxOutlineBlankIcon
          className="join-grid-checkbox-icon"
          color={disabled ? 'disabled' : undefined}
          style={{ width, height }}
        />
      );
    return (
      <IndeterminateCheckBoxIcon
        className="join-grid-checkbox-icon"
        color={disabled ? 'disabled' : undefined}
        style={{ width, height }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [checked, unchecked]);

  let locationStyle = '';
  if (position === 'center') locationStyle = 'column-cell-content';

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={includeBorder ? 'column-cell-border' : ''}>
      <button
        className={`join-grid-checkbox-button ${locationStyle} 
          ${
            !disabled
              ? 'join-grid-checkbox-button-mouse'
              : 'join-grid-checkbox-button-disabled-mouse'
          } 
          ${showHoverBackground ? `join-grid-checkbox-button-hover` : ''}`}
        data-checked={checked} // used for integration tests
        data-cy={cy}
        onClick={(e) => onClick(e)}
      >
        {checkBoxIcon}
      </button>
    </div>
  );
};

export default GridRowCheckbox;
