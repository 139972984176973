import { useLocation, useParams } from 'react-router-dom';
import { useDebounce } from 'react-use';

import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { InsightsTabId } from '../types';

import ChartSection from './ChartSection';
import ProjectTimelineChart from './ProjectTimelineChart';

export default function InsightsProject() {
  const { projectId } = useParams();
  const { hash } = useLocation();
  const onUpdateHash = (hash: string) => {
    const el = document.querySelector(hash);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  useDebounce(() => onUpdateHash(hash), 100, [hash]);
  const projectPropsQueryResult = useProjectPropsQuery(projectId);
  const project = projectPropsQueryResult.data.project;

  if (projectPropsQueryResult.loading || !project) return null;

  return (
    <div className="w-full">
      <ChartSection id={`${InsightsTabId.Costs}`} title="Costs">
        <div className="h-[340px] w-full bg-background-2" />
      </ChartSection>
      <ChartSection id={`${InsightsTabId.Items}`} title="Items">
        <div className="w-full">
          <ProjectTimelineChart projectID={project.id} />
        </div>
      </ChartSection>
      <ChartSection id={`${InsightsTabId.Contingencies}`} title="Contingency & Allowance">
        <div className="h-[340px] w-full bg-background-2" />
      </ChartSection>
      <ChartSection id="Activity" title="Activity">
        <div className="h-[340px] w-full bg-background-2" />
      </ChartSection>
    </div>
  );
}
