import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, CardHeader, Divider, Typography } from '@material-ui/core';
import { FullscreenExit } from '@material-ui/icons';

import { EstimateType, JoinProjectRoutes, TermKey } from '../../../api/gqlEnums';
import { DEFAULT } from '../../../constants';
import { NS_REMOVE_S2_ITEM_MARKUP_REFS } from '../../../features';
import { PermissionResource } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useCostMode } from '../../../utilities/costMode';
import { computeDraftEstimateTotal } from '../../../utilities/permissions/itemsPermissions';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import CostReportSettingsPanel from '../../CostReport/CostReportSettingsPanel/CostReportSettingsPanel';
import { useGridEstimateArmatureQuery } from '../../JoinGrid/hooks/estimateQuery';
import { CostParams } from '../../Milestone/MilestoneDetails/MilestoneDetailsNav/MilestoneDetailsNavUtils';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import CTAIconButton from '../../shared-widgets/CTAIconButton';
import useMemoWrapper from '../../useMemoWrapper';
import CostEstimate from '../CostEstimate';

import styles from './EstimateAccordionStyles';
import { getEstimateType, getGridVariantFromEstimateType } from './EstimateAccordionUtils';
import EstimateTotal from './EstimateTotal';
import EstimateTotalHeader from './EstimateTotalHeader';

export enum MilestoneGridEvents {
  m_eCTA = 'milestone_estimateCTA',
  m_bCTA = 'milestone_budgetCTA',
}

export const computeCategorizations = (fields: Field[] = []): Categorization[] =>
  fields.map(({ categorization }) => categorization).filter((c): c is Categorization => !!c);

type EstimateAccordionProps = {
  classes: Classes<typeof styles>;
  costParams: CostParams;
  isMilestoneDetails?: boolean;
  milestone: Milestone;
  milestoneCostReports: MilestoneCostReports;
  refetchReports?: () => void;
  type?: EstimateType;
};

const EstimateAccordion: FC<EstimateAccordionProps> = ({
  classes,
  costParams,
  isMilestoneDetails = false,
  milestone,
  milestoneCostReports,
  refetchReports,
  type,
}) => {
  const navigate = useNavigate();
  const t = useContext(ProjectTermStore);
  const projectId = getProjectIdFromUrl();
  const milestoneId = getMilestoneIdFromUrl();
  const estimateType = type || getEstimateType();
  const isEstimate = estimateType === EstimateType.ACTIVE_ESTIMATE;

  const sendAnalytics = useSendAnalytics();
  const { canView } = usePermissions();
  const canViewProjectCategories = canView(PermissionResource.CATEGORIES_AND_TAGS);
  // TODO CT-750: MILESTONE_LINES deals with access to both Milestone Lines and Milestone Estimate
  const canViewDetails = isEstimate
    ? canView(PermissionResource.MILESTONE_LINES)
    : canView(PermissionResource.MILESTONE_BUDGET);

  const costMode = useCostMode();

  const { activeEstimateID, budgetID } = milestone;
  const estimateID = isEstimate ? activeEstimateID : budgetID;

  const estimateHandle = isEstimate ? t.titleCase(TermKey.ESTIMATE) : t.titleCase(TermKey.TARGET);
  const { data: estimateData, loading: loadingEstimateArmature } = useGridEstimateArmatureQuery(
    estimateID ?? undefined,
    undefined,
    costMode
  );
  const estimateArmature = estimateData?.estimate;
  const fields = estimateArmature?.fields;

  const hasRemoveS2ItemMarkupFeature = useHasFeature(NS_REMOVE_S2_ITEM_MARKUP_REFS);

  const categorizations = useMemoWrapper(computeCategorizations, fields);
  const value = useMemoWrapper(computeDraftEstimateTotal, milestoneCostReports, estimateType);
  const header = <EstimateTotalHeader type={type} />;

  const milestoneName = (milestone || {}).name || '';

  // FILTER INFO
  const {
    columnDescriptions,
    displayColumnDescriptions,
    displayGroupBy,
    filterManager,
    page,
    settings,
    setSetting,
  } = costParams;
  const { status, viewMode } = settings;
  const { clearFilters, filterQueryInput: viewFilter } = filterManager;
  const gridVariant = getGridVariantFromEstimateType(estimateType);

  if (loadingEstimateArmature) return null;

  const innerContentEdit = (
    <>
      {gridVariant && estimateArmature && (
        <CostEstimate
          activeEstimateID={estimateID ?? undefined}
          canViewDetails={canViewDetails}
          clearFilters={clearFilters}
          hasOwnerCostEstimate={!!estimateArmature?.ownerCostEstimate}
          hasRemoveS2ItemMarkupFeature={hasRemoveS2ItemMarkupFeature}
          projectID={projectId}
          refetchOuter={refetchReports}
          sendAnalytics={sendAnalytics}
          subtotals={estimateArmature}
          variant={gridVariant} // only milestone estimates can be filtered
          viewFilter={viewFilter}
        />
      )}
      <EstimateTotal
        boldTopBorder
        color={isEstimate ? 'Grey' : 'Blue'}
        cost={{ value }}
        header={header}
        topBorder
      />
    </>
  );

  if (isMilestoneDetails)
    return (
      <div className="grow" data-cy="milestone-estimate">
        {innerContentEdit}
      </div>
    );

  const settingsComponent = (
    <CostReportSettingsPanel
      canViewProjectCategories={canViewProjectCategories}
      categorizations={categorizations}
      columnDescriptions={columnDescriptions}
      displayColumnDescriptions={displayColumnDescriptions}
      displayGroupBy={displayGroupBy}
      filterManager={filterManager}
      milestoneID={milestoneId}
      page={page}
      setSetting={setSetting}
      settings={settings}
      status={status}
      statusDisabled
      viewGroupByColumnsDisabled
      viewMode={viewMode}
    />
  );

  const title = (
    <>
      <div className={classes.header}>
        <div className={classes.title}>
          <Breadcrumbs
            links={[
              {
                display: 'Milestones',
                destination: generateSharedPath(JoinProjectRoutes.MILESTONES, { projectId }),
                tooltip: 'Back to milestones list',
              },
              {
                display: milestoneName || 'Loading...',
                destination: generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
                  projectId,
                  milestoneId,
                }),
                tooltip: 'Back to milestone details',
              },
              {
                display: estimateHandle,
                destination: '',
                tooltip: `${milestone.name} ${estimateHandle}`,
              },
            ]}
          />
        </div>
        <div className={classes.controlHeader}>
          <div className={classes.headerOptions}>
            {settingsComponent}
            <CTAIconButton
              buttonText={`Close ${estimateHandle}`}
              color={DEFAULT}
              icon={<FullscreenExit />}
              onClick={() => {
                sendAnalytics({
                  type: isEstimate ? MilestoneGridEvents.m_eCTA : MilestoneGridEvents.m_bCTA,
                  eventProperties: { status: 'close' },
                });
                navigate(
                  generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
                    projectId,
                    milestoneId,
                  })
                );
              }}
              variant="outlined"
            />
          </div>
        </div>
      </div>
    </>
  );

  const cardHeader = <CardHeader title={title} />;

  const content =
    milestoneId === null ? (
      <Typography className={classes.empty}>Something went wrong. Please reload.</Typography>
    ) : (
      <Card className={classes.card} elevation={0} square>
        <div className={classes.background}>{cardHeader}</div>
        <Divider />
        {innerContentEdit}
      </Card>
    );

  return (
    <div className="grow" onDragOver={(e) => e?.preventDefault()}>
      {content}
    </div>
  );
};

export default withStyles(styles)(EstimateAccordion);
