import { FC } from 'react';
import { useUpdate } from 'react-use';

import { renderCostString } from '../../CostReport/CostReportUtils';
import { GridController, GridVariant } from '../../JoinGrid/types';
import CollapseIcon from '../../shared-widgets/CollapseIcon';

type SectionCollapseProps = {
  collapsed: boolean;
  grid: GridController;
  includeSpacer?: boolean;
  setCollapse: (expanded: boolean) => void;
  term: string;
  disabled?: boolean;
  termSuffix?: string;
};

export const getSubtotalValue = (grid: Pick<GridController, 'footer'>) => {
  const { string } = grid.footer?.data.data.value as RegularCell;
  return { value: string };
};

const collapseDisplayString = (term: string, grid: GridController, termSuffix?: string) =>
  `${term} (${grid.numRows()})${termSuffix || ''}`;

const SectionCollapse: FC<SectionCollapseProps> = ({
  disabled = false,
  includeSpacer = false,
  collapsed,
  grid,
  setCollapse,
  term,
  termSuffix,
}) => {
  // This is the not-so-elegant way of hooking the grid up to this component to update it
  const update = useUpdate();
  grid.setUpdateHeader(update);
  const cost = getSubtotalValue(grid);

  const isReadOnlyVariant = grid.variant === GridVariant.READ_ONLY;

  const hasLines = !!grid.data.lines.length;
  if (isReadOnlyVariant && !hasLines) return <div className="h-2 w-full" />;
  return (
    <div className={`flex ${includeSpacer ? 'mt-4' : ''}`}>
      <div
        className={`relative flex-1 basis-full break-inside-avoid bg-background-2 pb-2 pl-2 ${
          !isReadOnlyVariant ? 'border-t border-border-default' : ''
        }`}
      >
        <div
          className={`relative flex  break-inside-avoid flex-row items-center pl-1 pt-2 outline-none ${
            !isReadOnlyVariant ? 'cursor-pointer' : 'cursor-default'
          }`}
          data-cy={`collapse-${term}`}
          onClick={() => !disabled && setCollapse(!collapsed)}
          onKeyDown={() => {}}
          role={!isReadOnlyVariant ? `button` : ''}
          tabIndex={-1}
        >
          <div className="ml-px flex w-full">
            {!disabled && !isReadOnlyVariant && <CollapseIcon isCollapsed={collapsed} />}
            <div className="ml-0.5 flex w-full items-center pr-7">
              <div className={`ml-1 flex ${isReadOnlyVariant ? 'type-button' : ''}`}>
                {collapseDisplayString(term, grid, termSuffix)}
              </div>
              {cost && collapsed && (
                <div className="flex-grow text-right type-table-number">
                  {renderCostString({
                    cost,
                    isWide: true,
                    isExact: true,
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionCollapse;
