import { FC, useMemo } from 'react';

import { createStyles } from '@material-ui/core';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import SelectValue from '../../../Select/SelectValue/SelectValue';

const styles = () =>
  createStyles({
    selectDraftCategorization: {
      width: 223,
    },
  });

type DraftCategorizationSelectProps = {
  classes: Classes<typeof styles>;
  cySelect?: string;
  selectedDraftCategorization: DraftCategorization | undefined;
  draftCategorizations: DraftCategorization[];
  level: Level;
  levels: Level[];
  disabled: boolean;
  updateMap: (name: string | undefined) => void;
  updateLevelShowDisabledTip: (
    selectedDraftCatName: string | undefined,
    draftCategorizations: DraftCategorization[],
    currentLevel: Level,
    levels: Level[]
  ) => void;
};

const DraftCategorizationSelect: FC<DraftCategorizationSelectProps> = ({
  classes,
  cySelect,
  selectedDraftCategorization,
  draftCategorizations: categorizationsProjectUni,
  level,
  levels,
  disabled,
  updateMap,
  updateLevelShowDisabledTip,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  const draftCategorizations = [...categorizationsProjectUni];
  // add a blank option to the start of the draft categorizations array
  draftCategorizations.unshift({
    name: '',
    include: false,
    existingCategorization: '',
    level: 0,
    isDraft: false,
    group: '',
    categoryMatchCounts: new Map<string, CategoryMatchInfo>(),
    isBuiltIn: false,
  });

  const groupingKey: 'type' | undefined = useMemo(() => {
    const isGroupUsed = draftCategorizations.find((d) => d.group !== '');
    return isGroupUsed ? 'type' : undefined;
  }, [draftCategorizations]);

  return (
    <div className={classes.selectDraftCategorization}>
      <SelectValue
        cySelect={cySelect}
        disabled={disabled}
        groupingKey={groupingKey}
        hidePrint={false}
        isCompact={false}
        onChange={(v: string | null) => {
          const value = v === '' || v === null ? undefined : v;
          updateMap(value);
          updateLevelShowDisabledTip(value, draftCategorizations, level, levels);
        }}
        subName={draftCategorizations.map(({ categoryPreview }) =>
          categoryPreview ? `Preview: ${categoryPreview}` : ''
        )}
        types={draftCategorizations.map(({ group: g }) => g || '')}
        value={selectedDraftCategorization?.name || ''}
        values={draftCategorizations.map(({ name: n }) => n || '')}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const StyledDraftCategorizationSelect: any = withStyles(styles)(DraftCategorizationSelect);

export default StyledDraftCategorizationSelect;
