import { ReactNode, RefObject, useRef } from 'react';
import { OverlayContainer, mergeProps, useOverlayPosition, useTooltip } from 'react-aria';
import { TooltipTriggerState } from 'react-stately';

import { getTextColor } from '../../../utilities/colors';
import useActiveTheme from '../Popover/useActiveTheme';

type Props = {
  backgroundColor?: string;
  children: ReactNode;
  /** @default 8 */
  offset?: Parameters<typeof useOverlayPosition>[0]['offset'];
  /** @default "top" */
  placement?: Parameters<typeof useOverlayPosition>[0]['placement'];
  state: TooltipTriggerState;
  triggerRef: RefObject<HTMLDivElement | SVGElement>;
  type?: 'default' | 'slim';
};

export default function TooltipOverlay(props: Props) {
  const overlayRef = useRef(null);
  const { tooltipProps } = useTooltip({}, props.state);
  const { overlayProps } = useOverlayPosition({
    offset: props.offset,
    overlayRef,
    placement: props.placement,
    targetRef: props.triggerRef,
  });

  const activeTheme = useActiveTheme(props.triggerRef);

  let bgClassName: string | undefined = 'bg-background-1';
  let textClassName = 'text-type-primary';
  if (props.backgroundColor) {
    bgClassName = undefined;
    textClassName = getTextColor(props.backgroundColor);
  }

  return (
    <OverlayContainer data-theme={activeTheme}>
      <div
        ref={overlayRef}
        className={[
          'max-w-[400px] overflow-hidden rounded-md border-border-muted bg-background-1 shadow',
          props.type === 'slim' ? 'px-1 py-0' : 'border p-2',
          bgClassName,
          textClassName,
        ].join(' ')}
        {...mergeProps(tooltipProps, overlayProps, {
          style: mergeProps(tooltipProps.style, overlayProps.style, {
            backgroundColor: props.backgroundColor,
          }),
        })}
      >
        {props.children}
      </div>
    </OverlayContainer>
  );
}

TooltipOverlay.defaultProps = {
  offset: 8,
  placement: 'top',
};
