import { FC } from 'react';

import { Print } from '@material-ui/icons';

import { DD_ITEM_NUMBER_LAYOUT_CHANGES } from '../../features';
import { useHasFeature } from '../../hooks/useHasFeature';
import { Export } from '../Icons/Export';
import { IconMenuButton, MenuEntry } from '../scales';

type ItemDetailExportPanelProps = {
  printOnClick: (hasHistory?: boolean) => void;
};

const ItemDetailExportPanel: FC<ItemDetailExportPanelProps> = ({ printOnClick }) => {
  const hasItemNumberLayoutChanges = useHasFeature(DD_ITEM_NUMBER_LAYOUT_CHANGES);

  const iconMenuOptions: MenuEntry[] = [
    {
      id: 'print-without-change-history',
      label: 'Print without change history',
      onClick: printOnClick,
      startAdornment: <Print />,
    },
    {
      id: 'print-with-change-histry',
      label: 'Print with change history',
      onClick: () => printOnClick(true),
      startAdornment: <Print />,
    },
  ];
  return (
    <IconMenuButton
      aria-label="Open export options"
      entries={iconMenuOptions}
      icon={hasItemNumberLayoutChanges ? <Print /> : <Export />}
      type="secondary"
    />
  );
};

export default ItemDetailExportPanel;
