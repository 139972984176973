import { FC, useContext, useMemo } from 'react';

import theme from '../../../theme/komodo-mui-theme';
import {
  VarianceColumnDescription,
  getColumnTermFromColumnKey,
} from '../../CostReport/CostReportColumns/CostReportColumns';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { getDate } from '../../VarianceReport/VarianceReportUtils';
import { makeVarianceChartCategoriesList } from '../ChartsEstimate/ChartsEstimateUtils';
import ChartsVariance from '../ChartsVariance/ChartsVariance';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const { palette }: { palette: any } = theme;

type Classes = {
  root: string;
};

type ChartsReportVarianceProps = {
  categorizations: Categorization[];
  classes: Classes;
  clearFilters?: () => void;
  costReports: VarianceReports;
  groupBy: Categorization[];
  headerDescriptions: VarianceColumnDescription[];
  isFiltered?: boolean;
  isPrint?: boolean;
  loading?: boolean;
  projectId: UUID;
};

const ChartsReportVariance: FC<ChartsReportVarianceProps> = ({
  categorizations,
  classes,
  clearFilters,
  costReports,
  groupBy,
  headerDescriptions = [],
  isFiltered = false,
  isPrint = false,
  loading = false,
  projectId,
}) => {
  const t = useContext(ProjectTermStore);
  const chartGroupBy: Categorization | undefined = groupBy && groupBy[0];

  const groupedCategoriesVarianceChart = useMemo(() => {
    if (!chartGroupBy || !costReports || costReports.length < 1 || !categorizations) {
      return [];
    }
    return makeVarianceChartCategoriesList(costReports as VarianceReports, headerDescriptions, t);
  }, [categorizations, costReports, chartGroupBy, headerDescriptions, t]);

  const showDate =
    new Set(headerDescriptions.map((c) => `${c.milestoneName} ${c.columnKey}`)).size === 1;
  const varianceLegend = headerDescriptions
    .filter((column) => !!column.milestoneID)
    .map((column, i) => {
      const { milestoneName, columnKey, date } = column;
      const columnText = `${getColumnTermFromColumnKey(t, columnKey)}${
        showDate ? ` (${getDate(date)})` : ''
      }`;
      const title = (
        <div>
          <div>{milestoneName}</div>
          <div>{columnText}</div>
        </div>
      );
      return {
        title,
        color: i < 1 ? palette.shadedGrey : palette.budget,
        strokeWidth: 8,
      };
    });

  return (
    <div className={classes.root}>
      <ChartsVariance
        clearFilters={clearFilters}
        displayColumnDescriptions={headerDescriptions}
        groupedCategories={groupedCategoriesVarianceChart}
        isFiltered={isFiltered}
        isPrint={isPrint}
        legend={varianceLegend}
        loading={loading}
        projectId={projectId}
        viewByName={chartGroupBy && chartGroupBy.name}
      />
    </div>
  );
};

export default ChartsReportVariance;
