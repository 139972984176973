import { LoadCollaboratorsQuery } from '../../../generated/graphql';

/** Collaborator with Company */
export type ToCollaborator = LoadCollaboratorsQuery['collaborators'][number] & {
  company?: ProjectCompany;
};

export interface Selected {
  [key: string]: boolean;
}

export enum UsersSortKey {
  COMPANY = 'company.company.name',
  NAME = 'user.name',
  ROLE = 'role.name',
}

export type UsersSortBy = {
  sortDirection: SortDirection;
  sortKey: UsersSortKey;
};

export interface BoolMap {
  [key: UUID]: boolean;
}

export interface TooltipMap {
  [key: UUID]: string;
}
