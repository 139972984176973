import { FC, useState } from 'react';

import { Checkbox, ListItem } from '@material-ui/core';

import { ITEM_WITH_OPTIONS } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import ItemSidebarIcon from '../../Icons/ItemSidebarIcon/ItemSidebarIcon';
import { isScheduleSettingsDisabled } from '../../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import CostImpact from '../../shared-widgets/CostImpact';
import ScheduleImpact, { ScheduleImpactVariant } from '../../shared-widgets/ScheduleImpact';
import ItemsStatusHoverSelectorData, {
  ItemStatusVariants,
} from '../ItemsStatusHoverSelector/ItemsStatusHoverSelectorData';

import ItemslistItemEstimateLink from './ItemslistItemEstimateLink';
import ItemsListItemLinkData from './ItemsListItemLinkData';
import ItemsListItemStyles from './ItemsListItemStyles';
import { generateTitle } from './ItemsListItemUtils';

type ItemFlag = {
  parentIsChosen?: boolean;
};

type CategorizedItem = (ItemLike | ItemsListItem) & CategorizedLine & ItemFlag;

type Props = {
  canViewCheckbox: boolean;
  shouldDisplayCosts: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  children?: any;
  classes: Classes<typeof ItemsListItemStyles>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  costImpact: any;
  disabled?: boolean;
  filteredMilestoneID?: UUID;
  filteredMilestoneState: PreviousMilestoneStatus | undefined;
  groupBy: number;
  hasCtaButton?: boolean;
  hasGroupings: boolean;
  isCollapseHeader?: boolean;
  isHighlightedItem?: boolean;
  isListWithOptions: boolean;
  isNested: boolean;
  isSelected?: boolean;
  item: CategorizedItem;
  link: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onMouseEnter?: (event: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onMouseLeave?: (event: any) => void;
  sharedUsers: Pick<User, 'id' | 'name'>[];
  show: string;
  showCheckbox?: boolean;
  toggleCheckbox: (s: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  totalValue: any;
};

const ItemsListItemCompact: FC<Props> = ({
  canViewCheckbox,
  shouldDisplayCosts,
  children,
  classes,
  costImpact,
  disabled = false,
  filteredMilestoneID,
  filteredMilestoneState,
  groupBy,
  hasCtaButton,
  hasGroupings,
  isCollapseHeader = false,
  isHighlightedItem = false,
  isListWithOptions,
  isNested,
  isSelected,
  item,
  link,
  onMouseEnter,
  onMouseLeave,
  sharedUsers,
  show,
  showCheckbox = false,
  toggleCheckbox,
  totalValue,
}) => {
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();
  const { number, id: itemID } = item;
  const { parent } = item as Option;
  const { parentID } = item as ItemsListItem;

  // HOVER STATE
  const [isHovering, setIsHovering] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const mouseEnter = (event: any) => {
    if (onMouseEnter) onMouseEnter(event);
    setIsHovering(true);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const mouseLeave = (event: any) => {
    if (onMouseLeave) onMouseLeave(event);
    setIsHovering(false);
  };

  // CLASSES
  const disableClass = disabled ? classes.disabled : '';
  // Sizing constants: adjust padding to fit different nesting scenarios
  let itemClass = isCollapseHeader ? classes.collapse : classes.itemPadding;
  if (
    ((parent || parentID) && isNested) ||
    (!!item &&
      !isListWithOptions &&
      show === ITEM_WITH_OPTIONS &&
      groupBy === 0 &&
      !isCollapseHeader)
  )
    itemClass = '';
  let adjustItemLikePadding = hasGroupings && !isCollapseHeader ? classes.itemLikeContainer : '';
  if (!!item && !isListWithOptions && show === ITEM_WITH_OPTIONS && groupBy === 0)
    adjustItemLikePadding = '';
  const collapseContentPadding =
    isNested && !isCollapseHeader ? classes.nestedItemLikeContainer : '';
  // This is coming from items list, we can reuse showCheckbox to indicate it is items list
  const isItemsList = showCheckbox;

  return (
    <ListItem
      className={` 
            ${classes.root}
            ${isHighlightedItem ? classes.sidebarItem : classes.itemBackground}
            ${classes.rootCompact}
            ${disableClass} 
            ${itemClass}
          `}
      disableGutters
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      title={generateTitle(item)}
    >
      <div
        className={`${classes.leftContainer} ${adjustItemLikePadding} ${collapseContentPadding}`}
      >
        {canViewCheckbox && showCheckbox && !hasCtaButton && (
          <Checkbox
            checked={isSelected}
            classes={{ root: `${classes.checkbox} ${classes.checkboxCompact}` }}
            data-cy={`checkbox-${number}`}
            disableRipple
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            onClick={(evt: any) => {
              evt.stopPropagation();
              toggleCheckbox(!isSelected);
            }}
          />
        )}
        <ItemsStatusHoverSelectorData
          disabled={disabled}
          isCollapseHeader={isCollapseHeader}
          isFilteredMilestone={Boolean(filteredMilestoneState)}
          isHighlightedItem={isHighlightedItem}
          item={item}
          variant={ItemStatusVariants.COMPACT}
        />
      </div>
      <ItemsListItemLinkData
        hasDoubleClick={isItemsList}
        item={item}
        link={link}
        sharedUsers={sharedUsers}
      />
      <div className={`${classes.column2} ${classes.marginRight}`}>
        {shouldDisplayCosts &&
          (isItemsList ? (
            <ItemslistItemEstimateLink
              costImpact={costImpact}
              filteredMilestoneID={filteredMilestoneID}
              item={item}
              totalValue={totalValue}
            />
          ) : (
            <CostImpact
              expanded
              fullWidth
              totalValue={totalValue}
              value={costImpact || item.cost}
            />
          ))}
      </div>
      {isScheduleImpactEnabled && item.scheduleImpact && (
        <div className={isItemsList ? classes.column4Wide : classes.columnSchedule}>
          <ScheduleImpact
            link={isItemsList ? link : undefined}
            scheduleImpact={item.scheduleImpact}
            tooltip={isItemsList}
            variant={
              !isItemsList ? ScheduleImpactVariant.LIST_OPTION : ScheduleImpactVariant.MINIMAL
            }
          />
        </div>
      )}
      <ItemSidebarIcon isHovering={isHovering} itemID={itemID} />
      {children && (
        <div className={hasCtaButton ? classes.column4WithCtaButton : classes.column4}>
          {children}
        </div>
      )}
    </ListItem>
  );
};

export default withStyles(ItemsListItemStyles)(ItemsListItemCompact);
