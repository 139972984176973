import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useClickAway, useKey } from 'react-use';

import { withStyles } from '../../../theme/komodo-mui-theme';
import DropDownArrow from '../DropDownArrow/DropDownArrow';

import styles from './SelectTextMenuStyles';

export interface SelectTextMenuOption {
  cy?: string;
  key: string;
  subText: string;
  text: string;
}

export enum MenuVariants {
  INLINE = 'inline',
}

interface SelectTextMenuProps {
  caption?: string | undefined;
  classes: Classes<typeof styles>;
  cy?: string;
  disabled?: boolean;
  menuOptions: SelectTextMenuOption[];
  onChange?: (key: string) => void;
  open?: boolean;
  placeholder?: string;
  selected?: string;
}

const SelectTextMenu: FC<SelectTextMenuProps> = ({
  caption,
  classes,
  cy = 'SelectTextMenu',
  disabled,
  menuOptions,
  onChange,
  open,
  placeholder,
  selected,
}) => {
  const [isOpen, setIsOpen] = useState(open && !disabled);
  const [selectedKey, setSelectedKey] = useState(selected);

  useEffect(() => {
    if (selectedKey !== selected) setSelectedKey(selectedKey);
  }, [selected, selectedKey]);
  const displayText = useMemo(() => {
    const selectedOption = menuOptions.find((option) => option.key === selectedKey);
    if (selectedOption) return selectedOption.text;
    return placeholder || '';
  }, [menuOptions, placeholder, selectedKey]);

  const dropdownMenuRef = useRef(null);
  const closeMenu = () => setIsOpen(false);
  useClickAway(dropdownMenuRef, closeMenu);
  useKey('Escape', closeMenu);
  const dropdownMenu = (
    <div ref={dropdownMenuRef} className={classes.dropdownMenu}>
      {menuOptions.map((menuOption) => {
        const { key, text, subText } = menuOption;
        const isSelected = key === selectedKey;

        let optionSelectedStyle = classes.menuOptionButton;
        if (isSelected) {
          optionSelectedStyle += ` ${classes.selectedOption}`;
        }

        return (
          <button
            key={menuOption.key}
            aria-label={menuOption.text}
            className={optionSelectedStyle}
            data-cy={`${cy}-${menuOption.cy || menuOption.text.replace(' ', '')}Button`}
            onClick={() => {
              setSelectedKey(menuOption.key);
              if (onChange) onChange(menuOption.key);
              setIsOpen(false);
            }}
            type="button"
          >
            <div className={classes.menuOptionText}>{text}</div>
            <div className={classes.menuOptionSubText}>{subText}</div>
          </button>
        );
      })}
    </div>
  );

  return (
    <>
      {caption && <div className={classes.caption}>{caption}</div>}
      <button
        aria-label="toggle menu"
        className={`${classes.button} ${classes.mainButton}`}
        onClick={() => !isOpen && setIsOpen(true)}
        type="button"
      >
        <div className={classes.selectedText} data-cy={`${cy}-displayText`}>
          {displayText}
        </div>
        <div className={classes.spacer} />
        <DropDownArrow
          dataCy="arrowDropDown-inputMenu"
          isRelative
          onClick={() => setIsOpen(true)}
        />
      </button>
      {isOpen && !disabled && dropdownMenu}
    </>
  );
};

/** @deprecated in favor of design system component, please use scales/Select */
export default withStyles(styles)(SelectTextMenu);
