import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { tableStyles } from '../../../theme/table-styles';

export default (theme: KomodoTheme) => {
  const { cell } = tableStyles(theme);
  return createStyles({
    cell,
    emptyHeaderCell: {
      minWidth: 66,
      backgroundColor: theme.palette.ds.background.primary,
      position: 'sticky',
      top: 0,
      zIndex: 100,
    },
    headerButton: {
      display: 'flex',
      gap: '6px',
      alignItems: 'center',
    },
    headerCell: {
      maxWidth: 'unset',
      textAlign: 'left',
      height: 'auto',
      borderBottom: theme.border.line,
      position: 'sticky',
      top: 0,
      zIndex: 100,
      background: theme.palette.ds.background.primary,

      cursor: 'pointer',
      '&[disabled]': {
        cursor: 'default',
      },

      '&:focus-visible': {
        outline: `2px solid ${theme.palette.ds.selection['focus-outline']}`,
        outlineOffset: -2,
      },
    },
    headerCellCompact: {
      ...theme.typography.webTableHeader,
      padding: '4px 8px',
    },
    headerCellStandard: {
      ...theme.typography.webButton,
      padding: 8,
    },
    headerButtonRightAligned: {
      justifyContent: 'flex-end',
    },
    iconSortArrow: {
      fontSize: theme.typography.webTableText.lineHeight,
      fill: theme.palette.disabledGrey,
    },
    iconSortArrowActive: {
      fill: theme.palette.primaryBlue,
    },
    iconSortArrowFlipped: {
      transform: 'rotate(180deg)',
    },
  });
};
