import { getOperationName } from 'apollo-link';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import { JoinProjectRoutes } from '../../../../api/gqlEnums';
import { REFETCH_PROJECT_TEMPLATE } from '../../../../api/refetchSets';
import {
  CreateProjectTemplateMutation,
  CreateProjectTemplateMutationVariables,
  DeleteProjectTemplateMutation,
  DeleteProjectTemplateMutationVariables,
  ProjectTemplateQuery,
  ProjectTemplateQueryVariables,
  ProjectTemplatesQuery,
  ProjectTemplatesQueryVariables,
  SetProjectTemplatePublicationMutation,
  SetProjectTemplatePublicationMutationVariables,
} from '../../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../../hooks/useMountAwareQuery';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { isNonNullable } from '../../../../utilities/types';

import {
  createProjectTemplateMutation,
  deleteProjectTemplateMutation,
  projectTemplateQuery,
  projectTemplatesQuery,
  setProjectTemplatePublicationMutation,
  updateProjectTemplateNameMutation,
} from './queries';

export const useCreateProjectTemplateMutation = () => {
  const [createProjectTemplateFunc] = useMutation<
    CreateProjectTemplateMutation,
    CreateProjectTemplateMutationVariables
  >(createProjectTemplateMutation);

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (name: string, templateID?: UUID, onSuccess?: any, onFailure?: any) =>
    createProjectTemplateFunc({
      variables: { name, templateID },
      refetchQueries: [getOperationName(projectTemplatesQuery)].filter(isNonNullable),
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { createProjectTemplate: value },
        } = result;
        if (onSuccess) onSuccess(value);
        navigate(generateSharedPath(JoinProjectRoutes.SETTINGS, { projectId: value?.id }));
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
};

export const useDeleteProjectTemplateMutation = (templateID: UUID) => {
  const [deleteProjectTemplateFunc] = useMutation<
    DeleteProjectTemplateMutation,
    DeleteProjectTemplateMutationVariables
  >(deleteProjectTemplateMutation);
  const deleteProjectTemplate = () =>
    deleteProjectTemplateFunc({
      variables: { templateID },
      refetchQueries: REFETCH_PROJECT_TEMPLATE,
    });

  return deleteProjectTemplate;
};

//
export const useSetProjectTemplatePublicationMutation = (templateID: UUID) => {
  const [setProjectTemplatePublicationFunc] = useMutation<
    SetProjectTemplatePublicationMutation,
    SetProjectTemplatePublicationMutationVariables
  >(setProjectTemplatePublicationMutation);
  const setProjectTemplatePublication = (published: boolean) =>
    setProjectTemplatePublicationFunc({
      variables: { templateID, published },
      refetchQueries: REFETCH_PROJECT_TEMPLATE,
    });

  return setProjectTemplatePublication;
};

export const useProjectTemplateQuery = (
  projectID: UUID,
  options?: QueryHookOptions<ProjectTemplateQuery, ProjectTemplateQueryVariables>
) => {
  return useQuery<ProjectTemplateQuery, ProjectTemplateQueryVariables>(projectTemplateQuery, {
    variables: { projectID },
    ...options,
  });
};

export const useProjectTemplatesQuery = (companyID: UUID | undefined, showDrafts: boolean) => {
  return useQuery<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>(projectTemplatesQuery, {
    variables: { companyID, showDrafts },
    fetchPolicy: 'cache-and-network',
    skip: !companyID,
  });
};

export const useUpdateProjectTemplateNameMutation = () => {
  const [mutationFunc] = useMutation(updateProjectTemplateNameMutation);
  return (templateID: UUID, name: string) =>
    mutationFunc({
      variables: { templateID, name },
    });
};
