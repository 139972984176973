import { gql } from '../../../api/graphqlFragments';

export const insightsProjectIDsQuery = gql`
  query Insights($input: InsightsInput!) {
    insights(input: $input) {
      id
    }
  }
`;

export const insightsProjectsQuery = gql`
  query InsightsProjects($input: InsightsInput!) {
    insights(input: $input) {
      id
      name
      projectLeadName: projectLead
      type: projectType
      location
      status
      lastUpdated
      lastUpdatedBy
      deliveryType
      milestoneDesignPhase
      orgNodes {
        id
        name
      }
      hasAccess
      thumbnail
    }
  }
`;
