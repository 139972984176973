import { FC } from 'react';

import theme from '../../../../theme/komodo-mui-theme';

interface LegendSquareProps {
  hatched?: boolean;
  color: string;
}

const LegendSquare: FC<LegendSquareProps> = ({ hatched = false, color }) => {
  return (
    <svg height="10" width="10">
      {hatched ? (
        <defs>
          <pattern
            height="4"
            id="diagonalHatch2"
            patternTransform="rotate(90)"
            patternUnits="userSpaceOnUse"
            width="4"
          >
            <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" stroke={color} strokeWidth="1" />
          </pattern>
        </defs>
      ) : null}
      <rect fill={hatched ? `url(#diagonalHatch2)` : color} height="100%" width="100%" />
    </svg>
  );
};

const legendItemStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 4,
};

const Legend: FC<{ hasOverdrawnContingency: boolean; hasRowGap?: boolean }> = ({
  hasOverdrawnContingency,
  hasRowGap = false,
}) => {
  return (
    <div
      style={{
        fontSize: 12,
        fontWeight: 400,
        display: 'flex',
        columnGap: 12,
        rowGap: hasRowGap ? 8 : undefined,
        flexWrap: 'wrap',
      }}
    >
      <div style={legendItemStyle}>
        <LegendSquare color={theme.palette.backgroundGrey} /> Used
      </div>
      <div style={legendItemStyle}>
        <LegendSquare color={theme.palette.chartLightBlue} /> Drawn
      </div>
      <div style={legendItemStyle}>
        <LegendSquare color={theme.palette.chartDarkGrey} hatched /> Pending
      </div>
      <div style={legendItemStyle}>
        <LegendSquare color={theme.palette.primaryBlue} /> Remaining
      </div>
      {hasOverdrawnContingency && (
        <div style={legendItemStyle}>
          <LegendSquare color={theme.palette.chartBrightRed} /> Overdrawn
        </div>
      )}
    </div>
  );
};

export default Legend;
