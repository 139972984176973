import { Help } from '@material-ui/icons';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { SortDirection } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { Tooltip } from '../Tooltip';

import styles from './TableHeaderStyles';
import { SortManager, TableHeader as TableHeaderType } from './types';

type Props = {
  classes: Classes<typeof styles>;
  compact?: boolean;
  dataCy: string;
  header: TableHeaderType;
  rightAligned?: boolean;
  sortManager?: SortManager;
};

const TableHeader = ({ classes, compact, dataCy, header, rightAligned, sortManager }: Props) => {
  const { component, copy, headerSortKey, tooltip } = header;

  const hasTitle = !!copy;
  const hasComponent = !!component;

  if (!hasTitle && !hasComponent) {
    return <span className={classes.headerCell} />;
  }

  const isActiveHeader = sortManager?.sortState.sortKey === headerSortKey;

  return (
    <button
      aria-label={`sort by ${copy}`}
      className={`${classes.cell} ${classes.headerCell} ${
        compact ? classes.headerCellCompact : classes.headerCellStandard
      }`}
      data-cy={`table-header-${dataCy}`}
      disabled={!sortManager || !headerSortKey}
      onClick={() => {
        if (!sortManager || !headerSortKey) {
          return;
        }

        let newSortDirection = SortDirection.SORT_ASCENDING;
        if (sortManager.sortState.sortKey === headerSortKey) {
          newSortDirection =
            sortManager.sortState.sortDirection === SortDirection.SORT_ASCENDING
              ? SortDirection.SORT_DESCENDING
              : SortDirection.SORT_ASCENDING;
        }

        sortManager.setSort({
          sortKey: headerSortKey,
          sortDirection: newSortDirection,
        });
      }}
    >
      <div
        className={`${classes.headerButton} ${
          rightAligned ? classes.headerButtonRightAligned : ''
        }`}
      >
        {component}
        {hasTitle && copy}
        {Boolean(tooltip) && (
          <Tooltip content={tooltip}>
            <div className="flex items-center text-type-muted icon-sm">
              <Help fontSize="inherit" />
            </div>
          </Tooltip>
        )}
        {hasTitle && headerSortKey && sortManager && (
          <ArrowDownwardIcon
            classes={{
              root: `
                ${classes.iconSortArrow}
                ${isActiveHeader ? classes.iconSortArrowActive : ''}
                ${
                  isActiveHeader &&
                  sortManager.sortState.sortDirection === SortDirection.SORT_DESCENDING
                    ? classes.iconSortArrowFlipped
                    : ''
                }
              `,
            }}
          />
        )}
      </div>
    </button>
  );
};

export default withStyles(styles)(TableHeader);
