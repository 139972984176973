import {
  INTRODUCTION,
  MASTERFORMAT_CELL,
  NOT_APPLICABLE,
  REJECTED,
  SEPARATED_COST,
  SORT_NUMBER,
  SORT_STATUS,
  Uncategorized,
} from '../../../constants';
import { CostReportColumnType, Status } from '../../../generated/graphql';
import theme from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { ItemState, getFilteredItemState } from '../../../utilities/items';
import { getItemLikeContributionsFromCostReport } from '../../../utilities/reports';
import { sortItemsBy } from '../../../utilities/sorting';
import stableSort from '../../../utilities/stableSort';
import { EMPTY_COST, pluralizeCountString } from '../../../utilities/string';
import { getCostValue, isCostRange } from '../../CostReport/CostReportUtils';
import { isNotAChosenOption } from '../../Items/ItemsUtils';
import { FILTERED_STATUSES } from '../../ItemsList/ItemsListUtils';

export type ItemStatusData = {
  status: string;
  adds?: number;
  deducts?: number;
  itemCount?: number;
};

export const sortCategories = (
  a: { name?: string; number?: string },
  b: { name?: string; number?: string }
) => {
  // sort uncategorized (items + nodes)
  if (b.number === a.number) return 0;
  if (b.number === ' ') return -1;
  if (a.number === ' ') return 1;
  if (b.name === Uncategorized) return 1;
  if (a.name === Uncategorized) return -1;
  if (b.name === INTRODUCTION) return 1;
  if (a.name === INTRODUCTION) return -1;
  // ALL OTHER SORT BY
  return sortItemsBy[SORT_NUMBER](a, b);
};

export type Label = {
  isPositive: boolean;
  x: string | number;
  y: number;
};

export type ChartItem = ItemState &
  Label & {
    displayCostImpact: number;
    status: Status | string;
    y0: number;
  };

type XValue = {
  x: string | number;
  number?: string;
  name?: string;
  [CostReportColumnType.RUNNINGTOTAL_REPORT]?: number;
};

type StatusColor = keyof typeof theme.palette;
export const getColor = (status: string) => {
  const simpleStatus = status && status.toLowerCase();
  if (simpleStatus in theme.palette) {
    return theme.palette[simpleStatus as StatusColor] as string;
  }
  return '';
};

const filterAndSortChartItems = (items: ChartItem[]) => {
  // TODO : Handle this with costMode + flag on backend
  // Filters out items without cost
  const filtered = items.filter((item: ChartItem) => item.displayCostImpact !== 0);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const sortFn = (a: any, b: any) => {
    // sort by positive
    if (b.isPositive && !a.isPositive) return -1;
    if (a.isPositive && !b.isPositive) return 1;
    // sort by status
    const statusA: string = getFilteredItemState(a).status;
    const statusB: string = getFilteredItemState(b).status;
    const statusDiff = FILTERED_STATUSES.indexOf(statusA) - FILTERED_STATUSES.indexOf(statusB);
    // cost difference
    const costDiff = Math.abs(a.displayCostImpact) - Math.abs(b.displayCostImpact);
    const diff = statusDiff !== 0 ? statusDiff : costDiff;
    if (!a.isPositive) {
      return diff;
    }
    return -diff;
  };
  const sortedItems = stableSort(filtered, sortFn);
  return sortedItems as ChartItem[];
};

const formatStatusChartItems = (items: ChartItem[]) => {
  const xValues = FILTERED_STATUSES.map((x: string) => ({ x }));
  const formattedItems = items
    .filter((item) => FILTERED_STATUSES.includes(item.status))
    .map((item) => ({
      ...item,
      category: null,
      x: item.status,
    }));
  return { xValues, formattedItems, xLabel: 'Status' };
};

const formatCategoryChartItems = (items: ChartItem[], viewBy?: string) => {
  let xValues: XValue[] = [];
  const formattedItems = items.map((item: ChartItem) => {
    let name = Uncategorized;
    let number = EMPTY_COST;
    const itemViewByCategory =
      item.categories &&
      item.categories.find((c: Category) => c.categorization && c.categorization.name === viewBy);
    const isMasterFormat =
      viewBy && viewBy.toLowerCase().includes(MASTERFORMAT_CELL.toLocaleLowerCase());
    if (itemViewByCategory) {
      const level0 = itemViewByCategory.levels && itemViewByCategory.levels[0];
      if (level0 && level0.name !== Uncategorized) ({ number, name } = level0);
    }
    if (isMasterFormat && number) {
      number = number.substring(0, 2);
    }
    const xIndex = xValues.findIndex((xVal: XValue) => number === xVal.x);
    if (xIndex < 0) {
      xValues.push({ x: number, number, name });
    }
    return { ...item, category: name, x: number };
  });
  xValues = xValues.sort(sortCategories);
  return { xValues, formattedItems, xLabel: viewBy || '' };
};

type CostRange = {
  min: number;
  max: number;
};

const getItemsListOptionsForItem = (item: ItemsListItem, itemMap: Map<UUID, ItemsListItem>) =>
  item.options.map((link) => itemMap.get(link.id)).filter((o): o is ItemsListItem => !!o);

export const getRejectedRange = (rejectedValues: number[]) => {
  const rejectedRange = { min: 0, max: 0 };
  rejectedValues.forEach((v) => {
    if (v < rejectedRange.min) rejectedRange.min = v;
    if (v > rejectedRange.max) rejectedRange.max = v;
  });
  return rejectedRange;
};

export const getRejectedOptionValues = (options: ItemsListItem[]) =>
  options
    .filter(
      (o) =>
        // Explicitly rejected.
        o.status === REJECTED ||
        // Implicitly rejected.
        (o.status !== NOT_APPLICABLE && isNotAChosenOption(o.availableStates, true, o.status))
    )
    .map((o) => getCostValue(o.cost));

const getRangeContribution = (
  optionM: number,
  itemM: number,
  isMax: boolean,
  item: ItemsListItem
) => {
  const nonZeroGreater = optionM > itemM;
  const hasContribution = isMax ? nonZeroGreater : !nonZeroGreater;
  if (hasContribution) {
    const displayCostImpact = optionM - itemM;
    const costImpact = { value: optionM };

    return [
      {
        ...item,
        categorizedState: {
          costImpact,
          status: REJECTED,
        },
        displayCostImpact,
        isPositive: isMax,
        status: REJECTED,
      },
    ];
  }
  return [];
};

export const getIWORejectedContributions = (
  itemRange: CostRange,
  rejected: CostRange,
  item: ItemsListItem
) => [
  ...getRangeContribution(rejected.min, Math.min(itemRange.min, 0), false, item),
  ...getRangeContribution(rejected.max, Math.max(itemRange.max, 0), true, item),
];

const computeRejectedOptionsContributions = (
  item: ItemsListItem,
  itemMap: Map<UUID, ItemsListItem>,
  min: number,
  max: number
) => {
  const options = getItemsListOptionsForItem(item, itemMap);
  if (options.length === 0) return [];
  const rejectedValues = getRejectedOptionValues(options);
  const rejectedRange = getRejectedRange(rejectedValues);
  return getIWORejectedContributions({ min, max }, rejectedRange, item);
};

export const computeStatusContributions = (
  items: ItemsListItem[],
  itemMap: Map<UUID, ItemsListItem>
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const chartItems: any[] = [];
  if (items) {
    items.forEach((item: ItemsListItem) => {
      const { cost, status } = item;
      let minValue = 0;
      let maxValue = 0;
      if (isCostRange(cost)) {
        const { min, max } = cost;
        minValue = Number(min);
        maxValue = Number(max);
        if (minValue < 0) {
          chartItems.push({
            ...item,
            categorizedState: {
              costImpact: { value: min },
              status,
            },
            displayCostImpact: minValue,
            isPositive: false,
            status,
          });
        }
        if (maxValue > 0) {
          chartItems.push({
            ...item,
            categorizedState: {
              costImpact: { value: max },
              status,
            },
            displayCostImpact: maxValue,
            isPositive: true,
            status,
          });
        }
      } else {
        const { value } = cost;
        const displayCostImpact = Number(value);
        const isPositive = displayCostImpact > 0;
        if (isPositive) maxValue = displayCostImpact;
        else minValue = displayCostImpact;
        chartItems.push({ ...item, displayCostImpact, isPositive, status });
      }
      chartItems.push(...computeRejectedOptionsContributions(item, itemMap, minValue, maxValue));
    });
  }
  return filterAndSortChartItems(chartItems);
};

export const computeMilestoneContributions: (
  items: Item[],
  costReport?: MilestoneCostReport
) => ChartItem[] = (items, costReport) => {
  if (costReport) {
    // We do not yet supporting options in Items Chart but need it in args
    const options: Option[] = [];
    const contributions = getItemLikeContributionsFromCostReport(costReport, items, options, true);
    const chartItems: ChartItem[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    contributions.forEach((c: any) => {
      const { costImpact } = getFilteredItemState(c);
      const displayCostImpact = getCostValue(costImpact);
      if (displayCostImpact) {
        const isPositive = displayCostImpact > 0;
        chartItems.push({
          ...c,
          displayCostImpact,
          isPositive,
        });
      }
    });
    return filterAndSortChartItems(chartItems);
  }
  return [];
};

type ComputeInput = { xValues: XValue[]; formattedItems: ChartItem[]; xLabel: string };
export type ComputeOutput = {
  min: number;
  max: number;
  items: ChartItem[];
  labels: Label[];
  buckets: string[];
  xLabel: string;
};

export const computeChartItems = ({ xValues, formattedItems, xLabel }: ComputeInput) => {
  const labels: Label[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const buckets: any = xValues.slice();
  formattedItems.forEach((item: ChartItem) => {
    // create label locations and buckets
    let idx = labels.findIndex(
      (label: Label) => item.x === label.x && label.isPositive === item.isPositive
    );
    if (idx < 0) {
      idx = labels.push({
        x: item.x, // this is messy
        isPositive: item.isPositive,
        y: item.displayCostImpact / 100,
      });
    } else {
      labels[idx] = {
        x: item.x, // this is messy
        isPositive: item.isPositive,
        y: labels[idx].y + item.displayCostImpact / 100,
      };
    }
    // Calculating the running total for this bucket?!?
    // sum total for buckets - this is not the best typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    const i = buckets.findIndex((b: any) => item.x === b.x);
    if (i >= 0) {
      buckets[i][CostReportColumnType.RUNNINGTOTAL_REPORT] =
        (buckets[i][CostReportColumnType.RUNNINGTOTAL_REPORT] || 0) +
        Number(item.displayCostImpact);
    }
  });

  const max = Math.max(...labels.map((cl) => Number(cl.y)));
  const min = Math.min(...labels.map((cl) => Number(cl.y)));

  const bases = labels
    .filter((label) => !label.isPositive)
    .map((label) => ({ x: label.x, b: label.y }));

  const items: ChartItem[] = formattedItems.map((item: ChartItem) => {
    const { status } = getFilteredItemState(item);
    const color = getColor(status);
    const bId = bases.findIndex((b) => b.x === item.x);
    const v = Math.abs(item.displayCostImpact / 100);
    let y0 = 0; // last basis for this status
    let y = y0 + v; // where the next one in the category starts
    if (bId >= 0) {
      y0 = bases[bId].b;
      y = y0 + v;
      bases[bId].b = y;
    } else {
      bases.push({ x: item.x, b: v });
    }
    return {
      ...item,
      color,
      y,
      y0,
    };
  });
  const output: ComputeOutput = { min, max, items, labels, buckets, xLabel };
  return output;
};

const filterItemsForItemsChart = (items: ItemsListItem[]) =>
  items.filter((i) => {
    if (i.categories && i.categories.length > 0) {
      const category = i.categories[0];
      if (category.number === SEPARATED_COST || category.name === SEPARATED_COST) return false;
    }
    return true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  }) as any[];

export const computeItemsStatus = (itemsCost: ItemsListItem[], itemMap: Map<UUID, ItemsListItem>) =>
  formatStatusChartItems(computeStatusContributions(itemsCost, itemMap));

export const computeItemsAll = (
  itemsCost: ItemsListItem[],
  viewBy: string | undefined,
  costReport?: MilestoneCostReport
) => {
  const filteredItems = filterItemsForItemsChart(itemsCost);
  const itemsContributions = computeMilestoneContributions(filteredItems, costReport);
  return formatCategoryChartItems(itemsContributions, viewBy);
};

export const computeItems = (
  items: ItemsListItem[],
  itemMap: Map<UUID, ItemsListItem>,
  viewBy: string | undefined,
  costReport?: MilestoneCostReport
) => {
  // TODO: think about unify/optimize/simplify, GH issue #4128
  const filteredItems = items.filter((i: ItemsListItem) => i.status !== NOT_APPLICABLE);
  const formattedItems =
    viewBy === SORT_STATUS
      ? computeItemsStatus(filteredItems, itemMap)
      : computeItemsAll(filteredItems, viewBy, costReport);
  return computeChartItems(formattedItems);
};

export const isZeroCost = (item: ItemsListItem) => {
  const { cost } = item;
  if (isCostRange(cost)) {
    return cost.min === 0 && cost.max === 0;
  }
  return cost.value === 0;
};

// HELPERS
export const generateLabel = (statusData: ItemStatusData[], hint?: string) => {
  // LABEL STRING PROCESSING
  let text = '';
  const currencyFormat = { short: true, signed: true, showCurrencySymbol: false };
  if (hint) {
    const statusReport = statusData.find((d) => d.status === hint);
    const statusString = hint.toLowerCase();
    if (statusReport) {
      const { adds, deducts, itemCount } = statusReport;
      const itemCountText = pluralizeCountString(`item`, itemCount ?? 0);
      const objectText = `${itemCountText}`;
      const addText =
        Number(adds) > 0 ? `${formatCost(adds, currencyFormat)} ${statusString} adds` : ``;
      const deductText =
        Number(deducts) < 0 ? `${formatCost(deducts, currencyFormat)} ${statusString} deducts` : ``;
      const spacer = addText && deductText ? ', ' : '';
      text = `${addText}${spacer}${deductText}\n from ${objectText}`;
    }
  }
  return text;
};
