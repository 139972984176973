import React from 'react';

const PendingPieToolTip = () => {
  const itemsFillerData = [
    { label: 'Past Due', percentage: 60, count: 84, color: 'var(--colors-item-status-rejected)' },
    {
      label: 'Due in the future',
      percentage: 16,
      count: 22,
      color: 'var(--colors-item-status-pending)',
    },
    {
      label: 'Missing Due Dates',
      percentage: 24,
      count: 33,
      color: 'var(--colors-item-status-pending-tint)',
    },
  ];

  return (
    <div className="">
      <div className="mb-2 type-heading3">Pending Items</div>
      <div className="grid grid-cols-[160px_40px_40px] gap-2 text-left">
        {itemsFillerData.map((item) => (
          <React.Fragment key={item.label}>
            <div className="flex items-center type-body2">
              <div className="mr-2 h-3 w-3" style={{ backgroundColor: item.color }} />
              <div className="w-full">{item.label}</div>
            </div>
            <div className="text-right type-body2">{item.percentage}%</div>
            <div className="text-right type-body2">{item.count}</div>
          </React.Fragment>
        ))}
        {/* Total row */}
        <div className="col-span-2 type-heading3">Total</div>
        <div className="text-right type-heading3">139</div>
      </div>
    </div>
  );
};

export default PendingPieToolTip;
