import { JoinRoutes } from '../../../../../api/gqlEnums';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../../../utilities/routes/links';
import ProjectLink from '../../../../ForecastingRoute/common/ProjectLink';
import { SearchItem } from '../../types';

import styles from './ProjectCellStyles';

const ProjectCell = ({ classes, item }: { classes: Classes<typeof styles>; item: SearchItem }) => (
  <ProjectLink
    className={`${classes.container} ${item.project.hasAccess ? classes.link : ''}`}
    hasAccess={item.project.hasAccess}
    projectName={item.project.name}
    to={generateSharedPath(JoinRoutes.PROJECT, {
      projectId: item.project.id,
    })}
  >
    {item.project.name}
  </ProjectLink>
);

export default withStyles(styles)(ProjectCell);
