import { useEffect, useMemo } from 'react';

import { Divider } from '@material-ui/core';

import { PageViews } from '../../../../analytics/analyticsEventProperties';
import { NULL_ID } from '../../../../constants';
import {
  ForecastingProjectsFilters,
  ProjectType,
  SearchResultType,
} from '../../../../generated/graphql';
import { useCompanyUserQuery } from '../../../../hooks/useCompanyUserQuery';
import { getSubtree, makeTree } from '../../../../utilities/utilities';
import useOrganizationsQuery from '../../../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import AdvancedFilters from '../../../frame/AdvancedFiltersSidebar/AdvancedFilters';
import ProjectsFilters, {
  FilterOptions,
} from '../../../frame/AdvancedFiltersSidebar/ProjectsFilters';
import useSearchProjectsFilterOptionsQuery from '../../../ProjectsList/hooks/useSearchProjectsFilterOptionsQuery';
import SearchHeader from '../SearchHeader';

import { activeFiltersCountProjects } from './activeFilterCountProjects';
import { useSearchProjectsQuery } from './hooks/useSearchProjectsQuery';
import useSearchProjectsQueryParams from './hooks/useSearchProjectsQueryParams';
import { useUpdateSearchProjectsFromURL } from './hooks/useUpdateSearchProjectsFromURL';
import LeadsBanner from './LeadsBanner';
import SearchProjectsList from './SearchProjects';

type Props = {
  isFilterMenuOpen: boolean;
  onToggleFilterMenu: (isOpen: boolean) => void;
};

const SearchProjectsData = (props: Props) => {
  useEffect(() => {
    document.title = 'Projects';
  }, []);

  const params = useSearchProjectsQueryParams();
  useUpdateSearchProjectsFromURL(
    params.onChangeSearch,
    params.onResetFilters,
    params.toggleParams?.onChange
  );

  const { data, fetchMore, loading, previousData } = useSearchProjectsQuery(params.variables);
  const { searchResultType, filters } = params.variables;
  const filterOptions = useFilterOptions(searchResultType !== SearchResultType.MY, filters);
  const projects = data?.searchProjects?.data ?? previousData?.searchProjects?.data ?? [];
  const counts = {
    current: data?.searchProjects?.total ?? previousData?.searchProjects?.total ?? 0,
    total:
      searchResultType !== SearchResultType.MY ? params.toggleCounts.all : params.toggleCounts.my,
  };

  const activeFiltersCount = useMemo(() => {
    return activeFiltersCountProjects(filters);
  }, [filters]);

  return (
    <div className="flex h-full grow flex-col">
      <SearchHeader
        activeFiltersCount={activeFiltersCount}
        isFilterMenuOpen={props.isFilterMenuOpen}
        onChangeSearch={params.onChangeSearch}
        onToggleFilterMenu={props.onToggleFilterMenu}
        search={params.variables.search}
        toggleParams={params.toggleParams}
      />
      <Divider />
      <LeadsBanner
        description="Update the Leads for projects in Company Settings > Project Stats."
        page={PageViews.PROJECT_LIST}
      />
      <div className="flex flex-grow overflow-hidden">
        {props.isFilterMenuOpen && (
          <AdvancedFilters
            hasActiveFilters={activeFiltersCount > 0}
            onCloseFiltersPanel={props.onToggleFilterMenu}
            onResetFilters={params.onResetFilters}
          >
            <ProjectsFilters
              filterOptions={filterOptions}
              filterValues={params.variables.filters}
              isExpanded
              onChangeFilters={params.onChangeFilters}
            />
          </AdvancedFilters>
        )}
        <div className="grow overflow-x-auto">
          <SearchProjectsList
            counts={counts}
            loading={loading}
            onFetchMore={fetchMore}
            projects={projects}
            searchText={params.variables.search}
            sortManager={params.sortManager}
          />
        </div>
      </div>
    </div>
  );
};

export const useFilterOptions = (
  includeCompanyProjects: boolean,
  filters: ForecastingProjectsFilters
): FilterOptions => {
  const { data: filterOptionsData } = useSearchProjectsFilterOptionsQuery(
    includeCompanyProjects ? SearchResultType.ALL : SearchResultType.MY
  );

  const { data: companyUserData } = useCompanyUserQuery();
  const companyID = companyUserData?.companyUser?.company?.id;
  const organizationsQueryResult = useOrganizationsQuery(companyID);

  return useMemo(() => {
    const options = filterOptionsData?.searchProjectsFilterOptions;
    if (!options)
      return {
        companies: [],
        locations: [],
        milestoneDesignPhases: [],
        organizationNodes: [],
        projectLeads: [],
        projectTypes: [],
        statuses: [],
        types: [],
      };

    const orgs = organizationsQueryResult.data?.organizations ?? [];
    const orgTrees = orgs.flatMap((org) =>
      makeTree(org.nodes.map((node) => ({ ...node, label: node.name })))
    );
    const subtree = getSubtree(
      orgTrees,
      options.organizationNodes.map((node) => node.id)
    );

    const filterOptions = {
      companies: getFilterOptions(filters.companies, options.companies),
      locations: getFilterOptions(filters.locations, options.locations),
      milestoneDesignPhases: getFilterOptions(
        filters.milestoneDesignPhases,
        options.milestoneDesignPhases
      ),
      organizationNodes: subtree,
      projectLeads: [
        { id: NULL_ID, label: 'Unassigned' },
        ...options.projectLeads.map(({ id, name }) => ({ id, label: name })),
      ],
      projectTypes: getProjectTypesFilterOptions(options.projectTypes),
      statuses: getFilterOptions(filters.statuses, options.statuses),
      types: getProjectTypesFilterOptions(options.projectTypes),
    };
    return filterOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [
    filterOptionsData?.searchProjectsFilterOptions,
    organizationsQueryResult,
    filters.companies,
    filters.locations,
    filters.milestoneDesignPhases,
    filters.statuses,
    filters.types,
  ]);
};

function getProjectTypesFilterOptions(filterOptions: ProjectType[]) {
  // Create a list of new project type filter options
  return filterOptions.map((type) => {
    return {
      ...type,
      label: type.name,
    };
  });
}

function getFilterOptions(filterSelections: string[], filterOptions: string[]) {
  // Create a list of new filter options
  const filters = filterOptions.map((option) => {
    return { id: option, label: option || 'None' };
  });
  //  Run through selected filters and if there is a pre-selected filter then display it so user can unselect it.
  filterSelections.forEach((selection) => {
    if (!filterOptions.includes(selection)) {
      filters.splice(0, 0, { id: selection, label: selection });
    }
  });
  return filters;
}

export default SearchProjectsData;
