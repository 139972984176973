import { FC, useMemo } from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  AreaSeries,
  Borders,
  ChartLabel,
  DiscreteColorLegend,
  FlexibleXYPlot,
  Hint,
  HorizontalGridLines,
  LabelSeries,
  LineSeries,
  MarkSeries,
  XAxis,
  YAxis,
  // @ts-ignore next-line
} from 'react-vis/dist';
import 'react-vis/dist/style.css';

import {
  ACCEPTED,
  PENDING,
  REJECTED,
  TRENDLINE_EVENT_DEFAULT,
  TRENDLINE_EVENT_ESTIMATE,
  TRENDLINE_EVENT_SELECTED,
} from '../../../constants';
import { entityColors } from '../../../theme/colors';
import theme from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { fontSize } from '../../../utilities/dashboard';
import { parseDate } from '../../../utilities/dates';
import { CostTrendlineEvent } from '../../dashboard/DashboardCharts/DashboardChartsTrend/DashboardChartsTrendUtils';
import {
  CHART_RIGHT_PADDING,
  CHART_TOP_PADDING,
} from '../ChartsAllMilestones/ChartsAllMilestonesUtils';
import {
  CHART_HEIGHT,
  calculateTickTotalForDomain,
  getChartLabelWithCurrency,
} from '../ChartsUtils';

import ChartsCostTrendlineGradientDefinition from './ChartsCostTrendlineGradientDefinition';
import styles from './ChartsCostTrendlineStyles';
import ChartsCostTrendlineTooltip from './ChartsCostTrendlineTooltip/ChartsCostTrendlineTooltip';

type ChartsCostTrendlineProps = {
  classes: Classes<typeof styles>;
  estimate?: number;
  height?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  labels?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  legendLabels: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onClick: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onHoverEvent: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onMouseLeave: any;
  milestoneID: UUID;
  selectedEvent: CostTrendlineEvent | null;
  showTooltip?: boolean;
  target?: number;
  trend?: CostTrendlineEvent[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  xDomain?: any[];
  yDomain?: number[];
  yOrigin?: number;
};

const ChartsCostTrendline: FC<ChartsCostTrendlineProps> = ({
  classes,
  estimate = 0,
  height = CHART_HEIGHT,
  labels,
  legendLabels,
  onClick,
  onHoverEvent,
  onMouseLeave,
  milestoneID,
  selectedEvent = null,
  showTooltip = true,
  target = 0,
  trend,
  xDomain,
  yDomain,
  yOrigin,
}) => {
  // get the list of visible trendlin events
  const visibleTrends = useMemo(
    () => (trend && trend.length > 0 && trend.filter((t) => t.visible)) || [],
    [trend]
  );
  const hoverMarks = useMemo(
    () =>
      visibleTrends.filter(
        (d) =>
          d.item?.status === ACCEPTED || d.item?.status === PENDING || d.item?.status === REJECTED
      ),
    [visibleTrends]
  );

  const hasVisibleTrendData = visibleTrends && visibleTrends.length > 0;

  // Don't change the value of the selected event if the
  // user hasn't selected a different event
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const onValueMouseOver = (v: any) => {
    if (v && (!selectedEvent || v.ids !== selectedEvent.ids)) {
      onHoverEvent(v);
    }
  };

  const costFormat = { short: true, showCurrencySymbol: false };

  return (
    <>
      {labels && (
        <div className={`${classes.rowContainer} ${classes.legendContainer}`}>
          <DiscreteColorLegend
            items={legendLabels}
            orientation="horizontal"
            style={{
              overflow: 'hidden',
              fontFamily: theme.typography.fontFamily,
            }}
          />
        </div>
      )}
      <div className={classes.rowContainer} data-cy="charts-cost-trendline">
        <FlexibleXYPlot
          className={classes.linechart}
          height={height}
          margin={{
            left: labels ? 90 : 32,
            right: labels ? CHART_RIGHT_PADDING : 32,
            bottom: labels ? 60 : 32,
            top: labels ? CHART_TOP_PADDING : 32,
          }}
          onClick={onClick}
          xDomain={xDomain}
          xType="ordinal"
          yDomain={yDomain}
        >
          {hasVisibleTrendData && <HorizontalGridLines tickTotal={6} />}
          {target !== 0 && (
            <HorizontalGridLines
              style={{ strokeWidth: 1, stroke: entityColors.milestone }}
              tickTotal={1}
              tickValues={[target]}
            />
          )}
          {estimate !== 0 && (
            <HorizontalGridLines
              style={{
                strokeDasharray: [1, 2],
                strokeWidth: 1,
                stroke: entityColors.estimate,
              }}
              tickTotal={1}
              tickValues={[estimate]}
            />
          )}
          {hasVisibleTrendData && (
            <AreaSeries
              curve="curveLinear"
              data={visibleTrends}
              fill="url(#stripes)"
              stroke={`${theme.palette.pending}55`}
              style={{ strokeWidth: 1 }}
            />
          )}
          {hasVisibleTrendData && (
            <LineSeries
              curve="curveLinear"
              data={visibleTrends}
              getY={(d: { cost: Cost }) => d.cost}
              stroke={entityColors.estimate}
              strokeWidth="2"
            />
          )}
          {hasVisibleTrendData && (
            <MarkSeries
              className="runningTotal"
              color={entityColors.estimate}
              data={[visibleTrends[0], visibleTrends[visibleTrends.length - 1]]}
              getY={(d: { cost: Cost }) => d.cost}
              size={TRENDLINE_EVENT_ESTIMATE}
            />
          )}
          {hasVisibleTrendData && (
            <MarkSeries
              className="hoverPoints"
              colorType="literal"
              data={hoverMarks}
              getY={(d: { yPoint: Cost }) => d.yPoint}
              size={TRENDLINE_EVENT_DEFAULT}
            />
          )}
          {selectedEvent && (
            <MarkSeries
              className="hoverPoints"
              colorType="literal"
              data={[selectedEvent]}
              getY={(d: { yPoint: Cost }) => d.yPoint}
              size={TRENDLINE_EVENT_SELECTED}
            />
          )}
          {labels &&
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            labels.map((l: any) => (
              <LabelSeries
                key={`${l.x}-${l.label}`}
                className={classes.label}
                data={[l]}
                labelAnchorX={l.anchorX}
                labelAnchorY={l.anchorY}
                style={{ ...l.style, filter: 'url(#blur)', ...theme.typography.number }}
              />
            ))}
          {hasVisibleTrendData && ( // for sensing hover...
            <MarkSeries
              className="hoverPoints"
              data={hoverMarks}
              getY={(d: { yPoint: Cost }) => d.yPoint}
              onSeriesMouseOut={onMouseLeave}
              onValueMouseOver={onValueMouseOver}
              opacity={0}
              size={20}
            />
          )}
          <Borders style={{ all: { fill: '#fff' } }} />
          {labels && (
            <ChartLabel
              className={classes.axisLabel}
              includeMargin={false}
              style={{
                y: 60,
                textAnchor: 'middle',
              }}
              text="Date"
              xPercent={0.5}
              yPercent={1}
            />
          )}
          {labels && (
            <ChartLabel
              className={classes.axisLabel}
              includeMargin={false}
              style={{
                y: -78,
                textAnchor: 'middle',
                transform: 'rotate(-90)',
              }}
              text={getChartLabelWithCurrency('Cost')}
              x="100px"
              xPercent={-0}
              yPercent={0.5}
            />
          )}
          {hasVisibleTrendData && labels ? (
            <XAxis
              style={{ fill: theme.palette.primaryGrey }}
              tickFormat={(dateString: string, j: number) =>
                j % Math.ceil(visibleTrends.length / 6) === 0 ? parseDate(dateString) : null
              }
              tickTotal={6}
            />
          ) : (
            <XAxis hideTicks style={{ fill: theme.palette.primaryGrey }} />
          )}
          {hasVisibleTrendData && labels ? (
            <YAxis
              style={{
                fill: theme.palette.primaryGrey,
                ...theme.typography.number,
                fontSize: fontSize.cost,
              }}
              tickFormat={(v: number) => formatCost(v, costFormat)}
              tickTotal={calculateTickTotalForDomain(yDomain, (value) =>
                formatCost(value, costFormat)
              )}
            />
          ) : (
            <YAxis hideTicks style={{ fill: theme.palette.primaryGrey }} />
          )}
          {selectedEvent && selectedEvent.visible && showTooltip && (
            <Hint
              align={{
                horizontal: Hint.ALIGN.AUTO,
                vertical:
                  !yOrigin || selectedEvent.yPoint > yOrigin ? Hint.ALIGN.TOP : Hint.ALIGN.BOTTOM,
              }}
              // ensure that the y value (and not y0) is the location of the
              // selected event.  Otherwise the tooltip may be in the wrong location
              value={{ ...selectedEvent, y: selectedEvent.yPoint }}
            >
              <ChartsCostTrendlineTooltip
                costTrendlineEvents={trend}
                milestoneID={milestoneID}
                selectedEvent={selectedEvent}
              />
            </Hint>
          )}
        </FlexibleXYPlot>
        <div style={{ height: 0 }}>
          <ChartsCostTrendlineGradientDefinition />
        </div>
      </div>
    </>
  );
};

export const ChartsCostTrendlineStyled = withStyles(styles)(ChartsCostTrendline);

export default ChartsCostTrendlineStyled;
