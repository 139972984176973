import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const ItemSidebarHeaderStyles = (theme: KomodoTheme) =>
  createStyles({
    closeButton: {
      height: theme.iconSizes.medium,
      width: theme.iconSizes.medium,
      background: theme.palette.backgroundWhite,
    },
    // navigation
    displayFlex: {
      display: 'flex',
      marginLeft: 'auto',
    },
    headerButtonContainer: {
      padding: 4,
    },
    itemHeaderProject: { maxWidth: '75%' },
    itemHeaderForecasting: { maxWidth: '85%' },
    itemName: {
      fontSize: 21,
      linesize: 28,
      fontWeight: 500,
      paddingLeft: theme.spacing.generic[3],
      paddingRight: theme.spacing.generic[3],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    itemNameContainer: {
      maxWidth: '90%',
    },
    itemNameLink: {
      cursor: 'pointer',
      display: 'flex',
    },
    optionsContainer: {
      paddingLeft: theme.spacing.generic[7],
      flexGrow: 1,
    },
    summaryHeader: {
      alignItems: 'center',
      backgroundColor: theme.palette.backgroundWhite,
      display: 'inline-flex',
      height: 58,
      width: 'inherit',
      flex: '0 0 auto',
      justifyContent: 'space-between',
    },
    underline: {
      textDecoration: 'underline',
    },
  });

export default ItemSidebarHeaderStyles;
