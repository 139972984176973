import { FC } from 'react';
import * as React from 'react';

import { Popper, Typography } from '@material-ui/core';

import { GetProjectLocationsQuery } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { MAX_SHOWN_PROJECTS, getTopProjects } from '../ExecutiveDashboardUtils';

import ExecutiveDashboardProjectMapLocation from './ExecutiveDashboardProjectMapLocation';
import ExecutiveDashboardProjectMapStyles from './ExecutiveDashboardProjectMapStyles';

type Project = NonNullable<
  GetProjectLocationsQuery['projectLocations']['projectsWithoutLocation']
>['projects'][number];

type ExecutiveDashboardProjectMapHintProps = {
  anchorEl: React.RefObject<HTMLDivElement>;
  classes: Classes<typeof ExecutiveDashboardProjectMapStyles>;
  open: boolean;
  projects: Project[];
};

const ExecutiveDashboardProjectMapHint: FC<ExecutiveDashboardProjectMapHintProps> = ({
  anchorEl,
  classes,
  open,
  projects,
}) => {
  // get the projects with no location
  // limit the length of the list to 10 so
  // it's not too long.
  const topProjects = getTopProjects(projects, MAX_SHOWN_PROJECTS) as Project[];

  return (
    <>
      <Popper
        anchorEl={anchorEl.current}
        className={classes.hintContainer}
        open={open}
        placement="bottom"
      >
        <div>
          <ExecutiveDashboardProjectMapLocation
            classes={classes}
            location="Unassigned Location"
            projects={topProjects}
          />
          {projects.length > MAX_SHOWN_PROJECTS && (
            <div className={classes.lastRow}>
              <Typography className={classes.extraProjects}>{`+${
                projects.length - MAX_SHOWN_PROJECTS
              }`}</Typography>
            </div>
          )}
        </div>
      </Popper>
    </>
  );
};

export default withStyles(ExecutiveDashboardProjectMapStyles)(ExecutiveDashboardProjectMapHint);
