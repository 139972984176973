import { Link } from 'react-router-dom';

import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@material-ui/icons';

import { JoinRoutes } from '../../../api/gqlEnums';
import { ImageDimension } from '../../../generated/graphql';
import { generateSharedPath } from '../../../utilities/routes/links';
import { BabyButton } from '../../scales';
import Thumbnail from '../../shared-widgets/Thumbnail/Thumbnail';

type Props = {
  isShowingMilestoneInfo?: boolean;
  onShowMilestoneInfo?: (value: boolean) => void;
  project?: {
    activeMilestone: { id: UUID; name: string };
    id: UUID;
    name?: string;
    thumbnail?: string;
  };
  showThumbnail?: boolean;
};

export default function ProjectTitle(props: Props) {
  return (
    <div className="flex gap-2 text-type-primary">
      {props.project && (
        <>
          {props.showThumbnail !== false && (
            <Link
              className="cursor-pointer border focus-visible:outline"
              to={generateSharedPath(JoinRoutes.PROJECT, {
                projectId: props.project.id,
              })}
            >
              <Thumbnail
                dimension={ImageDimension._92}
                padding={0}
                size={62}
                thumbnail={props.project?.thumbnail}
              />
            </Link>
          )}
          <div className="flex w-full min-w-0 flex-col">
            <div className="line-clamp-2 type-heading3" data-cy="project-title--project-name">
              {props.project.name ?? ''}
            </div>
            <div className="mt-auto flex items-center gap-2 type-body3">
              <div className="flex h-6 grow items-center truncate">
                <div className="grow truncate" title={props.project.activeMilestone.name}>
                  {props.project.activeMilestone.name}
                </div>
                {props.onShowMilestoneInfo !== undefined && (
                  <BabyButton
                    aria-label={
                      props.isShowingMilestoneInfo ? 'hide milestone info' : 'show milestone info'
                    }
                    icon={
                      props.isShowingMilestoneInfo ? (
                        <KeyboardArrowUpOutlined />
                      ) : (
                        <KeyboardArrowDownOutlined />
                      )
                    }
                    onClick={() => props.onShowMilestoneInfo?.(!props.isShowingMilestoneInfo)}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
