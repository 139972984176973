import { FC, useState } from 'react';
import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import ErrorTooltip from '../../JoinGrid/ErrorTooltip';
import { companyTypes } from '../../login/Signup/SignupPages/CreateCompanyData';
import JoinSelect from '../../Select/JoinSelect/JoinSelect';
import { useUpdateCompany } from '../CompanyHooks';
import CompanyTabStyles from '../CompanyTabStyles';
import { COMPANY_PROFILE } from '../CompanyTabUtils';
import CompanyThumbnail from '../CompanyThumbnail';
import CompanyTabHeader from '../Standards/CompanyTabHeader';

type CompanyTabSettingsProps = {
  classes: Classes<typeof CompanyTabStyles>;
  company: Company;
};

const CompanyTabSettings: FC<CompanyTabSettingsProps> = ({ classes, company }) => {
  const [revisedCompany, setRevisedCompany] = useState(company);
  const updateCompany = useUpdateCompany();
  const sendAnalytics = useSendAnalytics();
  return (
    <>
      <Grid className={classes.overflowAuto} container justify="space-between">
        <Grid item xs={4}>
          <div className="flex flex-wrap items-center justify-between gap-2">
            <CompanyTabHeader title={COMPANY_PROFILE} />
          </div>
          <div className={classes.title}>
            <Typography className={classes.subText} variant="title">
              Company Name*
            </Typography>
            <TextField
              className={classes.paddingBottom}
              data-cy="company-name"
              fullWidth
              id="companyName"
              InputProps={{
                className: classes.input,
                disableUnderline: true,
                endAdornment: !revisedCompany?.name?.trim() && (
                  <ErrorTooltip title="Company Name is required.">
                    <ErrorOutlineIcon className={classes.errorIcon} />
                  </ErrorTooltip>
                ),
              }}
              onBlur={() => {
                if (revisedCompany.name !== company.name && revisedCompany.name?.trim()) {
                  updateCompany({ companyID: company.id, name: revisedCompany.name });
                  sendAnalytics(
                    companyAdminAnalyticsEvent(CompanyAdminEventType.SETTINGS_NAME_CHANGE)
                  );
                } else {
                  setRevisedCompany(company);
                }
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRevisedCompany({ ...revisedCompany, name: event.target.value });
              }}
              value={revisedCompany.name}
            />
            <Typography className={classes.subText} variant="title">
              Company Type
            </Typography>
            <JoinSelect
              cySelect="select-CompanyType"
              entries={companyTypes}
              isCompact={false}
              onChange={(type: string) => {
                setRevisedCompany({ ...revisedCompany, type });
                updateCompany({ companyID: company.id, type });
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.SETTINGS_TYPE_CHANGE)
                );
              }}
              value={revisedCompany.type}
            />
            <Grid item xs={5}>
              <div className={classes.paddingTop} data-cy="Company-logo">
                <Typography className={classes.subText} variant="title">
                  Logo
                </Typography>
                <CompanyThumbnail company={company} updateCompany={updateCompany} />
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabSettings);
