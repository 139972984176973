import { FC, useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Collapse, IconButton, Typography } from '@material-ui/core';

import { currentUserReportVar } from '../../../api/apollo/reactiveVars';
import { EstimateType } from '../../../api/gqlEnums';
import { PermissionResource, Status, UserReportCommentInput } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { getDateString } from '../../../utilities/dates';
import usePermissions from '../../../utilities/permissions/usePermissions';
import NoteCell from '../../CostReport/CostReportList/CostReportListRow/NoteCell/NoteCell';
import ItemsQuickLink from '../../Items/ItemsQuickLink/ItemsQuickLink';
import { VarianceReportComments } from '../../ReportsTab/ReportHooks';
import CollapseIcon from '../../shared-widgets/CollapseIcon';

import { styles } from './ContingencyReportStyles';
import { ContingencyReportColumns, getContingencyComment } from './ContingencyReportUtils';

const EMPTY_CELL = '';

type ContingencyReportRowProps = {
  classes: Classes<typeof styles>;
  columns: ContingencyReportColumns[];
  contingency: ContingencyReport[number];
  allRowsCollapsed: boolean;
  isNotesColumnExpanded: boolean;
  setIsNotesColumnExpanded: (isNotesColumnExpanded: boolean) => void;
  projectID: UUID;
  comments?: VarianceReportComments;
  activeMilestoneID: UUID;
};

const ContingencyReportRow: FC<ContingencyReportRowProps> = ({
  classes,
  contingency,
  columns,
  allRowsCollapsed,
  isNotesColumnExpanded,
  setIsNotesColumnExpanded,
  projectID,
  comments,
  activeMilestoneID,
}) => {
  const [isRowCollapsed, setIsRowCollapsed] = useState(false);
  const [activeCommentRowID, setActiveCommentRowID] = useState<string | null>(null);

  const currentReport = useReactiveVar(currentUserReportVar);

  const { canDelete } = usePermissions();
  const canDeleteUserReportComment = canDelete(PermissionResource.USER_REPORT_NOTES);

  useEffect(() => {
    setIsRowCollapsed(allRowsCollapsed);
  }, [allRowsCollapsed]);

  // if the user closes the current report
  // then collapse the notes column
  useEffect(() => {
    if (!currentReport && isNotesColumnExpanded) setIsNotesColumnExpanded(false);
  }, [currentReport, isNotesColumnExpanded, setIsNotesColumnExpanded]);

  const summaryRowCells = columns.map((column) => {
    const userReportCommentInput: Omit<UserReportCommentInput, 'text'> = {
      markupID: contingency.contingencyID,
      viewParameters: {
        groupBys: [],
        milestoneEstimates: [
          {
            milestoneID: activeMilestoneID,
            estimateType: EstimateType.ACTIVE_ESTIMATE,
          },
        ],
      },
      costs: [contingency.startingCost, contingency.totalPendingCost, contingency.totalDrawnCost],
      categoryIDs: undefined,
      itemID: undefined,
      itemLineID: undefined,
    };
    const comment = comments?.markupComments?.find(
      (c) => c.commentLineID === contingency.contingencyID
    );

    let collapsedChildCommentCount = 0;

    // if the row is collapsed then count up the number of child comments that are hidden
    if (isRowCollapsed)
      collapsedChildCommentCount = contingency.rows.reduce((total, i) => {
        const { comment } = getContingencyComment(contingency, i, comments);
        return total + (comment ? 1 : 0);
      }, 0);

    switch (column) {
      case ContingencyReportColumns.NAME:
        return (
          <div key={column} className={classes.parentWideCell}>
            <IconButton className={classes.icon} onClick={() => setIsRowCollapsed(!isRowCollapsed)}>
              <CollapseIcon className={classes.icon} isCollapsed={isRowCollapsed} />
            </IconButton>
            <Typography className={classes.parentWideCell}>{contingency.name}</Typography>
          </div>
        );
      case ContingencyReportColumns.NOTES: // TODO: update to receive note from backend
        return (
          <div
            key={column}
            className={isNotesColumnExpanded ? classes.expandedNoteCell : classes.collapsedNoteCell}
          >
            <NoteCell
              canAddNote={!activeCommentRowID}
              canDeleteUserReportComment={canDeleteUserReportComment}
              collapsedChildCommentCount={collapsedChildCommentCount}
              currentReportID={currentReport?.id}
              isAddingNoteInRow={activeCommentRowID === contingency.contingencyID}
              isCurrentReportShared={!!currentReport?.shared}
              isNotesColumnExpanded={isNotesColumnExpanded}
              isPrint={false}
              isVarianceReport={false}
              projectID={projectID}
              setIsAddingNote={(value: boolean) =>
                setActiveCommentRowID(value ? contingency.contingencyID : null)
              }
              setNotesColumnExpanded={setIsNotesColumnExpanded}
              userCommentWithCosts={comment}
              userReportCommentInput={userReportCommentInput}
            />
          </div>
        );
      case ContingencyReportColumns.DRAWN:
        return (
          <Typography key={column} className={classes.parentCell}>
            {Number(contingency.totalDrawnCost)
              ? formatCost(-1 * contingency.totalDrawnCost, { rounded: true })
              : EMPTY_CELL}
          </Typography>
        );
      case ContingencyReportColumns.PENDING:
        return (
          <Typography key={column} className={classes.parentCell}>
            {Number(contingency.totalPendingCost)
              ? formatCost(-1 * contingency.totalPendingCost, { rounded: true })
              : EMPTY_CELL}
          </Typography>
        );
      default:
        return <Typography key={column} className={classes.parentCell} />;
    }
  });

  const itemRows = contingency.rows.map((item) => {
    const userReportCommentInput: Omit<UserReportCommentInput, 'text'> = {
      itemID: item.itemID,
      markupID: contingency.contingencyID,
      viewParameters: {
        groupBys: [],
        milestoneEstimates: [
          {
            milestoneID: activeMilestoneID,
            estimateType: EstimateType.ACTIVE_ESTIMATE,
          },
        ],
      },
      costs: [item.drawCost ?? 0],
      categoryIDs: undefined,
      itemLineID: undefined,
    };
    const { comment, commentLineID } = getContingencyComment(contingency, item, comments);

    const drawCostString = item.drawCost
      ? formatCost(-1 * item.drawCost, { rounded: true })
      : undefined;
    const itemRowCells = columns.map((column) => {
      switch (column) {
        case ContingencyReportColumns.NAME:
          return (
            <div key={column} className={classes.itemWideCell}>
              <div className={classes.itemIndent}>
                <ItemsQuickLink
                  hasDefaultWidth={false}
                  item={{
                    id: item.itemID,
                    name: item.name,
                    number: item.number,
                    status: item.status,
                  }}
                />
              </div>
            </div>
          );
        case ContingencyReportColumns.NOTES: // TODO: update to receive note from backend
          return (
            <div
              key={column}
              className={
                isNotesColumnExpanded ? classes.expandedNoteCell : classes.collapsedNoteCell
              }
            >
              <NoteCell
                canAddNote={!activeCommentRowID}
                canDeleteUserReportComment={canDeleteUserReportComment}
                currentReportID={currentReport?.id}
                isAddingNoteInRow={activeCommentRowID === commentLineID}
                isCurrentReportShared={!!currentReport?.shared}
                isNotesColumnExpanded={isNotesColumnExpanded}
                isPrint={false}
                isVarianceReport={false}
                projectID={projectID}
                setIsAddingNote={(value: boolean) =>
                  setActiveCommentRowID(value ? commentLineID : null)
                }
                setNotesColumnExpanded={setIsNotesColumnExpanded}
                userCommentWithCosts={comment}
                userReportCommentInput={userReportCommentInput}
              />
            </div>
          );
        case ContingencyReportColumns.DRAWN:
          return (
            <Typography key={column} className={classes.cell}>
              {(item.status === Status.ACCEPTED && drawCostString) || EMPTY_CELL}
            </Typography>
          );
        case ContingencyReportColumns.PENDING:
          return (
            <Typography key={column} className={classes.cell}>
              {(item.status === Status.PENDING && drawCostString) || EMPTY_CELL}
            </Typography>
          );
        case ContingencyReportColumns.DATE_ACCEPTED:
          return (
            <Typography key={column} className={classes.cell}>
              {(item.status === Status.ACCEPTED &&
                item.dateAccepted &&
                getDateString(new Date(item.dateAccepted))) ||
                EMPTY_CELL}
            </Typography>
          );
        case ContingencyReportColumns.ACCEPTED_BY:
          return (
            <Typography key={column} className={classes.cell}>
              {(item.status === Status.ACCEPTED && item.acceptedBy) || EMPTY_CELL}
            </Typography>
          );
        case ContingencyReportColumns.DATE_CREATED:
          return (
            <Typography key={column} className={classes.cell}>
              {(item.dateCreated && getDateString(new Date(item.dateCreated))) || EMPTY_CELL}
            </Typography>
          );
        case ContingencyReportColumns.CREATED_BY:
          return (
            <Typography key={column} className={classes.cell}>
              {item.createdBy || EMPTY_CELL}
            </Typography>
          );
        default:
          return <Typography key={column} className={classes.cell} />;
      }
    });
    return (
      <div key={item.name} className={classes.itemRow}>
        {itemRowCells}
      </div>
    );
  });

  return (
    <div className={classes.rowBorder}>
      <div className={classes.parentRow}>{summaryRowCells}</div>
      <Collapse in={!isRowCollapsed} timeout="auto" unmountOnExit>
        {itemRows}
      </Collapse>
    </div>
  );
};

export default withStyles(styles)(ContingencyReportRow);
