import { FC } from 'react';

import { Card, CardHeader, Divider } from '@material-ui/core';

import { PROJECT_ITEM_TEMPLATE } from '../../../constants';
import { useEstimateQuery } from '../../../hooks/useEstimateQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import CostEstimate from '../../estimate/CostEstimate';
import { GridVariant } from '../../JoinGrid/types';

import ProjectItemTemplateStyles from './ProjectItemTemplateStyles';

type ProjectItemTemplateProps = {
  templateEstimateID: UUID | undefined;
  classes: Classes<typeof ProjectItemTemplateStyles>;
};

const ProjectItemTemplate: FC<ProjectItemTemplateProps> = ({ classes, templateEstimateID }) => {
  const { data, refetch } = useEstimateQuery(templateEstimateID);
  const projectID = getProjectIdFromUrl();
  const estimate = data?.estimate;
  useEstimateQuery(templateEstimateID);
  const sendAnalytics = useSendAnalytics();

  return (
    <div data-cy="itemEstimateTemplate" id={PROJECT_ITEM_TEMPLATE}>
      <Card className={classes.card} elevation={0} square>
        <CardHeader
          subheader="This controls the default column structure setup when a new item is created. Add or delete columns that you would like to see in the item estimate by default. Updates will only affect the setup of new items, not existing items."
          title="Default Item Template"
        />
        <Divider />
        {!!templateEstimateID && (
          <div className={classes.estimate}>
            <CostEstimate
              activeEstimateID={estimate?.id}
              canViewDetails
              isOption={false}
              projectID={projectID}
              refetchOuter={refetch}
              sendAnalytics={sendAnalytics}
              variant={GridVariant.ITEM_TEMPLATE}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

export default withStyles(ProjectItemTemplateStyles)(ProjectItemTemplate);
