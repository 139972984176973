import { useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';

import { usersSortStateVar } from '../../../api/apollo/reactiveVars';
import { sortCollaborators } from '../../../hooks/useSortCollaborators';
import { isNonNullable } from '../../../utilities/types';
import { sortToTop } from '../../../utilities/utilities';
import UserAvatar from '../../Collaborators/UserAvatar';
import SearchText from '../../Search/SearchText/SearchText';
import useMemoWrapper from '../../useMemoWrapper';
import TableSelectable from '../TableSelectable/TableSelectable';
import { filterEnabledSelectableEntries } from '../TableSelectable/TableSelectableUtils';

import { BoolMap, ToCollaborator, TooltipMap, UsersSortBy, UsersSortKey } from './types';

type ColumnFn = (entry: ToCollaborator) => JSX.Element;

export const getSelectedIndices = (
  entries: ToCollaborator[],
  selectedIds: UUID[],
  disabledIds: UUID[]
) => entries.map(({ id }) => selectedIds.includes(id || '') || disabledIds.includes(id));

export const getSelectableIndices = (entries: ToCollaborator[]) => entries.map(() => true);

export const getDisabledIndices = (entries: ToCollaborator[], disabledIds: UUID[]) =>
  entries.map(({ id }) => disabledIds.includes(id));

export const getIndeterminateIndices = (entries: ToCollaborator[], map: BoolMap) =>
  entries.map(({ id }) => !!map[id]);

export const getTooltipIndices = (entries: ToCollaborator[], tooltipMap: TooltipMap) =>
  entries.map(({ id }) => tooltipMap[id]);

export const getEntries = (fileTableEntries: ToCollaborator[], columnFns: ColumnFn[]) =>
  fileTableEntries.map(
    (entry) => entry && columnFns.map((fn: ColumnFn) => ({ component: fn(entry), key: entry.id }))
  );

export const usersTableGridWidth = [
  '48px',
  'minmax(160px, 3fr)',
  'minmax(140px, 2.5fr)',
  'minmax(120px, 1.5fr)',
];

export type UsersTableHeaderContent = {
  copy: string;
  headerSortKey: UsersSortKey | null;
  key: string;
};

export const membersHeaderContent = [
  {
    copy: ' ',
    headerSortKey: null,
    key: 'icon',
  },
  {
    copy: 'Name',
    headerSortKey: UsersSortKey.NAME,
    key: 'user.name',
  },
  {
    copy: 'Company',
    headerSortKey: UsersSortKey.COMPANY,
    key: 'company.name',
  },
  {
    copy: 'Role',
    headerSortKey: UsersSortKey.ROLE,
    key: 'role.name',
  },
] as UsersTableHeaderContent[];

export type Props = {
  disabledIds?: UUID[];
  entries: ToCollaborator[];
  indeterminateMap?: BoolMap;
  loading?: boolean;
  onEntry: (entry: ToCollaborator) => void;
  onOpenCheckboxTooltip?: () => void;
  onToggleEntry: (entry: ToCollaborator) => void;
  onSelectAll: (ids: string[]) => void;
  onUnselectAll: (ids: string[]) => void;
  searchTerm: string;
  selectedIds: string[];
  sortManager?: {
    sortState: UsersSortBy;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    setSort: (state: any) => void;
  };
  tooltipMap?: TooltipMap;
  topIds?: UUID[];
};

export default function UsersTable(props: Props) {
  const {
    disabledIds = [],
    entries: entriesOuter,
    indeterminateMap = {},
    loading,
    onEntry,
    onOpenCheckboxTooltip,
    onToggleEntry,
    onSelectAll,
    onUnselectAll,
    searchTerm,
    selectedIds,
    sortManager: sortManagerOuter,
    tooltipMap = {},
    topIds,
  } = props;
  let entries = entriesOuter.filter(isNonNullable);

  // Component Fns
  const columnFns = useMemo(() => {
    const thumbnail = (entry: ToCollaborator) => <UserAvatar assignee={entry.user} />;

    const name = (entry: ToCollaborator) => (
      <div
        className="type-table-text"
        onClick={() => onEntry(entry)}
        onKeyDown={() => onEntry(entry)}
        role="button"
        tabIndex={-1}
      >
        <SearchText searchTerm={searchTerm} text={entry.user.name} />
      </div>
    );

    const company = (entry: ToCollaborator) => (
      <div className="type-table-text">
        <SearchText
          searchTerm={searchTerm}
          text={entry.company?.company?.name || entry.user.email.split('@')[1]}
        />
      </div>
    );

    const role = (entry: ToCollaborator) => (
      <div className="type-table-text">
        <SearchText searchTerm={searchTerm} text={entry.role.name} />
      </div>
    );
    return [thumbnail, name, company, role];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, selectedIds]);

  const sortState = useReactiveVar(usersSortStateVar);
  const sortManager = sortManagerOuter || {
    sortState,
    setSort: (state) => usersSortStateVar(state),
  };

  entries = sortCollaborators(sortState, entries);
  if (topIds?.length) {
    entries = sortToTop(entries, topIds);
  }
  if (disabledIds?.length) {
    entries = sortToTop(entries, disabledIds);
  }

  const joinTableEntries = useMemoWrapper(getEntries, entries, columnFns as ColumnFn[]);

  const onToggleTableCell = (index: number) => onToggleEntry(entries[index]);

  const selectedIndices = getSelectedIndices(entries, selectedIds, disabledIds);
  const selectableIndices = getSelectableIndices(entries);
  const disabledIndices = getDisabledIndices(entries, disabledIds);
  const indeterminateIndices = getIndeterminateIndices(entries, indeterminateMap);
  const tooltips = getTooltipIndices(entries, tooltipMap);

  const onTableSelectAll = () =>
    onSelectAll(filterEnabledSelectableEntries(entries, selectableIndices, disabledIndices));

  const onTableUnselectAll = () =>
    onUnselectAll(filterEnabledSelectableEntries(entries, selectableIndices, disabledIndices));

  return (
    <TableSelectable
      columnWidths={usersTableGridWidth}
      disabledIndices={disabledIndices}
      entries={joinTableEntries}
      headerContent={membersHeaderContent}
      indeterminateIndices={indeterminateIndices}
      loading={loading}
      onOpenCheckboxTooltip={onOpenCheckboxTooltip}
      onSelectAll={onTableSelectAll}
      onToggleEntry={onToggleTableCell}
      onUnselectAll={onTableUnselectAll}
      selectableIndices={selectableIndices}
      selectedIndices={selectedIndices}
      sortManager={sortManager}
      tooltips={tooltips}
    />
  );
}
