// Constants of known feature flags. These should directly reflect those in scalar/featureflag.go

export const PROJECT_COMPS = 'PROJECT_COMPS';
export const FORECASTED_COST_REPORT = 'FORECASTED_COST_REPORT';
export const NS_PROCORE_CHANGE_EVENT_INTEGRATION = 'NS_PROCORE_CHANGE_EVENT_INTEGRATION';
export const YC_INSIGHTS_V2 = 'YC_INSIGHTS_V2';
export const YC_COST_TRENDLINE_V2 = 'YC_COST_TRENDLINE_V2';
export const NS_REPORT_DISTRIBUTION = 'NS_REPORT_DISTRIBUTION';
export const CT_ITEM_ASSIST_EXPERIMENT = 'CT_ITEM_ASSIST_EXPERIMENT';
export const CT_BRAND_ASSIST_EXPERIMENT = 'CT_BRAND_ASSIST_EXPERIMENT';
export const NS_OWNER_COSTS = 'NS_OWNER_COSTS';
export const YC_GROUPS = 'YC_GROUPS';
export const NS_REPORT_DISTRIBUTION_EXPERIMENT = 'NS_REPORT_DISTRIBUTION_EXPERIMENT';
export const YC_ITEM_ESTIMATE_DELETION = 'YC_ITEM_ESTIMATE_DELETION';
export const DD_AUTODESK_PCO_INTEGRATION = 'DD_AUTODESK_PCO_INTEGRATION';
export const YC_PROJ_LEAD = 'YC_PROJ_LEAD';
export const NS_MSR_FOLLOWING_ACTIVE_MILESTONE = 'NS_MSR_FOLLOWING_ACTIVE_MILESTONE';
export const NS_REMOVE_S2_ITEM_MARKUP_REFS = 'NS_REMOVE_S2_ITEM_MARKUP_REFS';
export const DD_ITEM_NUMBER_LAYOUT_CHANGES = 'DD_ITEM_NUMBER_LAYOUT_CHANGES';
export const NS_ITEM_ESTIMATE_HISTORY = 'NS_ITEM_ESTIMATE_HISTORY';
export const DD_NAV_SUPPORT_MENU_ITEMS = 'DD_NAV_SUPPORT_MENU_ITEMS';
export const NS_PROGRAM_REPORTING = 'NS_PROGRAM_REPORTING';
