import { FC, useState } from 'react';

import { NOT_CHOSEN, PENDING } from '../../../constants';
import { getHoverDisplay } from '../../../utilities/item-status';
import ItemsIcons from '../../ItemsList/ItemsIcons/ItemsIcons';
import { LARGE, MEDIUM, XLARGE } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { isNotAChosenOption } from '../ItemsUtils';
import { DISABLE_ITEM_STATUS_TOOLTIP_NEW } from '../ItemVisibilityToggle/ItemVisibilityToggleUtils';

import { ItemStatusVariant, ItemStatusVariants } from './ItemsStatusHoverSelectorStyles';
import ItemsStatusHoverSelectorStylesCompact from './ItemsStatusHoverSelectorStylesCompact';
import ItemsStatusHoverSelectorStylesCozy from './ItemsStatusHoverSelectorStylesCozy';

// HELPER FUNCTIONS
export function getStateOptionsList(availableStates: string[], selectedStatus: string) {
  let states = availableStates.filter((state) => {
    return state !== selectedStatus;
  });
  if (selectedStatus === NOT_CHOSEN) {
    states = states.filter((state) => state !== PENDING);
  }

  return states;
}

export type ItemsStatusHoverSelectorProps = {
  availableStates: string[];
  classes:
    | Classes<typeof ItemsStatusHoverSelectorStylesCozy>
    | Classes<typeof ItemsStatusHoverSelectorStylesCompact>;
  disabled: boolean;
  hasItemStatusEditPermission: boolean;
  isHighlightedItem?: boolean;
  isCollapseHeader: boolean;
  isFilteredMilestone: boolean;
  isOption: boolean;
  isPublicItem: boolean;
  setStatusUpdate: (s: Status) => void;
  status: Status;
  variant?: ItemStatusVariant;
};

const ItemsStatusHoverSelector: FC<ItemsStatusHoverSelectorProps> = ({
  availableStates,
  hasItemStatusEditPermission,
  classes,
  disabled,
  isHighlightedItem,
  isCollapseHeader,
  isFilteredMilestone,
  isOption,
  isPublicItem,
  setStatusUpdate,
  status,
  variant = ItemStatusVariants.COZY,
}) => {
  // VARIABLES
  const notChosen = isNotAChosenOption(availableStates, isOption, status);
  const isCompact = variant === ItemStatusVariants.COMPACT;
  // STATE VARIABLES
  const [displayStatusOptions, setDisplayStatusOptions] = useState(false); // displays slide out status options
  const [isClicking, setIsClicking] = useState(false); // for outline style behavior
  const [isFocused, setIsFocused] = useState(false); // for border style behavior
  const disableSelection = isFilteredMilestone || disabled; // get the groupBy settings from the URL

  const canEditItemStatus = hasItemStatusEditPermission && isPublicItem;

  const nestedClass = isCollapseHeader ? classes.nested : '';
  // Only have button outline when tabbing
  const cursorClass = canEditItemStatus ? `${classes.cursor}` : '';
  const buttonClass = isClicking
    ? `${classes.button} ${classes.outlineOnClick} ${cursorClass}`
    : `${classes.button} ${cursorClass}`;
  // Event handler for current status button click
  const buttonClick = () => {
    setIsFocused(!isFocused);
    setDisplayStatusOptions(!displayStatusOptions);
  };
  // Slide status options in and out during open event
  const iconsWrapperClass =
    displayStatusOptions && canEditItemStatus
      ? `${classes.iconsWrapper} ${classes.iconsWrapperVisible}`
      : `${classes.iconsWrapper}  `;
  const innerContainerClass =
    isFocused && canEditItemStatus && availableStates.length !== 1
      ? `${classes.innerContainer} ${classes.innerContainerVisible}`
      : classes.innerContainer;
  // Only display label border when label is in focus
  const labelClass = `${classes.label} ${
    isFocused && canEditItemStatus ? `${classes.onFocusBorder} ${nestedClass}` : ''
  }`;

  // HANDLERS
  // Filters mouse events by disabling functionality when in previous milestone
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const handleDisableSelection = (mouseEvent: any, changeIsClicking = false, clicking = false) => {
    if (disableSelection) {
      return () => {
        // used to disable outlining on mouse event, enable for tabbing
        if (changeIsClicking) {
          setIsClicking(clicking);
        }
      };
    }
    return mouseEvent;
  };
  // Event handler function for label onChange
  const inputChange = () => {
    setDisplayStatusOptions(!displayStatusOptions);
  };
  // Event handler for mouseEnter (true) or mouseLeave (false)
  const mouseHover = (mouseEnter: boolean) => () => {
    setIsFocused(mouseEnter);
    setIsClicking(mouseEnter);
    setDisplayStatusOptions(mouseEnter);
  };

  // GENERATORS
  const stateOptionsListItems = getStateOptionsList(availableStates, status).map(
    (availableState: string) => (
      <li
        key={availableState}
        className={classes.li}
        id={availableState}
        title={getHoverDisplay(notChosen, availableState)} // displays on hover
      >
        <button
          aria-label={`Select ${availableState} Status`}
          className={buttonClass}
          id={availableState}
          onClick={() => {
            setDisplayStatusOptions(false);
            setIsFocused(false);
            setStatusUpdate(availableState as Status);
          }}
          tabIndex={!displayStatusOptions ? -1 : undefined}
          type="button"
        >
          <ItemsIcons
            // we should show Not Chosen as a next status only if this ISN'T the chosen option
            notChosen={notChosen}
            status={availableState}
            variant={isCompact ? MEDIUM : LARGE}
          />
        </button>
      </li>
    )
  );

  const slideOutIcons =
    availableStates.length > 1 ? (
      <div className={iconsWrapperClass}>
        <ul className={classes.ul}>{stateOptionsListItems}</ul>
      </div>
    ) : null;

  const statusIconHoverComponent = (
    <div
      className={`${classes.outerContainer}`}
      data-cy="itemsStatusHoverSelector"
      onMouseLeave={handleDisableSelection(mouseHover(false), true, false)}
    >
      <div className={innerContainerClass}>
        <div className={`${classes.labelContainer}`}>
          <label
            className={labelClass}
            htmlFor="itemsStatusHoverSelector"
            onMouseEnter={handleDisableSelection(mouseHover(true), true, true)}
            title={getHoverDisplay(notChosen, status)} // displays on hover
          >
            <input
              aria-label={`${status} status Selected. Press Enter to display more statuses.`}
              checked={displayStatusOptions}
              className={classes.input}
              id="itemsStatusHoverSelector"
              onChange={handleDisableSelection(inputChange)}
              tabIndex={-1} // remove this to item tabbable in the future
              type="checkbox"
            />
            <button
              key={status}
              className={buttonClass}
              data-cy="button-itemStatusHoverSelector"
              id={status}
              onClick={handleDisableSelection(buttonClick)}
              tabIndex={-1} // remove this to item tabbable in the future
              type="button"
            >
              <ItemsIcons
                notChosen={notChosen}
                status={status}
                variant={isCompact ? LARGE : XLARGE}
              />
            </button>
          </label>
        </div>
        {slideOutIcons}
      </div>
      <div
        className={`${classes.cover} ${nestedClass} ${
          isHighlightedItem && classes.sidebarBackground
        }`}
      />
    </div>
  );

  return isPublicItem ? (
    statusIconHoverComponent
  ) : (
    <NormalTooltip title={DISABLE_ITEM_STATUS_TOOLTIP_NEW}>
      {statusIconHoverComponent}
    </NormalTooltip>
  );
};

export default ItemsStatusHoverSelector;
