import { useCallback, useEffect, useState } from 'react';

import { timelineEvent } from '../../../analytics/analyticsEventProperties';
import { TimelineActivityType } from '../../../api/gqlEnumsBe';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { getTodayAtUtcNoon } from '../../../utilities/dates';
import { usePersistentStates } from '../../../utilities/urlState';
import {
  TimelineData,
  TimelineEvent,
} from '../../shared-widgets/TimelineChart/timeline/timeline.types';
import TimelineChart from '../../shared-widgets/TimelineChart/TimelineChart';
import { getRangeExtendedStr } from '../../shared-widgets/TimelineChart/TimelineChartUtils';
import { useItemsTimeline } from '../../Timeline/hooks/ItemsHook';
import { useTimelineQuery } from '../../Timeline/hooks/TimelineHook';
import { buildTree } from '../../Timeline/Timeline';
import { ActivityNode } from '../../Timeline/Timeline.types';
import {
  computeExpandableMap,
  computeItems,
  getExpandSettings,
  getTimelineUrlConfig,
} from '../../Timeline/TimelineUtils';
import useMemoWrapper from '../../useMemoWrapper';

const DATE_STR_INIT = '0';

export const ACTIVITIES_DEFAULT = [
  TimelineActivityType.EVENT,
  TimelineActivityType.PHASE,
  TimelineActivityType.MILESTONE,
  TimelineActivityType.ACTIVE_MILESTONE,
];

export default function ProjectTimelineChart({ projectID }: { projectID: UUID }) {
  const [settings, setSettings] = usePersistentStates(...getTimelineUrlConfig(projectID));

  // Activities
  const [activityFilter] = useState<TimelineActivityType[]>(ACTIVITIES_DEFAULT);
  const {
    data: recentData,
    loading: timelineLoading,
    previousData,
  } = useTimelineQuery({ projectID, types: activityFilter });
  const data = recentData || previousData;
  const activities = data?.timeline?.activities ?? [];

  const sendAnalytics = useSendAnalytics();

  const { data: itemsData, loading: itemsListLoading } = useItemsTimeline(projectID);
  const itemsList = itemsData?.itemsList?.items ?? [];

  const today = getTodayAtUtcNoon();
  const itemsListAll = itemsList;
  const items = useMemoWrapper(computeItems, itemsListAll, today);
  const withoutDueDateCount = itemsListAll.length - items.length;

  const loading = timelineLoading || itemsListLoading;

  // Min and Max date range values for zoom domain and pickers
  const [, setRange] = useState<string[]>([DATE_STR_INIT, DATE_STR_INIT]);
  const [startStr, setStartStr] = useState<string>(DATE_STR_INIT);
  const [endStr, setEndStr] = useState<string>(DATE_STR_INIT);

  // Expand states
  const { collapse = [], expand = [] } = settings ?? {};
  const expandedMap = useMemoWrapper(computeExpandableMap, collapse, expand);

  const tree = useMemoWrapper(buildTree, activities);

  const mapChildren = (a: ActivityNode): TimelineData => ({
    id: a.id,
    name: a.name,
    type: a.type,
    start: a.startDate,
    end: a.endDate ?? undefined,
    children: a.children.length === 0 ? undefined : a.children.map(mapChildren),
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const computeTimelineData = (tree: any) => tree.map(mapChildren);
  const timelineData: TimelineData[] = useMemoWrapper(computeTimelineData, tree);

  const setStartEnd = ([start, end]: string[]) => {
    setStartStr(start);
    setEndStr(end);
  };

  useEffect(() => {
    if (loading) return;
    const domain = getRangeExtendedStr(timelineData, items, today);
    setStartEnd(domain);
    setRange(domain);
  }, [data, items, loading, timelineData, today]);

  const onZoom = (domain: string[]) => setStartEnd(domain);

  const onExpand = useCallback(
    (id: UUID) => {
      expandedMap[id] = !expandedMap[id];
      const [newCollapse, newExpand] = getExpandSettings(expandedMap);
      setSettings({ collapse: newCollapse, expand: newExpand });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    [collapse, expand, expandedMap]
  );

  const onAnalytics = (event: TimelineEvent) => {
    sendAnalytics(timelineEvent(event, { source: 'timeline' }));
  };

  if (!data || startStr === DATE_STR_INIT || itemsListLoading) return null;

  const chart = (
    <TimelineChart
      activities={activities}
      data={timelineData}
      endDate={endStr}
      expandedMap={{ TIMELINE: true, ITEMS: true }}
      items={items}
      onAnalytics={onAnalytics}
      onExpand={onExpand}
      onZoom={onZoom}
      settings={{ ...settings, projectID }}
      startDate={startStr}
      today={today}
      withoutDueDateCount={withoutDueDateCount}
      zoomLineCompressed
    />
  );

  return chart;
}
