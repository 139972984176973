import { CSSProperties, ComponentProps, FC, UIEventHandler, memo } from 'react';

import TableCellComponent from './TableCell';
import TableHeaderComponent from './TableHeader';
import TableLoader from './TableLoader';
import { SortManager, TableCell, TableHeader } from './types';

type TableProps = {
  columnWidths: string[];
  compact?: boolean;
  entries: TableCell[][];
  headerContent?: TableHeader[];
  loading?: boolean;
  onNeedMoreData?: () => void;
  onScroll?: UIEventHandler<HTMLDivElement>;
  parentScroll?: boolean;
  /**
   * Supports any explicit or implicit row-height supported by `grid-auto-rows` rule.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/grid-auto-rows
   */
  rowHeight?: CSSProperties['gridAutoRows'];
  sortManager?: SortManager;
  stickyFooterEntry?: TableCell[];
};

const Table: FC<TableProps> = ({
  columnWidths,
  compact,
  entries,
  stickyFooterEntry,
  headerContent,
  loading = false,
  onNeedMoreData,
  onScroll,
  parentScroll,
  rowHeight = 'auto',
  sortManager,
}) => {
  // Styles
  const columnWidthStyle = `${columnWidths.join(' ')}`;

  const rows = entries.map((row) => {
    return row.map((entry, j) => (
      <TableCellComponent
        key={`${headerContent?.[j]?.key}-${entry.key}`}
        centered={headerContent?.[j]?.centered}
        entry={entry}
        hasBlackBorderBottom={entry.hasBlackBorderBottom}
        rightAligned={headerContent?.[j]?.rightAligned}
      />
    ));
  });

  let loaderOffset: ComponentProps<typeof TableLoader>['offset'] = 'none';
  if (headerContent) {
    loaderOffset = compact ? 'compact' : 'full';
  }

  let gridTemplateRows = '';
  if (headerContent) gridTemplateRows += 'auto ';
  if (loading) gridTemplateRows += 'auto ';

  return (
    <div
      className={[
        'relative grid min-w-[120px] bg-background-primary',
        parentScroll ? '' : 'max-h-full overflow-auto print:overflow-visible',
      ].join(' ')}
      onScroll={(event) => {
        onScroll?.(event);
        const {
          currentTarget: { clientHeight, scrollHeight, scrollTop },
        } = event;
        if (scrollTop + clientHeight > scrollHeight - clientHeight) onNeedMoreData?.();
      }}
      style={{
        gridAutoRows: rowHeight,
        gridTemplateColumns: columnWidthStyle,
        gridTemplateRows,
      }}
    >
      {headerContent?.map((header, index) => (
        <TableHeaderComponent
          key={`${index.toString()}-${header.copy}`}
          compact={compact}
          dataCy={header.key}
          header={header}
          rightAligned={header.rightAligned}
          sortManager={sortManager}
        />
      ))}
      {loading && <TableLoader negativeMargin={Boolean(entries.length)} offset={loaderOffset} />}
      {rows}
      {stickyFooterEntry &&
        stickyFooterEntry.map((entry, j) => (
          <TableCellComponent
            key={`${headerContent?.[j]?.key}-sticky-footer`}
            centered={headerContent?.[j]?.centered}
            entry={entry}
            hasBlackBorderBottom={entry.hasBlackBorderBottom}
            isStickyBottom
            rightAligned={headerContent?.[j]?.rightAligned}
          />
        ))}
    </div>
  );
};

// TODO: consider memoizing in a different fashion, based on data?
export default memo(Table);
