import { FC, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { noPermissionTooltip } from '../../../utilities/string';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';

type Props = {
  children: ReactNode;
  className?: string;
  hasAccess: boolean;
  to: LinkProps['to'];
  projectName: string;
};

const ProjectLink: FC<Props> = (props) => {
  if (props.hasAccess) {
    return (
      <Link className={props.className} to={props.to}>
        {props.children}
      </Link>
    );
  }

  return (
    <NormalTooltip title={noPermissionTooltip(props.projectName)}>
      <div className={props.className}>{props.children}</div>
    </NormalTooltip>
  );
};

export default ProjectLink;
