import { FC } from 'react';
import MediaQuery from 'react-responsive';

import { useReactiveVar } from '@apollo/client';
import { Typography } from '@material-ui/core';

import { currentUserReportVar } from '../../../api/apollo/reactiveVars';
import { withStyles } from '../../../theme/komodo-mui-theme';
import ExpandReportNotesHeader from '../../CostReport/CostReportList/CostReportListHeader/ExpandReportNotesHeader';
import ReportNoteHeader from '../../CostReport/CostReportList/CostReportListHeader/ReportNoteHeader';

import { styles } from './ContingencyReportStyles';
import { ContingencyReportColumns } from './ContingencyReportUtils';

type ContingencyDrawReportHeaderProps = {
  classes: Classes<typeof styles>;
  columns: ContingencyReportColumns[];
  allRowsCollapsed: boolean;
  setAllRowsCollapsed: (allRowsCollapsed: boolean) => void;
  isNotesColumnExpanded: boolean;
  setIsNotesColumnExpanded: (isNotesColumnExpanded: boolean) => void;
  hasComments: boolean;
};

const ContingencyDrawReportHeader: FC<ContingencyDrawReportHeaderProps> = ({
  classes,
  columns,
  allRowsCollapsed,
  setAllRowsCollapsed,
  isNotesColumnExpanded,
  setIsNotesColumnExpanded,
  hasComments,
}) => {
  const currentReport = useReactiveVar(currentUserReportVar);
  const toggleCollapseText = allRowsCollapsed ? 'Expand All' : 'Collapse All';

  const reportHeaderCells = columns.map((column) => {
    switch (column) {
      case ContingencyReportColumns.NAME:
        return (
          <div key={column} className={classes.wideHeaderCell}>
            <Typography className={classes.wideHeaderText}>{column}</Typography>
            <Typography
              className={classes.collapseAllButton}
              onClick={() => {
                setAllRowsCollapsed(!allRowsCollapsed);
              }}
            >
              {toggleCollapseText}
            </Typography>
          </div>
        );
      case ContingencyReportColumns.NOTES:
        return (
          <div key={column} className={classes.expandRow}>
            <MediaQuery print={false}>
              <div className={classes.expandIconRow}>
                <ReportNoteHeader
                  canExpandVarianceReportNotesColumn={!!currentReport?.id}
                  isNotesColumnExpanded={isNotesColumnExpanded}
                  setNotesColumnExpanded={setIsNotesColumnExpanded}
                />
              </div>
            </MediaQuery>
            <ExpandReportNotesHeader
              hasComments={hasComments}
              isNotesColumnExpanded={isNotesColumnExpanded}
              isPrint={false}
              setNotesColumnExpanded={setIsNotesColumnExpanded}
            />
          </div>
        );
      default:
        return (
          <Typography key={column} className={classes.headerCell}>
            {column}
          </Typography>
        );
    }
  });

  return (
    <>
      <div className={classes.headerRow}>{reportHeaderCells}</div>
    </>
  );
};

export default withStyles(styles)(ContingencyDrawReportHeader);
