import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      ...theme.typography.webLink,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      color: 'unset',
    },
    link: {
      color: theme.palette.brand.middleBlue,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });
