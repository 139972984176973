import { FC } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { categoryLabel } from '../../../utilities/string';
import { AddCategoriesToFilter } from '../../FilterPanel/filterUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';

import ItemsCategoriesStyles from './ItemsCategoriesStyles';

type ItemsCategoriesProps = {
  addCategoriesToFilter?: AddCategoriesToFilter;
  categories?: CategoryWithoutLevels[] | null;
  classes: Classes<typeof ItemsCategoriesStyles>;
  showCategories: boolean;
};

const ItemsCategories: FC<ItemsCategoriesProps> = ({
  addCategoriesToFilter,
  categories: categoriesLocked,
  classes,
  showCategories,
}) => {
  if (!categoriesLocked) return null;
  const categories = [...categoriesLocked].map((o) => ({ ...o }));
  const sortedCategories = categories.sort((a: Category, b: Category) =>
    ((a.categorization && a.categorization.name) || '') >
    ((b.categorization && b.categorization.name) || '')
      ? -1
      : 1
  );

  if (!showCategories || sortedCategories.length === 0) return null;

  const addCategory = (category: Category) => {
    const categorizationID = (category.categorization && category.categorization.id) || '';
    if (showCategories && addCategoriesToFilter && categorizationID.length > 0)
      addCategoriesToFilter(categorizationID, [category]);
  };

  return (
    <div className={classes.categoryChips}>
      {sortedCategories.map((category, index) => (
        <NormalTooltip
          key={category && `${category.number}-${index}-tooltip`}
          title={`${(category.categorization && category.categorization.name) || ''} category `}
        >
          <div
            key={category && `${category.number}-${index}`}
            className={classes.category}
            onClick={() => addCategory(category)}
            onKeyPress={() => addCategory(category)}
            role="button"
            style={{ cursor: 'pointer' }}
            tabIndex={0}
          >
            {categoryLabel(category.name, category.number, category.categorization)}
          </div>
        </NormalTooltip>
      ))}
    </div>
  );
};

export default withStyles(ItemsCategoriesStyles)(ItemsCategories);
