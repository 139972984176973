import { ReactNode, forwardRef, useRef } from 'react';
import { AriaButtonProps, mergeProps, useButton, useFocusRing } from 'react-aria';

import composeRefs from '@seznam/compose-react-refs';

import Badge from '../Badge/Badge';
import { TooltipTriggerProps, pickTooltipTriggerProps } from '../Tooltip';
import { validateDataCy } from '../utils/data-cy';

type Props = TooltipTriggerProps & {
  badge?: string | number;
  'data-cy'?: string;
  disabled?: boolean;
  endIcon?: ReactNode;
  fullWidth?: boolean;
  label: string;
  onClick?: AriaButtonProps['onPress'];
  startIcon?: ReactNode;
  type: 'primary' | 'secondary' | 'tertiary' | 'destructive' | 'warning';
};

export default forwardRef<HTMLButtonElement, Props>(function Button(props, forwardedRef) {
  const ref = useRef(null);

  // Manage focus via useFocusRing since focus has a habit of appearing when clicking via
  // the mouse when we rely solely on useButton.
  const { isFocusVisible, focusProps } = useFocusRing();

  const { buttonProps } = useButton(
    {
      isDisabled: Boolean(props.disabled),
      onPress: props.onClick,
    },
    ref
  );

  const classes = [
    'flex relative shrink-0 grow-0 type-button justify-center items-center gap-1 border-2 h-10 transition',
  ];

  if (props.fullWidth) {
    classes.push('w-full');
  } else {
    classes.push('w-min whitespace-nowrap');
  }

  let roundedClass = 'rounded-3xl';

  if (props.type === 'primary') {
    classes.push(
      'bg-button-primary text-type-secondary border-transparent',
      'hover:enabled:bg-button-primary-hover',
      isFocusVisible ? 'outline border-button-focus-outline' : 'outline-none',
      'disabled:text-type-inactive disabled:bg-button-inactive disabled:border-button-inactive'
    );
  } else if (props.type === 'secondary') {
    classes.push(
      'bg-button-secondary text-type-primary border-button-primary',
      'hover:enabled:bg-button-secondary-hover',
      isFocusVisible ? 'outline !border-transparent' : 'outline-none',
      'disabled:text-type-inactive disabled:border-button-inactive'
    );
  } else if (props.type === 'tertiary') {
    classes.push(
      'text-type-link border-transparent outline-none',
      'hover:enabled:underline',
      isFocusVisible ? 'bg-selection-hover' : 'bg-transparent',
      'disabled:text-type-inactive'
    );
    roundedClass = 'rounded';
  } else if (props.type === 'destructive') {
    classes.push(
      'bg-button-secondary text-button-destructive border-button-destructive',
      'hover:enabled:bg-button-destructive-hover',
      isFocusVisible
        ? 'outline outline-[3px] outline-button-destructive !border-transparent'
        : 'outline-none',
      'disabled:text-type-inactive disabled:border-button-inactive'
    );
  } else if (props.type === 'warning') {
    classes.push(
      'bg-button-warning text-button-warning-text border-transparent',
      'hover:enabled:bg-button-warning-hover',
      isFocusVisible
        ? 'outline outline-[3px] outline-button-warning-focus-outline'
        : 'outline-none',
      'disabled:text-type-inactive disabled:bg-button-inactive'
    );
  }

  let paddingClass = 'px-4';
  if (props.startIcon && props.endIcon) {
    paddingClass = 'px-2';
  } else if (props.startIcon) {
    paddingClass = 'pl-2 pr-4';
  } else if (props.endIcon) {
    paddingClass = 'pl-4 pr-2';
  }

  validateDataCy(props['data-cy'], 'button');

  return (
    <button
      {...mergeProps(pickTooltipTriggerProps(props), buttonProps, focusProps)}
      ref={composeRefs(ref, forwardedRef)}
      className={[...classes, paddingClass, roundedClass].join(' ')}
      data-cy={props['data-cy']}
    >
      {props.startIcon}
      {props.label}
      {props.endIcon}
      {props.badge !== undefined && props.badge !== '' ? (
        <Badge inset={{ right: 8 }}>{props.badge}</Badge>
      ) : null}
    </button>
  );
});
