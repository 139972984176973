import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { formatCost } from '../../../../../utilities/currency';
import { EMPTY_COST } from '../../../../../utilities/string';

import styles from './CostCellStyles';

export default withStyles(styles)(
  ({ classes, project }: { classes: Classes<typeof styles>; project: ForecastingProject }) => (
    <div className={classes.container}>
      {formatCost(project.estimate ?? EMPTY_COST, { rounded: true })}
    </div>
  )
);
