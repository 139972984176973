import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Check } from '@material-ui/icons';

import {
  reportDistributionEventTypes,
  userReportDistributionEvent,
} from '../../analytics/analyticsEventProperties';
import { ToastType } from '../../api/gqlEnums';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { setToast } from '../../hooks/useToastParametersLocalQuery';
import FourOhFour from '../FourOhFour';
import { useUnsubscribeReportDistribution } from '../ReportsTab/ReportHooks';
import { Button } from '../scales';

const ReportDistributionUnsubscribe = () => {
  const { reportDistributionId } = useParams();
  const [isUnsubscribeCompleted, setIsUnsubscribeCompleted] = useState(false);

  const sendAnalytics = useSendAnalytics();
  const unsubscribe = useUnsubscribeReportDistribution({
    onCompleted: () => {
      setToast(
        { message: 'Successfully unsubscribed from report distribution' },
        ToastType.SUCCESS
      );
      setIsUnsubscribeCompleted(true);
      // analytics
      const params = new URLSearchParams(window.location.search);
      const reportType = params.get('utm_report_type');
      sendAnalytics(
        userReportDistributionEvent(reportDistributionEventTypes.REPORT_DISTRIBUTION_UNSUBCRIBE, {
          reportType,
        })
      );
    },
  });

  if (!reportDistributionId) {
    return <FourOhFour message="Could not get report distribution from URL" />;
  }

  return (
    <div className="p-6">
      <div className="mb-6 type-heading1">Unsubscribe from Report Distribution</div>
      <div className="mb-6 type-body1">
        No longer want to receive emails from this report distribution?
      </div>
      <Button
        disabled={isUnsubscribeCompleted}
        label={isUnsubscribeCompleted ? 'Unsubscribed' : 'Unsubscribe'}
        onClick={() => unsubscribe(reportDistributionId)}
        startIcon={isUnsubscribeCompleted ? <Check /> : undefined}
        type="secondary"
      />
    </div>
  );
};

export default ReportDistributionUnsubscribe;
