import { BusinessOutlined, LocationOnOutlined } from '@material-ui/icons';

import { JoinRoutes } from '../../../api/gqlEnums';
import { ImageDimension, SearchResultType } from '../../../generated/graphql';
import { generateSharedPath } from '../../../utilities/routes/links';
import { formatNumber } from '../../../utilities/string';
import useCoreSearchPermissions from '../../HomeTab/Search/hooks/useCoreSearchPermissions';
import { useSearchProjectsQuery } from '../../HomeTab/Search/projects/hooks/useSearchProjectsQuery';
import { DEFAULT_QUERY as DEFAULT_PROJECTS_SEARCH_QUERY } from '../../HomeTab/Search/projects/hooks/useSearchProjectsQueryParams';
import { Button } from '../../scales';
import Thumbnail from '../../shared-widgets/Thumbnail/Thumbnail';
import { SpinnerIcon } from '../icons';

import SearchResultLink from './SearchResultLink';

export default function ProjectSearchResults(props: {
  onNavigate: (selection?: { projectID?: UUID }) => void;
  searchText: string;
}) {
  const { hasAllProjectsAccess } = useCoreSearchPermissions();

  const { data, previousData, loading } = useSearchProjectsQuery({
    ...DEFAULT_PROJECTS_SEARCH_QUERY,
    search: props.searchText,
    searchResultType: hasAllProjectsAccess ? SearchResultType.ALL : SearchResultType.MY,
    pagination: { limit: 5, offset: 0 },
  });

  const projects = data?.searchProjects?.data ?? previousData?.searchProjects?.data ?? [];
  const projectsCount = data?.searchProjects?.total ?? previousData?.searchProjects?.total ?? 0;

  return (
    <div className="flex flex-col gap-4">
      {projectsCount > 0 && (
        <div className="flex flex-col gap-2">
          <div className="flex h-4 items-center gap-1">
            <div className="type-body3">Relevant projects</div>
            {loading && <SpinnerIcon className="icon-md" />}
          </div>
          {projects.map((project) => (
            <SearchResultLink
              key={project.id}
              hasAccess={project.hasAccess}
              onClick={() => props.onNavigate({ projectID: project.id })}
              to={generateSharedPath(JoinRoutes.PROJECT, { projectId: project.id })}
            >
              <div className="border">
                <Thumbnail
                  dimension={ImageDimension._92}
                  padding={0}
                  size={58}
                  thumbnail={project.thumbnail}
                />
              </div>
              <div>
                <div className="type-heading3">{project.name}</div>
                <div className="flex items-center gap-1">
                  <div className="flex icon-sm">
                    <LocationOnOutlined fontSize="inherit" />
                  </div>
                  <div className="type-body3">{project.location}</div>
                </div>
                <div className="flex items-center gap-1">
                  <div className="flex icon-sm">
                    <BusinessOutlined fontSize="inherit" />
                  </div>
                  <div className="type-body3">{project.type}</div>
                </div>
              </div>
            </SearchResultLink>
          ))}
        </div>
      )}
      {projectsCount === 0 && !loading && <div className="muted type-body2">No projects found</div>}
      {projectsCount > 1 && (
        <div className="flex w-full justify-center">
          <Button
            label={`See all ${formatNumber(projectsCount)} projects`}
            onClick={() => props.onNavigate()}
            type="primary"
          />
        </div>
      )}
    </div>
  );
}
