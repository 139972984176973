import { FC } from 'react';

import { Add, DeleteOutline } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { COLLAPSE } from '../../../../constants';
import { OrgNode } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { CollapseSettings } from '../../../../utilities/urlState';
import { TreeEntry } from '../../../../utilities/utilities';
import { IconButton, Tooltip } from '../../../scales';
import CollapseEntry from '../../../Select/CollapseEntry/CollapseEntry';

import OrgNodeNameInput from './OrgNodeNameInput';

interface OrganizationNodesProps {
  node: TreeEntry<OrgNode>;
  nodes: TreeEntry<OrgNode>[];
  levels: string[];
  onAddNode: (parentNodeID: string | null) => void;
  onRemoveNode: (node: TreeEntry<OrgNode>) => void;
  setCollapse: (collapsed: boolean, ids: string[], id: string) => void;
  settings: CollapseSettings;
}

const OrganizationNodes: FC<OrganizationNodesProps> = ({
  node,
  nodes,
  levels,
  onAddNode,
  onRemoveNode,
  setCollapse,
  settings,
}) => {
  const sendAnalytics = useSendAnalytics();
  const nodeId = node.id;
  const levelIndex = node.depth;
  // Disable deleting the last remaining node in the list
  const disableDelete = node.depth === 0 && nodes.length === 1;
  const addTooltip = levelIndex >= levels.length - 1 ? '' : `Add ${levels[levelIndex + 1]}`;
  const deleteTooltip = disableDelete ? '' : `Delete ${levels[levelIndex]}`;

  return (
    <CollapseEntry
      key={nodeId}
      collapsed={settings[COLLAPSE].includes(nodeId)}
      endIcon={
        <div className="flex gap-2">
          <Tooltip content={addTooltip} placement="left">
            <IconButton
              aria-label="add node"
              disabled={levelIndex >= levels.length - 1}
              icon={<Add />}
              onClick={() => {
                onAddNode(nodeId);
                setCollapse(false, [nodeId], nodeId);
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_ADD_NODE, {
                    levelName: levels[levelIndex + 1],
                    levelNumber: levelIndex + 1,
                  })
                );
              }}
              type="secondary"
            />
          </Tooltip>
          <Tooltip content={deleteTooltip} placement="left">
            <IconButton
              aria-label="delete node"
              disabled={disableDelete}
              icon={<DeleteOutline color={disableDelete ? 'disabled' : 'error'} />}
              onClick={() => {
                onRemoveNode(node);
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_DELETE_NODE, {
                    node: node.name,
                  })
                );
              }}
              type="secondary"
            />
          </Tooltip>
        </div>
      }
      expandOnCollapseIconOnly
      hasNoBackground
      hasNoBorder
      hasPadding={false}
      hasZeroMarginContainer
      isInactive={false}
      setCollapse={(collapsed: boolean) => {
        setCollapse(collapsed, [nodeId], nodeId);
      }}
      showCollapseIcon={Boolean(node.entries?.length)}
      title={
        <div className="flex w-full flex-col p-1">
          <OrgNodeNameInput
            levelName={levels[levelIndex]}
            nodeID={node.id}
            nodeName={node.name}
            nodes={nodes}
          />
        </div>
      }
    >
      {node.entries && (
        <div className="flex w-full flex-col pl-8">
          {node.entries.map((childNode, _, nodes) => (
            <OrganizationNodes
              key={childNode.id}
              levels={levels}
              node={childNode}
              nodes={nodes}
              onAddNode={onAddNode}
              onRemoveNode={onRemoveNode}
              setCollapse={setCollapse}
              settings={settings}
            />
          ))}
        </div>
      )}
    </CollapseEntry>
  );
};

export default OrganizationNodes;
