import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { FC, KeyboardEvent } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { PermissionResource } from '../../../generated/graphql';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { localeDateFormat, maskFn, placeholderDefault } from '../../../utilities/dates';
import { computeMilestonesSorted } from '../../../utilities/milestones';
import usePermissions, {
  getItemLinesPermissionResource,
} from '../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { isTextValid } from '../../../utilities/string';
import { getProjectIdFromUrl } from '../../../utilities/url';
import InputsSelectAssigneeData from '../../Inputs/InputsSelectAssignee/InputsSelectAssigneeData';
import InputsTextAreaStyled from '../../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';
import { useMilestonesQuery } from '../../Milestones/hooks';
import MilestoneSelect from '../../Select/MilestoneSelect';
import SelectEventItemData from '../../Select/SelectEvent/SelectEventItemData';
import CostImpact from '../../shared-widgets/CostImpact';
import useMemoWrapper from '../../useMemoWrapper';

import DialogsNewItemDetailsStyles from './DialogsNewItemDetailsStyles';
import { getAssigneeEmail } from './DialogsNewItemUtils';

type DialogsNewItemDetailsProps = {
  classes: Classes<typeof DialogsNewItemDetailsStyles>;
  hasParentItem: boolean;
  isScenario?: boolean;
  item: DraftItem;
  parentItemID?: UUID | undefined;
  parentItemIsDraft?: boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onChange: (event: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onSubmit: (event?: any) => void;
};

const DialogsNewItemDetails: FC<DialogsNewItemDetailsProps> = ({
  classes,
  hasParentItem,
  isScenario,
  item,
  parentItemID,
  parentItemIsDraft,
  onChange,
  onSubmit,
}) => {
  const projectId = getProjectIdFromUrl();
  const {
    data: { project },
  } = useProjectPropsQuery(projectId);
  const activeMilestoneId = project?.activeMilestone.id;

  const { assigneeEmail, categories, dueDate, milestoneID, name } = item;

  // PERMISSIONS
  const { canView, canEdit, inTrade } = usePermissions({ trades: categories });
  const canEditItemMilestone = canEdit(PermissionResource.ITEM_MILESTONE_AND_MEETINGS);
  const canEditItemCost = canEdit(getItemLinesPermissionResource(inTrade));
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const canEditItemAssignees = canEdit(PermissionResource.ITEM_ASSIGNEES);
  const canViewEvent = canView(PermissionResource.ITEM_MILESTONE_AND_MEETINGS);
  const canEditEvent = canEdit(PermissionResource.ITEM_MILESTONE_AND_MEETINGS);

  const showAssigneeSelector = !isScenario && canEditItemAssignees;
  const showMilestoneSelector = !isScenario && canEditItemMilestone && !hasParentItem;

  const { data: { milestones = [] } = {} } = useMilestonesQuery(projectId, false);
  const allMilestones = useMemoWrapper(computeMilestonesSorted, milestones, activeMilestoneId);

  const handleKeydown = (evt: KeyboardEvent) => {
    if (evt.key === 'Enter' && !evt.shiftKey) {
      // if the name is valid then submit the item for creation
      if (isTextValid(name)) {
        if (evt.target instanceof HTMLElement) {
          evt.target.blur();
        }
        onSubmit();
      } else {
        // if the item has an invalid name
        // we need to ignore the new line
        // created by the enter key
        evt.preventDefault();
      }
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.column1}>
        <div className={`${classes.inputRow} ${classes.firstRow}`}>
          <Typography className={classes.caption} variant="caption">
            Name *
          </Typography>
          <TextField
            data-cy="input-itemLikeTitle"
            fullWidth
            InputProps={{
              className: classes.inputHeight,
              disableUnderline: true,
            }}
            inputRef={(input) => {
              if (input) {
                input.focus();
              }
            }}
            multiline
            onChange={(evt) => {
              onChange({ name: evt.target.value });
            }}
            onKeyDown={handleKeydown}
            placeholder="Title this item..."
            value={name}
          />
        </div>
        <div className={`${classes.fillHeight} ${classes.inputRow}`}>
          <Typography className={classes.caption} variant="caption">
            Description
          </Typography>
          <InputsTextAreaStyled
            editable
            onChangeComplete={(description: string, descriptionStyled: string) => {
              onChange({ description, descriptionStyled });
            }}
            placeholder="Describe this item..."
            textStyled={item.descriptionStyled}
          />
        </div>
      </div>
      <div className={classes.column2}>
        {canEditItemCost && shouldDisplayCosts && (
          <div className={`${classes.inputRow}`}>
            <Typography className={classes.caption} variant="caption">
              Estimate (order of magnitude)
            </Typography>
            <CostImpact
              compact
              editable
              expanded
              fullWidth
              onChange={(cost) => {
                onChange({ costImpact: cost });
              }}
              onKeyDown={(event) => {
                if (name && event.key === 'Enter') {
                  onSubmit();
                }
              }}
              placeholder="Add estimate"
              value={undefined}
            />
          </div>
        )}
        {showAssigneeSelector && (
          <div className={classes.inputRow}>
            {canEditItemCost && shouldDisplayCosts && <div className={classes.firstRow} />}
            <Typography className={classes.caption} variant="caption">
              Assignee
            </Typography>
            <InputsSelectAssigneeData
              disabled={false}
              isDraft={hasParentItem && parentItemIsDraft}
              itemID={parentItemID}
              onChange={(email) => onChange({ assigneeEmail: getAssigneeEmail(email) })}
              projectId={projectId}
              selected={assigneeEmail}
            />
          </div>
        )}
        {canEditItemAssignees && (
          <div className={classes.inputRow}>
            {showAssigneeSelector && <div className={classes.firstRow} />}
            <Typography className={classes.caption} variant="caption">
              Due Date
            </Typography>
            <div className={classes.inputDateSelect}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  animateYearScrolling={false}
                  clearable
                  data-cy="input-date-picker"
                  disabled={!canEditItemAssignees}
                  format={localeDateFormat}
                  FormHelperTextProps={{ classes: { error: classes.error } }}
                  fullWidth
                  InputProps={{
                    classes: { input: `picker ${classes.picker}` },
                    disableUnderline: true,
                  }}
                  invalidDateMessage=" "
                  keepCharPositions
                  keyboard
                  KeyboardButtonProps={{
                    classes: { root: classes.datePickerIcon },
                  }}
                  mask={maskFn}
                  minDateMessage=""
                  onChange={(value) => {
                    onChange({ dueDate: value });
                  }}
                  onKeyDown={(evt: KeyboardEvent) => {
                    if (evt.key === 'Enter' && evt.target instanceof HTMLElement) {
                      evt.target.blur();
                    }
                  }}
                  placeholder={placeholderDefault}
                  value={dueDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        )}
        {showMilestoneSelector && (
          <div className={classes.inputRow}>
            {allMilestones && allMilestones.length > 0 && (
              <div className={classes.milestoneRow}>
                <Typography className={classes.caption} variant="caption">
                  Milestone
                </Typography>
                <div className={classes.inputSelect}>
                  <MilestoneSelect
                    milestones={allMilestones}
                    onChangeMilestone={(newmilestoneID) =>
                      onChange({ milestoneID: newmilestoneID, bucketID: null })
                    }
                    selectedMilestone={milestoneID}
                  />
                </div>
              </div>
            )}

            {canViewEvent && (
              <div className={`${classes.inputRow}`}>
                <Typography className={classes.caption} variant="caption">
                  Events
                </Typography>
                <div>
                  <SelectEventItemData
                    disabled={!canEditEvent}
                    item={item}
                    maxChips={2}
                    onChange={(activityIDs) => onChange({ activityIDs })}
                    projectID={projectId}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(DialogsNewItemDetailsStyles)(DialogsNewItemDetails);
