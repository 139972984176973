import { HTMLAttributes, forwardRef, useRef } from 'react';
import { useTextField } from 'react-aria';

import composeRefs from '@seznam/compose-react-refs';

import { validateDataCy } from '../utils/data-cy';

type TextAreaProps = {
  id?: string;
  'data-cy'?: string;
  disabled?: boolean;
  errorMessage?: string;
  isResizable?: boolean;
  label?: string;
  onBlur?: HTMLAttributes<HTMLTextAreaElement>['onBlur'];
  onChange?: (newValue: string) => void;
  onClear?: () => void;
  onKeyDown?: Parameters<typeof useTextField>[0]['onKeyDown'];
  placeholder?: string;
  value?: string;
};

export default forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(
  props,
  forwardedRef
) {
  const { errorMessage } = props;
  const ref = useRef<HTMLTextAreaElement>(null);
  const { labelProps, inputProps, errorMessageProps } = useTextField(
    {
      ...props,
      // @ts-expect-error because e've forced the type of onBlur here to ensure
      // we get e.currentTarget being an instanceof HTMLInputElement. This is so
      // that we can acces the `value` a la onBlur={e => onUpdateFoo(e.currentTarget.value)}
      onBlur: props.onBlur,
      onKeyDown: (e) => {
        props.onKeyDown?.(e);
      },
      isDisabled: Boolean(props.disabled),
      ...(errorMessage ? { validationState: 'invalid', 'aria-errormessage': errorMessage } : {}),
      inputElementType: 'textarea',
    },
    ref
  );

  const hasLabel = Boolean(props.label);
  const hasClearButton = Boolean(props.onClear && !props.disabled);

  validateDataCy(props['data-cy'], 'text-area');

  return (
    <div className="w-full">
      <div className="flex flex-col gap-0.5 ">
        {(hasLabel || hasClearButton) && (
          <div className="flex">
            <label {...labelProps} className="mr-auto text-type-primary type-label">
              {props.label}
            </label>
            {hasClearButton && (
              <button className="rounded-md type-small-link" onClick={props.onClear} type="button">
                Clear
              </button>
            )}
          </div>
        )}
        <textarea
          {...inputProps}
          ref={composeRefs(ref, forwardedRef)}
          className={`block h-20 rounded-md border bg-background-primary p-2 text-type-primary type-body1 placeholder:text-type-inactive focus:outline disabled:bg-button-inactive disabled:text-type-inactive ${
            errorMessage ? 'border-type-error' : ''
          } ${props.isResizable ? '' : 'resize-none'}`}
          data-cy={props['data-cy']}
        />
        {errorMessage && (
          <div {...errorMessageProps} className="cursor-default text-type-error type-label">
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
});
