import { FC, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { IconButton, Snackbar, Typography } from '@material-ui/core';
import { SnackbarOrigin } from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Check, Close, Info } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { ItemShareKey } from '../../../analytics/analyticsEventProperties';
import {
  currentUserReportVar,
  defaultToastValue,
  newItemDialogOpenVar,
  toastParametersVar,
} from '../../../api/apollo/reactiveVars';
import { ToastType } from '../../../api/gqlEnums';
import { REPORT_BAR_MIN_HEIGHT } from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { closeToast } from '../../../hooks/useToastParametersLocalQuery';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getItemStatusFromString } from '../../../utilities/item-status';
import { generateSharedPath } from '../../../utilities/routes/links';
import { ItemStatusIcon } from '../../dragon-scales';
import ItemsListItemPrivateBadge from '../../Items/ItemsListItem/ItemsListItemPrivateBadge/ItemsListItemPrivateBadge';
import { generateTitle } from '../../Items/ItemsListItem/ItemsListItemUtils';
import { isPrivateVisibility } from '../../Items/ItemsUtils';
import ItemsIcons from '../../ItemsList/ItemsIcons/ItemsIcons';
import { LARGE } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import { Chip } from '../../scales';
import JoinLinkify from '../../shared-widgets/JoinLinkify';

import styles from './AlertsSnackBarStyles';
import { getItemText, openImportHelp } from './AlertsSnackBarUtils';

interface AlertsSnackBarProps {
  classes: Classes<typeof styles>;
}

const toastTitleLength = 30;

const AlertsSnackBar: FC<AlertsSnackBarProps> = ({ classes }) => {
  // Hooks
  const sendAnalytics = useSendAnalytics();

  // Functions
  const onCloseNewItem = () => {
    closeToast();
    newItemDialogOpenVar(false);
  };

  // Content
  const toast = useReactiveVar(toastParametersVar) || { ...defaultToastValue };
  const { icon, item, message, toastText, show, type } = toast;
  const { name, number } = item || {};

  const isPrivate = isPrivateVisibility(item?.visibility);

  // CUSTOMIZE THE CONTENT AND STYLES FOR THE SNACKBAR
  let anchorOrigin: SnackbarOrigin | undefined;
  let toastMessage: ReactElement | string | undefined;
  let contentClassName: string | undefined;
  let iconClassName: string | undefined;
  switch (type) {
    case ToastType.NONE:
      return null;
    // Detach Option Toast
    case ToastType.DETACH_OPTION:
      anchorOrigin = {
        horizontal: 'center',
        vertical: 'top',
      };
      contentClassName = 'detachOption';
      toastMessage = item ? (
        <span className={classes.detachOptionToast}>
          <ItemStatusIcon value={getItemStatusFromString(item.status)} />
          {isPrivateVisibility(item.visibility) && <Chip text="Draft" />}
          <span className={classes.newItemUpdateToastMessage}>
            <span className={classes.newItemUpdateToastDescription} data-cy="link-itemSnackbar">
              <Link
                className={classes.newItemUpdateToastDescription}
                data-cy="link-itemSnackbar"
                onClick={closeToast}
                to={generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
                  projectId: item.project.id,
                  itemId: item.id,
                })}
              >
                {getItemText(item, toastTitleLength)}
              </Link>
            </span>
            <span className={classes.newItemUpdateToastText}> was detached from this item</span>
          </span>
        </span>
      ) : (
        ''
      );
      break;
    // New Item Toast
    case ToastType.COPY_ITEM:
    case ToastType.NEW_ITEM:
      anchorOrigin = {
        horizontal: 'center',
        vertical: 'top',
      };
      contentClassName = 'newItemUpdate';
      toastMessage = item ? (
        <span className={classes.newItemUpdateToastMessage}>
          <ItemsIcons status={item.status} variant={LARGE} />
          {isPrivate && (
            <ItemsListItemPrivateBadge analyticsKey={ItemShareKey.SIDEBAR} hideBadge={false} />
          )}
          <span className={classes.newItemUpdateToastMessage}>
            <span className={classes.newItemUpdateToastDescription}>
              <Link
                className={classes.newItemUpdateToastDescription}
                data-cy="snackbar-item-description"
                onClick={onCloseNewItem}
                to={generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
                  projectId: item.project.id,
                  itemId: item.id,
                })}
              >
                {generateTitle({ name: name?.substring(0, toastTitleLength), number })}
                {name && name.length > toastTitleLength ? '...' : ''}
              </Link>
            </span>
            <span className={classes.newItemUpdateToastText}>
              {` created`}
              {type === ToastType.NEW_ITEM && item.milestone && ` in ${item.milestone.name}`}
              {type === ToastType.COPY_ITEM && item.project.name && ` in ${item.project.name}`}
            </span>
          </span>
        </span>
      ) : (
        ''
      );
      break;
    case ToastType.DEFAULT:
    case ToastType.BULK_ITEM_UPDATE:
      anchorOrigin = {
        horizontal: 'center',
        vertical: 'top',
      };
      contentClassName = 'bulkItemUpdate';
      toastMessage = (
        <span className={classes.bulkItemUpdateToastMessage} data-cy="snackbar-label">
          {icon || <Info />}
          &nbsp;&nbsp;
          <span className={`${contentClassName}ToastMessage`}>
            <span className={classes.bulkItemUpdateToastDescription} />
            {message}
          </span>
        </span>
      );
      break;
    case ToastType.SERVER_ERROR:
      anchorOrigin = {
        horizontal: 'center',
        vertical: 'top',
      };
      contentClassName = 'serverError';
      toastMessage = (
        <div>
          <Typography>
            <JoinLinkify text={message} />
          </Typography>
        </div>
      );
      break;
    case ToastType.IMPORT_ESTIMATE:
      anchorOrigin = {
        horizontal: 'center',
        vertical: 'top',
      };
      toastMessage = (
        <div className={classes.importEstimateContainter}>
          <span>
            <ErrorOutlineIcon className={classes.errorIcon} />
          </span>
          <Typography className={classes.importEstimateToastText}>{message}</Typography>
        </div>
      );
      iconClassName = 'importEstimateIcon';
      contentClassName = 'importEstimate';
      break;
    case ToastType.EXPORTING_EXCEL:
      anchorOrigin = {
        horizontal: 'center',
        vertical: 'top',
      };
      toastMessage = (
        <div>
          <Typography className={classes.exportExcelToast}>{message}</Typography>
        </div>
      );
      contentClassName = 'exportExcel';
      break;
    case ToastType.IMPORT_ESTIMATE_WINEST_EST:
      anchorOrigin = {
        horizontal: 'center',
        vertical: 'top',
      };
      toastMessage = (
        <div>
          <Typography className={classes.importEstimateToastText}>{message}</Typography>
          <span
            className={classes.exportHelpLink}
            onClick={() => openImportHelp(sendAnalytics)}
            onKeyDown={() => openImportHelp(sendAnalytics)}
            role="button"
            tabIndex={-1}
          >
            Click here to learn how to export an .xml file from WinEst
          </span>
        </div>
      );
      contentClassName = 'importEstimate';
      break;

    case ToastType.EXCLUDE_INCLUDE_PROJECT_COMP:
      anchorOrigin = {
        horizontal: 'center',
        vertical: 'top',
      };
      toastMessage = (
        <div>
          <span className={classes.nameInBold}>{toastText?.[0]?.text || ''}</span>
          <span>{message}</span>
          <span className={classes.nameInBold}>{toastText?.[1]?.text}</span>
        </div>
      );
      contentClassName = 'bulkItemUpdate';
      break;
    case ToastType.SUCCESS:
      anchorOrigin = {
        horizontal: 'center',
        vertical: 'top',
      };

      contentClassName = 'success';

      toastMessage = (
        <div className={classes.successToastMessage} data-cy="toast-success">
          <div className={classes.successToastIcon}>
            <Check />
          </div>
          <div>{message}</div>
        </div>
      );
      break;
    // Default to displaying a generic toast message
    default:
      anchorOrigin = {
        horizontal: 'center',
        vertical: 'bottom',
      };
      toastMessage = message;
      contentClassName = '';
  }

  return createPortal(
    <Snackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={4000}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      onClose={closeToast}
      open={show}
      // 49 is the offset required to move it below the frame title bar
      // then we need to move the snackbar below the report bar if it's open
      style={{ zIndex: 10000, top: currentUserReportVar() ? 49 + REPORT_BAR_MIN_HEIGHT * 2 : 49 }}
    >
      <SnackbarContent
        action={
          <IconButton
            key="close"
            aria-label="Close"
            classes={{ root: classes[iconClassName as keyof typeof classes] }}
            color="inherit"
            onClick={closeToast}
          >
            <Close />
          </IconButton>
        }
        aria-describedby="client-snackbar"
        classes={{
          action: classes.action,
          root: classes.root,
        }}
        className={classes[`${contentClassName}Toast` as keyof typeof classes]}
        message={toastMessage}
      />
    </Snackbar>,
    document.body
  );
};

export default withStyles(styles)(AlertsSnackBar);
