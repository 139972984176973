import { isMilestoneVariant } from '../../actions/gridAnalytics';
import { TermKey } from '../../api/gqlEnums';
import { S1, USCENTS_CELL } from '../../constants';
import { Scale } from '../../enums';
import { EstimateArmatureQuery, MarkupDisplayType, MarkupType } from '../../generated/graphql';
import { getSubtotalValue } from '../estimate/EstimateAccordion/SectionCollapse';

import {
  EstimateArmatureSubtotals,
  EstimateGridPermissions,
  Footer,
  GridController,
  GridType,
  GridVariant,
  MarkupGridState,
} from './types';

export const getControllerMarkups = (
  estimate: EstimateArmatureQuery['estimate'],
  gridType: GridType
) => {
  let markups: Markup[] = [];
  let markupSubtotal = 0;
  if (!estimate) return { markups, markupSubtotal };
  switch (gridType) {
    case GridType.MARKUP_GRID:
    case GridType.OWNER_COST_GRID:
      markups = estimate.markups;
      markupSubtotal = estimate.markupSubtotal;
      break;
    case GridType.INCORPORATED_ITEM_MARKUP_GRID:
      markups = estimate.incorporatedMarkups;
      markupSubtotal = estimate.incorporatedSubtotal;
      break;
    case GridType.INCORPORATED_ITEM_DRAWS_GRID:
      markups = estimate.incorporatedDraws;
      markupSubtotal = estimate.incorporatedDrawsSubtotal;
      break;

    case GridType.ITEM_DRAWS_GRID:
      markups =
        estimate.contingencyDraws?.map(({ id, name, draw }, i) => {
          return {
            id,
            name: `${name} Draw`,
            total: draw,
            percent: 1,
            percentScale: Scale.PERCENT_9,
            disabled: false,
            orderingNumerator: i.toString(),
            orderingDenominator: '0',
            shouldExcludeFromAllocation: false,
            shouldNotApplyCategoryFiltersToOtherMarkups: false,
            estimateId: estimate.id,
            displayType: MarkupDisplayType.DRAW,
            errors: [],
            type: MarkupType.PERCENT,
            markupReference: { appliesTo: [S1] },
            value: draw,
            categoryFilters: [],
            sourceFilterIDs: [],
            costTypeFilters: [],
            sourceFilterIDsWithoutS2Reference: [],
          };
        }) ?? [];
      markupSubtotal = estimate.drawSubtotal;
      break;
    case GridType.INHERITED_GRID:
      markups = estimate.inheritedMarkups;
      markupSubtotal = Number(estimate.inheritedSubtotal);
      break;
    case GridType.INHERITED_OWNER_COST_MARKUP_GRID:
      markups = estimate.inheritedOwnerCostMarkups;
      markupSubtotal = Number(estimate.inheritedOwnerCostMarkupsSubtotal);
      break;
    default:
  }
  return { markups, markupSubtotal };
};

export const getControllerMarkupSettings = (
  estimate: EstimateArmatureQuery['estimate'],
  gridType: GridType,
  gridVariant: GridVariant,
  permissions: EstimateGridPermissions,
  hasRemoveS2ItemMarkupFeature: boolean,
  isItem: boolean
) => {
  const { canEditMarkups, summaryMarkups, canEditOwnerCosts, canViewOwnerCosts } = permissions;
  const hasIncorporatedMarkups = !!estimate && estimate.incorporatedMarkups.length > 0;
  const hasIncorporatedDraws = !!estimate && estimate.incorporatedDraws.length > 0;

  let hasMarkupCheckboxButton = false;
  let canUpdateInheritedMarkupRefs = false;
  let isIncorporated = false;
  let isInherited = false;
  let isInheritedOwnerCost = false;
  let linesReadOnly = !canEditMarkups;
  let isSummary = summaryMarkups;
  let s1RefShouldIncludeS2 = hasIncorporatedMarkups;
  let hasDisplayTypeColumn = false;

  let linePrefix = '';
  let milestoneName: string | undefined;

  switch (gridType) {
    case GridType.MARKUP_GRID:
      hasDisplayTypeColumn = !isItem && gridVariant !== GridVariant.ITEM_TEMPLATE;

      linePrefix = hasIncorporatedMarkups ? 'MM' : 'M';
      break;
    case GridType.INCORPORATED_ITEM_MARKUP_GRID:
      isIncorporated = true;
      s1RefShouldIncludeS2 = false;
      linePrefix = 'IM';
      hasDisplayTypeColumn = true;
      break;
    case GridType.INCORPORATED_ITEM_DRAWS_GRID:
      isIncorporated = true;
      s1RefShouldIncludeS2 = false;
      linePrefix = 'D';
      hasDisplayTypeColumn = true;
      break;
    case GridType.ITEM_DRAWS_GRID:
      linePrefix = 'C';
      break;
    case GridType.INHERITED_GRID:
      hasMarkupCheckboxButton = canEditMarkups;
      canUpdateInheritedMarkupRefs = canEditMarkups && hasRemoveS2ItemMarkupFeature;
      isInherited = true;
      s1RefShouldIncludeS2 = true;
      linePrefix = 'P';
      milestoneName =
        (estimate && 'milestoneName' in estimate && estimate.milestoneName) || undefined;
      hasDisplayTypeColumn = true;
      break;
    case GridType.OWNER_COST_GRID:
      linePrefix = 'O';
      hasDisplayTypeColumn = false;
      linesReadOnly = !canEditOwnerCosts;
      isSummary = canViewOwnerCosts ? false : summaryMarkups;
      break;
    case GridType.INHERITED_OWNER_COST_MARKUP_GRID:
      isInheritedOwnerCost = true;
      hasMarkupCheckboxButton = canEditMarkups;
      isInherited = true;
      linesReadOnly = true;
      s1RefShouldIncludeS2 = true;
      linePrefix = 'IO';
      milestoneName =
        (estimate && 'milestoneName' in estimate && estimate.milestoneName) || undefined;
      hasDisplayTypeColumn = false;
      break;
    default:
  }
  return {
    hasIncorporatedMarkups,
    hasIncorporatedDraws,
    hasMarkupCheckboxButton,
    canUpdateInheritedMarkupRefs,
    hasDisplayTypeColumn,
    isIncorporated,
    isInherited,
    isInheritedOwnerCost,
    isSummary,
    linePrefix,
    linesReadOnly,
    milestoneName,
    s1RefShouldIncludeS2,
  };
};

// add up all of the estimate subtotals to get the cost of construction
// (owner costs are not included in the subtotals)
export const getCostOfConstructionTotal = (subtotals: EstimateArmatureSubtotals) =>
  [
    subtotals.subtotal,
    subtotals.markupSubtotal,
    subtotals.inheritedSubtotal,
    subtotals.incorporatedSubtotal,
    subtotals.incorporatedDrawsSubtotal,
  ].reduce((total, subtotal) => total + Number(subtotal), 0);

export const getMarkupHeaderName = (t: TermStore, isMilestoneMarkups: boolean) =>
  isMilestoneMarkups
    ? `Milestone ${t.titleCase(TermKey.MARKUP)}, Contingencies, and Allowances`
    : `Item ${t.titleCase(TermKey.MARKUP)}`;

export const getControllerMarkupFooter = (
  state: MarkupGridState,
  gridType: GridType,
  gridVariant: GridVariant,
  hasIncorporatedMarkups: boolean,
  hasIncorporatedDraws: boolean,
  hasItemDraws: boolean,
  t: TermStore
): Footer => {
  const isMilestoneMarkups = isMilestoneVariant(gridVariant);
  const footer = { prefix: '', name: '', type: USCENTS_CELL, data: state.subtotal };
  switch (gridType) {
    case GridType.INCORPORATED_ITEM_MARKUP_GRID:
      footer.prefix = 'S2';
      footer.name = `Incorporated ${t.titleCase(TermKey.MARKUP)} Subtotal`;
      break;
    case GridType.INHERITED_GRID:
      footer.prefix = 'S3';
      footer.name = `Milestone ${t.titleCase(TermKey.MARKUP)} Subtotal`;
      break;
    case GridType.INCORPORATED_ITEM_DRAWS_GRID:
      footer.prefix = hasIncorporatedMarkups ? 'S4' : 'S3';
      footer.name = `Incorporated Draws Subtotal`;
      break;
    case GridType.OWNER_COST_GRID:
      if (hasIncorporatedMarkups && hasIncorporatedDraws) {
        footer.prefix = 'S5';
      } else if (hasIncorporatedMarkups || hasIncorporatedDraws) {
        footer.prefix = 'S4';
      } else {
        footer.prefix = 'S3';
      }
      footer.name = `Owner Costs Subtotal`;
      break;
    case GridType.INHERITED_OWNER_COST_MARKUP_GRID:
      footer.prefix = hasItemDraws ? 'S5' : 'S4';
      footer.name = `Owner Costs Subtotal`;
      break;
    case GridType.ITEM_DRAWS_GRID:
      footer.prefix = 'S4';
      footer.name = `Contingency & Allowance Draws Subtotal`;
      break;
    case GridType.MARKUP_GRID:
    default:
      footer.prefix = hasIncorporatedMarkups ? 'S3' : 'S2';
      footer.name = `${getMarkupHeaderName(t, isMilestoneMarkups)} Subtotal`;
      break;
  }
  return footer;
};

export const showGrid = (
  isReadOnlyVariant: boolean,
  controller?: Pick<GridController, 'data' | 'footer'>
): boolean => {
  const cost = controller ? getSubtotalValue(controller) : { value: '0' };
  const hasCost = !!cost && cost.value !== '0';
  const hasCostOrEstimateLines = !!controller?.data.lines.length || hasCost;
  return !isReadOnlyVariant || hasCostOrEstimateLines;
};
