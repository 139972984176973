import { BusinessOutlined, ErrorOutline, LocationOnOutlined } from '@material-ui/icons';

import { COMPANY_DASHBOARD } from '../../constants';
import { ImageDimension } from '../../generated/graphql';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import { fromNow } from '../../utilities/dates';
import { Chips } from '../ForecastingRoute/common/Chips';
import { Chip } from '../scales';
import Thumbnail from '../shared-widgets/Thumbnail/Thumbnail';

import InsightsProject from './InsightsProject/InsightsProject';

type props = { projectID: string | undefined };

const InsightsProjectDetails = ({ projectID }: props) => {
  const projectPropsQueryResult = useProjectPropsQuery(projectID);
  const project = projectPropsQueryResult.data.project;
  if (!project) {
    return null;
  }

  const chips = (
    <Chips>
      <Chip data-cy="project-location" icon={<LocationOnOutlined />} text={project.location} />
      <Chip data-cy="project-type" icon={<BusinessOutlined />} text={project.type.name} />
      <Chip data-cy="project-status" text={project.status.name} />
      <Chip data-cy="project-status" icon={<LocationOnOutlined />} text={project.location} />
      {project.orgNodes.map((node) => (
        <Chip
          key={node.id}
          data-cy="project-orgs"
          icon={
            <div>
              <img alt="organization icon" src="/img/OrganizationTree.svg" width={12} />
            </div>
          }
          text={node.name}
        />
      ))}
    </Chips>
  );

  const alertChips = (
    <div className="flex gap-2">
      <Chip backgroundColor="#FDF0F0" icon={<ErrorOutline />} text="Budget gap is $26M" />
      <Chip backgroundColor="#FDF0F0" icon={<ErrorOutline />} text="Best-case budget gap is 12%" />
      <Chip backgroundColor="#FDF0F0" icon={<ErrorOutline />} text="25% of items are past due" />
      <Chip
        backgroundColor="#FDF0F0"
        icon={<ErrorOutline />}
        text="ABC Contingency has 7% remaining"
      />
    </div>
  );

  return (
    <div className="flex max-h-full max-w-full grow flex-col justify-between gap-4 overflow-auto px-5 py-4">
      <div className="flex justify-between">
        <div className="flex type-heading1">{`${COMPANY_DASHBOARD} / ${project?.name}`}</div>
      </div>
      <div className="flex justify-between">
        <div className="flex gap-2">
          <div className="flex">
            <Thumbnail
              dimension={ImageDimension._144}
              padding={0}
              size={120}
              thumbnail={project?.thumbnail}
            />
          </div>
          <div className="flex flex-col justify-between">
            <div className="flex">{chips}</div>
            {alertChips}
          </div>
        </div>
        <div className="type-body2">Last updated {fromNow(project.lastUpdated)}</div>
      </div>
      <InsightsProject />
    </div>
  );
};

export default InsightsProjectDetails;
