import { FC, useMemo } from 'react';

import { IconButton } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';

import { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';

import styles from './IconMenuStyles';
import { SelectVariants } from './SelectStyles';

interface IconMenuIconProps {
  classes: Classes<typeof styles>;
  cy?: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  handleClick?: (event: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  icon?: any | null;
  iconName?: string;
  iconButtonClassName?: string;
  id?: string;
  menuIsOpen?: boolean;
  title?: string;
  variant: SelectVariants;
  tooltip?: string;
}

const IconMenuIcon: FC<IconMenuIconProps> = ({
  classes,
  cy,
  disabled = false,
  icon = <MoreVert />,
  iconName,
  iconButtonClassName = '',
  id,
  handleClick = () => {},
  menuIsOpen = false,
  variant,
  title,
  tooltip,
}) => {
  const styleClasses = useMemo(
    () =>
      new Map([
        [SelectVariants.UNSTYLED, { variantClassName: '' }],
        [SelectVariants.MENU, { variantClassName: classes.menuOutline }],
        [SelectVariants.COMPACT, { variantClassName: classes.compact }],
        [SelectVariants.GRID, { variantClassName: classes.iconGrid }],
        [SelectVariants.ASSET, { variantClassName: classes.asset }],
        [SelectVariants.HEADER, { variantClassName: classes.sidebarHeaderIcon }],
      ]),
    [classes]
  );

  const className = `${styleClasses.get(variant)?.variantClassName} ${iconButtonClassName} ${
    menuIsOpen ? classes.open : ''
  }`;

  const iconButton = (
    <IconButton
      aria-owns={iconName}
      className={className}
      data-cy={cy || id}
      data-menu-open={menuIsOpen}
      disabled={disabled}
      disableRipple
      onClick={handleClick}
      title={title}
    >
      {icon}
    </IconButton>
  );

  if (disabled) return <div title={title}>{iconButton}</div>;
  if (tooltip) return <NormalTooltip title={tooltip}>{iconButton}</NormalTooltip>;

  return iconButton;
};

export default withStyles(styles)(IconMenuIcon);
