import { FC, useState } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Button, TextField, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { JoinCompanyRoutes } from '../../../api/gqlEnums';
import { UserStatus } from '../../../api/gqlEnumsBe';
import { useResendInviteMutation } from '../../../hooks/useResendInviteMutation';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { useCurrentUser } from '../../contexts/current-user';
import ErrorTooltip from '../../JoinGrid/ErrorTooltip';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { SortManager } from '../../scales';
import JoinSelect, { SelectEntry, getEntry } from '../../Select/JoinSelect/JoinSelect';
import {
  useUpdateCompanyUserJobTitleMutation,
  useUpdateCompanyUserNameMutation,
  useUpdateCompanyUserStatusMutation,
} from '../CompanyHooks';
import CompanyTabRoleInfo from '../CompanyTabRoleInfo/CompanyTabRoleInfo';
import CompanyTabRoleSelector from '../CompanyTabRoleSelector/CompanyTabRoleSelector';
import CompanyTabStyles from '../CompanyTabStyles';
import {
  CompanyUserSearchManager,
  companyRoleTooltip,
  getIsPending,
  getStatusColor,
  searchCompanyUsers,
} from '../CompanyTabUtils';
import CompanyUserPhoto from '../CompanyUserPhoto';

import CompanyTabMembersSidebar from './CompanyTabMembersSidebar';
import ProjectTable from './ProjectTable';

type CompanyTabMembersProfileProps = {
  classes: Classes<typeof CompanyTabStyles>;
  company: Company;
  companyProjectRoles: CompanyProjectRoles[];
  companyRoles: PartialRole[];
  companyUsers: CompanyUser[];
  companyUsersLoading: boolean;
  companyUserDetails?: CompanyUserDetails;
  countTotalMembers: number;
  projectSortManager: SortManager;
  sortManager: SortManager;
  filterStatus: string;
  setFilterStatus: (s: string) => void;
  searchManager: CompanyUserSearchManager;
  userCounts: number[];
};

const CompanyTabMembersProfile: FC<CompanyTabMembersProfileProps> = ({
  classes,
  company,
  companyProjectRoles,
  companyRoles,
  companyUsers,
  companyUsersLoading,
  companyUserDetails,
  countTotalMembers,
  projectSortManager,
  sortManager,
  filterStatus,
  setFilterStatus,
  searchManager,
  userCounts,
}) => {
  const { user } = companyUserDetails || { user: undefined };

  const [revisedUser, setRevisedUser] = useState(user);
  const updateUserName = useUpdateCompanyUserNameMutation(company?.id);
  const updateUserJobTitle = useUpdateCompanyUserJobTitleMutation(company?.id);
  const updateUserStatus = useUpdateCompanyUserStatusMutation();
  const resendInvite = useResendInviteMutation();

  const currentUser = useCurrentUser();

  const isCurrentUser = currentUser?.id === user?.id;

  const sendAnalytics = useSendAnalytics();

  const { searchTerm } = searchManager;

  const searchedCompanyUsers = searchCompanyUsers(companyUsers, searchTerm);

  const deactivated = user && user.status === UserStatus.DEACTIVATED;

  const getColorDot = (status: UserStatus) => (
    <div style={{ color: getStatusColor(status) }}>{'\u2B24'}&nbsp;</div>
  );

  const statusEntries = companyUserDetails?.user?.isEmailVerified
    ? ([
        getEntry(UserStatus.ACTIVE, UserStatus.ACTIVE, false, getColorDot(UserStatus.ACTIVE)),
        getEntry(
          UserStatus.DEACTIVATED,
          UserStatus.DEACTIVATED,
          false,
          getColorDot(UserStatus.DEACTIVATED)
        ),
      ] as SelectEntry[])
    : ([
        getEntry(UserStatus.PENDING, UserStatus.PENDING, false, getColorDot(UserStatus.PENDING)),
        getEntry(
          UserStatus.DEACTIVATED,
          UserStatus.DEACTIVATED,
          false,
          getColorDot(UserStatus.DEACTIVATED)
        ),
      ] as SelectEntry[]);

  return (
    <div className={classes.memberContainer}>
      <CompanyTabMembersSidebar
        company={company}
        companyProjectRoles={companyProjectRoles}
        companyRoles={companyRoles}
        companyUsers={searchedCompanyUsers}
        countTotalMembers={countTotalMembers}
        filterStatus={filterStatus}
        loading={companyUsersLoading}
        searchManager={searchManager}
        setFilterStatus={setFilterStatus}
        sortManager={sortManager}
        userCounts={userCounts}
      />
      {companyUserDetails && user && revisedUser && (
        <div className={classes.memberContent}>
          <div className={`${classes.flex} ${classes.marginBottom}`}>
            <Link
              className={classes.linkBreadcrumb}
              data-cy="breadcrumb-link"
              onClick={() => {
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBERS_BREADCRUMB_CLICK)
                );
              }}
              to={generateSharedPath(JoinCompanyRoutes.COMPANY_MEMBERS, {})}
            >
              <Typography>Employees</Typography>
            </Link>
            <Typography className={classes.divider}>/</Typography>
            <Typography>
              {user.name} - {user.email}
            </Typography>
          </div>
          <div className={classes.memberData}>
            <div>
              <div className={`${classes.flex} ${classes.marginBottom}`}>
                <div>
                  <div className={`${classes.flex} ${classes.marginRight}`}>
                    <Typography className={classes.subText} variant="title">
                      Employee Photo
                    </Typography>
                    <NormalTooltip title="Images can be PNG, JPG, GIF, or SVG. Drop image or click to choose file.">
                      <Help className={classes.tooltip} />
                    </NormalTooltip>
                  </div>
                  <div
                    className={
                      deactivated
                        ? `${classes.avatarProfile} ${classes.deactivatedAvatar}`
                        : `${classes.avatarProfile}`
                    }
                  >
                    <CompanyUserPhoto user={user} />
                  </div>
                </div>
                <div className={classes.flexGrow}>
                  <Typography className={classes.subText} variant="title">
                    Full Name*
                  </Typography>
                  <div className={deactivated ? classes.paddingBottom : ''}>
                    <TextField
                      className={deactivated ? classes.disabledInput : classes.paddingBottom}
                      data-cy="company-user-name"
                      disabled={deactivated}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: !revisedUser?.name?.trim() && (
                          <ErrorTooltip title="Name is required.">
                            <ErrorOutlineIcon className={classes.errorIcon} />
                          </ErrorTooltip>
                        ),
                      }}
                      onBlur={() => {
                        if (revisedUser.name !== user.name && revisedUser.name?.trim()) {
                          updateUserName(user.id, revisedUser.name);
                          sendAnalytics(
                            companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_NAME_CHANGE)
                          );
                        } else {
                          setRevisedUser(user);
                        }
                      }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setRevisedUser({ ...revisedUser, name: event.target.value });
                      }}
                      placeholder={revisedUser?.name ?? ''}
                      value={deactivated ? '' : revisedUser?.name ?? ''}
                    />
                  </div>
                  <Typography className={classes.subText} variant="title">
                    Job Title
                  </Typography>
                  <TextField
                    className={deactivated ? classes.disabledInput : ''}
                    data-cy="company-user-job-title"
                    disabled={deactivated}
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onBlur={() => {
                      if (revisedUser.jobTitle !== companyUserDetails.user.jobTitle) {
                        updateUserJobTitle(companyUserDetails.user.id, revisedUser.jobTitle);
                        sendAnalytics(
                          companyAdminAnalyticsEvent(
                            CompanyAdminEventType.MEMBER_JOB_TITLE_CHANGE,
                            { targetID: companyUserDetails.user.id, jobTitle: revisedUser.jobTitle }
                          )
                        );
                      }
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setRevisedUser({ ...revisedUser, jobTitle: event.target.value });
                    }}
                    placeholder={user.jobTitle.length > 0 ? user.jobTitle : 'e.g. Senior Estimator'}
                    value={deactivated ? '' : revisedUser?.jobTitle ?? ''}
                  />
                </div>
              </div>

              <div className={`${classes.marginBottom}`}>
                <Typography className={classes.titleBold}>Company Permissions</Typography>
                <div className={classes.flex}>
                  <Typography
                    className={`${classes.subText} ${classes.paddingTop}`}
                    variant="title"
                  >
                    Company Role
                  </Typography>
                  <NormalTooltip title={companyRoleTooltip}>
                    <Help className={`${classes.tooltip}`} />
                  </NormalTooltip>
                </div>
                <div className={`${classes.flex} ${classes.paddingLabel}`}>
                  <div className={classes.flexGrow}>
                    <CompanyTabRoleSelector
                      key={companyUserDetails?.role?.id}
                      analyticsView={CompanyAdminView.MEMBER_DETAILS}
                      companyID={company?.id}
                      currentRole={companyUserDetails?.role?.id}
                      disabled={deactivated}
                      roles={companyRoles}
                      userID={companyUserDetails.user.id}
                    />
                  </div>
                  <CompanyTabRoleInfo
                    onClick={() => {
                      sendAnalytics(
                        companyAdminAnalyticsEvent(
                          CompanyAdminEventType.MEMBER_COMPANY_ROLE_MODAL,
                          { view: CompanyAdminView.MEMBER_DETAILS }
                        )
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={classes.memberStatusColumn}>
              <div>
                <Typography className={classes.subText} variant="title">
                  Status
                </Typography>
              </div>
              <div className={classes.paddingBottom}>
                <JoinSelect
                  cySelect="select-status"
                  disabled={isCurrentUser}
                  entries={statusEntries}
                  onChange={(entry: UserStatus) => {
                    updateUserStatus(user.id, entry);
                    sendAnalytics(
                      companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_STATUS_CHANGE, {
                        view: CompanyAdminView.MEMBER_DETAILS,
                        status: entry,
                        targetID: user.id,
                      })
                    );
                  }}
                  value={user.status}
                />
              </div>
              {companyUserDetails.user.activeAt && (
                <Typography>
                  Latest activity on{' '}
                  {new Date(companyUserDetails.user.activeAt).toLocaleDateString()}
                </Typography>
              )}
              {getIsPending(companyUserDetails.user) ? (
                <div>
                  <Button
                    className={classes.resendButton}
                    data-cy="resend-company-invite"
                    onClick={() => {
                      resendInvite(companyUserDetails.user.id, undefined, undefined, () =>
                        setToast({
                          message: `Invite email has been resent to ${companyUserDetails?.user?.email} `,
                        })
                      );
                      sendAnalytics(
                        companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_RESEND_INVITE_CTA, {
                          targetID: user.id,
                        })
                      );
                    }}
                  >
                    Resend Invite
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
          <ProjectTable
            companyUserDetails={companyUserDetails}
            page={CompanyAdminView.MEMBER_DETAILS}
            sortManager={projectSortManager}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabMembersProfile);
