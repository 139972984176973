import { FC, MouseEvent } from 'react';

import 'react-vis/dist/style.css';
import { withStyles } from '../../../theme/komodo-mui-theme';

import CommentHoverWrapperStyles, { COMMENT_HEIGHT } from './CommentHoverWrapperStyles';

type CommentHoverWrapperProps = {
  classes: Classes<typeof CommentHoverWrapperStyles>;
  content: JSX.Element;
  onClick?: (e: MouseEvent) => void;
  anchorEl?: HTMLElement | null;
  isPrint?: boolean;
  containsPreviewContent?: boolean;
};

const CommentHoverWrapper: FC<CommentHoverWrapperProps> = ({
  classes,
  content,
  onClick,
  anchorEl,
  isPrint,
  containsPreviewContent,
}) => {
  if (!isPrint && anchorEl) {
    const { offsetTop, offsetLeft } = anchorEl;

    return (
      <span
        className={`${containsPreviewContent && classes.containsPreviewContent} 
        ${classes.hint} rv-hint__content`}
        data-cy="comment-hover-wrapper"
        onClick={onClick}
        onKeyPress={() => {}}
        style={{
          top: offsetTop + COMMENT_HEIGHT,
          left: offsetLeft,
        }}
      >
        {content}
      </span>
    );
  }
  return <></>;
};

export default withStyles(CommentHoverWrapperStyles)(CommentHoverWrapper);
