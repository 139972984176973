import { FC } from 'react';

import FilterSummaryText from '../../ProjectsList/FilterSummaryText';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
} from '../../ProjectsList/ProjectsListUtils';

import InsightsFilterPanel from './InsightsFilterPanel';

type InsightsFilterMenuProps = {
  filterManager: ProjectFilterManager;
  setSettings: ProjectFilterSetSettings;
};

const InsightsFilterMenu: FC<InsightsFilterMenuProps> = ({ filterManager, setSettings }) => {
  return (
    <>
      <div className="flex items-center gap-3">
        <div className="flex">
          <FilterSummaryText filterManager={filterManager} includeLabels />
        </div>
        <div className="flex">
          <InsightsFilterPanel filterManager={filterManager} setSettings={setSettings} />
        </div>
      </div>
    </>
  );
};

export default InsightsFilterMenu;
