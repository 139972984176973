import { FC, useState } from 'react';

import { Typography, withStyles } from '@material-ui/core';

import { DIALOG_CANCEL } from '../../../../constants';
import { UnitCount } from '../../../../generated/graphql';
import JoinDialog from '../../../Dialogs/JoinDialog/JoinDialog';

import MetricsSelection from './MetricsSelection';
import styles from './MetricsSelectionDialogStyles';

type MetricsSelectionDialogProps = {
  classes: Classes<typeof styles>;
  onClose: () => void;
  onOpen: () => void;
  onNext: (unitIDs: UUID[]) => void;
  open: boolean;
  selectedUnits: Unit[];
  unitCounts: UnitCount[];
  units: Unit[];
};

const MetricsSelectionDialog: FC<MetricsSelectionDialogProps> = ({
  classes,
  onClose,
  onOpen,
  onNext,
  open,
  selectedUnits,
  unitCounts,
  units,
}) => {
  const [selectedUnitsLocal, setSelectedUnitsLocal] = useState(selectedUnits);

  const onSubmit = () => {
    const selectedUnitIDs = selectedUnitsLocal.map((sul: Unit) => sul.id);
    onNext(selectedUnitIDs);
    onClose();
  };

  const contentComponent = (
    <div>
      <Typography className={classes.hintTextTop}>
        If a project does not have a metric enabled, you can still enter a value manually in the
        report.
      </Typography>
      <MetricsSelection
        selectedUnits={selectedUnitsLocal}
        setSelectedUnits={setSelectedUnitsLocal}
        unitCounts={unitCounts}
        units={units}
      />
    </div>
  );

  return (
    <JoinDialog
      dialog={{
        contentClass: classes.contentClass,
        contentComponent,
        dialogClass: classes.dialogClass,
        disabledNext: selectedUnitsLocal.length === 0, // should not happen, defaults to GSF
        headerText: 'Metrics settings',
        onBack: () => onClose(),
        onBackText: DIALOG_CANCEL,
        onClose: () => onClose(),
        onNext: () => onSubmit(),
        onNextText: 'Apply',
        onOpen: () => {
          onOpen();
        },
        open,
      }}
      hasDivider
      index={0}
      pageCounter={undefined}
    />
  );
};

export default withStyles(styles)(MetricsSelectionDialog);
