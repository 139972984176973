import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const ThumbnailStyles = (theme: any) =>
  createStyles({
    emptyMessage: {
      flexGrow: 1,
      textAlign: 'left',
    },
    hoverThumbnail: {
      color: theme.palette.shadedGrey,
      width: '244px',
      transition: '.2s',
    },
    thumbButton: {
      maxWidth: '100%',
      width: '244px',
      maxHeight: '100%',
      height: '244px',
      boxSizing: 'border-box',
      borderRadius: 0,
      border: `1x dashed ${theme.palette.shadedGrey}`,
    },
    fullButton: {
      border: `1px dashed ${theme.palette.shadedGrey}`,
      borderRadius: 0,
      marginBottom: 8,
    },
    icon: {
      height: 16,
      color: theme.palette.disabledGrey,
      verticalAlign: 'text-top',
    },
    img: {
      objectFit: 'contain',
      height: '240px',
      width: '240px',
    },
    link: {
      color: theme.palette.link,
      cursor: 'pointer',
      fontFamily: theme.typography.fontFamily,
      textDecoration: 'underline',
    },
    loadingContainer: {
      objectFit: 'cover',
      height: '240px',
      width: '240px',
      display: 'flex',
      background: theme.palette.backgroundGrey,
    },
    loading: {
      margin: 'auto',
    },
    uploadText: {
      fontFamily: theme.typography.fontFamily,
      ...theme.typography.h3,
      color: theme.palette.disabledGrey,
      fontWeight: 400,
    },
  });

export default ThumbnailStyles;
