/* eslint-disable no-param-reassign */

import { FieldType } from '../../../api/gqlEnumsBe';
import {
  INHERITED_MARKUP_CHECKBOX_CELL,
  INHERITED_REFERENCE_CELL,
  MARKUP_VALUE_CELL,
  REFERENCE_CELL,
  SELECT_CELL,
  SOURCE_CELL,
  STRING_CELL,
  USCENTS_CELL,
} from '../../../constants';
import AllocateTooltip from '../tooltips/Allocate';
import { Column } from '../types';

const getMarkupColumns = (isInherited: boolean, isOwnerCost: boolean): Column[] => [
  {
    type: STRING_CELL,
    name: 'Description',
    id: 'Description',
  },
  {
    type: SELECT_CELL,
    name: 'Value Type',
    id: 'Type',
  },
  {
    type: MARKUP_VALUE_CELL,
    name: 'Value',
    id: 'Value',
  },
  {
    type: !isInherited || isOwnerCost ? REFERENCE_CELL : INHERITED_REFERENCE_CELL,
    name: 'Applies to...',
    id: 'References',
  },
];

const markupTotalColumn: Column[] = [
  {
    type: USCENTS_CELL,
    name: 'Total',
    id: 'Total',
  },
];

export const buildMarkupColumns = (
  isReadOnlyVariant: boolean,
  isInherited: boolean,
  isOwnerCost: boolean,
  isInheritedOwnerCost: boolean,
  isItemDraw: boolean,
  hasSourceColumn: boolean,
  hasDisplayTypeColumn: boolean
): Column[] => {
  const inheritedColumn =
    isInherited && !isInheritedOwnerCost
      ? [
          {
            type: INHERITED_MARKUP_CHECKBOX_CELL,
            name: 'Applied',
            id: 'Checkbox',
          },
        ]
      : [];

  const sourceColumn = hasSourceColumn
    ? [
        {
          type: SOURCE_CELL,
          name: 'Source',
          id: 'Source',
        },
      ]
    : [];

  const allocateColumn = !isItemDraw
    ? [
        {
          type: FieldType.ALLOCATE,
          name: 'Allocate',
          id: 'Allocate',
          helpTip: !isReadOnlyVariant ? <AllocateTooltip isOwnerCost={isOwnerCost} /> : null,
        },
      ]
    : [];

  const displayTypeColumn = hasDisplayTypeColumn
    ? [
        {
          type: FieldType.MARKUP_DISPLAY_TYPE,
          name: 'Type',
          id: 'DisplayType',
        },
      ]
    : [];

  let existingTypeColumn = getMarkupColumns(isInherited, isOwnerCost);
  if (isItemDraw) existingTypeColumn = existingTypeColumn.filter((c) => c.id === 'Description');

  // the order we return these markup columns in matters
  return [
    ...inheritedColumn,
    ...displayTypeColumn,
    ...existingTypeColumn,
    ...sourceColumn,
    ...allocateColumn,
    ...markupTotalColumn,
  ];
};
