import { FC } from 'react';

import PageCounter from '../PageCounter/PageCounter';

import JoinDialog from './JoinDialog';
import { JoinDialogType } from './JoinDialogUtils';

type JoinDialogMultiProps = {
  dialogs: JoinDialogType[];
  disablePageCounter?: boolean;
  hasLinearProgress?: boolean;
  dynamicHeight?: boolean;
};

const JoinDialogMulti: FC<JoinDialogMultiProps> = ({
  dialogs,
  disablePageCounter,
  hasLinearProgress = false,
  dynamicHeight,
}) => {
  let pageCounter: JSX.Element;
  if (!disablePageCounter) {
    pageCounter = (
      <PageCounter index={0} onOpenDialogs={dialogs.map(({ onOpen }) => onOpen ?? (() => {}))} />
    );
  }

  return (
    <>
      {dialogs.map((dialog, i) => {
        const key = `JoinDialog ${i}`;
        return (
          <JoinDialog
            key={key}
            dialog={dialog}
            dynamicHeight={dynamicHeight}
            hasLinearProgress={hasLinearProgress}
            index={i}
            numberOfDialogs={dialogs.length}
            pageCounter={pageCounter}
          />
        );
      })}
    </>
  );
};

/** @deprecated in favor of design system component, please use scales/DialogFlow or scales/Dialog */
export default JoinDialogMulti;
