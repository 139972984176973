import { ReactNode, useState } from 'react';

import { DeleteOutline } from '@material-ui/icons';

import { Button, Checkbox, Dialog, DialogContent } from '../../scales';

type Props = {
  acceptCtaCopy?: string;
  body: ReactNode;
  cancelCtaCopy?: string;
  destructiveConfirm?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onDisable?: () => void;
  open: boolean;
  title: string;
};

export default function DialogsConfirm(props: Props) {
  const [isDisabled, setIsDisabled] = useState(false);

  const cancelButton = (
    <Button label={props.cancelCtaCopy ?? 'Cancel'} onClick={props.onClose} type="secondary" />
  );

  return (
    <Dialog
      data-cy="confirm-dialog"
      footerLeft={
        props.onDisable ? (
          <Checkbox isSelected={isDisabled} onChange={setIsDisabled}>
            {`Don't show this reminder again`}
          </Checkbox>
        ) : (
          cancelButton
        )
      }
      footerRight={
        <div className="flex gap-2">
          {props.onDisable ? cancelButton : null}
          <Button
            data-cy="confirm-dialog--ok-button"
            label={props.acceptCtaCopy ?? 'Ok'}
            onClick={() => {
              props.onConfirm?.();
              if (isDisabled) props.onDisable?.();
            }}
            startIcon={props.destructiveConfirm ? <DeleteOutline /> : undefined}
            type={props.destructiveConfirm ? 'destructive' : 'primary'}
          />
        </div>
      }
      isOpen={props.open}
      onClose={props.onClose}
      title={props.title}
    >
      <DialogContent>{props.body}</DialogContent>
    </Dialog>
  );
}
