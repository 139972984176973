import { Checkbox, ScrollContainer } from '../../scales';

import ProjectSelectionListEntry from './ProjectSelectionListEntry';

type Props = {
  fetchMore: () => void;
  onChange?: (projectIDs: UUID[]) => void;
  projects: ForecastingProject[];
  searchTerm: string;
  selectedProjectIDs?: UUID[];
  selectionMode: 'single' | 'multiple';
};

export default function ProjectSelectionList({
  fetchMore,
  onChange,
  projects,
  searchTerm,
  selectedProjectIDs = [],
  selectionMode,
}: Props) {
  return (
    <ScrollContainer className="gap-2" direction="vertical" onNeedMoreData={fetchMore}>
      {projects.map((project) => (
        <div
          key={project.id}
          className={`rounded border px-4 hover:bg-selection-hover ${
            selectedProjectIDs?.includes(project.id)
              ? 'bg-selection-selected'
              : 'bg-background-primary'
          }`}
        >
          {selectionMode === 'multiple' ? (
            <Checkbox
              key={project.id}
              aria-label="select this project"
              data-cy="project-selection-entry"
              isSelected={selectedProjectIDs.includes(project.id)}
              onChange={(selected) => {
                if (selected) {
                  onChange?.([...selectedProjectIDs, project.id]);
                } else {
                  onChange?.(selectedProjectIDs?.filter((id) => id !== project.id));
                }
              }}
            >
              <ProjectSelectionListEntry
                key={project.id}
                project={project}
                searchTerm={searchTerm}
              />
            </Checkbox>
          ) : (
            <button
              aria-label="select this project"
              className="block w-full text-left"
              data-cy="project-selection-entry"
              onClick={() => {
                onChange?.([project.id]);
              }}
            >
              <ProjectSelectionListEntry
                key={project.id}
                project={project}
                searchTerm={searchTerm}
              />
            </button>
          )}
        </div>
      ))}
    </ScrollContainer>
  );
}
