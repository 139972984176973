import { useCallback } from 'react';

import { navEventTypes } from '../../../analytics/analyticsEventProperties';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import useSendNavAnalytics from '../hooks/useSendNavAnalytics';

import HUD from './HUD/HUD';
import ProjectTitle from './ProjectTitle';

type Props = {
  projectID?: UUID;
  /** @default true */
  showThumbnail?: boolean;
};

export default function ProjectDetails(props: Props) {
  const sendNavAnalytics = useSendNavAnalytics();
  const { shouldDisplayCosts, setDisplayCostsToggle } = useShouldDisplayCosts(props.projectID);

  const [showHUD, setShowHUD] = useLocalStorage('NAV_SHOW_HUD', true);
  const onChangeHUDVisibility = useCallback(
    (expand: boolean) => {
      sendNavAnalytics(navEventTypes.HUD_EXPAND_CHANGED, { expand });
      setShowHUD(expand);
    },
    [sendNavAnalytics, setShowHUD]
  );

  const projectQueryResult = useProjectPropsQuery(props.projectID);
  const project =
    projectQueryResult.data.project ?? projectQueryResult.previousData?.project ?? undefined;

  if (!props.projectID) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2" data-cy="project-details">
      <div className="px-2">
        <ProjectTitle
          isShowingMilestoneInfo={showHUD}
          onShowMilestoneInfo={
            shouldDisplayCosts || setDisplayCostsToggle ? onChangeHUDVisibility : undefined
          }
          project={project}
          showThumbnail={props.showThumbnail}
        />
      </div>
      {showHUD && <HUD milestoneID={project?.activeMilestone.id} projectID={props.projectID} />}
    </div>
  );
}

ProjectDetails.defaultProps = {
  showThumbnail: true,
};
