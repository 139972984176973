import { searchEventTypes } from '../../../analytics/analyticsEventProperties';
import { formatNumber } from '../../../utilities/string';
import { isEnumValue } from '../../../utilities/types';
import { ButtonBar } from '../../scales';

import useSendSearchAnalytics from './hooks/useSendSearchAnalytics';

export enum SearchToggleValue {
  ALL = 'COMPANY_RESULTS',
  MY = 'MY_RESULTS',
}

type SearchToggleProps = {
  counts: { all: number; my: number };
  labels: { all: string; my: string };
  onChange: (value: SearchToggleValue) => void;
  value: SearchToggleValue;
};

export type SearchToggleParams = Omit<SearchToggleProps, 'classes'>;

export default function SearchToggle({ counts, labels, onChange, value }: SearchToggleProps) {
  const sendSearchAnalytics = useSendSearchAnalytics();
  return (
    <ButtonBar
      onChange={(value: string) => {
        if (isEnumValue(SearchToggleValue, value)) {
          onChange(value);
          sendSearchAnalytics(searchEventTypes.SEARCH_TOGGLE, {
            toggleValue: value,
          });
        }
      }}
      options={[
        {
          value: SearchToggleValue.ALL,
          label: `${labels.all} (${formatNumber(counts.all)})`,
        },
        {
          value: SearchToggleValue.MY,
          label: `${labels.my} (${formatNumber(counts.my)})`,
        },
      ]}
      value={value}
    />
  );
}
