import { To } from 'react-router-dom';

import { BusinessOutlined, LocationOnOutlined } from '@material-ui/icons';

import { JoinRoutes } from '../../../../../api/gqlEnums';
import { ImageDimension } from '../../../../../generated/graphql';
import {
  HOME_PROJECT_LOCATION,
  HOME_PROJECT_NAME,
  HOME_PROJECT_STATUS,
  HOME_PROJECT_TYPE,
} from '../../../../../tagConstants';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../../../utilities/routes/links';
import { Tooltip } from '../../../../scales';
import SearchText from '../../../../Search/SearchText/SearchText';
import Thumbnail from '../../../../shared-widgets/Thumbnail/Thumbnail';
import { Chip, Chips } from '../../../common/Chips';
import ProjectLink from '../../../common/ProjectLink';

import ClampedContent from './ClampedContent';
import styles from './ProjectCellStyles';

type Props = {
  classes: Classes<typeof styles>;
  isSingleLineHeader?: boolean;
  project: InsightsProjects | ForecastingProject;
  searchText: string;
  showOrgs: boolean;
  showProjectLead: boolean;
  to?: To;
  isSelected?: boolean;
};

const truncateText = (text: string, maxLength: number) =>
  text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

export default withStyles(styles)(
  ({
    classes,
    isSelected,
    isSingleLineHeader,
    project,
    searchText,
    showOrgs,
    showProjectLead,
    to,
  }: Props) => {
    const allChips: string[] = [
      project.location,
      project.type,
      project.status,
      project.projectLeadName,
      ...project.orgNodes.map((node) => node?.name),
    ].filter((chip): chip is string => chip !== null && chip !== undefined);

    const createChip = (text: string, iconComponent?: JSX.Element) => {
      const truncateCharLimit = 20;
      const truncatedText = truncateText(text, truncateCharLimit);
      const chipElement = (
        <Chip iconComponent={iconComponent}>
          <SearchText searchTerm={searchText} text={truncatedText} />
        </Chip>
      );
      return text.length > truncateCharLimit ? (
        <Tooltip content={text} placement="bottom">
          <div>{chipElement}</div>
        </Tooltip>
      ) : (
        chipElement
      );
    };

    const chips = (
      <Chips>
        <div data-cy={`${HOME_PROJECT_LOCATION}-${project.name}-${project.location}`}>
          {createChip(project.location, <LocationOnOutlined />)}
        </div>
        {project.type && (
          <div data-cy={`${HOME_PROJECT_TYPE}-${project.name}-${project.type}`}>
            {createChip(project.type, <BusinessOutlined />)}
          </div>
        )}
        {project.status && (
          <div data-cy={`${HOME_PROJECT_STATUS}-${project.name}-${project.status}`}>
            {createChip(project.status)}
          </div>
        )}
        {showProjectLead && project.projectLeadName && (
          <div>
            {createChip(
              project.projectLeadName,
              <div>
                <img alt="project lead icon" src="/img/GroupsOutlined.svg" width={16} />
              </div>
            )}
          </div>
        )}
        {showOrgs &&
          project.orgNodes.map((node) => (
            <div key={node?.id}>
              {node?.name &&
                createChip(
                  node.name,
                  <div>
                    <img alt="organization icon" src="/img/OrganizationTree.svg" width={12} />
                  </div>
                )}
            </div>
          ))}
      </Chips>
    );

    return (
      <div className={`${classes.container} ${isSelected ? 'bg-selection-selected' : ''}`}>
        <Thumbnail
          dimension={ImageDimension._92}
          padding={0}
          size={72}
          thumbnail={project.thumbnail}
        />
        <div className={classes.info}>
          <ProjectLink
            className={project.hasAccess ? classes.link : ''}
            hasAccess={project.hasAccess}
            projectName={project.name}
            to={to ?? generateSharedPath(JoinRoutes.PROJECT, { projectId: project.id })}
          >
            <div
              className={`${classes.title} ${isSingleLineHeader ? 'line-clamp-1' : ''}`}
              data-cy={`${HOME_PROJECT_NAME}-${project.name}`}
              data-project-id={project.id}
            >
              <SearchText searchTerm={searchText} text={project.name} />
            </div>
          </ProjectLink>
          <ClampedContent toolTipEntries={allChips}>{chips}</ClampedContent>
        </div>
      </div>
    );
  }
);
