type Props = {
  description?: string;
  title: string;
};

export default function CompanyTabHeader({ description, title }: Props) {
  return (
    <div className="gap-2 py-6 pl-6">
      <div className="capitalize type-heading2">{title}</div>
      <div className="type-body1">{description}</div>
    </div>
  );
}
