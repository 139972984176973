import * as d3 from 'd3';
import { NavigateFunction } from 'react-router-dom';

import theme from '../../../../theme/komodo-mui-theme';
import { formatCost } from '../../../../utilities/currency';
import { ChartSize, LabelOptions } from '../ChartsD3GraphWrapper';

export type VerticalBarGraphData = {
  project: ProjectInfo;
  stack: string;
  value: number;
};

export type ChartStackMap = {
  stack: string;
  color: string;
};

export type ProjectCostRange = {
  project: ProjectInfo;
  positiveCost: USCents;
  negativeCost: USCents;
  totalCost: USCents;
};

export type ChartTooltip = {
  projectID: UUID;
  x: number;
  y: number;
  bottom: number;
  right: number;
  anchorOrigin: AnchorOrigin;
  transformOrigin: AnchorOrigin;
  isTitle: boolean;
};

export type AnchorOrigin = {
  horizontal: HorizontalLocation;
  vertical: VerticalLocation;
};

export type HorizontalLocation = 'left' | 'center' | 'right';
export type VerticalLocation = 'top' | 'center' | 'bottom';

// TODO: Remove project-specific information from /Charts to clean up dependency cycles and make this chart generic.
type ProjectMap = Map<
  string,
  {
    hasAccess: boolean;
    name: string;
    milestoneName: string;
  }
>;

export const chartTooltipDefault = {
  projectID: '',
  x: 0,
  y: 0,
  bottom: 0,
  right: 0,
  anchorOrigin: {
    horizontal: 'left',
    vertical: 'center',
  },
  transformOrigin: {
    horizontal: 'left',
    vertical: 'bottom',
  },
  isTitle: false,
} as ChartTooltip;

// these type names are a bit long, I'm adding an alias for them
export type d3Selection = d3.Selection<d3.BaseType, undefined, null, null>;
export type d3Series = d3.Series<
  {
    [key: string]: number;
  },
  string
>;

// takes the input stack map and outputs two lists
// the first list is the positive and negative stacks by appending P or N
// for positive and negative respectively
// the second list is the corresponding list of colors for each stack
// ie input stackMap = [['rejected': 'red'], ['pending': 'yellow'], ['accepted': 'green'], ]
// becomes
// stacks = ['rejectedP', 'acceptedP', pendingP, rejectedN, acceptedN, pendingN]
// colors = ['red', 'yellow', 'green', 'red', 'yellow', 'green']
const positiveStackSuffix = 'P';
const negativeStackSuffix = 'N';
export const generateStacksAndColorsList = (stackMap: ChartStackMap[]) => {
  // get the stacks first
  const postiveStacks = [...stackMap].map(({ stack }) => stack.concat(positiveStackSuffix));
  const negativeStacks = [...stackMap].map(({ stack }) => stack.concat(negativeStackSuffix));
  const stacks = [...postiveStacks, ...negativeStacks];

  // next get the colors
  const positiveColors = [...stackMap].map(({ color }) => color);
  const negativeColors = [...stackMap].map(({ color }) => color);
  const colors = [...positiveColors, ...negativeColors];
  return [stacks, colors];
};

// take the input chart data, and convert it to a format usable by d3
export const formatData = (data: VerticalBarGraphData[], stackMap: ChartStackMap[]) => {
  // generate the list of stacks, and their associated colors
  const [stacks, colors] = generateStacksAndColorsList(stackMap);

  // generate the colors for the bars
  const color = d3.scaleOrdinal().domain(stacks).range(colors);

  // break the incoming stack into positive and negative stack
  const dataset = data.map((d) => {
    const stack =
      d.value > 0 ? d.stack.concat(positiveStackSuffix) : d.stack.concat(negativeStackSuffix);
    return { ...d, stack };
  });

  // convert Add up the data by project
  const rolledUpData = d3.rollups(
    dataset,
    (ds: VerticalBarGraphData[]) =>
      d3.rollup(
        ds,
        ([d]: VerticalBarGraphData[]) => d.value,
        (d: VerticalBarGraphData) => d.stack
      ),
    (d: VerticalBarGraphData) => d.project.id
  );

  // take the rolled up data, and convert to a format usable by d3 for a stack chart
  const series = d3
    .stack()
    .keys(stacks)
    .value((object, key) => {
      // ts doesn't recognize the correct type
      // since this is a stacked value chart the first second
      // item in the array is a map
      const map = object[1] as unknown as Map<string, number>;
      return map.get(key) || 0;
    })
    .offset(d3.stackOffsetDiverging)(rolledUpData as Iterable<{ [key: string]: number }>);

  return { color, rolledUpData, series };
};

// get the y domain from the total max and min value for each project
export const getDomainFromProjects = (projectCostRanges: ProjectCostRange[]) => {
  const max =
    // the USCents type is actually a string and not a number?
    d3.max(projectCostRanges, (d: ProjectCostRange) => parseInt(d.positiveCost.toString(), 10)) ||
    0;
  const min =
    d3.min(projectCostRanges, (d: ProjectCostRange) => parseInt(d.negativeCost.toString(), 10)) ||
    0;
  return [min, max];
};

export const getProjectName = (data: VerticalBarGraphData[], projectID: UUID) => {
  // find the corresponding project name for this project id
  const dataPoint = data.find((d: VerticalBarGraphData) => d.project.id === projectID);
  // data point should always be found....
  return dataPoint ? dataPoint.project.name : '';
};

// create the axis for the chart
export const generateAxis = (
  data: VerticalBarGraphData[],
  chartSize: ChartSize,
  projectOrder: Map<string, number>,
  projectCostRanges: ProjectCostRange[],
  navigate: NavigateFunction,
  projectMap?: ProjectMap
) => {
  const { width, height, padding, xAxisLayout, yAxisLayout } = chartSize;
  const xAxisTranslate = height - padding.bottom + xAxisLayout.padding;
  const yAxisTranslate = padding.left + yAxisLayout.padding;
  // this generates the x axis values
  const x = d3
    .scaleBand()
    .domain(projectOrder.keys())
    .range([padding.left + yAxisLayout.padding, width])
    .padding(0.2);

  // create the xAxis lables / ticks
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const xAxis = (g: any) =>
    g
      .attr('transform', `translate(0,${xAxisTranslate || 0})`)
      .call(
        d3
          .axisBottom(x)
          .tickSize(0)
          .tickFormat((id: string) =>
            chartSize.xAxisLayout.showLabels ? getProjectName(data, id) : ''
          )
      )
      .call((h: d3Selection) => h.selectAll('.domain').remove())
      .on('click', (d: PointerEvent) => {
        if (!projectMap) return;

        // clicking on a project name will link you to that project
        const element = d.target as Element;
        const projectID = element && element.id ? element.id : '';
        if (!projectMap.get(projectID)) return;
        if (projectID) navigate(`/${projectID}/project`);
      });
  // get the min and max cost values by checking costs for every project
  const [min, max] = getDomainFromProjects(projectCostRanges);

  // generate the y axis
  const y = d3
    .scaleLinear()
    .domain([min, max])
    .rangeRound([height - padding.bottom - xAxisLayout.padding, 10]);

  // create the y axis labels and ticks
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const yAxis = (g: any, _: number, format: string) =>
    g
      .attr('transform', `translate(${yAxisTranslate || 0},0)`)
      .attr('text-anchor', 'start')
      .call(
        yAxisLayout.numberOfTicks === 1
          ? d3.axisLeft(y).tickValues([0])
          : d3.axisLeft(y).ticks(yAxisLayout.numberOfTicks, format)
      ) // y axis ticks
      .call(
        (
          h: d3Selection // set the color for the y axis ticks (x axis is darker than other ticks)
        ) =>
          h
            .selectAll('.tick line')
            .clone()
            .attr('stroke', (d) =>
              d === 0 ? theme.palette.chartGrey : theme.palette.chartMediumGrey
            )
            .attr('x2', width - padding.left - padding.right - yAxisLayout.padding + 4)
      )
      .call(
        // y axis labels (cost)
        d3
          .axisLeft(y)
          .ticks(yAxisLayout.numberOfTicks, format)
          .tickSize(0)
          .tickFormat((cost: d3.NumberValue) =>
            chartSize.xAxisLayout.showLabels ? formatCost(cost, { short: true }) : ''
          )
      )
      .call((h: d3Selection) => h.selectAll('.domain').remove());
  return { x, xAxis, y, yAxis };
};

export const generateChartBars = (
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  chartSize: ChartSize,
  projectCostRanges: ProjectCostRange[],
  series: d3.Series<
    {
      [key: string]: number;
    },
    string
  >[],
  x: d3.ScaleBand<string>,
  y: d3.ScaleLinear<number, number, never>,
  color: d3.ScaleOrdinal<string, unknown, never>,
  setChartTooltip?: (tooltip: ChartTooltip) => void
) => {
  // add the bars to the chart
  svg
    .selectAll('body')
    .append('g')
    .attr('class', 'bar-chart')
    .data(series)
    .join('g')
    .attr('fill', (d) => color(d.key) as string)
    .selectAll('rect')
    .data((d: d3Series) => d)
    .join('rect')
    .attr('x', (d) => x(d.data[0].toString()) ?? null)
    .attr('y', (d) => y(d[1]))
    .attr('height', (d) => y(d[0]) - y(d[1]))
    .attr('width', x.bandwidth());

  // if the user wants a tooltip then
  // generate a series of transparent bars
  // for each project that represent the total cost
  // ie the series below combines accepeted, rejected, etc.
  // costs into a single bar.  This way the tooltip will
  // not change depending on which section of the stack
  // a user has hovered their mouse over
  if (setChartTooltip) {
    svg
      .selectAll('body')
      .append('g')
      .attr('class', 'bar-chart-hover')
      .data(projectCostRanges)
      .join('rect')
      .attr('fill', 'transparent')
      .attr('id', (d: ProjectCostRange) => d.project.id || '') // store the id for the hover state
      .attr('x', (d: ProjectCostRange) => x(d.project.id || '') || '')
      .on('mouseover', (d: PointerEvent) => {
        const t = d.target as Element;
        const location = t.getBoundingClientRect();
        let horizontalOrigin = 'center' as HorizontalLocation;
        // determine the location of this as a percentage
        // of the width of the chart, ie is the
        // bar in the first half, second half
        // to locate the tooltip to the right or left respectively
        const xLocation = location.x / chartSize.width;
        if (xLocation > 0.5) horizontalOrigin = 'right';
        if (xLocation < 0.5) horizontalOrigin = 'left';

        setChartTooltip({
          projectID: t.getAttribute('id') || '',
          x: location.x,
          y: location.y,
          right: location.right,
          bottom: location.bottom,
          anchorOrigin: {
            vertical: 'center',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: horizontalOrigin,
          },
          isTitle: false,
        });
      })
      .on('mouseleave', () => setChartTooltip(chartTooltipDefault))
      .attr('opacity', 0)
      .attr('y', (d: ProjectCostRange) => y(d.positiveCost))
      // Note:
      // y() returns the vertical location at which a cost will occur
      // on the y axis given the input number.
      // the height is measured staring from the top of the chart to the bottom
      .attr('height', (d: ProjectCostRange) => y(d.negativeCost) - y(d.positiveCost))
      .attr('width', x.bandwidth());
  }
};

export const generateChartAxis = (
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  rolledUpData: [string, d3.InternMap<string, number>][],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  xAxis: (g: any) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  yAxis: (g: any, _: number, format: string) => void,
  label: LabelOptions,
  projectMap?: ProjectMap,
  setChartTooltip?: (tooltip: ChartTooltip) => void
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const hasLink = (value: any) => projectMap?.get(value)?.hasAccess;
  // add the axes
  // create the x axis, and set the styles
  svg
    .append('g')
    .attr('class', 'y-axis')
    .call(xAxis)
    .selectAll('text')
    // add an id attribute to the xAxis labels
    // so we can hyperlink them to the project
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    .attr('id', (_: any, i: number) => (rolledUpData[i] ? rolledUpData[i][0] : ''))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    .style('color', (value: any) => {
      return hasLink(value) ? theme.palette.primaryBlue : theme.palette.shadedGrey;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    .style('cursor', (value: any) => {
      return hasLink(value) ? 'pointer' : 'default';
    })
    .style('font-size', label.fontSize)
    .style('font-weight', label.fontWeight)
    .style('font-family', theme.fontFamily)
    .style('transform', 'translate(0px, 1px)')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    .on('mouseover', (event, projectID: any) => {
      if (hasLink(projectID)) {
        d3.select(event.currentTarget).style('text-decoration', 'underline');
      }

      if (setChartTooltip) {
        const location = event.currentTarget.getBoundingClientRect();

        setChartTooltip({
          projectID,
          x: location.x,
          y: location.y,
          right: location.right,
          bottom: location.bottom,
          // not used
          anchorOrigin: {
            vertical: 'center',
            horizontal: 'center',
          },
          // not used
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          isTitle: true,
        });
      }
    })

    .on('mouseleave', (event) => {
      d3.select(event.currentTarget).style('text-decoration', 'none');
      if (setChartTooltip) {
        setChartTooltip(chartTooltipDefault);
      }
    });

  // create the y axis, and set the font
  svg
    .append('g')
    .attr('class', 'x-axis')
    .call(yAxis)
    .style('font-size', 12)
    .style('font-weight', 400);
};
