import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { FC, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { localeDateFormat, maskFn, parseDate, placeholderDefault } from '../../../utilities/dates';

import MilestoneDatePickerStyles from './MilestoneDatePickerStyles';

type MilestoneDatePickerProps = {
  classes: Classes<typeof MilestoneDatePickerStyles>;
  date: string | Date | null | undefined;
  disabled?: boolean;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  minDateMessage?: string;
  onFocus?: () => void;
  onUpdate: (date: string | null) => void;
  placeholder?: string;
  setDatePickerOpen?: (value: boolean) => void;
};

const MilestoneDatePicker: FC<MilestoneDatePickerProps> = ({
  classes,
  date: icDate,
  disabled,
  label = 'Milestone Start Date *',
  minDate,
  maxDate,
  minDateMessage,
  onFocus,
  onUpdate,
  placeholder = placeholderDefault,
  setDatePickerOpen,
}) => {
  const [date, setDate] = useState<string | null>(parseDate(icDate));

  // If props.date changes, update our local state.
  useEffect(() => {
    setDate(parseDate(icDate));
  }, [icDate]);

  const checkValidDateAndSave = (newDate: Date) => {
    // if the date is invalid then ignore the change
    // this function can be called while sending an update
    // this is because onChange is called after Blur
    // despite calling stopPropagation
    const isValid = !Number.isNaN(newDate.getTime());
    if (isValid) {
      const parsed = newDate.toISOString();
      setDate(parsed);
      onUpdate(parsed);
    } else {
      setDate(null);
      onUpdate(null);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const handleKeydown = (event: any) => {
    if (event.key === 'Enter') {
      // call blur to first update the name / date, then send the changes
      if (event.target.className.includes('picker')) {
        event.target.blur();
      }
    }
  };

  return (
    <div className="flex shrink-0 flex-col gap-0.5">
      {label && <div className="type-label">{label}</div>}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          className={disabled ? classes.dateDisabled : classes.date}
          data-cy={disabled ? 'milestone-date' : 'milestone-date-picker'}
          disabled={disabled}
          format={localeDateFormat}
          FormHelperTextProps={{ classes: { error: classes.error } }}
          InputProps={{
            classes: {
              root: '!rounded-md type-number !border-border-default !h-10 items-center',
              focused: '!outline',
            },
            disableUnderline: true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            onBlur: (event: any) => {
              checkValidDateAndSave(new Date(event.target.value));
            },
          }}
          invalidDateMessage=""
          keepCharPositions
          keyboard
          KeyboardButtonProps={{
            classes: {
              root: `${disabled ? classes.hidden : classes.datePickerIcon}`,
            },
          }}
          mask={maskFn}
          maxDate={maxDate}
          minDate={minDate}
          minDateMessage={minDateMessage}
          onChange={(change: Date) => {
            checkValidDateAndSave(change);
          }}
          onClose={setDatePickerOpen ? () => setDatePickerOpen(false) : undefined}
          onFocus={onFocus}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          onKeyDown={(event: any) => {
            handleKeydown(event);
          }}
          onOpen={setDatePickerOpen ? () => setDatePickerOpen(true) : undefined}
          placeholder={placeholder}
          value={date}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default withStyles(MilestoneDatePickerStyles)(MilestoneDatePicker);
