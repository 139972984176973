import { FC, useContext } from 'react';
import 'react-vis/dist/style.css';

import { Typography } from '@material-ui/core';

import { DASHBOARD_TOOLTIP_ITEM_CHARLIMIT } from '../../../../constants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import {
  CostTrendlineEvent,
  getEventDescription,
  getEventHint,
} from '../../../dashboard/DashboardCharts/DashboardChartsTrend/DashboardChartsTrendUtils';
import EventsDescription from '../../../Events/EventsDescription/EventsDescription';
import ItemsIcons from '../../../ItemsList/ItemsIcons/ItemsIcons';
import { ClassKey } from '../../../ItemsList/ItemsIcons/ItemsIconsMap';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';

import ChartsCostTrendlineTooltipStyles from './ChartsCostTrendlineTooltipStyles';

type ChartsCostTrendlineTooltipProps = {
  classes: Classes<typeof ChartsCostTrendlineTooltipStyles>;
  costTrendlineEvents?: CostTrendlineEvent[];
  isStyleless?: boolean;
  milestoneID: UUID;
  selectedEvent: CostTrendlineEvent;
};

const ChartsCostTrendlineTooltip: FC<ChartsCostTrendlineTooltipProps> = ({
  classes,
  costTrendlineEvents,
  isStyleless,
  milestoneID,
  selectedEvent,
}) => {
  const t = useContext(ProjectTermStore);
  const description = getEventDescription(selectedEvent, milestoneID, true);
  const hint = getEventHint(t, selectedEvent, costTrendlineEvents);

  return (
    <div className={isStyleless ? '' : `${classes.hint} rv-hint__content`}>
      {description && description.length > 0 && (
        <EventsDescription
          charNameLimit={DASHBOARD_TOOLTIP_ITEM_CHARLIMIT}
          description={description}
          variant="body2"
        />
      )}
      <div className={classes.lower}>
        {hint?.trendStatus && (
          <div className={classes.trendIcon}>
            <ItemsIcons status={hint?.trendStatus} variant={hint.trendVariant as ClassKey} />
          </div>
        )}
        <Typography variant="body2">{hint?.trendDescription}</Typography>
      </div>
    </div>
  );
};

export default withStyles(ChartsCostTrendlineTooltipStyles)(ChartsCostTrendlineTooltip);
