import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { USER_PROFILE_EDIT_CTA, USER_PROFILE_EDIT_VIEW } from '../../../actions/actionTypes';
import { analyticsEvent, initUploadAnalytics } from '../../../analytics/analyticsEventProperties';
import { JOIN_SUPPORT_EMAIL } from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { useUploadImage } from '../../assets/hooks/AssetsMutationHook';
import { getUserPicture } from '../../Collaborators/UserUtils';
import { useCurrentUser } from '../../contexts/current-user';
import SetupUser from '../../login/Signup/SignupPages/SetupUser';
import { blockedTypes } from '../../login/Signup/SignupPages/SetupUserData';
import { Button, Dialog, DialogContent } from '../../scales';

import {
  useUpdateUserJobTitleMutation,
  useUpdateUserNameMutation,
  useUpdateUserPictureMutation,
} from './DialogsEditProfileHooks';

type Props = {
  onClose: () => void;
};

export default function DialogsEditProfile(props: Props) {
  const sendAnalytics = useSendAnalytics();
  useEffectOnce(() => {
    sendAnalytics(analyticsEvent(USER_PROFILE_EDIT_VIEW));
  });

  const currentUser = useCurrentUser();
  const [inputName, setInputName] = useState(currentUser.name);
  const [inputJobTitle, setInputJobTitle] = useState(currentUser.jobTitle);
  // pictures works a little different, if a user uploads an image, it gets swapped
  // if they want to cancel that, we must undo it
  const [initialPicture] = useState<string | undefined>(currentUser.picture);

  const isJoinSupport = currentUser.email === JOIN_SUPPORT_EMAIL;
  const [loading, setLoading] = useState(false);

  const updateUserName = useUpdateUserNameMutation();
  const updateUserJobTitle = useUpdateUserJobTitleMutation();
  const updateUserPicture = useUpdateUserPictureMutation();
  const uploadImage = useUploadImage(blockedTypes);

  const handleCancel = () => {
    if (initialPicture) {
      updateUserPicture(initialPicture);
    }

    props.onClose();
  };

  return (
    <Dialog
      footerLeft={<Button label="Cancel" onClick={handleCancel} type="secondary" />}
      footerRight={
        <Button
          disabled={!inputName.trim() || loading}
          label="Save"
          onClick={() => {
            if (inputName.trim() !== currentUser.name) {
              updateUserName(inputName.trim());
            }
            if (inputJobTitle.trim() !== currentUser.jobTitle) {
              updateUserJobTitle(inputJobTitle.trim());
            }
            sendAnalytics(analyticsEvent(USER_PROFILE_EDIT_CTA));
            props.onClose();
          }}
          type="primary"
        />
      }
      isOpen
      onClose={handleCancel}
      title="Edit Profile"
    >
      <DialogContent>
        <SetupUser
          disabled={isJoinSupport}
          jobTitle={inputJobTitle}
          name={inputName}
          setJobTitle={setInputJobTitle}
          setName={setInputName}
          setThumbnail={(file: File) => {
            setLoading(true);
            const onSuccess = (asset: Asset) => {
              updateUserPicture(asset.location);
              setLoading(false);
            };
            const onFailure = (message: string) => {
              setLoading(false);
              setToast({ message });
            };
            uploadImage(file, null, onSuccess, onFailure);
            sendAnalytics(initUploadAnalytics(file?.name || 'No name', 'User Thumbnail'));
          }}
          thumbnail={getUserPicture(currentUser)}
          thumbnailLoading={loading}
        />
      </DialogContent>
    </Dialog>
  );
}
