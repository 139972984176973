import { FC } from 'react';

import { CenterLabel, PieChartSize } from './ChartsPieGraphUtils';

type Props = {
  chartSize: PieChartSize;
  centerLabel?: CenterLabel;
};

const ChartsPieGraphLabels: FC<Props> = ({ chartSize, centerLabel }) => {
  const radius = chartSize.diameter / 2;

  // add a margin so the text doesn't reach the inside radius of the chart
  const margin = 0.2;
  const textSize = chartSize.insideDiameter ? chartSize.insideDiameter * (1 - margin) : radius / 2;
  const textLocation = radius - textSize / 2;

  return (
    <foreignObject height={textSize} width={textSize} x={textLocation} y={textLocation}>
      <div className=" flex h-full flex-col">
        {centerLabel ? (
          <div
            className={`${
              centerLabel?.sublabel ? 'mt-auto' : 'm-auto'
            } overflow-hidden overflow-ellipsis whitespace-nowrap text-center type-heading3`}
          >
            {centerLabel.label}
          </div>
        ) : undefined}
        {centerLabel?.sublabel ? (
          <div className="mb-auto line-clamp-2 text-center type-body2">{centerLabel.sublabel}</div>
        ) : undefined}
      </div>
    </foreignObject>
  );
};

export default ChartsPieGraphLabels;
