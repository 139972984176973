import { Key, useCallback } from 'react';

import { UNASSIGNED } from '../../constants';
import User from '../Icons/User';
import { Select } from '../scales';

type Props = {
  'aria-label'?: string;
  availableUsers: Pick<User, 'email' | 'name' | 'picture' | 'pictureAsset'>[];
  'data-cy'?: string;
  includeUnassigned?: boolean;
  /** Allows removing the assignee entirely (different from setting Unassigned).
   * Only current use case is removing the assignee input for Bulk Item Edit */
  isClearable?: boolean;
  isDisabled?: boolean;
  label?: string;
  /** Unassigned is represented by undefined, Unset by null */
  onChange: (email: string | null | undefined) => void;
  /** Unassigned is represented by undefined, Unset by null. 'value' is an email */
  value: string | null | undefined;
};

const AssigneeSelect = (props: Props) => {
  const isUnassigned = props.value === undefined;

  const entries = [];
  if (props.includeUnassigned || isUnassigned)
    entries.push({
      id: UNASSIGNED,
      label: UNASSIGNED,
      startAdornment: <EmptyThumbnail />,
      disabled: !props.includeUnassigned,
    });

  props.availableUsers.forEach((user) => {
    const userThumbnail = user.pictureAsset?.thumbnailURL ?? user.picture;
    entries.push({
      description: user.email,
      id: user.email,
      label: user.name,
      startAdornment: userThumbnail ? (
        <AssigneeThumbnail name={user.name} src={userThumbnail} />
      ) : (
        <EmptyThumbnail />
      ),
    });
  });

  const { onChange: onChangeEmail } = props;
  const onChange = useCallback(
    (val: Key | null) => {
      if (typeof val === 'string') {
        onChangeEmail(val === UNASSIGNED ? undefined : val);
      } else if (val === null && props.isClearable) {
        onChangeEmail(val);
      }
    },
    [props.isClearable, onChangeEmail]
  );

  return (
    <Select
      aria-label={props['aria-label']}
      data-cy={props['data-cy']}
      entries={entries}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isSearchable
      label={props.label}
      onChange={onChange}
      value={isUnassigned ? UNASSIGNED : props.value}
    />
  );
};

/**
 * AssigneeThumbnail uses 32px for height and width
 * this is a bit large for our scales/Select component but is consistent with how it looked in the past
 * TODO: consider scaling down to 24px https://join-build.atlassian.net/browse/DS-50
 */
const AssigneeThumbnail = (props: { src: string; name: string }) => (
  <img
    alt={`${props.name} thumbnail`}
    className="h-[32px] w-[32px] rounded-full object-cover"
    src={props.src}
  />
);

// TODO: consider scaling down to 24px https://join-build.atlassian.net/browse/DS-50
//       use the `icon-md` utility to set it
export const EmptyThumbnail = () => (
  <div className="flex h-[32px] min-h-[32px] w-[32px] min-w-[32px] items-center justify-center rounded-full bg-[#D0D5D7] fill-[white] text-[32px]">
    <User />
  </div>
);

export default AssigneeSelect;
