import { FC } from 'react';

import theme from '../../../../theme/komodo-mui-theme';
import { formatCost } from '../../../../utilities/currency';
import { pluralizeCountString } from '../../../../utilities/string';
import ContingencyBar from '../BarGraph/ContingencyReportBarGraph';

type AllMilestoneReportRowCellProps = {
  contingency: MultiMilestoneContingencyReport['contingencies'][number]['report'][number] | null;
};

const AllMilestoneReportRowCell: FC<AllMilestoneReportRowCellProps> = ({ contingency }) => {
  const existsInMilestone = !!contingency?.existsInAllMilestonesReportMilestone;
  const isOverdrawn =
    !!contingency &&
    Number(contingency.startingCost) <
      Number(contingency.totalDrawnCost) + Number(contingency.usedCost);

  return (
    <div
      className=" mt-4 flex w-[250px] flex-col justify-between whitespace-nowrap pb-3 pl-3"
      style={
        !existsInMilestone
          ? {
              color: 'gray',
              alignItems: 'center',
              justifyContent: 'center',
            }
          : {}
      }
    >
      {existsInMilestone ? (
        <>
          <ContingencyBar
            drawn={-Number(contingency.totalDrawnCost)}
            isVertical
            overdrawLimit={0.15}
            pending={-Number(contingency.totalPendingCost)}
            starting={Number(contingency.startingCost)}
            used={-Number(contingency.usedCost)}
          />
          <div className="flex items-center justify-end gap-x-2.5 type-table-number">
            <div>{pluralizeCountString('item', contingency.acceptedItemCount)}</div>
            <div className="w-[120px] rounded border py-0.5 [&>div:not(:first-child)]:border-t [&>div]:flex [&>div]:h-8 [&>div]:items-center [&>div]:justify-end [&>div]:px-2 [&>div]:py-1">
              <div>{formatCost(contingency.startingCost, { rounded: true })}</div>
              <div>{formatCost(contingency.totalDrawnCost, { rounded: true })}</div>
              <div style={{ color: isOverdrawn ? theme.palette.chartBrightRed : undefined }}>
                {formatCost(
                  Number(contingency.startingCost) +
                    Number(contingency.usedCost) +
                    Number(contingency.totalDrawnCost),
                  {
                    rounded: true,
                  }
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        "Doesn't exist in Milestone"
      )}
    </div>
  );
};

export default AllMilestoneReportRowCell;
