import { FC } from 'react';

import { hasFeatureOption } from '../../../hooks/useHasFeature';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useFeatureFlags } from '../../contexts/feature-flags';
import NavigationTabs from '../../Navigation/NavigationTabs';
import { CompanyHeadingsTabNames, companyHeaders } from '../CompanyTabUtils';

import CompanyTabNavStyles from './CompanyTabNavStyles';

type CompanyTabNavProps = {
  classes: Classes<typeof CompanyTabNavStyles>;
  view: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setView: any;
};

const CompanyTabNav: FC<CompanyTabNavProps> = ({ classes, view, setView }) => {
  const flagValues = useFeatureFlags();

  const options = companyHeaders
    .filter((header) => {
      if (!header.featureFlag) return true;
      const isFeatureEnabled = hasFeatureOption(flagValues, header.featureFlag);
      return isFeatureEnabled;
    })
    .map((header: CompanyHeadingsTabNames) => {
      const { displayLabel: label, path: key } = header;

      return {
        key,
        label,
        title: label,
      };
    });

  return (
    <>
      <div className={classes.tabContainer}>
        <NavigationTabs options={options} setView={setView} view={view} />
      </div>
    </>
  );
};

export default withStyles(CompanyTabNavStyles)(CompanyTabNav);
