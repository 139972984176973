import { FC } from 'react';
import { useEffectOnce } from 'react-use';

import {
  INSIGHTS_DEFAULT_MAX_ITEMS_BAR,
  INSIGHTS_DEFAULT_TIME,
  insightsMaxItemsBar,
  insightsTimeDomain,
} from '../../../api/apollo/reactiveVars';
import { YC_GROUPS, YC_PROJ_LEAD } from '../../../features';
import { ForecastingProjectsSortKey } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { formatNumber } from '../../../utilities/string';
import ChartsPieGraph from '../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import ProjectCell from '../../ForecastingRoute/ForecastingExplore/projects/cells/ProjectCell';
import { SortManager, Table, Tooltip } from '../../scales';
import TableChartPlaceholder from '../TableChartPlaceholder';
import ContingenciesPieToolTip from '../ToolTips/ContingenciesPieToolTip';
import { InsightsTabId } from '../types';

import { randomPieData } from './ProjectsItems';
import StickyBottomRow from './StickyBottomRow';

type Props = {
  counts: { current: number; total: number };
  loading: boolean;
  onFetchMore: () => void;
  projects: InsightsProjects[];
  searchText: string;
  sortManager: SortManager;
};

const ProjectsContingenciesAndAllowances: FC<Props> = (props) => {
  const isGroupsEnabled = useHasFeature(YC_GROUPS);
  useEffectOnce(() => () => {
    insightsTimeDomain(INSIGHTS_DEFAULT_TIME);
    insightsMaxItemsBar(INSIGHTS_DEFAULT_MAX_ITEMS_BAR);
  });
  const isProjectLeadEnabled = useHasFeature(YC_PROJ_LEAD);

  const stickyFooterEntry = StickyBottomRow({
    columnsCount: 5,
    component: <TableChartPlaceholder />,
  });

  const contingenciesColors = {
    contingencies: '#4F73F5', // blue-300
    allowances: '#E07724', // orange-400
    remainingEstimate: '#E7EAEF', // grey-300
  };

  return (
    <>
      <Table
        columnWidths={[
          'minmax(400px, 3fr)',
          'minmax(100px, 0.5fr)',
          'minmax(100px, 0.5fr)',
          'minmax(100px, 0.5fr)',
          'minmax(600px, 6fr)',
        ]}
        entries={props.projects.map((project) => {
          const key = project.id;
          const to = {
            pathname: generateSharedPath(RouteKeys.INSIGHTS_PROJECT, {
              projectId: project.id,
            }),
            hash: `#${InsightsTabId.Contingencies}`,
          };
          const pie = randomPieData();
          return [
            {
              key,
              component: (
                <ProjectCell
                  isSingleLineHeader
                  project={project}
                  searchText={props.searchText}
                  showOrgs={isGroupsEnabled}
                  showProjectLead={isProjectLeadEnabled}
                  to={to}
                />
              ),
            },
            {
              key,
              centered: true,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ContingenciesPieToolTip />
                    </div>
                  }
                >
                  <div>
                    <ChartsPieGraph
                      centerLabel={{ label: String(Math.floor(Math.random() * 100) + 1) }}
                      chartSize={{
                        diameter: 60,
                        insideDiameter: 35,
                      }}
                      colors={[
                        contingenciesColors.contingencies,
                        contingenciesColors.allowances,
                        contingenciesColors.remainingEstimate,
                      ]}
                      data={pie.dataPie}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <div className="flex flex-col gap-2">
                  <div className="whitespace-nowrap type-table-number">$100K (8%)</div>
                  <div className="whitespace-nowrap type-table-number">$130k (10%)</div>
                </div>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <div className="flex flex-col gap-2">
                  <div className="whitespace-nowrap type-table-number">$60K (3%)</div>
                  <div className="whitespace-nowrap type-table-number">$65k (5%)</div>
                </div>
              ),
            },
            {
              key,
              component: <TableChartPlaceholder />,
            },
          ];
        })}
        headerContent={[
          {
            component: <div className="h-9" />,
            copy: `Projects (${formatNumber(props.counts.current)} of ${formatNumber(
              props.counts.total
            )})`,
            key: 'projects',
            headerSortKey: ForecastingProjectsSortKey.NAME,
          },
          {
            copy: '% of Est',
            key: 'est',
          },
          {
            copy: 'Contingencies (Rmdr/Total)',
            key: 'cont',
          },
          { copy: 'Allowances (Rmdr/Total)', key: 'all' },
          { copy: '', key: 'ca' },
        ]}
        loading={props.loading}
        onNeedMoreData={props.onFetchMore}
        sortManager={props.sortManager}
        stickyFooterEntry={stickyFooterEntry}
      />
    </>
  );
};

export default ProjectsContingenciesAndAllowances;
