import { commonCategorizationFields, gql } from '../../../api/graphqlFragments';

export const getCategorizationMetadataQuery = gql`
  query getCategorizationMetadata($id: UUID!) {
    categorization(id: $id) {
      ...commonCategorizationFields
      createdFrom {
        id
      }
    }
  }
  ${commonCategorizationFields}
`;

export const previewImportedCategoriesQuery = gql`
  query previewCategories($assetID: UUID!) {
    previewImportedCategories(assetID: $assetID) {
      categoryMap
      metadata {
        name
        description
        columnLevel1
        columnLevel2
        columnLevel3
        columnLevel4
      }
      errors
    }
  }
`;

export const createCategoriesFromImportMutation = gql`
  mutation createCategoriesFromImport(
    $categorizationID: UUID!
    $assetID: UUID!
    $categories: [CategoryMapInput!]!
    $metadata: CategorizationImportMetadataInput!
    $projectID: UUID
  ) {
    createCategoriesFromImport(
      categorizationID: $categorizationID
      assetID: $assetID
      categories: $categories
      metadata: $metadata
      projectID: $projectID
    ) {
      categories {
        id
        number
        name
        level
      }
      error
    }
  }
`;

export const replaceCategoriesMutation = gql`
  mutation ReplaceCategories(
    $categorizationID: UUID!
    $categories: [CategoryMapInput!]!
    $metadata: CategorizationImportMetadataInput
    $projectID: UUID
    $assetID: UUID
  ) {
    replaceCategories(
      categorizationID: $categorizationID
      categories: $categories
      metadata: $metadata
      projectID: $projectID
      assetID: $assetID
    ) {
      categorization {
        id
        levels
      }
      updated {
        id
      }
      deleted
    }
  }
`;

export const upsertCategorizationImportMetadataMutation = gql`
  mutation upsertCategorizationImportMetadata(
    $categorizationID: UUID!
    $metadata: CategorizationImportMetadataInput!
  ) {
    upsertCategorizationImportMetadata(categorizationID: $categorizationID, metadata: $metadata) {
      name
      description
      columnLevel1
      columnLevel2
      columnLevel3
      columnLevel4
    }
  }
`;

export const createCategorizationMutation = gql`
  mutation createCategorization($projectID: UUID!, $name: String!, $description: String) {
    createCategorization(projectID: $projectID, name: $name, description: $description) {
      ... on Categorization {
        ...commonCategorizationFields
      }
      ... on CreateCategorizationErrors {
        userErrors
      }
    }
  }
  ${commonCategorizationFields}
`;

export const removeCategorizationsMutation = gql`
  mutation removeCategorizations($projectID: UUID!, $categorizationIDs: [UUID!]!) {
    removeCategorizations(projectID: $projectID, categorizationIDs: $categorizationIDs) {
      removed
    }
  }
`;

export const updateCategorizationMutation = gql`
  mutation updateCategorization($id: UUID!, $name: String, $description: String, $projectID: UUID) {
    editCategorization(id: $id, name: $name, description: $description, projectID: $projectID) {
      ... on Categorization {
        id
        name
        description
        modifiedBy {
          id
        }
        modifiedAt
      }
    }
  }
`;
