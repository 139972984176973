import { FC } from 'react';

import {
  DETACH_ITEM_ASSET,
  DETACH_MILESTONE_ASSET,
  DOWNLOAD_ITEM_ASSET,
  DOWNLOAD_MILESTONE_ASSET,
} from '../../../actions/actionTypes';
import {
  getItemAssetAnalytics,
  getMilestoneAssetAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { assetViewerOpenVar } from '../../../api/apollo/reactiveVars';
import { SourceSystem } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import { useDownloadAsset } from '../hooks/AssetsMutationHook';
import { useDetachFromItem } from '../hooks/mutations/useDetachFromItem';
import { useDetachFromMilestone } from '../hooks/mutations/useDetachFromMilestone';

import AssetsCards from './AssetsCards';

type AssetsCardsWrapperProps = {
  assets: EntityAttachment[];
  customProjectID?: UUID | undefined;
  hasDeletePermission: boolean;
  itemID?: UUID;
  readOnly?: boolean;
  showMenu: boolean;
  onItemMutated?: () => void;
};

const AssetsCardsWrapper: FC<AssetsCardsWrapperProps> = ({
  assets,
  customProjectID,
  hasDeletePermission,
  itemID,
  readOnly,
  showMenu,
  onItemMutated,
}) => {
  const milestoneID = getMilestoneIdFromUrl();
  const projectID = customProjectID ?? getProjectIdFromUrl();

  const sendAnalytics = useSendAnalytics();

  const [detachFromItemFunc] = useDetachFromItem();
  const [detachFromMilestoneFunc] = useDetachFromMilestone();
  const detachAttachment = (assetID: string, sourceSystem?: SourceSystem) => {
    if (itemID) {
      detachFromItemFunc(projectID, itemID, assetID, sourceSystem, onItemMutated);
      sendAnalytics(getItemAssetAnalytics(DETACH_ITEM_ASSET, itemID, assetID));
      assetViewerOpenVar(false);
    } else if (milestoneID) {
      detachFromMilestoneFunc(projectID, milestoneID, assetID, sourceSystem);
      sendAnalytics(getMilestoneAssetAnalytics(DETACH_MILESTONE_ASSET, milestoneID, assetID));
      assetViewerOpenVar(false);
    }
  };

  const [downloadAssetFunc] = useDownloadAsset();
  const downloadAsset = (location: string, name: string, id: string) => {
    downloadAssetFunc(location, name);
    if (itemID) {
      sendAnalytics(getItemAssetAnalytics(DOWNLOAD_ITEM_ASSET, itemID, id));
    } else if (milestoneID) {
      sendAnalytics(getMilestoneAssetAnalytics(DOWNLOAD_MILESTONE_ASSET, milestoneID, id));
    }
  };

  return (
    <AssetsCards
      assets={assets}
      detachAttachment={detachAttachment}
      downloadAsset={downloadAsset}
      hasDeletePermission={hasDeletePermission}
      readOnly={readOnly}
      showMenu={showMenu}
    />
  );
};

export default AssetsCardsWrapper;
