import { CSSProperties, FC, useRef, useState } from 'react';
import * as React from 'react';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { withStyles } from '../../../theme/komodo-mui-theme';
import InputsChips from '../../Inputs/InputsChips/InputsChips';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { PortalPositional } from '../../Portal/PortalPositional/PortalPositional';

import SelectFilter from './SelectFilter';
import styles, { portalWidth } from './SelectFilterChipInputStyles';

interface SelectFilterChipInputMultiProps {
  allSelectedText?: string;
  classes: Classes<typeof styles>;
  cy: string;
  depths?: number[];
  formatTooltip?: boolean;
  formatValueForDisplay?: (value: string) => string;
  hideAllChipsSelected?: boolean;
  maxChipsShown: number;
  onSelect: (value: string) => void;
  options: string[];
  labels?: string[];
  selected: string[];
  selectedLabels?: string[];
  indeterminate?: string[];
  title: string;
  tooltip: string[];
}

const SelectFilterChipInputMulti: FC<SelectFilterChipInputMultiProps> = ({
  allSelectedText,
  classes,
  cy,
  depths = [],
  formatTooltip = true,
  formatValueForDisplay,
  hideAllChipsSelected = false,
  maxChipsShown,
  onSelect,
  options,
  labels = [],
  indeterminate = [],
  selected,
  selectedLabels = [],
  title,
  tooltip,
}) => {
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const buttonRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [showSelect, setShowSelect] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const hasSelectedFilters = selected.length > 0;

  const generateChips = () => (
    <InputsChips
      allSelectedLabel={
        selected?.length > 0 && selected.length === options?.length ? allSelectedText : undefined
      }
      chosen={hideAllChipsSelected && selected.length === options.length ? [] : selected}
      chosenLabels={selectedLabels}
      defaultLabel={`Filter by ${title.toLowerCase()}...`}
      formatValueForDisplay={formatValueForDisplay}
      labelFn={(str) => str}
      max={maxChipsShown}
      showTitle={false}
    />
  );

  const placeholderClass = options && options.length > 0 ? '' : classes.empty;
  const button = (
    <button
      ref={buttonRef}
      className={classes.selectContentButton}
      onClick={() => {
        setShowSelect(true);
      }}
      onMouseEnter={(e) => {
        setAnchorEl(e.currentTarget);
      }}
      onMouseLeave={() => {
        setAnchorEl(null);
      }}
      tabIndex={0}
      type="button"
    >
      <span className={`${classes.chipsWrapper} ${placeholderClass}`}>{generateChips()}</span>
      <ArrowDropDownIcon />
    </button>
  );
  const hoverButton = hasSelectedFilters ? (
    <NormalTooltip
      PopperProps={{ anchorEl, open: !!anchorEl }}
      title={tooltip.map((value: string) => (
        <div key={value}>
          {formatTooltip && formatValueForDisplay ? formatValueForDisplay(value) : value}
        </div>
      ))}
    >
      {button}
    </NormalTooltip>
  ) : (
    button
  );
  const selectMenu = (
    <PortalPositional>
      {(boundingRect: DOMRect) => {
        const portalStyles: CSSProperties = {
          position: 'fixed',
          left: `${boundingRect.x}px`,
          top: `${boundingRect.y}px`,
          width: `${portalWidth}px`,
          zIndex: 999999,
        };
        return (
          <div style={portalStyles}>
            <div className={classes.containerSelect}>
              <SelectFilter
                depths={depths}
                filterOptions={options}
                formatValueForDisplay={formatValueForDisplay}
                indeterminate={indeterminate}
                labels={labels}
                onSelect={onSelect}
                onSubmit={() => {
                  setShowSelect(false);
                  setAnchorEl(null);
                  if (buttonRef!.current) {
                    buttonRef.current.focus();
                  }
                }}
                selectedFilters={selected}
                title={title}
              />
            </div>
          </div>
        );
      }}
    </PortalPositional>
  );
  return (
    <div ref={inputRef} className={classes.containerOuter} data-cy={cy || ''}>
      {showSelect ? selectMenu : hoverButton}
    </div>
  );
};

export default withStyles(styles)(SelectFilterChipInputMulti);
