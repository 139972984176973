import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DoneIcon from '@material-ui/icons/Done';

import {
  projectCostBreakdownReportEvent,
  projectCostBreakdownReportEventTypes,
} from '../../../../analytics/analyticsEventProperties';
import { isEstimateUploadDialogOpen } from '../../../../api/apollo/reactiveVars';
import { JoinProjectRoutes, TermKey } from '../../../../api/gqlEnums';
import { CURRENT_MILESTONE } from '../../../../constants';
import { NS_OWNER_COSTS } from '../../../../features';
import { PermissionResource } from '../../../../generated/graphql';
import { useHasFeature } from '../../../../hooks/useHasFeature';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { checkCostModeIncludesMarkups, useCostMode } from '../../../../utilities/costMode';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../../utilities/permissions/useShouldDisplayCosts';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { isNonNullable } from '../../../../utilities/types';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../../utilities/url';
import CostReportSettingsPanel from '../../../CostReport/CostReportSettingsPanel/CostReportSettingsPanel';
import { getItemListLink } from '../../../ItemsList/ItemsListUtils';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import { Button, MenuButton } from '../../../scales';
import MilestoneExportPanel from '../../MilestoneExportPanel/MilestoneExportPanel';
import MilestonePublishModalManager from '../../MilestoneModals/MilestoneModalsManager';
import MilestoneSettingsPanel from '../../MilestoneSettingsPanel/MilestoneSettingsPanel';
import { MilestoneDetailsTabs } from '../../utils';

import { LongRightIcon, ReportIcon } from './MilestoneDetailsNavIcons';
import { CostParams } from './MilestoneDetailsNavUtils';

type Props = {
  canDeleteDraftMilestones: boolean;
  canEditDraftMilestones: boolean;
  canEditMilestones: boolean;
  canViewMilestoneCosts: boolean;
  costParams: CostParams;
  hasEstimate: boolean;
  isActive: boolean;
  milestone: Milestone;
  projectName: string;
  view: string;
};

const MilestoneDetailsNavSettings: FC<Props> = ({
  canDeleteDraftMilestones,
  canEditDraftMilestones,
  canEditMilestones,
  canViewMilestoneCosts,
  costParams,
  hasEstimate,
  isActive,
  milestone,
  projectName,
  view,
}) => {
  const projectId = getProjectIdFromUrl();
  const costMode = useCostMode();
  const milestoneId = getMilestoneIdFromUrl();
  const { activeEstimateID, budgetID, id, isDraft, itemsCount, name } = milestone;

  const hasOwnerCostFeature = useHasFeature(NS_OWNER_COSTS);
  const hasEstimateOrBudget = activeEstimateID || budgetID;

  const t = useContext(ProjectTermStore);

  // DISPLAY DRAFT
  const [showPublishDraftMilestoneDialog, setShowPublishDraftMilestoneDialog] = useState(false);

  // PERMISSIONS
  const { canView } = usePermissions();
  const canViewMarkups = canView(PermissionResource.MARKUPS);
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const canViewProjectCategories = canView(PermissionResource.CATEGORIES_AND_TAGS);
  const canViewMilestoneEstimate = canView(PermissionResource.MILESTONE_LINES);
  const canViewMilestoneBudget = canView(PermissionResource.MILESTONE_BUDGET);
  const canViewDetails =
    view === MilestoneDetailsTabs.ESTIMATE ? canViewMilestoneEstimate : canViewMilestoneBudget;

  // FILTER INFO
  const {
    categorizations,
    columnDescriptions,
    displayColumnDescriptions,
    displayGroupBy,
    filterManager,
    page,
    settings,
    setSetting,
  } = costParams;
  const { status, viewMode } = settings;

  const hasCategorizations = categorizations && categorizations.length > 0;

  // ANALYTICS
  const sendAnalytics = useSendAnalytics();

  const navigate = useNavigate();
  const entries = [
    !isDraft
      ? {
          id: 'Items',
          label: `Related Items (${itemsCount})`,
          endAdornment: <LongRightIcon />,
          onClick: () => navigate(getItemListLink(projectId, { [CURRENT_MILESTONE]: [id] })),
        }
      : undefined,
    shouldDisplayCosts
      ? {
          id: 'MSR',
          label: 'Milestone Summary Report',
          endAdornment: <LongRightIcon />,
          onClick: () =>
            navigate(generateSharedPath(JoinProjectRoutes.MSR, { projectId, milestoneId })),
        }
      : undefined,
    hasOwnerCostFeature && hasEstimateOrBudget
      ? {
          id: 'PCBR',
          label: 'Project Cost Breakdown Report',
          endAdornment: <LongRightIcon />,
          onClick: () => {
            sendAnalytics(
              projectCostBreakdownReportEvent(
                projectCostBreakdownReportEventTypes.BREAKDOWN_REPORT_NAVIGATE
              )
            );
            navigate(
              generateSharedPath(JoinProjectRoutes.PROJECT_COST_BREAKDOWN_REPORT, {
                projectId,
                milestoneId,
              })
            );
          },
        }
      : undefined,
    canViewMarkups && shouldDisplayCosts && isActive
      ? {
          id: 'C&R',
          label: 'Contingency & Allowance Draw Report',
          endAdornment: <LongRightIcon />,
          onClick: () =>
            navigate(
              generateSharedPath(JoinProjectRoutes.CONTINGENCY_ALLOWANCE_REPORT, {
                projectId,
                milestoneId,
              })
            ),
        }
      : undefined,
  ].filter(isNonNullable);

  const isTarget = view === 'Target';
  const termKey: TermKey = isTarget ? TermKey.TARGET : TermKey.ESTIMATE;
  const replaceText = `Replace ${t.lowerCase(termKey)}`;

  return (
    <div className="mb-2.5 mt-3.5 flex items-center gap-4">
      <MenuButton
        data-cy="view-reports-menu-button"
        entries={entries}
        label="View Reports"
        startIcon={<ReportIcon />}
        type="secondary"
      />
      {canEditMilestones && hasEstimate && (
        <Button
          aria-label={replaceText}
          data-cy="replace-estimate-button"
          label={replaceText}
          onClick={() => isEstimateUploadDialogOpen(true)}
          type="secondary"
        />
      )}
      {isDraft && (
        <Button
          data-cy="publish-draft-milestone-button"
          disabled={!canEditDraftMilestones}
          label="Publish Draft"
          onClick={() => setShowPublishDraftMilestoneDialog(true)}
          startIcon={<DoneIcon className="float-left mr-1" />}
          type="primary"
        />
      )}
      <div className="flex items-center">
        <MilestonePublishModalManager
          isOpen={showPublishDraftMilestoneDialog}
          milestone={milestone}
          projectID={projectId}
          setIsOpen={setShowPublishDraftMilestoneDialog}
        />
        {shouldDisplayCosts && canViewDetails && hasCategorizations && (
          <CostReportSettingsPanel
            canViewProjectCategories={canViewProjectCategories}
            categorizations={categorizations}
            columnDescriptions={columnDescriptions}
            displayColumnDescriptions={displayColumnDescriptions}
            displayGroupBy={displayGroupBy}
            filterManager={filterManager}
            milestoneID={milestoneId}
            page={page}
            setSetting={setSetting}
            settings={settings}
            status={status}
            statusDisabled
            viewGroupByColumnsDisabled
            viewMode={viewMode}
          />
        )}
        <MilestoneSettingsPanel
          canDelete={isDraft ? canDeleteDraftMilestones : true}
          disabled={isDraft ? !canDeleteDraftMilestones : !canEditMilestones}
          isActive={isActive}
          isDetails
          milestone={milestone}
        />
        {shouldDisplayCosts && (
          <MilestoneExportPanel
            budgetID={budgetID ?? null}
            canView={canViewMilestoneCosts}
            disabled={!canViewMilestoneCosts}
            estimateID={activeEstimateID ?? null}
            includeMarkups={checkCostModeIncludesMarkups(costMode)}
            isDetails
            milestoneName={name}
            projectName={projectName}
          />
        )}
      </div>
    </div>
  );
};

export default MilestoneDetailsNavSettings;
