import { cloneDeep } from 'lodash';
import { useState } from 'react';

import { CallMadeRounded, CallReceived } from '@material-ui/icons';

import { COMPANY_DASHBOARD } from '../../constants';
import { InsightsSortKey, SearchResultType, SortDirection } from '../../generated/graphql';
import { useProjectDeliveryTypes } from '../../hooks/useProjectDeliveryTypesQuery';
import theme from '../../theme/komodo-mui-theme';
import { formatPercent } from '../../utilities/string';
import { usePersistentStates } from '../../utilities/urlState';
import ChartsPieGraph from '../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import { PieChartData } from '../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraphUtils';
import { LegendElementType } from '../Charts/ChartsLegend/ChartsLegendUtils';
import { useCompanyTabID } from '../CompanyTab/CompanyTabUtils';
import { useDesignPhaseTypes } from '../Milestone/hooks/useDesignPhaseTypesQuery';
import useSearchProjectsFilterOptionsQuery from '../ProjectsList/hooks/useSearchProjectsFilterOptionsQuery';
import { useFilterProjects } from '../ProjectsList/ProjectsListUtils';

import InsightsFilterMenu from './FilterMenu/InsightsFilterMenu';
import { useInsightsProjectsQuery } from './hooks/useInsightsProjectsQuery';
import InsightsTabs from './InsightsTabs';
import ProjectListData from './ProjectsListData';
import { InsightsTabId } from './types';

const InsightsProjectsList = () => {
  const [selectedTabID, setSelectedTabID] = useState(InsightsTabId.Costs);

  // Company data
  const companyID = useCompanyTabID();
  // Sorting
  const [sortBy, setSortBy] = useState<InsightsSortKey>(InsightsSortKey.NAME);

  // Filtering
  const [settings, setSettings] = usePersistentStates(
    window.location,
    '',
    {
      deliveryMethods: [],
      estimateCostRange: {
        max: null,
        min: null,
      },
      gsfRange: {
        max: null,
        min: null,
      },
      statuses: [],
      types: [],
      projectTypes: [],
      companies: [],
      locations: [],
      orgNodeIDs: [],
      milestoneDesignPhases: [],
      projectLeadIDs: [],
      designPhases: [],
    },
    'Insights Filters -'
  );
  const designPhaseTypes = useDesignPhaseTypes();
  const projectDeliveryTypes = useProjectDeliveryTypes();
  const filterManager = useFilterProjects(settings);
  const { data: filterOptionsData } = useSearchProjectsFilterOptionsQuery(SearchResultType.ALL);

  if (filterOptionsData && designPhaseTypes && projectDeliveryTypes) {
    const intialFilterOptions: SearchProjectsFilterOptions = cloneDeep(
      filterOptionsData.searchProjectsFilterOptions
    );
    intialFilterOptions.gsfRange = {
      max: null,
      min: null,
    };
    intialFilterOptions.estimateCostRange = {
      max: null,
      min: null,
    };
    intialFilterOptions.designPhases = designPhaseTypes.map((d) => d.name) || [];
    intialFilterOptions.deliveryMethods = projectDeliveryTypes.map((d) => d.name) || [];
    filterManager.filterOptions = intialFilterOptions;
    if (filterManager.filterOptions)
      filterManager.filterOptions.types =
        filterOptionsData.searchProjectsFilterOptions.projectTypes || [];
  }

  const { data: insightsProjectsData } = useInsightsProjectsQuery({
    companyID: companyID || '',
    filter: {
      deliveryTypes: filterManager.filterState?.deliveryMethods || [],
      gsfRange:
        typeof filterManager.filterState.gsfRange === 'string'
          ? {
              max: null,
              min: null,
            }
          : filterManager.filterState.gsfRange,
      statuses: filterManager.filterState?.statuses || [],
      types:
        filterOptionsData?.searchProjectsFilterOptions.projectTypes
          .filter((t) => filterManager.filterState?.types?.includes(t.id))
          .map((t) => t.name) || [],
      companies: filterManager.filterState?.companies || [],
      estimateCostRange:
        typeof filterManager.filterState.estimateCostRange === 'string'
          ? {
              max: null,
              min: null,
            }
          : filterManager.filterState.estimateCostRange,
      locations: filterManager.filterState?.locations || [],
      orgNodeIDs: filterManager.filterState?.orgNodeIDs || [],
      milestoneDesignPhases: filterManager.filterState?.designPhases || [],
      projectLeadIDs: filterManager.filterState?.projectLeadIDs || [],
    },
    sort: {
      sortKey: sortBy,
      sortDirection: SortDirection.SORT_ASCENDING,
    },
  });
  const insightsProjects = insightsProjectsData?.insights;

  // Pie Chart
  const legendElements: LegendElementType[] = [];
  const dataPie: PieChartData[] = [];
  const pieChartData = [
    { name: 'Pending', percentOfMilestoneEstimate: 50 },
    { name: 'Accepted', percentOfMilestoneEstimate: 50 },
    { name: 'Rejected', percentOfMilestoneEstimate: 15 },
    { name: 'Not Applicable', percentOfMilestoneEstimate: 5 },
  ];
  pieChartData.forEach((c) => {
    const { name, percentOfMilestoneEstimate } = c;
    dataPie.push({
      share: percentOfMilestoneEstimate,
      name,
    });
    legendElements.push({
      text: name,
      percentage: formatPercent(percentOfMilestoneEstimate, 4),
    });
  });

  if (!companyID) return null;
  return (
    <div className="flex h-full max-w-full grow flex-col gap-2">
      <div>
        <div className="flex justify-between px-5 py-4">
          <div className="flex type-heading1">{COMPANY_DASHBOARD}</div>
          <div className="flex">
            <InsightsFilterMenu filterManager={filterManager} setSettings={setSettings} />
          </div>
        </div>

        <div className="flex h-36 items-center justify-between px-5 py-4">
          <div className="flex items-center justify-between">
            <div>
              <ChartsPieGraph
                centerLabel={{ label: '234', sublabel: 'Pending' }}
                chartSize={{
                  diameter: 128,
                  insideDiameter: 80,
                }}
                colors={[
                  theme.palette.pending,
                  theme.palette.rejected,
                  theme.palette.accepted,
                  theme.palette.backgroundWhite,
                ]}
                data={dataPie}
                options={{ includeGradient: true }}
              />
            </div>
            <div className="p-4">
              {/* Header */}
              <div className="py-2 type-heading3">Items this week</div>

              {/* Stats */}
              <div className="flex justify-between gap-3">
                {/* Decided */}
                <div className="flex flex-col items-start gap-2">
                  <div className="type-label">Decided</div>
                  <div className="flex items-center justify-end">
                    <div className="type-heading3">5</div>i87
                    <div className="flex items-center">
                      <div className="[&_*]:!icon-sm">
                        <CallMadeRounded />
                      </div>
                      <div className="type-number">2 (previous)</div>
                    </div>
                  </div>
                </div>

                {/* Past due */}
                <div className="flex flex-col items-start gap-2">
                  <div className="type-label">Past due</div>
                  <div className="flex items-center justify-end">
                    <div className="type-heading3">16</div>
                    <div className="flex items-center">
                      <div className="[&_*]:!icon-sm">
                        <CallReceived />
                      </div>
                      <div className="type-number">3 (last)</div>
                    </div>
                  </div>
                </div>

                {/* Due */}
                <div className="flex flex-col items-start gap-2">
                  <div className="type-label">Due</div>
                  <div className="flex items-center justify-end">
                    <div className="type-heading3">9</div>
                    <div className="flex items-center">
                      <div className="[&_*]:!icon-sm">
                        <CallMadeRounded />
                      </div>
                      <div className="type-number">15 (previous)</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-3 flex flex-col justify-stretch">
        <InsightsTabs
          onSortByChange={setSortBy}
          onTabChange={setSelectedTabID}
          selectedSortBy={sortBy}
          selectedTabID={selectedTabID}
        />
      </div>

      <div className="mx-3 flex-1 overflow-y-auto">
        <ProjectListData insightsProjects={insightsProjects || []} tabId={selectedTabID} />
      </div>
    </div>
  );
};

export default InsightsProjectsList;
