import { useState } from 'react';
import { useDeepCompareEffect as useDeepEffect, useUnmount } from 'react-use';

import { SharedResource } from '../../../generated/graphql';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { useProjectCompaniesQuery } from '../../../hooks/useProjectCompaniesQuery';
import { useSearchCollaborators } from '../../../hooks/useSearchCollaborators';
import { isNonNullable } from '../../../utilities/types';
import { getSharedIds, toIds } from '../../../utilities/utilities';
import useMemoWrapper from '../../useMemoWrapper';

import { ToCollaborator, UsersSortKey } from './types';
import UsersFilter from './UsersFilter';
import UsersTable from './UsersTable';
import {
  addCompaniesToCollaborators,
  filterActiveCollaborators,
  filterCollaboratorsByKey,
  getCollaboratorKeys,
  getSharedCount,
  useUsersTableSelection,
} from './utils';

const COMPANY = UsersSortKey.COMPANY;
const ROLE = UsersSortKey.ROLE;

export type Props = {
  // Current Collaborator ID
  collaboratorID: UUID;
  // Default selected collaborators
  defaultSelectedCollaborators?: UUID[];
  // Data update callbacks:
  onCollaboratorNewIDs: (ids: UUID[]) => void;
  onCollaboratorRemovedIDs: (ids: UUID[]) => void;
  onCollaboratorSharedIDs: (ids: UUID[]) => void;
  // custom filter to be applied to the collaborators
  customFilter?: (collaborators: ToCollaborator[]) => ToCollaborator[];
  // For Analytics
  onCompany?: (company: string) => void;
  onOpenCheckboxTooltip?: () => void;
  onRole?: (role: string) => void;
  onSearch?: (term: string) => void;
  projectID: UUID;
  // An array for bulk editing, use array with single ID for non bulk
  sharedResources: SharedResource[];
};

export default function UsersTableFilter(props: Props) {
  const { data, loading: loadingCollaborators } = useCollaboratorsQuery(props.projectID);
  let activeCollaborators = useMemoWrapper(filterActiveCollaborators, data?.collaborators || []);
  const customFilter = props.customFilter || ((cs: ToCollaborator[]) => cs);
  activeCollaborators = useMemoWrapper(customFilter, activeCollaborators) || [];

  const { data: dataCompanies, loading: loadingCompanies } = useProjectCompaniesQuery(
    props.projectID
  );

  const projectCompanies = dataCompanies?.projectCompanies;
  let collaborators = useMemoWrapper(
    addCompaniesToCollaborators,
    activeCollaborators,
    projectCompanies || []
  );

  const [sharedCollaboratorIDs, disabledIds, initialIndeterminateMap, tooltipMap] = useMemoWrapper(
    getSharedIds,
    props.sharedResources,
    collaborators,
    props.collaboratorID
  );
  const topIds = [...sharedCollaboratorIDs, ...Object.keys(initialIndeterminateMap)];

  const roles = useMemoWrapper(getCollaboratorKeys, collaborators, ROLE);
  const companies = useMemoWrapper(getCollaboratorKeys, collaborators, COMPANY);

  const [search, setSearch] = useState('');
  const [role, setRole] = useState('');
  const [company, setCompany] = useState('');

  collaborators = useMemoWrapper(filterCollaboratorsByKey, collaborators, role, ROLE);
  collaborators = useMemoWrapper(filterCollaboratorsByKey, collaborators, company, COMPANY);
  collaborators = useSearchCollaborators(collaborators, search);

  const displayIDs = useMemoWrapper(toIds, collaborators);

  const initialIds = [
    ...(props.defaultSelectedCollaborators ?? []),
    ...sharedCollaboratorIDs,
    props.collaboratorID,
  ].filter(isNonNullable);
  const { onToggleEntry, onSelectAll, onUnselectAll, selectedIds, indeterminateMap, resetMaps } =
    useUsersTableSelection(initialIds, props.collaboratorID, initialIndeterminateMap, displayIDs);

  const { newCollaboratorIDs, removedCollaboratorIDs } = useMemoWrapper(
    getSharedCount,
    selectedIds,
    props.collaboratorID,
    sharedCollaboratorIDs,
    Object.entries(indeterminateMap).flatMap(([id, isIndeterminate]) =>
      isIndeterminate ? [] : [id]
    )
  );

  const { onCollaboratorSharedIDs } = props;
  useDeepEffect(() => onCollaboratorSharedIDs(sharedCollaboratorIDs), [sharedCollaboratorIDs]);
  const { onCollaboratorNewIDs } = props;
  useDeepEffect(() => onCollaboratorNewIDs(newCollaboratorIDs), [newCollaboratorIDs]);
  const { onCollaboratorRemovedIDs } = props;
  useDeepEffect(() => onCollaboratorRemovedIDs(removedCollaboratorIDs), [removedCollaboratorIDs]);

  useUnmount(() => resetMaps(initialIds, initialIndeterminateMap));

  const loading = loadingCollaborators || loadingCompanies;

  const header = (
    <UsersFilter
      collaboratorsShown={collaborators.length}
      collaboratorsTotal={activeCollaborators.length}
      companies={companies}
      onCompanyChange={(company) => {
        setCompany(company);
        props.onCompany?.(company);
      }}
      onRoleChange={(role) => {
        setRole(role);
        props.onRole?.(role);
      }}
      roles={roles}
      search={search}
      setSearch={(term) => {
        setSearch(term);
        props.onSearch?.(term);
      }}
    />
  );

  return (
    <>
      {header}
      <UsersTable
        disabledIds={disabledIds}
        entries={collaborators}
        indeterminateMap={indeterminateMap}
        loading={loading}
        onEntry={onToggleEntry}
        onOpenCheckboxTooltip={props.onOpenCheckboxTooltip}
        onSelectAll={onSelectAll}
        onToggleEntry={onToggleEntry}
        onUnselectAll={onUnselectAll}
        searchTerm={search}
        selectedIds={selectedIds}
        tooltipMap={tooltipMap}
        topIds={topIds}
      />
    </>
  );
}
