import { FC } from 'react';

import { withStyles } from '@material-ui/core/styles';

import styles from './SelectAllStyles';

interface SelectAllProps {
  classes: Classes<typeof styles>;
  clearAllActive?: boolean;
  clearAllText?: string;
  onClearAll: () => void;
  onSelectAll: () => void;
  selectAllActive?: boolean;
  selectAllText?: string;
}

const SelectAll: FC<SelectAllProps> = ({
  classes,
  clearAllActive = true,
  clearAllText = 'Clear',
  onClearAll,
  onSelectAll,
  selectAllActive = true,
  selectAllText = 'Select All',
}) => (
  <div className={classes.header} data-cy="SelectAll-header">
    <button
      className={`${classes.selectBtn} ${selectAllActive ? '' : classes.disabled}`}
      data-cy="SelectAll-button-selectAll"
      onClick={onSelectAll}
      type="button"
    >
      {selectAllText}
    </button>
    <button
      className={`${classes.selectBtn} ${clearAllActive ? '' : classes.disabled}`}
      data-cy="SelectAll-button-clearAll"
      onClick={onClearAll}
      type="button"
    >
      {clearAllText}
    </button>
  </div>
);

export default withStyles(styles)(SelectAll);
