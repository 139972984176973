import { FC } from 'react';

import { ImageDimension } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import Thumbnail from '../../../shared-widgets/Thumbnail/Thumbnail';
import { CompsPrintThumbnailSize, getHeaderTitleDisplayData } from '../PrintProjectCompsUtils';

import PrintProjectCompStyles from './PrintProjectCompStyles';

const thumbnailDimension = ImageDimension._200;

type PrintColumnHeaderTitleProps = {
  classes: Classes<typeof PrintProjectCompStyles>;
  compData: ProjectComp | AverageComp;
  onThumbnailLoad: () => void;
  thumbnailSize: CompsPrintThumbnailSize;
};

const PrintColumnHeaderTitle: FC<PrintColumnHeaderTitleProps> = ({
  classes,
  compData,
  onThumbnailLoad,
  thumbnailSize,
}) => {
  const { color, name, thumbnail } = getHeaderTitleDisplayData(compData);
  return (
    <>
      <div className={classes.columnHeaderTitle_color} style={{ backgroundColor: color }} />
      {thumbnailSize === CompsPrintThumbnailSize.LARGE && (
        <div className={classes.columnHeaderTitle_thumbnail}>
          <div className="pb-1">
            <Thumbnail
              dimension={thumbnailDimension}
              onLoad={onThumbnailLoad}
              padding={0}
              size={40}
              thumbnail={thumbnail}
            />
          </div>
        </div>
      )}
      <div className={classes.columnHeaderTitle_container}>
        {thumbnailSize === CompsPrintThumbnailSize.SMALL && (
          <div className={classes.columnHeaderTitle_thumbnail}>
            <Thumbnail
              dimension={thumbnailDimension}
              onLoad={onThumbnailLoad}
              padding={0}
              size={16}
              thumbnail={thumbnail}
            />
          </div>
        )}
        <div
          className={`${classes.columnHeaderTitle_titleTextPlacement} ${classes.titleText} truncate`}
        >
          {name}
        </div>
      </div>
    </>
  );
};

export default withStyles(PrintProjectCompStyles)(PrintColumnHeaderTitle);
