import { ReactElement } from 'react';

import TipTapTextArea, { TipTapTextAreaVariant } from './TipTapTextArea/TipTapTextArea';
import { isEmptyText, isRichText } from './TipTapTextArea/TipTapTextAreaUtils';

type InputsTextAreaStyledProps = {
  editable: boolean;
  emptyElement?: ReactElement;
  flexGrow?: boolean;
  hideBorder?: boolean;
  hasMinHeight?: boolean;
  label?: string;
  onAttachAsset?: (asset: Asset) => void;
  onChangeComplete?: (value: string, valueStyled: string) => void;
  placeholder: string;
  textStyled?: string;
  variant?: TipTapTextAreaVariant;
};

const InputsTextAreaStyled = ({
  editable,
  emptyElement,
  flexGrow,
  hideBorder = false,
  hasMinHeight = true,
  label,
  onAttachAsset,
  onChangeComplete,
  placeholder,
  textStyled = '',
  variant = TipTapTextAreaVariant.DEFAULT,
}: InputsTextAreaStyledProps) => {
  // If empty...
  if (isEmptyText(textStyled)) {
    // If disabled, then we should render empty div
    if (!editable) {
      return <div />;
    }
    // if we specify an empty element, then we should render that
    if (emptyElement && isRichText(textStyled)) {
      return emptyElement;
    }
  }

  return (
    <div>
      <label className="mr-auto text-type-primary type-label">{label}</label>
      <TipTapTextArea
        dataCy="inputsTextAreaStyled"
        editable={editable}
        flexGrow={flexGrow}
        hasMinHeight={hasMinHeight}
        hideBorder={hideBorder}
        onAttachAsset={onAttachAsset}
        onChangeComplete={onChangeComplete}
        placeholder={placeholder}
        textStyled={textStyled}
        variant={variant}
      />
    </div>
  );
};

export default InputsTextAreaStyled;
