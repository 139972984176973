import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

type Data = { date: Date; value: number };

const DEFAULT_SIZE = 4;
const DEFAULT_OPACITY = 1;

export default function TimelineCircle(props: {
  data: Data;
  fill: string;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  size?: number;
  opacity?: number;
}) {
  if (!props.data) return null;

  const attrs = {
    className: props.fill,
    r: props.size ?? DEFAULT_SIZE,
    cx: props.x(props.data.date),
    cy: props.y(props.data.value),
    style: {
      opacity: props.opacity ?? DEFAULT_OPACITY,
      fill: props.fill,
    },
  };

  return <circle {...attrs} />;
}
