/* eslint-disable no-param-reassign */
import queryString from 'query-string';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { isItemVariant, isNonFullscreenVariant } from '../../actions/gridAnalytics';
import { CategorizationDialogType } from '../../api/gqlEnums';
import { FieldType } from '../../api/gqlEnumsBe';
import { CATEGORIZATION_ID, ITEM, RESIZE } from '../../constants';
import { EstimateTotalType } from '../../generated/graphql';
import { useJoinScroll } from '../../hooks';
// eslint-disable-next-line import/no-cycle
import CategorizationsListDialogs from '../Categorizations/CategorizationsListDialogs/CategorizationsListDialogs';
// eslint-disable-next-line import/no-cycle
import AddColumnDialog from '../table/estimate/AddColumnDialog';
import AddRemoveRowButtons from '../table/estimate/AddRemoveRowButtons';

// eslint-disable-next-line import/no-cycle
import { horizontalScrollBarHeight, tableBodyMaxWidth } from './controller/sizing';
import useUpdate from './hooks/useUpdate';
import GridFooter from './JoinGridFooter';
// eslint-disable-next-line import/no-cycle
import GridHeader from './JoinGridHeader';
import JoinGridInnerTable from './JoinGridInnerTable';
import { ROW_SPACER_CLASS } from './style/styleConstants';
import { GridButtonData, GridController, GridType, GridVariant } from './types';
import { bound } from './utilities/range';
import { DEFAULT_ROW_HEIGHT, findNextPosition, findPosition } from './utilities/size';

// Draw 5 rows above the actual start row, and 5 past the end too.
export const ROW_BUFFER = 5;

export type ItemGridDisplayProps = {
  isDirectCost?: boolean;
  maxSectionHeight?: number;
  totalDifference: number;
};

interface VirtualTableProps {
  grid: GridController;
  editing: boolean;
  itemGridDisplayProps?: ItemGridDisplayProps;
  setEditing: (b: boolean) => void;
  setEditorDefaultValue: (s: GridCellValue) => void;
  buttons?: GridButtonData;
}

export const calcSectionHeight = (props?: ItemGridDisplayProps, variant?: GridVariant) => {
  if (variant === GridVariant.ITEM_TEMPLATE) return window.innerHeight;
  if (!props) return null;

  const { isDirectCost, maxSectionHeight, totalDifference } = props;
  return isDirectCost ? maxSectionHeight || 0 + totalDifference : maxSectionHeight;
};

const useVisibleRange = (grid: GridController, y: number, scrollBarWidth: number) => {
  const { lines } = grid.data;
  const totals = grid.getRowTotals();
  const end = lines.length - 1;

  // This is a binary search, and is actually relatively inexpensive.
  // I have never seen it take over 0.5ms to return.

  // First figure out what the actual visible lines are
  let startRow = bound(0, end, findPosition(totals, Math.max(0, y)) + 1);
  let endRow = bound(0, end, findNextPosition(totals, startRow + 1, y + grid.maxHeight()) - 1);
  grid.visibleRows.start = startRow;
  grid.visibleRows.end = endRow;
  const rowCount = totals.length;
  const lastRowTotal = totals[rowCount - 1];

  const scrollBarHeight = horizontalScrollBarHeight(
    grid.colWidths(),
    grid.visibleWidth(),
    lines.length,
    grid.scrollBarWidth() > 0,
    !grid.linesReadOnly,
    scrollBarWidth
  );
  const validLastStart = lastRowTotal ? lastRowTotal + rowCount + 1 : rowCount * DEFAULT_ROW_HEIGHT; // We need to account for borders around each row for the max height of section to match content
  const headerHeight = DEFAULT_ROW_HEIGHT + 2;
  const maxHeight =
    scrollBarHeight +
    validLastStart +
    (grid.totalType === EstimateTotalType.TOTAL_TYPE_COST_TYPES &&
    grid.type === GridType.ESTIMATE_GRID
      ? 2 * headerHeight
      : headerHeight);

  // Add the buffering
  startRow = Math.max(0, startRow - ROW_BUFFER);
  endRow = Math.min(lines.length - 1, endRow + ROW_BUFFER);
  grid.renderedRows.start = startRow;
  grid.renderedRows.end = endRow;

  const floaterTop = totals[startRow - 1] || 0;
  let floaterBottom = totals[totals.length - 1] - totals[endRow];
  if (Number.isNaN(floaterBottom)) {
    floaterBottom = 0;
  }
  // Don't virtualize while printing.
  if (grid.isPrinting) {
    grid.visibleRows.start = 0;
    grid.visibleRows.end = end;
    grid.renderedRows.start = 0;
    grid.renderedRows.end = end;
    return { floaterTop: 0, floaterBottom: 0, maxHeight, rowCount, lastRowTotal };
  }

  return { floaterTop, floaterBottom, maxHeight, rowCount, lastRowTotal };
};

const VirtualTable: FC<VirtualTableProps> = ({
  grid,
  editing,
  itemGridDisplayProps,
  setEditing,
  setEditorDefaultValue,
  buttons,
}) => {
  const forceUpdate = useUpdate();

  const {
    data: { lines, columns },
    variant,
  } = grid;
  const tableContainer = useRef<HTMLDivElement>(null);

  grid.setBodyRef(tableContainer);
  const { y, direction } = useJoinScroll(tableContainer, 0);
  const SCROLLBAR_WIDTH = grid.scrollBarWidth();
  const { floaterTop, floaterBottom, maxHeight, rowCount, lastRowTotal } = useVisibleRange(
    grid,
    y,
    SCROLLBAR_WIDTH
  );
  useEffect(() => {
    // when there's no height, the rows don't yet have a height calc (NaN)
    // TODO: Fix the height calcs to adjust when this is the case
    if (rowCount && Number.isNaN(lastRowTotal)) {
      window.dispatchEvent(new Event(RESIZE));
    }
  }, [rowCount, lastRowTotal]);

  const sectionHeight = calcSectionHeight(itemGridDisplayProps, variant);
  const height =
    sectionHeight && sectionHeight > 0 ? Math.min(maxHeight, sectionHeight) : maxHeight;

  let containerStyle = {};
  if (!isNonFullscreenVariant(variant)) {
    containerStyle = { maxHeight: height };
  }
  const { start, end } = grid.renderedRows;

  // The index at which a new column is going to be added (determined by
  // the dropdown in the header before the dialog selects the actual
  // categorization or type that this column will then have)
  const [newColumnIndex, setNewColumnIndex] = useState(-1);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [cellToScroll, setCellToScroll] = useState({ row: -1, column: -1 });

  const [categorizationDialogType, setCategorizationDialogType] =
    useState<CategorizationDialogType>(CategorizationDialogType.NONE);

  // scroll the table to the column
  const scrollToColumn = (column: number) => {
    if (column > -1) {
      grid.scrollToColumn(column);
      grid.scrollToRow(0);
      grid.selectCell(0, column);
    }
  };

  const applyHistoryChange = (search: string) => {
    const categorizationId = queryString.parse(search, { arrayFormat: 'index' })[CATEGORIZATION_ID];
    if (categorizationId) {
      const column = grid.data.columns.findIndex(
        (c) => (c.categorization || {}).id === categorizationId
      );
      scrollToColumn(column);
    }
  };

  const location = useLocation();
  useEffect(() => {
    applyHistoryChange(location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [location]);

  useEffect(() => {
    const { current } = tableContainer;
    const scrollListener = () => {
      // Close the editor if we're scrolling. TODO: seems to lag a frame or two after scroll,
      // but it's way better than the editor floating around.
      // If we're scrolling, close the editor.
      if (editing) {
        setEditing(false);
      }
    };
    setTimeout(() => {
      if (current) {
        current.addEventListener('scroll', scrollListener);
      }
      window.addEventListener('scroll', scrollListener);
    }, 0);
    return () => {
      if (current) {
        current.removeEventListener('scroll', scrollListener);
      }
      window.removeEventListener('scroll', scrollListener);
    };
  }, [editing, grid, setEditing, tableContainer]);

  const ADDED_COLUMNS = grid.linesReadOnly ? 1 : 2;

  const rowToScrollLoaded = !!lines[cellToScroll.row];
  useEffect(() => {
    if (rowToScrollLoaded) {
      const { row, column } = cellToScroll;
      grid.scrollToRow(row);
      grid.selectCell(row, column);
    }
  }, [cellToScroll, grid, rowToScrollLoaded]);

  // scroll the table to the cell and highlight
  const scrollToCell = (row: number, column: number) => {
    if (tableContainer.current !== null) {
      grid.scrollToRow(row);
      grid.selectCell(row, column);
      if (!lines[row]) {
        setCellToScroll({ row, column });
      }
    }
  };

  const buttonsComp =
    (!!buttons && (
      <div className="grid-add-remove-buttons">
        <AddRemoveRowButtons
          disabled={!!buttons.isAddDisabled}
          disabledDelete={grid.isRowSelectedArr.length === 0 || grid.numSelectedRows === 0}
          gridType={grid.type}
          id={buttons.id}
          onClick={buttons.onAddClick}
          onClickDelete={buttons.onDeleteClick ? buttons.onDeleteClick : undefined}
          onClickReplace={buttons.onReplaceClick}
          tooltip={buttons.tooltip}
        />
      </div>
    )) ||
    undefined;

  const addColumn = (categorizationID: UUID) => {
    grid.addColumns([
      {
        categorizationID,
        ordering: newColumnIndex,
        type: FieldType.CATEGORY,
      },
    ]);
    setNewColumnIndex(-1);
    setDisplayDialog(false);
  };

  const isReadOnlyVariant = grid.variant === GridVariant.READ_ONLY;

  return (
    <>
      {displayDialog && grid.projectID && (
        <AddColumnDialog
          addColumn={addColumn}
          canCreate={!grid.columnsReadOnly}
          estimateCategorizations={columns.map(
            (f) => (f.categorization && f.categorization.id) ?? undefined
          )}
          onClose={() => {
            setNewColumnIndex(-1);
            setDisplayDialog(false);
          }}
          onNewCategorizationSelect={() => {
            setCategorizationDialogType(CategorizationDialogType.NEW);
          }}
          onSuccess={() => {}}
          projectId={grid.projectID}
          variant={isItemVariant(variant) ? ITEM : ''}
        />
      )}
      {categorizationDialogType !== CategorizationDialogType.NONE && (
        <CategorizationsListDialogs
          onCreateSuccess={(c) => {
            if ('id' in c) addColumn(c.id);
          }}
          projectId={grid.projectID}
          setType={setCategorizationDialogType}
          type={categorizationDialogType}
        />
      )}
      <div
        ref={tableContainer}
        aria-labelledby="HeadersCol"
        className="join-grid-container"
        onScroll={() => {
          if (document.activeElement === document.body) {
            setTimeout(() => {
              if (tableContainer.current) tableContainer.current.focus();
            }, 0);
          }
        }}
        role="region"
        style={containerStyle}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <table
          className={`join-grid-table ${
            !isReadOnlyVariant ? 'header-bottom-border join-grid-table-total-borders' : ''
          }`}
        >
          {!grid.isSummary && (
            <GridHeader
              grid={grid}
              onCheckBoxClick={(isUnchecked: boolean) => {
                grid.isRowSelectedArr.fill(isUnchecked);
                grid.numSelectedRows = isUnchecked ? grid.isRowSelectedArr.length : 0;
                grid.previouslySelectedRow = -1;
                grid.updateTable();
              }}
              scrollToCell={scrollToCell}
              setDisplayDialog={setDisplayDialog}
              setEditing={setEditing}
              setNewColumnIndex={setNewColumnIndex}
            />
          )}
          {!grid.isSummary && (
            <tbody
              className={`join-grid-tbody ${!grid.linesReadOnly ? 'join-grid-editable' : ''}`}
              style={{
                maxWidth: tableBodyMaxWidth(grid.overallWidth()),
                overflowY: SCROLLBAR_WIDTH > 0 ? 'scroll' : undefined,
              }}
              tabIndex={-1}
            >
              <tr className={ROW_SPACER_CLASS} style={{ height: floaterTop }}>
                {/* Span all of the columns, including line # and delete column, with 1 cell */}
                <td colSpan={columns.length + ADDED_COLUMNS} />
              </tr>
              <JoinGridInnerTable
                bodyRef={tableContainer}
                endRow={end}
                grid={grid}
                isEditing={editing}
                parentUpdate={forceUpdate}
                scrollDirection={direction}
                setEditing={setEditing}
                setEditorDefaultValue={setEditorDefaultValue}
                startRow={start}
              />
              <tr className={ROW_SPACER_CLASS} style={{ height: floaterBottom }}>
                <td colSpan={columns.length + ADDED_COLUMNS} />
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {grid.footer ? (
        <GridFooter footer={grid.footer} grid={grid}>
          {buttonsComp}
        </GridFooter>
      ) : (
        buttonsComp
      )}
    </>
  );
};

export default memo(VirtualTable);
