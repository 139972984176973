import { FC } from 'react';

import { Checkbox } from '@material-ui/core';

import { TimelineActivityType } from '../../api/gqlEnumsBe';
import InputsChips from '../Inputs/InputsChips/InputsChips';
import JoinMultiSelect from '../Select/JoinMultiSelect/JoinMultiSelect';
import { getEntry } from '../Select/JoinSelect/JoinSelect';

export const ENTRIES: VISIBLE_TYPES[] = [
  TimelineActivityType.EVENT,
  TimelineActivityType.MILESTONE,
  TimelineActivityType.PHASE,
];

export const TIMELINE_ACTIVITY_NAMES = {
  [TimelineActivityType.EVENT]: 'Events',
  [TimelineActivityType.PHASE]: 'Phases',
  [TimelineActivityType.MILESTONE]: 'Milestones',
};

export type VISIBLE_TYPES =
  | TimelineActivityType.EVENT
  | TimelineActivityType.MILESTONE
  | TimelineActivityType.PHASE;

export const enumToStr = (type: TimelineActivityType): string => TimelineActivityType[type];

export const typeToName = (type: VISIBLE_TYPES): string => TIMELINE_ACTIVITY_NAMES[type];

// @ts-ignore TODO CT-545
export const strToType = (value: string): VISIBLE_TYPES => TimelineActivityType[value];

type TimelineActivitiesSelectProps = {
  disabled?: boolean;
  labelFn?: (o: string) => string;
  maxChips?: number;
  onChange: (evt: TimelineActivityType[]) => void;
  selected?: TimelineActivityType[];
};

const TimelineActivitiesSelect: FC<TimelineActivitiesSelectProps> = ({
  disabled = false,
  labelFn = (id) => typeToName(strToType(id)),
  maxChips = 2,
  onChange: onChangeOuter,
  selected: selectedOuter = [],
}) => {
  const selected = selectedOuter ? [...selectedOuter] : [];
  const entries = ENTRIES.map((type) => {
    const checked = selected.includes(type) || false;
    return getEntry(
      enumToStr(type),
      typeToName(type),
      false,
      <Checkbox checked={checked} style={{ width: 24, height: 24 }} />,
      undefined,
      undefined,
      checked
    );
  });

  const onChange = (values: string[]) => {
    const acts: TimelineActivityType[] = (values || []).map(strToType);
    if (!acts.length) {
      acts.push(...ENTRIES);
    }
    if (
      acts.includes(TimelineActivityType.MILESTONE) &&
      !acts.includes(TimelineActivityType.ACTIVE_MILESTONE)
    ) {
      acts.push(TimelineActivityType.ACTIVE_MILESTONE);
    }
    if (
      !acts.includes(TimelineActivityType.MILESTONE) &&
      acts.includes(TimelineActivityType.ACTIVE_MILESTONE)
    ) {
      const index = acts.indexOf(TimelineActivityType.ACTIVE_MILESTONE);
      if (index > -1) {
        acts.splice(index, 1);
      }
    }
    onChangeOuter(acts);
  };

  const index = selected.indexOf(TimelineActivityType.ACTIVE_MILESTONE);
  if (index > -1) {
    selected.splice(index, 1);
  }
  const value = selected.map((type) => TimelineActivityType[type]);

  return (
    <JoinMultiSelect
      disabled={disabled}
      entries={entries}
      fullWidth={false}
      menuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        id: 'InputsMultiSelectOption',
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      onChange={onChange}
      onClose={() => {}}
      onOpen={() => {}}
      renderValue={(chosen) => (
        <div>
          <InputsChips
            chosen={chosen}
            // Function to render the name
            labelFn={labelFn}
            max={maxChips}
            showTitle={false}
          />
        </div>
      )}
      renderValueAll={() => (
        <div>
          <InputsChips chosen={['All']} isAll max={1} />
        </div>
      )}
      value={value}
      width={160}
    />
  );
};

export default TimelineActivitiesSelect;
