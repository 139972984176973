import { useState } from 'react';

import { DD_ITEM_NUMBER_LAYOUT_CHANGES } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { TextInput } from '../../scales';

type Props = {
  editable: boolean;
  isPrivate: boolean;
  itemID: string;
  itemType: string;
  label?: string;
  onChange: (id: string, number: string, itemType: string) => void;
  value?: string;
};

const ItemNumberTextField = (props: Props) => {
  const hasItemNumberLayoutChanges = useHasFeature(DD_ITEM_NUMBER_LAYOUT_CHANGES);

  const [number, setNumber] = useState<string | undefined>(props.value);
  const disabled = !props.editable || props.isPrivate;

  return (
    <TextInput
      aria-label="item-number-field"
      data-cy="item-number-text-input"
      disabled={disabled}
      label={props.label}
      onBlur={() => {
        if (number) props.onChange(props.itemID, number, props.itemType);
      }}
      onChange={(e) => setNumber(e)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && number) props.onChange(props.itemID, number, props.itemType);
      }}
      placeholder={!hasItemNumberLayoutChanges ? 'Number' : ''}
      startAdornment={
        hasItemNumberLayoutChanges ? (
          <div className="cursor-default select-none text-type-muted">#</div>
        ) : null
      }
      value={number}
    />
  );
};

export default ItemNumberTextField;
