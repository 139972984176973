import { FC } from 'react';

import { ITEM_DETAILS } from '../../../constants';
import { useCostMode } from '../../../utilities/costMode';
import {
  ITEM_HISTORY_ZOOM,
  useHeightOnLoad,
} from '../../Print/PrintItemDetails/PrintItemDetailsUtils';

import CommentsHistory from './CommentsHistory';

type CommentsHistoryPrintProps = {
  itemId: UUID;
  triggerOnComplete?: () => void;
};

const CommentsHistoryPrint: FC<CommentsHistoryPrintProps> = ({ itemId, triggerOnComplete }) => {
  const { onLoadComplete, ref, height } = useHeightOnLoad(triggerOnComplete, ITEM_HISTORY_ZOOM);

  return (
    <div
      ref={ref}
      style={{ height, transform: `scale(${ITEM_HISTORY_ZOOM})`, transformOrigin: 'top left' }}
    >
      <CommentsHistory
        canComment={false}
        costMode={useCostMode()}
        itemId={itemId}
        triggerOnComplete={onLoadComplete}
        variant={ITEM_DETAILS}
      />
    </div>
  );
};

export default CommentsHistoryPrint;
