import { FC, Fragment } from 'react';

import { CircularProgress } from '@material-ui/core';

import { itemActivityTimeSelector } from '../../../analytics/analyticsEventProperties';
import { PROJECT_ACTIVITY_EVENT_TABLE_SCROLL_ID } from '../../../constants';
import { DateGroup } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { isPrintFromUrl } from '../../../utilities/url';
import ItemActivityDateDivider from '../../Items/ItemActivityFeed/ItemActivityDateDivider';
import { ItemActivityEvent, ItemEventDetails } from '../utils';

import ItemHistoryEvent from './ItemHistoryEvent';

// ToDo: comments, attachments, and user icon when there is a comment
type Props = {
  events: ItemActivityEvent[];
  isItemHistory?: boolean;
  itemDetails?: ItemEventDetails;
  projectID: UUID;
  isDoneFetching: boolean;
  shouldDisplayCosts: boolean;
  canViewItemAttachments: boolean;
  selectedEventID?: UUID | null;
  setSelectedEventID?: (eventID: UUID | null) => void;
};

const ItemHistoryEventTable: FC<Props> = ({
  events,
  projectID,
  itemDetails,
  isItemHistory,
  isDoneFetching,
  shouldDisplayCosts,
  canViewItemAttachments,
  selectedEventID,
  setSelectedEventID,
}) => {
  const sendAnalytics = useSendAnalytics();
  const isPrint = isPrintFromUrl();

  const handleSelect = (dateGroup: DateGroup) => {
    const el = document.getElementById(dateGroup);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      // if there is no ref, scroll to the top of the container
      const container = document.getElementById(PROJECT_ACTIVITY_EVENT_TABLE_SCROLL_ID);
      if (container) {
        sendAnalytics(itemActivityTimeSelector(dateGroup));
        container.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  const loading = () =>
    isDoneFetching ? (
      <div key="done" className="my-10 w-full text-center text-type-muted type-body1">
        {`You've discovered the beginning of (our) time`}
      </div>
    ) : (
      <div key="loading" className="mx-auto mt-2 h-20 w-full">
        <div className="flex h-full items-center justify-center">
          <CircularProgress />
        </div>
      </div>
    );
  return (
    <div
      className={`${!isItemHistory ? 'mx-auto' : ''} max-w-[850px]`}
      data-cy="item-activity-event-table"
    >
      {events.map((event, i, array) => {
        let groupDividerElement = null;
        const prevDate = array[i - 1]?.date;
        if (!isItemHistory && prevDate !== event.date) {
          groupDividerElement = (
            <ItemActivityDateDivider
              currentDateGroup={event.dateGroup}
              date={event.date}
              handleSelect={handleSelect}
            />
          );
        }

        return (
          <Fragment key={event.id}>
            {groupDividerElement}
            <ItemHistoryEvent
              canViewItemAttachments={canViewItemAttachments}
              event={event}
              isItemHistory={isItemHistory}
              isPrint={isPrint}
              itemDetails={itemDetails}
              projectID={projectID}
              selectedEventID={selectedEventID}
              setSelectedEventID={setSelectedEventID}
              shouldDisplayCosts={shouldDisplayCosts}
            />
          </Fragment>
        );
      })}
      {!isItemHistory && loading()}
    </div>
  );
};

export default ItemHistoryEventTable;
