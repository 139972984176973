import {
  CostTrendlineEventsQuery,
  CostTrendlineEventsQueryVariables,
} from '../../../../../generated/graphql';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';
import { useCostMode } from '../../../../../utilities/costMode';

import { costTrendlineEventsQuery } from './queries';

export function useCostTrendlineEventsQuery(
  projectID: UUID,
  milestoneID: UUID,
  viewFilter: ViewFilterInput,
  columnInputs: CostReportColumnInput[],
  quantityMagnitude?: number
) {
  return useQuery<CostTrendlineEventsQuery, CostTrendlineEventsQueryVariables>(
    costTrendlineEventsQuery,
    {
      variables: {
        projectID,
        viewFilter,
        costMode: useCostMode(),
        milestoneID,
        columnInputs,
        quantityMagnitude,
      },
      errorPolicy: 'all',
      skip: !milestoneID,
    }
  );
}
