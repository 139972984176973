import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { ItemShareKey, navigateItemsAnalytics } from '../../../analytics/analyticsEventProperties';
import { ITEM_NUMBER_CHARLIMIT, VISIBILITY_DRAFT_ITEM } from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { capitalizeString } from '../../../utilities/string';
import ItemsListItemPrivateBadge from '../ItemsListItem/ItemsListItemPrivateBadge/ItemsListItemPrivateBadge';
import { styleItemNumber } from '../ItemsListItem/ItemsListItemUtils';
import ItemsQuickLink from '../ItemsQuickLink/ItemsQuickLink';
import { getStatusIsChosen, isPrivateVisibility } from '../ItemsUtils';

import ItemsDetailsTopNavStyles from './ItemDetailsTopNavStyles';
import NavigationArrowLink, { ArrowDirection } from './NavigationArrowLink/NavigationArrowLink';

const getItemFromDirection = (direction: ArrowDirection, navigationLinks: NavigationLinks) =>
  direction === ArrowDirection.NEXT ? navigationLinks.next : navigationLinks.previous;

// UNIT TESTED HELPER FUNCTIONS
function checkIfLinkExists(direction: ArrowDirection, navigationLinks?: NavigationLinks) {
  if (navigationLinks) {
    const item = getItemFromDirection(direction, navigationLinks);
    return !!item;
  }
  return false;
}

type ItemDetailsTopNavProps = {
  classes: Classes<typeof ItemsDetailsTopNavStyles>;
  item?: ItemDataQueryItem;
  navigationLinks?: NavigationLinks;
  parentItem: ItemLike;
  projectId: UUID;
};

const ItemsDetailsTopNav: FC<ItemDetailsTopNavProps> = ({
  classes,
  item = null,
  navigationLinks,
  parentItem,
  projectId,
}) => {
  // Hook for analytics
  const sendAnalytics = useSendAnalytics();
  // VARIABLES
  const isOption = parentItem?.id; // if the item has a parent item it's an option
  const { number, visibility } = item ?? {};
  const isPrivate = isPrivateVisibility(visibility);
  const itemType = isOption ? 'option' : 'item';
  const draftNameTag = VISIBILITY_DRAFT_ITEM;

  // GENERATORS
  function generateArrowButton(direction: ArrowDirection, navigationLinks?: NavigationLinks) {
    if (!navigationLinks) return <></>;
    const nodeExists = checkIfLinkExists(direction, navigationLinks);
    const linkItem = getItemFromDirection(direction, navigationLinks);
    const { visibility, number, name } = linkItem || {};
    const isLinkPrivate = isPrivateVisibility(visibility ?? undefined);
    if (nodeExists) {
      const title = `${capitalizeString(direction)} ${itemType}: ${
        number ? `${number}: ` : ''
      }${name}`;
      const { search } = window.location;
      const link = generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
        projectId,
        itemId: linkItem?.id,
        search,
      });
      const onClick = () =>
        sendAnalytics(navigateItemsAnalytics(`${itemType} ${capitalizeString(direction)} Click`));
      return (
        <NavigationArrowLink direction={direction} link={link} onClick={onClick} title={title}>
          <>
            {isLinkPrivate && (
              <ItemsListItemPrivateBadge
                analyticsKey={ItemShareKey.ITEM}
                showDraftToolTip={false}
              />
            )}
            <div className={classes.linkNumber}>
              <ItemsQuickLink
                charNumberLimit={ITEM_NUMBER_CHARLIMIT}
                ellipses
                item={linkItem as Item}
                parentIsChosen={parentItem ? getStatusIsChosen(parentItem.status) : false}
                variant={direction}
              />
            </div>
          </>
        </NavigationArrowLink>
      );
    }
    return (
      <NavigationArrowLink direction={direction}>
        <Typography className={classes.p}>{`No ${direction} ${itemType}`}</Typography>
      </NavigationArrowLink>
    );
  }
  const parentBreadcrumbs = () => (
    <>
      {isPrivateVisibility(parentItem.visibility) && (
        <ItemsListItemPrivateBadge analyticsKey={ItemShareKey.ITEM} showDraftToolTip={false} />
      )}
      <ItemsQuickLink
        charNameLimit={18}
        charNumberLimit={ITEM_NUMBER_CHARLIMIT}
        ellipses
        hasDefaultWidth={false}
        item={parentItem}
        variant="previous"
      />
      <Typography className={classes.divider}> / </Typography>
    </>
  );

  const generateBreadcrumbs = () => (
    <>
      <Link
        className={`${classes.a}`}
        data-cy="breadcrums-itemsList"
        to={generateSharedPath(RouteKeys.PROJECT_ITEMS, { projectId })}
      >
        <Typography className={` ${classes.breadcrumbsLocation}`}>Items </Typography>
      </Link>
      <Typography className={classes.divider}> / </Typography>
      {isOption && parentBreadcrumbs()}
      {item && (
        <>
          {isPrivate && (
            <ItemsListItemPrivateBadge analyticsKey={ItemShareKey.ITEM} showDraftToolTip={false} />
          )}
          <Typography className={classes.currentNodeNumber} data-cy="topNav-currentNodeNumber">
            {styleItemNumber(number)}
            {isPrivate && `${draftNameTag} ${itemType}`}
          </Typography>
        </>
      )}
    </>
  );

  return (
    <div className={classes.outerContainer}>
      <div className={classes.breadcrumbs} id="breadcrumbs">
        {generateBreadcrumbs()}
      </div>
      <div className={classes.navigation}>
        {generateArrowButton(ArrowDirection.PREVIOUS, navigationLinks)}
        {generateArrowButton(ArrowDirection.NEXT, navigationLinks)}
      </div>
    </div>
  );
};

export default withStyles(ItemsDetailsTopNavStyles)(ItemsDetailsTopNav);
