import {
  InsightsInput,
  InsightsProjectsQuery,
  InsightsProjectsQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { insightsProjectsQuery } from './queries';

export const useInsightsProjectsQuery = (input: InsightsInput) => {
  return useQuery<InsightsProjectsQuery, InsightsProjectsQueryVariables>(insightsProjectsQuery, {
    variables: { input },
    skip: !input.companyID,
  });
};
