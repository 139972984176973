import {
  ActiveMilestoneIcon,
  EventIcon,
  MilestoneIcon,
  PhaseIcon,
  TodayIcon,
} from '../../Icons/Timeline';

export default function TimelineLegend() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const legendEntry = (icon: any, text: string) => (
    <div className="flex items-center pl-2">
      <div className="p-1">{icon}</div>
      <div className="p-1">
        <div className="type-label">{text}</div>
      </div>
    </div>
  );

  return (
    <div className="ml-2 flex h-5 items-center self-end">
      {legendEntry(TodayIcon, 'Today')}
      {legendEntry(ActiveMilestoneIcon, 'Active Milestone')}
      {legendEntry(MilestoneIcon, 'Milestone')}
      {legendEntry(PhaseIcon, 'Phase')}
      {legendEntry(EventIcon, 'Event')}
    </div>
  );
}
