import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useUpdate } from 'react-use';

import { TOTAL } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getUnitText } from '../../CostReport/CostReportList/CostReportList/CostReportListUtils';
import SelectTextMenu, {
  MenuVariants,
  SelectTextMenuOption,
} from '../../Select/SelectTextMenu/SelectTextMenu';
import InputsToggle, { ToggleOption } from '../InputsToggle/InputsToggle';

import styles from './UnitToggleStyles';
import { getNonZeroQuantities } from './UnitToggleUtils';

type UnitToggleProps = {
  classes: Classes<typeof styles>;
  quantity: Quantity | undefined;
  quantities: Quantity[];
  setQuantity: (quanitity?: Quantity) => void;
  variant?: MenuVariants;
};

const getToggleOption = (quantity?: Quantity) => ({
  key: (quantity && quantity.unit.id) || '',
  text: quantity ? getUnitText(quantity.unit.abbreviationSingular, false) : TOTAL,
});

const getSelectOption = (quantity?: Quantity): SelectTextMenuOption => {
  const toggleOption = getToggleOption(quantity);
  return {
    ...toggleOption,
    subText: (quantity && quantity.unit.name) || 'Total project cost',
    cy: toggleOption.text,
  };
};

const MAX_WIDTH = 300;
// If we have 4 or more quantites, 5 options with TOTAL then we go to dropdown
const MAX_QUANTITIES_FOR_TOGGLE = 4;

const UnitToggle: FC<UnitToggleProps> = ({
  classes,
  setQuantity,
  quantity,
  quantities,
  variant,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  useEffect(useUpdate(), [quantities]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const toggleRef = useRef<any>(null);
  const nonZeroQuantities = getNonZeroQuantities(quantities);
  const toggleWidth =
    toggleRef && toggleRef.current && toggleRef.current.getBoundingClientRect().width;
  const [width, setWidth] = useState(MAX_WIDTH);
  const isInline = !!variant;

  // Try a couple time to get the measured toggle width after initial render
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (toggleWidth !== null) {
      if (toggleWidth !== width) {
        setWidth(toggleWidth);
      }
    } else if (count < 2) {
      setCount(count + 1);
    }
  }, [count, toggleWidth, width]);

  const isDropdown = useMemo(
    () => width >= MAX_WIDTH || quantities.length >= MAX_QUANTITIES_FOR_TOGGLE,
    [width, quantities]
  );

  if (!quantities || nonZeroQuantities.length === 0) return null;
  const options = [undefined, ...nonZeroQuantities];
  const toggleOptions = options.map(getToggleOption);
  const selectOptions = options.map(getSelectOption);

  const setSelected = (selected: ToggleOption | { key: string }) => {
    const thisQuantity = quantities.find(
      (q: Quantity | undefined) => q && q.unit.id === selected.key
    );
    setQuantity(thisQuantity);
  };

  // With the measurement of a hidden toggle, we can decide which to render...
  const hiddenToggle = (
    <div ref={toggleRef} className={classes.measure}>
      <InputsToggle
        isVisible={false}
        options={toggleOptions}
        selected={[getToggleOption(quantity)]}
        setSelected={setSelected}
      />
    </div>
  );

  const displayToggle = isDropdown ? (
    <SelectTextMenu
      cy="uom"
      menuOptions={selectOptions}
      onChange={(key: string) => setSelected({ key })}
      selected={getSelectOption(quantity).key}
    />
  ) : (
    <InputsToggle
      options={toggleOptions}
      selected={[getToggleOption(quantity)]}
      setSelected={setSelected}
    />
  );

  return (
    <>
      {hiddenToggle}
      {toggleWidth > 0 ? (
        <div
          className="flex flex flex-shrink-0 flex-grow-0 flex-col flex-col gap-0.5"
          style={{ width: isDropdown && !isInline ? '100%' : 'max-content' }}
        >
          <div className="type-label">Units of Measure</div>
          {displayToggle}
        </div>
      ) : null}
    </>
  );
};

export default withStyles(styles)(UnitToggle);
