import { Link } from 'react-router-dom';

import { BusinessOutlined, LocationOnOutlined } from '@material-ui/icons';

import { forecastingEventTypes } from '../../../../analytics/analyticsEventProperties';
import {
  projectCompsSetInputVar,
  projectCompsSettingsInputDefault,
} from '../../../../api/apollo/reactiveVars';
import { RouteKeys } from '../../../../routes/paths';
import { FORECASTING_REPORTS_REPORT_NAME } from '../../../../tagConstants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../../utilities/routes/links';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import SearchText from '../../../Search/SearchText/SearchText';
import useSendForecastingAnalytics from '../../hooks/useSendForecastingAnalytics';

import styles from './NameCellStyles';

export default withStyles(styles)(
  ({
    classes,
    report,
    searchText,
  }: {
    classes: Classes<typeof styles>;
    report: ForecastingReport;
    searchText: string;
  }) => {
    const sendForecastingAnalytics = useSendForecastingAnalytics();
    return (
      <div className={classes.container}>
        <div className={classes.info}>
          <Link
            className={classes.linkText}
            data-cy={`${FORECASTING_REPORTS_REPORT_NAME}-${report.name}`}
            onClick={() => {
              projectCompsSetInputVar(projectCompsSettingsInputDefault);
              sendForecastingAnalytics(forecastingEventTypes.VIEW_REPORT_CTA, {
                location: 'reports',
                reportID: report.id,
                reportName: report.name,
              });
            }}
            to={generateSharedPath(RouteKeys.FORECASTING_PROJECT_COMPS_SAVED, {
              reportID: report.id,
            })}
          >
            <NormalTooltip title={report.description}>
              <div className={classes.name} data-cy="report-name">
                <SearchText searchTerm={searchText} text={report.name} />
              </div>
            </NormalTooltip>
          </Link>
          <div className={classes.chips}>
            {report.attachedProject?.type?.name && (
              <div className={classes.chip} data-cy="report-location">
                <BusinessOutlined className={classes.chip_icon} />
                <div>{report.attachedProject.type.name}</div>
              </div>
            )}
            {report.attachedProject?.location && (
              <div className={classes.chip} data-cy="report-location">
                <LocationOnOutlined className={classes.chip_icon} />
                <div>{report.attachedProject.location}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
