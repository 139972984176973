import Https from '@material-ui/icons/Https';

import { VISIBILITY_DRAFT_ITEM, VISIBILITY_PUBLISH_ITEM } from '../../../constants';
import { Visibility } from '../../../generated/graphql';
import { GroupsIcon } from '../../Icons/GroupsIcon';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Radio, RadioGroup, Tooltip } from '../../scales';
import { isPrivateVisibility } from '../ItemsUtils';

import {
  DISABLE_VISIBILITY_TOGGLE_TOOLTIP,
  PRIVATE_ITEM_ICON_COPY,
  PUBLIC_ITEM_ICON_COPY,
} from './ItemVisibilityToggleUtils';

type ItemVisibilityRadioProps = {
  isDisabled: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onChange: (event: any) => void;
  visibilitySetting: Visibility;
  setShowPublishItemDialog?: (setShowPublishItemDialog: boolean) => void;
  setVisibilitySetting: (visibility: Visibility) => void;
};

export default function ItemVisibilityRadio({
  isDisabled,
  onChange,
  visibilitySetting,
  setShowPublishItemDialog,
  setVisibilitySetting,
}: ItemVisibilityRadioProps) {
  const isPrivate = isPrivateVisibility(visibilitySetting);

  const iconCaptionCopy = isPrivate ? PRIVATE_ITEM_ICON_COPY : PUBLIC_ITEM_ICON_COPY;

  const icon = isPrivate ? <Https /> : <GroupsIcon />;
  const updateVisibility = (visibility: Visibility) => {
    if (setShowPublishItemDialog) {
      setShowPublishItemDialog(true);
    } else {
      setVisibilitySetting(visibility);
      onChange({ visibility });
    }
  };

  const onChangeInputVisibility = () => {
    if (isPrivateVisibility(visibilitySetting)) {
      updateVisibility(Visibility.PUBLISHED);
    } else {
      updateVisibility(Visibility.PRIVATE_DRAFT);
    }
  };

  const visibilityIcon = (
    <div className="flex items-center">
      <div className="p-1">{icon}</div>
      <div className="p-1">
        <div className="type-body1">{iconCaptionCopy}</div>
      </div>
    </div>
  );

  if (isDisabled) {
    return (
      <NormalTooltip title={DISABLE_VISIBILITY_TOGGLE_TOOLTIP}>{visibilityIcon}</NormalTooltip>
    );
  }
  const tooltipDraft = (
    <div className="type-table-text">
      Draft items have limited availability to the team. Shareable with select teammates.
    </div>
  );
  const tooltipPublish = (
    <div className="type-table-text">Published items are available to all teammates.</div>
  );

  return (
    <div className="pb-1 pt-1">
      <div className="mb-2 mr-auto text-type-primary type-label">Share Settings</div>
      <RadioGroup onChange={onChangeInputVisibility} value={isPrivate ? 'true' : 'false'}>
        <div className="flex gap-8">
          <Radio data-cy="radio-public" value="false">
            <div className="flex flex-grow items-center gap-2">
              <GroupsIcon />
              <Tooltip content={tooltipPublish}>
                <div className="type-body1">{VISIBILITY_PUBLISH_ITEM}</div>
              </Tooltip>
            </div>
          </Radio>
          <Radio data-cy="radio-private" value="true">
            <div className="flex flex-grow items-center gap-2">
              <Tooltip content={tooltipDraft}>
                <div className="type-body1">{VISIBILITY_DRAFT_ITEM}</div>
              </Tooltip>
            </div>
          </Radio>
        </div>
      </RadioGroup>
    </div>
  );
}
