import { FC } from 'react';

import { Restore } from '@material-ui/icons';

import { ReportGroups } from '../../../api/gqlEnums';
import { REPORTS } from '../../../constants';
import { NS_REPORT_DISTRIBUTION } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { usePersistentStates } from '../../../utilities/urlState';
import { Button } from '../../scales';
import ShadedHelpTip from '../../shared-widgets/ShadedHelpTip/ShadedHelpTip';
import ReportsAccordion from '../ReportsAccordion/ReportsAccordion';
import { ReportsListEntryDataType } from '../ReportsList/ReportsList';
import { headerLabels } from '../ReportsList/ReportsList.mock';

import styles from './ReportsTabStyles';
import { reportsCountText } from './ReportsUtils';

const tipText =
  'You can customize, save, and print/download reports using the options at the top right of each report view';

type ReportsTabDataProps = {
  accordionReports: ReportsListEntryDataType[][];
  accordionHeaders: ((count: number) => string)[];
  setDistributionHistoryDialogIsOpen: () => void;
  classes: Classes<typeof styles>;
  reportsDefaults: { collapse: ReportGroups[]; expand: ReportGroups[] };
};

const ReportsTab: FC<ReportsTabDataProps> = ({
  accordionReports,
  accordionHeaders,
  setDistributionHistoryDialogIsOpen,
  classes,
  reportsDefaults,
}) => {
  const hasReportDistributionAccess = useHasFeature(NS_REPORT_DISTRIBUTION);
  const projectID = getProjectIdFromUrl();

  const [settings, setSettings] = usePersistentStates(
    window.location,
    REPORTS,
    reportsDefaults,
    `${projectID} - Reports - `
  );

  const header = (
    <div className="flex w-full items-center justify-between bg-background-primary p-4">
      <header className="type-heading1">Reports</header>
      <ShadedHelpTip shiftLeft tip={tipText} />
    </div>
  );

  const reportsCount = accordionReports.reduce((accum, current) => accum + current.length, 0);

  const distributedReportCount =
    accordionReports.flat().filter((report) => report.hasDistributionScheduled).length || 0;

  const reportsCountContent = (
    <p className="type-table-text">
      {reportsCountText(reportsCount, distributedReportCount, hasReportDistributionAccess)}
    </p>
  );

  const labelHeader = (
    <div className={classes.containerRowHeader}>
      {headerLabels.map((labels, i) => (
        <div key={JSON.stringify(i)} className={classes.cell}>
          <p className={classes.headerText}>{labels}</p>
        </div>
      ))}
    </div>
  );

  const content = (
    <ReportsAccordion
      accordionHeaders={accordionHeaders}
      accordionReports={accordionReports}
      setSettings={setSettings}
      settings={settings}
    />
  );

  const DistributionHistoryButton = (
    <div className="flex p-3">
      <Button
        label="Distribution History"
        onClick={setDistributionHistoryDialogIsOpen}
        startIcon={<Restore />}
        type="tertiary"
      />
    </div>
  );

  return (
    <div className="width[calc(100%-2px)] break-inside-avoid overflow-visible border border-solid">
      {header}
      <div
        className={`flex flex-row items-center justify-between bg-background-primary ${
          hasReportDistributionAccess ? 'pl-4' : 'p-4 pb-2'
        }`}
      >
        {reportsCountContent}
        {hasReportDistributionAccess && DistributionHistoryButton}
      </div>
      {labelHeader}
      {content}
    </div>
  );
};

export const StyledReportsTab = withStyles(styles)(ReportsTab);

export default StyledReportsTab;
