import { FC } from 'react';

import MultiGroup from './MultiGroup';
import { GroupByOption, MultiGroupOrderOption } from './MultiGroupOrderCategorizationUtils';
import MultiGroupOrderSelector, { EMPTY_MESSAGE_NONE } from './MultiGroupOrderSelector';

type MultiGroupOrderProps = {
  displayChips?: number;
  emptyMessage?: string;
  handleAdd: (toAdd: MultiGroupOrderOption[]) => void;
  handleDelete: (toDelete: MultiGroupOrderOption[]) => void;
  handleSet: (toSet: GroupByOption[]) => void;
  handleSort: (a: number, b: number) => void;
  hasSuggestions?: boolean;
  open?: boolean;
  options?: MultiGroupOrderOption[];
  selected?: MultiGroupOrderOption[];
  setOpen: (isOpen: boolean) => void;
};

const DISPLAY_CHIPS = 3;

const MultiGroupOrder: FC<MultiGroupOrderProps> = ({
  displayChips = DISPLAY_CHIPS,
  emptyMessage = EMPTY_MESSAGE_NONE,
  handleAdd,
  handleDelete,
  handleSet,
  handleSort,
  hasSuggestions,
  open = true,
  options = [],
  selected = [],
  setOpen,
}) => (
  <MultiGroup
    displayChips={displayChips}
    emptyMessage={emptyMessage}
    open={open}
    selectedNames={selected.map((s) => s.name ?? '')}
    setOpen={setOpen}
  >
    <MultiGroupOrderSelector
      handleAdd={handleAdd}
      handleDelete={handleDelete}
      handleSet={handleSet}
      handleSort={handleSort}
      hasSuggestions={hasSuggestions}
      options={options}
      selected={selected}
    />
  </MultiGroup>
);

export default MultiGroupOrder;
