import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { TextField } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, Autorenew, Edit } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import {
  EDIT_COMP_DESCRIPTION,
  ENTER,
  NUMBER,
  RESET_AVERAGE_COMP_COST,
  RESET_COMP_COST,
} from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import {
  PROJECT_COMP_COST_INPUT,
  PROJECT_COMP_COST_INPUT_DESCRIPTION_INDICATOR,
  PROJECT_COMP_ERROR_ICON_COST,
} from '../../../tagConstants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { simpleCost } from '../../../utilities/currency';
import { EMPTY_COST, parseCostNumber } from '../../../utilities/string';
import { renderValueString } from '../../CostReport/CostReportUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import ProjectCompsInputsModifiedDot from '../ProjectCompsInputs/ProjectCompsInputsModifiedDot';
import styles from '../ProjectCompsStyles';
import { useStateLocalUpdater } from '../ProjectCompUtils';

import ProjectCompsCostDescriptionDialog from './ProjectCompsCostDescriptionDialog';

type ProjectCompsCostTableCostCellProps = {
  classes: Classes<typeof styles>;
  costValue: USCents;
  cy?: string;
  errorMessage?: string;
  isAverageComp?: boolean;
  isEdited?: boolean;
  isHighlighted?: boolean;
  isMaxValue?: boolean;
  isMinValue?: boolean;
  resetLine?: () => void;
  setCost?: (editedValue: number) => void;
  setDescription?: (description: string) => void;
  showMinMax: boolean;
  title?: string;
};

const ProjectCompsCostTableCostCell: FC<ProjectCompsCostTableCostCellProps> = ({
  classes,
  costValue,
  cy,
  errorMessage,
  isAverageComp = false,
  isEdited = false,
  isHighlighted,
  isMaxValue,
  isMinValue,
  resetLine,
  setCost,
  setDescription,
  showMinMax,
  title,
}) => {
  const sendAnalytics = useSendAnalytics();

  const [localValue, setLocalValue] = useStateLocalUpdater(costValue);
  const [hasTypedEdit, setHasTypedEdit] = useStateLocalUpdater(false);
  const [isEditing, setIsEditing] = useState(false);
  const [descriptionDialogIsOpen, setDescriptionDialogIsOpen] = useState(false);
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  useEffect(() => {
    if (textElementRef?.current?.scrollWidth && textElementRef?.current?.clientWidth) {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }
  });

  const isEditable = setCost && setDescription && resetLine;

  let value: number | string | undefined;
  if (isEditing) {
    value = simpleCost(localValue);
  } else if (errorMessage) {
    value = errorMessage;
  } else {
    value = renderValueString({
      value: localValue,
      isExact: true,
      overrideZeroCostDisplay: !isEditable,
    });
  }

  const isEmptyCost = value === EMPTY_COST;

  const showMax = showMinMax && isMaxValue && !isEmptyCost;
  const showMin = showMinMax && isMinValue && !isEmptyCost;

  let backgroundStyle = classes.whiteBG;
  if ((isAverageComp && !isEditable) || isEmptyCost) {
    backgroundStyle = classes.greyBG;
  } else if (showMax) {
    backgroundStyle = classes.lightRedBG;
  } else if (showMin) {
    backgroundStyle = classes.lightGreenBG;
  }

  const highlightBorder = isHighlighted ? classes.greenBG : '';
  const highlightColor = isHighlighted ? classes.greenBG : '';

  const cellStyle = `${classes.costCellField} ${backgroundStyle}`;
  const inputStyle = `${classes.costInput} ${isEdited ? classes.costInputWithIcon : ''} ${
    errorMessage ? classes.costInputError : ''
  } ${highlightBorder} ${highlightColor}`;

  let startAdornment: JSX.Element | undefined;
  if (isEditable && isEdited) {
    const options = [
      {
        name: isAverageComp ? RESET_AVERAGE_COMP_COST : RESET_COMP_COST,
        icon: <Autorenew />,
        callback: () => {
          resetLine?.();
          sendAnalytics(
            projectCompsAnalyticsEvent(
              isAverageComp
                ? projectCompsEventTypes.PROJECT_COMPS_AVERAGE_CELL_RESET_COST_CTA
                : projectCompsEventTypes.PROJECT_COMPS_PROJECT_COMP_RESET_COST_CTA
            )
          );
        },
      },
      {
        name: EDIT_COMP_DESCRIPTION,
        icon: <Edit />,
        callback: () => {
          setDescriptionDialogIsOpen(true);
          sendAnalytics(
            projectCompsAnalyticsEvent(
              projectCompsEventTypes.PROJECT_COMPS_AVERAGE_CELL_EDIT_DESCRIPTION_CTA
            )
          );
        },
      },
    ];

    startAdornment = (
      <ProjectCompsInputsModifiedDot isAverageComp={isAverageComp} options={options} />
    );
  } else if (showMax) {
    startAdornment = <ArrowUpward className={classes.arrowIcon} />;
  } else if (showMin) {
    startAdornment = <ArrowDownward className={classes.arrowIcon} />;
  } else if (errorMessage) {
    startAdornment = (
      <ErrorOutlineIcon
        className={classes.errorIconCostCell}
        data-cy={PROJECT_COMP_ERROR_ICON_COST}
      />
    );
  }

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newLocalValue = parseCostNumber(e.target.value);
    if (String(newLocalValue) === String(localValue)) return;
    setHasTypedEdit(true);
    setLocalValue(newLocalValue);
  };

  const onExit = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setIsEditing(false);
    if (!hasTypedEdit) return;
    const newCostValue = parseCostNumber(e.target.value);
    setHasTypedEdit(false);
    setLocalValue(newCostValue);
    setCost?.(newCostValue);
  };

  let textfield = (
    <TextField
      className={cellStyle}
      data-cy={`${PROJECT_COMP_COST_INPUT}-${cy}`}
      disabled={!isEditable}
      InputProps={{
        classes: {
          focused: classes.costInputFocused,
        },
        startAdornment,
        endAdornment: title && (
          <div
            className={classes.descriptionIndicator}
            data-cy={PROJECT_COMP_COST_INPUT_DESCRIPTION_INDICATOR}
          />
        ),
        className: inputStyle,
        disableUnderline: true,
        // without specifying the step, this text field will be expecting integers only
        // this is needed to prevent an error that is shown on hover when you are typing a decimal
        inputProps: { step: 0.01 },
      }}
      inputRef={textElementRef}
      onBlur={onExit}
      onChange={onChange}
      onFocus={() => {
        setIsEditing(true);
      }}
      onKeyPress={(e) => {
        if (e.key === ENTER && e.target instanceof HTMLElement) {
          e.target.blur();
        }
      }}
      type={isEditing ? NUMBER : undefined}
      value={value}
    />
  );

  if (title) {
    textfield = (
      <NormalTooltip placement="top-end" title={title}>
        {textfield}
      </NormalTooltip>
    );
  }

  if (errorMessage && isOverflowed) {
    textfield = (
      <NormalTooltip placement="top-end" title={errorMessage}>
        {textfield}
      </NormalTooltip>
    );
  }

  return (
    <>
      {textfield}
      {descriptionDialogIsOpen && (
        <ProjectCompsCostDescriptionDialog
          description={title}
          isAverageComp={isAverageComp}
          onClose={() => setDescriptionDialogIsOpen(false)}
          onSave={(newDescription: string) => setDescription?.(newDescription)}
        />
      )}
    </>
  );
};

export default withStyles(styles)(ProjectCompsCostTableCostCell);
