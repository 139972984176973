import { FC, useRef } from 'react';
import { useButton } from 'react-aria';

type Props = {
  onClick: () => void;
  text?: string;
  small?: boolean;
};

const ResetButton: FC<Props> = ({ onClick, text = 'reset', small = false }) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton({ onPress: onClick }, ref);

  return (
    <button
      className={small ? 'type-small-link' : 'type-link'}
      data-cy="resetButton"
      {...buttonProps}
    >
      {text}
    </button>
  );
};

export default ResetButton;
