import { useMemo } from 'react';

import { ScenarioSandbox } from '../../../types';

import ScenariosChart from './ScenariosChart';
import { getChartPropsFromSandbox } from './ScenariosChartDataUtils';

type Props = {
  height: number;
  milestoneName: string;
  onLoad?: () => void;
  showMilestone: boolean;
  sandbox: ScenarioSandbox;
};

const ScenariosChartData = (props: Props) => {
  const { height, milestoneName, onLoad, sandbox, showMilestone } = props;
  const { barRunningTotals, budget, estimate } = useMemo(() => {
    if (sandbox && onLoad) onLoad();
    return getChartPropsFromSandbox(sandbox, milestoneName, showMilestone);
  }, [sandbox, milestoneName, showMilestone, onLoad]);

  if (!sandbox) return null;

  return (
    <div className="m-2 flex-shrink-0" style={{ height }}>
      <ScenariosChart barRunningTotals={barRunningTotals} budget={budget} estimate={estimate} />
    </div>
  );
};

export default ScenariosChartData;
