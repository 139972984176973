import { CostReportColumnType } from '../../../../generated/graphql';
import { getCostReportByType } from '../../../CostReport/CostReportUtils';
import { useMilestoneCostReportsQuery } from '../../../Milestone/hooks';
import { useMilestoneItemCountQuery } from '../../../Milestone/hooks/useMilestoneQuery';

export default function useItemStatusCostReport(projectID?: UUID, milestoneID?: UUID) {
  const costReports = useMilestoneCostReportsQuery(milestoneID, projectID, {}, COST_REPORT_COLUMNS)
    .data?.milestoneCostReports[0];

  const accepted = getCostReportByType(costReports, CostReportColumnType.ACCEPTED_REPORT);
  const incorporated = getCostReportByType(costReports, CostReportColumnType.INCORPORATED_REPORT);
  const pending = getCostReportByType(costReports, CostReportColumnType.PENDING_REPORT);
  const rejected = getCostReportByType(costReports, CostReportColumnType.REJECTED_REPORT);

  const itemCounts = useMilestoneItemCountQuery(milestoneID).data?.itemCounts;

  return {
    accepted: {
      cost: accepted?.segmented,
      numItems: itemCounts?.accepted,
    },
    incorporated: {
      cost: incorporated?.segmented,
      numItems: itemCounts?.incorporated,
    },
    pending: {
      cost: pending?.segmented,
      numItems: itemCounts?.pending,
    },
    rejected: {
      cost: rejected?.segmented,
      numItems: itemCounts?.rejected,
    },
  };
}

const COST_REPORT_COLUMNS = [
  CostReportColumnType.ACCEPTED_REPORT,
  CostReportColumnType.INCORPORATED_REPORT,
  CostReportColumnType.PENDING_REPORT,
  CostReportColumnType.REJECTED_REPORT,
].map((type) => ({ type }));
