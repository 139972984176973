import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton, Typography } from '@material-ui/core';
import { GetApp, OpenInNew } from '@material-ui/icons';

import {
  assetDownoadAnalytics,
  assetOpenNewTabAnalytics,
  reportDistributionEventTypes,
  userReportDistributionEvent,
} from '../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { REPORT_PDF } from '../../../constants';
import { Maybe } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getTextWidth } from '../../../utilities/string';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Button } from '../../scales';
import { SelectVariants } from '../../Select/SelectMenu/SelectStyles';
import TruncateText from '../../shared-widgets/TruncateText/TruncateText';
import { MIDDLE_TRUNC_PADDING } from '../../shared-widgets/TruncateText/TruncateTextStyles';
import useMemoWrapper from '../../useMemoWrapper';
import AssetsActionMenu from '../AssetsActionMenu/AssetsActionMenu';
import { assetIsModelType, calcMaxViewerWidth, getUploadedText } from '../utils';

import AssetsViewerFooterStyles, { getFooterActionWidth } from './AssetsViewerFooterStyles';

type AssetsViewerFooterProps = {
  asset: Pick<Asset, 'id' | 'name' | 'lastUpdated' | 'type'> & {
    uploadedBy?: Pick<User, 'id' | 'name' | 'email'> | null;
  };
  canDelete: boolean;
  classes: Classes<typeof AssetsViewerFooterStyles>;
  displayURL: string | null | undefined;
  detachAttachment?: () => void;
  downloadAsset?: () => void;
  showMenu: boolean;
  type?: Maybe<string>;
  hideFileInfo?: boolean;
  reportID?: UUID;
  projectId?: UUID;
};

const AssetsViewerFooter: FC<AssetsViewerFooterProps> = ({
  asset,
  canDelete,
  classes,
  displayURL,
  detachAttachment,
  downloadAsset,
  showMenu,
  projectId,
  type,
  hideFileInfo = false,
  reportID,
}) => {
  const sendAnalytics = useSendAnalytics();
  const navigate = useNavigate();

  const onClickNewTab = () => {
    if (type === REPORT_PDF) {
      sendAnalytics(
        userReportDistributionEvent(reportDistributionEventTypes.REPORT_DISTRIBUTION_NEW_TAB)
      );
    } else {
      sendAnalytics(assetOpenNewTabAnalytics(asset.id, asset.name, asset.type ?? undefined));
    }
  };

  const onDownload = () => {
    if (downloadAsset) downloadAsset();
    // analytics
    if (type === REPORT_PDF) {
      sendAnalytics(
        userReportDistributionEvent(reportDistributionEventTypes.REPORT_DISTRIBUTION_DOWNLOAD)
      );
    } else {
      sendAnalytics(assetDownoadAnalytics(asset.id, asset.name, asset.type ?? undefined));
    }
  };

  const assetName = asset.name;
  const canOpenNewTab = !assetIsModelType(asset.type);
  const maxModalWidth = calcMaxViewerWidth(window.innerWidth);
  const maxAssetTitleWidth = maxModalWidth - getFooterActionWidth(theme, canOpenNewTab);

  const isModel = assetIsModelType(asset.type);

  const openInNewTab = (isModel: boolean) => (
    <NormalTooltip
      title={isModel ? 'Revit models cannot be opened in a new tab' : 'Open in new tab'}
    >
      <div>
        <IconButton className={classes.footerButtonContainer} disabled={isModel}>
          {isModel ? (
            <OpenInNew className={classes.footerButtonDisabled} />
          ) : (
            <a
              className="flex"
              href={displayURL ?? undefined}
              onClick={onClickNewTab}
              rel="noopener noreferrer"
              target="_blank"
            >
              <OpenInNew />
            </a>
          )}
        </IconButton>
      </div>
    </NormalTooltip>
  );

  const textWidth = useMemoWrapper(getTextWidth, assetName, 16);
  const shouldTruncate = textWidth > maxAssetTitleWidth - MIDDLE_TRUNC_PADDING;

  return (
    <div className={classes.modalFooter}>
      {!hideFileInfo && (
        <div className={classes.modalFooterInfo}>
          {shouldTruncate ? (
            <TruncateText
              maxWidth={maxAssetTitleWidth}
              text={assetName}
              textClass={classes.titleText}
            />
          ) : (
            <Typography className={classes.titleText}>{assetName}</Typography>
          )}
          <Typography className={classes.subtitleText}>{getUploadedText(asset)}</Typography>
        </div>
      )}
      <div className={classes.modalFooterAction}>
        <NormalTooltip title="Download">
          <IconButton className={classes.footerButtonContainer} onClick={onDownload}>
            <GetApp className={classes.footerButton} />
          </IconButton>
        </NormalTooltip>
        {openInNewTab(isModel)}
        {showMenu && (
          <AssetsActionMenu
            canDelete={canDelete}
            detachAttachment={detachAttachment}
            variant={SelectVariants.HEADER}
          />
        )}
      </div>
      <div>
        {reportID && (
          <div className="mr-20">
            <Button
              label="View in Join"
              onClick={() =>
                navigate(
                  generateSharedPath(JoinProjectRoutes.REPORT_ROUTER, {
                    projectId,
                    reportID,
                  })
                )
              }
              type="primary"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(AssetsViewerFooterStyles)(AssetsViewerFooter);
