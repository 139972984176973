import { useState } from 'react';

import { ImageDimension } from '../../../../generated/graphql';
import { TextArea } from '../../../scales';
import Thumbnail from '../../../shared-widgets/Thumbnail/Thumbnail';

type Props = {
  description: string;
  onEditMetadata: (metadata: { name?: string; description?: string }) => void;
  thumbnailAssetID?: UUID;
};

const ScenarioCardTableHeader = (props: Props) => {
  const [description, setDescription] = useState(props.description);

  return (
    <div className="flex gap-2 pb-3 pl-3 pr-3">
      <TextArea
        aria-label="scenario description"
        data-cy="scenario-description-text-area"
        onBlur={() => props.onEditMetadata({ description })}
        onChange={setDescription}
        placeholder="Add a description..."
        value={description ?? ''}
      />
      {props.thumbnailAssetID && (
        <Thumbnail
          dimension={ImageDimension._144}
          padding={0}
          rounded
          size={80} // kinda fussy height, based on the above field heights
          thumbnail={props.thumbnailAssetID}
        />
      )}
    </div>
  );
};

export default ScenarioCardTableHeader;
