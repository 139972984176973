import { useState } from 'react';

import {
  ScenarioShareEvent,
  ScenarioShareEventProp,
  ScenarioShareKey,
  scenarioShareEvent,
} from '../../../analytics/analyticsEventProperties';
import { ResourceType } from '../../../generated/graphql';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { getShareResourcesUpdate, hasSharedAccess } from '../../../utilities/utilities';
import { ShareDialog, UsersTableFilter } from '../../dragon-scales';
import { filterActiveCollaborators } from '../../dragon-scales/ShareDialog/utils';
import { useGetSharedResources } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import { useShareResources } from '../../ItemsList/ItemsSharing/hooks/useShareResourceHook';
import NoShareAccessDialog from '../../ItemsList/ItemsSharing/ShareDraftItemDialog/NoShareAccessDialog';
import { DialogContent } from '../../scales';
import useMemoWrapper from '../../useMemoWrapper';

export type Props = {
  accentColor?: string;
  // Current Collaborator ID
  collaboratorID: UUID;
  scenarioID: UUID;
  scenarioName: string;
  isOpen?: boolean;
  onClose?: () => void;
  projectID: UUID;
};

export default function ShareScenarioDialog(props: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const [newCollaboratorIDs, setNewCollaboratorIDs] = useState<UUID[]>([]);
  const [removedCollaboratorIDs, setRemovedCollaboratorIDs] = useState<UUID[]>([]);
  const [sharedCollaboratorIDs, setSharedCollaboratorIDs] = useState<UUID[]>([]);

  const { data, loading: loadingCollaborators } = useCollaboratorsQuery(props.projectID);
  const activeCollaborators = useMemoWrapper(filterActiveCollaborators, data?.collaborators || []);
  const { data: sharedResourcesData, loading: loadingSharedResources } = useGetSharedResources(
    [props.scenarioID],
    ResourceType.SCENARIO
  );
  const sharedResources = sharedResourcesData?.getSharedResources?.resources ?? [];

  const currentUserId = activeCollaborators.find((c) => c.id === props.collaboratorID)?.user?.id;
  const hasShareAccess = useMemoWrapper(hasSharedAccess, sharedResources, currentUserId);

  const loading = loadingCollaborators || isLoading || loadingSharedResources;

  const disableShareSubmit =
    (newCollaboratorIDs.length === 0 && removedCollaboratorIDs.length === 0) || loading;

  const analyticsKey = ScenarioShareKey.SCENARIOS;
  const sendAnalytics = useSendAnalytics();
  const onShareEvent = (event: ScenarioShareEvent, eventProperties: ScenarioShareEventProp = {}) =>
    sendAnalytics(scenarioShareEvent(analyticsKey, event, eventProperties));
  const onFilterEvent = (eventProperties: ScenarioShareEventProp = {}) =>
    sendAnalytics(scenarioShareEvent(analyticsKey, ScenarioShareEvent.FILTER, eventProperties));

  const onClose = () => {
    props.onClose?.();
    onShareEvent(ScenarioShareEvent.CLOSE);
  };

  const shareResources = useShareResources();
  const onShare = () => {
    if (disableShareSubmit) return;
    setIsLoading(true);
    const { userIDs, accessLevels } = getShareResourcesUpdate(
      newCollaboratorIDs,
      removedCollaboratorIDs,
      activeCollaborators
    );
    if (!userIDs.length) return;
    const onSuccess = () => {
      setIsLoading(false);
      props.onClose?.();
    };
    const onFail = () => {
      setIsLoading(false);
    };
    shareResources(
      ResourceType.SCENARIO,
      [props.scenarioID],
      userIDs,
      accessLevels,
      onSuccess,
      onFail
    );
    const addCount = newCollaboratorIDs.length;
    if (addCount) onShareEvent(ScenarioShareEvent.ADD, { addCount });
    const removeCount = removedCollaboratorIDs.length;
    if (removeCount) onShareEvent(ScenarioShareEvent.REMOVE, { removeCount });
    onShareEvent(ScenarioShareEvent.SHARE_CLICK);
  };

  if (!hasShareAccess && !loading) return <NoShareAccessDialog onClose={onClose} />;

  if (!sharedResourcesData) return null;

  return (
    <ShareDialog
      {...props}
      newCollaboratorIDs={newCollaboratorIDs}
      onClose={onClose}
      onShare={onShare}
      removedCollaboratorIDs={removedCollaboratorIDs}
      sharedCollaboratorIDs={sharedCollaboratorIDs}
      shareDisabled={disableShareSubmit}
      shareLabel="Share"
      title={`Share Scenario: ${props.scenarioName}`}
    >
      <DialogContent className="flex h-full flex-col gap-2 pb-0">
        <UsersTableFilter
          collaboratorID={props.collaboratorID}
          // Data update callbacks
          onCollaboratorNewIDs={(ids) => setNewCollaboratorIDs(ids)}
          onCollaboratorRemovedIDs={(ids) => setRemovedCollaboratorIDs(ids)}
          onCollaboratorSharedIDs={(ids) => setSharedCollaboratorIDs(ids)}
          // For Analytics:
          onCompany={(company) => onFilterEvent({ filterCompany: company })}
          onRole={(role) => onFilterEvent({ filterRole: role })}
          onSearch={(term) => onFilterEvent({ filterSearch: term })}
          // Current project ID
          projectID={props.projectID}
          sharedResources={sharedResources}
        />
      </DialogContent>
    </ShareDialog>
  );
}
