import { ChangeEvent, FC, useRef, useState } from 'react';

import { Avatar, Button, IconButton, TextField } from '@material-ui/core';
import {
  Add,
  Close,
  DeleteOutline,
  EditOutlined as Edit,
  CenterFocusStrong as Focus,
  SaveOutlined as Save,
} from '@material-ui/icons';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import { computePayload } from '../../../../utilities/items';
import { getItemIdFromUrl, getProjectIdFromUrl } from '../../../../utilities/url';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import JoinHidden from '../../../shared-widgets/JoinHidden';
import useMemoWrapper from '../../../useMemoWrapper';
import useItemBookmarksQuery from '../../AssetsCards/ModelCardWithBookmarks/useItemBookmarksQuery';
import { filterAssetBookmarks } from '../../AssetsCards/ModelCardWithBookmarks/utils';

import AssetsModelForgeWrapper from './AssetsModelForgeWrapper/AssetsModelForgeWrapper';
import styles from './AssetsModelViewerStyles';
import { useRemoveBookmark, useUpsertBookmark } from './hooks';
import { markupModes } from './utils';

type ForgeWrapper = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  changeMarkupMode: any;
  closeViewer: () => void;
  enterMarkupMode: (command: string) => void;
  getCamera: () => void;
  getSection: () => void;
  getThumbnail: (i: number) => void;
  removeMarkups: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  saveAndExitMarkup: () => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setCamera: (tar: any, pos: any, upv: any) => void;
  setSection: (section: string) => void;
  setMarkup: (markup: string) => void;
};

const emptyWrapper: ForgeWrapper = {
  changeMarkupMode: () => {},
  closeViewer: () => {},
  enterMarkupMode: () => {},
  getCamera: () => {},
  getSection: () => {},
  getThumbnail: () => {},
  removeMarkups: () => {},
  saveAndExitMarkup: () => {},
  setCamera: () => {},
  setSection: () => {},
  setMarkup: () => {},
};

type ModelViewerProps = {
  asset: Pick<Asset, 'derivations'>;
  disabled: boolean;
  bookmarkEditIndex?: number;
  classes: Classes<typeof styles>;
  setEditingView?: (n: number) => void;
  url: string;
};

const AssetsModelViewer: FC<ModelViewerProps> = ({
  asset,
  bookmarkEditIndex = -1,
  classes,
  disabled,
  setEditingView = () => {},
  url,
}) => {
  const projectId = getProjectIdFromUrl();
  const itemID = getItemIdFromUrl();

  const itemBookmarksQueryResult = useItemBookmarksQuery(itemID);
  const allBookmarks = itemBookmarksQueryResult.data?.itemBookmarks ?? [];
  const bookmarks = useMemoWrapper(filterAssetBookmarks, asset, allBookmarks);

  const [writeBookmark] = useUpsertBookmark(projectId, itemID);
  const [deleteBookmark] = useRemoveBookmark(projectId, itemID);
  const [command, setCommand] = useState('cloud');
  const [loaded, setLoaded] = useState(false);
  const [markingUp, setMarkingUp] = useState(false);
  const [name, setName] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const forgeWrapper = useRef<any>(emptyWrapper);

  const editBookmark =
    !disabled && bookmarks && bookmarkEditIndex > -1 && bookmarks[bookmarkEditIndex];

  const editBookmarkPayload =
    (editBookmark &&
      editBookmark.payload &&
      (computePayload(editBookmark.payload) as BookmarkPayload).markup) ||
    undefined;
  const hasMarkup = !!editBookmarkPayload;

  // THUMBNAILS
  const createThumbnail = (i: number) => {
    if (forgeWrapper && forgeWrapper.current) forgeWrapper.current.getThumbnail(i);
  };
  // BOOKMARKS
  const loadBookmark = (i: number) => {
    if (bookmarks && bookmarks[i] && forgeWrapper && forgeWrapper.current) {
      const { name: bookmarkName, payload } = bookmarks[i];
      if (payload) {
        if (bookmarkName) setName(bookmarkName);
        const bookmarkPayload: BookmarkPayload | undefined = computePayload(payload);
        const { cam, markup, section } = bookmarkPayload as BookmarkPayload;
        if (cam) {
          const { tar, pos, upv } = cam;
          forgeWrapper.current.setCamera(tar, pos, upv);
        }
        if (markup) {
          forgeWrapper.current.setMarkup(markup);
        }
        if (section) {
          forgeWrapper.current.setSection(section);
        }
      }
    }
  };
  const isLoaded = (bookmark: Bookmark) => {
    // load viewer then open bookmark
    setLoaded(!!bookmark);
    if (bookmark && bookmarkEditIndex > -1) {
      loadBookmark(bookmarkEditIndex);
    }
  };
  const newBookmark = () => {
    if (forgeWrapper && forgeWrapper.current) {
      forgeWrapper.current.removeMarkups();
      if (bookmarks) {
        createThumbnail(bookmarks.length);
        setEditingView(bookmarks.length);
      }
    }
  };
  const writeBookmarkName = () => {
    if (editBookmark) writeBookmark({ ...editBookmark, payload: editBookmarkPayload, name });
  };
  const removeBookmark = () => {
    if (forgeWrapper && forgeWrapper.current) {
      setEditingView(0);
      forgeWrapper.current.removeMarkups();
      if (editBookmark) {
        deleteBookmark(editBookmark);
      }
    }
  };
  const exitBookmarkEdit = () => {
    if (forgeWrapper && forgeWrapper.current) {
      setEditingView(0);
      forgeWrapper.current.removeMarkups();
    }
  };

  // ACTIONS
  const saveMarkup = (markup: string) => {
    if (editBookmark && forgeWrapper && forgeWrapper.current) {
      const payload = { ...editBookmarkPayload, markup };
      writeBookmark({ ...editBookmark, payload }).then(() => {
        forgeWrapper.current.getThumbnail(bookmarkEditIndex);
      });
    }
  };
  const toggleMarkup = () => {
    if (forgeWrapper && forgeWrapper.current) {
      if (markingUp) {
        const markup = forgeWrapper.current.saveAndExitMarkup();
        saveMarkup(markup);
      } else {
        forgeWrapper.current.enterMarkupMode(command);
      }
      setMarkingUp(!markingUp);
    }
  };
  const handleBookmarkButtonClick = (i: number) => {
    if (forgeWrapper && forgeWrapper.current) {
      if (markingUp) toggleMarkup(); // exit markup mode
      forgeWrapper.current.removeMarkups();
      if (bookmarkEditIndex === i) {
        setEditingView(-1);
      } else {
        setEditingView(i);
        loadBookmark(i);
      }
    }
  };
  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  // MARKUP
  const changeMarkupMode = (commandMode: string) => {
    if (forgeWrapper && forgeWrapper.current) {
      forgeWrapper.current.changeMarkupMode(commandMode);
      setCommand(commandMode);
    }
  };
  const deleteMarkup: () => void = () => {
    if (bookmarks && forgeWrapper && forgeWrapper.current) {
      if (editBookmark) {
        const payload = { ...editBookmarkPayload, markup: null };
        writeBookmark({ ...editBookmark, payload }).then(() => {
          forgeWrapper.current.removeMarkups();
          // we need to only get thumbnail after we finish the removal
          forgeWrapper.current.getThumbnail(bookmarkEditIndex);
        });
      }
    }
  };

  // THUMBNAILS
  const returnThumbnail = (i: number, thumbnail: string) => {
    if (forgeWrapper && forgeWrapper.current) {
      const bookmark = (bookmarks ?? [])[i] || { name: `View ${i + 1}`, payload: '' };
      const { name: bookmarkName } = bookmark;
      if (bookmarkName) setName(bookmarkName);
      const section = forgeWrapper.current.getSection();
      const cam = forgeWrapper.current.getCamera();
      // the markup was assumed to be written to the svg, so we're good
      const payload = { ...computePayload(bookmark.payload), url, cam, section };
      writeBookmark({ ...bookmark, payload, bookmarkName, thumbnail });
    }
  };

  // TOOLTIP FOR EDIT
  let bottomToolTip = hasMarkup
    ? {
        class: classes.markup,
        click: deleteMarkup,
        content: <DeleteOutline />,
        title: 'Delete Markup',
      }
    : { class: '', click: toggleMarkup, content: <Edit />, title: 'Edit Markup' };
  if (markingUp) {
    bottomToolTip = {
      class: classes.markup,
      click: toggleMarkup,
      content: <Save />,
      title: 'Save and Exit Markup',
    };
  }

  // COMPONENTS
  const markupsToolbar = (
    <div style={{ display: 'inline' }}>
      {markingUp &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        markupModes.map((mode: any) => (
          <NormalTooltip key={mode.name} placement="bottom" title={mode.name}>
            <IconButton
              aria-label={mode.name}
              className={mode.command === command ? classes.select : ''}
              onClick={() => {
                changeMarkupMode(mode.command);
              }}
            >
              {mode.icon}
            </IconButton>
          </NormalTooltip>
        ))}
    </div>
  );

  const bookmarkButtons = () =>
    Array.isArray(bookmarks)
      ? (bookmarks as Bookmark[]).map((bookmark, i) => {
          const bPayload = computePayload(bookmark.payload) as BookmarkPayload;
          const bUrl = bPayload && bPayload.url;
          return (
            (!disabled && bookmark.payload && url === bUrl && (
              <NormalTooltip
                key={bookmark.id}
                placement="right"
                title={`Edit View: ${bookmark.name}`}
              >
                <Button
                  key={bookmark.id}
                  className={bookmarkEditIndex === i ? classes.selected : classes.view}
                  onClick={() => handleBookmarkButtonClick(i)}
                  variant="fab"
                >
                  <Avatar className={classes.avatar} src={bookmark.thumbnail ?? undefined} />
                </Button>
              </NormalTooltip>
            )) ||
            null
          );
        })
      : null;

  return (
    <div>
      {!disabled && loaded && bookmarks && (
        <div className={classes.interface}>
          <div className={classes.thumbnails}>
            {bookmarkButtons()}
            <NormalTooltip placement="right" title="Save New View">
              <Button key="Save" className={classes.control} onClick={newBookmark} variant="fab">
                <Add />
              </Button>
            </NormalTooltip>
          </div>
          {editBookmark && (
            <div>
              <div className={classes.editOptions}>
                <NormalTooltip placement="bottom" title="Update view">
                  <IconButton
                    aria-label="Update Bookmark"
                    onClick={() => createThumbnail(bookmarkEditIndex)}
                  >
                    <Focus />
                  </IconButton>
                </NormalTooltip>
                <TextField
                  className={classes.bookmarkName}
                  onBlur={writeBookmarkName}
                  onChange={handleTextChange}
                  placeholder="Bookmark name"
                  value={name}
                />
                <NormalTooltip placement="bottom" title="Delete View">
                  <IconButton aria-label="Delete View" onClick={removeBookmark}>
                    <DeleteOutline />
                  </IconButton>
                </NormalTooltip>
                <NormalTooltip placement="bottom" title="Exit Editing">
                  <IconButton aria-label="Exit Bookmark Editing" onClick={exitBookmarkEdit}>
                    <Close />
                  </IconButton>
                </NormalTooltip>
                <NormalTooltip placement="bottom" title={bottomToolTip.title}>
                  <IconButton
                    aria-label={bottomToolTip.title}
                    className={bottomToolTip.class}
                    onClick={bottomToolTip.click}
                  >
                    {bottomToolTip.content}
                  </IconButton>
                </NormalTooltip>
                <JoinHidden smDown>{markupsToolbar}</JoinHidden>
              </div>
              <JoinHidden smUp>
                <div className={classes.editMarkup}>{markupsToolbar}</div>
              </JoinHidden>
            </div>
          )}
        </div>
      )}
      <AssetsModelForgeWrapper
        ref={forgeWrapper}
        bookmarkEditIndex={bookmarkEditIndex}
        bookmarks={bookmarks}
        isLoaded={isLoaded}
        returnThumbnail={returnThumbnail}
        url={url}
      />
      <div>
        <canvas height="120px" id="svgThumbnail" width="120px" />
      </div>
    </div>
  );
};

export default withStyles(styles)(AssetsModelViewer);
