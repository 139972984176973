import { FC } from 'react';

import { ButtonBase, Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import { SourceSystem } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import {
  getSourceNameText,
  sourceSystemInfo,
} from '../../../DocumentMagic/Files/FilesDialog/FilesDialogUtils';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import AssetsActionMenu from '../../AssetsActionMenu/AssetsActionMenu';
import { getTruncatedAssetText } from '../../utils';

import AssetsCardStyles from './AssetsCardStyles';

type RemoteAssetCardProps = {
  asset: RemoteAsset;
  canDelete: boolean;
  classes: Classes<typeof AssetsCardStyles>;
  detachAttachment?: () => void;
  disabled?: boolean;
  showMenu?: boolean;
};

const RemoteAssetCard: FC<RemoteAssetCardProps> = ({
  asset,
  canDelete,
  classes,
  detachAttachment,
  disabled = false,
  showMenu = false,
}) => {
  const sourceContent = (sourceSystem: SourceSystem) => {
    const image = sourceSystemInfo[sourceSystem]?.img;
    if (image) {
      return (
        <div className={classes.sourceContainer}>
          <img
            alt={sourceSystem}
            className={`${classes.sourceIcon} ${classes.sourceIconCard}`}
            src={image}
          />
          <Typography className={classes.metadata} variant="caption">
            {getSourceNameText(sourceSystem)}
          </Typography>
          <div className={classes.spacer} />
          <OpenInNew className={classes.sourceIcon} />
        </div>
      );
    }
    return <></>;
  };

  const tooltipTitle = (sourceSystem: SourceSystem, name?: string) =>
    `${name ? `Linked by ${name}` : ''} \u2022 ${getSourceNameText(sourceSystem)}`;

  // TODO: A fair amount of common DNA with AssetsCard.tsx, consider shared parent
  return (
    <div className={classes.container}>
      <NormalTooltip title={tooltipTitle(asset.sourceSystem, asset.uploadedBy?.name)}>
        <ButtonBase
          className={`${classes.root} ${classes.tile}`}
          data-cy="buttonBase-projectAssetIcon"
          disabled={disabled}
        >
          <div className={classes.summaryTitleBookmark} data-cy="div-attachmentItemAsset">
            <Typography className={classes.summaryTitle} variant="body2">
              {getTruncatedAssetText(asset)}
            </Typography>
            {sourceContent(asset.sourceSystem)}
          </div>
        </ButtonBase>
      </NormalTooltip>
      {showMenu && (
        <div className={classes.menu}>
          <AssetsActionMenu canDelete={canDelete} detachAttachment={detachAttachment} />
        </div>
      )}
    </div>
  );
};

export default withStyles(AssetsCardStyles)(RemoteAssetCard);
