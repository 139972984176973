import { Button, Dialog, DialogContent } from '../../../scales';
import { ItemCopyState } from '../../hooks/useCopyItemMutationParams';
import { NEXT_ITEM_ATTACHMENTS } from '../CopyItemDialogUtils';

import CategorizationMappingsDialogInfo from './CategorizationMappingsDialogInfo';
import ProjectCategorizationListItem from './CategorizationMappingsListItem';

type CategorizationMappingsDialogProps = {
  state: ItemCopyState;
  onBack: () => void;
  onChange: (input: Partial<ItemCopyState>) => void;
  onClose: () => void;
  onNext: () => void;
  progressPercent?: number;
};

const CategorizationMappingsDialog = (props: CategorizationMappingsDialogProps) => {
  const onClear = (categorizationID: UUID) => {
    const categorizationMappings = props.state.categorizationMappings;
    categorizationMappings?.delete(categorizationID);
    props.onChange({ categorizationMappings });
  };

  const onChange = (oldCategorizationID: UUID, newCategorizationID?: UUID) => {
    const categorizationMappings = props.state.categorizationMappings;
    if (newCategorizationID) categorizationMappings?.set(oldCategorizationID, newCategorizationID);
    props.onChange({ categorizationMappings });
  };

  return (
    <Dialog
      footerLeft={<Button label="Back" onClick={props.onBack} type="secondary" />}
      footerRight={
        <Button
          disabled={!props.state.name}
          label={NEXT_ITEM_ATTACHMENTS}
          onClick={props.onNext}
          type="primary"
        />
      }
      isFullHeight
      isOpen
      onClose={props.onClose}
      progressPercent={props.progressPercent}
      size="lg"
      title="Match Estimate Categorizations to Destination Project Categorizations"
    >
      <DialogContent>
        <div className="flex flex-col gap-6">
          <CategorizationMappingsDialogInfo />
          <div className="flex flex-col gap-4">
            <div className="flex">
              <div className="flex grow gap-16 type-body2">
                <strong className="w-1/2">All categorizations in your estimate</strong>
                <strong className="w-1/2">
                  Match it to the estimate in your project destination
                </strong>
              </div>
            </div>
            {(props.state.itemEstimateCategorizations || []).map((c) => (
              <ProjectCategorizationListItem
                key={c?.id}
                onClear={onClear}
                onUpdate={onChange}
                sourceCategorization={c}
                state={props.state}
              />
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CategorizationMappingsDialog;
