import { FC, useEffect, useMemo, useState } from 'react';

import { Checkbox, IconButton, Typography } from '@material-ui/core';
import {
  AddCircleOutlineOutlined,
  CheckBoxRounded as CheckBoxChecked,
  CheckBoxOutlineBlankRounded as CheckBoxUnchecked,
  DeleteOutline,
} from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { NULL_ID } from '../../../constants';
import { ItemDrawInfo, MilestoneContingencyInfo } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { capitalizeString } from '../../../utilities/string';
import ErrorTooltip from '../../JoinGrid/ErrorTooltip';
import JoinSelect, { SelectEntry } from '../../Select/JoinSelect/JoinSelect';
import CostImpact from '../../shared-widgets/CostImpact';

import ItemsCollapseStyles from './ItemsCollapseStyles';

type Props = {
  availableContingencies: MilestoneContingencyInfo[];
  classes: Classes<typeof ItemsCollapseStyles>;
  draw?: ItemDrawInfo;
  estimateCost: Cost;
  fullWidth?: boolean;
  hasMultipleDraws: boolean;
  onAdd?: () => void;
  onSelect: (contingencyID: UUID) => void;
  onSetValue: (contingencyID: UUID, value: number) => void;
  onSetFullAmount: () => void;
  onRemove?: () => void;
};

const ContingencyDraw: FC<Props> = ({
  availableContingencies,
  classes,
  draw,
  fullWidth = false,
  hasMultipleDraws,
  onAdd,
  onSelect,
  onSetValue,
  onSetFullAmount,
  onRemove,
  estimateCost,
}) => {
  // keep track of the value as the user edits it, and before they submit the changes
  const [value, setValue] = useState<CostScalar | undefined>(
    draw && draw.draw ? { value: draw.draw } : undefined
  );
  const [submittedRemove, setSubmittedRemove] = useState(false);

  useEffect(() => {
    // value may be null if the contingencies havent' yet loaded
    // or when making changes to the cost of the contingency
    if (draw && draw.draw && !value) {
      setValue({ value: draw.draw });
    }
  }, [draw, value]);

  const entries: SelectEntry[] = useMemo(() => {
    const available: SelectEntry[] = [{ id: NULL_ID, name: 'No Draw', type: '' }];

    availableContingencies.forEach((a) => {
      const { id, name, type, isBeingDrawn } = a;
      available.push({
        id,
        name,
        selected: false,
        type: capitalizeString(type.toLowerCase()),
        title: type,
        disabled: !!isBeingDrawn && draw?.drawFromID !== id,
      });
    });
    return available;
  }, [availableContingencies, draw?.drawFromID]);

  const hasDrawFrom = !!draw?.drawFromID && draw.drawFromID !== NULL_ID;
  const isDrawZero = draw?.draw?.toString() === '0';
  const isDrawingFullAmount = (!!draw?.isDrawingFullAmount && hasDrawFrom) ?? true;
  const negativeFullCost = formatCost(
    estimateCost && 'value' in estimateCost ? -1 * Number(estimateCost.value) : 0,
    {
      showCents: true,
    }
  );

  const disabled = !draw;
  const valuePlaceholder =
    draw && draw.error ? (
      <div style={{ display: 'flex' }}>
        <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {draw.name}
        </Typography>
        <ErrorTooltip
          title={
            <span>
              <Typography style={{ fontSize: 13 }}>Warning:</Typography>
              <Typography color="textPrimary" style={{ fontSize: 13 }}>
                {draw.error}
              </Typography>
            </span>
          }
        >
          <ErrorOutlineIcon color="error" style={{ width: 20, height: 20 }} />
        </ErrorTooltip>
      </div>
    ) : (
      `${fullWidth ? `Select where to draw from...` : `Draw from...`}`
    );

  const select = () => (
    <div className={classes.contingencySelect} style={{ width: !fullWidth ? 175 : undefined }}>
      <JoinSelect
        cySelect={`select-contingency${draw?.name || 'Default'}`}
        entries={entries}
        groupingKey="type"
        onChange={(contingencyID) => {
          if (contingencyID === NULL_ID) setValue({ value: 0 });
          onSelect(contingencyID);
        }}
        value={draw?.drawFromID || ''}
        valuePlaceholder={valuePlaceholder}
      />
    </div>
  );

  const submit = (value: CostScalar | undefined, draw: ItemDrawInfo | undefined) => {
    if (value && draw?.drawFromID) {
      const cost = typeof value.value === 'string' ? parseInt(value.value, 10) : value.value;
      onSetValue(draw.drawFromID, -Math.abs(cost));
    }
  };

  const costImpact = () =>
    hasDrawFrom && (!isDrawingFullAmount || hasMultipleDraws) ? (
      <div className={classes.costImpact}>
        <CostImpact
          alwaysNegative
          compact={!fullWidth}
          editable
          onBlur={() => submit(value, draw)}
          onChange={(cost) => {
            setValue({ value: -Math.abs(cost) });
          }}
          placeholder={formatCost('-0', {
            showZeroCents: true,
          })}
          value={(hasDrawFrom && value) || { value: -0 }}
          width={!fullWidth ? 100 : undefined}
        />
      </div>
    ) : null;

  const checkboxLabel = fullWidth ? `Full Amount (${negativeFullCost})` : 'Full';

  const checkbox = () =>
    !hasMultipleDraws ? (
      <button
        className={classes.congingencyButton}
        data-cy={`button-fullAmount-${draw?.name}`}
        disabled={!draw || !hasDrawFrom}
        onClick={() => {
          onSetFullAmount();
          setValue({ value: 0 });
        }}
      >
        <Checkbox
          checked={isDrawingFullAmount || (!hasDrawFrom && isDrawZero) || !draw}
          checkedIcon={
            <CheckBoxChecked color={disabled || !hasDrawFrom ? 'disabled' : undefined} />
          }
          className={classes.contingencyFullDrawCheckbox}
          icon={<CheckBoxUnchecked />}
        />
        <Typography className={classes.contingencyFullDrawText}>{checkboxLabel}</Typography>
      </button>
    ) : null;

  return (
    <div className={`${classes.contingencyContainer} ${disabled ? classes.disabled : ''}`}>
      <div className={classes.costImpactContainer} style={{ width: fullWidth ? 600 : undefined }}>
        {select()}
        {costImpact()}
        {checkbox()}
      </div>
      {!!onAdd && (
        <IconButton
          className={classes.congingencyButton}
          data-cy="button-AddDraw"
          disabled={!hasDrawFrom}
          onClick={() => {
            onAdd();
            if (draw && draw.isDrawingFullAmount) setValue({ value: 0 });
          }}
        >
          <AddCircleOutlineOutlined className={classes.newContingencyIcon} />
        </IconButton>
      )}
      {!!onRemove && !submittedRemove && (
        <IconButton
          className={classes.congingencyButton}
          onClick={() => {
            setSubmittedRemove(true);
            onRemove();
          }}
        >
          <DeleteOutline className={classes.newContingencyIcon} />
        </IconButton>
      )}
    </div>
  );
};

export default withStyles(ItemsCollapseStyles)(ContingencyDraw);
