import {
  inAppNotificationsAnalyticsEvent,
  inAppNotificationsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { useActiveOverlayState } from '../../scales';
import useMemoWrapper from '../../useMemoWrapper';
import useSetInAppNotificationReadMutation from '../hooks/useSetInAppNotificationReadMutation';

import InAppNotificationEntry from './InAppNotificationEntry';
import { getNotificationDescription, getNotificationIcon } from './InAppNotificationEntryUtils';

type Props = {
  notification: InAppNotificationWithTime;
  showAllProjectNotifications: boolean;
};

export default function InAppNotificationEntryData(props: Props) {
  const { notification } = props;

  const description = useMemoWrapper(getNotificationDescription, notification);

  const itemId = description.item?.id;
  const icon = getNotificationIcon(notification);

  const sendAnalytics = useSendAnalytics();

  const activeOverlayState = useActiveOverlayState();

  const isRead = !!notification.readAt;
  const setInAppNotificationRead = useSetInAppNotificationReadMutation(notification.id);

  const toggleCheck = () => {
    setInAppNotificationRead(!isRead);

    sendAnalytics(
      inAppNotificationsAnalyticsEvent(
        inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_MARK_READ_CLICK,
        {
          action: !isRead ? 'Read' : 'Unread',
          itemId,
          notificationId: notification.id,
        }
      )
    );
  };

  const onLinkClick = () => {
    activeOverlayState?.close();
    setInAppNotificationRead(true);

    sendAnalytics(
      inAppNotificationsAnalyticsEvent(
        inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_LINK_VIEW_CLICK,
        {
          itemId,
          notificationId: notification.id,
          notificationType: notification.notificationType,
        }
      )
    );
  };

  if (!description) return null;

  return (
    <InAppNotificationEntry
      description={description}
      icon={icon}
      isRead={isRead}
      onLinkClick={onLinkClick}
      onToggleIsRead={toggleCheck}
      showProjectDetails={props.showAllProjectNotifications}
    />
  );
}
