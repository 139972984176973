import { useEffect, useMemo, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Divider } from '@material-ui/core';

import { itemSidebarHomeOpenVar } from '../../../../api/apollo/reactiveVars';
import { CostDisplay, SearchResultType } from '../../../../generated/graphql';
import { useAvailableCostModes, useCostMode } from '../../../../utilities/costMode';
import AdvancedFilters from '../../../frame/AdvancedFiltersSidebar/AdvancedFilters';
import ItemsFilters from '../../../frame/AdvancedFiltersSidebar/ItemsFilters';
import ProjectsFilters from '../../../frame/AdvancedFiltersSidebar/ProjectsFilters';
import { calculateCustomCostMode } from '../../../frame/ItemSidebar/ItemSidebarUtils';
import { activeFiltersCountProjects } from '../projects/activeFilterCountProjects';
import { useFilterOptions } from '../projects/SearchProjectsData';
import SearchHeader from '../SearchHeader';
import { SearchItem } from '../types';

import { activeFiltersCountItems } from './activeFilterCountItems';
import { useSearchItemsFilterOptions } from './hooks/useSearchItemsFilterOptions';
import { useSearchItemsQuery } from './hooks/useSearchItemsQuery';
import useSearchItemsQueryParams from './hooks/useSearchItemsQueryParams';
import { useUpdateSearchItemsFromURL } from './hooks/useUpdateSearchItemsFromURL';
import SearchItemsList from './SearchItems';
import SearchItemSidebarWrapper from './SearchItemSidebarWrapper';

type Props = {
  isFilterMenuOpen: boolean;
  onToggleFilterMenu: (isOpen: boolean) => void;
};

const SearchItemsData = (props: Props) => {
  useEffect(() => {
    document.title = 'Items';
  }, []);

  const searchItemSidebarItemID = useReactiveVar(itemSidebarHomeOpenVar);
  const params = useSearchItemsQueryParams();

  useUpdateSearchItemsFromURL(
    params.onChangeSearch,
    params.onChangeFilters,
    params.onResetFilters,
    params.toggleParams?.onChange
  );

  const { data, fetchMore, loading, previousData } = useSearchItemsQuery(params.variables);

  const [isProjectsSectionExpanded, setExpandedProjectFiltersSection] = useState(true);
  const [isItemsSectionExpanded, setExpandedItemFiltersSection] = useState(true);

  const sidebarProjectID = data?.searchItems?.data.find(
    (i: SearchItem) => i.id === searchItemSidebarItemID
  )?.project.id;
  const { availableCostDisplays, availableMarkupModes } = useAvailableCostModes(sidebarProjectID);
  const canViewDisplayCost = availableCostDisplays.includes(CostDisplay.SHOW_COSTS);
  const costMode = useCostMode(sidebarProjectID);
  const sidebarCostMode = calculateCustomCostMode(
    availableMarkupModes,
    canViewDisplayCost,
    costMode
  );

  const filterOptions = useSearchItemsFilterOptions(
    data?.searchItems?.aggregations ?? previousData?.searchItems?.aggregations,
    params.variables.filters
  );
  const projectsFilterOptions = useFilterOptions(
    params.variables.searchResultType !== SearchResultType.MY,
    params.variables.projectsFilters
  );

  const onOpenItemSidebar = (id: string) => {
    if (searchItemSidebarItemID === id) {
      itemSidebarHomeOpenVar(null);
    } else {
      if (props.isFilterMenuOpen) props.onToggleFilterMenu(!props.isFilterMenuOpen);
      itemSidebarHomeOpenVar(id);
    }
  };

  useEffect(() => {
    return () => {
      // Close item sidebar before component is unmounted
      itemSidebarHomeOpenVar(null);
    };
  }, []);

  const activeFiltersCount = useMemo(() => {
    return (
      activeFiltersCountProjects(params.variables.projectsFilters) +
      activeFiltersCountItems(params.variables.filters)
    );
  }, [params.variables.projectsFilters, params.variables.filters]);

  const items = data?.searchItems?.data ?? previousData?.searchItems?.data ?? [];
  const counts = {
    current: data?.searchItems?.total ?? previousData?.searchItems?.total ?? 0,
    total:
      params.variables.searchResultType !== SearchResultType.MY
        ? params.toggleCounts.all
        : params.toggleCounts.my,
  };

  return (
    <>
      <SearchHeader
        activeFiltersCount={activeFiltersCount}
        isFilterMenuOpen={props.isFilterMenuOpen}
        onChangeSearch={params.onChangeSearch}
        onToggleFilterMenu={props.onToggleFilterMenu}
        search={params.variables.search}
        toggleParams={params.toggleParams}
      />
      <Divider />
      <div className="flex grow overflow-hidden">
        {props.isFilterMenuOpen && (
          <AdvancedFilters
            hasActiveFilters={activeFiltersCount > 0}
            onCloseFiltersPanel={props.onToggleFilterMenu}
            onResetFilters={params.onResetFilters}
          >
            <ProjectsFilters
              filterOptions={projectsFilterOptions}
              filterValues={params.variables.projectsFilters}
              isExpanded={isProjectsSectionExpanded}
              onChangeFilters={params.onChangeProjectsFilters}
              onClickSectionName={() =>
                setExpandedProjectFiltersSection(!isProjectsSectionExpanded)
              }
            />
            <ItemsFilters
              filterOptions={filterOptions}
              filterValues={params.variables.filters}
              isExpanded={isItemsSectionExpanded}
              onChangeFilters={params.onChangeFilters}
              onClickSectionName={() => setExpandedItemFiltersSection(!isItemsSectionExpanded)}
            />
          </AdvancedFilters>
        )}
        <div className="grow overflow-x-auto">
          <SearchItemsList
            counts={counts}
            items={items}
            loading={loading}
            onFetchMore={fetchMore}
            onOpenItemSidebar={onOpenItemSidebar}
            searchText={params.variables.search}
            sortManager={params.sortManager}
          />
        </div>
        {searchItemSidebarItemID && sidebarCostMode && availableMarkupModes.length > 0 && (
          <SearchItemSidebarWrapper
            costMode={sidebarCostMode}
            itemID={searchItemSidebarItemID}
            items={data?.searchItems?.data ?? previousData?.searchItems?.data ?? []}
            projectID={sidebarProjectID ?? undefined}
          />
        )}
      </div>
    </>
  );
};

export default SearchItemsData;
