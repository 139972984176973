import { FC } from 'react';

import { withStyles } from '../../theme/komodo-mui-theme';

import styles from './TooltipTitlesStyles';

interface TooltipTitlesProps {
  boldHeader?: boolean;
  classes: Classes<typeof styles>;
  titles: string[];
}

const TooltipTitles: FC<TooltipTitlesProps> = ({ boldHeader, classes, titles }) => (
  <>
    {titles.map((title, i) => {
      const isBold = boldHeader && i % 2 === 0;
      return (
        <div key={title} className={`${classes.overflow} ${isBold && classes.bold}`}>
          {title}
        </div>
      );
    })}
  </>
);

export const StyledTooltipTitles = withStyles(styles)(TooltipTitles);

export default StyledTooltipTitles;
