const VALID_PREFIX = `var`;

/**
 * validateColor is used to enforce that all scales components require a
 * tokenized color
 */
export const validateColor = (color?: string) => {
  if (!color) return;
  // validate color starts with prefix
  if (!new RegExp(`^${VALID_PREFIX}`).test(color))
    throw new Error(
      `Provided color does not have required prefix: '${color}' must start with '${VALID_PREFIX}'`
    );
};
