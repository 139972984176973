import { CostTimeSeries, InsightsMilestone } from './types';

export const today = new Date('2024-06-28T12:00:00.000Z');
export const past = '2022-06-28T12:00:00.000Z';
export const future = '2026-06-28T12:00:00.000Z';

const value = 350000000 * 100;

const p = (p: number) => (value * p) / 100;

export const costData: CostTimeSeries[] = [
  {
    date: new Date('2022-06-28T12:00:00.000Z'),
    budget: value - p(1),
    estimate: value + p(5),
    runningTotal: value + p(4),
    pendingMax: value + p(2),
    pendingMin: value + p(1.6),
  },
  {
    date: new Date('2023-01-28T12:00:00.000Z'),
    budget: value - p(0),
    estimate: value + p(6),
    runningTotal: value + p(3),
    pendingMax: value + p(2.6),
    pendingMin: value + p(1),
  },
  {
    date: new Date('2023-06-28T12:00:00.000Z'),
    budget: value - p(2),
    estimate: value + p(4),
    runningTotal: value + p(4.5),
    pendingMax: value + p(2.2),
    pendingMin: value + p(0.3),
  },
  {
    date: new Date('2024-01-28T12:00:00.000Z'),
    budget: value - p(1),
    estimate: value + p(4.5),
    runningTotal: value + p(3.5),
    pendingMax: value + p(3),
    pendingMin: value + p(-0.5),
  },
  {
    date: today,
    budget: value - p(2),
    estimate: value + p(4.8),
    runningTotal: value + p(1),
    pendingMax: value + p(2.5),
    pendingMin: value + p(0),
  },
];

export const milestones: InsightsMilestone[] = [
  {
    date: new Date('2023-01-28T12:00:00.000Z'),
    id: '',
    active: false,
    designPhase: null,
    name: '25% Schematic Design',
  },
  {
    date: new Date('2023-06-28T12:00:00.000Z'),
    id: '3',
    active: false,
    designPhase: null,
    name: '50% Schematic Design',
  },
  {
    date: new Date('2024-01-28T12:00:00.000Z'),
    id: '4',
    active: true,
    designPhase: null,
    name: '75% Schematic Design',
  },
  {
    date: new Date('2024-11-28T12:00:00.000Z'),
    id: '5',
    active: false,
    designPhase: 'GMP',
    name: 'GMP',
  },
  {
    date: new Date('2025-04-28T12:00:00.000Z'),
    id: '6',
    active: false,
    designPhase: null,
    name: '100% Schematic Design',
  },
];
