import { useEffect, useState } from 'react';

import ProjectSelection from '../../ProjectsList/ProjectSelection/ProjectSelection';
import { Button, Dialog } from '../../scales';

type Props = {
  canViewCompanyProjects?: boolean;
  confirmButtonLabel: string | ((count: number) => string);
  isOpen?: boolean;
  onClose: () => void;
  onDone: (selectedProjectIDs: UUID[]) => void;
  selectionMode: 'single' | 'multiple';
  title: string;
};

export default function DialogsSelectionProjects(props: Props) {
  const [selectedProjectIDs, setSelectedProjectIDs] = useState<UUID[]>([]);

  // Reset the selected projects each time the dialog opens/closes.
  useEffect(() => {
    setSelectedProjectIDs([]);
  }, [props.isOpen]);

  const confirmButtonLabel =
    typeof props.confirmButtonLabel === 'string'
      ? props.confirmButtonLabel
      : props.confirmButtonLabel(selectedProjectIDs.length);

  return (
    <Dialog
      footerRight={
        <Button
          data-cy="add-projects-submit-button"
          disabled={!selectedProjectIDs.length}
          label={confirmButtonLabel}
          onClick={() => props.onDone(selectedProjectIDs)}
          type="primary"
        />
      }
      isFullHeight
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="lg"
      title={props.title}
    >
      <ProjectSelection
        includeCompanyProjects={Boolean(props.canViewCompanyProjects)}
        onChange={setSelectedProjectIDs}
        selectedProjectIDs={selectedProjectIDs}
        selectionMode={props.selectionMode}
      />
    </Dialog>
  );
}
