import { FC, cloneElement, useMemo, useRef } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import PrintPageBreak from '../PrintSharedComponents/PrintPageBreak';
import { PORTRAIT_WIDTH } from '../PrintUtils';

import PrintCompColumn from './PrintProjectComp/PrintCompColumn';
import PrintLabelsColumn from './PrintProjectComp/PrintLabelsColumn';
import PrintProjectCompsColumnGroupHeader from './PrintProjectCompsColumnGroupHeader';
import PrintProjectCompsStyles from './PrintProjectCompsStyles';
import {
  CompsPrintThumbnailSize,
  getPrintSubheaderParametersForColumnGroup,
  isProjectCompType,
  rowHasBreakpoint,
} from './PrintProjectCompsUtils';

type PrintProjectCompsColumnGroupProps = {
  classes: Classes<typeof PrintProjectCompsStyles>;
  columnGroup: (ProjectComp | AverageComp)[];
  costTableColumnInputs: CostTableColumnInputs;
  groupIndex: number;
  hasMarkups: boolean;
  onThumbnailLoad: (columnIndex: number) => void;
  pageHeader: JSX.Element;
  parentProject?: ProjectProps;
  projectCompsSet: ProjectCompsSet;
  thumbnailSize: CompsPrintThumbnailSize;
  showNotes: boolean;
  showOnlyFirstPage?: boolean;
};

const PrintProjectCompsColumnGroup: FC<PrintProjectCompsColumnGroupProps> = ({
  classes,
  columnGroup,
  costTableColumnInputs,
  groupIndex,
  hasMarkups,
  onThumbnailLoad,
  pageHeader,
  parentProject,
  projectCompsSet,
  thumbnailSize,
  showNotes,
  showOnlyFirstPage,
}) => {
  const hasAverageComp = Boolean(
    projectCompsSet.averageComp && !projectCompsSet.averageComp.isHidden
  );
  const numProjectComps = projectCompsSet.projectComps.filter((pc) => !pc.input.isExcluded).length;
  const pageText = getPrintSubheaderParametersForColumnGroup(
    groupIndex,
    numProjectComps,
    hasAverageComp
  );

  // modify the pageHeader to have a group-specific subheader (ex. "Showing projects: 1-3 of 6")
  const modifiedPageHeader = cloneElement(pageHeader, { pageText });

  const detailedGroupHeader = (
    <PrintProjectCompsColumnGroupHeader
      columnGroup={columnGroup}
      costTableColumnInputs={costTableColumnInputs}
      displayHeaderData
      groupIndex={groupIndex}
      onThumbnailLoad={onThumbnailLoad}
      parentProject={parentProject}
      projectCompsSet={projectCompsSet}
      showNotes={showNotes}
      thumbnailSize={thumbnailSize}
    />
  );
  const simpleGroupHeader = (
    <PrintProjectCompsColumnGroupHeader
      columnGroup={columnGroup}
      costTableColumnInputs={costTableColumnInputs}
      groupIndex={groupIndex}
      onThumbnailLoad={onThumbnailLoad}
      parentProject={parentProject}
      projectCompsSet={projectCompsSet}
      showNotes={showNotes}
      thumbnailSize={thumbnailSize}
    />
  );

  const initialHeaderRef = useRef<HTMLDivElement>(null);
  const initialHeader = (
    <div ref={initialHeaderRef}>
      {modifiedPageHeader}
      {detailedGroupHeader}
    </div>
  );
  const repeatedHeaderRef = useRef<HTMLDivElement>(null);
  const repeatedHeader = (
    <div ref={repeatedHeaderRef}>
      {modifiedPageHeader}
      {simpleGroupHeader}
    </div>
  );

  const initialHeaderHeight = initialHeaderRef.current?.clientHeight ?? 0;
  const repeatedHeaderHeight = repeatedHeaderRef.current?.clientHeight ?? 0;
  // Note: PORTRAIT_WIDTH is the height of this landscape page
  const firstPageColumnHeight = PORTRAIT_WIDTH - repeatedHeaderHeight - initialHeaderHeight;
  const hasBreakpoint = useMemo(
    () => (rowIndex: number) =>
      rowHasBreakpoint(rowIndex, repeatedHeaderHeight, initialHeaderHeight - repeatedHeaderHeight),
    [initialHeaderHeight, repeatedHeaderHeight]
  );

  const labelsColumn = (
    <PrintLabelsColumn
      hasMarkups={hasMarkups}
      headerHeight={repeatedHeaderHeight}
      pageHeader={repeatedHeader}
      projectCompsSet={projectCompsSet}
      rowHasBreakpoint={hasBreakpoint}
    />
  );
  const compColumns = columnGroup.map((compData) => {
    const columnKey = isProjectCompType(compData) ? compData.project.id : 'Average';
    return (
      <PrintCompColumn
        key={columnKey}
        compData={compData}
        costTableColumnInputs={projectCompsSet.input.costTableColumnInputs}
        hasMarkups={hasMarkups}
        headerHeight={repeatedHeaderHeight}
        rowHasBreakpoint={hasBreakpoint}
      />
    );
  });

  return (
    <>
      {initialHeader}
      <div
        className={classes.content}
        style={{
          height: showOnlyFirstPage ? firstPageColumnHeight : undefined,
          overflow: 'hidden',
        }}
      >
        {labelsColumn}
        {compColumns}
      </div>
      <PrintPageBreak />
    </>
  );
};

export default withStyles(PrintProjectCompsStyles)(PrintProjectCompsColumnGroup);
