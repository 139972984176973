import { useContext } from 'react';

import { VIEW_ONLY } from '../../../../constants';
import { Permission } from '../../../../generated/graphql';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import { filterMarkupModePermissions } from '../utils';

import { getMarkupModePermissionLabel } from './permissions';

type Props = {
  permissions: Permission[];
  hideCosts: boolean;
};

export default function MarkupModePermissionsTableView(props: Props) {
  const t = useContext(ProjectTermStore);
  const markupModePermissions = filterMarkupModePermissions(props.permissions);
  const savedMarkupModePermissions = markupModePermissions.filter((p) => p.level === VIEW_ONLY);

  return (
    <div className="grid grid-cols-7 border-t leading-10">
      <div className="col-span-3 content-center px-2 py-2 type-body1">Markups display options</div>
      <div className="col-span-4 content-evenly py-2">
        {props.hideCosts ? (
          <div className="px-2 type-body1">None</div>
        ) : (
          <>
            {savedMarkupModePermissions.map((p) => (
              <div key={p.resource} className="col-span-4 px-2 py-1 leading-10 type-body1">
                {getMarkupModePermissionLabel(p.resource, t)}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
