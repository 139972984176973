import { FC, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import { TermKey } from '../../../api/gqlEnums';
import {
  ACCEPTED,
  PENDING,
  PENDING_ADDS,
  PENDING_DEDUCTS,
  REJECTED,
  RUNNING,
  TARGET,
} from '../../../constants';
import theme from '../../../theme/komodo-mui-theme';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';

import getRangeStyle from './ItemsListHeadersUtils';

type StringObject = { [key: string]: string };

const titlesShort: StringObject = {
  [ACCEPTED]: 'Accepted',
  [PENDING]: 'Pending',
  [PENDING_ADDS]: 'Adds',
  [PENDING_DEDUCTS]: 'Deducts',
  [REJECTED]: 'Rejected',
  [RUNNING]: 'Running',
  [TARGET]: 'Budget',
};

const labelDescription: StringObject = {
  [ACCEPTED]: 'Difference from the milestone estimate after including all accepted changes',
  [PENDING]: 'Difference from the milestone estimate after including all pending changes',
  [PENDING_ADDS]:
    'Difference from the milestone estimate after including all positive pending changes',
  [PENDING_DEDUCTS]:
    'Difference from the milestone estimate after including all negative pending changes',
  [REJECTED]: 'Difference from the milestone estimate after including all rejected changes',
  [RUNNING]: 'New subtotal after accepted changes are applied',
  [TARGET]: 'Target budget for the milestone',
};

type ItemsListHeadersLabelProps = {
  compress?: boolean;
  status: string;
};

const ItemsListHeadersLabel: FC<ItemsListHeadersLabelProps> = ({ compress = false, status }) => {
  // CONSTANTS
  const t = useContext(ProjectTermStore);
  const minWidth = theme.breakpoints.values.lg;
  const isWideMedia = useMediaQuery({ minWidth });
  const { isWide } = getRangeStyle({
    compress,
    status,
    isWideMedia,
  });
  const titles: StringObject = {
    [ACCEPTED]: 'Accepted Items',
    [PENDING]: 'Pending Items',
    [PENDING_ADDS]: 'Pending Adds',
    [PENDING_DEDUCTS]: 'Pending Deducts',
    [REJECTED]: 'Rejected Items',
    [RUNNING]: t.titleCase(TermKey.RUNNING_TOTAL),
    [TARGET]: t.titleCase(TermKey.TARGET),
  };
  return (
    <div
      className={`ml-0 flex-shrink-0 text-right type-label ${isWide ? 'basis-32' : 'basis-20'}`}
      title={labelDescription[status]}
    >
      <span>{isWide ? titles[status] : titlesShort[status]}</span>
    </div>
  );
};

export default ItemsListHeadersLabel;
