import { ReactNode } from 'react';

import { Button, Dialog } from '../../scales';

import { getSelectedText } from './utils';

type DialogProps = {
  /** Hex color code to use when rendering an accent border */
  accentColor?: string;
  children: ReactNode;
  'data-cy'?: string;
  /** Nodes rendered in the footer must have a height <= 40px */
  footerCenter?: ReactNode;
  /** Nodes rendered in the footer must have a height <= 40px */
  footerLeft?: ReactNode;
  /** Nodes rendered in the footer must have a height <= 40px */
  footerRight?: ReactNode;
  image?: ReactNode;
  isFullHeight?: boolean;
  isNotDismissable?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  /** A decimal number between 0 and 1 */
  progressPercent?: number;
  size?: 'md' | 'lg';
  title: ReactNode;
};

export type Props = DialogProps & {
  newCollaboratorIDs: UUID[];
  onShare: () => void;
  removedCollaboratorIDs: UUID[];
  sharedCollaboratorIDs: UUID[];
  shareDisabled: boolean;
  shareLabel: string;
  getSelectedSummaryTextFn?: () => { summaryText: string; selectedText: string };
};

export default function ShareDialog(props: Props) {
  const { summaryText, selectedText } = props.getSelectedSummaryTextFn
    ? props.getSelectedSummaryTextFn()
    : getSelectedText(
        props.sharedCollaboratorIDs,
        props.newCollaboratorIDs,
        props.removedCollaboratorIDs
      );

  return (
    <Dialog
      {...props}
      footerLeft={
        props.footerLeft ? (
          props.footerLeft
        ) : (
          <div className="type-heading3" data-cy="share-dialog-footer-status">
            {selectedText}
          </div>
        )
      }
      footerRight={
        props.footerRight ? (
          props.footerRight
        ) : (
          <div className="flex items-center gap-5">
            <div className="type-heading3" data-cy="share-dialog-footer-update-message">
              {summaryText}
            </div>
            <Button
              data-cy="share-button"
              disabled={props.shareDisabled}
              label={props.shareLabel}
              onClick={props.onShare}
              type="primary"
            />
          </div>
        )
      }
      isFullHeight
      isNotDismissable={false}
      onClose={props.onClose}
      size="lg"
      title={props.title}
    >
      {props.children}
    </Dialog>
  );
}
