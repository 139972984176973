import { useQuery } from '@apollo/client';

import { gql } from '../api/graphqlFragments';
import {
  ItemIntegrationObjectsCountQuery,
  ItemIntegrationObjectsCountQueryVariables,
} from '../generated/graphql';

export const itemIntegrationObjectsCountQuery = gql`
  query ItemIntegrationObjectsCount($itemID: UUID!) {
    itemIntegrationObjectsCount(itemID: $itemID)
  }
`;

export default function useItemIntegrationObjectsCountQuery(
  variables: ItemIntegrationObjectsCountQueryVariables
) {
  return useQuery<ItemIntegrationObjectsCountQuery, ItemIntegrationObjectsCountQueryVariables>(
    itemIntegrationObjectsCountQuery,
    {
      variables: {
        ...variables,
      },
      skip: !variables.itemID,
    }
  );
}
