/**
 * This file houses the color definitions for the Design System.
 * Definitions are based on the tokens and Figma variables defined in:
 * https://www.figma.com/file/LZWON29XVriIx2d8QD1whCO3/Colors-and-Type
 * (see the "Color - App") file for current values.
 *
 * The main purpose of the file is to allow us to take this set of colors
 * and make them available in Tailwind's tailwind.config.js and mixed-into
 * the legacy MaterialUI theme.
 *
 * However, it also serves a second purpose wherein if you absolutely needed
 * to, you could import this file in your JS and have access to the colors.
 * The use case for this is /extremely/ narrow, but a possibility would be
 * something like a color picker that the user can interact with for chart
 * colors. In almost all other scenarios, you should define your colors
 * via Tailwind or MUI - depending on the section of the app you're working on.
 */

const globalColors = Object.freeze({
  'black-000': '#000',
  'white-000': '#FFF',
  'grey-100': '#F6F7F9',
  'grey-200': '#E7EAEF',
  'grey-300': '#D0D5D7',
  'grey-400': '#9B9B9B',
  'grey-500': '#686B6C',
  'grey-600': '#494B4B',
  'grey-tint-100': '#3D50701A',
  'green-100': '#E5FEE1',
  'green-200': '#CCFDC3',
  'green-300': '#6CF054',
  'green-400': '#5CD746',
  'green-500': '#6AAF6F',
  'green-600': '#08493B',
  'sage-300': '#C8EFC7',
  'sage-500': '#E1EFE1',
  'sage-600': '#CDD8D4',
  'blue-500': '#4B71A9',
  'red-200': '#FADADC',
  'red-500': '#E11E29',
  'coral-100': '#FDF0F0',
  'coral-400': '#ED6864',
  'yellow-200': '#FFF1D7',
  'yellow-400': '#F6B901',
  'yellow-600': '#DAA401',
  picker: {
    'amber-200': '#FFE18D',
    'amber-600': '#ECB32C',
    'blue-300': '#4F73F5',
    'green-400': '#116400',
    'lime-200': '#9AAF18',
    'orange-400': '#E07724',
    'pink-400': '#D63B79',
    'purple-400': '#A42ADD',
    'rose-600': '#680003',
    'sky-100': '#C0E5FE',
  },
});

const brandColors = Object.freeze({
  yellow: '#FFCF5D',
  coral: '#ED6864',
  sand: '#EAE7DB',
  orange: '#DE7C1D',
  slate: '#3C4746',
  lightGray: '#D8DADA',
});

/** @deprecated DO NOT IMPORT!!!
 *
 * This is exported for MUI+Tailwind sync-up only! It is not theme-aware and will be removed.
 *
 * */
const lightColors = Object.freeze({
  transparent: 'transparent',
  background: {
    backdrop: '#00000088',
    primary: globalColors['white-000'],
    1: globalColors['grey-100'],
    2: globalColors['grey-200'],
  },
  border: {
    default: globalColors['grey-300'],
    'calculations-total': globalColors['black-000'],
    muted: globalColors['grey-200'],
    separator: globalColors['grey-200'],
  },
  button: {
    primary: globalColors['black-000'],
    'primary-hover': globalColors['grey-600'],
    secondary: globalColors['white-000'],
    'secondary-hover': globalColors['grey-100'],
    'secondary-outline': globalColors['black-000'],
    'focus-outline': globalColors['green-300'],
    inactive: globalColors['grey-200'],
    'inactive-outline': globalColors['grey-400'],
    'icon-bg': 'transparent',
    'icon-hover': globalColors['grey-tint-100'],
    'icon-outline': globalColors['grey-300'],
    destructive: globalColors['red-500'],
    'destructive-hover': globalColors['red-200'],
    warning: globalColors['yellow-400'],
    'warning-hover': globalColors['yellow-600'],
    'warning-focus-outline': brandColors.orange,
    'warning-text': globalColors['black-000'],
  },
  checkbox: {
    disabled: globalColors['grey-300'],
    focused: globalColors['green-400'],
    selected: globalColors['black-000'],
    unselected: globalColors['grey-300'],
  },
  'item-status': {
    rejected: globalColors['red-500'],
    'rejected-tint': globalColors['red-200'],
    pending: globalColors['yellow-400'],
    'pending-tint': globalColors['yellow-200'],
    accepted: globalColors['green-500'],
    'accepted-tint': globalColors['sage-500'],
    incorporated: globalColors['green-600'],
    'incorporated-tint': globalColors['sage-600'],
    'not-applicable': globalColors['grey-400'],
    'not-applicable-tint': globalColors['grey-200'],
    'not-chosen': globalColors['grey-400'],
    'not-chosen-tint': globalColors['grey-200'],
  },
  notification: {
    fill: globalColors['coral-400'],
    tint: globalColors['coral-100'],
    'warning-fill': globalColors['yellow-400'],
    'warning-tint': globalColors['grey-100'],
  },
  radio: {
    disabled: globalColors['grey-400'],
    focused: globalColors['green-400'],
    selected: globalColors['black-000'],
    unselected: globalColors['grey-400'],
  },
  scrollbar: {
    fg: globalColors['grey-400'],
  },
  switch: {
    'disabled-off': globalColors['grey-200'],
    'disabled-on': globalColors['grey-400'],
    hover: globalColors['green-100'],
    'knob-active': globalColors['green-400'],
    'knob-off': globalColors['white-000'],
    'track-active': globalColors['green-200'],
    'track-off': globalColors['grey-300'],
  },
  selection: {
    hover: globalColors['green-100'],
    selected: globalColors['green-200'],
    'focus-outline': globalColors['green-300'],
    'focus-fill': globalColors['green-400'],
  },
  'status-banner': {
    'default-bg': globalColors['grey-100'],
    'error-bg': globalColors['coral-100'],
    'error-icon': globalColors['coral-400'],
    'warning-icon': globalColors['yellow-400'],
  },
  'theme-bauhaus': {
    berlin: globalColors.picker['amber-200'],
    gropius: globalColors.picker['pink-400'],
    hannesmeyer: globalColors.picker['green-400'],
    josefalbers: globalColors.picker['rose-600'],
    kandinsky: globalColors.picker['sky-100'],
    laszlo: globalColors.picker['orange-400'],
    marcelbreuer: globalColors.picker['blue-300'],
    mariannebrandt: globalColors.picker['lime-200'],
    seagram: globalColors.picker['amber-600'],
    telaviv: globalColors.picker['purple-400'],
  },
  chart: {
    axis: globalColors['grey-300'],
    'axis-label': globalColors['grey-400'],
    'axis-ticks': globalColors['grey-100'],
    'base-line': globalColors['black-000'],
    'pending-cost-area': '#f6b90199',
  },
  entities: {
    event: globalColors['coral-400'],
    'item-decided': globalColors['grey-300'],
    'item-pastdue': globalColors['coral-400'],
    'item-upcoming': globalColors['yellow-400'],
    gap: globalColors['grey-400'],
    milestone: globalColors['blue-500'],
    estimate: globalColors['black-000'],
    budget: globalColors['blue-500'],
    phase: globalColors['grey-300'],
    today: globalColors['green-400'],
  },
  type: {
    primary: globalColors['black-000'],
    secondary: globalColors['white-000'],
    link: globalColors['blue-500'],
    inactive: globalColors['grey-400'],
    error: globalColors['red-500'],
    delete: globalColors['red-500'],
    muted: globalColors['grey-400'],
    success: globalColors['green-500'],
    warning: globalColors['yellow-400'],
  },
});

const darkColors = Object.freeze({
  ...lightColors,
  background: {
    backdrop: '#00000080',
    primary: '#3C4746',
    1: '#565D59',
    2: '#333836',
  },
  border: {
    default: '#8E938D',
    'calculations-total': '#646C68',
    muted: '#646C68',
    separator: '#646C68',
  },
  button: {
    primary: '#646C68',
    'primary-hover': '#8E938D',
    secondary: '#3C4746',
    'secondary-hover': '#646C68',
    'secondary-outline': '#8E938D',
    'focus-outline': '#5A9851',
    'icon-bg': 'transparent',
    'icon-hover': '#EBF4EB3D',
    inactive: '#333836',
    'inactive-outline': '#646C68',
    'icon-outline': '#8E938D',
    destructive: '#F29CA1',
    'destructive-hover': '#2D0608',
    warning: globalColors['yellow-400'],
    'warning-hover': globalColors['yellow-600'],
    'warning-focus-outline': brandColors.orange,
    'warning-text': globalColors['black-000'],
  },
  checkbox: {
    disabled: '#646C68',
    focused: '#5A9851',
    selected: brandColors.yellow,
    unselected: brandColors.sand,
  },
  entities: {
    milestone: '#B1C3DD',
    gap: '#C5BC9B',
  },
  'item-status': {
    rejected: '#F29CA1',
    'rejected-tint': '#FFF', // placeholder
    pending: '#FEE290',
    'pending-tint': '#FFF', // placeholder
    accepted: '#8BC18F',
    'accepted-tint': '#FFF', // placeholder
    incorporated: '#8BBBB0',
    'incorporated-tint': '#FFF', // placeholder
    'not-applicable': '#C5BC9B',
    'not-applicable-tint': '#FFF', // placeholder
    'not-chosen': '#C5BC9B',
    'not-chosen-tint': '#FFF', // placeholder
  },
  radio: {
    disabled: '#646C68',
    focused: '#5A9851',
    selected: brandColors.yellow,
    unselected: brandColors.sand,
  },
  scrollbar: {
    fg: '#BBBCB3',
  },
  selection: {
    hover: '#646C68',
    selected: '#565D59',
    'focus-outline': '#5A9851',
    'focus-fill': globalColors['green-400'],
  },
  switch: {
    'disabled-off': '#8E938D',
    'disabled-on': '#9B9B9B',
    hover: '#E0C58B',
    'knob-active': '#FFCF5D',
    'knob-off': '#BBBCB3',
    'track-active': '#C9B078',
    'track-off': '#8E938D',
  },
  type: {
    primary: brandColors.sand,
    secondary: brandColors.sand,
    link: brandColors.yellow,
    inactive: '#8E938D',
    error: '#F29CA1',
    delete: '#F29CA1',
    muted: '#BBBCB3',
    success: '#8BC18F',
    warning: '#FEE290',
  },
});

const colors = {
  light: lightColors,
  dark: darkColors,
} as const;

/** @deprecated DO NOT IMPORT!!!
 *
 * This is exported for legacy purposes only! It is not theme-aware and will be removed.
 *
 * */
const entityColors = lightColors.entities;
const pickerColors = lightColors['theme-bauhaus'];
const chartColors = [
  '#AED026',
  '#5A5C23',
  '#D2FF52',
  '#00B8CE',
  '#B0D5B2',
  '#419946',
  '#FFFFCC',
  '#207223',
  '#B5FFED',
  '#07441F',
  '#70D074',
  '#008395',
  '#7A9BF0',
  '#98249B',
  '#050434',
  '#6BEFFF',
  '#4F5FC2',
  '#1D38C8',
  '#A26BFD',
  '#4C0578',
];

export { entityColors, pickerColors, chartColors };

export default colors;
