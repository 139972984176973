import { JoinProjectRoutes, ReportGroups } from '../../../api/gqlEnums';
import { UserReportType } from '../../../generated/graphql';
import { generateSharedPath } from '../../../utilities/routes/links';
import { pluralizeString } from '../../../utilities/string';
import { MapUserReportTypeToConfig } from '../config';
import { reportToPath } from '../utils';

export const getReportsDefaults = (hasMyReports: boolean, hasSharedReports: boolean) => {
  const reportDefaults: { collapse: ReportGroups[]; expand: ReportGroups[] } = {
    collapse: [],
    expand: [ReportGroups.BUILT_IN_REPORTS],
  };
  // My Reports
  if (hasMyReports) reportDefaults.expand.push(ReportGroups.MY_REPORTS);
  else reportDefaults.collapse.push(ReportGroups.MY_REPORTS);
  // Shared Reports
  if (hasSharedReports) reportDefaults.expand.push(ReportGroups.SHARED_REPORTS);
  else reportDefaults.collapse.push(ReportGroups.SHARED_REPORTS);

  return reportDefaults;
};

export const emptyUserReportInput: UserReportInput = {
  reportType: UserReportType.NONE,
  shared: false,
  name: '',
  description: '',
  settings: '',
};

export const getPath = (
  report: Pick<UserReport, 'reportType' | 'id'>,
  projectId: UUID,
  milestoneId: UUID
) => {
  const route = MapUserReportTypeToConfig[report.reportType]?.appViewRoute;

  const isBreakdownUserReport = report.reportType === UserReportType.USER_REPORT_BREAKDOWNS;
  return route
    ? generateSharedPath(JoinProjectRoutes.REPORT_ROUTER, {
        projectId,
        reportID: report.id,
        milestoneId: isBreakdownUserReport ? milestoneId : undefined,
      })
    : undefined;
};

export const getPrintPath = (
  report: Pick<UserReport, 'reportType' | 'settings' | 'milestoneID' | 'id'>,
  projectId: UUID,
  milestoneId: UUID
) => {
  const isPrintable = Boolean(MapUserReportTypeToConfig[report.reportType]?.printViewRoute);
  return isPrintable ? reportToPath('print', report, projectId, milestoneId) : undefined;
};

export const getExportPath = (
  report: UserReport,
  projectId: UUID,
  milestoneId: UUID,
  hasExportPermissions: boolean
) => {
  const isExportable = Boolean(MapUserReportTypeToConfig[report.reportType]?.exportViewRoute);
  return isExportable && hasExportPermissions
    ? reportToPath('export', report, projectId, milestoneId)
    : undefined;
};

export const formatTimeForReportsTab = (updatedAt: Time) =>
  new Date(updatedAt)
    .toLocaleTimeString(undefined, {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      month: 'numeric',
      year: 'numeric',
    })
    .replace(/\//g, '-')
    .replace(/,/g, '');

export const reportsCountText = (
  count: number,
  distributedReportCount: number,
  hasReportDistributionAccess: boolean
) => {
  if (hasReportDistributionAccess) {
    return `${count} Reports, ${distributedReportCount} Scheduled ${pluralizeString(
      'Distribution',
      distributedReportCount
    )}`;
  }
  return `${count} Reports`;
};
