import { FC, ReactElement } from 'react';

import { Collapse, Typography } from '@material-ui/core';

import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { renderCostString } from '../../CostReport/CostReportUtils';
import CollapseIcon from '../../shared-widgets/CollapseIcon';

import styles from './CollapseEntryStyles';

type CollapseEntryProps = {
  children?: ReactElement;
  chip?: ReactElement;
  classes: Classes<typeof styles>;
  collapsed: boolean;
  endIcon?: ReactElement;
  expandOnCollapseIconOnly?: boolean;
  fullWidth?: boolean;
  hasMargin?: boolean;
  hasZeroMarginContainer?: boolean;
  hasPadding?: boolean;
  hasNoBackground?: boolean;
  hasNoBorder?: boolean;
  isInactive?: boolean;
  hasBorderBottom?: boolean;
  setCollapse: (collapsed: boolean) => void;
  showCollapseIcon?: boolean;
  title: string | ReactElement;
  value?: Cost;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  analyticsEvent?: any;
};

const CollapseEntry: FC<CollapseEntryProps> = ({
  children,
  chip,
  classes,
  collapsed,
  endIcon,
  expandOnCollapseIconOnly = false,
  fullWidth = false,
  hasMargin = false,
  hasZeroMarginContainer = false,
  hasPadding = true,
  hasNoBackground = false,
  hasNoBorder = false,
  hasBorderBottom = false,
  isInactive = false,
  setCollapse,
  showCollapseIcon = true,
  title,
  value,
  analyticsEvent,
}) => {
  const sendAnalytics = useSendAnalytics();

  return (
    <div className={classes.container}>
      <div
        className={`${classes.root} ${hasNoBorder ? '' : classes.border} 
        ${hasBorderBottom ? classes.borderBottom : ''} ${classes.entryHeader}
        ${fullWidth ? classes.fullWidth : ''}
        ${hasMargin ? classes.margin : ''}
        ${hasPadding ? '' : classes.noPadding} ${hasNoBackground ? classes.noBackground : ''}`}
      >
        <div className={`${classes.endIcon} flex flex-col md:flex-row`}>
          <div
            className={`${classes.header} ${hasPadding ? '' : classes.noPadding}`}
            data-cy={`collapse-${title}`}
            onClick={() => {
              if (!expandOnCollapseIconOnly) {
                setCollapse(!collapsed);
                if (analyticsEvent) {
                  sendAnalytics(analyticsEvent);
                }
              }
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={-1}
          >
            <div className={`${classes.rowTitle} ${isInactive ? classes.inActive : ''}`}>
              {showCollapseIcon ? (
                <CollapseIcon
                  isCollapsed={collapsed}
                  onClick={() => {
                    setCollapse(!collapsed);
                    if (analyticsEvent) {
                      sendAnalytics(analyticsEvent);
                    }
                  }}
                />
              ) : (
                <div className="pl-6" />
              )}
              <div className={classes.rowTitleInner}>
                {typeof title === 'string' ? (
                  <Typography
                    className={`${classes.title} ${isInactive ? classes.inActive : ''}`}
                    variant="subheading"
                  >
                    {title}
                  </Typography>
                ) : (
                  title
                )}
                {value && (
                  <div className={classes.costPadding}>
                    <Typography className={classes.number}>
                      {renderCostString({
                        cost: value,
                        isWide: true,
                        isExact: true,
                      })}
                    </Typography>
                  </div>
                )}
                {chip}
              </div>
            </div>
          </div>
          <div className={classes.endIcon}>{endIcon}</div>
        </div>
        <Collapse
          classes={{
            entered: classes.overflow,
            container: hasZeroMarginContainer ? classes.zeroMargin : undefined,
          }}
          in={!collapsed}
          timeout="auto"
          unmountOnExit
        >
          {children}
        </Collapse>
      </div>
    </div>
  );
};

export const StyledCollapseEntry = withStyles(styles)(CollapseEntry);

export default StyledCollapseEntry;
