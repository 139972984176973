import { FC, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';

import {
  brandAssistAnalytics,
  createItemAnalytics,
  createOptionAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { ToastType } from '../../../api/gqlEnums';
import { REFETCH_CHANGE_ITEM, refetchItem } from '../../../api/refetchSets';
import {
  ConvertItemToItemWithOptionsMutation,
  ConvertItemToItemWithOptionsMutationVariables,
  Visibility,
} from '../../../generated/graphql';
import { useRefetch } from '../../../hooks/useRefetch';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { getCostMode } from '../../../utilities/costMode';
import { isTextValid } from '../../../utilities/string';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useCurrentUser } from '../../contexts/current-user';
import { convertItemToItemWithOptionsMutation } from '../../Items/hooks/itemMutation';
import useMemoWrapper from '../../useMemoWrapper';

import DialogsBrandAssistContent from './DialogsBrandAssistContent';
import DialogsNewItemContent from './DialogsNewItemContent';
import {
  analyticPropsForItem,
  filterCategoriesForSubmit,
  getGenerateNewItemFromParent,
  getNewOptions,
  useCreateItemMutation,
  useCreateOptionMutation,
} from './DialogsNewItemUtils';

type DialogsNewItemProps = {
  milestoneID: string;
  onClose: () => void;
  open: boolean;
  parentItem?: Item;
  onCreateAnother?: () => void;
  onCompleted?: () => void;
};

const DialogsNewItem: FC<DialogsNewItemProps> = ({
  milestoneID,
  onClose,
  open,
  parentItem,
  onCreateAnother,
  onCompleted,
}) => {
  const projectId = getProjectIdFromUrl();
  const currentUser = useCurrentUser();
  // OPTION CONSTANTS
  const isOption = !!parentItem;
  const isConvertingToIWO = isOption && parentItem?.options?.length === 0;

  const defaultVisibility = parentItem?.visibility || Visibility.PUBLISHED;

  // HOOKS
  const [visibilitySetting, setVisibilitySetting] = useState(defaultVisibility);
  const generateNewItemFromParent = getGenerateNewItemFromParent(milestoneID, visibilitySetting);

  const [triggeredBrandName, setTriggeredBrandName] = useState<string | undefined>(undefined);
  const [createItemAfterBrandAssist, setCreateItemAfterBrandAssist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<DraftItem>(generateNewItemFromParent(parentItem));
  const [count, setCount] = useState(0);
  const sendAnalytics = useSendAnalytics();
  const refetch = useRefetch(REFETCH_CHANGE_ITEM);

  // when parent categories change after first load, new item changes -- reset default item to have those categories
  useEffect(() => {
    if (parentItem || milestoneID !== item.milestoneID) {
      setItem(generateNewItemFromParent(parentItem));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOption, milestoneID, parentItem, setItem]);

  const onCloseDialog = () => {
    onClose();
    setItem({ ...generateNewItemFromParent(), visibility: defaultVisibility });
    setVisibilitySetting(defaultVisibility);
  };

  // ITEM + OPTION MUTATIONS
  const [convertItemToItemWithOption] = useMutation<
    ConvertItemToItemWithOptionsMutation,
    ConvertItemToItemWithOptionsMutationVariables
  >(convertItemToItemWithOptionsMutation);

  const onConvertItem = isOption
    ? () =>
        convertItemToItemWithOption({
          variables: {
            projectID: projectId,
            item: parentItem.id,
            costMode: getCostMode(),
          },
        }).then((result) => {
          onCompleted?.();
          if (parentItem.activeEstimate) {
            sendAnalytics(createOptionAnalytics({ howCreated: 'CONVERTED ITEM' }));
          }
          const { convertItemToItemWithOptions } = result.data || {};
          if (convertItemToItemWithOptions?.milestone) {
            refetch([refetchItem(convertItemToItemWithOptions.id)]);
          }
        })
    : () => Promise.resolve();

  const createItem = useCreateItemMutation({
    projectId,
  });
  const createOption = useCreateOptionMutation({
    parentItem,
    projectId,
  });
  const create = isOption ? createOption : createItem;
  const { categories, name } = item;

  const isValidName = useMemoWrapper(isTextValid, name);

  const onSubmit = (
    onCloseAction?: () => void,
    createAsDraft?: boolean,
    triggeredBrand?: string
  ) => {
    setTriggeredBrandName(triggeredBrand);
    setCreateItemAfterBrandAssist(triggeredBrand !== undefined && onCloseAction !== undefined);
    // don't create an item without a valid name
    // when users press enter to create an item
    // they can create an item with an invalid name
    // even if the submit button is disabled
    // also don't create an item if we've already submitted
    // the current item
    if (!isValidName || loading) {
      return;
    }
    const visibility = createAsDraft ? Visibility.PRIVATE_DRAFT : Visibility.PUBLISHED;
    item.visibility = visibility;
    if (createAsDraft && item.assigneeEmail !== currentUser.email) delete item.assigneeEmail;
    // bundle response activities
    setLoading(true);
    const onSuccess: Parameters<typeof create>[1] = (createdItem) => {
      if (triggeredBrand !== undefined) {
        sendAnalytics(
          brandAssistAnalytics({ type: 'Trigger', brand: triggeredBrand, itemID: createdItem.id })
        );
      }
      if (isOption) {
        // notify analytics
        sendAnalytics(createOptionAnalytics({ howCreated: 'NEW OPTION ON ITEM WITH OPTIONS' }));
        const newOptions = getNewOptions(createdItem, parentItem) || [];
        setToast(null, ToastType.NEW_ITEM, newOptions[0]);
        // update event data for the parent item
        if (onCreateAnother) onCreateAnother();
      } else {
        setToast(null, ToastType.NEW_ITEM, createdItem);
      }
      // we need to update the item...
      if (createdItem.milestone) {
        if (createdItem.id) {
          refetch([refetchItem(createdItem.id)]);
        }
      }
      setItem(generateNewItemFromParent(parentItem));
      if (onCloseAction) {
        onCloseAction();
      } else {
        setCount(count + 1);
      }
      setLoading(false);
    };
    const onFailure: Parameters<typeof create>[2] = () => {
      setLoading(false);
    };
    if (isConvertingToIWO) {
      onConvertItem().then(() => {
        create(
          {
            ...item,
            categories: filterCategoriesForSubmit(categories) ?? [],
          },
          onSuccess,
          onFailure
        );
      });
    } else {
      if (!isOption) {
        sendAnalytics(createItemAnalytics(analyticPropsForItem(item)));
      }
      create(
        {
          ...item,
          categories: filterCategoriesForSubmit(categories) ?? [],
        },
        onSuccess,
        onFailure
      );
    }
  };

  const dialogContent = (
    <DialogsNewItemContent
      convertItem={onConvertItem}
      count={count}
      isValidName={isValidName}
      item={item}
      loading={loading}
      onClose={onCloseDialog}
      onSubmit={onSubmit}
      open={open}
      parentItem={parentItem}
      setItem={setItem}
      setVisibilitySetting={setVisibilitySetting}
      visibilitySetting={visibilitySetting}
    />
  );

  const brandAssistContent = (
    <DialogsBrandAssistContent
      brandName={triggeredBrandName || ''}
      onClose={() => {
        sendAnalytics(brandAssistAnalytics({ type: 'Dismiss', brand: triggeredBrandName || '' }));
        setTriggeredBrandName(undefined);
        if (createItemAfterBrandAssist) {
          onCloseDialog();
        }
      }}
      onSubmit={() => {
        sendAnalytics(brandAssistAnalytics({ type: 'Submit', brand: triggeredBrandName || '' }));
        setTriggeredBrandName(undefined);
        if (createItemAfterBrandAssist) {
          onCloseDialog();
        }
      }}
      projectID={projectId}
    />
  );

  if (triggeredBrandName !== undefined) return brandAssistContent;
  return dialogContent;
};

export default DialogsNewItem;
