import { FC, useState } from 'react';
import * as React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Edit, FileCopy } from '@material-ui/icons';

import {
  reportTransition,
  transitionEventTypes,
} from '../../../analytics/analyticsEventProperties';
import { isEstimateUploadDialogOpen } from '../../../api/apollo/reactiveVars';
import { EstimateType, JoinProjectRoutes, UploadPlatform } from '../../../api/gqlEnums';
import { MANUAL, TYPE } from '../../../constants';
import { EstimateTotalType, PermissionResource } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { clickLinkKeyHandler } from '../../../utilities/clickHandler';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import { openImportHelp } from '../../alerts/AlertsSnackBar/AlertsSnackBarUtils';
import { useMilestonesQuery } from '../../Milestones/hooks';
import {
  filterMilestonesForEstimateTransition,
  getCurrentEstimateType,
} from '../../MilestoneTransition/Modals/TransitionMilestoneSelection/TransitionMilestoneSelectionUtils';
import { openTransitionOnboarding } from '../../MilestoneTransition/TransitionManager/TransitionManagerUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Uploader, UploaderLink } from '../../scales';
import CardButton from '../../shared-widgets/CardButton';
import useMemoWrapper from '../../useMemoWrapper';

import EstimateImportPlatformSVGs from './EstimateImportPlatformSVGs';
import EstimateToolMenu from './EstimateToolMenu';
import useCreateMilestoneDraftEstimate from './hooks/CreateMilestoneDraftEstimateHook';
import styles from './NewEstimateAddUploadStyles';

type EstimateAddUploadProps = {
  classes: Classes<typeof styles>;
  errorText: string | undefined;
  estimateType: EstimateType;
  loading: boolean;
  lowerTerm: string;
  onCancelUploading: () => void;
  onFileSelected: (file: File, source: FileSource) => void;
  refetchMilestone: () => void;
};

const unavailableInPreviewCopy = 'This feature is unavailable in preview mode';

const EstimateAddUploadDrag: FC<EstimateAddUploadProps> = (props) => {
  const { classes, errorText, estimateType, loading, onCancelUploading, refetchMilestone } = props;
  const [estimatePlatform, setEstimatePlatform] = useState(UploadPlatform.EMPTY_STATE);

  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl();
  const milestoneId = getMilestoneIdFromUrl();
  const [createMilestoneDraftEstimate] = useCreateMilestoneDraftEstimate();
  const sendAnalytics = useSendAnalytics();
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);

  const { canEdit, inPreviewMode, isViewOnly } = usePermissions();
  const canEditMilestoneCosts = canEdit(PermissionResource.MILESTONE_LINES);
  const isDisabled = isViewOnly || !canEditMilestoneCosts;

  const currentEstimateType = getCurrentEstimateType(window.location);
  const milestones = useMilestonesQuery(projectId, false).data?.milestones ?? [];

  const hasMilestonesToTransitionFrom = !!useMemoWrapper(
    filterMilestonesForEstimateTransition,
    milestones,
    milestoneId,
    currentEstimateType
  ).length;

  // opens the transition manager
  const openTransitionManager = () => {
    openTransitionOnboarding();
    sendAnalytics(reportTransition(transitionEventTypes.TRANSITION_MENU_CTA));
  };

  const closeDialogAndCreateDraftEstimate = (
    projectID: UUID,
    milestoneID: UUID,
    estimateType: EstimateType,
    navigate: NavigateFunction
  ) => {
    isEstimateUploadDialogOpen(false);
    if (inPreviewMode || isDisabled) {
      setTooltipIsOpen(true);
      setTimeout(() => setTooltipIsOpen(false), 2000);
      return;
    }
    createMilestoneDraftEstimate(
      projectID,
      milestoneID,
      estimateType,
      EstimateTotalType.TOTAL_TYPE_UNIT,
      (estimateId: UUID) => {
        refetchMilestone();
        const path = generateSharedPath(JoinProjectRoutes.IMPORT_ESTIMATE, {
          projectId: projectID,
          milestoneId: milestoneID,
          estimateId,
        });
        clickLinkKeyHandler(navigate, undefined, path, `${TYPE}=${estimateType}&${MANUAL}=${true}`);
      }
    );
  };

  const formats = (
    <div className="text-type-muted type-body2">
      {`We accept these formats: `}
      <strong>.xlsx</strong>
      {` (DESTINI / Procore / Join's spreadsheet Template), `}
      <strong>.xml</strong>
      {` (WinEst), and `}
      <strong>.zip</strong>
      {` (Sage). `}
    </div>
  );

  const onFileSelected = (file: File | undefined, source: FileSource) => {
    if (!file) return;
    props.onFileSelected(file, source);
  };

  const onHelp = () => openImportHelp(sendAnalytics);

  const addUploadDrag = (
    <div className="w-[780px]">
      <Uploader
        errorTextDescription={errorText}
        footerRight={<UploaderLink endAdornment={<></>} label="Need help?" onClick={onHelp} />}
        loading={loading}
        onCancelUploading={onCancelUploading}
        onFileSelected={onFileSelected}
        supportText={formats as unknown as string}
      >
        <EstimateImportPlatformSVGs estimatePlatform={estimatePlatform} />
      </Uploader>
    </div>
  );

  if (inPreviewMode || isDisabled)
    return (
      <div className={classes.container}>
        <div className="text-center text-type-muted type-body1">{`No ${props.lowerTerm} currently available`}</div>
      </div>
    );

  return (
    <div className={classes.container}>
      <div className={classes.shiftRight}>
        <EstimateToolMenu
          selectedEstimatePlatform={estimatePlatform}
          setEstimatePlatform={setEstimatePlatform}
        />
      </div>
      {addUploadDrag}
      <div className={classes.iconButtons}>
        <CardButton
          buttonText="Create a new blank Estimate"
          dataCy={`button-create-blank-${estimateType}`}
          disabled={isDisabled}
          icon={<Edit />}
          onClick={() => {
            closeDialogAndCreateDraftEstimate(projectId, milestoneId, estimateType, navigate);
          }}
        />
        <CardButton
          buttonText="Copy from Existing Milestone"
          dataCy={`button-transition-milestone-${estimateType}`}
          disabled={isDisabled || !hasMilestonesToTransitionFrom}
          icon={<FileCopy />}
          onClick={() => {
            isEstimateUploadDialogOpen(false);
            openTransitionManager();
          }}
        />
      </div>
      <NormalTooltip
        onClose={() => setTooltipIsOpen(false)}
        onOpen={() => setTooltipIsOpen(true)}
        open={tooltipIsOpen}
        title={unavailableInPreviewCopy}
      >
        <span />
      </NormalTooltip>
    </div>
  );
};

export default withStyles(styles)(EstimateAddUploadDrag);
