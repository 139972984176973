import { useCallback, useState } from 'react';

import {
  Add,
  DeleteOutline,
  FileCopyOutlined,
  KeyboardBackspace,
  MoreVert,
  PaletteOutlined,
  RemoveCircleOutline,
} from '@material-ui/icons';

import { ScenarioShareKey } from '../../../../analytics/analyticsEventProperties';
import { NULL_ID } from '../../../../constants';
import { PermissionResource, ScenarioItemCreationInput } from '../../../../generated/graphql';
import { RouteKeys } from '../../../../routes/paths';
import { useCostMode } from '../../../../utilities/costMode';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { toastScenarioApplied } from '../../../../utilities/toast';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import { useCurrentUser } from '../../../contexts/current-user';
import { KeyboardArrowEnd } from '../../../Icons/KeyboardArrowEnd';
import { Button, IconMenuButton, SortManager } from '../../../scales';
import CustomizationDialog from '../../../shared-widgets/CustomizationDialog/CustomizationDialog';
import useMemoWrapper from '../../../useMemoWrapper';
import ApplyScenarioDialog from '../../ApplyScenarioDialog/ApplyScenarioDialog';
import Card from '../../common/Card';
import CardFooter from '../../common/CardFooter';
import CardHeader from '../../common/CardHeader';
import { DeleteScenarioDialog } from '../../common/DeleteScenarioDialog';
import DropTargetPlaceholder from '../../common/DropTargetPlaceholder';
import { getNewColorForScenario } from '../../common/ScenariosUtils';
import { useApplyScenario } from '../../hooks/useApplyScenario';
import useCanApplyScenarioQuery from '../../hooks/useCanApplyScenarioQuery';
import { useCopyScenario } from '../../hooks/useCopyScenario';
import useCreateScenarioItemMutation from '../../hooks/useCreateScenarioItemMutation';
import useDeleteScenarioItemMutation from '../../hooks/useDeleteScenarioItemMutation';
import useDeleteScenarioMutation from '../../hooks/useDeleteScenarioMutation';
import { useRemoveItemFromScenariosMutation } from '../../hooks/useRemoveItemFromScenariosMutation';
import useSendScenariosAnalytics, {
  ScenariosAnalyticsEvents,
} from '../../hooks/useSendScenariosAnalytics';
import useUpdateScenarioMetadataMutation from '../../hooks/useUpdateScenarioMetadataMutation';
import useUpdateItemOverlaysMutation from '../../Library/hooks/useUpdateItemOverlaysMutation';
import { getScenarioItems } from '../../ScenarioUtils';
import SharedScenarioIcon from '../../Share/SharedScenarioIcon';
import ShareScenarioButton from '../../Share/ShareScenarioButton';
import { ScenarioSandbox, Scenario as ScenarioType } from '../../types';
import ItemsTableEdit from '../ItemsTable/ItemsTableEdit';

import DialogsNewScenarioItem from './DialogsNewScenarioItem';
import DropTargetItems from './DropTargetItems';
import ScenarioCardName from './ScenarioCardName';
import ScenarioCardTableHeader from './ScenarioCardTableHeader';

type Props = {
  index: number;
  milestoneID: UUID;
  milestoneName: string;
  sandbox: ScenarioSandbox;
  scenario: ScenarioType;
  sortManager: SortManager;
  onAddItem: (itemID: UUID) => void;
  onRemove: () => void;
  onReorder: (index: number) => void;
};

enum ScenarioMenuKeys {
  START = 'Move to the start',
  END = 'Move to the end',
  LEFT = 'Move to the left',
  RIGHT = 'Move to the right',
  CUSTOMIZE = 'Customize',
  COPY = 'Copy',
  REMOVE = 'Remove',
  DELETE = 'Delete',
}

const SCENARIO_CARD_IMAGE_WIDTH = 200;
const PlaceholderNoItems = ({ imgSrc }: { imgSrc: string }) => (
  <div className="flex flex-grow flex-col items-center justify-center gap-4 pb-1 type-body1">
    <img alt="Drag Item into Library" src={imgSrc} width={SCENARIO_CARD_IMAGE_WIDTH} />
    <div className="text-type-inactive">No items yet.</div>
    <div className="">Drag an item in from the Library to get started!</div>
  </div>
);

const ScenarioCard = (props: Props) => {
  const scenariosCount = props.sandbox.scenarios.length;

  // Analytics
  const sendScenariosAnalytics = useSendScenariosAnalytics();
  const { milestoneID, milestoneName } = props;
  const projectId = getProjectIdFromUrl();

  // Data
  const { color, name, scenarioID, items, thumbnailAssetID, createdBy } = props.scenario;
  const costMode = useCostMode();
  const scenarioItemsCount = useMemoWrapper(getScenarioItems, items).length;
  const itemsCount = (items ?? []).length - scenarioItemsCount;

  // Permissions
  const { data, loading } = useCanApplyScenarioQuery(scenarioID, projectId, milestoneID);

  const { canEdit } = usePermissions();
  const userID = useCurrentUser().id;
  const didCreateScenario = userID === createdBy;
  const hasApplyRolePermission =
    canEdit(PermissionResource.PUBLISH_ITEMS_SCENARIOS) || didCreateScenario;
  const hasApplyPermission =
    !loading && data?.canApplyScenario.hasApplyPermission && hasApplyRolePermission;

  const hasChanges = !loading && data?.canApplyScenario.hasChanges;

  // Ordering
  const { index } = props;
  const last = scenariosCount - 1;
  const isLast = index === last;
  const isFirst = index === 0;

  // State
  const [customizationOpen, setCustomizationOpen] = useState(false);
  const [applyScenarioDialogOpen, setApplyScenarioDialogOpen] = useState(false);
  const [createScenarioItemDialogOpen, setCreateScenarioItemDialogOpen] = useState(false);
  const [deleteScenarioDialogOpen, setDeleteScenarioDialogOpen] = useState(false);

  // Mutations
  const [removeItem] = useRemoveItemFromScenariosMutation();
  const [deleteScenarioItem] = useDeleteScenarioItemMutation();
  const [copyScenario] = useCopyScenario({ scenarioID, milestoneID: props.milestoneID });
  const [applyScenario] = useApplyScenario({
    scenarioID,
    milestoneID: props.milestoneID,
    costMode,
  });
  const [updateItemOverlays] = useUpdateItemOverlaysMutation({
    scenarioID,
    milestoneID: props.milestoneID,
  });
  const [updateMetadata] = useUpdateScenarioMetadataMutation();
  const [deleteScenario] = useDeleteScenarioMutation();
  const [createScenarioItem] = useCreateScenarioItemMutation({
    scenarioID,
    projectID: projectId,
    milestoneID,
  });

  // Functions
  const { onRemove, onReorder, sandbox } = props;

  const onEditMetadata = useCallback(
    ({ name, description }: { name?: string; description?: string | null }) => {
      updateMetadata({ name, description, scenarioID });
      sendScenariosAnalytics(ScenariosAnalyticsEvents.EDIT_METADATA, {
        scenarioID,
        name,
        description,
      });
    },
    [scenarioID, sendScenariosAnalytics, updateMetadata]
  );

  const onCustomize = useCallback(
    (color?: string, nullableThumbnailID?: UUID | null) => {
      // the dialog uses null to express clearing the thumbnail, but
      // updateMetadata expects to clear thumbnailAssetID if NULL_ID
      const thumbnailAssetID = nullableThumbnailID === null ? NULL_ID : nullableThumbnailID;
      updateMetadata({ color, scenarioID, thumbnailAssetID });
      sendScenariosAnalytics(ScenariosAnalyticsEvents.CUSTOMIZE_COLOR, {
        color,
        thumbnailAssetID,
        scenarioID,
      });
    },
    [scenarioID, sendScenariosAnalytics, updateMetadata]
  );

  const onDelete = useCallback(() => {
    deleteScenario({ scenarioID });
    sendScenariosAnalytics(ScenariosAnalyticsEvents.DELETE_SCENARIO, {
      scenarioID,
      milestoneID,
    });
  }, [deleteScenario, sendScenariosAnalytics, scenarioID, milestoneID]);

  const onRemoveScenario = useCallback(() => {
    onRemove();
    sendScenariosAnalytics(ScenariosAnalyticsEvents.REMOVE_SCENARIO, {
      scenarioID,
      milestoneID,
    });
  }, [onRemove, sendScenariosAnalytics, scenarioID, milestoneID]);

  const onCopy = useCallback(() => {
    const color = getNewColorForScenario(sandbox);
    copyScenario(color);
    sendScenariosAnalytics(ScenariosAnalyticsEvents.COPY_SCENARIO, {
      scenarioID,
      milestoneID,
    });
  }, [copyScenario, sandbox, sendScenariosAnalytics, scenarioID, milestoneID]);

  const onApply = useCallback(() => {
    sendScenariosAnalytics(ScenariosAnalyticsEvents.APPLY_SCENARIO, {
      scenarioID,
      milestoneID,
      itemsCount,
      scenarioItemsCount,
    });
    applyScenario().then(() => toastScenarioApplied(name, milestoneName));
  }, [
    sendScenariosAnalytics,
    scenarioID,
    milestoneID,
    itemsCount,
    scenarioItemsCount,
    applyScenario,
    name,
    milestoneName,
  ]);

  const onButtonClick = useCallback(() => {
    setApplyScenarioDialogOpen(true);
  }, [setApplyScenarioDialogOpen]);

  const onUpdateItems = useCallback(
    (updates: { itemID: string; status: Status }[]) => {
      updateItemOverlays({ itemOverlays: updates });
      sendScenariosAnalytics(ScenariosAnalyticsEvents.UPDATE_ITEMS, {
        itemOverlays: updates,
        scenarioID,
      });
    },
    [scenarioID, sendScenariosAnalytics, updateItemOverlays]
  );

  const onDeleteScenarioItem = useCallback(
    (itemID: UUID) => {
      deleteScenarioItem({ itemID, milestoneID });
      sendScenariosAnalytics(ScenariosAnalyticsEvents.DELETE_SCENARIO_ITEM, {
        itemID,
        scenarioID,
      });
    },
    [deleteScenarioItem, sendScenariosAnalytics, scenarioID, milestoneID]
  );

  const onRemoveItem = useCallback(
    (itemID: UUID) => {
      const scenarioIDs = [scenarioID];
      removeItem({ scenarioIDs, itemID });
      sendScenariosAnalytics(ScenariosAnalyticsEvents.REMOVE_ITEM, {
        itemID,
        scenarioID,
      });
    },
    [removeItem, sendScenariosAnalytics, scenarioID]
  );

  const onNavToItem = useCallback(
    (itemID: UUID) => {
      sendScenariosAnalytics(ScenariosAnalyticsEvents.VIEW_ITEM_DETAILS, {
        itemID,
      });
      window.open(
        generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, { projectId, itemId: itemID }),
        '_blank'
      );
    },
    [sendScenariosAnalytics, projectId]
  );

  const onOpenCreateScenarioItemDialog = useCallback(() => {
    setCreateScenarioItemDialogOpen(true);
    sendScenariosAnalytics(ScenariosAnalyticsEvents.CREATE_SCENARIO_ITEM_CTA, {
      scenarioID,
    });
  }, [setCreateScenarioItemDialogOpen, sendScenariosAnalytics, scenarioID]);

  const onCreateScenarioItem = useCallback(
    (itemInput: ScenarioItemCreationInput) => {
      createScenarioItem({ itemInput });
      sendScenariosAnalytics(ScenariosAnalyticsEvents.CREATE_SCENARIO_ITEM, {
        itemInput,
        scenarioID,
      });
    },
    [createScenarioItem, sendScenariosAnalytics, scenarioID]
  );

  const onCustomizeOpen = useCallback(() => {
    setCustomizationOpen(true);
  }, [setCustomizationOpen]);

  const scenarioShareBadge = (
    <div className="ml-2 mr-auto">
      <SharedScenarioIcon analyticsKey={ScenarioShareKey.SCENARIOS} scenarioID={scenarioID} />
    </div>
  );

  const newScenarioBtn = (
    <Button
      data-cy="new-scenario-item-button"
      fullWidth
      label="Create New Item in Scenario"
      onClick={() => {
        onOpenCreateScenarioItemDialog();
      }}
      startIcon={<Add />}
      type="secondary"
    />
  );

  const scenarioTableHeader = (
    <ScenarioCardTableHeader
      description={props.scenario.description ?? ''}
      onEditMetadata={onEditMetadata}
      thumbnailAssetID={thumbnailAssetID ?? undefined}
    />
  );

  return (
    <>
      <DropTargetItems onItemDrop={props.onAddItem}>
        {({ dropProps, isDropTarget }) => (
          <Card
            {...dropProps}
            color={props.scenario.color ?? undefined}
            id={props.scenario.scenarioID}
            shadow
          >
            <CardHeader>
              <div className="flex items-center justify-between gap-2">
                {scenarioShareBadge}
                <ShareScenarioButton
                  scenarioColor={color ?? undefined}
                  scenarioCreatorID={createdBy}
                  scenarioID={scenarioID}
                  scenarioName={name}
                />
                {createScenarioItemDialogOpen && (
                  <DialogsNewScenarioItem
                    milestoneID={milestoneID}
                    onCloseNoChange={() =>
                      sendScenariosAnalytics(ScenariosAnalyticsEvents.CREATE_SCENARIO_ITEM_CLOSE, {
                        scenarioID,
                        milestoneID,
                      })
                    }
                    onCreateScenarioItem={onCreateScenarioItem}
                    open={createScenarioItemDialogOpen}
                    scenarioProps={{ color: props.scenario.color || '', name: props.scenario.name }}
                    setOpen={setCreateScenarioItemDialogOpen}
                  />
                )}
                <IconMenuButton
                  aria-label="show scenario options"
                  data-cy="scenario-card-menu-button"
                  icon={<MoreVert />}
                  sections={[
                    {
                      'aria-label': 'First Section',
                      entries: [
                        {
                          disabled: isFirst,
                          id: ScenarioMenuKeys.LEFT,
                          label: ScenarioMenuKeys.LEFT,
                          onClick: () => onReorder(index - 1),
                          startAdornment: <KeyboardBackspace />,
                        },
                        {
                          disabled: isLast,
                          id: ScenarioMenuKeys.RIGHT,
                          label: ScenarioMenuKeys.RIGHT,
                          onClick: () => onReorder(index + 1),
                          startAdornment: <KeyboardBackspace className="rotate-180" />,
                        },
                        {
                          disabled: isFirst,
                          id: ScenarioMenuKeys.START,
                          label: ScenarioMenuKeys.START,
                          onClick: () => onReorder(0),
                          startAdornment: <KeyboardArrowEnd />,
                        },
                        {
                          disabled: isLast,
                          id: ScenarioMenuKeys.END,
                          label: ScenarioMenuKeys.END,
                          onClick: () => onReorder(last),
                          startAdornment: <KeyboardArrowEnd className="rotate-180" />,
                        },
                      ],
                    },
                    {
                      'aria-label': 'Second Section',
                      entries: [
                        {
                          id: ScenarioMenuKeys.COPY,
                          label: ScenarioMenuKeys.COPY,
                          onClick: onCopy,
                          startAdornment: <FileCopyOutlined />,
                        },
                        {
                          id: ScenarioMenuKeys.CUSTOMIZE,
                          label: ScenarioMenuKeys.CUSTOMIZE,
                          onClick: onCustomizeOpen,
                          startAdornment: <PaletteOutlined />,
                        },
                        {
                          id: ScenarioMenuKeys.REMOVE,
                          label: ScenarioMenuKeys.REMOVE,
                          onClick: onRemoveScenario,
                          startAdornment: <RemoveCircleOutline />,
                        },
                        {
                          id: ScenarioMenuKeys.DELETE,
                          label: 'Delete',
                          onClick: () => setDeleteScenarioDialogOpen(true),
                          startAdornment: <DeleteOutline />,
                          type: 'destructive',
                        },
                      ],
                    },
                  ]}
                  type="secondary"
                />
              </div>
              <ScenarioCardName name={props.scenario.name} onEditMetadata={onEditMetadata} />
            </CardHeader>
            <div className="relative flex flex-grow flex-col overflow-auto">
              {isDropTarget && (
                <DropTargetPlaceholder isOverlay text="Drop items to add them to your scenario" />
              )}
              <div className="overflow-auto">
                {scenarioTableHeader}
                <ItemsTableEdit
                  items={items}
                  onDeleteScenarioItem={onDeleteScenarioItem}
                  onNavToItem={onNavToItem}
                  onRemoveItem={onRemoveItem}
                  onUpdateItems={onUpdateItems}
                  scenario={props.scenario}
                  sortManager={props.sortManager}
                />
              </div>
              {items.length === 0 && (
                <PlaceholderNoItems
                  imgSrc={
                    sandbox.scenarios.length === 1
                      ? '/img/Scenarios/Scenarios_DragLibraryCard.gif'
                      : '/img/Scenarios/Scenarios_DragLibraryCard.svg'
                  }
                />
              )}
              <div className="flex border-b" />
              <div className="mb-2 ml-2 mr-auto mt-2">{newScenarioBtn}</div>
            </div>
            <CardFooter
              costReports={props.scenario.costReports}
              hasApplyPermission={hasApplyPermission}
              hasChanges={hasChanges}
              onButtonClick={onButtonClick}
            />
          </Card>
        )}
      </DropTargetItems>
      {customizationOpen && (
        <CustomizationDialog
          color={props.scenario.color ?? undefined}
          onClose={() => setCustomizationOpen(false)}
          setColorAndThumbnailID={onCustomize}
          thumbnailAssetID={thumbnailAssetID ?? undefined}
        />
      )}
      {applyScenarioDialogOpen && (
        <ApplyScenarioDialog
          isOpen={applyScenarioDialogOpen}
          milestoneName={props.milestoneName}
          onApply={onApply}
          onClose={() => setApplyScenarioDialogOpen(false)}
          scenario={props.scenario}
          sortManager={props.sortManager}
        />
      )}
      <DeleteScenarioDialog
        isOpen={deleteScenarioDialogOpen}
        milestoneName={props.milestoneName}
        onClose={() => {
          setDeleteScenarioDialogOpen(false);
          sendScenariosAnalytics(ScenariosAnalyticsEvents.DELETE_SCENARIO_CLOSE, {
            scenarioID,
            milestoneID,
          });
        }}
        onDelete={onDelete}
        scenarioName={props.scenario.name}
      />
    </>
  );
};

export default ScenarioCard;
