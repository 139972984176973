import { ComponentProps, FC, KeyboardEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { withStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import {
  LeadEvent,
  LeadKey,
  NewProjectEvent,
  leadEvent,
  newProjectEvent,
} from '../../../analytics/analyticsEventProperties';
import { UserStatus } from '../../../api/gqlEnumsBe';
import {
  PROJECT_LOCATION_CITY,
  SET_PROJECT_LOCATION_PLACEHOLDER,
  UNASSIGNED,
} from '../../../constants';
import { YC_GROUPS } from '../../../features';
import {
  CreateProjectMutationVariables,
  DesignPhaseType,
  ImageDimension,
  LocationDetailsInput,
  ProjectType,
} from '../../../generated/graphql';
import { useCreateProject } from '../../../hooks/ProjectHooks';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { useCompanyProjectTypesQuery } from '../../../hooks/useCompanyProjectTypesQuery';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { useProjectDeliveryTypes } from '../../../hooks/useProjectDeliveryTypesQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { EmptyThumbnail } from '../../AssigneeSelect/AssigneeSelect';
import { useCreateCollaborators } from '../../Collaborators/hooks';
import { useSetProjectLead } from '../../Collaborators/hooks/projectLead';
import UserAvatar from '../../Collaborators/UserAvatar';
import useOrganizationsQuery from '../../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import OrgSelector from '../../CompanyTab/CompanyTabOrganizations/OrgSelector/OrgSelector';
import { filterPublished } from '../../CompanyTab/CompanyTabOrganizations/OrgSelector/utils';
import { useCompanyRoutesData } from '../../CompanyTab/CompanyTabUtils';
import { useCompanyUsersQuery } from '../../CompanyTab/useCompanyUsersQuery';
import { SelectLevelsEntry } from '../../frame/AdvancedFiltersSidebar/FilterGroupLevels';
import { Lightbulb } from '../../Icons/Lightbulb';
import { ProjectIcon } from '../../Icons/ProjectIcon';
import { useDesignPhaseTypes } from '../../Milestone/hooks/useDesignPhaseTypesQuery';
import MilestoneDatePicker from '../../Milestone/MilestoneDatePicker/MilestoneDatePicker';
import MilestoneDesignPhaseSelector from '../../Milestone/MilestoneDesignPhaseSelector/MilestoneDesignPhaseSelector';
import { SpinnerIcon } from '../../Nav/icons';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { useSetProjectOrgs } from '../../Organizations/hooks/hooks';
import ProjectPropSelector, { ProjectProp } from '../../ProjectProperties/ProjectPropSelector';
import useProjectStatusesQuery from '../../ProjectsList/hooks/useProjectStatusesQuery';
import {
  Button,
  Callout,
  DialogContent,
  DialogFlow,
  Select,
  SelectEntry,
  TextInput,
} from '../../scales';
import PlacesAutocompleteWrapper from '../../shared-widgets/PlacesAutocomplete/PlacesAutocompleteWrapper';
import Thumbnail from '../../shared-widgets/Thumbnail/Thumbnail';
import useMemoWrapper from '../../useMemoWrapper';

import styles from './DialogsNewProjectStyles';
import {
  computeClearInactive,
  computeProjectTypes,
  getProjectInputDefault,
  milestoneLabel,
  milestoneTooltip,
} from './DialogsNewProjectUtils';
import ProjectTypeSelector from './ProjectTypeSelector';

type DialogsNewProjectProps = {
  classes: Classes<typeof styles>;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  template?: ProjectTemplate;
};

type DialogFlowStep = ComponentProps<typeof DialogFlow>['steps'][number];

const DialogsNewProject: FC<DialogsNewProjectProps> = ({
  classes,
  dialogOpen = false,
  setDialogOpen,
  template,
}) => {
  const createProject = useCreateProject();
  const [createCollaborators] = useCreateCollaborators();
  const [setProjectOrgs] = useSetProjectOrgs();
  const [setProjectLead] = useSetProjectLead();

  const sendAnalytics = useSendAnalytics();
  const isGroupsFeature = useHasFeature(YC_GROUPS);
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);

  const tp = template?.project;
  const projectInput = getProjectInputDefault(tp, template?.settings);
  const { data } = useCollaboratorsQuery(tp?.id);
  const collaboratorsFromTemplate = useMemo(() => data?.collaborators || [], [data?.collaborators]);
  const [project, setProject] = useState<CreateProjectMutationVariables>(projectInput);
  const [projectID, setProjectID] = useState<string | null>(null);
  const [projectLeadID, setProjectLeadID] = useState<string | undefined>(
    collaboratorsFromTemplate.find((c) => c.isProjectLead === true)?.user.id
  );

  const resetProject = () => setProject(projectInput);
  const {
    location,
    milestone,
    milestoneDate,
    milestoneDesignPhaseID,
    name,
    statusID,
    typeID,
    projectDeliveryTypeID,
  } = project;

  const onCreateProject = () => {
    setSubmitted(true);
    createProject(
      project,
      (res) => {
        setSubmitted(false);
        setProjectID(res?.id);
      },
      () => {
        setSubmitted(false);
        setDialogOpen(false);
        resetProject();
      }
    );
    sendAnalytics(
      newProjectEvent(NewProjectEvent.CREATE_CTA, {
        location: window.location.pathname,
        milestoneDesignPhase: milestoneDesignPhaseID,
        milestoneName: milestone,
        milestoneStartDate: milestoneDate,
        orgNodeIDs: project.orgNodeIDs ?? undefined,
        projectDeliveryMethod: projectDeliveryTypeID ?? undefined,
        projectStatus: statusID,
        projectType: typeID ?? undefined,
      })
    );
  };
  const onKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
    if (evt.key === 'Enter' && name !== '' && milestone && !submitted && project.statusID) {
      setSubmitted(true);
      onCreateProject();
    }
  };
  // Currency

  // Project Statuses
  const projectStatuses = useProjectStatusesQuery().data?.projectStatuses ?? [];
  const statuses = useMemoWrapper(computeClearInactive, projectStatuses);

  // Project Types
  const { companyID } = useCompanyRoutesData();
  const { data: typesDataNew } = useCompanyProjectTypesQuery(false, companyID);
  const projectTypes = typesDataNew?.companyProjectTypes || [];
  const types: ProjectType[] = useMemoWrapper(computeProjectTypes, projectTypes);
  const { data: companyUserData } = useCompanyUsersQuery({
    variables: { companyID },
    skip: !companyID,
  });
  const activeUsers =
    useMemo(
      () =>
        companyUserData?.companyUsers.filter(
          (companyUser) => companyUser?.user?.status !== UserStatus.DEACTIVATED
        ),
      [companyUserData]
    ) || [];

  // Project Delivery Types
  const projectDeliveryTypes = useProjectDeliveryTypes();

  // Design Phase Types
  const designPhaseTypes = useDesignPhaseTypes();

  // Project Leads
  const projectLeadEntries: SelectEntry[] = [];
  activeUsers.forEach((companyUser) => {
    if (companyUser.user) {
      projectLeadEntries.push({
        id: companyUser.user?.id,
        label: companyUser.user?.name,
        startAdornment: <UserAvatar assignee={companyUser.user} />,
      });
    }
  });
  const unassignedEntry: SelectEntry = {
    id: UNASSIGNED,
    label: UNASSIGNED,
    startAdornment: <EmptyThumbnail />,
    isSection: false,
  };
  projectLeadEntries.unshift(unassignedEntry);

  // Organizations
  const organizationsQueryResult = useOrganizationsQuery(companyID);
  const orgs = organizationsQueryResult.data?.organizations;
  const publishedOrgs = useMemoWrapper(filterPublished, orgs);

  const infoIcon = <Info className="text-type-muted" fontSize="inherit" />;

  const overviewStep: DialogFlowStep = {
    renderContent: () => (
      <DialogContent className={classes.content}>
        <div className="flex h-100 flex-col gap-2">
          {template ? (
            <Callout fullWidth>
              <div className="flex items-center gap-2 p-0.5">
                <Thumbnail
                  dimension={ImageDimension._50}
                  padding={0}
                  size={20}
                  thumbnail={template.project.thumbnail}
                />
                <div className="flex items-center type-body1">{`Creating from ${template.name} template.`}</div>
              </div>
            </Callout>
          ) : (
            <div>Let&#39;s start with some basic information about this new project.</div>
          )}
          <div className="pb-2 pt-3 type-heading3">Project Overview</div>
          <TextInput
            autoFocus
            data-cy="project-name-text-input"
            disabled={submitted}
            id="name"
            label="Project Name *"
            onChange={(value) => {
              setProject({ ...project, name: value });
            }}
            onKeyDown={onKeyDown}
            placeholder={tp?.name || 'Set project name'}
            value={name || ''}
          />
          <div className="flex flex-row justify-between gap-2">
            <div className="flex w-1/2 flex-col gap-0.5">
              <div className="type-label">Project Status *</div>
              <ProjectPropSelector
                data-cy="project-status-select"
                editable={!submitted}
                name="status"
                selectedValue={statusID}
                setProjectProp={(p: ProjectProp | null) => {
                  if (p) {
                    setProject({ ...project, statusID: p.id });
                  }
                }}
                values={statuses}
              />
            </div>
            <div className="flex w-1/2 flex-col">
              <ProjectTypeSelector
                editable={!submitted}
                label="Project Type *"
                name="type"
                search
                selectedValue={typeID}
                setProjectProp={(p: ProjectProp | SelectLevelsEntry | null) => {
                  setProject({ ...project, typeID: p?.id });
                }}
                values={types}
              />
            </div>
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="type-label">Project Delivery Method *</div>
            <ProjectPropSelector
              data-cy="project-delivery-select"
              editable={!submitted}
              name="delivery method"
              selectedValue={projectDeliveryTypeID}
              setProjectProp={(p: ProjectProp | null) => {
                setProject({ ...project, projectDeliveryTypeID: p?.id });
              }}
              values={projectDeliveryTypes}
            />
          </div>
          <PlacesAutocompleteWrapper
            defaultValue={location}
            disabled={submitted}
            label={`${PROJECT_LOCATION_CITY} *`}
            onChange={(locationDetails?: LocationDetailsInput) => {
              if (locationDetails)
                setProject({
                  ...project,
                  location: locationDetails.name,
                  lat: locationDetails.lat,
                  lon: locationDetails.lon,
                  locationDetails,
                });
            }}
            placeholder={SET_PROJECT_LOCATION_PLACEHOLDER}
          />
        </div>
      </DialogContent>
    ),
    renderFooterLeft: () => null,
    renderFooterRight: ({ onNext }) => (
      <Button
        data-cy="next-button"
        disabled={!name || !statusID || !typeID || !location || !projectDeliveryTypeID}
        label="Next"
        onClick={onNext}
        type="primary"
      />
    ),
  };

  const milestoneStep: DialogFlowStep = {
    renderContent: () => (
      <DialogContent className={classes.content}>
        <div className="flex h-100 flex-col justify-between ">
          <div className="flex flex-col gap-2">
            {template && (
              <Callout fullWidth>
                <div className="flex items-center gap-1">
                  <ProjectIcon />
                  <div className="inline items-center type-body1">{`Creating from ${template.name} template.`}</div>
                </div>
              </Callout>
            )}
            <div className="pb-2 pt-3 type-heading3">Active Milestone</div>
            <div className="flex flex-row gap-2">
              <div className="flex w-1/2 flex-col gap-0.5">
                <div className="gap-0.5 type-label">
                  {milestoneLabel(!template)}
                  <NormalTooltip
                    title={
                      <>
                        <div>{milestoneTooltip}</div>
                        {template && (
                          <div>
                            Once a project is created you can go to the milestone and change its
                            name.
                          </div>
                        )}
                      </>
                    }
                  >
                    {infoIcon}
                  </NormalTooltip>
                </div>
                <TextInput
                  aria-label="Set active milestone name"
                  data-cy="active-milestone-name-text-input"
                  disabled={!!template || submitted}
                  onChange={(milestone) => {
                    setProject({ ...project, milestone });
                  }}
                  onKeyDown={onKeyDown}
                  placeholder="Set milestone name"
                  value={milestone || ''}
                />
              </div>
              <div className="flex w-1/2 flex-col">
                <MilestoneDatePicker
                  date={milestoneDate}
                  disabled={submitted}
                  onUpdate={(date) => {
                    setProject({ ...project, milestoneDate: date || '' });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-0.5">
              <div className="type-label">Milestone Design Phase *</div>
              <MilestoneDesignPhaseSelector
                disabled={submitted}
                selectedValue={milestoneDesignPhaseID}
                setSelection={(designPhase: DesignPhaseType | undefined) => {
                  if (designPhase) {
                    setProject({ ...project, milestoneDesignPhaseID: designPhase.id });
                  }
                }}
                values={designPhaseTypes}
              />
            </div>
          </div>
          <div className="flex py-2">
            <Callout fullWidth>
              <div className="flex items-center gap-1">
                <Lightbulb />
                <div className="inline type-body3">
                  Tip: In a Join project, you&#39;ll manage and present items, which are potential
                  changes relative to a particular project milestone.
                </div>
              </div>
            </Callout>
          </div>
        </div>
      </DialogContent>
    ),
    renderFooterRight: ({ onNext }) => (
      <Button
        data-cy="create-project-button"
        disabled={
          !name ||
          !milestone ||
          !statusID ||
          !typeID ||
          !location ||
          !milestoneDate ||
          !milestoneDesignPhaseID ||
          !projectDeliveryTypeID
        }
        label="Create Project"
        onClick={() => {
          if (!submitted) {
            onCreateProject();
            sendAnalytics(leadEvent(LeadKey.NEW_PROJECT, LeadEvent.CREATE));
            onNext();
          }
        }}
        type="primary"
      />
    ),
  };

  const categorizeProjectButton = (
    <Button
      disabled={submitted}
      label="Finish"
      onClick={async () => {
        if (projectID !== null) {
          const projectLeadEmail = activeUsers.find((user) => user.user?.id === projectLeadID)?.user
            ?.email;
          if (projectLeadEmail) {
            await createCollaborators(projectID, [
              {
                userEmail: projectLeadEmail,
                allTrades: true,
                roleName: 'Administrator',
                responsibility: '',
                trades: [],
              },
            ]);
          }
          if (projectLeadID && projectLeadID !== UNASSIGNED) {
            await setProjectLead({
              variables: {
                projectID,
                userID: projectLeadID,
              },
            });
            sendAnalytics(leadEvent(LeadKey.NEW_PROJECT, LeadEvent.SET, { userID: projectLeadID }));
          }
          if (project.orgNodeIDs) {
            await setProjectOrgs({
              variables: {
                input: {
                  projectID,
                  nodeIDs: project.orgNodeIDs,
                },
              },
            });
          }
          sendAnalytics(leadEvent(LeadKey.NEW_PROJECT, LeadEvent.FINISH));
          navigate(`/${projectID}/settings/`);
        }
        navigate(`/${projectID}/settings/`);
      }}
      type="primary"
    />
  );

  const categorizeProjectStep: DialogFlowStep = {
    renderContent: () => (
      <DialogContent>
        <div className="flex h-100 flex-col justify-between ">
          <div className="flex flex-col gap-2">
            {submitted ? (
              <div className="flex justify-center p-4">
                <img
                  alt="BuildingNewProject First Stage"
                  src="/img/NewProject/BuildingNewProject_FirstStage.gif"
                  width={360}
                />
              </div>
            ) : (
              <div className="flex justify-center p-4">
                <img
                  alt="BuildingNewProject Completed pStage"
                  src="/img/NewProject/BuildingNewProject_CompleteStage.gif"
                  width={360}
                />
              </div>
            )}
            <div className="mb-1">
              <Select
                entries={projectLeadEntries}
                isSearchable
                label={`${activeUsers[0]?.company?.name} Project Lead`}
                onChange={(userID) => {
                  setProjectLeadID(userID);
                  sendAnalytics(
                    leadEvent(LeadKey.NEW_PROJECT, LeadEvent.OPTIONAL_LEADS, { userID })
                  );
                }}
                value={projectLeadID}
              />
            </div>
            {isGroupsFeature && publishedOrgs && publishedOrgs.length > 0 && (
              <div className="mb-2">
                <OrgSelector
                  isHiddenForNoOrgs
                  label="Organizations"
                  onChange={(orgNodeIDs: string[]) => {
                    setProject({ ...project, orgNodeIDs });
                    sendAnalytics(
                      leadEvent(LeadKey.NEW_PROJECT, LeadEvent.OPTIONAL_ORGS, { orgNodeIDs })
                    );
                  }}
                  orgNodes={tp?.orgNodes}
                  orgs={orgs}
                />
              </div>
            )}
          </div>
          <div className="flex pb-2 pt-4">
            <Callout fullWidth>
              <div className="flex items-center gap-1">
                <Lightbulb />
                <div className="inline type-body3">
                  Tip: Help your colleagues find this project more easily. This is optional and you
                  can always do it later.
                </div>
              </div>
            </Callout>
          </div>
        </div>
      </DialogContent>
    ),
    renderFooterLeft: () => null, // No going back
    renderFooterRight: () => {
      return (
        <div className="flex items-stretch gap-4">
          {submitted ? (
            <div className="flex items-center">
              <SpinnerIcon className="mr-2" />{' '}
              <p className="text-type-inactive">Creating Project {name}</p>
            </div>
          ) : undefined}
          {categorizeProjectButton}
        </div>
      );
    },
  };

  const steps = [overviewStep, milestoneStep, categorizeProjectStep];

  const projectDialogFlow = (
    <>
      <DialogFlow
        isOpen={dialogOpen}
        onClose={(index) => {
          setDialogOpen(false);
          sendAnalytics(
            leadEvent(LeadKey.NEW_PROJECT, LeadEvent.CLOSE, {
              step: index + 1,
            })
          );
        }}
        onComplete={() => setDialogOpen(false)}
        size="md"
        steps={steps}
        title="New Project"
      />
      <br />
    </>
  );

  return <div>{projectDialogFlow}</div>;
};

export default withStyles(styles)(DialogsNewProject);
