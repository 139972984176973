import { FC } from 'react';

import { ErrorOutline } from '@material-ui/icons';

import { formatPercent } from '../../../utilities/string';
import ErrorTooltip from '../ErrorTooltip';

interface PercentRendererProps {
  value: number;
  error: string;
  disabled?: boolean;
  scale?: number;
}

const PercentRenderer: FC<PercentRendererProps> = ({ value, error, disabled, scale = 0 }) => {
  const disabledMarkupStyle = disabled ? `join-grid-line-disabled` : '';
  const errorMarkupStyle = error ? 'join-grid-cell-error' : '';
  return (
    <div
      className={`${errorMarkupStyle} join-grid-renderer join-grid-string ${disabledMarkupStyle}`}
    >
      <div className="join-grid-trim-text push-right">{formatPercent(value, scale)}</div>
      {error ? (
        <div style={{ paddingLeft: 4 }}>
          <ErrorTooltip title={error}>
            <ErrorOutline />
          </ErrorTooltip>
        </div>
      ) : null}
    </div>
  );
};

export default PercentRenderer;
