import { Select, SelectEntry } from '../scales';

export type ProjectProp = {
  id: string;
  name: string;
  description?: string;
  abbreviation?: string;
};

type Props = {
  'data-cy'?: string;
  editable?: boolean;
  name: string;
  search?: boolean;
  selectedValue?: string | null;
  setProjectProp: (p: ProjectProp | null) => void;
  values: ProjectProp[];
};

const getEntry = (id: string, label: string, description?: string): SelectEntry => {
  return {
    id,
    label,
    description,
  };
};

/** @deprecated ProjectPropSelector has very little value as a component as its
 * only used for Project Delivery Method and Project Status. Instead of using
 * this component we should just use Select directly, or create dragon-scales
 * components for ProjectDeliveryMethodSelect and ProjectStatusSelect */
export default function ProjectPropSelector(props: Props) {
  const { editable = false, name, search = false, selectedValue, setProjectProp, values } = props;
  const entries = values.map((prop) => {
    const { id, name: entryName, description, abbreviation } = prop;
    const modifiedName = abbreviation ? `${entryName} (${abbreviation})` : entryName;
    return getEntry(id, modifiedName, description);
  });

  return (
    <Select
      aria-label={`Select a project ${name}`}
      data-cy={props['data-cy']}
      entries={entries}
      isDisabled={!editable}
      isSearchable={search}
      onChange={(newID: UUID | null) => {
        const newProp = values.find((p: ProjectProp) => p.id === newID) || null;
        setProjectProp(newProp);
      }}
      placeholder={`Set project ${name}`}
      value={selectedValue}
    />
  );
}
