import { MouseEventHandler, ReactNode } from 'react';

import { ButtonBase } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './ButtonStyles';

type Props = {
  children?: ReactNode;
  classes: Classes<typeof styles>;
  className?: string;
  dataCy?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  type: 'primary' | 'secondary' | 'selected' | 'text' | 'link';
};

const Button = (props: Props) => {
  const classes = [props.classes.container];
  if (props.type === 'primary') {
    classes.push(props.classes.type_primary);
  } else if (props.type === 'secondary') {
    classes.push(props.classes.type_secondary);
  } else if (props.type === 'selected') {
    classes.push(props.classes.type_selected);
  } else if (props.type === 'text') {
    classes.push(props.classes.type_text);
  } else if (props.type === 'link') {
    classes.push(props.classes.type_link);
  }
  if (props.fullWidth) classes.push(props.classes.fullWidth);
  if (props.icon && props.children) classes.push(props.classes.iconAndText);
  if (props.icon && !props.children) classes.push(props.classes.iconOnly);
  if (props.className) classes.push(props.className);

  return (
    <ButtonBase
      className={classes.join(' ')}
      data-cy={props.dataCy}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.icon}
      {props.children}
    </ButtonBase>
  );
};

export default withStyles(styles)(Button);
