import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';
import { ReactNode } from 'react';

type Data = { date: Date; value: number };

export default function TimelineSvg(props: {
  data: Data;
  icon: ReactNode;
  iconWidth: number;
  iconHeight: number;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
}) {
  const positionX = props.x(props.data.date);
  const positionY = props.y(props.data.value);

  return (
    <g transform={`translate(${positionX}, ${positionY})`}>
      <g transform={`translate(${-props.iconWidth / 2}, ${-props.iconHeight / 2})`}>{props.icon}</g>
    </g>
  );
}
