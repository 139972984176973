import { FC } from 'react';

import { OPTION } from '../../../constants';
import { PermissionResource } from '../../../generated/graphql';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { getProjectIdFromUrl } from '../../../utilities/url';
import useUpdateStatus from '../ItemsHooks';
import { isPublicVisibility } from '../ItemsUtils';

import ItemsStatusHoverSelectorCompact from './ItemsStatusHoverSelectorCompact';
import ItemsStatusHoverSelectorCozy from './ItemsStatusHoverSelectorCozy';
import { ItemStatusVariant, ItemStatusVariants } from './ItemsStatusHoverSelectorStyles';

export { ItemStatusVariants };

type ItemsStatusHoverSelectorDataProps = {
  disabled: boolean;
  isCollapseHeader: boolean;
  isFilteredMilestone: boolean;
  isHighlightedItem?: boolean;
  item: ItemLike | ItemsListItem;
  variant?: ItemStatusVariant;
};

const ItemsStatusHoverSelectorData: FC<ItemsStatusHoverSelectorDataProps> = ({
  disabled,
  isCollapseHeader,
  isFilteredMilestone,
  isHighlightedItem = false,
  item,
  variant = ItemStatusVariants.COZY,
}) => {
  const projectId = getProjectIdFromUrl();
  const { status, itemType, availableStates, visibility, categories } = item;
  const parentID = 'parentID' in item ? item.parentID : undefined;
  const isOption = itemType === OPTION || !!parentID;
  const isPublicItem = isPublicVisibility(visibility);

  const { canEdit } = usePermissions({ trades: categories });
  const canEditItemStatus = canEdit(PermissionResource.ITEM_STATUS);

  // UPDATE HOOK
  const updateStatus = useUpdateStatus();
  const setStatusUpdate = (newStatus: Status) => {
    if (status !== newStatus) updateStatus(projectId, item, newStatus);
  };

  if (variant === ItemStatusVariants.COMPACT) {
    return (
      <ItemsStatusHoverSelectorCompact
        availableStates={availableStates}
        disabled={disabled}
        hasItemStatusEditPermission={canEditItemStatus}
        isCollapseHeader={isCollapseHeader}
        isFilteredMilestone={isFilteredMilestone}
        isHighlightedItem={isHighlightedItem}
        isOption={isOption}
        isPublicItem={isPublicItem}
        setStatusUpdate={setStatusUpdate}
        status={status}
        variant={variant}
      />
    );
  }
  return (
    <ItemsStatusHoverSelectorCozy
      availableStates={availableStates}
      disabled={disabled}
      hasItemStatusEditPermission={canEditItemStatus}
      isCollapseHeader={isCollapseHeader}
      isFilteredMilestone={isFilteredMilestone}
      isHighlightedItem={isHighlightedItem}
      isOption={isOption}
      isPublicItem={isPublicItem}
      setStatusUpdate={setStatusUpdate}
      status={status}
      variant={variant}
    />
  );
};

export default ItemsStatusHoverSelectorData;
