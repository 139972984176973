import { FC } from 'react';

import { Status } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';

import ItemsIconsMap from './ItemsIconsMap';
import ItemsIconsStyles from './ItemsIconsStyles';

type Variant = keyof ReturnType<typeof ItemsIconsStyles>;

type ItemsIconsStatusProps = {
  classes: Classes<typeof ItemsIconsStyles>;
  notChosen?: boolean;
  status?: string;
  variant?: Variant;
};

const ItemsIconsStatus: FC<ItemsIconsStatusProps> = ({
  classes,
  notChosen,
  status = Status.PENDING,
  variant,
}) => (
  <div className={classes.statusContainer} data-cy="statusIcon-statusIconContainer">
    <ItemsIconsMap notChosen={notChosen} status={status} variant={variant} />
  </div>
);

/**
 * @deprecated use ItemStatusIcon.tsx for item status icon, or import the individual icon yourself
 * Note: ItemStatusIcon.tsx uses lg=40px, while ItemsIcons.tsx uses large=32px and xLarge=40px
 * */
export default withStyles(ItemsIconsStyles)(ItemsIconsStatus);
