import * as React from 'react';

import { Dialog, DialogActions, Divider, IconButton, Typography } from '@material-ui/core';
import { Close, DeleteOutline } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useDeleteProjectTemplateMutation } from '../../CompanyTab/Standards/ProjectTemplates/hooks';
import { Button } from '../../scales';
import DialogsStyles from '../DialogsStyles';

type DialogsNewTemplateProps = {
  classes: Classes<typeof DialogsStyles>;
  open: boolean;
  onClose: () => void;
  projectTemplate: ProjectTemplate;
};

const DialogsNewTemplate: React.FC<DialogsNewTemplateProps> = ({
  classes,
  open,
  onClose,
  projectTemplate,
}) => {
  const sendAnalytics = useSendAnalytics();
  const deleteProjectTemplate = useDeleteProjectTemplateMutation(projectTemplate.id);
  const header = (
    <div className={`${classes.titleContainer}`}>
      <Typography variant="title">Delete {projectTemplate.name}?</Typography>
      <IconButton
        onClick={() => {
          onClose();
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_DELETE_CLOSE)
          );
        }}
        title="Close dialog"
      >
        <Close />
      </IconButton>
    </div>
  );

  const content = (
    <div className={`${classes.horizontalContainer} ${classes.content} ${classes.paddingBottom}`}>
      <Typography>
        Are you sure? Deleting this template is a permanent action and cannot be undone. Once
        deleted, this template will no longer be available.
      </Typography>
    </div>
  );

  const footer = (
    <DialogActions className={classes.action}>
      <Button
        label="Cancel"
        onClick={() => {
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_DELETE_CANCEL)
          );
          onClose();
        }}
        type="secondary"
      />
      <Button
        label="Delete"
        onClick={() => {
          sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_DELETE));
          deleteProjectTemplate();
          onClose();
        }}
        startIcon={<DeleteOutline />}
        type="destructive"
      />
    </DialogActions>
  );

  return (
    <Dialog
      classes={{
        paper: `${classes.dialogPaper} ${classes.dialogPaperSmall}`,
      }}
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      {header}
      <Divider />
      {content}
      {footer}
    </Dialog>
  );
};

export default withStyles(DialogsStyles)(DialogsNewTemplate);
