import React from 'react';

const ContingenciesPieToolTip = () => {
  const contingenciesFillerData = [
    {
      label: 'Contingencies',
      percentage: 10,
      count: '$130K',
      color: 'var(--colors-theme-bauhaus-marcelbreuer)',
    },
    {
      label: 'Allowances',
      percentage: 1,
      count: '$13K',
      color: 'var(--colors-theme-bauhaus-laszlo)',
    },
  ];

  return (
    <div className="flex flex-col">
      {/* Header */}
      <div className="mb-2 flex justify-between ">
        <div className="type-heading3">Estimate</div>
        <div className="type-heading3">$1.3M</div>
      </div>
      {/* Table section */}
      <div className="grid grid-cols-[160px_40px_40px] gap-2 text-left">
        {contingenciesFillerData.map((item) => (
          <React.Fragment key={item.label}>
            <div className="flex items-center type-body2">
              <div className="mr-2 h-3 w-3" style={{ backgroundColor: item.color }} />
              <div className="w-full">{item.label}</div>
            </div>
            <div className="text-right type-body2">{item.percentage}%</div>
            <div className="text-right type-body2">{item.count}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ContingenciesPieToolTip;
