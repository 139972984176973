import { useContext, useState } from 'react';

import { TermKey } from '../../../../api/gqlEnums';
import { VIEW_ONLY } from '../../../../constants';
import { Permission, PermissionLevel } from '../../../../generated/graphql';
import { capitalizeString } from '../../../../utilities/string';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import { Checkbox } from '../../../scales';
import { filterMarkupModePermissions } from '../utils';

import { getMarkupModeDescription, getMarkupModePermissionLabel } from './permissions';

type Props = {
  canViewCosts: boolean;
  onChange: (id: UUID, level: PermissionLevel) => void;
  permissions: Permission[];
};

export default function MarkupModePermissionTableEdit(props: Props) {
  const t = useContext(ProjectTermStore);

  const markupsTerm = t.lowerCase(TermKey.MARKUP);

  const markupModePermissions = filterMarkupModePermissions(props.permissions);

  const savedMarkupModePermissions = markupModePermissions.filter((p) => p.level === VIEW_ONLY);

  const [selectedMarkupModePermissions, setSelectedMarkupModePermissions] = useState([
    ...savedMarkupModePermissions.map((p) => p.id),
  ]);

  const onChangeCostPermissions = (permissionID: string, level: PermissionLevel) => {
    // keeps track of unpersisted changes in order to prevent disabling all cost modes
    const newEnabledPermissions = selectedMarkupModePermissions.filter((id) => id !== permissionID);
    if (level && level === VIEW_ONLY) newEnabledPermissions.push(permissionID);
    setSelectedMarkupModePermissions(newEnabledPermissions);
    props.onChange(permissionID, level);
  };

  return (
    <div className="grid grid-cols-12 border-y">
      <div className="col-span-2 content-center border-x px-6 py-2 type-body1">
        Markups display options
      </div>
      {markupModePermissions.map((p, i) => (
        <div
          key={p.resource}
          className={`
              ${i === 0 ? 'col-span-4' : 'col-span-3'} content-center border-r px-4 py-2`}
        >
          <Checkbox
            isDisabled={
              !props.canViewCosts ||
              (selectedMarkupModePermissions.length === 1 &&
                selectedMarkupModePermissions.some((id) => id === p.id))
            }
            isSelected={p.level === PermissionLevel.VIEW_ONLY}
            onChange={(isSelected) =>
              onChangeCostPermissions(
                p.id,
                isSelected ? PermissionLevel.VIEW_ONLY : PermissionLevel.NONE
              )
            }
          >
            <div className="type-heading3">{getMarkupModePermissionLabel(p.resource, t)}</div>
            <div className="whitespace-normal type-body2">
              {getMarkupModeDescription(p.resource, capitalizeString(markupsTerm))}
            </div>
          </Checkbox>
        </div>
      ))}
    </div>
  );
}
