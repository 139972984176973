import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    root: {
      color: theme.palette.primaryGrey,
    },
    boldBlue: {
      color: theme.palette.middleBlue,
      fontWeight: 500,
      fontSize: '1em',
    },
    paper: {
      borderRadius: '4px',
    },
    content: {
      padding: 24,
      paddingTop: 12,
      '@media screen and (min-height: 600px)': {
        overflowY: 'visible',
      },
    },
    columns: {
      display: 'grid',
      gap: '10px',
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },
    contentRoot: {
      flexGrow: 1,
      padding: 8,
    },
    close: {
      margin: 8,
      width: 32,
      height: 32,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 12,
    },
    marginBottom: {
      marginBottom: 8,
    },
    inputTitle: {
      fontWeight: 300,
      display: 'flex',
      alignItems: 'center',
    },
    textFieldStyles: {
      height: 36,
    },
    title: {
      padding: '12px 12px 8px 12px',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    milestoneDialogContent: {
      overflowY: 'visible',
    },
    tooltipTextField: {
      '& label': {
        zIndex: 2,
        width: '130%',
        display: 'flex',
        alignItems: 'center',
      },
    },
    datePickerCaption: {
      flexBasis: '25%',
      flexShrink: 2,
      '& span': {
        fontWeight: 300,
      },
    },
    dateWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dateInput: {
      width: '48%',
      alignContent: 'stretch',
      height: '72px', // for error message to display with shifting other elements
    },
    footerCentered: {
      display: 'flex',
      flexGrow: 1,
      gap: '10px',
      justifyContent: 'center',
    },
    icon: {
      color: theme.palette.disabledGrey,
      marginTop: -1,
      marginBottom: -3,
    },
    iconHeight: {
      height: 20,
      padding: '0 0 0 3px',
    },
    iconHeightCurrency: {
      height: 15,
    },
  });
