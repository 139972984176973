import { useCallback, useEffect, useState } from 'react';

import JoinDialog from '../JoinDialog/JoinDialog';
import { ActionButton } from '../JoinDialog/JoinDialogUtils';

import DialogsForecastingReportContent from './DialogsForecastingReportContent';

const DialogForecastingReport = (props: {
  headerText: string;
  onClose: () => void;
  onSubmit: (reportInput: ForecastingReportInput) => void;
  open: boolean;
  initialReportInput: ForecastingReportInput;
}) => {
  const [reportInput, setReportInput] = useState(props.initialReportInput);
  useEffect(() => {
    setReportInput(props.initialReportInput);
  }, [props.initialReportInput]);
  const onSubmitReportInput = useCallback(() => {
    props.onSubmit(reportInput);
  }, [props, reportInput]);

  const contentComponent = (
    <DialogsForecastingReportContent
      onSubmit={onSubmitReportInput}
      reportInput={reportInput}
      setReportInput={setReportInput}
    />
  );
  const actionButtons: ActionButton[] = [
    {
      color: 'primary',
      isLeftAligned: true,
      onClick: () => {
        props.onClose();
      },
      text: 'Cancel',
      variant: 'outlined',
    },
    {
      color: 'primary',
      dataCy: 'confirm-dialog--ok-button', // same dataCy as DialogsReportsData
      disabled: !reportInput.name,
      onClick: onSubmitReportInput,
      text: 'Confirm',
      variant: 'contained',
    },
  ];

  return (
    <JoinDialog
      dialog={{
        actionButtons,
        contentComponent,
        headerText: props.headerText,
        onBack: () => props.onClose(),
        onClose: () => props.onClose(),
        onNext: undefined,
        open: props.open,
      }}
      dynamicHeight
      hasDivider
    />
  );
};
export default DialogForecastingReport;
