import {
  CostReportType,
  FieldType,
  ImportEstimateErrorType,
  ScheduleImpactFilter,
  ScheduleImpactType,
  TimelineActivityType,
  UserStatus,
} from '../api/gqlEnumsBe';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  CostReportType: { input: CostReportType; output: CostReportType };
  ExitCode: { input: any; output: any };
  FeatureFlag: { input: FeatureFlag; output: FeatureFlag };
  FieldType: { input: FieldType; output: FieldType };
  ImportEstimateErrorType: { input: ImportEstimateErrorType; output: ImportEstimateErrorType };
  ModuleEntitlementFlag: { input: ModuleEntitlementFlag; output: ModuleEntitlementFlag };
  Numeric: { input: Numeric; output: Numeric };
  Ordering: { input: Ordering; output: Ordering };
  PaginationByOffsetIDType: { input: PaginationByOffsetID; output: PaginationByOffsetID };
  ProjectCompsSetInputType: { input: ProjectCompsSetInput; output: ProjectCompsSetInput };
  ScheduleImpactFilter: { input: ScheduleImpactFilter; output: ScheduleImpactFilter };
  ScheduleImpactType: { input: ScheduleImpactType; output: ScheduleImpactType };
  Size: { input: Size; output: Size };
  Time: { input: Time; output: Time };
  TimelineActivityType: { input: TimelineActivityType; output: TimelineActivityType };
  USCents: { input: USCents; output: USCents };
  UUID: { input: UUID; output: UUID };
  UserStatus: { input: UserStatus; output: UserStatus };
};

export enum AccessLevel {
  DEFAULT = 'DEFAULT',
  NONE = 'NONE',
  OWNER = 'OWNER',
}

export type Actions = {
  __typename?: 'Actions';
  add: Scalars['Boolean']['output'];
  delete: Scalars['Boolean']['output'];
  edit: Scalars['Boolean']['output'];
  view: Scalars['Boolean']['output'];
};

export type ActiveUser = {
  __typename?: 'ActiveUser';
  commentsAdded: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  itemsCreated: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  picture: Scalars['String']['output'];
  pictureAsset?: Maybe<Asset>;
};

export type AddDeductCost = {
  __typename?: 'AddDeductCost';
  adds: Scalars['USCents']['output'];
  deducts: Scalars['USCents']['output'];
};

export type AddOrgLevelInput = {
  organizationID: Scalars['UUID']['input'];
};

export type Aggregation = {
  __typename?: 'Aggregation';
  name: Scalars['String']['output'];
  values: Array<AggregationValue>;
};

export type AggregationValue = {
  __typename?: 'AggregationValue';
  count: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type AnalyticsProperties = {
  __typename?: 'AnalyticsProperties';
  companyProperties: CompanyAnalyticsProperties;
  groupProperties: GroupAnalyticsProperties;
  permissionsProperties?: Maybe<PermissionsAnalyticsProperties>;
  projectProperties?: Maybe<ProjectAnalyticsProperties>;
  userProperties: UserAnalyticsProperties;
};

export type Asset = {
  __typename?: 'Asset';
  derivations: Array<Derivation>;
  id: Scalars['UUID']['output'];
  lastUpdated: Scalars['Time']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  provider: Scalars['Int']['output'];
  providerMetadata: Scalars['String']['output'];
  size: Scalars['Size']['output'];
  thumbnailURL?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uploadedBy?: Maybe<User>;
};

export type AssetInput = {
  AssetID: Scalars['UUID']['input'];
};

export type AssignItem = {
  __typename?: 'AssignItem';
  item: ItemLink;
  project: Project;
  triggeringUser: User;
};

export type AssignProjectLead = {
  __typename?: 'AssignProjectLead';
  project: Project;
  triggeringUser: User;
};

export type Attachment = Asset | RemoteAsset;

export type AutodeskPotentialChangeOrder = ItemIntegrationObject & {
  __typename?: 'AutodeskPotentialChangeOrder';
  cost: Scalars['USCents']['output'];
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  eventType: Scalars['String']['output'];
  integrationObjectID: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type AverageComp = {
  __typename?: 'AverageComp';
  input: AverageCompInputReturn;
  isHidden: Scalars['Boolean']['output'];
  isResettable: Scalars['Boolean']['output'];
  projectCompsCostTable: ProjectCompsCostTable;
};

export type AverageCompInput = {
  categoryLineInputs?: InputMaybe<Array<CategoryLineInput>>;
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  lon?: InputMaybe<Scalars['Float']['input']>;
  markupLineInputs?: InputMaybe<Array<MarkupLineInput>>;
  metrics?: InputMaybe<Array<MetricsInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  thumbnailAssetID?: InputMaybe<Scalars['UUID']['input']>;
};

export type AverageCompInputReturn = {
  __typename?: 'AverageCompInputReturn';
  categoryLineInputs: Array<CategoryLineInputReturn>;
  color?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  lon?: Maybe<Scalars['Float']['output']>;
  markupLineInputs: Array<MarkupLineInputReturn>;
  metrics: Array<MetricsInputReturn>;
  name?: Maybe<Scalars['String']['output']>;
  thumbnailAssetID?: Maybe<Scalars['UUID']['output']>;
};

export type Banner = {
  __typename?: 'Banner';
  asset: Asset;
  bannerName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
};

export type Bookmark = {
  __typename?: 'Bookmark';
  id: Scalars['UUID']['output'];
  kind: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type BookmarkInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  item: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type Bucket = {
  __typename?: 'Bucket';
  date?: Maybe<Scalars['Time']['output']>;
  id: Scalars['UUID']['output'];
  itemsCount: Scalars['Int']['output'];
  milestone?: Maybe<Milestone>;
  name: Scalars['String']['output'];
};

export type BucketInput = {
  date?: InputMaybe<Scalars['Time']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  indexInMilestone?: InputMaybe<Scalars['Int']['input']>;
  milestone?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BulkItemsUpdateInput = {
  addCategories?: InputMaybe<Array<InputMaybe<CategoryInput>>>;
  assigneeEmail?: InputMaybe<Scalars['String']['input']>;
  clearAssigneeEmail?: InputMaybe<Scalars['Boolean']['input']>;
  clearCategories?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  clearDueDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearMeetingID?: InputMaybe<Scalars['Boolean']['input']>;
  dueDate?: InputMaybe<Scalars['Time']['input']>;
  itemIDs: Array<Scalars['UUID']['input']>;
  meetingID?: InputMaybe<Scalars['UUID']['input']>;
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<Status>;
};

export type BulkItemsUpdateOutput = {
  __typename?: 'BulkItemsUpdateOutput';
  itemCount: Scalars['Int']['output'];
  optionCount: Scalars['Int']['output'];
};

export type CanApplyScenario = {
  __typename?: 'CanApplyScenario';
  hasApplyPermission: Scalars['Boolean']['output'];
  hasChanges: Scalars['Boolean']['output'];
};

export type Categorization = {
  __typename?: 'Categorization';
  builtin: Scalars['Boolean']['output'];
  categoriesCount?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Array<Category>>;
  createdFrom?: Maybe<Categorization>;
  deprecated: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  draft: Scalars['Boolean']['output'];
  exampleCategories?: Maybe<Array<Category>>;
  id: Scalars['UUID']['output'];
  importMetadata?: Maybe<CategorizationImportMetadata>;
  levels: Scalars['Int']['output'];
  modifiedAt?: Maybe<Scalars['Time']['output']>;
  modifiedBy?: Maybe<User>;
  name: Scalars['String']['output'];
};

export type CategorizationCategoryCount = {
  __typename?: 'CategorizationCategoryCount';
  categorizationID: Scalars['UUID']['output'];
  count: Scalars['Int']['output'];
};

export type CategorizationContent = {
  __typename?: 'CategorizationContent';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type CategorizationImportMetadata = {
  __typename?: 'CategorizationImportMetadata';
  columnLevel1?: Maybe<Scalars['String']['output']>;
  columnLevel2?: Maybe<Scalars['String']['output']>;
  columnLevel3?: Maybe<Scalars['String']['output']>;
  columnLevel4?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CategorizationImportMetadataInput = {
  columnLevel1?: InputMaybe<Scalars['String']['input']>;
  columnLevel2?: InputMaybe<Scalars['String']['input']>;
  columnLevel3?: InputMaybe<Scalars['String']['input']>;
  columnLevel4?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CategorizationInput = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type CategorizationLink = {
  __typename?: 'CategorizationLink';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type CategorizationMapping = {
  newCategorizationID: Scalars['UUID']['input'];
  oldCategorizationID: Scalars['UUID']['input'];
};

export type CategorizationMatches = {
  __typename?: 'CategorizationMatches';
  categorizationID: Scalars['UUID']['output'];
  matchingCategories: Scalars['Int']['output'];
};

export enum CategorizationTemplate {
  BLANK_CATEGORIZATION = 'BLANK_CATEGORIZATION',
  MASTERFORMAT = 'MASTERFORMAT',
  UNIFORMAT = 'UNIFORMAT',
}

export type CategorizedItemCost = {
  __typename?: 'CategorizedItemCost';
  canViewCosts: Scalars['Boolean']['output'];
  categories: Array<Category>;
  directCostRange: Cost;
  id: Scalars['UUID']['output'];
  itemContributionLines?: Maybe<Array<Maybe<ItemContributionLines>>>;
  milestoneId: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  parentId?: Maybe<Scalars['UUID']['output']>;
  range: Cost;
  status?: Maybe<Status>;
};

export type Category = {
  __typename?: 'Category';
  categorization?: Maybe<Categorization>;
  error?: Maybe<Scalars['String']['output']>;
  hasSubCategories: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  level: Scalars['Int']['output'];
  levels: Array<Category>;
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  parentID?: Maybe<Scalars['UUID']['output']>;
};

export type CategoryCell = {
  __typename?: 'CategoryCell';
  category?: Maybe<Category>;
  search: Scalars['String']['output'];
};

export type CategoryChange = {
  __typename?: 'CategoryChange';
  newCategory?: Maybe<Category>;
  oldCategory?: Maybe<Category>;
};

export type CategoryCombination = {
  __typename?: 'CategoryCombination';
  categories: Array<Category>;
};

export type CategoryContent = {
  __typename?: 'CategoryContent';
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
};

export type CategoryContentInput = {
  name: Scalars['String']['input'];
  number: Scalars['String']['input'];
};

export type CategoryErrorIndicies = {
  __typename?: 'CategoryErrorIndicies';
  categoryNumber: Scalars['String']['output'];
  indicies: Array<Scalars['Int']['output']>;
};

export type CategoryInput = {
  categorizationID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  number?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryInputReturn = {
  __typename?: 'CategoryInputReturn';
  categorizationID: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
};

export type CategoryLineInput = {
  category: CategoryContentInput;
  cellType: ProjectCompsCostTableCellType;
  description?: InputMaybe<Scalars['String']['input']>;
  editedValue: Scalars['USCents']['input'];
  unitID?: InputMaybe<Scalars['UUID']['input']>;
};

export type CategoryLineInputReturn = {
  __typename?: 'CategoryLineInputReturn';
  category: Category;
  cellType: ProjectCompsCostTableCellType;
  description?: Maybe<Scalars['String']['output']>;
  editedValue: Scalars['USCents']['output'];
  unitID?: Maybe<Scalars['UUID']['output']>;
};

export type CategoryLink = {
  __typename?: 'CategoryLink';
  categorization: CategorizationLink;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
};

export type CategoryMapInput = {
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
  level: Scalars['Int']['input'];
  subcategories: Array<CategoryMapInput>;
};

export type CategoryMapResults = {
  __typename?: 'CategoryMapResults';
  categoryMap: Scalars['String']['output'];
  errors: Scalars['String']['output'];
  metadata?: Maybe<CategorizationImportMetadata>;
};

export type CategoryReplacement = {
  new: Scalars['String']['input'];
  original: Scalars['String']['input'];
};

export type CategorySearch = {
  categorizationID: Scalars['UUID']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  term: Scalars['String']['input'];
};

export type CategoryUpdates = {
  categoryReplacements: Array<CategoryReplacement>;
  newCategories: Array<Scalars['String']['input']>;
};

export type Cell = {
  __typename?: 'Cell';
  error?: Maybe<Scalars['String']['output']>;
  estimateId: Scalars['UUID']['output'];
  field: Scalars['UUID']['output'];
  line: Scalars['UUID']['output'];
  value?: Maybe<CellValue>;
};

export type CellInput = {
  field: Scalars['UUID']['input'];
  line: Scalars['UUID']['input'];
  value: Scalars['String']['input'];
};

export type CellUpdateResult = {
  __typename?: 'CellUpdateResult';
  cells: Array<Cell>;
  estimateUpdateResult?: Maybe<EstimateUpdateResult>;
};

export type CellValue = CategoryCell | RegularCell | SourceCell;

export type CityCostIndex = {
  __typename?: 'CityCostIndex';
  index: Scalars['Float']['output'];
  lat: Scalars['Float']['output'];
  lon: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type CityCostIndicesResponse = {
  __typename?: 'CityCostIndicesResponse';
  cityCostIndices: Array<CityCostIndex>;
  total: Scalars['Int']['output'];
};

export type Collaborator = {
  __typename?: 'Collaborator';
  allTrades: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  inviter?: Maybe<User>;
  isProjectLead: Scalars['Boolean']['output'];
  responsibility?: Maybe<Scalars['String']['output']>;
  role: Role;
  trades: Array<Category>;
  user: User;
};

export type CollaboratorInput = {
  allTrades: Scalars['Boolean']['input'];
  responsibility?: InputMaybe<Scalars['String']['input']>;
  roleName: Scalars['String']['input'];
  trades?: InputMaybe<Array<CategoryInput>>;
  userEmail: Scalars['String']['input'];
};

export type CollaboratorRoleInput = {
  collaboratorID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
};

export type Comment = {
  __typename?: 'Comment';
  author: User;
  created: Scalars['Time']['output'];
  id: Scalars['UUID']['output'];
  text: MentionableText;
};

export enum CommentNotificationSetting {
  ALL = 'All',
  NONE = 'None',
  RELEVANT = 'Relevant',
}

export type Company = {
  __typename?: 'Company';
  asset?: Maybe<Asset>;
  assetID?: Maybe<Scalars['UUID']['output']>;
  deactivated: Scalars['Boolean']['output'];
  domain?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type CompanyAnalyticsProperties = {
  __typename?: 'CompanyAnalyticsProperties';
  companyDomain?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['UUID']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyType?: Maybe<Scalars['String']['output']>;
  isIndependent: Scalars['Boolean']['output'];
};

export type CompanyCategorization = {
  __typename?: 'CompanyCategorization';
  categorization: Categorization;
  companyID: Scalars['UUID']['output'];
  disabled?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyCollaborator = {
  __typename?: 'CompanyCollaborator';
  companyUser: CompanyUser;
};

export type CompanyCollaboratorFilter = {
  statuses?: InputMaybe<Array<Scalars['UserStatus']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyEmployeeInput = {
  companyRoleName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectIDs: Array<Scalars['UUID']['input']>;
  projectRoleNames: Array<Scalars['String']['input']>;
};

export enum CompanyProjectRole {
  ADMINISTRATOR = 'ADMINISTRATOR',
  COLLABORATOR = 'COLLABORATOR',
}

export type CompanyProjectRoles = {
  __typename?: 'CompanyProjectRoles';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  roles: Array<PartialRole>;
};

export type CompanyRole = {
  __typename?: 'CompanyRole';
  companyID: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  ordering: Scalars['Int']['output'];
  permissionGroups: Array<PermissionGroup>;
};

export type CompanyUser = {
  __typename?: 'CompanyUser';
  company?: Maybe<Company>;
  isIndependent: Scalars['Boolean']['output'];
  matchedCompany?: Maybe<Company>;
  role?: Maybe<PartialRole>;
  user?: Maybe<User>;
};

export type CompanyUserDetails = {
  __typename?: 'CompanyUserDetails';
  companyProjects: Array<UserProject>;
  outsideProjects: Array<UserProject>;
  role?: Maybe<CompanyRole>;
  user: User;
};

export type CompanyUserFilter = {
  includeCollaborators?: InputMaybe<Scalars['Boolean']['input']>;
  includeMembers?: InputMaybe<Scalars['Boolean']['input']>;
  statuses?: InputMaybe<Array<Scalars['UserStatus']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyUserSortBy = {
  sortDirection: SortDirection;
  sortKey: CompanyUserSortKeys;
};

export enum CompanyUserSortKeys {
  SORT_COMPANY_USER_COMPANY_NAME = 'SORT_COMPANY_USER_COMPANY_NAME',
  SORT_COMPANY_USER_COMPANY_ROLE = 'SORT_COMPANY_USER_COMPANY_ROLE',
  SORT_COMPANY_USER_COMPANY_TYPE = 'SORT_COMPANY_USER_COMPANY_TYPE',
  SORT_COMPANY_USER_EMAIL = 'SORT_COMPANY_USER_EMAIL',
  SORT_COMPANY_USER_JOB_TITLE = 'SORT_COMPANY_USER_JOB_TITLE',
  SORT_COMPANY_USER_LATEST_ACTIVITY = 'SORT_COMPANY_USER_LATEST_ACTIVITY',
  SORT_COMPANY_USER_STATUS = 'SORT_COMPANY_USER_STATUS',
  SORT_COMPANY_USER_USER_NAME = 'SORT_COMPANY_USER_USER_NAME',
}

export type ContingencyInfo = {
  error: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  type: MarkupDisplayType;
};

export type ContingencyReportContingency = {
  __typename?: 'ContingencyReportContingency';
  acceptedItemCount: Scalars['Int']['output'];
  contingencyID: Scalars['UUID']['output'];
  displayType: MarkupDisplayType;
  existsInAllMilestonesReportMilestone: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  percentOfMilestoneEstimate?: Maybe<Scalars['USCents']['output']>;
  rows: Array<ContingencyReportItem>;
  startingCost: Scalars['USCents']['output'];
  totalDrawnCost: Scalars['USCents']['output'];
  totalPendingCost: Scalars['USCents']['output'];
  usedCost: Scalars['USCents']['output'];
};

export type ContingencyReportItem = {
  __typename?: 'ContingencyReportItem';
  acceptedBy?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  dateAccepted?: Maybe<Scalars['Time']['output']>;
  dateCreated?: Maybe<Scalars['Time']['output']>;
  drawCost?: Maybe<Scalars['USCents']['output']>;
  itemID: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  status: Status;
};

export type CopyItem = {
  __typename?: 'CopyItem';
  copyItemID: Scalars['UUID']['output'];
};

export type CopyItemData = {
  __typename?: 'CopyItemData';
  convertedItemCategories: Array<Category>;
  itemEstimateCategorizations: Array<Categorization>;
};

export type Cost = CostRange | CostScalar;

export type CostChange = {
  __typename?: 'CostChange';
  newCost: Cost;
  oldCost: Cost;
  timestamp: Scalars['Time']['output'];
};

export enum CostDisplay {
  HIDE_COSTS = 'HIDE_COSTS',
  SHOW_COSTS = 'SHOW_COSTS',
}

export type CostMode = {
  costDisplay: CostDisplay;
  markupMode: MarkupMode;
};

export type CostRange = {
  __typename?: 'CostRange';
  max: Scalars['USCents']['output'];
  min: Scalars['USCents']['output'];
};

export type CostRangeInput = {
  max?: InputMaybe<Scalars['USCents']['input']>;
  min?: InputMaybe<Scalars['USCents']['input']>;
};

export type CostReport = {
  __typename?: 'CostReport';
  categorizedItemCosts: Array<CategorizedItemCost>;
  directCostRange: Cost;
  directCostSegmented: AddDeductCost;
  markupContributions: Array<MarkupContribution>;
  numeric: Scalars['Numeric']['output'];
  projectTotalRange?: Maybe<Cost>;
  projectTotalSegmented?: Maybe<AddDeductCost>;
  range: Cost;
  segmented: AddDeductCost;
  subtotals: Array<CostReportItem>;
  type: Scalars['CostReportType']['output'];
};

export type CostReportColumn = {
  __typename?: 'CostReportColumn';
  columnKey: CostReportColumnKey;
  isCategorized?: Maybe<Scalars['Boolean']['output']>;
  quantity?: Maybe<Quantity>;
  report?: Maybe<CostReport>;
  type: CostReportColumnType;
};

export type CostReportColumnInput = {
  isCategorized?: InputMaybe<Scalars['Boolean']['input']>;
  type: CostReportColumnType;
  unitID?: InputMaybe<Scalars['UUID']['input']>;
};

export enum CostReportColumnKey {
  ACCEPTEDADDS_KEY = 'ACCEPTEDADDS_KEY',
  ACCEPTEDDEDUCTS_KEY = 'ACCEPTEDDEDUCTS_KEY',
  ACCEPTED_KEY = 'ACCEPTED_KEY',
  CATEGORIZEDMETRIC_KEY = 'CATEGORIZEDMETRIC_KEY',
  DELTA_KEY = 'DELTA_KEY',
  ESTIMATE_KEY = 'ESTIMATE_KEY',
  INCORPORATED_KEY = 'INCORPORATED_KEY',
  METRIC_KEY = 'METRIC_KEY',
  NOTAPPLICABLE_KEY = 'NOTAPPLICABLE_KEY',
  OPTIONS_KEY = 'OPTIONS_KEY',
  PENDINGADDS_KEY = 'PENDINGADDS_KEY',
  PENDINGDEDUCTS_KEY = 'PENDINGDEDUCTS_KEY',
  PENDING_KEY = 'PENDING_KEY',
  REJECTEDADDS_KEY = 'REJECTEDADDS_KEY',
  REJECTEDDEDUCTS_KEY = 'REJECTEDDEDUCTS_KEY',
  REJECTED_KEY = 'REJECTED_KEY',
  REMAINING_KEY = 'REMAINING_KEY',
  RUNNINGTOTAL_KEY = 'RUNNINGTOTAL_KEY',
  SCENARIODELTA_KEY = 'SCENARIODELTA_KEY',
  TARGET_KEY = 'TARGET_KEY',
  VARIANCEMETRIC_KEY = 'VARIANCEMETRIC_KEY',
  VARIANCE_KEY = 'VARIANCE_KEY',
}

export enum CostReportColumnType {
  ACCEPTED_REPORT = 'ACCEPTED_REPORT',
  CATEGORIZEDMETRIC_REPORT = 'CATEGORIZEDMETRIC_REPORT',
  DELTA_REPORT = 'DELTA_REPORT',
  ESTIMATE_REPORT = 'ESTIMATE_REPORT',
  INCORPORATED_REPORT = 'INCORPORATED_REPORT',
  METRIC_REPORT = 'METRIC_REPORT',
  NOTAPPLICABLE_REPORT = 'NOTAPPLICABLE_REPORT',
  NOTCHOSEN_REPORT = 'NOTCHOSEN_REPORT',
  OPTIONS_REPORT = 'OPTIONS_REPORT',
  PENDING_REPORT = 'PENDING_REPORT',
  REJECTED_REPORT = 'REJECTED_REPORT',
  REMAINING_REPORT = 'REMAINING_REPORT',
  RUNNINGTOTAL_REPORT = 'RUNNINGTOTAL_REPORT',
  SCENARIODELTA_REPORT = 'SCENARIODELTA_REPORT',
  TARGET_REPORT = 'TARGET_REPORT',
  VARIANCE = 'VARIANCE',
}

export type CostReportItem = {
  __typename?: 'CostReportItem';
  categories: Array<Category>;
  subtotalValue: SubtotalValue;
};

export type CostReportMilestoneInput = {
  date?: InputMaybe<Scalars['Time']['input']>;
  milestoneID: Scalars['UUID']['input'];
};

export type CostReportParameters = {
  columns: Array<CostReportColumnInput>;
  costMode: CostMode;
  groupByItems: Array<GroupByItem>;
  milestones: Array<CostReportMilestoneInput>;
  projectID: Scalars['UUID']['input'];
  shouldIncludeOwnerCosts?: InputMaybe<Scalars['Boolean']['input']>;
  useDraftBudget: Scalars['Boolean']['input'];
  useDraftEstimate: Scalars['Boolean']['input'];
  viewFilter: ViewFilter;
};

export type CostScalar = {
  __typename?: 'CostScalar';
  value: Scalars['USCents']['output'];
};

export type CostTableColumnInput = {
  key: CostTableColumnInputKey;
  unitID?: InputMaybe<Scalars['UUID']['input']>;
};

export enum CostTableColumnInputKey {
  METRIC = 'METRIC',
  PERCENT = 'PERCENT',
  TOTAL = 'TOTAL',
  TOTAL_PER_METRIC = 'TOTAL_PER_METRIC',
}

export type CostTableColumnInputReturn = {
  __typename?: 'CostTableColumnInputReturn';
  key: CostTableColumnInputKey;
  unitID?: Maybe<Scalars['UUID']['output']>;
};

export type CostTrendlineEvent = {
  __typename?: 'CostTrendlineEvent';
  cost: Scalars['USCents']['output'];
  eventContent: EventContent;
  eventType: EventType;
  ids: Array<Scalars['UUID']['output']>;
  item?: Maybe<EventItem>;
  timestamp: Scalars['Time']['output'];
  visible: Scalars['Boolean']['output'];
  y: Scalars['USCents']['output'];
  y0: Scalars['USCents']['output'];
  yPoint: Scalars['USCents']['output'];
};

export type CostTrendlineEvents = {
  __typename?: 'CostTrendlineEvents';
  estimate: Scalars['USCents']['output'];
  events: Array<CostTrendlineEvent>;
  runningTotal: Scalars['USCents']['output'];
  target: Scalars['USCents']['output'];
};

export enum CostType {
  EQUIPMENT_COST = 'EQUIPMENT_COST',
  LABOR_COST = 'LABOR_COST',
  MATERIAL_COST = 'MATERIAL_COST',
  OTHER_COST = 'OTHER_COST',
  SUBCONTRACTOR_COST = 'SUBCONTRACTOR_COST',
  USER_COST = 'USER_COST',
}

export type CreateAlertInput = {
  triggers: Array<InsightsTriggerInput>;
};

export type CreateAlertPayload = {
  __typename?: 'CreateAlertPayload';
  alert?: Maybe<InsightsAlert>;
};

export type CreateCategorizationErrors = {
  __typename?: 'CreateCategorizationErrors';
  userErrors: Array<Scalars['String']['output']>;
};

export type CreateCategorizationPayload = Categorization | CreateCategorizationErrors;

export type CreateCategoryResult = {
  __typename?: 'CreateCategoryResult';
  categories: Array<Category>;
  error: Scalars['String']['output'];
};

export type CreateItemIntegrationObject = {
  __typename?: 'CreateItemIntegrationObject';
  autodeskPotentialChangeOrder?: Maybe<AutodeskPotentialChangeOrder>;
  error?: Maybe<Scalars['String']['output']>;
  procoreChangeEvent?: Maybe<ProcoreChangeEvent>;
};

export type CreateItemIntegrationObjectInput = {
  changeReason?: InputMaybe<Scalars['String']['input']>;
  cost?: InputMaybe<Scalars['USCents']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  itemURL?: InputMaybe<Scalars['String']['input']>;
  shouldIncludeAttachments?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateItemsFromImportPayload = {
  __typename?: 'CreateItemsFromImportPayload';
  userErrors: Array<Scalars['String']['output']>;
};

export type CreateOrgInput = {
  companyID: Scalars['UUID']['input'];
};

export type CreateOrgNodeInput = {
  organizationID: Scalars['UUID']['input'];
  parentID?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateOrgNodePayload = {
  __typename?: 'CreateOrgNodePayload';
  organization?: Maybe<Org>;
};

export type CreateOrgPayload = {
  __typename?: 'CreateOrgPayload';
  organization?: Maybe<Org>;
};

export type CreateScenarioSandboxInput = {
  milestoneID: Scalars['UUID']['input'];
};

export type CurrentItemBreakdown = {
  __typename?: 'CurrentItemBreakdown';
  cost: Scalars['USCents']['output'];
  project: ProjectChartInfo;
  status: Status;
};

export type CurrentItemsBreakdown = {
  __typename?: 'CurrentItemsBreakdown';
  itemBreakdownsByProject: Array<CurrentItemBreakdown>;
  projectTotals: Array<ProjectItemTotalCostBreakdown>;
};

export type DashboardCompany = {
  __typename?: 'DashboardCompany';
  domain: Scalars['String']['output'];
  name: Scalars['String']['output'];
  projectIndices: Array<Scalars['Int']['output']>;
};

export type DashboardProjects = {
  __typename?: 'DashboardProjects';
  companies: Array<DashboardCompany>;
  counts: ProjectsCounts;
  projects: Array<ProjectChartInfo>;
};

export type DateFilter = {
  days?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Time']['input']>;
};

export enum DateGroup {
  BEFOREYESTERDAY = 'BeforeYesterday',
  LASTWEEK = 'LastWeek',
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
}

export type DateRangeInput = {
  end?: InputMaybe<Scalars['Time']['input']>;
  start?: InputMaybe<Scalars['Time']['input']>;
};

export type DateVisibilityToggle = {
  dateEnd: Scalars['String']['input'];
  dateStart: Scalars['String']['input'];
  milestoneID: Scalars['UUID']['input'];
  visible: Scalars['Boolean']['input'];
};

export type DecisionSummary = {
  __typename?: 'DecisionSummary';
  acceptedCost: Scalars['USCents']['output'];
  acceptedCount: Scalars['Int']['output'];
  incorporatedCost: Scalars['USCents']['output'];
  incorporatedCount: Scalars['Int']['output'];
  notApplicableCost: Scalars['USCents']['output'];
  notApplicableCount: Scalars['Int']['output'];
  pendingCost: Scalars['USCents']['output'];
  pendingCount: Scalars['Int']['output'];
  rejectedCost: Scalars['USCents']['output'];
  rejectedCount: Scalars['Int']['output'];
  totalItemCount: Scalars['Int']['output'];
};

export type DeleteItemEstimateInput = {
  itemID: Scalars['UUID']['input'];
};

export type DeleteItemEstimatePayload = {
  __typename?: 'DeleteItemEstimatePayload';
  itemID?: Maybe<Scalars['UUID']['output']>;
};

export type DeleteOrgInput = {
  organizationID: Scalars['UUID']['input'];
};

export type DeleteOrgLevelInput = {
  name: Scalars['String']['input'];
  organizationID: Scalars['UUID']['input'];
};

export type DeleteOrgNodeInput = {
  nodeID: Scalars['UUID']['input'];
};

export type DeleteOrgNodePayload = {
  __typename?: 'DeleteOrgNodePayload';
  organization?: Maybe<Org>;
};

export type DeleteOrgPayload = {
  __typename?: 'DeleteOrgPayload';
  organization?: Maybe<Org>;
};

export type DeprecateAlertInput = {
  alertID: Scalars['UUID']['input'];
};

export type DeprecateAlertPayload = {
  __typename?: 'DeprecateAlertPayload';
  alert?: Maybe<InsightsAlert>;
};

export type Derivation = {
  __typename?: 'Derivation';
  contents: Array<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  errors: Array<Scalars['String']['output']>;
  exitCode: Scalars['ExitCode']['output'];
  id: Scalars['UUID']['output'];
  kind: Scalars['String']['output'];
  output: Scalars['String']['output'];
};

export type DesignPhaseType = {
  __typename?: 'DesignPhaseType';
  abbreviation: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type DistributedReport = {
  __typename?: 'DistributedReport';
  assetURL: Scalars['String']['output'];
  roleID: Scalars['UUID']['output'];
  sentTo: Array<Scalars['UUID']['output']>;
};

export type DraftCategorization = {
  categoryUpdates?: InputMaybe<CategoryUpdates>;
  existingCategorization: Scalars['String']['input'];
  include: Scalars['Boolean']['input'];
  isDraft: Scalars['Boolean']['input'];
  level: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type DraftEstimate = {
  __typename?: 'DraftEstimate';
  author: Scalars['UUID']['output'];
  errors: Array<ImportEstimateError>;
  estimate: Estimate;
};

export type DraftEstimateInfo = {
  __typename?: 'DraftEstimateInfo';
  asset?: Maybe<Scalars['UUID']['output']>;
  author: Scalars['UUID']['output'];
};

export type DrawChange = {
  __typename?: 'DrawChange';
  fromValue: Scalars['Numeric']['output'];
  name: Scalars['String']['output'];
  timestamp: Scalars['Time']['output'];
  toValue: Scalars['Numeric']['output'];
};

export type DuplicateAlertInput = {
  alertID: Scalars['UUID']['input'];
};

export type DuplicateAlertPayload = {
  __typename?: 'DuplicateAlertPayload';
  alert?: Maybe<InsightsAlert>;
};

export type EditCategoryInput = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  number: Scalars['String']['input'];
};

export type Estimate = {
  __typename?: 'Estimate';
  asset?: Maybe<Scalars['UUID']['output']>;
  contingencyDraws?: Maybe<Array<ItemDrawInfo>>;
  drawSubtotal: Scalars['USCents']['output'];
  fields: Array<Field>;
  id: Scalars['UUID']['output'];
  incorporatedDraws: Array<Markup>;
  incorporatedDrawsSubtotal: Scalars['USCents']['output'];
  incorporatedMarkups: Array<Markup>;
  incorporatedSubtotal: Scalars['USCents']['output'];
  inheritedMarkups: Array<Markup>;
  inheritedOwnerCostMarkups: Array<Markup>;
  inheritedOwnerCostMarkupsSubtotal: Scalars['USCents']['output'];
  inheritedSubtotal: Scalars['USCents']['output'];
  isRunningTotal: Scalars['Boolean']['output'];
  lineIDs?: Maybe<Array<Scalars['UUID']['output']>>;
  lines: Array<Line>;
  linesCount?: Maybe<Scalars['Int']['output']>;
  markupSubtotal: Scalars['USCents']['output'];
  markups: Array<Markup>;
  milestoneName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ownerCostEstimate?: Maybe<Estimate>;
  subtotal: Scalars['Numeric']['output'];
  totalType: EstimateTotalType;
};

export type EstimateCreationInput = {
  categorizationName?: InputMaybe<Scalars['String']['input']>;
  estimateType: EstimateType;
  milestoneInput: MilestoneInput;
};

export type EstimateCreationResult = {
  __typename?: 'EstimateCreationResult';
  estimate: Scalars['UUID']['output'];
};

export type EstimateMarkupsInfo = {
  __typename?: 'EstimateMarkupsInfo';
  incorporatedMarkups: Array<Markup>;
  markups: Array<Markup>;
};

export type EstimateSearchResult = {
  __typename?: 'EstimateSearchResult';
  categories?: Maybe<Array<Category>>;
  description: Scalars['String']['output'];
  estimateID: Scalars['UUID']['output'];
  estimateType: Scalars['String']['output'];
  lastUpdated: Scalars['Time']['output'];
  lineID: Scalars['UUID']['output'];
  milestoneID: Scalars['UUID']['output'];
  milestoneName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  project: Project;
  quantity: Scalars['String']['output'];
  total: Scalars['String']['output'];
  unit: Scalars['String']['output'];
  unitPrice: Scalars['String']['output'];
};

export type EstimateSortBy = {
  fieldID: Scalars['UUID']['input'];
  sortDirection: Scalars['String']['input'];
};

export enum EstimateTotalType {
  TOTAL_TYPE_COST_TYPES = 'TOTAL_TYPE_COST_TYPES',
  TOTAL_TYPE_TOTAL = 'TOTAL_TYPE_TOTAL',
  TOTAL_TYPE_UNIT = 'TOTAL_TYPE_UNIT',
}

export type EstimateTotalTypeConflictInfo = {
  __typename?: 'EstimateTotalTypeConflictInfo';
  hasConflict: Scalars['Boolean']['output'];
  mostDetailedEstimateTotalType?: Maybe<EstimateTotalType>;
};

export enum EstimateType {
  ACTIVE_ESTIMATE = 'ACTIVE_ESTIMATE',
  BUDGET = 'BUDGET',
  ITEM = 'ITEM',
  OWNER_COST = 'OWNER_COST',
}

export type EstimateUpdateResult = {
  __typename?: 'EstimateUpdateResult';
  estimate?: Maybe<Estimate>;
};

export type EventContent = {
  __typename?: 'EventContent';
  newCost?: Maybe<Cost>;
  newMilestone?: Maybe<Scalars['UUID']['output']>;
  newOptionStatus?: Maybe<Status>;
  newStatus?: Maybe<Status>;
  oldCost?: Maybe<Cost>;
  oldMilestone?: Maybe<Scalars['UUID']['output']>;
  oldOptionStatus?: Maybe<Status>;
  oldStatus?: Maybe<Status>;
  option?: Maybe<ItemSummary>;
  options?: Maybe<Array<ItemSummary>>;
  parent?: Maybe<ItemSummary>;
};

export type EventCostImpact = {
  __typename?: 'EventCostImpact';
  cost: Scalars['USCents']['output'];
  maxPending: Scalars['USCents']['output'];
  minPending: Scalars['USCents']['output'];
};

export type EventData = {
  __typename?: 'EventData';
  events: Array<EventDataItem>;
};

export type EventDataItem = {
  __typename?: 'EventDataItem';
  costImpact: EventCostImpact;
  currentItemNumber: Scalars['String']['output'];
  eventContent: EventContent;
  eventType: EventType;
  ids: Array<Scalars['UUID']['output']>;
  item: EventItem;
  timestamp: Scalars['Time']['output'];
  user: User;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type EventItem = {
  __typename?: 'EventItem';
  cost?: Maybe<Cost>;
  id: Scalars['UUID']['output'];
  milestone?: Maybe<Milestone>;
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  status: Status;
};

export enum EventType {
  ADD_ASSET = 'ADD_ASSET',
  ADD_COMMENT = 'ADD_COMMENT',
  ADD_SHARED_DRAFT_USER_EVENT = 'ADD_SHARED_DRAFT_USER_EVENT',
  ATTACH_OPTION = 'ATTACH_OPTION',
  BASELINE = 'BASELINE',
  CHANGE_ACTIVITIES = 'CHANGE_ACTIVITIES',
  CHANGE_ASSIGNEE = 'CHANGE_ASSIGNEE',
  CHANGE_CATEGORY = 'CHANGE_CATEGORY',
  CHANGE_COST = 'CHANGE_COST',
  CHANGE_DESCRIPTION = 'CHANGE_DESCRIPTION',
  CHANGE_DUE_DATE = 'CHANGE_DUE_DATE',
  CHANGE_ESTIMATE = 'CHANGE_ESTIMATE',
  CHANGE_MEETING = 'CHANGE_MEETING',
  CHANGE_MILESTONE = 'CHANGE_MILESTONE',
  CHANGE_NAME = 'CHANGE_NAME',
  CHANGE_NUMBER = 'CHANGE_NUMBER',
  CHANGE_PRIORITY_IMPACTS = 'CHANGE_PRIORITY_IMPACTS',
  CHANGE_SCHEDULE_IMPACT = 'CHANGE_SCHEDULE_IMPACT',
  CHANGE_STATUS = 'CHANGE_STATUS',
  CHANGE_VISIBILITY = 'CHANGE_VISIBILITY',
  CONVERT_TO_PARENT = 'CONVERT_TO_PARENT',
  CREATE_AUTODESK_POTENTIAL_CHANGE_ORDER = 'CREATE_AUTODESK_POTENTIAL_CHANGE_ORDER',
  CREATE_ITEM = 'CREATE_ITEM',
  CREATE_OPTION = 'CREATE_OPTION',
  CREATE_PROCORE_CHANGE_EVENT = 'CREATE_PROCORE_CHANGE_EVENT',
  DELETE_AUTODESK_POTENTIAL_CHANGE_ORDER = 'DELETE_AUTODESK_POTENTIAL_CHANGE_ORDER',
  DELETE_PROCORE_CHANGE_EVENT = 'DELETE_PROCORE_CHANGE_EVENT',
  DETACH_OPTION = 'DETACH_OPTION',
  ITEM_DELETED = 'ITEM_DELETED',
  ITEM_DRAW_SET = 'ITEM_DRAW_SET',
  ITEM_DRAW_UPDATE = 'ITEM_DRAW_UPDATE',
  ITEM_REFERENCED = 'ITEM_REFERENCED',
  REMOVE_ASSET = 'REMOVE_ASSET',
  REMOVE_FROM_MILESTONE = 'REMOVE_FROM_MILESTONE',
  REMOVE_SHARED_DRAFT_USER_EVENT = 'REMOVE_SHARED_DRAFT_USER_EVENT',
  UNKNOWN_EVENT = 'UNKNOWN_EVENT',
}

export type ExportAverageCompEstimateInput = {
  categorizationID?: InputMaybe<Scalars['UUID']['input']>;
  categorizationName?: InputMaybe<Scalars['String']['input']>;
  estimateType: EstimateType;
};

export type Field = {
  __typename?: 'Field';
  categorization?: Maybe<Categorization>;
  errors: Array<FieldError>;
  group: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type FieldError = {
  __typename?: 'FieldError';
  categoryErrorIndicies: Array<CategoryErrorIndicies>;
  count: Scalars['Int']['output'];
  type: FieldErrorType;
};

export enum FieldErrorType {
  CATEGORY_NOT_FOUND = 'CATEGORY_NOT_FOUND',
  DRAFT_CATEGORIZATION = 'DRAFT_CATEGORIZATION',
}

export type FieldInput = {
  categorizationID?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordering: Scalars['Int']['input'];
  type: Scalars['FieldType']['input'];
};

export type FilesSortBy = {
  sortDirection: SortDirection;
  sortKey: FilesSortKey;
};

export enum FilesSortKey {
  DATE = 'DATE',
  NAME = 'NAME',
  TYPE = 'TYPE',
}

export type ForecastingExploreItemsFilters = {
  categoryNumbers: Array<Scalars['String']['input']>;
  costImpact: CostRangeInput;
  milestoneDate: DateRangeInput;
  milestoneNames: Array<Scalars['String']['input']>;
  projectLocations: Array<Scalars['String']['input']>;
  projectNames: Array<Scalars['String']['input']>;
  projectTypes: Array<Scalars['String']['input']>;
  statuses: Array<Scalars['String']['input']>;
};

export type ForecastingExploreItemsSortBy = {
  sortDirection: SortDirection;
  sortKey: ForecastingExploreItemsSortKey;
};

export enum ForecastingExploreItemsSortKey {
  COST_IMPACT = 'COST_IMPACT',
  MILESTONE_DATE = 'MILESTONE_DATE',
  PROJECT_NAME = 'PROJECT_NAME',
  SCORE = 'SCORE',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT',
}

export type ForecastingItem = {
  __typename?: 'ForecastingItem';
  categories: Array<SearchCategory>;
  cost?: Maybe<CostRange>;
  createdBy?: Maybe<User>;
  estimateLines?: Maybe<Array<ItemSearchEstimate>>;
  hasAccess: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  milestoneDate?: Maybe<Scalars['Time']['output']>;
  name: Scalars['String']['output'];
  numOptions: Scalars['Int']['output'];
  number: Scalars['String']['output'];
  parentID?: Maybe<Scalars['UUID']['output']>;
  parentName?: Maybe<Scalars['String']['output']>;
  project: ForecastingProject;
  projectID: Scalars['UUID']['output'];
  status: Status;
  type: ItemType;
  updatedAt: Scalars['Time']['output'];
};

export type ForecastingItems = {
  __typename?: 'ForecastingItems';
  aggregations: Array<Aggregation>;
  data: Array<ForecastingItem>;
  total: Scalars['Float']['output'];
};

export type ForecastingProject = {
  __typename?: 'ForecastingProject';
  budget?: Maybe<Scalars['USCents']['output']>;
  estimate?: Maybe<Scalars['USCents']['output']>;
  hasAccess: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  location: Scalars['String']['output'];
  milestoneDate?: Maybe<Scalars['Time']['output']>;
  milestoneID: Scalars['UUID']['output'];
  milestoneName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgNodes: Array<OrgNode>;
  projectLeadName?: Maybe<Scalars['String']['output']>;
  runningTotal?: Maybe<Scalars['USCents']['output']>;
  status: Scalars['String']['output'];
  thumbnail: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Time']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ForecastingProjectsFilters = {
  companies: Array<Scalars['String']['input']>;
  estimateCostRange: CostRangeInput;
  locations: Array<Scalars['String']['input']>;
  milestoneDateRange: DateRangeInput;
  milestoneDesignPhases: Array<Scalars['String']['input']>;
  orgNodeIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  projectLeadIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  projectTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses: Array<Scalars['String']['input']>;
  types: Array<Scalars['String']['input']>;
};

export type ForecastingProjectsSortBy = {
  sortDirection: SortDirection;
  sortKey: ForecastingProjectsSortKey;
};

export enum ForecastingProjectsSortKey {
  ESTIMATE = 'ESTIMATE',
  LOCATION = 'LOCATION',
  MILESTONE_NAME = 'MILESTONE_NAME',
  NAME = 'NAME',
  SORT_FORECASTING_PROJECT_ESTIMATE = 'SORT_FORECASTING_PROJECT_ESTIMATE',
  SORT_FORECASTING_PROJECT_MILESTONE_NAME = 'SORT_FORECASTING_PROJECT_MILESTONE_NAME',
  SORT_FORECASTING_PROJECT_NAME = 'SORT_FORECASTING_PROJECT_NAME',
  SORT_FORECASTING_PROJECT_UPDATED_AT = 'SORT_FORECASTING_PROJECT_UPDATED_AT',
  STATUS = 'STATUS',
  TYPE = 'TYPE',
  UPDATED_AT = 'UPDATED_AT',
}

export type ForecastingReportsSortBy = {
  sortDirection: SortDirection;
  sortKey: ForecastingReportsSortKey;
};

export enum ForecastingReportsSortKey {
  AVERAGE_QUANTITY_COST = 'AVERAGE_QUANTITY_COST',
  NAME = 'NAME',
  PINNED_UNIT_QUANTITY_MAGNITUDE = 'PINNED_UNIT_QUANTITY_MAGNITUDE',
  PROJECTS_COUNT = 'PROJECTS_COUNT',
  UPDATED_AT = 'UPDATED_AT',
}

export type FormulaDisplay = {
  __typename?: 'FormulaDisplay';
  error: Scalars['String']['output'];
  text: Scalars['String']['output'];
  type: FormulaDisplayType;
};

export enum FormulaDisplayType {
  FORMULA_DISPLAY_REFERENCE = 'FORMULA_DISPLAY_REFERENCE',
  FORMULA_DISPLAY_STRING = 'FORMULA_DISPLAY_STRING',
}

export type GsfRangeInput = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type GetSharedResourceInput = {
  resourceID: Scalars['UUID']['input'];
  resourceType: ResourceType;
};

export type GetSharedResourcePayload = {
  __typename?: 'GetSharedResourcePayload';
  resource?: Maybe<SharedResource>;
};

export type GetSharedResourcesInput = {
  resourceIDs: Array<Scalars['UUID']['input']>;
  resourceType: ResourceType;
};

export type GetSharedResourcesPayload = {
  __typename?: 'GetSharedResourcesPayload';
  resources: Array<SharedResource>;
};

export type GroupAnalyticsProperties = {
  __typename?: 'GroupAnalyticsProperties';
  companyId?: Maybe<Scalars['UUID']['output']>;
  groupId: Scalars['UUID']['output'];
  projectId?: Maybe<Scalars['UUID']['output']>;
  userId?: Maybe<Scalars['UUID']['output']>;
};

export type GroupByItem = {
  categorization: CategorizationInput;
  level: Scalars['Int']['input'];
};

export type HistoricalCostIndex = {
  __typename?: 'HistoricalCostIndex';
  index: Scalars['Float']['output'];
  quarter: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export enum ImageDimension {
  _50 = '_50',
  _92 = '_92',
  _144 = '_144',
  _200 = '_200',
}

export type ImportErrorContent = ImportErrorContentMissingCategories;

export type ImportErrorContentMissingCategories = {
  __typename?: 'ImportErrorContentMissingCategories';
  categories: Array<CategoryContent>;
  categorization: CategorizationContent;
  lines: Scalars['Int']['output'];
};

export type ImportErrorContentMissingCategorization = {
  __typename?: 'ImportErrorContentMissingCategorization';
  categorizations: Array<CategorizationContent>;
};

export type ImportEstimateError = {
  __typename?: 'ImportEstimateError';
  content: ImportErrorContent;
  created: Scalars['Time']['output'];
  id: Scalars['UUID']['output'];
  level: ImportEstimateErrorLevel;
  resolvedCount: Scalars['Int']['output'];
  type: Scalars['ImportEstimateErrorType']['output'];
  unresolvedCount: Scalars['Int']['output'];
  updated?: Maybe<Scalars['Time']['output']>;
};

export enum ImportEstimateErrorLevel {
  IMPORT_ERROR_ACCEPTED = 'IMPORT_ERROR_ACCEPTED',
  IMPORT_ERROR_ERROR = 'IMPORT_ERROR_ERROR',
  IMPORT_ERROR_INFO = 'IMPORT_ERROR_INFO',
  IMPORT_ERROR_WARNING = 'IMPORT_ERROR_WARNING',
}

export type InAppNotification = {
  __typename?: 'InAppNotification';
  content: NotificationContent;
  createdAt: Scalars['Time']['output'];
  id: Scalars['UUID']['output'];
  notificationType: NotificationType;
  notifiedAt?: Maybe<Scalars['Time']['output']>;
  projectID: Scalars['UUID']['output'];
  readAt?: Maybe<Scalars['Time']['output']>;
};

export type InAppNotifications = {
  __typename?: 'InAppNotifications';
  inAppNotifications?: Maybe<Array<InAppNotification>>;
  paginationData: PaginationData;
};

export type InAppNotificationsCounts = {
  __typename?: 'InAppNotificationsCounts';
  allNotificationsCount: Scalars['Int']['output'];
  allProjectNotificationsCount: Scalars['Int']['output'];
  unreadNotificationsCount: Scalars['Int']['output'];
  unreadProjectNotificationsCount: Scalars['Int']['output'];
};

export type InsightsActiveAlert = {
  __typename?: 'InsightsActiveAlert';
  alert: InsightsAlert;
  projectID: Scalars['UUID']['output'];
};

export type InsightsAlert = {
  __typename?: 'InsightsAlert';
  id: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  triggers: Array<InsightsTrigger>;
};

export type InsightsFilter = {
  companies: Array<Scalars['String']['input']>;
  deliveryTypes: Array<Scalars['String']['input']>;
  estimateCostRange: CostRangeInput;
  gsfRange: GsfRangeInput;
  locations: Array<Scalars['String']['input']>;
  milestoneDesignPhases: Array<Scalars['String']['input']>;
  orgNodeIDs: Array<Scalars['UUID']['input']>;
  projectLeadIDs: Array<Scalars['UUID']['input']>;
  statuses: Array<Scalars['String']['input']>;
  types: Array<Scalars['String']['input']>;
};

export type InsightsInput = {
  companyID: Scalars['UUID']['input'];
  filter: InsightsFilter;
  sort: InsightsSort;
};

export type InsightsMilestone = {
  __typename?: 'InsightsMilestone';
  active: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['Time']['output'];
};

export type InsightsProject = {
  __typename?: 'InsightsProject';
  deliveryType: Scalars['String']['output'];
  hasAccess: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  lastUpdated: Scalars['Time']['output'];
  lastUpdatedBy: Scalars['String']['output'];
  location: Scalars['String']['output'];
  milestoneDesignPhase: Scalars['String']['output'];
  milestones: Array<InsightsMilestone>;
  name: Scalars['String']['output'];
  orgNodes: Array<Maybe<OrgNode>>;
  projectLead?: Maybe<Scalars['String']['output']>;
  projectType: Scalars['String']['output'];
  status: Scalars['String']['output'];
  thumbnail: Scalars['String']['output'];
};

export type InsightsSort = {
  sortDirection: SortDirection;
  sortKey: InsightsSortKey;
};

export enum InsightsSortKey {
  COST = 'COST',
  DELIVERY_TYPE = 'DELIVERY_TYPE',
  DESIGN_PHASE = 'DESIGN_PHASE',
  LAST_UPDATED = 'LAST_UPDATED',
  NAME = 'NAME',
  SIZE = 'SIZE',
  STATUS = 'STATUS',
}

export type InsightsTrigger = {
  __typename?: 'InsightsTrigger';
  id: Scalars['UUID']['output'];
  metricID?: Maybe<Scalars['UUID']['output']>;
  metricType: TriggerMetricType;
  operator: TriggerOperatorType;
  threshold?: Maybe<Scalars['Int']['output']>;
};

export type InsightsTriggerInput = {
  metricID?: InputMaybe<Scalars['UUID']['input']>;
  metricType: TriggerMetricType;
  operator: TriggerOperatorType;
  threshold?: InputMaybe<Scalars['Int']['input']>;
};

export enum IntegrationFilterType {
  INTEGRATION_FILTER_EXCLUDE_CHANGE_EVENTS = 'INTEGRATION_FILTER_EXCLUDE_CHANGE_EVENTS',
  INTEGRATION_FILTER_INCLUDE_CHANGE_EVENTS = 'INTEGRATION_FILTER_INCLUDE_CHANGE_EVENTS',
}

export enum IntervalType {
  BI_WEEKLY = 'BI_WEEKLY',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export type InviteToProject = {
  __typename?: 'InviteToProject';
  message?: Maybe<Scalars['String']['output']>;
  project: Project;
  triggeringUser: User;
};

export type Item = {
  __typename?: 'Item';
  activeEstimate?: Maybe<Estimate>;
  activityIDs?: Maybe<Array<Scalars['UUID']['output']>>;
  assetCount: Scalars['Int']['output'];
  assets: Array<Asset>;
  assignee?: Maybe<User>;
  availableStates: Array<Status>;
  bucket?: Maybe<Bucket>;
  categories: Array<Category>;
  commentCount: Scalars['Int']['output'];
  comments: Array<Comment>;
  contingencyDrawCost?: Maybe<Cost>;
  cost?: Maybe<Cost>;
  createdBy?: Maybe<User>;
  creationTime?: Maybe<Scalars['Time']['output']>;
  description: Scalars['String']['output'];
  descriptionStyled: Scalars['String']['output'];
  dueDate?: Maybe<Scalars['Time']['output']>;
  estimate?: Maybe<Estimate>;
  estimateCost?: Maybe<Cost>;
  filteredCategories?: Maybe<Array<Scalars['UUID']['output']>>;
  filteredMilestone?: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  itemType: ItemType;
  milestone?: Maybe<Milestone>;
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  optionSubtotals: Array<OptionSubtotal>;
  options: Array<Option>;
  previousMilestoneStatus: Array<PreviousMilestoneStatus>;
  project: Project;
  remoteAssetCount: Scalars['Int']['output'];
  scheduleImpact?: Maybe<ScheduleImpact>;
  status: Status;
  unfilteredCost?: Maybe<Cost>;
  updateTime?: Maybe<Scalars['Time']['output']>;
  visibility: Visibility;
};

export type ItemActivity = {
  __typename?: 'ItemActivity';
  data: Array<ItemHistoryEvent>;
  paginationData: PaginationData;
};

export type ItemCommentInput = {
  itemID: Scalars['UUID']['input'];
  text: MentionableTextInput;
};

export type ItemContingency = {
  __typename?: 'ItemContingency';
  availableMilestoneContingencies: Array<MilestoneContingencyInfo>;
  draws: Array<ItemDrawInfo>;
};

export type ItemContributionLines = {
  __typename?: 'ItemContributionLines';
  categories: Array<Category>;
  directCostRange: Cost;
  lineDescription?: Maybe<Scalars['String']['output']>;
  lineID?: Maybe<Scalars['UUID']['output']>;
  range: Cost;
};

export type ItemCopyInput = {
  activityIDs: Array<Scalars['UUID']['input']>;
  assetIDs: Array<Scalars['UUID']['input']>;
  assigneeEmail?: InputMaybe<Scalars['String']['input']>;
  categories: Array<CategoryInput>;
  categorizations: Array<CategorizationMapping>;
  description: Scalars['String']['input'];
  descriptionStyled: Scalars['String']['input'];
  dueDate?: InputMaybe<Scalars['Time']['input']>;
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  projectID: Scalars['UUID']['input'];
  status: Status;
  visibility: Visibility;
};

export type ItemCost = {
  __typename?: 'ItemCost';
  cost?: Maybe<Cost>;
  currentCost?: Maybe<Cost>;
};

export type ItemCostParameters = {
  costMode: CostMode;
  itemID: Scalars['UUID']['input'];
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
  viewFilter: ViewFilter;
};

export type ItemCounts = {
  __typename?: 'ItemCounts';
  accepted: Scalars['Int']['output'];
  incorporated: Scalars['Int']['output'];
  notapplicable: Scalars['Int']['output'];
  pending: Scalars['Int']['output'];
  rejected: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ItemCreationInput = {
  activityIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  assigneeEmail?: InputMaybe<Scalars['String']['input']>;
  bucketID?: InputMaybe<Scalars['UUID']['input']>;
  categories?: InputMaybe<Array<CategoryInput>>;
  costImpact?: InputMaybe<Scalars['USCents']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionStyled?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Time']['input']>;
  milestoneID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  projectID: Scalars['UUID']['input'];
  status?: InputMaybe<Status>;
  visibility?: InputMaybe<Visibility>;
};

export type ItemDrawInfo = ContingencyInfo & {
  __typename?: 'ItemDrawInfo';
  draw: Scalars['USCents']['output'];
  drawFromID?: Maybe<Scalars['UUID']['output']>;
  error: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  isDrawingFullAmount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: MarkupDisplayType;
};

export type ItemDueDateProject = {
  __typename?: 'ItemDueDateProject';
  dueThisWeekCount: Scalars['Int']['output'];
  noDueDateCount: Scalars['Int']['output'];
  openCount: Scalars['Int']['output'];
  pastDueCount: Scalars['Int']['output'];
  projectID: Scalars['UUID']['output'];
  upcomingCount: Scalars['Int']['output'];
};

export type ItemEstimateInput = {
  itemID: Scalars['UUID']['input'];
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
};

export type ItemHistory = {
  __typename?: 'ItemHistory';
  events: Array<ItemHistoryEvent>;
};

export type ItemHistoryEvent = {
  __typename?: 'ItemHistoryEvent';
  eventContent: ItemHistoryEventContent;
  eventTypes: Array<EventType>;
  id: Scalars['UUID']['output'];
  itemID: Scalars['UUID']['output'];
  timestamp: Scalars['Time']['output'];
  user?: Maybe<User>;
};

export type ItemHistoryEventContent = {
  __typename?: 'ItemHistoryEventContent';
  addedAttachments?: Maybe<Array<Maybe<Attachment>>>;
  assignee?: Maybe<User>;
  attachedOptions?: Maybe<Array<Maybe<ItemLikeSummary>>>;
  categoryChanges?: Maybe<Array<CategoryChange>>;
  comment?: Maybe<Comment>;
  copiedItem?: Maybe<ItemLinkWithProjectId>;
  costChanges?: Maybe<Array<CostChange>>;
  deletedBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionStyled?: Maybe<Scalars['String']['output']>;
  drawChanges?: Maybe<Array<DrawChange>>;
  drawFromValue?: Maybe<Scalars['Numeric']['output']>;
  drawName?: Maybe<Scalars['String']['output']>;
  drawToValue?: Maybe<Scalars['Numeric']['output']>;
  dueDate?: Maybe<Scalars['Time']['output']>;
  itemIntegrationObject?: Maybe<ItemIntegrationObjectSummary>;
  itemLikeSummary?: Maybe<ItemLikeSummary>;
  meeting?: Maybe<Bucket>;
  milestoneID?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  newCost?: Maybe<Cost>;
  newMilestoneID?: Maybe<Scalars['UUID']['output']>;
  newNumber?: Maybe<Scalars['String']['output']>;
  newTimelineActivities: Array<TimelineActivity>;
  oldCost?: Maybe<Cost>;
  oldMilestoneID?: Maybe<Scalars['UUID']['output']>;
  oldNumber?: Maybe<Scalars['String']['output']>;
  oldTimelineActivities: Array<TimelineActivity>;
  referencedByItems?: Maybe<Array<Maybe<ItemLikeSummary>>>;
  removedAssets?: Maybe<Array<Maybe<Asset>>>;
  removedOptions?: Maybe<Array<Maybe<ItemLikeSummary>>>;
  scheduleImpactChanges: Array<ScheduleImpactChange>;
  sharedUsers: Array<Maybe<User>>;
  status?: Maybe<Status>;
  statusChangeDraws?: Maybe<Array<Maybe<StatusChangeDraw>>>;
  visibility?: Maybe<Visibility>;
};

export type ItemHistoryLink = {
  __typename?: 'ItemHistoryLink';
  deprecated: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  status: Status;
  visibility?: Maybe<Visibility>;
};

export type ItemIntegrationObject = {
  cost: Scalars['USCents']['output'];
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  eventType: Scalars['String']['output'];
  integrationObjectID: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ItemIntegrationObjectSummary = {
  __typename?: 'ItemIntegrationObjectSummary';
  id: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  sourceEntityID: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ItemIntegrationObjects = {
  __typename?: 'ItemIntegrationObjects';
  autodeskPotentialChangeOrders: Array<AutodeskPotentialChangeOrder>;
  error?: Maybe<Scalars['String']['output']>;
  procoreChangeEvents: Array<ProcoreChangeEvent>;
  refetchedAt: Scalars['Time']['output'];
};

export type ItemLike = Item | Option;

export type ItemLikeSummary = {
  __typename?: 'ItemLikeSummary';
  id: Scalars['UUID']['output'];
  kind?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  visibility: Visibility;
};

export type ItemLink = {
  __typename?: 'ItemLink';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  status: Status;
  visibility?: Maybe<Visibility>;
};

export type ItemLinkWithProjectId = {
  __typename?: 'ItemLinkWithProjectID';
  hasAccess: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  projectID: Scalars['UUID']['output'];
  status: Status;
  visibility?: Maybe<Visibility>;
};

export type ItemOverlay = {
  itemID: Scalars['UUID']['input'];
  status?: InputMaybe<Status>;
};

export type ItemQuery = {
  costMode: CostMode;
  milestone?: InputMaybe<Scalars['UUID']['input']>;
  project: Scalars['UUID']['input'];
  viewFilter: ViewFilter;
};

export type ItemReference = {
  __typename?: 'ItemReference';
  id: Scalars['UUID']['output'];
  position: Scalars['Int']['output'];
  referencedItem?: Maybe<ItemsListItem>;
  text: Scalars['String']['output'];
};

export type ItemReferenceInput = {
  position: Scalars['Int']['input'];
  referencedItemID: Scalars['UUID']['input'];
  text: Scalars['String']['input'];
};

export type ItemSearchEstimate = {
  __typename?: 'ItemSearchEstimate';
  description: Scalars['String']['output'];
  estimateID: Scalars['UUID']['output'];
  estimateType: Scalars['String']['output'];
  lastUpdated: Scalars['Time']['output'];
  lineID: Scalars['UUID']['output'];
  milestoneID: Scalars['UUID']['output'];
  milestoneName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['String']['output'];
  total: Scalars['String']['output'];
  unit: Scalars['String']['output'];
  unitPrice: Scalars['String']['output'];
};

export type ItemSearchResult = {
  __typename?: 'ItemSearchResult';
  categories?: Maybe<Array<Category>>;
  cost: Cost;
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  id: Scalars['UUID']['output'];
  itemSearchEstimate?: Maybe<Array<ItemSearchEstimate>>;
  itemType: ItemType;
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  project: Project;
  status: Status;
};

export type ItemSummary = {
  __typename?: 'ItemSummary';
  currentNumber: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  number: Scalars['String']['output'];
  status: Status;
};

export enum ItemType {
  ITEM = 'ITEM',
  ITEM_WITH_OPTIONS = 'ITEM_WITH_OPTIONS',
  OPTION = 'OPTION',
}

export type ItemsFilterBy = {
  contingencyDraws?: InputMaybe<Array<Scalars['UUID']['input']>>;
  creators: Array<Scalars['UUID']['input']>;
  currentMilestoneID?: InputMaybe<Scalars['UUID']['input']>;
  dueDateEnd?: InputMaybe<Scalars['Time']['input']>;
  dueDateStart?: InputMaybe<Scalars['Time']['input']>;
  integrations: Array<IntegrationFilterType>;
  scheduleImpacts?: InputMaybe<Array<Scalars['ScheduleImpactFilter']['input']>>;
  searchText: Scalars['String']['input'];
  showOptions: Scalars['Boolean']['input'];
  statuses: Array<Status>;
  visibilities: Array<Visibility>;
};

export enum ItemsGroupKey {
  GROUP_ASSIGNEE = 'GROUP_ASSIGNEE',
  GROUP_CATEGORY = 'GROUP_CATEGORY',
  GROUP_CREATOR = 'GROUP_CREATOR',
  GROUP_MEETING = 'GROUP_MEETING',
  GROUP_MILESTONE = 'GROUP_MILESTONE',
  GROUP_SCHEDULE_IMPACT = 'GROUP_SCHEDULE_IMPACT',
  GROUP_STATUS = 'GROUP_STATUS',
  GROUP_VISIBILITY = 'GROUP_VISIBILITY',
}

export type ItemsList = {
  __typename?: 'ItemsList';
  items: Array<ItemsListItem>;
};

export type ItemsListItem = {
  __typename?: 'ItemsListItem';
  activityIDs?: Maybe<Array<Scalars['UUID']['output']>>;
  assetCount: Scalars['Int']['output'];
  assigneeID?: Maybe<Scalars['UUID']['output']>;
  availableStates: Array<Status>;
  categories: Array<CategoryLink>;
  commentCount: Scalars['Int']['output'];
  cost: Cost;
  createdAt: Scalars['Time']['output'];
  creator: UserLink;
  currentCost: Cost;
  currentMilestone: MilestoneLink;
  currentStatus: Status;
  dueDate?: Maybe<Scalars['Time']['output']>;
  hasEstimate: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  isOverlay?: Maybe<Scalars['Boolean']['output']>;
  itemType: ItemType;
  meeting?: Maybe<MeetingLink>;
  milestone: MilestoneLink;
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  options: Array<ItemLink>;
  overlay?: Maybe<OverlayDiff>;
  parent?: Maybe<ItemLink>;
  parentID?: Maybe<Scalars['UUID']['output']>;
  remoteAssetCount: Scalars['Int']['output'];
  scheduleImpact: ScheduleImpact;
  status: Status;
  updateTime: Scalars['Time']['output'];
  visibility: Visibility;
};

export type ItemsListParameters = {
  activityID?: InputMaybe<Scalars['UUID']['input']>;
  costMode: CostMode;
  integrations: Array<IntegrationFilterType>;
  loadItemCosts?: InputMaybe<Scalars['Boolean']['input']>;
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
  showOptions: Scalars['Boolean']['input'];
  viewFilter: ViewFilter;
  visibilityView?: InputMaybe<VisibilityView>;
};

export type ItemsListTotalCost = {
  __typename?: 'ItemsListTotalCost';
  cost: Cost;
};

export type ItemsListTotalCostParameters = {
  costMode: CostMode;
  itemIDs: Array<Scalars['UUID']['input']>;
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
  viewFilter: ViewFilter;
};

export type ItemsOwnership = {
  __typename?: 'ItemsOwnership';
  assigneeRoleName: Scalars['String']['output'];
  itemCount: Scalars['Int']['output'];
  itemPercentage: Scalars['Float']['output'];
};

export type ItemsSortBy = {
  categorizationID?: InputMaybe<Scalars['UUID']['input']>;
  sortKey: Scalars['String']['input'];
};

export enum ItemsSortKey {
  SORT_CATEGORY = 'SORT_CATEGORY',
  SORT_COST_IMPACT = 'SORT_COST_IMPACT',
  SORT_COST_IMPACT_MAGNITUDE = 'SORT_COST_IMPACT_MAGNITUDE',
  SORT_CREATION_TIME = 'SORT_CREATION_TIME',
  SORT_CREATOR = 'SORT_CREATOR',
  SORT_DATE_MODIFIED = 'SORT_DATE_MODIFIED',
  SORT_DUE_DATE = 'SORT_DUE_DATE',
  SORT_NONE = 'SORT_NONE',
  SORT_NUMBER = 'SORT_NUMBER',
  SORT_SCHEDULE_IMPACT = 'SORT_SCHEDULE_IMPACT',
  SORT_STATUS = 'SORT_STATUS',
  SORT_VISIBILITY = 'SORT_VISIBILITY',
}

export type ItemsTree = {
  __typename?: 'ItemsTree';
  branches: Array<ItemsTreeBranch>;
  items: Array<ItemsTreeItem>;
  level: Scalars['Int']['output'];
  orderedItemIDs: Array<Scalars['UUID']['output']>;
  totalItems: Scalars['Int']['output'];
};

export type ItemsTreeBranch = {
  __typename?: 'ItemsTreeBranch';
  branches: Array<ItemsTreeBranch>;
  displayName: Scalars['String']['output'];
  displayNameForPrint: Scalars['String']['output'];
  items: Array<ItemsTreeItem>;
  key: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  nodeCosts?: Maybe<ItemsTreeBranchNodeCosts>;
  totalItems: Scalars['Int']['output'];
};

export type ItemsTreeBranchNodeCosts = {
  __typename?: 'ItemsTreeBranchNodeCosts';
  acceptedCost: Cost;
  pendingAddsDeductsCost: AddDeductCost;
  rejectedAddsDeductsCost: AddDeductCost;
  runningTotalCost: Cost;
  targetCost: Cost;
};

export type ItemsTreeItem = {
  __typename?: 'ItemsTreeItem';
  cost?: Maybe<Cost>;
  itemLikeID: Scalars['UUID']['output'];
  options: Array<ItemsTreeOption>;
};

export type ItemsTreeOption = {
  __typename?: 'ItemsTreeOption';
  cost?: Maybe<Cost>;
  itemLikeID: Scalars['UUID']['output'];
};

export type ItemsTreeParameters = {
  activityID?: InputMaybe<Scalars['UUID']['input']>;
  costMode: CostMode;
  groupByItems: Array<GroupByItem>;
  itemsFilterBy: ItemsFilterBy;
  itemsGroupBy?: InputMaybe<Scalars['String']['input']>;
  itemsSortBy: ItemsSortBy;
  meetingID?: InputMaybe<Scalars['UUID']['input']>;
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
  viewFilter: ViewFilter;
};

export type Line = {
  __typename?: 'Line';
  cells: Array<Cell>;
  estimateId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  orderingDenominator: Scalars['Ordering']['output'];
  orderingNumerator: Scalars['Ordering']['output'];
};

export type LineCreateResult = {
  __typename?: 'LineCreateResult';
  estimateUpdateResult?: Maybe<EstimateUpdateResult>;
  lines: Array<Line>;
};

export type LineInput = {
  fields: Array<Scalars['UUID']['input']>;
  values: Array<Scalars['String']['input']>;
};

export type LineReorderResult = {
  __typename?: 'LineReorderResult';
  lines: Array<Line>;
};

export type LocationDetails = {
  __typename?: 'LocationDetails';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  lat: Scalars['Float']['output'];
  lon: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
  stateLong: Scalars['String']['output'];
};

export type LocationDetailsInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  state: Scalars['String']['input'];
  stateLong: Scalars['String']['input'];
};

export type LocationEscalation = {
  __typename?: 'LocationEscalation';
  from: CityCostIndex;
  fuzzyMatch: Scalars['Boolean']['output'];
  percentage: Scalars['Float']['output'];
  sourceIsFuzzyMatch: Scalars['Boolean']['output'];
  targetIsFuzzyMatch: Scalars['Boolean']['output'];
  to: CityCostIndex;
};

export type LocationEscalationInput = {
  from: LocationInput;
  to: LocationInput;
};

export type LocationInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lon?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
};

export type Markup = {
  __typename?: 'Markup';
  categoryFilters: Array<Category>;
  costTypeFilters?: Maybe<Array<CostType>>;
  disabled: Scalars['Boolean']['output'];
  displayType: MarkupDisplayType;
  drawFromID?: Maybe<Scalars['UUID']['output']>;
  errors: Array<MarkupError>;
  estimateId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  markupReference?: Maybe<MarkupReference>;
  name: Scalars['String']['output'];
  orderingDenominator: Scalars['Ordering']['output'];
  orderingNumerator: Scalars['Ordering']['output'];
  percent?: Maybe<Scalars['Int']['output']>;
  percentScale?: Maybe<Scalars['Int']['output']>;
  shouldExcludeFromAllocation: Scalars['Boolean']['output'];
  shouldNotApplyCategoryFiltersToOtherMarkups: Scalars['Boolean']['output'];
  source?: Maybe<SourceIDs>;
  sourceFilterIDs: Array<Scalars['UUID']['output']>;
  sourceFilterIDsWithoutS2Reference: Array<Scalars['UUID']['output']>;
  sourceFilters?: Maybe<Array<ItemLink>>;
  total: Scalars['USCents']['output'];
  type: MarkupType;
  value?: Maybe<Scalars['Numeric']['output']>;
};

export type MarkupContribution = {
  __typename?: 'MarkupContribution';
  displayType: MarkupDisplayType;
  isIncorporated: Scalars['Boolean']['output'];
  isOwnerCost: Scalars['Boolean']['output'];
  markupId: Scalars['UUID']['output'];
  markupName: Scalars['String']['output'];
  range: Cost;
  segmented: AddDeductCost;
};

export enum MarkupDisplayType {
  ALLOWANCE = 'ALLOWANCE',
  CONTINGENCY = 'CONTINGENCY',
  DRAW = 'DRAW',
  MARKUP = 'MARKUP',
}

export type MarkupError = {
  __typename?: 'MarkupError';
  error: Scalars['String']['output'];
  field: Scalars['String']['output'];
};

export type MarkupInput = {
  categoryFilterIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  costTypeFilters?: InputMaybe<Array<CostType>>;
  displayType?: InputMaybe<MarkupDisplayType>;
  drawFromID?: InputMaybe<Scalars['UUID']['input']>;
  id: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  percent?: InputMaybe<Scalars['Int']['input']>;
  percentScale?: InputMaybe<Scalars['Int']['input']>;
  references?: InputMaybe<Array<Scalars['String']['input']>>;
  shouldExcludeFromAllocation?: InputMaybe<Scalars['Boolean']['input']>;
  shouldNotApplyCategoryFiltersToOtherMarkups?: InputMaybe<Scalars['Boolean']['input']>;
  sourceFilterIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  sourceFilterIDsWithoutS2Reference?: InputMaybe<Array<Scalars['UUID']['input']>>;
  type?: InputMaybe<MarkupType>;
  value?: InputMaybe<Scalars['Numeric']['input']>;
};

export type MarkupItemReference = {
  __typename?: 'MarkupItemReference';
  isDeprecated: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
};

export type MarkupLineInput = {
  cellType: ProjectCompsCostTableCellType;
  description?: InputMaybe<Scalars['String']['input']>;
  editedValue: Scalars['USCents']['input'];
  markupName: Scalars['String']['input'];
  unitID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MarkupLineInputReturn = {
  __typename?: 'MarkupLineInputReturn';
  cellType: ProjectCompsCostTableCellType;
  description?: Maybe<Scalars['String']['output']>;
  editedValue: Scalars['USCents']['output'];
  markupName: Scalars['String']['output'];
  unitID?: Maybe<Scalars['UUID']['output']>;
};

export enum MarkupMode {
  ALLOCATED_MARKUPS = 'ALLOCATED_MARKUPS',
  NO_MARKUPS = 'NO_MARKUPS',
  SEPARATED_MARKUPS = 'SEPARATED_MARKUPS',
}

export type MarkupReference = {
  __typename?: 'MarkupReference';
  appliesTo: Array<Scalars['String']['output']>;
  item?: Maybe<MarkupItemReference>;
};

export type MarkupReorderResult = {
  __typename?: 'MarkupReorderResult';
  markups: Array<Markup>;
};

export enum MarkupType {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
}

export type MarkupUpdateResult = {
  __typename?: 'MarkupUpdateResult';
  estimateUpdateResult?: Maybe<EstimateUpdateResult>;
  markups: Array<Markup>;
};

export type MeetingLink = {
  __typename?: 'MeetingLink';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type Mention = {
  __typename?: 'Mention';
  id: Scalars['UUID']['output'];
  position: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  user: User;
};

export type MentionInput = {
  position: Scalars['Int']['input'];
  text: Scalars['String']['input'];
  userID: Scalars['UUID']['input'];
};

export type MentionableText = {
  __typename?: 'MentionableText';
  contents: Scalars['String']['output'];
  itemReferences?: Maybe<Array<ItemReference>>;
  mentions?: Maybe<Array<Mention>>;
};

export type MentionableTextInput = {
  contents: Scalars['String']['input'];
  itemReferences?: InputMaybe<Array<ItemReferenceInput>>;
  mentions?: InputMaybe<Array<MentionInput>>;
};

export type MetricsInput = {
  hasMilestoneQuantity?: InputMaybe<Scalars['Boolean']['input']>;
  quantityMagnitude?: InputMaybe<Scalars['String']['input']>;
  unitID: Scalars['UUID']['input'];
};

export type MetricsInputReturn = {
  __typename?: 'MetricsInputReturn';
  hasMilestoneQuantity: Scalars['Boolean']['output'];
  quantityMagnitude?: Maybe<Scalars['String']['output']>;
  unitID: Scalars['UUID']['output'];
};

export type Milestone = {
  __typename?: 'Milestone';
  activeEstimateDraftID?: Maybe<Scalars['UUID']['output']>;
  activeEstimateID?: Maybe<Scalars['UUID']['output']>;
  assetCount: Scalars['Int']['output'];
  assets: Array<Asset>;
  buckets?: Maybe<Array<Bucket>>;
  budgetDraftID?: Maybe<Scalars['UUID']['output']>;
  budgetID?: Maybe<Scalars['UUID']['output']>;
  createdBy?: Maybe<User>;
  date?: Maybe<Scalars['Time']['output']>;
  deletable: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  descriptionStyled: Scalars['String']['output'];
  designPhase?: Maybe<DesignPhaseType>;
  draftItemsCount: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  importedEstimates: Array<Asset>;
  isDraft: Scalars['Boolean']['output'];
  itemsCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  quantities?: Maybe<Array<Maybe<Quantity>>>;
  remoteAssetCount: Scalars['Int']['output'];
  timelinePhase?: Maybe<TimelineActivity>;
};

export type MilestoneColumn = {
  __typename?: 'MilestoneColumn';
  costReports: MilestoneCostReports;
  items: Array<ItemsListItem>;
  milestoneID: Scalars['UUID']['output'];
};

export type MilestoneContingencyInfo = ContingencyInfo & {
  __typename?: 'MilestoneContingencyInfo';
  error: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  isBeingDrawn?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  total: Scalars['USCents']['output'];
  type: MarkupDisplayType;
};

export type MilestoneCostReports = {
  __typename?: 'MilestoneCostReports';
  costReportColumns: Array<CostReportColumn>;
  date?: Maybe<Scalars['Time']['output']>;
  milestoneID: Scalars['UUID']['output'];
};

export type MilestoneCostTrendsBreakdown = {
  __typename?: 'MilestoneCostTrendsBreakdown';
  categoryID?: Maybe<Scalars['UUID']['output']>;
  milestoneCostReports: Array<MilestoneCostReports>;
  segments: Array<Segment>;
};

export type MilestoneDateVisibility = {
  __typename?: 'MilestoneDateVisibility';
  dates: Array<VisibleDate>;
  milestoneID: Scalars['UUID']['output'];
};

export type MilestoneEstimateInfo = {
  estimateType: EstimateType;
  milestoneID: Scalars['UUID']['input'];
};

export type MilestoneInput = {
  activeEstimateID?: InputMaybe<Scalars['UUID']['input']>;
  date?: InputMaybe<Scalars['Time']['input']>;
  designPhaseID?: InputMaybe<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isDraft: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  projectID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MilestoneLink = {
  __typename?: 'MilestoneLink';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type MilestoneMarkupsInfo = {
  __typename?: 'MilestoneMarkupsInfo';
  activeEstimate?: Maybe<EstimateMarkupsInfo>;
  budget?: Maybe<EstimateMarkupsInfo>;
  id: Scalars['UUID']['output'];
};

export type MilestoneNotesCount = {
  __typename?: 'MilestoneNotesCount';
  milestoneID: Scalars['UUID']['output'];
  noteCount: Scalars['Int']['output'];
};

export type MonthlyItemBreakdown = {
  __typename?: 'MonthlyItemBreakdown';
  cost: Scalars['USCents']['output'];
  status: Status;
};

export type MonthlyProjectCostRange = {
  __typename?: 'MonthlyProjectCostRange';
  itemBreakdown: Array<MonthlyItemBreakdown>;
  month: Scalars['String']['output'];
  negativeCost: Scalars['USCents']['output'];
  positiveCost: Scalars['USCents']['output'];
  totalCost: Scalars['USCents']['output'];
};

export type MultiMilestoneContingency = {
  __typename?: 'MultiMilestoneContingency';
  initialAmountSet: Scalars['USCents']['output'];
  initialPercentOfMilestoneEstimate?: Maybe<Scalars['USCents']['output']>;
  name: Scalars['String']['output'];
  report: Array<ContingencyReportContingency>;
  type: MarkupDisplayType;
};

export type MultiMilestoneContingencyMilestone = {
  __typename?: 'MultiMilestoneContingencyMilestone';
  isActiveMilestone: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  startDate?: Maybe<Scalars['Time']['output']>;
};

export type MultiMilestoneContingencyReportData = {
  __typename?: 'MultiMilestoneContingencyReportData';
  contingencies: Array<MultiMilestoneContingency>;
  milestones: Array<MultiMilestoneContingencyMilestone>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptImportEstimateError: Scalars['Boolean']['output'];
  acceptTermsOfService: Scalars['Boolean']['output'];
  addCollaborators: Array<Collaborator>;
  addCompanyCategorizationsToProject: Array<ProjectCategorization>;
  addCompanyEmployee?: Maybe<CompanyUser>;
  addFields: Estimate;
  addItemToScenarios: Scalars['Boolean']['output'];
  addOrgLevel: Org;
  addQuantityBreakdown: Quantity;
  addScenarioToSandbox: Scalars['Boolean']['output'];
  addTrades: Collaborator;
  applyMilestoneDraftEstimate: Milestone;
  applyScenario: Scalars['Boolean']['output'];
  attachItemAsOption?: Maybe<Item>;
  attachToItem?: Maybe<Array<Maybe<Attachment>>>;
  attachToMilestone?: Maybe<Array<Maybe<Attachment>>>;
  bulkItemUpdate: BulkItemsUpdateOutput;
  commentOnItem: Array<Comment>;
  connectUserSource: Scalars['Boolean']['output'];
  contingencyDrawEvent: Scalars['Boolean']['output'];
  convertItemToItemWithOptions?: Maybe<Item>;
  copyItem: CopyItem;
  copyScenario?: Maybe<Scalars['UUID']['output']>;
  createAlert: CreateAlertPayload;
  createCategories: CreateCategoryResult;
  createCategoriesFromImport?: Maybe<CreateCategoryResult>;
  createCategorization: CreateCategorizationPayload;
  createCompany: Company;
  createCompanyCategorization: Categorization;
  createCompanyUser: CompanyUser;
  createContingencyDraw: Scalars['Boolean']['output'];
  createItem?: Maybe<Item>;
  createItemEstimate?: Maybe<EstimateCreationResult>;
  createItemIntegrationObject: CreateItemIntegrationObject;
  createItemsFromImport?: Maybe<CreateItemsFromImportPayload>;
  createLines: LineCreateResult;
  createMarkups: Array<Markup>;
  createMilestone: Array<Maybe<Milestone>>;
  createMilestoneDraftEstimate: Milestone;
  createMilestoneDraftEstimateFromPreviousMilestone: Milestone;
  createOption?: Maybe<Item>;
  createOrUpdateBookmark: Array<Bookmark>;
  createOrUpdateBucket: Array<Bucket>;
  createOrUpdateCustomUnit: Unit;
  createOrg: CreateOrgPayload;
  createOrgNode: CreateOrgNodePayload;
  createOwnerCostEstimate: Scalars['UUID']['output'];
  createProject?: Maybe<Project>;
  createProjectBanner: Banner;
  createProjectComparisonReport: ProjectComparisonReport;
  createProjectRole: Scalars['UUID']['output'];
  createProjectTemplate?: Maybe<ProjectTemplate>;
  createProjectType: ProjectType;
  createQuantity: Quantity;
  createReportDistribution: Scalars['Boolean']['output'];
  createScenarioInSandbox?: Maybe<Scalars['UUID']['output']>;
  createScenarioItem?: Maybe<Item>;
  createTimelineActivity: TimelineActivity;
  createUserReport: UserReport;
  createUserReportComment: UserReportComment;
  deleteBucket: Array<Bucket>;
  deleteCategory: Scalars['String']['output'];
  deleteCompanyCategorization?: Maybe<Scalars['UUID']['output']>;
  deleteForecastingReport: Scalars['Boolean']['output'];
  deleteItemEstimate: DeleteItemEstimatePayload;
  deleteMilestoneDraftEstimate: Scalars['UUID']['output'];
  deleteOrg: DeleteOrgPayload;
  deleteOrgLevel: Org;
  deleteOrgNode: DeleteOrgNodePayload;
  deleteProjectRole: Scalars['Boolean']['output'];
  deleteProjectTemplate: Scalars['UUID']['output'];
  deleteProjectType?: Maybe<Scalars['UUID']['output']>;
  deleteReportDistribution: Scalars['Boolean']['output'];
  deleteScenario: Scalars['Boolean']['output'];
  deleteScenarioItem: Scalars['Boolean']['output'];
  deleteTimelineActivity?: Maybe<Scalars['UUID']['output']>;
  deleteUserReportComment: Scalars['UUID']['output'];
  deprecateAlert: DeprecateAlertPayload;
  deprecateItem: Scalars['UUID']['output'];
  detachFromItem?: Maybe<Array<Maybe<Attachment>>>;
  detachFromMilestone?: Maybe<Array<Maybe<Attachment>>>;
  detachOption: Array<ItemLike>;
  duplicateAlert: DuplicateAlertPayload;
  editCategories: Array<Category>;
  editCategorization: Categorization;
  exportAverageCompToExistingMilestone: Milestone;
  exportAverageCompToNewMilestone: Milestone;
  finalizeImportEstimate: Milestone;
  linkIntegrationsEntity: Scalars['Boolean']['output'];
  removeActiveProjectBanner?: Maybe<Scalars['UUID']['output']>;
  removeBookmarkFromItem: Array<Bookmark>;
  removeCategorization: Scalars['UUID']['output'];
  removeCategorizations?: Maybe<RemoveCategorizationsPayload>;
  removeCollaborator: Scalars['Boolean']['output'];
  removeCustomUnit?: Maybe<Scalars['UUID']['output']>;
  removeFields: Estimate;
  removeItemCategory: ItemLike;
  removeItemFromScenarios: Scalars['Boolean']['output'];
  removeItemMilestoneHistory: EventData;
  removeLines: EstimateUpdateResult;
  removeMarkups: EstimateUpdateResult;
  removeMilestone: Scalars['UUID']['output'];
  removeProjectBanner?: Maybe<Scalars['UUID']['output']>;
  removeProjectLead: RemoveProjectLeadPayload;
  removeProjectsLead: RemoveProjectsLeadPayload;
  removeQuantityBreakdown: Quantity;
  removeScenarioFromSandbox: Scalars['Boolean']['output'];
  removeTrades: Collaborator;
  removeUserReport?: Maybe<Scalars['UUID']['output']>;
  removeUserSource: Scalars['Boolean']['output'];
  reorderCategorization?: Maybe<Scalars['Boolean']['output']>;
  reorderField: Estimate;
  reorderLines: LineReorderResult;
  reorderMarkups: MarkupReorderResult;
  replaceCategories?: Maybe<ReplaceCategoriesPayload>;
  replaceFieldCategories: CellUpdateResult;
  reportImportEstimateTimeEvent: Scalars['Boolean']['output'];
  reportItemAssistExperimentRequest: Scalars['Boolean']['output'];
  reportUserEvent: Scalars['Boolean']['output'];
  resendInvite: CompanyUser;
  resetProjectTerminology: Array<Terminology>;
  sendEmailVerification: Scalars['Boolean']['output'];
  setActiveProjectBanner: Banner;
  setAllInAppNotificationsRead: Array<Scalars['UUID']['output']>;
  setBookmarkIndexInItem: Array<Bookmark>;
  setBulkProjectOrgs: Array<SetProjectOrgsPayload>;
  setCollaboratorAllTrades: Collaborator;
  setCollaboratorResponsibility: Collaborator;
  setCollaboratorRole: Collaborator;
  setCollaboratorTrades: Scalars['Boolean']['output'];
  setDisabledProjectCategorization: ProjectCategorization;
  setDisabledProjectCategorizations: Array<Scalars['UUID']['output']>;
  setEstimateTotalType: Estimate;
  setInAppNotificationRead: InAppNotification;
  setItemActivity: ItemLike;
  setItemAssignee: ItemLike;
  setItemBucket: ItemLike;
  setItemCategory: ItemLike;
  setItemDescription: ItemLike;
  setItemDueDate: ItemLike;
  setItemMilestone: ItemLike;
  setItemName: ItemLike;
  setItemNumber: ItemLike;
  setItemPublished: ItemLike;
  setMilestoneDate: Array<Maybe<Milestone>>;
  setMilestoneDescription: Milestone;
  setMilestoneDesignPhase: Milestone;
  setMilestoneName: Milestone;
  setMilestonePublishDraft: Milestone;
  setMilestoneTimelinePhase: Milestone;
  setOrgLevel: Org;
  setOrgName: Org;
  setOrgNodeName: SetOrgNodeNamePayload;
  setOrgPublished: SetOrgPublishedPayload;
  setProjectLead: SetProjectLeadPayload;
  setProjectOrgs: SetProjectOrgsPayload;
  setProjectTemplatePublication?: Maybe<ProjectTemplate>;
  setProjectType?: Maybe<Project>;
  setProjectsLead: SetProjectLeadPayload;
  setRoleHasTrade: Scalars['Boolean']['output'];
  setRoleName: Scalars['String']['output'];
  setStatus: ItemLike;
  shareResource?: Maybe<ShareResourcePayload>;
  shareResources?: Maybe<ShareResourcesPayload>;
  toggleAlert: ToggleAlertPayload;
  toggleAllMilestonesTrendDateVisibility: ProjectDateVisibility;
  toggleBuiltInCategorization?: Maybe<Scalars['Boolean']['output']>;
  toggleEventDataVisibility: ToggleEventDataResult;
  toggleInheritedItemMarkup: EstimateUpdateResult;
  toggleMarkupWithoutS2Reference: EstimateUpdateResult;
  toggleProjectTypeVisibility: UpdateProjectTypePayload;
  toggleProjectUnits: Array<Unit>;
  unlinkItemIntegrationObject?: Maybe<Scalars['UUID']['output']>;
  unsetItemActivity: ItemLike;
  unsubscribeReportDistribution: Scalars['Boolean']['output'];
  updateBulkCompanyProjectType: Array<ProjectStats>;
  updateCells: CellUpdateResult;
  updateCompany: Company;
  updateCompanyProjectType?: Maybe<ProjectStats>;
  updateCompanyUserCompanyRole?: Maybe<CompanyUser>;
  updateCompanyUserJobTitle?: Maybe<User>;
  updateCompanyUserName?: Maybe<User>;
  updateCompanyUserPicture?: Maybe<User>;
  updateCompanyUserStatus?: Maybe<User>;
  updateMarkups: MarkupUpdateResult;
  updatePermission: Permission;
  updateProject?: Maybe<Project>;
  updateProjectComparisonReport: ProjectComparisonReport;
  updateProjectSetting: Array<ProjectSetting>;
  updateProjectTemplateName?: Maybe<ProjectTemplate>;
  updateProjectTerminology: Array<Terminology>;
  updateProjectType: UpdateProjectTypePayload;
  updateReportDistribution: Scalars['Boolean']['output'];
  updateSandbox: Scalars['Boolean']['output'];
  updateScenarioItemOverlays: ScenarioSandbox;
  updateScenarioMetadata: ScenarioMetadata;
  updateScheduleImpact?: Maybe<UpdateScheduleImpactPayload>;
  updateTimelineActivity: TimelineActivity;
  updateTrigger: UpdateTriggerPayload;
  updateUserInAppNotificationSetting: UserNotificationSetting;
  updateUserJobTitle: User;
  updateUserName: User;
  updateUserNotificationSetting: UserNotificationSetting;
  updateUserPicture: User;
  updateUserReport: UserReport;
  updateUserReportComment: UserReportComment;
  upsertCategorizationImportMetadata: CategorizationImportMetadata;
  upsertProjectCompanyRole: ProjectCompany;
  userDidSetup: User;
  verifyEmail: Scalars['Boolean']['output'];
};

export type MutationAcceptImportEstimateErrorArgs = {
  errorID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationAddCollaboratorsArgs = {
  collaborators: Array<CollaboratorInput>;
  disableNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  projectID: Scalars['UUID']['input'];
};

export type MutationAddCompanyCategorizationsToProjectArgs = {
  categorizationIDs: Array<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
};

export type MutationAddCompanyEmployeeArgs = {
  input: CompanyEmployeeInput;
};

export type MutationAddFieldsArgs = {
  estimateID: Scalars['UUID']['input'];
  fields: Array<FieldInput>;
  projectID: Scalars['UUID']['input'];
};

export type MutationAddItemToScenariosArgs = {
  itemID: Scalars['UUID']['input'];
  scenarioIDs: Array<Scalars['UUID']['input']>;
};

export type MutationAddOrgLevelArgs = {
  input: AddOrgLevelInput;
};

export type MutationAddQuantityBreakdownArgs = {
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  quantity: QuantityInputAddBreakdown;
};

export type MutationAddScenarioToSandboxArgs = {
  milestoneID: Scalars['UUID']['input'];
  scenarioID: Scalars['UUID']['input'];
};

export type MutationAddTradesArgs = {
  categoryIDs: Array<Scalars['UUID']['input']>;
  collaboratorID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationApplyMilestoneDraftEstimateArgs = {
  estimateID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationApplyScenarioArgs = {
  costMode: CostMode;
  milestoneID: Scalars['UUID']['input'];
  scenarioID: Scalars['UUID']['input'];
};

export type MutationAttachItemAsOptionArgs = {
  child: Scalars['UUID']['input'];
  costMode: CostMode;
  parent: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationAttachToItemArgs = {
  assetInput?: InputMaybe<AssetInput>;
  itemID: Scalars['UUID']['input'];
  remoteAssetInput?: InputMaybe<RemoteAssetInput>;
};

export type MutationAttachToMilestoneArgs = {
  assetInput?: InputMaybe<AssetInput>;
  milestoneID: Scalars['UUID']['input'];
  remoteAssetInput?: InputMaybe<RemoteAssetInput>;
};

export type MutationBulkItemUpdateArgs = {
  costMode: CostMode;
  items: BulkItemsUpdateInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationCommentOnItemArgs = {
  comment?: InputMaybe<ItemCommentInput>;
  costMode?: InputMaybe<CostMode>;
  projectID: Scalars['UUID']['input'];
};

export type MutationConnectUserSourceArgs = {
  host?: InputMaybe<Scalars['String']['input']>;
  publicToken: Scalars['String']['input'];
  sourceSystem: SourceSystem;
};

export type MutationContingencyDrawEventArgs = {
  currentValue: Scalars['Numeric']['input'];
  drawFromID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
  isFirst: Scalars['Boolean']['input'];
  itemID: Scalars['UUID']['input'];
  markupType: MarkupType;
  projectID: Scalars['UUID']['input'];
  value: Scalars['Numeric']['input'];
};

export type MutationConvertItemToItemWithOptionsArgs = {
  costMode: CostMode;
  item: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationCopyItemArgs = {
  input: ItemCopyInput;
  itemID: Scalars['UUID']['input'];
};

export type MutationCopyScenarioArgs = {
  color: Scalars['String']['input'];
  milestoneID: Scalars['UUID']['input'];
  scenarioID: Scalars['UUID']['input'];
};

export type MutationCreateAlertArgs = {
  input: CreateAlertInput;
};

export type MutationCreateCategoriesArgs = {
  categories: Array<CategoryContentInput>;
  categorizationID: Scalars['UUID']['input'];
  projectID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateCategoriesFromImportArgs = {
  assetID: Scalars['UUID']['input'];
  categories: Array<CategoryMapInput>;
  categorizationID: Scalars['UUID']['input'];
  metadata: CategorizationImportMetadataInput;
  projectID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateCategorizationArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateCompanyArgs = {
  assetID?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type MutationCreateCompanyCategorizationArgs = {
  companyID: Scalars['UUID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type MutationCreateCompanyUserArgs = {
  companyID: Scalars['UUID']['input'];
  isRejected: Scalars['Boolean']['input'];
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateContingencyDrawArgs = {
  contingencyID?: InputMaybe<Scalars['UUID']['input']>;
  estimateID: Scalars['UUID']['input'];
  itemID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateItemArgs = {
  costMode: CostMode;
  item: ItemCreationInput;
};

export type MutationCreateItemEstimateArgs = {
  item: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateItemIntegrationObjectArgs = {
  input: CreateItemIntegrationObjectInput;
  itemID: Scalars['UUID']['input'];
  sourceCompanyID?: InputMaybe<Scalars['String']['input']>;
  sourceID: Scalars['UUID']['input'];
  sourceProjectID: Scalars['String']['input'];
  sourceSystem: SourceSystem;
};

export type MutationCreateItemsFromImportArgs = {
  assetID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  visibility: Visibility;
};

export type MutationCreateLinesArgs = {
  estimateID: Scalars['UUID']['input'];
  lines: Array<LineInput>;
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateMarkupsArgs = {
  estimate: Scalars['UUID']['input'];
  number: Scalars['Int']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateMilestoneArgs = {
  milestone?: InputMaybe<MilestoneInput>;
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateMilestoneDraftEstimateArgs = {
  estimateTotalType: EstimateTotalType;
  estimateType: EstimateType;
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateMilestoneDraftEstimateFromPreviousMilestoneArgs = {
  currentEstimateType: EstimateType;
  currentMilestoneID: Scalars['UUID']['input'];
  incorporateAccepted: Scalars['Boolean']['input'];
  isRunningTotal: Scalars['Boolean']['input'];
  previousEstimateType: EstimateType;
  previousMilestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateOptionArgs = {
  costMode: CostMode;
  item: ItemCreationInput;
  parent: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateOrUpdateBookmarkArgs = {
  bookmark: BookmarkInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateOrUpdateBucketArgs = {
  bucket: BucketInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateOrUpdateCustomUnitArgs = {
  projectID: Scalars['UUID']['input'];
  unit: UnitInput;
};

export type MutationCreateOrgArgs = {
  input: CreateOrgInput;
};

export type MutationCreateOrgNodeArgs = {
  input: CreateOrgNodeInput;
};

export type MutationCreateOwnerCostEstimateArgs = {
  estimateID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateProjectArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  location: Scalars['String']['input'];
  locationDetails: LocationDetailsInput;
  lon?: InputMaybe<Scalars['Float']['input']>;
  milestone: Scalars['String']['input'];
  milestoneDate?: InputMaybe<Scalars['Time']['input']>;
  milestoneDesignPhaseID?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  orgNodeIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  projectDeliveryTypeID?: InputMaybe<Scalars['UUID']['input']>;
  statusID: Scalars['UUID']['input'];
  templateID?: InputMaybe<Scalars['UUID']['input']>;
  typeID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateProjectBannerArgs = {
  assetID: Scalars['UUID']['input'];
  assetName: Scalars['String']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateProjectComparisonReportArgs = {
  attachedProjectID?: InputMaybe<Scalars['UUID']['input']>;
  projectCompsSetInput: ProjectCompsSetInput;
  reportDescription: Scalars['String']['input'];
  reportName: Scalars['String']['input'];
};

export type MutationCreateProjectRoleArgs = {
  projectID: Scalars['UUID']['input'];
  roleID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateProjectTemplateArgs = {
  name: Scalars['String']['input'];
  templateID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateProjectTypeArgs = {
  companyID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  parentID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateQuantityArgs = {
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  quantity: QuantityInputCreate;
};

export type MutationCreateReportDistributionArgs = {
  input: ReportDistributionInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateScenarioInSandboxArgs = {
  color: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  itemOverlays: Array<ItemOverlay>;
  milestoneID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type MutationCreateScenarioItemArgs = {
  costMode: CostMode;
  itemInput: ScenarioItemCreationInput;
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  scenarioID: Scalars['UUID']['input'];
};

export type MutationCreateTimelineActivityArgs = {
  input: TimelineActivityInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationCreateUserReportArgs = {
  projectID: Scalars['UUID']['input'];
  userReport: UserReportInput;
};

export type MutationCreateUserReportCommentArgs = {
  costMode?: InputMaybe<CostMode>;
  projectID: Scalars['UUID']['input'];
  reportCommentInput: UserReportCommentInput;
  userReportID: Scalars['UUID']['input'];
};

export type MutationDeleteBucketArgs = {
  bucket: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationDeleteCategoryArgs = {
  category: CategoryInput;
  projectID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationDeleteCompanyCategorizationArgs = {
  categorizationID: Scalars['UUID']['input'];
};

export type MutationDeleteForecastingReportArgs = {
  reportID: Scalars['UUID']['input'];
};

export type MutationDeleteItemEstimateArgs = {
  input: DeleteItemEstimateInput;
};

export type MutationDeleteMilestoneDraftEstimateArgs = {
  estimateID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationDeleteOrgArgs = {
  input: DeleteOrgInput;
};

export type MutationDeleteOrgLevelArgs = {
  input: DeleteOrgLevelInput;
};

export type MutationDeleteOrgNodeArgs = {
  input: DeleteOrgNodeInput;
};

export type MutationDeleteProjectRoleArgs = {
  collaboratorRoleInputs: Array<CollaboratorRoleInput>;
  projectID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
};

export type MutationDeleteProjectTemplateArgs = {
  templateID: Scalars['UUID']['input'];
};

export type MutationDeleteProjectTypeArgs = {
  typeID: Scalars['UUID']['input'];
};

export type MutationDeleteReportDistributionArgs = {
  projectID: Scalars['UUID']['input'];
  reportDistributionID: Scalars['UUID']['input'];
};

export type MutationDeleteScenarioArgs = {
  scenarioID: Scalars['UUID']['input'];
};

export type MutationDeleteScenarioItemArgs = {
  itemID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
};

export type MutationDeleteTimelineActivityArgs = {
  activityID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationDeleteUserReportCommentArgs = {
  commentID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationDeprecateAlertArgs = {
  input: DeprecateAlertInput;
};

export type MutationDeprecateItemArgs = {
  itemID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationDetachFromItemArgs = {
  assetID?: InputMaybe<Scalars['UUID']['input']>;
  itemID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  remoteAssetID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationDetachFromMilestoneArgs = {
  assetID?: InputMaybe<Scalars['UUID']['input']>;
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  remoteAssetID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationDetachOptionArgs = {
  costMode: CostMode;
  option: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationDuplicateAlertArgs = {
  input: DuplicateAlertInput;
};

export type MutationEditCategoriesArgs = {
  categories: Array<EditCategoryInput>;
  categorizationID: Scalars['UUID']['input'];
  projectID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationEditCategorizationArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  projectID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationExportAverageCompToExistingMilestoneArgs = {
  exportAverageCompEstimateInput: ExportAverageCompEstimateInput;
  milestoneID: Scalars['UUID']['input'];
  projectCompsSetInput: ProjectCompsSetInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationExportAverageCompToNewMilestoneArgs = {
  exportAverageCompEstimateInput: ExportAverageCompEstimateInput;
  milestoneInput: MilestoneInput;
  projectCompsSetInput: ProjectCompsSetInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationFinalizeImportEstimateArgs = {
  draftCategorizations: Array<DraftCategorization>;
  draftID: Scalars['UUID']['input'];
  estimateTotalType: EstimateTotalType;
  projectID: Scalars['UUID']['input'];
};

export type MutationLinkIntegrationsEntityArgs = {
  projectID: Scalars['UUID']['input'];
  sourceCompanyID?: InputMaybe<Scalars['String']['input']>;
  sourceEntityID: Scalars['String']['input'];
  sourceEntityType: SourceType;
  sourceSystem: SourceSystem;
};

export type MutationRemoveActiveProjectBannerArgs = {
  projectID: Scalars['UUID']['input'];
};

export type MutationRemoveBookmarkFromItemArgs = {
  bookmark: Scalars['UUID']['input'];
  item: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationRemoveCategorizationArgs = {
  id: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationRemoveCategorizationsArgs = {
  categorizationIDs: Array<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
};

export type MutationRemoveCollaboratorArgs = {
  collaborator: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationRemoveCustomUnitArgs = {
  projectID: Scalars['UUID']['input'];
  unitID: Scalars['UUID']['input'];
};

export type MutationRemoveFieldsArgs = {
  estimateID: Scalars['UUID']['input'];
  fieldIDs: Array<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
};

export type MutationRemoveItemCategoryArgs = {
  category: Scalars['UUID']['input'];
  costMode: CostMode;
  id: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationRemoveItemFromScenariosArgs = {
  itemID: Scalars['UUID']['input'];
  scenarioIDs: Array<Scalars['UUID']['input']>;
};

export type MutationRemoveItemMilestoneHistoryArgs = {
  id: Scalars['UUID']['input'];
  milestone: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationRemoveLinesArgs = {
  estimateID: Scalars['UUID']['input'];
  lineIDs: Array<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
};

export type MutationRemoveMarkupsArgs = {
  estimate: Scalars['UUID']['input'];
  markupIDs: Array<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
  viewFilter?: InputMaybe<ViewFilter>;
};

export type MutationRemoveMilestoneArgs = {
  milestone: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationRemoveProjectBannerArgs = {
  bannerID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationRemoveProjectLeadArgs = {
  projectID: Scalars['UUID']['input'];
  userID: Scalars['UUID']['input'];
};

export type MutationRemoveProjectsLeadArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type MutationRemoveQuantityBreakdownArgs = {
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  quantity: QuantityInputRemoveBreakdown;
};

export type MutationRemoveScenarioFromSandboxArgs = {
  milestoneID: Scalars['UUID']['input'];
  scenarioID: Scalars['UUID']['input'];
};

export type MutationRemoveTradesArgs = {
  categoryIDs: Array<Scalars['UUID']['input']>;
  collaboratorID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationRemoveUserReportArgs = {
  projectID: Scalars['UUID']['input'];
  reportID: Scalars['UUID']['input'];
};

export type MutationRemoveUserSourceArgs = {
  sourceID: Scalars['UUID']['input'];
};

export type MutationReorderCategorizationArgs = {
  categorizationID: Scalars['UUID']['input'];
  ordering: Scalars['Int']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationReorderFieldArgs = {
  estimateID: Scalars['UUID']['input'];
  fieldID: Scalars['UUID']['input'];
  ordering: Scalars['Int']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationReorderLinesArgs = {
  estimate: Scalars['UUID']['input'];
  lines: Array<ReorderInput>;
  projectID: Scalars['UUID']['input'];
};

export type MutationReorderMarkupsArgs = {
  estimate: Scalars['UUID']['input'];
  markups: Array<ReorderInput>;
  projectID: Scalars['UUID']['input'];
};

export type MutationReplaceCategoriesArgs = {
  assetID?: InputMaybe<Scalars['UUID']['input']>;
  categories: Array<CategoryMapInput>;
  categorizationID: Scalars['UUID']['input'];
  metadata?: InputMaybe<CategorizationImportMetadataInput>;
  projectID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationReplaceFieldCategoriesArgs = {
  estimateID: Scalars['UUID']['input'];
  fieldID: Scalars['UUID']['input'];
  fromCategories: Array<Scalars['String']['input']>;
  projectID: Scalars['UUID']['input'];
  toCategories: Array<Scalars['UUID']['input']>;
};

export type MutationReportImportEstimateTimeEventArgs = {
  loadTime: Scalars['Float']['input'];
  milestoneEstimateID: Scalars['UUID']['input'];
  type: UserEventType;
};

export type MutationReportItemAssistExperimentRequestArgs = {
  additionalInfo: Scalars['String']['input'];
  connectWithBPM: Scalars['Boolean']['input'];
  itemID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationReportUserEventArgs = {
  userEvent: UserEventInput;
};

export type MutationResendInviteArgs = {
  inviteeUserID: Scalars['UUID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  projectID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationResetProjectTerminologyArgs = {
  key: Scalars['String']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationSendEmailVerificationArgs = {
  url: Scalars['String']['input'];
};

export type MutationSetActiveProjectBannerArgs = {
  assetID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationSetAllInAppNotificationsReadArgs = {
  allProjects: Scalars['Boolean']['input'];
  projectID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationSetBookmarkIndexInItemArgs = {
  bookmark: Scalars['UUID']['input'];
  index: Scalars['Int']['input'];
  item: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationSetBulkProjectOrgsArgs = {
  input: SetBulkProjectOrgsInput;
};

export type MutationSetCollaboratorAllTradesArgs = {
  allTrades: Scalars['Boolean']['input'];
  collaboratorID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationSetCollaboratorResponsibilityArgs = {
  collaboratorID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  responsibility: Scalars['String']['input'];
};

export type MutationSetCollaboratorRoleArgs = {
  collaboratorID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
  tradeIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type MutationSetCollaboratorTradesArgs = {
  allTrades: Scalars['Boolean']['input'];
  categoryIDs: Array<Scalars['UUID']['input']>;
  collaboratorID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationSetDisabledProjectCategorizationArgs = {
  categorizationID: Scalars['UUID']['input'];
  disabled: Scalars['Boolean']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationSetDisabledProjectCategorizationsArgs = {
  categorizationIDs: Array<Scalars['UUID']['input']>;
  disable: Scalars['Boolean']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationSetEstimateTotalTypeArgs = {
  estimateID: Scalars['UUID']['input'];
  estimateTotalType: EstimateTotalType;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetInAppNotificationReadArgs = {
  costMode?: InputMaybe<CostMode>;
  isRead: Scalars['Boolean']['input'];
  notificationEventID: Scalars['UUID']['input'];
};

export type MutationSetItemActivityArgs = {
  costMode: CostMode;
  payload: SetActivityInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetItemAssigneeArgs = {
  costMode: CostMode;
  payload: SetAssigneeInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetItemBucketArgs = {
  costMode: CostMode;
  payload: SetBucketInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetItemCategoryArgs = {
  category: Scalars['UUID']['input'];
  costMode: CostMode;
  id: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationSetItemDescriptionArgs = {
  costMode: CostMode;
  description: Scalars['String']['input'];
  descriptionStyled?: InputMaybe<Scalars['String']['input']>;
  itemID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationSetItemDueDateArgs = {
  costMode: CostMode;
  payload: SetDueDateInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetItemMilestoneArgs = {
  costMode: CostMode;
  itemMilestoneInput: SetMilestoneInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetItemNameArgs = {
  costMode: CostMode;
  itemID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationSetItemNumberArgs = {
  costMode: CostMode;
  payload: SetNumberInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetItemPublishedArgs = {
  costMode: CostMode;
  payload: SetVisibilityInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetMilestoneDateArgs = {
  milestone?: InputMaybe<SetMilestoneDateInput>;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetMilestoneDescriptionArgs = {
  milestone?: InputMaybe<SetMilestoneDescriptionInput>;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetMilestoneDesignPhaseArgs = {
  milestone: SetMilestoneDesignPhaseInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetMilestoneNameArgs = {
  milestone?: InputMaybe<SetMilestoneNameInput>;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetMilestonePublishDraftArgs = {
  milestone?: InputMaybe<SetMilestonePublishDraftInput>;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetMilestoneTimelinePhaseArgs = {
  input: SetMilestoneTimelinePhaseInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationSetOrgLevelArgs = {
  input: SetOrgLevelInput;
};

export type MutationSetOrgNameArgs = {
  input: SetOrgNameInput;
};

export type MutationSetOrgNodeNameArgs = {
  input: SetOrgNodeNameInput;
};

export type MutationSetOrgPublishedArgs = {
  input: SetOrgPublishedInput;
};

export type MutationSetProjectLeadArgs = {
  projectID: Scalars['UUID']['input'];
  userID: Scalars['UUID']['input'];
};

export type MutationSetProjectOrgsArgs = {
  input: SetProjectOrgsInput;
};

export type MutationSetProjectTemplatePublicationArgs = {
  published: Scalars['Boolean']['input'];
  templateID: Scalars['UUID']['input'];
};

export type MutationSetProjectTypeArgs = {
  projectID: Scalars['UUID']['input'];
  typeID: Scalars['UUID']['input'];
};

export type MutationSetProjectsLeadArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
  userID: Scalars['UUID']['input'];
};

export type MutationSetRoleHasTradeArgs = {
  hasTrade: Scalars['Boolean']['input'];
  projectID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
};

export type MutationSetRoleNameArgs = {
  name: Scalars['String']['input'];
  projectID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
};

export type MutationSetStatusArgs = {
  costMode: CostMode;
  itemStatusInput: SetStatusInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationShareResourceArgs = {
  input: ShareResourceInput;
};

export type MutationShareResourcesArgs = {
  input: ShareResourcesInput;
};

export type MutationToggleAlertArgs = {
  input: ToggleAlertInput;
};

export type MutationToggleAllMilestonesTrendDateVisibilityArgs = {
  intervalType: IntervalType;
  projectID: Scalars['UUID']['input'];
  toggles: Array<DateVisibilityToggle>;
};

export type MutationToggleBuiltInCategorizationArgs = {
  categorizationID: Scalars['UUID']['input'];
  disabled: Scalars['Boolean']['input'];
};

export type MutationToggleEventDataVisibilityArgs = {
  events: Array<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
  visible: Scalars['Boolean']['input'];
};

export type MutationToggleInheritedItemMarkupArgs = {
  disabled: Scalars['Boolean']['input'];
  estimateId: Scalars['UUID']['input'];
  markupId: Scalars['UUID']['input'];
  projectID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationToggleMarkupWithoutS2ReferenceArgs = {
  estimateID: Scalars['UUID']['input'];
  markupID: Scalars['UUID']['input'];
  withoutS2Reference: Scalars['Boolean']['input'];
};

export type MutationToggleProjectTypeVisibilityArgs = {
  hide?: InputMaybe<Scalars['Boolean']['input']>;
  typeID: Scalars['UUID']['input'];
};

export type MutationToggleProjectUnitsArgs = {
  projectID: Scalars['UUID']['input'];
  units: Array<UnitToggleInput>;
};

export type MutationUnlinkItemIntegrationObjectArgs = {
  input: UnlinkItemIntegrationObjectInput;
  itemID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  sourceID: Scalars['UUID']['input'];
  sourceSystem: SourceSystem;
  sourceType: SourceType;
};

export type MutationUnsetItemActivityArgs = {
  costMode: CostMode;
  payload: UnsetActivityInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationUnsubscribeReportDistributionArgs = {
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  reportDistributionID: Scalars['UUID']['input'];
};

export type MutationUpdateBulkCompanyProjectTypeArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
  typeID: Scalars['UUID']['input'];
};

export type MutationUpdateCellsArgs = {
  cells: Array<CellInput>;
  estimateID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationUpdateCompanyArgs = {
  assetID?: InputMaybe<Scalars['UUID']['input']>;
  companyID: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateCompanyProjectTypeArgs = {
  projectID: Scalars['UUID']['input'];
  typeID: Scalars['UUID']['input'];
};

export type MutationUpdateCompanyUserCompanyRoleArgs = {
  roleID: Scalars['UUID']['input'];
  userID: Scalars['UUID']['input'];
};

export type MutationUpdateCompanyUserJobTitleArgs = {
  jobTitle: Scalars['String']['input'];
  userID: Scalars['UUID']['input'];
};

export type MutationUpdateCompanyUserNameArgs = {
  name: Scalars['String']['input'];
  userID: Scalars['UUID']['input'];
};

export type MutationUpdateCompanyUserPictureArgs = {
  picture: Scalars['String']['input'];
  userID: Scalars['UUID']['input'];
};

export type MutationUpdateCompanyUserStatusArgs = {
  status: Scalars['UserStatus']['input'];
  userID: Scalars['UUID']['input'];
};

export type MutationUpdateMarkupsArgs = {
  estimate: Scalars['UUID']['input'];
  markups: Array<MarkupInput>;
  projectID: Scalars['UUID']['input'];
  viewFilter?: InputMaybe<ViewFilter>;
};

export type MutationUpdatePermissionArgs = {
  level: PermissionLevel;
  permission: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationUpdateProjectArgs = {
  project?: InputMaybe<ProjectInput>;
};

export type MutationUpdateProjectComparisonReportArgs = {
  attachedProjectID?: InputMaybe<Scalars['UUID']['input']>;
  projectCompsSetInput?: InputMaybe<ProjectCompsSetInput>;
  reportDescription?: InputMaybe<Scalars['String']['input']>;
  reportID: Scalars['UUID']['input'];
  reportName?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateProjectSettingArgs = {
  key: Scalars['String']['input'];
  projectID: Scalars['UUID']['input'];
  value: Scalars['String']['input'];
};

export type MutationUpdateProjectTemplateNameArgs = {
  name: Scalars['String']['input'];
  templateID: Scalars['UUID']['input'];
};

export type MutationUpdateProjectTerminologyArgs = {
  key: Scalars['String']['input'];
  overrideValue: Scalars['String']['input'];
  projectID: Scalars['UUID']['input'];
};

export type MutationUpdateProjectTypeArgs = {
  name: Scalars['String']['input'];
  parentID?: InputMaybe<Scalars['UUID']['input']>;
  typeID: Scalars['UUID']['input'];
};

export type MutationUpdateReportDistributionArgs = {
  input: ReportDistributionInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationUpdateSandboxArgs = {
  milestoneID: Scalars['UUID']['input'];
  scenarioIDs: Array<Scalars['UUID']['input']>;
};

export type MutationUpdateScenarioItemOverlaysArgs = {
  costMode: CostMode;
  itemOverlays: Array<ItemOverlay>;
  milestoneID: Scalars['UUID']['input'];
  scenarioID: Scalars['UUID']['input'];
};

export type MutationUpdateScenarioMetadataArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  scenarioID: Scalars['UUID']['input'];
  thumbnailAssetID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationUpdateScheduleImpactArgs = {
  input: ScheduleImpactInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationUpdateTimelineActivityArgs = {
  activityID: Scalars['UUID']['input'];
  input: TimelineActivityInput;
  projectID: Scalars['UUID']['input'];
};

export type MutationUpdateTriggerArgs = {
  input: UpdateTriggerInput;
};

export type MutationUpdateUserInAppNotificationSettingArgs = {
  projectID: Scalars['UUID']['input'];
  userNotificationSetting: UserNotificationSettingInput;
};

export type MutationUpdateUserJobTitleArgs = {
  jobTitle: Scalars['String']['input'];
};

export type MutationUpdateUserNameArgs = {
  name: Scalars['String']['input'];
};

export type MutationUpdateUserNotificationSettingArgs = {
  projectID: Scalars['UUID']['input'];
  userNotificationSetting: UserNotificationSettingInput;
};

export type MutationUpdateUserPictureArgs = {
  picture: Scalars['String']['input'];
};

export type MutationUpdateUserReportArgs = {
  projectID: Scalars['UUID']['input'];
  userReport: UserReportInput;
};

export type MutationUpdateUserReportCommentArgs = {
  commentID: Scalars['UUID']['input'];
  costMode?: InputMaybe<CostMode>;
  costs: Array<Scalars['USCents']['input']>;
  projectID: Scalars['UUID']['input'];
  text: MentionableTextInput;
};

export type MutationUpsertCategorizationImportMetadataArgs = {
  categorizationID: Scalars['UUID']['input'];
  metadata: CategorizationImportMetadataInput;
};

export type MutationUpsertProjectCompanyRoleArgs = {
  companyID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  role: Scalars['String']['input'];
};

export type MutationVerifyEmailArgs = {
  emailVerify: Scalars['String']['input'];
};

export type NewComment = {
  __typename?: 'NewComment';
  comment: Comment;
  item: ItemLink;
  project: Project;
  triggeringUser: User;
};

export type NewItem = {
  __typename?: 'NewItem';
  item: ItemLink;
  project: Project;
  triggeringUser: User;
};

export type NewMention = {
  __typename?: 'NewMention';
  comment: Comment;
  item: ItemLink;
  project: Project;
  triggeringUser: User;
};

export type NewOption = {
  __typename?: 'NewOption';
  option: ItemLink;
  parentItem: ItemLink;
  project: Project;
  triggeringUser: User;
};

export type NewReportMention = {
  __typename?: 'NewReportMention';
  project: Project;
  reportID: Scalars['UUID']['output'];
  reportName: Scalars['String']['output'];
  triggeringUser: User;
  userReportComment: UserReportComment;
};

export enum NotificationChannel {
  EMAIL = 'Email',
  INAPP = 'InApp',
}

export type NotificationContent =
  | AssignItem
  | AssignProjectLead
  | InviteToProject
  | NewComment
  | NewItem
  | NewMention
  | NewOption
  | NewReportMention
  | RemoveFromProject
  | ShareDraftItem
  | ShareScenario;

export enum NotificationType {
  ASSIGNITEM = 'AssignItem',
  ASSIGNPROJECTLEAD = 'AssignProjectLead',
  INVITETOCOMPANY = 'InviteToCompany',
  INVITETOPROJECT = 'InviteToProject',
  NEWCOMMENT = 'NewComment',
  NEWITEM = 'NewItem',
  NEWMENTION = 'NewMention',
  NEWOPTION = 'NewOption',
  NEWREPORTMENTION = 'NewReportMention',
  REMOVEFROMPROJECT = 'RemoveFromProject',
  REPLYTOTHREAD = 'ReplyToThread',
  RESENDCOMPANYINVITE = 'ResendCompanyInvite',
  RESENDPROJECTINVITE = 'ResendProjectInvite',
  SENDVERIFYEMAIL = 'SendVerifyEmail',
  SHAREDRAFTITEM = 'ShareDraftItem',
  SHARESCENARIO = 'ShareScenario',
}

export type Option = {
  __typename?: 'Option';
  activeEstimate?: Maybe<Estimate>;
  activityIDs?: Maybe<Array<Scalars['UUID']['output']>>;
  assetCount: Scalars['Int']['output'];
  assets: Array<Asset>;
  assignee?: Maybe<User>;
  availableStates: Array<Status>;
  bucket?: Maybe<Bucket>;
  categories: Array<Category>;
  commentCount: Scalars['Int']['output'];
  comments: Array<Comment>;
  contingencyDrawCost?: Maybe<Cost>;
  cost?: Maybe<Cost>;
  createdBy?: Maybe<User>;
  creationTime?: Maybe<Scalars['Time']['output']>;
  description: Scalars['String']['output'];
  descriptionStyled: Scalars['String']['output'];
  dueDate?: Maybe<Scalars['Time']['output']>;
  estimate?: Maybe<Estimate>;
  estimateCost?: Maybe<Cost>;
  filteredCategories?: Maybe<Array<Scalars['UUID']['output']>>;
  filteredMilestone?: Maybe<Scalars['UUID']['output']>;
  hasEstimate?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  itemType: ItemType;
  milestone?: Maybe<Milestone>;
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  parent: Scalars['UUID']['output'];
  parentVisibility?: Maybe<Visibility>;
  previousMilestoneStatus: Array<PreviousMilestoneStatus>;
  project: Project;
  remoteAssetCount: Scalars['Int']['output'];
  scheduleImpact?: Maybe<ScheduleImpact>;
  status: Status;
  unfilteredCost?: Maybe<Cost>;
  updateTime?: Maybe<Scalars['Time']['output']>;
  visibility: Visibility;
};

export type OptionSubtotal = {
  __typename?: 'OptionSubtotal';
  adds: Scalars['USCents']['output'];
  deducts: Scalars['USCents']['output'];
  status: Status;
};

export type Org = {
  __typename?: 'Org';
  id: Scalars['UUID']['output'];
  isDraft: Scalars['Boolean']['output'];
  levels: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nodes: Array<OrgNode>;
};

export type OrgNode = {
  __typename?: 'OrgNode';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  parentID?: Maybe<Scalars['UUID']['output']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
};

export type OrganizationInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
};

export type OverlayDiff = {
  __typename?: 'OverlayDiff';
  baseStatus?: Maybe<Status>;
};

export type PcMarkup = {
  __typename?: 'PCMarkup';
  isCustom: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type PageLoadEventInput = {
  loadTime: Scalars['Float']['input'];
  page: Scalars['String']['input'];
};

export type Pagination = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PaginationByOffsetId = {
  limit: Scalars['Int']['input'];
  offsetID: Scalars['UUID']['input'];
};

export type PaginationData = {
  __typename?: 'PaginationData';
  nextOffsetID?: Maybe<Scalars['UUID']['output']>;
};

export type PartialAddDeductCost = {
  __typename?: 'PartialAddDeductCost';
  adds?: Maybe<Scalars['USCents']['output']>;
  deducts?: Maybe<Scalars['USCents']['output']>;
};

export type PartialRole = {
  __typename?: 'PartialRole';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type Permission = {
  __typename?: 'Permission';
  actions: Actions;
  id: Scalars['UUID']['output'];
  level: PermissionLevel;
  resource: PermissionResource;
};

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  permissions: Array<Permission>;
  type: PermissionGroupType;
};

export enum PermissionGroupType {
  COMPANY = 'COMPANY',
  COST = 'COST',
  ITEM_IN_TRADE = 'ITEM_IN_TRADE',
  ITEM_OUT_OF_TRADE = 'ITEM_OUT_OF_TRADE',
  MILESTONE = 'MILESTONE',
  MODULE = 'MODULE',
  PROJECT = 'PROJECT',
}

export enum PermissionLevel {
  ADMIN = 'ADMIN',
  NONE = 'NONE',
  STANDARD = 'STANDARD',
  VIEW_ONLY = 'VIEW_ONLY',
}

export enum PermissionResource {
  ALLOCATED_MARKUPS_VIEW = 'ALLOCATED_MARKUPS_VIEW',
  ALL_COMPANY_SEARCH_ACCESS = 'ALL_COMPANY_SEARCH_ACCESS',
  ALL_PROJECTS_ACCESS = 'ALL_PROJECTS_ACCESS',
  CATEGORIES_AND_TAGS = 'CATEGORIES_AND_TAGS',
  COLLABORATORS = 'COLLABORATORS',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  COMPANY_STANDARDS = 'COMPANY_STANDARDS',
  DISPLAY_COSTS = 'DISPLAY_COSTS',
  DRAFT_ESTIMATE = 'DRAFT_ESTIMATE',
  DRAFT_MILESTONE = 'DRAFT_MILESTONE',
  DRAW_EVENTS_VIEW = 'DRAW_EVENTS_VIEW',
  ESTIMATE_COST_SUBTOTALS = 'ESTIMATE_COST_SUBTOTALS',
  GROSS_VIEW = 'GROSS_VIEW',
  INSIGHTS_ACCESS = 'INSIGHTS_ACCESS',
  ITEM_ASSIGNEES = 'ITEM_ASSIGNEES',
  ITEM_ATTACHMENTS = 'ITEM_ATTACHMENTS',
  ITEM_CATEGORIES = 'ITEM_CATEGORIES',
  ITEM_COMMENTS = 'ITEM_COMMENTS',
  ITEM_DETAILS = 'ITEM_DETAILS',
  ITEM_INTEGRATIONS = 'ITEM_INTEGRATIONS',
  ITEM_IN_TRADE_LINES = 'ITEM_IN_TRADE_LINES',
  ITEM_LIST = 'ITEM_LIST',
  ITEM_MILESTONE_AND_MEETINGS = 'ITEM_MILESTONE_AND_MEETINGS',
  ITEM_NUMBER = 'ITEM_NUMBER',
  ITEM_OUT_OF_TRADE_LINES = 'ITEM_OUT_OF_TRADE_LINES',
  ITEM_PRIORITIES = 'ITEM_PRIORITIES',
  ITEM_STATUS = 'ITEM_STATUS',
  ITEM_TEMPLATE = 'ITEM_TEMPLATE',
  MARKUPS = 'MARKUPS',
  MEETINGS = 'MEETINGS',
  METRICS = 'METRICS',
  MILESTONES = 'MILESTONES',
  MILESTONE_ATTACHMENTS = 'MILESTONE_ATTACHMENTS',
  MILESTONE_BUDGET = 'MILESTONE_BUDGET',
  MILESTONE_LINES = 'MILESTONE_LINES',
  NET_VIEW = 'NET_VIEW',
  NO_MARKUPS_VIEW = 'NO_MARKUPS_VIEW',
  OWNER_COSTS = 'OWNER_COSTS',
  PERMISSION_TEMPLATES = 'PERMISSION_TEMPLATES',
  PROJECT_COMPS_ACCESS = 'PROJECT_COMPS_ACCESS',
  PROJECT_CURRENCY = 'PROJECT_CURRENCY',
  PROJECT_DETAILS = 'PROJECT_DETAILS',
  PROJECT_PRIORITIES = 'PROJECT_PRIORITIES',
  PROJECT_TEMPLATE_ACCESS = 'PROJECT_TEMPLATE_ACCESS',
  PROJECT_TERMINOLOGY = 'PROJECT_TERMINOLOGY',
  PROJECT_UNITS = 'PROJECT_UNITS',
  PUBLISH_DRAFT_ITEMS = 'PUBLISH_DRAFT_ITEMS',
  PUBLISH_ITEMS_SCENARIOS = 'PUBLISH_ITEMS_SCENARIOS',
  REPORT_DISTRIBUTION = 'REPORT_DISTRIBUTION',
  SCHEDULE_IMPACT = 'SCHEDULE_IMPACT',
  SEPARATED_MARKUPS_VIEW = 'SEPARATED_MARKUPS_VIEW',
  SHARED_USER_REPORTS = 'SHARED_USER_REPORTS',
  SHARE_DRAFT_ITEMS = 'SHARE_DRAFT_ITEMS',
  SHARE_ITEMS_SCENARIOS = 'SHARE_ITEMS_SCENARIOS',
  TIMELINE = 'TIMELINE',
  USER_DETAILS = 'USER_DETAILS',
  USER_REPORT_NOTES = 'USER_REPORT_NOTES',
}

export type PermissionRow = {
  __typename?: 'PermissionRow';
  levels: Array<PermissionLevel>;
  resource: PermissionResource;
};

export type PermissionTableGroup = {
  __typename?: 'PermissionTableGroup';
  rows: Array<PermissionRow>;
  type: PermissionGroupType;
};

export type PermissionsAnalyticsProperties = {
  __typename?: 'PermissionsAnalyticsProperties';
  previewMode: Scalars['Boolean']['output'];
  previewRole?: Maybe<Scalars['String']['output']>;
  previewRoleId?: Maybe<Scalars['UUID']['output']>;
  previewUserEmail?: Maybe<Scalars['String']['output']>;
  previewUserId?: Maybe<Scalars['UUID']['output']>;
  userRole: Scalars['String']['output'];
};

export type Point = {
  __typename?: 'Point';
  dateEnd: Scalars['Time']['output'];
  dateStart: Scalars['Time']['output'];
  isVisible: Scalars['Boolean']['output'];
  values: Array<PointValue>;
};

export type PointValue = {
  __typename?: 'PointValue';
  isChanged: Scalars['Boolean']['output'];
  type: CostReportColumnType;
  y: Scalars['USCents']['output'];
};

export type PreviousMilestoneStatus = {
  __typename?: 'PreviousMilestoneStatus';
  contingencyDrawCost?: Maybe<Cost>;
  cost?: Maybe<Cost>;
  estimateCost?: Maybe<Cost>;
  hasEstimate?: Maybe<Scalars['Boolean']['output']>;
  itemType: ItemType;
  milestoneID: Scalars['UUID']['output'];
  optionSubtotals: Array<OptionSubtotal>;
  status: Status;
};

export type PrivateItemsViewFilter = {
  excludePrivateItems?: InputMaybe<Scalars['Boolean']['input']>;
  filterItemCreator?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProcoreChangeEvent = ItemIntegrationObject & {
  __typename?: 'ProcoreChangeEvent';
  alphanumericNumber: Scalars['String']['output'];
  changeReason: Scalars['String']['output'];
  cost: Scalars['USCents']['output'];
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  eventScope: Scalars['String']['output'];
  eventType: Scalars['String']['output'];
  integrationObjectID: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type Project = {
  __typename?: 'Project';
  activeMilestone: Milestone;
  categorizations: Array<Categorization>;
  code?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  descriptionStyled: Scalars['String']['output'];
  hasAccess: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  lastUpdated: Scalars['Time']['output'];
  lat?: Maybe<Scalars['Float']['output']>;
  location: Scalars['String']['output'];
  locationDetails?: Maybe<LocationDetails>;
  lon?: Maybe<Scalars['Float']['output']>;
  milestones: Array<Milestone>;
  name: Scalars['String']['output'];
  orgNodes: Array<OrgNode>;
  projectDeliveryType?: Maybe<ProjectDeliveryType>;
  roles: Array<Role>;
  status: ProjectStatus;
  team?: Maybe<Team>;
  templateEstimateID: Scalars['UUID']['output'];
  thumbnail: Scalars['String']['output'];
  type: ProjectType;
  updatedBy?: Maybe<User>;
  variant?: Maybe<ProjectVariant>;
};

export type ProjectActiveUsers = {
  __typename?: 'ProjectActiveUsers';
  month: Scalars['Time']['output'];
  projectID: Scalars['UUID']['output'];
  userCount: Scalars['Int']['output'];
};

export type ProjectAnalyticsProperties = {
  __typename?: 'ProjectAnalyticsProperties';
  projectId: Scalars['UUID']['output'];
  projectLocation: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
  projectStatus: Scalars['String']['output'];
};

export type ProjectBreakdownReportInput = {
  costMode: CostMode;
  estimateType: EstimateType;
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type ProjectBudgetGap = {
  __typename?: 'ProjectBudgetGap';
  accepted: Scalars['USCents']['output'];
  budget: Scalars['USCents']['output'];
  estimate: Scalars['USCents']['output'];
  projectID: Scalars['UUID']['output'];
  relativeGap: Scalars['Float']['output'];
  runningTotal: Scalars['USCents']['output'];
};

export type ProjectCategorization = {
  __typename?: 'ProjectCategorization';
  categorization: Categorization;
  disabled: Scalars['Boolean']['output'];
  ordering?: Maybe<Scalars['Int']['output']>;
  projectID: Scalars['UUID']['output'];
};

export type ProjectChartInfo = {
  __typename?: 'ProjectChartInfo';
  code?: Maybe<Scalars['String']['output']>;
  hasAccess: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  milestoneName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ProjectCollaborators = {
  __typename?: 'ProjectCollaborators';
  collaborators: Array<Collaborator>;
  projectID: Scalars['UUID']['output'];
};

export type ProjectComp = {
  __typename?: 'ProjectComp';
  input: ProjectCompInputReturn;
  isResettable: Scalars['Boolean']['output'];
  milestone: Milestone;
  project: Project;
  projectCompsCostTable: ProjectCompsCostTable;
};

export type ProjectCompEscalation = {
  __typename?: 'ProjectCompEscalation';
  location?: Maybe<Scalars['String']['output']>;
  locationMeta?: Maybe<ProjectCompEscalationMeta>;
  time?: Maybe<Scalars['String']['output']>;
  timeFuture?: Maybe<Scalars['String']['output']>;
  timeMeta?: Maybe<ProjectCompEscalationMeta>;
};

export type ProjectCompEscalationInput = {
  location?: InputMaybe<Scalars['String']['input']>;
  locationMeta?: InputMaybe<ProjectCompEscalationMetaInput>;
  time?: InputMaybe<Scalars['String']['input']>;
  timeFuture?: InputMaybe<Scalars['String']['input']>;
  timeMeta?: InputMaybe<ProjectCompEscalationMetaInput>;
};

export type ProjectCompEscalationMeta = {
  __typename?: 'ProjectCompEscalationMeta';
  isAutoEscalated: Scalars['Boolean']['output'];
  isFuzzyMatch: Scalars['Boolean']['output'];
  sourceIsFuzzyMatch: Scalars['Boolean']['output'];
  sourceLabel: Scalars['String']['output'];
  targetIsFuzzyMatch: Scalars['Boolean']['output'];
  targetLabel: Scalars['String']['output'];
};

export type ProjectCompEscalationMetaInput = {
  isAutoEscalated?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzyMatch?: InputMaybe<Scalars['Boolean']['input']>;
  sourceIsFuzzyMatch?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLabel?: InputMaybe<Scalars['String']['input']>;
  targetIsFuzzyMatch?: InputMaybe<Scalars['Boolean']['input']>;
  targetLabel?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCompInput = {
  categorizationID?: InputMaybe<Scalars['UUID']['input']>;
  categorizationLevel?: InputMaybe<Scalars['Int']['input']>;
  categoryLineInputs?: InputMaybe<Array<CategoryLineInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  escalation?: InputMaybe<ProjectCompEscalationInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isExcluded?: InputMaybe<Scalars['Boolean']['input']>;
  markupLineInputs?: InputMaybe<Array<MarkupLineInput>>;
  metrics?: InputMaybe<Array<MetricsInput>>;
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectID: Scalars['UUID']['input'];
  viewFilter?: InputMaybe<ViewFilter>;
};

export type ProjectCompInputReturn = {
  __typename?: 'ProjectCompInputReturn';
  categorizationID?: Maybe<Scalars['UUID']['output']>;
  categorizationLevel?: Maybe<Scalars['Int']['output']>;
  categoryLineInputs: Array<CategoryLineInputReturn>;
  description?: Maybe<Scalars['String']['output']>;
  escalation?: Maybe<ProjectCompEscalation>;
  id: Scalars['UUID']['output'];
  isExcluded?: Maybe<Scalars['Boolean']['output']>;
  markupLineInputs: Array<MarkupLineInputReturn>;
  metrics: Array<MetricsInputReturn>;
  milestoneID?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectID: Scalars['UUID']['output'];
  viewFilter?: Maybe<ViewFilterReturn>;
};

export type ProjectCompany = {
  __typename?: 'ProjectCompany';
  company: Company;
  role?: Maybe<Scalars['String']['output']>;
};

export type ProjectComparisonReport = {
  __typename?: 'ProjectComparisonReport';
  attachedProject?: Maybe<Project>;
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  metadata: ProjectComparisonReportMetadata;
  name: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
  updatedBy: User;
};

export type ProjectComparisonReportMetadata = {
  __typename?: 'ProjectComparisonReportMetadata';
  averageQuantityCost?: Maybe<Scalars['USCents']['output']>;
  averageTotalCost?: Maybe<Scalars['USCents']['output']>;
  pinnedUnit: Unit;
  pinnedUnitQuantityMagnitude?: Maybe<Scalars['Numeric']['output']>;
  projectsCount: Scalars['Int']['output'];
};

export type ProjectCompsCostTable = {
  __typename?: 'ProjectCompsCostTable';
  categoryLines: Array<ProjectCompsCostTableCategoryLine>;
  markupLines: Array<ProjectCompsCostTableMarkupLine>;
  summaryLines: ProjectCompsCostTableSummaryLines;
};

export type ProjectCompsCostTableCategoryLine = {
  __typename?: 'ProjectCompsCostTableCategoryLine';
  category: Category;
  columnValues: Array<ProjectCompsCostTableColumnValue>;
  description?: Maybe<Scalars['String']['output']>;
  pinnedUnitValues: ProjectCompsCostTableCostValues;
};

export enum ProjectCompsCostTableCellType {
  QUANTITY = 'QUANTITY',
  TOTAL = 'TOTAL',
}

export type ProjectCompsCostTableColumnValue = {
  __typename?: 'ProjectCompsCostTableColumnValue';
  costTableColumnInput: CostTableColumnInputReturn;
  hasError: Scalars['Boolean']['output'];
  isEdited: Scalars['Boolean']['output'];
  isMaxValue: Scalars['Boolean']['output'];
  isMinValue: Scalars['Boolean']['output'];
  valueNumeric: Scalars['Numeric']['output'];
};

export type ProjectCompsCostTableCostValues = {
  __typename?: 'ProjectCompsCostTableCostValues';
  percentValue: Scalars['Float']['output'];
  quantityMagnitude: Scalars['Numeric']['output'];
  quantityValue: Cost;
  totalValue: Cost;
};

export type ProjectCompsCostTableMarkupLine = {
  __typename?: 'ProjectCompsCostTableMarkupLine';
  columnValues: Array<ProjectCompsCostTableColumnValue>;
  description?: Maybe<Scalars['String']['output']>;
  markupName: Scalars['String']['output'];
  pinnedUnitValues: ProjectCompsCostTableCostValues;
};

export type ProjectCompsCostTableSummaryLine = {
  __typename?: 'ProjectCompsCostTableSummaryLine';
  columnValues: Array<ProjectCompsCostTableColumnValue>;
  pinnedUnitValues: ProjectCompsCostTableCostValues;
};

export type ProjectCompsCostTableSummaryLines = {
  __typename?: 'ProjectCompsCostTableSummaryLines';
  markupsTotalLine?: Maybe<ProjectCompsCostTableSummaryLine>;
  subtotalLine?: Maybe<ProjectCompsCostTableSummaryLine>;
  totalLine: ProjectCompsCostTableSummaryLine;
};

export type ProjectCompsSet = {
  __typename?: 'ProjectCompsSet';
  averageComp?: Maybe<AverageComp>;
  categories: Array<Category>;
  input: ProjectCompsSetInputReturn;
  markups: Array<PcMarkup>;
  projectComps: Array<ProjectComp>;
  selectedUnits: Array<Unit>;
  unitCounts: Array<UnitCount>;
  units: Array<Unit>;
};

export type ProjectCompsSetInput = {
  addedMarkupNames?: InputMaybe<Array<Scalars['String']['input']>>;
  averageInput?: InputMaybe<AverageCompInput>;
  costTableColumnInputs?: InputMaybe<Array<CostTableColumnInput>>;
  excludedCategoryContents?: InputMaybe<Array<CategoryContentInput>>;
  excludedMarkupNames?: InputMaybe<Array<Scalars['String']['input']>>;
  markupMode?: InputMaybe<MarkupMode>;
  pinnedUnitID?: InputMaybe<Scalars['UUID']['input']>;
  projectCompInputs: Array<ProjectCompInput>;
  selectedUnitIDs: Array<Scalars['UUID']['input']>;
};

export type ProjectCompsSetInputReturn = {
  __typename?: 'ProjectCompsSetInputReturn';
  addedMarkupNames: Array<Scalars['String']['output']>;
  averageInput?: Maybe<AverageCompInputReturn>;
  costTableColumnInputs?: Maybe<Array<CostTableColumnInputReturn>>;
  excludedCategoryContents: Array<CategoryContent>;
  excludedMarkupNames: Array<Scalars['String']['output']>;
  markupMode: MarkupMode;
  pinnedUnitID?: Maybe<Scalars['UUID']['output']>;
  projectCompInputs: Array<ProjectCompInputReturn>;
  selectedUnitIDs: Array<Scalars['UUID']['output']>;
};

export type ProjectCostBreakdown = {
  __typename?: 'ProjectCostBreakdown';
  cost: Scalars['USCents']['output'];
  percent: Scalars['Float']['output'];
  type: ProjectCostBreakdownType;
};

export type ProjectCostBreakdownReport = {
  __typename?: 'ProjectCostBreakdownReport';
  breakdown: Array<ProjectCostBreakdown>;
  total: Scalars['USCents']['output'];
};

export enum ProjectCostBreakdownType {
  ALLOWANCES = 'Allowances',
  CONTINGENCIES = 'Contingencies',
  COSTOFCONSTRUCTION = 'CostOfConstruction',
  DIRECTCOSTS = 'DirectCosts',
  DIRECTCOSTSANDALLOCATEDMARKUPS = 'DirectCostsAndAllocatedMarkups',
  MARKUPS = 'Markups',
  OWNERCOSTS = 'OwnerCosts',
}

export type ProjectCosts = {
  __typename?: 'ProjectCosts';
  budget: Scalars['USCents']['output'];
  estimate: Scalars['USCents']['output'];
  id: Scalars['UUID']['output'];
  runningTotal: Scalars['USCents']['output'];
};

export type ProjectDateVisibility = {
  __typename?: 'ProjectDateVisibility';
  milestoneDates: Array<MilestoneDateVisibility>;
};

export type ProjectDeliveryType = {
  __typename?: 'ProjectDeliveryType';
  abbreviation: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type ProjectInfoType = {
  __typename?: 'ProjectInfoType';
  name: Scalars['String']['output'];
  projectID: Scalars['UUID']['output'];
  typeID: Scalars['UUID']['output'];
  variant: ProjectVariant;
};

export type ProjectInput = {
  activeMilestoneID?: InputMaybe<Scalars['UUID']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionStyled?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  lat?: InputMaybe<Scalars['Float']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  locationDetails?: InputMaybe<LocationDetailsInput>;
  lon?: InputMaybe<Scalars['Float']['input']>;
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgNodeIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  projectDeliveryTypeID?: InputMaybe<Scalars['UUID']['input']>;
  statusID?: InputMaybe<Scalars['UUID']['input']>;
  team?: InputMaybe<TeamInput>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectItemStatusCostBreakdown = {
  __typename?: 'ProjectItemStatusCostBreakdown';
  cost: Scalars['USCents']['output'];
  itemCount: Scalars['Int']['output'];
  percent: Scalars['Float']['output'];
  status: Status;
};

export type ProjectItemTotalCostBreakdown = {
  __typename?: 'ProjectItemTotalCostBreakdown';
  monthlyBreakdown: Array<MonthlyProjectCostRange>;
  negativeCost: Scalars['USCents']['output'];
  positiveCost: Scalars['USCents']['output'];
  project: ProjectChartInfo;
  projectStatus: Scalars['String']['output'];
  statusBreakdown: Array<ProjectItemStatusCostBreakdown>;
  statusDuration: Scalars['Int']['output'];
  totalCost: Scalars['USCents']['output'];
  totalItemCount: Scalars['Int']['output'];
};

export type ProjectLocation = {
  __typename?: 'ProjectLocation';
  lat?: Maybe<Scalars['Float']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  lon?: Maybe<Scalars['Float']['output']>;
  projects: Array<ProjectLocationData>;
  totalAPV: Scalars['USCents']['output'];
};

export type ProjectLocationData = {
  __typename?: 'ProjectLocationData';
  apv: Scalars['USCents']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type ProjectLocations = {
  __typename?: 'ProjectLocations';
  projectLocations: Array<ProjectLocation>;
  projectsWithoutLocation?: Maybe<ProjectLocation>;
};

export type ProjectMovement = {
  __typename?: 'ProjectMovement';
  absolute: Scalars['USCents']['output'];
  lastUpdated: Scalars['Time']['output'];
  milestoneName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  projectID: Scalars['UUID']['output'];
  relative: Scalars['Float']['output'];
};

export type ProjectMovements = {
  __typename?: 'ProjectMovements';
  decreases: Array<ProjectMovement>;
  increases: Array<ProjectMovement>;
};

export type ProjectOrgNodeInfo = {
  __typename?: 'ProjectOrgNodeInfo';
  name: Scalars['String']['output'];
  nodeID: Scalars['UUID']['output'];
  projectID: Scalars['UUID']['output'];
};

export type ProjectOverviewData = {
  __typename?: 'ProjectOverviewData';
  apv: Scalars['USCents']['output'];
  apvPercentage: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  countPercentage: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export enum ProjectRoleType {
  ADMINISTRATOR = 'ADMINISTRATOR',
  CUSTOM = 'CUSTOM',
  DESIGN_TEAM = 'DESIGN_TEAM',
  EDIT_TEMPLATE = 'EDIT_TEMPLATE',
  GENERAL_CONTRACTOR = 'GENERAL_CONTRACTOR',
  OWNER = 'OWNER',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  UNSET = 'UNSET',
  VIEW_ONLY = 'VIEW_ONLY',
}

export type ProjectSetting = {
  __typename?: 'ProjectSetting';
  key: ProjectSettingKeys;
  validValues: Array<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export enum ProjectSettingKeys {
  CURRENCY = 'CURRENCY',
  PREV_SCHEDULE_IMPACT_DISPLAY = 'PREV_SCHEDULE_IMPACT_DISPLAY',
  ROUNDING_PRECISION = 'ROUNDING_PRECISION',
  SCHEDULE_IMPACT_DISPLAY = 'SCHEDULE_IMPACT_DISPLAY',
}

export type ProjectStats = {
  __typename?: 'ProjectStats';
  budget?: Maybe<Scalars['USCents']['output']>;
  createdAt?: Maybe<Scalars['Time']['output']>;
  createdBy?: Maybe<CompanyUser>;
  estimate?: Maybe<Scalars['USCents']['output']>;
  id: Scalars['UUID']['output'];
  itemCount: Scalars['Int']['output'];
  location?: Maybe<Scalars['String']['output']>;
  milestoneCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  orgNodes: Array<OrgNode>;
  projectLead?: Maybe<User>;
  projectType: ProjectType;
  runningTotal?: Maybe<Scalars['USCents']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  teamCount: Scalars['Int']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type ProjectStatus = {
  __typename?: 'ProjectStatus';
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ProjectTemplate = {
  __typename?: 'ProjectTemplate';
  id: Scalars['UUID']['output'];
  isDraft: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  project: Project;
  settings: Array<ProjectSetting>;
};

export type ProjectType = {
  __typename?: 'ProjectType';
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  parentID?: Maybe<Scalars['UUID']['output']>;
};

export enum ProjectVariant {
  PROJECT = 'Project',
  TEMPLATE = 'Template',
}

export type ProjectsCounts = {
  __typename?: 'ProjectsCounts';
  allProjectsCount: Scalars['Int']['output'];
  myProjectsCount: Scalars['Int']['output'];
};

export type ProjectsFilterBy = {
  companies: Array<Scalars['String']['input']>;
  locations: Array<Scalars['String']['input']>;
  orgNodeIDs: Array<Scalars['UUID']['input']>;
  projectLeadIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  statuses: Array<Scalars['String']['input']>;
  types: Array<Scalars['String']['input']>;
};

export type ProjectsFilterOptions = {
  __typename?: 'ProjectsFilterOptions';
  companies: Array<Scalars['String']['output']>;
  locations: Array<Scalars['String']['output']>;
  milestoneDesignPhases: Array<Scalars['String']['output']>;
  organizationNodes: Array<OrgNode>;
  projectLeads: Array<User>;
  projectTypes: Array<ProjectType>;
  statuses: Array<Scalars['String']['output']>;
  types: Array<Scalars['String']['output']>;
};

export type ProjectsOverview = {
  __typename?: 'ProjectsOverview';
  projectStatusData: Array<ProjectOverviewData>;
  projectTypeData: Array<ProjectOverviewData>;
  totalAPV: Scalars['USCents']['output'];
};

export type ProjectsSortBy = {
  sortDirection: Scalars['String']['input'];
  sortKey: Scalars['String']['input'];
};

export enum ProjectsSortKey {
  SORT_PROJECT_BUDGET = 'SORT_PROJECT_BUDGET',
  SORT_PROJECT_CREATED_AT = 'SORT_PROJECT_CREATED_AT',
  SORT_PROJECT_CREATED_BY = 'SORT_PROJECT_CREATED_BY',
  SORT_PROJECT_ESTIMATE = 'SORT_PROJECT_ESTIMATE',
  SORT_PROJECT_ITEM_COUNT = 'SORT_PROJECT_ITEM_COUNT',
  SORT_PROJECT_LOCATION = 'SORT_PROJECT_LOCATION',
  SORT_PROJECT_MILESTONE_COUNT = 'SORT_PROJECT_MILESTONE_COUNT',
  SORT_PROJECT_NAME = 'SORT_PROJECT_NAME',
  SORT_PROJECT_ORGANIZATION = 'SORT_PROJECT_ORGANIZATION',
  SORT_PROJECT_RUNNING_TOTAL = 'SORT_PROJECT_RUNNING_TOTAL',
  SORT_PROJECT_STATUS = 'SORT_PROJECT_STATUS',
  SORT_PROJECT_TEAM_COUNT = 'SORT_PROJECT_TEAM_COUNT',
  SORT_PROJECT_TYPE = 'SORT_PROJECT_TYPE',
  SORT_PROJECT_UPDATED = 'SORT_PROJECT_UPDATED',
}

export type Quantity = {
  __typename?: 'Quantity';
  estimateID: Scalars['UUID']['output'];
  hasBreakdown: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  magnitude: Scalars['Numeric']['output'];
  unit: Unit;
};

export type QuantityInputAddBreakdown = {
  fields: Array<FieldInput>;
  quantityID?: InputMaybe<Scalars['UUID']['input']>;
  unitID?: InputMaybe<Scalars['UUID']['input']>;
};

export type QuantityInputCreate = {
  magnitude?: InputMaybe<Scalars['Numeric']['input']>;
  unitID: Scalars['UUID']['input'];
};

export type QuantityInputRemoveBreakdown = {
  estimateID: Scalars['UUID']['input'];
  magnitude: Scalars['Numeric']['input'];
  quantityID: Scalars['UUID']['input'];
  unitID: Scalars['UUID']['input'];
};

export type Query = {
  __typename?: 'Query';
  acceptedTermsOfService: TermsOfServiceCheckResult;
  activeProjectBanner?: Maybe<Banner>;
  activeUsers: Array<ActiveUser>;
  allMilestonesTrendDateVisibility: ProjectDateVisibility;
  analyticsProperties: AnalyticsProperties;
  asset?: Maybe<Asset>;
  assets?: Maybe<Array<Maybe<Asset>>>;
  canApplyScenario: CanApplyScenario;
  categories: Array<Category>;
  categorization: Categorization;
  category: Array<Category>;
  categoryCombinationCounts: Array<CategorizationCategoryCount>;
  categoryCombinations: Array<CategoryCombination>;
  cityCostIndices?: Maybe<CityCostIndicesResponse>;
  collaborators: Array<Collaborator>;
  companies: Array<Company>;
  company?: Maybe<Company>;
  companyAsset?: Maybe<Asset>;
  companyCategorizations: Array<CompanyCategorization>;
  companyCollaboratorDetails: CompanyUserDetails;
  companyCollaborators: Array<CompanyCollaborator>;
  companyProjectRoles: Array<CompanyProjectRoles>;
  companyProjectStats: Array<Maybe<ProjectStats>>;
  companyProjectTypes: Array<ProjectType>;
  companyRoles: Array<PartialRole>;
  companyUser?: Maybe<CompanyUser>;
  companyUserDetails: CompanyUserDetails;
  companyUsers: Array<CompanyUser>;
  contingencyReport: Array<ContingencyReportContingency>;
  copyItemData: CopyItemData;
  costTrendlineEvents: CostTrendlineEvents;
  currentCollaborator: Collaborator;
  currentCompanyProjectRole?: Maybe<CompanyProjectRole>;
  currentCompanyRole?: Maybe<CompanyRole>;
  currentItemsBreakdown: CurrentItemsBreakdown;
  currentUser: User;
  customCategorizations: Array<Categorization>;
  dashboardProjects: DashboardProjects;
  decisionSummary: DecisionSummary;
  derivations?: Maybe<Array<Maybe<Derivation>>>;
  designPhaseTypes: Array<DesignPhaseType>;
  draftCategorizations: Array<Categorization>;
  draftEstimate?: Maybe<DraftEstimate>;
  draftEstimateInfo?: Maybe<DraftEstimateInfo>;
  estimate?: Maybe<Estimate>;
  estimateSourceItemInfos: Array<ItemLink>;
  estimateTotalTypeConflictInfo: EstimateTotalTypeConflictInfo;
  forecastingItems?: Maybe<ForecastingItems>;
  forecastingProjects?: Maybe<Array<ForecastingProject>>;
  getActiveAlerts: Array<InsightsActiveAlert>;
  getAlerts: Array<InsightsAlert>;
  getDesignPhaseType: DesignPhaseType;
  getDoMilestoneMarkupsHaveContingencyDraws: Scalars['Boolean']['output'];
  getItemContingencies: ItemContingency;
  getMilestoneContingencies: Array<MilestoneContingencyInfo>;
  getProjectContingencies: Array<MilestoneContingencyInfo>;
  getProjectTypes: Array<ProjectType>;
  getProjectUnits: Array<Unit>;
  getProjectsUsingOrg: Array<ProjectOrgNodeInfo>;
  getProjectsUsingOrgNode: Array<ProjectOrgNodeInfo>;
  getProjectsWithType: Array<ProjectInfoType>;
  getSharedResource?: Maybe<GetSharedResourcePayload>;
  getSharedResources?: Maybe<GetSharedResourcesPayload>;
  hasUnreadInAppNotifications: Scalars['Boolean']['output'];
  hubspotToken: Scalars['String']['output'];
  importEstimateErrors: Array<ImportEstimateError>;
  insights: Array<InsightsProject>;
  insightsProjectCounts: ProjectsCounts;
  isJoinSupport: Scalars['Boolean']['output'];
  item?: Maybe<ItemLike>;
  itemAssets?: Maybe<Array<Maybe<Asset>>>;
  itemAttachments: Array<Attachment>;
  itemBookmarks: Array<Bookmark>;
  itemCost: ItemCost;
  itemCounts: ItemCounts;
  itemCurrentCost: ItemCost;
  itemDueDates: Array<ItemDueDateProject>;
  itemHistory?: Maybe<ItemHistory>;
  itemHistoryLinks: Array<ItemHistoryLink>;
  itemIntegrationObjects: ItemIntegrationObjects;
  itemIntegrationObjectsCount: Scalars['Int']['output'];
  itemLinks: Array<ItemLink>;
  items?: Maybe<Array<Maybe<Item>>>;
  itemsList: ItemsList;
  itemsListTotalCost: ItemsListTotalCost;
  itemsOwnership: Array<ItemsOwnership>;
  itemsTree: ItemsTree;
  loadCollaboratorsByRole: Array<Collaborator>;
  loadCollaboratorsMultipleProjects: Array<ProjectCollaborators>;
  loadFeatureFlags: Array<Scalars['FeatureFlag']['output']>;
  loadInAppNotifications: InAppNotifications;
  loadInAppNotificationsCount: InAppNotificationsCounts;
  loadModuleEntitlementFlags: Array<Scalars['ModuleEntitlementFlag']['output']>;
  loadOrCreateScenarioSandboxByMilestoneID: ScenarioSandbox;
  loadScenario: ScenarioColumn;
  loadScenariosMetadata: Array<ScenarioMetadata>;
  loadScenariosSummaryByMilestoneID: Array<ScenarioSummary>;
  locationEscalation?: Maybe<LocationEscalation>;
  locationEscalationMultiple: Array<Maybe<LocationEscalation>>;
  milestone?: Maybe<Milestone>;
  milestoneAssets?: Maybe<Array<Maybe<Asset>>>;
  milestoneAttachments: Array<Attachment>;
  milestoneCostReports: Array<MilestoneCostReports>;
  milestones: Array<Milestone>;
  milestonesCostTrends: Array<Segment>;
  milestonesCostTrendsBreakdowns: Array<MilestoneCostTrendsBreakdown>;
  milestonesMarkups: Array<MilestoneMarkupsInfo>;
  multiMilestoneContingencyReport: MultiMilestoneContingencyReportData;
  organization: Org;
  organizations: Array<Org>;
  permissionTable: Array<PermissionTableGroup>;
  previewImportedCategories?: Maybe<CategoryMapResults>;
  previewProjectCollaborator: Collaborator;
  previewProjectRole: Role;
  project?: Maybe<Project>;
  projectActiveUsers: Array<ProjectActiveUsers>;
  projectActivity?: Maybe<ItemActivity>;
  projectAssets?: Maybe<Array<Asset>>;
  projectBanners: Array<Banner>;
  projectBudgetGaps: Array<ProjectBudgetGap>;
  projectCategorizations: Array<ProjectCategorization>;
  projectCategorizationsCategoryMatches: Array<CategorizationMatches>;
  projectCompSettingsReactive: Scalars['ProjectCompsSetInputType']['output'];
  projectCompanies: Array<ProjectCompany>;
  projectComparisonReport: ProjectComparisonReport;
  projectComparisonReports: Array<ProjectComparisonReport>;
  projectCompsSet: ProjectCompsSet;
  projectCompsSetInput: ProjectCompsSetInputReturn;
  projectCostBreakdownReport?: Maybe<ProjectCostBreakdownReport>;
  projectDeliveryTypes: Array<ProjectDeliveryType>;
  projectLocations: ProjectLocations;
  projectMovements: ProjectMovements;
  projectRole: Role;
  projectRoles: Array<Role>;
  projectSettings: Array<ProjectSetting>;
  projectStatuses?: Maybe<Array<ProjectStatus>>;
  projectTemplate: ProjectTemplate;
  projectTemplates: Array<ProjectTemplate>;
  projectTerminology: Array<Terminology>;
  projectThumbnail: Asset;
  projectTypes: Array<ProjectType>;
  projectsCounts: ProjectsCounts;
  projectsFilterOptions: ProjectsFilterOptions;
  projectsOverview: ProjectsOverview;
  quantities: Array<Quantity>;
  recentlyCreatedProjects: Array<RecentProject>;
  reportDistribution: ReportDistribution;
  reportDistributionHistory: Array<ReportDistributionEvent>;
  reportIDByReportDistributionAssetID: Scalars['UUID']['output'];
  searchCategoryFilterLabels: Array<SearchFilterLabel>;
  searchItems?: Maybe<ForecastingItems>;
  searchProjects?: Maybe<SearchProjects>;
  searchProjectsFilterOptions: ProjectsFilterOptions;
  sourceFolderContents: SourceFolderEntities;
  sourceFolderRoot: SourceFolderEntities;
  sourceHubProjects: SourceHubProjects;
  sourceHubRoot: SourceHubRoot;
  sourceLinkedProject?: Maybe<SourceLinkedProject>;
  sourceProjectFolders: SourceProjectFolders;
  standardCategorizations: Array<StandardCategorization>;
  subcategory: Array<Category>;
  timeEscalation?: Maybe<TimeEscalation>;
  timeEscalationMultiple: Array<Maybe<TimeEscalation>>;
  timeline: Timeline;
  user?: Maybe<User>;
  userAssets?: Maybe<Array<Maybe<Asset>>>;
  userByID?: Maybe<UserLink>;
  userInAppNotificationSetting: UserNotificationSetting;
  userNotificationSetting: UserNotificationSetting;
  userReport: UserReport;
  userReportNotesPerMilestoneID: Array<MilestoneNotesCount>;
  userReports: Array<UserReport>;
  userSourceCompanies: Array<SourceCompany>;
  userSources: UserSources;
  usersByIDs: Array<Maybe<UserLink>>;
  varianceReportComments: VarianceReportComments;
  varianceReports: Array<VarianceReports>;
};

export type QueryActiveProjectBannerArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryActiveUsersArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type QueryAllMilestonesTrendDateVisibilityArgs = {
  intervalType: IntervalType;
  projectID: Scalars['UUID']['input'];
};

export type QueryAnalyticsPropertiesArgs = {
  previewRoleID?: InputMaybe<Scalars['UUID']['input']>;
  previewUserID?: InputMaybe<Scalars['UUID']['input']>;
  projectID?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryAssetArgs = {
  assetID: Scalars['UUID']['input'];
  dimension?: InputMaybe<ImageDimension>;
};

export type QueryAssetsArgs = {
  assetIDs: Array<Scalars['UUID']['input']>;
};

export type QueryCanApplyScenarioArgs = {
  costMode: CostMode;
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  scenarioID: Scalars['UUID']['input'];
};

export type QueryCategoriesArgs = {
  categorizationID: Scalars['UUID']['input'];
  categoryIDs: Array<Scalars['UUID']['input']>;
};

export type QueryCategorizationArgs = {
  id: Scalars['UUID']['input'];
  pagination?: InputMaybe<Pagination>;
};

export type QueryCategoryArgs = {
  search: CategorySearch;
};

export type QueryCategoryCombinationCountsArgs = {
  searches: Array<CategorySearch>;
};

export type QueryCategoryCombinationsArgs = {
  estimateID?: InputMaybe<Scalars['UUID']['input']>;
  searches: Array<CategorySearch>;
};

export type QueryCityCostIndicesArgs = {
  pagination: Pagination;
};

export type QueryCollaboratorsArgs = {
  project: Scalars['UUID']['input'];
};

export type QueryCompanyArgs = {
  companyID: Scalars['UUID']['input'];
};

export type QueryCompanyAssetArgs = {
  assetID: Scalars['UUID']['input'];
};

export type QueryCompanyCategorizationsArgs = {
  companyID: Scalars['UUID']['input'];
};

export type QueryCompanyCollaboratorDetailsArgs = {
  companyID: Scalars['UUID']['input'];
  sortBy?: InputMaybe<UserProjectSortBy>;
  userID: Scalars['UUID']['input'];
};

export type QueryCompanyCollaboratorsArgs = {
  companyID: Scalars['UUID']['input'];
  filters?: InputMaybe<CompanyCollaboratorFilter>;
  userSortBy?: InputMaybe<CompanyUserSortBy>;
};

export type QueryCompanyProjectRolesArgs = {
  companyID: Scalars['UUID']['input'];
};

export type QueryCompanyProjectStatsArgs = {
  companyID: Scalars['UUID']['input'];
  filters: ProjectsFilterBy;
  sortBy: ProjectsSortBy;
};

export type QueryCompanyProjectTypesArgs = {
  companyID: Scalars['UUID']['input'];
  includeHidden: Scalars['Boolean']['input'];
};

export type QueryCompanyRolesArgs = {
  companyID: Scalars['UUID']['input'];
};

export type QueryCompanyUserDetailsArgs = {
  sortBy?: InputMaybe<UserProjectSortBy>;
  userID: Scalars['UUID']['input'];
};

export type QueryCompanyUsersArgs = {
  companyID: Scalars['UUID']['input'];
  filters?: InputMaybe<CompanyUserFilter>;
  sortBy?: InputMaybe<CompanyUserSortBy>;
};

export type QueryContingencyReportArgs = {
  filteredAllowances?: InputMaybe<Array<Scalars['String']['input']>>;
  filteredContingencies?: InputMaybe<Array<Scalars['String']['input']>>;
  itemStatuses?: InputMaybe<Array<Status>>;
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type QueryCopyItemDataArgs = {
  itemID: Scalars['UUID']['input'];
  projectID?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryCostTrendlineEventsArgs = {
  columnInputs: Array<CostReportColumnInput>;
  costMode: CostMode;
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  quantityMagnitude?: InputMaybe<Scalars['Numeric']['input']>;
  viewFilter: ViewFilter;
};

export type QueryCurrentCollaboratorArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryCurrentCompanyProjectRoleArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryCurrentItemsBreakdownArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type QueryCustomCategorizationsArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryDashboardProjectsArgs = {
  companyID?: InputMaybe<Scalars['UUID']['input']>;
  includeCompanyProjects?: InputMaybe<Scalars['Boolean']['input']>;
  projectsFilterBy: ProjectsFilterBy;
};

export type QueryDecisionSummaryArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type QueryDerivationsArgs = {
  assetID: Scalars['UUID']['input'];
};

export type QueryDraftCategorizationsArgs = {
  estimateID: Scalars['UUID']['input'];
};

export type QueryDraftEstimateArgs = {
  costMode: CostMode;
  estimateID: Scalars['UUID']['input'];
};

export type QueryDraftEstimateInfoArgs = {
  costMode: CostMode;
  estimateID: Scalars['UUID']['input'];
};

export type QueryEstimateArgs = {
  costMode: CostMode;
  estimateID: Scalars['UUID']['input'];
  estimateSortBy?: InputMaybe<EstimateSortBy>;
  itemEstimateInput?: InputMaybe<ItemEstimateInput>;
  pagination?: InputMaybe<Pagination>;
  viewFilter?: InputMaybe<ViewFilter>;
};

export type QueryEstimateSourceItemInfosArgs = {
  estimateID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type QueryEstimateTotalTypeConflictInfoArgs = {
  estimateID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type QueryForecastingItemsArgs = {
  filters: ForecastingExploreItemsFilters;
  includeCompanyProjects?: InputMaybe<Scalars['Boolean']['input']>;
  pagination: Pagination;
  projectsFilters: ForecastingProjectsFilters;
  search: Scalars['String']['input'];
  sortBy: ForecastingExploreItemsSortBy;
};

export type QueryForecastingProjectsArgs = {
  filters: ForecastingProjectsFilters;
  includeCompanyProjects?: InputMaybe<Scalars['Boolean']['input']>;
  pagination: Pagination;
  search: Scalars['String']['input'];
  sortBy: ForecastingProjectsSortBy;
};

export type QueryGetActiveAlertsArgs = {
  companyID: Scalars['UUID']['input'];
};

export type QueryGetAlertsArgs = {
  companyID: Scalars['UUID']['input'];
};

export type QueryGetDesignPhaseTypeArgs = {
  designPhaseID: Scalars['UUID']['input'];
};

export type QueryGetDoMilestoneMarkupsHaveContingencyDrawsArgs = {
  estimateID: Scalars['UUID']['input'];
  markupIDs: Array<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
};

export type QueryGetItemContingenciesArgs = {
  costMode?: InputMaybe<CostMode>;
  itemID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type QueryGetMilestoneContingenciesArgs = {
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
};

export type QueryGetProjectContingenciesArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryGetProjectTypesArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryGetProjectUnitsArgs = {
  isEnabledOnly: Scalars['Boolean']['input'];
  projectID: Scalars['UUID']['input'];
};

export type QueryGetProjectsUsingOrgArgs = {
  organizationID: Scalars['UUID']['input'];
};

export type QueryGetProjectsUsingOrgNodeArgs = {
  orgNodeID: Scalars['UUID']['input'];
};

export type QueryGetProjectsWithTypeArgs = {
  typeID: Scalars['UUID']['input'];
};

export type QueryGetSharedResourceArgs = {
  input: GetSharedResourceInput;
};

export type QueryGetSharedResourcesArgs = {
  input: GetSharedResourcesInput;
};

export type QueryImportEstimateErrorsArgs = {
  estimateID: Scalars['UUID']['input'];
};

export type QueryInsightsArgs = {
  input: InsightsInput;
};

export type QueryInsightsProjectCountsArgs = {
  companyID?: InputMaybe<Scalars['UUID']['input']>;
  projectsFilterBy: ProjectsFilterBy;
};

export type QueryItemArgs = {
  costMode: CostMode;
  id: Scalars['UUID']['input'];
};

export type QueryItemAssetsArgs = {
  itemID: Scalars['UUID']['input'];
};

export type QueryItemAttachmentsArgs = {
  itemID: Scalars['UUID']['input'];
};

export type QueryItemBookmarksArgs = {
  itemID: Scalars['UUID']['input'];
};

export type QueryItemCostArgs = {
  parameters: ItemCostParameters;
};

export type QueryItemCountsArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryItemCurrentCostArgs = {
  parameters: ItemCostParameters;
};

export type QueryItemDueDatesArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type QueryItemHistoryArgs = {
  costMode: CostMode;
  eventTypeFilters: Array<EventType>;
  itemID?: InputMaybe<Scalars['UUID']['input']>;
  viewFilter: ViewFilter;
};

export type QueryItemHistoryLinksArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryItemIntegrationObjectsArgs = {
  itemID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  sourceID: Scalars['UUID']['input'];
  sourceSystem: SourceSystem;
};

export type QueryItemIntegrationObjectsCountArgs = {
  itemID: Scalars['UUID']['input'];
};

export type QueryItemLinksArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryItemsArgs = {
  query?: InputMaybe<ItemQuery>;
};

export type QueryItemsListArgs = {
  parameters: ItemsListParameters;
};

export type QueryItemsListTotalCostArgs = {
  parameters: ItemsListTotalCostParameters;
};

export type QueryItemsOwnershipArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type QueryItemsTreeArgs = {
  parameters: ItemsTreeParameters;
};

export type QueryLoadCollaboratorsByRoleArgs = {
  projectID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
};

export type QueryLoadCollaboratorsMultipleProjectsArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type QueryLoadFeatureFlagsArgs = {
  projectID: Scalars['UUID']['input'];
  userEmail: Scalars['String']['input'];
};

export type QueryLoadInAppNotificationsArgs = {
  costMode?: InputMaybe<CostMode>;
  pagination: PaginationByOffsetId;
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  showUnreadOnly: Scalars['Boolean']['input'];
};

export type QueryLoadInAppNotificationsCountArgs = {
  maxCount: Scalars['Int']['input'];
  projectID?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryLoadModuleEntitlementFlagsArgs = {
  companyID?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryLoadOrCreateScenarioSandboxByMilestoneIdArgs = {
  costMode: CostMode;
  milestoneID: Scalars['UUID']['input'];
  scenariosSortBy: ScenariosSortBy;
};

export type QueryLoadScenarioArgs = {
  costMode: CostMode;
  milestoneID: Scalars['UUID']['input'];
  scenarioID: Scalars['UUID']['input'];
  scenariosSortBy: ScenariosSortBy;
};

export type QueryLoadScenariosMetadataArgs = {
  milestoneID: Scalars['UUID']['input'];
};

export type QueryLoadScenariosSummaryByMilestoneIdArgs = {
  costMode: CostMode;
  milestoneID: Scalars['UUID']['input'];
};

export type QueryLocationEscalationArgs = {
  from: LocationInput;
  to: LocationInput;
};

export type QueryLocationEscalationMultipleArgs = {
  locations: Array<LocationEscalationInput>;
};

export type QueryMilestoneArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryMilestoneAssetsArgs = {
  milestoneID: Scalars['UUID']['input'];
};

export type QueryMilestoneAttachmentsArgs = {
  milestoneID: Scalars['UUID']['input'];
};

export type QueryMilestoneCostReportsArgs = {
  parameters: CostReportParameters;
};

export type QueryMilestonesArgs = {
  projectID: Scalars['UUID']['input'];
  showDrafts: Scalars['Boolean']['input'];
};

export type QueryMilestonesCostTrendsArgs = {
  parameters: MilestonesCostTrendsQuery;
};

export type QueryMilestonesCostTrendsBreakdownsArgs = {
  parameters: MilestonesCostTrendsBreakdownsQuery;
};

export type QueryMilestonesMarkupsArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryMultiMilestoneContingencyReportArgs = {
  filteredAllowances?: InputMaybe<Array<Scalars['String']['input']>>;
  filteredContingencies?: InputMaybe<Array<Scalars['String']['input']>>;
  projectID: Scalars['UUID']['input'];
};

export type QueryOrganizationArgs = {
  organizationID: Scalars['UUID']['input'];
};

export type QueryOrganizationsArgs = {
  companyID: Scalars['UUID']['input'];
};

export type QueryPermissionTableArgs = {
  project: Scalars['UUID']['input'];
};

export type QueryPreviewImportedCategoriesArgs = {
  assetID: Scalars['UUID']['input'];
};

export type QueryPreviewProjectCollaboratorArgs = {
  projectID: Scalars['UUID']['input'];
  userID: Scalars['UUID']['input'];
};

export type QueryPreviewProjectRoleArgs = {
  projectID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
};

export type QueryProjectArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryProjectActiveUsersArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type QueryProjectActivityArgs = {
  costMode: CostMode;
  dateFilter: DateFilter;
  filterByUserID?: InputMaybe<Scalars['UUID']['input']>;
  pagination: PaginationByOffsetId;
  projectID: Scalars['UUID']['input'];
  timezoneOffset: Scalars['Int']['input'];
};

export type QueryProjectAssetsArgs = {
  projectID: Scalars['UUID']['input'];
  sortBy?: InputMaybe<FilesSortBy>;
};

export type QueryProjectBannersArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryProjectBudgetGapsArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type QueryProjectCategorizationsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  projectID: Scalars['UUID']['input'];
};

export type QueryProjectCategorizationsCategoryMatchesArgs = {
  categoryNumbers: Array<Scalars['String']['input']>;
  projectID: Scalars['UUID']['input'];
};

export type QueryProjectCompaniesArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryProjectComparisonReportArgs = {
  reportID: Scalars['UUID']['input'];
};

export type QueryProjectComparisonReportsArgs = {
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  search: Scalars['String']['input'];
  sortBy: ForecastingReportsSortBy;
};

export type QueryProjectCompsSetArgs = {
  projectCompsSetInput?: InputMaybe<ProjectCompsSetInput>;
  reportID?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryProjectCompsSetInputArgs = {
  reportID: Scalars['UUID']['input'];
};

export type QueryProjectCostBreakdownReportArgs = {
  input: ProjectBreakdownReportInput;
};

export type QueryProjectLocationsArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type QueryProjectMovementsArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type QueryProjectRoleArgs = {
  projectID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
};

export type QueryProjectRolesArgs = {
  project: Scalars['UUID']['input'];
};

export type QueryProjectSettingsArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryProjectTemplateArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryProjectTemplatesArgs = {
  companyID: Scalars['UUID']['input'];
  showDrafts: Scalars['Boolean']['input'];
};

export type QueryProjectTerminologyArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryProjectThumbnailArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryProjectTypesArgs = {
  companyID?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryProjectsCountsArgs = {
  projectsFilterBy: ProjectsFilterBy;
  projectsSearch: Scalars['String']['input'];
};

export type QueryProjectsFilterOptionsArgs = {
  companyID?: InputMaybe<Scalars['UUID']['input']>;
  includeCompanyProjects: Scalars['Boolean']['input'];
  includeUserProjects?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryProjectsOverviewArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type QueryQuantitiesArgs = {
  milestoneID: Scalars['UUID']['input'];
};

export type QueryRecentlyCreatedProjectsArgs = {
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type QueryReportDistributionArgs = {
  projectID: Scalars['UUID']['input'];
  reportID: Scalars['UUID']['input'];
};

export type QueryReportDistributionHistoryArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryReportIdByReportDistributionAssetIdArgs = {
  assetID: Scalars['UUID']['input'];
};

export type QuerySearchItemsArgs = {
  filters: ForecastingExploreItemsFilters;
  pagination: Pagination;
  projectsFilters: ForecastingProjectsFilters;
  search: Scalars['String']['input'];
  searchResultType?: InputMaybe<SearchResultType>;
  sortBy: ForecastingExploreItemsSortBy;
};

export type QuerySearchProjectsArgs = {
  filters: ForecastingProjectsFilters;
  pagination: Pagination;
  search: Scalars['String']['input'];
  searchResultType?: InputMaybe<SearchResultType>;
  sortBy: ForecastingProjectsSortBy;
};

export type QuerySearchProjectsFilterOptionsArgs = {
  searchResultType: SearchResultType;
};

export type QuerySourceFolderContentsArgs = {
  folderID?: InputMaybe<Scalars['String']['input']>;
  hubID?: InputMaybe<Scalars['String']['input']>;
  projectID?: InputMaybe<Scalars['String']['input']>;
  sortBy: FilesSortBy;
  sourceID: Scalars['UUID']['input'];
  sourceSystem: SourceSystem;
};

export type QuerySourceFolderRootArgs = {
  sortBy: FilesSortBy;
  sourceID: Scalars['UUID']['input'];
  sourceSystem: SourceSystem;
};

export type QuerySourceHubProjectsArgs = {
  hubID: Scalars['String']['input'];
  sourceID: Scalars['UUID']['input'];
  sourceSystem: SourceSystem;
};

export type QuerySourceHubRootArgs = {
  sourceID: Scalars['UUID']['input'];
  sourceSystem: SourceSystem;
};

export type QuerySourceLinkedProjectArgs = {
  projectID: Scalars['UUID']['input'];
  source: SourceSystem;
  sourceID?: InputMaybe<Scalars['UUID']['input']>;
  sourceUserEmail?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySourceProjectFoldersArgs = {
  hubID?: InputMaybe<Scalars['String']['input']>;
  projectID: Scalars['String']['input'];
  sourceID: Scalars['UUID']['input'];
  sourceSystem: SourceSystem;
};

export type QueryStandardCategorizationsArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QuerySubcategoryArgs = {
  search: SubcategorySearch;
};

export type QueryTimeEscalationArgs = {
  from: Scalars['Time']['input'];
  to: Scalars['Time']['input'];
};

export type QueryTimeEscalationMultipleArgs = {
  dates: Array<TimeEscalationInput>;
};

export type QueryTimelineArgs = {
  input: TimelineInput;
};

export type QueryUserArgs = {
  email: Scalars['String']['input'];
};

export type QueryUserAssetsArgs = {
  userID: Scalars['UUID']['input'];
};

export type QueryUserByIdArgs = {
  ID: Scalars['UUID']['input'];
};

export type QueryUserInAppNotificationSettingArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryUserNotificationSettingArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryUserReportArgs = {
  projectID: Scalars['UUID']['input'];
  reportID: Scalars['UUID']['input'];
};

export type QueryUserReportNotesPerMilestoneIdArgs = {
  projectID: Scalars['UUID']['input'];
  reportID: Scalars['UUID']['input'];
};

export type QueryUserReportsArgs = {
  projectID: Scalars['UUID']['input'];
};

export type QueryUserSourceCompaniesArgs = {
  sourceID: Scalars['UUID']['input'];
};

export type QueryUsersByIDsArgs = {
  IDs: Array<Scalars['UUID']['input']>;
};

export type QueryVarianceReportCommentsArgs = {
  costMode?: InputMaybe<CostMode>;
  reportID: Scalars['UUID']['input'];
  userReportCommentViewParameters: UserReportCommentViewParameters;
};

export type QueryVarianceReportsArgs = {
  parameters: VarianceReportsParameters;
};

export type RecentProject = {
  __typename?: 'RecentProject';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['String']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  projectId: Scalars['UUID']['output'];
  status: Scalars['String']['output'];
  thumbnail: Scalars['String']['output'];
};

export type RegularCell = {
  __typename?: 'RegularCell';
  formula: Scalars['String']['output'];
  formulaDisplay: Array<FormulaDisplay>;
  string: Scalars['String']['output'];
};

export type RemoteAsset = {
  __typename?: 'RemoteAsset';
  id: Scalars['UUID']['output'];
  lastUpdated: Scalars['Time']['output'];
  name: Scalars['String']['output'];
  remoteLink: Scalars['String']['output'];
  sourceAssetID: Scalars['String']['output'];
  sourceSystem: SourceSystem;
  uploadedBy?: Maybe<User>;
};

export type RemoteAssetInput = {
  AssetID: Scalars['String']['input'];
  HubID: Scalars['String']['input'];
  ProjectID?: InputMaybe<Scalars['String']['input']>;
  SourceID: Scalars['UUID']['input'];
  SourceSystem: SourceSystem;
};

export type RemoveCategorizationsPayload = {
  __typename?: 'RemoveCategorizationsPayload';
  removed: Array<Scalars['UUID']['output']>;
};

export type RemoveFromProject = {
  __typename?: 'RemoveFromProject';
  project: Project;
  triggeringUser: User;
};

export type RemoveProjectLeadPayload = {
  __typename?: 'RemoveProjectLeadPayload';
  updated?: Maybe<Collaborator>;
};

export type RemoveProjectsLeadPayload = {
  __typename?: 'RemoveProjectsLeadPayload';
  updated: Array<Collaborator>;
};

export type ReorderInput = {
  id: Scalars['UUID']['input'];
  orderingDenominator: Scalars['Ordering']['input'];
  orderingNumerator: Scalars['Ordering']['input'];
};

export type ReplaceCategoriesPayload = {
  __typename?: 'ReplaceCategoriesPayload';
  categorization?: Maybe<Categorization>;
  deleted: Array<Scalars['UUID']['output']>;
  updated: Array<Category>;
};

export type ReportDistribution = {
  __typename?: 'ReportDistribution';
  cadence: Scalars['String']['output'];
  collaboratorIDs: Array<Scalars['UUID']['output']>;
  createdBy: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  reportID: Scalars['UUID']['output'];
  startDate: Scalars['Time']['output'];
};

export type ReportDistributionEvent = {
  __typename?: 'ReportDistributionEvent';
  deprecated: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  isScheduled: Scalars['Boolean']['output'];
  reportID: Scalars['UUID']['output'];
  reportName: Scalars['String']['output'];
  reports: Array<DistributedReport>;
  sentAt: Scalars['Time']['output'];
  sentBy: Scalars['UUID']['output'];
};

export type ReportDistributionInput = {
  cadence: Scalars['String']['input'];
  collaboratorIDs: Array<Scalars['UUID']['input']>;
  reportID: Scalars['UUID']['input'];
  startDate: Scalars['Time']['input'];
  timezone: Scalars['String']['input'];
};

export enum ResourceType {
  ITEM = 'ITEM',
  SCENARIO = 'SCENARIO',
}

export type Role = {
  __typename?: 'Role';
  hasTrade: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  permissionGroups: Array<PermissionGroup>;
  type: ProjectRoleType;
};

export type ScenarioColumn = {
  __typename?: 'ScenarioColumn';
  color?: Maybe<Scalars['String']['output']>;
  costReports: MilestoneCostReports;
  createdBy: Scalars['UUID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  items: Array<ItemsListItem>;
  name: Scalars['String']['output'];
  scenarioID: Scalars['UUID']['output'];
  thumbnailAssetID?: Maybe<Scalars['UUID']['output']>;
};

export type ScenarioItemCreationInput = {
  categories?: InputMaybe<Array<CategoryInput>>;
  costImpact?: InputMaybe<Scalars['USCents']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionStyled?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Time']['input']>;
  name: Scalars['String']['input'];
};

export type ScenarioLink = {
  __typename?: 'ScenarioLink';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type ScenarioMetadata = {
  __typename?: 'ScenarioMetadata';
  color?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  scenarioID: Scalars['UUID']['output'];
  thumbnailAssetID?: Maybe<Scalars['UUID']['output']>;
};

export type ScenarioSandbox = {
  __typename?: 'ScenarioSandbox';
  id: Scalars['UUID']['output'];
  milestone: MilestoneColumn;
  milestoneID: Scalars['UUID']['output'];
  scenarios: Array<ScenarioColumn>;
};

export type ScenarioSummary = {
  __typename?: 'ScenarioSummary';
  color?: Maybe<Scalars['String']['output']>;
  costImpact: CostReportColumn;
  description?: Maybe<Scalars['String']['output']>;
  itemsCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  scenarioID: Scalars['UUID']['output'];
  thumbnailAssetID?: Maybe<Scalars['UUID']['output']>;
};

export type ScenariosSortBy = {
  sortDirection: SortDirection;
  sortKey: ItemsSortKey;
};

export type ScheduleImpact = {
  __typename?: 'ScheduleImpact';
  criticalPath: Scalars['Boolean']['output'];
  days: Scalars['Int']['output'];
  max?: Maybe<Scalars['String']['output']>;
  maxImpact?: Maybe<ScheduleImpact>;
  min?: Maybe<Scalars['String']['output']>;
  minImpact?: Maybe<ScheduleImpact>;
  type: Scalars['ScheduleImpactType']['output'];
};

export type ScheduleImpactChange = {
  __typename?: 'ScheduleImpactChange';
  new: ScheduleImpact;
  old?: Maybe<ScheduleImpact>;
  timestamp: Scalars['Time']['output'];
};

export type ScheduleImpactInput = {
  criticalPath: Scalars['Boolean']['input'];
  days: Scalars['Int']['input'];
  itemID: Scalars['UUID']['input'];
  type: Scalars['ScheduleImpactType']['input'];
};

export type SearchCategorization = {
  __typename?: 'SearchCategorization';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type SearchCategory = {
  __typename?: 'SearchCategory';
  categorization: SearchCategorization;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
};

export type SearchFilterLabel = {
  __typename?: 'SearchFilterLabel';
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type SearchProjects = {
  __typename?: 'SearchProjects';
  data: Array<ForecastingProject>;
  total: Scalars['Float']['output'];
};

export enum SearchResultType {
  ALL = 'ALL',
  COMPANY = 'COMPANY',
  MY = 'MY',
}

export type Segment = {
  __typename?: 'Segment';
  milestone: Milestone;
  points: Array<Point>;
};

export type SetActivityInput = {
  activityID?: InputMaybe<Scalars['UUID']['input']>;
  id: Scalars['UUID']['input'];
};

export type SetAssigneeInput = {
  assigneeEmail?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

export type SetBucketInput = {
  bucket?: InputMaybe<Scalars['UUID']['input']>;
  id: Scalars['UUID']['input'];
};

export type SetBulkProjectOrgsInput = {
  nodeIDs: Array<Scalars['UUID']['input']>;
  projectIDs: Array<Scalars['UUID']['input']>;
};

export type SetDueDateInput = {
  dueDate?: InputMaybe<Scalars['Time']['input']>;
  id: Scalars['UUID']['input'];
};

export type SetMilestoneDateInput = {
  date?: InputMaybe<Scalars['Time']['input']>;
  milestoneID: Scalars['UUID']['input'];
};

export type SetMilestoneDescriptionInput = {
  description: Scalars['String']['input'];
  descriptionStyled?: InputMaybe<Scalars['String']['input']>;
  milestoneID: Scalars['UUID']['input'];
};

export type SetMilestoneDesignPhaseInput = {
  designPhaseID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
};

export type SetMilestoneInput = {
  id: Scalars['UUID']['input'];
  milestone?: InputMaybe<Scalars['UUID']['input']>;
  status: Status;
};

export type SetMilestoneNameInput = {
  milestoneID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type SetMilestonePublishDraftInput = {
  isDraft: Scalars['Boolean']['input'];
  milestoneID: Scalars['UUID']['input'];
};

export type SetMilestoneTimelinePhaseInput = {
  milestoneID: Scalars['UUID']['input'];
  phaseID?: InputMaybe<Scalars['UUID']['input']>;
};

export type SetNumberInput = {
  id: Scalars['UUID']['input'];
  number: Scalars['String']['input'];
};

export type SetOrgLevelInput = {
  name: Scalars['String']['input'];
  number: Scalars['Int']['input'];
  organizationID: Scalars['UUID']['input'];
};

export type SetOrgNameInput = {
  name: Scalars['String']['input'];
  organizationID: Scalars['UUID']['input'];
};

export type SetOrgNodeNameInput = {
  name: Scalars['String']['input'];
  nodeID: Scalars['UUID']['input'];
};

export type SetOrgNodeNamePayload = {
  __typename?: 'SetOrgNodeNamePayload';
  organization?: Maybe<Org>;
};

export type SetOrgPublishedInput = {
  organizationID: Scalars['UUID']['input'];
};

export type SetOrgPublishedPayload = {
  __typename?: 'SetOrgPublishedPayload';
  organization?: Maybe<Org>;
};

export type SetProjectLeadPayload = {
  __typename?: 'SetProjectLeadPayload';
  updated: Array<Collaborator>;
};

export type SetProjectOrgsInput = {
  nodeIDs: Array<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
};

export type SetProjectOrgsPayload = {
  __typename?: 'SetProjectOrgsPayload';
  removedNodeIDs: Array<Scalars['UUID']['output']>;
};

export type SetStatusInput = {
  id: Scalars['UUID']['input'];
  status?: InputMaybe<Status>;
};

export type SetVisibilityInput = {
  activeMilestoneID?: InputMaybe<Scalars['UUID']['input']>;
  createdBy?: InputMaybe<Scalars['UUID']['input']>;
  id: Scalars['UUID']['input'];
};

export type ShareDraftItem = {
  __typename?: 'ShareDraftItem';
  item: ItemLink;
  project: Project;
  triggeringUser: User;
};

export type ShareResourceInput = {
  accessLevel: AccessLevel;
  resourceID: Scalars['UUID']['input'];
  resourceType: ResourceType;
  userIDs: Array<Scalars['UUID']['input']>;
};

export type ShareResourcePayload = {
  __typename?: 'ShareResourcePayload';
  resource?: Maybe<SharedResource>;
};

export type ShareResourcesInput = {
  accessLevels: Array<AccessLevel>;
  resourceIDs: Array<Scalars['UUID']['input']>;
  resourceType: ResourceType;
  userIDs: Array<Scalars['UUID']['input']>;
};

export type ShareResourcesPayload = {
  __typename?: 'ShareResourcesPayload';
  resources: Array<SharedResource>;
};

export type ShareScenario = {
  __typename?: 'ShareScenario';
  project: Project;
  scenario: ScenarioLink;
  triggeringUser: User;
};

export type SharedResource = {
  __typename?: 'SharedResource';
  accessLevels: Array<AccessLevel>;
  id: Scalars['UUID']['output'];
  type: ResourceType;
  users: Array<User>;
};

export enum SortDirection {
  SORT_ASCENDING = 'SORT_ASCENDING',
  SORT_DESCENDING = 'SORT_DESCENDING',
  SORT_NONE = 'SORT_NONE',
}

export type SourceAsset = {
  __typename?: 'SourceAsset';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  remoteLink: Scalars['String']['output'];
  sourceUpdatedAt: Scalars['Time']['output'];
};

export type SourceCell = {
  __typename?: 'SourceCell';
  sourceIDs?: Maybe<SourceIDs>;
};

export type SourceClient = {
  __typename?: 'SourceClient';
  clientID: Scalars['String']['output'];
  sourceSystem: SourceSystem;
};

export type SourceCompany = {
  __typename?: 'SourceCompany';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SourceEntity = SourceAsset | SourceFolder;

export type SourceFolder = {
  __typename?: 'SourceFolder';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  remoteLink: Scalars['String']['output'];
  sourceUpdatedAt: Scalars['Time']['output'];
};

export type SourceFolderContents = {
  __typename?: 'SourceFolderContents';
  assets: Array<SourceAsset>;
  sourceError?: Maybe<Scalars['String']['output']>;
  subFolders: Array<SourceFolder>;
};

export type SourceFolderEntities = {
  __typename?: 'SourceFolderEntities';
  entities: Array<SourceEntity>;
  sourceError?: Maybe<Scalars['String']['output']>;
};

export type SourceHub = {
  __typename?: 'SourceHub';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  remoteLink: Scalars['String']['output'];
  sourceUpdatedAt?: Maybe<Scalars['Time']['output']>;
};

export type SourceHubProjects = {
  __typename?: 'SourceHubProjects';
  projects: Array<SourceProject>;
  sourceError?: Maybe<Scalars['String']['output']>;
};

export type SourceHubRoot = {
  __typename?: 'SourceHubRoot';
  hubs: Array<SourceHub>;
  sourceError?: Maybe<Scalars['String']['output']>;
};

export type SourceIDs = {
  __typename?: 'SourceIDs';
  estimateID: Scalars['UUID']['output'];
  itemID?: Maybe<Scalars['UUID']['output']>;
  lineID?: Maybe<Scalars['UUID']['output']>;
  markupID?: Maybe<Scalars['UUID']['output']>;
  milestoneID: Scalars['UUID']['output'];
};

export type SourceLinkedProject = {
  __typename?: 'SourceLinkedProject';
  canCreateItemIntegrationObject: Scalars['Boolean']['output'];
  sourceCompanyID: Scalars['String']['output'];
  sourceProjectID: Scalars['String']['output'];
};

export type SourceProject = {
  __typename?: 'SourceProject';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  remoteLink: Scalars['String']['output'];
  sourceUpdatedAt?: Maybe<Scalars['Time']['output']>;
};

export type SourceProjectFolders = {
  __typename?: 'SourceProjectFolders';
  folders: Array<SourceFolder>;
  sourceError?: Maybe<Scalars['String']['output']>;
};

export enum SourceSystem {
  AUTODESK = 'AUTODESK',
  EGNYTE = 'EGNYTE',
  PROCORE = 'PROCORE',
}

export enum SourceType {
  CHANGE_EVENT = 'CHANGE_EVENT',
  COORDINATION_ISSUE = 'COORDINATION_ISSUE',
  SOURCE_PROJECT = 'SOURCE_PROJECT',
}

export type StandardCategorization = {
  __typename?: 'StandardCategorization';
  categorization: Categorization;
  disabled: Scalars['Boolean']['output'];
  ordering?: Maybe<Scalars['Int']['output']>;
  projectID: Scalars['UUID']['output'];
};

export enum Status {
  ACCEPTED = 'ACCEPTED',
  INCORPORATED = 'INCORPORATED',
  NOTAPPLICABLE = 'NOTAPPLICABLE',
  NOTCHOSEN = 'NOTCHOSEN',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type StatusChangeDraw = {
  __typename?: 'StatusChangeDraw';
  name: Scalars['String']['output'];
  value: Scalars['Numeric']['output'];
};

export type SubcategorySearch = {
  categorizationID: Scalars['UUID']['input'];
  categoryID?: InputMaybe<Scalars['UUID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type SubtotalAddsDeducts = {
  __typename?: 'SubtotalAddsDeducts';
  directCostSegmented: PartialAddDeductCost;
  segmented: PartialAddDeductCost;
};

export type SubtotalCost = {
  __typename?: 'SubtotalCost';
  directCostRange: Cost;
  range: Cost;
};

export type SubtotalNumeric = {
  __typename?: 'SubtotalNumeric';
  value: Scalars['Numeric']['output'];
};

export type SubtotalValue = SubtotalAddsDeducts | SubtotalCost | SubtotalNumeric;

export type Team = {
  __typename?: 'Team';
  designTeam?: Maybe<Organization>;
  generalContractor?: Maybe<Organization>;
  owner?: Maybe<Organization>;
};

export type TeamInput = {
  designTeam?: InputMaybe<OrganizationInput>;
  generalContractor?: InputMaybe<OrganizationInput>;
  owner?: InputMaybe<OrganizationInput>;
};

export type Terminology = {
  __typename?: 'Terminology';
  key: Scalars['String']['output'];
  maxLength: Scalars['Int']['output'];
  override: Scalars['Boolean']['output'];
  overrideValue?: Maybe<Scalars['String']['output']>;
};

export type TermsOfServiceCheckResult = {
  __typename?: 'TermsOfServiceCheckResult';
  accepted: Scalars['Boolean']['output'];
  dateEffective: Scalars['String']['output'];
};

export type TimeEscalation = {
  __typename?: 'TimeEscalation';
  from: HistoricalCostIndex;
  fuzzyMatch: Scalars['Boolean']['output'];
  percentage: Scalars['Float']['output'];
  to: HistoricalCostIndex;
};

export type TimeEscalationInput = {
  from: Scalars['Time']['input'];
  to: Scalars['Time']['input'];
};

export type Timeline = {
  __typename?: 'Timeline';
  activities: Array<TimelineActivity>;
};

export type TimelineActivity = {
  __typename?: 'TimelineActivity';
  children: Array<Scalars['UUID']['output']>;
  endDate?: Maybe<Scalars['Time']['output']>;
  id: Scalars['UUID']['output'];
  itemCount: Scalars['Int']['output'];
  milestone?: Maybe<Milestone>;
  name: Scalars['String']['output'];
  startDate: Scalars['Time']['output'];
  type: Scalars['TimelineActivityType']['output'];
};

export type TimelineActivityInput = {
  activityID?: InputMaybe<Scalars['UUID']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  startDate: Scalars['Time']['input'];
  type: Scalars['TimelineActivityType']['input'];
};

export type TimelineActivityRelationInput = {
  activityID: Scalars['UUID']['input'];
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  phaseID?: InputMaybe<Scalars['UUID']['input']>;
};

export type TimelineInput = {
  includeAllMilestones?: InputMaybe<Scalars['Boolean']['input']>;
  projectID: Scalars['UUID']['input'];
  types: Array<Scalars['TimelineActivityType']['input']>;
};

export type ToggleAlertInput = {
  alertID: Scalars['UUID']['input'];
};

export type ToggleAlertPayload = {
  __typename?: 'ToggleAlertPayload';
  alert?: Maybe<InsightsAlert>;
};

export type ToggleEventDataResult = {
  __typename?: 'ToggleEventDataResult';
  events: Array<Scalars['UUID']['output']>;
  visible: Scalars['Boolean']['output'];
};

export enum TriggerMetricType {
  DAYSPASTDUE = 'DaysPastDue',
  GAP = 'Gap',
  GAPBESTCASE = 'GapBestCase',
  ITEMSPASTDUE = 'ItemsPastDue',
  MILESTONEDESIGNPHASE = 'MilestoneDesignPhase',
  PROJECTEDCONTINGENCYALLOWANCE = 'ProjectedContingencyAllowance',
}

export enum TriggerOperatorType {
  EQ = 'EQ',
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
}

export type Unit = {
  __typename?: 'Unit';
  abbreviationPlural: Scalars['String']['output'];
  abbreviationSingular: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isDefault: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: UnitType;
};

export type UnitCount = {
  __typename?: 'UnitCount';
  countText: Scalars['String']['output'];
  unitID: Scalars['UUID']['output'];
};

export type UnitInput = {
  abbreviationPlural: Scalars['String']['input'];
  abbreviationSingular: Scalars['String']['input'];
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  type: UnitType;
};

export type UnitToggleInput = {
  id: Scalars['UUID']['input'];
  isEnabled: Scalars['Boolean']['input'];
};

export enum UnitType {
  COUNT = 'COUNT',
  CUBICFEET = 'CUBICFEET',
  CUBICMETER = 'CUBICMETER',
  CUBICYARDS = 'CUBICYARDS',
  LINEARFEET = 'LINEARFEET',
  LINEARMETER = 'LINEARMETER',
  OTHER = 'OTHER',
  SQUAREFEET = 'SQUAREFEET',
  SQUAREMETER = 'SQUAREMETER',
}

export type UnlinkItemIntegrationObjectInput = {
  number: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type UnsetActivityInput = {
  activityID?: InputMaybe<Scalars['UUID']['input']>;
  id: Scalars['UUID']['input'];
};

export type UpdateProjectTypePayload = {
  __typename?: 'UpdateProjectTypePayload';
  type?: Maybe<ProjectType>;
};

export type UpdateScheduleImpactPayload = {
  __typename?: 'UpdateScheduleImpactPayload';
  item?: Maybe<ItemLike>;
};

export type UpdateTriggerInput = {
  trigger: InsightsTriggerInput;
  triggerID: Scalars['UUID']['input'];
};

export type UpdateTriggerPayload = {
  __typename?: 'UpdateTriggerPayload';
  trigger?: Maybe<InsightsTrigger>;
};

export type User = {
  __typename?: 'User';
  activeAt?: Maybe<Scalars['Time']['output']>;
  didSetup?: Maybe<Scalars['Time']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  isEmailVerified: Scalars['Boolean']['output'];
  jobTitle: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  picture: Scalars['String']['output'];
  pictureAsset?: Maybe<Asset>;
  signupAt?: Maybe<Scalars['Time']['output']>;
  status: Scalars['UserStatus']['output'];
};

export type UserAnalyticsProperties = {
  __typename?: 'UserAnalyticsProperties';
  userEmail: Scalars['String']['output'];
  userId: Scalars['UUID']['output'];
  userIsEmailVerified: Scalars['Boolean']['output'];
  userJobTitle: Scalars['String']['output'];
  userName: Scalars['String']['output'];
  userSignUpAt?: Maybe<Scalars['Time']['output']>;
};

export type UserEventInput = {
  eventData: PageLoadEventInput;
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  type: UserEventType;
};

export enum UserEventType {
  IMPORT_ESTIMATE_CREATE_ESTIMATE_FINISH = 'IMPORT_ESTIMATE_CREATE_ESTIMATE_FINISH',
  IMPORT_ESTIMATE_CREATE_ESTIMATE_INIT = 'IMPORT_ESTIMATE_CREATE_ESTIMATE_INIT',
  IMPORT_ESTIMATE_UPLOAD_FINISH = 'IMPORT_ESTIMATE_UPLOAD_FINISH',
  IMPORT_ESTIMATE_UPLOAD_INIT = 'IMPORT_ESTIMATE_UPLOAD_INIT',
  MILESTONE_TRANSITION = 'MILESTONE_TRANSITION',
  PAGE_LOAD = 'PAGE_LOAD',
}

export type UserLink = {
  __typename?: 'UserLink';
  email: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  picture: Scalars['String']['output'];
  pictureAsset?: Maybe<Asset>;
  status: Scalars['UserStatus']['output'];
};

export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  assignments: Scalars['Boolean']['output'];
  channel: NotificationChannel;
  comments: CommentNotificationSetting;
  isGlobalSubscribed: Scalars['Boolean']['output'];
  mentions: Scalars['Boolean']['output'];
  newItems: Scalars['Boolean']['output'];
  shareDraftItem: Scalars['Boolean']['output'];
  shareScenarios: Scalars['Boolean']['output'];
};

export type UserNotificationSettingInput = {
  assignments?: InputMaybe<Scalars['Boolean']['input']>;
  comments?: InputMaybe<CommentNotificationSetting>;
  isGlobalSubscribed?: InputMaybe<Scalars['Boolean']['input']>;
  mentions?: InputMaybe<Scalars['Boolean']['input']>;
  newItems?: InputMaybe<Scalars['Boolean']['input']>;
  shareDraftItem?: InputMaybe<Scalars['Boolean']['input']>;
  shareScenarios?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserProject = {
  __typename?: 'UserProject';
  collaboratorID: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  roleID: Scalars['UUID']['output'];
  roles: Array<PartialRole>;
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type UserProjectSortBy = {
  sortDirection: SortDirection;
  sortKey: UserProjectSortKey;
};

export enum UserProjectSortKey {
  SORT_USER_PROJECT_LOCATION = 'SORT_USER_PROJECT_LOCATION',
  SORT_USER_PROJECT_NAME = 'SORT_USER_PROJECT_NAME',
  SORT_USER_PROJECT_ROLE = 'SORT_USER_PROJECT_ROLE',
  SORT_USER_PROJECT_STATUS = 'SORT_USER_PROJECT_STATUS',
  SORT_USER_PROJECT_TYPE = 'SORT_USER_PROJECT_TYPE',
}

export type UserReport = {
  __typename?: 'UserReport';
  createdBy: User;
  description: Scalars['String']['output'];
  hasDistributionScheduled: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  isFollowingActiveMilestone?: Maybe<Scalars['Boolean']['output']>;
  milestoneID?: Maybe<Scalars['UUID']['output']>;
  name: Scalars['String']['output'];
  owner: Scalars['Boolean']['output'];
  projectID: Scalars['UUID']['output'];
  reportType: UserReportType;
  settings: Scalars['String']['output'];
  shared: Scalars['Boolean']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type UserReportComment = {
  __typename?: 'UserReportComment';
  comment: Comment;
  commentLineID: Scalars['String']['output'];
  costs: Array<Scalars['USCents']['output']>;
  type: UserReportCommentType;
};

export type UserReportCommentInput = {
  categoryIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  costs: Array<Scalars['USCents']['input']>;
  itemID?: InputMaybe<Scalars['UUID']['input']>;
  itemLineID?: InputMaybe<Scalars['UUID']['input']>;
  markupID?: InputMaybe<Scalars['UUID']['input']>;
  text: MentionableTextInput;
  viewParameters: UserReportCommentViewParameters;
};

export enum UserReportCommentType {
  CATEGORY = 'CATEGORY',
  ITEM = 'ITEM',
  ITEM_ESTIMATE_LINE = 'ITEM_ESTIMATE_LINE',
  MARKUP = 'MARKUP',
}

export type UserReportCommentViewParameters = {
  groupBys: Array<Scalars['UUID']['input']>;
  milestoneEstimates: Array<MilestoneEstimateInfo>;
};

export type UserReportInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  reportType: UserReportType;
  settings: Scalars['String']['input'];
  shared: Scalars['Boolean']['input'];
};

export enum UserReportType {
  NONE = 'NONE',
  USER_REPORT_ACTIVE_BUDGET = 'USER_REPORT_ACTIVE_BUDGET',
  USER_REPORT_ACTIVE_ESTIMATE = 'USER_REPORT_ACTIVE_ESTIMATE',
  USER_REPORT_BREAKDOWNS = 'USER_REPORT_BREAKDOWNS',
  USER_REPORT_CONTINGENCY_ALL_MILESTONES = 'USER_REPORT_CONTINGENCY_ALL_MILESTONES',
  USER_REPORT_CONTINGENCY_DRAW = 'USER_REPORT_CONTINGENCY_DRAW',
  USER_REPORT_DASHBOARD = 'USER_REPORT_DASHBOARD',
  USER_REPORT_ITEMS_LIST = 'USER_REPORT_ITEMS_LIST',
  USER_REPORT_ITEMS_LIST_DETAILS = 'USER_REPORT_ITEMS_LIST_DETAILS',
  USER_REPORT_MSR = 'USER_REPORT_MSR',
  USER_REPORT_PROJECT_COST_BREAKDOWN = 'USER_REPORT_PROJECT_COST_BREAKDOWN',
  USER_REPORT_VARIANCE = 'USER_REPORT_VARIANCE',
}

export type UserSource = {
  __typename?: 'UserSource';
  companyID?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  sourceSystem: SourceSystem;
};

export type UserSources = {
  __typename?: 'UserSources';
  clients: Array<SourceClient>;
  sources: Array<UserSource>;
};

export type VarianceColumnSet = {
  columns: Array<CostReportColumnInput>;
  date?: InputMaybe<Scalars['Time']['input']>;
  milestoneID: Scalars['UUID']['input'];
};

export type VarianceReportComments = {
  __typename?: 'VarianceReportComments';
  itemComments: Array<UserReportComment>;
  itemLineComments: Array<UserReportComment>;
  markupComments: Array<UserReportComment>;
  subtotalComments: Array<UserReportComment>;
};

export type VarianceReports = {
  __typename?: 'VarianceReports';
  date?: Maybe<Scalars['Time']['output']>;
  milestoneID?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  varianceColumns: Array<CostReportColumn>;
};

export type VarianceReportsParameters = {
  columnSets: Array<VarianceColumnSet>;
  costMode: CostMode;
  groupByItems: Array<GroupByItem>;
  projectID: Scalars['UUID']['input'];
  shouldIncludeOwnerCosts?: InputMaybe<Scalars['Boolean']['input']>;
  viewFilter: ViewFilter;
};

export type VerifyEmailResult = {
  __typename?: 'VerifyEmailResult';
  isVerified: Scalars['Boolean']['output'];
};

export type ViewFilter = {
  categories?: InputMaybe<Array<CategoryInput>>;
  uncategorizedCategorizationIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ViewFilterReturn = {
  __typename?: 'ViewFilterReturn';
  categories?: Maybe<Array<CategoryInputReturn>>;
};

export enum Visibility {
  INTERNAL_DRAFT = 'INTERNAL_DRAFT',
  PRIVATE_DRAFT = 'PRIVATE_DRAFT',
  PUBLISHED = 'PUBLISHED',
  SCENARIO = 'SCENARIO',
  SHARED_DRAFT = 'SHARED_DRAFT',
}

export enum VisibilityView {
  ALL_VIEW = 'ALL_VIEW',
  HYBRID_VIEW = 'HYBRID_VIEW',
  PRIVATE_VIEW = 'PRIVATE_VIEW',
  PUBLIC_VIEW = 'PUBLIC_VIEW',
  SCENARIO_ITEM_VIEW = 'SCENARIO_ITEM_VIEW',
  SCENARIO_VIEW = 'SCENARIO_VIEW',
}

export type VisibleDate = {
  __typename?: 'VisibleDate';
  date: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};

export type MilestonesCostTrendsBreakdownsQuery = {
  columns: Array<CostReportColumnInput>;
  costMode: CostMode;
  groupByItems: Array<GroupByItem>;
  intervalType: IntervalType;
  milestones: Array<CostReportMilestoneInput>;
  projectID: Scalars['UUID']['input'];
  summary: Scalars['Boolean']['input'];
  viewFilters: Array<ViewFilter>;
};

export type MilestonesCostTrendsQuery = {
  columns: Array<CostReportColumnInput>;
  costMode: CostMode;
  intervalType: IntervalType;
  projectID: Scalars['UUID']['input'];
  summary: Scalars['Boolean']['input'];
  viewFilter: ViewFilter;
};

export type CommonUserFieldsFragment = {
  __typename?: 'User';
  email: string;
  id: UUID;
  jobTitle: string;
  name: string;
  phone?: string | null;
  picture: string;
  status: UserStatus;
  pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
};

export type CommonCompanyUserFieldsFragment = {
  __typename?: 'CompanyUser';
  isIndependent: boolean;
  company?: {
    __typename?: 'Company';
    id: UUID;
    name: string;
    type: string;
    domain?: string | null;
    assetID?: UUID | null;
    deactivated: boolean;
  } | null;
  matchedCompany?: {
    __typename?: 'Company';
    id: UUID;
    name: string;
    type: string;
    domain?: string | null;
    assetID?: UUID | null;
  } | null;
  role?: { __typename?: 'PartialRole'; id: UUID; name: string } | null;
  user?: {
    __typename?: 'User';
    email: string;
    id: UUID;
    name: string;
    phone?: string | null;
    picture: string;
    didSetup?: Time | null;
    jobTitle: string;
    status: UserStatus;
    activeAt?: Time | null;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
};

export type CommonCategoryFieldsFragment = {
  __typename?: 'Category';
  id: UUID;
  name: string;
  number: string;
  error?: string | null;
  level: number;
  hasSubCategories: boolean;
  categorization?: {
    __typename?: 'Categorization';
    id: UUID;
    name: string;
    builtin: boolean;
    description: string;
    draft: boolean;
    levels: number;
    deprecated: boolean;
  } | null;
  levels: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    hasSubCategories: boolean;
    level: number;
  }>;
};

type CostTypeFields_CostRange_Fragment = { __typename?: 'CostRange'; min: USCents; max: USCents };

type CostTypeFields_CostScalar_Fragment = { __typename?: 'CostScalar'; value: USCents };

export type CostTypeFieldsFragment =
  | CostTypeFields_CostRange_Fragment
  | CostTypeFields_CostScalar_Fragment;

export type ScheduleImpactFieldsFragment = {
  __typename?: 'ScheduleImpact';
  type: ScheduleImpactType;
  criticalPath: boolean;
  days: number;
  minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
  maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
};

export type CommonItemLikeItemFieldsFragment = {
  __typename?: 'Item';
  id: UUID;
  itemType: ItemType;
  description: string;
  descriptionStyled: string;
  dueDate?: Time | null;
  creationTime?: Time | null;
  updateTime?: Time | null;
  name: string;
  number: string;
  status: Status;
  visibility: Visibility;
  availableStates: Array<Status>;
  assetCount: number;
  remoteAssetCount: number;
  commentCount: number;
  filteredMilestone?: UUID | null;
  filteredCategories?: Array<UUID> | null;
  activityIDs?: Array<UUID> | null;
  bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
  project: { __typename?: 'Project'; id: UUID; name: string };
  createdBy?: {
    __typename?: 'User';
    id: UUID;
    email: string;
    name: string;
    picture: string;
  } | null;
  milestone?: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    date?: Time | null;
    buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
  } | null;
  categories: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    error?: string | null;
    level: number;
    hasSubCategories: boolean;
    categorization?: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      builtin: boolean;
      description: string;
      draft: boolean;
      levels: number;
      deprecated: boolean;
    } | null;
    levels: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
    }>;
  }>;
  cost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  estimateCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  contingencyDrawCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  assignee?: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
  previousMilestoneStatus: Array<{
    __typename?: 'PreviousMilestoneStatus';
    hasEstimate?: boolean | null;
    milestoneID: UUID;
    itemType: ItemType;
    status: Status;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
  }>;
  scheduleImpact?: {
    __typename?: 'ScheduleImpact';
    type: ScheduleImpactType;
    criticalPath: boolean;
    days: number;
    minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
  } | null;
};

export type CommonCategorizationFieldsFragment = {
  __typename?: 'Categorization';
  id: UUID;
  name: string;
  description: string;
  levels: number;
  builtin: boolean;
};

export type CommonMarkupFieldsFragment = {
  __typename?: 'Markup';
  id: UUID;
  estimateId: UUID;
  name: string;
  percent?: number | null;
  percentScale?: number | null;
  sourceFilterIDs: Array<UUID>;
  sourceFilterIDsWithoutS2Reference: Array<UUID>;
  total: USCents;
  type: MarkupType;
  displayType: MarkupDisplayType;
  drawFromID?: UUID | null;
  value?: Numeric | null;
  disabled: boolean;
  orderingNumerator: Ordering;
  orderingDenominator: Ordering;
  costTypeFilters?: Array<CostType> | null;
  shouldExcludeFromAllocation: boolean;
  shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
  errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
  markupReference?: {
    __typename?: 'MarkupReference';
    appliesTo: Array<string>;
    item?: {
      __typename?: 'MarkupItemReference';
      number: string;
      name: string;
      isDeprecated: boolean;
    } | null;
  } | null;
  categoryFilters: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    level: number;
    error?: string | null;
    categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
  }>;
  source?: {
    __typename?: 'SourceIDs';
    itemID?: UUID | null;
    estimateID: UUID;
    milestoneID: UUID;
    lineID?: UUID | null;
    markupID?: UUID | null;
  } | null;
};

export type CommonItemLikeOptionFieldsFragment = {
  __typename?: 'Option';
  id: UUID;
  itemType: ItemType;
  description: string;
  descriptionStyled: string;
  dueDate?: Time | null;
  creationTime?: Time | null;
  updateTime?: Time | null;
  name: string;
  number: string;
  status: Status;
  visibility: Visibility;
  availableStates: Array<Status>;
  assetCount: number;
  remoteAssetCount: number;
  commentCount: number;
  filteredMilestone?: UUID | null;
  filteredCategories?: Array<UUID> | null;
  activityIDs?: Array<UUID> | null;
  bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
  project: { __typename?: 'Project'; id: UUID; name: string };
  createdBy?: {
    __typename?: 'User';
    id: UUID;
    email: string;
    name: string;
    picture: string;
  } | null;
  milestone?: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    date?: Time | null;
    buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
  } | null;
  categories: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    error?: string | null;
    level: number;
    hasSubCategories: boolean;
    categorization?: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      builtin: boolean;
      description: string;
      draft: boolean;
      levels: number;
      deprecated: boolean;
    } | null;
    levels: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
    }>;
  }>;
  cost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  estimateCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  contingencyDrawCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  assignee?: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
  previousMilestoneStatus: Array<{
    __typename?: 'PreviousMilestoneStatus';
    hasEstimate?: boolean | null;
    milestoneID: UUID;
    itemType: ItemType;
    status: Status;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
  }>;
  scheduleImpact?: {
    __typename?: 'ScheduleImpact';
    type: ScheduleImpactType;
    criticalPath: boolean;
    days: number;
    minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
  } | null;
};

export type CommonCategoryUniqueFieldsFragment = {
  __typename?: 'Category';
  id: UUID;
  parentID?: UUID | null;
  name: string;
  hasSubCategories: boolean;
  number: string;
  level: number;
  error?: string | null;
  categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
  levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
};

export type ImportEstimateErrorFieldsFragment = {
  __typename?: 'ImportEstimateError';
  id: UUID;
  type: ImportEstimateErrorType;
  level: ImportEstimateErrorLevel;
  unresolvedCount: number;
  resolvedCount: number;
  created: Time;
  updated?: Time | null;
  content: {
    __typename?: 'ImportErrorContentMissingCategories';
    lines: number;
    categorization: { __typename?: 'CategorizationContent'; id: UUID; name: string };
    categories: Array<{ __typename?: 'CategoryContent'; number: string; name: string }>;
  };
};

export type FullCategoryCategorizationFieldsFragment = {
  __typename?: 'Category';
  id: UUID;
  name: string;
  hasSubCategories: boolean;
  number: string;
  level: number;
  error?: string | null;
  categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
};

export type CommonCellFieldsFragment = {
  __typename?: 'Cell';
  estimateId: UUID;
  field: UUID;
  line: UUID;
  error?: string | null;
  value?:
    | {
        __typename?: 'CategoryCell';
        search: string;
        category?: {
          __typename?: 'Category';
          id: UUID;
          parentID?: UUID | null;
          name: string;
          hasSubCategories: boolean;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
        } | null;
      }
    | { __typename?: 'RegularCell'; string: string }
    | {
        __typename?: 'SourceCell';
        sourceIDs?: {
          __typename?: 'SourceIDs';
          estimateID: UUID;
          itemID?: UUID | null;
          lineID?: UUID | null;
          markupID?: UUID | null;
          milestoneID: UUID;
        } | null;
      }
    | null;
};

export type CategoryGridFieldsFragment = {
  __typename?: 'Category';
  id: UUID;
  name: string;
  number: string;
  error?: string | null;
  categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
};

export type GridCellFieldsFragment = {
  __typename?: 'Cell';
  error?: string | null;
  value?:
    | {
        __typename?: 'CategoryCell';
        search: string;
        category?: {
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        } | null;
      }
    | {
        __typename?: 'RegularCell';
        string: string;
        formula: string;
        formulaDisplay: Array<{
          __typename?: 'FormulaDisplay';
          type: FormulaDisplayType;
          text: string;
          error: string;
        }>;
      }
    | {
        __typename?: 'SourceCell';
        sourceIDs?: {
          __typename?: 'SourceIDs';
          estimateID: UUID;
          itemID?: UUID | null;
          lineID?: UUID | null;
          markupID?: UUID | null;
          milestoneID: UUID;
        } | null;
      }
    | null;
};

export type IndexedGridCellFieldsFragment = {
  __typename?: 'Cell';
  field: UUID;
  line: UUID;
  error?: string | null;
  value?:
    | {
        __typename?: 'CategoryCell';
        search: string;
        category?: {
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        } | null;
      }
    | {
        __typename?: 'RegularCell';
        string: string;
        formula: string;
        formulaDisplay: Array<{
          __typename?: 'FormulaDisplay';
          type: FormulaDisplayType;
          text: string;
          error: string;
        }>;
      }
    | {
        __typename?: 'SourceCell';
        sourceIDs?: {
          __typename?: 'SourceIDs';
          estimateID: UUID;
          itemID?: UUID | null;
          lineID?: UUID | null;
          markupID?: UUID | null;
          milestoneID: UUID;
        } | null;
      }
    | null;
};

export type GridLineFieldsFragment = {
  __typename?: 'Line';
  id: UUID;
  orderingNumerator: Ordering;
  orderingDenominator: Ordering;
  cells: Array<{
    __typename?: 'Cell';
    error?: string | null;
    value?:
      | {
          __typename?: 'CategoryCell';
          search: string;
          category?: {
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          } | null;
        }
      | {
          __typename?: 'RegularCell';
          string: string;
          formula: string;
          formulaDisplay: Array<{
            __typename?: 'FormulaDisplay';
            type: FormulaDisplayType;
            text: string;
            error: string;
          }>;
        }
      | {
          __typename?: 'SourceCell';
          sourceIDs?: {
            __typename?: 'SourceIDs';
            estimateID: UUID;
            itemID?: UUID | null;
            lineID?: UUID | null;
            markupID?: UUID | null;
            milestoneID: UUID;
          } | null;
        }
      | null;
  }>;
};

export type CommonLineFieldsFragment = {
  __typename?: 'Line';
  id: UUID;
  estimateId: UUID;
  orderingNumerator: Ordering;
  orderingDenominator: Ordering;
  cells: Array<{
    __typename?: 'Cell';
    estimateId: UUID;
    field: UUID;
    line: UUID;
    error?: string | null;
    value?:
      | {
          __typename?: 'CategoryCell';
          search: string;
          category?: {
            __typename?: 'Category';
            id: UUID;
            parentID?: UUID | null;
            name: string;
            hasSubCategories: boolean;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
          } | null;
        }
      | { __typename?: 'RegularCell'; string: string }
      | {
          __typename?: 'SourceCell';
          sourceIDs?: {
            __typename?: 'SourceIDs';
            estimateID: UUID;
            itemID?: UUID | null;
            lineID?: UUID | null;
            markupID?: UUID | null;
            milestoneID: UUID;
          } | null;
        }
      | null;
  }>;
};

export type GridFieldFieldsFragment = {
  __typename?: 'Field';
  id: UUID;
  name: string;
  type: string;
  group: string;
  categorization?: {
    __typename?: 'Categorization';
    id: UUID;
    name: string;
    description: string;
    levels: number;
    builtin: boolean;
    createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
  } | null;
  errors: Array<{
    __typename?: 'FieldError';
    type: FieldErrorType;
    count: number;
    categoryErrorIndicies: Array<{
      __typename?: 'CategoryErrorIndicies';
      categoryNumber: string;
      indicies: Array<number>;
    }>;
  }>;
};

export type LimitedUpdateEstimateResultFragment = {
  __typename?: 'EstimateUpdateResult';
  estimate?: {
    __typename?: 'Estimate';
    subtotal: Numeric;
    markupSubtotal: USCents;
    inheritedSubtotal: USCents;
    incorporatedSubtotal: USCents;
    incorporatedDrawsSubtotal: USCents;
    inheritedOwnerCostMarkupsSubtotal: USCents;
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
    inheritedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    markups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    incorporatedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    incorporatedDraws: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    inheritedOwnerCostMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    ownerCostEstimate?: {
      __typename?: 'Estimate';
      id: UUID;
      linesCount?: number | null;
      name: string;
      milestoneName?: string | null;
      subtotal: Numeric;
      markupSubtotal: USCents;
      incorporatedSubtotal: USCents;
      incorporatedDrawsSubtotal: USCents;
      inheritedSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      drawSubtotal: USCents;
      totalType: EstimateTotalType;
      isRunningTotal: boolean;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      incorporatedDraws: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      contingencyDraws?: Array<{
        __typename?: 'ItemDrawInfo';
        id: UUID;
        name: string;
        error: string;
        draw: USCents;
      }> | null;
    } | null;
  } | null;
};

export type QuantityUpdateEstimateResultFragment = {
  __typename?: 'EstimateUpdateResult';
  estimate?: {
    __typename?: 'Estimate';
    subtotal: Numeric;
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
  } | null;
};

export type CommonEstimateFieldsFragment = {
  __typename?: 'Estimate';
  id: UUID;
  name: string;
  subtotal: Numeric;
  markupSubtotal: USCents;
  drawSubtotal: USCents;
  inheritedSubtotal: USCents;
  inheritedOwnerCostMarkupsSubtotal: USCents;
  asset?: UUID | null;
  totalType: EstimateTotalType;
  isRunningTotal: boolean;
  lines: Array<{
    __typename?: 'Line';
    id: UUID;
    estimateId: UUID;
    orderingNumerator: Ordering;
    orderingDenominator: Ordering;
    cells: Array<{
      __typename?: 'Cell';
      estimateId: UUID;
      field: UUID;
      line: UUID;
      error?: string | null;
      value?:
        | {
            __typename?: 'CategoryCell';
            search: string;
            category?: {
              __typename?: 'Category';
              id: UUID;
              parentID?: UUID | null;
              name: string;
              hasSubCategories: boolean;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
            } | null;
          }
        | { __typename?: 'RegularCell'; string: string }
        | {
            __typename?: 'SourceCell';
            sourceIDs?: {
              __typename?: 'SourceIDs';
              estimateID: UUID;
              itemID?: UUID | null;
              lineID?: UUID | null;
              markupID?: UUID | null;
              milestoneID: UUID;
            } | null;
          }
        | null;
    }>;
  }>;
  fields: Array<{
    __typename?: 'Field';
    id: UUID;
    name: string;
    type: string;
    group: string;
    categorization?: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      description: string;
      levels: number;
      builtin: boolean;
      createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
    } | null;
    errors: Array<{
      __typename?: 'FieldError';
      type: FieldErrorType;
      count: number;
      categoryErrorIndicies: Array<{
        __typename?: 'CategoryErrorIndicies';
        categoryNumber: string;
        indicies: Array<number>;
      }>;
    }>;
  }>;
  incorporatedMarkups: Array<{
    __typename?: 'Markup';
    id: UUID;
    estimateId: UUID;
    name: string;
    percent?: number | null;
    percentScale?: number | null;
    sourceFilterIDs: Array<UUID>;
    sourceFilterIDsWithoutS2Reference: Array<UUID>;
    total: USCents;
    type: MarkupType;
    displayType: MarkupDisplayType;
    drawFromID?: UUID | null;
    value?: Numeric | null;
    disabled: boolean;
    orderingNumerator: Ordering;
    orderingDenominator: Ordering;
    costTypeFilters?: Array<CostType> | null;
    shouldExcludeFromAllocation: boolean;
    shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
    errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
    markupReference?: {
      __typename?: 'MarkupReference';
      appliesTo: Array<string>;
      item?: {
        __typename?: 'MarkupItemReference';
        number: string;
        name: string;
        isDeprecated: boolean;
      } | null;
    } | null;
    categoryFilters: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    }>;
    source?: {
      __typename?: 'SourceIDs';
      itemID?: UUID | null;
      estimateID: UUID;
      milestoneID: UUID;
      lineID?: UUID | null;
      markupID?: UUID | null;
    } | null;
  }>;
  contingencyDraws?: Array<{
    __typename?: 'ItemDrawInfo';
    id: UUID;
    type: MarkupDisplayType;
    name: string;
    error: string;
    draw: USCents;
    drawFromID?: UUID | null;
    isDrawingFullAmount: boolean;
  }> | null;
  inheritedMarkups: Array<{
    __typename?: 'Markup';
    id: UUID;
    estimateId: UUID;
    name: string;
    percent?: number | null;
    percentScale?: number | null;
    sourceFilterIDs: Array<UUID>;
    sourceFilterIDsWithoutS2Reference: Array<UUID>;
    total: USCents;
    type: MarkupType;
    displayType: MarkupDisplayType;
    drawFromID?: UUID | null;
    value?: Numeric | null;
    disabled: boolean;
    orderingNumerator: Ordering;
    orderingDenominator: Ordering;
    costTypeFilters?: Array<CostType> | null;
    shouldExcludeFromAllocation: boolean;
    shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
    errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
    markupReference?: {
      __typename?: 'MarkupReference';
      appliesTo: Array<string>;
      item?: {
        __typename?: 'MarkupItemReference';
        number: string;
        name: string;
        isDeprecated: boolean;
      } | null;
    } | null;
    categoryFilters: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    }>;
    source?: {
      __typename?: 'SourceIDs';
      itemID?: UUID | null;
      estimateID: UUID;
      milestoneID: UUID;
      lineID?: UUID | null;
      markupID?: UUID | null;
    } | null;
  }>;
  inheritedOwnerCostMarkups: Array<{
    __typename?: 'Markup';
    id: UUID;
    estimateId: UUID;
    name: string;
    percent?: number | null;
    percentScale?: number | null;
    sourceFilterIDs: Array<UUID>;
    sourceFilterIDsWithoutS2Reference: Array<UUID>;
    total: USCents;
    type: MarkupType;
    displayType: MarkupDisplayType;
    drawFromID?: UUID | null;
    value?: Numeric | null;
    disabled: boolean;
    orderingNumerator: Ordering;
    orderingDenominator: Ordering;
    costTypeFilters?: Array<CostType> | null;
    shouldExcludeFromAllocation: boolean;
    shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
    errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
    markupReference?: {
      __typename?: 'MarkupReference';
      appliesTo: Array<string>;
      item?: {
        __typename?: 'MarkupItemReference';
        number: string;
        name: string;
        isDeprecated: boolean;
      } | null;
    } | null;
    categoryFilters: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    }>;
    source?: {
      __typename?: 'SourceIDs';
      itemID?: UUID | null;
      estimateID: UUID;
      milestoneID: UUID;
      lineID?: UUID | null;
      markupID?: UUID | null;
    } | null;
  }>;
  markups: Array<{
    __typename?: 'Markup';
    id: UUID;
    estimateId: UUID;
    name: string;
    percent?: number | null;
    percentScale?: number | null;
    sourceFilterIDs: Array<UUID>;
    sourceFilterIDsWithoutS2Reference: Array<UUID>;
    total: USCents;
    type: MarkupType;
    displayType: MarkupDisplayType;
    drawFromID?: UUID | null;
    value?: Numeric | null;
    disabled: boolean;
    orderingNumerator: Ordering;
    orderingDenominator: Ordering;
    costTypeFilters?: Array<CostType> | null;
    shouldExcludeFromAllocation: boolean;
    shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
    errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
    markupReference?: {
      __typename?: 'MarkupReference';
      appliesTo: Array<string>;
      item?: {
        __typename?: 'MarkupItemReference';
        number: string;
        name: string;
        isDeprecated: boolean;
      } | null;
    } | null;
    categoryFilters: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    }>;
    source?: {
      __typename?: 'SourceIDs';
      itemID?: UUID | null;
      estimateID: UUID;
      milestoneID: UUID;
      lineID?: UUID | null;
      markupID?: UUID | null;
    } | null;
  }>;
};

export type DraftEstimateInfoFieldsFragment = {
  __typename?: 'DraftEstimateInfo';
  author: UUID;
  asset?: UUID | null;
};

export type MilestoneLinkFieldsFragment = { __typename?: 'MilestoneLink'; id: UUID; name: string };

export type UserLinkFieldsWithoutPictureFragment = {
  __typename?: 'UserLink';
  id: UUID;
  name: string;
  status: UserStatus;
};

export type ItemLinkFieldsFragment = {
  __typename?: 'ItemLink';
  id: UUID;
  name: string;
  number: string;
  status: Status;
  visibility?: Visibility | null;
};

export type ItemHistoryLinkFieldsFragment = {
  __typename?: 'ItemHistoryLink';
  id: UUID;
  name: string;
  number: string;
  status: Status;
  deprecated: boolean;
  visibility?: Visibility | null;
};

export type ItemsListItemSummaryFieldsFragment = {
  __typename?: 'ItemsListItem';
  createdAt: Time;
  currentStatus: Status;
  id: UUID;
  name: string;
  number: string;
  status: Status;
  updateTime: Time;
  visibility: Visibility;
  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
  currentCost:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents };
  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
  options: Array<{
    __typename?: 'ItemLink';
    id: UUID;
    name: string;
    number: string;
    status: Status;
    visibility?: Visibility | null;
  }>;
};

export type CommonCommentFieldsFragment = {
  __typename?: 'Comment';
  id: UUID;
  created: Time;
  author: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  };
  text: {
    __typename?: 'MentionableText';
    contents: string;
    mentions?: Array<{
      __typename?: 'Mention';
      id: UUID;
      position: number;
      text: string;
      user: { __typename?: 'User'; id: UUID };
    }> | null;
    itemReferences?: Array<{
      __typename?: 'ItemReference';
      id: UUID;
      position: number;
      text: string;
      referencedItem?: {
        __typename?: 'ItemsListItem';
        createdAt: Time;
        currentStatus: Status;
        id: UUID;
        name: string;
        number: string;
        status: Status;
        updateTime: Time;
        visibility: Visibility;
        creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
        currentCost:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
        options: Array<{
          __typename?: 'ItemLink';
          id: UUID;
          name: string;
          number: string;
          status: Status;
          visibility?: Visibility | null;
        }>;
      } | null;
    }> | null;
  };
};

export type CommonUserReportCommentFieldsFragment = {
  __typename?: 'UserReportComment';
  commentLineID: string;
  type: UserReportCommentType;
  costs: Array<USCents>;
  comment: {
    __typename?: 'Comment';
    id: UUID;
    created: Time;
    author: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    text: {
      __typename?: 'MentionableText';
      contents: string;
      mentions?: Array<{
        __typename?: 'Mention';
        id: UUID;
        position: number;
        text: string;
        user: { __typename?: 'User'; id: UUID };
      }> | null;
      itemReferences?: Array<{
        __typename?: 'ItemReference';
        id: UUID;
        position: number;
        text: string;
        referencedItem?: {
          __typename?: 'ItemsListItem';
          createdAt: Time;
          currentStatus: Status;
          id: UUID;
          name: string;
          number: string;
          status: Status;
          updateTime: Time;
          visibility: Visibility;
          creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
          currentCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
          options: Array<{
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            status: Status;
            visibility?: Visibility | null;
          }>;
        } | null;
      }> | null;
    };
  };
};

export type DetailedItemLikeOptionFieldsFragment = {
  __typename?: 'Option';
  id: UUID;
  itemType: ItemType;
  description: string;
  descriptionStyled: string;
  dueDate?: Time | null;
  creationTime?: Time | null;
  updateTime?: Time | null;
  name: string;
  number: string;
  status: Status;
  visibility: Visibility;
  availableStates: Array<Status>;
  assetCount: number;
  remoteAssetCount: number;
  commentCount: number;
  filteredMilestone?: UUID | null;
  filteredCategories?: Array<UUID> | null;
  activityIDs?: Array<UUID> | null;
  comments: Array<{
    __typename?: 'Comment';
    id: UUID;
    created: Time;
    author: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    text: {
      __typename?: 'MentionableText';
      contents: string;
      mentions?: Array<{
        __typename?: 'Mention';
        id: UUID;
        position: number;
        text: string;
        user: { __typename?: 'User'; id: UUID };
      }> | null;
      itemReferences?: Array<{
        __typename?: 'ItemReference';
        id: UUID;
        position: number;
        text: string;
        referencedItem?: {
          __typename?: 'ItemsListItem';
          createdAt: Time;
          currentStatus: Status;
          id: UUID;
          name: string;
          number: string;
          status: Status;
          updateTime: Time;
          visibility: Visibility;
          creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
          currentCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
          options: Array<{
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            status: Status;
            visibility?: Visibility | null;
          }>;
        } | null;
      }> | null;
    };
  }>;
  assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
  activeEstimate?: {
    __typename?: 'Estimate';
    id: UUID;
    name: string;
    subtotal: Numeric;
    markupSubtotal: USCents;
    drawSubtotal: USCents;
    inheritedSubtotal: USCents;
    inheritedOwnerCostMarkupsSubtotal: USCents;
    asset?: UUID | null;
    totalType: EstimateTotalType;
    isRunningTotal: boolean;
    lines: Array<{
      __typename?: 'Line';
      id: UUID;
      estimateId: UUID;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      cells: Array<{
        __typename?: 'Cell';
        estimateId: UUID;
        field: UUID;
        line: UUID;
        error?: string | null;
        value?:
          | {
              __typename?: 'CategoryCell';
              search: string;
              category?: {
                __typename?: 'Category';
                id: UUID;
                parentID?: UUID | null;
                name: string;
                hasSubCategories: boolean;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
              } | null;
            }
          | { __typename?: 'RegularCell'; string: string }
          | {
              __typename?: 'SourceCell';
              sourceIDs?: {
                __typename?: 'SourceIDs';
                estimateID: UUID;
                itemID?: UUID | null;
                lineID?: UUID | null;
                markupID?: UUID | null;
                milestoneID: UUID;
              } | null;
            }
          | null;
      }>;
    }>;
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
    incorporatedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    contingencyDraws?: Array<{
      __typename?: 'ItemDrawInfo';
      id: UUID;
      type: MarkupDisplayType;
      name: string;
      error: string;
      draw: USCents;
      drawFromID?: UUID | null;
      isDrawingFullAmount: boolean;
    }> | null;
    inheritedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    inheritedOwnerCostMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    markups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
  } | null;
  bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
  project: { __typename?: 'Project'; id: UUID; name: string };
  createdBy?: {
    __typename?: 'User';
    id: UUID;
    email: string;
    name: string;
    picture: string;
  } | null;
  milestone?: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    date?: Time | null;
    buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
  } | null;
  categories: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    error?: string | null;
    level: number;
    hasSubCategories: boolean;
    categorization?: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      builtin: boolean;
      description: string;
      draft: boolean;
      levels: number;
      deprecated: boolean;
    } | null;
    levels: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
    }>;
  }>;
  cost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  estimateCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  contingencyDrawCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  assignee?: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
  previousMilestoneStatus: Array<{
    __typename?: 'PreviousMilestoneStatus';
    hasEstimate?: boolean | null;
    milestoneID: UUID;
    itemType: ItemType;
    status: Status;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
  }>;
  scheduleImpact?: {
    __typename?: 'ScheduleImpact';
    type: ScheduleImpactType;
    criticalPath: boolean;
    days: number;
    minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
  } | null;
};

export type DetailedItemLikeItemFieldsFragment = {
  __typename?: 'Item';
  id: UUID;
  itemType: ItemType;
  description: string;
  descriptionStyled: string;
  dueDate?: Time | null;
  creationTime?: Time | null;
  updateTime?: Time | null;
  name: string;
  number: string;
  status: Status;
  visibility: Visibility;
  availableStates: Array<Status>;
  assetCount: number;
  remoteAssetCount: number;
  commentCount: number;
  filteredMilestone?: UUID | null;
  filteredCategories?: Array<UUID> | null;
  activityIDs?: Array<UUID> | null;
  comments: Array<{
    __typename?: 'Comment';
    id: UUID;
    created: Time;
    author: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    text: {
      __typename?: 'MentionableText';
      contents: string;
      mentions?: Array<{
        __typename?: 'Mention';
        id: UUID;
        position: number;
        text: string;
        user: { __typename?: 'User'; id: UUID };
      }> | null;
      itemReferences?: Array<{
        __typename?: 'ItemReference';
        id: UUID;
        position: number;
        text: string;
        referencedItem?: {
          __typename?: 'ItemsListItem';
          createdAt: Time;
          currentStatus: Status;
          id: UUID;
          name: string;
          number: string;
          status: Status;
          updateTime: Time;
          visibility: Visibility;
          creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
          currentCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
          options: Array<{
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            status: Status;
            visibility?: Visibility | null;
          }>;
        } | null;
      }> | null;
    };
  }>;
  assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
  activeEstimate?: {
    __typename?: 'Estimate';
    id: UUID;
    name: string;
    subtotal: Numeric;
    markupSubtotal: USCents;
    drawSubtotal: USCents;
    inheritedSubtotal: USCents;
    inheritedOwnerCostMarkupsSubtotal: USCents;
    asset?: UUID | null;
    totalType: EstimateTotalType;
    isRunningTotal: boolean;
    lines: Array<{
      __typename?: 'Line';
      id: UUID;
      estimateId: UUID;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      cells: Array<{
        __typename?: 'Cell';
        estimateId: UUID;
        field: UUID;
        line: UUID;
        error?: string | null;
        value?:
          | {
              __typename?: 'CategoryCell';
              search: string;
              category?: {
                __typename?: 'Category';
                id: UUID;
                parentID?: UUID | null;
                name: string;
                hasSubCategories: boolean;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
              } | null;
            }
          | { __typename?: 'RegularCell'; string: string }
          | {
              __typename?: 'SourceCell';
              sourceIDs?: {
                __typename?: 'SourceIDs';
                estimateID: UUID;
                itemID?: UUID | null;
                lineID?: UUID | null;
                markupID?: UUID | null;
                milestoneID: UUID;
              } | null;
            }
          | null;
      }>;
    }>;
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
    incorporatedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    contingencyDraws?: Array<{
      __typename?: 'ItemDrawInfo';
      id: UUID;
      type: MarkupDisplayType;
      name: string;
      error: string;
      draw: USCents;
      drawFromID?: UUID | null;
      isDrawingFullAmount: boolean;
    }> | null;
    inheritedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    inheritedOwnerCostMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    markups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
  } | null;
  bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
  project: { __typename?: 'Project'; id: UUID; name: string };
  createdBy?: {
    __typename?: 'User';
    id: UUID;
    email: string;
    name: string;
    picture: string;
  } | null;
  milestone?: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    date?: Time | null;
    buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
  } | null;
  categories: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    error?: string | null;
    level: number;
    hasSubCategories: boolean;
    categorization?: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      builtin: boolean;
      description: string;
      draft: boolean;
      levels: number;
      deprecated: boolean;
    } | null;
    levels: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
    }>;
  }>;
  cost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  estimateCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  contingencyDrawCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  assignee?: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
  previousMilestoneStatus: Array<{
    __typename?: 'PreviousMilestoneStatus';
    hasEstimate?: boolean | null;
    milestoneID: UUID;
    itemType: ItemType;
    status: Status;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
  }>;
  scheduleImpact?: {
    __typename?: 'ScheduleImpact';
    type: ScheduleImpactType;
    criticalPath: boolean;
    days: number;
    minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
  } | null;
};

export type DetailedOptionFieldsFragment = {
  __typename?: 'Option';
  parent: UUID;
  parentVisibility?: Visibility | null;
  hasEstimate?: boolean | null;
  id: UUID;
  itemType: ItemType;
  description: string;
  descriptionStyled: string;
  dueDate?: Time | null;
  creationTime?: Time | null;
  updateTime?: Time | null;
  name: string;
  number: string;
  status: Status;
  visibility: Visibility;
  availableStates: Array<Status>;
  assetCount: number;
  remoteAssetCount: number;
  commentCount: number;
  filteredMilestone?: UUID | null;
  filteredCategories?: Array<UUID> | null;
  activityIDs?: Array<UUID> | null;
  comments: Array<{
    __typename?: 'Comment';
    id: UUID;
    created: Time;
    author: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    text: {
      __typename?: 'MentionableText';
      contents: string;
      mentions?: Array<{
        __typename?: 'Mention';
        id: UUID;
        position: number;
        text: string;
        user: { __typename?: 'User'; id: UUID };
      }> | null;
      itemReferences?: Array<{
        __typename?: 'ItemReference';
        id: UUID;
        position: number;
        text: string;
        referencedItem?: {
          __typename?: 'ItemsListItem';
          createdAt: Time;
          currentStatus: Status;
          id: UUID;
          name: string;
          number: string;
          status: Status;
          updateTime: Time;
          visibility: Visibility;
          creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
          currentCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
          options: Array<{
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            status: Status;
            visibility?: Visibility | null;
          }>;
        } | null;
      }> | null;
    };
  }>;
  assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
  activeEstimate?: {
    __typename?: 'Estimate';
    id: UUID;
    name: string;
    subtotal: Numeric;
    markupSubtotal: USCents;
    drawSubtotal: USCents;
    inheritedSubtotal: USCents;
    inheritedOwnerCostMarkupsSubtotal: USCents;
    asset?: UUID | null;
    totalType: EstimateTotalType;
    isRunningTotal: boolean;
    lines: Array<{
      __typename?: 'Line';
      id: UUID;
      estimateId: UUID;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      cells: Array<{
        __typename?: 'Cell';
        estimateId: UUID;
        field: UUID;
        line: UUID;
        error?: string | null;
        value?:
          | {
              __typename?: 'CategoryCell';
              search: string;
              category?: {
                __typename?: 'Category';
                id: UUID;
                parentID?: UUID | null;
                name: string;
                hasSubCategories: boolean;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
              } | null;
            }
          | { __typename?: 'RegularCell'; string: string }
          | {
              __typename?: 'SourceCell';
              sourceIDs?: {
                __typename?: 'SourceIDs';
                estimateID: UUID;
                itemID?: UUID | null;
                lineID?: UUID | null;
                markupID?: UUID | null;
                milestoneID: UUID;
              } | null;
            }
          | null;
      }>;
    }>;
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
    incorporatedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    contingencyDraws?: Array<{
      __typename?: 'ItemDrawInfo';
      id: UUID;
      type: MarkupDisplayType;
      name: string;
      error: string;
      draw: USCents;
      drawFromID?: UUID | null;
      isDrawingFullAmount: boolean;
    }> | null;
    inheritedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    inheritedOwnerCostMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    markups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
  } | null;
  bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
  project: { __typename?: 'Project'; id: UUID; name: string };
  createdBy?: {
    __typename?: 'User';
    id: UUID;
    email: string;
    name: string;
    picture: string;
  } | null;
  milestone?: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    date?: Time | null;
    buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
  } | null;
  categories: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    error?: string | null;
    level: number;
    hasSubCategories: boolean;
    categorization?: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      builtin: boolean;
      description: string;
      draft: boolean;
      levels: number;
      deprecated: boolean;
    } | null;
    levels: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
    }>;
  }>;
  cost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  estimateCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  contingencyDrawCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  assignee?: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
  previousMilestoneStatus: Array<{
    __typename?: 'PreviousMilestoneStatus';
    hasEstimate?: boolean | null;
    milestoneID: UUID;
    itemType: ItemType;
    status: Status;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
  }>;
  scheduleImpact?: {
    __typename?: 'ScheduleImpact';
    type: ScheduleImpactType;
    criticalPath: boolean;
    days: number;
    minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
  } | null;
};

export type DetailedItemFieldsFragment = {
  __typename?: 'Item';
  id: UUID;
  itemType: ItemType;
  description: string;
  descriptionStyled: string;
  dueDate?: Time | null;
  creationTime?: Time | null;
  updateTime?: Time | null;
  name: string;
  number: string;
  status: Status;
  visibility: Visibility;
  availableStates: Array<Status>;
  assetCount: number;
  remoteAssetCount: number;
  commentCount: number;
  filteredMilestone?: UUID | null;
  filteredCategories?: Array<UUID> | null;
  activityIDs?: Array<UUID> | null;
  options: Array<{
    __typename?: 'Option';
    parent: UUID;
    parentVisibility?: Visibility | null;
    hasEstimate?: boolean | null;
    id: UUID;
    itemType: ItemType;
    description: string;
    descriptionStyled: string;
    dueDate?: Time | null;
    creationTime?: Time | null;
    updateTime?: Time | null;
    name: string;
    number: string;
    status: Status;
    visibility: Visibility;
    availableStates: Array<Status>;
    assetCount: number;
    remoteAssetCount: number;
    commentCount: number;
    filteredMilestone?: UUID | null;
    filteredCategories?: Array<UUID> | null;
    activityIDs?: Array<UUID> | null;
    comments: Array<{
      __typename?: 'Comment';
      id: UUID;
      created: Time;
      author: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      text: {
        __typename?: 'MentionableText';
        contents: string;
        mentions?: Array<{
          __typename?: 'Mention';
          id: UUID;
          position: number;
          text: string;
          user: { __typename?: 'User'; id: UUID };
        }> | null;
        itemReferences?: Array<{
          __typename?: 'ItemReference';
          id: UUID;
          position: number;
          text: string;
          referencedItem?: {
            __typename?: 'ItemsListItem';
            createdAt: Time;
            currentStatus: Status;
            id: UUID;
            name: string;
            number: string;
            status: Status;
            updateTime: Time;
            visibility: Visibility;
            creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
            currentCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
            options: Array<{
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              status: Status;
              visibility?: Visibility | null;
            }>;
          } | null;
        }> | null;
      };
    }>;
    assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
    activeEstimate?: {
      __typename?: 'Estimate';
      id: UUID;
      name: string;
      subtotal: Numeric;
      markupSubtotal: USCents;
      drawSubtotal: USCents;
      inheritedSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      asset?: UUID | null;
      totalType: EstimateTotalType;
      isRunningTotal: boolean;
      lines: Array<{
        __typename?: 'Line';
        id: UUID;
        estimateId: UUID;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        cells: Array<{
          __typename?: 'Cell';
          estimateId: UUID;
          field: UUID;
          line: UUID;
          error?: string | null;
          value?:
            | {
                __typename?: 'CategoryCell';
                search: string;
                category?: {
                  __typename?: 'Category';
                  id: UUID;
                  parentID?: UUID | null;
                  name: string;
                  hasSubCategories: boolean;
                  number: string;
                  level: number;
                  error?: string | null;
                  categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                  levels: Array<{
                    __typename?: 'Category';
                    id: UUID;
                    name: string;
                    number: string;
                  }>;
                } | null;
              }
            | { __typename?: 'RegularCell'; string: string }
            | {
                __typename?: 'SourceCell';
                sourceIDs?: {
                  __typename?: 'SourceIDs';
                  estimateID: UUID;
                  itemID?: UUID | null;
                  lineID?: UUID | null;
                  markupID?: UUID | null;
                  milestoneID: UUID;
                } | null;
              }
            | null;
        }>;
      }>;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      contingencyDraws?: Array<{
        __typename?: 'ItemDrawInfo';
        id: UUID;
        type: MarkupDisplayType;
        name: string;
        error: string;
        draw: USCents;
        drawFromID?: UUID | null;
        isDrawingFullAmount: boolean;
      }> | null;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
    } | null;
    bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
    project: { __typename?: 'Project'; id: UUID; name: string };
    createdBy?: {
      __typename?: 'User';
      id: UUID;
      email: string;
      name: string;
      picture: string;
    } | null;
    milestone?: {
      __typename?: 'Milestone';
      id: UUID;
      name: string;
      date?: Time | null;
      buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
    } | null;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    assignee?: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
    previousMilestoneStatus: Array<{
      __typename?: 'PreviousMilestoneStatus';
      hasEstimate?: boolean | null;
      milestoneID: UUID;
      itemType: ItemType;
      status: Status;
      optionSubtotals: Array<{
        __typename?: 'OptionSubtotal';
        status: Status;
        adds: USCents;
        deducts: USCents;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
    }>;
    scheduleImpact?: {
      __typename?: 'ScheduleImpact';
      type: ScheduleImpactType;
      criticalPath: boolean;
      days: number;
      minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
      maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    } | null;
  }>;
  optionSubtotals: Array<{
    __typename?: 'OptionSubtotal';
    status: Status;
    adds: USCents;
    deducts: USCents;
  }>;
  comments: Array<{
    __typename?: 'Comment';
    id: UUID;
    created: Time;
    author: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    text: {
      __typename?: 'MentionableText';
      contents: string;
      mentions?: Array<{
        __typename?: 'Mention';
        id: UUID;
        position: number;
        text: string;
        user: { __typename?: 'User'; id: UUID };
      }> | null;
      itemReferences?: Array<{
        __typename?: 'ItemReference';
        id: UUID;
        position: number;
        text: string;
        referencedItem?: {
          __typename?: 'ItemsListItem';
          createdAt: Time;
          currentStatus: Status;
          id: UUID;
          name: string;
          number: string;
          status: Status;
          updateTime: Time;
          visibility: Visibility;
          creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
          currentCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
          options: Array<{
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            status: Status;
            visibility?: Visibility | null;
          }>;
        } | null;
      }> | null;
    };
  }>;
  assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
  activeEstimate?: {
    __typename?: 'Estimate';
    id: UUID;
    name: string;
    subtotal: Numeric;
    markupSubtotal: USCents;
    drawSubtotal: USCents;
    inheritedSubtotal: USCents;
    inheritedOwnerCostMarkupsSubtotal: USCents;
    asset?: UUID | null;
    totalType: EstimateTotalType;
    isRunningTotal: boolean;
    lines: Array<{
      __typename?: 'Line';
      id: UUID;
      estimateId: UUID;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      cells: Array<{
        __typename?: 'Cell';
        estimateId: UUID;
        field: UUID;
        line: UUID;
        error?: string | null;
        value?:
          | {
              __typename?: 'CategoryCell';
              search: string;
              category?: {
                __typename?: 'Category';
                id: UUID;
                parentID?: UUID | null;
                name: string;
                hasSubCategories: boolean;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
              } | null;
            }
          | { __typename?: 'RegularCell'; string: string }
          | {
              __typename?: 'SourceCell';
              sourceIDs?: {
                __typename?: 'SourceIDs';
                estimateID: UUID;
                itemID?: UUID | null;
                lineID?: UUID | null;
                markupID?: UUID | null;
                milestoneID: UUID;
              } | null;
            }
          | null;
      }>;
    }>;
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
    incorporatedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    contingencyDraws?: Array<{
      __typename?: 'ItemDrawInfo';
      id: UUID;
      type: MarkupDisplayType;
      name: string;
      error: string;
      draw: USCents;
      drawFromID?: UUID | null;
      isDrawingFullAmount: boolean;
    }> | null;
    inheritedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    inheritedOwnerCostMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    markups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
  } | null;
  bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
  project: { __typename?: 'Project'; id: UUID; name: string };
  createdBy?: {
    __typename?: 'User';
    id: UUID;
    email: string;
    name: string;
    picture: string;
  } | null;
  milestone?: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    date?: Time | null;
    buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
  } | null;
  categories: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    error?: string | null;
    level: number;
    hasSubCategories: boolean;
    categorization?: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      builtin: boolean;
      description: string;
      draft: boolean;
      levels: number;
      deprecated: boolean;
    } | null;
    levels: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
    }>;
  }>;
  cost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  estimateCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  contingencyDrawCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  assignee?: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
  previousMilestoneStatus: Array<{
    __typename?: 'PreviousMilestoneStatus';
    hasEstimate?: boolean | null;
    milestoneID: UUID;
    itemType: ItemType;
    status: Status;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
  }>;
  scheduleImpact?: {
    __typename?: 'ScheduleImpact';
    type: ScheduleImpactType;
    criticalPath: boolean;
    days: number;
    minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
  } | null;
};

type SharedDetailedItemLikeFields_Item_Fragment = {
  __typename?: 'Item';
  id: UUID;
  itemType: ItemType;
  description: string;
  descriptionStyled: string;
  dueDate?: Time | null;
  creationTime?: Time | null;
  updateTime?: Time | null;
  name: string;
  number: string;
  status: Status;
  visibility: Visibility;
  availableStates: Array<Status>;
  assetCount: number;
  remoteAssetCount: number;
  commentCount: number;
  filteredMilestone?: UUID | null;
  filteredCategories?: Array<UUID> | null;
  activityIDs?: Array<UUID> | null;
  options: Array<{
    __typename?: 'Option';
    parent: UUID;
    parentVisibility?: Visibility | null;
    hasEstimate?: boolean | null;
    id: UUID;
    itemType: ItemType;
    description: string;
    descriptionStyled: string;
    dueDate?: Time | null;
    creationTime?: Time | null;
    updateTime?: Time | null;
    name: string;
    number: string;
    status: Status;
    visibility: Visibility;
    availableStates: Array<Status>;
    assetCount: number;
    remoteAssetCount: number;
    commentCount: number;
    filteredMilestone?: UUID | null;
    filteredCategories?: Array<UUID> | null;
    activityIDs?: Array<UUID> | null;
    comments: Array<{
      __typename?: 'Comment';
      id: UUID;
      created: Time;
      author: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      text: {
        __typename?: 'MentionableText';
        contents: string;
        mentions?: Array<{
          __typename?: 'Mention';
          id: UUID;
          position: number;
          text: string;
          user: { __typename?: 'User'; id: UUID };
        }> | null;
        itemReferences?: Array<{
          __typename?: 'ItemReference';
          id: UUID;
          position: number;
          text: string;
          referencedItem?: {
            __typename?: 'ItemsListItem';
            createdAt: Time;
            currentStatus: Status;
            id: UUID;
            name: string;
            number: string;
            status: Status;
            updateTime: Time;
            visibility: Visibility;
            creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
            currentCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
            options: Array<{
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              status: Status;
              visibility?: Visibility | null;
            }>;
          } | null;
        }> | null;
      };
    }>;
    assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
    activeEstimate?: {
      __typename?: 'Estimate';
      id: UUID;
      name: string;
      subtotal: Numeric;
      markupSubtotal: USCents;
      drawSubtotal: USCents;
      inheritedSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      asset?: UUID | null;
      totalType: EstimateTotalType;
      isRunningTotal: boolean;
      lines: Array<{
        __typename?: 'Line';
        id: UUID;
        estimateId: UUID;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        cells: Array<{
          __typename?: 'Cell';
          estimateId: UUID;
          field: UUID;
          line: UUID;
          error?: string | null;
          value?:
            | {
                __typename?: 'CategoryCell';
                search: string;
                category?: {
                  __typename?: 'Category';
                  id: UUID;
                  parentID?: UUID | null;
                  name: string;
                  hasSubCategories: boolean;
                  number: string;
                  level: number;
                  error?: string | null;
                  categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                  levels: Array<{
                    __typename?: 'Category';
                    id: UUID;
                    name: string;
                    number: string;
                  }>;
                } | null;
              }
            | { __typename?: 'RegularCell'; string: string }
            | {
                __typename?: 'SourceCell';
                sourceIDs?: {
                  __typename?: 'SourceIDs';
                  estimateID: UUID;
                  itemID?: UUID | null;
                  lineID?: UUID | null;
                  markupID?: UUID | null;
                  milestoneID: UUID;
                } | null;
              }
            | null;
        }>;
      }>;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      contingencyDraws?: Array<{
        __typename?: 'ItemDrawInfo';
        id: UUID;
        type: MarkupDisplayType;
        name: string;
        error: string;
        draw: USCents;
        drawFromID?: UUID | null;
        isDrawingFullAmount: boolean;
      }> | null;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
    } | null;
    bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
    project: { __typename?: 'Project'; id: UUID; name: string };
    createdBy?: {
      __typename?: 'User';
      id: UUID;
      email: string;
      name: string;
      picture: string;
    } | null;
    milestone?: {
      __typename?: 'Milestone';
      id: UUID;
      name: string;
      date?: Time | null;
      buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
    } | null;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    assignee?: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
    previousMilestoneStatus: Array<{
      __typename?: 'PreviousMilestoneStatus';
      hasEstimate?: boolean | null;
      milestoneID: UUID;
      itemType: ItemType;
      status: Status;
      optionSubtotals: Array<{
        __typename?: 'OptionSubtotal';
        status: Status;
        adds: USCents;
        deducts: USCents;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
    }>;
    scheduleImpact?: {
      __typename?: 'ScheduleImpact';
      type: ScheduleImpactType;
      criticalPath: boolean;
      days: number;
      minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
      maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    } | null;
  }>;
  optionSubtotals: Array<{
    __typename?: 'OptionSubtotal';
    status: Status;
    adds: USCents;
    deducts: USCents;
  }>;
  comments: Array<{
    __typename?: 'Comment';
    id: UUID;
    created: Time;
    author: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    text: {
      __typename?: 'MentionableText';
      contents: string;
      mentions?: Array<{
        __typename?: 'Mention';
        id: UUID;
        position: number;
        text: string;
        user: { __typename?: 'User'; id: UUID };
      }> | null;
      itemReferences?: Array<{
        __typename?: 'ItemReference';
        id: UUID;
        position: number;
        text: string;
        referencedItem?: {
          __typename?: 'ItemsListItem';
          createdAt: Time;
          currentStatus: Status;
          id: UUID;
          name: string;
          number: string;
          status: Status;
          updateTime: Time;
          visibility: Visibility;
          creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
          currentCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
          options: Array<{
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            status: Status;
            visibility?: Visibility | null;
          }>;
        } | null;
      }> | null;
    };
  }>;
  assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
  activeEstimate?: {
    __typename?: 'Estimate';
    id: UUID;
    name: string;
    subtotal: Numeric;
    markupSubtotal: USCents;
    drawSubtotal: USCents;
    inheritedSubtotal: USCents;
    inheritedOwnerCostMarkupsSubtotal: USCents;
    asset?: UUID | null;
    totalType: EstimateTotalType;
    isRunningTotal: boolean;
    lines: Array<{
      __typename?: 'Line';
      id: UUID;
      estimateId: UUID;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      cells: Array<{
        __typename?: 'Cell';
        estimateId: UUID;
        field: UUID;
        line: UUID;
        error?: string | null;
        value?:
          | {
              __typename?: 'CategoryCell';
              search: string;
              category?: {
                __typename?: 'Category';
                id: UUID;
                parentID?: UUID | null;
                name: string;
                hasSubCategories: boolean;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
              } | null;
            }
          | { __typename?: 'RegularCell'; string: string }
          | {
              __typename?: 'SourceCell';
              sourceIDs?: {
                __typename?: 'SourceIDs';
                estimateID: UUID;
                itemID?: UUID | null;
                lineID?: UUID | null;
                markupID?: UUID | null;
                milestoneID: UUID;
              } | null;
            }
          | null;
      }>;
    }>;
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
    incorporatedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    contingencyDraws?: Array<{
      __typename?: 'ItemDrawInfo';
      id: UUID;
      type: MarkupDisplayType;
      name: string;
      error: string;
      draw: USCents;
      drawFromID?: UUID | null;
      isDrawingFullAmount: boolean;
    }> | null;
    inheritedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    inheritedOwnerCostMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    markups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
  } | null;
  bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
  project: { __typename?: 'Project'; id: UUID; name: string };
  createdBy?: {
    __typename?: 'User';
    id: UUID;
    email: string;
    name: string;
    picture: string;
  } | null;
  milestone?: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    date?: Time | null;
    buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
  } | null;
  categories: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    error?: string | null;
    level: number;
    hasSubCategories: boolean;
    categorization?: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      builtin: boolean;
      description: string;
      draft: boolean;
      levels: number;
      deprecated: boolean;
    } | null;
    levels: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
    }>;
  }>;
  cost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  estimateCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  contingencyDrawCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  assignee?: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
  previousMilestoneStatus: Array<{
    __typename?: 'PreviousMilestoneStatus';
    hasEstimate?: boolean | null;
    milestoneID: UUID;
    itemType: ItemType;
    status: Status;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
  }>;
  scheduleImpact?: {
    __typename?: 'ScheduleImpact';
    type: ScheduleImpactType;
    criticalPath: boolean;
    days: number;
    minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
  } | null;
};

type SharedDetailedItemLikeFields_Option_Fragment = {
  __typename?: 'Option';
  parent: UUID;
  parentVisibility?: Visibility | null;
  hasEstimate?: boolean | null;
  id: UUID;
  itemType: ItemType;
  description: string;
  descriptionStyled: string;
  dueDate?: Time | null;
  creationTime?: Time | null;
  updateTime?: Time | null;
  name: string;
  number: string;
  status: Status;
  visibility: Visibility;
  availableStates: Array<Status>;
  assetCount: number;
  remoteAssetCount: number;
  commentCount: number;
  filteredMilestone?: UUID | null;
  filteredCategories?: Array<UUID> | null;
  activityIDs?: Array<UUID> | null;
  comments: Array<{
    __typename?: 'Comment';
    id: UUID;
    created: Time;
    author: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    text: {
      __typename?: 'MentionableText';
      contents: string;
      mentions?: Array<{
        __typename?: 'Mention';
        id: UUID;
        position: number;
        text: string;
        user: { __typename?: 'User'; id: UUID };
      }> | null;
      itemReferences?: Array<{
        __typename?: 'ItemReference';
        id: UUID;
        position: number;
        text: string;
        referencedItem?: {
          __typename?: 'ItemsListItem';
          createdAt: Time;
          currentStatus: Status;
          id: UUID;
          name: string;
          number: string;
          status: Status;
          updateTime: Time;
          visibility: Visibility;
          creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
          currentCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
          options: Array<{
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            status: Status;
            visibility?: Visibility | null;
          }>;
        } | null;
      }> | null;
    };
  }>;
  assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
  activeEstimate?: {
    __typename?: 'Estimate';
    id: UUID;
    name: string;
    subtotal: Numeric;
    markupSubtotal: USCents;
    drawSubtotal: USCents;
    inheritedSubtotal: USCents;
    inheritedOwnerCostMarkupsSubtotal: USCents;
    asset?: UUID | null;
    totalType: EstimateTotalType;
    isRunningTotal: boolean;
    lines: Array<{
      __typename?: 'Line';
      id: UUID;
      estimateId: UUID;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      cells: Array<{
        __typename?: 'Cell';
        estimateId: UUID;
        field: UUID;
        line: UUID;
        error?: string | null;
        value?:
          | {
              __typename?: 'CategoryCell';
              search: string;
              category?: {
                __typename?: 'Category';
                id: UUID;
                parentID?: UUID | null;
                name: string;
                hasSubCategories: boolean;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
              } | null;
            }
          | { __typename?: 'RegularCell'; string: string }
          | {
              __typename?: 'SourceCell';
              sourceIDs?: {
                __typename?: 'SourceIDs';
                estimateID: UUID;
                itemID?: UUID | null;
                lineID?: UUID | null;
                markupID?: UUID | null;
                milestoneID: UUID;
              } | null;
            }
          | null;
      }>;
    }>;
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
    incorporatedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    contingencyDraws?: Array<{
      __typename?: 'ItemDrawInfo';
      id: UUID;
      type: MarkupDisplayType;
      name: string;
      error: string;
      draw: USCents;
      drawFromID?: UUID | null;
      isDrawingFullAmount: boolean;
    }> | null;
    inheritedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    inheritedOwnerCostMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    markups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
  } | null;
  bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
  project: { __typename?: 'Project'; id: UUID; name: string };
  createdBy?: {
    __typename?: 'User';
    id: UUID;
    email: string;
    name: string;
    picture: string;
  } | null;
  milestone?: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    date?: Time | null;
    buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
  } | null;
  categories: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    error?: string | null;
    level: number;
    hasSubCategories: boolean;
    categorization?: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      builtin: boolean;
      description: string;
      draft: boolean;
      levels: number;
      deprecated: boolean;
    } | null;
    levels: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
    }>;
  }>;
  cost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  estimateCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  contingencyDrawCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  assignee?: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
  previousMilestoneStatus: Array<{
    __typename?: 'PreviousMilestoneStatus';
    hasEstimate?: boolean | null;
    milestoneID: UUID;
    itemType: ItemType;
    status: Status;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
  }>;
  scheduleImpact?: {
    __typename?: 'ScheduleImpact';
    type: ScheduleImpactType;
    criticalPath: boolean;
    days: number;
    minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
  } | null;
};

export type SharedDetailedItemLikeFieldsFragment =
  | SharedDetailedItemLikeFields_Item_Fragment
  | SharedDetailedItemLikeFields_Option_Fragment;

export type SourceItemFieldsFragment = {
  __typename?: 'Item';
  id: UUID;
  name: string;
  number: string;
  status: Status;
};

export type SourceOptionFieldsFragment = {
  __typename?: 'Option';
  id: UUID;
  name: string;
  number: string;
  status: Status;
};

type SharedSourceItemLikeFields_Item_Fragment = {
  __typename?: 'Item';
  id: UUID;
  name: string;
  number: string;
  status: Status;
};

type SharedSourceItemLikeFields_Option_Fragment = {
  __typename?: 'Option';
  id: UUID;
  name: string;
  number: string;
  status: Status;
};

export type SharedSourceItemLikeFieldsFragment =
  | SharedSourceItemLikeFields_Item_Fragment
  | SharedSourceItemLikeFields_Option_Fragment;

export type CategorizedItemCostsFieldsFragment = {
  __typename?: 'CategorizedItemCost';
  id: UUID;
  milestoneId: UUID;
  name: string;
  number: string;
  parentId?: UUID | null;
  status?: Status | null;
  canViewCosts: boolean;
  categories: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    error?: string | null;
    level: number;
    hasSubCategories: boolean;
    categorization?: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      builtin: boolean;
      description: string;
      draft: boolean;
      levels: number;
      deprecated: boolean;
    } | null;
    levels: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
    }>;
  }>;
  directCostRange:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents };
  range:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents };
  itemContributionLines?: Array<{
    __typename?: 'ItemContributionLines';
    lineID?: UUID | null;
    lineDescription?: string | null;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    }>;
    directCostRange:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
    range:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
  } | null> | null;
};

export type SharedCostReportValueFieldsFragment = {
  __typename?: 'CostReportItem';
  subtotalValue:
    | {
        __typename?: 'SubtotalAddsDeducts';
        directCostSegmented: {
          __typename?: 'PartialAddDeductCost';
          adds?: USCents | null;
          deducts?: USCents | null;
        };
        segmented: {
          __typename?: 'PartialAddDeductCost';
          adds?: USCents | null;
          deducts?: USCents | null;
        };
      }
    | {
        __typename?: 'SubtotalCost';
        directCostRange:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        range:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
      }
    | { __typename?: 'SubtotalNumeric'; value: Numeric };
};

export type CommonCostReportFieldsFragment = {
  __typename?: 'CostReport';
  numeric: Numeric;
  type: CostReportType;
  directCostRange:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents };
  directCostSegmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
  projectTotalRange?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  projectTotalSegmented?: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents } | null;
  range:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents };
  segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
  subtotals: Array<{
    __typename?: 'CostReportItem';
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    }>;
    subtotalValue:
      | {
          __typename?: 'SubtotalAddsDeducts';
          directCostSegmented: {
            __typename?: 'PartialAddDeductCost';
            adds?: USCents | null;
            deducts?: USCents | null;
          };
          segmented: {
            __typename?: 'PartialAddDeductCost';
            adds?: USCents | null;
            deducts?: USCents | null;
          };
        }
      | {
          __typename?: 'SubtotalCost';
          directCostRange:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          range:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        }
      | { __typename?: 'SubtotalNumeric'; value: Numeric };
  }>;
  categorizedItemCosts: Array<{
    __typename?: 'CategorizedItemCost';
    id: UUID;
    milestoneId: UUID;
    name: string;
    number: string;
    parentId?: UUID | null;
    status?: Status | null;
    canViewCosts: boolean;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    }>;
    directCostRange:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
    range:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
    itemContributionLines?: Array<{
      __typename?: 'ItemContributionLines';
      lineID?: UUID | null;
      lineDescription?: string | null;
      categories: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        error?: string | null;
        level: number;
        hasSubCategories: boolean;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          builtin: boolean;
          description: string;
          draft: boolean;
          levels: number;
          deprecated: boolean;
        } | null;
        levels: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          hasSubCategories: boolean;
          level: number;
        }>;
      }>;
      directCostRange:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
      range:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
    } | null> | null;
  }>;
  markupContributions: Array<{
    __typename?: 'MarkupContribution';
    isIncorporated: boolean;
    isOwnerCost: boolean;
    markupId: UUID;
    markupName: string;
    displayType: MarkupDisplayType;
    range:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
    segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
  }>;
};

export type UnitFieldsFragment = {
  __typename?: 'Unit';
  abbreviationPlural: string;
  abbreviationSingular: string;
  description?: string | null;
  id: UUID;
  isDefault: boolean;
  isEnabled: boolean;
  name: string;
  type: UnitType;
};

export type QuantityFieldsFragment = {
  __typename?: 'Quantity';
  id: UUID;
  magnitude: Numeric;
  estimateID: UUID;
  hasBreakdown: boolean;
  unit: {
    __typename?: 'Unit';
    abbreviationPlural: string;
    abbreviationSingular: string;
    description?: string | null;
    id: UUID;
    isDefault: boolean;
    isEnabled: boolean;
    name: string;
    type: UnitType;
  };
};

export type CommonCostReportColumnFieldsFragment = {
  __typename?: 'CostReportColumn';
  type: CostReportColumnType;
  columnKey: CostReportColumnKey;
  isCategorized?: boolean | null;
  report?: {
    __typename?: 'CostReport';
    numeric: Numeric;
    type: CostReportType;
    directCostRange:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
    directCostSegmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
    projectTotalRange?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    projectTotalSegmented?: {
      __typename?: 'AddDeductCost';
      adds: USCents;
      deducts: USCents;
    } | null;
    range:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
    segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
    subtotals: Array<{
      __typename?: 'CostReportItem';
      categories: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        error?: string | null;
        level: number;
        hasSubCategories: boolean;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          builtin: boolean;
          description: string;
          draft: boolean;
          levels: number;
          deprecated: boolean;
        } | null;
        levels: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          hasSubCategories: boolean;
          level: number;
        }>;
      }>;
      subtotalValue:
        | {
            __typename?: 'SubtotalAddsDeducts';
            directCostSegmented: {
              __typename?: 'PartialAddDeductCost';
              adds?: USCents | null;
              deducts?: USCents | null;
            };
            segmented: {
              __typename?: 'PartialAddDeductCost';
              adds?: USCents | null;
              deducts?: USCents | null;
            };
          }
        | {
            __typename?: 'SubtotalCost';
            directCostRange:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            range:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          }
        | { __typename?: 'SubtotalNumeric'; value: Numeric };
    }>;
    categorizedItemCosts: Array<{
      __typename?: 'CategorizedItemCost';
      id: UUID;
      milestoneId: UUID;
      name: string;
      number: string;
      parentId?: UUID | null;
      status?: Status | null;
      canViewCosts: boolean;
      categories: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        error?: string | null;
        level: number;
        hasSubCategories: boolean;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          builtin: boolean;
          description: string;
          draft: boolean;
          levels: number;
          deprecated: boolean;
        } | null;
        levels: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          hasSubCategories: boolean;
          level: number;
        }>;
      }>;
      directCostRange:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
      range:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
      itemContributionLines?: Array<{
        __typename?: 'ItemContributionLines';
        lineID?: UUID | null;
        lineDescription?: string | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        directCostRange:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        range:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
      } | null> | null;
    }>;
    markupContributions: Array<{
      __typename?: 'MarkupContribution';
      isIncorporated: boolean;
      isOwnerCost: boolean;
      markupId: UUID;
      markupName: string;
      displayType: MarkupDisplayType;
      range:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
      segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
    }>;
  } | null;
  quantity?: {
    __typename?: 'Quantity';
    id: UUID;
    magnitude: Numeric;
    estimateID: UUID;
    hasBreakdown: boolean;
    unit: {
      __typename?: 'Unit';
      abbreviationPlural: string;
      abbreviationSingular: string;
      description?: string | null;
      id: UUID;
      isDefault: boolean;
      isEnabled: boolean;
      name: string;
      type: UnitType;
    };
  } | null;
};

export type CommonProjectStatusFieldsFragment = {
  __typename?: 'ProjectStatus';
  id: UUID;
  name: string;
  type: string;
  description: string;
};

export type CommonProjectTypeFieldsFragment = {
  __typename?: 'ProjectType';
  id: UUID;
  name: string;
  parentID?: UUID | null;
  hidden?: boolean | null;
};

export type CommonProjectDeliveryTypeFieldsFragment = {
  __typename?: 'ProjectDeliveryType';
  id: UUID;
  name: string;
  abbreviation: string;
};

export type CommonLocationDetailsFieldsFragment = {
  __typename?: 'LocationDetails';
  name: string;
  lat: number;
  lon: number;
  city: string;
  state: string;
  stateLong: string;
  country: string;
};

export type CommonProjectPropsFieldsFragment = {
  __typename?: 'Project';
  id: UUID;
  description: string;
  descriptionStyled: string;
  name: string;
  code?: string | null;
  hasAccess: boolean;
  location: string;
  lat?: number | null;
  lon?: number | null;
  thumbnail: string;
  templateEstimateID: UUID;
  lastUpdated: Time;
  activeMilestone: { __typename?: 'Milestone'; id: UUID; name: string };
  status: {
    __typename?: 'ProjectStatus';
    id: UUID;
    name: string;
    type: string;
    description: string;
  };
  team?: {
    __typename?: 'Team';
    owner?: { __typename?: 'Organization'; name?: string | null; picture?: string | null } | null;
    generalContractor?: {
      __typename?: 'Organization';
      name?: string | null;
      picture?: string | null;
    } | null;
    designTeam?: {
      __typename?: 'Organization';
      name?: string | null;
      picture?: string | null;
    } | null;
  } | null;
  type: { __typename?: 'ProjectType'; id: UUID; name: string };
  projectDeliveryType?: {
    __typename?: 'ProjectDeliveryType';
    id: UUID;
    name: string;
    abbreviation: string;
  } | null;
  locationDetails?: {
    __typename?: 'LocationDetails';
    name: string;
    lat: number;
    lon: number;
    city: string;
    state: string;
    stateLong: string;
    country: string;
  } | null;
  orgNodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string }>;
};

export type CommonProjectCompsCostTableColumnValueFieldsFragment = {
  __typename?: 'ProjectCompsCostTableColumnValue';
  valueNumeric: Numeric;
  isEdited: boolean;
  isMaxValue: boolean;
  isMinValue: boolean;
  hasError: boolean;
  costTableColumnInput: {
    __typename?: 'CostTableColumnInputReturn';
    key: CostTableColumnInputKey;
    unitID?: UUID | null;
  };
};

export type CommonProjectCompsCostTableCostValuesFieldsFragment = {
  __typename?: 'ProjectCompsCostTableCostValues';
  quantityMagnitude: Numeric;
  percentValue: number;
  totalValue:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents };
  quantityValue:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents };
};

export type CommonProjectCompsCostTableCategoryLineFieldsFragment = {
  __typename?: 'ProjectCompsCostTableCategoryLine';
  description?: string | null;
  category: {
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    error?: string | null;
    level: number;
    hasSubCategories: boolean;
    categorization?: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      builtin: boolean;
      description: string;
      draft: boolean;
      levels: number;
      deprecated: boolean;
    } | null;
    levels: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
    }>;
  };
  pinnedUnitValues: {
    __typename?: 'ProjectCompsCostTableCostValues';
    quantityMagnitude: Numeric;
    percentValue: number;
    totalValue:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
    quantityValue:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
  };
  columnValues: Array<{
    __typename?: 'ProjectCompsCostTableColumnValue';
    valueNumeric: Numeric;
    isEdited: boolean;
    isMaxValue: boolean;
    isMinValue: boolean;
    hasError: boolean;
    costTableColumnInput: {
      __typename?: 'CostTableColumnInputReturn';
      key: CostTableColumnInputKey;
      unitID?: UUID | null;
    };
  }>;
};

export type CommonProjectCompsCostTableMarkupLineFieldsFragment = {
  __typename?: 'ProjectCompsCostTableMarkupLine';
  markupName: string;
  description?: string | null;
  pinnedUnitValues: {
    __typename?: 'ProjectCompsCostTableCostValues';
    quantityMagnitude: Numeric;
    percentValue: number;
    totalValue:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
    quantityValue:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
  };
  columnValues: Array<{
    __typename?: 'ProjectCompsCostTableColumnValue';
    valueNumeric: Numeric;
    isEdited: boolean;
    isMaxValue: boolean;
    isMinValue: boolean;
    hasError: boolean;
    costTableColumnInput: {
      __typename?: 'CostTableColumnInputReturn';
      key: CostTableColumnInputKey;
      unitID?: UUID | null;
    };
  }>;
};

export type CommonProjectCompsCostTableSummaryLineFieldsFragment = {
  __typename?: 'ProjectCompsCostTableSummaryLine';
  pinnedUnitValues: {
    __typename?: 'ProjectCompsCostTableCostValues';
    quantityMagnitude: Numeric;
    percentValue: number;
    totalValue:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
    quantityValue:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
  };
  columnValues: Array<{
    __typename?: 'ProjectCompsCostTableColumnValue';
    valueNumeric: Numeric;
    isEdited: boolean;
    isMaxValue: boolean;
    isMinValue: boolean;
    hasError: boolean;
    costTableColumnInput: {
      __typename?: 'CostTableColumnInputReturn';
      key: CostTableColumnInputKey;
      unitID?: UUID | null;
    };
  }>;
};

export type CommonProjectCompsCostTableSummaryLinesFieldsFragment = {
  __typename?: 'ProjectCompsCostTableSummaryLines';
  subtotalLine?: {
    __typename?: 'ProjectCompsCostTableSummaryLine';
    pinnedUnitValues: {
      __typename?: 'ProjectCompsCostTableCostValues';
      quantityMagnitude: Numeric;
      percentValue: number;
      totalValue:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
      quantityValue:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
    };
    columnValues: Array<{
      __typename?: 'ProjectCompsCostTableColumnValue';
      valueNumeric: Numeric;
      isEdited: boolean;
      isMaxValue: boolean;
      isMinValue: boolean;
      hasError: boolean;
      costTableColumnInput: {
        __typename?: 'CostTableColumnInputReturn';
        key: CostTableColumnInputKey;
        unitID?: UUID | null;
      };
    }>;
  } | null;
  totalLine: {
    __typename?: 'ProjectCompsCostTableSummaryLine';
    pinnedUnitValues: {
      __typename?: 'ProjectCompsCostTableCostValues';
      quantityMagnitude: Numeric;
      percentValue: number;
      totalValue:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
      quantityValue:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
    };
    columnValues: Array<{
      __typename?: 'ProjectCompsCostTableColumnValue';
      valueNumeric: Numeric;
      isEdited: boolean;
      isMaxValue: boolean;
      isMinValue: boolean;
      hasError: boolean;
      costTableColumnInput: {
        __typename?: 'CostTableColumnInputReturn';
        key: CostTableColumnInputKey;
        unitID?: UUID | null;
      };
    }>;
  };
  markupsTotalLine?: {
    __typename?: 'ProjectCompsCostTableSummaryLine';
    pinnedUnitValues: {
      __typename?: 'ProjectCompsCostTableCostValues';
      quantityMagnitude: Numeric;
      percentValue: number;
      totalValue:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
      quantityValue:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
    };
    columnValues: Array<{
      __typename?: 'ProjectCompsCostTableColumnValue';
      valueNumeric: Numeric;
      isEdited: boolean;
      isMaxValue: boolean;
      isMinValue: boolean;
      hasError: boolean;
      costTableColumnInput: {
        __typename?: 'CostTableColumnInputReturn';
        key: CostTableColumnInputKey;
        unitID?: UUID | null;
      };
    }>;
  } | null;
};

export type CommonProjectCompsCostTableFieldsFragment = {
  __typename?: 'ProjectCompsCostTable';
  categoryLines: Array<{
    __typename?: 'ProjectCompsCostTableCategoryLine';
    description?: string | null;
    category: {
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    };
    pinnedUnitValues: {
      __typename?: 'ProjectCompsCostTableCostValues';
      quantityMagnitude: Numeric;
      percentValue: number;
      totalValue:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
      quantityValue:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
    };
    columnValues: Array<{
      __typename?: 'ProjectCompsCostTableColumnValue';
      valueNumeric: Numeric;
      isEdited: boolean;
      isMaxValue: boolean;
      isMinValue: boolean;
      hasError: boolean;
      costTableColumnInput: {
        __typename?: 'CostTableColumnInputReturn';
        key: CostTableColumnInputKey;
        unitID?: UUID | null;
      };
    }>;
  }>;
  markupLines: Array<{
    __typename?: 'ProjectCompsCostTableMarkupLine';
    markupName: string;
    description?: string | null;
    pinnedUnitValues: {
      __typename?: 'ProjectCompsCostTableCostValues';
      quantityMagnitude: Numeric;
      percentValue: number;
      totalValue:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
      quantityValue:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
    };
    columnValues: Array<{
      __typename?: 'ProjectCompsCostTableColumnValue';
      valueNumeric: Numeric;
      isEdited: boolean;
      isMaxValue: boolean;
      isMinValue: boolean;
      hasError: boolean;
      costTableColumnInput: {
        __typename?: 'CostTableColumnInputReturn';
        key: CostTableColumnInputKey;
        unitID?: UUID | null;
      };
    }>;
  }>;
  summaryLines: {
    __typename?: 'ProjectCompsCostTableSummaryLines';
    subtotalLine?: {
      __typename?: 'ProjectCompsCostTableSummaryLine';
      pinnedUnitValues: {
        __typename?: 'ProjectCompsCostTableCostValues';
        quantityMagnitude: Numeric;
        percentValue: number;
        totalValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        quantityValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
      };
      columnValues: Array<{
        __typename?: 'ProjectCompsCostTableColumnValue';
        valueNumeric: Numeric;
        isEdited: boolean;
        isMaxValue: boolean;
        isMinValue: boolean;
        hasError: boolean;
        costTableColumnInput: {
          __typename?: 'CostTableColumnInputReturn';
          key: CostTableColumnInputKey;
          unitID?: UUID | null;
        };
      }>;
    } | null;
    totalLine: {
      __typename?: 'ProjectCompsCostTableSummaryLine';
      pinnedUnitValues: {
        __typename?: 'ProjectCompsCostTableCostValues';
        quantityMagnitude: Numeric;
        percentValue: number;
        totalValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        quantityValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
      };
      columnValues: Array<{
        __typename?: 'ProjectCompsCostTableColumnValue';
        valueNumeric: Numeric;
        isEdited: boolean;
        isMaxValue: boolean;
        isMinValue: boolean;
        hasError: boolean;
        costTableColumnInput: {
          __typename?: 'CostTableColumnInputReturn';
          key: CostTableColumnInputKey;
          unitID?: UUID | null;
        };
      }>;
    };
    markupsTotalLine?: {
      __typename?: 'ProjectCompsCostTableSummaryLine';
      pinnedUnitValues: {
        __typename?: 'ProjectCompsCostTableCostValues';
        quantityMagnitude: Numeric;
        percentValue: number;
        totalValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        quantityValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
      };
      columnValues: Array<{
        __typename?: 'ProjectCompsCostTableColumnValue';
        valueNumeric: Numeric;
        isEdited: boolean;
        isMaxValue: boolean;
        isMinValue: boolean;
        hasError: boolean;
        costTableColumnInput: {
          __typename?: 'CostTableColumnInputReturn';
          key: CostTableColumnInputKey;
          unitID?: UUID | null;
        };
      }>;
    } | null;
  };
};

export type CommonDisplayCategoryFieldsFragment = {
  __typename?: 'Category';
  id: UUID;
  name: string;
  number: string;
  level: number;
  error?: string | null;
  categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
};

export type AverageCompInputReturnFieldsFragment = {
  __typename?: 'AverageCompInputReturn';
  name?: string | null;
  description?: string | null;
  color?: string | null;
  isHidden?: boolean | null;
  thumbnailAssetID?: UUID | null;
  location?: string | null;
  lat?: number | null;
  lon?: number | null;
  metrics: Array<{
    __typename?: 'MetricsInputReturn';
    unitID: UUID;
    quantityMagnitude?: string | null;
    hasMilestoneQuantity: boolean;
  }>;
  categoryLineInputs: Array<{
    __typename?: 'CategoryLineInputReturn';
    description?: string | null;
    editedValue: USCents;
    cellType: ProjectCompsCostTableCellType;
    unitID?: UUID | null;
    category: { __typename?: 'Category'; name: string; number: string };
  }>;
  markupLineInputs: Array<{
    __typename?: 'MarkupLineInputReturn';
    markupName: string;
    description?: string | null;
    editedValue: USCents;
    cellType: ProjectCompsCostTableCellType;
    unitID?: UUID | null;
  }>;
};

export type ProjectCompInputReturnFieldsFragment = {
  __typename?: 'ProjectCompInputReturn';
  id: UUID;
  projectID: UUID;
  name?: string | null;
  description?: string | null;
  categorizationID?: UUID | null;
  categorizationLevel?: number | null;
  isExcluded?: boolean | null;
  milestoneID?: UUID | null;
  escalation?: {
    __typename?: 'ProjectCompEscalation';
    time?: string | null;
    timeFuture?: string | null;
    location?: string | null;
    timeMeta?: {
      __typename?: 'ProjectCompEscalationMeta';
      isAutoEscalated: boolean;
      isFuzzyMatch: boolean;
      sourceLabel: string;
      sourceIsFuzzyMatch: boolean;
      targetLabel: string;
      targetIsFuzzyMatch: boolean;
    } | null;
    locationMeta?: {
      __typename?: 'ProjectCompEscalationMeta';
      isAutoEscalated: boolean;
      isFuzzyMatch: boolean;
      sourceLabel: string;
      sourceIsFuzzyMatch: boolean;
      targetLabel: string;
      targetIsFuzzyMatch: boolean;
    } | null;
  } | null;
  metrics: Array<{
    __typename?: 'MetricsInputReturn';
    unitID: UUID;
    quantityMagnitude?: string | null;
    hasMilestoneQuantity: boolean;
  }>;
  categoryLineInputs: Array<{
    __typename?: 'CategoryLineInputReturn';
    description?: string | null;
    editedValue: USCents;
    cellType: ProjectCompsCostTableCellType;
    unitID?: UUID | null;
    category: { __typename?: 'Category'; name: string; number: string };
  }>;
  markupLineInputs: Array<{
    __typename?: 'MarkupLineInputReturn';
    markupName: string;
    description?: string | null;
    editedValue: USCents;
    cellType: ProjectCompsCostTableCellType;
    unitID?: UUID | null;
  }>;
  viewFilter?: {
    __typename?: 'ViewFilterReturn';
    categories?: Array<{
      __typename?: 'CategoryInputReturn';
      categorizationID: UUID;
      id: UUID;
    }> | null;
  } | null;
};

export type ProjectCompsSetInputReturnFieldsFragment = {
  __typename?: 'ProjectCompsSetInputReturn';
  addedMarkupNames: Array<string>;
  markupMode: MarkupMode;
  pinnedUnitID?: UUID | null;
  selectedUnitIDs: Array<UUID>;
  excludedMarkupNames: Array<string>;
  averageInput?: {
    __typename?: 'AverageCompInputReturn';
    name?: string | null;
    description?: string | null;
    color?: string | null;
    isHidden?: boolean | null;
    thumbnailAssetID?: UUID | null;
    location?: string | null;
    lat?: number | null;
    lon?: number | null;
    metrics: Array<{
      __typename?: 'MetricsInputReturn';
      unitID: UUID;
      quantityMagnitude?: string | null;
      hasMilestoneQuantity: boolean;
    }>;
    categoryLineInputs: Array<{
      __typename?: 'CategoryLineInputReturn';
      description?: string | null;
      editedValue: USCents;
      cellType: ProjectCompsCostTableCellType;
      unitID?: UUID | null;
      category: { __typename?: 'Category'; name: string; number: string };
    }>;
    markupLineInputs: Array<{
      __typename?: 'MarkupLineInputReturn';
      markupName: string;
      description?: string | null;
      editedValue: USCents;
      cellType: ProjectCompsCostTableCellType;
      unitID?: UUID | null;
    }>;
  } | null;
  costTableColumnInputs?: Array<{
    __typename?: 'CostTableColumnInputReturn';
    key: CostTableColumnInputKey;
    unitID?: UUID | null;
  }> | null;
  projectCompInputs: Array<{
    __typename?: 'ProjectCompInputReturn';
    id: UUID;
    projectID: UUID;
    name?: string | null;
    description?: string | null;
    categorizationID?: UUID | null;
    categorizationLevel?: number | null;
    isExcluded?: boolean | null;
    milestoneID?: UUID | null;
    escalation?: {
      __typename?: 'ProjectCompEscalation';
      time?: string | null;
      timeFuture?: string | null;
      location?: string | null;
      timeMeta?: {
        __typename?: 'ProjectCompEscalationMeta';
        isAutoEscalated: boolean;
        isFuzzyMatch: boolean;
        sourceLabel: string;
        sourceIsFuzzyMatch: boolean;
        targetLabel: string;
        targetIsFuzzyMatch: boolean;
      } | null;
      locationMeta?: {
        __typename?: 'ProjectCompEscalationMeta';
        isAutoEscalated: boolean;
        isFuzzyMatch: boolean;
        sourceLabel: string;
        sourceIsFuzzyMatch: boolean;
        targetLabel: string;
        targetIsFuzzyMatch: boolean;
      } | null;
    } | null;
    metrics: Array<{
      __typename?: 'MetricsInputReturn';
      unitID: UUID;
      quantityMagnitude?: string | null;
      hasMilestoneQuantity: boolean;
    }>;
    categoryLineInputs: Array<{
      __typename?: 'CategoryLineInputReturn';
      description?: string | null;
      editedValue: USCents;
      cellType: ProjectCompsCostTableCellType;
      unitID?: UUID | null;
      category: { __typename?: 'Category'; name: string; number: string };
    }>;
    markupLineInputs: Array<{
      __typename?: 'MarkupLineInputReturn';
      markupName: string;
      description?: string | null;
      editedValue: USCents;
      cellType: ProjectCompsCostTableCellType;
      unitID?: UUID | null;
    }>;
    viewFilter?: {
      __typename?: 'ViewFilterReturn';
      categories?: Array<{
        __typename?: 'CategoryInputReturn';
        categorizationID: UUID;
        id: UUID;
      }> | null;
    } | null;
  }>;
  excludedCategoryContents: Array<{ __typename?: 'CategoryContent'; number: string; name: string }>;
};

export type CommonProjectCompFieldsFragment = {
  __typename?: 'ProjectComp';
  isResettable: boolean;
  input: {
    __typename?: 'ProjectCompInputReturn';
    id: UUID;
    projectID: UUID;
    name?: string | null;
    description?: string | null;
    categorizationID?: UUID | null;
    categorizationLevel?: number | null;
    isExcluded?: boolean | null;
    milestoneID?: UUID | null;
    escalation?: {
      __typename?: 'ProjectCompEscalation';
      time?: string | null;
      timeFuture?: string | null;
      location?: string | null;
      timeMeta?: {
        __typename?: 'ProjectCompEscalationMeta';
        isAutoEscalated: boolean;
        isFuzzyMatch: boolean;
        sourceLabel: string;
        sourceIsFuzzyMatch: boolean;
        targetLabel: string;
        targetIsFuzzyMatch: boolean;
      } | null;
      locationMeta?: {
        __typename?: 'ProjectCompEscalationMeta';
        isAutoEscalated: boolean;
        isFuzzyMatch: boolean;
        sourceLabel: string;
        sourceIsFuzzyMatch: boolean;
        targetLabel: string;
        targetIsFuzzyMatch: boolean;
      } | null;
    } | null;
    metrics: Array<{
      __typename?: 'MetricsInputReturn';
      unitID: UUID;
      quantityMagnitude?: string | null;
      hasMilestoneQuantity: boolean;
    }>;
    categoryLineInputs: Array<{
      __typename?: 'CategoryLineInputReturn';
      description?: string | null;
      editedValue: USCents;
      cellType: ProjectCompsCostTableCellType;
      unitID?: UUID | null;
      category: { __typename?: 'Category'; name: string; number: string };
    }>;
    markupLineInputs: Array<{
      __typename?: 'MarkupLineInputReturn';
      markupName: string;
      description?: string | null;
      editedValue: USCents;
      cellType: ProjectCompsCostTableCellType;
      unitID?: UUID | null;
    }>;
    viewFilter?: {
      __typename?: 'ViewFilterReturn';
      categories?: Array<{
        __typename?: 'CategoryInputReturn';
        categorizationID: UUID;
        id: UUID;
      }> | null;
    } | null;
  };
  milestone: { __typename?: 'Milestone'; id: UUID; date?: Time | null; name: string };
  project: {
    __typename?: 'Project';
    id: UUID;
    name: string;
    hasAccess: boolean;
    location: string;
    lat?: number | null;
    lon?: number | null;
    thumbnail: string;
    status: { __typename?: 'ProjectStatus'; name: string };
    milestones: Array<{ __typename?: 'Milestone'; id: UUID; date?: Time | null; name: string }>;
    activeMilestone: { __typename?: 'Milestone'; id: UUID };
    type: { __typename?: 'ProjectType'; name: string };
  };
  projectCompsCostTable: {
    __typename?: 'ProjectCompsCostTable';
    categoryLines: Array<{
      __typename?: 'ProjectCompsCostTableCategoryLine';
      description?: string | null;
      category: {
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        error?: string | null;
        level: number;
        hasSubCategories: boolean;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          builtin: boolean;
          description: string;
          draft: boolean;
          levels: number;
          deprecated: boolean;
        } | null;
        levels: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          hasSubCategories: boolean;
          level: number;
        }>;
      };
      pinnedUnitValues: {
        __typename?: 'ProjectCompsCostTableCostValues';
        quantityMagnitude: Numeric;
        percentValue: number;
        totalValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        quantityValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
      };
      columnValues: Array<{
        __typename?: 'ProjectCompsCostTableColumnValue';
        valueNumeric: Numeric;
        isEdited: boolean;
        isMaxValue: boolean;
        isMinValue: boolean;
        hasError: boolean;
        costTableColumnInput: {
          __typename?: 'CostTableColumnInputReturn';
          key: CostTableColumnInputKey;
          unitID?: UUID | null;
        };
      }>;
    }>;
    markupLines: Array<{
      __typename?: 'ProjectCompsCostTableMarkupLine';
      markupName: string;
      description?: string | null;
      pinnedUnitValues: {
        __typename?: 'ProjectCompsCostTableCostValues';
        quantityMagnitude: Numeric;
        percentValue: number;
        totalValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        quantityValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
      };
      columnValues: Array<{
        __typename?: 'ProjectCompsCostTableColumnValue';
        valueNumeric: Numeric;
        isEdited: boolean;
        isMaxValue: boolean;
        isMinValue: boolean;
        hasError: boolean;
        costTableColumnInput: {
          __typename?: 'CostTableColumnInputReturn';
          key: CostTableColumnInputKey;
          unitID?: UUID | null;
        };
      }>;
    }>;
    summaryLines: {
      __typename?: 'ProjectCompsCostTableSummaryLines';
      subtotalLine?: {
        __typename?: 'ProjectCompsCostTableSummaryLine';
        pinnedUnitValues: {
          __typename?: 'ProjectCompsCostTableCostValues';
          quantityMagnitude: Numeric;
          percentValue: number;
          totalValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          quantityValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        };
        columnValues: Array<{
          __typename?: 'ProjectCompsCostTableColumnValue';
          valueNumeric: Numeric;
          isEdited: boolean;
          isMaxValue: boolean;
          isMinValue: boolean;
          hasError: boolean;
          costTableColumnInput: {
            __typename?: 'CostTableColumnInputReturn';
            key: CostTableColumnInputKey;
            unitID?: UUID | null;
          };
        }>;
      } | null;
      totalLine: {
        __typename?: 'ProjectCompsCostTableSummaryLine';
        pinnedUnitValues: {
          __typename?: 'ProjectCompsCostTableCostValues';
          quantityMagnitude: Numeric;
          percentValue: number;
          totalValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          quantityValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        };
        columnValues: Array<{
          __typename?: 'ProjectCompsCostTableColumnValue';
          valueNumeric: Numeric;
          isEdited: boolean;
          isMaxValue: boolean;
          isMinValue: boolean;
          hasError: boolean;
          costTableColumnInput: {
            __typename?: 'CostTableColumnInputReturn';
            key: CostTableColumnInputKey;
            unitID?: UUID | null;
          };
        }>;
      };
      markupsTotalLine?: {
        __typename?: 'ProjectCompsCostTableSummaryLine';
        pinnedUnitValues: {
          __typename?: 'ProjectCompsCostTableCostValues';
          quantityMagnitude: Numeric;
          percentValue: number;
          totalValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          quantityValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        };
        columnValues: Array<{
          __typename?: 'ProjectCompsCostTableColumnValue';
          valueNumeric: Numeric;
          isEdited: boolean;
          isMaxValue: boolean;
          isMinValue: boolean;
          hasError: boolean;
          costTableColumnInput: {
            __typename?: 'CostTableColumnInputReturn';
            key: CostTableColumnInputKey;
            unitID?: UUID | null;
          };
        }>;
      } | null;
    };
  };
};

export type CommonAverageCompFieldsFragment = {
  __typename?: 'AverageComp';
  isHidden: boolean;
  isResettable: boolean;
  input: {
    __typename?: 'AverageCompInputReturn';
    name?: string | null;
    description?: string | null;
    color?: string | null;
    isHidden?: boolean | null;
    thumbnailAssetID?: UUID | null;
    location?: string | null;
    lat?: number | null;
    lon?: number | null;
    metrics: Array<{
      __typename?: 'MetricsInputReturn';
      unitID: UUID;
      quantityMagnitude?: string | null;
      hasMilestoneQuantity: boolean;
    }>;
    categoryLineInputs: Array<{
      __typename?: 'CategoryLineInputReturn';
      description?: string | null;
      editedValue: USCents;
      cellType: ProjectCompsCostTableCellType;
      unitID?: UUID | null;
      category: { __typename?: 'Category'; name: string; number: string };
    }>;
    markupLineInputs: Array<{
      __typename?: 'MarkupLineInputReturn';
      markupName: string;
      description?: string | null;
      editedValue: USCents;
      cellType: ProjectCompsCostTableCellType;
      unitID?: UUID | null;
    }>;
  };
  projectCompsCostTable: {
    __typename?: 'ProjectCompsCostTable';
    categoryLines: Array<{
      __typename?: 'ProjectCompsCostTableCategoryLine';
      description?: string | null;
      category: {
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        error?: string | null;
        level: number;
        hasSubCategories: boolean;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          builtin: boolean;
          description: string;
          draft: boolean;
          levels: number;
          deprecated: boolean;
        } | null;
        levels: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          hasSubCategories: boolean;
          level: number;
        }>;
      };
      pinnedUnitValues: {
        __typename?: 'ProjectCompsCostTableCostValues';
        quantityMagnitude: Numeric;
        percentValue: number;
        totalValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        quantityValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
      };
      columnValues: Array<{
        __typename?: 'ProjectCompsCostTableColumnValue';
        valueNumeric: Numeric;
        isEdited: boolean;
        isMaxValue: boolean;
        isMinValue: boolean;
        hasError: boolean;
        costTableColumnInput: {
          __typename?: 'CostTableColumnInputReturn';
          key: CostTableColumnInputKey;
          unitID?: UUID | null;
        };
      }>;
    }>;
    markupLines: Array<{
      __typename?: 'ProjectCompsCostTableMarkupLine';
      markupName: string;
      description?: string | null;
      pinnedUnitValues: {
        __typename?: 'ProjectCompsCostTableCostValues';
        quantityMagnitude: Numeric;
        percentValue: number;
        totalValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        quantityValue:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
      };
      columnValues: Array<{
        __typename?: 'ProjectCompsCostTableColumnValue';
        valueNumeric: Numeric;
        isEdited: boolean;
        isMaxValue: boolean;
        isMinValue: boolean;
        hasError: boolean;
        costTableColumnInput: {
          __typename?: 'CostTableColumnInputReturn';
          key: CostTableColumnInputKey;
          unitID?: UUID | null;
        };
      }>;
    }>;
    summaryLines: {
      __typename?: 'ProjectCompsCostTableSummaryLines';
      subtotalLine?: {
        __typename?: 'ProjectCompsCostTableSummaryLine';
        pinnedUnitValues: {
          __typename?: 'ProjectCompsCostTableCostValues';
          quantityMagnitude: Numeric;
          percentValue: number;
          totalValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          quantityValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        };
        columnValues: Array<{
          __typename?: 'ProjectCompsCostTableColumnValue';
          valueNumeric: Numeric;
          isEdited: boolean;
          isMaxValue: boolean;
          isMinValue: boolean;
          hasError: boolean;
          costTableColumnInput: {
            __typename?: 'CostTableColumnInputReturn';
            key: CostTableColumnInputKey;
            unitID?: UUID | null;
          };
        }>;
      } | null;
      totalLine: {
        __typename?: 'ProjectCompsCostTableSummaryLine';
        pinnedUnitValues: {
          __typename?: 'ProjectCompsCostTableCostValues';
          quantityMagnitude: Numeric;
          percentValue: number;
          totalValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          quantityValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        };
        columnValues: Array<{
          __typename?: 'ProjectCompsCostTableColumnValue';
          valueNumeric: Numeric;
          isEdited: boolean;
          isMaxValue: boolean;
          isMinValue: boolean;
          hasError: boolean;
          costTableColumnInput: {
            __typename?: 'CostTableColumnInputReturn';
            key: CostTableColumnInputKey;
            unitID?: UUID | null;
          };
        }>;
      };
      markupsTotalLine?: {
        __typename?: 'ProjectCompsCostTableSummaryLine';
        pinnedUnitValues: {
          __typename?: 'ProjectCompsCostTableCostValues';
          quantityMagnitude: Numeric;
          percentValue: number;
          totalValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          quantityValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        };
        columnValues: Array<{
          __typename?: 'ProjectCompsCostTableColumnValue';
          valueNumeric: Numeric;
          isEdited: boolean;
          isMaxValue: boolean;
          isMinValue: boolean;
          hasError: boolean;
          costTableColumnInput: {
            __typename?: 'CostTableColumnInputReturn';
            key: CostTableColumnInputKey;
            unitID?: UUID | null;
          };
        }>;
      } | null;
    };
  };
};

export type CommonUserReportFieldsFragment = {
  __typename?: 'UserReport';
  id: UUID;
  milestoneID?: UUID | null;
  reportType: UserReportType;
  shared: boolean;
  owner: boolean;
  name: string;
  description: string;
  settings: string;
  updatedAt: Time;
  hasDistributionScheduled: boolean;
  isFollowingActiveMilestone?: boolean | null;
  createdBy: { __typename?: 'User'; id: UUID; name: string; email: string };
};

export type CommonReportDistributionFieldsFragment = {
  __typename?: 'ReportDistribution';
  cadence: string;
  createdBy: UUID;
  id: UUID;
  reportID: UUID;
  collaboratorIDs: Array<UUID>;
  startDate: Time;
};

export type CommonRoleFieldsFragment = {
  __typename?: 'Role';
  id: UUID;
  name: string;
  type: ProjectRoleType;
  hasTrade: boolean;
  permissionGroups: Array<{
    __typename?: 'PermissionGroup';
    type: PermissionGroupType;
    permissions: Array<{
      __typename?: 'Permission';
      id: UUID;
      level: PermissionLevel;
      resource: PermissionResource;
      actions: {
        __typename?: 'Actions';
        view: boolean;
        add: boolean;
        delete: boolean;
        edit: boolean;
      };
    }>;
  }>;
};

export type CommonItemLikeSummaryFieldsFragment = {
  __typename?: 'ItemLikeSummary';
  id: UUID;
  number: string;
  kind?: string | null;
  visibility: Visibility;
};

export type MinimalBucketFieldsFragment = {
  __typename?: 'Bucket';
  id: UUID;
  name: string;
  date?: Time | null;
  milestone?: { __typename?: 'Milestone'; id: UUID } | null;
};

export type CommonTradeFieldsFragment = {
  __typename?: 'Category';
  id: UUID;
  name: string;
  number: string;
  hasSubCategories: boolean;
  level: number;
  categorization?: {
    __typename?: 'Categorization';
    draft: boolean;
    id: UUID;
    name: string;
    description: string;
    levels: number;
    builtin: boolean;
  } | null;
};

export type CommonCollaboratorFieldsFragment = {
  __typename?: 'Collaborator';
  allTrades: boolean;
  id: UUID;
  isProjectLead: boolean;
  responsibility?: string | null;
  role: {
    __typename?: 'Role';
    id: UUID;
    name: string;
    type: ProjectRoleType;
    hasTrade: boolean;
    permissionGroups: Array<{
      __typename?: 'PermissionGroup';
      type: PermissionGroupType;
      permissions: Array<{
        __typename?: 'Permission';
        id: UUID;
        level: PermissionLevel;
        resource: PermissionResource;
        actions: {
          __typename?: 'Actions';
          view: boolean;
          add: boolean;
          delete: boolean;
          edit: boolean;
        };
      }>;
    }>;
  };
  trades: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    hasSubCategories: boolean;
    level: number;
    categorization?: {
      __typename?: 'Categorization';
      draft: boolean;
      id: UUID;
      name: string;
      description: string;
      levels: number;
      builtin: boolean;
    } | null;
  }>;
  user: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  };
};

export type CommonCompanyRoleFieldsFragment = {
  __typename?: 'CompanyRole';
  id: UUID;
  name: string;
  permissionGroups: Array<{
    __typename?: 'PermissionGroup';
    type: PermissionGroupType;
    permissions: Array<{
      __typename?: 'Permission';
      id: UUID;
      level: PermissionLevel;
      resource: PermissionResource;
      actions: {
        __typename?: 'Actions';
        view: boolean;
        add: boolean;
        delete: boolean;
        edit: boolean;
      };
    }>;
  }>;
};

export type AssetFieldsFragment = {
  __typename?: 'Asset';
  id: UUID;
  name: string;
  size: Size;
  lastUpdated: Time;
  location: string;
  type?: string | null;
  derivations: Array<{
    __typename?: 'Derivation';
    id: UUID;
    kind: string;
    contents: Array<string>;
  }>;
  uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
};

export type RemoteAssetFieldsFragment = {
  __typename?: 'RemoteAsset';
  id: UUID;
  sourceAssetID: string;
  name: string;
  lastUpdated: Time;
  remoteLink: string;
  sourceSystem: SourceSystem;
  uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
};

type AttachmentFieldsQuery_Asset_Fragment = {
  __typename?: 'Asset';
  id: UUID;
  name: string;
  size: Size;
  lastUpdated: Time;
  location: string;
  type?: string | null;
  derivations: Array<{
    __typename?: 'Derivation';
    id: UUID;
    kind: string;
    contents: Array<string>;
  }>;
  uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
};

type AttachmentFieldsQuery_RemoteAsset_Fragment = {
  __typename?: 'RemoteAsset';
  id: UUID;
  sourceAssetID: string;
  name: string;
  lastUpdated: Time;
  remoteLink: string;
  sourceSystem: SourceSystem;
  uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
};

export type AttachmentFieldsQueryFragment =
  | AttachmentFieldsQuery_Asset_Fragment
  | AttachmentFieldsQuery_RemoteAsset_Fragment;

export type CommonItemHistoryEventContentFieldsFragment = {
  __typename?: 'ItemHistoryEventContent';
  newNumber?: string | null;
  oldNumber?: string | null;
  name?: string | null;
  description?: string | null;
  descriptionStyled?: string | null;
  newMilestoneID?: UUID | null;
  oldMilestoneID?: UUID | null;
  status?: Status | null;
  visibility?: Visibility | null;
  dueDate?: Time | null;
  drawName?: string | null;
  drawFromValue?: Numeric | null;
  drawToValue?: Numeric | null;
  milestoneID?: UUID | null;
  assignee?: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
  deletedBy?: {
    __typename?: 'User';
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    status: UserStatus;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
  sharedUsers: Array<{
    __typename?: 'User';
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    status: UserStatus;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null>;
  meeting?: {
    __typename?: 'Bucket';
    id: UUID;
    name: string;
    date?: Time | null;
    milestone?: { __typename?: 'Milestone'; id: UUID } | null;
  } | null;
  addedAttachments?: Array<
    | {
        __typename?: 'Asset';
        id: UUID;
        name: string;
        size: Size;
        lastUpdated: Time;
        location: string;
        type?: string | null;
        derivations: Array<{
          __typename?: 'Derivation';
          id: UUID;
          kind: string;
          contents: Array<string>;
        }>;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
    | {
        __typename?: 'RemoteAsset';
        id: UUID;
        sourceAssetID: string;
        name: string;
        lastUpdated: Time;
        remoteLink: string;
        sourceSystem: SourceSystem;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
    | null
  > | null;
  removedAssets?: Array<{ __typename?: 'Asset'; name: string } | null> | null;
  newCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  oldCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  categoryChanges?: Array<{
    __typename?: 'CategoryChange';
    oldCategory?: {
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    } | null;
    newCategory?: {
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    } | null;
  }> | null;
  costChanges?: Array<{
    __typename?: 'CostChange';
    timestamp: Time;
    oldCost:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
    newCost:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
  }> | null;
  scheduleImpactChanges: Array<{
    __typename?: 'ScheduleImpactChange';
    timestamp: Time;
    old?: {
      __typename?: 'ScheduleImpact';
      type: ScheduleImpactType;
      criticalPath: boolean;
      days: number;
      minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
      maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    } | null;
    new: {
      __typename?: 'ScheduleImpact';
      type: ScheduleImpactType;
      criticalPath: boolean;
      days: number;
      minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
      maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    };
  }>;
  oldTimelineActivities: Array<{ __typename?: 'TimelineActivity'; id: UUID; name: string }>;
  newTimelineActivities: Array<{ __typename?: 'TimelineActivity'; id: UUID; name: string }>;
  itemLikeSummary?: {
    __typename?: 'ItemLikeSummary';
    id: UUID;
    number: string;
    kind?: string | null;
    visibility: Visibility;
  } | null;
  removedOptions?: Array<{
    __typename?: 'ItemLikeSummary';
    id: UUID;
    number: string;
    kind?: string | null;
    visibility: Visibility;
  } | null> | null;
  attachedOptions?: Array<{
    __typename?: 'ItemLikeSummary';
    id: UUID;
    number: string;
    kind?: string | null;
    visibility: Visibility;
  } | null> | null;
  statusChangeDraws?: Array<{
    __typename?: 'StatusChangeDraw';
    name: string;
    value: Numeric;
  } | null> | null;
  drawChanges?: Array<{
    __typename?: 'DrawChange';
    name: string;
    fromValue: Numeric;
    toValue: Numeric;
    timestamp: Time;
  }> | null;
  referencedByItems?: Array<{
    __typename?: 'ItemLikeSummary';
    id: UUID;
    number: string;
    kind?: string | null;
    visibility: Visibility;
  } | null> | null;
  comment?: {
    __typename?: 'Comment';
    id: UUID;
    created: Time;
    author: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    text: {
      __typename?: 'MentionableText';
      contents: string;
      mentions?: Array<{
        __typename?: 'Mention';
        id: UUID;
        position: number;
        text: string;
        user: { __typename?: 'User'; id: UUID };
      }> | null;
      itemReferences?: Array<{
        __typename?: 'ItemReference';
        id: UUID;
        position: number;
        text: string;
        referencedItem?: {
          __typename?: 'ItemsListItem';
          createdAt: Time;
          currentStatus: Status;
          id: UUID;
          name: string;
          number: string;
          status: Status;
          updateTime: Time;
          visibility: Visibility;
          creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
          currentCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
          options: Array<{
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            status: Status;
            visibility?: Visibility | null;
          }>;
        } | null;
      }> | null;
    };
  } | null;
  copiedItem?: {
    __typename?: 'ItemLinkWithProjectID';
    id: UUID;
    name: string;
    number: string;
    projectID: UUID;
    status: Status;
    visibility?: Visibility | null;
    hasAccess: boolean;
  } | null;
  itemIntegrationObject?: {
    __typename?: 'ItemIntegrationObjectSummary';
    sourceEntityID: string;
    number: number;
    title: string;
  } | null;
};

export type ItemHistoryFieldsFragment = {
  __typename?: 'ItemHistory';
  events: Array<{
    __typename?: 'ItemHistoryEvent';
    id: UUID;
    itemID: UUID;
    eventTypes: Array<EventType>;
    timestamp: Time;
    user?: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
    eventContent: {
      __typename?: 'ItemHistoryEventContent';
      newNumber?: string | null;
      oldNumber?: string | null;
      name?: string | null;
      description?: string | null;
      descriptionStyled?: string | null;
      newMilestoneID?: UUID | null;
      oldMilestoneID?: UUID | null;
      status?: Status | null;
      visibility?: Visibility | null;
      dueDate?: Time | null;
      drawName?: string | null;
      drawFromValue?: Numeric | null;
      drawToValue?: Numeric | null;
      milestoneID?: UUID | null;
      assignee?: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null;
      deletedBy?: {
        __typename?: 'User';
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        status: UserStatus;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null;
      sharedUsers: Array<{
        __typename?: 'User';
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        status: UserStatus;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null>;
      meeting?: {
        __typename?: 'Bucket';
        id: UUID;
        name: string;
        date?: Time | null;
        milestone?: { __typename?: 'Milestone'; id: UUID } | null;
      } | null;
      addedAttachments?: Array<
        | {
            __typename?: 'Asset';
            id: UUID;
            name: string;
            size: Size;
            lastUpdated: Time;
            location: string;
            type?: string | null;
            derivations: Array<{
              __typename?: 'Derivation';
              id: UUID;
              kind: string;
              contents: Array<string>;
            }>;
            uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
          }
        | {
            __typename?: 'RemoteAsset';
            id: UUID;
            sourceAssetID: string;
            name: string;
            lastUpdated: Time;
            remoteLink: string;
            sourceSystem: SourceSystem;
            uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
          }
        | null
      > | null;
      removedAssets?: Array<{ __typename?: 'Asset'; name: string } | null> | null;
      newCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      oldCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      categoryChanges?: Array<{
        __typename?: 'CategoryChange';
        oldCategory?: {
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        } | null;
        newCategory?: {
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        } | null;
      }> | null;
      costChanges?: Array<{
        __typename?: 'CostChange';
        timestamp: Time;
        oldCost:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        newCost:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
      }> | null;
      scheduleImpactChanges: Array<{
        __typename?: 'ScheduleImpactChange';
        timestamp: Time;
        old?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
        new: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        };
      }>;
      oldTimelineActivities: Array<{ __typename?: 'TimelineActivity'; id: UUID; name: string }>;
      newTimelineActivities: Array<{ __typename?: 'TimelineActivity'; id: UUID; name: string }>;
      itemLikeSummary?: {
        __typename?: 'ItemLikeSummary';
        id: UUID;
        number: string;
        kind?: string | null;
        visibility: Visibility;
      } | null;
      removedOptions?: Array<{
        __typename?: 'ItemLikeSummary';
        id: UUID;
        number: string;
        kind?: string | null;
        visibility: Visibility;
      } | null> | null;
      attachedOptions?: Array<{
        __typename?: 'ItemLikeSummary';
        id: UUID;
        number: string;
        kind?: string | null;
        visibility: Visibility;
      } | null> | null;
      statusChangeDraws?: Array<{
        __typename?: 'StatusChangeDraw';
        name: string;
        value: Numeric;
      } | null> | null;
      drawChanges?: Array<{
        __typename?: 'DrawChange';
        name: string;
        fromValue: Numeric;
        toValue: Numeric;
        timestamp: Time;
      }> | null;
      referencedByItems?: Array<{
        __typename?: 'ItemLikeSummary';
        id: UUID;
        number: string;
        kind?: string | null;
        visibility: Visibility;
      } | null> | null;
      comment?: {
        __typename?: 'Comment';
        id: UUID;
        created: Time;
        author: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        };
        text: {
          __typename?: 'MentionableText';
          contents: string;
          mentions?: Array<{
            __typename?: 'Mention';
            id: UUID;
            position: number;
            text: string;
            user: { __typename?: 'User'; id: UUID };
          }> | null;
          itemReferences?: Array<{
            __typename?: 'ItemReference';
            id: UUID;
            position: number;
            text: string;
            referencedItem?: {
              __typename?: 'ItemsListItem';
              createdAt: Time;
              currentStatus: Status;
              id: UUID;
              name: string;
              number: string;
              status: Status;
              updateTime: Time;
              visibility: Visibility;
              creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
              currentCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
              options: Array<{
                __typename?: 'ItemLink';
                id: UUID;
                name: string;
                number: string;
                status: Status;
                visibility?: Visibility | null;
              }>;
            } | null;
          }> | null;
        };
      } | null;
      copiedItem?: {
        __typename?: 'ItemLinkWithProjectID';
        id: UUID;
        name: string;
        number: string;
        projectID: UUID;
        status: Status;
        visibility?: Visibility | null;
        hasAccess: boolean;
      } | null;
      itemIntegrationObject?: {
        __typename?: 'ItemIntegrationObjectSummary';
        sourceEntityID: string;
        number: number;
        title: string;
      } | null;
    };
  }>;
};

export type ItemActivityEventFieldsFragment = {
  __typename?: 'ItemHistoryEvent';
  id: UUID;
  itemID: UUID;
  eventTypes: Array<EventType>;
  timestamp: Time;
  user?: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
  eventContent: {
    __typename?: 'ItemHistoryEventContent';
    newNumber?: string | null;
    oldNumber?: string | null;
    name?: string | null;
    description?: string | null;
    descriptionStyled?: string | null;
    newMilestoneID?: UUID | null;
    oldMilestoneID?: UUID | null;
    status?: Status | null;
    visibility?: Visibility | null;
    dueDate?: Time | null;
    drawName?: string | null;
    drawFromValue?: Numeric | null;
    drawToValue?: Numeric | null;
    milestoneID?: UUID | null;
    assignee?: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
    deletedBy?: {
      __typename?: 'User';
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      status: UserStatus;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
    sharedUsers: Array<{
      __typename?: 'User';
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      status: UserStatus;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null>;
    meeting?: {
      __typename?: 'Bucket';
      id: UUID;
      name: string;
      date?: Time | null;
      milestone?: { __typename?: 'Milestone'; id: UUID } | null;
    } | null;
    addedAttachments?: Array<
      | {
          __typename?: 'Asset';
          id: UUID;
          name: string;
          size: Size;
          lastUpdated: Time;
          location: string;
          type?: string | null;
          derivations: Array<{
            __typename?: 'Derivation';
            id: UUID;
            kind: string;
            contents: Array<string>;
          }>;
          uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
        }
      | {
          __typename?: 'RemoteAsset';
          id: UUID;
          sourceAssetID: string;
          name: string;
          lastUpdated: Time;
          remoteLink: string;
          sourceSystem: SourceSystem;
          uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
        }
      | null
    > | null;
    removedAssets?: Array<{ __typename?: 'Asset'; name: string } | null> | null;
    newCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    oldCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    categoryChanges?: Array<{
      __typename?: 'CategoryChange';
      oldCategory?: {
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        error?: string | null;
        level: number;
        hasSubCategories: boolean;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          builtin: boolean;
          description: string;
          draft: boolean;
          levels: number;
          deprecated: boolean;
        } | null;
        levels: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          hasSubCategories: boolean;
          level: number;
        }>;
      } | null;
      newCategory?: {
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        error?: string | null;
        level: number;
        hasSubCategories: boolean;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          builtin: boolean;
          description: string;
          draft: boolean;
          levels: number;
          deprecated: boolean;
        } | null;
        levels: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          hasSubCategories: boolean;
          level: number;
        }>;
      } | null;
    }> | null;
    costChanges?: Array<{
      __typename?: 'CostChange';
      timestamp: Time;
      oldCost:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
      newCost:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
    }> | null;
    scheduleImpactChanges: Array<{
      __typename?: 'ScheduleImpactChange';
      timestamp: Time;
      old?: {
        __typename?: 'ScheduleImpact';
        type: ScheduleImpactType;
        criticalPath: boolean;
        days: number;
        minImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
        maxImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
      } | null;
      new: {
        __typename?: 'ScheduleImpact';
        type: ScheduleImpactType;
        criticalPath: boolean;
        days: number;
        minImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
        maxImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
      };
    }>;
    oldTimelineActivities: Array<{ __typename?: 'TimelineActivity'; id: UUID; name: string }>;
    newTimelineActivities: Array<{ __typename?: 'TimelineActivity'; id: UUID; name: string }>;
    itemLikeSummary?: {
      __typename?: 'ItemLikeSummary';
      id: UUID;
      number: string;
      kind?: string | null;
      visibility: Visibility;
    } | null;
    removedOptions?: Array<{
      __typename?: 'ItemLikeSummary';
      id: UUID;
      number: string;
      kind?: string | null;
      visibility: Visibility;
    } | null> | null;
    attachedOptions?: Array<{
      __typename?: 'ItemLikeSummary';
      id: UUID;
      number: string;
      kind?: string | null;
      visibility: Visibility;
    } | null> | null;
    statusChangeDraws?: Array<{
      __typename?: 'StatusChangeDraw';
      name: string;
      value: Numeric;
    } | null> | null;
    drawChanges?: Array<{
      __typename?: 'DrawChange';
      name: string;
      fromValue: Numeric;
      toValue: Numeric;
      timestamp: Time;
    }> | null;
    referencedByItems?: Array<{
      __typename?: 'ItemLikeSummary';
      id: UUID;
      number: string;
      kind?: string | null;
      visibility: Visibility;
    } | null> | null;
    comment?: {
      __typename?: 'Comment';
      id: UUID;
      created: Time;
      author: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      text: {
        __typename?: 'MentionableText';
        contents: string;
        mentions?: Array<{
          __typename?: 'Mention';
          id: UUID;
          position: number;
          text: string;
          user: { __typename?: 'User'; id: UUID };
        }> | null;
        itemReferences?: Array<{
          __typename?: 'ItemReference';
          id: UUID;
          position: number;
          text: string;
          referencedItem?: {
            __typename?: 'ItemsListItem';
            createdAt: Time;
            currentStatus: Status;
            id: UUID;
            name: string;
            number: string;
            status: Status;
            updateTime: Time;
            visibility: Visibility;
            creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
            currentCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
            options: Array<{
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              status: Status;
              visibility?: Visibility | null;
            }>;
          } | null;
        }> | null;
      };
    } | null;
    copiedItem?: {
      __typename?: 'ItemLinkWithProjectID';
      id: UUID;
      name: string;
      number: string;
      projectID: UUID;
      status: Status;
      visibility?: Visibility | null;
      hasAccess: boolean;
    } | null;
    itemIntegrationObject?: {
      __typename?: 'ItemIntegrationObjectSummary';
      sourceEntityID: string;
      number: number;
      title: string;
    } | null;
  };
};

export type ItemActivityFieldsFragment = {
  __typename?: 'ItemActivity';
  paginationData: { __typename?: 'PaginationData'; nextOffsetID?: UUID | null };
  data: Array<{
    __typename?: 'ItemHistoryEvent';
    id: UUID;
    itemID: UUID;
    eventTypes: Array<EventType>;
    timestamp: Time;
    user?: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
    eventContent: {
      __typename?: 'ItemHistoryEventContent';
      newNumber?: string | null;
      oldNumber?: string | null;
      name?: string | null;
      description?: string | null;
      descriptionStyled?: string | null;
      newMilestoneID?: UUID | null;
      oldMilestoneID?: UUID | null;
      status?: Status | null;
      visibility?: Visibility | null;
      dueDate?: Time | null;
      drawName?: string | null;
      drawFromValue?: Numeric | null;
      drawToValue?: Numeric | null;
      milestoneID?: UUID | null;
      assignee?: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null;
      deletedBy?: {
        __typename?: 'User';
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        status: UserStatus;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null;
      sharedUsers: Array<{
        __typename?: 'User';
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        status: UserStatus;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null>;
      meeting?: {
        __typename?: 'Bucket';
        id: UUID;
        name: string;
        date?: Time | null;
        milestone?: { __typename?: 'Milestone'; id: UUID } | null;
      } | null;
      addedAttachments?: Array<
        | {
            __typename?: 'Asset';
            id: UUID;
            name: string;
            size: Size;
            lastUpdated: Time;
            location: string;
            type?: string | null;
            derivations: Array<{
              __typename?: 'Derivation';
              id: UUID;
              kind: string;
              contents: Array<string>;
            }>;
            uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
          }
        | {
            __typename?: 'RemoteAsset';
            id: UUID;
            sourceAssetID: string;
            name: string;
            lastUpdated: Time;
            remoteLink: string;
            sourceSystem: SourceSystem;
            uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
          }
        | null
      > | null;
      removedAssets?: Array<{ __typename?: 'Asset'; name: string } | null> | null;
      newCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      oldCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      categoryChanges?: Array<{
        __typename?: 'CategoryChange';
        oldCategory?: {
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        } | null;
        newCategory?: {
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        } | null;
      }> | null;
      costChanges?: Array<{
        __typename?: 'CostChange';
        timestamp: Time;
        oldCost:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        newCost:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
      }> | null;
      scheduleImpactChanges: Array<{
        __typename?: 'ScheduleImpactChange';
        timestamp: Time;
        old?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
        new: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        };
      }>;
      oldTimelineActivities: Array<{ __typename?: 'TimelineActivity'; id: UUID; name: string }>;
      newTimelineActivities: Array<{ __typename?: 'TimelineActivity'; id: UUID; name: string }>;
      itemLikeSummary?: {
        __typename?: 'ItemLikeSummary';
        id: UUID;
        number: string;
        kind?: string | null;
        visibility: Visibility;
      } | null;
      removedOptions?: Array<{
        __typename?: 'ItemLikeSummary';
        id: UUID;
        number: string;
        kind?: string | null;
        visibility: Visibility;
      } | null> | null;
      attachedOptions?: Array<{
        __typename?: 'ItemLikeSummary';
        id: UUID;
        number: string;
        kind?: string | null;
        visibility: Visibility;
      } | null> | null;
      statusChangeDraws?: Array<{
        __typename?: 'StatusChangeDraw';
        name: string;
        value: Numeric;
      } | null> | null;
      drawChanges?: Array<{
        __typename?: 'DrawChange';
        name: string;
        fromValue: Numeric;
        toValue: Numeric;
        timestamp: Time;
      }> | null;
      referencedByItems?: Array<{
        __typename?: 'ItemLikeSummary';
        id: UUID;
        number: string;
        kind?: string | null;
        visibility: Visibility;
      } | null> | null;
      comment?: {
        __typename?: 'Comment';
        id: UUID;
        created: Time;
        author: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        };
        text: {
          __typename?: 'MentionableText';
          contents: string;
          mentions?: Array<{
            __typename?: 'Mention';
            id: UUID;
            position: number;
            text: string;
            user: { __typename?: 'User'; id: UUID };
          }> | null;
          itemReferences?: Array<{
            __typename?: 'ItemReference';
            id: UUID;
            position: number;
            text: string;
            referencedItem?: {
              __typename?: 'ItemsListItem';
              createdAt: Time;
              currentStatus: Status;
              id: UUID;
              name: string;
              number: string;
              status: Status;
              updateTime: Time;
              visibility: Visibility;
              creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
              currentCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
              options: Array<{
                __typename?: 'ItemLink';
                id: UUID;
                name: string;
                number: string;
                status: Status;
                visibility?: Visibility | null;
              }>;
            } | null;
          }> | null;
        };
      } | null;
      copiedItem?: {
        __typename?: 'ItemLinkWithProjectID';
        id: UUID;
        name: string;
        number: string;
        projectID: UUID;
        status: Status;
        visibility?: Visibility | null;
        hasAccess: boolean;
      } | null;
      itemIntegrationObject?: {
        __typename?: 'ItemIntegrationObjectSummary';
        sourceEntityID: string;
        number: number;
        title: string;
      } | null;
    };
  }>;
};

export type EventDataItemFieldsFragment = {
  __typename?: 'EventItem';
  id: UUID;
  name: string;
  number: string;
  status: Status;
  cost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  milestone?: { __typename?: 'Milestone'; id: UUID } | null;
};

export type ItemEventSummaryFieldsFragment = {
  __typename?: 'ItemSummary';
  id: UUID;
  number: string;
  currentNumber: string;
  status: Status;
};

export type CommonEventDataFieldsFragment = {
  __typename?: 'EventData';
  events: Array<{
    __typename?: 'EventDataItem';
    timestamp: Time;
    eventType: EventType;
    currentItemNumber: string;
    ids: Array<UUID>;
    visible?: boolean | null;
    item: {
      __typename?: 'EventItem';
      id: UUID;
      name: string;
      number: string;
      status: Status;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      milestone?: { __typename?: 'Milestone'; id: UUID } | null;
    };
    user: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    costImpact: {
      __typename?: 'EventCostImpact';
      minPending: USCents;
      maxPending: USCents;
      cost: USCents;
    };
    eventContent: {
      __typename?: 'EventContent';
      oldMilestone?: UUID | null;
      newMilestone?: UUID | null;
      oldOptionStatus?: Status | null;
      newOptionStatus?: Status | null;
      oldStatus?: Status | null;
      newStatus?: Status | null;
      oldCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      newCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      option?: {
        __typename?: 'ItemSummary';
        id: UUID;
        number: string;
        currentNumber: string;
        status: Status;
      } | null;
      options?: Array<{
        __typename?: 'ItemSummary';
        id: UUID;
        number: string;
        currentNumber: string;
        status: Status;
      }> | null;
      parent?: {
        __typename?: 'ItemSummary';
        id: UUID;
        number: string;
        currentNumber: string;
        status: Status;
      } | null;
    };
  }>;
};

export type ShareResourcesFieldsFragment = {
  __typename?: 'ShareResourcesPayload';
  resources: Array<{
    __typename?: 'SharedResource';
    id: UUID;
    type: ResourceType;
    accessLevels: Array<AccessLevel>;
    users: Array<{
      __typename?: 'User';
      id: UUID;
      email: string;
      isEmailVerified: boolean;
      jobTitle: string;
      name: string;
      picture: string;
      status: UserStatus;
    }>;
  }>;
};

export type GetShareResourceFieldsFragment = {
  __typename?: 'GetSharedResourcePayload';
  resource?: {
    __typename?: 'SharedResource';
    id: UUID;
    type: ResourceType;
    accessLevels: Array<AccessLevel>;
    users: Array<{
      __typename?: 'User';
      id: UUID;
      email: string;
      isEmailVerified: boolean;
      jobTitle: string;
      name: string;
      picture: string;
      status: UserStatus;
    }>;
  } | null;
};

export type CommonRemoteAssetFieldsFragment = {
  __typename?: 'RemoteAsset';
  id: UUID;
  name: string;
  lastUpdated: Time;
  remoteLink: string;
  uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
};

type AttachmentFields_Asset_Fragment = {
  __typename?: 'Asset';
  id: UUID;
  name: string;
  size: Size;
  lastUpdated: Time;
  location: string;
  type?: string | null;
  derivations: Array<{
    __typename?: 'Derivation';
    id: UUID;
    kind: string;
    contents: Array<string>;
  }>;
  uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
};

type AttachmentFields_RemoteAsset_Fragment = {
  __typename?: 'RemoteAsset';
  id: UUID;
  name: string;
  lastUpdated: Time;
  remoteLink: string;
  uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
};

export type AttachmentFieldsFragment =
  | AttachmentFields_Asset_Fragment
  | AttachmentFields_RemoteAsset_Fragment;

export type DetailedMilestoneFieldsFragment = {
  __typename?: 'Milestone';
  id: UUID;
  name: string;
  description: string;
  descriptionStyled: string;
  deletable: boolean;
  date?: Time | null;
  isDraft: boolean;
  itemsCount: number;
  draftItemsCount: number;
  assetCount: number;
  remoteAssetCount: number;
  activeEstimateID?: UUID | null;
  activeEstimateDraftID?: UUID | null;
  budgetID?: UUID | null;
  budgetDraftID?: UUID | null;
  buckets?: Array<{
    __typename?: 'Bucket';
    date?: Time | null;
    id: UUID;
    name: string;
    itemsCount: number;
  }> | null;
  createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
  designPhase?: {
    __typename?: 'DesignPhaseType';
    id: UUID;
    name: string;
    abbreviation: string;
  } | null;
  timelinePhase?: {
    __typename?: 'TimelineActivity';
    id: UUID;
    name: string;
    startDate: Time;
    endDate?: Time | null;
  } | null;
  importedEstimates: Array<{
    __typename?: 'Asset';
    id: UUID;
    name: string;
    size: Size;
    lastUpdated: Time;
    location: string;
    type?: string | null;
    derivations: Array<{
      __typename?: 'Derivation';
      id: UUID;
      kind: string;
      contents: Array<string>;
    }>;
    uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
  }>;
  quantities?: Array<{
    __typename?: 'Quantity';
    id: UUID;
    magnitude: Numeric;
    estimateID: UUID;
    hasBreakdown: boolean;
    unit: {
      __typename?: 'Unit';
      abbreviationPlural: string;
      abbreviationSingular: string;
      description?: string | null;
      id: UUID;
      isDefault: boolean;
      isEnabled: boolean;
      name: string;
      type: UnitType;
    };
  } | null> | null;
};

export type CommonUserNotificationSettingFieldsFragment = {
  __typename?: 'UserNotificationSetting';
  assignments: boolean;
  channel: NotificationChannel;
  comments: CommentNotificationSetting;
  isGlobalSubscribed: boolean;
  mentions: boolean;
  newItems: boolean;
  shareScenarios: boolean;
};

export type CommonProjectFieldsFragment = {
  __typename?: 'Project';
  id: UUID;
  description: string;
  descriptionStyled: string;
  name: string;
  location: string;
  thumbnail: string;
  activeMilestone: { __typename?: 'Milestone'; id: UUID; name: string };
  status: {
    __typename?: 'ProjectStatus';
    id: UUID;
    name: string;
    type: string;
    description: string;
  };
  team?: {
    __typename?: 'Team';
    owner?: { __typename?: 'Organization'; name?: string | null; picture?: string | null } | null;
    generalContractor?: {
      __typename?: 'Organization';
      name?: string | null;
      picture?: string | null;
    } | null;
    designTeam?: {
      __typename?: 'Organization';
      name?: string | null;
      picture?: string | null;
    } | null;
  } | null;
  type: { __typename?: 'ProjectType'; id: UUID; name: string };
  milestones: Array<{
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    assetCount: number;
    remoteAssetCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    buckets?: Array<{
      __typename?: 'Bucket';
      date?: Time | null;
      id: UUID;
      name: string;
      itemsCount: number;
    }> | null;
    createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    importedEstimates: Array<{
      __typename?: 'Asset';
      id: UUID;
      name: string;
      size: Size;
      lastUpdated: Time;
      location: string;
      type?: string | null;
      derivations: Array<{
        __typename?: 'Derivation';
        id: UUID;
        kind: string;
        contents: Array<string>;
      }>;
      uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    }>;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  }>;
  roles: Array<{
    __typename?: 'Role';
    id: UUID;
    name: string;
    type: ProjectRoleType;
    hasTrade: boolean;
    permissionGroups: Array<{
      __typename?: 'PermissionGroup';
      type: PermissionGroupType;
      permissions: Array<{
        __typename?: 'Permission';
        id: UUID;
        level: PermissionLevel;
        resource: PermissionResource;
        actions: {
          __typename?: 'Actions';
          view: boolean;
          add: boolean;
          delete: boolean;
          edit: boolean;
        };
      }>;
    }>;
  }>;
  projectDeliveryType?: {
    __typename?: 'ProjectDeliveryType';
    id: UUID;
    name: string;
    abbreviation: string;
  } | null;
  locationDetails?: {
    __typename?: 'LocationDetails';
    name: string;
    lat: number;
    lon: number;
    city: string;
    state: string;
    stateLong: string;
    country: string;
  } | null;
};

export type CommonTriggeringUserFieldsFragment = {
  __typename?: 'User';
  name: string;
  email: string;
  id: UUID;
};

export type CommonNotificationProjectFieldsFragment = {
  __typename?: 'Project';
  name: string;
  id: UUID;
};

export type AssignItemFieldsFragment = {
  __typename?: 'AssignItem';
  triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
  project: { __typename?: 'Project'; name: string; id: UUID };
  item: {
    __typename?: 'ItemLink';
    id: UUID;
    name: string;
    number: string;
    visibility?: Visibility | null;
  };
};

export type AssignProjectLeadFieldsFragment = {
  __typename?: 'AssignProjectLead';
  triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
  project: { __typename?: 'Project'; name: string; id: UUID };
};

export type ShareDraftItemFieldsFragment = {
  __typename?: 'ShareDraftItem';
  triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
  project: { __typename?: 'Project'; name: string; id: UUID };
  item: {
    __typename?: 'ItemLink';
    id: UUID;
    name: string;
    number: string;
    visibility?: Visibility | null;
  };
};

export type ShareScenarioFieldsFragment = {
  __typename?: 'ShareScenario';
  triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
  project: { __typename?: 'Project'; name: string; id: UUID };
  scenario: { __typename?: 'ScenarioLink'; id: UUID; name: string };
};

export type NewMentionFieldsFragment = {
  __typename?: 'NewMention';
  triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
  project: { __typename?: 'Project'; name: string; id: UUID };
  item: {
    __typename?: 'ItemLink';
    id: UUID;
    name: string;
    number: string;
    visibility?: Visibility | null;
  };
  comment: {
    __typename?: 'Comment';
    id: UUID;
    created: Time;
    author: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    text: {
      __typename?: 'MentionableText';
      contents: string;
      mentions?: Array<{
        __typename?: 'Mention';
        id: UUID;
        position: number;
        text: string;
        user: { __typename?: 'User'; id: UUID };
      }> | null;
      itemReferences?: Array<{
        __typename?: 'ItemReference';
        id: UUID;
        position: number;
        text: string;
        referencedItem?: {
          __typename?: 'ItemsListItem';
          createdAt: Time;
          currentStatus: Status;
          id: UUID;
          name: string;
          number: string;
          status: Status;
          updateTime: Time;
          visibility: Visibility;
          creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
          currentCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
          options: Array<{
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            status: Status;
            visibility?: Visibility | null;
          }>;
        } | null;
      }> | null;
    };
  };
};

export type NewReportMentionFieldsFragment = {
  __typename?: 'NewReportMention';
  reportID: UUID;
  reportName: string;
  triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
  project: { __typename?: 'Project'; name: string; id: UUID };
  userReportComment: {
    __typename?: 'UserReportComment';
    commentLineID: string;
    type: UserReportCommentType;
    costs: Array<USCents>;
    comment: {
      __typename?: 'Comment';
      id: UUID;
      created: Time;
      author: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      text: {
        __typename?: 'MentionableText';
        contents: string;
        mentions?: Array<{
          __typename?: 'Mention';
          id: UUID;
          position: number;
          text: string;
          user: { __typename?: 'User'; id: UUID };
        }> | null;
        itemReferences?: Array<{
          __typename?: 'ItemReference';
          id: UUID;
          position: number;
          text: string;
          referencedItem?: {
            __typename?: 'ItemsListItem';
            createdAt: Time;
            currentStatus: Status;
            id: UUID;
            name: string;
            number: string;
            status: Status;
            updateTime: Time;
            visibility: Visibility;
            creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
            currentCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
            options: Array<{
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              status: Status;
              visibility?: Visibility | null;
            }>;
          } | null;
        }> | null;
      };
    };
  };
};

export type NewCommentFieldsFragment = {
  __typename?: 'NewComment';
  triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
  project: { __typename?: 'Project'; name: string; id: UUID };
  item: {
    __typename?: 'ItemLink';
    id: UUID;
    name: string;
    number: string;
    visibility?: Visibility | null;
  };
  comment: {
    __typename?: 'Comment';
    id: UUID;
    created: Time;
    author: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    text: {
      __typename?: 'MentionableText';
      contents: string;
      mentions?: Array<{
        __typename?: 'Mention';
        id: UUID;
        position: number;
        text: string;
        user: { __typename?: 'User'; id: UUID };
      }> | null;
      itemReferences?: Array<{
        __typename?: 'ItemReference';
        id: UUID;
        position: number;
        text: string;
        referencedItem?: {
          __typename?: 'ItemsListItem';
          createdAt: Time;
          currentStatus: Status;
          id: UUID;
          name: string;
          number: string;
          status: Status;
          updateTime: Time;
          visibility: Visibility;
          creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
          currentCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
          options: Array<{
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            status: Status;
            visibility?: Visibility | null;
          }>;
        } | null;
      }> | null;
    };
  };
};

export type NewItemFieldsFragment = {
  __typename?: 'NewItem';
  triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
  project: { __typename?: 'Project'; name: string; id: UUID };
  item: {
    __typename?: 'ItemLink';
    id: UUID;
    name: string;
    number: string;
    visibility?: Visibility | null;
  };
};

export type NewOptionFieldsFragment = {
  __typename?: 'NewOption';
  triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
  project: { __typename?: 'Project'; name: string; id: UUID };
  parentItem: {
    __typename?: 'ItemLink';
    id: UUID;
    name: string;
    number: string;
    visibility?: Visibility | null;
  };
  option: {
    __typename?: 'ItemLink';
    id: UUID;
    name: string;
    number: string;
    visibility?: Visibility | null;
  };
};

export type ProjectInviteFieldsFragment = {
  __typename?: 'InviteToProject';
  message?: string | null;
  triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
  project: { __typename?: 'Project'; name: string; id: UUID };
};

export type ProjectRemoveFieldsFragment = {
  __typename?: 'RemoveFromProject';
  triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
  project: { __typename?: 'Project'; name: string; id: UUID };
};

export type CommonInAppNotificationFieldsFragment = {
  __typename?: 'InAppNotification';
  id: UUID;
  notificationType: NotificationType;
  projectID: UUID;
  createdAt: Time;
  readAt?: Time | null;
  content:
    | {
        __typename?: 'AssignItem';
        triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
        project: { __typename?: 'Project'; name: string; id: UUID };
        item: {
          __typename?: 'ItemLink';
          id: UUID;
          name: string;
          number: string;
          visibility?: Visibility | null;
        };
      }
    | {
        __typename?: 'AssignProjectLead';
        triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
        project: { __typename?: 'Project'; name: string; id: UUID };
      }
    | {
        __typename?: 'InviteToProject';
        message?: string | null;
        triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
        project: { __typename?: 'Project'; name: string; id: UUID };
      }
    | {
        __typename?: 'NewComment';
        triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
        project: { __typename?: 'Project'; name: string; id: UUID };
        item: {
          __typename?: 'ItemLink';
          id: UUID;
          name: string;
          number: string;
          visibility?: Visibility | null;
        };
        comment: {
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        };
      }
    | {
        __typename?: 'NewItem';
        triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
        project: { __typename?: 'Project'; name: string; id: UUID };
        item: {
          __typename?: 'ItemLink';
          id: UUID;
          name: string;
          number: string;
          visibility?: Visibility | null;
        };
      }
    | {
        __typename?: 'NewMention';
        triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
        project: { __typename?: 'Project'; name: string; id: UUID };
        item: {
          __typename?: 'ItemLink';
          id: UUID;
          name: string;
          number: string;
          visibility?: Visibility | null;
        };
        comment: {
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        };
      }
    | {
        __typename?: 'NewOption';
        triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
        project: { __typename?: 'Project'; name: string; id: UUID };
        parentItem: {
          __typename?: 'ItemLink';
          id: UUID;
          name: string;
          number: string;
          visibility?: Visibility | null;
        };
        option: {
          __typename?: 'ItemLink';
          id: UUID;
          name: string;
          number: string;
          visibility?: Visibility | null;
        };
      }
    | {
        __typename?: 'NewReportMention';
        reportID: UUID;
        reportName: string;
        triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
        project: { __typename?: 'Project'; name: string; id: UUID };
        userReportComment: {
          __typename?: 'UserReportComment';
          commentLineID: string;
          type: UserReportCommentType;
          costs: Array<USCents>;
          comment: {
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          };
        };
      }
    | {
        __typename?: 'RemoveFromProject';
        triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
        project: { __typename?: 'Project'; name: string; id: UUID };
      }
    | {
        __typename?: 'ShareDraftItem';
        triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
        project: { __typename?: 'Project'; name: string; id: UUID };
        item: {
          __typename?: 'ItemLink';
          id: UUID;
          name: string;
          number: string;
          visibility?: Visibility | null;
        };
      }
    | {
        __typename?: 'ShareScenario';
        triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
        project: { __typename?: 'Project'; name: string; id: UUID };
        scenario: { __typename?: 'ScenarioLink'; id: UUID; name: string };
      };
};

export type CommonInAppNotificationsFieldsFragment = {
  __typename?: 'InAppNotifications';
  inAppNotifications?: Array<{
    __typename?: 'InAppNotification';
    id: UUID;
    notificationType: NotificationType;
    projectID: UUID;
    createdAt: Time;
    readAt?: Time | null;
    content:
      | {
          __typename?: 'AssignItem';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          item: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
        }
      | {
          __typename?: 'AssignProjectLead';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
        }
      | {
          __typename?: 'InviteToProject';
          message?: string | null;
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
        }
      | {
          __typename?: 'NewComment';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          item: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
          comment: {
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          };
        }
      | {
          __typename?: 'NewItem';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          item: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
        }
      | {
          __typename?: 'NewMention';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          item: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
          comment: {
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          };
        }
      | {
          __typename?: 'NewOption';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          parentItem: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
          option: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
        }
      | {
          __typename?: 'NewReportMention';
          reportID: UUID;
          reportName: string;
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          userReportComment: {
            __typename?: 'UserReportComment';
            commentLineID: string;
            type: UserReportCommentType;
            costs: Array<USCents>;
            comment: {
              __typename?: 'Comment';
              id: UUID;
              created: Time;
              author: {
                __typename?: 'User';
                status: UserStatus;
                email: string;
                id: UUID;
                jobTitle: string;
                name: string;
                phone?: string | null;
                picture: string;
                pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
              };
              text: {
                __typename?: 'MentionableText';
                contents: string;
                mentions?: Array<{
                  __typename?: 'Mention';
                  id: UUID;
                  position: number;
                  text: string;
                  user: { __typename?: 'User'; id: UUID };
                }> | null;
                itemReferences?: Array<{
                  __typename?: 'ItemReference';
                  id: UUID;
                  position: number;
                  text: string;
                  referencedItem?: {
                    __typename?: 'ItemsListItem';
                    createdAt: Time;
                    currentStatus: Status;
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    updateTime: Time;
                    visibility: Visibility;
                    creator: {
                      __typename?: 'UserLink';
                      id: UUID;
                      name: string;
                      status: UserStatus;
                    };
                    currentCost:
                      | { __typename?: 'CostRange'; min: USCents; max: USCents }
                      | { __typename?: 'CostScalar'; value: USCents };
                    currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                    options: Array<{
                      __typename?: 'ItemLink';
                      id: UUID;
                      name: string;
                      number: string;
                      status: Status;
                      visibility?: Visibility | null;
                    }>;
                  } | null;
                }> | null;
              };
            };
          };
        }
      | {
          __typename?: 'RemoveFromProject';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
        }
      | {
          __typename?: 'ShareDraftItem';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          item: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
        }
      | {
          __typename?: 'ShareScenario';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          scenario: { __typename?: 'ScenarioLink'; id: UUID; name: string };
        };
  }> | null;
  paginationData: { __typename?: 'PaginationData'; nextOffsetID?: UUID | null };
};

export type CommonMilestoneFieldsFragment = {
  __typename?: 'Milestone';
  id: UUID;
  name: string;
  description: string;
  descriptionStyled: string;
  deletable: boolean;
  date?: Time | null;
  isDraft: boolean;
  itemsCount: number;
  draftItemsCount: number;
  activeEstimateID?: UUID | null;
  activeEstimateDraftID?: UUID | null;
  budgetID?: UUID | null;
  budgetDraftID?: UUID | null;
  createdBy?: { __typename?: 'User'; id: UUID } | null;
  designPhase?: {
    __typename?: 'DesignPhaseType';
    id: UUID;
    name: string;
    abbreviation: string;
  } | null;
  timelinePhase?: {
    __typename?: 'TimelineActivity';
    id: UUID;
    name: string;
    startDate: Time;
    endDate?: Time | null;
  } | null;
  buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string; itemsCount: number }> | null;
  quantities?: Array<{
    __typename?: 'Quantity';
    id: UUID;
    magnitude: Numeric;
    estimateID: UUID;
    hasBreakdown: boolean;
    unit: {
      __typename?: 'Unit';
      abbreviationPlural: string;
      abbreviationSingular: string;
      description?: string | null;
      id: UUID;
      isDefault: boolean;
      isEnabled: boolean;
      name: string;
      type: UnitType;
    };
  } | null> | null;
};

export type CommonProjectCompsSetFieldsFragment = {
  __typename?: 'ProjectCompsSet';
  input: {
    __typename?: 'ProjectCompsSetInputReturn';
    addedMarkupNames: Array<string>;
    markupMode: MarkupMode;
    pinnedUnitID?: UUID | null;
    selectedUnitIDs: Array<UUID>;
    excludedMarkupNames: Array<string>;
    averageInput?: {
      __typename?: 'AverageCompInputReturn';
      name?: string | null;
      description?: string | null;
      color?: string | null;
      isHidden?: boolean | null;
      thumbnailAssetID?: UUID | null;
      location?: string | null;
      lat?: number | null;
      lon?: number | null;
      metrics: Array<{
        __typename?: 'MetricsInputReturn';
        unitID: UUID;
        quantityMagnitude?: string | null;
        hasMilestoneQuantity: boolean;
      }>;
      categoryLineInputs: Array<{
        __typename?: 'CategoryLineInputReturn';
        description?: string | null;
        editedValue: USCents;
        cellType: ProjectCompsCostTableCellType;
        unitID?: UUID | null;
        category: { __typename?: 'Category'; name: string; number: string };
      }>;
      markupLineInputs: Array<{
        __typename?: 'MarkupLineInputReturn';
        markupName: string;
        description?: string | null;
        editedValue: USCents;
        cellType: ProjectCompsCostTableCellType;
        unitID?: UUID | null;
      }>;
    } | null;
    costTableColumnInputs?: Array<{
      __typename?: 'CostTableColumnInputReturn';
      key: CostTableColumnInputKey;
      unitID?: UUID | null;
    }> | null;
    projectCompInputs: Array<{
      __typename?: 'ProjectCompInputReturn';
      id: UUID;
      projectID: UUID;
      name?: string | null;
      description?: string | null;
      categorizationID?: UUID | null;
      categorizationLevel?: number | null;
      isExcluded?: boolean | null;
      milestoneID?: UUID | null;
      escalation?: {
        __typename?: 'ProjectCompEscalation';
        time?: string | null;
        timeFuture?: string | null;
        location?: string | null;
        timeMeta?: {
          __typename?: 'ProjectCompEscalationMeta';
          isAutoEscalated: boolean;
          isFuzzyMatch: boolean;
          sourceLabel: string;
          sourceIsFuzzyMatch: boolean;
          targetLabel: string;
          targetIsFuzzyMatch: boolean;
        } | null;
        locationMeta?: {
          __typename?: 'ProjectCompEscalationMeta';
          isAutoEscalated: boolean;
          isFuzzyMatch: boolean;
          sourceLabel: string;
          sourceIsFuzzyMatch: boolean;
          targetLabel: string;
          targetIsFuzzyMatch: boolean;
        } | null;
      } | null;
      metrics: Array<{
        __typename?: 'MetricsInputReturn';
        unitID: UUID;
        quantityMagnitude?: string | null;
        hasMilestoneQuantity: boolean;
      }>;
      categoryLineInputs: Array<{
        __typename?: 'CategoryLineInputReturn';
        description?: string | null;
        editedValue: USCents;
        cellType: ProjectCompsCostTableCellType;
        unitID?: UUID | null;
        category: { __typename?: 'Category'; name: string; number: string };
      }>;
      markupLineInputs: Array<{
        __typename?: 'MarkupLineInputReturn';
        markupName: string;
        description?: string | null;
        editedValue: USCents;
        cellType: ProjectCompsCostTableCellType;
        unitID?: UUID | null;
      }>;
      viewFilter?: {
        __typename?: 'ViewFilterReturn';
        categories?: Array<{
          __typename?: 'CategoryInputReturn';
          categorizationID: UUID;
          id: UUID;
        }> | null;
      } | null;
    }>;
    excludedCategoryContents: Array<{
      __typename?: 'CategoryContent';
      number: string;
      name: string;
    }>;
  };
  averageComp?: {
    __typename?: 'AverageComp';
    isHidden: boolean;
    isResettable: boolean;
    input: {
      __typename?: 'AverageCompInputReturn';
      name?: string | null;
      description?: string | null;
      color?: string | null;
      isHidden?: boolean | null;
      thumbnailAssetID?: UUID | null;
      location?: string | null;
      lat?: number | null;
      lon?: number | null;
      metrics: Array<{
        __typename?: 'MetricsInputReturn';
        unitID: UUID;
        quantityMagnitude?: string | null;
        hasMilestoneQuantity: boolean;
      }>;
      categoryLineInputs: Array<{
        __typename?: 'CategoryLineInputReturn';
        description?: string | null;
        editedValue: USCents;
        cellType: ProjectCompsCostTableCellType;
        unitID?: UUID | null;
        category: { __typename?: 'Category'; name: string; number: string };
      }>;
      markupLineInputs: Array<{
        __typename?: 'MarkupLineInputReturn';
        markupName: string;
        description?: string | null;
        editedValue: USCents;
        cellType: ProjectCompsCostTableCellType;
        unitID?: UUID | null;
      }>;
    };
    projectCompsCostTable: {
      __typename?: 'ProjectCompsCostTable';
      categoryLines: Array<{
        __typename?: 'ProjectCompsCostTableCategoryLine';
        description?: string | null;
        category: {
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        };
        pinnedUnitValues: {
          __typename?: 'ProjectCompsCostTableCostValues';
          quantityMagnitude: Numeric;
          percentValue: number;
          totalValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          quantityValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        };
        columnValues: Array<{
          __typename?: 'ProjectCompsCostTableColumnValue';
          valueNumeric: Numeric;
          isEdited: boolean;
          isMaxValue: boolean;
          isMinValue: boolean;
          hasError: boolean;
          costTableColumnInput: {
            __typename?: 'CostTableColumnInputReturn';
            key: CostTableColumnInputKey;
            unitID?: UUID | null;
          };
        }>;
      }>;
      markupLines: Array<{
        __typename?: 'ProjectCompsCostTableMarkupLine';
        markupName: string;
        description?: string | null;
        pinnedUnitValues: {
          __typename?: 'ProjectCompsCostTableCostValues';
          quantityMagnitude: Numeric;
          percentValue: number;
          totalValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          quantityValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        };
        columnValues: Array<{
          __typename?: 'ProjectCompsCostTableColumnValue';
          valueNumeric: Numeric;
          isEdited: boolean;
          isMaxValue: boolean;
          isMinValue: boolean;
          hasError: boolean;
          costTableColumnInput: {
            __typename?: 'CostTableColumnInputReturn';
            key: CostTableColumnInputKey;
            unitID?: UUID | null;
          };
        }>;
      }>;
      summaryLines: {
        __typename?: 'ProjectCompsCostTableSummaryLines';
        subtotalLine?: {
          __typename?: 'ProjectCompsCostTableSummaryLine';
          pinnedUnitValues: {
            __typename?: 'ProjectCompsCostTableCostValues';
            quantityMagnitude: Numeric;
            percentValue: number;
            totalValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            quantityValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          };
          columnValues: Array<{
            __typename?: 'ProjectCompsCostTableColumnValue';
            valueNumeric: Numeric;
            isEdited: boolean;
            isMaxValue: boolean;
            isMinValue: boolean;
            hasError: boolean;
            costTableColumnInput: {
              __typename?: 'CostTableColumnInputReturn';
              key: CostTableColumnInputKey;
              unitID?: UUID | null;
            };
          }>;
        } | null;
        totalLine: {
          __typename?: 'ProjectCompsCostTableSummaryLine';
          pinnedUnitValues: {
            __typename?: 'ProjectCompsCostTableCostValues';
            quantityMagnitude: Numeric;
            percentValue: number;
            totalValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            quantityValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          };
          columnValues: Array<{
            __typename?: 'ProjectCompsCostTableColumnValue';
            valueNumeric: Numeric;
            isEdited: boolean;
            isMaxValue: boolean;
            isMinValue: boolean;
            hasError: boolean;
            costTableColumnInput: {
              __typename?: 'CostTableColumnInputReturn';
              key: CostTableColumnInputKey;
              unitID?: UUID | null;
            };
          }>;
        };
        markupsTotalLine?: {
          __typename?: 'ProjectCompsCostTableSummaryLine';
          pinnedUnitValues: {
            __typename?: 'ProjectCompsCostTableCostValues';
            quantityMagnitude: Numeric;
            percentValue: number;
            totalValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            quantityValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          };
          columnValues: Array<{
            __typename?: 'ProjectCompsCostTableColumnValue';
            valueNumeric: Numeric;
            isEdited: boolean;
            isMaxValue: boolean;
            isMinValue: boolean;
            hasError: boolean;
            costTableColumnInput: {
              __typename?: 'CostTableColumnInputReturn';
              key: CostTableColumnInputKey;
              unitID?: UUID | null;
            };
          }>;
        } | null;
      };
    };
  } | null;
  projectComps: Array<{
    __typename?: 'ProjectComp';
    isResettable: boolean;
    input: {
      __typename?: 'ProjectCompInputReturn';
      id: UUID;
      projectID: UUID;
      name?: string | null;
      description?: string | null;
      categorizationID?: UUID | null;
      categorizationLevel?: number | null;
      isExcluded?: boolean | null;
      milestoneID?: UUID | null;
      escalation?: {
        __typename?: 'ProjectCompEscalation';
        time?: string | null;
        timeFuture?: string | null;
        location?: string | null;
        timeMeta?: {
          __typename?: 'ProjectCompEscalationMeta';
          isAutoEscalated: boolean;
          isFuzzyMatch: boolean;
          sourceLabel: string;
          sourceIsFuzzyMatch: boolean;
          targetLabel: string;
          targetIsFuzzyMatch: boolean;
        } | null;
        locationMeta?: {
          __typename?: 'ProjectCompEscalationMeta';
          isAutoEscalated: boolean;
          isFuzzyMatch: boolean;
          sourceLabel: string;
          sourceIsFuzzyMatch: boolean;
          targetLabel: string;
          targetIsFuzzyMatch: boolean;
        } | null;
      } | null;
      metrics: Array<{
        __typename?: 'MetricsInputReturn';
        unitID: UUID;
        quantityMagnitude?: string | null;
        hasMilestoneQuantity: boolean;
      }>;
      categoryLineInputs: Array<{
        __typename?: 'CategoryLineInputReturn';
        description?: string | null;
        editedValue: USCents;
        cellType: ProjectCompsCostTableCellType;
        unitID?: UUID | null;
        category: { __typename?: 'Category'; name: string; number: string };
      }>;
      markupLineInputs: Array<{
        __typename?: 'MarkupLineInputReturn';
        markupName: string;
        description?: string | null;
        editedValue: USCents;
        cellType: ProjectCompsCostTableCellType;
        unitID?: UUID | null;
      }>;
      viewFilter?: {
        __typename?: 'ViewFilterReturn';
        categories?: Array<{
          __typename?: 'CategoryInputReturn';
          categorizationID: UUID;
          id: UUID;
        }> | null;
      } | null;
    };
    milestone: { __typename?: 'Milestone'; id: UUID; date?: Time | null; name: string };
    project: {
      __typename?: 'Project';
      id: UUID;
      name: string;
      hasAccess: boolean;
      location: string;
      lat?: number | null;
      lon?: number | null;
      thumbnail: string;
      status: { __typename?: 'ProjectStatus'; name: string };
      milestones: Array<{ __typename?: 'Milestone'; id: UUID; date?: Time | null; name: string }>;
      activeMilestone: { __typename?: 'Milestone'; id: UUID };
      type: { __typename?: 'ProjectType'; name: string };
    };
    projectCompsCostTable: {
      __typename?: 'ProjectCompsCostTable';
      categoryLines: Array<{
        __typename?: 'ProjectCompsCostTableCategoryLine';
        description?: string | null;
        category: {
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        };
        pinnedUnitValues: {
          __typename?: 'ProjectCompsCostTableCostValues';
          quantityMagnitude: Numeric;
          percentValue: number;
          totalValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          quantityValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        };
        columnValues: Array<{
          __typename?: 'ProjectCompsCostTableColumnValue';
          valueNumeric: Numeric;
          isEdited: boolean;
          isMaxValue: boolean;
          isMinValue: boolean;
          hasError: boolean;
          costTableColumnInput: {
            __typename?: 'CostTableColumnInputReturn';
            key: CostTableColumnInputKey;
            unitID?: UUID | null;
          };
        }>;
      }>;
      markupLines: Array<{
        __typename?: 'ProjectCompsCostTableMarkupLine';
        markupName: string;
        description?: string | null;
        pinnedUnitValues: {
          __typename?: 'ProjectCompsCostTableCostValues';
          quantityMagnitude: Numeric;
          percentValue: number;
          totalValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          quantityValue:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        };
        columnValues: Array<{
          __typename?: 'ProjectCompsCostTableColumnValue';
          valueNumeric: Numeric;
          isEdited: boolean;
          isMaxValue: boolean;
          isMinValue: boolean;
          hasError: boolean;
          costTableColumnInput: {
            __typename?: 'CostTableColumnInputReturn';
            key: CostTableColumnInputKey;
            unitID?: UUID | null;
          };
        }>;
      }>;
      summaryLines: {
        __typename?: 'ProjectCompsCostTableSummaryLines';
        subtotalLine?: {
          __typename?: 'ProjectCompsCostTableSummaryLine';
          pinnedUnitValues: {
            __typename?: 'ProjectCompsCostTableCostValues';
            quantityMagnitude: Numeric;
            percentValue: number;
            totalValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            quantityValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          };
          columnValues: Array<{
            __typename?: 'ProjectCompsCostTableColumnValue';
            valueNumeric: Numeric;
            isEdited: boolean;
            isMaxValue: boolean;
            isMinValue: boolean;
            hasError: boolean;
            costTableColumnInput: {
              __typename?: 'CostTableColumnInputReturn';
              key: CostTableColumnInputKey;
              unitID?: UUID | null;
            };
          }>;
        } | null;
        totalLine: {
          __typename?: 'ProjectCompsCostTableSummaryLine';
          pinnedUnitValues: {
            __typename?: 'ProjectCompsCostTableCostValues';
            quantityMagnitude: Numeric;
            percentValue: number;
            totalValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            quantityValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          };
          columnValues: Array<{
            __typename?: 'ProjectCompsCostTableColumnValue';
            valueNumeric: Numeric;
            isEdited: boolean;
            isMaxValue: boolean;
            isMinValue: boolean;
            hasError: boolean;
            costTableColumnInput: {
              __typename?: 'CostTableColumnInputReturn';
              key: CostTableColumnInputKey;
              unitID?: UUID | null;
            };
          }>;
        };
        markupsTotalLine?: {
          __typename?: 'ProjectCompsCostTableSummaryLine';
          pinnedUnitValues: {
            __typename?: 'ProjectCompsCostTableCostValues';
            quantityMagnitude: Numeric;
            percentValue: number;
            totalValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            quantityValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          };
          columnValues: Array<{
            __typename?: 'ProjectCompsCostTableColumnValue';
            valueNumeric: Numeric;
            isEdited: boolean;
            isMaxValue: boolean;
            isMinValue: boolean;
            hasError: boolean;
            costTableColumnInput: {
              __typename?: 'CostTableColumnInputReturn';
              key: CostTableColumnInputKey;
              unitID?: UUID | null;
            };
          }>;
        } | null;
      };
    };
  }>;
  categories: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    level: number;
    error?: string | null;
    categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
  }>;
  units: Array<{
    __typename?: 'Unit';
    abbreviationPlural: string;
    abbreviationSingular: string;
    description?: string | null;
    id: UUID;
    isDefault: boolean;
    isEnabled: boolean;
    name: string;
    type: UnitType;
  }>;
  selectedUnits: Array<{
    __typename?: 'Unit';
    abbreviationPlural: string;
    abbreviationSingular: string;
    description?: string | null;
    id: UUID;
    isDefault: boolean;
    isEnabled: boolean;
    name: string;
    type: UnitType;
  }>;
  unitCounts: Array<{ __typename?: 'UnitCount'; unitID: UUID; countText: string }>;
  markups: Array<{ __typename?: 'PCMarkup'; name: string; isCustom: boolean }>;
};

export type EstimateTotalTypeConflictInfoFieldsFragment = {
  __typename?: 'EstimateTotalTypeConflictInfo';
  hasConflict: boolean;
  mostDetailedEstimateTotalType?: EstimateTotalType | null;
};

export type ProjectComparisonReportFieldsFragment = {
  __typename?: 'ProjectComparisonReport';
  id: UUID;
  name: string;
  description: string;
  createdAt: Time;
  updatedAt: Time;
  attachedProject?: {
    __typename?: 'Project';
    id: UUID;
    name: string;
    location: string;
    hasAccess: boolean;
    type: { __typename?: 'ProjectType'; id: UUID; name: string };
  } | null;
  createdBy: { __typename?: 'User'; id: UUID; name: string; email: string };
  updatedBy: { __typename?: 'User'; id: UUID; name: string; email: string };
  metadata: {
    __typename?: 'ProjectComparisonReportMetadata';
    averageQuantityCost?: USCents | null;
    averageTotalCost?: USCents | null;
    pinnedUnitQuantityMagnitude?: Numeric | null;
    projectsCount: number;
    pinnedUnit: {
      __typename?: 'Unit';
      abbreviationPlural: string;
      abbreviationSingular: string;
      description?: string | null;
      id: UUID;
      isDefault: boolean;
      isEnabled: boolean;
      name: string;
      type: UnitType;
    };
  };
};

export type ProcoreChangeEventFieldsFragment = {
  __typename?: 'ProcoreChangeEvent';
  integrationObjectID: string;
  number: number;
  alphanumericNumber: string;
  title: string;
  description: string;
  status: string;
  eventScope: string;
  eventType: string;
  createdAt: string;
  updatedAt: string;
  changeReason: string;
  cost: USCents;
  isDeleted: boolean;
};

export type AutodeskPotentialChangeOrderFieldsFragment = {
  __typename?: 'AutodeskPotentialChangeOrder';
  integrationObjectID: string;
  number: number;
  title: string;
  description: string;
  eventType: string;
  createdAt: string;
  updatedAt: string;
  cost: USCents;
};

export type OrganizationFieldsFragment = {
  __typename?: 'Org';
  id: UUID;
  isDraft: boolean;
  name: string;
  levels: Array<string>;
  nodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string; parentID?: UUID | null }>;
};

export type SetDisabledProjectCategorizationMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  categorizationID: Scalars['UUID']['input'];
  disabled: Scalars['Boolean']['input'];
}>;

export type SetDisabledProjectCategorizationMutation = {
  __typename?: 'Mutation';
  setDisabledProjectCategorization: {
    __typename?: 'ProjectCategorization';
    projectID: UUID;
    disabled: boolean;
    categorization: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      description: string;
      levels: number;
      builtin: boolean;
    };
  };
};

export type ReorderCategorizationMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  categorizationID: Scalars['UUID']['input'];
  ordering: Scalars['Int']['input'];
}>;

export type ReorderCategorizationMutation = {
  __typename?: 'Mutation';
  reorderCategorization?: boolean | null;
};

export type UsersByIDsQueryVariables = Exact<{
  IDs: Array<Scalars['UUID']['input']>;
}>;

export type UsersByIDsQuery = {
  __typename?: 'Query';
  usersByIDs: Array<{ __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus } | null>;
};

export type AssigneeByIdQueryVariables = Exact<{
  ID: Scalars['UUID']['input'];
}>;

export type AssigneeByIdQuery = {
  __typename?: 'Query';
  userByID?: {
    __typename?: 'UserLink';
    id: UUID;
    name: string;
    picture: string;
    status: UserStatus;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
};

export type UserByIdQueryVariables = Exact<{
  ID: Scalars['UUID']['input'];
}>;

export type UserByIdQuery = {
  __typename?: 'Query';
  userByID?: { __typename?: 'UserLink'; id: UUID; name: string; email: string } | null;
};

export type GetProjectNameQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type GetProjectNameQuery = {
  __typename?: 'Query';
  project?: { __typename?: 'Project'; id: UUID; name: string } | null;
};

export type LoadCollaboratorsQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
}>;

export type LoadCollaboratorsQuery = {
  __typename?: 'Query';
  collaborators: Array<{
    __typename?: 'Collaborator';
    allTrades: boolean;
    id: UUID;
    isProjectLead: boolean;
    responsibility?: string | null;
    role: {
      __typename?: 'Role';
      id: UUID;
      name: string;
      type: ProjectRoleType;
      hasTrade: boolean;
      permissionGroups: Array<{
        __typename?: 'PermissionGroup';
        type: PermissionGroupType;
        permissions: Array<{
          __typename?: 'Permission';
          id: UUID;
          level: PermissionLevel;
          resource: PermissionResource;
          actions: {
            __typename?: 'Actions';
            view: boolean;
            add: boolean;
            delete: boolean;
            edit: boolean;
          };
        }>;
      }>;
    };
    trades: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
      categorization?: {
        __typename?: 'Categorization';
        draft: boolean;
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
      } | null;
    }>;
    user: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
  }>;
};

export type LoadCollaboratorsMultipleProjectsQueryVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
}>;

export type LoadCollaboratorsMultipleProjectsQuery = {
  __typename?: 'Query';
  loadCollaboratorsMultipleProjects: Array<{
    __typename?: 'ProjectCollaborators';
    projectID: UUID;
    collaborators: Array<{
      __typename?: 'Collaborator';
      allTrades: boolean;
      id: UUID;
      isProjectLead: boolean;
      responsibility?: string | null;
      role: {
        __typename?: 'Role';
        id: UUID;
        name: string;
        type: ProjectRoleType;
        hasTrade: boolean;
        permissionGroups: Array<{
          __typename?: 'PermissionGroup';
          type: PermissionGroupType;
          permissions: Array<{
            __typename?: 'Permission';
            id: UUID;
            level: PermissionLevel;
            resource: PermissionResource;
            actions: {
              __typename?: 'Actions';
              view: boolean;
              add: boolean;
              delete: boolean;
              edit: boolean;
            };
          }>;
        }>;
      };
      trades: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
        categorization?: {
          __typename?: 'Categorization';
          draft: boolean;
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
        } | null;
      }>;
      user: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
    }>;
  }>;
};

export type CreateCollaboratorsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  collaborators: Array<CollaboratorInput>;
  message?: InputMaybe<Scalars['String']['input']>;
  disableNotifications?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CreateCollaboratorsMutation = {
  __typename?: 'Mutation';
  addCollaborators: Array<{
    __typename?: 'Collaborator';
    allTrades: boolean;
    id: UUID;
    isProjectLead: boolean;
    responsibility?: string | null;
    role: {
      __typename?: 'Role';
      id: UUID;
      name: string;
      type: ProjectRoleType;
      hasTrade: boolean;
      permissionGroups: Array<{
        __typename?: 'PermissionGroup';
        type: PermissionGroupType;
        permissions: Array<{
          __typename?: 'Permission';
          id: UUID;
          level: PermissionLevel;
          resource: PermissionResource;
          actions: {
            __typename?: 'Actions';
            view: boolean;
            add: boolean;
            delete: boolean;
            edit: boolean;
          };
        }>;
      }>;
    };
    trades: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
      categorization?: {
        __typename?: 'Categorization';
        draft: boolean;
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
      } | null;
    }>;
    user: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
  }>;
};

export type UpdateCollaboratorAllTradesMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  collaboratorId: Scalars['UUID']['input'];
  tradeSetting: Scalars['Boolean']['input'];
}>;

export type UpdateCollaboratorAllTradesMutation = {
  __typename?: 'Mutation';
  setCollaboratorAllTrades: {
    __typename?: 'Collaborator';
    allTrades: boolean;
    id: UUID;
    isProjectLead: boolean;
    responsibility?: string | null;
    role: {
      __typename?: 'Role';
      id: UUID;
      name: string;
      type: ProjectRoleType;
      hasTrade: boolean;
      permissionGroups: Array<{
        __typename?: 'PermissionGroup';
        type: PermissionGroupType;
        permissions: Array<{
          __typename?: 'Permission';
          id: UUID;
          level: PermissionLevel;
          resource: PermissionResource;
          actions: {
            __typename?: 'Actions';
            view: boolean;
            add: boolean;
            delete: boolean;
            edit: boolean;
          };
        }>;
      }>;
    };
    trades: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
      categorization?: {
        __typename?: 'Categorization';
        draft: boolean;
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
      } | null;
    }>;
    user: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
  };
};

export type UpdateCollaboratorRoleMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  collaboratorID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
  tradeIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
}>;

export type UpdateCollaboratorRoleMutation = {
  __typename?: 'Mutation';
  setCollaboratorRole: {
    __typename?: 'Collaborator';
    allTrades: boolean;
    id: UUID;
    isProjectLead: boolean;
    responsibility?: string | null;
    role: {
      __typename?: 'Role';
      id: UUID;
      name: string;
      type: ProjectRoleType;
      hasTrade: boolean;
      permissionGroups: Array<{
        __typename?: 'PermissionGroup';
        type: PermissionGroupType;
        permissions: Array<{
          __typename?: 'Permission';
          id: UUID;
          level: PermissionLevel;
          resource: PermissionResource;
          actions: {
            __typename?: 'Actions';
            view: boolean;
            add: boolean;
            delete: boolean;
            edit: boolean;
          };
        }>;
      }>;
    };
    trades: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
      categorization?: {
        __typename?: 'Categorization';
        draft: boolean;
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
      } | null;
    }>;
    user: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
  };
};

export type UpdateCollaboratorResponsibilityMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  collaboratorID: Scalars['UUID']['input'];
  responsibility: Scalars['String']['input'];
}>;

export type UpdateCollaboratorResponsibilityMutation = {
  __typename?: 'Mutation';
  setCollaboratorResponsibility: {
    __typename?: 'Collaborator';
    allTrades: boolean;
    id: UUID;
    isProjectLead: boolean;
    responsibility?: string | null;
    role: {
      __typename?: 'Role';
      id: UUID;
      name: string;
      type: ProjectRoleType;
      hasTrade: boolean;
      permissionGroups: Array<{
        __typename?: 'PermissionGroup';
        type: PermissionGroupType;
        permissions: Array<{
          __typename?: 'Permission';
          id: UUID;
          level: PermissionLevel;
          resource: PermissionResource;
          actions: {
            __typename?: 'Actions';
            view: boolean;
            add: boolean;
            delete: boolean;
            edit: boolean;
          };
        }>;
      }>;
    };
    trades: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
      categorization?: {
        __typename?: 'Categorization';
        draft: boolean;
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
      } | null;
    }>;
    user: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
  };
};

export type SetCollaboratorTradesMutationVariables = Exact<{
  allTrades: Scalars['Boolean']['input'];
  categoryIDs: Array<Scalars['UUID']['input']>;
  collaboratorID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
}>;

export type SetCollaboratorTradesMutation = {
  __typename?: 'Mutation';
  setCollaboratorTrades: boolean;
};

export type DeleteCollaboratorMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  collaboratorId: Scalars['UUID']['input'];
}>;

export type DeleteCollaboratorMutation = { __typename?: 'Mutation'; removeCollaborator: boolean };

export type AddCollaboratorTradesMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  collaboratorID: Scalars['UUID']['input'];
  categoryIDs: Array<Scalars['UUID']['input']>;
}>;

export type AddCollaboratorTradesMutation = {
  __typename?: 'Mutation';
  addTrades: {
    __typename?: 'Collaborator';
    allTrades: boolean;
    id: UUID;
    isProjectLead: boolean;
    responsibility?: string | null;
    role: {
      __typename?: 'Role';
      id: UUID;
      name: string;
      type: ProjectRoleType;
      hasTrade: boolean;
      permissionGroups: Array<{
        __typename?: 'PermissionGroup';
        type: PermissionGroupType;
        permissions: Array<{
          __typename?: 'Permission';
          id: UUID;
          level: PermissionLevel;
          resource: PermissionResource;
          actions: {
            __typename?: 'Actions';
            view: boolean;
            add: boolean;
            delete: boolean;
            edit: boolean;
          };
        }>;
      }>;
    };
    trades: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
      categorization?: {
        __typename?: 'Categorization';
        draft: boolean;
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
      } | null;
    }>;
    user: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
  };
};

export type RemoveCollaboratorTradesMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  collaboratorID: Scalars['UUID']['input'];
  categoryIDs: Array<Scalars['UUID']['input']>;
}>;

export type RemoveCollaboratorTradesMutation = {
  __typename?: 'Mutation';
  removeTrades: {
    __typename?: 'Collaborator';
    allTrades: boolean;
    id: UUID;
    isProjectLead: boolean;
    responsibility?: string | null;
    role: {
      __typename?: 'Role';
      id: UUID;
      name: string;
      type: ProjectRoleType;
      hasTrade: boolean;
      permissionGroups: Array<{
        __typename?: 'PermissionGroup';
        type: PermissionGroupType;
        permissions: Array<{
          __typename?: 'Permission';
          id: UUID;
          level: PermissionLevel;
          resource: PermissionResource;
          actions: {
            __typename?: 'Actions';
            view: boolean;
            add: boolean;
            delete: boolean;
            edit: boolean;
          };
        }>;
      }>;
    };
    trades: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
      categorization?: {
        __typename?: 'Categorization';
        draft: boolean;
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
      } | null;
    }>;
    user: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
  };
};

export type SetProjectLeadMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  userID: Scalars['UUID']['input'];
}>;

export type SetProjectLeadMutation = {
  __typename?: 'Mutation';
  setProjectLead: {
    __typename?: 'SetProjectLeadPayload';
    updated: Array<{ __typename?: 'Collaborator'; id: UUID; isProjectLead: boolean }>;
  };
};

export type SetProjectsLeadMutationVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
  userID: Scalars['UUID']['input'];
}>;

export type SetProjectsLeadMutation = {
  __typename?: 'Mutation';
  setProjectsLead: {
    __typename?: 'SetProjectLeadPayload';
    updated: Array<{ __typename?: 'Collaborator'; id: UUID; isProjectLead: boolean }>;
  };
};

export type RemoveProjectLeadMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  userID: Scalars['UUID']['input'];
}>;

export type RemoveProjectLeadMutation = {
  __typename?: 'Mutation';
  removeProjectLead: {
    __typename?: 'RemoveProjectLeadPayload';
    updated?: { __typename?: 'Collaborator'; id: UUID; isProjectLead: boolean } | null;
  };
};

export type RemoveProjectsLeadMutationVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
}>;

export type RemoveProjectsLeadMutation = {
  __typename?: 'Mutation';
  removeProjectsLead: {
    __typename?: 'RemoveProjectsLeadPayload';
    updated: Array<{ __typename?: 'Collaborator'; id: UUID; isProjectLead: boolean }>;
  };
};

export type CreateItemCommentMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  comment?: InputMaybe<ItemCommentInput>;
  costMode?: InputMaybe<CostMode>;
}>;

export type CreateItemCommentMutation = {
  __typename?: 'Mutation';
  commentOnItem: Array<{
    __typename?: 'Comment';
    id: UUID;
    created: Time;
    author: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    text: {
      __typename?: 'MentionableText';
      contents: string;
      mentions?: Array<{
        __typename?: 'Mention';
        id: UUID;
        position: number;
        text: string;
        user: { __typename?: 'User'; id: UUID };
      }> | null;
      itemReferences?: Array<{
        __typename?: 'ItemReference';
        id: UUID;
        position: number;
        text: string;
        referencedItem?: {
          __typename?: 'ItemsListItem';
          createdAt: Time;
          currentStatus: Status;
          id: UUID;
          name: string;
          number: string;
          status: Status;
          updateTime: Time;
          visibility: Visibility;
          creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
          currentCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
          options: Array<{
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            status: Status;
            visibility?: Visibility | null;
          }>;
        } | null;
      }> | null;
    };
  }>;
};

export type CreateCompanyCategorizationMutationVariables = Exact<{
  companyID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateCompanyCategorizationMutation = {
  __typename?: 'Mutation';
  createCompanyCategorization: {
    __typename?: 'Categorization';
    id: UUID;
    name: string;
    description: string;
    levels: number;
    builtin: boolean;
  };
};

export type CompanyCategorizationsQueryVariables = Exact<{
  companyID: Scalars['UUID']['input'];
}>;

export type CompanyCategorizationsQuery = {
  __typename?: 'Query';
  companyCategorizations: Array<{
    __typename?: 'CompanyCategorization';
    companyID: UUID;
    disabled?: boolean | null;
    categorization: {
      __typename?: 'Categorization';
      modifiedAt?: Time | null;
      id: UUID;
      name: string;
      description: string;
      levels: number;
      builtin: boolean;
      createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      modifiedBy?: { __typename?: 'User'; id: UUID; name: string } | null;
      importMetadata?: {
        __typename?: 'CategorizationImportMetadata';
        columnLevel1?: string | null;
        columnLevel2?: string | null;
        columnLevel3?: string | null;
        columnLevel4?: string | null;
      } | null;
    };
  }>;
};

export type DeleteCompanyCategorizationMutationVariables = Exact<{
  categorizationID: Scalars['UUID']['input'];
}>;

export type DeleteCompanyCategorizationMutation = {
  __typename?: 'Mutation';
  deleteCompanyCategorization?: UUID | null;
};

export type ToggleBuiltInCategorizationMutationVariables = Exact<{
  categorizationID: Scalars['UUID']['input'];
  disabled: Scalars['Boolean']['input'];
}>;

export type ToggleBuiltInCategorizationMutation = {
  __typename?: 'Mutation';
  toggleBuiltInCategorization?: boolean | null;
};

export type AddCompanyCategorizationsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  categorizationIDs: Array<Scalars['UUID']['input']>;
}>;

export type AddCompanyCategorizationsMutation = {
  __typename?: 'Mutation';
  addCompanyCategorizationsToProject: Array<{
    __typename?: 'ProjectCategorization';
    projectID: UUID;
    disabled: boolean;
    categorization: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      description: string;
      levels: number;
      builtin: boolean;
    };
  }>;
};

export type CreateProjectTemplateMutationVariables = Exact<{
  name: Scalars['String']['input'];
  templateID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type CreateProjectTemplateMutation = {
  __typename?: 'Mutation';
  createProjectTemplate?: { __typename?: 'ProjectTemplate'; id: UUID; name: string } | null;
};

export type DeleteProjectTemplateMutationVariables = Exact<{
  templateID: Scalars['UUID']['input'];
}>;

export type DeleteProjectTemplateMutation = {
  __typename?: 'Mutation';
  deleteProjectTemplate: UUID;
};

export type SetProjectTemplatePublicationMutationVariables = Exact<{
  templateID: Scalars['UUID']['input'];
  published: Scalars['Boolean']['input'];
}>;

export type SetProjectTemplatePublicationMutation = {
  __typename?: 'Mutation';
  setProjectTemplatePublication?: {
    __typename?: 'ProjectTemplate';
    id: UUID;
    isDraft: boolean;
  } | null;
};

export type ProjectTemplateQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type ProjectTemplateQuery = {
  __typename?: 'Query';
  projectTemplate: { __typename?: 'ProjectTemplate'; id: UUID; name: string; isDraft: boolean };
};

export type ProjectTemplatesQueryVariables = Exact<{
  companyID: Scalars['UUID']['input'];
  showDrafts: Scalars['Boolean']['input'];
}>;

export type ProjectTemplatesQuery = {
  __typename?: 'Query';
  projectTemplates: Array<{
    __typename?: 'ProjectTemplate';
    id: UUID;
    name: string;
    isDraft: boolean;
    project: {
      __typename?: 'Project';
      id: UUID;
      name: string;
      code?: string | null;
      thumbnail: string;
      location: string;
      lat?: number | null;
      lon?: number | null;
      activeMilestone: {
        __typename?: 'Milestone';
        id: UUID;
        name: string;
        date?: Time | null;
        designPhase?: {
          __typename?: 'DesignPhaseType';
          id: UUID;
          name: string;
          abbreviation: string;
        } | null;
      };
      status: { __typename?: 'ProjectStatus'; id: UUID };
      type: { __typename?: 'ProjectType'; id: UUID };
      projectDeliveryType?: {
        __typename?: 'ProjectDeliveryType';
        id: UUID;
        name: string;
        abbreviation: string;
      } | null;
      locationDetails?: {
        __typename?: 'LocationDetails';
        name: string;
        lat: number;
        lon: number;
        city: string;
        state: string;
        stateLong: string;
        country: string;
      } | null;
      orgNodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string; parentID?: UUID | null }>;
    };
    settings: Array<{
      __typename?: 'ProjectSetting';
      key: ProjectSettingKeys;
      value: string;
      validValues: Array<string>;
    }>;
  }>;
};

export type UpdateProjectTemplateNameMutationVariables = Exact<{
  templateID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
}>;

export type UpdateProjectTemplateNameMutation = {
  __typename?: 'Mutation';
  updateProjectTemplateName?: { __typename?: 'ProjectTemplate'; id: UUID; name: string } | null;
};

export type CreateProjectTypeMutationVariables = Exact<{
  name: Scalars['String']['input'];
  companyID: Scalars['UUID']['input'];
  parentID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type CreateProjectTypeMutation = {
  __typename?: 'Mutation';
  createProjectType: {
    __typename?: 'ProjectType';
    id: UUID;
    name: string;
    parentID?: UUID | null;
    hidden?: boolean | null;
  };
};

export type UserProjectFieldsFragment = {
  __typename?: 'UserProject';
  id: UUID;
  name: string;
  status: string;
  type: string;
  location: string;
  collaboratorID: UUID;
  roleID: UUID;
  roles: Array<{ __typename?: 'PartialRole'; id: UUID; name: string }>;
};

export type UpdateCompanyMutationVariables = Exact<{
  companyID: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  assetID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type UpdateCompanyMutation = {
  __typename?: 'Mutation';
  updateCompany: {
    __typename?: 'Company';
    id: UUID;
    name: string;
    type: string;
    domain?: string | null;
    assetID?: UUID | null;
  };
};

export type UpdateCompanyUserCompanyRoleMutationVariables = Exact<{
  userID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
}>;

export type UpdateCompanyUserCompanyRoleMutation = {
  __typename?: 'Mutation';
  updateCompanyUserCompanyRole?: {
    __typename?: 'CompanyUser';
    isIndependent: boolean;
    company?: {
      __typename?: 'Company';
      id: UUID;
      name: string;
      type: string;
      domain?: string | null;
      assetID?: UUID | null;
      deactivated: boolean;
    } | null;
    matchedCompany?: {
      __typename?: 'Company';
      id: UUID;
      name: string;
      type: string;
      domain?: string | null;
      assetID?: UUID | null;
    } | null;
    role?: { __typename?: 'PartialRole'; id: UUID; name: string } | null;
    user?: {
      __typename?: 'User';
      email: string;
      id: UUID;
      name: string;
      phone?: string | null;
      picture: string;
      didSetup?: Time | null;
      jobTitle: string;
      status: UserStatus;
      activeAt?: Time | null;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
  } | null;
};

export type UpdateCompanyUserNameMutationVariables = Exact<{
  userID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
}>;

export type UpdateCompanyUserNameMutation = {
  __typename?: 'Mutation';
  updateCompanyUserName?: {
    __typename?: 'User';
    id: UUID;
    name: string;
    phone?: string | null;
    picture: string;
    email: string;
    jobTitle: string;
  } | null;
};

export type UpdateCompanyUserJobTitleMutationVariables = Exact<{
  userID: Scalars['UUID']['input'];
  jobTitle: Scalars['String']['input'];
}>;

export type UpdateCompanyUserJobTitleMutation = {
  __typename?: 'Mutation';
  updateCompanyUserJobTitle?: {
    __typename?: 'User';
    id: UUID;
    name: string;
    phone?: string | null;
    picture: string;
    email: string;
    jobTitle: string;
  } | null;
};

export type UpdateCompanyUserPictureMutationVariables = Exact<{
  userID: Scalars['UUID']['input'];
  picture: Scalars['String']['input'];
}>;

export type UpdateCompanyUserPictureMutation = {
  __typename?: 'Mutation';
  updateCompanyUserPicture?: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
};

export type UpdateCompanyUserStatusMutationVariables = Exact<{
  userID: Scalars['UUID']['input'];
  status: Scalars['UserStatus']['input'];
}>;

export type UpdateCompanyUserStatusMutation = {
  __typename?: 'Mutation';
  updateCompanyUserStatus?: {
    __typename?: 'User';
    id: UUID;
    name: string;
    phone?: string | null;
    picture: string;
    email: string;
    jobTitle: string;
    status: UserStatus;
  } | null;
};

export type CompanyUserDetailsQueryVariables = Exact<{
  userID: Scalars['UUID']['input'];
  sortBy?: InputMaybe<UserProjectSortBy>;
}>;

export type CompanyUserDetailsQuery = {
  __typename?: 'Query';
  companyUserDetails: {
    __typename?: 'CompanyUserDetails';
    user: {
      __typename?: 'User';
      activeAt?: Time | null;
      isEmailVerified: boolean;
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    role?: { __typename?: 'CompanyRole'; id: UUID; name: string; companyID: UUID } | null;
    companyProjects: Array<{
      __typename?: 'UserProject';
      id: UUID;
      name: string;
      status: string;
      type: string;
      location: string;
      collaboratorID: UUID;
      roleID: UUID;
      roles: Array<{ __typename?: 'PartialRole'; id: UUID; name: string }>;
    }>;
    outsideProjects: Array<{
      __typename?: 'UserProject';
      id: UUID;
      name: string;
      status: string;
      type: string;
      location: string;
      collaboratorID: UUID;
      roleID: UUID;
      roles: Array<{ __typename?: 'PartialRole'; id: UUID; name: string }>;
    }>;
  };
};

export type CompanyCollaboratorDetailsQueryVariables = Exact<{
  userID: Scalars['UUID']['input'];
  companyID: Scalars['UUID']['input'];
  sortBy?: InputMaybe<UserProjectSortBy>;
}>;

export type CompanyCollaboratorDetailsQuery = {
  __typename?: 'Query';
  companyCollaboratorDetails: {
    __typename?: 'CompanyUserDetails';
    user: {
      __typename?: 'User';
      activeAt?: Time | null;
      isEmailVerified: boolean;
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    role?: { __typename?: 'CompanyRole'; id: UUID; name: string; companyID: UUID } | null;
    companyProjects: Array<{
      __typename?: 'UserProject';
      id: UUID;
      name: string;
      status: string;
      type: string;
      location: string;
      collaboratorID: UUID;
      roleID: UUID;
      roles: Array<{ __typename?: 'PartialRole'; id: UUID; name: string }>;
    }>;
    outsideProjects: Array<{
      __typename?: 'UserProject';
      id: UUID;
      name: string;
      status: string;
      type: string;
      location: string;
      collaboratorID: UUID;
      roleID: UUID;
      roles: Array<{ __typename?: 'PartialRole'; id: UUID; name: string }>;
    }>;
  };
};

export type CompanyProjectStatsQueryVariables = Exact<{
  companyID: Scalars['UUID']['input'];
  filters: ProjectsFilterBy;
  sortBy: ProjectsSortBy;
}>;

export type CompanyProjectStatsQuery = {
  __typename?: 'Query';
  companyProjectStats: Array<{
    __typename?: 'ProjectStats';
    id: UUID;
    name: string;
    type?: string | null;
    location?: string | null;
    createdAt?: Time | null;
    status?: string | null;
    estimate?: USCents | null;
    runningTotal?: USCents | null;
    budget?: USCents | null;
    milestoneCount: number;
    itemCount: number;
    teamCount: number;
    projectType: { __typename?: 'ProjectType'; id: UUID; name: string };
    createdBy?: {
      __typename?: 'CompanyUser';
      company?: { __typename?: 'Company'; id: UUID } | null;
      user?: { __typename?: 'User'; id: UUID; name: string } | null;
    } | null;
    projectLead?: { __typename?: 'User'; id: UUID } | null;
    orgNodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string }>;
  } | null>;
};

export type CompanyRolesQueryVariables = Exact<{
  companyID: Scalars['UUID']['input'];
}>;

export type CompanyRolesQuery = {
  __typename?: 'Query';
  companyRoles: Array<{ __typename?: 'PartialRole'; id: UUID; name: string }>;
};

export type CompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type CompaniesQuery = {
  __typename?: 'Query';
  companies: Array<{ __typename?: 'Company'; id: UUID; name: string }>;
};

export type AddCompanyEmployeeMutationVariables = Exact<{
  input: CompanyEmployeeInput;
}>;

export type AddCompanyEmployeeMutation = {
  __typename?: 'Mutation';
  addCompanyEmployee?: {
    __typename?: 'CompanyUser';
    isIndependent: boolean;
    company?: {
      __typename?: 'Company';
      id: UUID;
      name: string;
      type: string;
      domain?: string | null;
      assetID?: UUID | null;
      deactivated: boolean;
    } | null;
    matchedCompany?: {
      __typename?: 'Company';
      id: UUID;
      name: string;
      type: string;
      domain?: string | null;
      assetID?: UUID | null;
    } | null;
    role?: { __typename?: 'PartialRole'; id: UUID; name: string } | null;
    user?: {
      __typename?: 'User';
      email: string;
      id: UUID;
      name: string;
      phone?: string | null;
      picture: string;
      didSetup?: Time | null;
      jobTitle: string;
      status: UserStatus;
      activeAt?: Time | null;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
  } | null;
};

export type CompanyProjectRolesQueryVariables = Exact<{
  companyID: Scalars['UUID']['input'];
}>;

export type CompanyProjectRolesQuery = {
  __typename?: 'Query';
  companyProjectRoles: Array<{
    __typename?: 'CompanyProjectRoles';
    id: UUID;
    name: string;
    roles: Array<{ __typename?: 'PartialRole'; id: UUID; name: string }>;
  }>;
};

export type CurrentCompanyProjectRoleQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type CurrentCompanyProjectRoleQuery = {
  __typename?: 'Query';
  currentCompanyProjectRole?: CompanyProjectRole | null;
};

export type UpdateCompanyProjectTypeMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  typeID: Scalars['UUID']['input'];
}>;

export type UpdateCompanyProjectTypeMutation = {
  __typename?: 'Mutation';
  updateCompanyProjectType?: {
    __typename?: 'ProjectStats';
    id: UUID;
    type?: string | null;
    projectType: { __typename?: 'ProjectType'; id: UUID; name: string };
  } | null;
};

export type UpdateBulkCompanyProjectTypeMutationVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
  typeID: Scalars['UUID']['input'];
}>;

export type UpdateBulkCompanyProjectTypeMutation = {
  __typename?: 'Mutation';
  updateBulkCompanyProjectType: Array<{
    __typename?: 'ProjectStats';
    id: UUID;
    type?: string | null;
    projectType: { __typename?: 'ProjectType'; id: UUID; name: string };
  }>;
};

export type UpdateProjectTypeMutationVariables = Exact<{
  typeID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  parentID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type UpdateProjectTypeMutation = {
  __typename?: 'Mutation';
  updateProjectType: {
    __typename?: 'UpdateProjectTypePayload';
    type?: {
      __typename?: 'ProjectType';
      id: UUID;
      name: string;
      parentID?: UUID | null;
      hidden?: boolean | null;
    } | null;
  };
};

export type ToggleProjectTypeVisibilityMutationVariables = Exact<{
  typeID: Scalars['UUID']['input'];
  hide?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ToggleProjectTypeVisibilityMutation = {
  __typename?: 'Mutation';
  toggleProjectTypeVisibility: {
    __typename?: 'UpdateProjectTypePayload';
    type?: {
      __typename?: 'ProjectType';
      id: UUID;
      name: string;
      parentID?: UUID | null;
      hidden?: boolean | null;
    } | null;
  };
};

export type DeleteProjectTypeMutationVariables = Exact<{
  typeID: Scalars['UUID']['input'];
}>;

export type DeleteProjectTypeMutation = {
  __typename?: 'Mutation';
  deleteProjectType?: UUID | null;
};

export type CompanyUsersQueryVariables = Exact<{
  companyID: Scalars['UUID']['input'];
  filters?: InputMaybe<CompanyUserFilter>;
  sortBy?: InputMaybe<CompanyUserSortBy>;
}>;

export type CompanyUsersQuery = {
  __typename?: 'Query';
  companyUsers: Array<{
    __typename?: 'CompanyUser';
    isIndependent: boolean;
    company?: {
      __typename?: 'Company';
      id: UUID;
      name: string;
      type: string;
      domain?: string | null;
      assetID?: UUID | null;
      deactivated: boolean;
    } | null;
    matchedCompany?: {
      __typename?: 'Company';
      id: UUID;
      name: string;
      type: string;
      domain?: string | null;
      assetID?: UUID | null;
    } | null;
    role?: { __typename?: 'PartialRole'; id: UUID; name: string } | null;
    user?: {
      __typename?: 'User';
      email: string;
      id: UUID;
      name: string;
      phone?: string | null;
      picture: string;
      didSetup?: Time | null;
      jobTitle: string;
      status: UserStatus;
      activeAt?: Time | null;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
  }>;
};

export type CompanyCollaboratorsQueryVariables = Exact<{
  companyID: Scalars['UUID']['input'];
  filters?: InputMaybe<CompanyCollaboratorFilter>;
  userSortBy?: InputMaybe<CompanyUserSortBy>;
}>;

export type CompanyCollaboratorsQuery = {
  __typename?: 'Query';
  companyCollaborators: Array<{
    __typename?: 'CompanyCollaborator';
    companyUser: {
      __typename?: 'CompanyUser';
      isIndependent: boolean;
      company?: {
        __typename?: 'Company';
        id: UUID;
        name: string;
        type: string;
        domain?: string | null;
        deactivated: boolean;
      } | null;
      matchedCompany?: {
        __typename?: 'Company';
        id: UUID;
        name: string;
        type: string;
        domain?: string | null;
        deactivated: boolean;
      } | null;
      role?: { __typename?: 'PartialRole'; id: UUID; name: string } | null;
      user?: {
        __typename?: 'User';
        email: string;
        id: UUID;
        name: string;
        phone?: string | null;
        picture: string;
        didSetup?: Time | null;
        jobTitle: string;
        status: UserStatus;
        activeAt?: Time | null;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null;
    };
  }>;
};

export type GetContingencyReportQueryVariables = Exact<{
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  itemStatuses?: InputMaybe<Array<Status>>;
  filteredContingencies?: InputMaybe<Array<Scalars['String']['input']>>;
  filteredAllowances?: InputMaybe<Array<Scalars['String']['input']>>;
}>;

export type GetContingencyReportQuery = {
  __typename?: 'Query';
  contingencyReport: Array<{
    __typename?: 'ContingencyReportContingency';
    name: string;
    contingencyID: UUID;
    displayType: MarkupDisplayType;
    startingCost: USCents;
    usedCost: USCents;
    totalPendingCost: USCents;
    totalDrawnCost: USCents;
    percentOfMilestoneEstimate?: USCents | null;
    rows: Array<{
      __typename?: 'ContingencyReportItem';
      itemID: UUID;
      status: Status;
      name: string;
      number: string;
      drawCost?: USCents | null;
      acceptedBy?: string | null;
      dateAccepted?: Time | null;
      createdBy?: string | null;
      dateCreated?: Time | null;
    }>;
  }>;
};

export type GetAllMilestonesContingencyReportQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  filteredContingencies?: InputMaybe<Array<Scalars['String']['input']>>;
  filteredAllowances?: InputMaybe<Array<Scalars['String']['input']>>;
}>;

export type GetAllMilestonesContingencyReportQuery = {
  __typename?: 'Query';
  multiMilestoneContingencyReport: {
    __typename?: 'MultiMilestoneContingencyReportData';
    milestones: Array<{
      __typename?: 'MultiMilestoneContingencyMilestone';
      name: string;
      startDate?: Time | null;
      isActiveMilestone: boolean;
    }>;
    contingencies: Array<{
      __typename?: 'MultiMilestoneContingency';
      name: string;
      type: MarkupDisplayType;
      initialAmountSet: USCents;
      initialPercentOfMilestoneEstimate?: USCents | null;
      report: Array<{
        __typename?: 'ContingencyReportContingency';
        startingCost: USCents;
        usedCost: USCents;
        totalPendingCost: USCents;
        totalDrawnCost: USCents;
        percentOfMilestoneEstimate?: USCents | null;
        acceptedItemCount: number;
        existsInAllMilestonesReportMilestone: boolean;
      }>;
    }>;
  };
};

export type CopyItemDataQueryVariables = Exact<{
  itemID: Scalars['UUID']['input'];
  projectID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type CopyItemDataQuery = {
  __typename?: 'Query';
  copyItemData: {
    __typename?: 'CopyItemData';
    convertedItemCategories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    }>;
    itemEstimateCategorizations: Array<{
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      description: string;
      levels: number;
      builtin: boolean;
    }>;
  };
};

export type GetMilestonesMarkupsQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type GetMilestonesMarkupsQuery = {
  __typename?: 'Query';
  milestonesMarkups: Array<{
    __typename?: 'MilestoneMarkupsInfo';
    id: UUID;
    activeEstimate?: {
      __typename?: 'EstimateMarkupsInfo';
      markups: Array<{
        __typename?: 'Markup';
        name: string;
        id: UUID;
        estimateId: UUID;
        displayType: MarkupDisplayType;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        name: string;
        id: UUID;
        estimateId: UUID;
        displayType: MarkupDisplayType;
      }>;
    } | null;
    budget?: {
      __typename?: 'EstimateMarkupsInfo';
      markups: Array<{
        __typename?: 'Markup';
        name: string;
        id: UUID;
        estimateId: UUID;
        displayType: MarkupDisplayType;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        name: string;
        id: UUID;
        estimateId: UUID;
        displayType: MarkupDisplayType;
      }>;
    } | null;
  }>;
};

export type MinimalCostReportsQueryVariables = Exact<{
  milestones: Array<CostReportMilestoneInput>;
  columns: Array<CostReportColumnInput>;
  groupByItems: Array<GroupByItem>;
  costMode: CostMode;
  projectID: Scalars['UUID']['input'];
  shouldIncludeOwnerCosts?: InputMaybe<Scalars['Boolean']['input']>;
  viewFilter: ViewFilter;
  useDraftEstimate: Scalars['Boolean']['input'];
  useDraftBudget: Scalars['Boolean']['input'];
}>;

export type MinimalCostReportsQuery = {
  __typename?: 'Query';
  milestoneCostReports: Array<{
    __typename?: 'MilestoneCostReports';
    milestoneID: UUID;
    costReportColumns: Array<{
      __typename?: 'CostReportColumn';
      type: CostReportColumnType;
      columnKey: CostReportColumnKey;
      isCategorized?: boolean | null;
      report?: {
        __typename?: 'CostReport';
        numeric: Numeric;
        type: CostReportType;
        directCostRange:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        directCostSegmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        projectTotalRange?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        projectTotalSegmented?: {
          __typename?: 'AddDeductCost';
          adds: USCents;
          deducts: USCents;
        } | null;
        range:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        subtotals: Array<{
          __typename?: 'CostReportItem';
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          subtotalValue:
            | {
                __typename?: 'SubtotalAddsDeducts';
                directCostSegmented: {
                  __typename?: 'PartialAddDeductCost';
                  adds?: USCents | null;
                  deducts?: USCents | null;
                };
                segmented: {
                  __typename?: 'PartialAddDeductCost';
                  adds?: USCents | null;
                  deducts?: USCents | null;
                };
              }
            | {
                __typename?: 'SubtotalCost';
                directCostRange:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                range:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
              }
            | { __typename?: 'SubtotalNumeric'; value: Numeric };
        }>;
        categorizedItemCosts: Array<{
          __typename?: 'CategorizedItemCost';
          id: UUID;
          milestoneId: UUID;
          name: string;
          number: string;
          parentId?: UUID | null;
          status?: Status | null;
          canViewCosts: boolean;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          directCostRange:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          range:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          itemContributionLines?: Array<{
            __typename?: 'ItemContributionLines';
            lineID?: UUID | null;
            lineDescription?: string | null;
            categories: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              error?: string | null;
              level: number;
              hasSubCategories: boolean;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                builtin: boolean;
                description: string;
                draft: boolean;
                levels: number;
                deprecated: boolean;
              } | null;
              levels: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                hasSubCategories: boolean;
                level: number;
              }>;
            }>;
            directCostRange:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            range:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          } | null> | null;
        }>;
        markupContributions: Array<{
          __typename?: 'MarkupContribution';
          isIncorporated: boolean;
          isOwnerCost: boolean;
          markupId: UUID;
          markupName: string;
          displayType: MarkupDisplayType;
          range:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        }>;
      } | null;
      quantity?: {
        __typename?: 'Quantity';
        id: UUID;
        magnitude: Numeric;
        estimateID: UUID;
        hasBreakdown: boolean;
        unit: {
          __typename?: 'Unit';
          abbreviationPlural: string;
          abbreviationSingular: string;
          description?: string | null;
          id: UUID;
          isDefault: boolean;
          isEnabled: boolean;
          name: string;
          type: UnitType;
        };
      } | null;
    }>;
  }>;
};

export type IsJoinSupportQueryVariables = Exact<{ [key: string]: never }>;

export type IsJoinSupportQuery = { __typename?: 'Query'; isJoinSupport: boolean };

export type UpdateUserNameMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type UpdateUserNameMutation = {
  __typename?: 'Mutation';
  updateUserName: {
    __typename?: 'User';
    id: UUID;
    name: string;
    phone?: string | null;
    picture: string;
    email: string;
    jobTitle: string;
  };
};

export type UpdateUserJobTitleMutationVariables = Exact<{
  jobTitle: Scalars['String']['input'];
}>;

export type UpdateUserJobTitleMutation = {
  __typename?: 'Mutation';
  updateUserJobTitle: {
    __typename?: 'User';
    id: UUID;
    name: string;
    phone?: string | null;
    picture: string;
    email: string;
    jobTitle: string;
  };
};

export type UpdateUserPictureMutationVariables = Exact<{
  picture: Scalars['String']['input'];
}>;

export type UpdateUserPictureMutation = {
  __typename?: 'Mutation';
  updateUserPicture: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  };
};

export type ProjectDateVisibilityFragment = {
  __typename?: 'ProjectDateVisibility';
  milestoneDates: Array<{
    __typename?: 'MilestoneDateVisibility';
    milestoneID: UUID;
    dates: Array<{ __typename?: 'VisibleDate'; date: string; visible: boolean }>;
  }>;
};

export type ToggleAllMilestonesTrendDateVisibilityMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  intervalType: IntervalType;
  toggles: Array<DateVisibilityToggle>;
}>;

export type ToggleAllMilestonesTrendDateVisibilityMutation = {
  __typename?: 'Mutation';
  toggleAllMilestonesTrendDateVisibility: {
    __typename?: 'ProjectDateVisibility';
    milestoneDates: Array<{
      __typename?: 'MilestoneDateVisibility';
      milestoneID: UUID;
      dates: Array<{ __typename?: 'VisibleDate'; date: string; visible: boolean }>;
    }>;
  };
};

export type CreateOptionMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  parent: Scalars['UUID']['input'];
  item: ItemCreationInput;
  costMode: CostMode;
}>;

export type CreateOptionMutation = {
  __typename?: 'Mutation';
  createOption?: {
    __typename?: 'Item';
    id: UUID;
    itemType: ItemType;
    description: string;
    descriptionStyled: string;
    dueDate?: Time | null;
    creationTime?: Time | null;
    updateTime?: Time | null;
    name: string;
    number: string;
    status: Status;
    visibility: Visibility;
    availableStates: Array<Status>;
    assetCount: number;
    remoteAssetCount: number;
    commentCount: number;
    filteredMilestone?: UUID | null;
    filteredCategories?: Array<UUID> | null;
    activityIDs?: Array<UUID> | null;
    options: Array<{
      __typename?: 'Option';
      parent: UUID;
      parentVisibility?: Visibility | null;
      hasEstimate?: boolean | null;
      id: UUID;
      itemType: ItemType;
      description: string;
      descriptionStyled: string;
      dueDate?: Time | null;
      creationTime?: Time | null;
      updateTime?: Time | null;
      name: string;
      number: string;
      status: Status;
      visibility: Visibility;
      availableStates: Array<Status>;
      assetCount: number;
      remoteAssetCount: number;
      commentCount: number;
      filteredMilestone?: UUID | null;
      filteredCategories?: Array<UUID> | null;
      activityIDs?: Array<UUID> | null;
      comments: Array<{
        __typename?: 'Comment';
        id: UUID;
        created: Time;
        author: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        };
        text: {
          __typename?: 'MentionableText';
          contents: string;
          mentions?: Array<{
            __typename?: 'Mention';
            id: UUID;
            position: number;
            text: string;
            user: { __typename?: 'User'; id: UUID };
          }> | null;
          itemReferences?: Array<{
            __typename?: 'ItemReference';
            id: UUID;
            position: number;
            text: string;
            referencedItem?: {
              __typename?: 'ItemsListItem';
              createdAt: Time;
              currentStatus: Status;
              id: UUID;
              name: string;
              number: string;
              status: Status;
              updateTime: Time;
              visibility: Visibility;
              creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
              currentCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
              options: Array<{
                __typename?: 'ItemLink';
                id: UUID;
                name: string;
                number: string;
                status: Status;
                visibility?: Visibility | null;
              }>;
            } | null;
          }> | null;
        };
      }>;
      assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
      activeEstimate?: {
        __typename?: 'Estimate';
        id: UUID;
        name: string;
        subtotal: Numeric;
        markupSubtotal: USCents;
        drawSubtotal: USCents;
        inheritedSubtotal: USCents;
        inheritedOwnerCostMarkupsSubtotal: USCents;
        asset?: UUID | null;
        totalType: EstimateTotalType;
        isRunningTotal: boolean;
        lines: Array<{
          __typename?: 'Line';
          id: UUID;
          estimateId: UUID;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          cells: Array<{
            __typename?: 'Cell';
            estimateId: UUID;
            field: UUID;
            line: UUID;
            error?: string | null;
            value?:
              | {
                  __typename?: 'CategoryCell';
                  search: string;
                  category?: {
                    __typename?: 'Category';
                    id: UUID;
                    parentID?: UUID | null;
                    name: string;
                    hasSubCategories: boolean;
                    number: string;
                    level: number;
                    error?: string | null;
                    categorization?: {
                      __typename?: 'Categorization';
                      id: UUID;
                      name: string;
                    } | null;
                    levels: Array<{
                      __typename?: 'Category';
                      id: UUID;
                      name: string;
                      number: string;
                    }>;
                  } | null;
                }
              | { __typename?: 'RegularCell'; string: string }
              | {
                  __typename?: 'SourceCell';
                  sourceIDs?: {
                    __typename?: 'SourceIDs';
                    estimateID: UUID;
                    itemID?: UUID | null;
                    lineID?: UUID | null;
                    markupID?: UUID | null;
                    milestoneID: UUID;
                  } | null;
                }
              | null;
          }>;
        }>;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
        incorporatedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        contingencyDraws?: Array<{
          __typename?: 'ItemDrawInfo';
          id: UUID;
          type: MarkupDisplayType;
          name: string;
          error: string;
          draw: USCents;
          drawFromID?: UUID | null;
          isDrawingFullAmount: boolean;
        }> | null;
        inheritedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedOwnerCostMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        markups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
      } | null;
      bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
      project: { __typename?: 'Project'; id: UUID; name: string };
      createdBy?: {
        __typename?: 'User';
        id: UUID;
        email: string;
        name: string;
        picture: string;
      } | null;
      milestone?: {
        __typename?: 'Milestone';
        id: UUID;
        name: string;
        date?: Time | null;
        buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
      } | null;
      categories: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        error?: string | null;
        level: number;
        hasSubCategories: boolean;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          builtin: boolean;
          description: string;
          draft: boolean;
          levels: number;
          deprecated: boolean;
        } | null;
        levels: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          hasSubCategories: boolean;
          level: number;
        }>;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      assignee?: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null;
      previousMilestoneStatus: Array<{
        __typename?: 'PreviousMilestoneStatus';
        hasEstimate?: boolean | null;
        milestoneID: UUID;
        itemType: ItemType;
        status: Status;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
      }>;
      scheduleImpact?: {
        __typename?: 'ScheduleImpact';
        type: ScheduleImpactType;
        criticalPath: boolean;
        days: number;
        minImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
        maxImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
      } | null;
    }>;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    comments: Array<{
      __typename?: 'Comment';
      id: UUID;
      created: Time;
      author: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      text: {
        __typename?: 'MentionableText';
        contents: string;
        mentions?: Array<{
          __typename?: 'Mention';
          id: UUID;
          position: number;
          text: string;
          user: { __typename?: 'User'; id: UUID };
        }> | null;
        itemReferences?: Array<{
          __typename?: 'ItemReference';
          id: UUID;
          position: number;
          text: string;
          referencedItem?: {
            __typename?: 'ItemsListItem';
            createdAt: Time;
            currentStatus: Status;
            id: UUID;
            name: string;
            number: string;
            status: Status;
            updateTime: Time;
            visibility: Visibility;
            creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
            currentCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
            options: Array<{
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              status: Status;
              visibility?: Visibility | null;
            }>;
          } | null;
        }> | null;
      };
    }>;
    assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
    activeEstimate?: {
      __typename?: 'Estimate';
      id: UUID;
      name: string;
      subtotal: Numeric;
      markupSubtotal: USCents;
      drawSubtotal: USCents;
      inheritedSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      asset?: UUID | null;
      totalType: EstimateTotalType;
      isRunningTotal: boolean;
      lines: Array<{
        __typename?: 'Line';
        id: UUID;
        estimateId: UUID;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        cells: Array<{
          __typename?: 'Cell';
          estimateId: UUID;
          field: UUID;
          line: UUID;
          error?: string | null;
          value?:
            | {
                __typename?: 'CategoryCell';
                search: string;
                category?: {
                  __typename?: 'Category';
                  id: UUID;
                  parentID?: UUID | null;
                  name: string;
                  hasSubCategories: boolean;
                  number: string;
                  level: number;
                  error?: string | null;
                  categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                  levels: Array<{
                    __typename?: 'Category';
                    id: UUID;
                    name: string;
                    number: string;
                  }>;
                } | null;
              }
            | { __typename?: 'RegularCell'; string: string }
            | {
                __typename?: 'SourceCell';
                sourceIDs?: {
                  __typename?: 'SourceIDs';
                  estimateID: UUID;
                  itemID?: UUID | null;
                  lineID?: UUID | null;
                  markupID?: UUID | null;
                  milestoneID: UUID;
                } | null;
              }
            | null;
        }>;
      }>;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      contingencyDraws?: Array<{
        __typename?: 'ItemDrawInfo';
        id: UUID;
        type: MarkupDisplayType;
        name: string;
        error: string;
        draw: USCents;
        drawFromID?: UUID | null;
        isDrawingFullAmount: boolean;
      }> | null;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
    } | null;
    bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
    project: { __typename?: 'Project'; id: UUID; name: string };
    createdBy?: {
      __typename?: 'User';
      id: UUID;
      email: string;
      name: string;
      picture: string;
    } | null;
    milestone?: {
      __typename?: 'Milestone';
      id: UUID;
      name: string;
      date?: Time | null;
      buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
    } | null;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    assignee?: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
    previousMilestoneStatus: Array<{
      __typename?: 'PreviousMilestoneStatus';
      hasEstimate?: boolean | null;
      milestoneID: UUID;
      itemType: ItemType;
      status: Status;
      optionSubtotals: Array<{
        __typename?: 'OptionSubtotal';
        status: Status;
        adds: USCents;
        deducts: USCents;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
    }>;
    scheduleImpact?: {
      __typename?: 'ScheduleImpact';
      type: ScheduleImpactType;
      criticalPath: boolean;
      days: number;
      minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
      maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    } | null;
  } | null;
};

export type CreateMilestoneMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  input?: InputMaybe<MilestoneInput>;
}>;

export type CreateMilestoneMutation = {
  __typename?: 'Mutation';
  createMilestone: Array<{
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    assetCount: number;
    remoteAssetCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    buckets?: Array<{
      __typename?: 'Bucket';
      date?: Time | null;
      id: UUID;
      name: string;
      itemsCount: number;
    }> | null;
    createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    importedEstimates: Array<{
      __typename?: 'Asset';
      id: UUID;
      name: string;
      size: Size;
      lastUpdated: Time;
      location: string;
      type?: string | null;
      derivations: Array<{
        __typename?: 'Derivation';
        id: UUID;
        kind: string;
        contents: Array<string>;
      }>;
      uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    }>;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  } | null>;
};

export type CommonSourceHubFieldsFragment = {
  __typename?: 'SourceHub';
  id: string;
  name: string;
  remoteLink: string;
  sourceUpdatedAt?: Time | null;
};

export type CommonSourceProjectFieldsFragment = {
  __typename?: 'SourceProject';
  id: string;
  name: string;
  remoteLink: string;
  sourceUpdatedAt?: Time | null;
};

export type CommonSourceFolderFieldsFragment = {
  __typename?: 'SourceFolder';
  id: string;
  name: string;
  remoteLink: string;
  sourceUpdatedAt: Time;
};

export type CommonSourceAssetFieldsFragment = {
  __typename?: 'SourceAsset';
  id: string;
  name: string;
  remoteLink: string;
  sourceUpdatedAt: Time;
};

type CommonSourceEntityFields_SourceAsset_Fragment = {
  __typename?: 'SourceAsset';
  id: string;
  name: string;
  remoteLink: string;
  sourceUpdatedAt: Time;
};

type CommonSourceEntityFields_SourceFolder_Fragment = {
  __typename?: 'SourceFolder';
  id: string;
  name: string;
  remoteLink: string;
  sourceUpdatedAt: Time;
};

export type CommonSourceEntityFieldsFragment =
  | CommonSourceEntityFields_SourceAsset_Fragment
  | CommonSourceEntityFields_SourceFolder_Fragment;

export type LoadSourceHubRootQueryVariables = Exact<{
  sourceSystem: SourceSystem;
  sourceID: Scalars['UUID']['input'];
}>;

export type LoadSourceHubRootQuery = {
  __typename?: 'Query';
  sourceHubRoot: {
    __typename?: 'SourceHubRoot';
    sourceError?: string | null;
    hubs: Array<{
      __typename?: 'SourceHub';
      id: string;
      name: string;
      remoteLink: string;
      sourceUpdatedAt?: Time | null;
    }>;
  };
};

export type LoadSourceHubProjectsQueryVariables = Exact<{
  sourceSystem: SourceSystem;
  sourceID: Scalars['UUID']['input'];
  hubID: Scalars['String']['input'];
}>;

export type LoadSourceHubProjectsQuery = {
  __typename?: 'Query';
  sourceHubProjects: {
    __typename?: 'SourceHubProjects';
    sourceError?: string | null;
    projects: Array<{
      __typename?: 'SourceProject';
      id: string;
      name: string;
      remoteLink: string;
      sourceUpdatedAt?: Time | null;
    }>;
  };
};

export type LoadSourceProjectFoldersQueryVariables = Exact<{
  sourceSystem: SourceSystem;
  sourceID: Scalars['UUID']['input'];
  hubID: Scalars['String']['input'];
  projectID: Scalars['String']['input'];
}>;

export type LoadSourceProjectFoldersQuery = {
  __typename?: 'Query';
  sourceProjectFolders: {
    __typename?: 'SourceProjectFolders';
    sourceError?: string | null;
    folders: Array<{
      __typename?: 'SourceFolder';
      id: string;
      name: string;
      remoteLink: string;
      sourceUpdatedAt: Time;
    }>;
  };
};

export type LoadSourceFolderRootQueryVariables = Exact<{
  sourceSystem: SourceSystem;
  sourceID: Scalars['UUID']['input'];
  sortBy: FilesSortBy;
}>;

export type LoadSourceFolderRootQuery = {
  __typename?: 'Query';
  sourceFolderRoot: {
    __typename?: 'SourceFolderEntities';
    entities: Array<
      | {
          __typename?: 'SourceAsset';
          id: string;
          name: string;
          remoteLink: string;
          sourceUpdatedAt: Time;
        }
      | {
          __typename?: 'SourceFolder';
          id: string;
          name: string;
          remoteLink: string;
          sourceUpdatedAt: Time;
        }
    >;
  };
};

export type LoadSourceFolderContentsQueryVariables = Exact<{
  sourceSystem: SourceSystem;
  sourceID: Scalars['UUID']['input'];
  projectID?: InputMaybe<Scalars['String']['input']>;
  folderID?: InputMaybe<Scalars['String']['input']>;
  hubID?: InputMaybe<Scalars['String']['input']>;
  sortBy: FilesSortBy;
}>;

export type LoadSourceFolderContentsQuery = {
  __typename?: 'Query';
  sourceFolderContents: {
    __typename?: 'SourceFolderEntities';
    sourceError?: string | null;
    entities: Array<
      | {
          __typename?: 'SourceAsset';
          id: string;
          name: string;
          remoteLink: string;
          sourceUpdatedAt: Time;
        }
      | {
          __typename?: 'SourceFolder';
          id: string;
          name: string;
          remoteLink: string;
          sourceUpdatedAt: Time;
        }
    >;
  };
};

export type UserSourcesQueryVariables = Exact<{ [key: string]: never }>;

export type UserSourcesQuery = {
  __typename?: 'Query';
  userSources: {
    __typename?: 'UserSources';
    sources: Array<{
      __typename?: 'UserSource';
      sourceSystem: SourceSystem;
      id: UUID;
      email?: string | null;
      companyID?: string | null;
    }>;
    clients: Array<{ __typename?: 'SourceClient'; clientID: string; sourceSystem: SourceSystem }>;
  };
};

export type ConnectUserSourceMutationVariables = Exact<{
  publicToken: Scalars['String']['input'];
  sourceSystem: SourceSystem;
  host?: InputMaybe<Scalars['String']['input']>;
}>;

export type ConnectUserSourceMutation = { __typename?: 'Mutation'; connectUserSource: boolean };

export type RemoveUserSourceMutationVariables = Exact<{
  sourceID: Scalars['UUID']['input'];
}>;

export type RemoveUserSourceMutation = { __typename?: 'Mutation'; removeUserSource: boolean };

export type ImportEstimateErrorsQueryVariables = Exact<{
  estimateID: Scalars['UUID']['input'];
}>;

export type ImportEstimateErrorsQuery = {
  __typename?: 'Query';
  importEstimateErrors: Array<{
    __typename?: 'ImportEstimateError';
    id: UUID;
    type: ImportEstimateErrorType;
    level: ImportEstimateErrorLevel;
    unresolvedCount: number;
    resolvedCount: number;
    created: Time;
    updated?: Time | null;
    content: {
      __typename?: 'ImportErrorContentMissingCategories';
      lines: number;
      categorization: { __typename?: 'CategorizationContent'; id: UUID; name: string };
      categories: Array<{ __typename?: 'CategoryContent'; number: string; name: string }>;
    };
  }>;
};

export type ApplyMilestoneDraftEstimateMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
}>;

export type ApplyMilestoneDraftEstimateMutation = {
  __typename?: 'Mutation';
  applyMilestoneDraftEstimate: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    assetCount: number;
    remoteAssetCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    buckets?: Array<{
      __typename?: 'Bucket';
      date?: Time | null;
      id: UUID;
      name: string;
      itemsCount: number;
    }> | null;
    createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    importedEstimates: Array<{
      __typename?: 'Asset';
      id: UUID;
      name: string;
      size: Size;
      lastUpdated: Time;
      location: string;
      type?: string | null;
      derivations: Array<{
        __typename?: 'Derivation';
        id: UUID;
        kind: string;
        contents: Array<string>;
      }>;
      uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    }>;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  };
};

export type DeleteMilestoneDraftEstimateMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
}>;

export type DeleteMilestoneDraftEstimateMutation = {
  __typename?: 'Mutation';
  deleteMilestoneDraftEstimate: UUID;
};

export type ItemHistoryQueryVariables = Exact<{
  itemID?: InputMaybe<Scalars['UUID']['input']>;
  costMode: CostMode;
  viewFilter: ViewFilter;
  eventTypeFilters: Array<EventType>;
}>;

export type ItemHistoryQuery = {
  __typename?: 'Query';
  itemHistory?: {
    __typename?: 'ItemHistory';
    events: Array<{
      __typename?: 'ItemHistoryEvent';
      id: UUID;
      itemID: UUID;
      eventTypes: Array<EventType>;
      timestamp: Time;
      user?: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null;
      eventContent: {
        __typename?: 'ItemHistoryEventContent';
        newNumber?: string | null;
        oldNumber?: string | null;
        name?: string | null;
        description?: string | null;
        descriptionStyled?: string | null;
        newMilestoneID?: UUID | null;
        oldMilestoneID?: UUID | null;
        status?: Status | null;
        visibility?: Visibility | null;
        dueDate?: Time | null;
        drawName?: string | null;
        drawFromValue?: Numeric | null;
        drawToValue?: Numeric | null;
        milestoneID?: UUID | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        deletedBy?: {
          __typename?: 'User';
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          status: UserStatus;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        sharedUsers: Array<{
          __typename?: 'User';
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          status: UserStatus;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null>;
        meeting?: {
          __typename?: 'Bucket';
          id: UUID;
          name: string;
          date?: Time | null;
          milestone?: { __typename?: 'Milestone'; id: UUID } | null;
        } | null;
        addedAttachments?: Array<
          | {
              __typename?: 'Asset';
              id: UUID;
              name: string;
              size: Size;
              lastUpdated: Time;
              location: string;
              type?: string | null;
              derivations: Array<{
                __typename?: 'Derivation';
                id: UUID;
                kind: string;
                contents: Array<string>;
              }>;
              uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
            }
          | {
              __typename?: 'RemoteAsset';
              id: UUID;
              sourceAssetID: string;
              name: string;
              lastUpdated: Time;
              remoteLink: string;
              sourceSystem: SourceSystem;
              uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
            }
          | null
        > | null;
        removedAssets?: Array<{ __typename?: 'Asset'; name: string } | null> | null;
        newCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        oldCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        categoryChanges?: Array<{
          __typename?: 'CategoryChange';
          oldCategory?: {
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          } | null;
          newCategory?: {
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          } | null;
        }> | null;
        costChanges?: Array<{
          __typename?: 'CostChange';
          timestamp: Time;
          oldCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          newCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        }> | null;
        scheduleImpactChanges: Array<{
          __typename?: 'ScheduleImpactChange';
          timestamp: Time;
          old?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
          new: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          };
        }>;
        oldTimelineActivities: Array<{ __typename?: 'TimelineActivity'; id: UUID; name: string }>;
        newTimelineActivities: Array<{ __typename?: 'TimelineActivity'; id: UUID; name: string }>;
        itemLikeSummary?: {
          __typename?: 'ItemLikeSummary';
          id: UUID;
          number: string;
          kind?: string | null;
          visibility: Visibility;
        } | null;
        removedOptions?: Array<{
          __typename?: 'ItemLikeSummary';
          id: UUID;
          number: string;
          kind?: string | null;
          visibility: Visibility;
        } | null> | null;
        attachedOptions?: Array<{
          __typename?: 'ItemLikeSummary';
          id: UUID;
          number: string;
          kind?: string | null;
          visibility: Visibility;
        } | null> | null;
        statusChangeDraws?: Array<{
          __typename?: 'StatusChangeDraw';
          name: string;
          value: Numeric;
        } | null> | null;
        drawChanges?: Array<{
          __typename?: 'DrawChange';
          name: string;
          fromValue: Numeric;
          toValue: Numeric;
          timestamp: Time;
        }> | null;
        referencedByItems?: Array<{
          __typename?: 'ItemLikeSummary';
          id: UUID;
          number: string;
          kind?: string | null;
          visibility: Visibility;
        } | null> | null;
        comment?: {
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        } | null;
        copiedItem?: {
          __typename?: 'ItemLinkWithProjectID';
          id: UUID;
          name: string;
          number: string;
          projectID: UUID;
          status: Status;
          visibility?: Visibility | null;
          hasAccess: boolean;
        } | null;
        itemIntegrationObject?: {
          __typename?: 'ItemIntegrationObjectSummary';
          sourceEntityID: string;
          number: number;
          title: string;
        } | null;
      };
    }>;
  } | null;
};

export type ListDashboardProjectsQueryVariables = Exact<{
  companyID?: InputMaybe<Scalars['UUID']['input']>;
  projectsFilterBy: ProjectsFilterBy;
  includeCompanyProjects?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ListDashboardProjectsQuery = {
  __typename?: 'Query';
  dashboardProjects: {
    __typename?: 'DashboardProjects';
    projects: Array<{
      __typename?: 'ProjectChartInfo';
      code?: string | null;
      hasAccess: boolean;
      id: UUID;
      milestoneName: string;
      name: string;
    }>;
  };
};

export type InsightsProjectCountsQueryVariables = Exact<{
  companyID?: InputMaybe<Scalars['UUID']['input']>;
  projectsFilterBy: ProjectsFilterBy;
}>;

export type InsightsProjectCountsQuery = {
  __typename?: 'Query';
  insightsProjectCounts: {
    __typename?: 'ProjectsCounts';
    allProjectsCount: number;
    myProjectsCount: number;
  };
};

export type GetActiveUsersQueryVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
}>;

export type GetActiveUsersQuery = {
  __typename?: 'Query';
  activeUsers: Array<{
    __typename?: 'ActiveUser';
    commentsAdded: number;
    email: string;
    itemsCreated: number;
    name: string;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  }>;
};

export type GetProjectActiveUsersQueryVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
}>;

export type GetProjectActiveUsersQuery = {
  __typename?: 'Query';
  projectActiveUsers: Array<{
    __typename?: 'ProjectActiveUsers';
    projectID: UUID;
    userCount: number;
    month: Time;
  }>;
};

export type GetDecisionSummaryQueryVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
}>;

export type GetDecisionSummaryQuery = {
  __typename?: 'Query';
  decisionSummary: {
    __typename?: 'DecisionSummary';
    acceptedCount: number;
    acceptedCost: USCents;
    incorporatedCount: number;
    incorporatedCost: USCents;
    notApplicableCount: number;
    notApplicableCost: USCents;
    pendingCount: number;
    pendingCost: USCents;
    rejectedCount: number;
    rejectedCost: USCents;
    totalItemCount: number;
  };
};

export type GetItemsOwnershipQueryVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
}>;

export type GetItemsOwnershipQuery = {
  __typename?: 'Query';
  itemsOwnership: Array<{
    __typename?: 'ItemsOwnership';
    itemCount: number;
    itemPercentage: number;
    assigneeRoleName: string;
  }>;
};

export type GetProjectBudgetGapsQueryVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
}>;

export type GetProjectBudgetGapsQuery = {
  __typename?: 'Query';
  projectBudgetGaps: Array<{
    __typename?: 'ProjectBudgetGap';
    accepted: USCents;
    budget: USCents;
    estimate: USCents;
    projectID: UUID;
    relativeGap: number;
    runningTotal: USCents;
  }>;
};

export type ProjectLocationFieldsFragment = {
  __typename?: 'ProjectLocation';
  location?: string | null;
  lat?: number | null;
  lon?: number | null;
  totalAPV: USCents;
  projects: Array<{
    __typename?: 'ProjectLocationData';
    apv: USCents;
    id: UUID;
    name: string;
    status: string;
  }>;
};

export type GetProjectLocationsQueryVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
}>;

export type GetProjectLocationsQuery = {
  __typename?: 'Query';
  projectLocations: {
    __typename?: 'ProjectLocations';
    projectLocations: Array<{
      __typename?: 'ProjectLocation';
      location?: string | null;
      lat?: number | null;
      lon?: number | null;
      totalAPV: USCents;
      projects: Array<{
        __typename?: 'ProjectLocationData';
        apv: USCents;
        id: UUID;
        name: string;
        status: string;
      }>;
    }>;
    projectsWithoutLocation?: {
      __typename?: 'ProjectLocation';
      location?: string | null;
      lat?: number | null;
      lon?: number | null;
      totalAPV: USCents;
      projects: Array<{
        __typename?: 'ProjectLocationData';
        apv: USCents;
        id: UUID;
        name: string;
        status: string;
      }>;
    } | null;
  };
};

export type GetProjectMovementsQueryVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
}>;

export type GetProjectMovementsQuery = {
  __typename?: 'Query';
  projectMovements: {
    __typename?: 'ProjectMovements';
    increases: Array<{
      __typename?: 'ProjectMovement';
      projectID: UUID;
      lastUpdated: Time;
      name: string;
      milestoneName: string;
      absolute: USCents;
      relative: number;
    }>;
    decreases: Array<{
      __typename?: 'ProjectMovement';
      projectID: UUID;
      lastUpdated: Time;
      name: string;
      milestoneName: string;
      absolute: USCents;
      relative: number;
    }>;
  };
};

export type GetProjectsOverviewQueryVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
}>;

export type GetProjectsOverviewQuery = {
  __typename?: 'Query';
  projectsOverview: {
    __typename?: 'ProjectsOverview';
    totalAPV: USCents;
    projectStatusData: Array<{
      __typename?: 'ProjectOverviewData';
      name: string;
      count: number;
      countPercentage: number;
      apv: USCents;
      apvPercentage: number;
    }>;
    projectTypeData: Array<{
      __typename?: 'ProjectOverviewData';
      name: string;
      count: number;
      countPercentage: number;
      apv: USCents;
      apvPercentage: number;
    }>;
  };
};

export type ListItemDueDatesQueryVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
}>;

export type ListItemDueDatesQuery = {
  __typename?: 'Query';
  itemDueDates: Array<{
    __typename?: 'ItemDueDateProject';
    noDueDateCount: number;
    pastDueCount: number;
    dueThisWeekCount: number;
    upcomingCount: number;
    openCount: number;
    projectID: UUID;
  }>;
};

export type ListCurrentItemsBreakdownQueryVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
}>;

export type ListCurrentItemsBreakdownQuery = {
  __typename?: 'Query';
  currentItemsBreakdown: {
    __typename?: 'CurrentItemsBreakdown';
    projectTotals: Array<{
      __typename?: 'ProjectItemTotalCostBreakdown';
      projectStatus: string;
      statusDuration: number;
      positiveCost: USCents;
      negativeCost: USCents;
      totalCost: USCents;
      totalItemCount: number;
      project: { __typename?: 'ProjectChartInfo'; id: UUID; name: string; code?: string | null };
      statusBreakdown: Array<{
        __typename?: 'ProjectItemStatusCostBreakdown';
        status: Status;
        percent: number;
        itemCount: number;
        cost: USCents;
      }>;
      monthlyBreakdown: Array<{
        __typename?: 'MonthlyProjectCostRange';
        month: string;
        positiveCost: USCents;
        negativeCost: USCents;
        totalCost: USCents;
        itemBreakdown: Array<{
          __typename?: 'MonthlyItemBreakdown';
          status: Status;
          cost: USCents;
        }>;
      }>;
    }>;
    itemBreakdownsByProject: Array<{
      __typename?: 'CurrentItemBreakdown';
      status: Status;
      cost: USCents;
      project: { __typename?: 'ProjectChartInfo'; id: UUID; name: string; code?: string | null };
    }>;
  };
};

export type GetRecentlyCreatedProjectsQueryVariables = Exact<{
  projectIDs: Array<Scalars['UUID']['input']>;
}>;

export type GetRecentlyCreatedProjectsQuery = {
  __typename?: 'Query';
  recentlyCreatedProjects: Array<{
    __typename?: 'RecentProject';
    name: string;
    status: string;
    location: string;
    createdBy: string;
    createdAt: Time;
    thumbnail: string;
    projectId: UUID;
  }>;
};

export type InsightsQueryVariables = Exact<{
  input: InsightsInput;
}>;

export type InsightsQuery = {
  __typename?: 'Query';
  insights: Array<{ __typename?: 'InsightsProject'; id: UUID }>;
};

export type InsightsProjectsQueryVariables = Exact<{
  input: InsightsInput;
}>;

export type InsightsProjectsQuery = {
  __typename?: 'Query';
  insights: Array<{
    __typename?: 'InsightsProject';
    id: UUID;
    name: string;
    location: string;
    status: string;
    lastUpdated: Time;
    lastUpdatedBy: string;
    deliveryType: string;
    milestoneDesignPhase: string;
    hasAccess: boolean;
    thumbnail: string;
    projectLeadName?: string | null;
    type: string;
    orgNodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string } | null>;
  }>;
};

export type ForecastingProjectsQueryVariables = Exact<{
  filters: ForecastingProjectsFilters;
  search: Scalars['String']['input'];
  sortBy: ForecastingProjectsSortBy;
  pagination: Pagination;
}>;

export type ForecastingProjectsQuery = {
  __typename?: 'Query';
  forecastingProjects?: Array<{
    __typename?: 'ForecastingProject';
    budget?: USCents | null;
    estimate?: USCents | null;
    hasAccess: boolean;
    id: UUID;
    location: string;
    milestoneDate?: Time | null;
    milestoneID: UUID;
    milestoneName: string;
    name: string;
    projectLeadName?: string | null;
    runningTotal?: USCents | null;
    status: string;
    thumbnail: string;
    type: string;
    updatedAt?: Time | null;
    updatedBy?: string | null;
    orgNodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string }>;
  }> | null;
};

export type ProjectComparisonReportsQueryVariables = Exact<{
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  search: Scalars['String']['input'];
  sortBy: ForecastingReportsSortBy;
}>;

export type ProjectComparisonReportsQuery = {
  __typename?: 'Query';
  projectComparisonReports: Array<{
    __typename?: 'ProjectComparisonReport';
    id: UUID;
    name: string;
    description: string;
    createdAt: Time;
    updatedAt: Time;
    attachedProject?: {
      __typename?: 'Project';
      id: UUID;
      name: string;
      location: string;
      hasAccess: boolean;
      type: { __typename?: 'ProjectType'; id: UUID; name: string };
    } | null;
    createdBy: { __typename?: 'User'; id: UUID; name: string; email: string };
    updatedBy: { __typename?: 'User'; id: UUID; name: string; email: string };
    metadata: {
      __typename?: 'ProjectComparisonReportMetadata';
      averageQuantityCost?: USCents | null;
      averageTotalCost?: USCents | null;
      pinnedUnitQuantityMagnitude?: Numeric | null;
      projectsCount: number;
      pinnedUnit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    };
  }>;
};

export type ProjectComparisonReportQueryVariables = Exact<{
  reportID: Scalars['UUID']['input'];
}>;

export type ProjectComparisonReportQuery = {
  __typename?: 'Query';
  projectComparisonReport: {
    __typename?: 'ProjectComparisonReport';
    id: UUID;
    name: string;
    description: string;
    createdAt: Time;
    updatedAt: Time;
    attachedProject?: {
      __typename?: 'Project';
      id: UUID;
      name: string;
      location: string;
      hasAccess: boolean;
      type: { __typename?: 'ProjectType'; id: UUID; name: string };
    } | null;
    createdBy: { __typename?: 'User'; id: UUID; name: string; email: string };
    updatedBy: { __typename?: 'User'; id: UUID; name: string; email: string };
    metadata: {
      __typename?: 'ProjectComparisonReportMetadata';
      averageQuantityCost?: USCents | null;
      averageTotalCost?: USCents | null;
      pinnedUnitQuantityMagnitude?: Numeric | null;
      projectsCount: number;
      pinnedUnit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    };
  };
};

export type CreateProjectComparisonReportMutationVariables = Exact<{
  attachedProjectID?: InputMaybe<Scalars['UUID']['input']>;
  reportName: Scalars['String']['input'];
  reportDescription: Scalars['String']['input'];
  projectCompsSetInput: ProjectCompsSetInput;
}>;

export type CreateProjectComparisonReportMutation = {
  __typename?: 'Mutation';
  createProjectComparisonReport: {
    __typename?: 'ProjectComparisonReport';
    id: UUID;
    name: string;
    description: string;
    createdAt: Time;
    updatedAt: Time;
    attachedProject?: {
      __typename?: 'Project';
      id: UUID;
      name: string;
      location: string;
      hasAccess: boolean;
      type: { __typename?: 'ProjectType'; id: UUID; name: string };
    } | null;
    createdBy: { __typename?: 'User'; id: UUID; name: string; email: string };
    updatedBy: { __typename?: 'User'; id: UUID; name: string; email: string };
    metadata: {
      __typename?: 'ProjectComparisonReportMetadata';
      averageQuantityCost?: USCents | null;
      averageTotalCost?: USCents | null;
      pinnedUnitQuantityMagnitude?: Numeric | null;
      projectsCount: number;
      pinnedUnit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    };
  };
};

export type ProjectCompsSetInputQueryVariables = Exact<{
  reportID: Scalars['UUID']['input'];
}>;

export type ProjectCompsSetInputQuery = {
  __typename?: 'Query';
  projectCompsSetInput: {
    __typename?: 'ProjectCompsSetInputReturn';
    addedMarkupNames: Array<string>;
    markupMode: MarkupMode;
    pinnedUnitID?: UUID | null;
    selectedUnitIDs: Array<UUID>;
    excludedMarkupNames: Array<string>;
    averageInput?: {
      __typename?: 'AverageCompInputReturn';
      name?: string | null;
      description?: string | null;
      color?: string | null;
      isHidden?: boolean | null;
      thumbnailAssetID?: UUID | null;
      location?: string | null;
      lat?: number | null;
      lon?: number | null;
      metrics: Array<{
        __typename?: 'MetricsInputReturn';
        unitID: UUID;
        quantityMagnitude?: string | null;
        hasMilestoneQuantity: boolean;
      }>;
      categoryLineInputs: Array<{
        __typename?: 'CategoryLineInputReturn';
        description?: string | null;
        editedValue: USCents;
        cellType: ProjectCompsCostTableCellType;
        unitID?: UUID | null;
        category: { __typename?: 'Category'; name: string; number: string };
      }>;
      markupLineInputs: Array<{
        __typename?: 'MarkupLineInputReturn';
        markupName: string;
        description?: string | null;
        editedValue: USCents;
        cellType: ProjectCompsCostTableCellType;
        unitID?: UUID | null;
      }>;
    } | null;
    costTableColumnInputs?: Array<{
      __typename?: 'CostTableColumnInputReturn';
      key: CostTableColumnInputKey;
      unitID?: UUID | null;
    }> | null;
    projectCompInputs: Array<{
      __typename?: 'ProjectCompInputReturn';
      id: UUID;
      projectID: UUID;
      name?: string | null;
      description?: string | null;
      categorizationID?: UUID | null;
      categorizationLevel?: number | null;
      isExcluded?: boolean | null;
      milestoneID?: UUID | null;
      escalation?: {
        __typename?: 'ProjectCompEscalation';
        time?: string | null;
        timeFuture?: string | null;
        location?: string | null;
        timeMeta?: {
          __typename?: 'ProjectCompEscalationMeta';
          isAutoEscalated: boolean;
          isFuzzyMatch: boolean;
          sourceLabel: string;
          sourceIsFuzzyMatch: boolean;
          targetLabel: string;
          targetIsFuzzyMatch: boolean;
        } | null;
        locationMeta?: {
          __typename?: 'ProjectCompEscalationMeta';
          isAutoEscalated: boolean;
          isFuzzyMatch: boolean;
          sourceLabel: string;
          sourceIsFuzzyMatch: boolean;
          targetLabel: string;
          targetIsFuzzyMatch: boolean;
        } | null;
      } | null;
      metrics: Array<{
        __typename?: 'MetricsInputReturn';
        unitID: UUID;
        quantityMagnitude?: string | null;
        hasMilestoneQuantity: boolean;
      }>;
      categoryLineInputs: Array<{
        __typename?: 'CategoryLineInputReturn';
        description?: string | null;
        editedValue: USCents;
        cellType: ProjectCompsCostTableCellType;
        unitID?: UUID | null;
        category: { __typename?: 'Category'; name: string; number: string };
      }>;
      markupLineInputs: Array<{
        __typename?: 'MarkupLineInputReturn';
        markupName: string;
        description?: string | null;
        editedValue: USCents;
        cellType: ProjectCompsCostTableCellType;
        unitID?: UUID | null;
      }>;
      viewFilter?: {
        __typename?: 'ViewFilterReturn';
        categories?: Array<{
          __typename?: 'CategoryInputReturn';
          categorizationID: UUID;
          id: UUID;
        }> | null;
      } | null;
    }>;
    excludedCategoryContents: Array<{
      __typename?: 'CategoryContent';
      number: string;
      name: string;
    }>;
  };
};

export type DeleteForecastingReportMutationVariables = Exact<{
  reportID: Scalars['UUID']['input'];
}>;

export type DeleteForecastingReportMutation = {
  __typename?: 'Mutation';
  deleteForecastingReport: boolean;
};

export type UpdateProjectComparisonReportMutationVariables = Exact<{
  reportID: Scalars['UUID']['input'];
  reportName?: InputMaybe<Scalars['String']['input']>;
  reportDescription?: InputMaybe<Scalars['String']['input']>;
  projectCompsSetInput?: InputMaybe<ProjectCompsSetInput>;
  attachedProjectID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type UpdateProjectComparisonReportMutation = {
  __typename?: 'Mutation';
  updateProjectComparisonReport: {
    __typename?: 'ProjectComparisonReport';
    id: UUID;
    name: string;
    description: string;
    createdAt: Time;
    updatedAt: Time;
    attachedProject?: {
      __typename?: 'Project';
      id: UUID;
      name: string;
      location: string;
      hasAccess: boolean;
      type: { __typename?: 'ProjectType'; id: UUID; name: string };
    } | null;
    createdBy: { __typename?: 'User'; id: UUID; name: string; email: string };
    updatedBy: { __typename?: 'User'; id: UUID; name: string; email: string };
    metadata: {
      __typename?: 'ProjectComparisonReportMetadata';
      averageQuantityCost?: USCents | null;
      averageTotalCost?: USCents | null;
      pinnedUnitQuantityMagnitude?: Numeric | null;
      projectsCount: number;
      pinnedUnit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    };
  };
};

export type SearchItemsQueryVariables = Exact<{
  filters: ForecastingExploreItemsFilters;
  projectsFilters: ForecastingProjectsFilters;
  searchResultType?: InputMaybe<SearchResultType>;
  search: Scalars['String']['input'];
  sortBy: ForecastingExploreItemsSortBy;
  pagination: Pagination;
}>;

export type SearchItemsQuery = {
  __typename?: 'Query';
  searchItems?: {
    __typename?: 'ForecastingItems';
    total: number;
    aggregations: Array<{
      __typename?: 'Aggregation';
      name: string;
      values: Array<{ __typename?: 'AggregationValue'; name: string; count: number }>;
    }>;
    data: Array<{
      __typename?: 'ForecastingItem';
      hasAccess: boolean;
      id: UUID;
      milestoneDate?: Time | null;
      name: string;
      number: string;
      numOptions: number;
      parentID?: UUID | null;
      parentName?: string | null;
      status: Status;
      type: ItemType;
      updatedAt: Time;
      categories: Array<{
        __typename?: 'SearchCategory';
        id: UUID;
        name: string;
        number: string;
        categorization: { __typename?: 'SearchCategorization'; id: UUID; name: string };
      }>;
      cost?: { __typename?: 'CostRange'; min: USCents; max: USCents } | null;
      createdBy?: { __typename?: 'User'; name: string } | null;
      project: {
        __typename?: 'ForecastingProject';
        budget?: USCents | null;
        estimate?: USCents | null;
        id: UUID;
        location: string;
        milestoneDate?: Time | null;
        milestoneID: UUID;
        milestoneName: string;
        name: string;
        projectLeadName?: string | null;
        runningTotal?: USCents | null;
        status: string;
        thumbnail: string;
        type: string;
        updatedAt?: Time | null;
        updatedBy?: string | null;
        hasAccess: boolean;
        orgNodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string }>;
      };
    }>;
  } | null;
};

export type SearchCategoryFilterLabelsQueryVariables = Exact<{ [key: string]: never }>;

export type SearchCategoryFilterLabelsQuery = {
  __typename?: 'Query';
  searchCategoryFilterLabels: Array<{
    __typename?: 'SearchFilterLabel';
    key: string;
    label: string;
  }>;
};

export type SearchProjectsQueryVariables = Exact<{
  filters: ForecastingProjectsFilters;
  searchResultType?: InputMaybe<SearchResultType>;
  search: Scalars['String']['input'];
  sortBy: ForecastingProjectsSortBy;
  pagination: Pagination;
}>;

export type SearchProjectsQuery = {
  __typename?: 'Query';
  searchProjects?: {
    __typename?: 'SearchProjects';
    total: number;
    data: Array<{
      __typename?: 'ForecastingProject';
      budget?: USCents | null;
      estimate?: USCents | null;
      hasAccess: boolean;
      id: UUID;
      location: string;
      milestoneDate?: Time | null;
      milestoneID: UUID;
      milestoneName: string;
      name: string;
      projectLeadName?: string | null;
      runningTotal?: USCents | null;
      status: string;
      thumbnail: string;
      type: string;
      updatedAt?: Time | null;
      updatedBy?: string | null;
      orgNodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string }>;
    }>;
  } | null;
};

export type SearchProjectsIdsQueryVariables = Exact<{
  filters: ForecastingProjectsFilters;
  searchResultType?: InputMaybe<SearchResultType>;
  search: Scalars['String']['input'];
  sortBy: ForecastingProjectsSortBy;
  pagination: Pagination;
}>;

export type SearchProjectsIdsQuery = {
  __typename?: 'Query';
  searchProjects?: {
    __typename?: 'SearchProjects';
    total: number;
    data: Array<{ __typename?: 'ForecastingProject'; id: UUID }>;
  } | null;
};

export type CreateMilestoneDraftEstimateFromPreviousMilestoneMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  currentMilestoneID: Scalars['UUID']['input'];
  currentEstimateType: EstimateType;
  previousMilestoneID: Scalars['UUID']['input'];
  previousEstimateType: EstimateType;
  isRunningTotal: Scalars['Boolean']['input'];
  incorporateAccepted: Scalars['Boolean']['input'];
}>;

export type CreateMilestoneDraftEstimateFromPreviousMilestoneMutation = {
  __typename?: 'Mutation';
  createMilestoneDraftEstimateFromPreviousMilestone: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    assetCount: number;
    remoteAssetCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    buckets?: Array<{
      __typename?: 'Bucket';
      date?: Time | null;
      id: UUID;
      name: string;
      itemsCount: number;
    }> | null;
    createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    importedEstimates: Array<{
      __typename?: 'Asset';
      id: UUID;
      name: string;
      size: Size;
      lastUpdated: Time;
      location: string;
      type?: string | null;
      derivations: Array<{
        __typename?: 'Derivation';
        id: UUID;
        kind: string;
        contents: Array<string>;
      }>;
      uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    }>;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  };
};

export type CreateMilestoneDraftEstimateMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  estimateType: EstimateType;
  estimateTotalType: EstimateTotalType;
}>;

export type CreateMilestoneDraftEstimateMutation = {
  __typename?: 'Mutation';
  createMilestoneDraftEstimate: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    assetCount: number;
    remoteAssetCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    buckets?: Array<{
      __typename?: 'Bucket';
      date?: Time | null;
      id: UUID;
      name: string;
      itemsCount: number;
    }> | null;
    createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    importedEstimates: Array<{
      __typename?: 'Asset';
      id: UUID;
      name: string;
      size: Size;
      lastUpdated: Time;
      location: string;
      type?: string | null;
      derivations: Array<{
        __typename?: 'Derivation';
        id: UUID;
        kind: string;
        contents: Array<string>;
      }>;
      uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    }>;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  };
};

export type EstimateTotalTypeConflictInfoQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
}>;

export type EstimateTotalTypeConflictInfoQuery = {
  __typename?: 'Query';
  estimateTotalTypeConflictInfo: {
    __typename?: 'EstimateTotalTypeConflictInfo';
    hasConflict: boolean;
    mostDetailedEstimateTotalType?: EstimateTotalType | null;
  };
};

export type FinalizeImportEstimateMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  draftID: Scalars['UUID']['input'];
  draftCategorizations: Array<DraftCategorization>;
  estimateTotalType: EstimateTotalType;
}>;

export type FinalizeImportEstimateMutation = {
  __typename?: 'Mutation';
  finalizeImportEstimate: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    assetCount: number;
    remoteAssetCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    buckets?: Array<{
      __typename?: 'Bucket';
      date?: Time | null;
      id: UUID;
      name: string;
      itemsCount: number;
    }> | null;
    createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    importedEstimates: Array<{
      __typename?: 'Asset';
      id: UUID;
      name: string;
      size: Size;
      lastUpdated: Time;
      location: string;
      type?: string | null;
      derivations: Array<{
        __typename?: 'Derivation';
        id: UUID;
        kind: string;
        contents: Array<string>;
      }>;
      uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    }>;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  };
};

export type SetDisabledProjectCategorizationsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  categorizationIDs: Array<Scalars['UUID']['input']>;
  disable: Scalars['Boolean']['input'];
}>;

export type SetDisabledProjectCategorizationsMutation = {
  __typename?: 'Mutation';
  setDisabledProjectCategorizations: Array<UUID>;
};

export type HasUnreadInAppNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type HasUnreadInAppNotificationsQuery = {
  __typename?: 'Query';
  hasUnreadInAppNotifications: boolean;
};

export type LoadInAppNotificationsCountQueryVariables = Exact<{
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  maxCount: Scalars['Int']['input'];
}>;

export type LoadInAppNotificationsCountQuery = {
  __typename?: 'Query';
  loadInAppNotificationsCount: {
    __typename?: 'InAppNotificationsCounts';
    allNotificationsCount: number;
    unreadNotificationsCount: number;
    allProjectNotificationsCount: number;
    unreadProjectNotificationsCount: number;
  };
};

export type LoadInAppNotificationsQueryVariables = Exact<{
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  showUnreadOnly: Scalars['Boolean']['input'];
  pagination: PaginationByOffsetId;
  costMode?: InputMaybe<CostMode>;
}>;

export type LoadInAppNotificationsQuery = {
  __typename?: 'Query';
  loadInAppNotifications: {
    __typename?: 'InAppNotifications';
    inAppNotifications?: Array<{
      __typename?: 'InAppNotification';
      id: UUID;
      notificationType: NotificationType;
      projectID: UUID;
      createdAt: Time;
      readAt?: Time | null;
      content:
        | {
            __typename?: 'AssignItem';
            triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
            project: { __typename?: 'Project'; name: string; id: UUID };
            item: {
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              visibility?: Visibility | null;
            };
          }
        | {
            __typename?: 'AssignProjectLead';
            triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
            project: { __typename?: 'Project'; name: string; id: UUID };
          }
        | {
            __typename?: 'InviteToProject';
            message?: string | null;
            triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
            project: { __typename?: 'Project'; name: string; id: UUID };
          }
        | {
            __typename?: 'NewComment';
            triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
            project: { __typename?: 'Project'; name: string; id: UUID };
            item: {
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              visibility?: Visibility | null;
            };
            comment: {
              __typename?: 'Comment';
              id: UUID;
              created: Time;
              author: {
                __typename?: 'User';
                status: UserStatus;
                email: string;
                id: UUID;
                jobTitle: string;
                name: string;
                phone?: string | null;
                picture: string;
                pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
              };
              text: {
                __typename?: 'MentionableText';
                contents: string;
                mentions?: Array<{
                  __typename?: 'Mention';
                  id: UUID;
                  position: number;
                  text: string;
                  user: { __typename?: 'User'; id: UUID };
                }> | null;
                itemReferences?: Array<{
                  __typename?: 'ItemReference';
                  id: UUID;
                  position: number;
                  text: string;
                  referencedItem?: {
                    __typename?: 'ItemsListItem';
                    createdAt: Time;
                    currentStatus: Status;
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    updateTime: Time;
                    visibility: Visibility;
                    creator: {
                      __typename?: 'UserLink';
                      id: UUID;
                      name: string;
                      status: UserStatus;
                    };
                    currentCost:
                      | { __typename?: 'CostRange'; min: USCents; max: USCents }
                      | { __typename?: 'CostScalar'; value: USCents };
                    currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                    options: Array<{
                      __typename?: 'ItemLink';
                      id: UUID;
                      name: string;
                      number: string;
                      status: Status;
                      visibility?: Visibility | null;
                    }>;
                  } | null;
                }> | null;
              };
            };
          }
        | {
            __typename?: 'NewItem';
            triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
            project: { __typename?: 'Project'; name: string; id: UUID };
            item: {
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              visibility?: Visibility | null;
            };
          }
        | {
            __typename?: 'NewMention';
            triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
            project: { __typename?: 'Project'; name: string; id: UUID };
            item: {
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              visibility?: Visibility | null;
            };
            comment: {
              __typename?: 'Comment';
              id: UUID;
              created: Time;
              author: {
                __typename?: 'User';
                status: UserStatus;
                email: string;
                id: UUID;
                jobTitle: string;
                name: string;
                phone?: string | null;
                picture: string;
                pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
              };
              text: {
                __typename?: 'MentionableText';
                contents: string;
                mentions?: Array<{
                  __typename?: 'Mention';
                  id: UUID;
                  position: number;
                  text: string;
                  user: { __typename?: 'User'; id: UUID };
                }> | null;
                itemReferences?: Array<{
                  __typename?: 'ItemReference';
                  id: UUID;
                  position: number;
                  text: string;
                  referencedItem?: {
                    __typename?: 'ItemsListItem';
                    createdAt: Time;
                    currentStatus: Status;
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    updateTime: Time;
                    visibility: Visibility;
                    creator: {
                      __typename?: 'UserLink';
                      id: UUID;
                      name: string;
                      status: UserStatus;
                    };
                    currentCost:
                      | { __typename?: 'CostRange'; min: USCents; max: USCents }
                      | { __typename?: 'CostScalar'; value: USCents };
                    currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                    options: Array<{
                      __typename?: 'ItemLink';
                      id: UUID;
                      name: string;
                      number: string;
                      status: Status;
                      visibility?: Visibility | null;
                    }>;
                  } | null;
                }> | null;
              };
            };
          }
        | {
            __typename?: 'NewOption';
            triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
            project: { __typename?: 'Project'; name: string; id: UUID };
            parentItem: {
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              visibility?: Visibility | null;
            };
            option: {
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              visibility?: Visibility | null;
            };
          }
        | {
            __typename?: 'NewReportMention';
            reportID: UUID;
            reportName: string;
            triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
            project: { __typename?: 'Project'; name: string; id: UUID };
            userReportComment: {
              __typename?: 'UserReportComment';
              commentLineID: string;
              type: UserReportCommentType;
              costs: Array<USCents>;
              comment: {
                __typename?: 'Comment';
                id: UUID;
                created: Time;
                author: {
                  __typename?: 'User';
                  status: UserStatus;
                  email: string;
                  id: UUID;
                  jobTitle: string;
                  name: string;
                  phone?: string | null;
                  picture: string;
                  pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
                };
                text: {
                  __typename?: 'MentionableText';
                  contents: string;
                  mentions?: Array<{
                    __typename?: 'Mention';
                    id: UUID;
                    position: number;
                    text: string;
                    user: { __typename?: 'User'; id: UUID };
                  }> | null;
                  itemReferences?: Array<{
                    __typename?: 'ItemReference';
                    id: UUID;
                    position: number;
                    text: string;
                    referencedItem?: {
                      __typename?: 'ItemsListItem';
                      createdAt: Time;
                      currentStatus: Status;
                      id: UUID;
                      name: string;
                      number: string;
                      status: Status;
                      updateTime: Time;
                      visibility: Visibility;
                      creator: {
                        __typename?: 'UserLink';
                        id: UUID;
                        name: string;
                        status: UserStatus;
                      };
                      currentCost:
                        | { __typename?: 'CostRange'; min: USCents; max: USCents }
                        | { __typename?: 'CostScalar'; value: USCents };
                      currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                      options: Array<{
                        __typename?: 'ItemLink';
                        id: UUID;
                        name: string;
                        number: string;
                        status: Status;
                        visibility?: Visibility | null;
                      }>;
                    } | null;
                  }> | null;
                };
              };
            };
          }
        | {
            __typename?: 'RemoveFromProject';
            triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
            project: { __typename?: 'Project'; name: string; id: UUID };
          }
        | {
            __typename?: 'ShareDraftItem';
            triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
            project: { __typename?: 'Project'; name: string; id: UUID };
            item: {
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              visibility?: Visibility | null;
            };
          }
        | {
            __typename?: 'ShareScenario';
            triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
            project: { __typename?: 'Project'; name: string; id: UUID };
            scenario: { __typename?: 'ScenarioLink'; id: UUID; name: string };
          };
    }> | null;
    paginationData: { __typename?: 'PaginationData'; nextOffsetID?: UUID | null };
  };
};

export type SetInAppNotificationReadMutationVariables = Exact<{
  notificationEventID: Scalars['UUID']['input'];
  isRead: Scalars['Boolean']['input'];
  costMode?: InputMaybe<CostMode>;
}>;

export type SetInAppNotificationReadMutation = {
  __typename?: 'Mutation';
  setInAppNotificationRead: {
    __typename?: 'InAppNotification';
    id: UUID;
    notificationType: NotificationType;
    projectID: UUID;
    createdAt: Time;
    readAt?: Time | null;
    content:
      | {
          __typename?: 'AssignItem';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          item: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
        }
      | {
          __typename?: 'AssignProjectLead';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
        }
      | {
          __typename?: 'InviteToProject';
          message?: string | null;
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
        }
      | {
          __typename?: 'NewComment';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          item: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
          comment: {
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          };
        }
      | {
          __typename?: 'NewItem';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          item: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
        }
      | {
          __typename?: 'NewMention';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          item: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
          comment: {
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          };
        }
      | {
          __typename?: 'NewOption';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          parentItem: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
          option: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
        }
      | {
          __typename?: 'NewReportMention';
          reportID: UUID;
          reportName: string;
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          userReportComment: {
            __typename?: 'UserReportComment';
            commentLineID: string;
            type: UserReportCommentType;
            costs: Array<USCents>;
            comment: {
              __typename?: 'Comment';
              id: UUID;
              created: Time;
              author: {
                __typename?: 'User';
                status: UserStatus;
                email: string;
                id: UUID;
                jobTitle: string;
                name: string;
                phone?: string | null;
                picture: string;
                pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
              };
              text: {
                __typename?: 'MentionableText';
                contents: string;
                mentions?: Array<{
                  __typename?: 'Mention';
                  id: UUID;
                  position: number;
                  text: string;
                  user: { __typename?: 'User'; id: UUID };
                }> | null;
                itemReferences?: Array<{
                  __typename?: 'ItemReference';
                  id: UUID;
                  position: number;
                  text: string;
                  referencedItem?: {
                    __typename?: 'ItemsListItem';
                    createdAt: Time;
                    currentStatus: Status;
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    updateTime: Time;
                    visibility: Visibility;
                    creator: {
                      __typename?: 'UserLink';
                      id: UUID;
                      name: string;
                      status: UserStatus;
                    };
                    currentCost:
                      | { __typename?: 'CostRange'; min: USCents; max: USCents }
                      | { __typename?: 'CostScalar'; value: USCents };
                    currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                    options: Array<{
                      __typename?: 'ItemLink';
                      id: UUID;
                      name: string;
                      number: string;
                      status: Status;
                      visibility?: Visibility | null;
                    }>;
                  } | null;
                }> | null;
              };
            };
          };
        }
      | {
          __typename?: 'RemoveFromProject';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
        }
      | {
          __typename?: 'ShareDraftItem';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          item: {
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            visibility?: Visibility | null;
          };
        }
      | {
          __typename?: 'ShareScenario';
          triggeringUser: { __typename?: 'User'; name: string; email: string; id: UUID };
          project: { __typename?: 'Project'; name: string; id: UUID };
          scenario: { __typename?: 'ScenarioLink'; id: UUID; name: string };
        };
  };
};

export type SetAllInAppNotificationsReadMutationVariables = Exact<{
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  allProjects: Scalars['Boolean']['input'];
}>;

export type SetAllInAppNotificationsReadMutation = {
  __typename?: 'Mutation';
  setAllInAppNotificationsRead: Array<UUID>;
};

export type ListCategoriesQueryVariables = Exact<{
  categorizationID: Scalars['UUID']['input'];
  term: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ListCategoriesQuery = {
  __typename?: 'Query';
  category: Array<{
    __typename?: 'Category';
    id: UUID;
    parentID?: UUID | null;
    name: string;
    hasSubCategories: boolean;
    number: string;
    level: number;
    error?: string | null;
    categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
  }>;
};

export type CopyItemMutationVariables = Exact<{
  itemID: Scalars['UUID']['input'];
  input: ItemCopyInput;
}>;

export type CopyItemMutation = {
  __typename?: 'Mutation';
  copyItem: { __typename?: 'CopyItem'; copyItemID: UUID };
};

export type LoadItemLinksQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type LoadItemLinksQuery = {
  __typename?: 'Query';
  itemLinks: Array<{
    __typename?: 'ItemLink';
    id: UUID;
    name: string;
    number: string;
    status: Status;
    visibility?: Visibility | null;
  }>;
};

export type LoadItemHistoryLinksQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type LoadItemHistoryLinksQuery = {
  __typename?: 'Query';
  itemHistoryLinks: Array<{
    __typename?: 'ItemHistoryLink';
    id: UUID;
    name: string;
    number: string;
    status: Status;
    deprecated: boolean;
    visibility?: Visibility | null;
  }>;
};

export type CreateItemEstimateMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  item: Scalars['UUID']['input'];
}>;

export type CreateItemEstimateMutation = {
  __typename?: 'Mutation';
  createItemEstimate?: { __typename?: 'EstimateCreationResult'; estimate: UUID } | null;
};

export type LinkIntegrationsEntityMutationVariables = Exact<{
  sourceSystem: SourceSystem;
  sourceEntityType: SourceType;
  sourceEntityID: Scalars['String']['input'];
  sourceCompanyID?: InputMaybe<Scalars['String']['input']>;
  projectID: Scalars['UUID']['input'];
}>;

export type LinkIntegrationsEntityMutation = {
  __typename?: 'Mutation';
  linkIntegrationsEntity: boolean;
};

export type SourceLinkedProjectQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  source: SourceSystem;
  sourceID?: InputMaybe<Scalars['UUID']['input']>;
  sourceUserEmail?: InputMaybe<Scalars['String']['input']>;
}>;

export type SourceLinkedProjectQuery = {
  __typename?: 'Query';
  sourceLinkedProject?: {
    __typename?: 'SourceLinkedProject';
    sourceProjectID: string;
    sourceCompanyID: string;
    canCreateItemIntegrationObject: boolean;
  } | null;
};

export type ContingencyDrawEventMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
  itemID: Scalars['UUID']['input'];
  drawFromID: Scalars['UUID']['input'];
  value: Scalars['Numeric']['input'];
  isFirst: Scalars['Boolean']['input'];
  markupType: MarkupType;
  currentValue: Scalars['Numeric']['input'];
}>;

export type ContingencyDrawEventMutation = {
  __typename?: 'Mutation';
  contingencyDrawEvent: boolean;
};

export type CreateContingencyDrawMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
  itemID: Scalars['UUID']['input'];
  contingencyID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type CreateContingencyDrawMutation = {
  __typename?: 'Mutation';
  createContingencyDraw: boolean;
};

export type GetItemContingenciesQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  itemID: Scalars['UUID']['input'];
  costMode?: InputMaybe<CostMode>;
}>;

export type GetItemContingenciesQuery = {
  __typename?: 'Query';
  getItemContingencies: {
    __typename?: 'ItemContingency';
    draws: Array<{
      __typename?: 'ItemDrawInfo';
      id: UUID;
      type: MarkupDisplayType;
      name: string;
      error: string;
      draw: USCents;
      drawFromID?: UUID | null;
      isDrawingFullAmount: boolean;
    }>;
    availableMilestoneContingencies: Array<{
      __typename?: 'MilestoneContingencyInfo';
      id: UUID;
      type: MarkupDisplayType;
      name: string;
      error: string;
      total: USCents;
      isBeingDrawn?: boolean | null;
    }>;
  };
};

export type SetItemAssigneeMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  assigneeEmail?: InputMaybe<Scalars['String']['input']>;
  costMode: CostMode;
}>;

export type SetItemAssigneeMutation = {
  __typename?: 'Mutation';
  setItemAssignee:
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | {
        __typename?: 'Option';
        parent: UUID;
        parentVisibility?: Visibility | null;
        hasEstimate?: boolean | null;
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      };
};

export type RemoveItemCategoryMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  category: Scalars['UUID']['input'];
  costMode: CostMode;
}>;

export type RemoveItemCategoryMutation = {
  __typename?: 'Mutation';
  removeItemCategory:
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | {
        __typename?: 'Option';
        parent: UUID;
        parentVisibility?: Visibility | null;
        hasEstimate?: boolean | null;
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      };
};

export type SetItemCategoryMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  category: Scalars['UUID']['input'];
  costMode: CostMode;
}>;

export type SetItemCategoryMutation = {
  __typename?: 'Mutation';
  setItemCategory:
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | {
        __typename?: 'Option';
        parent: UUID;
        parentVisibility?: Visibility | null;
        hasEstimate?: boolean | null;
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      };
};

export type SetItemDueDateMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  dueDate?: InputMaybe<Scalars['Time']['input']>;
  costMode: CostMode;
}>;

export type SetItemDueDateMutation = {
  __typename?: 'Mutation';
  setItemDueDate:
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | {
        __typename?: 'Option';
        parent: UUID;
        parentVisibility?: Visibility | null;
        hasEstimate?: boolean | null;
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      };
};

export type SetItemNumberMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  number: Scalars['String']['input'];
  costMode: CostMode;
}>;

export type SetItemNumberMutation = {
  __typename?: 'Mutation';
  setItemNumber:
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | {
        __typename?: 'Option';
        parent: UUID;
        parentVisibility?: Visibility | null;
        hasEstimate?: boolean | null;
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      };
};

export type SetItemPublishedMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  createdBy?: InputMaybe<Scalars['UUID']['input']>;
  costMode: CostMode;
  activeMilestoneID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type SetItemPublishedMutation = {
  __typename?: 'Mutation';
  setItemPublished:
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | {
        __typename?: 'Option';
        parent: UUID;
        parentVisibility?: Visibility | null;
        hasEstimate?: boolean | null;
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      };
};

export type SetItemMilestoneMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  milestone?: InputMaybe<Scalars['UUID']['input']>;
  status: Status;
  costMode: CostMode;
}>;

export type SetItemMilestoneMutation = {
  __typename?: 'Mutation';
  setItemMilestone:
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | {
        __typename?: 'Option';
        parent: UUID;
        parentVisibility?: Visibility | null;
        hasEstimate?: boolean | null;
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      };
};

export type SetItemActivityMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  activityID?: InputMaybe<Scalars['UUID']['input']>;
  costMode: CostMode;
}>;

export type SetItemActivityMutation = {
  __typename?: 'Mutation';
  setItemActivity:
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | {
        __typename?: 'Option';
        parent: UUID;
        parentVisibility?: Visibility | null;
        hasEstimate?: boolean | null;
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      };
};

export type UnsetItemActivityMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  activityID?: InputMaybe<Scalars['UUID']['input']>;
  costMode: CostMode;
}>;

export type UnsetItemActivityMutation = {
  __typename?: 'Mutation';
  unsetItemActivity:
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | {
        __typename?: 'Option';
        parent: UUID;
        parentVisibility?: Visibility | null;
        hasEstimate?: boolean | null;
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      };
};

export type SetStatusMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  itemStatusInput: SetStatusInput;
  costMode: CostMode;
}>;

export type SetStatusMutation = {
  __typename?: 'Mutation';
  setStatus:
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | {
        __typename?: 'Option';
        parent: UUID;
        parentVisibility?: Visibility | null;
        hasEstimate?: boolean | null;
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      };
};

export type ConvertItemToItemWithOptionsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  item: Scalars['UUID']['input'];
  costMode: CostMode;
}>;

export type ConvertItemToItemWithOptionsMutation = {
  __typename?: 'Mutation';
  convertItemToItemWithOptions?: {
    __typename?: 'Item';
    id: UUID;
    itemType: ItemType;
    description: string;
    descriptionStyled: string;
    dueDate?: Time | null;
    creationTime?: Time | null;
    updateTime?: Time | null;
    name: string;
    number: string;
    status: Status;
    visibility: Visibility;
    availableStates: Array<Status>;
    assetCount: number;
    remoteAssetCount: number;
    commentCount: number;
    filteredMilestone?: UUID | null;
    filteredCategories?: Array<UUID> | null;
    activityIDs?: Array<UUID> | null;
    options: Array<{
      __typename?: 'Option';
      parent: UUID;
      parentVisibility?: Visibility | null;
      hasEstimate?: boolean | null;
      id: UUID;
      itemType: ItemType;
      description: string;
      descriptionStyled: string;
      dueDate?: Time | null;
      creationTime?: Time | null;
      updateTime?: Time | null;
      name: string;
      number: string;
      status: Status;
      visibility: Visibility;
      availableStates: Array<Status>;
      assetCount: number;
      remoteAssetCount: number;
      commentCount: number;
      filteredMilestone?: UUID | null;
      filteredCategories?: Array<UUID> | null;
      activityIDs?: Array<UUID> | null;
      comments: Array<{
        __typename?: 'Comment';
        id: UUID;
        created: Time;
        author: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        };
        text: {
          __typename?: 'MentionableText';
          contents: string;
          mentions?: Array<{
            __typename?: 'Mention';
            id: UUID;
            position: number;
            text: string;
            user: { __typename?: 'User'; id: UUID };
          }> | null;
          itemReferences?: Array<{
            __typename?: 'ItemReference';
            id: UUID;
            position: number;
            text: string;
            referencedItem?: {
              __typename?: 'ItemsListItem';
              createdAt: Time;
              currentStatus: Status;
              id: UUID;
              name: string;
              number: string;
              status: Status;
              updateTime: Time;
              visibility: Visibility;
              creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
              currentCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
              options: Array<{
                __typename?: 'ItemLink';
                id: UUID;
                name: string;
                number: string;
                status: Status;
                visibility?: Visibility | null;
              }>;
            } | null;
          }> | null;
        };
      }>;
      assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
      activeEstimate?: {
        __typename?: 'Estimate';
        id: UUID;
        name: string;
        subtotal: Numeric;
        markupSubtotal: USCents;
        drawSubtotal: USCents;
        inheritedSubtotal: USCents;
        inheritedOwnerCostMarkupsSubtotal: USCents;
        asset?: UUID | null;
        totalType: EstimateTotalType;
        isRunningTotal: boolean;
        lines: Array<{
          __typename?: 'Line';
          id: UUID;
          estimateId: UUID;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          cells: Array<{
            __typename?: 'Cell';
            estimateId: UUID;
            field: UUID;
            line: UUID;
            error?: string | null;
            value?:
              | {
                  __typename?: 'CategoryCell';
                  search: string;
                  category?: {
                    __typename?: 'Category';
                    id: UUID;
                    parentID?: UUID | null;
                    name: string;
                    hasSubCategories: boolean;
                    number: string;
                    level: number;
                    error?: string | null;
                    categorization?: {
                      __typename?: 'Categorization';
                      id: UUID;
                      name: string;
                    } | null;
                    levels: Array<{
                      __typename?: 'Category';
                      id: UUID;
                      name: string;
                      number: string;
                    }>;
                  } | null;
                }
              | { __typename?: 'RegularCell'; string: string }
              | {
                  __typename?: 'SourceCell';
                  sourceIDs?: {
                    __typename?: 'SourceIDs';
                    estimateID: UUID;
                    itemID?: UUID | null;
                    lineID?: UUID | null;
                    markupID?: UUID | null;
                    milestoneID: UUID;
                  } | null;
                }
              | null;
          }>;
        }>;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
        incorporatedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        contingencyDraws?: Array<{
          __typename?: 'ItemDrawInfo';
          id: UUID;
          type: MarkupDisplayType;
          name: string;
          error: string;
          draw: USCents;
          drawFromID?: UUID | null;
          isDrawingFullAmount: boolean;
        }> | null;
        inheritedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedOwnerCostMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        markups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
      } | null;
      bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
      project: { __typename?: 'Project'; id: UUID; name: string };
      createdBy?: {
        __typename?: 'User';
        id: UUID;
        email: string;
        name: string;
        picture: string;
      } | null;
      milestone?: {
        __typename?: 'Milestone';
        id: UUID;
        name: string;
        date?: Time | null;
        buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
      } | null;
      categories: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        error?: string | null;
        level: number;
        hasSubCategories: boolean;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          builtin: boolean;
          description: string;
          draft: boolean;
          levels: number;
          deprecated: boolean;
        } | null;
        levels: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          hasSubCategories: boolean;
          level: number;
        }>;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      assignee?: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null;
      previousMilestoneStatus: Array<{
        __typename?: 'PreviousMilestoneStatus';
        hasEstimate?: boolean | null;
        milestoneID: UUID;
        itemType: ItemType;
        status: Status;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
      }>;
      scheduleImpact?: {
        __typename?: 'ScheduleImpact';
        type: ScheduleImpactType;
        criticalPath: boolean;
        days: number;
        minImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
        maxImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
      } | null;
    }>;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    comments: Array<{
      __typename?: 'Comment';
      id: UUID;
      created: Time;
      author: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      text: {
        __typename?: 'MentionableText';
        contents: string;
        mentions?: Array<{
          __typename?: 'Mention';
          id: UUID;
          position: number;
          text: string;
          user: { __typename?: 'User'; id: UUID };
        }> | null;
        itemReferences?: Array<{
          __typename?: 'ItemReference';
          id: UUID;
          position: number;
          text: string;
          referencedItem?: {
            __typename?: 'ItemsListItem';
            createdAt: Time;
            currentStatus: Status;
            id: UUID;
            name: string;
            number: string;
            status: Status;
            updateTime: Time;
            visibility: Visibility;
            creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
            currentCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
            options: Array<{
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              status: Status;
              visibility?: Visibility | null;
            }>;
          } | null;
        }> | null;
      };
    }>;
    assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
    activeEstimate?: {
      __typename?: 'Estimate';
      id: UUID;
      name: string;
      subtotal: Numeric;
      markupSubtotal: USCents;
      drawSubtotal: USCents;
      inheritedSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      asset?: UUID | null;
      totalType: EstimateTotalType;
      isRunningTotal: boolean;
      lines: Array<{
        __typename?: 'Line';
        id: UUID;
        estimateId: UUID;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        cells: Array<{
          __typename?: 'Cell';
          estimateId: UUID;
          field: UUID;
          line: UUID;
          error?: string | null;
          value?:
            | {
                __typename?: 'CategoryCell';
                search: string;
                category?: {
                  __typename?: 'Category';
                  id: UUID;
                  parentID?: UUID | null;
                  name: string;
                  hasSubCategories: boolean;
                  number: string;
                  level: number;
                  error?: string | null;
                  categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                  levels: Array<{
                    __typename?: 'Category';
                    id: UUID;
                    name: string;
                    number: string;
                  }>;
                } | null;
              }
            | { __typename?: 'RegularCell'; string: string }
            | {
                __typename?: 'SourceCell';
                sourceIDs?: {
                  __typename?: 'SourceIDs';
                  estimateID: UUID;
                  itemID?: UUID | null;
                  lineID?: UUID | null;
                  markupID?: UUID | null;
                  milestoneID: UUID;
                } | null;
              }
            | null;
        }>;
      }>;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      contingencyDraws?: Array<{
        __typename?: 'ItemDrawInfo';
        id: UUID;
        type: MarkupDisplayType;
        name: string;
        error: string;
        draw: USCents;
        drawFromID?: UUID | null;
        isDrawingFullAmount: boolean;
      }> | null;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
    } | null;
    bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
    project: { __typename?: 'Project'; id: UUID; name: string };
    createdBy?: {
      __typename?: 'User';
      id: UUID;
      email: string;
      name: string;
      picture: string;
    } | null;
    milestone?: {
      __typename?: 'Milestone';
      id: UUID;
      name: string;
      date?: Time | null;
      buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
    } | null;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    assignee?: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
    previousMilestoneStatus: Array<{
      __typename?: 'PreviousMilestoneStatus';
      hasEstimate?: boolean | null;
      milestoneID: UUID;
      itemType: ItemType;
      status: Status;
      optionSubtotals: Array<{
        __typename?: 'OptionSubtotal';
        status: Status;
        adds: USCents;
        deducts: USCents;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
    }>;
    scheduleImpact?: {
      __typename?: 'ScheduleImpact';
      type: ScheduleImpactType;
      criticalPath: boolean;
      days: number;
      minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
      maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    } | null;
  } | null;
};

export type AttachItemAsOptionMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  parent: Scalars['UUID']['input'];
  child: Scalars['UUID']['input'];
  costMode: CostMode;
}>;

export type AttachItemAsOptionMutation = {
  __typename?: 'Mutation';
  attachItemAsOption?: {
    __typename?: 'Item';
    id: UUID;
    itemType: ItemType;
    description: string;
    descriptionStyled: string;
    dueDate?: Time | null;
    creationTime?: Time | null;
    updateTime?: Time | null;
    name: string;
    number: string;
    status: Status;
    visibility: Visibility;
    availableStates: Array<Status>;
    assetCount: number;
    remoteAssetCount: number;
    commentCount: number;
    filteredMilestone?: UUID | null;
    filteredCategories?: Array<UUID> | null;
    activityIDs?: Array<UUID> | null;
    options: Array<{
      __typename?: 'Option';
      parent: UUID;
      parentVisibility?: Visibility | null;
      hasEstimate?: boolean | null;
      id: UUID;
      itemType: ItemType;
      description: string;
      descriptionStyled: string;
      dueDate?: Time | null;
      creationTime?: Time | null;
      updateTime?: Time | null;
      name: string;
      number: string;
      status: Status;
      visibility: Visibility;
      availableStates: Array<Status>;
      assetCount: number;
      remoteAssetCount: number;
      commentCount: number;
      filteredMilestone?: UUID | null;
      filteredCategories?: Array<UUID> | null;
      activityIDs?: Array<UUID> | null;
      comments: Array<{
        __typename?: 'Comment';
        id: UUID;
        created: Time;
        author: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        };
        text: {
          __typename?: 'MentionableText';
          contents: string;
          mentions?: Array<{
            __typename?: 'Mention';
            id: UUID;
            position: number;
            text: string;
            user: { __typename?: 'User'; id: UUID };
          }> | null;
          itemReferences?: Array<{
            __typename?: 'ItemReference';
            id: UUID;
            position: number;
            text: string;
            referencedItem?: {
              __typename?: 'ItemsListItem';
              createdAt: Time;
              currentStatus: Status;
              id: UUID;
              name: string;
              number: string;
              status: Status;
              updateTime: Time;
              visibility: Visibility;
              creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
              currentCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
              options: Array<{
                __typename?: 'ItemLink';
                id: UUID;
                name: string;
                number: string;
                status: Status;
                visibility?: Visibility | null;
              }>;
            } | null;
          }> | null;
        };
      }>;
      assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
      activeEstimate?: {
        __typename?: 'Estimate';
        id: UUID;
        name: string;
        subtotal: Numeric;
        markupSubtotal: USCents;
        drawSubtotal: USCents;
        inheritedSubtotal: USCents;
        inheritedOwnerCostMarkupsSubtotal: USCents;
        asset?: UUID | null;
        totalType: EstimateTotalType;
        isRunningTotal: boolean;
        lines: Array<{
          __typename?: 'Line';
          id: UUID;
          estimateId: UUID;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          cells: Array<{
            __typename?: 'Cell';
            estimateId: UUID;
            field: UUID;
            line: UUID;
            error?: string | null;
            value?:
              | {
                  __typename?: 'CategoryCell';
                  search: string;
                  category?: {
                    __typename?: 'Category';
                    id: UUID;
                    parentID?: UUID | null;
                    name: string;
                    hasSubCategories: boolean;
                    number: string;
                    level: number;
                    error?: string | null;
                    categorization?: {
                      __typename?: 'Categorization';
                      id: UUID;
                      name: string;
                    } | null;
                    levels: Array<{
                      __typename?: 'Category';
                      id: UUID;
                      name: string;
                      number: string;
                    }>;
                  } | null;
                }
              | { __typename?: 'RegularCell'; string: string }
              | {
                  __typename?: 'SourceCell';
                  sourceIDs?: {
                    __typename?: 'SourceIDs';
                    estimateID: UUID;
                    itemID?: UUID | null;
                    lineID?: UUID | null;
                    markupID?: UUID | null;
                    milestoneID: UUID;
                  } | null;
                }
              | null;
          }>;
        }>;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
        incorporatedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        contingencyDraws?: Array<{
          __typename?: 'ItemDrawInfo';
          id: UUID;
          type: MarkupDisplayType;
          name: string;
          error: string;
          draw: USCents;
          drawFromID?: UUID | null;
          isDrawingFullAmount: boolean;
        }> | null;
        inheritedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedOwnerCostMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        markups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
      } | null;
      bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
      project: { __typename?: 'Project'; id: UUID; name: string };
      createdBy?: {
        __typename?: 'User';
        id: UUID;
        email: string;
        name: string;
        picture: string;
      } | null;
      milestone?: {
        __typename?: 'Milestone';
        id: UUID;
        name: string;
        date?: Time | null;
        buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
      } | null;
      categories: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        error?: string | null;
        level: number;
        hasSubCategories: boolean;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          builtin: boolean;
          description: string;
          draft: boolean;
          levels: number;
          deprecated: boolean;
        } | null;
        levels: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          hasSubCategories: boolean;
          level: number;
        }>;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      assignee?: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null;
      previousMilestoneStatus: Array<{
        __typename?: 'PreviousMilestoneStatus';
        hasEstimate?: boolean | null;
        milestoneID: UUID;
        itemType: ItemType;
        status: Status;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
      }>;
      scheduleImpact?: {
        __typename?: 'ScheduleImpact';
        type: ScheduleImpactType;
        criticalPath: boolean;
        days: number;
        minImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
        maxImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
      } | null;
    }>;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    comments: Array<{
      __typename?: 'Comment';
      id: UUID;
      created: Time;
      author: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      text: {
        __typename?: 'MentionableText';
        contents: string;
        mentions?: Array<{
          __typename?: 'Mention';
          id: UUID;
          position: number;
          text: string;
          user: { __typename?: 'User'; id: UUID };
        }> | null;
        itemReferences?: Array<{
          __typename?: 'ItemReference';
          id: UUID;
          position: number;
          text: string;
          referencedItem?: {
            __typename?: 'ItemsListItem';
            createdAt: Time;
            currentStatus: Status;
            id: UUID;
            name: string;
            number: string;
            status: Status;
            updateTime: Time;
            visibility: Visibility;
            creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
            currentCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
            options: Array<{
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              status: Status;
              visibility?: Visibility | null;
            }>;
          } | null;
        }> | null;
      };
    }>;
    assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
    activeEstimate?: {
      __typename?: 'Estimate';
      id: UUID;
      name: string;
      subtotal: Numeric;
      markupSubtotal: USCents;
      drawSubtotal: USCents;
      inheritedSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      asset?: UUID | null;
      totalType: EstimateTotalType;
      isRunningTotal: boolean;
      lines: Array<{
        __typename?: 'Line';
        id: UUID;
        estimateId: UUID;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        cells: Array<{
          __typename?: 'Cell';
          estimateId: UUID;
          field: UUID;
          line: UUID;
          error?: string | null;
          value?:
            | {
                __typename?: 'CategoryCell';
                search: string;
                category?: {
                  __typename?: 'Category';
                  id: UUID;
                  parentID?: UUID | null;
                  name: string;
                  hasSubCategories: boolean;
                  number: string;
                  level: number;
                  error?: string | null;
                  categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                  levels: Array<{
                    __typename?: 'Category';
                    id: UUID;
                    name: string;
                    number: string;
                  }>;
                } | null;
              }
            | { __typename?: 'RegularCell'; string: string }
            | {
                __typename?: 'SourceCell';
                sourceIDs?: {
                  __typename?: 'SourceIDs';
                  estimateID: UUID;
                  itemID?: UUID | null;
                  lineID?: UUID | null;
                  markupID?: UUID | null;
                  milestoneID: UUID;
                } | null;
              }
            | null;
        }>;
      }>;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      contingencyDraws?: Array<{
        __typename?: 'ItemDrawInfo';
        id: UUID;
        type: MarkupDisplayType;
        name: string;
        error: string;
        draw: USCents;
        drawFromID?: UUID | null;
        isDrawingFullAmount: boolean;
      }> | null;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
    } | null;
    bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
    project: { __typename?: 'Project'; id: UUID; name: string };
    createdBy?: {
      __typename?: 'User';
      id: UUID;
      email: string;
      name: string;
      picture: string;
    } | null;
    milestone?: {
      __typename?: 'Milestone';
      id: UUID;
      name: string;
      date?: Time | null;
      buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
    } | null;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    assignee?: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
    previousMilestoneStatus: Array<{
      __typename?: 'PreviousMilestoneStatus';
      hasEstimate?: boolean | null;
      milestoneID: UUID;
      itemType: ItemType;
      status: Status;
      optionSubtotals: Array<{
        __typename?: 'OptionSubtotal';
        status: Status;
        adds: USCents;
        deducts: USCents;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
    }>;
    scheduleImpact?: {
      __typename?: 'ScheduleImpact';
      type: ScheduleImpactType;
      criticalPath: boolean;
      days: number;
      minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
      maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    } | null;
  } | null;
};

export type DetachOptionMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  option: Scalars['UUID']['input'];
  costMode: CostMode;
}>;

export type DetachOptionMutation = {
  __typename?: 'Mutation';
  detachOption: Array<
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | { __typename?: 'Option' }
  >;
};

export type SetItemNameMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  costMode: CostMode;
}>;

export type SetItemNameMutation = {
  __typename?: 'Mutation';
  setItemName:
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | {
        __typename?: 'Option';
        parent: UUID;
        parentVisibility?: Visibility | null;
        hasEstimate?: boolean | null;
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      };
};

export type SetItemDescriptionMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  description: Scalars['String']['input'];
  descriptionStyled: Scalars['String']['input'];
  costMode: CostMode;
}>;

export type SetItemDescriptionMutation = {
  __typename?: 'Mutation';
  setItemDescription:
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | {
        __typename?: 'Option';
        parent: UUID;
        parentVisibility?: Visibility | null;
        hasEstimate?: boolean | null;
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      };
};

export type UpdateScheduleImpactMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  itemID: Scalars['UUID']['input'];
  type: Scalars['ScheduleImpactType']['input'];
  criticalPath: Scalars['Boolean']['input'];
  days: Scalars['Int']['input'];
}>;

export type UpdateScheduleImpactMutation = {
  __typename?: 'Mutation';
  updateScheduleImpact?: {
    __typename?: 'UpdateScheduleImpactPayload';
    item?:
      | {
          __typename?: 'Item';
          id: UUID;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }
      | {
          __typename?: 'Option';
          id: UUID;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }
      | null;
  } | null;
};

export type CreateItemMutationVariables = Exact<{
  activityIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  name: Scalars['String']['input'];
  costImpact?: InputMaybe<Scalars['USCents']['input']>;
  bucketID?: InputMaybe<Scalars['UUID']['input']>;
  milestoneID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  categories?: InputMaybe<Array<CategoryInput>>;
  assigneeEmail?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Time']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionStyled?: InputMaybe<Scalars['String']['input']>;
  costMode: CostMode;
  visibility?: InputMaybe<Visibility>;
}>;

export type CreateItemMutation = {
  __typename?: 'Mutation';
  createItem?: {
    __typename?: 'Item';
    id: UUID;
    itemType: ItemType;
    description: string;
    descriptionStyled: string;
    dueDate?: Time | null;
    creationTime?: Time | null;
    updateTime?: Time | null;
    name: string;
    number: string;
    status: Status;
    visibility: Visibility;
    availableStates: Array<Status>;
    assetCount: number;
    remoteAssetCount: number;
    commentCount: number;
    filteredMilestone?: UUID | null;
    filteredCategories?: Array<UUID> | null;
    activityIDs?: Array<UUID> | null;
    options: Array<{
      __typename?: 'Option';
      parent: UUID;
      parentVisibility?: Visibility | null;
      hasEstimate?: boolean | null;
      id: UUID;
      itemType: ItemType;
      description: string;
      descriptionStyled: string;
      dueDate?: Time | null;
      creationTime?: Time | null;
      updateTime?: Time | null;
      name: string;
      number: string;
      status: Status;
      visibility: Visibility;
      availableStates: Array<Status>;
      assetCount: number;
      remoteAssetCount: number;
      commentCount: number;
      filteredMilestone?: UUID | null;
      filteredCategories?: Array<UUID> | null;
      activityIDs?: Array<UUID> | null;
      comments: Array<{
        __typename?: 'Comment';
        id: UUID;
        created: Time;
        author: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        };
        text: {
          __typename?: 'MentionableText';
          contents: string;
          mentions?: Array<{
            __typename?: 'Mention';
            id: UUID;
            position: number;
            text: string;
            user: { __typename?: 'User'; id: UUID };
          }> | null;
          itemReferences?: Array<{
            __typename?: 'ItemReference';
            id: UUID;
            position: number;
            text: string;
            referencedItem?: {
              __typename?: 'ItemsListItem';
              createdAt: Time;
              currentStatus: Status;
              id: UUID;
              name: string;
              number: string;
              status: Status;
              updateTime: Time;
              visibility: Visibility;
              creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
              currentCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
              options: Array<{
                __typename?: 'ItemLink';
                id: UUID;
                name: string;
                number: string;
                status: Status;
                visibility?: Visibility | null;
              }>;
            } | null;
          }> | null;
        };
      }>;
      assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
      activeEstimate?: {
        __typename?: 'Estimate';
        id: UUID;
        name: string;
        subtotal: Numeric;
        markupSubtotal: USCents;
        drawSubtotal: USCents;
        inheritedSubtotal: USCents;
        inheritedOwnerCostMarkupsSubtotal: USCents;
        asset?: UUID | null;
        totalType: EstimateTotalType;
        isRunningTotal: boolean;
        lines: Array<{
          __typename?: 'Line';
          id: UUID;
          estimateId: UUID;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          cells: Array<{
            __typename?: 'Cell';
            estimateId: UUID;
            field: UUID;
            line: UUID;
            error?: string | null;
            value?:
              | {
                  __typename?: 'CategoryCell';
                  search: string;
                  category?: {
                    __typename?: 'Category';
                    id: UUID;
                    parentID?: UUID | null;
                    name: string;
                    hasSubCategories: boolean;
                    number: string;
                    level: number;
                    error?: string | null;
                    categorization?: {
                      __typename?: 'Categorization';
                      id: UUID;
                      name: string;
                    } | null;
                    levels: Array<{
                      __typename?: 'Category';
                      id: UUID;
                      name: string;
                      number: string;
                    }>;
                  } | null;
                }
              | { __typename?: 'RegularCell'; string: string }
              | {
                  __typename?: 'SourceCell';
                  sourceIDs?: {
                    __typename?: 'SourceIDs';
                    estimateID: UUID;
                    itemID?: UUID | null;
                    lineID?: UUID | null;
                    markupID?: UUID | null;
                    milestoneID: UUID;
                  } | null;
                }
              | null;
          }>;
        }>;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
        incorporatedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        contingencyDraws?: Array<{
          __typename?: 'ItemDrawInfo';
          id: UUID;
          type: MarkupDisplayType;
          name: string;
          error: string;
          draw: USCents;
          drawFromID?: UUID | null;
          isDrawingFullAmount: boolean;
        }> | null;
        inheritedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedOwnerCostMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        markups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
      } | null;
      bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
      project: { __typename?: 'Project'; id: UUID; name: string };
      createdBy?: {
        __typename?: 'User';
        id: UUID;
        email: string;
        name: string;
        picture: string;
      } | null;
      milestone?: {
        __typename?: 'Milestone';
        id: UUID;
        name: string;
        date?: Time | null;
        buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
      } | null;
      categories: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        error?: string | null;
        level: number;
        hasSubCategories: boolean;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          builtin: boolean;
          description: string;
          draft: boolean;
          levels: number;
          deprecated: boolean;
        } | null;
        levels: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          hasSubCategories: boolean;
          level: number;
        }>;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      assignee?: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null;
      previousMilestoneStatus: Array<{
        __typename?: 'PreviousMilestoneStatus';
        hasEstimate?: boolean | null;
        milestoneID: UUID;
        itemType: ItemType;
        status: Status;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
      }>;
      scheduleImpact?: {
        __typename?: 'ScheduleImpact';
        type: ScheduleImpactType;
        criticalPath: boolean;
        days: number;
        minImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
        maxImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
      } | null;
    }>;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    comments: Array<{
      __typename?: 'Comment';
      id: UUID;
      created: Time;
      author: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      text: {
        __typename?: 'MentionableText';
        contents: string;
        mentions?: Array<{
          __typename?: 'Mention';
          id: UUID;
          position: number;
          text: string;
          user: { __typename?: 'User'; id: UUID };
        }> | null;
        itemReferences?: Array<{
          __typename?: 'ItemReference';
          id: UUID;
          position: number;
          text: string;
          referencedItem?: {
            __typename?: 'ItemsListItem';
            createdAt: Time;
            currentStatus: Status;
            id: UUID;
            name: string;
            number: string;
            status: Status;
            updateTime: Time;
            visibility: Visibility;
            creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
            currentCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
            options: Array<{
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              status: Status;
              visibility?: Visibility | null;
            }>;
          } | null;
        }> | null;
      };
    }>;
    assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
    activeEstimate?: {
      __typename?: 'Estimate';
      id: UUID;
      name: string;
      subtotal: Numeric;
      markupSubtotal: USCents;
      drawSubtotal: USCents;
      inheritedSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      asset?: UUID | null;
      totalType: EstimateTotalType;
      isRunningTotal: boolean;
      lines: Array<{
        __typename?: 'Line';
        id: UUID;
        estimateId: UUID;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        cells: Array<{
          __typename?: 'Cell';
          estimateId: UUID;
          field: UUID;
          line: UUID;
          error?: string | null;
          value?:
            | {
                __typename?: 'CategoryCell';
                search: string;
                category?: {
                  __typename?: 'Category';
                  id: UUID;
                  parentID?: UUID | null;
                  name: string;
                  hasSubCategories: boolean;
                  number: string;
                  level: number;
                  error?: string | null;
                  categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                  levels: Array<{
                    __typename?: 'Category';
                    id: UUID;
                    name: string;
                    number: string;
                  }>;
                } | null;
              }
            | { __typename?: 'RegularCell'; string: string }
            | {
                __typename?: 'SourceCell';
                sourceIDs?: {
                  __typename?: 'SourceIDs';
                  estimateID: UUID;
                  itemID?: UUID | null;
                  lineID?: UUID | null;
                  markupID?: UUID | null;
                  milestoneID: UUID;
                } | null;
              }
            | null;
        }>;
      }>;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      contingencyDraws?: Array<{
        __typename?: 'ItemDrawInfo';
        id: UUID;
        type: MarkupDisplayType;
        name: string;
        error: string;
        draw: USCents;
        drawFromID?: UUID | null;
        isDrawingFullAmount: boolean;
      }> | null;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
    } | null;
    bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
    project: { __typename?: 'Project'; id: UUID; name: string };
    createdBy?: {
      __typename?: 'User';
      id: UUID;
      email: string;
      name: string;
      picture: string;
    } | null;
    milestone?: {
      __typename?: 'Milestone';
      id: UUID;
      name: string;
      date?: Time | null;
      buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
    } | null;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    assignee?: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
    previousMilestoneStatus: Array<{
      __typename?: 'PreviousMilestoneStatus';
      hasEstimate?: boolean | null;
      milestoneID: UUID;
      itemType: ItemType;
      status: Status;
      optionSubtotals: Array<{
        __typename?: 'OptionSubtotal';
        status: Status;
        adds: USCents;
        deducts: USCents;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
    }>;
    scheduleImpact?: {
      __typename?: 'ScheduleImpact';
      type: ScheduleImpactType;
      criticalPath: boolean;
      days: number;
      minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
      maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    } | null;
  } | null;
};

export type CreateItemsFromImportMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  assetID: Scalars['UUID']['input'];
  visibility: Visibility;
}>;

export type CreateItemsFromImportMutation = {
  __typename?: 'Mutation';
  createItemsFromImport?: {
    __typename?: 'CreateItemsFromImportPayload';
    userErrors: Array<string>;
  } | null;
};

export type RemoveItemMilestoneHistoryMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  milestone: Scalars['UUID']['input'];
}>;

export type RemoveItemMilestoneHistoryMutation = {
  __typename?: 'Mutation';
  removeItemMilestoneHistory: {
    __typename?: 'EventData';
    events: Array<{
      __typename?: 'EventDataItem';
      timestamp: Time;
      eventType: EventType;
      currentItemNumber: string;
      ids: Array<UUID>;
      visible?: boolean | null;
      item: {
        __typename?: 'EventItem';
        id: UUID;
        name: string;
        number: string;
        status: Status;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        milestone?: { __typename?: 'Milestone'; id: UUID } | null;
      };
      user: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      costImpact: {
        __typename?: 'EventCostImpact';
        minPending: USCents;
        maxPending: USCents;
        cost: USCents;
      };
      eventContent: {
        __typename?: 'EventContent';
        oldMilestone?: UUID | null;
        newMilestone?: UUID | null;
        oldOptionStatus?: Status | null;
        newOptionStatus?: Status | null;
        oldStatus?: Status | null;
        newStatus?: Status | null;
        oldCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        newCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        option?: {
          __typename?: 'ItemSummary';
          id: UUID;
          number: string;
          currentNumber: string;
          status: Status;
        } | null;
        options?: Array<{
          __typename?: 'ItemSummary';
          id: UUID;
          number: string;
          currentNumber: string;
          status: Status;
        }> | null;
        parent?: {
          __typename?: 'ItemSummary';
          id: UUID;
          number: string;
          currentNumber: string;
          status: Status;
        } | null;
      };
    }>;
  };
};

export type DeleteItemEstimateMutationVariables = Exact<{
  input: DeleteItemEstimateInput;
}>;

export type DeleteItemEstimateMutation = {
  __typename?: 'Mutation';
  deleteItemEstimate: { __typename?: 'DeleteItemEstimatePayload'; itemID?: UUID | null };
};

export type ItemDataQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  costMode: CostMode;
}>;

export type ItemDataQuery = {
  __typename?: 'Query';
  item?:
    | {
        __typename?: 'Item';
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        options: Array<{
          __typename?: 'Option';
          parent: UUID;
          parentVisibility?: Visibility | null;
          hasEstimate?: boolean | null;
          id: UUID;
          itemType: ItemType;
          description: string;
          descriptionStyled: string;
          dueDate?: Time | null;
          creationTime?: Time | null;
          updateTime?: Time | null;
          name: string;
          number: string;
          status: Status;
          visibility: Visibility;
          availableStates: Array<Status>;
          assetCount: number;
          remoteAssetCount: number;
          commentCount: number;
          filteredMilestone?: UUID | null;
          filteredCategories?: Array<UUID> | null;
          activityIDs?: Array<UUID> | null;
          comments: Array<{
            __typename?: 'Comment';
            id: UUID;
            created: Time;
            author: {
              __typename?: 'User';
              status: UserStatus;
              email: string;
              id: UUID;
              jobTitle: string;
              name: string;
              phone?: string | null;
              picture: string;
              pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
            };
            text: {
              __typename?: 'MentionableText';
              contents: string;
              mentions?: Array<{
                __typename?: 'Mention';
                id: UUID;
                position: number;
                text: string;
                user: { __typename?: 'User'; id: UUID };
              }> | null;
              itemReferences?: Array<{
                __typename?: 'ItemReference';
                id: UUID;
                position: number;
                text: string;
                referencedItem?: {
                  __typename?: 'ItemsListItem';
                  createdAt: Time;
                  currentStatus: Status;
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  updateTime: Time;
                  visibility: Visibility;
                  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                  currentCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                  options: Array<{
                    __typename?: 'ItemLink';
                    id: UUID;
                    name: string;
                    number: string;
                    status: Status;
                    visibility?: Visibility | null;
                  }>;
                } | null;
              }> | null;
            };
          }>;
          assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
          activeEstimate?: {
            __typename?: 'Estimate';
            id: UUID;
            name: string;
            subtotal: Numeric;
            markupSubtotal: USCents;
            drawSubtotal: USCents;
            inheritedSubtotal: USCents;
            inheritedOwnerCostMarkupsSubtotal: USCents;
            asset?: UUID | null;
            totalType: EstimateTotalType;
            isRunningTotal: boolean;
            lines: Array<{
              __typename?: 'Line';
              id: UUID;
              estimateId: UUID;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              cells: Array<{
                __typename?: 'Cell';
                estimateId: UUID;
                field: UUID;
                line: UUID;
                error?: string | null;
                value?:
                  | {
                      __typename?: 'CategoryCell';
                      search: string;
                      category?: {
                        __typename?: 'Category';
                        id: UUID;
                        parentID?: UUID | null;
                        name: string;
                        hasSubCategories: boolean;
                        number: string;
                        level: number;
                        error?: string | null;
                        categorization?: {
                          __typename?: 'Categorization';
                          id: UUID;
                          name: string;
                        } | null;
                        levels: Array<{
                          __typename?: 'Category';
                          id: UUID;
                          name: string;
                          number: string;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'RegularCell'; string: string }
                  | {
                      __typename?: 'SourceCell';
                      sourceIDs?: {
                        __typename?: 'SourceIDs';
                        estimateID: UUID;
                        itemID?: UUID | null;
                        lineID?: UUID | null;
                        markupID?: UUID | null;
                        milestoneID: UUID;
                      } | null;
                    }
                  | null;
              }>;
            }>;
            fields: Array<{
              __typename?: 'Field';
              id: UUID;
              name: string;
              type: string;
              group: string;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                description: string;
                levels: number;
                builtin: boolean;
                createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
              } | null;
              errors: Array<{
                __typename?: 'FieldError';
                type: FieldErrorType;
                count: number;
                categoryErrorIndicies: Array<{
                  __typename?: 'CategoryErrorIndicies';
                  categoryNumber: string;
                  indicies: Array<number>;
                }>;
              }>;
            }>;
            incorporatedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            contingencyDraws?: Array<{
              __typename?: 'ItemDrawInfo';
              id: UUID;
              type: MarkupDisplayType;
              name: string;
              error: string;
              draw: USCents;
              drawFromID?: UUID | null;
              isDrawingFullAmount: boolean;
            }> | null;
            inheritedMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            inheritedOwnerCostMarkups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
            markups: Array<{
              __typename?: 'Markup';
              id: UUID;
              estimateId: UUID;
              name: string;
              percent?: number | null;
              percentScale?: number | null;
              sourceFilterIDs: Array<UUID>;
              sourceFilterIDsWithoutS2Reference: Array<UUID>;
              total: USCents;
              type: MarkupType;
              displayType: MarkupDisplayType;
              drawFromID?: UUID | null;
              value?: Numeric | null;
              disabled: boolean;
              orderingNumerator: Ordering;
              orderingDenominator: Ordering;
              costTypeFilters?: Array<CostType> | null;
              shouldExcludeFromAllocation: boolean;
              shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
              errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
              markupReference?: {
                __typename?: 'MarkupReference';
                appliesTo: Array<string>;
                item?: {
                  __typename?: 'MarkupItemReference';
                  number: string;
                  name: string;
                  isDeprecated: boolean;
                } | null;
              } | null;
              categoryFilters: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              }>;
              source?: {
                __typename?: 'SourceIDs';
                itemID?: UUID | null;
                estimateID: UUID;
                milestoneID: UUID;
                lineID?: UUID | null;
                markupID?: UUID | null;
              } | null;
            }>;
          } | null;
          bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
          project: { __typename?: 'Project'; id: UUID; name: string };
          createdBy?: {
            __typename?: 'User';
            id: UUID;
            email: string;
            name: string;
            picture: string;
          } | null;
          milestone?: {
            __typename?: 'Milestone';
            id: UUID;
            name: string;
            date?: Time | null;
            buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
          } | null;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          assignee?: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          } | null;
          previousMilestoneStatus: Array<{
            __typename?: 'PreviousMilestoneStatus';
            hasEstimate?: boolean | null;
            milestoneID: UUID;
            itemType: ItemType;
            status: Status;
            optionSubtotals: Array<{
              __typename?: 'OptionSubtotal';
              status: Status;
              adds: USCents;
              deducts: USCents;
            }>;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            estimateCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            contingencyDrawCost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
          scheduleImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
        }>;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | {
        __typename?: 'Option';
        parent: UUID;
        parentVisibility?: Visibility | null;
        hasEstimate?: boolean | null;
        id: UUID;
        itemType: ItemType;
        description: string;
        descriptionStyled: string;
        dueDate?: Time | null;
        creationTime?: Time | null;
        updateTime?: Time | null;
        name: string;
        number: string;
        status: Status;
        visibility: Visibility;
        availableStates: Array<Status>;
        assetCount: number;
        remoteAssetCount: number;
        commentCount: number;
        filteredMilestone?: UUID | null;
        filteredCategories?: Array<UUID> | null;
        activityIDs?: Array<UUID> | null;
        comments: Array<{
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        }>;
        assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
        activeEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          name: string;
          subtotal: Numeric;
          markupSubtotal: USCents;
          drawSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          asset?: UUID | null;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          lines: Array<{
            __typename?: 'Line';
            id: UUID;
            estimateId: UUID;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            cells: Array<{
              __typename?: 'Cell';
              estimateId: UUID;
              field: UUID;
              line: UUID;
              error?: string | null;
              value?:
                | {
                    __typename?: 'CategoryCell';
                    search: string;
                    category?: {
                      __typename?: 'Category';
                      id: UUID;
                      parentID?: UUID | null;
                      name: string;
                      hasSubCategories: boolean;
                      number: string;
                      level: number;
                      error?: string | null;
                      categorization?: {
                        __typename?: 'Categorization';
                        id: UUID;
                        name: string;
                      } | null;
                      levels: Array<{
                        __typename?: 'Category';
                        id: UUID;
                        name: string;
                        number: string;
                      }>;
                    } | null;
                  }
                | { __typename?: 'RegularCell'; string: string }
                | {
                    __typename?: 'SourceCell';
                    sourceIDs?: {
                      __typename?: 'SourceIDs';
                      estimateID: UUID;
                      itemID?: UUID | null;
                      lineID?: UUID | null;
                      markupID?: UUID | null;
                      milestoneID: UUID;
                    } | null;
                  }
                | null;
            }>;
          }>;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            type: MarkupDisplayType;
            name: string;
            error: string;
            draw: USCents;
            drawFromID?: UUID | null;
            isDrawingFullAmount: boolean;
          }> | null;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
        } | null;
        bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
        project: { __typename?: 'Project'; id: UUID; name: string };
        createdBy?: {
          __typename?: 'User';
          id: UUID;
          email: string;
          name: string;
          picture: string;
        } | null;
        milestone?: {
          __typename?: 'Milestone';
          id: UUID;
          name: string;
          date?: Time | null;
          buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
        } | null;
        categories: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          error?: string | null;
          level: number;
          hasSubCategories: boolean;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            builtin: boolean;
            description: string;
            draft: boolean;
            levels: number;
            deprecated: boolean;
          } | null;
          levels: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            hasSubCategories: boolean;
            level: number;
          }>;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        previousMilestoneStatus: Array<{
          __typename?: 'PreviousMilestoneStatus';
          hasEstimate?: boolean | null;
          milestoneID: UUID;
          itemType: ItemType;
          status: Status;
          optionSubtotals: Array<{
            __typename?: 'OptionSubtotal';
            status: Status;
            adds: USCents;
            deducts: USCents;
          }>;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          estimateCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          contingencyDrawCost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
        scheduleImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          criticalPath: boolean;
          days: number;
          minImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
          maxImpact?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            days: number;
          } | null;
        } | null;
      }
    | null;
};

export type SourceItemQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  costMode: CostMode;
}>;

export type SourceItemQuery = {
  __typename?: 'Query';
  item?:
    | { __typename?: 'Item'; id: UUID; name: string; number: string; status: Status }
    | { __typename?: 'Option'; id: UUID; name: string; number: string; status: Status }
    | null;
};

export type ProjectActivityQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  filterByUserID?: InputMaybe<Scalars['UUID']['input']>;
  dateFilter: DateFilter;
  timezoneOffset: Scalars['Int']['input'];
  costMode: CostMode;
  pagination: PaginationByOffsetId;
}>;

export type ProjectActivityQuery = {
  __typename?: 'Query';
  projectActivity?: {
    __typename?: 'ItemActivity';
    paginationData: { __typename?: 'PaginationData'; nextOffsetID?: UUID | null };
    data: Array<{
      __typename?: 'ItemHistoryEvent';
      id: UUID;
      itemID: UUID;
      eventTypes: Array<EventType>;
      timestamp: Time;
      user?: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null;
      eventContent: {
        __typename?: 'ItemHistoryEventContent';
        newNumber?: string | null;
        oldNumber?: string | null;
        name?: string | null;
        description?: string | null;
        descriptionStyled?: string | null;
        newMilestoneID?: UUID | null;
        oldMilestoneID?: UUID | null;
        status?: Status | null;
        visibility?: Visibility | null;
        dueDate?: Time | null;
        drawName?: string | null;
        drawFromValue?: Numeric | null;
        drawToValue?: Numeric | null;
        milestoneID?: UUID | null;
        assignee?: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        deletedBy?: {
          __typename?: 'User';
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          status: UserStatus;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null;
        sharedUsers: Array<{
          __typename?: 'User';
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          status: UserStatus;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        } | null>;
        meeting?: {
          __typename?: 'Bucket';
          id: UUID;
          name: string;
          date?: Time | null;
          milestone?: { __typename?: 'Milestone'; id: UUID } | null;
        } | null;
        addedAttachments?: Array<
          | {
              __typename?: 'Asset';
              id: UUID;
              name: string;
              size: Size;
              lastUpdated: Time;
              location: string;
              type?: string | null;
              derivations: Array<{
                __typename?: 'Derivation';
                id: UUID;
                kind: string;
                contents: Array<string>;
              }>;
              uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
            }
          | {
              __typename?: 'RemoteAsset';
              id: UUID;
              sourceAssetID: string;
              name: string;
              lastUpdated: Time;
              remoteLink: string;
              sourceSystem: SourceSystem;
              uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
            }
          | null
        > | null;
        removedAssets?: Array<{ __typename?: 'Asset'; name: string } | null> | null;
        newCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        oldCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        categoryChanges?: Array<{
          __typename?: 'CategoryChange';
          oldCategory?: {
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          } | null;
          newCategory?: {
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          } | null;
        }> | null;
        costChanges?: Array<{
          __typename?: 'CostChange';
          timestamp: Time;
          oldCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          newCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        }> | null;
        scheduleImpactChanges: Array<{
          __typename?: 'ScheduleImpactChange';
          timestamp: Time;
          old?: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          } | null;
          new: {
            __typename?: 'ScheduleImpact';
            type: ScheduleImpactType;
            criticalPath: boolean;
            days: number;
            minImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
            maxImpact?: {
              __typename?: 'ScheduleImpact';
              type: ScheduleImpactType;
              days: number;
            } | null;
          };
        }>;
        oldTimelineActivities: Array<{ __typename?: 'TimelineActivity'; id: UUID; name: string }>;
        newTimelineActivities: Array<{ __typename?: 'TimelineActivity'; id: UUID; name: string }>;
        itemLikeSummary?: {
          __typename?: 'ItemLikeSummary';
          id: UUID;
          number: string;
          kind?: string | null;
          visibility: Visibility;
        } | null;
        removedOptions?: Array<{
          __typename?: 'ItemLikeSummary';
          id: UUID;
          number: string;
          kind?: string | null;
          visibility: Visibility;
        } | null> | null;
        attachedOptions?: Array<{
          __typename?: 'ItemLikeSummary';
          id: UUID;
          number: string;
          kind?: string | null;
          visibility: Visibility;
        } | null> | null;
        statusChangeDraws?: Array<{
          __typename?: 'StatusChangeDraw';
          name: string;
          value: Numeric;
        } | null> | null;
        drawChanges?: Array<{
          __typename?: 'DrawChange';
          name: string;
          fromValue: Numeric;
          toValue: Numeric;
          timestamp: Time;
        }> | null;
        referencedByItems?: Array<{
          __typename?: 'ItemLikeSummary';
          id: UUID;
          number: string;
          kind?: string | null;
          visibility: Visibility;
        } | null> | null;
        comment?: {
          __typename?: 'Comment';
          id: UUID;
          created: Time;
          author: {
            __typename?: 'User';
            status: UserStatus;
            email: string;
            id: UUID;
            jobTitle: string;
            name: string;
            phone?: string | null;
            picture: string;
            pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
          };
          text: {
            __typename?: 'MentionableText';
            contents: string;
            mentions?: Array<{
              __typename?: 'Mention';
              id: UUID;
              position: number;
              text: string;
              user: { __typename?: 'User'; id: UUID };
            }> | null;
            itemReferences?: Array<{
              __typename?: 'ItemReference';
              id: UUID;
              position: number;
              text: string;
              referencedItem?: {
                __typename?: 'ItemsListItem';
                createdAt: Time;
                currentStatus: Status;
                id: UUID;
                name: string;
                number: string;
                status: Status;
                updateTime: Time;
                visibility: Visibility;
                creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
                currentCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
                options: Array<{
                  __typename?: 'ItemLink';
                  id: UUID;
                  name: string;
                  number: string;
                  status: Status;
                  visibility?: Visibility | null;
                }>;
              } | null;
            }> | null;
          };
        } | null;
        copiedItem?: {
          __typename?: 'ItemLinkWithProjectID';
          id: UUID;
          name: string;
          number: string;
          projectID: UUID;
          status: Status;
          visibility?: Visibility | null;
          hasAccess: boolean;
        } | null;
        itemIntegrationObject?: {
          __typename?: 'ItemIntegrationObjectSummary';
          sourceEntityID: string;
          number: number;
          title: string;
        } | null;
      };
    }>;
  } | null;
};

export type RemoveContingencyMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimate: Scalars['UUID']['input'];
  markupIDs: Array<Scalars['UUID']['input']>;
  viewFilter?: InputMaybe<ViewFilter>;
}>;

export type RemoveContingencyMutation = {
  __typename?: 'Mutation';
  removeMarkups: {
    __typename?: 'EstimateUpdateResult';
    estimate?: {
      __typename?: 'Estimate';
      id: UUID;
      subtotal: Numeric;
      markupSubtotal: USCents;
      inheritedSubtotal: USCents;
      incorporatedSubtotal: USCents;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
    } | null;
  };
};

export type BulkItemUpdateMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  itemIDs: Array<Scalars['UUID']['input']>;
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<Status>;
  meetingID?: InputMaybe<Scalars['UUID']['input']>;
  clearMeetingID?: InputMaybe<Scalars['Boolean']['input']>;
  assigneeEmail?: InputMaybe<Scalars['String']['input']>;
  clearAssigneeEmail?: InputMaybe<Scalars['Boolean']['input']>;
  dueDate?: InputMaybe<Scalars['Time']['input']>;
  clearDueDate?: InputMaybe<Scalars['Boolean']['input']>;
  addCategories?: InputMaybe<Array<InputMaybe<CategoryInput>>>;
  clearCategories?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  costMode: CostMode;
}>;

export type BulkItemUpdateMutation = {
  __typename?: 'Mutation';
  bulkItemUpdate: { __typename?: 'BulkItemsUpdateOutput'; itemCount: number; optionCount: number };
};

export type ShareResourcesMutationVariables = Exact<{
  resourceType: ResourceType;
  resourceIDs: Array<Scalars['UUID']['input']>;
  userIDs: Array<Scalars['UUID']['input']>;
  accessLevels: Array<AccessLevel>;
}>;

export type ShareResourcesMutation = {
  __typename?: 'Mutation';
  shareResources?: {
    __typename?: 'ShareResourcesPayload';
    resources: Array<{
      __typename?: 'SharedResource';
      id: UUID;
      type: ResourceType;
      accessLevels: Array<AccessLevel>;
      users: Array<{
        __typename?: 'User';
        id: UUID;
        email: string;
        isEmailVerified: boolean;
        jobTitle: string;
        name: string;
        picture: string;
        status: UserStatus;
      }>;
    }>;
  } | null;
};

export type GetSharedResourcesQueryVariables = Exact<{
  input: GetSharedResourcesInput;
}>;

export type GetSharedResourcesQuery = {
  __typename?: 'Query';
  getSharedResources?: {
    __typename?: 'GetSharedResourcesPayload';
    resources: Array<{
      __typename?: 'SharedResource';
      id: UUID;
      type: ResourceType;
      accessLevels: Array<AccessLevel>;
      users: Array<{
        __typename?: 'User';
        id: UUID;
        email: string;
        isEmailVerified: boolean;
        jobTitle: string;
        name: string;
        picture: string;
        status: UserStatus;
      }>;
    }>;
  } | null;
};

export type GetSharedResourceQueryVariables = Exact<{
  resourceID: Scalars['UUID']['input'];
  resourceType: ResourceType;
}>;

export type GetSharedResourceQuery = {
  __typename?: 'Query';
  getSharedResource?: {
    __typename?: 'GetSharedResourcePayload';
    resource?: {
      __typename?: 'SharedResource';
      id: UUID;
      type: ResourceType;
      accessLevels: Array<AccessLevel>;
      users: Array<{
        __typename?: 'User';
        id: UUID;
        email: string;
        isEmailVerified: boolean;
        jobTitle: string;
        name: string;
        picture: string;
        status: UserStatus;
      }>;
    } | null;
  } | null;
};

export type CategoryLinkFieldsFragment = {
  __typename?: 'CategoryLink';
  id: UUID;
  name: string;
  number: string;
  categorization: { __typename?: 'CategorizationLink'; id: UUID; name: string };
};

export type MeetingLinkFieldsFragment = { __typename?: 'MeetingLink'; id: UUID; name: string };

export type UserLinkFieldsFragment = {
  __typename?: 'UserLink';
  id: UUID;
  name: string;
  picture: string;
  status: UserStatus;
  pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
};

export type UserFieldsFragment = { __typename?: 'UserLink'; id: UUID; name: string; email: string };

export type ItemsListItemFieldsFragment = {
  __typename?: 'ItemsListItem';
  activityIDs?: Array<UUID> | null;
  assigneeID?: UUID | null;
  assetCount: number;
  availableStates: Array<Status>;
  commentCount: number;
  createdAt: Time;
  dueDate?: Time | null;
  currentStatus: Status;
  id: UUID;
  itemType: ItemType;
  name: string;
  number: string;
  parentID?: UUID | null;
  remoteAssetCount: number;
  status: Status;
  visibility: Visibility;
  updateTime: Time;
  hasEstimate: boolean;
  categories: Array<{
    __typename?: 'CategoryLink';
    id: UUID;
    name: string;
    number: string;
    categorization: { __typename?: 'CategorizationLink'; id: UUID; name: string };
  }>;
  creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
  cost:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents };
  currentCost:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents };
  currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
  meeting?: { __typename?: 'MeetingLink'; id: UUID; name: string } | null;
  milestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
  options: Array<{
    __typename?: 'ItemLink';
    id: UUID;
    name: string;
    number: string;
    status: Status;
    visibility?: Visibility | null;
  }>;
  parent?: {
    __typename?: 'ItemLink';
    id: UUID;
    name: string;
    number: string;
    status: Status;
    visibility?: Visibility | null;
  } | null;
  scheduleImpact: {
    __typename?: 'ScheduleImpact';
    type: ScheduleImpactType;
    criticalPath: boolean;
    days: number;
    minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
  };
};

export type ItemsListQueryVariables = Exact<{
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  activityID?: InputMaybe<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
  showOptions: Scalars['Boolean']['input'];
  integrations: Array<IntegrationFilterType>;
  costMode: CostMode;
  viewFilter: ViewFilter;
  visibilityView: VisibilityView;
  loadItemCosts?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ItemsListQuery = {
  __typename?: 'Query';
  itemsList: {
    __typename?: 'ItemsList';
    items: Array<{
      __typename?: 'ItemsListItem';
      activityIDs?: Array<UUID> | null;
      assigneeID?: UUID | null;
      assetCount: number;
      availableStates: Array<Status>;
      commentCount: number;
      createdAt: Time;
      dueDate?: Time | null;
      currentStatus: Status;
      id: UUID;
      itemType: ItemType;
      name: string;
      number: string;
      parentID?: UUID | null;
      remoteAssetCount: number;
      status: Status;
      visibility: Visibility;
      updateTime: Time;
      hasEstimate: boolean;
      categories: Array<{
        __typename?: 'CategoryLink';
        id: UUID;
        name: string;
        number: string;
        categorization: { __typename?: 'CategorizationLink'; id: UUID; name: string };
      }>;
      creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
      cost:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
      currentCost:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents };
      currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
      meeting?: { __typename?: 'MeetingLink'; id: UUID; name: string } | null;
      milestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
      options: Array<{
        __typename?: 'ItemLink';
        id: UUID;
        name: string;
        number: string;
        status: Status;
        visibility?: Visibility | null;
      }>;
      parent?: {
        __typename?: 'ItemLink';
        id: UUID;
        name: string;
        number: string;
        status: Status;
        visibility?: Visibility | null;
      } | null;
      scheduleImpact: {
        __typename?: 'ScheduleImpact';
        type: ScheduleImpactType;
        criticalPath: boolean;
        days: number;
        minImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
        maxImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
      };
    }>;
  };
};

export type ItemsListTotalCostQueryVariables = Exact<{
  itemIDs: Array<Scalars['UUID']['input']>;
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
  costMode: CostMode;
  viewFilter: ViewFilter;
}>;

export type ItemsListTotalCostQuery = {
  __typename?: 'Query';
  itemsListTotalCost: {
    __typename?: 'ItemsListTotalCost';
    cost:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
  };
};

export type ItemCostQueryVariables = Exact<{
  itemID: Scalars['UUID']['input'];
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
  costMode: CostMode;
  viewFilter: ViewFilter;
}>;

export type ItemCostQuery = {
  __typename?: 'Query';
  itemCost: {
    __typename?: 'ItemCost';
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
  };
};

export type ItemCurrentCostQueryVariables = Exact<{
  itemID: Scalars['UUID']['input'];
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
  costMode: CostMode;
  viewFilter: ViewFilter;
}>;

export type ItemCurrentCostQuery = {
  __typename?: 'Query';
  itemCurrentCost: {
    __typename?: 'ItemCost';
    currentCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
  };
};

export type GetMilestoneContingenciesQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
}>;

export type GetMilestoneContingenciesQuery = {
  __typename?: 'Query';
  getMilestoneContingencies: Array<{
    __typename?: 'MilestoneContingencyInfo';
    id: UUID;
    name: string;
    type: MarkupDisplayType;
  }>;
};

export type GetProjectContingenciesQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type GetProjectContingenciesQuery = {
  __typename?: 'Query';
  getProjectContingencies: Array<{
    __typename?: 'MilestoneContingencyInfo';
    name: string;
    type: MarkupDisplayType;
  }>;
};

export type AddsDeductsFieldsFragment = {
  __typename?: 'AddDeductCost';
  adds: USCents;
  deducts: USCents;
};

export type CommonCategorizedItemsTreeNodeCostsFieldsFragment = {
  __typename?: 'ItemsTreeBranchNodeCosts';
  pendingAddsDeductsCost: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
  rejectedAddsDeductsCost: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
  acceptedCost:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents };
  targetCost:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents };
  runningTotalCost:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents };
};

export type CommonItemsTreeOptionFieldsFragment = {
  __typename?: 'ItemsTreeOption';
  itemLikeID: UUID;
  cost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
};

export type CommonItemsTreeItemsFieldsFragment = {
  __typename?: 'ItemsTreeItem';
  itemLikeID: UUID;
  cost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  options: Array<{
    __typename?: 'ItemsTreeOption';
    itemLikeID: UUID;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
  }>;
};

export type CommonCategorizedItemsTreeBranchFieldsFragment = {
  __typename?: 'ItemsTreeBranch';
  key: string;
  displayName: string;
  displayNameForPrint: string;
  level: number;
  totalItems: number;
  nodeCosts?: {
    __typename?: 'ItemsTreeBranchNodeCosts';
    pendingAddsDeductsCost: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
    rejectedAddsDeductsCost: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
    acceptedCost:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
    targetCost:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
    runningTotalCost:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents };
  } | null;
  items: Array<{
    __typename?: 'ItemsTreeItem';
    itemLikeID: UUID;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    options: Array<{
      __typename?: 'ItemsTreeOption';
      itemLikeID: UUID;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
    }>;
  }>;
};

export type ItemsTreeQueryVariables = Exact<{
  itemsSortBy: ItemsSortBy;
  itemsFilterBy: ItemsFilterBy;
  itemsGroupBy?: InputMaybe<Scalars['String']['input']>;
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  activityID?: InputMaybe<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
  meetingID?: InputMaybe<Scalars['UUID']['input']>;
  costMode: CostMode;
  viewFilter: ViewFilter;
  groupByItems: Array<GroupByItem>;
}>;

export type ItemsTreeQuery = {
  __typename?: 'Query';
  itemsTree: {
    __typename?: 'ItemsTree';
    level: number;
    orderedItemIDs: Array<UUID>;
    totalItems: number;
    branches: Array<{
      __typename?: 'ItemsTreeBranch';
      key: string;
      displayName: string;
      displayNameForPrint: string;
      level: number;
      totalItems: number;
      branches: Array<{
        __typename?: 'ItemsTreeBranch';
        key: string;
        displayName: string;
        displayNameForPrint: string;
        level: number;
        totalItems: number;
        branches: Array<{
          __typename?: 'ItemsTreeBranch';
          key: string;
          displayName: string;
          displayNameForPrint: string;
          level: number;
          totalItems: number;
          branches: Array<{
            __typename?: 'ItemsTreeBranch';
            key: string;
            displayName: string;
            displayNameForPrint: string;
            level: number;
            totalItems: number;
            branches: Array<{
              __typename?: 'ItemsTreeBranch';
              key: string;
              displayName: string;
              displayNameForPrint: string;
              level: number;
              totalItems: number;
              branches: Array<{
                __typename?: 'ItemsTreeBranch';
                key: string;
                displayName: string;
                displayNameForPrint: string;
                level: number;
                totalItems: number;
                branches: Array<{
                  __typename?: 'ItemsTreeBranch';
                  key: string;
                  displayName: string;
                  displayNameForPrint: string;
                  level: number;
                  totalItems: number;
                  branches: Array<{
                    __typename?: 'ItemsTreeBranch';
                    key: string;
                    displayName: string;
                    displayNameForPrint: string;
                    level: number;
                    totalItems: number;
                    branches: Array<{
                      __typename?: 'ItemsTreeBranch';
                      key: string;
                      displayName: string;
                      displayNameForPrint: string;
                      level: number;
                      totalItems: number;
                      branches: Array<{
                        __typename?: 'ItemsTreeBranch';
                        key: string;
                        displayName: string;
                        displayNameForPrint: string;
                        level: number;
                        totalItems: number;
                        nodeCosts?: {
                          __typename?: 'ItemsTreeBranchNodeCosts';
                          pendingAddsDeductsCost: {
                            __typename?: 'AddDeductCost';
                            adds: USCents;
                            deducts: USCents;
                          };
                          rejectedAddsDeductsCost: {
                            __typename?: 'AddDeductCost';
                            adds: USCents;
                            deducts: USCents;
                          };
                          acceptedCost:
                            | { __typename?: 'CostRange'; min: USCents; max: USCents }
                            | { __typename?: 'CostScalar'; value: USCents };
                          targetCost:
                            | { __typename?: 'CostRange'; min: USCents; max: USCents }
                            | { __typename?: 'CostScalar'; value: USCents };
                          runningTotalCost:
                            | { __typename?: 'CostRange'; min: USCents; max: USCents }
                            | { __typename?: 'CostScalar'; value: USCents };
                        } | null;
                        items: Array<{
                          __typename?: 'ItemsTreeItem';
                          itemLikeID: UUID;
                          cost?:
                            | { __typename?: 'CostRange'; min: USCents; max: USCents }
                            | { __typename?: 'CostScalar'; value: USCents }
                            | null;
                          options: Array<{
                            __typename?: 'ItemsTreeOption';
                            itemLikeID: UUID;
                            cost?:
                              | { __typename?: 'CostRange'; min: USCents; max: USCents }
                              | { __typename?: 'CostScalar'; value: USCents }
                              | null;
                          }>;
                        }>;
                      }>;
                      nodeCosts?: {
                        __typename?: 'ItemsTreeBranchNodeCosts';
                        pendingAddsDeductsCost: {
                          __typename?: 'AddDeductCost';
                          adds: USCents;
                          deducts: USCents;
                        };
                        rejectedAddsDeductsCost: {
                          __typename?: 'AddDeductCost';
                          adds: USCents;
                          deducts: USCents;
                        };
                        acceptedCost:
                          | { __typename?: 'CostRange'; min: USCents; max: USCents }
                          | { __typename?: 'CostScalar'; value: USCents };
                        targetCost:
                          | { __typename?: 'CostRange'; min: USCents; max: USCents }
                          | { __typename?: 'CostScalar'; value: USCents };
                        runningTotalCost:
                          | { __typename?: 'CostRange'; min: USCents; max: USCents }
                          | { __typename?: 'CostScalar'; value: USCents };
                      } | null;
                      items: Array<{
                        __typename?: 'ItemsTreeItem';
                        itemLikeID: UUID;
                        cost?:
                          | { __typename?: 'CostRange'; min: USCents; max: USCents }
                          | { __typename?: 'CostScalar'; value: USCents }
                          | null;
                        options: Array<{
                          __typename?: 'ItemsTreeOption';
                          itemLikeID: UUID;
                          cost?:
                            | { __typename?: 'CostRange'; min: USCents; max: USCents }
                            | { __typename?: 'CostScalar'; value: USCents }
                            | null;
                        }>;
                      }>;
                    }>;
                    nodeCosts?: {
                      __typename?: 'ItemsTreeBranchNodeCosts';
                      pendingAddsDeductsCost: {
                        __typename?: 'AddDeductCost';
                        adds: USCents;
                        deducts: USCents;
                      };
                      rejectedAddsDeductsCost: {
                        __typename?: 'AddDeductCost';
                        adds: USCents;
                        deducts: USCents;
                      };
                      acceptedCost:
                        | { __typename?: 'CostRange'; min: USCents; max: USCents }
                        | { __typename?: 'CostScalar'; value: USCents };
                      targetCost:
                        | { __typename?: 'CostRange'; min: USCents; max: USCents }
                        | { __typename?: 'CostScalar'; value: USCents };
                      runningTotalCost:
                        | { __typename?: 'CostRange'; min: USCents; max: USCents }
                        | { __typename?: 'CostScalar'; value: USCents };
                    } | null;
                    items: Array<{
                      __typename?: 'ItemsTreeItem';
                      itemLikeID: UUID;
                      cost?:
                        | { __typename?: 'CostRange'; min: USCents; max: USCents }
                        | { __typename?: 'CostScalar'; value: USCents }
                        | null;
                      options: Array<{
                        __typename?: 'ItemsTreeOption';
                        itemLikeID: UUID;
                        cost?:
                          | { __typename?: 'CostRange'; min: USCents; max: USCents }
                          | { __typename?: 'CostScalar'; value: USCents }
                          | null;
                      }>;
                    }>;
                  }>;
                  nodeCosts?: {
                    __typename?: 'ItemsTreeBranchNodeCosts';
                    pendingAddsDeductsCost: {
                      __typename?: 'AddDeductCost';
                      adds: USCents;
                      deducts: USCents;
                    };
                    rejectedAddsDeductsCost: {
                      __typename?: 'AddDeductCost';
                      adds: USCents;
                      deducts: USCents;
                    };
                    acceptedCost:
                      | { __typename?: 'CostRange'; min: USCents; max: USCents }
                      | { __typename?: 'CostScalar'; value: USCents };
                    targetCost:
                      | { __typename?: 'CostRange'; min: USCents; max: USCents }
                      | { __typename?: 'CostScalar'; value: USCents };
                    runningTotalCost:
                      | { __typename?: 'CostRange'; min: USCents; max: USCents }
                      | { __typename?: 'CostScalar'; value: USCents };
                  } | null;
                  items: Array<{
                    __typename?: 'ItemsTreeItem';
                    itemLikeID: UUID;
                    cost?:
                      | { __typename?: 'CostRange'; min: USCents; max: USCents }
                      | { __typename?: 'CostScalar'; value: USCents }
                      | null;
                    options: Array<{
                      __typename?: 'ItemsTreeOption';
                      itemLikeID: UUID;
                      cost?:
                        | { __typename?: 'CostRange'; min: USCents; max: USCents }
                        | { __typename?: 'CostScalar'; value: USCents }
                        | null;
                    }>;
                  }>;
                }>;
                nodeCosts?: {
                  __typename?: 'ItemsTreeBranchNodeCosts';
                  pendingAddsDeductsCost: {
                    __typename?: 'AddDeductCost';
                    adds: USCents;
                    deducts: USCents;
                  };
                  rejectedAddsDeductsCost: {
                    __typename?: 'AddDeductCost';
                    adds: USCents;
                    deducts: USCents;
                  };
                  acceptedCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  targetCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  runningTotalCost:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                } | null;
                items: Array<{
                  __typename?: 'ItemsTreeItem';
                  itemLikeID: UUID;
                  cost?:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents }
                    | null;
                  options: Array<{
                    __typename?: 'ItemsTreeOption';
                    itemLikeID: UUID;
                    cost?:
                      | { __typename?: 'CostRange'; min: USCents; max: USCents }
                      | { __typename?: 'CostScalar'; value: USCents }
                      | null;
                  }>;
                }>;
              }>;
              nodeCosts?: {
                __typename?: 'ItemsTreeBranchNodeCosts';
                pendingAddsDeductsCost: {
                  __typename?: 'AddDeductCost';
                  adds: USCents;
                  deducts: USCents;
                };
                rejectedAddsDeductsCost: {
                  __typename?: 'AddDeductCost';
                  adds: USCents;
                  deducts: USCents;
                };
                acceptedCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                targetCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                runningTotalCost:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
              } | null;
              items: Array<{
                __typename?: 'ItemsTreeItem';
                itemLikeID: UUID;
                cost?:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents }
                  | null;
                options: Array<{
                  __typename?: 'ItemsTreeOption';
                  itemLikeID: UUID;
                  cost?:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents }
                    | null;
                }>;
              }>;
            }>;
            nodeCosts?: {
              __typename?: 'ItemsTreeBranchNodeCosts';
              pendingAddsDeductsCost: {
                __typename?: 'AddDeductCost';
                adds: USCents;
                deducts: USCents;
              };
              rejectedAddsDeductsCost: {
                __typename?: 'AddDeductCost';
                adds: USCents;
                deducts: USCents;
              };
              acceptedCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              targetCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              runningTotalCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
            } | null;
            items: Array<{
              __typename?: 'ItemsTreeItem';
              itemLikeID: UUID;
              cost?:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents }
                | null;
              options: Array<{
                __typename?: 'ItemsTreeOption';
                itemLikeID: UUID;
                cost?:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents }
                  | null;
              }>;
            }>;
          }>;
          nodeCosts?: {
            __typename?: 'ItemsTreeBranchNodeCosts';
            pendingAddsDeductsCost: {
              __typename?: 'AddDeductCost';
              adds: USCents;
              deducts: USCents;
            };
            rejectedAddsDeductsCost: {
              __typename?: 'AddDeductCost';
              adds: USCents;
              deducts: USCents;
            };
            acceptedCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            targetCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            runningTotalCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          } | null;
          items: Array<{
            __typename?: 'ItemsTreeItem';
            itemLikeID: UUID;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            options: Array<{
              __typename?: 'ItemsTreeOption';
              itemLikeID: UUID;
              cost?:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents }
                | null;
            }>;
          }>;
        }>;
        nodeCosts?: {
          __typename?: 'ItemsTreeBranchNodeCosts';
          pendingAddsDeductsCost: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
          rejectedAddsDeductsCost: {
            __typename?: 'AddDeductCost';
            adds: USCents;
            deducts: USCents;
          };
          acceptedCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          targetCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          runningTotalCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
        } | null;
        items: Array<{
          __typename?: 'ItemsTreeItem';
          itemLikeID: UUID;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          options: Array<{
            __typename?: 'ItemsTreeOption';
            itemLikeID: UUID;
            cost?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
          }>;
        }>;
      }>;
      nodeCosts?: {
        __typename?: 'ItemsTreeBranchNodeCosts';
        pendingAddsDeductsCost: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        rejectedAddsDeductsCost: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        acceptedCost:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        targetCost:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        runningTotalCost:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
      } | null;
      items: Array<{
        __typename?: 'ItemsTreeItem';
        itemLikeID: UUID;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        options: Array<{
          __typename?: 'ItemsTreeOption';
          itemLikeID: UUID;
          cost?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
        }>;
      }>;
    }>;
    items: Array<{
      __typename?: 'ItemsTreeItem';
      itemLikeID: UUID;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      options: Array<{
        __typename?: 'ItemsTreeOption';
        itemLikeID: UUID;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
      }>;
    }>;
  };
};

export type GridEstimateLineIDsFieldsFragment = {
  __typename?: 'Estimate';
  lineIDs?: Array<UUID> | null;
};

export type GridEstimateArmatureFieldsFragment = {
  __typename?: 'Estimate';
  id: UUID;
  linesCount?: number | null;
  name: string;
  milestoneName?: string | null;
  subtotal: Numeric;
  markupSubtotal: USCents;
  incorporatedSubtotal: USCents;
  incorporatedDrawsSubtotal: USCents;
  inheritedSubtotal: USCents;
  inheritedOwnerCostMarkupsSubtotal: USCents;
  drawSubtotal: USCents;
  totalType: EstimateTotalType;
  isRunningTotal: boolean;
  fields: Array<{
    __typename?: 'Field';
    id: UUID;
    name: string;
    type: string;
    group: string;
    categorization?: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      description: string;
      levels: number;
      builtin: boolean;
      createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
    } | null;
    errors: Array<{
      __typename?: 'FieldError';
      type: FieldErrorType;
      count: number;
      categoryErrorIndicies: Array<{
        __typename?: 'CategoryErrorIndicies';
        categoryNumber: string;
        indicies: Array<number>;
      }>;
    }>;
  }>;
  incorporatedMarkups: Array<{
    __typename?: 'Markup';
    id: UUID;
    estimateId: UUID;
    name: string;
    percent?: number | null;
    percentScale?: number | null;
    sourceFilterIDs: Array<UUID>;
    sourceFilterIDsWithoutS2Reference: Array<UUID>;
    total: USCents;
    type: MarkupType;
    displayType: MarkupDisplayType;
    drawFromID?: UUID | null;
    value?: Numeric | null;
    disabled: boolean;
    orderingNumerator: Ordering;
    orderingDenominator: Ordering;
    costTypeFilters?: Array<CostType> | null;
    shouldExcludeFromAllocation: boolean;
    shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
    errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
    markupReference?: {
      __typename?: 'MarkupReference';
      appliesTo: Array<string>;
      item?: {
        __typename?: 'MarkupItemReference';
        number: string;
        name: string;
        isDeprecated: boolean;
      } | null;
    } | null;
    categoryFilters: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    }>;
    source?: {
      __typename?: 'SourceIDs';
      itemID?: UUID | null;
      estimateID: UUID;
      milestoneID: UUID;
      lineID?: UUID | null;
      markupID?: UUID | null;
    } | null;
  }>;
  incorporatedDraws: Array<{
    __typename?: 'Markup';
    id: UUID;
    estimateId: UUID;
    name: string;
    percent?: number | null;
    percentScale?: number | null;
    sourceFilterIDs: Array<UUID>;
    sourceFilterIDsWithoutS2Reference: Array<UUID>;
    total: USCents;
    type: MarkupType;
    displayType: MarkupDisplayType;
    drawFromID?: UUID | null;
    value?: Numeric | null;
    disabled: boolean;
    orderingNumerator: Ordering;
    orderingDenominator: Ordering;
    costTypeFilters?: Array<CostType> | null;
    shouldExcludeFromAllocation: boolean;
    shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
    errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
    markupReference?: {
      __typename?: 'MarkupReference';
      appliesTo: Array<string>;
      item?: {
        __typename?: 'MarkupItemReference';
        number: string;
        name: string;
        isDeprecated: boolean;
      } | null;
    } | null;
    categoryFilters: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    }>;
    source?: {
      __typename?: 'SourceIDs';
      itemID?: UUID | null;
      estimateID: UUID;
      milestoneID: UUID;
      lineID?: UUID | null;
      markupID?: UUID | null;
    } | null;
  }>;
  inheritedMarkups: Array<{
    __typename?: 'Markup';
    id: UUID;
    estimateId: UUID;
    name: string;
    percent?: number | null;
    percentScale?: number | null;
    sourceFilterIDs: Array<UUID>;
    sourceFilterIDsWithoutS2Reference: Array<UUID>;
    total: USCents;
    type: MarkupType;
    displayType: MarkupDisplayType;
    drawFromID?: UUID | null;
    value?: Numeric | null;
    disabled: boolean;
    orderingNumerator: Ordering;
    orderingDenominator: Ordering;
    costTypeFilters?: Array<CostType> | null;
    shouldExcludeFromAllocation: boolean;
    shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
    errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
    markupReference?: {
      __typename?: 'MarkupReference';
      appliesTo: Array<string>;
      item?: {
        __typename?: 'MarkupItemReference';
        number: string;
        name: string;
        isDeprecated: boolean;
      } | null;
    } | null;
    categoryFilters: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    }>;
    source?: {
      __typename?: 'SourceIDs';
      itemID?: UUID | null;
      estimateID: UUID;
      milestoneID: UUID;
      lineID?: UUID | null;
      markupID?: UUID | null;
    } | null;
  }>;
  inheritedOwnerCostMarkups: Array<{
    __typename?: 'Markup';
    id: UUID;
    estimateId: UUID;
    name: string;
    percent?: number | null;
    percentScale?: number | null;
    sourceFilterIDs: Array<UUID>;
    sourceFilterIDsWithoutS2Reference: Array<UUID>;
    total: USCents;
    type: MarkupType;
    displayType: MarkupDisplayType;
    drawFromID?: UUID | null;
    value?: Numeric | null;
    disabled: boolean;
    orderingNumerator: Ordering;
    orderingDenominator: Ordering;
    costTypeFilters?: Array<CostType> | null;
    shouldExcludeFromAllocation: boolean;
    shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
    errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
    markupReference?: {
      __typename?: 'MarkupReference';
      appliesTo: Array<string>;
      item?: {
        __typename?: 'MarkupItemReference';
        number: string;
        name: string;
        isDeprecated: boolean;
      } | null;
    } | null;
    categoryFilters: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    }>;
    source?: {
      __typename?: 'SourceIDs';
      itemID?: UUID | null;
      estimateID: UUID;
      milestoneID: UUID;
      lineID?: UUID | null;
      markupID?: UUID | null;
    } | null;
  }>;
  markups: Array<{
    __typename?: 'Markup';
    id: UUID;
    estimateId: UUID;
    name: string;
    percent?: number | null;
    percentScale?: number | null;
    sourceFilterIDs: Array<UUID>;
    sourceFilterIDsWithoutS2Reference: Array<UUID>;
    total: USCents;
    type: MarkupType;
    displayType: MarkupDisplayType;
    drawFromID?: UUID | null;
    value?: Numeric | null;
    disabled: boolean;
    orderingNumerator: Ordering;
    orderingDenominator: Ordering;
    costTypeFilters?: Array<CostType> | null;
    shouldExcludeFromAllocation: boolean;
    shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
    errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
    markupReference?: {
      __typename?: 'MarkupReference';
      appliesTo: Array<string>;
      item?: {
        __typename?: 'MarkupItemReference';
        number: string;
        name: string;
        isDeprecated: boolean;
      } | null;
    } | null;
    categoryFilters: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    }>;
    source?: {
      __typename?: 'SourceIDs';
      itemID?: UUID | null;
      estimateID: UUID;
      milestoneID: UUID;
      lineID?: UUID | null;
      markupID?: UUID | null;
    } | null;
  }>;
  contingencyDraws?: Array<{
    __typename?: 'ItemDrawInfo';
    id: UUID;
    name: string;
    error: string;
    draw: USCents;
  }> | null;
};

export type EstimateArmatureQueryVariables = Exact<{
  estimateID: Scalars['UUID']['input'];
  itemEstimateInput?: InputMaybe<ItemEstimateInput>;
  costMode: CostMode;
  viewFilter?: InputMaybe<ViewFilter>;
  estimateSortBy?: InputMaybe<EstimateSortBy>;
  pagination?: InputMaybe<Pagination>;
}>;

export type EstimateArmatureQuery = {
  __typename?: 'Query';
  estimate?: {
    __typename?: 'Estimate';
    id: UUID;
    linesCount?: number | null;
    name: string;
    milestoneName?: string | null;
    subtotal: Numeric;
    markupSubtotal: USCents;
    incorporatedSubtotal: USCents;
    incorporatedDrawsSubtotal: USCents;
    inheritedSubtotal: USCents;
    inheritedOwnerCostMarkupsSubtotal: USCents;
    drawSubtotal: USCents;
    totalType: EstimateTotalType;
    isRunningTotal: boolean;
    ownerCostEstimate?: {
      __typename?: 'Estimate';
      id: UUID;
      linesCount?: number | null;
      name: string;
      milestoneName?: string | null;
      subtotal: Numeric;
      markupSubtotal: USCents;
      incorporatedSubtotal: USCents;
      incorporatedDrawsSubtotal: USCents;
      inheritedSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      drawSubtotal: USCents;
      totalType: EstimateTotalType;
      isRunningTotal: boolean;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      incorporatedDraws: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      contingencyDraws?: Array<{
        __typename?: 'ItemDrawInfo';
        id: UUID;
        name: string;
        error: string;
        draw: USCents;
      }> | null;
    } | null;
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
    incorporatedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    incorporatedDraws: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    inheritedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    inheritedOwnerCostMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    markups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    contingencyDraws?: Array<{
      __typename?: 'ItemDrawInfo';
      id: UUID;
      name: string;
      error: string;
      draw: USCents;
    }> | null;
  } | null;
};

export type DraftEstimateInfoQueryVariables = Exact<{
  estimateID: Scalars['UUID']['input'];
  costMode: CostMode;
}>;

export type DraftEstimateInfoQuery = {
  __typename?: 'Query';
  draftEstimateInfo?: {
    __typename?: 'DraftEstimateInfo';
    author: UUID;
    asset?: UUID | null;
  } | null;
};

export type EstimateLineIDsQueryVariables = Exact<{
  estimateID: Scalars['UUID']['input'];
  costMode: CostMode;
  viewFilter?: InputMaybe<ViewFilter>;
  estimateSortBy?: InputMaybe<EstimateSortBy>;
}>;

export type EstimateLineIDsQuery = {
  __typename?: 'Query';
  estimate?: { __typename?: 'Estimate'; lineIDs?: Array<UUID> | null } | null;
};

export type GridEstimateLinesFieldsFragment = {
  __typename?: 'Estimate';
  lines: Array<{
    __typename?: 'Line';
    id: UUID;
    orderingNumerator: Ordering;
    orderingDenominator: Ordering;
    cells: Array<{
      __typename?: 'Cell';
      error?: string | null;
      value?:
        | {
            __typename?: 'CategoryCell';
            search: string;
            category?: {
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            } | null;
          }
        | {
            __typename?: 'RegularCell';
            string: string;
            formula: string;
            formulaDisplay: Array<{
              __typename?: 'FormulaDisplay';
              type: FormulaDisplayType;
              text: string;
              error: string;
            }>;
          }
        | {
            __typename?: 'SourceCell';
            sourceIDs?: {
              __typename?: 'SourceIDs';
              estimateID: UUID;
              itemID?: UUID | null;
              lineID?: UUID | null;
              markupID?: UUID | null;
              milestoneID: UUID;
            } | null;
          }
        | null;
    }>;
  }>;
};

export type EstimateLinesQueryVariables = Exact<{
  estimateID: Scalars['UUID']['input'];
  itemEstimateInput?: InputMaybe<ItemEstimateInput>;
  costMode: CostMode;
  viewFilter?: InputMaybe<ViewFilter>;
  estimateSortBy?: InputMaybe<EstimateSortBy>;
  pagination?: InputMaybe<Pagination>;
}>;

export type EstimateLinesQuery = {
  __typename?: 'Query';
  estimate?: {
    __typename?: 'Estimate';
    lines: Array<{
      __typename?: 'Line';
      id: UUID;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      cells: Array<{
        __typename?: 'Cell';
        error?: string | null;
        value?:
          | {
              __typename?: 'CategoryCell';
              search: string;
              category?: {
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              } | null;
            }
          | {
              __typename?: 'RegularCell';
              string: string;
              formula: string;
              formulaDisplay: Array<{
                __typename?: 'FormulaDisplay';
                type: FormulaDisplayType;
                text: string;
                error: string;
              }>;
            }
          | {
              __typename?: 'SourceCell';
              sourceIDs?: {
                __typename?: 'SourceIDs';
                estimateID: UUID;
                itemID?: UUID | null;
                lineID?: UUID | null;
                markupID?: UUID | null;
                milestoneID: UUID;
              } | null;
            }
          | null;
      }>;
    }>;
  } | null;
};

export type CreateMarkupsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimate: Scalars['UUID']['input'];
  number: Scalars['Int']['input'];
}>;

export type CreateMarkupsMutation = {
  __typename?: 'Mutation';
  createMarkups: Array<{
    __typename?: 'Markup';
    id: UUID;
    estimateId: UUID;
    name: string;
    percent?: number | null;
    percentScale?: number | null;
    sourceFilterIDs: Array<UUID>;
    sourceFilterIDsWithoutS2Reference: Array<UUID>;
    total: USCents;
    type: MarkupType;
    displayType: MarkupDisplayType;
    drawFromID?: UUID | null;
    value?: Numeric | null;
    disabled: boolean;
    orderingNumerator: Ordering;
    orderingDenominator: Ordering;
    costTypeFilters?: Array<CostType> | null;
    shouldExcludeFromAllocation: boolean;
    shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
    errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
    markupReference?: {
      __typename?: 'MarkupReference';
      appliesTo: Array<string>;
      item?: {
        __typename?: 'MarkupItemReference';
        number: string;
        name: string;
        isDeprecated: boolean;
      } | null;
    } | null;
    categoryFilters: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    }>;
    source?: {
      __typename?: 'SourceIDs';
      itemID?: UUID | null;
      estimateID: UUID;
      milestoneID: UUID;
      lineID?: UUID | null;
      markupID?: UUID | null;
    } | null;
  }>;
};

export type UpdateMarkupsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimate: Scalars['UUID']['input'];
  markups: Array<MarkupInput>;
  viewFilter?: InputMaybe<ViewFilter>;
}>;

export type UpdateMarkupsMutation = {
  __typename?: 'Mutation';
  updateMarkups: {
    __typename?: 'MarkupUpdateResult';
    markups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    estimateUpdateResult?: {
      __typename?: 'EstimateUpdateResult';
      estimate?: {
        __typename?: 'Estimate';
        subtotal: Numeric;
        markupSubtotal: USCents;
        inheritedSubtotal: USCents;
        incorporatedSubtotal: USCents;
        incorporatedDrawsSubtotal: USCents;
        inheritedOwnerCostMarkupsSubtotal: USCents;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
        inheritedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        markups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        incorporatedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        incorporatedDraws: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedOwnerCostMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        ownerCostEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          linesCount?: number | null;
          name: string;
          milestoneName?: string | null;
          subtotal: Numeric;
          markupSubtotal: USCents;
          incorporatedSubtotal: USCents;
          incorporatedDrawsSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          drawSubtotal: USCents;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          incorporatedDraws: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            name: string;
            error: string;
            draw: USCents;
          }> | null;
        } | null;
      } | null;
    } | null;
  };
};

export type RemoveMarkupsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimate: Scalars['UUID']['input'];
  markupIDs: Array<Scalars['UUID']['input']>;
  viewFilter?: InputMaybe<ViewFilter>;
}>;

export type RemoveMarkupsMutation = {
  __typename?: 'Mutation';
  removeMarkups: {
    __typename?: 'EstimateUpdateResult';
    estimate?: {
      __typename?: 'Estimate';
      id: UUID;
      subtotal: Numeric;
      markupSubtotal: USCents;
      inheritedSubtotal: USCents;
      incorporatedSubtotal: USCents;
      incorporatedDrawsSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      incorporatedDraws: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
    } | null;
  };
};

export type ToggleInheritedItemMarkupMutationVariables = Exact<{
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  estimateId: Scalars['UUID']['input'];
  markupId: Scalars['UUID']['input'];
  disabled: Scalars['Boolean']['input'];
}>;

export type ToggleInheritedItemMarkupMutation = {
  __typename?: 'Mutation';
  toggleInheritedItemMarkup: {
    __typename?: 'EstimateUpdateResult';
    estimate?: {
      __typename?: 'Estimate';
      subtotal: Numeric;
      markupSubtotal: USCents;
      inheritedSubtotal: USCents;
      incorporatedSubtotal: USCents;
      incorporatedDrawsSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      incorporatedDraws: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      ownerCostEstimate?: {
        __typename?: 'Estimate';
        id: UUID;
        linesCount?: number | null;
        name: string;
        milestoneName?: string | null;
        subtotal: Numeric;
        markupSubtotal: USCents;
        incorporatedSubtotal: USCents;
        incorporatedDrawsSubtotal: USCents;
        inheritedSubtotal: USCents;
        inheritedOwnerCostMarkupsSubtotal: USCents;
        drawSubtotal: USCents;
        totalType: EstimateTotalType;
        isRunningTotal: boolean;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
        incorporatedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        incorporatedDraws: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedOwnerCostMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        markups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        contingencyDraws?: Array<{
          __typename?: 'ItemDrawInfo';
          id: UUID;
          name: string;
          error: string;
          draw: USCents;
        }> | null;
      } | null;
    } | null;
  };
};

export type ToggleMarkupWithoutS2ReferenceMutationVariables = Exact<{
  estimateID: Scalars['UUID']['input'];
  markupID: Scalars['UUID']['input'];
  withoutS2Reference: Scalars['Boolean']['input'];
}>;

export type ToggleMarkupWithoutS2ReferenceMutation = {
  __typename?: 'Mutation';
  toggleMarkupWithoutS2Reference: {
    __typename?: 'EstimateUpdateResult';
    estimate?: {
      __typename?: 'Estimate';
      subtotal: Numeric;
      markupSubtotal: USCents;
      inheritedSubtotal: USCents;
      incorporatedSubtotal: USCents;
      incorporatedDrawsSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      incorporatedDraws: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      ownerCostEstimate?: {
        __typename?: 'Estimate';
        id: UUID;
        linesCount?: number | null;
        name: string;
        milestoneName?: string | null;
        subtotal: Numeric;
        markupSubtotal: USCents;
        incorporatedSubtotal: USCents;
        incorporatedDrawsSubtotal: USCents;
        inheritedSubtotal: USCents;
        inheritedOwnerCostMarkupsSubtotal: USCents;
        drawSubtotal: USCents;
        totalType: EstimateTotalType;
        isRunningTotal: boolean;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
        incorporatedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        incorporatedDraws: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedOwnerCostMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        markups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        contingencyDraws?: Array<{
          __typename?: 'ItemDrawInfo';
          id: UUID;
          name: string;
          error: string;
          draw: USCents;
        }> | null;
      } | null;
    } | null;
  };
};

export type GridReorderMarkupsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimate: Scalars['UUID']['input'];
  markups: Array<ReorderInput>;
}>;

export type GridReorderMarkupsMutation = {
  __typename?: 'Mutation';
  reorderMarkups: {
    __typename?: 'MarkupReorderResult';
    markups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
  };
};

export type QuantityRemoveLineMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
  lineIDs: Array<Scalars['UUID']['input']>;
}>;

export type QuantityRemoveLineMutation = {
  __typename?: 'Mutation';
  removeLines: {
    __typename?: 'EstimateUpdateResult';
    estimate?: {
      __typename?: 'Estimate';
      subtotal: Numeric;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
    } | null;
  };
};

export type QuantityCreateLinesMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimate: Scalars['UUID']['input'];
  lines: Array<LineInput>;
}>;

export type QuantityCreateLinesMutation = {
  __typename?: 'Mutation';
  createLines: {
    __typename?: 'LineCreateResult';
    lines: Array<{
      __typename?: 'Line';
      id: UUID;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      cells: Array<{
        __typename?: 'Cell';
        error?: string | null;
        value?:
          | {
              __typename?: 'CategoryCell';
              search: string;
              category?: {
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              } | null;
            }
          | {
              __typename?: 'RegularCell';
              string: string;
              formula: string;
              formulaDisplay: Array<{
                __typename?: 'FormulaDisplay';
                type: FormulaDisplayType;
                text: string;
                error: string;
              }>;
            }
          | {
              __typename?: 'SourceCell';
              sourceIDs?: {
                __typename?: 'SourceIDs';
                estimateID: UUID;
                itemID?: UUID | null;
                lineID?: UUID | null;
                markupID?: UUID | null;
                milestoneID: UUID;
              } | null;
            }
          | null;
      }>;
    }>;
    estimateUpdateResult?: {
      __typename?: 'EstimateUpdateResult';
      estimate?: {
        __typename?: 'Estimate';
        subtotal: Numeric;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
      } | null;
    } | null;
  };
};

export type QuantityUpdateCellsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimate: Scalars['UUID']['input'];
  cells: Array<CellInput>;
}>;

export type QuantityUpdateCellsMutation = {
  __typename?: 'Mutation';
  updateCells: {
    __typename?: 'CellUpdateResult';
    cells: Array<{
      __typename?: 'Cell';
      field: UUID;
      line: UUID;
      error?: string | null;
      value?:
        | {
            __typename?: 'CategoryCell';
            search: string;
            category?: {
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            } | null;
          }
        | {
            __typename?: 'RegularCell';
            string: string;
            formula: string;
            formulaDisplay: Array<{
              __typename?: 'FormulaDisplay';
              type: FormulaDisplayType;
              text: string;
              error: string;
            }>;
          }
        | {
            __typename?: 'SourceCell';
            sourceIDs?: {
              __typename?: 'SourceIDs';
              estimateID: UUID;
              itemID?: UUID | null;
              lineID?: UUID | null;
              markupID?: UUID | null;
              milestoneID: UUID;
            } | null;
          }
        | null;
    }>;
    estimateUpdateResult?: {
      __typename?: 'EstimateUpdateResult';
      estimate?: {
        __typename?: 'Estimate';
        subtotal: Numeric;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
      } | null;
    } | null;
  };
};

export type GridReplaceColumnCategoriesMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
  fieldID: Scalars['UUID']['input'];
  fromCategories: Array<Scalars['String']['input']>;
  toCategories: Array<Scalars['UUID']['input']>;
}>;

export type GridReplaceColumnCategoriesMutation = {
  __typename?: 'Mutation';
  replaceFieldCategories: {
    __typename?: 'CellUpdateResult';
    cells: Array<{
      __typename?: 'Cell';
      field: UUID;
      line: UUID;
      error?: string | null;
      value?:
        | {
            __typename?: 'CategoryCell';
            search: string;
            category?: {
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            } | null;
          }
        | {
            __typename?: 'RegularCell';
            string: string;
            formula: string;
            formulaDisplay: Array<{
              __typename?: 'FormulaDisplay';
              type: FormulaDisplayType;
              text: string;
              error: string;
            }>;
          }
        | {
            __typename?: 'SourceCell';
            sourceIDs?: {
              __typename?: 'SourceIDs';
              estimateID: UUID;
              itemID?: UUID | null;
              lineID?: UUID | null;
              markupID?: UUID | null;
              milestoneID: UUID;
            } | null;
          }
        | null;
    }>;
    estimateUpdateResult?: {
      __typename?: 'EstimateUpdateResult';
      estimate?: {
        __typename?: 'Estimate';
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
      } | null;
    } | null;
  };
};

export type CreateCategoriesMutationVariables = Exact<{
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  categorizationID: Scalars['UUID']['input'];
  categories: Array<CategoryContentInput>;
}>;

export type CreateCategoriesMutation = {
  __typename?: 'Mutation';
  createCategories: {
    __typename?: 'CreateCategoryResult';
    error: string;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      parentID?: UUID | null;
      name: string;
      hasSubCategories: boolean;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
    }>;
  };
};

export type EditCategoriesMutationVariables = Exact<{
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  categorizationID: Scalars['UUID']['input'];
  categories: Array<EditCategoryInput>;
}>;

export type EditCategoriesMutation = {
  __typename?: 'Mutation';
  editCategories: Array<{
    __typename?: 'Category';
    id: UUID;
    parentID?: UUID | null;
    name: string;
    hasSubCategories: boolean;
    number: string;
    level: number;
    error?: string | null;
    categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
  }>;
};

export type GetCategorizationQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  pagination?: InputMaybe<Pagination>;
}>;

export type GetCategorizationQuery = {
  __typename?: 'Query';
  categorization: {
    __typename?: 'Categorization';
    id: UUID;
    name: string;
    description: string;
    levels: number;
    builtin: boolean;
    content?: Array<{
      __typename?: 'Category';
      id: UUID;
      parentID?: UUID | null;
      name: string;
      hasSubCategories: boolean;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
    }> | null;
  };
};

export type DeleteCategoryMutationVariables = Exact<{
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  category: CategoryInput;
}>;

export type DeleteCategoryMutation = { __typename?: 'Mutation'; deleteCategory: string };

export type AcceptImportEstimateErrorMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
  errorID: Scalars['UUID']['input'];
}>;

export type AcceptImportEstimateErrorMutation = {
  __typename?: 'Mutation';
  acceptImportEstimateError: boolean;
};

export type SetEstimateTotalTypeMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
  estimateTotalType: EstimateTotalType;
}>;

export type SetEstimateTotalTypeMutation = {
  __typename?: 'Mutation';
  setEstimateTotalType: {
    __typename?: 'Estimate';
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
  };
};

export type GetEstimateSourceItemInfosQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
}>;

export type GetEstimateSourceItemInfosQuery = {
  __typename?: 'Query';
  estimateSourceItemInfos: Array<{
    __typename?: 'ItemLink';
    id: UUID;
    name: string;
    number: string;
    status: Status;
    visibility?: Visibility | null;
  }>;
};

export type GridReorderColumnMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
  fieldID: Scalars['UUID']['input'];
  ordering: Scalars['Int']['input'];
}>;

export type GridReorderColumnMutation = {
  __typename?: 'Mutation';
  reorderField: {
    __typename?: 'Estimate';
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
  };
};

export type GridRemoveColumnsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
  fieldIDs: Array<Scalars['UUID']['input']>;
}>;

export type GridRemoveColumnsMutation = {
  __typename?: 'Mutation';
  removeFields: {
    __typename?: 'Estimate';
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
  };
};

export type GridAddColumnsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
  fields: Array<FieldInput>;
}>;

export type GridAddColumnsMutation = {
  __typename?: 'Mutation';
  addFields: {
    __typename?: 'Estimate';
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
  };
};

export type GridReorderLinesMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimate: Scalars['UUID']['input'];
  lines: Array<ReorderInput>;
}>;

export type GridReorderLinesMutation = {
  __typename?: 'Mutation';
  reorderLines: {
    __typename?: 'LineReorderResult';
    lines: Array<{
      __typename?: 'Line';
      id: UUID;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      cells: Array<{
        __typename?: 'Cell';
        error?: string | null;
        value?:
          | {
              __typename?: 'CategoryCell';
              search: string;
              category?: {
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              } | null;
            }
          | {
              __typename?: 'RegularCell';
              string: string;
              formula: string;
              formulaDisplay: Array<{
                __typename?: 'FormulaDisplay';
                type: FormulaDisplayType;
                text: string;
                error: string;
              }>;
            }
          | {
              __typename?: 'SourceCell';
              sourceIDs?: {
                __typename?: 'SourceIDs';
                estimateID: UUID;
                itemID?: UUID | null;
                lineID?: UUID | null;
                markupID?: UUID | null;
                milestoneID: UUID;
              } | null;
            }
          | null;
      }>;
    }>;
  };
};

export type GridCreateLinesMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimate: Scalars['UUID']['input'];
  lines: Array<LineInput>;
}>;

export type GridCreateLinesMutation = {
  __typename?: 'Mutation';
  createLines: {
    __typename?: 'LineCreateResult';
    lines: Array<{
      __typename?: 'Line';
      id: UUID;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      cells: Array<{
        __typename?: 'Cell';
        error?: string | null;
        value?:
          | {
              __typename?: 'CategoryCell';
              search: string;
              category?: {
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
              } | null;
            }
          | {
              __typename?: 'RegularCell';
              string: string;
              formula: string;
              formulaDisplay: Array<{
                __typename?: 'FormulaDisplay';
                type: FormulaDisplayType;
                text: string;
                error: string;
              }>;
            }
          | {
              __typename?: 'SourceCell';
              sourceIDs?: {
                __typename?: 'SourceIDs';
                estimateID: UUID;
                itemID?: UUID | null;
                lineID?: UUID | null;
                markupID?: UUID | null;
                milestoneID: UUID;
              } | null;
            }
          | null;
      }>;
    }>;
    estimateUpdateResult?: {
      __typename?: 'EstimateUpdateResult';
      estimate?: {
        __typename?: 'Estimate';
        subtotal: Numeric;
        markupSubtotal: USCents;
        inheritedSubtotal: USCents;
        incorporatedSubtotal: USCents;
        incorporatedDrawsSubtotal: USCents;
        inheritedOwnerCostMarkupsSubtotal: USCents;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
        inheritedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        markups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        incorporatedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        incorporatedDraws: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedOwnerCostMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        ownerCostEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          linesCount?: number | null;
          name: string;
          milestoneName?: string | null;
          subtotal: Numeric;
          markupSubtotal: USCents;
          incorporatedSubtotal: USCents;
          incorporatedDrawsSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          drawSubtotal: USCents;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          incorporatedDraws: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            name: string;
            error: string;
            draw: USCents;
          }> | null;
        } | null;
      } | null;
    } | null;
  };
};

export type GridRemoveLineMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
  lineIDs: Array<Scalars['UUID']['input']>;
}>;

export type GridRemoveLineMutation = {
  __typename?: 'Mutation';
  removeLines: {
    __typename?: 'EstimateUpdateResult';
    estimate?: {
      __typename?: 'Estimate';
      subtotal: Numeric;
      markupSubtotal: USCents;
      inheritedSubtotal: USCents;
      incorporatedSubtotal: USCents;
      incorporatedDrawsSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      incorporatedDraws: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      ownerCostEstimate?: {
        __typename?: 'Estimate';
        id: UUID;
        linesCount?: number | null;
        name: string;
        milestoneName?: string | null;
        subtotal: Numeric;
        markupSubtotal: USCents;
        incorporatedSubtotal: USCents;
        incorporatedDrawsSubtotal: USCents;
        inheritedSubtotal: USCents;
        inheritedOwnerCostMarkupsSubtotal: USCents;
        drawSubtotal: USCents;
        totalType: EstimateTotalType;
        isRunningTotal: boolean;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
        incorporatedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        incorporatedDraws: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedOwnerCostMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        markups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        contingencyDraws?: Array<{
          __typename?: 'ItemDrawInfo';
          id: UUID;
          name: string;
          error: string;
          draw: USCents;
        }> | null;
      } | null;
    } | null;
  };
};

export type GridUpdateCellsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimate: Scalars['UUID']['input'];
  cells: Array<CellInput>;
}>;

export type GridUpdateCellsMutation = {
  __typename?: 'Mutation';
  updateCells: {
    __typename?: 'CellUpdateResult';
    cells: Array<{
      __typename?: 'Cell';
      field: UUID;
      line: UUID;
      error?: string | null;
      value?:
        | {
            __typename?: 'CategoryCell';
            search: string;
            category?: {
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            } | null;
          }
        | {
            __typename?: 'RegularCell';
            string: string;
            formula: string;
            formulaDisplay: Array<{
              __typename?: 'FormulaDisplay';
              type: FormulaDisplayType;
              text: string;
              error: string;
            }>;
          }
        | {
            __typename?: 'SourceCell';
            sourceIDs?: {
              __typename?: 'SourceIDs';
              estimateID: UUID;
              itemID?: UUID | null;
              lineID?: UUID | null;
              markupID?: UUID | null;
              milestoneID: UUID;
            } | null;
          }
        | null;
    }>;
    estimateUpdateResult?: {
      __typename?: 'EstimateUpdateResult';
      estimate?: {
        __typename?: 'Estimate';
        subtotal: Numeric;
        markupSubtotal: USCents;
        inheritedSubtotal: USCents;
        incorporatedSubtotal: USCents;
        incorporatedDrawsSubtotal: USCents;
        inheritedOwnerCostMarkupsSubtotal: USCents;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
        inheritedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        markups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        incorporatedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        incorporatedDraws: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedOwnerCostMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        ownerCostEstimate?: {
          __typename?: 'Estimate';
          id: UUID;
          linesCount?: number | null;
          name: string;
          milestoneName?: string | null;
          subtotal: Numeric;
          markupSubtotal: USCents;
          incorporatedSubtotal: USCents;
          incorporatedDrawsSubtotal: USCents;
          inheritedSubtotal: USCents;
          inheritedOwnerCostMarkupsSubtotal: USCents;
          drawSubtotal: USCents;
          totalType: EstimateTotalType;
          isRunningTotal: boolean;
          fields: Array<{
            __typename?: 'Field';
            id: UUID;
            name: string;
            type: string;
            group: string;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              description: string;
              levels: number;
              builtin: boolean;
              createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
            } | null;
            errors: Array<{
              __typename?: 'FieldError';
              type: FieldErrorType;
              count: number;
              categoryErrorIndicies: Array<{
                __typename?: 'CategoryErrorIndicies';
                categoryNumber: string;
                indicies: Array<number>;
              }>;
            }>;
          }>;
          incorporatedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          incorporatedDraws: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          inheritedOwnerCostMarkups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          markups: Array<{
            __typename?: 'Markup';
            id: UUID;
            estimateId: UUID;
            name: string;
            percent?: number | null;
            percentScale?: number | null;
            sourceFilterIDs: Array<UUID>;
            sourceFilterIDsWithoutS2Reference: Array<UUID>;
            total: USCents;
            type: MarkupType;
            displayType: MarkupDisplayType;
            drawFromID?: UUID | null;
            value?: Numeric | null;
            disabled: boolean;
            orderingNumerator: Ordering;
            orderingDenominator: Ordering;
            costTypeFilters?: Array<CostType> | null;
            shouldExcludeFromAllocation: boolean;
            shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
            errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
            markupReference?: {
              __typename?: 'MarkupReference';
              appliesTo: Array<string>;
              item?: {
                __typename?: 'MarkupItemReference';
                number: string;
                name: string;
                isDeprecated: boolean;
              } | null;
            } | null;
            categoryFilters: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              level: number;
              error?: string | null;
              categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
            }>;
            source?: {
              __typename?: 'SourceIDs';
              itemID?: UUID | null;
              estimateID: UUID;
              milestoneID: UUID;
              lineID?: UUID | null;
              markupID?: UUID | null;
            } | null;
          }>;
          contingencyDraws?: Array<{
            __typename?: 'ItemDrawInfo';
            id: UUID;
            name: string;
            error: string;
            draw: USCents;
          }> | null;
        } | null;
      } | null;
    } | null;
  };
};

export type GetDoMilestoneMarkupsHaveContingencyDrawsQueryVariables = Exact<{
  estimateID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  markupIDs: Array<Scalars['UUID']['input']>;
}>;

export type GetDoMilestoneMarkupsHaveContingencyDrawsQuery = {
  __typename?: 'Query';
  getDoMilestoneMarkupsHaveContingencyDraws: boolean;
};

export type CreateOwnerCostEstimateMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
}>;

export type CreateOwnerCostEstimateMutation = {
  __typename?: 'Mutation';
  createOwnerCostEstimate: UUID;
};

export type GetMilestoneItemCountQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetMilestoneItemCountQuery = {
  __typename?: 'Query';
  itemCounts: {
    __typename?: 'ItemCounts';
    rejected: number;
    pending: number;
    accepted: number;
    incorporated: number;
    total: number;
  };
};

export type CommonMilestonePublishDraftFieldsFragment = {
  __typename?: 'Milestone';
  id: UUID;
  name: string;
  description: string;
  deletable: boolean;
  date?: Time | null;
  isDraft: boolean;
  createdBy?: { __typename?: 'User'; id: UUID; email: string; name: string } | null;
};

export type SetMilestonePublishDraftMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  input: SetMilestonePublishDraftInput;
}>;

export type SetMilestonePublishDraftMutation = {
  __typename?: 'Mutation';
  setMilestonePublishDraft: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    createdBy?: { __typename?: 'User'; id: UUID; email: string; name: string } | null;
  };
};

export type SourceMilestoneFieldsFragment = { __typename?: 'Milestone'; id: UUID; name: string };

export type GetSourceMilestoneQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetSourceMilestoneQuery = {
  __typename?: 'Query';
  milestone?: { __typename?: 'Milestone'; id: UUID; name: string } | null;
};

export type CommonBucketFieldsFragment = {
  __typename?: 'Bucket';
  id: UUID;
  name: string;
  date?: Time | null;
  itemsCount: number;
  milestone?: { __typename?: 'Milestone'; id: UUID; name: string; date?: Time | null } | null;
};

export type CreateOrUpdateBucketMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  milestone: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Time']['input']>;
}>;

export type CreateOrUpdateBucketMutation = {
  __typename?: 'Mutation';
  createOrUpdateBucket: Array<{
    __typename?: 'Bucket';
    id: UUID;
    name: string;
    date?: Time | null;
    itemsCount: number;
    milestone?: { __typename?: 'Milestone'; id: UUID; name: string; date?: Time | null } | null;
  }>;
};

export type DeleteBucketMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  bucket: Scalars['UUID']['input'];
}>;

export type DeleteBucketMutation = {
  __typename?: 'Mutation';
  deleteBucket: Array<{
    __typename?: 'Bucket';
    id: UUID;
    name: string;
    date?: Time | null;
    itemsCount: number;
    milestone?: { __typename?: 'Milestone'; id: UUID; name: string; date?: Time | null } | null;
  }>;
};

export type GetProjectUnitsQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  isEnabledOnly: Scalars['Boolean']['input'];
}>;

export type GetProjectUnitsQuery = {
  __typename?: 'Query';
  getProjectUnits: Array<{
    __typename?: 'Unit';
    abbreviationPlural: string;
    abbreviationSingular: string;
    description?: string | null;
    id: UUID;
    isDefault: boolean;
    isEnabled: boolean;
    name: string;
    type: UnitType;
  }>;
};

export type ToggleProjectUnitsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  units: Array<UnitToggleInput>;
}>;

export type ToggleProjectUnitsMutation = {
  __typename?: 'Mutation';
  toggleProjectUnits: Array<{
    __typename?: 'Unit';
    abbreviationPlural: string;
    abbreviationSingular: string;
    description?: string | null;
    id: UUID;
    isDefault: boolean;
    isEnabled: boolean;
    name: string;
    type: UnitType;
  }>;
};

export type CreateOrUpdateCustomUnitMutationVariables = Exact<{
  unit: UnitInput;
  projectID: Scalars['UUID']['input'];
}>;

export type CreateOrUpdateCustomUnitMutation = {
  __typename?: 'Mutation';
  createOrUpdateCustomUnit: {
    __typename?: 'Unit';
    abbreviationPlural: string;
    abbreviationSingular: string;
    description?: string | null;
    id: UUID;
    isDefault: boolean;
    isEnabled: boolean;
    name: string;
    type: UnitType;
  };
};

export type RemoveCustomUnitMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  unitID: Scalars['UUID']['input'];
}>;

export type RemoveCustomUnitMutation = { __typename?: 'Mutation'; removeCustomUnit?: UUID | null };

export type CreateQuantityMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  unitID: Scalars['UUID']['input'];
  magnitude: Scalars['Numeric']['input'];
}>;

export type CreateQuantityMutation = {
  __typename?: 'Mutation';
  createQuantity: {
    __typename?: 'Quantity';
    id: UUID;
    magnitude: Numeric;
    estimateID: UUID;
    hasBreakdown: boolean;
    unit: {
      __typename?: 'Unit';
      abbreviationPlural: string;
      abbreviationSingular: string;
      description?: string | null;
      id: UUID;
      isDefault: boolean;
      isEnabled: boolean;
      name: string;
      type: UnitType;
    };
  };
};

export type AddQuantityBreakdownMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  quantityID?: InputMaybe<Scalars['UUID']['input']>;
  unitID?: InputMaybe<Scalars['UUID']['input']>;
  fields: Array<FieldInput>;
}>;

export type AddQuantityBreakdownMutation = {
  __typename?: 'Mutation';
  addQuantityBreakdown: {
    __typename?: 'Quantity';
    id: UUID;
    magnitude: Numeric;
    estimateID: UUID;
    hasBreakdown: boolean;
    unit: {
      __typename?: 'Unit';
      abbreviationPlural: string;
      abbreviationSingular: string;
      description?: string | null;
      id: UUID;
      isDefault: boolean;
      isEnabled: boolean;
      name: string;
      type: UnitType;
    };
  };
};

export type RemoveQuantityBreakdownMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  quantityID: Scalars['UUID']['input'];
  estimateID: Scalars['UUID']['input'];
  unitID: Scalars['UUID']['input'];
  magnitude: Scalars['Numeric']['input'];
}>;

export type RemoveQuantityBreakdownMutation = {
  __typename?: 'Mutation';
  removeQuantityBreakdown: {
    __typename?: 'Quantity';
    id: UUID;
    magnitude: Numeric;
    estimateID: UUID;
    hasBreakdown: boolean;
    unit: {
      __typename?: 'Unit';
      abbreviationPlural: string;
      abbreviationSingular: string;
      description?: string | null;
      id: UUID;
      isDefault: boolean;
      isEnabled: boolean;
      name: string;
      type: UnitType;
    };
  };
};

export type GetMilestoneQuantitiesQueryVariables = Exact<{
  milestoneID: Scalars['UUID']['input'];
}>;

export type GetMilestoneQuantitiesQuery = {
  __typename?: 'Query';
  quantities: Array<{
    __typename?: 'Quantity';
    id: UUID;
    magnitude: Numeric;
    estimateID: UUID;
    hasBreakdown: boolean;
    unit: {
      __typename?: 'Unit';
      abbreviationPlural: string;
      abbreviationSingular: string;
      description?: string | null;
      id: UUID;
      isDefault: boolean;
      isEnabled: boolean;
      name: string;
      type: UnitType;
    };
  }>;
};

export type DeleteMilestonesMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
}>;

export type DeleteMilestonesMutation = {
  __typename?: 'Mutation';
  updateProject?: {
    __typename?: 'Project';
    id: UUID;
    description: string;
    descriptionStyled: string;
    name: string;
    location: string;
    thumbnail: string;
    activeMilestone: { __typename?: 'Milestone'; id: UUID; name: string };
    status: {
      __typename?: 'ProjectStatus';
      id: UUID;
      name: string;
      type: string;
      description: string;
    };
    team?: {
      __typename?: 'Team';
      owner?: { __typename?: 'Organization'; name?: string | null; picture?: string | null } | null;
      generalContractor?: {
        __typename?: 'Organization';
        name?: string | null;
        picture?: string | null;
      } | null;
      designTeam?: {
        __typename?: 'Organization';
        name?: string | null;
        picture?: string | null;
      } | null;
    } | null;
    type: { __typename?: 'ProjectType'; id: UUID; name: string };
    milestones: Array<{
      __typename?: 'Milestone';
      id: UUID;
      name: string;
      description: string;
      descriptionStyled: string;
      deletable: boolean;
      date?: Time | null;
      isDraft: boolean;
      itemsCount: number;
      draftItemsCount: number;
      assetCount: number;
      remoteAssetCount: number;
      activeEstimateID?: UUID | null;
      activeEstimateDraftID?: UUID | null;
      budgetID?: UUID | null;
      budgetDraftID?: UUID | null;
      buckets?: Array<{
        __typename?: 'Bucket';
        date?: Time | null;
        id: UUID;
        name: string;
        itemsCount: number;
      }> | null;
      createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      designPhase?: {
        __typename?: 'DesignPhaseType';
        id: UUID;
        name: string;
        abbreviation: string;
      } | null;
      timelinePhase?: {
        __typename?: 'TimelineActivity';
        id: UUID;
        name: string;
        startDate: Time;
        endDate?: Time | null;
      } | null;
      importedEstimates: Array<{
        __typename?: 'Asset';
        id: UUID;
        name: string;
        size: Size;
        lastUpdated: Time;
        location: string;
        type?: string | null;
        derivations: Array<{
          __typename?: 'Derivation';
          id: UUID;
          kind: string;
          contents: Array<string>;
        }>;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }>;
      quantities?: Array<{
        __typename?: 'Quantity';
        id: UUID;
        magnitude: Numeric;
        estimateID: UUID;
        hasBreakdown: boolean;
        unit: {
          __typename?: 'Unit';
          abbreviationPlural: string;
          abbreviationSingular: string;
          description?: string | null;
          id: UUID;
          isDefault: boolean;
          isEnabled: boolean;
          name: string;
          type: UnitType;
        };
      } | null> | null;
    }>;
    roles: Array<{
      __typename?: 'Role';
      id: UUID;
      name: string;
      type: ProjectRoleType;
      hasTrade: boolean;
      permissionGroups: Array<{
        __typename?: 'PermissionGroup';
        type: PermissionGroupType;
        permissions: Array<{
          __typename?: 'Permission';
          id: UUID;
          level: PermissionLevel;
          resource: PermissionResource;
          actions: {
            __typename?: 'Actions';
            view: boolean;
            add: boolean;
            delete: boolean;
            edit: boolean;
          };
        }>;
      }>;
    }>;
    projectDeliveryType?: {
      __typename?: 'ProjectDeliveryType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    locationDetails?: {
      __typename?: 'LocationDetails';
      name: string;
      lat: number;
      lon: number;
      city: string;
      state: string;
      stateLong: string;
      country: string;
    } | null;
  } | null;
};

export type SetMilestoneNameMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  input?: InputMaybe<SetMilestoneNameInput>;
}>;

export type SetMilestoneNameMutation = {
  __typename?: 'Mutation';
  setMilestoneName: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    assetCount: number;
    remoteAssetCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    buckets?: Array<{
      __typename?: 'Bucket';
      date?: Time | null;
      id: UUID;
      name: string;
      itemsCount: number;
    }> | null;
    createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    importedEstimates: Array<{
      __typename?: 'Asset';
      id: UUID;
      name: string;
      size: Size;
      lastUpdated: Time;
      location: string;
      type?: string | null;
      derivations: Array<{
        __typename?: 'Derivation';
        id: UUID;
        kind: string;
        contents: Array<string>;
      }>;
      uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    }>;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  };
};

export type SetMilestoneDescriptionMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  input?: InputMaybe<SetMilestoneDescriptionInput>;
}>;

export type SetMilestoneDescriptionMutation = {
  __typename?: 'Mutation';
  setMilestoneDescription: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    assetCount: number;
    remoteAssetCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    buckets?: Array<{
      __typename?: 'Bucket';
      date?: Time | null;
      id: UUID;
      name: string;
      itemsCount: number;
    }> | null;
    createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    importedEstimates: Array<{
      __typename?: 'Asset';
      id: UUID;
      name: string;
      size: Size;
      lastUpdated: Time;
      location: string;
      type?: string | null;
      derivations: Array<{
        __typename?: 'Derivation';
        id: UUID;
        kind: string;
        contents: Array<string>;
      }>;
      uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    }>;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  };
};

export type SetMilestoneDateMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  input?: InputMaybe<SetMilestoneDateInput>;
}>;

export type SetMilestoneDateMutation = {
  __typename?: 'Mutation';
  setMilestoneDate: Array<{
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    assetCount: number;
    remoteAssetCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    buckets?: Array<{
      __typename?: 'Bucket';
      date?: Time | null;
      id: UUID;
      name: string;
      itemsCount: number;
    }> | null;
    createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    importedEstimates: Array<{
      __typename?: 'Asset';
      id: UUID;
      name: string;
      size: Size;
      lastUpdated: Time;
      location: string;
      type?: string | null;
      derivations: Array<{
        __typename?: 'Derivation';
        id: UUID;
        kind: string;
        contents: Array<string>;
      }>;
      uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    }>;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  } | null>;
};

export type SetMilestoneDesignPhaseMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  input: SetMilestoneDesignPhaseInput;
}>;

export type SetMilestoneDesignPhaseMutation = {
  __typename?: 'Mutation';
  setMilestoneDesignPhase: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    assetCount: number;
    remoteAssetCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    buckets?: Array<{
      __typename?: 'Bucket';
      date?: Time | null;
      id: UUID;
      name: string;
      itemsCount: number;
    }> | null;
    createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    importedEstimates: Array<{
      __typename?: 'Asset';
      id: UUID;
      name: string;
      size: Size;
      lastUpdated: Time;
      location: string;
      type?: string | null;
      derivations: Array<{
        __typename?: 'Derivation';
        id: UUID;
        kind: string;
        contents: Array<string>;
      }>;
      uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    }>;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  };
};

export type SetMilestoneTimelinePhaseMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  input: SetMilestoneTimelinePhaseInput;
}>;

export type SetMilestoneTimelinePhaseMutation = {
  __typename?: 'Mutation';
  setMilestoneTimelinePhase: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    assetCount: number;
    remoteAssetCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    buckets?: Array<{
      __typename?: 'Bucket';
      date?: Time | null;
      id: UUID;
      name: string;
      itemsCount: number;
    }> | null;
    createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    importedEstimates: Array<{
      __typename?: 'Asset';
      id: UUID;
      name: string;
      size: Size;
      lastUpdated: Time;
      location: string;
      type?: string | null;
      derivations: Array<{
        __typename?: 'Derivation';
        id: UUID;
        kind: string;
        contents: Array<string>;
      }>;
      uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    }>;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  };
};

export type GetMilestoneQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetMilestoneQuery = {
  __typename?: 'Query';
  milestone?: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    assetCount: number;
    remoteAssetCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    buckets?: Array<{
      __typename?: 'Bucket';
      date?: Time | null;
      id: UUID;
      name: string;
      itemsCount: number;
    }> | null;
    createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    importedEstimates: Array<{
      __typename?: 'Asset';
      id: UUID;
      name: string;
      size: Size;
      lastUpdated: Time;
      location: string;
      type?: string | null;
      derivations: Array<{
        __typename?: 'Derivation';
        id: UUID;
        kind: string;
        contents: Array<string>;
      }>;
      uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    }>;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  } | null;
};

export type DesignPhaseTypesQueryVariables = Exact<{ [key: string]: never }>;

export type DesignPhaseTypesQuery = {
  __typename?: 'Query';
  designPhaseTypes: Array<{
    __typename?: 'DesignPhaseType';
    id: UUID;
    name: string;
    abbreviation: string;
  }>;
};

export type GetMilestonesQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  showDrafts: Scalars['Boolean']['input'];
}>;

export type GetMilestonesQuery = {
  __typename?: 'Query';
  milestones: Array<{
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    createdBy?: { __typename?: 'User'; id: UUID } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string; itemsCount: number }> | null;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  }>;
};

export type GetDetailedMilestonesQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  showDrafts: Scalars['Boolean']['input'];
}>;

export type GetDetailedMilestonesQuery = {
  __typename?: 'Query';
  milestones: Array<{
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    description: string;
    descriptionStyled: string;
    deletable: boolean;
    date?: Time | null;
    isDraft: boolean;
    itemsCount: number;
    draftItemsCount: number;
    assetCount: number;
    remoteAssetCount: number;
    activeEstimateID?: UUID | null;
    activeEstimateDraftID?: UUID | null;
    budgetID?: UUID | null;
    budgetDraftID?: UUID | null;
    buckets?: Array<{
      __typename?: 'Bucket';
      date?: Time | null;
      id: UUID;
      name: string;
      itemsCount: number;
    }> | null;
    createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    designPhase?: {
      __typename?: 'DesignPhaseType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    timelinePhase?: {
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      startDate: Time;
      endDate?: Time | null;
    } | null;
    importedEstimates: Array<{
      __typename?: 'Asset';
      id: UUID;
      name: string;
      size: Size;
      lastUpdated: Time;
      location: string;
      type?: string | null;
      derivations: Array<{
        __typename?: 'Derivation';
        id: UUID;
        kind: string;
        contents: Array<string>;
      }>;
      uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
    }>;
    quantities?: Array<{
      __typename?: 'Quantity';
      id: UUID;
      magnitude: Numeric;
      estimateID: UUID;
      hasBreakdown: boolean;
      unit: {
        __typename?: 'Unit';
        abbreviationPlural: string;
        abbreviationSingular: string;
        description?: string | null;
        id: UUID;
        isDefault: boolean;
        isEnabled: boolean;
        name: string;
        type: UnitType;
      };
    } | null> | null;
  }>;
};

export type OrganizationsQueryVariables = Exact<{
  companyID: Scalars['UUID']['input'];
}>;

export type OrganizationsQuery = {
  __typename?: 'Query';
  organizations: Array<{
    __typename?: 'Org';
    id: UUID;
    isDraft: boolean;
    name: string;
    levels: Array<string>;
    nodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string; parentID?: UUID | null }>;
  }>;
};

export type OrganizationQueryVariables = Exact<{
  organizationID: Scalars['UUID']['input'];
}>;

export type OrganizationQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Org';
    id: UUID;
    isDraft: boolean;
    name: string;
    levels: Array<string>;
    nodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string; parentID?: UUID | null }>;
  };
};

export type GetProjectsUsingOrgNodeQueryVariables = Exact<{
  orgNodeID: Scalars['UUID']['input'];
}>;

export type GetProjectsUsingOrgNodeQuery = {
  __typename?: 'Query';
  getProjectsUsingOrgNode: Array<{
    __typename?: 'ProjectOrgNodeInfo';
    name: string;
    projectID: UUID;
    nodeID: UUID;
  }>;
};

export type GetProjectsUsingOrgQueryVariables = Exact<{
  organizationID: Scalars['UUID']['input'];
}>;

export type GetProjectsUsingOrgQuery = {
  __typename?: 'Query';
  getProjectsUsingOrg: Array<{
    __typename?: 'ProjectOrgNodeInfo';
    name: string;
    projectID: UUID;
    nodeID: UUID;
  }>;
};

export type CreateOrgMutationVariables = Exact<{
  input: CreateOrgInput;
}>;

export type CreateOrgMutation = {
  __typename?: 'Mutation';
  createOrg: {
    __typename?: 'CreateOrgPayload';
    organization?: {
      __typename?: 'Org';
      id: UUID;
      isDraft: boolean;
      name: string;
      levels: Array<string>;
      nodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string; parentID?: UUID | null }>;
    } | null;
  };
};

export type CreateOrgNodeMutationVariables = Exact<{
  input: CreateOrgNodeInput;
}>;

export type CreateOrgNodeMutation = {
  __typename?: 'Mutation';
  createOrgNode: {
    __typename?: 'CreateOrgNodePayload';
    organization?: {
      __typename?: 'Org';
      id: UUID;
      isDraft: boolean;
      name: string;
      levels: Array<string>;
      nodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string; parentID?: UUID | null }>;
    } | null;
  };
};

export type DeleteOrgMutationVariables = Exact<{
  input: DeleteOrgInput;
}>;

export type DeleteOrgMutation = {
  __typename?: 'Mutation';
  deleteOrg: {
    __typename?: 'DeleteOrgPayload';
    organization?: { __typename?: 'Org'; id: UUID } | null;
  };
};

export type DeleteOrgNodeMutationVariables = Exact<{
  input: DeleteOrgNodeInput;
}>;

export type DeleteOrgNodeMutation = {
  __typename?: 'Mutation';
  deleteOrgNode: {
    __typename?: 'DeleteOrgNodePayload';
    organization?: {
      __typename?: 'Org';
      id: UUID;
      isDraft: boolean;
      name: string;
      levels: Array<string>;
      nodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string; parentID?: UUID | null }>;
    } | null;
  };
};

export type SetOrgLevelMutationVariables = Exact<{
  input: SetOrgLevelInput;
}>;

export type SetOrgLevelMutation = {
  __typename?: 'Mutation';
  setOrgLevel: {
    __typename?: 'Org';
    id: UUID;
    isDraft: boolean;
    name: string;
    levels: Array<string>;
    nodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string; parentID?: UUID | null }>;
  };
};

export type SetOrgNameMutationVariables = Exact<{
  input: SetOrgNameInput;
}>;

export type SetOrgNameMutation = {
  __typename?: 'Mutation';
  setOrgName: {
    __typename?: 'Org';
    id: UUID;
    isDraft: boolean;
    name: string;
    levels: Array<string>;
    nodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string; parentID?: UUID | null }>;
  };
};

export type AddOrgLevelMutationVariables = Exact<{
  input: AddOrgLevelInput;
}>;

export type AddOrgLevelMutation = {
  __typename?: 'Mutation';
  addOrgLevel: {
    __typename?: 'Org';
    id: UUID;
    isDraft: boolean;
    name: string;
    levels: Array<string>;
    nodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string; parentID?: UUID | null }>;
  };
};

export type DeleteOrgLevelMutationVariables = Exact<{
  input: DeleteOrgLevelInput;
}>;

export type DeleteOrgLevelMutation = {
  __typename?: 'Mutation';
  deleteOrgLevel: {
    __typename?: 'Org';
    id: UUID;
    isDraft: boolean;
    name: string;
    levels: Array<string>;
    nodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string; parentID?: UUID | null }>;
  };
};

export type SetOrgNodeNameMutationVariables = Exact<{
  input: SetOrgNodeNameInput;
}>;

export type SetOrgNodeNameMutation = {
  __typename?: 'Mutation';
  setOrgNodeName: {
    __typename?: 'SetOrgNodeNamePayload';
    organization?: {
      __typename?: 'Org';
      id: UUID;
      isDraft: boolean;
      name: string;
      levels: Array<string>;
      nodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string; parentID?: UUID | null }>;
    } | null;
  };
};

export type SetOrgPublishedMutationVariables = Exact<{
  input: SetOrgPublishedInput;
}>;

export type SetOrgPublishedMutation = {
  __typename?: 'Mutation';
  setOrgPublished: {
    __typename?: 'SetOrgPublishedPayload';
    organization?: {
      __typename?: 'Org';
      id: UUID;
      isDraft: boolean;
      name: string;
      levels: Array<string>;
      nodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string; parentID?: UUID | null }>;
    } | null;
  };
};

export type SetProjectOrgsMutationVariables = Exact<{
  input: SetProjectOrgsInput;
}>;

export type SetProjectOrgsMutation = {
  __typename?: 'Mutation';
  setProjectOrgs: { __typename?: 'SetProjectOrgsPayload'; removedNodeIDs: Array<UUID> };
};

export type SetBulkProjectOrgsMutationVariables = Exact<{
  input: SetBulkProjectOrgsInput;
}>;

export type SetBulkProjectOrgsMutation = {
  __typename?: 'Mutation';
  setBulkProjectOrgs: Array<{ __typename?: 'SetProjectOrgsPayload'; removedNodeIDs: Array<UUID> }>;
};

export type ReportPageLoadMutationVariables = Exact<{
  project?: InputMaybe<Scalars['UUID']['input']>;
  type: UserEventType;
  page: Scalars['String']['input'];
  loadTime: Scalars['Float']['input'];
}>;

export type ReportPageLoadMutation = { __typename?: 'Mutation'; reportUserEvent: boolean };

export type ReportImportEstimateTimeMutationVariables = Exact<{
  milestoneEstimateID: Scalars['UUID']['input'];
  loadTime: Scalars['Float']['input'];
  type: UserEventType;
}>;

export type ReportImportEstimateTimeMutation = {
  __typename?: 'Mutation';
  reportImportEstimateTimeEvent: boolean;
};

export type MilestonesCostTrendsBreakdownsDataQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  columns: Array<CostReportColumnInput>;
  costMode: CostMode;
  intervalType: IntervalType;
  viewFilters: Array<ViewFilter>;
  summary: Scalars['Boolean']['input'];
  milestones: Array<CostReportMilestoneInput>;
  groupByItems: Array<GroupByItem>;
}>;

export type MilestonesCostTrendsBreakdownsDataQuery = {
  __typename?: 'Query';
  milestonesCostTrendsBreakdowns: Array<{
    __typename?: 'MilestoneCostTrendsBreakdown';
    categoryID?: UUID | null;
    segments: Array<{
      __typename?: 'Segment';
      milestone: {
        __typename?: 'Milestone';
        id: UUID;
        name: string;
        description: string;
        date?: Time | null;
        quantities?: Array<{
          __typename?: 'Quantity';
          id: UUID;
          magnitude: Numeric;
          estimateID: UUID;
          hasBreakdown: boolean;
          unit: {
            __typename?: 'Unit';
            abbreviationPlural: string;
            abbreviationSingular: string;
            description?: string | null;
            id: UUID;
            isDefault: boolean;
            isEnabled: boolean;
            name: string;
            type: UnitType;
          };
        } | null> | null;
      };
      points: Array<{
        __typename?: 'Point';
        dateStart: Time;
        dateEnd: Time;
        isVisible: boolean;
        values: Array<{
          __typename?: 'PointValue';
          type: CostReportColumnType;
          y: USCents;
          isChanged: boolean;
        }>;
      }>;
    }>;
    milestoneCostReports: Array<{
      __typename?: 'MilestoneCostReports';
      costReportColumns: Array<{
        __typename?: 'CostReportColumn';
        type: CostReportColumnType;
        columnKey: CostReportColumnKey;
        isCategorized?: boolean | null;
        report?: {
          __typename?: 'CostReport';
          numeric: Numeric;
          type: CostReportType;
          directCostRange:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          directCostSegmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
          projectTotalRange?:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents }
            | null;
          projectTotalSegmented?: {
            __typename?: 'AddDeductCost';
            adds: USCents;
            deducts: USCents;
          } | null;
          range:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
          subtotals: Array<{
            __typename?: 'CostReportItem';
            categories: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              error?: string | null;
              level: number;
              hasSubCategories: boolean;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                builtin: boolean;
                description: string;
                draft: boolean;
                levels: number;
                deprecated: boolean;
              } | null;
              levels: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                hasSubCategories: boolean;
                level: number;
              }>;
            }>;
            subtotalValue:
              | {
                  __typename?: 'SubtotalAddsDeducts';
                  directCostSegmented: {
                    __typename?: 'PartialAddDeductCost';
                    adds?: USCents | null;
                    deducts?: USCents | null;
                  };
                  segmented: {
                    __typename?: 'PartialAddDeductCost';
                    adds?: USCents | null;
                    deducts?: USCents | null;
                  };
                }
              | {
                  __typename?: 'SubtotalCost';
                  directCostRange:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                  range:
                    | { __typename?: 'CostRange'; min: USCents; max: USCents }
                    | { __typename?: 'CostScalar'; value: USCents };
                }
              | { __typename?: 'SubtotalNumeric'; value: Numeric };
          }>;
          categorizedItemCosts: Array<{
            __typename?: 'CategorizedItemCost';
            id: UUID;
            milestoneId: UUID;
            name: string;
            number: string;
            parentId?: UUID | null;
            status?: Status | null;
            canViewCosts: boolean;
            categories: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              error?: string | null;
              level: number;
              hasSubCategories: boolean;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                builtin: boolean;
                description: string;
                draft: boolean;
                levels: number;
                deprecated: boolean;
              } | null;
              levels: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                hasSubCategories: boolean;
                level: number;
              }>;
            }>;
            directCostRange:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            range:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            itemContributionLines?: Array<{
              __typename?: 'ItemContributionLines';
              lineID?: UUID | null;
              lineDescription?: string | null;
              categories: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                error?: string | null;
                level: number;
                hasSubCategories: boolean;
                categorization?: {
                  __typename?: 'Categorization';
                  id: UUID;
                  name: string;
                  builtin: boolean;
                  description: string;
                  draft: boolean;
                  levels: number;
                  deprecated: boolean;
                } | null;
                levels: Array<{
                  __typename?: 'Category';
                  id: UUID;
                  name: string;
                  number: string;
                  hasSubCategories: boolean;
                  level: number;
                }>;
              }>;
              directCostRange:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              range:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
            } | null> | null;
          }>;
          markupContributions: Array<{
            __typename?: 'MarkupContribution';
            isIncorporated: boolean;
            isOwnerCost: boolean;
            markupId: UUID;
            markupName: string;
            displayType: MarkupDisplayType;
            range:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
          }>;
        } | null;
        quantity?: {
          __typename?: 'Quantity';
          id: UUID;
          magnitude: Numeric;
          estimateID: UUID;
          hasBreakdown: boolean;
          unit: {
            __typename?: 'Unit';
            abbreviationPlural: string;
            abbreviationSingular: string;
            description?: string | null;
            id: UUID;
            isDefault: boolean;
            isEnabled: boolean;
            name: string;
            type: UnitType;
          };
        } | null;
      }>;
    }>;
  }>;
};

export type DetailedPrintItemFieldsFragment = {
  __typename?: 'Item';
  id: UUID;
  itemType: ItemType;
  description: string;
  descriptionStyled: string;
  dueDate?: Time | null;
  creationTime?: Time | null;
  updateTime?: Time | null;
  name: string;
  number: string;
  status: Status;
  visibility: Visibility;
  availableStates: Array<Status>;
  assetCount: number;
  remoteAssetCount: number;
  commentCount: number;
  filteredMilestone?: UUID | null;
  filteredCategories?: Array<UUID> | null;
  activityIDs?: Array<UUID> | null;
  options: Array<{
    __typename?: 'Option';
    parent: UUID;
    parentVisibility?: Visibility | null;
    hasEstimate?: boolean | null;
    id: UUID;
    itemType: ItemType;
    description: string;
    descriptionStyled: string;
    dueDate?: Time | null;
    creationTime?: Time | null;
    updateTime?: Time | null;
    name: string;
    number: string;
    status: Status;
    visibility: Visibility;
    availableStates: Array<Status>;
    assetCount: number;
    remoteAssetCount: number;
    commentCount: number;
    filteredMilestone?: UUID | null;
    filteredCategories?: Array<UUID> | null;
    activityIDs?: Array<UUID> | null;
    unfilteredCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    comments: Array<{
      __typename?: 'Comment';
      id: UUID;
      created: Time;
      author: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      text: {
        __typename?: 'MentionableText';
        contents: string;
        mentions?: Array<{
          __typename?: 'Mention';
          id: UUID;
          position: number;
          text: string;
          user: { __typename?: 'User'; id: UUID };
        }> | null;
        itemReferences?: Array<{
          __typename?: 'ItemReference';
          id: UUID;
          position: number;
          text: string;
          referencedItem?: {
            __typename?: 'ItemsListItem';
            createdAt: Time;
            currentStatus: Status;
            id: UUID;
            name: string;
            number: string;
            status: Status;
            updateTime: Time;
            visibility: Visibility;
            creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
            currentCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
            options: Array<{
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              status: Status;
              visibility?: Visibility | null;
            }>;
          } | null;
        }> | null;
      };
    }>;
    assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
    activeEstimate?: {
      __typename?: 'Estimate';
      id: UUID;
      name: string;
      subtotal: Numeric;
      markupSubtotal: USCents;
      drawSubtotal: USCents;
      inheritedSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      asset?: UUID | null;
      totalType: EstimateTotalType;
      isRunningTotal: boolean;
      lines: Array<{
        __typename?: 'Line';
        id: UUID;
        estimateId: UUID;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        cells: Array<{
          __typename?: 'Cell';
          estimateId: UUID;
          field: UUID;
          line: UUID;
          error?: string | null;
          value?:
            | {
                __typename?: 'CategoryCell';
                search: string;
                category?: {
                  __typename?: 'Category';
                  id: UUID;
                  parentID?: UUID | null;
                  name: string;
                  hasSubCategories: boolean;
                  number: string;
                  level: number;
                  error?: string | null;
                  categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                  levels: Array<{
                    __typename?: 'Category';
                    id: UUID;
                    name: string;
                    number: string;
                  }>;
                } | null;
              }
            | { __typename?: 'RegularCell'; string: string }
            | {
                __typename?: 'SourceCell';
                sourceIDs?: {
                  __typename?: 'SourceIDs';
                  estimateID: UUID;
                  itemID?: UUID | null;
                  lineID?: UUID | null;
                  markupID?: UUID | null;
                  milestoneID: UUID;
                } | null;
              }
            | null;
        }>;
      }>;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      contingencyDraws?: Array<{
        __typename?: 'ItemDrawInfo';
        id: UUID;
        type: MarkupDisplayType;
        name: string;
        error: string;
        draw: USCents;
        drawFromID?: UUID | null;
        isDrawingFullAmount: boolean;
      }> | null;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
    } | null;
    bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
    project: { __typename?: 'Project'; id: UUID; name: string };
    createdBy?: {
      __typename?: 'User';
      id: UUID;
      email: string;
      name: string;
      picture: string;
    } | null;
    milestone?: {
      __typename?: 'Milestone';
      id: UUID;
      name: string;
      date?: Time | null;
      buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
    } | null;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    assignee?: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
    previousMilestoneStatus: Array<{
      __typename?: 'PreviousMilestoneStatus';
      hasEstimate?: boolean | null;
      milestoneID: UUID;
      itemType: ItemType;
      status: Status;
      optionSubtotals: Array<{
        __typename?: 'OptionSubtotal';
        status: Status;
        adds: USCents;
        deducts: USCents;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
    }>;
    scheduleImpact?: {
      __typename?: 'ScheduleImpact';
      type: ScheduleImpactType;
      criticalPath: boolean;
      days: number;
      minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
      maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    } | null;
  }>;
  optionSubtotals: Array<{
    __typename?: 'OptionSubtotal';
    status: Status;
    adds: USCents;
    deducts: USCents;
  }>;
  unfilteredCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  comments: Array<{
    __typename?: 'Comment';
    id: UUID;
    created: Time;
    author: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
    text: {
      __typename?: 'MentionableText';
      contents: string;
      mentions?: Array<{
        __typename?: 'Mention';
        id: UUID;
        position: number;
        text: string;
        user: { __typename?: 'User'; id: UUID };
      }> | null;
      itemReferences?: Array<{
        __typename?: 'ItemReference';
        id: UUID;
        position: number;
        text: string;
        referencedItem?: {
          __typename?: 'ItemsListItem';
          createdAt: Time;
          currentStatus: Status;
          id: UUID;
          name: string;
          number: string;
          status: Status;
          updateTime: Time;
          visibility: Visibility;
          creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
          currentCost:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
          options: Array<{
            __typename?: 'ItemLink';
            id: UUID;
            name: string;
            number: string;
            status: Status;
            visibility?: Visibility | null;
          }>;
        } | null;
      }> | null;
    };
  }>;
  assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
  activeEstimate?: {
    __typename?: 'Estimate';
    id: UUID;
    name: string;
    subtotal: Numeric;
    markupSubtotal: USCents;
    drawSubtotal: USCents;
    inheritedSubtotal: USCents;
    inheritedOwnerCostMarkupsSubtotal: USCents;
    asset?: UUID | null;
    totalType: EstimateTotalType;
    isRunningTotal: boolean;
    lines: Array<{
      __typename?: 'Line';
      id: UUID;
      estimateId: UUID;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      cells: Array<{
        __typename?: 'Cell';
        estimateId: UUID;
        field: UUID;
        line: UUID;
        error?: string | null;
        value?:
          | {
              __typename?: 'CategoryCell';
              search: string;
              category?: {
                __typename?: 'Category';
                id: UUID;
                parentID?: UUID | null;
                name: string;
                hasSubCategories: boolean;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
              } | null;
            }
          | { __typename?: 'RegularCell'; string: string }
          | {
              __typename?: 'SourceCell';
              sourceIDs?: {
                __typename?: 'SourceIDs';
                estimateID: UUID;
                itemID?: UUID | null;
                lineID?: UUID | null;
                markupID?: UUID | null;
                milestoneID: UUID;
              } | null;
            }
          | null;
      }>;
    }>;
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
    incorporatedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    contingencyDraws?: Array<{
      __typename?: 'ItemDrawInfo';
      id: UUID;
      type: MarkupDisplayType;
      name: string;
      error: string;
      draw: USCents;
      drawFromID?: UUID | null;
      isDrawingFullAmount: boolean;
    }> | null;
    inheritedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    inheritedOwnerCostMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    markups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
  } | null;
  bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
  project: { __typename?: 'Project'; id: UUID; name: string };
  createdBy?: {
    __typename?: 'User';
    id: UUID;
    email: string;
    name: string;
    picture: string;
  } | null;
  milestone?: {
    __typename?: 'Milestone';
    id: UUID;
    name: string;
    date?: Time | null;
    buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
  } | null;
  categories: Array<{
    __typename?: 'Category';
    id: UUID;
    name: string;
    number: string;
    error?: string | null;
    level: number;
    hasSubCategories: boolean;
    categorization?: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      builtin: boolean;
      description: string;
      draft: boolean;
      levels: number;
      deprecated: boolean;
    } | null;
    levels: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
    }>;
  }>;
  cost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  estimateCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  contingencyDrawCost?:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents }
    | null;
  assignee?: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
  previousMilestoneStatus: Array<{
    __typename?: 'PreviousMilestoneStatus';
    hasEstimate?: boolean | null;
    milestoneID: UUID;
    itemType: ItemType;
    status: Status;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
  }>;
  scheduleImpact?: {
    __typename?: 'ScheduleImpact';
    type: ScheduleImpactType;
    criticalPath: boolean;
    days: number;
    minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
  } | null;
};

export type ListDetailedItemsQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  milestoneId?: InputMaybe<Scalars['UUID']['input']>;
  costMode: CostMode;
  viewFilter: ViewFilter;
}>;

export type ListDetailedItemsQuery = {
  __typename?: 'Query';
  items?: Array<{
    __typename?: 'Item';
    id: UUID;
    itemType: ItemType;
    description: string;
    descriptionStyled: string;
    dueDate?: Time | null;
    creationTime?: Time | null;
    updateTime?: Time | null;
    name: string;
    number: string;
    status: Status;
    visibility: Visibility;
    availableStates: Array<Status>;
    assetCount: number;
    remoteAssetCount: number;
    commentCount: number;
    filteredMilestone?: UUID | null;
    filteredCategories?: Array<UUID> | null;
    activityIDs?: Array<UUID> | null;
    options: Array<{
      __typename?: 'Option';
      parent: UUID;
      parentVisibility?: Visibility | null;
      hasEstimate?: boolean | null;
      id: UUID;
      itemType: ItemType;
      description: string;
      descriptionStyled: string;
      dueDate?: Time | null;
      creationTime?: Time | null;
      updateTime?: Time | null;
      name: string;
      number: string;
      status: Status;
      visibility: Visibility;
      availableStates: Array<Status>;
      assetCount: number;
      remoteAssetCount: number;
      commentCount: number;
      filteredMilestone?: UUID | null;
      filteredCategories?: Array<UUID> | null;
      activityIDs?: Array<UUID> | null;
      unfilteredCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      comments: Array<{
        __typename?: 'Comment';
        id: UUID;
        created: Time;
        author: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        };
        text: {
          __typename?: 'MentionableText';
          contents: string;
          mentions?: Array<{
            __typename?: 'Mention';
            id: UUID;
            position: number;
            text: string;
            user: { __typename?: 'User'; id: UUID };
          }> | null;
          itemReferences?: Array<{
            __typename?: 'ItemReference';
            id: UUID;
            position: number;
            text: string;
            referencedItem?: {
              __typename?: 'ItemsListItem';
              createdAt: Time;
              currentStatus: Status;
              id: UUID;
              name: string;
              number: string;
              status: Status;
              updateTime: Time;
              visibility: Visibility;
              creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
              currentCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
              options: Array<{
                __typename?: 'ItemLink';
                id: UUID;
                name: string;
                number: string;
                status: Status;
                visibility?: Visibility | null;
              }>;
            } | null;
          }> | null;
        };
      }>;
      assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
      activeEstimate?: {
        __typename?: 'Estimate';
        id: UUID;
        name: string;
        subtotal: Numeric;
        markupSubtotal: USCents;
        drawSubtotal: USCents;
        inheritedSubtotal: USCents;
        inheritedOwnerCostMarkupsSubtotal: USCents;
        asset?: UUID | null;
        totalType: EstimateTotalType;
        isRunningTotal: boolean;
        lines: Array<{
          __typename?: 'Line';
          id: UUID;
          estimateId: UUID;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          cells: Array<{
            __typename?: 'Cell';
            estimateId: UUID;
            field: UUID;
            line: UUID;
            error?: string | null;
            value?:
              | {
                  __typename?: 'CategoryCell';
                  search: string;
                  category?: {
                    __typename?: 'Category';
                    id: UUID;
                    parentID?: UUID | null;
                    name: string;
                    hasSubCategories: boolean;
                    number: string;
                    level: number;
                    error?: string | null;
                    categorization?: {
                      __typename?: 'Categorization';
                      id: UUID;
                      name: string;
                    } | null;
                    levels: Array<{
                      __typename?: 'Category';
                      id: UUID;
                      name: string;
                      number: string;
                    }>;
                  } | null;
                }
              | { __typename?: 'RegularCell'; string: string }
              | {
                  __typename?: 'SourceCell';
                  sourceIDs?: {
                    __typename?: 'SourceIDs';
                    estimateID: UUID;
                    itemID?: UUID | null;
                    lineID?: UUID | null;
                    markupID?: UUID | null;
                    milestoneID: UUID;
                  } | null;
                }
              | null;
          }>;
        }>;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
        incorporatedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        contingencyDraws?: Array<{
          __typename?: 'ItemDrawInfo';
          id: UUID;
          type: MarkupDisplayType;
          name: string;
          error: string;
          draw: USCents;
          drawFromID?: UUID | null;
          isDrawingFullAmount: boolean;
        }> | null;
        inheritedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedOwnerCostMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        markups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
      } | null;
      bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
      project: { __typename?: 'Project'; id: UUID; name: string };
      createdBy?: {
        __typename?: 'User';
        id: UUID;
        email: string;
        name: string;
        picture: string;
      } | null;
      milestone?: {
        __typename?: 'Milestone';
        id: UUID;
        name: string;
        date?: Time | null;
        buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
      } | null;
      categories: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        error?: string | null;
        level: number;
        hasSubCategories: boolean;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          builtin: boolean;
          description: string;
          draft: boolean;
          levels: number;
          deprecated: boolean;
        } | null;
        levels: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          hasSubCategories: boolean;
          level: number;
        }>;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      assignee?: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null;
      previousMilestoneStatus: Array<{
        __typename?: 'PreviousMilestoneStatus';
        hasEstimate?: boolean | null;
        milestoneID: UUID;
        itemType: ItemType;
        status: Status;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
      }>;
      scheduleImpact?: {
        __typename?: 'ScheduleImpact';
        type: ScheduleImpactType;
        criticalPath: boolean;
        days: number;
        minImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
        maxImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
      } | null;
    }>;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    unfilteredCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    comments: Array<{
      __typename?: 'Comment';
      id: UUID;
      created: Time;
      author: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      text: {
        __typename?: 'MentionableText';
        contents: string;
        mentions?: Array<{
          __typename?: 'Mention';
          id: UUID;
          position: number;
          text: string;
          user: { __typename?: 'User'; id: UUID };
        }> | null;
        itemReferences?: Array<{
          __typename?: 'ItemReference';
          id: UUID;
          position: number;
          text: string;
          referencedItem?: {
            __typename?: 'ItemsListItem';
            createdAt: Time;
            currentStatus: Status;
            id: UUID;
            name: string;
            number: string;
            status: Status;
            updateTime: Time;
            visibility: Visibility;
            creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
            currentCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
            options: Array<{
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              status: Status;
              visibility?: Visibility | null;
            }>;
          } | null;
        }> | null;
      };
    }>;
    assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
    activeEstimate?: {
      __typename?: 'Estimate';
      id: UUID;
      name: string;
      subtotal: Numeric;
      markupSubtotal: USCents;
      drawSubtotal: USCents;
      inheritedSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      asset?: UUID | null;
      totalType: EstimateTotalType;
      isRunningTotal: boolean;
      lines: Array<{
        __typename?: 'Line';
        id: UUID;
        estimateId: UUID;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        cells: Array<{
          __typename?: 'Cell';
          estimateId: UUID;
          field: UUID;
          line: UUID;
          error?: string | null;
          value?:
            | {
                __typename?: 'CategoryCell';
                search: string;
                category?: {
                  __typename?: 'Category';
                  id: UUID;
                  parentID?: UUID | null;
                  name: string;
                  hasSubCategories: boolean;
                  number: string;
                  level: number;
                  error?: string | null;
                  categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                  levels: Array<{
                    __typename?: 'Category';
                    id: UUID;
                    name: string;
                    number: string;
                  }>;
                } | null;
              }
            | { __typename?: 'RegularCell'; string: string }
            | {
                __typename?: 'SourceCell';
                sourceIDs?: {
                  __typename?: 'SourceIDs';
                  estimateID: UUID;
                  itemID?: UUID | null;
                  lineID?: UUID | null;
                  markupID?: UUID | null;
                  milestoneID: UUID;
                } | null;
              }
            | null;
        }>;
      }>;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      contingencyDraws?: Array<{
        __typename?: 'ItemDrawInfo';
        id: UUID;
        type: MarkupDisplayType;
        name: string;
        error: string;
        draw: USCents;
        drawFromID?: UUID | null;
        isDrawingFullAmount: boolean;
      }> | null;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
    } | null;
    bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
    project: { __typename?: 'Project'; id: UUID; name: string };
    createdBy?: {
      __typename?: 'User';
      id: UUID;
      email: string;
      name: string;
      picture: string;
    } | null;
    milestone?: {
      __typename?: 'Milestone';
      id: UUID;
      name: string;
      date?: Time | null;
      buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
    } | null;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    assignee?: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
    previousMilestoneStatus: Array<{
      __typename?: 'PreviousMilestoneStatus';
      hasEstimate?: boolean | null;
      milestoneID: UUID;
      itemType: ItemType;
      status: Status;
      optionSubtotals: Array<{
        __typename?: 'OptionSubtotal';
        status: Status;
        adds: USCents;
        deducts: USCents;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
    }>;
    scheduleImpact?: {
      __typename?: 'ScheduleImpact';
      type: ScheduleImpactType;
      criticalPath: boolean;
      days: number;
      minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
      maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    } | null;
  } | null> | null;
};

export type ExportAverageCompToExistingMilestoneMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  projectCompsSetInput: ProjectCompsSetInput;
  exportAverageCompEstimateInput: ExportAverageCompEstimateInput;
}>;

export type ExportAverageCompToExistingMilestoneMutation = {
  __typename?: 'Mutation';
  exportAverageCompToExistingMilestone: { __typename?: 'Milestone'; id: UUID };
};

export type ExportAverageCompToNewMilestoneMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  milestoneInput: MilestoneInput;
  projectCompsSetInput: ProjectCompsSetInput;
  exportAverageCompEstimateInput: ExportAverageCompEstimateInput;
}>;

export type ExportAverageCompToNewMilestoneMutation = {
  __typename?: 'Mutation';
  exportAverageCompToNewMilestone: { __typename?: 'Milestone'; id: UUID };
};

export type ProjectCategorizationsCategoryMatchesQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  categoryNumbers: Array<Scalars['String']['input']>;
}>;

export type ProjectCategorizationsCategoryMatchesQuery = {
  __typename?: 'Query';
  projectCategorizationsCategoryMatches: Array<{
    __typename?: 'CategorizationMatches';
    categorizationID: UUID;
    matchingCategories: number;
  }>;
};

export type TimeEscalationQueryVariables = Exact<{
  from: Scalars['Time']['input'];
  to: Scalars['Time']['input'];
}>;

export type TimeEscalationQuery = {
  __typename?: 'Query';
  timeEscalation?: {
    __typename?: 'TimeEscalation';
    percentage: number;
    fuzzyMatch: boolean;
    from: { __typename?: 'HistoricalCostIndex'; year: number; quarter: number; index: number };
    to: { __typename?: 'HistoricalCostIndex'; year: number; quarter: number; index: number };
  } | null;
};

export type LocationEscalationQueryVariables = Exact<{
  from: LocationInput;
  to: LocationInput;
}>;

export type LocationEscalationQuery = {
  __typename?: 'Query';
  locationEscalation?: {
    __typename?: 'LocationEscalation';
    percentage: number;
    fuzzyMatch: boolean;
    targetIsFuzzyMatch: boolean;
    sourceIsFuzzyMatch: boolean;
    from: { __typename?: 'CityCostIndex'; index: number; lat: number; lon: number; name: string };
    to: { __typename?: 'CityCostIndex'; index: number; lat: number; lon: number; name: string };
  } | null;
};

export type CityCostIndicesQueryVariables = Exact<{
  pagination: Pagination;
}>;

export type CityCostIndicesQuery = {
  __typename?: 'Query';
  cityCostIndices?: {
    __typename?: 'CityCostIndicesResponse';
    total: number;
    cityCostIndices: Array<{
      __typename?: 'CityCostIndex';
      name: string;
      lat: number;
      lon: number;
      index: number;
    }>;
  } | null;
};

export type ProjectCompsSetQueryVariables = Exact<{
  projectCompsSetInput?: InputMaybe<ProjectCompsSetInput>;
  reportID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type ProjectCompsSetQuery = {
  __typename?: 'Query';
  projectCompSettingsReactive: ProjectCompsSetInput;
  projectCompsSet: {
    __typename?: 'ProjectCompsSet';
    input: {
      __typename?: 'ProjectCompsSetInputReturn';
      addedMarkupNames: Array<string>;
      markupMode: MarkupMode;
      pinnedUnitID?: UUID | null;
      selectedUnitIDs: Array<UUID>;
      excludedMarkupNames: Array<string>;
      averageInput?: {
        __typename?: 'AverageCompInputReturn';
        name?: string | null;
        description?: string | null;
        color?: string | null;
        isHidden?: boolean | null;
        thumbnailAssetID?: UUID | null;
        location?: string | null;
        lat?: number | null;
        lon?: number | null;
        metrics: Array<{
          __typename?: 'MetricsInputReturn';
          unitID: UUID;
          quantityMagnitude?: string | null;
          hasMilestoneQuantity: boolean;
        }>;
        categoryLineInputs: Array<{
          __typename?: 'CategoryLineInputReturn';
          description?: string | null;
          editedValue: USCents;
          cellType: ProjectCompsCostTableCellType;
          unitID?: UUID | null;
          category: { __typename?: 'Category'; name: string; number: string };
        }>;
        markupLineInputs: Array<{
          __typename?: 'MarkupLineInputReturn';
          markupName: string;
          description?: string | null;
          editedValue: USCents;
          cellType: ProjectCompsCostTableCellType;
          unitID?: UUID | null;
        }>;
      } | null;
      costTableColumnInputs?: Array<{
        __typename?: 'CostTableColumnInputReturn';
        key: CostTableColumnInputKey;
        unitID?: UUID | null;
      }> | null;
      projectCompInputs: Array<{
        __typename?: 'ProjectCompInputReturn';
        id: UUID;
        projectID: UUID;
        name?: string | null;
        description?: string | null;
        categorizationID?: UUID | null;
        categorizationLevel?: number | null;
        isExcluded?: boolean | null;
        milestoneID?: UUID | null;
        escalation?: {
          __typename?: 'ProjectCompEscalation';
          time?: string | null;
          timeFuture?: string | null;
          location?: string | null;
          timeMeta?: {
            __typename?: 'ProjectCompEscalationMeta';
            isAutoEscalated: boolean;
            isFuzzyMatch: boolean;
            sourceLabel: string;
            sourceIsFuzzyMatch: boolean;
            targetLabel: string;
            targetIsFuzzyMatch: boolean;
          } | null;
          locationMeta?: {
            __typename?: 'ProjectCompEscalationMeta';
            isAutoEscalated: boolean;
            isFuzzyMatch: boolean;
            sourceLabel: string;
            sourceIsFuzzyMatch: boolean;
            targetLabel: string;
            targetIsFuzzyMatch: boolean;
          } | null;
        } | null;
        metrics: Array<{
          __typename?: 'MetricsInputReturn';
          unitID: UUID;
          quantityMagnitude?: string | null;
          hasMilestoneQuantity: boolean;
        }>;
        categoryLineInputs: Array<{
          __typename?: 'CategoryLineInputReturn';
          description?: string | null;
          editedValue: USCents;
          cellType: ProjectCompsCostTableCellType;
          unitID?: UUID | null;
          category: { __typename?: 'Category'; name: string; number: string };
        }>;
        markupLineInputs: Array<{
          __typename?: 'MarkupLineInputReturn';
          markupName: string;
          description?: string | null;
          editedValue: USCents;
          cellType: ProjectCompsCostTableCellType;
          unitID?: UUID | null;
        }>;
        viewFilter?: {
          __typename?: 'ViewFilterReturn';
          categories?: Array<{
            __typename?: 'CategoryInputReturn';
            categorizationID: UUID;
            id: UUID;
          }> | null;
        } | null;
      }>;
      excludedCategoryContents: Array<{
        __typename?: 'CategoryContent';
        number: string;
        name: string;
      }>;
    };
    averageComp?: {
      __typename?: 'AverageComp';
      isHidden: boolean;
      isResettable: boolean;
      input: {
        __typename?: 'AverageCompInputReturn';
        name?: string | null;
        description?: string | null;
        color?: string | null;
        isHidden?: boolean | null;
        thumbnailAssetID?: UUID | null;
        location?: string | null;
        lat?: number | null;
        lon?: number | null;
        metrics: Array<{
          __typename?: 'MetricsInputReturn';
          unitID: UUID;
          quantityMagnitude?: string | null;
          hasMilestoneQuantity: boolean;
        }>;
        categoryLineInputs: Array<{
          __typename?: 'CategoryLineInputReturn';
          description?: string | null;
          editedValue: USCents;
          cellType: ProjectCompsCostTableCellType;
          unitID?: UUID | null;
          category: { __typename?: 'Category'; name: string; number: string };
        }>;
        markupLineInputs: Array<{
          __typename?: 'MarkupLineInputReturn';
          markupName: string;
          description?: string | null;
          editedValue: USCents;
          cellType: ProjectCompsCostTableCellType;
          unitID?: UUID | null;
        }>;
      };
      projectCompsCostTable: {
        __typename?: 'ProjectCompsCostTable';
        categoryLines: Array<{
          __typename?: 'ProjectCompsCostTableCategoryLine';
          description?: string | null;
          category: {
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          };
          pinnedUnitValues: {
            __typename?: 'ProjectCompsCostTableCostValues';
            quantityMagnitude: Numeric;
            percentValue: number;
            totalValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            quantityValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          };
          columnValues: Array<{
            __typename?: 'ProjectCompsCostTableColumnValue';
            valueNumeric: Numeric;
            isEdited: boolean;
            isMaxValue: boolean;
            isMinValue: boolean;
            hasError: boolean;
            costTableColumnInput: {
              __typename?: 'CostTableColumnInputReturn';
              key: CostTableColumnInputKey;
              unitID?: UUID | null;
            };
          }>;
        }>;
        markupLines: Array<{
          __typename?: 'ProjectCompsCostTableMarkupLine';
          markupName: string;
          description?: string | null;
          pinnedUnitValues: {
            __typename?: 'ProjectCompsCostTableCostValues';
            quantityMagnitude: Numeric;
            percentValue: number;
            totalValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            quantityValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          };
          columnValues: Array<{
            __typename?: 'ProjectCompsCostTableColumnValue';
            valueNumeric: Numeric;
            isEdited: boolean;
            isMaxValue: boolean;
            isMinValue: boolean;
            hasError: boolean;
            costTableColumnInput: {
              __typename?: 'CostTableColumnInputReturn';
              key: CostTableColumnInputKey;
              unitID?: UUID | null;
            };
          }>;
        }>;
        summaryLines: {
          __typename?: 'ProjectCompsCostTableSummaryLines';
          subtotalLine?: {
            __typename?: 'ProjectCompsCostTableSummaryLine';
            pinnedUnitValues: {
              __typename?: 'ProjectCompsCostTableCostValues';
              quantityMagnitude: Numeric;
              percentValue: number;
              totalValue:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              quantityValue:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
            };
            columnValues: Array<{
              __typename?: 'ProjectCompsCostTableColumnValue';
              valueNumeric: Numeric;
              isEdited: boolean;
              isMaxValue: boolean;
              isMinValue: boolean;
              hasError: boolean;
              costTableColumnInput: {
                __typename?: 'CostTableColumnInputReturn';
                key: CostTableColumnInputKey;
                unitID?: UUID | null;
              };
            }>;
          } | null;
          totalLine: {
            __typename?: 'ProjectCompsCostTableSummaryLine';
            pinnedUnitValues: {
              __typename?: 'ProjectCompsCostTableCostValues';
              quantityMagnitude: Numeric;
              percentValue: number;
              totalValue:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              quantityValue:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
            };
            columnValues: Array<{
              __typename?: 'ProjectCompsCostTableColumnValue';
              valueNumeric: Numeric;
              isEdited: boolean;
              isMaxValue: boolean;
              isMinValue: boolean;
              hasError: boolean;
              costTableColumnInput: {
                __typename?: 'CostTableColumnInputReturn';
                key: CostTableColumnInputKey;
                unitID?: UUID | null;
              };
            }>;
          };
          markupsTotalLine?: {
            __typename?: 'ProjectCompsCostTableSummaryLine';
            pinnedUnitValues: {
              __typename?: 'ProjectCompsCostTableCostValues';
              quantityMagnitude: Numeric;
              percentValue: number;
              totalValue:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              quantityValue:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
            };
            columnValues: Array<{
              __typename?: 'ProjectCompsCostTableColumnValue';
              valueNumeric: Numeric;
              isEdited: boolean;
              isMaxValue: boolean;
              isMinValue: boolean;
              hasError: boolean;
              costTableColumnInput: {
                __typename?: 'CostTableColumnInputReturn';
                key: CostTableColumnInputKey;
                unitID?: UUID | null;
              };
            }>;
          } | null;
        };
      };
    } | null;
    projectComps: Array<{
      __typename?: 'ProjectComp';
      isResettable: boolean;
      input: {
        __typename?: 'ProjectCompInputReturn';
        id: UUID;
        projectID: UUID;
        name?: string | null;
        description?: string | null;
        categorizationID?: UUID | null;
        categorizationLevel?: number | null;
        isExcluded?: boolean | null;
        milestoneID?: UUID | null;
        escalation?: {
          __typename?: 'ProjectCompEscalation';
          time?: string | null;
          timeFuture?: string | null;
          location?: string | null;
          timeMeta?: {
            __typename?: 'ProjectCompEscalationMeta';
            isAutoEscalated: boolean;
            isFuzzyMatch: boolean;
            sourceLabel: string;
            sourceIsFuzzyMatch: boolean;
            targetLabel: string;
            targetIsFuzzyMatch: boolean;
          } | null;
          locationMeta?: {
            __typename?: 'ProjectCompEscalationMeta';
            isAutoEscalated: boolean;
            isFuzzyMatch: boolean;
            sourceLabel: string;
            sourceIsFuzzyMatch: boolean;
            targetLabel: string;
            targetIsFuzzyMatch: boolean;
          } | null;
        } | null;
        metrics: Array<{
          __typename?: 'MetricsInputReturn';
          unitID: UUID;
          quantityMagnitude?: string | null;
          hasMilestoneQuantity: boolean;
        }>;
        categoryLineInputs: Array<{
          __typename?: 'CategoryLineInputReturn';
          description?: string | null;
          editedValue: USCents;
          cellType: ProjectCompsCostTableCellType;
          unitID?: UUID | null;
          category: { __typename?: 'Category'; name: string; number: string };
        }>;
        markupLineInputs: Array<{
          __typename?: 'MarkupLineInputReturn';
          markupName: string;
          description?: string | null;
          editedValue: USCents;
          cellType: ProjectCompsCostTableCellType;
          unitID?: UUID | null;
        }>;
        viewFilter?: {
          __typename?: 'ViewFilterReturn';
          categories?: Array<{
            __typename?: 'CategoryInputReturn';
            categorizationID: UUID;
            id: UUID;
          }> | null;
        } | null;
      };
      milestone: { __typename?: 'Milestone'; id: UUID; date?: Time | null; name: string };
      project: {
        __typename?: 'Project';
        id: UUID;
        name: string;
        hasAccess: boolean;
        location: string;
        lat?: number | null;
        lon?: number | null;
        thumbnail: string;
        status: { __typename?: 'ProjectStatus'; name: string };
        milestones: Array<{ __typename?: 'Milestone'; id: UUID; date?: Time | null; name: string }>;
        activeMilestone: { __typename?: 'Milestone'; id: UUID };
        type: { __typename?: 'ProjectType'; name: string };
      };
      projectCompsCostTable: {
        __typename?: 'ProjectCompsCostTable';
        categoryLines: Array<{
          __typename?: 'ProjectCompsCostTableCategoryLine';
          description?: string | null;
          category: {
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          };
          pinnedUnitValues: {
            __typename?: 'ProjectCompsCostTableCostValues';
            quantityMagnitude: Numeric;
            percentValue: number;
            totalValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            quantityValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          };
          columnValues: Array<{
            __typename?: 'ProjectCompsCostTableColumnValue';
            valueNumeric: Numeric;
            isEdited: boolean;
            isMaxValue: boolean;
            isMinValue: boolean;
            hasError: boolean;
            costTableColumnInput: {
              __typename?: 'CostTableColumnInputReturn';
              key: CostTableColumnInputKey;
              unitID?: UUID | null;
            };
          }>;
        }>;
        markupLines: Array<{
          __typename?: 'ProjectCompsCostTableMarkupLine';
          markupName: string;
          description?: string | null;
          pinnedUnitValues: {
            __typename?: 'ProjectCompsCostTableCostValues';
            quantityMagnitude: Numeric;
            percentValue: number;
            totalValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            quantityValue:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          };
          columnValues: Array<{
            __typename?: 'ProjectCompsCostTableColumnValue';
            valueNumeric: Numeric;
            isEdited: boolean;
            isMaxValue: boolean;
            isMinValue: boolean;
            hasError: boolean;
            costTableColumnInput: {
              __typename?: 'CostTableColumnInputReturn';
              key: CostTableColumnInputKey;
              unitID?: UUID | null;
            };
          }>;
        }>;
        summaryLines: {
          __typename?: 'ProjectCompsCostTableSummaryLines';
          subtotalLine?: {
            __typename?: 'ProjectCompsCostTableSummaryLine';
            pinnedUnitValues: {
              __typename?: 'ProjectCompsCostTableCostValues';
              quantityMagnitude: Numeric;
              percentValue: number;
              totalValue:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              quantityValue:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
            };
            columnValues: Array<{
              __typename?: 'ProjectCompsCostTableColumnValue';
              valueNumeric: Numeric;
              isEdited: boolean;
              isMaxValue: boolean;
              isMinValue: boolean;
              hasError: boolean;
              costTableColumnInput: {
                __typename?: 'CostTableColumnInputReturn';
                key: CostTableColumnInputKey;
                unitID?: UUID | null;
              };
            }>;
          } | null;
          totalLine: {
            __typename?: 'ProjectCompsCostTableSummaryLine';
            pinnedUnitValues: {
              __typename?: 'ProjectCompsCostTableCostValues';
              quantityMagnitude: Numeric;
              percentValue: number;
              totalValue:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              quantityValue:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
            };
            columnValues: Array<{
              __typename?: 'ProjectCompsCostTableColumnValue';
              valueNumeric: Numeric;
              isEdited: boolean;
              isMaxValue: boolean;
              isMinValue: boolean;
              hasError: boolean;
              costTableColumnInput: {
                __typename?: 'CostTableColumnInputReturn';
                key: CostTableColumnInputKey;
                unitID?: UUID | null;
              };
            }>;
          };
          markupsTotalLine?: {
            __typename?: 'ProjectCompsCostTableSummaryLine';
            pinnedUnitValues: {
              __typename?: 'ProjectCompsCostTableCostValues';
              quantityMagnitude: Numeric;
              percentValue: number;
              totalValue:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              quantityValue:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
            };
            columnValues: Array<{
              __typename?: 'ProjectCompsCostTableColumnValue';
              valueNumeric: Numeric;
              isEdited: boolean;
              isMaxValue: boolean;
              isMinValue: boolean;
              hasError: boolean;
              costTableColumnInput: {
                __typename?: 'CostTableColumnInputReturn';
                key: CostTableColumnInputKey;
                unitID?: UUID | null;
              };
            }>;
          } | null;
        };
      };
    }>;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    }>;
    units: Array<{
      __typename?: 'Unit';
      abbreviationPlural: string;
      abbreviationSingular: string;
      description?: string | null;
      id: UUID;
      isDefault: boolean;
      isEnabled: boolean;
      name: string;
      type: UnitType;
    }>;
    selectedUnits: Array<{
      __typename?: 'Unit';
      abbreviationPlural: string;
      abbreviationSingular: string;
      description?: string | null;
      id: UUID;
      isDefault: boolean;
      isEnabled: boolean;
      name: string;
      type: UnitType;
    }>;
    unitCounts: Array<{ __typename?: 'UnitCount'; unitID: UUID; countText: string }>;
    markups: Array<{ __typename?: 'PCMarkup'; name: string; isCustom: boolean }>;
  };
};

export type LocationEscalationMultipleQueryVariables = Exact<{
  locations: Array<LocationEscalationInput>;
}>;

export type LocationEscalationMultipleQuery = {
  __typename?: 'Query';
  locationEscalationMultiple: Array<{
    __typename?: 'LocationEscalation';
    percentage: number;
    fuzzyMatch: boolean;
    targetIsFuzzyMatch: boolean;
    sourceIsFuzzyMatch: boolean;
    from: { __typename?: 'CityCostIndex'; index: number; lat: number; lon: number; name: string };
    to: { __typename?: 'CityCostIndex'; index: number; lat: number; lon: number; name: string };
  } | null>;
};

export type TimeEscalationMultipleQueryVariables = Exact<{
  dates: Array<TimeEscalationInput>;
}>;

export type TimeEscalationMultipleQuery = {
  __typename?: 'Query';
  timeEscalationMultiple: Array<{
    __typename?: 'TimeEscalation';
    fuzzyMatch: boolean;
    percentage: number;
    from: { __typename?: 'HistoricalCostIndex'; year: number; quarter: number; index: number };
    to: { __typename?: 'HistoricalCostIndex'; year: number; quarter: number; index: number };
  } | null>;
};

export type ProjectCostBreakdownReportQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  estimateType: EstimateType;
  costMode: CostMode;
}>;

export type ProjectCostBreakdownReportQuery = {
  __typename?: 'Query';
  projectCostBreakdownReport?: {
    __typename?: 'ProjectCostBreakdownReport';
    total: USCents;
    breakdown: Array<{
      __typename?: 'ProjectCostBreakdown';
      type: ProjectCostBreakdownType;
      cost: USCents;
      percent: number;
    }>;
  } | null;
};

export type CommonProjectSettingFragment = {
  __typename?: 'ProjectSetting';
  key: ProjectSettingKeys;
  value: string;
  validValues: Array<string>;
};

export type ProjectSettingsQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type ProjectSettingsQuery = {
  __typename?: 'Query';
  projectSettings: Array<{
    __typename?: 'ProjectSetting';
    key: ProjectSettingKeys;
    value: string;
    validValues: Array<string>;
  }>;
};

export type UpdateProjectSettingMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
}>;

export type UpdateProjectSettingMutation = {
  __typename?: 'Mutation';
  updateProjectSetting: Array<{
    __typename?: 'ProjectSetting';
    key: ProjectSettingKeys;
    value: string;
    validValues: Array<string>;
  }>;
};

export type CommonTerminologyFragment = {
  __typename?: 'Terminology';
  key: string;
  maxLength: number;
  override: boolean;
  overrideValue?: string | null;
};

export type ProjectTerminologyQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type ProjectTerminologyQuery = {
  __typename?: 'Query';
  projectTerminology: Array<{
    __typename?: 'Terminology';
    key: string;
    maxLength: number;
    override: boolean;
    overrideValue?: string | null;
  }>;
};

export type UpdateProjectTerminologyMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  key: Scalars['String']['input'];
  overrideValue: Scalars['String']['input'];
}>;

export type UpdateProjectTerminologyMutation = {
  __typename?: 'Mutation';
  updateProjectTerminology: Array<{
    __typename?: 'Terminology';
    key: string;
    maxLength: number;
    override: boolean;
    overrideValue?: string | null;
  }>;
};

export type ResetProjectTerminologyMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  key: Scalars['String']['input'];
}>;

export type ResetProjectTerminologyMutation = {
  __typename?: 'Mutation';
  resetProjectTerminology: Array<{
    __typename?: 'Terminology';
    key: string;
    maxLength: number;
    override: boolean;
    overrideValue?: string | null;
  }>;
};

export type CreateProjectBannerMutationVariables = Exact<{
  assetID: Scalars['UUID']['input'];
  assetName: Scalars['String']['input'];
  projectID: Scalars['UUID']['input'];
}>;

export type CreateProjectBannerMutation = {
  __typename?: 'Mutation';
  createProjectBanner: {
    __typename?: 'Banner';
    id: UUID;
    bannerName: string;
    asset: { __typename?: 'Asset'; id: UUID; location: string; name: string };
  };
};

export type ProjectBannersQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type ProjectBannersQuery = {
  __typename?: 'Query';
  projectBanners: Array<{
    __typename?: 'Banner';
    id: UUID;
    bannerName: string;
    asset: { __typename?: 'Asset'; id: UUID; location: string; name: string };
  }>;
};

export type ActiveProjectBannerQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type ActiveProjectBannerQuery = {
  __typename?: 'Query';
  activeProjectBanner?: {
    __typename?: 'Banner';
    id: UUID;
    bannerName: string;
    asset: { __typename?: 'Asset'; id: UUID; location: string; name: string };
  } | null;
};

export type SetActiveProjectBannerMutationVariables = Exact<{
  assetID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
}>;

export type SetActiveProjectBannerMutation = {
  __typename?: 'Mutation';
  setActiveProjectBanner: {
    __typename?: 'Banner';
    id: UUID;
    bannerName: string;
    asset: { __typename?: 'Asset'; id: UUID; location: string; name: string };
  };
};

export type RemoveActiveProjectBannerMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type RemoveActiveProjectBannerMutation = {
  __typename?: 'Mutation';
  removeActiveProjectBanner?: UUID | null;
};

export type RemoveProjectBannerMutationVariables = Exact<{
  bannerID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
}>;

export type RemoveProjectBannerMutation = {
  __typename?: 'Mutation';
  removeProjectBanner?: UUID | null;
};

export type GetProjectThumbnailQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type GetProjectThumbnailQuery = {
  __typename?: 'Query';
  projectThumbnail: { __typename?: 'Asset'; id: UUID; name: string; location: string };
};

export type UpdateUserNotificationSettingMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  isGlobalSubscribed?: InputMaybe<Scalars['Boolean']['input']>;
  comments?: InputMaybe<CommentNotificationSetting>;
  assignments?: InputMaybe<Scalars['Boolean']['input']>;
  newItems?: InputMaybe<Scalars['Boolean']['input']>;
  mentions?: InputMaybe<Scalars['Boolean']['input']>;
  shareScenarios?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateUserNotificationSettingMutation = {
  __typename?: 'Mutation';
  updateUserNotificationSetting: {
    __typename?: 'UserNotificationSetting';
    assignments: boolean;
    channel: NotificationChannel;
    comments: CommentNotificationSetting;
    isGlobalSubscribed: boolean;
    mentions: boolean;
    newItems: boolean;
    shareScenarios: boolean;
  };
};

export type UpdateUserInAppNotificationSettingMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  isGlobalSubscribed?: InputMaybe<Scalars['Boolean']['input']>;
  comments?: InputMaybe<CommentNotificationSetting>;
  assignments?: InputMaybe<Scalars['Boolean']['input']>;
  newItems?: InputMaybe<Scalars['Boolean']['input']>;
  mentions?: InputMaybe<Scalars['Boolean']['input']>;
  shareScenarios?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateUserInAppNotificationSettingMutation = {
  __typename?: 'Mutation';
  updateUserInAppNotificationSetting: {
    __typename?: 'UserNotificationSetting';
    assignments: boolean;
    channel: NotificationChannel;
    comments: CommentNotificationSetting;
    isGlobalSubscribed: boolean;
    mentions: boolean;
    newItems: boolean;
    shareScenarios: boolean;
  };
};

export type SetProjectTypeMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  typeID: Scalars['UUID']['input'];
}>;

export type SetProjectTypeMutation = {
  __typename?: 'Mutation';
  setProjectType?: {
    __typename?: 'Project';
    id: UUID;
    description: string;
    descriptionStyled: string;
    name: string;
    code?: string | null;
    hasAccess: boolean;
    location: string;
    lat?: number | null;
    lon?: number | null;
    thumbnail: string;
    templateEstimateID: UUID;
    lastUpdated: Time;
    activeMilestone: { __typename?: 'Milestone'; id: UUID; name: string };
    status: {
      __typename?: 'ProjectStatus';
      id: UUID;
      name: string;
      type: string;
      description: string;
    };
    team?: {
      __typename?: 'Team';
      owner?: { __typename?: 'Organization'; name?: string | null; picture?: string | null } | null;
      generalContractor?: {
        __typename?: 'Organization';
        name?: string | null;
        picture?: string | null;
      } | null;
      designTeam?: {
        __typename?: 'Organization';
        name?: string | null;
        picture?: string | null;
      } | null;
    } | null;
    type: { __typename?: 'ProjectType'; id: UUID; name: string };
    projectDeliveryType?: {
      __typename?: 'ProjectDeliveryType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    locationDetails?: {
      __typename?: 'LocationDetails';
      name: string;
      lat: number;
      lon: number;
      city: string;
      state: string;
      stateLong: string;
      country: string;
    } | null;
    orgNodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string }>;
  } | null;
};

export type ProjectsCountsQueryVariables = Exact<{
  projectsFilterBy: ProjectsFilterBy;
  projectsSearch: Scalars['String']['input'];
}>;

export type ProjectsCountsQuery = {
  __typename?: 'Query';
  projectsCounts: {
    __typename?: 'ProjectsCounts';
    allProjectsCount: number;
    myProjectsCount: number;
  };
};

export type ProjectsFilterOptionsQueryVariables = Exact<{
  includeCompanyProjects: Scalars['Boolean']['input'];
  includeUserProjects?: InputMaybe<Scalars['Boolean']['input']>;
  companyID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type ProjectsFilterOptionsQuery = {
  __typename?: 'Query';
  projectsFilterOptions: {
    __typename?: 'ProjectsFilterOptions';
    locations: Array<string>;
    statuses: Array<string>;
    companies: Array<string>;
    types: Array<string>;
    projectLeads: Array<{ __typename?: 'User'; id: UUID; name: string }>;
    projectTypes: Array<{
      __typename?: 'ProjectType';
      id: UUID;
      name: string;
      parentID?: UUID | null;
      hidden?: boolean | null;
    }>;
    organizationNodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string }>;
  };
};

export type SearchProjectsFilterOptionsQueryVariables = Exact<{
  searchResultType: SearchResultType;
}>;

export type SearchProjectsFilterOptionsQuery = {
  __typename?: 'Query';
  searchProjectsFilterOptions: {
    __typename?: 'ProjectsFilterOptions';
    locations: Array<string>;
    statuses: Array<string>;
    companies: Array<string>;
    types: Array<string>;
    milestoneDesignPhases: Array<string>;
    projectLeads: Array<{ __typename?: 'User'; id: UUID; name: string }>;
    projectTypes: Array<{
      __typename?: 'ProjectType';
      id: UUID;
      name: string;
      parentID?: UUID | null;
      hidden?: boolean | null;
    }>;
    organizationNodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string }>;
  };
};

export type GetProjectStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectStatusesQuery = {
  __typename?: 'Query';
  projectStatuses?: Array<{
    __typename?: 'ProjectStatus';
    id: UUID;
    name: string;
    type: string;
    description: string;
  }> | null;
};

export type UserReportsAndProjectPropsQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type UserReportsAndProjectPropsQuery = {
  __typename?: 'Query';
  userReports: Array<{
    __typename?: 'UserReport';
    id: UUID;
    milestoneID?: UUID | null;
    reportType: UserReportType;
    shared: boolean;
    owner: boolean;
    name: string;
    description: string;
    settings: string;
    updatedAt: Time;
    hasDistributionScheduled: boolean;
    isFollowingActiveMilestone?: boolean | null;
    createdBy: { __typename?: 'User'; id: UUID; name: string; email: string };
  }>;
  project?: {
    __typename?: 'Project';
    id: UUID;
    description: string;
    descriptionStyled: string;
    name: string;
    code?: string | null;
    hasAccess: boolean;
    location: string;
    lat?: number | null;
    lon?: number | null;
    thumbnail: string;
    templateEstimateID: UUID;
    lastUpdated: Time;
    activeMilestone: { __typename?: 'Milestone'; id: UUID; name: string };
    status: {
      __typename?: 'ProjectStatus';
      id: UUID;
      name: string;
      type: string;
      description: string;
    };
    team?: {
      __typename?: 'Team';
      owner?: { __typename?: 'Organization'; name?: string | null; picture?: string | null } | null;
      generalContractor?: {
        __typename?: 'Organization';
        name?: string | null;
        picture?: string | null;
      } | null;
      designTeam?: {
        __typename?: 'Organization';
        name?: string | null;
        picture?: string | null;
      } | null;
    } | null;
    type: { __typename?: 'ProjectType'; id: UUID; name: string };
    projectDeliveryType?: {
      __typename?: 'ProjectDeliveryType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    locationDetails?: {
      __typename?: 'LocationDetails';
      name: string;
      lat: number;
      lon: number;
      city: string;
      state: string;
      stateLong: string;
      country: string;
    } | null;
    orgNodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string }>;
  } | null;
};

export type UserReportQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  reportID: Scalars['UUID']['input'];
}>;

export type UserReportQuery = {
  __typename?: 'Query';
  userReport: {
    __typename?: 'UserReport';
    id: UUID;
    milestoneID?: UUID | null;
    reportType: UserReportType;
    shared: boolean;
    owner: boolean;
    name: string;
    description: string;
    settings: string;
    updatedAt: Time;
    hasDistributionScheduled: boolean;
    isFollowingActiveMilestone?: boolean | null;
    createdBy: { __typename?: 'User'; id: UUID; name: string; email: string };
  };
};

export type UserReportsQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type UserReportsQuery = {
  __typename?: 'Query';
  userReports: Array<{
    __typename?: 'UserReport';
    id: UUID;
    milestoneID?: UUID | null;
    reportType: UserReportType;
    shared: boolean;
    owner: boolean;
    name: string;
    description: string;
    settings: string;
    updatedAt: Time;
    hasDistributionScheduled: boolean;
    isFollowingActiveMilestone?: boolean | null;
    createdBy: { __typename?: 'User'; id: UUID; name: string; email: string };
  }>;
};

export type CreateUserReportMutationVariables = Exact<{
  userReport: UserReportInput;
  projectID: Scalars['UUID']['input'];
}>;

export type CreateUserReportMutation = {
  __typename?: 'Mutation';
  createUserReport: {
    __typename?: 'UserReport';
    id: UUID;
    milestoneID?: UUID | null;
    reportType: UserReportType;
    shared: boolean;
    owner: boolean;
    name: string;
    description: string;
    settings: string;
    updatedAt: Time;
    hasDistributionScheduled: boolean;
    isFollowingActiveMilestone?: boolean | null;
    createdBy: { __typename?: 'User'; id: UUID; name: string; email: string };
  };
};

export type UpdateUserReportMutationVariables = Exact<{
  userReport: UserReportInput;
  projectID: Scalars['UUID']['input'];
}>;

export type UpdateUserReportMutation = {
  __typename?: 'Mutation';
  updateUserReport: {
    __typename?: 'UserReport';
    id: UUID;
    milestoneID?: UUID | null;
    reportType: UserReportType;
    shared: boolean;
    owner: boolean;
    name: string;
    description: string;
    settings: string;
    updatedAt: Time;
    hasDistributionScheduled: boolean;
    isFollowingActiveMilestone?: boolean | null;
    createdBy: { __typename?: 'User'; id: UUID; name: string; email: string };
  };
};

export type RemoveUserReportMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  reportID: Scalars['UUID']['input'];
}>;

export type RemoveUserReportMutation = { __typename?: 'Mutation'; removeUserReport?: UUID | null };

export type CreateReportDistributionMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  input: ReportDistributionInput;
}>;

export type CreateReportDistributionMutation = {
  __typename?: 'Mutation';
  createReportDistribution: boolean;
};

export type DeleteReportDistributionMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  reportDistributionID: Scalars['UUID']['input'];
}>;

export type DeleteReportDistributionMutation = {
  __typename?: 'Mutation';
  deleteReportDistribution: boolean;
};

export type UpdateReportDistributionMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  input: ReportDistributionInput;
}>;

export type UpdateReportDistributionMutation = {
  __typename?: 'Mutation';
  updateReportDistribution: boolean;
};

export type ReportDistributionQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  reportID: Scalars['UUID']['input'];
}>;

export type ReportDistributionQuery = {
  __typename?: 'Query';
  reportDistribution: {
    __typename?: 'ReportDistribution';
    cadence: string;
    createdBy: UUID;
    id: UUID;
    reportID: UUID;
    collaboratorIDs: Array<UUID>;
    startDate: Time;
  };
};

export type ReportDistributionHistoryQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type ReportDistributionHistoryQuery = {
  __typename?: 'Query';
  reportDistributionHistory: Array<{
    __typename?: 'ReportDistributionEvent';
    sentAt: Time;
    sentBy: UUID;
    reportID: UUID;
    reportName: string;
    id: UUID;
    deprecated: boolean;
    isScheduled: boolean;
    reports: Array<{
      __typename?: 'DistributedReport';
      roleID: UUID;
      assetURL: string;
      sentTo: Array<UUID>;
    }>;
  }>;
};

export type UnsubscribeReportDistributionMutationVariables = Exact<{
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  reportDistributionID: Scalars['UUID']['input'];
}>;

export type UnsubscribeReportDistributionMutation = {
  __typename?: 'Mutation';
  unsubscribeReportDistribution: boolean;
};

export type ReportIdByReportDistributionAssetIdQueryVariables = Exact<{
  assetID: Scalars['UUID']['input'];
}>;

export type ReportIdByReportDistributionAssetIdQuery = {
  __typename?: 'Query';
  reportIDByReportDistributionAssetID: UUID;
};

export type LoadScenariosSummaryByMilestoneIdQueryVariables = Exact<{
  milestoneID: Scalars['UUID']['input'];
  costMode: CostMode;
}>;

export type LoadScenariosSummaryByMilestoneIdQuery = {
  __typename?: 'Query';
  loadScenariosSummaryByMilestoneID: Array<{
    __typename?: 'ScenarioSummary';
    scenarioID: UUID;
    name: string;
    description?: string | null;
    color?: string | null;
    thumbnailAssetID?: UUID | null;
    itemsCount: number;
    costImpact: {
      __typename?: 'CostReportColumn';
      type: CostReportColumnType;
      columnKey: CostReportColumnKey;
      isCategorized?: boolean | null;
      report?: {
        __typename?: 'CostReport';
        numeric: Numeric;
        type: CostReportType;
        directCostRange:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        directCostSegmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        projectTotalRange?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        projectTotalSegmented?: {
          __typename?: 'AddDeductCost';
          adds: USCents;
          deducts: USCents;
        } | null;
        range:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        subtotals: Array<{
          __typename?: 'CostReportItem';
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          subtotalValue:
            | {
                __typename?: 'SubtotalAddsDeducts';
                directCostSegmented: {
                  __typename?: 'PartialAddDeductCost';
                  adds?: USCents | null;
                  deducts?: USCents | null;
                };
                segmented: {
                  __typename?: 'PartialAddDeductCost';
                  adds?: USCents | null;
                  deducts?: USCents | null;
                };
              }
            | {
                __typename?: 'SubtotalCost';
                directCostRange:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                range:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
              }
            | { __typename?: 'SubtotalNumeric'; value: Numeric };
        }>;
        categorizedItemCosts: Array<{
          __typename?: 'CategorizedItemCost';
          id: UUID;
          milestoneId: UUID;
          name: string;
          number: string;
          parentId?: UUID | null;
          status?: Status | null;
          canViewCosts: boolean;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          directCostRange:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          range:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          itemContributionLines?: Array<{
            __typename?: 'ItemContributionLines';
            lineID?: UUID | null;
            lineDescription?: string | null;
            categories: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              error?: string | null;
              level: number;
              hasSubCategories: boolean;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                builtin: boolean;
                description: string;
                draft: boolean;
                levels: number;
                deprecated: boolean;
              } | null;
              levels: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                hasSubCategories: boolean;
                level: number;
              }>;
            }>;
            directCostRange:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            range:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          } | null> | null;
        }>;
        markupContributions: Array<{
          __typename?: 'MarkupContribution';
          isIncorporated: boolean;
          isOwnerCost: boolean;
          markupId: UUID;
          markupName: string;
          displayType: MarkupDisplayType;
          range:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        }>;
      } | null;
      quantity?: {
        __typename?: 'Quantity';
        id: UUID;
        magnitude: Numeric;
        estimateID: UUID;
        hasBreakdown: boolean;
        unit: {
          __typename?: 'Unit';
          abbreviationPlural: string;
          abbreviationSingular: string;
          description?: string | null;
          id: UUID;
          isDefault: boolean;
          isEnabled: boolean;
          name: string;
          type: UnitType;
        };
      } | null;
    };
  }>;
};

export type UpdateScenarioItemOverlaysMutationVariables = Exact<{
  scenarioID: Scalars['UUID']['input'];
  itemOverlays: Array<ItemOverlay>;
  milestoneID: Scalars['UUID']['input'];
  costMode: CostMode;
}>;

export type UpdateScenarioItemOverlaysMutation = {
  __typename?: 'Mutation';
  updateScenarioItemOverlays: { __typename?: 'ScenarioSandbox'; id: UUID };
};

export type AddItemToScenariosMutationVariables = Exact<{
  scenarioIDs: Array<Scalars['UUID']['input']>;
  itemID: Scalars['UUID']['input'];
}>;

export type AddItemToScenariosMutation = { __typename?: 'Mutation'; addItemToScenarios: boolean };

export type CreateScenarioInSandboxMutationVariables = Exact<{
  milestoneID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  color: Scalars['String']['input'];
  itemOverlays: Array<ItemOverlay>;
}>;

export type CreateScenarioInSandboxMutation = {
  __typename?: 'Mutation';
  createScenarioInSandbox?: UUID | null;
};

export type CopyScenarioMutationVariables = Exact<{
  scenarioID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  color: Scalars['String']['input'];
}>;

export type CopyScenarioMutation = { __typename?: 'Mutation'; copyScenario?: UUID | null };

export type UpdateScenarioMetadataMutationVariables = Exact<{
  scenarioID: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  thumbnailAssetID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type UpdateScenarioMetadataMutation = {
  __typename?: 'Mutation';
  updateScenarioMetadata: {
    __typename?: 'ScenarioMetadata';
    scenarioID: UUID;
    name: string;
    description?: string | null;
    color?: string | null;
    thumbnailAssetID?: UUID | null;
  };
};

export type RemoveScenarioFromSandboxMutationVariables = Exact<{
  scenarioID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
}>;

export type RemoveScenarioFromSandboxMutation = {
  __typename?: 'Mutation';
  removeScenarioFromSandbox: boolean;
};

export type AddScenarioToSandboxMutationVariables = Exact<{
  scenarioID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
}>;

export type AddScenarioToSandboxMutation = {
  __typename?: 'Mutation';
  addScenarioToSandbox: boolean;
};

export type UpdateSandboxMutationVariables = Exact<{
  scenarioIDs: Array<Scalars['UUID']['input']>;
  milestoneID: Scalars['UUID']['input'];
}>;

export type UpdateSandboxMutation = { __typename?: 'Mutation'; updateSandbox: boolean };

export type DeleteScenarioMutationVariables = Exact<{
  scenarioID: Scalars['UUID']['input'];
}>;

export type DeleteScenarioMutation = { __typename?: 'Mutation'; deleteScenario: boolean };

export type ItemInScenarioFieldsFragment = {
  __typename?: 'ItemsListItem';
  id: UUID;
  createdAt: Time;
  isOverlay?: boolean | null;
  name: string;
  number: string;
  parentID?: UUID | null;
  status: Status;
  itemType: ItemType;
  visibility: Visibility;
  cost:
    | { __typename?: 'CostRange'; min: USCents; max: USCents }
    | { __typename?: 'CostScalar'; value: USCents };
  creator: { __typename?: 'UserLink'; id: UUID; name: string; picture: string; status: UserStatus };
  overlay?: { __typename?: 'OverlayDiff'; baseStatus?: Status | null } | null;
  options: Array<{
    __typename?: 'ItemLink';
    id: UUID;
    name: string;
    number: string;
    status: Status;
    visibility?: Visibility | null;
  }>;
};

export type LoadOrCreateScenarioSandboxByMilestoneIdQueryVariables = Exact<{
  milestoneID: Scalars['UUID']['input'];
  costMode: CostMode;
  scenariosSortBy: ScenariosSortBy;
}>;

export type LoadOrCreateScenarioSandboxByMilestoneIdQuery = {
  __typename?: 'Query';
  loadOrCreateScenarioSandboxByMilestoneID: {
    __typename?: 'ScenarioSandbox';
    id: UUID;
    milestoneID: UUID;
    scenarios: Array<{
      __typename?: 'ScenarioColumn';
      scenarioID: UUID;
      name: string;
      description?: string | null;
      color?: string | null;
      thumbnailAssetID?: UUID | null;
      createdBy: UUID;
      items: Array<{
        __typename?: 'ItemsListItem';
        id: UUID;
        createdAt: Time;
        isOverlay?: boolean | null;
        name: string;
        number: string;
        parentID?: UUID | null;
        status: Status;
        itemType: ItemType;
        visibility: Visibility;
        cost:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        creator: {
          __typename?: 'UserLink';
          id: UUID;
          name: string;
          picture: string;
          status: UserStatus;
        };
        overlay?: { __typename?: 'OverlayDiff'; baseStatus?: Status | null } | null;
        options: Array<{
          __typename?: 'ItemLink';
          id: UUID;
          name: string;
          number: string;
          status: Status;
          visibility?: Visibility | null;
        }>;
      }>;
      costReports: {
        __typename?: 'MilestoneCostReports';
        costReportColumns: Array<{
          __typename?: 'CostReportColumn';
          type: CostReportColumnType;
          columnKey: CostReportColumnKey;
          isCategorized?: boolean | null;
          report?: {
            __typename?: 'CostReport';
            numeric: Numeric;
            type: CostReportType;
            directCostRange:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            directCostSegmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
            projectTotalRange?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            projectTotalSegmented?: {
              __typename?: 'AddDeductCost';
              adds: USCents;
              deducts: USCents;
            } | null;
            range:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
            subtotals: Array<{
              __typename?: 'CostReportItem';
              categories: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                error?: string | null;
                level: number;
                hasSubCategories: boolean;
                categorization?: {
                  __typename?: 'Categorization';
                  id: UUID;
                  name: string;
                  builtin: boolean;
                  description: string;
                  draft: boolean;
                  levels: number;
                  deprecated: boolean;
                } | null;
                levels: Array<{
                  __typename?: 'Category';
                  id: UUID;
                  name: string;
                  number: string;
                  hasSubCategories: boolean;
                  level: number;
                }>;
              }>;
              subtotalValue:
                | {
                    __typename?: 'SubtotalAddsDeducts';
                    directCostSegmented: {
                      __typename?: 'PartialAddDeductCost';
                      adds?: USCents | null;
                      deducts?: USCents | null;
                    };
                    segmented: {
                      __typename?: 'PartialAddDeductCost';
                      adds?: USCents | null;
                      deducts?: USCents | null;
                    };
                  }
                | {
                    __typename?: 'SubtotalCost';
                    directCostRange:
                      | { __typename?: 'CostRange'; min: USCents; max: USCents }
                      | { __typename?: 'CostScalar'; value: USCents };
                    range:
                      | { __typename?: 'CostRange'; min: USCents; max: USCents }
                      | { __typename?: 'CostScalar'; value: USCents };
                  }
                | { __typename?: 'SubtotalNumeric'; value: Numeric };
            }>;
            categorizedItemCosts: Array<{
              __typename?: 'CategorizedItemCost';
              id: UUID;
              milestoneId: UUID;
              name: string;
              number: string;
              parentId?: UUID | null;
              status?: Status | null;
              canViewCosts: boolean;
              categories: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                error?: string | null;
                level: number;
                hasSubCategories: boolean;
                categorization?: {
                  __typename?: 'Categorization';
                  id: UUID;
                  name: string;
                  builtin: boolean;
                  description: string;
                  draft: boolean;
                  levels: number;
                  deprecated: boolean;
                } | null;
                levels: Array<{
                  __typename?: 'Category';
                  id: UUID;
                  name: string;
                  number: string;
                  hasSubCategories: boolean;
                  level: number;
                }>;
              }>;
              directCostRange:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              range:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              itemContributionLines?: Array<{
                __typename?: 'ItemContributionLines';
                lineID?: UUID | null;
                lineDescription?: string | null;
                categories: Array<{
                  __typename?: 'Category';
                  id: UUID;
                  name: string;
                  number: string;
                  error?: string | null;
                  level: number;
                  hasSubCategories: boolean;
                  categorization?: {
                    __typename?: 'Categorization';
                    id: UUID;
                    name: string;
                    builtin: boolean;
                    description: string;
                    draft: boolean;
                    levels: number;
                    deprecated: boolean;
                  } | null;
                  levels: Array<{
                    __typename?: 'Category';
                    id: UUID;
                    name: string;
                    number: string;
                    hasSubCategories: boolean;
                    level: number;
                  }>;
                }>;
                directCostRange:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                range:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
              } | null> | null;
            }>;
            markupContributions: Array<{
              __typename?: 'MarkupContribution';
              isIncorporated: boolean;
              isOwnerCost: boolean;
              markupId: UUID;
              markupName: string;
              displayType: MarkupDisplayType;
              range:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
            }>;
          } | null;
          quantity?: {
            __typename?: 'Quantity';
            id: UUID;
            magnitude: Numeric;
            estimateID: UUID;
            hasBreakdown: boolean;
            unit: {
              __typename?: 'Unit';
              abbreviationPlural: string;
              abbreviationSingular: string;
              description?: string | null;
              id: UUID;
              isDefault: boolean;
              isEnabled: boolean;
              name: string;
              type: UnitType;
            };
          } | null;
        }>;
      };
    }>;
    milestone: {
      __typename?: 'MilestoneColumn';
      milestoneID: UUID;
      items: Array<{
        __typename?: 'ItemsListItem';
        id: UUID;
        createdAt: Time;
        isOverlay?: boolean | null;
        name: string;
        number: string;
        parentID?: UUID | null;
        status: Status;
        itemType: ItemType;
        visibility: Visibility;
        cost:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        creator: {
          __typename?: 'UserLink';
          id: UUID;
          name: string;
          picture: string;
          status: UserStatus;
        };
        overlay?: { __typename?: 'OverlayDiff'; baseStatus?: Status | null } | null;
        options: Array<{
          __typename?: 'ItemLink';
          id: UUID;
          name: string;
          number: string;
          status: Status;
          visibility?: Visibility | null;
        }>;
      }>;
      costReports: {
        __typename?: 'MilestoneCostReports';
        costReportColumns: Array<{
          __typename?: 'CostReportColumn';
          type: CostReportColumnType;
          columnKey: CostReportColumnKey;
          isCategorized?: boolean | null;
          report?: {
            __typename?: 'CostReport';
            numeric: Numeric;
            type: CostReportType;
            directCostRange:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            directCostSegmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
            projectTotalRange?:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents }
              | null;
            projectTotalSegmented?: {
              __typename?: 'AddDeductCost';
              adds: USCents;
              deducts: USCents;
            } | null;
            range:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
            subtotals: Array<{
              __typename?: 'CostReportItem';
              categories: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                error?: string | null;
                level: number;
                hasSubCategories: boolean;
                categorization?: {
                  __typename?: 'Categorization';
                  id: UUID;
                  name: string;
                  builtin: boolean;
                  description: string;
                  draft: boolean;
                  levels: number;
                  deprecated: boolean;
                } | null;
                levels: Array<{
                  __typename?: 'Category';
                  id: UUID;
                  name: string;
                  number: string;
                  hasSubCategories: boolean;
                  level: number;
                }>;
              }>;
              subtotalValue:
                | {
                    __typename?: 'SubtotalAddsDeducts';
                    directCostSegmented: {
                      __typename?: 'PartialAddDeductCost';
                      adds?: USCents | null;
                      deducts?: USCents | null;
                    };
                    segmented: {
                      __typename?: 'PartialAddDeductCost';
                      adds?: USCents | null;
                      deducts?: USCents | null;
                    };
                  }
                | {
                    __typename?: 'SubtotalCost';
                    directCostRange:
                      | { __typename?: 'CostRange'; min: USCents; max: USCents }
                      | { __typename?: 'CostScalar'; value: USCents };
                    range:
                      | { __typename?: 'CostRange'; min: USCents; max: USCents }
                      | { __typename?: 'CostScalar'; value: USCents };
                  }
                | { __typename?: 'SubtotalNumeric'; value: Numeric };
            }>;
            categorizedItemCosts: Array<{
              __typename?: 'CategorizedItemCost';
              id: UUID;
              milestoneId: UUID;
              name: string;
              number: string;
              parentId?: UUID | null;
              status?: Status | null;
              canViewCosts: boolean;
              categories: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                error?: string | null;
                level: number;
                hasSubCategories: boolean;
                categorization?: {
                  __typename?: 'Categorization';
                  id: UUID;
                  name: string;
                  builtin: boolean;
                  description: string;
                  draft: boolean;
                  levels: number;
                  deprecated: boolean;
                } | null;
                levels: Array<{
                  __typename?: 'Category';
                  id: UUID;
                  name: string;
                  number: string;
                  hasSubCategories: boolean;
                  level: number;
                }>;
              }>;
              directCostRange:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              range:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              itemContributionLines?: Array<{
                __typename?: 'ItemContributionLines';
                lineID?: UUID | null;
                lineDescription?: string | null;
                categories: Array<{
                  __typename?: 'Category';
                  id: UUID;
                  name: string;
                  number: string;
                  error?: string | null;
                  level: number;
                  hasSubCategories: boolean;
                  categorization?: {
                    __typename?: 'Categorization';
                    id: UUID;
                    name: string;
                    builtin: boolean;
                    description: string;
                    draft: boolean;
                    levels: number;
                    deprecated: boolean;
                  } | null;
                  levels: Array<{
                    __typename?: 'Category';
                    id: UUID;
                    name: string;
                    number: string;
                    hasSubCategories: boolean;
                    level: number;
                  }>;
                }>;
                directCostRange:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                range:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
              } | null> | null;
            }>;
            markupContributions: Array<{
              __typename?: 'MarkupContribution';
              isIncorporated: boolean;
              isOwnerCost: boolean;
              markupId: UUID;
              markupName: string;
              displayType: MarkupDisplayType;
              range:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
            }>;
          } | null;
          quantity?: {
            __typename?: 'Quantity';
            id: UUID;
            magnitude: Numeric;
            estimateID: UUID;
            hasBreakdown: boolean;
            unit: {
              __typename?: 'Unit';
              abbreviationPlural: string;
              abbreviationSingular: string;
              description?: string | null;
              id: UUID;
              isDefault: boolean;
              isEnabled: boolean;
              name: string;
              type: UnitType;
            };
          } | null;
        }>;
      };
    };
  };
};

export type RemoveItemFromScenariosMutationVariables = Exact<{
  scenarioIDs: Array<Scalars['UUID']['input']>;
  itemID: Scalars['UUID']['input'];
}>;

export type RemoveItemFromScenariosMutation = {
  __typename?: 'Mutation';
  removeItemFromScenarios: boolean;
};

export type ApplyScenarioMutationVariables = Exact<{
  scenarioID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  costMode: CostMode;
}>;

export type ApplyScenarioMutation = { __typename?: 'Mutation'; applyScenario: boolean };

export type CreateScenarioItemMutationVariables = Exact<{
  itemInput: ScenarioItemCreationInput;
  scenarioID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  costMode: CostMode;
}>;

export type CreateScenarioItemMutation = {
  __typename?: 'Mutation';
  createScenarioItem?: {
    __typename?: 'Item';
    id: UUID;
    itemType: ItemType;
    description: string;
    descriptionStyled: string;
    dueDate?: Time | null;
    creationTime?: Time | null;
    updateTime?: Time | null;
    name: string;
    number: string;
    status: Status;
    visibility: Visibility;
    availableStates: Array<Status>;
    assetCount: number;
    remoteAssetCount: number;
    commentCount: number;
    filteredMilestone?: UUID | null;
    filteredCategories?: Array<UUID> | null;
    activityIDs?: Array<UUID> | null;
    options: Array<{
      __typename?: 'Option';
      parent: UUID;
      parentVisibility?: Visibility | null;
      hasEstimate?: boolean | null;
      id: UUID;
      itemType: ItemType;
      description: string;
      descriptionStyled: string;
      dueDate?: Time | null;
      creationTime?: Time | null;
      updateTime?: Time | null;
      name: string;
      number: string;
      status: Status;
      visibility: Visibility;
      availableStates: Array<Status>;
      assetCount: number;
      remoteAssetCount: number;
      commentCount: number;
      filteredMilestone?: UUID | null;
      filteredCategories?: Array<UUID> | null;
      activityIDs?: Array<UUID> | null;
      comments: Array<{
        __typename?: 'Comment';
        id: UUID;
        created: Time;
        author: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        };
        text: {
          __typename?: 'MentionableText';
          contents: string;
          mentions?: Array<{
            __typename?: 'Mention';
            id: UUID;
            position: number;
            text: string;
            user: { __typename?: 'User'; id: UUID };
          }> | null;
          itemReferences?: Array<{
            __typename?: 'ItemReference';
            id: UUID;
            position: number;
            text: string;
            referencedItem?: {
              __typename?: 'ItemsListItem';
              createdAt: Time;
              currentStatus: Status;
              id: UUID;
              name: string;
              number: string;
              status: Status;
              updateTime: Time;
              visibility: Visibility;
              creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
              currentCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
              options: Array<{
                __typename?: 'ItemLink';
                id: UUID;
                name: string;
                number: string;
                status: Status;
                visibility?: Visibility | null;
              }>;
            } | null;
          }> | null;
        };
      }>;
      assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
      activeEstimate?: {
        __typename?: 'Estimate';
        id: UUID;
        name: string;
        subtotal: Numeric;
        markupSubtotal: USCents;
        drawSubtotal: USCents;
        inheritedSubtotal: USCents;
        inheritedOwnerCostMarkupsSubtotal: USCents;
        asset?: UUID | null;
        totalType: EstimateTotalType;
        isRunningTotal: boolean;
        lines: Array<{
          __typename?: 'Line';
          id: UUID;
          estimateId: UUID;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          cells: Array<{
            __typename?: 'Cell';
            estimateId: UUID;
            field: UUID;
            line: UUID;
            error?: string | null;
            value?:
              | {
                  __typename?: 'CategoryCell';
                  search: string;
                  category?: {
                    __typename?: 'Category';
                    id: UUID;
                    parentID?: UUID | null;
                    name: string;
                    hasSubCategories: boolean;
                    number: string;
                    level: number;
                    error?: string | null;
                    categorization?: {
                      __typename?: 'Categorization';
                      id: UUID;
                      name: string;
                    } | null;
                    levels: Array<{
                      __typename?: 'Category';
                      id: UUID;
                      name: string;
                      number: string;
                    }>;
                  } | null;
                }
              | { __typename?: 'RegularCell'; string: string }
              | {
                  __typename?: 'SourceCell';
                  sourceIDs?: {
                    __typename?: 'SourceIDs';
                    estimateID: UUID;
                    itemID?: UUID | null;
                    lineID?: UUID | null;
                    markupID?: UUID | null;
                    milestoneID: UUID;
                  } | null;
                }
              | null;
          }>;
        }>;
        fields: Array<{
          __typename?: 'Field';
          id: UUID;
          name: string;
          type: string;
          group: string;
          categorization?: {
            __typename?: 'Categorization';
            id: UUID;
            name: string;
            description: string;
            levels: number;
            builtin: boolean;
            createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
          } | null;
          errors: Array<{
            __typename?: 'FieldError';
            type: FieldErrorType;
            count: number;
            categoryErrorIndicies: Array<{
              __typename?: 'CategoryErrorIndicies';
              categoryNumber: string;
              indicies: Array<number>;
            }>;
          }>;
        }>;
        incorporatedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        contingencyDraws?: Array<{
          __typename?: 'ItemDrawInfo';
          id: UUID;
          type: MarkupDisplayType;
          name: string;
          error: string;
          draw: USCents;
          drawFromID?: UUID | null;
          isDrawingFullAmount: boolean;
        }> | null;
        inheritedMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        inheritedOwnerCostMarkups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
        markups: Array<{
          __typename?: 'Markup';
          id: UUID;
          estimateId: UUID;
          name: string;
          percent?: number | null;
          percentScale?: number | null;
          sourceFilterIDs: Array<UUID>;
          sourceFilterIDsWithoutS2Reference: Array<UUID>;
          total: USCents;
          type: MarkupType;
          displayType: MarkupDisplayType;
          drawFromID?: UUID | null;
          value?: Numeric | null;
          disabled: boolean;
          orderingNumerator: Ordering;
          orderingDenominator: Ordering;
          costTypeFilters?: Array<CostType> | null;
          shouldExcludeFromAllocation: boolean;
          shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
          errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
          markupReference?: {
            __typename?: 'MarkupReference';
            appliesTo: Array<string>;
            item?: {
              __typename?: 'MarkupItemReference';
              number: string;
              name: string;
              isDeprecated: boolean;
            } | null;
          } | null;
          categoryFilters: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            level: number;
            error?: string | null;
            categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
          }>;
          source?: {
            __typename?: 'SourceIDs';
            itemID?: UUID | null;
            estimateID: UUID;
            milestoneID: UUID;
            lineID?: UUID | null;
            markupID?: UUID | null;
          } | null;
        }>;
      } | null;
      bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
      project: { __typename?: 'Project'; id: UUID; name: string };
      createdBy?: {
        __typename?: 'User';
        id: UUID;
        email: string;
        name: string;
        picture: string;
      } | null;
      milestone?: {
        __typename?: 'Milestone';
        id: UUID;
        name: string;
        date?: Time | null;
        buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
      } | null;
      categories: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        error?: string | null;
        level: number;
        hasSubCategories: boolean;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          builtin: boolean;
          description: string;
          draft: boolean;
          levels: number;
          deprecated: boolean;
        } | null;
        levels: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          hasSubCategories: boolean;
          level: number;
        }>;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      assignee?: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      } | null;
      previousMilestoneStatus: Array<{
        __typename?: 'PreviousMilestoneStatus';
        hasEstimate?: boolean | null;
        milestoneID: UUID;
        itemType: ItemType;
        status: Status;
        optionSubtotals: Array<{
          __typename?: 'OptionSubtotal';
          status: Status;
          adds: USCents;
          deducts: USCents;
        }>;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        estimateCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        contingencyDrawCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
      }>;
      scheduleImpact?: {
        __typename?: 'ScheduleImpact';
        type: ScheduleImpactType;
        criticalPath: boolean;
        days: number;
        minImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
        maxImpact?: {
          __typename?: 'ScheduleImpact';
          type: ScheduleImpactType;
          days: number;
        } | null;
      } | null;
    }>;
    optionSubtotals: Array<{
      __typename?: 'OptionSubtotal';
      status: Status;
      adds: USCents;
      deducts: USCents;
    }>;
    comments: Array<{
      __typename?: 'Comment';
      id: UUID;
      created: Time;
      author: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      text: {
        __typename?: 'MentionableText';
        contents: string;
        mentions?: Array<{
          __typename?: 'Mention';
          id: UUID;
          position: number;
          text: string;
          user: { __typename?: 'User'; id: UUID };
        }> | null;
        itemReferences?: Array<{
          __typename?: 'ItemReference';
          id: UUID;
          position: number;
          text: string;
          referencedItem?: {
            __typename?: 'ItemsListItem';
            createdAt: Time;
            currentStatus: Status;
            id: UUID;
            name: string;
            number: string;
            status: Status;
            updateTime: Time;
            visibility: Visibility;
            creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
            currentCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
            options: Array<{
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              status: Status;
              visibility?: Visibility | null;
            }>;
          } | null;
        }> | null;
      };
    }>;
    assets: Array<{ __typename?: 'Asset'; id: UUID; name: string }>;
    activeEstimate?: {
      __typename?: 'Estimate';
      id: UUID;
      name: string;
      subtotal: Numeric;
      markupSubtotal: USCents;
      drawSubtotal: USCents;
      inheritedSubtotal: USCents;
      inheritedOwnerCostMarkupsSubtotal: USCents;
      asset?: UUID | null;
      totalType: EstimateTotalType;
      isRunningTotal: boolean;
      lines: Array<{
        __typename?: 'Line';
        id: UUID;
        estimateId: UUID;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        cells: Array<{
          __typename?: 'Cell';
          estimateId: UUID;
          field: UUID;
          line: UUID;
          error?: string | null;
          value?:
            | {
                __typename?: 'CategoryCell';
                search: string;
                category?: {
                  __typename?: 'Category';
                  id: UUID;
                  parentID?: UUID | null;
                  name: string;
                  hasSubCategories: boolean;
                  number: string;
                  level: number;
                  error?: string | null;
                  categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                  levels: Array<{
                    __typename?: 'Category';
                    id: UUID;
                    name: string;
                    number: string;
                  }>;
                } | null;
              }
            | { __typename?: 'RegularCell'; string: string }
            | {
                __typename?: 'SourceCell';
                sourceIDs?: {
                  __typename?: 'SourceIDs';
                  estimateID: UUID;
                  itemID?: UUID | null;
                  lineID?: UUID | null;
                  markupID?: UUID | null;
                  milestoneID: UUID;
                } | null;
              }
            | null;
        }>;
      }>;
      fields: Array<{
        __typename?: 'Field';
        id: UUID;
        name: string;
        type: string;
        group: string;
        categorization?: {
          __typename?: 'Categorization';
          id: UUID;
          name: string;
          description: string;
          levels: number;
          builtin: boolean;
          createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
        } | null;
        errors: Array<{
          __typename?: 'FieldError';
          type: FieldErrorType;
          count: number;
          categoryErrorIndicies: Array<{
            __typename?: 'CategoryErrorIndicies';
            categoryNumber: string;
            indicies: Array<number>;
          }>;
        }>;
      }>;
      incorporatedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      contingencyDraws?: Array<{
        __typename?: 'ItemDrawInfo';
        id: UUID;
        type: MarkupDisplayType;
        name: string;
        error: string;
        draw: USCents;
        drawFromID?: UUID | null;
        isDrawingFullAmount: boolean;
      }> | null;
      inheritedMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      inheritedOwnerCostMarkups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
      markups: Array<{
        __typename?: 'Markup';
        id: UUID;
        estimateId: UUID;
        name: string;
        percent?: number | null;
        percentScale?: number | null;
        sourceFilterIDs: Array<UUID>;
        sourceFilterIDsWithoutS2Reference: Array<UUID>;
        total: USCents;
        type: MarkupType;
        displayType: MarkupDisplayType;
        drawFromID?: UUID | null;
        value?: Numeric | null;
        disabled: boolean;
        orderingNumerator: Ordering;
        orderingDenominator: Ordering;
        costTypeFilters?: Array<CostType> | null;
        shouldExcludeFromAllocation: boolean;
        shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
        errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
        markupReference?: {
          __typename?: 'MarkupReference';
          appliesTo: Array<string>;
          item?: {
            __typename?: 'MarkupItemReference';
            number: string;
            name: string;
            isDeprecated: boolean;
          } | null;
        } | null;
        categoryFilters: Array<{
          __typename?: 'Category';
          id: UUID;
          name: string;
          number: string;
          level: number;
          error?: string | null;
          categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
        }>;
        source?: {
          __typename?: 'SourceIDs';
          itemID?: UUID | null;
          estimateID: UUID;
          milestoneID: UUID;
          lineID?: UUID | null;
          markupID?: UUID | null;
        } | null;
      }>;
    } | null;
    bucket?: { __typename?: 'Bucket'; id: UUID; name: string; date?: Time | null } | null;
    project: { __typename?: 'Project'; id: UUID; name: string };
    createdBy?: {
      __typename?: 'User';
      id: UUID;
      email: string;
      name: string;
      picture: string;
    } | null;
    milestone?: {
      __typename?: 'Milestone';
      id: UUID;
      name: string;
      date?: Time | null;
      buckets?: Array<{ __typename?: 'Bucket'; id: UUID; name: string }> | null;
    } | null;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      error?: string | null;
      level: number;
      hasSubCategories: boolean;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        builtin: boolean;
        description: string;
        draft: boolean;
        levels: number;
        deprecated: boolean;
      } | null;
      levels: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        hasSubCategories: boolean;
        level: number;
      }>;
    }>;
    cost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    estimateCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    contingencyDrawCost?:
      | { __typename?: 'CostRange'; min: USCents; max: USCents }
      | { __typename?: 'CostScalar'; value: USCents }
      | null;
    assignee?: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
    previousMilestoneStatus: Array<{
      __typename?: 'PreviousMilestoneStatus';
      hasEstimate?: boolean | null;
      milestoneID: UUID;
      itemType: ItemType;
      status: Status;
      optionSubtotals: Array<{
        __typename?: 'OptionSubtotal';
        status: Status;
        adds: USCents;
        deducts: USCents;
      }>;
      cost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      estimateCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
      contingencyDrawCost?:
        | { __typename?: 'CostRange'; min: USCents; max: USCents }
        | { __typename?: 'CostScalar'; value: USCents }
        | null;
    }>;
    scheduleImpact?: {
      __typename?: 'ScheduleImpact';
      type: ScheduleImpactType;
      criticalPath: boolean;
      days: number;
      minImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
      maxImpact?: { __typename?: 'ScheduleImpact'; type: ScheduleImpactType; days: number } | null;
    } | null;
  } | null;
};

export type DeleteScenarioItemMutationVariables = Exact<{
  itemID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
}>;

export type DeleteScenarioItemMutation = { __typename?: 'Mutation'; deleteScenarioItem: boolean };

export type CanApplyScenarioQueryVariables = Exact<{
  scenarioID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  costMode: CostMode;
}>;

export type CanApplyScenarioQuery = {
  __typename?: 'Query';
  canApplyScenario: {
    __typename?: 'CanApplyScenario';
    hasApplyPermission: boolean;
    hasChanges: boolean;
  };
};

export type UpsertProjectCompanyRoleMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  companyID: Scalars['UUID']['input'];
  role: Scalars['String']['input'];
}>;

export type UpsertProjectCompanyRoleMutation = {
  __typename?: 'Mutation';
  upsertProjectCompanyRole: {
    __typename?: 'ProjectCompany';
    role?: string | null;
    company: {
      __typename?: 'Company';
      id: UUID;
      domain?: string | null;
      name: string;
      type: string;
      asset?: { __typename?: 'Asset'; id: UUID; name: string } | null;
    };
  };
};

export type CreateProjectRoleMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  roleID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type CreateProjectRoleMutation = { __typename?: 'Mutation'; createProjectRole: UUID };

export type DeleteProjectRoleMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
  collaboratorRoleInputs: Array<CollaboratorRoleInput>;
}>;

export type DeleteProjectRoleMutation = { __typename?: 'Mutation'; deleteProjectRole: boolean };

export type LoadCollaboratorsByRoleQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
}>;

export type LoadCollaboratorsByRoleQuery = {
  __typename?: 'Query';
  loadCollaboratorsByRole: Array<{
    __typename?: 'Collaborator';
    id: UUID;
    user: { __typename?: 'User'; id: UUID; name: string; picture: string; status: UserStatus };
  }>;
};

export type UpdatePermissionMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  level: PermissionLevel;
}>;

export type UpdatePermissionMutation = {
  __typename?: 'Mutation';
  updatePermission: {
    __typename?: 'Permission';
    id: UUID;
    level: PermissionLevel;
    resource: PermissionResource;
    actions: {
      __typename?: 'Actions';
      view: boolean;
      add: boolean;
      delete: boolean;
      edit: boolean;
    };
  };
};

export type UpdateRoleNameMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
}>;

export type UpdateRoleNameMutation = { __typename?: 'Mutation'; setRoleName: string };

export type UpdateRoleTradeMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
  hasTrade: Scalars['Boolean']['input'];
}>;

export type UpdateRoleTradeMutation = { __typename?: 'Mutation'; setRoleHasTrade: boolean };

export type TimelineQueryVariables = Exact<{
  input: TimelineInput;
}>;

export type TimelineQuery = {
  __typename?: 'Query';
  timeline: {
    __typename?: 'Timeline';
    activities: Array<{
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      type: TimelineActivityType;
      startDate: Time;
      endDate?: Time | null;
      children: Array<UUID>;
      itemCount: number;
      milestone?: {
        __typename?: 'Milestone';
        id: UUID;
        name: string;
        description: string;
        itemsCount: number;
      } | null;
    }>;
  };
};

export type TimelineActivityOptionsQueryVariables = Exact<{
  input: TimelineInput;
}>;

export type TimelineActivityOptionsQuery = {
  __typename?: 'Query';
  timeline: {
    __typename?: 'Timeline';
    activities: Array<{
      __typename?: 'TimelineActivity';
      id: UUID;
      name: string;
      type: TimelineActivityType;
      itemCount: number;
      milestone?: {
        __typename?: 'Milestone';
        id: UUID;
        name: string;
        description: string;
        itemsCount: number;
      } | null;
    }>;
  };
};

export type CreateTimelineActivityMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  input: TimelineActivityInput;
}>;

export type CreateTimelineActivityMutation = {
  __typename?: 'Mutation';
  createTimelineActivity: {
    __typename?: 'TimelineActivity';
    id: UUID;
    type: TimelineActivityType;
    name: string;
    startDate: Time;
    endDate?: Time | null;
  };
};

export type UpdateTimelineActivityMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  activityID: Scalars['UUID']['input'];
  input: TimelineActivityInput;
}>;

export type UpdateTimelineActivityMutation = {
  __typename?: 'Mutation';
  updateTimelineActivity: {
    __typename?: 'TimelineActivity';
    id: UUID;
    type: TimelineActivityType;
    name: string;
    startDate: Time;
    endDate?: Time | null;
  };
};

export type DeleteTimelineActivityMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  activityID: Scalars['UUID']['input'];
}>;

export type DeleteTimelineActivityMutation = {
  __typename?: 'Mutation';
  deleteTimelineActivity?: UUID | null;
};

export type ItemsTimelineItemFieldsFragment = {
  __typename?: 'ItemsListItem';
  dueDate?: Time | null;
  id: UUID;
  name: string;
  status: Status;
};

export type ItemsTimelineQueryVariables = Exact<{
  milestoneID?: InputMaybe<Scalars['UUID']['input']>;
  activityID?: InputMaybe<Scalars['UUID']['input']>;
  projectID: Scalars['UUID']['input'];
  showOptions: Scalars['Boolean']['input'];
  costMode: CostMode;
  loadItemCosts?: InputMaybe<Scalars['Boolean']['input']>;
  viewFilter: ViewFilter;
  visibilityView: VisibilityView;
  integrations: Array<IntegrationFilterType>;
}>;

export type ItemsTimelineQuery = {
  __typename?: 'Query';
  itemsList: {
    __typename?: 'ItemsList';
    items: Array<{
      __typename?: 'ItemsListItem';
      dueDate?: Time | null;
      id: UUID;
      name: string;
      status: Status;
    }>;
  };
};

export type VarianceReportsQueryVariables = Exact<{
  columnSets: Array<VarianceColumnSet>;
  groupByItems: Array<GroupByItem>;
  costMode: CostMode;
  projectID: Scalars['UUID']['input'];
  shouldIncludeOwnerCosts?: InputMaybe<Scalars['Boolean']['input']>;
  viewFilter: ViewFilter;
}>;

export type VarianceReportsQuery = {
  __typename?: 'Query';
  varianceReports: Array<{
    __typename?: 'VarianceReports';
    milestoneID?: UUID | null;
    name?: string | null;
    date?: Time | null;
    varianceColumns: Array<{
      __typename?: 'CostReportColumn';
      type: CostReportColumnType;
      columnKey: CostReportColumnKey;
      isCategorized?: boolean | null;
      report?: {
        __typename?: 'CostReport';
        numeric: Numeric;
        type: CostReportType;
        directCostRange:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        directCostSegmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        projectTotalRange?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        projectTotalSegmented?: {
          __typename?: 'AddDeductCost';
          adds: USCents;
          deducts: USCents;
        } | null;
        range:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        subtotals: Array<{
          __typename?: 'CostReportItem';
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          subtotalValue:
            | {
                __typename?: 'SubtotalAddsDeducts';
                directCostSegmented: {
                  __typename?: 'PartialAddDeductCost';
                  adds?: USCents | null;
                  deducts?: USCents | null;
                };
                segmented: {
                  __typename?: 'PartialAddDeductCost';
                  adds?: USCents | null;
                  deducts?: USCents | null;
                };
              }
            | {
                __typename?: 'SubtotalCost';
                directCostRange:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                range:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
              }
            | { __typename?: 'SubtotalNumeric'; value: Numeric };
        }>;
        categorizedItemCosts: Array<{
          __typename?: 'CategorizedItemCost';
          id: UUID;
          milestoneId: UUID;
          name: string;
          number: string;
          parentId?: UUID | null;
          status?: Status | null;
          canViewCosts: boolean;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          directCostRange:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          range:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          itemContributionLines?: Array<{
            __typename?: 'ItemContributionLines';
            lineID?: UUID | null;
            lineDescription?: string | null;
            categories: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              error?: string | null;
              level: number;
              hasSubCategories: boolean;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                builtin: boolean;
                description: string;
                draft: boolean;
                levels: number;
                deprecated: boolean;
              } | null;
              levels: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                hasSubCategories: boolean;
                level: number;
              }>;
            }>;
            directCostRange:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            range:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          } | null> | null;
        }>;
        markupContributions: Array<{
          __typename?: 'MarkupContribution';
          isIncorporated: boolean;
          isOwnerCost: boolean;
          markupId: UUID;
          markupName: string;
          displayType: MarkupDisplayType;
          range:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        }>;
      } | null;
      quantity?: {
        __typename?: 'Quantity';
        id: UUID;
        magnitude: Numeric;
        estimateID: UUID;
        hasBreakdown: boolean;
        unit: {
          __typename?: 'Unit';
          abbreviationPlural: string;
          abbreviationSingular: string;
          description?: string | null;
          id: UUID;
          isDefault: boolean;
          isEnabled: boolean;
          name: string;
          type: UnitType;
        };
      } | null;
    }>;
  }>;
};

export type LoadVarianceReportCommentsQueryVariables = Exact<{
  reportID: Scalars['UUID']['input'];
  userReportCommentViewParameters: UserReportCommentViewParameters;
  costMode?: InputMaybe<CostMode>;
}>;

export type LoadVarianceReportCommentsQuery = {
  __typename?: 'Query';
  varianceReportComments: {
    __typename?: 'VarianceReportComments';
    itemComments: Array<{
      __typename?: 'UserReportComment';
      commentLineID: string;
      type: UserReportCommentType;
      costs: Array<USCents>;
      comment: {
        __typename?: 'Comment';
        id: UUID;
        created: Time;
        author: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        };
        text: {
          __typename?: 'MentionableText';
          contents: string;
          mentions?: Array<{
            __typename?: 'Mention';
            id: UUID;
            position: number;
            text: string;
            user: { __typename?: 'User'; id: UUID };
          }> | null;
          itemReferences?: Array<{
            __typename?: 'ItemReference';
            id: UUID;
            position: number;
            text: string;
            referencedItem?: {
              __typename?: 'ItemsListItem';
              createdAt: Time;
              currentStatus: Status;
              id: UUID;
              name: string;
              number: string;
              status: Status;
              updateTime: Time;
              visibility: Visibility;
              creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
              currentCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
              options: Array<{
                __typename?: 'ItemLink';
                id: UUID;
                name: string;
                number: string;
                status: Status;
                visibility?: Visibility | null;
              }>;
            } | null;
          }> | null;
        };
      };
    }>;
    itemLineComments: Array<{
      __typename?: 'UserReportComment';
      commentLineID: string;
      type: UserReportCommentType;
      costs: Array<USCents>;
      comment: {
        __typename?: 'Comment';
        id: UUID;
        created: Time;
        author: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        };
        text: {
          __typename?: 'MentionableText';
          contents: string;
          mentions?: Array<{
            __typename?: 'Mention';
            id: UUID;
            position: number;
            text: string;
            user: { __typename?: 'User'; id: UUID };
          }> | null;
          itemReferences?: Array<{
            __typename?: 'ItemReference';
            id: UUID;
            position: number;
            text: string;
            referencedItem?: {
              __typename?: 'ItemsListItem';
              createdAt: Time;
              currentStatus: Status;
              id: UUID;
              name: string;
              number: string;
              status: Status;
              updateTime: Time;
              visibility: Visibility;
              creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
              currentCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
              options: Array<{
                __typename?: 'ItemLink';
                id: UUID;
                name: string;
                number: string;
                status: Status;
                visibility?: Visibility | null;
              }>;
            } | null;
          }> | null;
        };
      };
    }>;
    subtotalComments: Array<{
      __typename?: 'UserReportComment';
      commentLineID: string;
      type: UserReportCommentType;
      costs: Array<USCents>;
      comment: {
        __typename?: 'Comment';
        id: UUID;
        created: Time;
        author: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        };
        text: {
          __typename?: 'MentionableText';
          contents: string;
          mentions?: Array<{
            __typename?: 'Mention';
            id: UUID;
            position: number;
            text: string;
            user: { __typename?: 'User'; id: UUID };
          }> | null;
          itemReferences?: Array<{
            __typename?: 'ItemReference';
            id: UUID;
            position: number;
            text: string;
            referencedItem?: {
              __typename?: 'ItemsListItem';
              createdAt: Time;
              currentStatus: Status;
              id: UUID;
              name: string;
              number: string;
              status: Status;
              updateTime: Time;
              visibility: Visibility;
              creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
              currentCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
              options: Array<{
                __typename?: 'ItemLink';
                id: UUID;
                name: string;
                number: string;
                status: Status;
                visibility?: Visibility | null;
              }>;
            } | null;
          }> | null;
        };
      };
    }>;
    markupComments: Array<{
      __typename?: 'UserReportComment';
      commentLineID: string;
      type: UserReportCommentType;
      costs: Array<USCents>;
      comment: {
        __typename?: 'Comment';
        id: UUID;
        created: Time;
        author: {
          __typename?: 'User';
          status: UserStatus;
          email: string;
          id: UUID;
          jobTitle: string;
          name: string;
          phone?: string | null;
          picture: string;
          pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
        };
        text: {
          __typename?: 'MentionableText';
          contents: string;
          mentions?: Array<{
            __typename?: 'Mention';
            id: UUID;
            position: number;
            text: string;
            user: { __typename?: 'User'; id: UUID };
          }> | null;
          itemReferences?: Array<{
            __typename?: 'ItemReference';
            id: UUID;
            position: number;
            text: string;
            referencedItem?: {
              __typename?: 'ItemsListItem';
              createdAt: Time;
              currentStatus: Status;
              id: UUID;
              name: string;
              number: string;
              status: Status;
              updateTime: Time;
              visibility: Visibility;
              creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
              currentCost:
                | { __typename?: 'CostRange'; min: USCents; max: USCents }
                | { __typename?: 'CostScalar'; value: USCents };
              currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
              options: Array<{
                __typename?: 'ItemLink';
                id: UUID;
                name: string;
                number: string;
                status: Status;
                visibility?: Visibility | null;
              }>;
            } | null;
          }> | null;
        };
      };
    }>;
  };
};

export type CreateUserReportCommentMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  userReportID: Scalars['UUID']['input'];
  reportCommentInput: UserReportCommentInput;
  costMode?: InputMaybe<CostMode>;
}>;

export type CreateUserReportCommentMutation = {
  __typename?: 'Mutation';
  createUserReportComment: {
    __typename?: 'UserReportComment';
    commentLineID: string;
    type: UserReportCommentType;
    costs: Array<USCents>;
    comment: {
      __typename?: 'Comment';
      id: UUID;
      created: Time;
      author: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      text: {
        __typename?: 'MentionableText';
        contents: string;
        mentions?: Array<{
          __typename?: 'Mention';
          id: UUID;
          position: number;
          text: string;
          user: { __typename?: 'User'; id: UUID };
        }> | null;
        itemReferences?: Array<{
          __typename?: 'ItemReference';
          id: UUID;
          position: number;
          text: string;
          referencedItem?: {
            __typename?: 'ItemsListItem';
            createdAt: Time;
            currentStatus: Status;
            id: UUID;
            name: string;
            number: string;
            status: Status;
            updateTime: Time;
            visibility: Visibility;
            creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
            currentCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
            options: Array<{
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              status: Status;
              visibility?: Visibility | null;
            }>;
          } | null;
        }> | null;
      };
    };
  };
};

export type UpdateUserReportCommentMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  commentID: Scalars['UUID']['input'];
  text: MentionableTextInput;
  costs: Array<Scalars['USCents']['input']>;
  costMode?: InputMaybe<CostMode>;
}>;

export type UpdateUserReportCommentMutation = {
  __typename?: 'Mutation';
  updateUserReportComment: {
    __typename?: 'UserReportComment';
    commentLineID: string;
    type: UserReportCommentType;
    costs: Array<USCents>;
    comment: {
      __typename?: 'Comment';
      id: UUID;
      created: Time;
      author: {
        __typename?: 'User';
        status: UserStatus;
        email: string;
        id: UUID;
        jobTitle: string;
        name: string;
        phone?: string | null;
        picture: string;
        pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
      };
      text: {
        __typename?: 'MentionableText';
        contents: string;
        mentions?: Array<{
          __typename?: 'Mention';
          id: UUID;
          position: number;
          text: string;
          user: { __typename?: 'User'; id: UUID };
        }> | null;
        itemReferences?: Array<{
          __typename?: 'ItemReference';
          id: UUID;
          position: number;
          text: string;
          referencedItem?: {
            __typename?: 'ItemsListItem';
            createdAt: Time;
            currentStatus: Status;
            id: UUID;
            name: string;
            number: string;
            status: Status;
            updateTime: Time;
            visibility: Visibility;
            creator: { __typename?: 'UserLink'; id: UUID; name: string; status: UserStatus };
            currentCost:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            currentMilestone: { __typename?: 'MilestoneLink'; id: UUID; name: string };
            options: Array<{
              __typename?: 'ItemLink';
              id: UUID;
              name: string;
              number: string;
              status: Status;
              visibility?: Visibility | null;
            }>;
          } | null;
        }> | null;
      };
    };
  };
};

export type DeleteUserReportCommentMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  commentID: Scalars['UUID']['input'];
}>;

export type DeleteUserReportCommentMutation = {
  __typename?: 'Mutation';
  deleteUserReportComment: UUID;
};

export type UserReportNotesPerMilestoneIdQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  reportID: Scalars['UUID']['input'];
}>;

export type UserReportNotesPerMilestoneIdQuery = {
  __typename?: 'Query';
  userReportNotesPerMilestoneID: Array<{
    __typename?: 'MilestoneNotesCount';
    milestoneID: UUID;
    noteCount: number;
  }>;
};

export type ItemBookmarksQueryVariables = Exact<{
  itemID: Scalars['UUID']['input'];
}>;

export type ItemBookmarksQuery = {
  __typename?: 'Query';
  itemBookmarks: Array<{
    __typename?: 'Bookmark';
    id: UUID;
    name?: string | null;
    thumbnail?: string | null;
    payload?: string | null;
  }>;
};

export type RemoveBookmarkFromItemMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  item: Scalars['UUID']['input'];
  bookmark: Scalars['UUID']['input'];
}>;

export type RemoveBookmarkFromItemMutation = {
  __typename?: 'Mutation';
  removeBookmarkFromItem: Array<{
    __typename?: 'Bookmark';
    id: UUID;
    name?: string | null;
    kind: number;
    payload?: string | null;
    thumbnail?: string | null;
  }>;
};

export type CreateOrUpdateBookmarkMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  bookmark: BookmarkInput;
}>;

export type CreateOrUpdateBookmarkMutation = {
  __typename?: 'Mutation';
  createOrUpdateBookmark: Array<{
    __typename?: 'Bookmark';
    id: UUID;
    kind: number;
    name?: string | null;
    payload?: string | null;
    thumbnail?: string | null;
  }>;
};

export type ItemAttachmentsQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type ItemAttachmentsQuery = {
  __typename?: 'Query';
  itemAttachments: Array<
    | {
        __typename?: 'Asset';
        id: UUID;
        name: string;
        size: Size;
        lastUpdated: Time;
        location: string;
        type?: string | null;
        derivations: Array<{
          __typename?: 'Derivation';
          id: UUID;
          kind: string;
          contents: Array<string>;
        }>;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
    | {
        __typename?: 'RemoteAsset';
        id: UUID;
        sourceAssetID: string;
        name: string;
        lastUpdated: Time;
        remoteLink: string;
        sourceSystem: SourceSystem;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
  >;
};

export type ProjectAssetsQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  sortBy?: InputMaybe<FilesSortBy>;
}>;

export type ProjectAssetsQuery = {
  __typename?: 'Query';
  projectAssets?: Array<{
    __typename?: 'Asset';
    id: UUID;
    name: string;
    size: Size;
    lastUpdated: Time;
    location: string;
    type?: string | null;
    derivations: Array<{
      __typename?: 'Derivation';
      id: UUID;
      kind: string;
      contents: Array<string>;
    }>;
    uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
  }> | null;
};

export type MilestoneAttachmentsQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type MilestoneAttachmentsQuery = {
  __typename?: 'Query';
  milestoneAttachments: Array<
    | {
        __typename?: 'Asset';
        id: UUID;
        name: string;
        size: Size;
        lastUpdated: Time;
        location: string;
        type?: string | null;
        derivations: Array<{
          __typename?: 'Derivation';
          id: UUID;
          kind: string;
          contents: Array<string>;
        }>;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
    | {
        __typename?: 'RemoteAsset';
        id: UUID;
        sourceAssetID: string;
        name: string;
        lastUpdated: Time;
        remoteLink: string;
        sourceSystem: SourceSystem;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
  >;
};

export type AssetQueryVariables = Exact<{
  assetID: Scalars['UUID']['input'];
}>;

export type AssetQuery = {
  __typename?: 'Query';
  asset?: {
    __typename?: 'Asset';
    id: UUID;
    name: string;
    size: Size;
    lastUpdated: Time;
    location: string;
    type?: string | null;
    derivations: Array<{
      __typename?: 'Derivation';
      id: UUID;
      kind: string;
      contents: Array<string>;
    }>;
    uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
  } | null;
};

export type AssetsQueryVariables = Exact<{
  assetIDs: Array<Scalars['UUID']['input']>;
}>;

export type AssetsQuery = {
  __typename?: 'Query';
  assets?: Array<{
    __typename?: 'Asset';
    id: UUID;
    name: string;
    size: Size;
    lastUpdated: Time;
    location: string;
    type?: string | null;
    derivations: Array<{
      __typename?: 'Derivation';
      id: UUID;
      kind: string;
      contents: Array<string>;
    }>;
    uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
  } | null> | null;
};

export type CompanyAssetQueryVariables = Exact<{
  assetID: Scalars['UUID']['input'];
}>;

export type CompanyAssetQuery = {
  __typename?: 'Query';
  companyAsset?: {
    __typename?: 'Asset';
    id: UUID;
    location: string;
    name: string;
    size: Size;
    lastUpdated: Time;
  } | null;
};

export type AttachToItemMutationVariables = Exact<{
  itemID: Scalars['UUID']['input'];
  assetInput?: InputMaybe<AssetInput>;
  remoteAssetInput?: InputMaybe<RemoteAssetInput>;
}>;

export type AttachToItemMutation = {
  __typename?: 'Mutation';
  attachToItem?: Array<
    | {
        __typename?: 'Asset';
        id: UUID;
        name: string;
        size: Size;
        lastUpdated: Time;
        location: string;
        type?: string | null;
        derivations: Array<{
          __typename?: 'Derivation';
          id: UUID;
          kind: string;
          contents: Array<string>;
        }>;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
    | {
        __typename?: 'RemoteAsset';
        id: UUID;
        name: string;
        lastUpdated: Time;
        remoteLink: string;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
    | null
  > | null;
};

export type AttachToMilestoneMutationVariables = Exact<{
  milestoneID: Scalars['UUID']['input'];
  assetInput?: InputMaybe<AssetInput>;
  remoteAssetInput?: InputMaybe<RemoteAssetInput>;
}>;

export type AttachToMilestoneMutation = {
  __typename?: 'Mutation';
  attachToMilestone?: Array<
    | {
        __typename?: 'Asset';
        id: UUID;
        name: string;
        size: Size;
        lastUpdated: Time;
        location: string;
        type?: string | null;
        derivations: Array<{
          __typename?: 'Derivation';
          id: UUID;
          kind: string;
          contents: Array<string>;
        }>;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
    | {
        __typename?: 'RemoteAsset';
        id: UUID;
        name: string;
        lastUpdated: Time;
        remoteLink: string;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
    | null
  > | null;
};

export type DetachFromItemMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  itemID: Scalars['UUID']['input'];
  assetID?: InputMaybe<Scalars['UUID']['input']>;
  remoteAssetID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type DetachFromItemMutation = {
  __typename?: 'Mutation';
  detachFromItem?: Array<
    | {
        __typename?: 'Asset';
        id: UUID;
        name: string;
        size: Size;
        lastUpdated: Time;
        location: string;
        type?: string | null;
        derivations: Array<{
          __typename?: 'Derivation';
          id: UUID;
          kind: string;
          contents: Array<string>;
        }>;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
    | {
        __typename?: 'RemoteAsset';
        id: UUID;
        name: string;
        lastUpdated: Time;
        remoteLink: string;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
    | null
  > | null;
};

export type DetachFromMilestoneMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  assetID?: InputMaybe<Scalars['UUID']['input']>;
  remoteAssetID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type DetachFromMilestoneMutation = {
  __typename?: 'Mutation';
  detachFromMilestone?: Array<
    | {
        __typename?: 'Asset';
        id: UUID;
        name: string;
        size: Size;
        lastUpdated: Time;
        location: string;
        type?: string | null;
        derivations: Array<{
          __typename?: 'Derivation';
          id: UUID;
          kind: string;
          contents: Array<string>;
        }>;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
    | {
        __typename?: 'RemoteAsset';
        id: UUID;
        name: string;
        lastUpdated: Time;
        remoteLink: string;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }
    | null
  > | null;
};

export type AssetThumbnailQueryVariables = Exact<{
  assetID: Scalars['UUID']['input'];
  dimension?: InputMaybe<ImageDimension>;
}>;

export type AssetThumbnailQuery = {
  __typename?: 'Query';
  asset?: {
    __typename?: 'Asset';
    id: UUID;
    location: string;
    name: string;
    thumbnailURL?: string | null;
  } | null;
};

export type CompanyUserQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyUserQuery = {
  __typename?: 'Query';
  companyUser?: {
    __typename?: 'CompanyUser';
    isIndependent: boolean;
    company?: {
      __typename?: 'Company';
      id: UUID;
      name: string;
      type: string;
      domain?: string | null;
      assetID?: UUID | null;
      deactivated: boolean;
    } | null;
    matchedCompany?: {
      __typename?: 'Company';
      id: UUID;
      name: string;
      type: string;
      domain?: string | null;
      assetID?: UUID | null;
    } | null;
    role?: { __typename?: 'PartialRole'; id: UUID; name: string } | null;
    user?: {
      __typename?: 'User';
      email: string;
      id: UUID;
      name: string;
      phone?: string | null;
      picture: string;
      didSetup?: Time | null;
      jobTitle: string;
      status: UserStatus;
      activeAt?: Time | null;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
  } | null;
};

export type CurrentCollaboratorQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type CurrentCollaboratorQuery = {
  __typename?: 'Query';
  currentCollaborator: {
    __typename?: 'Collaborator';
    allTrades: boolean;
    id: UUID;
    isProjectLead: boolean;
    responsibility?: string | null;
    role: {
      __typename?: 'Role';
      id: UUID;
      name: string;
      type: ProjectRoleType;
      hasTrade: boolean;
      permissionGroups: Array<{
        __typename?: 'PermissionGroup';
        type: PermissionGroupType;
        permissions: Array<{
          __typename?: 'Permission';
          id: UUID;
          level: PermissionLevel;
          resource: PermissionResource;
          actions: {
            __typename?: 'Actions';
            view: boolean;
            add: boolean;
            delete: boolean;
            edit: boolean;
          };
        }>;
      }>;
    };
    trades: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
      categorization?: {
        __typename?: 'Categorization';
        draft: boolean;
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
      } | null;
    }>;
    user: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
  };
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  currentUser: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  };
};

export type FeatureFlagQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  userEmail: Scalars['String']['input'];
}>;

export type FeatureFlagQuery = { __typename?: 'Query'; loadFeatureFlags: Array<FeatureFlag> };

export type CostTrendlineEventsQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  milestoneID: Scalars['UUID']['input'];
  costMode: CostMode;
  viewFilter: ViewFilter;
  columnInputs: Array<CostReportColumnInput>;
  quantityMagnitude?: InputMaybe<Scalars['Numeric']['input']>;
}>;

export type CostTrendlineEventsQuery = {
  __typename?: 'Query';
  costTrendlineEvents: {
    __typename?: 'CostTrendlineEvents';
    estimate: USCents;
    target: USCents;
    runningTotal: USCents;
    events: Array<{
      __typename?: 'CostTrendlineEvent';
      timestamp: Time;
      eventType: EventType;
      visible: boolean;
      cost: USCents;
      y0: USCents;
      y: USCents;
      yPoint: USCents;
      ids: Array<UUID>;
      item?: {
        __typename?: 'EventItem';
        id: UUID;
        name: string;
        number: string;
        status: Status;
        cost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        milestone?: { __typename?: 'Milestone'; id: UUID } | null;
      } | null;
      eventContent: {
        __typename?: 'EventContent';
        oldMilestone?: UUID | null;
        newMilestone?: UUID | null;
        oldOptionStatus?: Status | null;
        newOptionStatus?: Status | null;
        oldStatus?: Status | null;
        newStatus?: Status | null;
        oldCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        newCost?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        option?: {
          __typename?: 'ItemSummary';
          id: UUID;
          number: string;
          currentNumber: string;
          status: Status;
        } | null;
        options?: Array<{
          __typename?: 'ItemSummary';
          id: UUID;
          number: string;
          currentNumber: string;
          status: Status;
        }> | null;
        parent?: {
          __typename?: 'ItemSummary';
          id: UUID;
          number: string;
          currentNumber: string;
          status: Status;
        } | null;
      };
    }>;
  };
};

export type MilestonesCostTrendsDataQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  columns: Array<CostReportColumnInput>;
  intervalType: IntervalType;
  costMode: CostMode;
  viewFilter: ViewFilter;
  summary: Scalars['Boolean']['input'];
}>;

export type MilestonesCostTrendsDataQuery = {
  __typename?: 'Query';
  milestonesCostTrends: Array<{
    __typename?: 'Segment';
    milestone: {
      __typename?: 'Milestone';
      id: UUID;
      name: string;
      description: string;
      date?: Time | null;
      quantities?: Array<{
        __typename?: 'Quantity';
        id: UUID;
        magnitude: Numeric;
        estimateID: UUID;
        hasBreakdown: boolean;
        unit: {
          __typename?: 'Unit';
          abbreviationPlural: string;
          abbreviationSingular: string;
          description?: string | null;
          id: UUID;
          isDefault: boolean;
          isEnabled: boolean;
          name: string;
          type: UnitType;
        };
      } | null> | null;
    };
    points: Array<{
      __typename?: 'Point';
      dateStart: Time;
      dateEnd: Time;
      isVisible: boolean;
      values: Array<{
        __typename?: 'PointValue';
        type: CostReportColumnType;
        y: USCents;
        isChanged: boolean;
      }>;
    }>;
  }>;
};

export type ListCategoriesByIDsQueryVariables = Exact<{
  categorizationID: Scalars['UUID']['input'];
  categoryIDs: Array<Scalars['UUID']['input']>;
}>;

export type ListCategoriesByIDsQuery = {
  __typename?: 'Query';
  categories: Array<{
    __typename?: 'Category';
    id: UUID;
    parentID?: UUID | null;
    name: string;
    hasSubCategories: boolean;
    number: string;
    level: number;
    error?: string | null;
    categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
  }>;
};

export type AcceptTermsOfServiceMutationVariables = Exact<{ [key: string]: never }>;

export type AcceptTermsOfServiceMutation = {
  __typename?: 'Mutation';
  acceptTermsOfService: boolean;
};

export type AcceptedTermsOfServiceQueryVariables = Exact<{ [key: string]: never }>;

export type AcceptedTermsOfServiceQuery = {
  __typename?: 'Query';
  acceptedTermsOfService: {
    __typename?: 'TermsOfServiceCheckResult';
    accepted: boolean;
    dateEffective: string;
  };
};

export type ListCompanyFieldsFragment = {
  __typename?: 'Company';
  id: UUID;
  name: string;
  type: string;
  domain?: string | null;
  deactivated: boolean;
};

export type ListCompanyUserFieldsQueryFragment = {
  __typename?: 'CompanyUser';
  isIndependent: boolean;
  company?: {
    __typename?: 'Company';
    id: UUID;
    name: string;
    type: string;
    domain?: string | null;
    deactivated: boolean;
  } | null;
  matchedCompany?: {
    __typename?: 'Company';
    id: UUID;
    name: string;
    type: string;
    domain?: string | null;
    deactivated: boolean;
  } | null;
  role?: { __typename?: 'PartialRole'; id: UUID; name: string } | null;
  user?: {
    __typename?: 'User';
    email: string;
    id: UUID;
    name: string;
    phone?: string | null;
    picture: string;
    didSetup?: Time | null;
    jobTitle: string;
    status: UserStatus;
    activeAt?: Time | null;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
};

export type CommonCompanyFieldsFragment = {
  __typename?: 'Company';
  id: UUID;
  name: string;
  type: string;
  domain?: string | null;
  assetID?: UUID | null;
};

export type CreateCompanyMutationVariables = Exact<{
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  assetID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type CreateCompanyMutation = {
  __typename?: 'Mutation';
  createCompany: {
    __typename?: 'Company';
    id: UUID;
    name: string;
    type: string;
    domain?: string | null;
    assetID?: UUID | null;
  };
};

export type CreateCompanyUserMutationVariables = Exact<{
  companyID: Scalars['UUID']['input'];
  isRejected: Scalars['Boolean']['input'];
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateCompanyUserMutation = {
  __typename?: 'Mutation';
  createCompanyUser: {
    __typename?: 'CompanyUser';
    isIndependent: boolean;
    company?: {
      __typename?: 'Company';
      id: UUID;
      name: string;
      type: string;
      domain?: string | null;
      assetID?: UUID | null;
      deactivated: boolean;
    } | null;
    matchedCompany?: {
      __typename?: 'Company';
      id: UUID;
      name: string;
      type: string;
      domain?: string | null;
      assetID?: UUID | null;
    } | null;
    role?: { __typename?: 'PartialRole'; id: UUID; name: string } | null;
    user?: {
      __typename?: 'User';
      email: string;
      id: UUID;
      name: string;
      phone?: string | null;
      picture: string;
      didSetup?: Time | null;
      jobTitle: string;
      status: UserStatus;
      activeAt?: Time | null;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
  };
};

export type CompanyQueryVariables = Exact<{
  companyID: Scalars['UUID']['input'];
}>;

export type CompanyQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id: UUID;
    name: string;
    type: string;
    domain?: string | null;
    assetID?: UUID | null;
  } | null;
};

export type SendEmailVerificationMutationVariables = Exact<{
  url: Scalars['String']['input'];
}>;

export type SendEmailVerificationMutation = {
  __typename?: 'Mutation';
  sendEmailVerification: boolean;
};

export type VerifyEmailMutationVariables = Exact<{
  emailVerify: Scalars['String']['input'];
}>;

export type VerifyEmailMutation = { __typename?: 'Mutation'; verifyEmail: boolean };

export type UserDidSetupMutationVariables = Exact<{ [key: string]: never }>;

export type UserDidSetupMutation = {
  __typename?: 'Mutation';
  userDidSetup: { __typename?: 'User'; id: UUID; didSetup?: Time | null };
};

export type HubspotTokenQueryVariables = Exact<{ [key: string]: never }>;

export type HubspotTokenQuery = { __typename?: 'Query'; hubspotToken: string };

export type GetCategorizationMetadataQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetCategorizationMetadataQuery = {
  __typename?: 'Query';
  categorization: {
    __typename?: 'Categorization';
    id: UUID;
    name: string;
    description: string;
    levels: number;
    builtin: boolean;
    createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
  };
};

export type PreviewCategoriesQueryVariables = Exact<{
  assetID: Scalars['UUID']['input'];
}>;

export type PreviewCategoriesQuery = {
  __typename?: 'Query';
  previewImportedCategories?: {
    __typename?: 'CategoryMapResults';
    categoryMap: string;
    errors: string;
    metadata?: {
      __typename?: 'CategorizationImportMetadata';
      name: string;
      description?: string | null;
      columnLevel1?: string | null;
      columnLevel2?: string | null;
      columnLevel3?: string | null;
      columnLevel4?: string | null;
    } | null;
  } | null;
};

export type CreateCategoriesFromImportMutationVariables = Exact<{
  categorizationID: Scalars['UUID']['input'];
  assetID: Scalars['UUID']['input'];
  categories: Array<CategoryMapInput>;
  metadata: CategorizationImportMetadataInput;
  projectID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type CreateCategoriesFromImportMutation = {
  __typename?: 'Mutation';
  createCategoriesFromImport?: {
    __typename?: 'CreateCategoryResult';
    error: string;
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      number: string;
      name: string;
      level: number;
    }>;
  } | null;
};

export type ReplaceCategoriesMutationVariables = Exact<{
  categorizationID: Scalars['UUID']['input'];
  categories: Array<CategoryMapInput>;
  metadata?: InputMaybe<CategorizationImportMetadataInput>;
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  assetID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type ReplaceCategoriesMutation = {
  __typename?: 'Mutation';
  replaceCategories?: {
    __typename?: 'ReplaceCategoriesPayload';
    deleted: Array<UUID>;
    categorization?: { __typename?: 'Categorization'; id: UUID; levels: number } | null;
    updated: Array<{ __typename?: 'Category'; id: UUID }>;
  } | null;
};

export type UpsertCategorizationImportMetadataMutationVariables = Exact<{
  categorizationID: Scalars['UUID']['input'];
  metadata: CategorizationImportMetadataInput;
}>;

export type UpsertCategorizationImportMetadataMutation = {
  __typename?: 'Mutation';
  upsertCategorizationImportMetadata: {
    __typename?: 'CategorizationImportMetadata';
    name: string;
    description?: string | null;
    columnLevel1?: string | null;
    columnLevel2?: string | null;
    columnLevel3?: string | null;
    columnLevel4?: string | null;
  };
};

export type CreateCategorizationMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateCategorizationMutation = {
  __typename?: 'Mutation';
  createCategorization:
    | {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
      }
    | { __typename?: 'CreateCategorizationErrors'; userErrors: Array<string> };
};

export type RemoveCategorizationsMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  categorizationIDs: Array<Scalars['UUID']['input']>;
}>;

export type RemoveCategorizationsMutation = {
  __typename?: 'Mutation';
  removeCategorizations?: {
    __typename?: 'RemoveCategorizationsPayload';
    removed: Array<UUID>;
  } | null;
};

export type UpdateCategorizationMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  projectID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type UpdateCategorizationMutation = {
  __typename?: 'Mutation';
  editCategorization: {
    __typename?: 'Categorization';
    id: UUID;
    name: string;
    description: string;
    modifiedAt?: Time | null;
    modifiedBy?: { __typename?: 'User'; id: UUID } | null;
  };
};

export type ReportItemAssistExperimentRequestMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  itemID: Scalars['UUID']['input'];
  additionalInfo: Scalars['String']['input'];
  connectWithBPM: Scalars['Boolean']['input'];
}>;

export type ReportItemAssistExperimentRequestMutation = {
  __typename?: 'Mutation';
  reportItemAssistExperimentRequest: boolean;
};

export type DeprecateItemMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  itemID: Scalars['UUID']['input'];
}>;

export type DeprecateItemMutation = { __typename?: 'Mutation'; deprecateItem: UUID };

export type AnalyticsPropertiesQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['UUID']['input']>;
  previewRoleId?: InputMaybe<Scalars['UUID']['input']>;
  previewUserId?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type AnalyticsPropertiesQuery = {
  __typename?: 'Query';
  analyticsProperties: {
    __typename?: 'AnalyticsProperties';
    userProperties: {
      __typename?: 'UserAnalyticsProperties';
      userEmail: string;
      userId: UUID;
      userIsEmailVerified: boolean;
      userJobTitle: string;
      userName: string;
      userSignUpAt?: Time | null;
    };
    companyProperties: {
      __typename?: 'CompanyAnalyticsProperties';
      companyId?: UUID | null;
      companyName?: string | null;
      companyType?: string | null;
      companyDomain?: string | null;
      isIndependent: boolean;
    };
    projectProperties?: {
      __typename?: 'ProjectAnalyticsProperties';
      projectId: UUID;
      projectLocation: string;
      projectName: string;
      projectStatus: string;
    } | null;
    permissionsProperties?: {
      __typename?: 'PermissionsAnalyticsProperties';
      previewMode: boolean;
      previewRole?: string | null;
      previewRoleId?: UUID | null;
      previewUserEmail?: string | null;
      previewUserId?: UUID | null;
      userRole: string;
    } | null;
    groupProperties: {
      __typename?: 'GroupAnalyticsProperties';
      groupId: UUID;
      userId?: UUID | null;
      projectId?: UUID | null;
      companyId?: UUID | null;
    };
  };
};

export type CurrentCompanyRoleQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentCompanyRoleQuery = {
  __typename?: 'Query';
  currentCompanyRole?: {
    __typename?: 'CompanyRole';
    id: UUID;
    name: string;
    permissionGroups: Array<{
      __typename?: 'PermissionGroup';
      type: PermissionGroupType;
      permissions: Array<{
        __typename?: 'Permission';
        id: UUID;
        level: PermissionLevel;
        resource: PermissionResource;
        actions: {
          __typename?: 'Actions';
          view: boolean;
          add: boolean;
          delete: boolean;
          edit: boolean;
        };
      }>;
    }>;
  } | null;
};

export type ListProjectCategorizationsQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ListProjectCategorizationsQuery = {
  __typename?: 'Query';
  projectCategorizations: Array<{
    __typename?: 'ProjectCategorization';
    projectID: UUID;
    ordering?: number | null;
    disabled: boolean;
    categorization: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      description: string;
      levels: number;
      builtin: boolean;
      createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      importMetadata?: {
        __typename?: 'CategorizationImportMetadata';
        columnLevel1?: string | null;
        columnLevel2?: string | null;
        columnLevel3?: string | null;
        columnLevel4?: string | null;
      } | null;
    };
  }>;
};

export type ProjectCategorizationsMetadataQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ProjectCategorizationsMetadataQuery = {
  __typename?: 'Query';
  projectCategorizations: Array<{
    __typename?: 'ProjectCategorization';
    projectID: UUID;
    ordering?: number | null;
    disabled: boolean;
    categorization: {
      __typename?: 'Categorization';
      id: UUID;
      name: string;
      description: string;
      levels: number;
      builtin: boolean;
      createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      importMetadata?: {
        __typename?: 'CategorizationImportMetadata';
        columnLevel1?: string | null;
        columnLevel2?: string | null;
        columnLevel3?: string | null;
        columnLevel4?: string | null;
      } | null;
      exampleCategories?: Array<{
        __typename?: 'Category';
        id: UUID;
        number: string;
        name: string;
        level: number;
      }> | null;
    };
  }>;
};

export type StandardCategorizationsQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type StandardCategorizationsQuery = {
  __typename?: 'Query';
  standardCategorizations: Array<{
    __typename?: 'StandardCategorization';
    projectID: UUID;
    ordering?: number | null;
    disabled: boolean;
    categorization: {
      __typename?: 'Categorization';
      builtin: boolean;
      id: UUID;
      name: string;
      description: string;
      levels: number;
      createdFrom?: { __typename?: 'Categorization'; id: UUID; deprecated: boolean } | null;
    };
  }>;
};

export type CategoryCombinationsQueryVariables = Exact<{
  searches: Array<CategorySearch>;
  estimateID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type CategoryCombinationsQuery = {
  __typename?: 'Query';
  categoryCombinations: Array<{
    __typename?: 'CategoryCombination';
    categories: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      hasSubCategories: boolean;
      number: string;
      level: number;
      error?: string | null;
      categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    }>;
  }>;
};

export type CategoryCombinationCountsQueryVariables = Exact<{
  searches: Array<CategorySearch>;
}>;

export type CategoryCombinationCountsQuery = {
  __typename?: 'Query';
  categoryCombinationCounts: Array<{
    __typename?: 'CategorizationCategoryCount';
    categorizationID: UUID;
    count: number;
  }>;
};

export type GetProjectPropsQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetProjectPropsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: UUID;
    description: string;
    descriptionStyled: string;
    name: string;
    code?: string | null;
    hasAccess: boolean;
    location: string;
    lat?: number | null;
    lon?: number | null;
    thumbnail: string;
    templateEstimateID: UUID;
    lastUpdated: Time;
    activeMilestone: { __typename?: 'Milestone'; id: UUID; name: string };
    status: {
      __typename?: 'ProjectStatus';
      id: UUID;
      name: string;
      type: string;
      description: string;
    };
    team?: {
      __typename?: 'Team';
      owner?: { __typename?: 'Organization'; name?: string | null; picture?: string | null } | null;
      generalContractor?: {
        __typename?: 'Organization';
        name?: string | null;
        picture?: string | null;
      } | null;
      designTeam?: {
        __typename?: 'Organization';
        name?: string | null;
        picture?: string | null;
      } | null;
    } | null;
    type: { __typename?: 'ProjectType'; id: UUID; name: string };
    projectDeliveryType?: {
      __typename?: 'ProjectDeliveryType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    locationDetails?: {
      __typename?: 'LocationDetails';
      name: string;
      lat: number;
      lon: number;
      city: string;
      state: string;
      stateLong: string;
      country: string;
    } | null;
    orgNodes: Array<{ __typename?: 'OrgNode'; id: UUID; name: string }>;
  } | null;
};

export type CompanyProjectTypesQueryVariables = Exact<{
  companyID: Scalars['UUID']['input'];
  includeHidden: Scalars['Boolean']['input'];
}>;

export type CompanyProjectTypesQuery = {
  __typename?: 'Query';
  companyProjectTypes: Array<{
    __typename?: 'ProjectType';
    id: UUID;
    name: string;
    parentID?: UUID | null;
    hidden?: boolean | null;
  }>;
};

export type GetProjectsWithTypeQueryVariables = Exact<{
  typeID: Scalars['UUID']['input'];
}>;

export type GetProjectsWithTypeQuery = {
  __typename?: 'Query';
  getProjectsWithType: Array<{
    __typename?: 'ProjectInfoType';
    name: string;
    projectID: UUID;
    typeID: UUID;
    variant: ProjectVariant;
  }>;
};

export type GetProjectTypesQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type GetProjectTypesQuery = {
  __typename?: 'Query';
  getProjectTypes: Array<{
    __typename?: 'ProjectType';
    id: UUID;
    name: string;
    parentID?: UUID | null;
    hidden?: boolean | null;
  }>;
};

export type ProjectDeliveryTypesQueryVariables = Exact<{ [key: string]: never }>;

export type ProjectDeliveryTypesQuery = {
  __typename?: 'Query';
  projectDeliveryTypes: Array<{
    __typename?: 'ProjectDeliveryType';
    id: UUID;
    name: string;
    abbreviation: string;
  }>;
};

export type MilestoneCostReportsQueryVariables = Exact<{
  milestones: Array<CostReportMilestoneInput>;
  columns: Array<CostReportColumnInput>;
  groupByItems: Array<GroupByItem>;
  costMode: CostMode;
  projectID: Scalars['UUID']['input'];
  viewFilter: ViewFilter;
}>;

export type MilestoneCostReportsQuery = {
  __typename?: 'Query';
  milestoneCostReports: Array<{
    __typename?: 'MilestoneCostReports';
    milestoneID: UUID;
    costReportColumns: Array<{
      __typename?: 'CostReportColumn';
      type: CostReportColumnType;
      columnKey: CostReportColumnKey;
      isCategorized?: boolean | null;
      report?: {
        __typename?: 'CostReport';
        numeric: Numeric;
        type: CostReportType;
        directCostRange:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        directCostSegmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        projectTotalRange?:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents }
          | null;
        projectTotalSegmented?: {
          __typename?: 'AddDeductCost';
          adds: USCents;
          deducts: USCents;
        } | null;
        range:
          | { __typename?: 'CostRange'; min: USCents; max: USCents }
          | { __typename?: 'CostScalar'; value: USCents };
        segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        subtotals: Array<{
          __typename?: 'CostReportItem';
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          subtotalValue:
            | {
                __typename?: 'SubtotalAddsDeducts';
                directCostSegmented: {
                  __typename?: 'PartialAddDeductCost';
                  adds?: USCents | null;
                  deducts?: USCents | null;
                };
                segmented: {
                  __typename?: 'PartialAddDeductCost';
                  adds?: USCents | null;
                  deducts?: USCents | null;
                };
              }
            | {
                __typename?: 'SubtotalCost';
                directCostRange:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
                range:
                  | { __typename?: 'CostRange'; min: USCents; max: USCents }
                  | { __typename?: 'CostScalar'; value: USCents };
              }
            | { __typename?: 'SubtotalNumeric'; value: Numeric };
        }>;
        categorizedItemCosts: Array<{
          __typename?: 'CategorizedItemCost';
          id: UUID;
          milestoneId: UUID;
          name: string;
          number: string;
          parentId?: UUID | null;
          status?: Status | null;
          canViewCosts: boolean;
          categories: Array<{
            __typename?: 'Category';
            id: UUID;
            name: string;
            number: string;
            error?: string | null;
            level: number;
            hasSubCategories: boolean;
            categorization?: {
              __typename?: 'Categorization';
              id: UUID;
              name: string;
              builtin: boolean;
              description: string;
              draft: boolean;
              levels: number;
              deprecated: boolean;
            } | null;
            levels: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              hasSubCategories: boolean;
              level: number;
            }>;
          }>;
          directCostRange:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          range:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          itemContributionLines?: Array<{
            __typename?: 'ItemContributionLines';
            lineID?: UUID | null;
            lineDescription?: string | null;
            categories: Array<{
              __typename?: 'Category';
              id: UUID;
              name: string;
              number: string;
              error?: string | null;
              level: number;
              hasSubCategories: boolean;
              categorization?: {
                __typename?: 'Categorization';
                id: UUID;
                name: string;
                builtin: boolean;
                description: string;
                draft: boolean;
                levels: number;
                deprecated: boolean;
              } | null;
              levels: Array<{
                __typename?: 'Category';
                id: UUID;
                name: string;
                number: string;
                hasSubCategories: boolean;
                level: number;
              }>;
            }>;
            directCostRange:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
            range:
              | { __typename?: 'CostRange'; min: USCents; max: USCents }
              | { __typename?: 'CostScalar'; value: USCents };
          } | null> | null;
        }>;
        markupContributions: Array<{
          __typename?: 'MarkupContribution';
          isIncorporated: boolean;
          isOwnerCost: boolean;
          markupId: UUID;
          markupName: string;
          displayType: MarkupDisplayType;
          range:
            | { __typename?: 'CostRange'; min: USCents; max: USCents }
            | { __typename?: 'CostScalar'; value: USCents };
          segmented: { __typename?: 'AddDeductCost'; adds: USCents; deducts: USCents };
        }>;
      } | null;
      quantity?: {
        __typename?: 'Quantity';
        id: UUID;
        magnitude: Numeric;
        estimateID: UUID;
        hasBreakdown: boolean;
        unit: {
          __typename?: 'Unit';
          abbreviationPlural: string;
          abbreviationSingular: string;
          description?: string | null;
          id: UUID;
          isDefault: boolean;
          isEnabled: boolean;
          name: string;
          type: UnitType;
        };
      } | null;
    }>;
  }>;
};

export type CreateProjectMutationVariables = Exact<{
  name: Scalars['String']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  milestone: Scalars['String']['input'];
  milestoneDate: Scalars['Time']['input'];
  milestoneDesignPhaseID: Scalars['UUID']['input'];
  typeID?: InputMaybe<Scalars['UUID']['input']>;
  location: Scalars['String']['input'];
  lat?: InputMaybe<Scalars['Float']['input']>;
  lon?: InputMaybe<Scalars['Float']['input']>;
  statusID: Scalars['UUID']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  templateID?: InputMaybe<Scalars['UUID']['input']>;
  projectDeliveryTypeID?: InputMaybe<Scalars['UUID']['input']>;
  locationDetails: LocationDetailsInput;
  orgNodeIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject?: {
    __typename?: 'Project';
    id: UUID;
    description: string;
    descriptionStyled: string;
    name: string;
    location: string;
    thumbnail: string;
    activeMilestone: { __typename?: 'Milestone'; id: UUID; name: string };
    status: {
      __typename?: 'ProjectStatus';
      id: UUID;
      name: string;
      type: string;
      description: string;
    };
    team?: {
      __typename?: 'Team';
      owner?: { __typename?: 'Organization'; name?: string | null; picture?: string | null } | null;
      generalContractor?: {
        __typename?: 'Organization';
        name?: string | null;
        picture?: string | null;
      } | null;
      designTeam?: {
        __typename?: 'Organization';
        name?: string | null;
        picture?: string | null;
      } | null;
    } | null;
    type: { __typename?: 'ProjectType'; id: UUID; name: string };
    milestones: Array<{
      __typename?: 'Milestone';
      id: UUID;
      name: string;
      description: string;
      descriptionStyled: string;
      deletable: boolean;
      date?: Time | null;
      isDraft: boolean;
      itemsCount: number;
      draftItemsCount: number;
      assetCount: number;
      remoteAssetCount: number;
      activeEstimateID?: UUID | null;
      activeEstimateDraftID?: UUID | null;
      budgetID?: UUID | null;
      budgetDraftID?: UUID | null;
      buckets?: Array<{
        __typename?: 'Bucket';
        date?: Time | null;
        id: UUID;
        name: string;
        itemsCount: number;
      }> | null;
      createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      designPhase?: {
        __typename?: 'DesignPhaseType';
        id: UUID;
        name: string;
        abbreviation: string;
      } | null;
      timelinePhase?: {
        __typename?: 'TimelineActivity';
        id: UUID;
        name: string;
        startDate: Time;
        endDate?: Time | null;
      } | null;
      importedEstimates: Array<{
        __typename?: 'Asset';
        id: UUID;
        name: string;
        size: Size;
        lastUpdated: Time;
        location: string;
        type?: string | null;
        derivations: Array<{
          __typename?: 'Derivation';
          id: UUID;
          kind: string;
          contents: Array<string>;
        }>;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }>;
      quantities?: Array<{
        __typename?: 'Quantity';
        id: UUID;
        magnitude: Numeric;
        estimateID: UUID;
        hasBreakdown: boolean;
        unit: {
          __typename?: 'Unit';
          abbreviationPlural: string;
          abbreviationSingular: string;
          description?: string | null;
          id: UUID;
          isDefault: boolean;
          isEnabled: boolean;
          name: string;
          type: UnitType;
        };
      } | null> | null;
    }>;
    roles: Array<{
      __typename?: 'Role';
      id: UUID;
      name: string;
      type: ProjectRoleType;
      hasTrade: boolean;
      permissionGroups: Array<{
        __typename?: 'PermissionGroup';
        type: PermissionGroupType;
        permissions: Array<{
          __typename?: 'Permission';
          id: UUID;
          level: PermissionLevel;
          resource: PermissionResource;
          actions: {
            __typename?: 'Actions';
            view: boolean;
            add: boolean;
            delete: boolean;
            edit: boolean;
          };
        }>;
      }>;
    }>;
    projectDeliveryType?: {
      __typename?: 'ProjectDeliveryType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    locationDetails?: {
      __typename?: 'LocationDetails';
      name: string;
      lat: number;
      lon: number;
      city: string;
      state: string;
      stateLong: string;
      country: string;
    } | null;
  } | null;
};

export type ListSubcategoriesQueryVariables = Exact<{
  search: SubcategorySearch;
}>;

export type ListSubcategoriesQuery = {
  __typename?: 'Query';
  subcategory: Array<{
    __typename?: 'Category';
    id: UUID;
    parentID?: UUID | null;
    name: string;
    hasSubCategories: boolean;
    number: string;
    level: number;
    error?: string | null;
    categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
    levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
  }>;
};

export type LoadUserQueryVariables = Exact<{
  userEmail: Scalars['String']['input'];
}>;

export type LoadUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    status: UserStatus;
    email: string;
    id: UUID;
    jobTitle: string;
    name: string;
    phone?: string | null;
    picture: string;
    pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
  } | null;
};

export type LoadUserNotificationSettingQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type LoadUserNotificationSettingQuery = {
  __typename?: 'Query';
  userNotificationSetting: {
    __typename?: 'UserNotificationSetting';
    assignments: boolean;
    channel: NotificationChannel;
    comments: CommentNotificationSetting;
    isGlobalSubscribed: boolean;
    mentions: boolean;
    newItems: boolean;
    shareScenarios: boolean;
  };
};

export type LoadUserInAppNotificationSettingQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type LoadUserInAppNotificationSettingQuery = {
  __typename?: 'Query';
  userInAppNotificationSetting: {
    __typename?: 'UserNotificationSetting';
    assignments: boolean;
    channel: NotificationChannel;
    comments: CommentNotificationSetting;
    isGlobalSubscribed: boolean;
    mentions: boolean;
    newItems: boolean;
    shareScenarios: boolean;
  };
};

export type ToggleEventDataVisibilityMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  visible: Scalars['Boolean']['input'];
  events: Array<Scalars['UUID']['input']>;
}>;

export type ToggleEventDataVisibilityMutation = {
  __typename?: 'Mutation';
  toggleEventDataVisibility: {
    __typename?: 'ToggleEventDataResult';
    visible: boolean;
    events: Array<UUID>;
  };
};

export type LoadProjectRolesQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
}>;

export type LoadProjectRolesQuery = {
  __typename?: 'Query';
  projectRoles: Array<{
    __typename?: 'Role';
    id: UUID;
    name: string;
    type: ProjectRoleType;
    hasTrade: boolean;
    permissionGroups: Array<{
      __typename?: 'PermissionGroup';
      type: PermissionGroupType;
      permissions: Array<{
        __typename?: 'Permission';
        id: UUID;
        level: PermissionLevel;
        resource: PermissionResource;
        actions: {
          __typename?: 'Actions';
          view: boolean;
          add: boolean;
          delete: boolean;
          edit: boolean;
        };
      }>;
    }>;
  }>;
};

export type LoadProjectRoleQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
}>;

export type LoadProjectRoleQuery = {
  __typename?: 'Query';
  projectRole: {
    __typename?: 'Role';
    id: UUID;
    name: string;
    type: ProjectRoleType;
    hasTrade: boolean;
    permissionGroups: Array<{
      __typename?: 'PermissionGroup';
      type: PermissionGroupType;
      permissions: Array<{
        __typename?: 'Permission';
        id: UUID;
        level: PermissionLevel;
        resource: PermissionResource;
        actions: {
          __typename?: 'Actions';
          view: boolean;
          add: boolean;
          delete: boolean;
          edit: boolean;
        };
      }>;
    }>;
  };
};

export type ResendInviteMutationVariables = Exact<{
  inviteeUserID: Scalars['UUID']['input'];
  projectID?: InputMaybe<Scalars['UUID']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
}>;

export type ResendInviteMutation = {
  __typename?: 'Mutation';
  resendInvite: {
    __typename?: 'CompanyUser';
    isIndependent: boolean;
    company?: {
      __typename?: 'Company';
      id: UUID;
      name: string;
      type: string;
      domain?: string | null;
      assetID?: UUID | null;
      deactivated: boolean;
    } | null;
    matchedCompany?: {
      __typename?: 'Company';
      id: UUID;
      name: string;
      type: string;
      domain?: string | null;
      assetID?: UUID | null;
    } | null;
    role?: { __typename?: 'PartialRole'; id: UUID; name: string } | null;
    user?: {
      __typename?: 'User';
      email: string;
      id: UUID;
      name: string;
      phone?: string | null;
      picture: string;
      didSetup?: Time | null;
      jobTitle: string;
      status: UserStatus;
      activeAt?: Time | null;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    } | null;
  };
};

export type UpdateProjectMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  activeMilestoneID?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionStyled?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lon?: InputMaybe<Scalars['Float']['input']>;
  statusID?: InputMaybe<Scalars['UUID']['input']>;
  team?: InputMaybe<TeamInput>;
  projectDeliveryTypeID?: InputMaybe<Scalars['UUID']['input']>;
  locationDetails?: InputMaybe<LocationDetailsInput>;
  orgNodeIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
}>;

export type UpdateProjectMutation = {
  __typename?: 'Mutation';
  updateProject?: {
    __typename?: 'Project';
    id: UUID;
    description: string;
    descriptionStyled: string;
    name: string;
    location: string;
    thumbnail: string;
    activeMilestone: { __typename?: 'Milestone'; id: UUID; name: string };
    status: {
      __typename?: 'ProjectStatus';
      id: UUID;
      name: string;
      type: string;
      description: string;
    };
    team?: {
      __typename?: 'Team';
      owner?: { __typename?: 'Organization'; name?: string | null; picture?: string | null } | null;
      generalContractor?: {
        __typename?: 'Organization';
        name?: string | null;
        picture?: string | null;
      } | null;
      designTeam?: {
        __typename?: 'Organization';
        name?: string | null;
        picture?: string | null;
      } | null;
    } | null;
    type: { __typename?: 'ProjectType'; id: UUID; name: string };
    milestones: Array<{
      __typename?: 'Milestone';
      id: UUID;
      name: string;
      description: string;
      descriptionStyled: string;
      deletable: boolean;
      date?: Time | null;
      isDraft: boolean;
      itemsCount: number;
      draftItemsCount: number;
      assetCount: number;
      remoteAssetCount: number;
      activeEstimateID?: UUID | null;
      activeEstimateDraftID?: UUID | null;
      budgetID?: UUID | null;
      budgetDraftID?: UUID | null;
      buckets?: Array<{
        __typename?: 'Bucket';
        date?: Time | null;
        id: UUID;
        name: string;
        itemsCount: number;
      }> | null;
      createdBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      designPhase?: {
        __typename?: 'DesignPhaseType';
        id: UUID;
        name: string;
        abbreviation: string;
      } | null;
      timelinePhase?: {
        __typename?: 'TimelineActivity';
        id: UUID;
        name: string;
        startDate: Time;
        endDate?: Time | null;
      } | null;
      importedEstimates: Array<{
        __typename?: 'Asset';
        id: UUID;
        name: string;
        size: Size;
        lastUpdated: Time;
        location: string;
        type?: string | null;
        derivations: Array<{
          __typename?: 'Derivation';
          id: UUID;
          kind: string;
          contents: Array<string>;
        }>;
        uploadedBy?: { __typename?: 'User'; id: UUID; name: string; email: string } | null;
      }>;
      quantities?: Array<{
        __typename?: 'Quantity';
        id: UUID;
        magnitude: Numeric;
        estimateID: UUID;
        hasBreakdown: boolean;
        unit: {
          __typename?: 'Unit';
          abbreviationPlural: string;
          abbreviationSingular: string;
          description?: string | null;
          id: UUID;
          isDefault: boolean;
          isEnabled: boolean;
          name: string;
          type: UnitType;
        };
      } | null> | null;
    }>;
    roles: Array<{
      __typename?: 'Role';
      id: UUID;
      name: string;
      type: ProjectRoleType;
      hasTrade: boolean;
      permissionGroups: Array<{
        __typename?: 'PermissionGroup';
        type: PermissionGroupType;
        permissions: Array<{
          __typename?: 'Permission';
          id: UUID;
          level: PermissionLevel;
          resource: PermissionResource;
          actions: {
            __typename?: 'Actions';
            view: boolean;
            add: boolean;
            delete: boolean;
            edit: boolean;
          };
        }>;
      }>;
    }>;
    projectDeliveryType?: {
      __typename?: 'ProjectDeliveryType';
      id: UUID;
      name: string;
      abbreviation: string;
    } | null;
    locationDetails?: {
      __typename?: 'LocationDetails';
      name: string;
      lat: number;
      lon: number;
      city: string;
      state: string;
      stateLong: string;
      country: string;
    } | null;
  } | null;
};

export type ListCustomCategorizationsQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type ListCustomCategorizationsQuery = {
  __typename?: 'Query';
  customCategorizations: Array<{
    __typename?: 'Categorization';
    id: UUID;
    name: string;
    builtin: boolean;
    content?: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }> | null;
  }>;
};

export type ListDraftCategoriesQueryVariables = Exact<{
  estimateID: Scalars['UUID']['input'];
}>;

export type ListDraftCategoriesQuery = {
  __typename?: 'Query';
  draftCategorizations: Array<{
    __typename?: 'Categorization';
    id: UUID;
    name: string;
    description: string;
    levels: number;
    builtin: boolean;
  }>;
};

export type ModuleEntitlementsQueryVariables = Exact<{
  companyID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type ModuleEntitlementsQuery = {
  __typename?: 'Query';
  loadModuleEntitlementFlags: Array<ModuleEntitlementFlag>;
};

export type EstimateQueryVariables = Exact<{
  estimateID: Scalars['UUID']['input'];
  costMode: CostMode;
  viewFilter?: InputMaybe<ViewFilter>;
  estimateSortBy?: InputMaybe<EstimateSortBy>;
  pagination?: InputMaybe<Pagination>;
}>;

export type EstimateQuery = {
  __typename?: 'Query';
  estimate?: {
    __typename?: 'Estimate';
    id: UUID;
    name: string;
    subtotal: Numeric;
    markupSubtotal: USCents;
    drawSubtotal: USCents;
    inheritedSubtotal: USCents;
    inheritedOwnerCostMarkupsSubtotal: USCents;
    asset?: UUID | null;
    totalType: EstimateTotalType;
    isRunningTotal: boolean;
    lines: Array<{
      __typename?: 'Line';
      id: UUID;
      estimateId: UUID;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      cells: Array<{
        __typename?: 'Cell';
        estimateId: UUID;
        field: UUID;
        line: UUID;
        error?: string | null;
        value?:
          | {
              __typename?: 'CategoryCell';
              search: string;
              category?: {
                __typename?: 'Category';
                id: UUID;
                parentID?: UUID | null;
                name: string;
                hasSubCategories: boolean;
                number: string;
                level: number;
                error?: string | null;
                categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
                levels: Array<{ __typename?: 'Category'; id: UUID; name: string; number: string }>;
              } | null;
            }
          | { __typename?: 'RegularCell'; string: string }
          | {
              __typename?: 'SourceCell';
              sourceIDs?: {
                __typename?: 'SourceIDs';
                estimateID: UUID;
                itemID?: UUID | null;
                lineID?: UUID | null;
                markupID?: UUID | null;
                milestoneID: UUID;
              } | null;
            }
          | null;
      }>;
    }>;
    fields: Array<{
      __typename?: 'Field';
      id: UUID;
      name: string;
      type: string;
      group: string;
      categorization?: {
        __typename?: 'Categorization';
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
        createdFrom?: { __typename?: 'Categorization'; id: UUID } | null;
      } | null;
      errors: Array<{
        __typename?: 'FieldError';
        type: FieldErrorType;
        count: number;
        categoryErrorIndicies: Array<{
          __typename?: 'CategoryErrorIndicies';
          categoryNumber: string;
          indicies: Array<number>;
        }>;
      }>;
    }>;
    incorporatedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    contingencyDraws?: Array<{
      __typename?: 'ItemDrawInfo';
      id: UUID;
      type: MarkupDisplayType;
      name: string;
      error: string;
      draw: USCents;
      drawFromID?: UUID | null;
      isDrawingFullAmount: boolean;
    }> | null;
    inheritedMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    inheritedOwnerCostMarkups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
    markups: Array<{
      __typename?: 'Markup';
      id: UUID;
      estimateId: UUID;
      name: string;
      percent?: number | null;
      percentScale?: number | null;
      sourceFilterIDs: Array<UUID>;
      sourceFilterIDsWithoutS2Reference: Array<UUID>;
      total: USCents;
      type: MarkupType;
      displayType: MarkupDisplayType;
      drawFromID?: UUID | null;
      value?: Numeric | null;
      disabled: boolean;
      orderingNumerator: Ordering;
      orderingDenominator: Ordering;
      costTypeFilters?: Array<CostType> | null;
      shouldExcludeFromAllocation: boolean;
      shouldNotApplyCategoryFiltersToOtherMarkups: boolean;
      errors: Array<{ __typename?: 'MarkupError'; field: string; error: string }>;
      markupReference?: {
        __typename?: 'MarkupReference';
        appliesTo: Array<string>;
        item?: {
          __typename?: 'MarkupItemReference';
          number: string;
          name: string;
          isDeprecated: boolean;
        } | null;
      } | null;
      categoryFilters: Array<{
        __typename?: 'Category';
        id: UUID;
        name: string;
        number: string;
        level: number;
        error?: string | null;
        categorization?: { __typename?: 'Categorization'; id: UUID; name: string } | null;
      }>;
      source?: {
        __typename?: 'SourceIDs';
        itemID?: UUID | null;
        estimateID: UUID;
        milestoneID: UUID;
        lineID?: UUID | null;
        markupID?: UUID | null;
      } | null;
    }>;
  } | null;
};

export type ProjectCompaniesQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type ProjectCompaniesQuery = {
  __typename?: 'Query';
  projectCompanies: Array<{
    __typename?: 'ProjectCompany';
    role?: string | null;
    company: {
      __typename?: 'Company';
      id: UUID;
      domain?: string | null;
      name: string;
      type: string;
      asset?: { __typename?: 'Asset'; id: UUID; name: string } | null;
    };
  }>;
};

export type CreateItemIntegrationObjectMutationVariables = Exact<{
  sourceSystem: SourceSystem;
  sourceID: Scalars['UUID']['input'];
  sourceProjectID: Scalars['String']['input'];
  sourceCompanyID?: InputMaybe<Scalars['String']['input']>;
  itemID: Scalars['UUID']['input'];
  input: CreateItemIntegrationObjectInput;
}>;

export type CreateItemIntegrationObjectMutation = {
  __typename?: 'Mutation';
  createItemIntegrationObject: {
    __typename?: 'CreateItemIntegrationObject';
    error?: string | null;
    procoreChangeEvent?: {
      __typename?: 'ProcoreChangeEvent';
      integrationObjectID: string;
      number: number;
      alphanumericNumber: string;
      title: string;
      description: string;
      status: string;
      eventScope: string;
      eventType: string;
      createdAt: string;
      updatedAt: string;
      changeReason: string;
      cost: USCents;
      isDeleted: boolean;
    } | null;
    autodeskPotentialChangeOrder?: {
      __typename?: 'AutodeskPotentialChangeOrder';
      integrationObjectID: string;
      number: number;
      title: string;
      description: string;
      eventType: string;
      createdAt: string;
      updatedAt: string;
      cost: USCents;
    } | null;
  };
};

export type ItemIntegrationObjectsCountQueryVariables = Exact<{
  itemID: Scalars['UUID']['input'];
}>;

export type ItemIntegrationObjectsCountQuery = {
  __typename?: 'Query';
  itemIntegrationObjectsCount: number;
};

export type ItemIntegrationObjectsQueryVariables = Exact<{
  sourceSystem: SourceSystem;
  sourceID: Scalars['UUID']['input'];
  projectID: Scalars['UUID']['input'];
  itemID: Scalars['UUID']['input'];
}>;

export type ItemIntegrationObjectsQuery = {
  __typename?: 'Query';
  itemIntegrationObjects: {
    __typename?: 'ItemIntegrationObjects';
    refetchedAt: Time;
    error?: string | null;
    procoreChangeEvents: Array<{
      __typename?: 'ProcoreChangeEvent';
      integrationObjectID: string;
      number: number;
      alphanumericNumber: string;
      title: string;
      description: string;
      status: string;
      eventScope: string;
      eventType: string;
      createdAt: string;
      updatedAt: string;
      changeReason: string;
      cost: USCents;
      isDeleted: boolean;
    }>;
    autodeskPotentialChangeOrders: Array<{
      __typename?: 'AutodeskPotentialChangeOrder';
      integrationObjectID: string;
      number: number;
      title: string;
      description: string;
      eventType: string;
      createdAt: string;
      updatedAt: string;
      cost: USCents;
    }>;
  };
};

export type UnlinkItemIntegrationObjectMutationVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  itemID: Scalars['UUID']['input'];
  sourceSystem: SourceSystem;
  sourceType: SourceType;
  sourceID: Scalars['UUID']['input'];
  input: UnlinkItemIntegrationObjectInput;
}>;

export type UnlinkItemIntegrationObjectMutation = {
  __typename?: 'Mutation';
  unlinkItemIntegrationObject?: UUID | null;
};

export type PreviewProjectCollaboratorQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  userID: Scalars['UUID']['input'];
}>;

export type PreviewProjectCollaboratorQuery = {
  __typename?: 'Query';
  previewProjectCollaborator: {
    __typename?: 'Collaborator';
    allTrades: boolean;
    id: UUID;
    isProjectLead: boolean;
    responsibility?: string | null;
    role: {
      __typename?: 'Role';
      id: UUID;
      name: string;
      type: ProjectRoleType;
      hasTrade: boolean;
      permissionGroups: Array<{
        __typename?: 'PermissionGroup';
        type: PermissionGroupType;
        permissions: Array<{
          __typename?: 'Permission';
          id: UUID;
          level: PermissionLevel;
          resource: PermissionResource;
          actions: {
            __typename?: 'Actions';
            view: boolean;
            add: boolean;
            delete: boolean;
            edit: boolean;
          };
        }>;
      }>;
    };
    trades: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
      categorization?: {
        __typename?: 'Categorization';
        draft: boolean;
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
      } | null;
    }>;
    user: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
  };
};

export type PreviewProjectRoleQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
  roleID: Scalars['UUID']['input'];
}>;

export type PreviewProjectRoleQuery = {
  __typename?: 'Query';
  previewProjectRole: {
    __typename?: 'Role';
    id: UUID;
    name: string;
    type: ProjectRoleType;
    hasTrade: boolean;
    permissionGroups: Array<{
      __typename?: 'PermissionGroup';
      type: PermissionGroupType;
      permissions: Array<{
        __typename?: 'Permission';
        id: UUID;
        level: PermissionLevel;
        resource: PermissionResource;
        actions: {
          __typename?: 'Actions';
          view: boolean;
          add: boolean;
          delete: boolean;
          edit: boolean;
        };
      }>;
    }>;
  };
};

export type ProjectCollaboratorQueryVariables = Exact<{
  projectID: Scalars['UUID']['input'];
}>;

export type ProjectCollaboratorQuery = {
  __typename?: 'Query';
  currentCollaborator: {
    __typename?: 'Collaborator';
    allTrades: boolean;
    id: UUID;
    isProjectLead: boolean;
    responsibility?: string | null;
    role: {
      __typename?: 'Role';
      id: UUID;
      name: string;
      type: ProjectRoleType;
      hasTrade: boolean;
      permissionGroups: Array<{
        __typename?: 'PermissionGroup';
        type: PermissionGroupType;
        permissions: Array<{
          __typename?: 'Permission';
          id: UUID;
          level: PermissionLevel;
          resource: PermissionResource;
          actions: {
            __typename?: 'Actions';
            view: boolean;
            add: boolean;
            delete: boolean;
            edit: boolean;
          };
        }>;
      }>;
    };
    trades: Array<{
      __typename?: 'Category';
      id: UUID;
      name: string;
      number: string;
      hasSubCategories: boolean;
      level: number;
      categorization?: {
        __typename?: 'Categorization';
        draft: boolean;
        id: UUID;
        name: string;
        description: string;
        levels: number;
        builtin: boolean;
      } | null;
    }>;
    user: {
      __typename?: 'User';
      status: UserStatus;
      email: string;
      id: UUID;
      jobTitle: string;
      name: string;
      phone?: string | null;
      picture: string;
      pictureAsset?: { __typename?: 'Asset'; thumbnailURL?: string | null } | null;
    };
  };
};

export const CommonCompanyUserFieldsFragmentDoc = gql`
  fragment commonCompanyUserFields on CompanyUser {
    company {
      id
      name
      type
      domain
      assetID
      deactivated
    }
    isIndependent
    matchedCompany {
      id
      name
      type
      domain
      assetID
    }
    role {
      id
      name
    }
    user {
      email
      id
      name
      phone
      picture
      didSetup
      pictureAsset {
        thumbnailURL
      }
      jobTitle
      status
      activeAt
    }
  }
`;
export const ImportEstimateErrorFieldsFragmentDoc = gql`
  fragment importEstimateErrorFields on ImportEstimateError {
    id
    type
    level
    unresolvedCount
    resolvedCount
    created
    updated
    content {
      ... on ImportErrorContentMissingCategories {
        categorization {
          id
          name
        }
        lines
        categories {
          number
          name
        }
      }
    }
  }
`;
export const FullCategoryCategorizationFieldsFragmentDoc = gql`
  fragment fullCategoryCategorizationFields on Category {
    categorization {
      id
      name
    }
    id
    name
    hasSubCategories
    number
    level
    error
  }
`;
export const CategoryGridFieldsFragmentDoc = gql`
  fragment categoryGridFields on Category {
    categorization {
      id
      name
    }
    id
    name
    number
    error
  }
`;
export const GridCellFieldsFragmentDoc = gql`
  fragment gridCellFields on Cell {
    value {
      ... on SourceCell {
        sourceIDs {
          estimateID
          itemID
          lineID
          markupID
          milestoneID
        }
      }
      ... on CategoryCell {
        category {
          ...categoryGridFields
        }
        search
      }
      ... on RegularCell {
        string
        formula
        formulaDisplay {
          type
          text
          error
        }
      }
    }
    error
  }
  ${CategoryGridFieldsFragmentDoc}
`;
export const IndexedGridCellFieldsFragmentDoc = gql`
  fragment indexedGridCellFields on Cell {
    ...gridCellFields
    field
    line
  }
  ${GridCellFieldsFragmentDoc}
`;
export const CommonCategorizationFieldsFragmentDoc = gql`
  fragment commonCategorizationFields on Categorization {
    id
    name
    description
    levels
    builtin
  }
`;
export const GridFieldFieldsFragmentDoc = gql`
  fragment gridFieldFields on Field {
    id
    name
    type
    group
    categorization {
      ...commonCategorizationFields
      createdFrom {
        id
      }
    }
    errors {
      type
      count
      categoryErrorIndicies {
        categoryNumber
        indicies
      }
    }
  }
  ${CommonCategorizationFieldsFragmentDoc}
`;
export const CommonMarkupFieldsFragmentDoc = gql`
  fragment commonMarkupFields on Markup {
    id
    estimateId
    errors {
      field
      error
    }
    name
    percent
    percentScale
    markupReference {
      appliesTo
      item {
        number
        name
        isDeprecated
      }
    }
    categoryFilters {
      categorization {
        id
        name
      }
      id
      name
      number
      level
      error
    }
    sourceFilterIDs
    sourceFilterIDsWithoutS2Reference
    total
    type
    displayType
    drawFromID
    value
    source {
      itemID
      estimateID
      milestoneID
      lineID
      markupID
    }
    disabled
    orderingNumerator
    orderingDenominator
    costTypeFilters
    shouldExcludeFromAllocation
    shouldNotApplyCategoryFiltersToOtherMarkups
  }
`;
export const GridEstimateArmatureFieldsFragmentDoc = gql`
  fragment gridEstimateArmatureFields on Estimate {
    id
    linesCount
    name
    milestoneName
    fields {
      ...gridFieldFields
    }
    incorporatedMarkups {
      ...commonMarkupFields
    }
    incorporatedDraws {
      ...commonMarkupFields
    }
    inheritedMarkups {
      ...commonMarkupFields
    }
    inheritedOwnerCostMarkups {
      ...commonMarkupFields
    }
    markups {
      ...commonMarkupFields
    }
    contingencyDraws {
      id
      name
      error
      draw
    }
    subtotal
    markupSubtotal
    incorporatedSubtotal
    incorporatedDrawsSubtotal
    inheritedSubtotal
    inheritedOwnerCostMarkupsSubtotal
    drawSubtotal
    totalType
    isRunningTotal
  }
  ${GridFieldFieldsFragmentDoc}
  ${CommonMarkupFieldsFragmentDoc}
`;
export const LimitedUpdateEstimateResultFragmentDoc = gql`
  fragment limitedUpdateEstimateResult on EstimateUpdateResult {
    estimate {
      fields {
        ...gridFieldFields
      }
      inheritedMarkups {
        ...commonMarkupFields
      }
      markups {
        ...commonMarkupFields
      }
      incorporatedMarkups {
        ...commonMarkupFields
      }
      incorporatedDraws {
        ...commonMarkupFields
      }
      inheritedOwnerCostMarkups {
        ...commonMarkupFields
      }
      ownerCostEstimate {
        ...gridEstimateArmatureFields
      }
      subtotal
      markupSubtotal
      inheritedSubtotal
      incorporatedSubtotal
      incorporatedDrawsSubtotal
      inheritedOwnerCostMarkupsSubtotal
    }
  }
  ${GridFieldFieldsFragmentDoc}
  ${CommonMarkupFieldsFragmentDoc}
  ${GridEstimateArmatureFieldsFragmentDoc}
`;
export const QuantityUpdateEstimateResultFragmentDoc = gql`
  fragment quantityUpdateEstimateResult on EstimateUpdateResult {
    estimate {
      fields {
        ...gridFieldFields
      }
      subtotal
    }
  }
  ${GridFieldFieldsFragmentDoc}
`;
export const DraftEstimateInfoFieldsFragmentDoc = gql`
  fragment draftEstimateInfoFields on DraftEstimateInfo {
    author
    asset
  }
`;
export const ItemHistoryLinkFieldsFragmentDoc = gql`
  fragment itemHistoryLinkFields on ItemHistoryLink {
    id
    name
    number
    status
    deprecated
    visibility
  }
`;
export const CommonCategoryFieldsFragmentDoc = gql`
  fragment commonCategoryFields on Category {
    categorization {
      id
      name
      builtin
      description
      draft
      levels
      deprecated
    }
    id
    name
    number
    error
    level
    levels {
      id
      name
      number
      hasSubCategories
      level
    }
    hasSubCategories
  }
`;
export const CostTypeFieldsFragmentDoc = gql`
  fragment costTypeFields on Cost {
    ... on CostRange {
      min
      max
    }
    ... on CostScalar {
      value
    }
  }
`;
export const CommonUserFieldsFragmentDoc = gql`
  fragment commonUserFields on User {
    email
    id
    jobTitle
    name
    phone
    picture
    pictureAsset {
      thumbnailURL
    }
    status
  }
`;
export const ScheduleImpactFieldsFragmentDoc = gql`
  fragment scheduleImpactFields on ScheduleImpact {
    type
    criticalPath
    days
    minImpact {
      type
      days
    }
    maxImpact {
      type
      days
    }
  }
`;
export const CommonItemLikeOptionFieldsFragmentDoc = gql`
  fragment commonItemLikeOptionFields on Option {
    id
    itemType
    bucket {
      id
      name
      date
    }
    project {
      id
      name
    }
    description
    descriptionStyled
    dueDate
    creationTime
    updateTime
    createdBy {
      id
      email
      name
      picture
    }
    milestone {
      id
      name
      date
      buckets {
        id
        name
      }
    }
    categories {
      ...commonCategoryFields
    }
    name
    number
    cost {
      ...costTypeFields
    }
    estimateCost {
      ...costTypeFields
    }
    contingencyDrawCost {
      ...costTypeFields
    }
    assignee {
      ...commonUserFields
      status
    }
    status
    visibility
    availableStates
    assetCount
    remoteAssetCount
    commentCount
    previousMilestoneStatus {
      hasEstimate
      milestoneID
      optionSubtotals {
        status
        adds
        deducts
      }
      itemType
      cost {
        ...costTypeFields
      }
      estimateCost {
        ...costTypeFields
      }
      contingencyDrawCost {
        ...costTypeFields
      }
      status
    }
    filteredMilestone
    filteredCategories
    scheduleImpact {
      ...scheduleImpactFields
    }
    activityIDs
  }
  ${CommonCategoryFieldsFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
  ${CommonUserFieldsFragmentDoc}
  ${ScheduleImpactFieldsFragmentDoc}
`;
export const UserLinkFieldsWithoutPictureFragmentDoc = gql`
  fragment userLinkFieldsWithoutPicture on UserLink {
    id
    name
    status
  }
`;
export const MilestoneLinkFieldsFragmentDoc = gql`
  fragment milestoneLinkFields on MilestoneLink {
    id
    name
  }
`;
export const ItemLinkFieldsFragmentDoc = gql`
  fragment itemLinkFields on ItemLink {
    id
    name
    number
    status
    visibility
  }
`;
export const ItemsListItemSummaryFieldsFragmentDoc = gql`
  fragment itemsListItemSummaryFields on ItemsListItem {
    createdAt
    creator {
      ...userLinkFieldsWithoutPicture
    }
    currentCost {
      ...costTypeFields
    }
    currentMilestone {
      ...milestoneLinkFields
    }
    currentStatus
    id
    name
    number
    options {
      ...itemLinkFields
    }
    status
    updateTime
    visibility
  }
  ${UserLinkFieldsWithoutPictureFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
  ${MilestoneLinkFieldsFragmentDoc}
  ${ItemLinkFieldsFragmentDoc}
`;
export const CommonCommentFieldsFragmentDoc = gql`
  fragment commonCommentFields on Comment {
    author {
      ...commonUserFields
      status
    }
    id
    created
    text {
      contents
      mentions {
        id
        position
        text
        user {
          id
        }
      }
      itemReferences {
        id
        position
        text
        referencedItem {
          ...itemsListItemSummaryFields
        }
      }
    }
  }
  ${CommonUserFieldsFragmentDoc}
  ${ItemsListItemSummaryFieldsFragmentDoc}
`;
export const CommonCategoryUniqueFieldsFragmentDoc = gql`
  fragment commonCategoryUniqueFields on Category {
    categorization {
      id
      name
    }
    id
    parentID
    name
    hasSubCategories
    number
    level
    levels {
      id
      name
      number
    }
    error
  }
`;
export const CommonCellFieldsFragmentDoc = gql`
  fragment commonCellFields on Cell {
    estimateId
    field
    line
    value {
      ... on SourceCell {
        sourceIDs {
          estimateID
          itemID
          lineID
          markupID
          milestoneID
        }
      }
      ... on CategoryCell {
        category {
          ...commonCategoryUniqueFields
        }
        search
      }
      ... on RegularCell {
        string
      }
    }
    error
  }
  ${CommonCategoryUniqueFieldsFragmentDoc}
`;
export const CommonLineFieldsFragmentDoc = gql`
  fragment commonLineFields on Line {
    id
    estimateId
    cells {
      ...commonCellFields
    }
    orderingNumerator
    orderingDenominator
  }
  ${CommonCellFieldsFragmentDoc}
`;
export const CommonEstimateFieldsFragmentDoc = gql`
  fragment commonEstimateFields on Estimate {
    id
    lines {
      ...commonLineFields
    }
    name
    fields {
      ...gridFieldFields
    }
    subtotal
    markupSubtotal
    incorporatedMarkups {
      ...commonMarkupFields
    }
    contingencyDraws {
      id
      type
      name
      error
      draw
      drawFromID
      isDrawingFullAmount
    }
    drawSubtotal
    inheritedSubtotal
    inheritedMarkups {
      ...commonMarkupFields
    }
    inheritedOwnerCostMarkups {
      ...commonMarkupFields
    }
    markups {
      ...commonMarkupFields
    }
    inheritedOwnerCostMarkups {
      ...commonMarkupFields
    }
    inheritedOwnerCostMarkupsSubtotal
    asset
    totalType
    isRunningTotal
  }
  ${CommonLineFieldsFragmentDoc}
  ${GridFieldFieldsFragmentDoc}
  ${CommonMarkupFieldsFragmentDoc}
`;
export const DetailedItemLikeOptionFieldsFragmentDoc = gql`
  fragment detailedItemLikeOptionFields on Option {
    ...commonItemLikeOptionFields
    comments {
      ...commonCommentFields
    }
    assets {
      id
      name
    }
    activeEstimate {
      ...commonEstimateFields
    }
  }
  ${CommonItemLikeOptionFieldsFragmentDoc}
  ${CommonCommentFieldsFragmentDoc}
  ${CommonEstimateFieldsFragmentDoc}
`;
export const DetailedOptionFieldsFragmentDoc = gql`
  fragment detailedOptionFields on Option {
    ...detailedItemLikeOptionFields
    parent
    parentVisibility
    hasEstimate
  }
  ${DetailedItemLikeOptionFieldsFragmentDoc}
`;
export const CommonItemLikeItemFieldsFragmentDoc = gql`
  fragment commonItemLikeItemFields on Item {
    id
    itemType
    bucket {
      id
      name
      date
    }
    project {
      id
      name
    }
    description
    descriptionStyled
    dueDate
    creationTime
    updateTime
    createdBy {
      id
      email
      name
      picture
    }
    milestone {
      id
      name
      date
      buckets {
        id
        name
      }
    }
    categories {
      ...commonCategoryFields
    }
    name
    number
    cost {
      ...costTypeFields
    }
    estimateCost {
      ...costTypeFields
    }
    contingencyDrawCost {
      ...costTypeFields
    }
    assignee {
      ...commonUserFields
      status
    }
    status
    visibility
    availableStates
    assetCount
    remoteAssetCount
    commentCount
    previousMilestoneStatus {
      hasEstimate
      milestoneID
      optionSubtotals {
        status
        adds
        deducts
      }
      itemType
      cost {
        ...costTypeFields
      }
      estimateCost {
        ...costTypeFields
      }
      contingencyDrawCost {
        ...costTypeFields
      }
      status
    }
    filteredMilestone
    filteredCategories
    scheduleImpact {
      ...scheduleImpactFields
    }
    activityIDs
  }
  ${CommonCategoryFieldsFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
  ${CommonUserFieldsFragmentDoc}
  ${ScheduleImpactFieldsFragmentDoc}
`;
export const DetailedItemLikeItemFieldsFragmentDoc = gql`
  fragment detailedItemLikeItemFields on Item {
    ...commonItemLikeItemFields
    comments {
      ...commonCommentFields
    }
    assets {
      id
      name
    }
    activeEstimate {
      ...commonEstimateFields
    }
  }
  ${CommonItemLikeItemFieldsFragmentDoc}
  ${CommonCommentFieldsFragmentDoc}
  ${CommonEstimateFieldsFragmentDoc}
`;
export const DetailedItemFieldsFragmentDoc = gql`
  fragment detailedItemFields on Item {
    ...detailedItemLikeItemFields
    options {
      ...detailedOptionFields
    }
    optionSubtotals {
      status
      adds
      deducts
    }
  }
  ${DetailedItemLikeItemFieldsFragmentDoc}
  ${DetailedOptionFieldsFragmentDoc}
`;
export const SharedDetailedItemLikeFieldsFragmentDoc = gql`
  fragment sharedDetailedItemLikeFields on ItemLike {
    ... on Option {
      ...detailedOptionFields
    }
    ... on Item {
      ...detailedItemFields
    }
  }
  ${DetailedOptionFieldsFragmentDoc}
  ${DetailedItemFieldsFragmentDoc}
`;
export const SourceOptionFieldsFragmentDoc = gql`
  fragment sourceOptionFields on Option {
    id
    name
    number
    status
  }
`;
export const SourceItemFieldsFragmentDoc = gql`
  fragment sourceItemFields on Item {
    id
    name
    number
    status
  }
`;
export const SharedSourceItemLikeFieldsFragmentDoc = gql`
  fragment sharedSourceItemLikeFields on ItemLike {
    ... on Option {
      ...sourceOptionFields
    }
    ... on Item {
      ...sourceItemFields
    }
  }
  ${SourceOptionFieldsFragmentDoc}
  ${SourceItemFieldsFragmentDoc}
`;
export const SharedCostReportValueFieldsFragmentDoc = gql`
  fragment sharedCostReportValueFields on CostReportItem {
    subtotalValue {
      ... on SubtotalCost {
        directCostRange {
          ...costTypeFields
        }
        range {
          ...costTypeFields
        }
      }
      ... on SubtotalAddsDeducts {
        directCostSegmented {
          adds
          deducts
        }
        segmented {
          adds
          deducts
        }
      }
      ... on SubtotalNumeric {
        value
      }
    }
  }
  ${CostTypeFieldsFragmentDoc}
`;
export const CategorizedItemCostsFieldsFragmentDoc = gql`
  fragment categorizedItemCostsFields on CategorizedItemCost {
    id
    milestoneId
    name
    number
    parentId
    status
    canViewCosts
    categories {
      ...commonCategoryFields
    }
    directCostRange {
      ...costTypeFields
    }
    range {
      ...costTypeFields
    }
    itemContributionLines {
      categories {
        ...commonCategoryFields
      }
      directCostRange {
        ...costTypeFields
      }
      range {
        ...costTypeFields
      }
      lineID
      lineDescription
    }
  }
  ${CommonCategoryFieldsFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
`;
export const CommonCostReportFieldsFragmentDoc = gql`
  fragment commonCostReportFields on CostReport {
    directCostRange {
      ...costTypeFields
    }
    directCostSegmented {
      adds
      deducts
    }
    projectTotalRange {
      ...costTypeFields
    }
    projectTotalSegmented {
      adds
      deducts
    }
    range {
      ...costTypeFields
    }
    segmented {
      adds
      deducts
    }
    numeric
    subtotals {
      categories {
        ...commonCategoryFields
      }
      ...sharedCostReportValueFields
    }
    categorizedItemCosts {
      ...categorizedItemCostsFields
    }
    markupContributions {
      isIncorporated
      isOwnerCost
      markupId
      markupName
      displayType
      range {
        ...costTypeFields
      }
      segmented {
        adds
        deducts
      }
    }
    type
  }
  ${CostTypeFieldsFragmentDoc}
  ${CommonCategoryFieldsFragmentDoc}
  ${SharedCostReportValueFieldsFragmentDoc}
  ${CategorizedItemCostsFieldsFragmentDoc}
`;
export const UnitFieldsFragmentDoc = gql`
  fragment unitFields on Unit {
    abbreviationPlural
    abbreviationSingular
    description
    id
    isDefault
    isEnabled
    name
    type
  }
`;
export const QuantityFieldsFragmentDoc = gql`
  fragment quantityFields on Quantity {
    id
    magnitude
    unit {
      ...unitFields
    }
    estimateID
    hasBreakdown
  }
  ${UnitFieldsFragmentDoc}
`;
export const CommonCostReportColumnFieldsFragmentDoc = gql`
  fragment commonCostReportColumnFields on CostReportColumn {
    report {
      ...commonCostReportFields
    }
    type
    columnKey
    quantity {
      ...quantityFields
    }
    isCategorized
  }
  ${CommonCostReportFieldsFragmentDoc}
  ${QuantityFieldsFragmentDoc}
`;
export const CommonProjectTypeFieldsFragmentDoc = gql`
  fragment commonProjectTypeFields on ProjectType {
    id
    name
    parentID
    hidden
  }
`;
export const CommonProjectStatusFieldsFragmentDoc = gql`
  fragment commonProjectStatusFields on ProjectStatus {
    id
    name
    type
    description
  }
`;
export const CommonProjectDeliveryTypeFieldsFragmentDoc = gql`
  fragment commonProjectDeliveryTypeFields on ProjectDeliveryType {
    id
    name
    abbreviation
  }
`;
export const CommonLocationDetailsFieldsFragmentDoc = gql`
  fragment commonLocationDetailsFields on LocationDetails {
    name
    lat
    lon
    city
    state
    stateLong
    country
  }
`;
export const CommonProjectPropsFieldsFragmentDoc = gql`
  fragment commonProjectPropsFields on Project {
    activeMilestone {
      id
      name
    }
    id
    description
    descriptionStyled
    name
    code
    status {
      ...commonProjectStatusFields
    }
    team {
      owner {
        name
        picture
      }
      generalContractor {
        name
        picture
      }
      designTeam {
        name
        picture
      }
    }
    type {
      id
      name
    }
    hasAccess
    location
    lat
    lon
    thumbnail
    templateEstimateID
    lastUpdated
    projectDeliveryType {
      ...commonProjectDeliveryTypeFields
    }
    locationDetails {
      ...commonLocationDetailsFields
    }
    orgNodes {
      id
      name
    }
  }
  ${CommonProjectStatusFieldsFragmentDoc}
  ${CommonProjectDeliveryTypeFieldsFragmentDoc}
  ${CommonLocationDetailsFieldsFragmentDoc}
`;
export const CommonUserReportFieldsFragmentDoc = gql`
  fragment commonUserReportFields on UserReport {
    id
    milestoneID
    reportType
    shared
    owner
    name
    description
    settings
    createdBy {
      id
      name
      email
    }
    updatedAt
    hasDistributionScheduled
    isFollowingActiveMilestone
  }
`;
export const CommonReportDistributionFieldsFragmentDoc = gql`
  fragment commonReportDistributionFields on ReportDistribution {
    cadence
    createdBy
    id
    reportID
    collaboratorIDs
    startDate
  }
`;
export const CommonRoleFieldsFragmentDoc = gql`
  fragment commonRoleFields on Role {
    id
    name
    type
    hasTrade
    permissionGroups {
      type
      permissions {
        id
        level
        resource
        actions {
          view
          add
          delete
          edit
        }
      }
    }
  }
`;
export const CommonTradeFieldsFragmentDoc = gql`
  fragment commonTradeFields on Category {
    categorization {
      ...commonCategorizationFields
      draft
    }
    id
    name
    number
    hasSubCategories
    level
  }
  ${CommonCategorizationFieldsFragmentDoc}
`;
export const CommonCollaboratorFieldsFragmentDoc = gql`
  fragment commonCollaboratorFields on Collaborator {
    allTrades
    id
    isProjectLead
    responsibility
    role {
      ...commonRoleFields
    }
    trades {
      ...commonTradeFields
    }
    user {
      ...commonUserFields
      status
    }
  }
  ${CommonRoleFieldsFragmentDoc}
  ${CommonTradeFieldsFragmentDoc}
  ${CommonUserFieldsFragmentDoc}
`;
export const CommonCompanyRoleFieldsFragmentDoc = gql`
  fragment commonCompanyRoleFields on CompanyRole {
    id
    name
    permissionGroups {
      type
      permissions {
        id
        level
        resource
        actions {
          view
          add
          delete
          edit
        }
      }
    }
  }
`;
export const MinimalBucketFieldsFragmentDoc = gql`
  fragment minimalBucketFields on Bucket {
    id
    name
    date
    milestone {
      id
    }
  }
`;
export const AssetFieldsFragmentDoc = gql`
  fragment assetFields on Asset {
    id
    derivations {
      id
      kind
      contents
    }
    name
    size
    lastUpdated
    location
    uploadedBy {
      id
      name
      email
    }
    type @client
  }
`;
export const RemoteAssetFieldsFragmentDoc = gql`
  fragment remoteAssetFields on RemoteAsset {
    id
    sourceAssetID
    name
    lastUpdated
    remoteLink
    uploadedBy {
      id
      name
      email
    }
    sourceSystem
  }
`;
export const AttachmentFieldsQueryFragmentDoc = gql`
  fragment attachmentFieldsQuery on Attachment {
    ... on Asset {
      ...assetFields
    }
    ... on RemoteAsset {
      ...remoteAssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
  ${RemoteAssetFieldsFragmentDoc}
`;
export const CommonItemLikeSummaryFieldsFragmentDoc = gql`
  fragment commonItemLikeSummaryFields on ItemLikeSummary {
    id
    number
    kind
    visibility
  }
`;
export const CommonItemHistoryEventContentFieldsFragmentDoc = gql`
  fragment commonItemHistoryEventContentFields on ItemHistoryEventContent {
    newNumber
    oldNumber
    name
    description
    descriptionStyled
    newMilestoneID
    oldMilestoneID
    status
    visibility
    dueDate
    assignee {
      ...commonUserFields
      status
    }
    deletedBy {
      ...commonUserFields
    }
    sharedUsers {
      ...commonUserFields
    }
    meeting {
      ...minimalBucketFields
    }
    addedAttachments {
      ...attachmentFieldsQuery
    }
    removedAssets {
      name
    }
    newCost {
      ...costTypeFields
    }
    oldCost {
      ...costTypeFields
    }
    categoryChanges {
      oldCategory {
        ...commonCategoryFields
      }
      newCategory {
        ...commonCategoryFields
      }
    }
    costChanges {
      oldCost {
        ...costTypeFields
      }
      newCost {
        ...costTypeFields
      }
      timestamp
    }
    scheduleImpactChanges {
      old {
        ...scheduleImpactFields
      }
      new {
        ...scheduleImpactFields
      }
      timestamp
    }
    oldTimelineActivities {
      id
      name
    }
    newTimelineActivities {
      id
      name
    }
    itemLikeSummary {
      ...commonItemLikeSummaryFields
    }
    removedOptions {
      ...commonItemLikeSummaryFields
    }
    attachedOptions {
      ...commonItemLikeSummaryFields
    }
    drawName
    drawFromValue
    drawToValue
    statusChangeDraws {
      name
      value
    }
    drawChanges {
      name
      fromValue
      toValue
      timestamp
    }
    referencedByItems {
      ...commonItemLikeSummaryFields
    }
    comment {
      ...commonCommentFields
    }
    copiedItem {
      id
      name
      number
      projectID
      status
      visibility
      hasAccess
    }
    itemIntegrationObject {
      sourceEntityID
      number
      title
    }
    milestoneID
  }
  ${CommonUserFieldsFragmentDoc}
  ${MinimalBucketFieldsFragmentDoc}
  ${AttachmentFieldsQueryFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
  ${CommonCategoryFieldsFragmentDoc}
  ${ScheduleImpactFieldsFragmentDoc}
  ${CommonItemLikeSummaryFieldsFragmentDoc}
  ${CommonCommentFieldsFragmentDoc}
`;
export const ItemHistoryFieldsFragmentDoc = gql`
  fragment itemHistoryFields on ItemHistory {
    events {
      id
      user {
        ...commonUserFields
        status
      }
      itemID
      eventTypes
      timestamp
      eventContent {
        ...commonItemHistoryEventContentFields
      }
    }
  }
  ${CommonUserFieldsFragmentDoc}
  ${CommonItemHistoryEventContentFieldsFragmentDoc}
`;
export const ItemActivityEventFieldsFragmentDoc = gql`
  fragment itemActivityEventFields on ItemHistoryEvent {
    id
    user {
      ...commonUserFields
      status
    }
    itemID
    eventTypes
    timestamp
    eventContent {
      ...commonItemHistoryEventContentFields
    }
  }
  ${CommonUserFieldsFragmentDoc}
  ${CommonItemHistoryEventContentFieldsFragmentDoc}
`;
export const ItemActivityFieldsFragmentDoc = gql`
  fragment itemActivityFields on ItemActivity {
    paginationData {
      nextOffsetID
    }
    data {
      ...itemActivityEventFields
    }
  }
  ${ItemActivityEventFieldsFragmentDoc}
`;
export const EventDataItemFieldsFragmentDoc = gql`
  fragment eventDataItemFields on EventItem {
    id
    cost {
      ...costTypeFields
    }
    name
    number
    status
    milestone {
      id
    }
  }
  ${CostTypeFieldsFragmentDoc}
`;
export const ItemEventSummaryFieldsFragmentDoc = gql`
  fragment itemEventSummaryFields on ItemSummary {
    id
    number
    currentNumber
    status
  }
`;
export const CommonEventDataFieldsFragmentDoc = gql`
  fragment commonEventDataFields on EventData {
    events {
      timestamp
      eventType
      item {
        ...eventDataItemFields
      }
      currentItemNumber
      user {
        ...commonUserFields
        status
      }
      costImpact {
        minPending
        maxPending
        cost
      }
      eventContent {
        oldCost {
          ...costTypeFields
        }
        newCost {
          ...costTypeFields
        }
        option {
          ...itemEventSummaryFields
        }
        options {
          ...itemEventSummaryFields
        }
        oldMilestone
        newMilestone
        oldOptionStatus
        newOptionStatus
        oldStatus
        newStatus
        parent {
          ...itemEventSummaryFields
        }
      }
      ids
      visible
    }
  }
  ${EventDataItemFieldsFragmentDoc}
  ${CommonUserFieldsFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
  ${ItemEventSummaryFieldsFragmentDoc}
`;
export const ShareResourcesFieldsFragmentDoc = gql`
  fragment shareResourcesFields on ShareResourcesPayload {
    resources {
      id
      type
      accessLevels
      users {
        id
        email
        isEmailVerified
        jobTitle
        name
        picture
        status
      }
    }
  }
`;
export const GetShareResourceFieldsFragmentDoc = gql`
  fragment getShareResourceFields on GetSharedResourcePayload {
    resource {
      id
      type
      accessLevels
      users {
        id
        email
        isEmailVerified
        jobTitle
        name
        picture
        status
      }
    }
  }
`;
export const CommonRemoteAssetFieldsFragmentDoc = gql`
  fragment commonRemoteAssetFields on RemoteAsset {
    id
    name
    lastUpdated
    remoteLink
    uploadedBy {
      id
      name
      email
    }
  }
`;
export const AttachmentFieldsFragmentDoc = gql`
  fragment attachmentFields on Attachment {
    ... on Asset {
      ...assetFields
    }
    ... on RemoteAsset {
      ...commonRemoteAssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
  ${CommonRemoteAssetFieldsFragmentDoc}
`;
export const CommonUserNotificationSettingFieldsFragmentDoc = gql`
  fragment commonUserNotificationSettingFields on UserNotificationSetting {
    assignments
    channel
    comments
    isGlobalSubscribed
    mentions
    newItems
    shareScenarios
  }
`;
export const DetailedMilestoneFieldsFragmentDoc = gql`
  fragment detailedMilestoneFields on Milestone {
    id
    buckets {
      date
      id
      name
      itemsCount
    }
    name
    description
    descriptionStyled
    deletable
    date
    isDraft
    createdBy {
      id
      name
      email
    }
    designPhase {
      id
      name
      abbreviation
    }
    timelinePhase {
      id
      name
      startDate
      endDate
    }
    itemsCount
    draftItemsCount
    assetCount
    remoteAssetCount
    activeEstimateID
    activeEstimateDraftID
    budgetID
    budgetDraftID
    importedEstimates {
      ...assetFields
    }
    quantities {
      ...quantityFields
    }
  }
  ${AssetFieldsFragmentDoc}
  ${QuantityFieldsFragmentDoc}
`;
export const CommonProjectFieldsFragmentDoc = gql`
  fragment commonProjectFields on Project {
    activeMilestone {
      id
      name
    }
    id
    description
    descriptionStyled
    name
    status {
      ...commonProjectStatusFields
    }
    team {
      owner {
        name
        picture
      }
      generalContractor {
        name
        picture
      }
      designTeam {
        name
        picture
      }
    }
    type {
      id
      name
    }
    location
    milestones {
      ...detailedMilestoneFields
    }
    thumbnail
    roles {
      ...commonRoleFields
    }
    projectDeliveryType {
      ...commonProjectDeliveryTypeFields
    }
    locationDetails {
      ...commonLocationDetailsFields
    }
  }
  ${CommonProjectStatusFieldsFragmentDoc}
  ${DetailedMilestoneFieldsFragmentDoc}
  ${CommonRoleFieldsFragmentDoc}
  ${CommonProjectDeliveryTypeFieldsFragmentDoc}
  ${CommonLocationDetailsFieldsFragmentDoc}
`;
export const CommonTriggeringUserFieldsFragmentDoc = gql`
  fragment commonTriggeringUserFields on User {
    name
    email
    id
  }
`;
export const CommonNotificationProjectFieldsFragmentDoc = gql`
  fragment commonNotificationProjectFields on Project {
    name
    id
  }
`;
export const AssignItemFieldsFragmentDoc = gql`
  fragment assignItemFields on AssignItem {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    item {
      id
      name
      number
      visibility
    }
  }
  ${CommonTriggeringUserFieldsFragmentDoc}
  ${CommonNotificationProjectFieldsFragmentDoc}
`;
export const AssignProjectLeadFieldsFragmentDoc = gql`
  fragment assignProjectLeadFields on AssignProjectLead {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
  }
  ${CommonTriggeringUserFieldsFragmentDoc}
  ${CommonNotificationProjectFieldsFragmentDoc}
`;
export const NewMentionFieldsFragmentDoc = gql`
  fragment newMentionFields on NewMention {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    item {
      id
      name
      number
      visibility
    }
    comment {
      ...commonCommentFields
    }
  }
  ${CommonTriggeringUserFieldsFragmentDoc}
  ${CommonNotificationProjectFieldsFragmentDoc}
  ${CommonCommentFieldsFragmentDoc}
`;
export const CommonUserReportCommentFieldsFragmentDoc = gql`
  fragment commonUserReportCommentFields on UserReportComment {
    comment {
      ...commonCommentFields
    }
    commentLineID
    type
    costs
  }
  ${CommonCommentFieldsFragmentDoc}
`;
export const NewReportMentionFieldsFragmentDoc = gql`
  fragment NewReportMentionFields on NewReportMention {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    reportID
    reportName
    userReportComment {
      ...commonUserReportCommentFields
    }
  }
  ${CommonTriggeringUserFieldsFragmentDoc}
  ${CommonNotificationProjectFieldsFragmentDoc}
  ${CommonUserReportCommentFieldsFragmentDoc}
`;
export const ProjectInviteFieldsFragmentDoc = gql`
  fragment projectInviteFields on InviteToProject {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    message
  }
  ${CommonTriggeringUserFieldsFragmentDoc}
  ${CommonNotificationProjectFieldsFragmentDoc}
`;
export const NewCommentFieldsFragmentDoc = gql`
  fragment newCommentFields on NewComment {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    item {
      id
      name
      number
      visibility
    }
    comment {
      ...commonCommentFields
    }
  }
  ${CommonTriggeringUserFieldsFragmentDoc}
  ${CommonNotificationProjectFieldsFragmentDoc}
  ${CommonCommentFieldsFragmentDoc}
`;
export const NewItemFieldsFragmentDoc = gql`
  fragment newItemFields on NewItem {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    item {
      id
      name
      number
      visibility
    }
  }
  ${CommonTriggeringUserFieldsFragmentDoc}
  ${CommonNotificationProjectFieldsFragmentDoc}
`;
export const NewOptionFieldsFragmentDoc = gql`
  fragment newOptionFields on NewOption {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    parentItem {
      id
      name
      number
      visibility
    }
    option {
      id
      name
      number
      visibility
    }
  }
  ${CommonTriggeringUserFieldsFragmentDoc}
  ${CommonNotificationProjectFieldsFragmentDoc}
`;
export const ProjectRemoveFieldsFragmentDoc = gql`
  fragment projectRemoveFields on RemoveFromProject {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
  }
  ${CommonTriggeringUserFieldsFragmentDoc}
  ${CommonNotificationProjectFieldsFragmentDoc}
`;
export const ShareDraftItemFieldsFragmentDoc = gql`
  fragment shareDraftItemFields on ShareDraftItem {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    item {
      id
      name
      number
      visibility
    }
  }
  ${CommonTriggeringUserFieldsFragmentDoc}
  ${CommonNotificationProjectFieldsFragmentDoc}
`;
export const ShareScenarioFieldsFragmentDoc = gql`
  fragment shareScenarioFields on ShareScenario {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    scenario {
      id
      name
    }
  }
  ${CommonTriggeringUserFieldsFragmentDoc}
  ${CommonNotificationProjectFieldsFragmentDoc}
`;
export const CommonInAppNotificationFieldsFragmentDoc = gql`
  fragment commonInAppNotificationFields on InAppNotification {
    id
    notificationType
    projectID
    content {
      ... on AssignItem {
        ...assignItemFields
      }
      ... on AssignProjectLead {
        ...assignProjectLeadFields
      }
      ... on NewMention {
        ...newMentionFields
      }
      ... on NewReportMention {
        ...NewReportMentionFields
      }
      ... on InviteToProject {
        ...projectInviteFields
      }
      ... on NewComment {
        ...newCommentFields
      }
      ... on NewItem {
        ...newItemFields
      }
      ... on NewOption {
        ...newOptionFields
      }
      ... on RemoveFromProject {
        ...projectRemoveFields
      }
      ... on ShareDraftItem {
        ...shareDraftItemFields
      }
      ... on ShareScenario {
        ...shareScenarioFields
      }
    }
    createdAt
    readAt
  }
  ${AssignItemFieldsFragmentDoc}
  ${AssignProjectLeadFieldsFragmentDoc}
  ${NewMentionFieldsFragmentDoc}
  ${NewReportMentionFieldsFragmentDoc}
  ${ProjectInviteFieldsFragmentDoc}
  ${NewCommentFieldsFragmentDoc}
  ${NewItemFieldsFragmentDoc}
  ${NewOptionFieldsFragmentDoc}
  ${ProjectRemoveFieldsFragmentDoc}
  ${ShareDraftItemFieldsFragmentDoc}
  ${ShareScenarioFieldsFragmentDoc}
`;
export const CommonInAppNotificationsFieldsFragmentDoc = gql`
  fragment commonInAppNotificationsFields on InAppNotifications {
    inAppNotifications {
      ...commonInAppNotificationFields
    }
    paginationData {
      nextOffsetID
    }
  }
  ${CommonInAppNotificationFieldsFragmentDoc}
`;
export const CommonMilestoneFieldsFragmentDoc = gql`
  fragment commonMilestoneFields on Milestone {
    id
    name
    description
    descriptionStyled
    deletable
    date
    isDraft
    createdBy {
      id
    }
    itemsCount
    draftItemsCount
    designPhase {
      id
      name
      abbreviation
    }
    timelinePhase {
      id
      name
      startDate
      endDate
    }
    buckets {
      id
      name
      itemsCount
    }
    activeEstimateID
    activeEstimateDraftID
    budgetID
    budgetDraftID
    quantities {
      ...quantityFields
    }
  }
  ${QuantityFieldsFragmentDoc}
`;
export const AverageCompInputReturnFieldsFragmentDoc = gql`
  fragment averageCompInputReturnFields on AverageCompInputReturn {
    name
    description
    color
    isHidden
    thumbnailAssetID
    metrics {
      unitID
      quantityMagnitude
      hasMilestoneQuantity
    }
    location
    lat
    lon
    categoryLineInputs {
      category {
        name
        number
      }
      description
      editedValue
      cellType
      unitID
    }
    markupLineInputs {
      markupName
      description
      editedValue
      cellType
      unitID
    }
  }
`;
export const ProjectCompInputReturnFieldsFragmentDoc = gql`
  fragment projectCompInputReturnFields on ProjectCompInputReturn {
    id
    projectID
    escalation {
      time
      timeFuture
      location
      timeMeta {
        isAutoEscalated
        isFuzzyMatch
        sourceLabel
        sourceIsFuzzyMatch
        targetLabel
        targetIsFuzzyMatch
      }
      locationMeta {
        isAutoEscalated
        isFuzzyMatch
        sourceLabel
        sourceIsFuzzyMatch
        targetLabel
        targetIsFuzzyMatch
      }
    }
    name
    description
    metrics {
      unitID
      quantityMagnitude
      hasMilestoneQuantity
    }
    categorizationID
    categorizationLevel
    isExcluded
    milestoneID
    categoryLineInputs {
      category {
        name
        number
      }
      description
      editedValue
      cellType
      unitID
    }
    markupLineInputs {
      markupName
      description
      editedValue
      cellType
      unitID
    }
    viewFilter {
      categories {
        categorizationID
        id
      }
    }
  }
`;
export const ProjectCompsSetInputReturnFieldsFragmentDoc = gql`
  fragment projectCompsSetInputReturnFields on ProjectCompsSetInputReturn {
    addedMarkupNames
    averageInput {
      ...averageCompInputReturnFields
    }
    costTableColumnInputs {
      key
      unitID
    }
    markupMode
    pinnedUnitID
    projectCompInputs {
      ...projectCompInputReturnFields
    }
    selectedUnitIDs
    excludedCategoryContents {
      number
      name
    }
    excludedMarkupNames
  }
  ${AverageCompInputReturnFieldsFragmentDoc}
  ${ProjectCompInputReturnFieldsFragmentDoc}
`;
export const CommonProjectCompsCostTableCostValuesFieldsFragmentDoc = gql`
  fragment commonProjectCompsCostTableCostValuesFields on ProjectCompsCostTableCostValues {
    totalValue {
      ...costTypeFields
    }
    quantityValue {
      ...costTypeFields
    }
    quantityMagnitude
    percentValue
  }
  ${CostTypeFieldsFragmentDoc}
`;
export const CommonProjectCompsCostTableColumnValueFieldsFragmentDoc = gql`
  fragment commonProjectCompsCostTableColumnValueFields on ProjectCompsCostTableColumnValue {
    valueNumeric
    isEdited
    isMaxValue
    isMinValue
    hasError
    costTableColumnInput {
      key
      unitID
    }
  }
`;
export const CommonProjectCompsCostTableCategoryLineFieldsFragmentDoc = gql`
  fragment commonProjectCompsCostTableCategoryLineFields on ProjectCompsCostTableCategoryLine {
    category {
      ...commonCategoryFields
    }
    description
    pinnedUnitValues {
      ...commonProjectCompsCostTableCostValuesFields
    }
    columnValues {
      ...commonProjectCompsCostTableColumnValueFields
    }
  }
  ${CommonCategoryFieldsFragmentDoc}
  ${CommonProjectCompsCostTableCostValuesFieldsFragmentDoc}
  ${CommonProjectCompsCostTableColumnValueFieldsFragmentDoc}
`;
export const CommonProjectCompsCostTableMarkupLineFieldsFragmentDoc = gql`
  fragment commonProjectCompsCostTableMarkupLineFields on ProjectCompsCostTableMarkupLine {
    markupName
    description
    pinnedUnitValues {
      ...commonProjectCompsCostTableCostValuesFields
    }
    columnValues {
      ...commonProjectCompsCostTableColumnValueFields
    }
  }
  ${CommonProjectCompsCostTableCostValuesFieldsFragmentDoc}
  ${CommonProjectCompsCostTableColumnValueFieldsFragmentDoc}
`;
export const CommonProjectCompsCostTableSummaryLineFieldsFragmentDoc = gql`
  fragment commonProjectCompsCostTableSummaryLineFields on ProjectCompsCostTableSummaryLine {
    pinnedUnitValues {
      ...commonProjectCompsCostTableCostValuesFields
    }
    columnValues {
      ...commonProjectCompsCostTableColumnValueFields
    }
  }
  ${CommonProjectCompsCostTableCostValuesFieldsFragmentDoc}
  ${CommonProjectCompsCostTableColumnValueFieldsFragmentDoc}
`;
export const CommonProjectCompsCostTableSummaryLinesFieldsFragmentDoc = gql`
  fragment commonProjectCompsCostTableSummaryLinesFields on ProjectCompsCostTableSummaryLines {
    subtotalLine {
      ...commonProjectCompsCostTableSummaryLineFields
    }
    totalLine {
      ...commonProjectCompsCostTableSummaryLineFields
    }
    markupsTotalLine {
      ...commonProjectCompsCostTableSummaryLineFields
    }
  }
  ${CommonProjectCompsCostTableSummaryLineFieldsFragmentDoc}
`;
export const CommonProjectCompsCostTableFieldsFragmentDoc = gql`
  fragment commonProjectCompsCostTableFields on ProjectCompsCostTable {
    categoryLines {
      ...commonProjectCompsCostTableCategoryLineFields
    }
    markupLines {
      ...commonProjectCompsCostTableMarkupLineFields
    }
    summaryLines {
      ...commonProjectCompsCostTableSummaryLinesFields
    }
  }
  ${CommonProjectCompsCostTableCategoryLineFieldsFragmentDoc}
  ${CommonProjectCompsCostTableMarkupLineFieldsFragmentDoc}
  ${CommonProjectCompsCostTableSummaryLinesFieldsFragmentDoc}
`;
export const CommonAverageCompFieldsFragmentDoc = gql`
  fragment commonAverageCompFields on AverageComp {
    input {
      ...averageCompInputReturnFields
    }
    projectCompsCostTable {
      ...commonProjectCompsCostTableFields
    }
    isHidden
    isResettable
  }
  ${AverageCompInputReturnFieldsFragmentDoc}
  ${CommonProjectCompsCostTableFieldsFragmentDoc}
`;
export const CommonProjectCompFieldsFragmentDoc = gql`
  fragment commonProjectCompFields on ProjectComp {
    input {
      ...projectCompInputReturnFields
    }
    milestone {
      id
      date
      name
    }
    project {
      id
      name
      hasAccess
      location
      lat
      lon
      thumbnail
      status {
        name
      }
      milestones {
        id
        date
        name
      }
      activeMilestone {
        id
      }
      type {
        name
      }
    }
    projectCompsCostTable {
      ...commonProjectCompsCostTableFields
    }
    isResettable
  }
  ${ProjectCompInputReturnFieldsFragmentDoc}
  ${CommonProjectCompsCostTableFieldsFragmentDoc}
`;
export const CommonDisplayCategoryFieldsFragmentDoc = gql`
  fragment commonDisplayCategoryFields on Category {
    id
    name
    number
    level
    error
    categorization {
      id
      name
    }
  }
`;
export const CommonProjectCompsSetFieldsFragmentDoc = gql`
  fragment commonProjectCompsSetFields on ProjectCompsSet {
    input {
      ...projectCompsSetInputReturnFields
    }
    averageComp {
      ...commonAverageCompFields
    }
    projectComps {
      ...commonProjectCompFields
    }
    categories {
      ...commonDisplayCategoryFields
    }
    units {
      ...unitFields
    }
    selectedUnits {
      ...unitFields
    }
    unitCounts {
      unitID
      countText
    }
    markups {
      name
      isCustom
    }
  }
  ${ProjectCompsSetInputReturnFieldsFragmentDoc}
  ${CommonAverageCompFieldsFragmentDoc}
  ${CommonProjectCompFieldsFragmentDoc}
  ${CommonDisplayCategoryFieldsFragmentDoc}
  ${UnitFieldsFragmentDoc}
`;
export const EstimateTotalTypeConflictInfoFieldsFragmentDoc = gql`
  fragment estimateTotalTypeConflictInfoFields on EstimateTotalTypeConflictInfo {
    hasConflict
    mostDetailedEstimateTotalType
  }
`;
export const ProjectComparisonReportFieldsFragmentDoc = gql`
  fragment projectComparisonReportFields on ProjectComparisonReport {
    id
    attachedProject {
      id
      name
      location
      type {
        id
        name
      }
      hasAccess
    }
    name
    description
    createdAt
    createdBy {
      id
      name
      email
    }
    updatedAt
    updatedBy {
      id
      name
      email
    }
    metadata {
      averageQuantityCost
      averageTotalCost
      pinnedUnit {
        ...unitFields
      }
      pinnedUnitQuantityMagnitude
      projectsCount
    }
  }
  ${UnitFieldsFragmentDoc}
`;
export const ProcoreChangeEventFieldsFragmentDoc = gql`
  fragment procoreChangeEventFields on ProcoreChangeEvent {
    integrationObjectID
    number
    alphanumericNumber
    title
    description
    status
    eventScope
    eventType
    createdAt
    updatedAt
    changeReason
    cost
    isDeleted
  }
`;
export const AutodeskPotentialChangeOrderFieldsFragmentDoc = gql`
  fragment autodeskPotentialChangeOrderFields on AutodeskPotentialChangeOrder {
    integrationObjectID
    number
    title
    description
    eventType
    createdAt
    updatedAt
    cost
  }
`;
export const OrganizationFieldsFragmentDoc = gql`
  fragment organizationFields on Org {
    id
    isDraft
    name
    levels
    nodes {
      id
      name
      parentID
    }
  }
`;
export const UserProjectFieldsFragmentDoc = gql`
  fragment userProjectFields on UserProject {
    id
    name
    status
    type
    location
    collaboratorID
    roleID
    roles {
      id
      name
    }
  }
`;
export const ProjectDateVisibilityFragmentDoc = gql`
  fragment projectDateVisibility on ProjectDateVisibility {
    milestoneDates {
      milestoneID
      dates {
        date
        visible
      }
    }
  }
`;
export const CommonSourceHubFieldsFragmentDoc = gql`
  fragment commonSourceHubFields on SourceHub {
    id
    name
    remoteLink
    sourceUpdatedAt
  }
`;
export const CommonSourceProjectFieldsFragmentDoc = gql`
  fragment commonSourceProjectFields on SourceProject {
    id
    name
    remoteLink
    sourceUpdatedAt
  }
`;
export const CommonSourceAssetFieldsFragmentDoc = gql`
  fragment commonSourceAssetFields on SourceAsset {
    id
    name
    remoteLink
    sourceUpdatedAt
  }
`;
export const CommonSourceFolderFieldsFragmentDoc = gql`
  fragment commonSourceFolderFields on SourceFolder {
    id
    name
    remoteLink
    sourceUpdatedAt
  }
`;
export const CommonSourceEntityFieldsFragmentDoc = gql`
  fragment commonSourceEntityFields on SourceEntity {
    ... on SourceAsset {
      ...commonSourceAssetFields
    }
    ... on SourceFolder {
      ...commonSourceFolderFields
    }
  }
  ${CommonSourceAssetFieldsFragmentDoc}
  ${CommonSourceFolderFieldsFragmentDoc}
`;
export const ProjectLocationFieldsFragmentDoc = gql`
  fragment projectLocationFields on ProjectLocation {
    location
    lat
    lon
    projects {
      apv
      id
      name
      status
    }
    totalAPV
  }
`;
export const UserLinkFieldsFragmentDoc = gql`
  fragment userLinkFields on UserLink {
    id
    name
    picture
    pictureAsset {
      thumbnailURL
    }
    status
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment userFields on UserLink {
    id
    name
    email
  }
`;
export const CategoryLinkFieldsFragmentDoc = gql`
  fragment categoryLinkFields on CategoryLink {
    id
    name
    number
    categorization {
      id
      name
    }
  }
`;
export const MeetingLinkFieldsFragmentDoc = gql`
  fragment meetingLinkFields on MeetingLink {
    id
    name
  }
`;
export const ItemsListItemFieldsFragmentDoc = gql`
  fragment itemsListItemFields on ItemsListItem {
    activityIDs
    assigneeID
    assetCount
    availableStates
    categories {
      ...categoryLinkFields
    }
    commentCount
    createdAt
    creator {
      ...userLinkFieldsWithoutPicture
    }
    dueDate
    cost {
      ...costTypeFields
    }
    currentCost {
      ...costTypeFields
    }
    currentMilestone {
      ...milestoneLinkFields
    }
    currentStatus
    id
    itemType
    meeting {
      ...meetingLinkFields
    }
    milestone {
      ...milestoneLinkFields
    }
    name
    number
    options {
      ...itemLinkFields
    }
    parent {
      ...itemLinkFields
    }
    parentID
    remoteAssetCount
    scheduleImpact {
      ...scheduleImpactFields
    }
    status
    visibility
    updateTime
    hasEstimate
  }
  ${CategoryLinkFieldsFragmentDoc}
  ${UserLinkFieldsWithoutPictureFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
  ${MilestoneLinkFieldsFragmentDoc}
  ${MeetingLinkFieldsFragmentDoc}
  ${ItemLinkFieldsFragmentDoc}
  ${ScheduleImpactFieldsFragmentDoc}
`;
export const AddsDeductsFieldsFragmentDoc = gql`
  fragment addsDeductsFields on AddDeductCost {
    adds
    deducts
  }
`;
export const CommonCategorizedItemsTreeNodeCostsFieldsFragmentDoc = gql`
  fragment commonCategorizedItemsTreeNodeCostsFields on ItemsTreeBranchNodeCosts {
    pendingAddsDeductsCost {
      ...addsDeductsFields
    }
    rejectedAddsDeductsCost {
      ...addsDeductsFields
    }
    acceptedCost {
      ...costTypeFields
    }
    targetCost {
      ...costTypeFields
    }
    runningTotalCost {
      ...costTypeFields
    }
  }
  ${AddsDeductsFieldsFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
`;
export const CommonItemsTreeOptionFieldsFragmentDoc = gql`
  fragment commonItemsTreeOptionFields on ItemsTreeOption {
    itemLikeID
    cost {
      ...costTypeFields
    }
  }
  ${CostTypeFieldsFragmentDoc}
`;
export const CommonItemsTreeItemsFieldsFragmentDoc = gql`
  fragment commonItemsTreeItemsFields on ItemsTreeItem {
    itemLikeID
    cost {
      ...costTypeFields
    }
    options {
      ...commonItemsTreeOptionFields
    }
  }
  ${CostTypeFieldsFragmentDoc}
  ${CommonItemsTreeOptionFieldsFragmentDoc}
`;
export const CommonCategorizedItemsTreeBranchFieldsFragmentDoc = gql`
  fragment commonCategorizedItemsTreeBranchFields on ItemsTreeBranch {
    key
    displayName
    displayNameForPrint
    level
    nodeCosts {
      ...commonCategorizedItemsTreeNodeCostsFields
    }
    totalItems
    items {
      ...commonItemsTreeItemsFields
    }
  }
  ${CommonCategorizedItemsTreeNodeCostsFieldsFragmentDoc}
  ${CommonItemsTreeItemsFieldsFragmentDoc}
`;
export const GridEstimateLineIDsFieldsFragmentDoc = gql`
  fragment gridEstimateLineIDsFields on Estimate {
    lineIDs
  }
`;
export const GridLineFieldsFragmentDoc = gql`
  fragment gridLineFields on Line {
    id
    cells {
      ...gridCellFields
    }
    orderingNumerator
    orderingDenominator
  }
  ${GridCellFieldsFragmentDoc}
`;
export const GridEstimateLinesFieldsFragmentDoc = gql`
  fragment gridEstimateLinesFields on Estimate {
    lines {
      ...gridLineFields
    }
  }
  ${GridLineFieldsFragmentDoc}
`;
export const CommonMilestonePublishDraftFieldsFragmentDoc = gql`
  fragment commonMilestonePublishDraftFields on Milestone {
    id
    name
    description
    deletable
    date
    isDraft
    createdBy {
      id
      email
      name
    }
  }
`;
export const SourceMilestoneFieldsFragmentDoc = gql`
  fragment sourceMilestoneFields on Milestone {
    id
    name
  }
`;
export const CommonBucketFieldsFragmentDoc = gql`
  fragment commonBucketFields on Bucket {
    id
    milestone {
      id
      name
      date
    }
    name
    date
    itemsCount
  }
`;
export const DetailedPrintItemFieldsFragmentDoc = gql`
  fragment detailedPrintItemFields on Item {
    ...detailedItemLikeItemFields
    options {
      ...detailedOptionFields
      unfilteredCost {
        ...costTypeFields
      }
    }
    optionSubtotals {
      status
      adds
      deducts
    }
    unfilteredCost {
      ...costTypeFields
    }
  }
  ${DetailedItemLikeItemFieldsFragmentDoc}
  ${DetailedOptionFieldsFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
`;
export const CommonProjectSettingFragmentDoc = gql`
  fragment commonProjectSetting on ProjectSetting {
    key
    value
    validValues
  }
`;
export const CommonTerminologyFragmentDoc = gql`
  fragment commonTerminology on Terminology {
    key
    maxLength
    override
    overrideValue
  }
`;
export const ItemInScenarioFieldsFragmentDoc = gql`
  fragment itemInScenarioFields on ItemsListItem {
    cost {
      ...costTypeFields
    }
    id
    creator {
      id
      name
      picture
      status
    }
    createdAt
    isOverlay
    overlay {
      baseStatus
    }
    name
    number
    parentID
    status
    options {
      ...itemLinkFields
    }
    itemType
    visibility
  }
  ${CostTypeFieldsFragmentDoc}
  ${ItemLinkFieldsFragmentDoc}
`;
export const ItemsTimelineItemFieldsFragmentDoc = gql`
  fragment itemsTimelineItemFields on ItemsListItem {
    dueDate
    id
    name
    status
  }
`;
export const ListCompanyFieldsFragmentDoc = gql`
  fragment listCompanyFields on Company {
    id
    name
    type
    domain
    deactivated
  }
`;
export const ListCompanyUserFieldsQueryFragmentDoc = gql`
  fragment listCompanyUserFieldsQuery on CompanyUser {
    company {
      ...listCompanyFields
    }
    isIndependent
    matchedCompany {
      ...listCompanyFields
    }
    role {
      id
      name
    }
    user {
      email
      id
      name
      phone
      picture
      didSetup
      pictureAsset {
        thumbnailURL
      }
      jobTitle
      status
      activeAt
    }
  }
  ${ListCompanyFieldsFragmentDoc}
`;
export const CommonCompanyFieldsFragmentDoc = gql`
  fragment commonCompanyFields on Company {
    id
    name
    type
    domain
    assetID
  }
`;
export const SetDisabledProjectCategorizationDocument = gql`
  mutation setDisabledProjectCategorization(
    $projectID: UUID!
    $categorizationID: UUID!
    $disabled: Boolean!
  ) {
    setDisabledProjectCategorization(
      projectID: $projectID
      categorizationID: $categorizationID
      disabled: $disabled
    ) {
      projectID
      categorization {
        ...commonCategorizationFields
      }
      disabled
    }
  }
  ${CommonCategorizationFieldsFragmentDoc}
`;
export type SetDisabledProjectCategorizationMutationFn = Apollo.MutationFunction<
  SetDisabledProjectCategorizationMutation,
  SetDisabledProjectCategorizationMutationVariables
>;
export type SetDisabledProjectCategorizationMutationResult =
  Apollo.MutationResult<SetDisabledProjectCategorizationMutation>;
export type SetDisabledProjectCategorizationMutationOptions = Apollo.BaseMutationOptions<
  SetDisabledProjectCategorizationMutation,
  SetDisabledProjectCategorizationMutationVariables
>;
export const ReorderCategorizationDocument = gql`
  mutation reorderCategorization($projectID: UUID!, $categorizationID: UUID!, $ordering: Int!) {
    reorderCategorization(
      projectID: $projectID
      categorizationID: $categorizationID
      ordering: $ordering
    )
  }
`;
export type ReorderCategorizationMutationFn = Apollo.MutationFunction<
  ReorderCategorizationMutation,
  ReorderCategorizationMutationVariables
>;
export type ReorderCategorizationMutationResult =
  Apollo.MutationResult<ReorderCategorizationMutation>;
export type ReorderCategorizationMutationOptions = Apollo.BaseMutationOptions<
  ReorderCategorizationMutation,
  ReorderCategorizationMutationVariables
>;
export const UsersByIDsDocument = gql`
  query usersByIDs($IDs: [UUID!]!) {
    usersByIDs(IDs: $IDs) {
      ...userLinkFieldsWithoutPicture
    }
  }
  ${UserLinkFieldsWithoutPictureFragmentDoc}
`;
export type UsersByIDsQueryResult = Apollo.QueryResult<UsersByIDsQuery, UsersByIDsQueryVariables>;
export const AssigneeByIdDocument = gql`
  query assigneeById($ID: UUID!) {
    userByID(ID: $ID) {
      ...userLinkFields
    }
  }
  ${UserLinkFieldsFragmentDoc}
`;
export type AssigneeByIdQueryResult = Apollo.QueryResult<
  AssigneeByIdQuery,
  AssigneeByIdQueryVariables
>;
export const UserByIdDocument = gql`
  query userById($ID: UUID!) {
    userByID(ID: $ID) {
      ...userFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export const GetProjectNameDocument = gql`
  query getProjectName($projectID: UUID!) {
    project(id: $projectID) {
      id
      name
    }
  }
`;
export type GetProjectNameQueryResult = Apollo.QueryResult<
  GetProjectNameQuery,
  GetProjectNameQueryVariables
>;
export const LoadCollaboratorsDocument = gql`
  query loadCollaborators($projectId: UUID!) {
    collaborators(project: $projectId) {
      ...commonCollaboratorFields
    }
  }
  ${CommonCollaboratorFieldsFragmentDoc}
`;
export type LoadCollaboratorsQueryResult = Apollo.QueryResult<
  LoadCollaboratorsQuery,
  LoadCollaboratorsQueryVariables
>;
export const LoadCollaboratorsMultipleProjectsDocument = gql`
  query loadCollaboratorsMultipleProjects($projectIDs: [UUID!]!) {
    loadCollaboratorsMultipleProjects(projectIDs: $projectIDs) {
      collaborators {
        ...commonCollaboratorFields
      }
      projectID
    }
  }
  ${CommonCollaboratorFieldsFragmentDoc}
`;
export type LoadCollaboratorsMultipleProjectsQueryResult = Apollo.QueryResult<
  LoadCollaboratorsMultipleProjectsQuery,
  LoadCollaboratorsMultipleProjectsQueryVariables
>;
export const CreateCollaboratorsDocument = gql`
  mutation createCollaborators(
    $projectID: UUID!
    $collaborators: [CollaboratorInput!]!
    $message: String
    $disableNotifications: Boolean
  ) {
    addCollaborators(
      projectID: $projectID
      collaborators: $collaborators
      message: $message
      disableNotifications: $disableNotifications
    ) {
      ...commonCollaboratorFields
    }
  }
  ${CommonCollaboratorFieldsFragmentDoc}
`;
export type CreateCollaboratorsMutationFn = Apollo.MutationFunction<
  CreateCollaboratorsMutation,
  CreateCollaboratorsMutationVariables
>;
export type CreateCollaboratorsMutationResult = Apollo.MutationResult<CreateCollaboratorsMutation>;
export type CreateCollaboratorsMutationOptions = Apollo.BaseMutationOptions<
  CreateCollaboratorsMutation,
  CreateCollaboratorsMutationVariables
>;
export const UpdateCollaboratorAllTradesDocument = gql`
  mutation updateCollaboratorAllTrades(
    $projectID: UUID!
    $collaboratorId: UUID!
    $tradeSetting: Boolean!
  ) {
    setCollaboratorAllTrades(
      projectID: $projectID
      collaboratorID: $collaboratorId
      allTrades: $tradeSetting
    ) {
      ...commonCollaboratorFields
    }
  }
  ${CommonCollaboratorFieldsFragmentDoc}
`;
export type UpdateCollaboratorAllTradesMutationFn = Apollo.MutationFunction<
  UpdateCollaboratorAllTradesMutation,
  UpdateCollaboratorAllTradesMutationVariables
>;
export type UpdateCollaboratorAllTradesMutationResult =
  Apollo.MutationResult<UpdateCollaboratorAllTradesMutation>;
export type UpdateCollaboratorAllTradesMutationOptions = Apollo.BaseMutationOptions<
  UpdateCollaboratorAllTradesMutation,
  UpdateCollaboratorAllTradesMutationVariables
>;
export const UpdateCollaboratorRoleDocument = gql`
  mutation updateCollaboratorRole(
    $projectID: UUID!
    $collaboratorID: UUID!
    $roleID: UUID!
    $tradeIDs: [UUID!]
  ) {
    setCollaboratorRole(
      projectID: $projectID
      collaboratorID: $collaboratorID
      roleID: $roleID
      tradeIDs: $tradeIDs
    ) {
      ...commonCollaboratorFields
    }
  }
  ${CommonCollaboratorFieldsFragmentDoc}
`;
export type UpdateCollaboratorRoleMutationFn = Apollo.MutationFunction<
  UpdateCollaboratorRoleMutation,
  UpdateCollaboratorRoleMutationVariables
>;
export type UpdateCollaboratorRoleMutationResult =
  Apollo.MutationResult<UpdateCollaboratorRoleMutation>;
export type UpdateCollaboratorRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateCollaboratorRoleMutation,
  UpdateCollaboratorRoleMutationVariables
>;
export const UpdateCollaboratorResponsibilityDocument = gql`
  mutation updateCollaboratorResponsibility(
    $projectID: UUID!
    $collaboratorID: UUID!
    $responsibility: String!
  ) {
    setCollaboratorResponsibility(
      projectID: $projectID
      collaboratorID: $collaboratorID
      responsibility: $responsibility
    ) {
      ...commonCollaboratorFields
    }
  }
  ${CommonCollaboratorFieldsFragmentDoc}
`;
export type UpdateCollaboratorResponsibilityMutationFn = Apollo.MutationFunction<
  UpdateCollaboratorResponsibilityMutation,
  UpdateCollaboratorResponsibilityMutationVariables
>;
export type UpdateCollaboratorResponsibilityMutationResult =
  Apollo.MutationResult<UpdateCollaboratorResponsibilityMutation>;
export type UpdateCollaboratorResponsibilityMutationOptions = Apollo.BaseMutationOptions<
  UpdateCollaboratorResponsibilityMutation,
  UpdateCollaboratorResponsibilityMutationVariables
>;
export const SetCollaboratorTradesDocument = gql`
  mutation setCollaboratorTrades(
    $allTrades: Boolean!
    $categoryIDs: [UUID!]!
    $collaboratorID: UUID!
    $projectID: UUID!
  ) {
    setCollaboratorTrades(
      allTrades: $allTrades
      categoryIDs: $categoryIDs
      collaboratorID: $collaboratorID
      projectID: $projectID
    )
  }
`;
export type SetCollaboratorTradesMutationFn = Apollo.MutationFunction<
  SetCollaboratorTradesMutation,
  SetCollaboratorTradesMutationVariables
>;
export type SetCollaboratorTradesMutationResult =
  Apollo.MutationResult<SetCollaboratorTradesMutation>;
export type SetCollaboratorTradesMutationOptions = Apollo.BaseMutationOptions<
  SetCollaboratorTradesMutation,
  SetCollaboratorTradesMutationVariables
>;
export const DeleteCollaboratorDocument = gql`
  mutation deleteCollaborator($projectID: UUID!, $collaboratorId: UUID!) {
    removeCollaborator(projectID: $projectID, collaborator: $collaboratorId)
  }
`;
export type DeleteCollaboratorMutationFn = Apollo.MutationFunction<
  DeleteCollaboratorMutation,
  DeleteCollaboratorMutationVariables
>;
export type DeleteCollaboratorMutationResult = Apollo.MutationResult<DeleteCollaboratorMutation>;
export type DeleteCollaboratorMutationOptions = Apollo.BaseMutationOptions<
  DeleteCollaboratorMutation,
  DeleteCollaboratorMutationVariables
>;
export const AddCollaboratorTradesDocument = gql`
  mutation addCollaboratorTrades(
    $projectID: UUID!
    $collaboratorID: UUID!
    $categoryIDs: [UUID!]!
  ) {
    addTrades(projectID: $projectID, collaboratorID: $collaboratorID, categoryIDs: $categoryIDs) {
      ...commonCollaboratorFields
    }
  }
  ${CommonCollaboratorFieldsFragmentDoc}
`;
export type AddCollaboratorTradesMutationFn = Apollo.MutationFunction<
  AddCollaboratorTradesMutation,
  AddCollaboratorTradesMutationVariables
>;
export type AddCollaboratorTradesMutationResult =
  Apollo.MutationResult<AddCollaboratorTradesMutation>;
export type AddCollaboratorTradesMutationOptions = Apollo.BaseMutationOptions<
  AddCollaboratorTradesMutation,
  AddCollaboratorTradesMutationVariables
>;
export const RemoveCollaboratorTradesDocument = gql`
  mutation removeCollaboratorTrades(
    $projectID: UUID!
    $collaboratorID: UUID!
    $categoryIDs: [UUID!]!
  ) {
    removeTrades(
      projectID: $projectID
      collaboratorID: $collaboratorID
      categoryIDs: $categoryIDs
    ) {
      ...commonCollaboratorFields
    }
  }
  ${CommonCollaboratorFieldsFragmentDoc}
`;
export type RemoveCollaboratorTradesMutationFn = Apollo.MutationFunction<
  RemoveCollaboratorTradesMutation,
  RemoveCollaboratorTradesMutationVariables
>;
export type RemoveCollaboratorTradesMutationResult =
  Apollo.MutationResult<RemoveCollaboratorTradesMutation>;
export type RemoveCollaboratorTradesMutationOptions = Apollo.BaseMutationOptions<
  RemoveCollaboratorTradesMutation,
  RemoveCollaboratorTradesMutationVariables
>;
export const SetProjectLeadDocument = gql`
  mutation SetProjectLead($projectID: UUID!, $userID: UUID!) {
    setProjectLead(projectID: $projectID, userID: $userID) {
      updated {
        id
        isProjectLead
      }
    }
  }
`;
export type SetProjectLeadMutationFn = Apollo.MutationFunction<
  SetProjectLeadMutation,
  SetProjectLeadMutationVariables
>;
export type SetProjectLeadMutationResult = Apollo.MutationResult<SetProjectLeadMutation>;
export type SetProjectLeadMutationOptions = Apollo.BaseMutationOptions<
  SetProjectLeadMutation,
  SetProjectLeadMutationVariables
>;
export const SetProjectsLeadDocument = gql`
  mutation SetProjectsLead($projectIDs: [UUID!]!, $userID: UUID!) {
    setProjectsLead(projectIDs: $projectIDs, userID: $userID) {
      updated {
        id
        isProjectLead
      }
    }
  }
`;
export type SetProjectsLeadMutationFn = Apollo.MutationFunction<
  SetProjectsLeadMutation,
  SetProjectsLeadMutationVariables
>;
export type SetProjectsLeadMutationResult = Apollo.MutationResult<SetProjectsLeadMutation>;
export type SetProjectsLeadMutationOptions = Apollo.BaseMutationOptions<
  SetProjectsLeadMutation,
  SetProjectsLeadMutationVariables
>;
export const RemoveProjectLeadDocument = gql`
  mutation RemoveProjectLead($projectID: UUID!, $userID: UUID!) {
    removeProjectLead(projectID: $projectID, userID: $userID) {
      updated {
        id
        isProjectLead
      }
    }
  }
`;
export type RemoveProjectLeadMutationFn = Apollo.MutationFunction<
  RemoveProjectLeadMutation,
  RemoveProjectLeadMutationVariables
>;
export type RemoveProjectLeadMutationResult = Apollo.MutationResult<RemoveProjectLeadMutation>;
export type RemoveProjectLeadMutationOptions = Apollo.BaseMutationOptions<
  RemoveProjectLeadMutation,
  RemoveProjectLeadMutationVariables
>;
export const RemoveProjectsLeadDocument = gql`
  mutation RemoveProjectsLead($projectIDs: [UUID!]!) {
    removeProjectsLead(projectIDs: $projectIDs) {
      updated {
        id
        isProjectLead
      }
    }
  }
`;
export type RemoveProjectsLeadMutationFn = Apollo.MutationFunction<
  RemoveProjectsLeadMutation,
  RemoveProjectsLeadMutationVariables
>;
export type RemoveProjectsLeadMutationResult = Apollo.MutationResult<RemoveProjectsLeadMutation>;
export type RemoveProjectsLeadMutationOptions = Apollo.BaseMutationOptions<
  RemoveProjectsLeadMutation,
  RemoveProjectsLeadMutationVariables
>;
export const CreateItemCommentDocument = gql`
  mutation createItemComment($projectID: UUID!, $comment: ItemCommentInput, $costMode: CostMode) {
    commentOnItem(projectID: $projectID, comment: $comment, costMode: $costMode) {
      ...commonCommentFields
    }
  }
  ${CommonCommentFieldsFragmentDoc}
`;
export type CreateItemCommentMutationFn = Apollo.MutationFunction<
  CreateItemCommentMutation,
  CreateItemCommentMutationVariables
>;
export type CreateItemCommentMutationResult = Apollo.MutationResult<CreateItemCommentMutation>;
export type CreateItemCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateItemCommentMutation,
  CreateItemCommentMutationVariables
>;
export const CreateCompanyCategorizationDocument = gql`
  mutation createCompanyCategorization($companyID: UUID!, $name: String!, $description: String) {
    createCompanyCategorization(companyID: $companyID, name: $name, description: $description) {
      ...commonCategorizationFields
    }
  }
  ${CommonCategorizationFieldsFragmentDoc}
`;
export type CreateCompanyCategorizationMutationFn = Apollo.MutationFunction<
  CreateCompanyCategorizationMutation,
  CreateCompanyCategorizationMutationVariables
>;
export type CreateCompanyCategorizationMutationResult =
  Apollo.MutationResult<CreateCompanyCategorizationMutation>;
export type CreateCompanyCategorizationMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyCategorizationMutation,
  CreateCompanyCategorizationMutationVariables
>;
export const CompanyCategorizationsDocument = gql`
  query companyCategorizations($companyID: UUID!) {
    companyCategorizations(companyID: $companyID) {
      companyID
      categorization {
        ...commonCategorizationFields
        createdFrom {
          id
        }
        modifiedBy {
          id
          name
        }
        importMetadata {
          columnLevel1
          columnLevel2
          columnLevel3
          columnLevel4
        }
        modifiedAt
      }
      disabled
    }
  }
  ${CommonCategorizationFieldsFragmentDoc}
`;
export type CompanyCategorizationsQueryResult = Apollo.QueryResult<
  CompanyCategorizationsQuery,
  CompanyCategorizationsQueryVariables
>;
export const DeleteCompanyCategorizationDocument = gql`
  mutation deleteCompanyCategorization($categorizationID: UUID!) {
    deleteCompanyCategorization(categorizationID: $categorizationID)
  }
`;
export type DeleteCompanyCategorizationMutationFn = Apollo.MutationFunction<
  DeleteCompanyCategorizationMutation,
  DeleteCompanyCategorizationMutationVariables
>;
export type DeleteCompanyCategorizationMutationResult =
  Apollo.MutationResult<DeleteCompanyCategorizationMutation>;
export type DeleteCompanyCategorizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyCategorizationMutation,
  DeleteCompanyCategorizationMutationVariables
>;
export const ToggleBuiltInCategorizationDocument = gql`
  mutation toggleBuiltInCategorization($categorizationID: UUID!, $disabled: Boolean!) {
    toggleBuiltInCategorization(categorizationID: $categorizationID, disabled: $disabled)
  }
`;
export type ToggleBuiltInCategorizationMutationFn = Apollo.MutationFunction<
  ToggleBuiltInCategorizationMutation,
  ToggleBuiltInCategorizationMutationVariables
>;
export type ToggleBuiltInCategorizationMutationResult =
  Apollo.MutationResult<ToggleBuiltInCategorizationMutation>;
export type ToggleBuiltInCategorizationMutationOptions = Apollo.BaseMutationOptions<
  ToggleBuiltInCategorizationMutation,
  ToggleBuiltInCategorizationMutationVariables
>;
export const AddCompanyCategorizationsDocument = gql`
  mutation addCompanyCategorizations($projectID: UUID!, $categorizationIDs: [UUID!]!) {
    addCompanyCategorizationsToProject(
      projectID: $projectID
      categorizationIDs: $categorizationIDs
    ) {
      projectID
      categorization {
        ...commonCategorizationFields
      }
      disabled
    }
  }
  ${CommonCategorizationFieldsFragmentDoc}
`;
export type AddCompanyCategorizationsMutationFn = Apollo.MutationFunction<
  AddCompanyCategorizationsMutation,
  AddCompanyCategorizationsMutationVariables
>;
export type AddCompanyCategorizationsMutationResult =
  Apollo.MutationResult<AddCompanyCategorizationsMutation>;
export type AddCompanyCategorizationsMutationOptions = Apollo.BaseMutationOptions<
  AddCompanyCategorizationsMutation,
  AddCompanyCategorizationsMutationVariables
>;
export const CreateProjectTemplateDocument = gql`
  mutation createProjectTemplate($name: String!, $templateID: UUID) {
    createProjectTemplate(name: $name, templateID: $templateID) {
      id
      name
    }
  }
`;
export type CreateProjectTemplateMutationFn = Apollo.MutationFunction<
  CreateProjectTemplateMutation,
  CreateProjectTemplateMutationVariables
>;
export type CreateProjectTemplateMutationResult =
  Apollo.MutationResult<CreateProjectTemplateMutation>;
export type CreateProjectTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectTemplateMutation,
  CreateProjectTemplateMutationVariables
>;
export const DeleteProjectTemplateDocument = gql`
  mutation deleteProjectTemplate($templateID: UUID!) {
    deleteProjectTemplate(templateID: $templateID)
  }
`;
export type DeleteProjectTemplateMutationFn = Apollo.MutationFunction<
  DeleteProjectTemplateMutation,
  DeleteProjectTemplateMutationVariables
>;
export type DeleteProjectTemplateMutationResult =
  Apollo.MutationResult<DeleteProjectTemplateMutation>;
export type DeleteProjectTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectTemplateMutation,
  DeleteProjectTemplateMutationVariables
>;
export const SetProjectTemplatePublicationDocument = gql`
  mutation setProjectTemplatePublication($templateID: UUID!, $published: Boolean!) {
    setProjectTemplatePublication(templateID: $templateID, published: $published) {
      id
      isDraft
    }
  }
`;
export type SetProjectTemplatePublicationMutationFn = Apollo.MutationFunction<
  SetProjectTemplatePublicationMutation,
  SetProjectTemplatePublicationMutationVariables
>;
export type SetProjectTemplatePublicationMutationResult =
  Apollo.MutationResult<SetProjectTemplatePublicationMutation>;
export type SetProjectTemplatePublicationMutationOptions = Apollo.BaseMutationOptions<
  SetProjectTemplatePublicationMutation,
  SetProjectTemplatePublicationMutationVariables
>;
export const ProjectTemplateDocument = gql`
  query projectTemplate($projectID: UUID!) {
    projectTemplate(projectID: $projectID) {
      id
      name
      isDraft
    }
  }
`;
export type ProjectTemplateQueryResult = Apollo.QueryResult<
  ProjectTemplateQuery,
  ProjectTemplateQueryVariables
>;
export const ProjectTemplatesDocument = gql`
  query projectTemplates($companyID: UUID!, $showDrafts: Boolean!) {
    projectTemplates(companyID: $companyID, showDrafts: $showDrafts) {
      id
      name
      isDraft
      project {
        id
        name
        code
        thumbnail
        location
        lat
        lon
        activeMilestone {
          id
          name
          date
          designPhase {
            id
            name
            abbreviation
          }
        }
        status {
          id
        }
        type {
          id
        }
        projectDeliveryType {
          ...commonProjectDeliveryTypeFields
        }
        locationDetails {
          ...commonLocationDetailsFields
        }
        orgNodes {
          id
          name
          parentID
        }
      }
      settings {
        ...commonProjectSetting
      }
    }
  }
  ${CommonProjectDeliveryTypeFieldsFragmentDoc}
  ${CommonLocationDetailsFieldsFragmentDoc}
  ${CommonProjectSettingFragmentDoc}
`;
export type ProjectTemplatesQueryResult = Apollo.QueryResult<
  ProjectTemplatesQuery,
  ProjectTemplatesQueryVariables
>;
export const UpdateProjectTemplateNameDocument = gql`
  mutation updateProjectTemplateName($templateID: UUID!, $name: String!) {
    updateProjectTemplateName(templateID: $templateID, name: $name) {
      id
      name
    }
  }
`;
export type UpdateProjectTemplateNameMutationFn = Apollo.MutationFunction<
  UpdateProjectTemplateNameMutation,
  UpdateProjectTemplateNameMutationVariables
>;
export type UpdateProjectTemplateNameMutationResult =
  Apollo.MutationResult<UpdateProjectTemplateNameMutation>;
export type UpdateProjectTemplateNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTemplateNameMutation,
  UpdateProjectTemplateNameMutationVariables
>;
export const CreateProjectTypeDocument = gql`
  mutation createProjectType($name: String!, $companyID: UUID!, $parentID: UUID) {
    createProjectType(name: $name, companyID: $companyID, parentID: $parentID) {
      ...commonProjectTypeFields
    }
  }
  ${CommonProjectTypeFieldsFragmentDoc}
`;
export type CreateProjectTypeMutationFn = Apollo.MutationFunction<
  CreateProjectTypeMutation,
  CreateProjectTypeMutationVariables
>;
export type CreateProjectTypeMutationResult = Apollo.MutationResult<CreateProjectTypeMutation>;
export type CreateProjectTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectTypeMutation,
  CreateProjectTypeMutationVariables
>;
export const UpdateCompanyDocument = gql`
  mutation updateCompany($companyID: UUID!, $name: String, $type: String, $assetID: UUID) {
    updateCompany(companyID: $companyID, name: $name, type: $type, assetID: $assetID) {
      ...commonCompanyFields
    }
  }
  ${CommonCompanyFieldsFragmentDoc}
`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
export const UpdateCompanyUserCompanyRoleDocument = gql`
  mutation updateCompanyUserCompanyRole($userID: UUID!, $roleID: UUID!) {
    updateCompanyUserCompanyRole(userID: $userID, roleID: $roleID) {
      ...commonCompanyUserFields
    }
  }
  ${CommonCompanyUserFieldsFragmentDoc}
`;
export type UpdateCompanyUserCompanyRoleMutationFn = Apollo.MutationFunction<
  UpdateCompanyUserCompanyRoleMutation,
  UpdateCompanyUserCompanyRoleMutationVariables
>;
export type UpdateCompanyUserCompanyRoleMutationResult =
  Apollo.MutationResult<UpdateCompanyUserCompanyRoleMutation>;
export type UpdateCompanyUserCompanyRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyUserCompanyRoleMutation,
  UpdateCompanyUserCompanyRoleMutationVariables
>;
export const UpdateCompanyUserNameDocument = gql`
  mutation updateCompanyUserName($userID: UUID!, $name: String!) {
    updateCompanyUserName(userID: $userID, name: $name) {
      id
      name
      phone
      picture
      email
      jobTitle
    }
  }
`;
export type UpdateCompanyUserNameMutationFn = Apollo.MutationFunction<
  UpdateCompanyUserNameMutation,
  UpdateCompanyUserNameMutationVariables
>;
export type UpdateCompanyUserNameMutationResult =
  Apollo.MutationResult<UpdateCompanyUserNameMutation>;
export type UpdateCompanyUserNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyUserNameMutation,
  UpdateCompanyUserNameMutationVariables
>;
export const UpdateCompanyUserJobTitleDocument = gql`
  mutation updateCompanyUserJobTitle($userID: UUID!, $jobTitle: String!) {
    updateCompanyUserJobTitle(userID: $userID, jobTitle: $jobTitle) {
      id
      name
      phone
      picture
      email
      jobTitle
    }
  }
`;
export type UpdateCompanyUserJobTitleMutationFn = Apollo.MutationFunction<
  UpdateCompanyUserJobTitleMutation,
  UpdateCompanyUserJobTitleMutationVariables
>;
export type UpdateCompanyUserJobTitleMutationResult =
  Apollo.MutationResult<UpdateCompanyUserJobTitleMutation>;
export type UpdateCompanyUserJobTitleMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyUserJobTitleMutation,
  UpdateCompanyUserJobTitleMutationVariables
>;
export const UpdateCompanyUserPictureDocument = gql`
  mutation updateCompanyUserPicture($userID: UUID!, $picture: String!) {
    updateCompanyUserPicture(userID: $userID, picture: $picture) {
      ...commonUserFields
      status
    }
  }
  ${CommonUserFieldsFragmentDoc}
`;
export type UpdateCompanyUserPictureMutationFn = Apollo.MutationFunction<
  UpdateCompanyUserPictureMutation,
  UpdateCompanyUserPictureMutationVariables
>;
export type UpdateCompanyUserPictureMutationResult =
  Apollo.MutationResult<UpdateCompanyUserPictureMutation>;
export type UpdateCompanyUserPictureMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyUserPictureMutation,
  UpdateCompanyUserPictureMutationVariables
>;
export const UpdateCompanyUserStatusDocument = gql`
  mutation updateCompanyUserStatus($userID: UUID!, $status: UserStatus!) {
    updateCompanyUserStatus(userID: $userID, status: $status) {
      id
      name
      phone
      picture
      email
      jobTitle
      status
    }
  }
`;
export type UpdateCompanyUserStatusMutationFn = Apollo.MutationFunction<
  UpdateCompanyUserStatusMutation,
  UpdateCompanyUserStatusMutationVariables
>;
export type UpdateCompanyUserStatusMutationResult =
  Apollo.MutationResult<UpdateCompanyUserStatusMutation>;
export type UpdateCompanyUserStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyUserStatusMutation,
  UpdateCompanyUserStatusMutationVariables
>;
export const CompanyUserDetailsDocument = gql`
  query companyUserDetails($userID: UUID!, $sortBy: UserProjectSortBy) {
    companyUserDetails(userID: $userID, sortBy: $sortBy) {
      user {
        ...commonUserFields
        activeAt
        isEmailVerified
        status
      }
      role {
        id
        name
        companyID
      }
      companyProjects {
        ...userProjectFields
      }
      outsideProjects {
        ...userProjectFields
      }
    }
  }
  ${CommonUserFieldsFragmentDoc}
  ${UserProjectFieldsFragmentDoc}
`;
export type CompanyUserDetailsQueryResult = Apollo.QueryResult<
  CompanyUserDetailsQuery,
  CompanyUserDetailsQueryVariables
>;
export const CompanyCollaboratorDetailsDocument = gql`
  query companyCollaboratorDetails($userID: UUID!, $companyID: UUID!, $sortBy: UserProjectSortBy) {
    companyCollaboratorDetails(userID: $userID, companyID: $companyID, sortBy: $sortBy) {
      user {
        ...commonUserFields
        activeAt
        isEmailVerified
        status
      }
      role {
        id
        name
        companyID
      }
      companyProjects {
        ...userProjectFields
      }
      outsideProjects {
        ...userProjectFields
      }
    }
  }
  ${CommonUserFieldsFragmentDoc}
  ${UserProjectFieldsFragmentDoc}
`;
export type CompanyCollaboratorDetailsQueryResult = Apollo.QueryResult<
  CompanyCollaboratorDetailsQuery,
  CompanyCollaboratorDetailsQueryVariables
>;
export const CompanyProjectStatsDocument = gql`
  query companyProjectStats(
    $companyID: UUID!
    $filters: ProjectsFilterBy!
    $sortBy: ProjectsSortBy!
  ) {
    companyProjectStats(companyID: $companyID, filters: $filters, sortBy: $sortBy) {
      id
      name
      type
      projectType {
        id
        name
      }
      location
      createdAt
      status
      estimate
      runningTotal
      budget
      createdBy {
        company {
          id
        }
        user {
          id
          name
        }
      }
      projectLead {
        id
      }
      milestoneCount
      itemCount
      teamCount
      orgNodes {
        id
        name
      }
    }
  }
`;
export type CompanyProjectStatsQueryResult = Apollo.QueryResult<
  CompanyProjectStatsQuery,
  CompanyProjectStatsQueryVariables
>;
export const CompanyRolesDocument = gql`
  query companyRoles($companyID: UUID!) {
    companyRoles(companyID: $companyID) {
      id
      name
    }
  }
`;
export type CompanyRolesQueryResult = Apollo.QueryResult<
  CompanyRolesQuery,
  CompanyRolesQueryVariables
>;
export const CompaniesDocument = gql`
  query companies {
    companies {
      id
      name
    }
  }
`;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const AddCompanyEmployeeDocument = gql`
  mutation addCompanyEmployee($input: CompanyEmployeeInput!) {
    addCompanyEmployee(input: $input) {
      ...commonCompanyUserFields
    }
  }
  ${CommonCompanyUserFieldsFragmentDoc}
`;
export type AddCompanyEmployeeMutationFn = Apollo.MutationFunction<
  AddCompanyEmployeeMutation,
  AddCompanyEmployeeMutationVariables
>;
export type AddCompanyEmployeeMutationResult = Apollo.MutationResult<AddCompanyEmployeeMutation>;
export type AddCompanyEmployeeMutationOptions = Apollo.BaseMutationOptions<
  AddCompanyEmployeeMutation,
  AddCompanyEmployeeMutationVariables
>;
export const CompanyProjectRolesDocument = gql`
  query companyProjectRoles($companyID: UUID!) {
    companyProjectRoles(companyID: $companyID) {
      id
      name
      roles {
        id
        name
      }
    }
  }
`;
export type CompanyProjectRolesQueryResult = Apollo.QueryResult<
  CompanyProjectRolesQuery,
  CompanyProjectRolesQueryVariables
>;
export const CurrentCompanyProjectRoleDocument = gql`
  query CurrentCompanyProjectRole($projectID: UUID!) {
    currentCompanyProjectRole(projectID: $projectID)
  }
`;
export type CurrentCompanyProjectRoleQueryResult = Apollo.QueryResult<
  CurrentCompanyProjectRoleQuery,
  CurrentCompanyProjectRoleQueryVariables
>;
export const UpdateCompanyProjectTypeDocument = gql`
  mutation UpdateCompanyProjectType($projectID: UUID!, $typeID: UUID!) {
    updateCompanyProjectType(projectID: $projectID, typeID: $typeID) {
      id
      type
      projectType {
        id
        name
      }
    }
  }
`;
export type UpdateCompanyProjectTypeMutationFn = Apollo.MutationFunction<
  UpdateCompanyProjectTypeMutation,
  UpdateCompanyProjectTypeMutationVariables
>;
export type UpdateCompanyProjectTypeMutationResult =
  Apollo.MutationResult<UpdateCompanyProjectTypeMutation>;
export type UpdateCompanyProjectTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyProjectTypeMutation,
  UpdateCompanyProjectTypeMutationVariables
>;
export const UpdateBulkCompanyProjectTypeDocument = gql`
  mutation UpdateBulkCompanyProjectType($projectIDs: [UUID!]!, $typeID: UUID!) {
    updateBulkCompanyProjectType(projectIDs: $projectIDs, typeID: $typeID) {
      id
      type
      projectType {
        id
        name
      }
    }
  }
`;
export type UpdateBulkCompanyProjectTypeMutationFn = Apollo.MutationFunction<
  UpdateBulkCompanyProjectTypeMutation,
  UpdateBulkCompanyProjectTypeMutationVariables
>;
export type UpdateBulkCompanyProjectTypeMutationResult =
  Apollo.MutationResult<UpdateBulkCompanyProjectTypeMutation>;
export type UpdateBulkCompanyProjectTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateBulkCompanyProjectTypeMutation,
  UpdateBulkCompanyProjectTypeMutationVariables
>;
export const UpdateProjectTypeDocument = gql`
  mutation UpdateProjectType($typeID: UUID!, $name: String!, $parentID: UUID) {
    updateProjectType(typeID: $typeID, name: $name, parentID: $parentID) {
      type {
        id
        name
        parentID
        hidden
      }
    }
  }
`;
export type UpdateProjectTypeMutationFn = Apollo.MutationFunction<
  UpdateProjectTypeMutation,
  UpdateProjectTypeMutationVariables
>;
export type UpdateProjectTypeMutationResult = Apollo.MutationResult<UpdateProjectTypeMutation>;
export type UpdateProjectTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTypeMutation,
  UpdateProjectTypeMutationVariables
>;
export const ToggleProjectTypeVisibilityDocument = gql`
  mutation ToggleProjectTypeVisibility($typeID: UUID!, $hide: Boolean) {
    toggleProjectTypeVisibility(typeID: $typeID, hide: $hide) {
      type {
        id
        name
        parentID
        hidden
      }
    }
  }
`;
export type ToggleProjectTypeVisibilityMutationFn = Apollo.MutationFunction<
  ToggleProjectTypeVisibilityMutation,
  ToggleProjectTypeVisibilityMutationVariables
>;
export type ToggleProjectTypeVisibilityMutationResult =
  Apollo.MutationResult<ToggleProjectTypeVisibilityMutation>;
export type ToggleProjectTypeVisibilityMutationOptions = Apollo.BaseMutationOptions<
  ToggleProjectTypeVisibilityMutation,
  ToggleProjectTypeVisibilityMutationVariables
>;
export const DeleteProjectTypeDocument = gql`
  mutation DeleteProjectType($typeID: UUID!) {
    deleteProjectType(typeID: $typeID)
  }
`;
export type DeleteProjectTypeMutationFn = Apollo.MutationFunction<
  DeleteProjectTypeMutation,
  DeleteProjectTypeMutationVariables
>;
export type DeleteProjectTypeMutationResult = Apollo.MutationResult<DeleteProjectTypeMutation>;
export type DeleteProjectTypeMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectTypeMutation,
  DeleteProjectTypeMutationVariables
>;
export const CompanyUsersDocument = gql`
  query companyUsers($companyID: UUID!, $filters: CompanyUserFilter, $sortBy: CompanyUserSortBy) {
    companyUsers(companyID: $companyID, filters: $filters, sortBy: $sortBy) {
      ...commonCompanyUserFields
    }
  }
  ${CommonCompanyUserFieldsFragmentDoc}
`;
export type CompanyUsersQueryResult = Apollo.QueryResult<
  CompanyUsersQuery,
  CompanyUsersQueryVariables
>;
export const CompanyCollaboratorsDocument = gql`
  query companyCollaborators(
    $companyID: UUID!
    $filters: CompanyCollaboratorFilter
    $userSortBy: CompanyUserSortBy
  ) {
    companyCollaborators(companyID: $companyID, filters: $filters, userSortBy: $userSortBy) {
      companyUser {
        ...listCompanyUserFieldsQuery
      }
    }
  }
  ${ListCompanyUserFieldsQueryFragmentDoc}
`;
export type CompanyCollaboratorsQueryResult = Apollo.QueryResult<
  CompanyCollaboratorsQuery,
  CompanyCollaboratorsQueryVariables
>;
export const GetContingencyReportDocument = gql`
  query getContingencyReport(
    $milestoneID: UUID!
    $projectID: UUID!
    $itemStatuses: [Status!]
    $filteredContingencies: [String!]
    $filteredAllowances: [String!]
  ) {
    contingencyReport(
      milestoneID: $milestoneID
      projectID: $projectID
      itemStatuses: $itemStatuses
      filteredContingencies: $filteredContingencies
      filteredAllowances: $filteredAllowances
    ) {
      name
      contingencyID
      displayType
      startingCost
      usedCost
      totalPendingCost
      totalDrawnCost
      percentOfMilestoneEstimate
      rows {
        itemID
        status
        name
        number
        drawCost
        acceptedBy
        dateAccepted
        createdBy
        dateCreated
      }
    }
  }
`;
export type GetContingencyReportQueryResult = Apollo.QueryResult<
  GetContingencyReportQuery,
  GetContingencyReportQueryVariables
>;
export const GetAllMilestonesContingencyReportDocument = gql`
  query getAllMilestonesContingencyReport(
    $projectID: UUID!
    $filteredContingencies: [String!]
    $filteredAllowances: [String!]
  ) {
    multiMilestoneContingencyReport(
      projectID: $projectID
      filteredContingencies: $filteredContingencies
      filteredAllowances: $filteredAllowances
    ) {
      milestones {
        name
        startDate
        isActiveMilestone
      }
      contingencies {
        name
        type
        initialAmountSet
        initialPercentOfMilestoneEstimate
        report {
          startingCost
          usedCost
          totalPendingCost
          totalDrawnCost
          percentOfMilestoneEstimate
          acceptedItemCount
          existsInAllMilestonesReportMilestone
        }
      }
    }
  }
`;
export type GetAllMilestonesContingencyReportQueryResult = Apollo.QueryResult<
  GetAllMilestonesContingencyReportQuery,
  GetAllMilestonesContingencyReportQueryVariables
>;
export const CopyItemDataDocument = gql`
  query copyItemData($itemID: UUID!, $projectID: UUID) {
    copyItemData(itemID: $itemID, projectID: $projectID) {
      convertedItemCategories {
        ...commonCategoryFields
      }
      itemEstimateCategorizations {
        ...commonCategorizationFields
      }
    }
  }
  ${CommonCategoryFieldsFragmentDoc}
  ${CommonCategorizationFieldsFragmentDoc}
`;
export type CopyItemDataQueryResult = Apollo.QueryResult<
  CopyItemDataQuery,
  CopyItemDataQueryVariables
>;
export const GetMilestonesMarkupsDocument = gql`
  query getMilestonesMarkups($projectID: UUID!) {
    milestonesMarkups(projectID: $projectID) {
      id
      activeEstimate {
        markups {
          name
          id
          estimateId
          displayType
        }
        incorporatedMarkups {
          name
          id
          estimateId
          displayType
        }
      }
      budget {
        markups {
          name
          id
          estimateId
          displayType
        }
        incorporatedMarkups {
          name
          id
          estimateId
          displayType
        }
      }
    }
  }
`;
export type GetMilestonesMarkupsQueryResult = Apollo.QueryResult<
  GetMilestonesMarkupsQuery,
  GetMilestonesMarkupsQueryVariables
>;
export const MinimalCostReportsDocument = gql`
  query minimalCostReports(
    $milestones: [CostReportMilestoneInput!]!
    $columns: [CostReportColumnInput!]!
    $groupByItems: [GroupByItem!]!
    $costMode: CostMode!
    $projectID: UUID!
    $shouldIncludeOwnerCosts: Boolean
    $viewFilter: ViewFilter!
    $useDraftEstimate: Boolean!
    $useDraftBudget: Boolean!
  ) {
    milestoneCostReports(
      parameters: {
        milestones: $milestones
        columns: $columns
        groupByItems: $groupByItems
        costMode: $costMode
        projectID: $projectID
        shouldIncludeOwnerCosts: $shouldIncludeOwnerCosts
        viewFilter: $viewFilter
        useDraftEstimate: $useDraftEstimate
        useDraftBudget: $useDraftBudget
      }
    ) {
      milestoneID
      costReportColumns {
        ...commonCostReportColumnFields
      }
    }
  }
  ${CommonCostReportColumnFieldsFragmentDoc}
`;
export type MinimalCostReportsQueryResult = Apollo.QueryResult<
  MinimalCostReportsQuery,
  MinimalCostReportsQueryVariables
>;
export const IsJoinSupportDocument = gql`
  query isJoinSupport {
    isJoinSupport
  }
`;
export type IsJoinSupportQueryResult = Apollo.QueryResult<
  IsJoinSupportQuery,
  IsJoinSupportQueryVariables
>;
export const UpdateUserNameDocument = gql`
  mutation updateUserName($name: String!) {
    updateUserName(name: $name) {
      id
      name
      phone
      picture
      email
      jobTitle
    }
  }
`;
export type UpdateUserNameMutationFn = Apollo.MutationFunction<
  UpdateUserNameMutation,
  UpdateUserNameMutationVariables
>;
export type UpdateUserNameMutationResult = Apollo.MutationResult<UpdateUserNameMutation>;
export type UpdateUserNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserNameMutation,
  UpdateUserNameMutationVariables
>;
export const UpdateUserJobTitleDocument = gql`
  mutation updateUserJobTitle($jobTitle: String!) {
    updateUserJobTitle(jobTitle: $jobTitle) {
      id
      name
      phone
      picture
      email
      jobTitle
    }
  }
`;
export type UpdateUserJobTitleMutationFn = Apollo.MutationFunction<
  UpdateUserJobTitleMutation,
  UpdateUserJobTitleMutationVariables
>;
export type UpdateUserJobTitleMutationResult = Apollo.MutationResult<UpdateUserJobTitleMutation>;
export type UpdateUserJobTitleMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserJobTitleMutation,
  UpdateUserJobTitleMutationVariables
>;
export const UpdateUserPictureDocument = gql`
  mutation updateUserPicture($picture: String!) {
    updateUserPicture(picture: $picture) {
      ...commonUserFields
      status
    }
  }
  ${CommonUserFieldsFragmentDoc}
`;
export type UpdateUserPictureMutationFn = Apollo.MutationFunction<
  UpdateUserPictureMutation,
  UpdateUserPictureMutationVariables
>;
export type UpdateUserPictureMutationResult = Apollo.MutationResult<UpdateUserPictureMutation>;
export type UpdateUserPictureMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPictureMutation,
  UpdateUserPictureMutationVariables
>;
export const ToggleAllMilestonesTrendDateVisibilityDocument = gql`
  mutation toggleAllMilestonesTrendDateVisibility(
    $projectID: UUID!
    $intervalType: IntervalType!
    $toggles: [DateVisibilityToggle!]!
  ) {
    toggleAllMilestonesTrendDateVisibility(
      projectID: $projectID
      intervalType: $intervalType
      toggles: $toggles
    ) {
      ...projectDateVisibility
    }
  }
  ${ProjectDateVisibilityFragmentDoc}
`;
export type ToggleAllMilestonesTrendDateVisibilityMutationFn = Apollo.MutationFunction<
  ToggleAllMilestonesTrendDateVisibilityMutation,
  ToggleAllMilestonesTrendDateVisibilityMutationVariables
>;
export type ToggleAllMilestonesTrendDateVisibilityMutationResult =
  Apollo.MutationResult<ToggleAllMilestonesTrendDateVisibilityMutation>;
export type ToggleAllMilestonesTrendDateVisibilityMutationOptions = Apollo.BaseMutationOptions<
  ToggleAllMilestonesTrendDateVisibilityMutation,
  ToggleAllMilestonesTrendDateVisibilityMutationVariables
>;
export const CreateOptionDocument = gql`
  mutation createOption(
    $projectID: UUID!
    $parent: UUID!
    $item: ItemCreationInput!
    $costMode: CostMode!
  ) {
    createOption(projectID: $projectID, parent: $parent, item: $item, costMode: $costMode) {
      ...detailedItemFields
    }
  }
  ${DetailedItemFieldsFragmentDoc}
`;
export type CreateOptionMutationFn = Apollo.MutationFunction<
  CreateOptionMutation,
  CreateOptionMutationVariables
>;
export type CreateOptionMutationResult = Apollo.MutationResult<CreateOptionMutation>;
export type CreateOptionMutationOptions = Apollo.BaseMutationOptions<
  CreateOptionMutation,
  CreateOptionMutationVariables
>;
export const CreateMilestoneDocument = gql`
  mutation createMilestone($projectID: UUID!, $input: MilestoneInput) {
    createMilestone(projectID: $projectID, milestone: $input) {
      ...detailedMilestoneFields
    }
  }
  ${DetailedMilestoneFieldsFragmentDoc}
`;
export type CreateMilestoneMutationFn = Apollo.MutationFunction<
  CreateMilestoneMutation,
  CreateMilestoneMutationVariables
>;
export type CreateMilestoneMutationResult = Apollo.MutationResult<CreateMilestoneMutation>;
export type CreateMilestoneMutationOptions = Apollo.BaseMutationOptions<
  CreateMilestoneMutation,
  CreateMilestoneMutationVariables
>;
export const LoadSourceHubRootDocument = gql`
  query loadSourceHubRoot($sourceSystem: SourceSystem!, $sourceID: UUID!) {
    sourceHubRoot(sourceSystem: $sourceSystem, sourceID: $sourceID) {
      hubs {
        ...commonSourceHubFields
      }
      sourceError
    }
  }
  ${CommonSourceHubFieldsFragmentDoc}
`;
export type LoadSourceHubRootQueryResult = Apollo.QueryResult<
  LoadSourceHubRootQuery,
  LoadSourceHubRootQueryVariables
>;
export const LoadSourceHubProjectsDocument = gql`
  query loadSourceHubProjects($sourceSystem: SourceSystem!, $sourceID: UUID!, $hubID: String!) {
    sourceHubProjects(sourceSystem: $sourceSystem, sourceID: $sourceID, hubID: $hubID) {
      projects {
        ...commonSourceProjectFields
      }
      sourceError
    }
  }
  ${CommonSourceProjectFieldsFragmentDoc}
`;
export type LoadSourceHubProjectsQueryResult = Apollo.QueryResult<
  LoadSourceHubProjectsQuery,
  LoadSourceHubProjectsQueryVariables
>;
export const LoadSourceProjectFoldersDocument = gql`
  query loadSourceProjectFolders(
    $sourceSystem: SourceSystem!
    $sourceID: UUID!
    $hubID: String!
    $projectID: String!
  ) {
    sourceProjectFolders(
      sourceSystem: $sourceSystem
      sourceID: $sourceID
      hubID: $hubID
      projectID: $projectID
    ) {
      folders {
        ...commonSourceFolderFields
      }
      sourceError
    }
  }
  ${CommonSourceFolderFieldsFragmentDoc}
`;
export type LoadSourceProjectFoldersQueryResult = Apollo.QueryResult<
  LoadSourceProjectFoldersQuery,
  LoadSourceProjectFoldersQueryVariables
>;
export const LoadSourceFolderRootDocument = gql`
  query loadSourceFolderRoot(
    $sourceSystem: SourceSystem!
    $sourceID: UUID!
    $sortBy: FilesSortBy!
  ) {
    sourceFolderRoot(sourceSystem: $sourceSystem, sourceID: $sourceID, sortBy: $sortBy) {
      entities {
        ...commonSourceEntityFields
      }
    }
  }
  ${CommonSourceEntityFieldsFragmentDoc}
`;
export type LoadSourceFolderRootQueryResult = Apollo.QueryResult<
  LoadSourceFolderRootQuery,
  LoadSourceFolderRootQueryVariables
>;
export const LoadSourceFolderContentsDocument = gql`
  query loadSourceFolderContents(
    $sourceSystem: SourceSystem!
    $sourceID: UUID!
    $projectID: String
    $folderID: String
    $hubID: String
    $sortBy: FilesSortBy!
  ) {
    sourceFolderContents(
      sourceSystem: $sourceSystem
      sourceID: $sourceID
      projectID: $projectID
      folderID: $folderID
      hubID: $hubID
      sortBy: $sortBy
    ) {
      entities {
        ...commonSourceEntityFields
      }
      sourceError
    }
  }
  ${CommonSourceEntityFieldsFragmentDoc}
`;
export type LoadSourceFolderContentsQueryResult = Apollo.QueryResult<
  LoadSourceFolderContentsQuery,
  LoadSourceFolderContentsQueryVariables
>;
export const UserSourcesDocument = gql`
  query userSources {
    userSources {
      sources {
        sourceSystem
        id
        email
        companyID
      }
      clients {
        clientID
        sourceSystem
      }
    }
  }
`;
export type UserSourcesQueryResult = Apollo.QueryResult<
  UserSourcesQuery,
  UserSourcesQueryVariables
>;
export const ConnectUserSourceDocument = gql`
  mutation connectUserSource($publicToken: String!, $sourceSystem: SourceSystem!, $host: String) {
    connectUserSource(publicToken: $publicToken, sourceSystem: $sourceSystem, host: $host)
  }
`;
export type ConnectUserSourceMutationFn = Apollo.MutationFunction<
  ConnectUserSourceMutation,
  ConnectUserSourceMutationVariables
>;
export type ConnectUserSourceMutationResult = Apollo.MutationResult<ConnectUserSourceMutation>;
export type ConnectUserSourceMutationOptions = Apollo.BaseMutationOptions<
  ConnectUserSourceMutation,
  ConnectUserSourceMutationVariables
>;
export const RemoveUserSourceDocument = gql`
  mutation removeUserSource($sourceID: UUID!) {
    removeUserSource(sourceID: $sourceID)
  }
`;
export type RemoveUserSourceMutationFn = Apollo.MutationFunction<
  RemoveUserSourceMutation,
  RemoveUserSourceMutationVariables
>;
export type RemoveUserSourceMutationResult = Apollo.MutationResult<RemoveUserSourceMutation>;
export type RemoveUserSourceMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserSourceMutation,
  RemoveUserSourceMutationVariables
>;
export const ImportEstimateErrorsDocument = gql`
  query importEstimateErrors($estimateID: UUID!) {
    importEstimateErrors(estimateID: $estimateID) {
      ...importEstimateErrorFields
    }
  }
  ${ImportEstimateErrorFieldsFragmentDoc}
`;
export type ImportEstimateErrorsQueryResult = Apollo.QueryResult<
  ImportEstimateErrorsQuery,
  ImportEstimateErrorsQueryVariables
>;
export const ApplyMilestoneDraftEstimateDocument = gql`
  mutation applyMilestoneDraftEstimate($projectID: UUID!, $estimateID: UUID!) {
    applyMilestoneDraftEstimate(projectID: $projectID, estimateID: $estimateID) {
      ...detailedMilestoneFields
    }
  }
  ${DetailedMilestoneFieldsFragmentDoc}
`;
export type ApplyMilestoneDraftEstimateMutationFn = Apollo.MutationFunction<
  ApplyMilestoneDraftEstimateMutation,
  ApplyMilestoneDraftEstimateMutationVariables
>;
export type ApplyMilestoneDraftEstimateMutationResult =
  Apollo.MutationResult<ApplyMilestoneDraftEstimateMutation>;
export type ApplyMilestoneDraftEstimateMutationOptions = Apollo.BaseMutationOptions<
  ApplyMilestoneDraftEstimateMutation,
  ApplyMilestoneDraftEstimateMutationVariables
>;
export const DeleteMilestoneDraftEstimateDocument = gql`
  mutation deleteMilestoneDraftEstimate($projectID: UUID!, $estimateID: UUID!) {
    deleteMilestoneDraftEstimate(projectID: $projectID, estimateID: $estimateID)
  }
`;
export type DeleteMilestoneDraftEstimateMutationFn = Apollo.MutationFunction<
  DeleteMilestoneDraftEstimateMutation,
  DeleteMilestoneDraftEstimateMutationVariables
>;
export type DeleteMilestoneDraftEstimateMutationResult =
  Apollo.MutationResult<DeleteMilestoneDraftEstimateMutation>;
export type DeleteMilestoneDraftEstimateMutationOptions = Apollo.BaseMutationOptions<
  DeleteMilestoneDraftEstimateMutation,
  DeleteMilestoneDraftEstimateMutationVariables
>;
export const ItemHistoryDocument = gql`
  query itemHistory(
    $itemID: UUID
    $costMode: CostMode!
    $viewFilter: ViewFilter!
    $eventTypeFilters: [EventType!]!
  ) {
    itemHistory(
      itemID: $itemID
      costMode: $costMode
      viewFilter: $viewFilter
      eventTypeFilters: $eventTypeFilters
    ) {
      ...itemHistoryFields
    }
  }
  ${ItemHistoryFieldsFragmentDoc}
`;
export type ItemHistoryQueryResult = Apollo.QueryResult<
  ItemHistoryQuery,
  ItemHistoryQueryVariables
>;
export const ListDashboardProjectsDocument = gql`
  query listDashboardProjects(
    $companyID: UUID
    $projectsFilterBy: ProjectsFilterBy!
    $includeCompanyProjects: Boolean
  ) {
    dashboardProjects(
      companyID: $companyID
      projectsFilterBy: $projectsFilterBy
      includeCompanyProjects: $includeCompanyProjects
    ) {
      projects {
        code
        hasAccess
        id
        milestoneName
        name
      }
    }
  }
`;
export type ListDashboardProjectsQueryResult = Apollo.QueryResult<
  ListDashboardProjectsQuery,
  ListDashboardProjectsQueryVariables
>;
export const InsightsProjectCountsDocument = gql`
  query insightsProjectCounts($companyID: UUID, $projectsFilterBy: ProjectsFilterBy!) {
    insightsProjectCounts(companyID: $companyID, projectsFilterBy: $projectsFilterBy) {
      allProjectsCount
      myProjectsCount
    }
  }
`;
export type InsightsProjectCountsQueryResult = Apollo.QueryResult<
  InsightsProjectCountsQuery,
  InsightsProjectCountsQueryVariables
>;
export const GetActiveUsersDocument = gql`
  query getActiveUsers($projectIDs: [UUID!]!) {
    activeUsers(projectIDs: $projectIDs) {
      commentsAdded
      email
      itemsCreated
      name
      picture
      pictureAsset {
        thumbnailURL
      }
    }
  }
`;
export type GetActiveUsersQueryResult = Apollo.QueryResult<
  GetActiveUsersQuery,
  GetActiveUsersQueryVariables
>;
export const GetProjectActiveUsersDocument = gql`
  query getProjectActiveUsers($projectIDs: [UUID!]!) {
    projectActiveUsers(projectIDs: $projectIDs) {
      projectID
      userCount
      month
    }
  }
`;
export type GetProjectActiveUsersQueryResult = Apollo.QueryResult<
  GetProjectActiveUsersQuery,
  GetProjectActiveUsersQueryVariables
>;
export const GetDecisionSummaryDocument = gql`
  query getDecisionSummary($projectIDs: [UUID!]!) {
    decisionSummary(projectIDs: $projectIDs) {
      acceptedCount
      acceptedCost
      incorporatedCount
      incorporatedCost
      notApplicableCount
      notApplicableCost
      pendingCount
      pendingCost
      rejectedCount
      rejectedCost
      totalItemCount
    }
  }
`;
export type GetDecisionSummaryQueryResult = Apollo.QueryResult<
  GetDecisionSummaryQuery,
  GetDecisionSummaryQueryVariables
>;
export const GetItemsOwnershipDocument = gql`
  query getItemsOwnership($projectIDs: [UUID!]!) {
    itemsOwnership(projectIDs: $projectIDs) {
      itemCount
      itemPercentage
      assigneeRoleName
    }
  }
`;
export type GetItemsOwnershipQueryResult = Apollo.QueryResult<
  GetItemsOwnershipQuery,
  GetItemsOwnershipQueryVariables
>;
export const GetProjectBudgetGapsDocument = gql`
  query getProjectBudgetGaps($projectIDs: [UUID!]!) {
    projectBudgetGaps(projectIDs: $projectIDs) {
      accepted
      budget
      estimate
      projectID
      relativeGap
      runningTotal
    }
  }
`;
export type GetProjectBudgetGapsQueryResult = Apollo.QueryResult<
  GetProjectBudgetGapsQuery,
  GetProjectBudgetGapsQueryVariables
>;
export const GetProjectLocationsDocument = gql`
  query getProjectLocations($projectIDs: [UUID!]!) {
    projectLocations(projectIDs: $projectIDs) {
      projectLocations {
        ...projectLocationFields
      }
      projectsWithoutLocation {
        ...projectLocationFields
      }
    }
  }
  ${ProjectLocationFieldsFragmentDoc}
`;
export type GetProjectLocationsQueryResult = Apollo.QueryResult<
  GetProjectLocationsQuery,
  GetProjectLocationsQueryVariables
>;
export const GetProjectMovementsDocument = gql`
  query getProjectMovements($projectIDs: [UUID!]!) {
    projectMovements(projectIDs: $projectIDs) {
      increases {
        projectID
        lastUpdated
        name
        milestoneName
        absolute
        relative
      }
      decreases {
        projectID
        lastUpdated
        name
        milestoneName
        absolute
        relative
      }
    }
  }
`;
export type GetProjectMovementsQueryResult = Apollo.QueryResult<
  GetProjectMovementsQuery,
  GetProjectMovementsQueryVariables
>;
export const GetProjectsOverviewDocument = gql`
  query getProjectsOverview($projectIDs: [UUID!]!) {
    projectsOverview(projectIDs: $projectIDs) {
      totalAPV
      projectStatusData {
        name
        count
        countPercentage
        apv
        apvPercentage
      }
      projectTypeData {
        name
        count
        countPercentage
        apv
        apvPercentage
      }
    }
  }
`;
export type GetProjectsOverviewQueryResult = Apollo.QueryResult<
  GetProjectsOverviewQuery,
  GetProjectsOverviewQueryVariables
>;
export const ListItemDueDatesDocument = gql`
  query listItemDueDates($projectIDs: [UUID!]!) {
    itemDueDates(projectIDs: $projectIDs) {
      noDueDateCount
      pastDueCount
      dueThisWeekCount
      upcomingCount
      openCount
      projectID
    }
  }
`;
export type ListItemDueDatesQueryResult = Apollo.QueryResult<
  ListItemDueDatesQuery,
  ListItemDueDatesQueryVariables
>;
export const ListCurrentItemsBreakdownDocument = gql`
  query listCurrentItemsBreakdown($projectIDs: [UUID!]!) {
    currentItemsBreakdown(projectIDs: $projectIDs) {
      projectTotals {
        project {
          id
          name
          code
        }
        projectStatus
        statusDuration
        positiveCost
        negativeCost
        totalCost
        totalItemCount
        statusBreakdown {
          status
          percent
          itemCount
          cost
        }
        monthlyBreakdown {
          month
          positiveCost
          negativeCost
          totalCost
          itemBreakdown {
            status
            cost
          }
        }
      }
      itemBreakdownsByProject {
        project {
          id
          name
          code
        }
        status
        cost
      }
    }
  }
`;
export type ListCurrentItemsBreakdownQueryResult = Apollo.QueryResult<
  ListCurrentItemsBreakdownQuery,
  ListCurrentItemsBreakdownQueryVariables
>;
export const GetRecentlyCreatedProjectsDocument = gql`
  query getRecentlyCreatedProjects($projectIDs: [UUID!]!) {
    recentlyCreatedProjects(projectIDs: $projectIDs) {
      name
      status
      location
      createdBy
      createdAt
      thumbnail
      projectId
    }
  }
`;
export type GetRecentlyCreatedProjectsQueryResult = Apollo.QueryResult<
  GetRecentlyCreatedProjectsQuery,
  GetRecentlyCreatedProjectsQueryVariables
>;
export const InsightsDocument = gql`
  query Insights($input: InsightsInput!) {
    insights(input: $input) {
      id
    }
  }
`;
export type InsightsQueryResult = Apollo.QueryResult<InsightsQuery, InsightsQueryVariables>;
export const InsightsProjectsDocument = gql`
  query InsightsProjects($input: InsightsInput!) {
    insights(input: $input) {
      id
      name
      projectLeadName: projectLead
      type: projectType
      location
      status
      lastUpdated
      lastUpdatedBy
      deliveryType
      milestoneDesignPhase
      orgNodes {
        id
        name
      }
      hasAccess
      thumbnail
    }
  }
`;
export type InsightsProjectsQueryResult = Apollo.QueryResult<
  InsightsProjectsQuery,
  InsightsProjectsQueryVariables
>;
export const ForecastingProjectsDocument = gql`
  query forecastingProjects(
    $filters: ForecastingProjectsFilters!
    $search: String!
    $sortBy: ForecastingProjectsSortBy!
    $pagination: Pagination!
  ) {
    forecastingProjects(
      filters: $filters
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      budget
      estimate
      hasAccess
      id
      location
      milestoneDate
      milestoneID
      milestoneName
      name
      orgNodes {
        id
        name
      }
      projectLeadName
      runningTotal
      status
      thumbnail
      type
      updatedAt
      updatedBy
    }
  }
`;
export type ForecastingProjectsQueryResult = Apollo.QueryResult<
  ForecastingProjectsQuery,
  ForecastingProjectsQueryVariables
>;
export const ProjectComparisonReportsDocument = gql`
  query projectComparisonReports(
    $projectID: UUID
    $search: String!
    $sortBy: ForecastingReportsSortBy!
  ) {
    projectComparisonReports(projectID: $projectID, search: $search, sortBy: $sortBy) {
      ...projectComparisonReportFields
    }
  }
  ${ProjectComparisonReportFieldsFragmentDoc}
`;
export type ProjectComparisonReportsQueryResult = Apollo.QueryResult<
  ProjectComparisonReportsQuery,
  ProjectComparisonReportsQueryVariables
>;
export const ProjectComparisonReportDocument = gql`
  query projectComparisonReport($reportID: UUID!) {
    projectComparisonReport(reportID: $reportID) {
      ...projectComparisonReportFields
    }
  }
  ${ProjectComparisonReportFieldsFragmentDoc}
`;
export type ProjectComparisonReportQueryResult = Apollo.QueryResult<
  ProjectComparisonReportQuery,
  ProjectComparisonReportQueryVariables
>;
export const CreateProjectComparisonReportDocument = gql`
  mutation createProjectComparisonReport(
    $attachedProjectID: UUID
    $reportName: String!
    $reportDescription: String!
    $projectCompsSetInput: ProjectCompsSetInput!
  ) {
    createProjectComparisonReport(
      attachedProjectID: $attachedProjectID
      reportName: $reportName
      reportDescription: $reportDescription
      projectCompsSetInput: $projectCompsSetInput
    ) {
      ...projectComparisonReportFields
    }
  }
  ${ProjectComparisonReportFieldsFragmentDoc}
`;
export type CreateProjectComparisonReportMutationFn = Apollo.MutationFunction<
  CreateProjectComparisonReportMutation,
  CreateProjectComparisonReportMutationVariables
>;
export type CreateProjectComparisonReportMutationResult =
  Apollo.MutationResult<CreateProjectComparisonReportMutation>;
export type CreateProjectComparisonReportMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectComparisonReportMutation,
  CreateProjectComparisonReportMutationVariables
>;
export const ProjectCompsSetInputDocument = gql`
  query projectCompsSetInput($reportID: UUID!) {
    projectCompsSetInput(reportID: $reportID) {
      ...projectCompsSetInputReturnFields
    }
  }
  ${ProjectCompsSetInputReturnFieldsFragmentDoc}
`;
export type ProjectCompsSetInputQueryResult = Apollo.QueryResult<
  ProjectCompsSetInputQuery,
  ProjectCompsSetInputQueryVariables
>;
export const DeleteForecastingReportDocument = gql`
  mutation deleteForecastingReport($reportID: UUID!) {
    deleteForecastingReport(reportID: $reportID)
  }
`;
export type DeleteForecastingReportMutationFn = Apollo.MutationFunction<
  DeleteForecastingReportMutation,
  DeleteForecastingReportMutationVariables
>;
export type DeleteForecastingReportMutationResult =
  Apollo.MutationResult<DeleteForecastingReportMutation>;
export type DeleteForecastingReportMutationOptions = Apollo.BaseMutationOptions<
  DeleteForecastingReportMutation,
  DeleteForecastingReportMutationVariables
>;
export const UpdateProjectComparisonReportDocument = gql`
  mutation updateProjectComparisonReport(
    $reportID: UUID!
    $reportName: String
    $reportDescription: String
    $projectCompsSetInput: ProjectCompsSetInput
    $attachedProjectID: UUID
  ) {
    updateProjectComparisonReport(
      reportID: $reportID
      reportName: $reportName
      reportDescription: $reportDescription
      projectCompsSetInput: $projectCompsSetInput
      attachedProjectID: $attachedProjectID
    ) {
      ...projectComparisonReportFields
    }
  }
  ${ProjectComparisonReportFieldsFragmentDoc}
`;
export type UpdateProjectComparisonReportMutationFn = Apollo.MutationFunction<
  UpdateProjectComparisonReportMutation,
  UpdateProjectComparisonReportMutationVariables
>;
export type UpdateProjectComparisonReportMutationResult =
  Apollo.MutationResult<UpdateProjectComparisonReportMutation>;
export type UpdateProjectComparisonReportMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectComparisonReportMutation,
  UpdateProjectComparisonReportMutationVariables
>;
export const SearchItemsDocument = gql`
  query searchItems(
    $filters: ForecastingExploreItemsFilters!
    $projectsFilters: ForecastingProjectsFilters!
    $searchResultType: SearchResultType
    $search: String!
    $sortBy: ForecastingExploreItemsSortBy!
    $pagination: Pagination!
  ) {
    searchItems(
      filters: $filters
      projectsFilters: $projectsFilters
      searchResultType: $searchResultType
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      aggregations {
        name
        values {
          name
          count
        }
      }
      data {
        categories {
          id
          name
          number
          categorization {
            id
            name
          }
        }
        cost {
          min
          max
        }
        createdBy {
          name
        }
        hasAccess
        id
        milestoneDate
        name
        number
        numOptions
        parentID
        parentName
        project {
          budget
          estimate
          id
          location
          milestoneDate
          milestoneID
          milestoneName
          name
          orgNodes {
            id
            name
          }
          projectLeadName
          runningTotal
          status
          thumbnail
          type
          updatedAt
          updatedBy
          hasAccess
        }
        status
        type
        updatedAt
      }
      total
    }
  }
`;
export type SearchItemsQueryResult = Apollo.QueryResult<
  SearchItemsQuery,
  SearchItemsQueryVariables
>;
export const SearchCategoryFilterLabelsDocument = gql`
  query searchCategoryFilterLabels {
    searchCategoryFilterLabels {
      key
      label
    }
  }
`;
export type SearchCategoryFilterLabelsQueryResult = Apollo.QueryResult<
  SearchCategoryFilterLabelsQuery,
  SearchCategoryFilterLabelsQueryVariables
>;
export const SearchProjectsDocument = gql`
  query searchProjects(
    $filters: ForecastingProjectsFilters!
    $searchResultType: SearchResultType
    $search: String!
    $sortBy: ForecastingProjectsSortBy!
    $pagination: Pagination!
  ) {
    searchProjects(
      filters: $filters
      searchResultType: $searchResultType
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      data {
        budget
        estimate
        hasAccess
        id
        location
        milestoneDate
        milestoneID
        milestoneName
        name
        orgNodes {
          id
          name
        }
        projectLeadName
        runningTotal
        status
        thumbnail
        type
        updatedAt
        updatedBy
      }
      total
    }
  }
`;
export type SearchProjectsQueryResult = Apollo.QueryResult<
  SearchProjectsQuery,
  SearchProjectsQueryVariables
>;
export const SearchProjectsIdsDocument = gql`
  query searchProjectsIds(
    $filters: ForecastingProjectsFilters!
    $searchResultType: SearchResultType
    $search: String!
    $sortBy: ForecastingProjectsSortBy!
    $pagination: Pagination!
  ) {
    searchProjects(
      filters: $filters
      searchResultType: $searchResultType
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      data {
        id
      }
      total
    }
  }
`;
export type SearchProjectsIdsQueryResult = Apollo.QueryResult<
  SearchProjectsIdsQuery,
  SearchProjectsIdsQueryVariables
>;
export const CreateMilestoneDraftEstimateFromPreviousMilestoneDocument = gql`
  mutation createMilestoneDraftEstimateFromPreviousMilestone(
    $projectID: UUID!
    $currentMilestoneID: UUID!
    $currentEstimateType: EstimateType!
    $previousMilestoneID: UUID!
    $previousEstimateType: EstimateType!
    $isRunningTotal: Boolean!
    $incorporateAccepted: Boolean!
  ) {
    createMilestoneDraftEstimateFromPreviousMilestone(
      projectID: $projectID
      currentMilestoneID: $currentMilestoneID
      currentEstimateType: $currentEstimateType
      previousMilestoneID: $previousMilestoneID
      previousEstimateType: $previousEstimateType
      isRunningTotal: $isRunningTotal
      incorporateAccepted: $incorporateAccepted
    ) {
      ...detailedMilestoneFields
    }
  }
  ${DetailedMilestoneFieldsFragmentDoc}
`;
export type CreateMilestoneDraftEstimateFromPreviousMilestoneMutationFn = Apollo.MutationFunction<
  CreateMilestoneDraftEstimateFromPreviousMilestoneMutation,
  CreateMilestoneDraftEstimateFromPreviousMilestoneMutationVariables
>;
export type CreateMilestoneDraftEstimateFromPreviousMilestoneMutationResult =
  Apollo.MutationResult<CreateMilestoneDraftEstimateFromPreviousMilestoneMutation>;
export type CreateMilestoneDraftEstimateFromPreviousMilestoneMutationOptions =
  Apollo.BaseMutationOptions<
    CreateMilestoneDraftEstimateFromPreviousMilestoneMutation,
    CreateMilestoneDraftEstimateFromPreviousMilestoneMutationVariables
  >;
export const CreateMilestoneDraftEstimateDocument = gql`
  mutation createMilestoneDraftEstimate(
    $projectID: UUID!
    $milestoneID: UUID!
    $estimateType: EstimateType!
    $estimateTotalType: EstimateTotalType!
  ) {
    createMilestoneDraftEstimate(
      projectID: $projectID
      milestoneID: $milestoneID
      estimateType: $estimateType
      estimateTotalType: $estimateTotalType
    ) {
      ...detailedMilestoneFields
    }
  }
  ${DetailedMilestoneFieldsFragmentDoc}
`;
export type CreateMilestoneDraftEstimateMutationFn = Apollo.MutationFunction<
  CreateMilestoneDraftEstimateMutation,
  CreateMilestoneDraftEstimateMutationVariables
>;
export type CreateMilestoneDraftEstimateMutationResult =
  Apollo.MutationResult<CreateMilestoneDraftEstimateMutation>;
export type CreateMilestoneDraftEstimateMutationOptions = Apollo.BaseMutationOptions<
  CreateMilestoneDraftEstimateMutation,
  CreateMilestoneDraftEstimateMutationVariables
>;
export const EstimateTotalTypeConflictInfoDocument = gql`
  query estimateTotalTypeConflictInfo($projectID: UUID!, $milestoneID: UUID!, $estimateID: UUID!) {
    estimateTotalTypeConflictInfo(
      projectID: $projectID
      milestoneID: $milestoneID
      estimateID: $estimateID
    ) {
      ...estimateTotalTypeConflictInfoFields
    }
  }
  ${EstimateTotalTypeConflictInfoFieldsFragmentDoc}
`;
export type EstimateTotalTypeConflictInfoQueryResult = Apollo.QueryResult<
  EstimateTotalTypeConflictInfoQuery,
  EstimateTotalTypeConflictInfoQueryVariables
>;
export const FinalizeImportEstimateDocument = gql`
  mutation finalizeImportEstimate(
    $projectID: UUID!
    $draftID: UUID!
    $draftCategorizations: [DraftCategorization!]!
    $estimateTotalType: EstimateTotalType!
  ) {
    finalizeImportEstimate(
      projectID: $projectID
      draftID: $draftID
      draftCategorizations: $draftCategorizations
      estimateTotalType: $estimateTotalType
    ) {
      ...detailedMilestoneFields
    }
  }
  ${DetailedMilestoneFieldsFragmentDoc}
`;
export type FinalizeImportEstimateMutationFn = Apollo.MutationFunction<
  FinalizeImportEstimateMutation,
  FinalizeImportEstimateMutationVariables
>;
export type FinalizeImportEstimateMutationResult =
  Apollo.MutationResult<FinalizeImportEstimateMutation>;
export type FinalizeImportEstimateMutationOptions = Apollo.BaseMutationOptions<
  FinalizeImportEstimateMutation,
  FinalizeImportEstimateMutationVariables
>;
export const SetDisabledProjectCategorizationsDocument = gql`
  mutation setDisabledProjectCategorizations(
    $projectID: UUID!
    $categorizationIDs: [UUID!]!
    $disable: Boolean!
  ) {
    setDisabledProjectCategorizations(
      projectID: $projectID
      categorizationIDs: $categorizationIDs
      disable: $disable
    )
  }
`;
export type SetDisabledProjectCategorizationsMutationFn = Apollo.MutationFunction<
  SetDisabledProjectCategorizationsMutation,
  SetDisabledProjectCategorizationsMutationVariables
>;
export type SetDisabledProjectCategorizationsMutationResult =
  Apollo.MutationResult<SetDisabledProjectCategorizationsMutation>;
export type SetDisabledProjectCategorizationsMutationOptions = Apollo.BaseMutationOptions<
  SetDisabledProjectCategorizationsMutation,
  SetDisabledProjectCategorizationsMutationVariables
>;
export const HasUnreadInAppNotificationsDocument = gql`
  query hasUnreadInAppNotifications {
    hasUnreadInAppNotifications
  }
`;
export type HasUnreadInAppNotificationsQueryResult = Apollo.QueryResult<
  HasUnreadInAppNotificationsQuery,
  HasUnreadInAppNotificationsQueryVariables
>;
export const LoadInAppNotificationsCountDocument = gql`
  query loadInAppNotificationsCount($projectID: UUID, $maxCount: Int!) {
    loadInAppNotificationsCount(projectID: $projectID, maxCount: $maxCount) {
      allNotificationsCount
      unreadNotificationsCount
      allProjectNotificationsCount
      unreadProjectNotificationsCount
    }
  }
`;
export type LoadInAppNotificationsCountQueryResult = Apollo.QueryResult<
  LoadInAppNotificationsCountQuery,
  LoadInAppNotificationsCountQueryVariables
>;
export const LoadInAppNotificationsDocument = gql`
  query loadInAppNotifications(
    $projectID: UUID
    $showUnreadOnly: Boolean!
    $pagination: PaginationByOffsetID!
    $costMode: CostMode
  ) {
    loadInAppNotifications(
      projectID: $projectID
      showUnreadOnly: $showUnreadOnly
      pagination: $pagination
      costMode: $costMode
    ) {
      ...commonInAppNotificationsFields
    }
  }
  ${CommonInAppNotificationsFieldsFragmentDoc}
`;
export type LoadInAppNotificationsQueryResult = Apollo.QueryResult<
  LoadInAppNotificationsQuery,
  LoadInAppNotificationsQueryVariables
>;
export const SetInAppNotificationReadDocument = gql`
  mutation setInAppNotificationRead(
    $notificationEventID: UUID!
    $isRead: Boolean!
    $costMode: CostMode
  ) {
    setInAppNotificationRead(
      notificationEventID: $notificationEventID
      isRead: $isRead
      costMode: $costMode
    ) {
      ...commonInAppNotificationFields
    }
  }
  ${CommonInAppNotificationFieldsFragmentDoc}
`;
export type SetInAppNotificationReadMutationFn = Apollo.MutationFunction<
  SetInAppNotificationReadMutation,
  SetInAppNotificationReadMutationVariables
>;
export type SetInAppNotificationReadMutationResult =
  Apollo.MutationResult<SetInAppNotificationReadMutation>;
export type SetInAppNotificationReadMutationOptions = Apollo.BaseMutationOptions<
  SetInAppNotificationReadMutation,
  SetInAppNotificationReadMutationVariables
>;
export const SetAllInAppNotificationsReadDocument = gql`
  mutation setAllInAppNotificationsRead($projectID: UUID, $allProjects: Boolean!) {
    setAllInAppNotificationsRead(projectID: $projectID, allProjects: $allProjects)
  }
`;
export type SetAllInAppNotificationsReadMutationFn = Apollo.MutationFunction<
  SetAllInAppNotificationsReadMutation,
  SetAllInAppNotificationsReadMutationVariables
>;
export type SetAllInAppNotificationsReadMutationResult =
  Apollo.MutationResult<SetAllInAppNotificationsReadMutation>;
export type SetAllInAppNotificationsReadMutationOptions = Apollo.BaseMutationOptions<
  SetAllInAppNotificationsReadMutation,
  SetAllInAppNotificationsReadMutationVariables
>;
export const ListCategoriesDocument = gql`
  query listCategories($categorizationID: UUID!, $term: String!, $limit: Int, $level: Int) {
    category(
      search: { categorizationID: $categorizationID, term: $term, limit: $limit, level: $level }
    ) {
      ...commonCategoryUniqueFields
    }
  }
  ${CommonCategoryUniqueFieldsFragmentDoc}
`;
export type ListCategoriesQueryResult = Apollo.QueryResult<
  ListCategoriesQuery,
  ListCategoriesQueryVariables
>;
export const CopyItemDocument = gql`
  mutation copyItem($itemID: UUID!, $input: ItemCopyInput!) {
    copyItem(itemID: $itemID, input: $input) {
      copyItemID
    }
  }
`;
export type CopyItemMutationFn = Apollo.MutationFunction<
  CopyItemMutation,
  CopyItemMutationVariables
>;
export type CopyItemMutationResult = Apollo.MutationResult<CopyItemMutation>;
export type CopyItemMutationOptions = Apollo.BaseMutationOptions<
  CopyItemMutation,
  CopyItemMutationVariables
>;
export const LoadItemLinksDocument = gql`
  query loadItemLinks($projectID: UUID!) {
    itemLinks(projectID: $projectID) {
      ...itemLinkFields
    }
  }
  ${ItemLinkFieldsFragmentDoc}
`;
export type LoadItemLinksQueryResult = Apollo.QueryResult<
  LoadItemLinksQuery,
  LoadItemLinksQueryVariables
>;
export const LoadItemHistoryLinksDocument = gql`
  query loadItemHistoryLinks($projectID: UUID!) {
    itemHistoryLinks(projectID: $projectID) {
      ...itemHistoryLinkFields
    }
  }
  ${ItemHistoryLinkFieldsFragmentDoc}
`;
export type LoadItemHistoryLinksQueryResult = Apollo.QueryResult<
  LoadItemHistoryLinksQuery,
  LoadItemHistoryLinksQueryVariables
>;
export const CreateItemEstimateDocument = gql`
  mutation createItemEstimate($projectID: UUID!, $item: UUID!) {
    createItemEstimate(projectID: $projectID, item: $item) {
      estimate
    }
  }
`;
export type CreateItemEstimateMutationFn = Apollo.MutationFunction<
  CreateItemEstimateMutation,
  CreateItemEstimateMutationVariables
>;
export type CreateItemEstimateMutationResult = Apollo.MutationResult<CreateItemEstimateMutation>;
export type CreateItemEstimateMutationOptions = Apollo.BaseMutationOptions<
  CreateItemEstimateMutation,
  CreateItemEstimateMutationVariables
>;
export const LinkIntegrationsEntityDocument = gql`
  mutation linkIntegrationsEntity(
    $sourceSystem: SourceSystem!
    $sourceEntityType: SourceType!
    $sourceEntityID: String!
    $sourceCompanyID: String
    $projectID: UUID!
  ) {
    linkIntegrationsEntity(
      sourceSystem: $sourceSystem
      sourceEntityType: $sourceEntityType
      sourceEntityID: $sourceEntityID
      sourceCompanyID: $sourceCompanyID
      projectID: $projectID
    )
  }
`;
export type LinkIntegrationsEntityMutationFn = Apollo.MutationFunction<
  LinkIntegrationsEntityMutation,
  LinkIntegrationsEntityMutationVariables
>;
export type LinkIntegrationsEntityMutationResult =
  Apollo.MutationResult<LinkIntegrationsEntityMutation>;
export type LinkIntegrationsEntityMutationOptions = Apollo.BaseMutationOptions<
  LinkIntegrationsEntityMutation,
  LinkIntegrationsEntityMutationVariables
>;
export const SourceLinkedProjectDocument = gql`
  query sourceLinkedProject(
    $projectID: UUID!
    $source: SourceSystem!
    $sourceID: UUID
    $sourceUserEmail: String
  ) {
    sourceLinkedProject(
      projectID: $projectID
      source: $source
      sourceID: $sourceID
      sourceUserEmail: $sourceUserEmail
    ) {
      sourceProjectID
      sourceCompanyID
      canCreateItemIntegrationObject
    }
  }
`;
export type SourceLinkedProjectQueryResult = Apollo.QueryResult<
  SourceLinkedProjectQuery,
  SourceLinkedProjectQueryVariables
>;
export const ContingencyDrawEventDocument = gql`
  mutation contingencyDrawEvent(
    $projectID: UUID!
    $estimateID: UUID!
    $itemID: UUID!
    $drawFromID: UUID!
    $value: Numeric!
    $isFirst: Boolean!
    $markupType: MarkupType!
    $currentValue: Numeric!
  ) {
    contingencyDrawEvent(
      projectID: $projectID
      estimateID: $estimateID
      itemID: $itemID
      drawFromID: $drawFromID
      value: $value
      isFirst: $isFirst
      markupType: $markupType
      currentValue: $currentValue
    )
  }
`;
export type ContingencyDrawEventMutationFn = Apollo.MutationFunction<
  ContingencyDrawEventMutation,
  ContingencyDrawEventMutationVariables
>;
export type ContingencyDrawEventMutationResult =
  Apollo.MutationResult<ContingencyDrawEventMutation>;
export type ContingencyDrawEventMutationOptions = Apollo.BaseMutationOptions<
  ContingencyDrawEventMutation,
  ContingencyDrawEventMutationVariables
>;
export const CreateContingencyDrawDocument = gql`
  mutation createContingencyDraw(
    $projectID: UUID!
    $estimateID: UUID!
    $itemID: UUID!
    $contingencyID: UUID
  ) {
    createContingencyDraw(
      projectID: $projectID
      estimateID: $estimateID
      itemID: $itemID
      contingencyID: $contingencyID
    )
  }
`;
export type CreateContingencyDrawMutationFn = Apollo.MutationFunction<
  CreateContingencyDrawMutation,
  CreateContingencyDrawMutationVariables
>;
export type CreateContingencyDrawMutationResult =
  Apollo.MutationResult<CreateContingencyDrawMutation>;
export type CreateContingencyDrawMutationOptions = Apollo.BaseMutationOptions<
  CreateContingencyDrawMutation,
  CreateContingencyDrawMutationVariables
>;
export const GetItemContingenciesDocument = gql`
  query getItemContingencies($projectID: UUID!, $itemID: UUID!, $costMode: CostMode) {
    getItemContingencies(projectID: $projectID, itemID: $itemID, costMode: $costMode) {
      draws {
        id
        type
        name
        error
        draw
        drawFromID
        isDrawingFullAmount
      }
      availableMilestoneContingencies {
        id
        type
        name
        error
        total
        isBeingDrawn
      }
    }
  }
`;
export type GetItemContingenciesQueryResult = Apollo.QueryResult<
  GetItemContingenciesQuery,
  GetItemContingenciesQueryVariables
>;
export const SetItemAssigneeDocument = gql`
  mutation setItemAssignee(
    $projectID: UUID!
    $id: UUID!
    $assigneeEmail: String
    $costMode: CostMode!
  ) {
    setItemAssignee(
      projectID: $projectID
      payload: { id: $id, assigneeEmail: $assigneeEmail }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${SharedDetailedItemLikeFieldsFragmentDoc}
`;
export type SetItemAssigneeMutationFn = Apollo.MutationFunction<
  SetItemAssigneeMutation,
  SetItemAssigneeMutationVariables
>;
export type SetItemAssigneeMutationResult = Apollo.MutationResult<SetItemAssigneeMutation>;
export type SetItemAssigneeMutationOptions = Apollo.BaseMutationOptions<
  SetItemAssigneeMutation,
  SetItemAssigneeMutationVariables
>;
export const RemoveItemCategoryDocument = gql`
  mutation removeItemCategory(
    $projectID: UUID!
    $id: UUID!
    $category: UUID!
    $costMode: CostMode!
  ) {
    removeItemCategory(projectID: $projectID, id: $id, category: $category, costMode: $costMode) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${SharedDetailedItemLikeFieldsFragmentDoc}
`;
export type RemoveItemCategoryMutationFn = Apollo.MutationFunction<
  RemoveItemCategoryMutation,
  RemoveItemCategoryMutationVariables
>;
export type RemoveItemCategoryMutationResult = Apollo.MutationResult<RemoveItemCategoryMutation>;
export type RemoveItemCategoryMutationOptions = Apollo.BaseMutationOptions<
  RemoveItemCategoryMutation,
  RemoveItemCategoryMutationVariables
>;
export const SetItemCategoryDocument = gql`
  mutation setItemCategory($projectID: UUID!, $id: UUID!, $category: UUID!, $costMode: CostMode!) {
    setItemCategory(projectID: $projectID, id: $id, category: $category, costMode: $costMode) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${SharedDetailedItemLikeFieldsFragmentDoc}
`;
export type SetItemCategoryMutationFn = Apollo.MutationFunction<
  SetItemCategoryMutation,
  SetItemCategoryMutationVariables
>;
export type SetItemCategoryMutationResult = Apollo.MutationResult<SetItemCategoryMutation>;
export type SetItemCategoryMutationOptions = Apollo.BaseMutationOptions<
  SetItemCategoryMutation,
  SetItemCategoryMutationVariables
>;
export const SetItemDueDateDocument = gql`
  mutation setItemDueDate($projectID: UUID!, $id: UUID!, $dueDate: Time, $costMode: CostMode!) {
    setItemDueDate(
      projectID: $projectID
      payload: { id: $id, dueDate: $dueDate }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${SharedDetailedItemLikeFieldsFragmentDoc}
`;
export type SetItemDueDateMutationFn = Apollo.MutationFunction<
  SetItemDueDateMutation,
  SetItemDueDateMutationVariables
>;
export type SetItemDueDateMutationResult = Apollo.MutationResult<SetItemDueDateMutation>;
export type SetItemDueDateMutationOptions = Apollo.BaseMutationOptions<
  SetItemDueDateMutation,
  SetItemDueDateMutationVariables
>;
export const SetItemNumberDocument = gql`
  mutation setItemNumber($projectID: UUID!, $id: UUID!, $number: String!, $costMode: CostMode!) {
    setItemNumber(
      projectID: $projectID
      payload: { id: $id, number: $number }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${SharedDetailedItemLikeFieldsFragmentDoc}
`;
export type SetItemNumberMutationFn = Apollo.MutationFunction<
  SetItemNumberMutation,
  SetItemNumberMutationVariables
>;
export type SetItemNumberMutationResult = Apollo.MutationResult<SetItemNumberMutation>;
export type SetItemNumberMutationOptions = Apollo.BaseMutationOptions<
  SetItemNumberMutation,
  SetItemNumberMutationVariables
>;
export const SetItemPublishedDocument = gql`
  mutation setItemPublished(
    $projectID: UUID!
    $id: UUID!
    $createdBy: UUID
    $costMode: CostMode!
    $activeMilestoneID: UUID
  ) {
    setItemPublished(
      projectID: $projectID
      payload: { id: $id, createdBy: $createdBy, activeMilestoneID: $activeMilestoneID }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${SharedDetailedItemLikeFieldsFragmentDoc}
`;
export type SetItemPublishedMutationFn = Apollo.MutationFunction<
  SetItemPublishedMutation,
  SetItemPublishedMutationVariables
>;
export type SetItemPublishedMutationResult = Apollo.MutationResult<SetItemPublishedMutation>;
export type SetItemPublishedMutationOptions = Apollo.BaseMutationOptions<
  SetItemPublishedMutation,
  SetItemPublishedMutationVariables
>;
export const SetItemMilestoneDocument = gql`
  mutation setItemMilestone(
    $projectID: UUID!
    $id: UUID!
    $milestone: UUID
    $status: Status!
    $costMode: CostMode!
  ) {
    setItemMilestone(
      projectID: $projectID
      itemMilestoneInput: { id: $id, milestone: $milestone, status: $status }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${SharedDetailedItemLikeFieldsFragmentDoc}
`;
export type SetItemMilestoneMutationFn = Apollo.MutationFunction<
  SetItemMilestoneMutation,
  SetItemMilestoneMutationVariables
>;
export type SetItemMilestoneMutationResult = Apollo.MutationResult<SetItemMilestoneMutation>;
export type SetItemMilestoneMutationOptions = Apollo.BaseMutationOptions<
  SetItemMilestoneMutation,
  SetItemMilestoneMutationVariables
>;
export const SetItemActivityDocument = gql`
  mutation setItemActivity($projectID: UUID!, $id: UUID!, $activityID: UUID, $costMode: CostMode!) {
    setItemActivity(
      projectID: $projectID
      payload: { id: $id, activityID: $activityID }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${SharedDetailedItemLikeFieldsFragmentDoc}
`;
export type SetItemActivityMutationFn = Apollo.MutationFunction<
  SetItemActivityMutation,
  SetItemActivityMutationVariables
>;
export type SetItemActivityMutationResult = Apollo.MutationResult<SetItemActivityMutation>;
export type SetItemActivityMutationOptions = Apollo.BaseMutationOptions<
  SetItemActivityMutation,
  SetItemActivityMutationVariables
>;
export const UnsetItemActivityDocument = gql`
  mutation unsetItemActivity(
    $projectID: UUID!
    $id: UUID!
    $activityID: UUID
    $costMode: CostMode!
  ) {
    unsetItemActivity(
      projectID: $projectID
      payload: { id: $id, activityID: $activityID }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${SharedDetailedItemLikeFieldsFragmentDoc}
`;
export type UnsetItemActivityMutationFn = Apollo.MutationFunction<
  UnsetItemActivityMutation,
  UnsetItemActivityMutationVariables
>;
export type UnsetItemActivityMutationResult = Apollo.MutationResult<UnsetItemActivityMutation>;
export type UnsetItemActivityMutationOptions = Apollo.BaseMutationOptions<
  UnsetItemActivityMutation,
  UnsetItemActivityMutationVariables
>;
export const SetStatusDocument = gql`
  mutation setStatus($projectID: UUID!, $itemStatusInput: SetStatusInput!, $costMode: CostMode!) {
    setStatus(projectID: $projectID, itemStatusInput: $itemStatusInput, costMode: $costMode) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${SharedDetailedItemLikeFieldsFragmentDoc}
`;
export type SetStatusMutationFn = Apollo.MutationFunction<
  SetStatusMutation,
  SetStatusMutationVariables
>;
export type SetStatusMutationResult = Apollo.MutationResult<SetStatusMutation>;
export type SetStatusMutationOptions = Apollo.BaseMutationOptions<
  SetStatusMutation,
  SetStatusMutationVariables
>;
export const ConvertItemToItemWithOptionsDocument = gql`
  mutation convertItemToItemWithOptions($projectID: UUID!, $item: UUID!, $costMode: CostMode!) {
    convertItemToItemWithOptions(projectID: $projectID, item: $item, costMode: $costMode) {
      ...detailedItemFields
    }
  }
  ${DetailedItemFieldsFragmentDoc}
`;
export type ConvertItemToItemWithOptionsMutationFn = Apollo.MutationFunction<
  ConvertItemToItemWithOptionsMutation,
  ConvertItemToItemWithOptionsMutationVariables
>;
export type ConvertItemToItemWithOptionsMutationResult =
  Apollo.MutationResult<ConvertItemToItemWithOptionsMutation>;
export type ConvertItemToItemWithOptionsMutationOptions = Apollo.BaseMutationOptions<
  ConvertItemToItemWithOptionsMutation,
  ConvertItemToItemWithOptionsMutationVariables
>;
export const AttachItemAsOptionDocument = gql`
  mutation attachItemAsOption(
    $projectID: UUID!
    $parent: UUID!
    $child: UUID!
    $costMode: CostMode!
  ) {
    attachItemAsOption(projectID: $projectID, parent: $parent, child: $child, costMode: $costMode) {
      ...detailedItemFields
    }
  }
  ${DetailedItemFieldsFragmentDoc}
`;
export type AttachItemAsOptionMutationFn = Apollo.MutationFunction<
  AttachItemAsOptionMutation,
  AttachItemAsOptionMutationVariables
>;
export type AttachItemAsOptionMutationResult = Apollo.MutationResult<AttachItemAsOptionMutation>;
export type AttachItemAsOptionMutationOptions = Apollo.BaseMutationOptions<
  AttachItemAsOptionMutation,
  AttachItemAsOptionMutationVariables
>;
export const DetachOptionDocument = gql`
  mutation detachOption($projectID: UUID!, $option: UUID!, $costMode: CostMode!) {
    detachOption(projectID: $projectID, option: $option, costMode: $costMode) {
      ...detailedItemFields
    }
  }
  ${DetailedItemFieldsFragmentDoc}
`;
export type DetachOptionMutationFn = Apollo.MutationFunction<
  DetachOptionMutation,
  DetachOptionMutationVariables
>;
export type DetachOptionMutationResult = Apollo.MutationResult<DetachOptionMutation>;
export type DetachOptionMutationOptions = Apollo.BaseMutationOptions<
  DetachOptionMutation,
  DetachOptionMutationVariables
>;
export const SetItemNameDocument = gql`
  mutation setItemName($projectID: UUID!, $id: UUID!, $name: String!, $costMode: CostMode!) {
    setItemName(projectID: $projectID, itemID: $id, name: $name, costMode: $costMode) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${SharedDetailedItemLikeFieldsFragmentDoc}
`;
export type SetItemNameMutationFn = Apollo.MutationFunction<
  SetItemNameMutation,
  SetItemNameMutationVariables
>;
export type SetItemNameMutationResult = Apollo.MutationResult<SetItemNameMutation>;
export type SetItemNameMutationOptions = Apollo.BaseMutationOptions<
  SetItemNameMutation,
  SetItemNameMutationVariables
>;
export const SetItemDescriptionDocument = gql`
  mutation setItemDescription(
    $projectID: UUID!
    $id: UUID!
    $description: String!
    $descriptionStyled: String!
    $costMode: CostMode!
  ) {
    setItemDescription(
      projectID: $projectID
      itemID: $id
      description: $description
      descriptionStyled: $descriptionStyled
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${SharedDetailedItemLikeFieldsFragmentDoc}
`;
export type SetItemDescriptionMutationFn = Apollo.MutationFunction<
  SetItemDescriptionMutation,
  SetItemDescriptionMutationVariables
>;
export type SetItemDescriptionMutationResult = Apollo.MutationResult<SetItemDescriptionMutation>;
export type SetItemDescriptionMutationOptions = Apollo.BaseMutationOptions<
  SetItemDescriptionMutation,
  SetItemDescriptionMutationVariables
>;
export const UpdateScheduleImpactDocument = gql`
  mutation UpdateScheduleImpact(
    $projectID: UUID!
    $itemID: UUID!
    $type: ScheduleImpactType!
    $criticalPath: Boolean!
    $days: Int!
  ) {
    updateScheduleImpact(
      projectID: $projectID
      input: { itemID: $itemID, type: $type, criticalPath: $criticalPath, days: $days }
    ) {
      item {
        ... on Option {
          id
          scheduleImpact {
            ...scheduleImpactFields
          }
        }
        ... on Item {
          id
          scheduleImpact {
            ...scheduleImpactFields
          }
        }
      }
    }
  }
  ${ScheduleImpactFieldsFragmentDoc}
`;
export type UpdateScheduleImpactMutationFn = Apollo.MutationFunction<
  UpdateScheduleImpactMutation,
  UpdateScheduleImpactMutationVariables
>;
export type UpdateScheduleImpactMutationResult =
  Apollo.MutationResult<UpdateScheduleImpactMutation>;
export type UpdateScheduleImpactMutationOptions = Apollo.BaseMutationOptions<
  UpdateScheduleImpactMutation,
  UpdateScheduleImpactMutationVariables
>;
export const CreateItemDocument = gql`
  mutation createItem(
    $activityIDs: [UUID!]
    $name: String!
    $costImpact: USCents
    $bucketID: UUID
    $milestoneID: UUID!
    $projectID: UUID!
    $categories: [CategoryInput!]
    $assigneeEmail: String
    $dueDate: Time
    $description: String
    $descriptionStyled: String
    $costMode: CostMode!
    $visibility: Visibility
  ) {
    createItem(
      item: {
        activityIDs: $activityIDs
        projectID: $projectID
        bucketID: $bucketID
        costImpact: $costImpact
        milestoneID: $milestoneID
        name: $name
        categories: $categories
        assigneeEmail: $assigneeEmail
        dueDate: $dueDate
        description: $description
        descriptionStyled: $descriptionStyled
        visibility: $visibility
      }
      costMode: $costMode
    ) {
      ...detailedItemFields
    }
  }
  ${DetailedItemFieldsFragmentDoc}
`;
export type CreateItemMutationFn = Apollo.MutationFunction<
  CreateItemMutation,
  CreateItemMutationVariables
>;
export type CreateItemMutationResult = Apollo.MutationResult<CreateItemMutation>;
export type CreateItemMutationOptions = Apollo.BaseMutationOptions<
  CreateItemMutation,
  CreateItemMutationVariables
>;
export const CreateItemsFromImportDocument = gql`
  mutation CreateItemsFromImport(
    $projectID: UUID!
    $milestoneID: UUID!
    $assetID: UUID!
    $visibility: Visibility!
  ) {
    createItemsFromImport(
      projectID: $projectID
      milestoneID: $milestoneID
      assetID: $assetID
      visibility: $visibility
    ) {
      userErrors
    }
  }
`;
export type CreateItemsFromImportMutationFn = Apollo.MutationFunction<
  CreateItemsFromImportMutation,
  CreateItemsFromImportMutationVariables
>;
export type CreateItemsFromImportMutationResult =
  Apollo.MutationResult<CreateItemsFromImportMutation>;
export type CreateItemsFromImportMutationOptions = Apollo.BaseMutationOptions<
  CreateItemsFromImportMutation,
  CreateItemsFromImportMutationVariables
>;
export const RemoveItemMilestoneHistoryDocument = gql`
  mutation removeItemMilestoneHistory($projectID: UUID!, $id: UUID!, $milestone: UUID!) {
    removeItemMilestoneHistory(projectID: $projectID, id: $id, milestone: $milestone) {
      ...commonEventDataFields
    }
  }
  ${CommonEventDataFieldsFragmentDoc}
`;
export type RemoveItemMilestoneHistoryMutationFn = Apollo.MutationFunction<
  RemoveItemMilestoneHistoryMutation,
  RemoveItemMilestoneHistoryMutationVariables
>;
export type RemoveItemMilestoneHistoryMutationResult =
  Apollo.MutationResult<RemoveItemMilestoneHistoryMutation>;
export type RemoveItemMilestoneHistoryMutationOptions = Apollo.BaseMutationOptions<
  RemoveItemMilestoneHistoryMutation,
  RemoveItemMilestoneHistoryMutationVariables
>;
export const DeleteItemEstimateDocument = gql`
  mutation DeleteItemEstimate($input: DeleteItemEstimateInput!) {
    deleteItemEstimate(input: $input) {
      itemID
    }
  }
`;
export type DeleteItemEstimateMutationFn = Apollo.MutationFunction<
  DeleteItemEstimateMutation,
  DeleteItemEstimateMutationVariables
>;
export type DeleteItemEstimateMutationResult = Apollo.MutationResult<DeleteItemEstimateMutation>;
export type DeleteItemEstimateMutationOptions = Apollo.BaseMutationOptions<
  DeleteItemEstimateMutation,
  DeleteItemEstimateMutationVariables
>;
export const ItemDataDocument = gql`
  query itemData($id: UUID!, $costMode: CostMode!) {
    item(id: $id, costMode: $costMode) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${SharedDetailedItemLikeFieldsFragmentDoc}
`;
export type ItemDataQueryResult = Apollo.QueryResult<ItemDataQuery, ItemDataQueryVariables>;
export const SourceItemDocument = gql`
  query sourceItem($id: UUID!, $costMode: CostMode!) {
    item(id: $id, costMode: $costMode) {
      ...sharedSourceItemLikeFields
    }
  }
  ${SharedSourceItemLikeFieldsFragmentDoc}
`;
export type SourceItemQueryResult = Apollo.QueryResult<SourceItemQuery, SourceItemQueryVariables>;
export const ProjectActivityDocument = gql`
  query ProjectActivity(
    $projectID: UUID!
    $filterByUserID: UUID
    $dateFilter: DateFilter!
    $timezoneOffset: Int!
    $costMode: CostMode!
    $pagination: PaginationByOffsetID!
  ) {
    projectActivity(
      projectID: $projectID
      filterByUserID: $filterByUserID
      dateFilter: $dateFilter
      timezoneOffset: $timezoneOffset
      costMode: $costMode
      pagination: $pagination
    ) {
      ...itemActivityFields
    }
  }
  ${ItemActivityFieldsFragmentDoc}
`;
export type ProjectActivityQueryResult = Apollo.QueryResult<
  ProjectActivityQuery,
  ProjectActivityQueryVariables
>;
export const RemoveContingencyDocument = gql`
  mutation removeContingency(
    $projectID: UUID!
    $estimate: UUID!
    $markupIDs: [UUID!]!
    $viewFilter: ViewFilter
  ) {
    removeMarkups(
      projectID: $projectID
      estimate: $estimate
      markupIDs: $markupIDs
      viewFilter: $viewFilter
    ) {
      estimate {
        id
        inheritedMarkups {
          ...commonMarkupFields
        }
        markups {
          ...commonMarkupFields
        }
        incorporatedMarkups {
          ...commonMarkupFields
        }
        subtotal
        markupSubtotal
        inheritedSubtotal
        incorporatedSubtotal
      }
    }
  }
  ${CommonMarkupFieldsFragmentDoc}
`;
export type RemoveContingencyMutationFn = Apollo.MutationFunction<
  RemoveContingencyMutation,
  RemoveContingencyMutationVariables
>;
export type RemoveContingencyMutationResult = Apollo.MutationResult<RemoveContingencyMutation>;
export type RemoveContingencyMutationOptions = Apollo.BaseMutationOptions<
  RemoveContingencyMutation,
  RemoveContingencyMutationVariables
>;
export const BulkItemUpdateDocument = gql`
  mutation bulkItemUpdate(
    $projectID: UUID!
    $itemIDs: [UUID!]!
    $milestoneID: UUID
    $status: Status
    $meetingID: UUID
    $clearMeetingID: Boolean
    $assigneeEmail: String
    $clearAssigneeEmail: Boolean
    $dueDate: Time
    $clearDueDate: Boolean
    $addCategories: [CategoryInput]
    $clearCategories: [UUID]
    $costMode: CostMode!
  ) {
    bulkItemUpdate(
      projectID: $projectID
      items: {
        itemIDs: $itemIDs
        milestoneID: $milestoneID
        status: $status
        meetingID: $meetingID
        clearMeetingID: $clearMeetingID
        assigneeEmail: $assigneeEmail
        clearAssigneeEmail: $clearAssigneeEmail
        dueDate: $dueDate
        clearDueDate: $clearDueDate
        addCategories: $addCategories
        clearCategories: $clearCategories
      }
      costMode: $costMode
    ) {
      itemCount
      optionCount
    }
  }
`;
export type BulkItemUpdateMutationFn = Apollo.MutationFunction<
  BulkItemUpdateMutation,
  BulkItemUpdateMutationVariables
>;
export type BulkItemUpdateMutationResult = Apollo.MutationResult<BulkItemUpdateMutation>;
export type BulkItemUpdateMutationOptions = Apollo.BaseMutationOptions<
  BulkItemUpdateMutation,
  BulkItemUpdateMutationVariables
>;
export const ShareResourcesDocument = gql`
  mutation ShareResources(
    $resourceType: ResourceType!
    $resourceIDs: [UUID!]!
    $userIDs: [UUID!]!
    $accessLevels: [AccessLevel!]!
  ) {
    shareResources(
      input: {
        resourceType: $resourceType
        resourceIDs: $resourceIDs
        userIDs: $userIDs
        accessLevels: $accessLevels
      }
    ) {
      ...shareResourcesFields
    }
  }
  ${ShareResourcesFieldsFragmentDoc}
`;
export type ShareResourcesMutationFn = Apollo.MutationFunction<
  ShareResourcesMutation,
  ShareResourcesMutationVariables
>;
export type ShareResourcesMutationResult = Apollo.MutationResult<ShareResourcesMutation>;
export type ShareResourcesMutationOptions = Apollo.BaseMutationOptions<
  ShareResourcesMutation,
  ShareResourcesMutationVariables
>;
export const GetSharedResourcesDocument = gql`
  query getSharedResources($input: GetSharedResourcesInput!) {
    getSharedResources(input: $input) {
      resources {
        id
        type
        accessLevels
        users {
          id
          email
          isEmailVerified
          jobTitle
          name
          picture
          status
        }
      }
    }
  }
`;
export type GetSharedResourcesQueryResult = Apollo.QueryResult<
  GetSharedResourcesQuery,
  GetSharedResourcesQueryVariables
>;
export const GetSharedResourceDocument = gql`
  query getSharedResource($resourceID: UUID!, $resourceType: ResourceType!) {
    getSharedResource(input: { resourceID: $resourceID, resourceType: $resourceType }) {
      ...getShareResourceFields
    }
  }
  ${GetShareResourceFieldsFragmentDoc}
`;
export type GetSharedResourceQueryResult = Apollo.QueryResult<
  GetSharedResourceQuery,
  GetSharedResourceQueryVariables
>;
export const ItemsListDocument = gql`
  query itemsList(
    $milestoneID: UUID
    $activityID: UUID
    $projectID: UUID!
    $showOptions: Boolean!
    $integrations: [IntegrationFilterType!]!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
    $visibilityView: VisibilityView!
    $loadItemCosts: Boolean
  ) {
    itemsList(
      parameters: {
        milestoneID: $milestoneID
        activityID: $activityID
        projectID: $projectID
        showOptions: $showOptions
        integrations: $integrations
        costMode: $costMode
        viewFilter: $viewFilter
        visibilityView: $visibilityView
        loadItemCosts: $loadItemCosts
      }
    ) {
      items {
        ...itemsListItemFields
      }
    }
  }
  ${ItemsListItemFieldsFragmentDoc}
`;
export type ItemsListQueryResult = Apollo.QueryResult<ItemsListQuery, ItemsListQueryVariables>;
export const ItemsListTotalCostDocument = gql`
  query itemsListTotalCost(
    $itemIDs: [UUID!]!
    $milestoneID: UUID
    $projectID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
  ) {
    itemsListTotalCost(
      parameters: {
        itemIDs: $itemIDs
        milestoneID: $milestoneID
        projectID: $projectID
        costMode: $costMode
        viewFilter: $viewFilter
      }
    ) {
      cost {
        ...costTypeFields
      }
    }
  }
  ${CostTypeFieldsFragmentDoc}
`;
export type ItemsListTotalCostQueryResult = Apollo.QueryResult<
  ItemsListTotalCostQuery,
  ItemsListTotalCostQueryVariables
>;
export const ItemCostDocument = gql`
  query itemCost(
    $itemID: UUID!
    $milestoneID: UUID
    $projectID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
  ) {
    itemCost(
      parameters: {
        itemID: $itemID
        milestoneID: $milestoneID
        projectID: $projectID
        costMode: $costMode
        viewFilter: $viewFilter
      }
    ) {
      cost {
        ...costTypeFields
      }
    }
  }
  ${CostTypeFieldsFragmentDoc}
`;
export type ItemCostQueryResult = Apollo.QueryResult<ItemCostQuery, ItemCostQueryVariables>;
export const ItemCurrentCostDocument = gql`
  query itemCurrentCost(
    $itemID: UUID!
    $milestoneID: UUID
    $projectID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
  ) {
    itemCurrentCost(
      parameters: {
        itemID: $itemID
        milestoneID: $milestoneID
        projectID: $projectID
        costMode: $costMode
        viewFilter: $viewFilter
      }
    ) {
      currentCost {
        ...costTypeFields
      }
    }
  }
  ${CostTypeFieldsFragmentDoc}
`;
export type ItemCurrentCostQueryResult = Apollo.QueryResult<
  ItemCurrentCostQuery,
  ItemCurrentCostQueryVariables
>;
export const GetMilestoneContingenciesDocument = gql`
  query getMilestoneContingencies($projectID: UUID!, $milestoneID: UUID!) {
    getMilestoneContingencies(projectID: $projectID, milestoneID: $milestoneID) {
      id
      name
      type
    }
  }
`;
export type GetMilestoneContingenciesQueryResult = Apollo.QueryResult<
  GetMilestoneContingenciesQuery,
  GetMilestoneContingenciesQueryVariables
>;
export const GetProjectContingenciesDocument = gql`
  query getProjectContingencies($projectID: UUID!) {
    getProjectContingencies(projectID: $projectID) {
      name
      type
    }
  }
`;
export type GetProjectContingenciesQueryResult = Apollo.QueryResult<
  GetProjectContingenciesQuery,
  GetProjectContingenciesQueryVariables
>;
export const ItemsTreeDocument = gql`
  query itemsTree(
    $itemsSortBy: ItemsSortBy!
    $itemsFilterBy: ItemsFilterBy!
    $itemsGroupBy: String
    $milestoneID: UUID
    $activityID: UUID
    $projectID: UUID!
    $meetingID: UUID
    $costMode: CostMode!
    $viewFilter: ViewFilter!
    $groupByItems: [GroupByItem!]!
  ) {
    itemsTree(
      parameters: {
        itemsSortBy: $itemsSortBy
        itemsFilterBy: $itemsFilterBy
        itemsGroupBy: $itemsGroupBy
        milestoneID: $milestoneID
        activityID: $activityID
        projectID: $projectID
        meetingID: $meetingID
        costMode: $costMode
        viewFilter: $viewFilter
        groupByItems: $groupByItems
      }
    ) {
      branches {
        ...commonCategorizedItemsTreeBranchFields
        branches {
          ...commonCategorizedItemsTreeBranchFields
          branches {
            ...commonCategorizedItemsTreeBranchFields
            branches {
              ...commonCategorizedItemsTreeBranchFields
              branches {
                ...commonCategorizedItemsTreeBranchFields
                branches {
                  ...commonCategorizedItemsTreeBranchFields
                  branches {
                    ...commonCategorizedItemsTreeBranchFields
                    branches {
                      ...commonCategorizedItemsTreeBranchFields
                      branches {
                        ...commonCategorizedItemsTreeBranchFields
                        branches {
                          ...commonCategorizedItemsTreeBranchFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      items {
        ...commonItemsTreeItemsFields
      }
      level
      orderedItemIDs
      totalItems
    }
  }
  ${CommonCategorizedItemsTreeBranchFieldsFragmentDoc}
  ${CommonItemsTreeItemsFieldsFragmentDoc}
`;
export type ItemsTreeQueryResult = Apollo.QueryResult<ItemsTreeQuery, ItemsTreeQueryVariables>;
export const EstimateArmatureDocument = gql`
  query estimateArmature(
    $estimateID: UUID!
    $itemEstimateInput: ItemEstimateInput
    $costMode: CostMode!
    $viewFilter: ViewFilter
    $estimateSortBy: EstimateSortBy
    $pagination: Pagination
  ) {
    estimate(
      estimateID: $estimateID
      itemEstimateInput: $itemEstimateInput
      costMode: $costMode
      viewFilter: $viewFilter
      estimateSortBy: $estimateSortBy
      pagination: $pagination
    ) {
      ...gridEstimateArmatureFields
      ownerCostEstimate {
        ...gridEstimateArmatureFields
      }
    }
  }
  ${GridEstimateArmatureFieldsFragmentDoc}
`;
export type EstimateArmatureQueryResult = Apollo.QueryResult<
  EstimateArmatureQuery,
  EstimateArmatureQueryVariables
>;
export const DraftEstimateInfoDocument = gql`
  query draftEstimateInfo($estimateID: UUID!, $costMode: CostMode!) {
    draftEstimateInfo(estimateID: $estimateID, costMode: $costMode) {
      ...draftEstimateInfoFields
    }
  }
  ${DraftEstimateInfoFieldsFragmentDoc}
`;
export type DraftEstimateInfoQueryResult = Apollo.QueryResult<
  DraftEstimateInfoQuery,
  DraftEstimateInfoQueryVariables
>;
export const EstimateLineIDsDocument = gql`
  query estimateLineIDs(
    $estimateID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter
    $estimateSortBy: EstimateSortBy
  ) {
    estimate(
      estimateID: $estimateID
      costMode: $costMode
      viewFilter: $viewFilter
      estimateSortBy: $estimateSortBy
    ) {
      ...gridEstimateLineIDsFields
    }
  }
  ${GridEstimateLineIDsFieldsFragmentDoc}
`;
export type EstimateLineIDsQueryResult = Apollo.QueryResult<
  EstimateLineIDsQuery,
  EstimateLineIDsQueryVariables
>;
export const EstimateLinesDocument = gql`
  query estimateLines(
    $estimateID: UUID!
    $itemEstimateInput: ItemEstimateInput
    $costMode: CostMode!
    $viewFilter: ViewFilter
    $estimateSortBy: EstimateSortBy
    $pagination: Pagination
  ) {
    estimate(
      estimateID: $estimateID
      itemEstimateInput: $itemEstimateInput
      costMode: $costMode
      viewFilter: $viewFilter
      estimateSortBy: $estimateSortBy
      pagination: $pagination
    ) {
      ...gridEstimateLinesFields
    }
  }
  ${GridEstimateLinesFieldsFragmentDoc}
`;
export type EstimateLinesQueryResult = Apollo.QueryResult<
  EstimateLinesQuery,
  EstimateLinesQueryVariables
>;
export const CreateMarkupsDocument = gql`
  mutation createMarkups($projectID: UUID!, $estimate: UUID!, $number: Int!) {
    createMarkups(projectID: $projectID, estimate: $estimate, number: $number) {
      ...commonMarkupFields
    }
  }
  ${CommonMarkupFieldsFragmentDoc}
`;
export type CreateMarkupsMutationFn = Apollo.MutationFunction<
  CreateMarkupsMutation,
  CreateMarkupsMutationVariables
>;
export type CreateMarkupsMutationResult = Apollo.MutationResult<CreateMarkupsMutation>;
export type CreateMarkupsMutationOptions = Apollo.BaseMutationOptions<
  CreateMarkupsMutation,
  CreateMarkupsMutationVariables
>;
export const UpdateMarkupsDocument = gql`
  mutation updateMarkups(
    $projectID: UUID!
    $estimate: UUID!
    $markups: [MarkupInput!]!
    $viewFilter: ViewFilter
  ) {
    updateMarkups(
      projectID: $projectID
      estimate: $estimate
      markups: $markups
      viewFilter: $viewFilter
    ) {
      markups {
        ...commonMarkupFields
      }
      estimateUpdateResult {
        ...limitedUpdateEstimateResult
      }
    }
  }
  ${CommonMarkupFieldsFragmentDoc}
  ${LimitedUpdateEstimateResultFragmentDoc}
`;
export type UpdateMarkupsMutationFn = Apollo.MutationFunction<
  UpdateMarkupsMutation,
  UpdateMarkupsMutationVariables
>;
export type UpdateMarkupsMutationResult = Apollo.MutationResult<UpdateMarkupsMutation>;
export type UpdateMarkupsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMarkupsMutation,
  UpdateMarkupsMutationVariables
>;
export const RemoveMarkupsDocument = gql`
  mutation removeMarkups(
    $projectID: UUID!
    $estimate: UUID!
    $markupIDs: [UUID!]!
    $viewFilter: ViewFilter
  ) {
    removeMarkups(
      projectID: $projectID
      estimate: $estimate
      markupIDs: $markupIDs
      viewFilter: $viewFilter
    ) {
      estimate {
        id
        inheritedMarkups {
          ...commonMarkupFields
        }
        markups {
          ...commonMarkupFields
        }
        incorporatedMarkups {
          ...commonMarkupFields
        }
        incorporatedDraws {
          ...commonMarkupFields
        }
        subtotal
        markupSubtotal
        inheritedSubtotal
        incorporatedSubtotal
        incorporatedDrawsSubtotal
        inheritedOwnerCostMarkupsSubtotal
      }
    }
  }
  ${CommonMarkupFieldsFragmentDoc}
`;
export type RemoveMarkupsMutationFn = Apollo.MutationFunction<
  RemoveMarkupsMutation,
  RemoveMarkupsMutationVariables
>;
export type RemoveMarkupsMutationResult = Apollo.MutationResult<RemoveMarkupsMutation>;
export type RemoveMarkupsMutationOptions = Apollo.BaseMutationOptions<
  RemoveMarkupsMutation,
  RemoveMarkupsMutationVariables
>;
export const ToggleInheritedItemMarkupDocument = gql`
  mutation toggleInheritedItemMarkup(
    $projectID: UUID
    $estimateId: UUID!
    $markupId: UUID!
    $disabled: Boolean!
  ) {
    toggleInheritedItemMarkup(
      projectID: $projectID
      estimateId: $estimateId
      markupId: $markupId
      disabled: $disabled
    ) {
      ...limitedUpdateEstimateResult
    }
  }
  ${LimitedUpdateEstimateResultFragmentDoc}
`;
export type ToggleInheritedItemMarkupMutationFn = Apollo.MutationFunction<
  ToggleInheritedItemMarkupMutation,
  ToggleInheritedItemMarkupMutationVariables
>;
export type ToggleInheritedItemMarkupMutationResult =
  Apollo.MutationResult<ToggleInheritedItemMarkupMutation>;
export type ToggleInheritedItemMarkupMutationOptions = Apollo.BaseMutationOptions<
  ToggleInheritedItemMarkupMutation,
  ToggleInheritedItemMarkupMutationVariables
>;
export const ToggleMarkupWithoutS2ReferenceDocument = gql`
  mutation toggleMarkupWithoutS2Reference(
    $estimateID: UUID!
    $markupID: UUID!
    $withoutS2Reference: Boolean!
  ) {
    toggleMarkupWithoutS2Reference(
      estimateID: $estimateID
      markupID: $markupID
      withoutS2Reference: $withoutS2Reference
    ) {
      ...limitedUpdateEstimateResult
    }
  }
  ${LimitedUpdateEstimateResultFragmentDoc}
`;
export type ToggleMarkupWithoutS2ReferenceMutationFn = Apollo.MutationFunction<
  ToggleMarkupWithoutS2ReferenceMutation,
  ToggleMarkupWithoutS2ReferenceMutationVariables
>;
export type ToggleMarkupWithoutS2ReferenceMutationResult =
  Apollo.MutationResult<ToggleMarkupWithoutS2ReferenceMutation>;
export type ToggleMarkupWithoutS2ReferenceMutationOptions = Apollo.BaseMutationOptions<
  ToggleMarkupWithoutS2ReferenceMutation,
  ToggleMarkupWithoutS2ReferenceMutationVariables
>;
export const GridReorderMarkupsDocument = gql`
  mutation gridReorderMarkups($projectID: UUID!, $estimate: UUID!, $markups: [ReorderInput!]!) {
    reorderMarkups(projectID: $projectID, estimate: $estimate, markups: $markups) {
      markups {
        ...commonMarkupFields
      }
    }
  }
  ${CommonMarkupFieldsFragmentDoc}
`;
export type GridReorderMarkupsMutationFn = Apollo.MutationFunction<
  GridReorderMarkupsMutation,
  GridReorderMarkupsMutationVariables
>;
export type GridReorderMarkupsMutationResult = Apollo.MutationResult<GridReorderMarkupsMutation>;
export type GridReorderMarkupsMutationOptions = Apollo.BaseMutationOptions<
  GridReorderMarkupsMutation,
  GridReorderMarkupsMutationVariables
>;
export const QuantityRemoveLineDocument = gql`
  mutation quantityRemoveLine($projectID: UUID!, $estimateID: UUID!, $lineIDs: [UUID!]!) {
    removeLines(projectID: $projectID, estimateID: $estimateID, lineIDs: $lineIDs) {
      ...quantityUpdateEstimateResult
    }
  }
  ${QuantityUpdateEstimateResultFragmentDoc}
`;
export type QuantityRemoveLineMutationFn = Apollo.MutationFunction<
  QuantityRemoveLineMutation,
  QuantityRemoveLineMutationVariables
>;
export type QuantityRemoveLineMutationResult = Apollo.MutationResult<QuantityRemoveLineMutation>;
export type QuantityRemoveLineMutationOptions = Apollo.BaseMutationOptions<
  QuantityRemoveLineMutation,
  QuantityRemoveLineMutationVariables
>;
export const QuantityCreateLinesDocument = gql`
  mutation quantityCreateLines($projectID: UUID!, $estimate: UUID!, $lines: [LineInput!]!) {
    createLines(projectID: $projectID, estimateID: $estimate, lines: $lines) {
      lines {
        ...gridLineFields
      }
      estimateUpdateResult {
        ...quantityUpdateEstimateResult
      }
    }
  }
  ${GridLineFieldsFragmentDoc}
  ${QuantityUpdateEstimateResultFragmentDoc}
`;
export type QuantityCreateLinesMutationFn = Apollo.MutationFunction<
  QuantityCreateLinesMutation,
  QuantityCreateLinesMutationVariables
>;
export type QuantityCreateLinesMutationResult = Apollo.MutationResult<QuantityCreateLinesMutation>;
export type QuantityCreateLinesMutationOptions = Apollo.BaseMutationOptions<
  QuantityCreateLinesMutation,
  QuantityCreateLinesMutationVariables
>;
export const QuantityUpdateCellsDocument = gql`
  mutation quantityUpdateCells($projectID: UUID!, $estimate: UUID!, $cells: [CellInput!]!) {
    updateCells(projectID: $projectID, estimateID: $estimate, cells: $cells) {
      cells {
        ...indexedGridCellFields
      }
      estimateUpdateResult {
        ...quantityUpdateEstimateResult
      }
    }
  }
  ${IndexedGridCellFieldsFragmentDoc}
  ${QuantityUpdateEstimateResultFragmentDoc}
`;
export type QuantityUpdateCellsMutationFn = Apollo.MutationFunction<
  QuantityUpdateCellsMutation,
  QuantityUpdateCellsMutationVariables
>;
export type QuantityUpdateCellsMutationResult = Apollo.MutationResult<QuantityUpdateCellsMutation>;
export type QuantityUpdateCellsMutationOptions = Apollo.BaseMutationOptions<
  QuantityUpdateCellsMutation,
  QuantityUpdateCellsMutationVariables
>;
export const GridReplaceColumnCategoriesDocument = gql`
  mutation gridReplaceColumnCategories(
    $projectID: UUID!
    $estimateID: UUID!
    $fieldID: UUID!
    $fromCategories: [String!]!
    $toCategories: [UUID!]!
  ) {
    replaceFieldCategories(
      projectID: $projectID
      estimateID: $estimateID
      fieldID: $fieldID
      fromCategories: $fromCategories
      toCategories: $toCategories
    ) {
      cells {
        ...indexedGridCellFields
      }
      estimateUpdateResult {
        estimate {
          fields {
            ...gridFieldFields
          }
        }
      }
    }
  }
  ${IndexedGridCellFieldsFragmentDoc}
  ${GridFieldFieldsFragmentDoc}
`;
export type GridReplaceColumnCategoriesMutationFn = Apollo.MutationFunction<
  GridReplaceColumnCategoriesMutation,
  GridReplaceColumnCategoriesMutationVariables
>;
export type GridReplaceColumnCategoriesMutationResult =
  Apollo.MutationResult<GridReplaceColumnCategoriesMutation>;
export type GridReplaceColumnCategoriesMutationOptions = Apollo.BaseMutationOptions<
  GridReplaceColumnCategoriesMutation,
  GridReplaceColumnCategoriesMutationVariables
>;
export const CreateCategoriesDocument = gql`
  mutation createCategories(
    $projectID: UUID
    $categorizationID: UUID!
    $categories: [CategoryContentInput!]!
  ) {
    createCategories(
      projectID: $projectID
      categorizationID: $categorizationID
      categories: $categories
    ) {
      categories {
        ...commonCategoryUniqueFields
      }
      error
    }
  }
  ${CommonCategoryUniqueFieldsFragmentDoc}
`;
export type CreateCategoriesMutationFn = Apollo.MutationFunction<
  CreateCategoriesMutation,
  CreateCategoriesMutationVariables
>;
export type CreateCategoriesMutationResult = Apollo.MutationResult<CreateCategoriesMutation>;
export type CreateCategoriesMutationOptions = Apollo.BaseMutationOptions<
  CreateCategoriesMutation,
  CreateCategoriesMutationVariables
>;
export const EditCategoriesDocument = gql`
  mutation editCategories(
    $projectID: UUID
    $categorizationID: UUID!
    $categories: [EditCategoryInput!]!
  ) {
    editCategories(
      projectID: $projectID
      categorizationID: $categorizationID
      categories: $categories
    ) {
      ...commonCategoryUniqueFields
    }
  }
  ${CommonCategoryUniqueFieldsFragmentDoc}
`;
export type EditCategoriesMutationFn = Apollo.MutationFunction<
  EditCategoriesMutation,
  EditCategoriesMutationVariables
>;
export type EditCategoriesMutationResult = Apollo.MutationResult<EditCategoriesMutation>;
export type EditCategoriesMutationOptions = Apollo.BaseMutationOptions<
  EditCategoriesMutation,
  EditCategoriesMutationVariables
>;
export const GetCategorizationDocument = gql`
  query getCategorization($id: UUID!, $pagination: Pagination) {
    categorization(id: $id, pagination: $pagination) {
      ...commonCategorizationFields
      content {
        ...commonCategoryUniqueFields
      }
    }
  }
  ${CommonCategorizationFieldsFragmentDoc}
  ${CommonCategoryUniqueFieldsFragmentDoc}
`;
export type GetCategorizationQueryResult = Apollo.QueryResult<
  GetCategorizationQuery,
  GetCategorizationQueryVariables
>;
export const DeleteCategoryDocument = gql`
  mutation deleteCategory($projectID: UUID, $category: CategoryInput!) {
    deleteCategory(projectID: $projectID, category: $category)
  }
`;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables
>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables
>;
export const AcceptImportEstimateErrorDocument = gql`
  mutation acceptImportEstimateError($projectID: UUID!, $estimateID: UUID!, $errorID: UUID!) {
    acceptImportEstimateError(projectID: $projectID, estimateID: $estimateID, errorID: $errorID)
  }
`;
export type AcceptImportEstimateErrorMutationFn = Apollo.MutationFunction<
  AcceptImportEstimateErrorMutation,
  AcceptImportEstimateErrorMutationVariables
>;
export type AcceptImportEstimateErrorMutationResult =
  Apollo.MutationResult<AcceptImportEstimateErrorMutation>;
export type AcceptImportEstimateErrorMutationOptions = Apollo.BaseMutationOptions<
  AcceptImportEstimateErrorMutation,
  AcceptImportEstimateErrorMutationVariables
>;
export const SetEstimateTotalTypeDocument = gql`
  mutation setEstimateTotalType(
    $projectID: UUID!
    $estimateID: UUID!
    $estimateTotalType: EstimateTotalType!
  ) {
    setEstimateTotalType(
      projectID: $projectID
      estimateID: $estimateID
      estimateTotalType: $estimateTotalType
    ) {
      fields {
        ...gridFieldFields
      }
    }
  }
  ${GridFieldFieldsFragmentDoc}
`;
export type SetEstimateTotalTypeMutationFn = Apollo.MutationFunction<
  SetEstimateTotalTypeMutation,
  SetEstimateTotalTypeMutationVariables
>;
export type SetEstimateTotalTypeMutationResult =
  Apollo.MutationResult<SetEstimateTotalTypeMutation>;
export type SetEstimateTotalTypeMutationOptions = Apollo.BaseMutationOptions<
  SetEstimateTotalTypeMutation,
  SetEstimateTotalTypeMutationVariables
>;
export const GetEstimateSourceItemInfosDocument = gql`
  query getEstimateSourceItemInfos($projectID: UUID!, $estimateID: UUID!) {
    estimateSourceItemInfos(projectID: $projectID, estimateID: $estimateID) {
      ...itemLinkFields
    }
  }
  ${ItemLinkFieldsFragmentDoc}
`;
export type GetEstimateSourceItemInfosQueryResult = Apollo.QueryResult<
  GetEstimateSourceItemInfosQuery,
  GetEstimateSourceItemInfosQueryVariables
>;
export const GridReorderColumnDocument = gql`
  mutation gridReorderColumn(
    $projectID: UUID!
    $estimateID: UUID!
    $fieldID: UUID!
    $ordering: Int!
  ) {
    reorderField(
      projectID: $projectID
      estimateID: $estimateID
      fieldID: $fieldID
      ordering: $ordering
    ) {
      fields {
        ...gridFieldFields
      }
    }
  }
  ${GridFieldFieldsFragmentDoc}
`;
export type GridReorderColumnMutationFn = Apollo.MutationFunction<
  GridReorderColumnMutation,
  GridReorderColumnMutationVariables
>;
export type GridReorderColumnMutationResult = Apollo.MutationResult<GridReorderColumnMutation>;
export type GridReorderColumnMutationOptions = Apollo.BaseMutationOptions<
  GridReorderColumnMutation,
  GridReorderColumnMutationVariables
>;
export const GridRemoveColumnsDocument = gql`
  mutation gridRemoveColumns($projectID: UUID!, $estimateID: UUID!, $fieldIDs: [UUID!]!) {
    removeFields(projectID: $projectID, estimateID: $estimateID, fieldIDs: $fieldIDs) {
      fields {
        ...gridFieldFields
      }
    }
  }
  ${GridFieldFieldsFragmentDoc}
`;
export type GridRemoveColumnsMutationFn = Apollo.MutationFunction<
  GridRemoveColumnsMutation,
  GridRemoveColumnsMutationVariables
>;
export type GridRemoveColumnsMutationResult = Apollo.MutationResult<GridRemoveColumnsMutation>;
export type GridRemoveColumnsMutationOptions = Apollo.BaseMutationOptions<
  GridRemoveColumnsMutation,
  GridRemoveColumnsMutationVariables
>;
export const GridAddColumnsDocument = gql`
  mutation gridAddColumns($projectID: UUID!, $estimateID: UUID!, $fields: [FieldInput!]!) {
    addFields(projectID: $projectID, estimateID: $estimateID, fields: $fields) {
      fields {
        ...gridFieldFields
      }
    }
  }
  ${GridFieldFieldsFragmentDoc}
`;
export type GridAddColumnsMutationFn = Apollo.MutationFunction<
  GridAddColumnsMutation,
  GridAddColumnsMutationVariables
>;
export type GridAddColumnsMutationResult = Apollo.MutationResult<GridAddColumnsMutation>;
export type GridAddColumnsMutationOptions = Apollo.BaseMutationOptions<
  GridAddColumnsMutation,
  GridAddColumnsMutationVariables
>;
export const GridReorderLinesDocument = gql`
  mutation gridReorderLines($projectID: UUID!, $estimate: UUID!, $lines: [ReorderInput!]!) {
    reorderLines(projectID: $projectID, estimate: $estimate, lines: $lines) {
      lines {
        ...gridLineFields
      }
    }
  }
  ${GridLineFieldsFragmentDoc}
`;
export type GridReorderLinesMutationFn = Apollo.MutationFunction<
  GridReorderLinesMutation,
  GridReorderLinesMutationVariables
>;
export type GridReorderLinesMutationResult = Apollo.MutationResult<GridReorderLinesMutation>;
export type GridReorderLinesMutationOptions = Apollo.BaseMutationOptions<
  GridReorderLinesMutation,
  GridReorderLinesMutationVariables
>;
export const GridCreateLinesDocument = gql`
  mutation gridCreateLines($projectID: UUID!, $estimate: UUID!, $lines: [LineInput!]!) {
    createLines(projectID: $projectID, estimateID: $estimate, lines: $lines) {
      lines {
        ...gridLineFields
      }
      estimateUpdateResult {
        ...limitedUpdateEstimateResult
      }
    }
  }
  ${GridLineFieldsFragmentDoc}
  ${LimitedUpdateEstimateResultFragmentDoc}
`;
export type GridCreateLinesMutationFn = Apollo.MutationFunction<
  GridCreateLinesMutation,
  GridCreateLinesMutationVariables
>;
export type GridCreateLinesMutationResult = Apollo.MutationResult<GridCreateLinesMutation>;
export type GridCreateLinesMutationOptions = Apollo.BaseMutationOptions<
  GridCreateLinesMutation,
  GridCreateLinesMutationVariables
>;
export const GridRemoveLineDocument = gql`
  mutation gridRemoveLine($projectID: UUID!, $estimateID: UUID!, $lineIDs: [UUID!]!) {
    removeLines(projectID: $projectID, estimateID: $estimateID, lineIDs: $lineIDs) {
      ...limitedUpdateEstimateResult
    }
  }
  ${LimitedUpdateEstimateResultFragmentDoc}
`;
export type GridRemoveLineMutationFn = Apollo.MutationFunction<
  GridRemoveLineMutation,
  GridRemoveLineMutationVariables
>;
export type GridRemoveLineMutationResult = Apollo.MutationResult<GridRemoveLineMutation>;
export type GridRemoveLineMutationOptions = Apollo.BaseMutationOptions<
  GridRemoveLineMutation,
  GridRemoveLineMutationVariables
>;
export const GridUpdateCellsDocument = gql`
  mutation gridUpdateCells($projectID: UUID!, $estimate: UUID!, $cells: [CellInput!]!) {
    updateCells(projectID: $projectID, estimateID: $estimate, cells: $cells) {
      cells {
        ...indexedGridCellFields
      }
      estimateUpdateResult {
        ...limitedUpdateEstimateResult
      }
    }
  }
  ${IndexedGridCellFieldsFragmentDoc}
  ${LimitedUpdateEstimateResultFragmentDoc}
`;
export type GridUpdateCellsMutationFn = Apollo.MutationFunction<
  GridUpdateCellsMutation,
  GridUpdateCellsMutationVariables
>;
export type GridUpdateCellsMutationResult = Apollo.MutationResult<GridUpdateCellsMutation>;
export type GridUpdateCellsMutationOptions = Apollo.BaseMutationOptions<
  GridUpdateCellsMutation,
  GridUpdateCellsMutationVariables
>;
export const GetDoMilestoneMarkupsHaveContingencyDrawsDocument = gql`
  query getDoMilestoneMarkupsHaveContingencyDraws(
    $estimateID: UUID!
    $projectID: UUID!
    $markupIDs: [UUID!]!
  ) {
    getDoMilestoneMarkupsHaveContingencyDraws(
      estimateID: $estimateID
      projectID: $projectID
      markupIDs: $markupIDs
    )
  }
`;
export type GetDoMilestoneMarkupsHaveContingencyDrawsQueryResult = Apollo.QueryResult<
  GetDoMilestoneMarkupsHaveContingencyDrawsQuery,
  GetDoMilestoneMarkupsHaveContingencyDrawsQueryVariables
>;
export const CreateOwnerCostEstimateDocument = gql`
  mutation createOwnerCostEstimate($projectID: UUID!, $estimateID: UUID!) {
    createOwnerCostEstimate(projectID: $projectID, estimateID: $estimateID)
  }
`;
export type CreateOwnerCostEstimateMutationFn = Apollo.MutationFunction<
  CreateOwnerCostEstimateMutation,
  CreateOwnerCostEstimateMutationVariables
>;
export type CreateOwnerCostEstimateMutationResult =
  Apollo.MutationResult<CreateOwnerCostEstimateMutation>;
export type CreateOwnerCostEstimateMutationOptions = Apollo.BaseMutationOptions<
  CreateOwnerCostEstimateMutation,
  CreateOwnerCostEstimateMutationVariables
>;
export const GetMilestoneItemCountDocument = gql`
  query getMilestoneItemCount($id: UUID!) {
    itemCounts(id: $id) {
      rejected
      pending
      accepted
      incorporated
      total
    }
  }
`;
export type GetMilestoneItemCountQueryResult = Apollo.QueryResult<
  GetMilestoneItemCountQuery,
  GetMilestoneItemCountQueryVariables
>;
export const SetMilestonePublishDraftDocument = gql`
  mutation setMilestonePublishDraft($projectID: UUID!, $input: SetMilestonePublishDraftInput!) {
    setMilestonePublishDraft(projectID: $projectID, milestone: $input) {
      ...commonMilestonePublishDraftFields
    }
  }
  ${CommonMilestonePublishDraftFieldsFragmentDoc}
`;
export type SetMilestonePublishDraftMutationFn = Apollo.MutationFunction<
  SetMilestonePublishDraftMutation,
  SetMilestonePublishDraftMutationVariables
>;
export type SetMilestonePublishDraftMutationResult =
  Apollo.MutationResult<SetMilestonePublishDraftMutation>;
export type SetMilestonePublishDraftMutationOptions = Apollo.BaseMutationOptions<
  SetMilestonePublishDraftMutation,
  SetMilestonePublishDraftMutationVariables
>;
export const GetSourceMilestoneDocument = gql`
  query getSourceMilestone($id: UUID!) {
    milestone(id: $id) {
      ...sourceMilestoneFields
    }
  }
  ${SourceMilestoneFieldsFragmentDoc}
`;
export type GetSourceMilestoneQueryResult = Apollo.QueryResult<
  GetSourceMilestoneQuery,
  GetSourceMilestoneQueryVariables
>;
export const CreateOrUpdateBucketDocument = gql`
  mutation createOrUpdateBucket(
    $projectID: UUID!
    $id: UUID
    $milestone: UUID!
    $name: String
    $date: Time
  ) {
    createOrUpdateBucket(
      projectID: $projectID
      bucket: { id: $id, milestone: $milestone, name: $name, date: $date }
    ) {
      ...commonBucketFields
    }
  }
  ${CommonBucketFieldsFragmentDoc}
`;
export type CreateOrUpdateBucketMutationFn = Apollo.MutationFunction<
  CreateOrUpdateBucketMutation,
  CreateOrUpdateBucketMutationVariables
>;
export type CreateOrUpdateBucketMutationResult =
  Apollo.MutationResult<CreateOrUpdateBucketMutation>;
export type CreateOrUpdateBucketMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateBucketMutation,
  CreateOrUpdateBucketMutationVariables
>;
export const DeleteBucketDocument = gql`
  mutation deleteBucket($projectID: UUID!, $bucket: UUID!) {
    deleteBucket(projectID: $projectID, bucket: $bucket) {
      ...commonBucketFields
    }
  }
  ${CommonBucketFieldsFragmentDoc}
`;
export type DeleteBucketMutationFn = Apollo.MutationFunction<
  DeleteBucketMutation,
  DeleteBucketMutationVariables
>;
export type DeleteBucketMutationResult = Apollo.MutationResult<DeleteBucketMutation>;
export type DeleteBucketMutationOptions = Apollo.BaseMutationOptions<
  DeleteBucketMutation,
  DeleteBucketMutationVariables
>;
export const GetProjectUnitsDocument = gql`
  query getProjectUnits($projectID: UUID!, $isEnabledOnly: Boolean!) {
    getProjectUnits(projectID: $projectID, isEnabledOnly: $isEnabledOnly) {
      ...unitFields
    }
  }
  ${UnitFieldsFragmentDoc}
`;
export type GetProjectUnitsQueryResult = Apollo.QueryResult<
  GetProjectUnitsQuery,
  GetProjectUnitsQueryVariables
>;
export const ToggleProjectUnitsDocument = gql`
  mutation toggleProjectUnits($projectID: UUID!, $units: [UnitToggleInput!]!) {
    toggleProjectUnits(projectID: $projectID, units: $units) {
      ...unitFields
    }
  }
  ${UnitFieldsFragmentDoc}
`;
export type ToggleProjectUnitsMutationFn = Apollo.MutationFunction<
  ToggleProjectUnitsMutation,
  ToggleProjectUnitsMutationVariables
>;
export type ToggleProjectUnitsMutationResult = Apollo.MutationResult<ToggleProjectUnitsMutation>;
export type ToggleProjectUnitsMutationOptions = Apollo.BaseMutationOptions<
  ToggleProjectUnitsMutation,
  ToggleProjectUnitsMutationVariables
>;
export const CreateOrUpdateCustomUnitDocument = gql`
  mutation createOrUpdateCustomUnit($unit: UnitInput!, $projectID: UUID!) {
    createOrUpdateCustomUnit(unit: $unit, projectID: $projectID) {
      ...unitFields
    }
  }
  ${UnitFieldsFragmentDoc}
`;
export type CreateOrUpdateCustomUnitMutationFn = Apollo.MutationFunction<
  CreateOrUpdateCustomUnitMutation,
  CreateOrUpdateCustomUnitMutationVariables
>;
export type CreateOrUpdateCustomUnitMutationResult =
  Apollo.MutationResult<CreateOrUpdateCustomUnitMutation>;
export type CreateOrUpdateCustomUnitMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateCustomUnitMutation,
  CreateOrUpdateCustomUnitMutationVariables
>;
export const RemoveCustomUnitDocument = gql`
  mutation removeCustomUnit($projectID: UUID!, $unitID: UUID!) {
    removeCustomUnit(projectID: $projectID, unitID: $unitID)
  }
`;
export type RemoveCustomUnitMutationFn = Apollo.MutationFunction<
  RemoveCustomUnitMutation,
  RemoveCustomUnitMutationVariables
>;
export type RemoveCustomUnitMutationResult = Apollo.MutationResult<RemoveCustomUnitMutation>;
export type RemoveCustomUnitMutationOptions = Apollo.BaseMutationOptions<
  RemoveCustomUnitMutation,
  RemoveCustomUnitMutationVariables
>;
export const CreateQuantityDocument = gql`
  mutation createQuantity(
    $projectID: UUID!
    $milestoneID: UUID!
    $unitID: UUID!
    $magnitude: Numeric!
  ) {
    createQuantity(
      projectID: $projectID
      quantity: { unitID: $unitID, magnitude: $magnitude }
      milestoneID: $milestoneID
    ) {
      ...quantityFields
    }
  }
  ${QuantityFieldsFragmentDoc}
`;
export type CreateQuantityMutationFn = Apollo.MutationFunction<
  CreateQuantityMutation,
  CreateQuantityMutationVariables
>;
export type CreateQuantityMutationResult = Apollo.MutationResult<CreateQuantityMutation>;
export type CreateQuantityMutationOptions = Apollo.BaseMutationOptions<
  CreateQuantityMutation,
  CreateQuantityMutationVariables
>;
export const AddQuantityBreakdownDocument = gql`
  mutation addQuantityBreakdown(
    $projectID: UUID!
    $milestoneID: UUID!
    $quantityID: UUID
    $unitID: UUID
    $fields: [FieldInput!]!
  ) {
    addQuantityBreakdown(
      projectID: $projectID
      quantity: { quantityID: $quantityID, unitID: $unitID, fields: $fields }
      milestoneID: $milestoneID
    ) {
      ...quantityFields
    }
  }
  ${QuantityFieldsFragmentDoc}
`;
export type AddQuantityBreakdownMutationFn = Apollo.MutationFunction<
  AddQuantityBreakdownMutation,
  AddQuantityBreakdownMutationVariables
>;
export type AddQuantityBreakdownMutationResult =
  Apollo.MutationResult<AddQuantityBreakdownMutation>;
export type AddQuantityBreakdownMutationOptions = Apollo.BaseMutationOptions<
  AddQuantityBreakdownMutation,
  AddQuantityBreakdownMutationVariables
>;
export const RemoveQuantityBreakdownDocument = gql`
  mutation removeQuantityBreakdown(
    $projectID: UUID!
    $milestoneID: UUID!
    $quantityID: UUID!
    $estimateID: UUID!
    $unitID: UUID!
    $magnitude: Numeric!
  ) {
    removeQuantityBreakdown(
      projectID: $projectID
      quantity: {
        quantityID: $quantityID
        estimateID: $estimateID
        unitID: $unitID
        magnitude: $magnitude
      }
      milestoneID: $milestoneID
    ) {
      ...quantityFields
    }
  }
  ${QuantityFieldsFragmentDoc}
`;
export type RemoveQuantityBreakdownMutationFn = Apollo.MutationFunction<
  RemoveQuantityBreakdownMutation,
  RemoveQuantityBreakdownMutationVariables
>;
export type RemoveQuantityBreakdownMutationResult =
  Apollo.MutationResult<RemoveQuantityBreakdownMutation>;
export type RemoveQuantityBreakdownMutationOptions = Apollo.BaseMutationOptions<
  RemoveQuantityBreakdownMutation,
  RemoveQuantityBreakdownMutationVariables
>;
export const GetMilestoneQuantitiesDocument = gql`
  query getMilestoneQuantities($milestoneID: UUID!) {
    quantities(milestoneID: $milestoneID) {
      ...quantityFields
    }
  }
  ${QuantityFieldsFragmentDoc}
`;
export type GetMilestoneQuantitiesQueryResult = Apollo.QueryResult<
  GetMilestoneQuantitiesQuery,
  GetMilestoneQuantitiesQueryVariables
>;
export const DeleteMilestonesDocument = gql`
  mutation deleteMilestones($projectID: UUID!, $milestoneID: UUID!) {
    updateProject(project: { id: $projectID, milestoneID: $milestoneID }) {
      ...commonProjectFields
    }
  }
  ${CommonProjectFieldsFragmentDoc}
`;
export type DeleteMilestonesMutationFn = Apollo.MutationFunction<
  DeleteMilestonesMutation,
  DeleteMilestonesMutationVariables
>;
export type DeleteMilestonesMutationResult = Apollo.MutationResult<DeleteMilestonesMutation>;
export type DeleteMilestonesMutationOptions = Apollo.BaseMutationOptions<
  DeleteMilestonesMutation,
  DeleteMilestonesMutationVariables
>;
export const SetMilestoneNameDocument = gql`
  mutation setMilestoneName($projectID: UUID!, $input: SetMilestoneNameInput) {
    setMilestoneName(projectID: $projectID, milestone: $input) {
      ...detailedMilestoneFields
    }
  }
  ${DetailedMilestoneFieldsFragmentDoc}
`;
export type SetMilestoneNameMutationFn = Apollo.MutationFunction<
  SetMilestoneNameMutation,
  SetMilestoneNameMutationVariables
>;
export type SetMilestoneNameMutationResult = Apollo.MutationResult<SetMilestoneNameMutation>;
export type SetMilestoneNameMutationOptions = Apollo.BaseMutationOptions<
  SetMilestoneNameMutation,
  SetMilestoneNameMutationVariables
>;
export const SetMilestoneDescriptionDocument = gql`
  mutation setMilestoneDescription($projectID: UUID!, $input: SetMilestoneDescriptionInput) {
    setMilestoneDescription(projectID: $projectID, milestone: $input) {
      ...detailedMilestoneFields
    }
  }
  ${DetailedMilestoneFieldsFragmentDoc}
`;
export type SetMilestoneDescriptionMutationFn = Apollo.MutationFunction<
  SetMilestoneDescriptionMutation,
  SetMilestoneDescriptionMutationVariables
>;
export type SetMilestoneDescriptionMutationResult =
  Apollo.MutationResult<SetMilestoneDescriptionMutation>;
export type SetMilestoneDescriptionMutationOptions = Apollo.BaseMutationOptions<
  SetMilestoneDescriptionMutation,
  SetMilestoneDescriptionMutationVariables
>;
export const SetMilestoneDateDocument = gql`
  mutation setMilestoneDate($projectID: UUID!, $input: SetMilestoneDateInput) {
    setMilestoneDate(projectID: $projectID, milestone: $input) {
      ...detailedMilestoneFields
    }
  }
  ${DetailedMilestoneFieldsFragmentDoc}
`;
export type SetMilestoneDateMutationFn = Apollo.MutationFunction<
  SetMilestoneDateMutation,
  SetMilestoneDateMutationVariables
>;
export type SetMilestoneDateMutationResult = Apollo.MutationResult<SetMilestoneDateMutation>;
export type SetMilestoneDateMutationOptions = Apollo.BaseMutationOptions<
  SetMilestoneDateMutation,
  SetMilestoneDateMutationVariables
>;
export const SetMilestoneDesignPhaseDocument = gql`
  mutation setMilestoneDesignPhase($projectID: UUID!, $input: SetMilestoneDesignPhaseInput!) {
    setMilestoneDesignPhase(projectID: $projectID, milestone: $input) {
      ...detailedMilestoneFields
    }
  }
  ${DetailedMilestoneFieldsFragmentDoc}
`;
export type SetMilestoneDesignPhaseMutationFn = Apollo.MutationFunction<
  SetMilestoneDesignPhaseMutation,
  SetMilestoneDesignPhaseMutationVariables
>;
export type SetMilestoneDesignPhaseMutationResult =
  Apollo.MutationResult<SetMilestoneDesignPhaseMutation>;
export type SetMilestoneDesignPhaseMutationOptions = Apollo.BaseMutationOptions<
  SetMilestoneDesignPhaseMutation,
  SetMilestoneDesignPhaseMutationVariables
>;
export const SetMilestoneTimelinePhaseDocument = gql`
  mutation setMilestoneTimelinePhase($projectID: UUID!, $input: SetMilestoneTimelinePhaseInput!) {
    setMilestoneTimelinePhase(projectID: $projectID, input: $input) {
      ...detailedMilestoneFields
    }
  }
  ${DetailedMilestoneFieldsFragmentDoc}
`;
export type SetMilestoneTimelinePhaseMutationFn = Apollo.MutationFunction<
  SetMilestoneTimelinePhaseMutation,
  SetMilestoneTimelinePhaseMutationVariables
>;
export type SetMilestoneTimelinePhaseMutationResult =
  Apollo.MutationResult<SetMilestoneTimelinePhaseMutation>;
export type SetMilestoneTimelinePhaseMutationOptions = Apollo.BaseMutationOptions<
  SetMilestoneTimelinePhaseMutation,
  SetMilestoneTimelinePhaseMutationVariables
>;
export const GetMilestoneDocument = gql`
  query getMilestone($id: UUID!) {
    milestone(id: $id) {
      ...detailedMilestoneFields
    }
  }
  ${DetailedMilestoneFieldsFragmentDoc}
`;
export type GetMilestoneQueryResult = Apollo.QueryResult<
  GetMilestoneQuery,
  GetMilestoneQueryVariables
>;
export const DesignPhaseTypesDocument = gql`
  query designPhaseTypes {
    designPhaseTypes {
      id
      name
      abbreviation
    }
  }
`;
export type DesignPhaseTypesQueryResult = Apollo.QueryResult<
  DesignPhaseTypesQuery,
  DesignPhaseTypesQueryVariables
>;
export const GetMilestonesDocument = gql`
  query getMilestones($projectID: UUID!, $showDrafts: Boolean!) {
    milestones(projectID: $projectID, showDrafts: $showDrafts) {
      ...commonMilestoneFields
    }
  }
  ${CommonMilestoneFieldsFragmentDoc}
`;
export type GetMilestonesQueryResult = Apollo.QueryResult<
  GetMilestonesQuery,
  GetMilestonesQueryVariables
>;
export const GetDetailedMilestonesDocument = gql`
  query getDetailedMilestones($projectID: UUID!, $showDrafts: Boolean!) {
    milestones(projectID: $projectID, showDrafts: $showDrafts) {
      ...detailedMilestoneFields
    }
  }
  ${DetailedMilestoneFieldsFragmentDoc}
`;
export type GetDetailedMilestonesQueryResult = Apollo.QueryResult<
  GetDetailedMilestonesQuery,
  GetDetailedMilestonesQueryVariables
>;
export const OrganizationsDocument = gql`
  query organizations($companyID: UUID!) {
    organizations(companyID: $companyID) {
      ...organizationFields
    }
  }
  ${OrganizationFieldsFragmentDoc}
`;
export type OrganizationsQueryResult = Apollo.QueryResult<
  OrganizationsQuery,
  OrganizationsQueryVariables
>;
export const OrganizationDocument = gql`
  query organization($organizationID: UUID!) {
    organization(organizationID: $organizationID) {
      ...organizationFields
    }
  }
  ${OrganizationFieldsFragmentDoc}
`;
export type OrganizationQueryResult = Apollo.QueryResult<
  OrganizationQuery,
  OrganizationQueryVariables
>;
export const GetProjectsUsingOrgNodeDocument = gql`
  query getProjectsUsingOrgNode($orgNodeID: UUID!) {
    getProjectsUsingOrgNode(orgNodeID: $orgNodeID) {
      name
      projectID
      nodeID
    }
  }
`;
export type GetProjectsUsingOrgNodeQueryResult = Apollo.QueryResult<
  GetProjectsUsingOrgNodeQuery,
  GetProjectsUsingOrgNodeQueryVariables
>;
export const GetProjectsUsingOrgDocument = gql`
  query getProjectsUsingOrg($organizationID: UUID!) {
    getProjectsUsingOrg(organizationID: $organizationID) {
      name
      projectID
      nodeID
    }
  }
`;
export type GetProjectsUsingOrgQueryResult = Apollo.QueryResult<
  GetProjectsUsingOrgQuery,
  GetProjectsUsingOrgQueryVariables
>;
export const CreateOrgDocument = gql`
  mutation CreateOrg($input: CreateOrgInput!) {
    createOrg(input: $input) {
      organization {
        ...organizationFields
      }
    }
  }
  ${OrganizationFieldsFragmentDoc}
`;
export type CreateOrgMutationFn = Apollo.MutationFunction<
  CreateOrgMutation,
  CreateOrgMutationVariables
>;
export type CreateOrgMutationResult = Apollo.MutationResult<CreateOrgMutation>;
export type CreateOrgMutationOptions = Apollo.BaseMutationOptions<
  CreateOrgMutation,
  CreateOrgMutationVariables
>;
export const CreateOrgNodeDocument = gql`
  mutation CreateOrgNode($input: CreateOrgNodeInput!) {
    createOrgNode(input: $input) {
      organization {
        ...organizationFields
      }
    }
  }
  ${OrganizationFieldsFragmentDoc}
`;
export type CreateOrgNodeMutationFn = Apollo.MutationFunction<
  CreateOrgNodeMutation,
  CreateOrgNodeMutationVariables
>;
export type CreateOrgNodeMutationResult = Apollo.MutationResult<CreateOrgNodeMutation>;
export type CreateOrgNodeMutationOptions = Apollo.BaseMutationOptions<
  CreateOrgNodeMutation,
  CreateOrgNodeMutationVariables
>;
export const DeleteOrgDocument = gql`
  mutation DeleteOrg($input: DeleteOrgInput!) {
    deleteOrg(input: $input) {
      organization {
        id
      }
    }
  }
`;
export type DeleteOrgMutationFn = Apollo.MutationFunction<
  DeleteOrgMutation,
  DeleteOrgMutationVariables
>;
export type DeleteOrgMutationResult = Apollo.MutationResult<DeleteOrgMutation>;
export type DeleteOrgMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrgMutation,
  DeleteOrgMutationVariables
>;
export const DeleteOrgNodeDocument = gql`
  mutation DeleteOrgNode($input: DeleteOrgNodeInput!) {
    deleteOrgNode(input: $input) {
      organization {
        ...organizationFields
      }
    }
  }
  ${OrganizationFieldsFragmentDoc}
`;
export type DeleteOrgNodeMutationFn = Apollo.MutationFunction<
  DeleteOrgNodeMutation,
  DeleteOrgNodeMutationVariables
>;
export type DeleteOrgNodeMutationResult = Apollo.MutationResult<DeleteOrgNodeMutation>;
export type DeleteOrgNodeMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrgNodeMutation,
  DeleteOrgNodeMutationVariables
>;
export const SetOrgLevelDocument = gql`
  mutation setOrgLevel($input: SetOrgLevelInput!) {
    setOrgLevel(input: $input) {
      ...organizationFields
    }
  }
  ${OrganizationFieldsFragmentDoc}
`;
export type SetOrgLevelMutationFn = Apollo.MutationFunction<
  SetOrgLevelMutation,
  SetOrgLevelMutationVariables
>;
export type SetOrgLevelMutationResult = Apollo.MutationResult<SetOrgLevelMutation>;
export type SetOrgLevelMutationOptions = Apollo.BaseMutationOptions<
  SetOrgLevelMutation,
  SetOrgLevelMutationVariables
>;
export const SetOrgNameDocument = gql`
  mutation setOrgName($input: SetOrgNameInput!) {
    setOrgName(input: $input) {
      ...organizationFields
    }
  }
  ${OrganizationFieldsFragmentDoc}
`;
export type SetOrgNameMutationFn = Apollo.MutationFunction<
  SetOrgNameMutation,
  SetOrgNameMutationVariables
>;
export type SetOrgNameMutationResult = Apollo.MutationResult<SetOrgNameMutation>;
export type SetOrgNameMutationOptions = Apollo.BaseMutationOptions<
  SetOrgNameMutation,
  SetOrgNameMutationVariables
>;
export const AddOrgLevelDocument = gql`
  mutation addOrgLevel($input: AddOrgLevelInput!) {
    addOrgLevel(input: $input) {
      ...organizationFields
    }
  }
  ${OrganizationFieldsFragmentDoc}
`;
export type AddOrgLevelMutationFn = Apollo.MutationFunction<
  AddOrgLevelMutation,
  AddOrgLevelMutationVariables
>;
export type AddOrgLevelMutationResult = Apollo.MutationResult<AddOrgLevelMutation>;
export type AddOrgLevelMutationOptions = Apollo.BaseMutationOptions<
  AddOrgLevelMutation,
  AddOrgLevelMutationVariables
>;
export const DeleteOrgLevelDocument = gql`
  mutation deleteOrgLevel($input: DeleteOrgLevelInput!) {
    deleteOrgLevel(input: $input) {
      ...organizationFields
    }
  }
  ${OrganizationFieldsFragmentDoc}
`;
export type DeleteOrgLevelMutationFn = Apollo.MutationFunction<
  DeleteOrgLevelMutation,
  DeleteOrgLevelMutationVariables
>;
export type DeleteOrgLevelMutationResult = Apollo.MutationResult<DeleteOrgLevelMutation>;
export type DeleteOrgLevelMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrgLevelMutation,
  DeleteOrgLevelMutationVariables
>;
export const SetOrgNodeNameDocument = gql`
  mutation SetOrgNodeName($input: SetOrgNodeNameInput!) {
    setOrgNodeName(input: $input) {
      organization {
        ...organizationFields
      }
    }
  }
  ${OrganizationFieldsFragmentDoc}
`;
export type SetOrgNodeNameMutationFn = Apollo.MutationFunction<
  SetOrgNodeNameMutation,
  SetOrgNodeNameMutationVariables
>;
export type SetOrgNodeNameMutationResult = Apollo.MutationResult<SetOrgNodeNameMutation>;
export type SetOrgNodeNameMutationOptions = Apollo.BaseMutationOptions<
  SetOrgNodeNameMutation,
  SetOrgNodeNameMutationVariables
>;
export const SetOrgPublishedDocument = gql`
  mutation SetOrgPublished($input: SetOrgPublishedInput!) {
    setOrgPublished(input: $input) {
      organization {
        ...organizationFields
      }
    }
  }
  ${OrganizationFieldsFragmentDoc}
`;
export type SetOrgPublishedMutationFn = Apollo.MutationFunction<
  SetOrgPublishedMutation,
  SetOrgPublishedMutationVariables
>;
export type SetOrgPublishedMutationResult = Apollo.MutationResult<SetOrgPublishedMutation>;
export type SetOrgPublishedMutationOptions = Apollo.BaseMutationOptions<
  SetOrgPublishedMutation,
  SetOrgPublishedMutationVariables
>;
export const SetProjectOrgsDocument = gql`
  mutation SetProjectOrgs($input: SetProjectOrgsInput!) {
    setProjectOrgs(input: $input) {
      removedNodeIDs
    }
  }
`;
export type SetProjectOrgsMutationFn = Apollo.MutationFunction<
  SetProjectOrgsMutation,
  SetProjectOrgsMutationVariables
>;
export type SetProjectOrgsMutationResult = Apollo.MutationResult<SetProjectOrgsMutation>;
export type SetProjectOrgsMutationOptions = Apollo.BaseMutationOptions<
  SetProjectOrgsMutation,
  SetProjectOrgsMutationVariables
>;
export const SetBulkProjectOrgsDocument = gql`
  mutation SetBulkProjectOrgs($input: SetBulkProjectOrgsInput!) {
    setBulkProjectOrgs(input: $input) {
      removedNodeIDs
    }
  }
`;
export type SetBulkProjectOrgsMutationFn = Apollo.MutationFunction<
  SetBulkProjectOrgsMutation,
  SetBulkProjectOrgsMutationVariables
>;
export type SetBulkProjectOrgsMutationResult = Apollo.MutationResult<SetBulkProjectOrgsMutation>;
export type SetBulkProjectOrgsMutationOptions = Apollo.BaseMutationOptions<
  SetBulkProjectOrgsMutation,
  SetBulkProjectOrgsMutationVariables
>;
export const ReportPageLoadDocument = gql`
  mutation reportPageLoad(
    $project: UUID
    $type: UserEventType!
    $page: String!
    $loadTime: Float!
  ) {
    reportUserEvent(
      userEvent: {
        type: $type
        projectID: $project
        eventData: { page: $page, loadTime: $loadTime }
      }
    )
  }
`;
export type ReportPageLoadMutationFn = Apollo.MutationFunction<
  ReportPageLoadMutation,
  ReportPageLoadMutationVariables
>;
export type ReportPageLoadMutationResult = Apollo.MutationResult<ReportPageLoadMutation>;
export type ReportPageLoadMutationOptions = Apollo.BaseMutationOptions<
  ReportPageLoadMutation,
  ReportPageLoadMutationVariables
>;
export const ReportImportEstimateTimeDocument = gql`
  mutation reportImportEstimateTime(
    $milestoneEstimateID: UUID!
    $loadTime: Float!
    $type: UserEventType!
  ) {
    reportImportEstimateTimeEvent(
      milestoneEstimateID: $milestoneEstimateID
      loadTime: $loadTime
      type: $type
    )
  }
`;
export type ReportImportEstimateTimeMutationFn = Apollo.MutationFunction<
  ReportImportEstimateTimeMutation,
  ReportImportEstimateTimeMutationVariables
>;
export type ReportImportEstimateTimeMutationResult =
  Apollo.MutationResult<ReportImportEstimateTimeMutation>;
export type ReportImportEstimateTimeMutationOptions = Apollo.BaseMutationOptions<
  ReportImportEstimateTimeMutation,
  ReportImportEstimateTimeMutationVariables
>;
export const MilestonesCostTrendsBreakdownsDataDocument = gql`
  query milestonesCostTrendsBreakdownsData(
    $projectID: UUID!
    $columns: [CostReportColumnInput!]!
    $costMode: CostMode!
    $intervalType: IntervalType!
    $viewFilters: [ViewFilter!]!
    $summary: Boolean!
    $milestones: [CostReportMilestoneInput!]!
    $groupByItems: [GroupByItem!]!
  ) {
    milestonesCostTrendsBreakdowns(
      parameters: {
        projectID: $projectID
        columns: $columns
        costMode: $costMode
        intervalType: $intervalType
        viewFilters: $viewFilters
        summary: $summary
        milestones: $milestones
        groupByItems: $groupByItems
      }
    ) {
      segments {
        milestone {
          id
          name
          description
          date
          quantities {
            ...quantityFields
          }
        }
        points {
          dateStart
          dateEnd
          values {
            type
            y
            isChanged
          }
          isVisible
        }
      }
      milestoneCostReports {
        costReportColumns {
          ...commonCostReportColumnFields
        }
      }
      categoryID
    }
  }
  ${QuantityFieldsFragmentDoc}
  ${CommonCostReportColumnFieldsFragmentDoc}
`;
export type MilestonesCostTrendsBreakdownsDataQueryResult = Apollo.QueryResult<
  MilestonesCostTrendsBreakdownsDataQuery,
  MilestonesCostTrendsBreakdownsDataQueryVariables
>;
export const ListDetailedItemsDocument = gql`
  query listDetailedItems(
    $projectId: UUID!
    $milestoneId: UUID
    $costMode: CostMode!
    $viewFilter: ViewFilter!
  ) {
    items(
      query: {
        project: $projectId
        milestone: $milestoneId
        costMode: $costMode
        viewFilter: $viewFilter
      }
    ) {
      ...detailedPrintItemFields
    }
  }
  ${DetailedPrintItemFieldsFragmentDoc}
`;
export type ListDetailedItemsQueryResult = Apollo.QueryResult<
  ListDetailedItemsQuery,
  ListDetailedItemsQueryVariables
>;
export const ExportAverageCompToExistingMilestoneDocument = gql`
  mutation exportAverageCompToExistingMilestone(
    $projectID: UUID!
    $milestoneID: UUID!
    $projectCompsSetInput: ProjectCompsSetInput!
    $exportAverageCompEstimateInput: ExportAverageCompEstimateInput!
  ) {
    exportAverageCompToExistingMilestone(
      projectID: $projectID
      milestoneID: $milestoneID
      projectCompsSetInput: $projectCompsSetInput
      exportAverageCompEstimateInput: $exportAverageCompEstimateInput
    ) {
      id
    }
  }
`;
export type ExportAverageCompToExistingMilestoneMutationFn = Apollo.MutationFunction<
  ExportAverageCompToExistingMilestoneMutation,
  ExportAverageCompToExistingMilestoneMutationVariables
>;
export type ExportAverageCompToExistingMilestoneMutationResult =
  Apollo.MutationResult<ExportAverageCompToExistingMilestoneMutation>;
export type ExportAverageCompToExistingMilestoneMutationOptions = Apollo.BaseMutationOptions<
  ExportAverageCompToExistingMilestoneMutation,
  ExportAverageCompToExistingMilestoneMutationVariables
>;
export const ExportAverageCompToNewMilestoneDocument = gql`
  mutation exportAverageCompToNewMilestone(
    $projectID: UUID!
    $milestoneInput: MilestoneInput!
    $projectCompsSetInput: ProjectCompsSetInput!
    $exportAverageCompEstimateInput: ExportAverageCompEstimateInput!
  ) {
    exportAverageCompToNewMilestone(
      projectID: $projectID
      milestoneInput: $milestoneInput
      projectCompsSetInput: $projectCompsSetInput
      exportAverageCompEstimateInput: $exportAverageCompEstimateInput
    ) {
      id
    }
  }
`;
export type ExportAverageCompToNewMilestoneMutationFn = Apollo.MutationFunction<
  ExportAverageCompToNewMilestoneMutation,
  ExportAverageCompToNewMilestoneMutationVariables
>;
export type ExportAverageCompToNewMilestoneMutationResult =
  Apollo.MutationResult<ExportAverageCompToNewMilestoneMutation>;
export type ExportAverageCompToNewMilestoneMutationOptions = Apollo.BaseMutationOptions<
  ExportAverageCompToNewMilestoneMutation,
  ExportAverageCompToNewMilestoneMutationVariables
>;
export const ProjectCategorizationsCategoryMatchesDocument = gql`
  query projectCategorizationsCategoryMatches($projectID: UUID!, $categoryNumbers: [String!]!) {
    projectCategorizationsCategoryMatches(
      projectID: $projectID
      categoryNumbers: $categoryNumbers
    ) {
      categorizationID
      matchingCategories
    }
  }
`;
export type ProjectCategorizationsCategoryMatchesQueryResult = Apollo.QueryResult<
  ProjectCategorizationsCategoryMatchesQuery,
  ProjectCategorizationsCategoryMatchesQueryVariables
>;
export const TimeEscalationDocument = gql`
  query timeEscalation($from: Time!, $to: Time!) {
    timeEscalation(from: $from, to: $to) {
      percentage
      fuzzyMatch
      from {
        year
        quarter
        index
      }
      to {
        year
        quarter
        index
      }
    }
  }
`;
export type TimeEscalationQueryResult = Apollo.QueryResult<
  TimeEscalationQuery,
  TimeEscalationQueryVariables
>;
export const LocationEscalationDocument = gql`
  query locationEscalation($from: LocationInput!, $to: LocationInput!) {
    locationEscalation(from: $from, to: $to) {
      percentage
      fuzzyMatch
      targetIsFuzzyMatch
      sourceIsFuzzyMatch
      from {
        index
        lat
        lon
        name
      }
      to {
        index
        lat
        lon
        name
      }
    }
  }
`;
export type LocationEscalationQueryResult = Apollo.QueryResult<
  LocationEscalationQuery,
  LocationEscalationQueryVariables
>;
export const CityCostIndicesDocument = gql`
  query cityCostIndices($pagination: Pagination!) {
    cityCostIndices(pagination: $pagination) {
      cityCostIndices {
        name
        lat
        lon
        index
      }
      total
    }
  }
`;
export type CityCostIndicesQueryResult = Apollo.QueryResult<
  CityCostIndicesQuery,
  CityCostIndicesQueryVariables
>;
export const ProjectCompsSetDocument = gql`
  query projectCompsSet($projectCompsSetInput: ProjectCompsSetInput, $reportID: UUID) {
    projectCompSettingsReactive @client @export(as: "projectCompsSetInput")
    projectCompsSet(projectCompsSetInput: $projectCompsSetInput, reportID: $reportID) {
      ...commonProjectCompsSetFields
    }
  }
  ${CommonProjectCompsSetFieldsFragmentDoc}
`;
export type ProjectCompsSetQueryResult = Apollo.QueryResult<
  ProjectCompsSetQuery,
  ProjectCompsSetQueryVariables
>;
export const LocationEscalationMultipleDocument = gql`
  query locationEscalationMultiple($locations: [LocationEscalationInput!]!) {
    locationEscalationMultiple(locations: $locations) {
      percentage
      fuzzyMatch
      targetIsFuzzyMatch
      sourceIsFuzzyMatch
      from {
        index
        lat
        lon
        name
      }
      to {
        index
        lat
        lon
        name
      }
    }
  }
`;
export type LocationEscalationMultipleQueryResult = Apollo.QueryResult<
  LocationEscalationMultipleQuery,
  LocationEscalationMultipleQueryVariables
>;
export const TimeEscalationMultipleDocument = gql`
  query timeEscalationMultiple($dates: [TimeEscalationInput!]!) {
    timeEscalationMultiple(dates: $dates) {
      from {
        year
        quarter
        index
      }
      fuzzyMatch
      percentage
      to {
        year
        quarter
        index
      }
    }
  }
`;
export type TimeEscalationMultipleQueryResult = Apollo.QueryResult<
  TimeEscalationMultipleQuery,
  TimeEscalationMultipleQueryVariables
>;
export const ProjectCostBreakdownReportDocument = gql`
  query projectCostBreakdownReport(
    $projectID: UUID!
    $milestoneID: UUID!
    $estimateType: EstimateType!
    $costMode: CostMode!
  ) {
    projectCostBreakdownReport(
      input: {
        projectID: $projectID
        milestoneID: $milestoneID
        estimateType: $estimateType
        costMode: $costMode
      }
    ) {
      total
      breakdown {
        type
        cost
        percent
      }
    }
  }
`;
export type ProjectCostBreakdownReportQueryResult = Apollo.QueryResult<
  ProjectCostBreakdownReportQuery,
  ProjectCostBreakdownReportQueryVariables
>;
export const ProjectSettingsDocument = gql`
  query projectSettings($projectID: UUID!) {
    projectSettings(projectID: $projectID) {
      ...commonProjectSetting
    }
  }
  ${CommonProjectSettingFragmentDoc}
`;
export type ProjectSettingsQueryResult = Apollo.QueryResult<
  ProjectSettingsQuery,
  ProjectSettingsQueryVariables
>;
export const UpdateProjectSettingDocument = gql`
  mutation updateProjectSetting($projectID: UUID!, $key: String!, $value: String!) {
    updateProjectSetting(projectID: $projectID, key: $key, value: $value) {
      ...commonProjectSetting
    }
  }
  ${CommonProjectSettingFragmentDoc}
`;
export type UpdateProjectSettingMutationFn = Apollo.MutationFunction<
  UpdateProjectSettingMutation,
  UpdateProjectSettingMutationVariables
>;
export type UpdateProjectSettingMutationResult =
  Apollo.MutationResult<UpdateProjectSettingMutation>;
export type UpdateProjectSettingMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectSettingMutation,
  UpdateProjectSettingMutationVariables
>;
export const ProjectTerminologyDocument = gql`
  query projectTerminology($projectID: UUID!) {
    projectTerminology(projectID: $projectID) {
      ...commonTerminology
    }
  }
  ${CommonTerminologyFragmentDoc}
`;
export type ProjectTerminologyQueryResult = Apollo.QueryResult<
  ProjectTerminologyQuery,
  ProjectTerminologyQueryVariables
>;
export const UpdateProjectTerminologyDocument = gql`
  mutation updateProjectTerminology($projectID: UUID!, $key: String!, $overrideValue: String!) {
    updateProjectTerminology(projectID: $projectID, key: $key, overrideValue: $overrideValue) {
      ...commonTerminology
    }
  }
  ${CommonTerminologyFragmentDoc}
`;
export type UpdateProjectTerminologyMutationFn = Apollo.MutationFunction<
  UpdateProjectTerminologyMutation,
  UpdateProjectTerminologyMutationVariables
>;
export type UpdateProjectTerminologyMutationResult =
  Apollo.MutationResult<UpdateProjectTerminologyMutation>;
export type UpdateProjectTerminologyMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectTerminologyMutation,
  UpdateProjectTerminologyMutationVariables
>;
export const ResetProjectTerminologyDocument = gql`
  mutation resetProjectTerminology($projectID: UUID!, $key: String!) {
    resetProjectTerminology(projectID: $projectID, key: $key) {
      ...commonTerminology
    }
  }
  ${CommonTerminologyFragmentDoc}
`;
export type ResetProjectTerminologyMutationFn = Apollo.MutationFunction<
  ResetProjectTerminologyMutation,
  ResetProjectTerminologyMutationVariables
>;
export type ResetProjectTerminologyMutationResult =
  Apollo.MutationResult<ResetProjectTerminologyMutation>;
export type ResetProjectTerminologyMutationOptions = Apollo.BaseMutationOptions<
  ResetProjectTerminologyMutation,
  ResetProjectTerminologyMutationVariables
>;
export const CreateProjectBannerDocument = gql`
  mutation createProjectBanner($assetID: UUID!, $assetName: String!, $projectID: UUID!) {
    createProjectBanner(assetID: $assetID, assetName: $assetName, projectID: $projectID) {
      id
      bannerName
      asset {
        id
        location
        name
      }
    }
  }
`;
export type CreateProjectBannerMutationFn = Apollo.MutationFunction<
  CreateProjectBannerMutation,
  CreateProjectBannerMutationVariables
>;
export type CreateProjectBannerMutationResult = Apollo.MutationResult<CreateProjectBannerMutation>;
export type CreateProjectBannerMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectBannerMutation,
  CreateProjectBannerMutationVariables
>;
export const ProjectBannersDocument = gql`
  query projectBanners($projectID: UUID!) {
    projectBanners(projectID: $projectID) {
      id
      bannerName
      asset {
        id
        location
        name
      }
    }
  }
`;
export type ProjectBannersQueryResult = Apollo.QueryResult<
  ProjectBannersQuery,
  ProjectBannersQueryVariables
>;
export const ActiveProjectBannerDocument = gql`
  query activeProjectBanner($projectID: UUID!) {
    activeProjectBanner(projectID: $projectID) {
      id
      bannerName
      asset {
        id
        location
        name
      }
    }
  }
`;
export type ActiveProjectBannerQueryResult = Apollo.QueryResult<
  ActiveProjectBannerQuery,
  ActiveProjectBannerQueryVariables
>;
export const SetActiveProjectBannerDocument = gql`
  mutation setActiveProjectBanner($assetID: UUID!, $projectID: UUID!) {
    setActiveProjectBanner(assetID: $assetID, projectID: $projectID) {
      id
      bannerName
      asset {
        id
        location
        name
      }
    }
  }
`;
export type SetActiveProjectBannerMutationFn = Apollo.MutationFunction<
  SetActiveProjectBannerMutation,
  SetActiveProjectBannerMutationVariables
>;
export type SetActiveProjectBannerMutationResult =
  Apollo.MutationResult<SetActiveProjectBannerMutation>;
export type SetActiveProjectBannerMutationOptions = Apollo.BaseMutationOptions<
  SetActiveProjectBannerMutation,
  SetActiveProjectBannerMutationVariables
>;
export const RemoveActiveProjectBannerDocument = gql`
  mutation removeActiveProjectBanner($projectID: UUID!) {
    removeActiveProjectBanner(projectID: $projectID)
  }
`;
export type RemoveActiveProjectBannerMutationFn = Apollo.MutationFunction<
  RemoveActiveProjectBannerMutation,
  RemoveActiveProjectBannerMutationVariables
>;
export type RemoveActiveProjectBannerMutationResult =
  Apollo.MutationResult<RemoveActiveProjectBannerMutation>;
export type RemoveActiveProjectBannerMutationOptions = Apollo.BaseMutationOptions<
  RemoveActiveProjectBannerMutation,
  RemoveActiveProjectBannerMutationVariables
>;
export const RemoveProjectBannerDocument = gql`
  mutation removeProjectBanner($bannerID: UUID!, $projectID: UUID!) {
    removeProjectBanner(bannerID: $bannerID, projectID: $projectID)
  }
`;
export type RemoveProjectBannerMutationFn = Apollo.MutationFunction<
  RemoveProjectBannerMutation,
  RemoveProjectBannerMutationVariables
>;
export type RemoveProjectBannerMutationResult = Apollo.MutationResult<RemoveProjectBannerMutation>;
export type RemoveProjectBannerMutationOptions = Apollo.BaseMutationOptions<
  RemoveProjectBannerMutation,
  RemoveProjectBannerMutationVariables
>;
export const GetProjectThumbnailDocument = gql`
  query getProjectThumbnail($projectID: UUID!) {
    projectThumbnail(projectID: $projectID) {
      id
      name
      location
    }
  }
`;
export type GetProjectThumbnailQueryResult = Apollo.QueryResult<
  GetProjectThumbnailQuery,
  GetProjectThumbnailQueryVariables
>;
export const UpdateUserNotificationSettingDocument = gql`
  mutation updateUserNotificationSetting(
    $projectID: UUID!
    $isGlobalSubscribed: Boolean
    $comments: CommentNotificationSetting
    $assignments: Boolean
    $newItems: Boolean
    $mentions: Boolean
    $shareScenarios: Boolean
  ) {
    updateUserNotificationSetting(
      projectID: $projectID
      userNotificationSetting: {
        isGlobalSubscribed: $isGlobalSubscribed
        comments: $comments
        assignments: $assignments
        newItems: $newItems
        mentions: $mentions
        shareScenarios: $shareScenarios
      }
    ) {
      ...commonUserNotificationSettingFields
    }
  }
  ${CommonUserNotificationSettingFieldsFragmentDoc}
`;
export type UpdateUserNotificationSettingMutationFn = Apollo.MutationFunction<
  UpdateUserNotificationSettingMutation,
  UpdateUserNotificationSettingMutationVariables
>;
export type UpdateUserNotificationSettingMutationResult =
  Apollo.MutationResult<UpdateUserNotificationSettingMutation>;
export type UpdateUserNotificationSettingMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserNotificationSettingMutation,
  UpdateUserNotificationSettingMutationVariables
>;
export const UpdateUserInAppNotificationSettingDocument = gql`
  mutation updateUserInAppNotificationSetting(
    $projectID: UUID!
    $isGlobalSubscribed: Boolean
    $comments: CommentNotificationSetting
    $assignments: Boolean
    $newItems: Boolean
    $mentions: Boolean
    $shareScenarios: Boolean
  ) {
    updateUserInAppNotificationSetting(
      projectID: $projectID
      userNotificationSetting: {
        isGlobalSubscribed: $isGlobalSubscribed
        comments: $comments
        assignments: $assignments
        newItems: $newItems
        mentions: $mentions
        shareScenarios: $shareScenarios
      }
    ) {
      ...commonUserNotificationSettingFields
    }
  }
  ${CommonUserNotificationSettingFieldsFragmentDoc}
`;
export type UpdateUserInAppNotificationSettingMutationFn = Apollo.MutationFunction<
  UpdateUserInAppNotificationSettingMutation,
  UpdateUserInAppNotificationSettingMutationVariables
>;
export type UpdateUserInAppNotificationSettingMutationResult =
  Apollo.MutationResult<UpdateUserInAppNotificationSettingMutation>;
export type UpdateUserInAppNotificationSettingMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserInAppNotificationSettingMutation,
  UpdateUserInAppNotificationSettingMutationVariables
>;
export const SetProjectTypeDocument = gql`
  mutation setProjectType($projectID: UUID!, $typeID: UUID!) {
    setProjectType(projectID: $projectID, typeID: $typeID) {
      ...commonProjectPropsFields
    }
  }
  ${CommonProjectPropsFieldsFragmentDoc}
`;
export type SetProjectTypeMutationFn = Apollo.MutationFunction<
  SetProjectTypeMutation,
  SetProjectTypeMutationVariables
>;
export type SetProjectTypeMutationResult = Apollo.MutationResult<SetProjectTypeMutation>;
export type SetProjectTypeMutationOptions = Apollo.BaseMutationOptions<
  SetProjectTypeMutation,
  SetProjectTypeMutationVariables
>;
export const ProjectsCountsDocument = gql`
  query projectsCounts($projectsFilterBy: ProjectsFilterBy!, $projectsSearch: String!) {
    projectsCounts(projectsFilterBy: $projectsFilterBy, projectsSearch: $projectsSearch) {
      allProjectsCount
      myProjectsCount
    }
  }
`;
export type ProjectsCountsQueryResult = Apollo.QueryResult<
  ProjectsCountsQuery,
  ProjectsCountsQueryVariables
>;
export const ProjectsFilterOptionsDocument = gql`
  query projectsFilterOptions(
    $includeCompanyProjects: Boolean!
    $includeUserProjects: Boolean
    $companyID: UUID
  ) {
    projectsFilterOptions(
      includeCompanyProjects: $includeCompanyProjects
      includeUserProjects: $includeUserProjects
      companyID: $companyID
    ) {
      locations
      statuses
      companies
      types
      projectLeads {
        id
        name
      }
      projectTypes {
        ...commonProjectTypeFields
      }
      organizationNodes {
        id
        name
      }
    }
  }
  ${CommonProjectTypeFieldsFragmentDoc}
`;
export type ProjectsFilterOptionsQueryResult = Apollo.QueryResult<
  ProjectsFilterOptionsQuery,
  ProjectsFilterOptionsQueryVariables
>;
export const SearchProjectsFilterOptionsDocument = gql`
  query searchProjectsFilterOptions($searchResultType: SearchResultType!) {
    searchProjectsFilterOptions(searchResultType: $searchResultType) {
      locations
      statuses
      companies
      types
      milestoneDesignPhases
      projectLeads {
        id
        name
      }
      projectTypes {
        ...commonProjectTypeFields
      }
      organizationNodes {
        id
        name
      }
    }
  }
  ${CommonProjectTypeFieldsFragmentDoc}
`;
export type SearchProjectsFilterOptionsQueryResult = Apollo.QueryResult<
  SearchProjectsFilterOptionsQuery,
  SearchProjectsFilterOptionsQueryVariables
>;
export const GetProjectStatusesDocument = gql`
  query getProjectStatuses {
    projectStatuses {
      ...commonProjectStatusFields
    }
  }
  ${CommonProjectStatusFieldsFragmentDoc}
`;
export type GetProjectStatusesQueryResult = Apollo.QueryResult<
  GetProjectStatusesQuery,
  GetProjectStatusesQueryVariables
>;
export const UserReportsAndProjectPropsDocument = gql`
  query userReportsAndProjectProps($projectID: UUID!) {
    userReports(projectID: $projectID) {
      ...commonUserReportFields
    }
    project(id: $projectID) {
      ...commonProjectPropsFields
    }
  }
  ${CommonUserReportFieldsFragmentDoc}
  ${CommonProjectPropsFieldsFragmentDoc}
`;
export type UserReportsAndProjectPropsQueryResult = Apollo.QueryResult<
  UserReportsAndProjectPropsQuery,
  UserReportsAndProjectPropsQueryVariables
>;
export const UserReportDocument = gql`
  query userReport($projectID: UUID!, $reportID: UUID!) {
    userReport(projectID: $projectID, reportID: $reportID) {
      ...commonUserReportFields
    }
  }
  ${CommonUserReportFieldsFragmentDoc}
`;
export type UserReportQueryResult = Apollo.QueryResult<UserReportQuery, UserReportQueryVariables>;
export const UserReportsDocument = gql`
  query userReports($projectID: UUID!) {
    userReports(projectID: $projectID) {
      ...commonUserReportFields
    }
  }
  ${CommonUserReportFieldsFragmentDoc}
`;
export type UserReportsQueryResult = Apollo.QueryResult<
  UserReportsQuery,
  UserReportsQueryVariables
>;
export const CreateUserReportDocument = gql`
  mutation createUserReport($userReport: UserReportInput!, $projectID: UUID!) {
    createUserReport(userReport: $userReport, projectID: $projectID) {
      ...commonUserReportFields
    }
  }
  ${CommonUserReportFieldsFragmentDoc}
`;
export type CreateUserReportMutationFn = Apollo.MutationFunction<
  CreateUserReportMutation,
  CreateUserReportMutationVariables
>;
export type CreateUserReportMutationResult = Apollo.MutationResult<CreateUserReportMutation>;
export type CreateUserReportMutationOptions = Apollo.BaseMutationOptions<
  CreateUserReportMutation,
  CreateUserReportMutationVariables
>;
export const UpdateUserReportDocument = gql`
  mutation updateUserReport($userReport: UserReportInput!, $projectID: UUID!) {
    updateUserReport(userReport: $userReport, projectID: $projectID) {
      ...commonUserReportFields
    }
  }
  ${CommonUserReportFieldsFragmentDoc}
`;
export type UpdateUserReportMutationFn = Apollo.MutationFunction<
  UpdateUserReportMutation,
  UpdateUserReportMutationVariables
>;
export type UpdateUserReportMutationResult = Apollo.MutationResult<UpdateUserReportMutation>;
export type UpdateUserReportMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserReportMutation,
  UpdateUserReportMutationVariables
>;
export const RemoveUserReportDocument = gql`
  mutation removeUserReport($projectID: UUID!, $reportID: UUID!) {
    removeUserReport(projectID: $projectID, reportID: $reportID)
  }
`;
export type RemoveUserReportMutationFn = Apollo.MutationFunction<
  RemoveUserReportMutation,
  RemoveUserReportMutationVariables
>;
export type RemoveUserReportMutationResult = Apollo.MutationResult<RemoveUserReportMutation>;
export type RemoveUserReportMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserReportMutation,
  RemoveUserReportMutationVariables
>;
export const CreateReportDistributionDocument = gql`
  mutation createReportDistribution($projectID: UUID!, $input: ReportDistributionInput!) {
    createReportDistribution(projectID: $projectID, input: $input)
  }
`;
export type CreateReportDistributionMutationFn = Apollo.MutationFunction<
  CreateReportDistributionMutation,
  CreateReportDistributionMutationVariables
>;
export type CreateReportDistributionMutationResult =
  Apollo.MutationResult<CreateReportDistributionMutation>;
export type CreateReportDistributionMutationOptions = Apollo.BaseMutationOptions<
  CreateReportDistributionMutation,
  CreateReportDistributionMutationVariables
>;
export const DeleteReportDistributionDocument = gql`
  mutation deleteReportDistribution($projectID: UUID!, $reportDistributionID: UUID!) {
    deleteReportDistribution(projectID: $projectID, reportDistributionID: $reportDistributionID)
  }
`;
export type DeleteReportDistributionMutationFn = Apollo.MutationFunction<
  DeleteReportDistributionMutation,
  DeleteReportDistributionMutationVariables
>;
export type DeleteReportDistributionMutationResult =
  Apollo.MutationResult<DeleteReportDistributionMutation>;
export type DeleteReportDistributionMutationOptions = Apollo.BaseMutationOptions<
  DeleteReportDistributionMutation,
  DeleteReportDistributionMutationVariables
>;
export const UpdateReportDistributionDocument = gql`
  mutation updateReportDistribution($projectID: UUID!, $input: ReportDistributionInput!) {
    updateReportDistribution(projectID: $projectID, input: $input)
  }
`;
export type UpdateReportDistributionMutationFn = Apollo.MutationFunction<
  UpdateReportDistributionMutation,
  UpdateReportDistributionMutationVariables
>;
export type UpdateReportDistributionMutationResult =
  Apollo.MutationResult<UpdateReportDistributionMutation>;
export type UpdateReportDistributionMutationOptions = Apollo.BaseMutationOptions<
  UpdateReportDistributionMutation,
  UpdateReportDistributionMutationVariables
>;
export const ReportDistributionDocument = gql`
  query reportDistribution($projectID: UUID!, $reportID: UUID!) {
    reportDistribution(projectID: $projectID, reportID: $reportID) {
      ...commonReportDistributionFields
    }
  }
  ${CommonReportDistributionFieldsFragmentDoc}
`;
export type ReportDistributionQueryResult = Apollo.QueryResult<
  ReportDistributionQuery,
  ReportDistributionQueryVariables
>;
export const ReportDistributionHistoryDocument = gql`
  query reportDistributionHistory($projectID: UUID!) {
    reportDistributionHistory(projectID: $projectID) {
      sentAt
      sentBy
      reportID
      reportName
      id
      deprecated
      isScheduled
      reports {
        roleID
        assetURL
        sentTo
      }
    }
  }
`;
export type ReportDistributionHistoryQueryResult = Apollo.QueryResult<
  ReportDistributionHistoryQuery,
  ReportDistributionHistoryQueryVariables
>;
export const UnsubscribeReportDistributionDocument = gql`
  mutation unsubscribeReportDistribution($projectID: UUID, $reportDistributionID: UUID!) {
    unsubscribeReportDistribution(
      projectID: $projectID
      reportDistributionID: $reportDistributionID
    )
  }
`;
export type UnsubscribeReportDistributionMutationFn = Apollo.MutationFunction<
  UnsubscribeReportDistributionMutation,
  UnsubscribeReportDistributionMutationVariables
>;
export type UnsubscribeReportDistributionMutationResult =
  Apollo.MutationResult<UnsubscribeReportDistributionMutation>;
export type UnsubscribeReportDistributionMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeReportDistributionMutation,
  UnsubscribeReportDistributionMutationVariables
>;
export const ReportIdByReportDistributionAssetIdDocument = gql`
  query reportIDByReportDistributionAssetID($assetID: UUID!) {
    reportIDByReportDistributionAssetID(assetID: $assetID)
  }
`;
export type ReportIdByReportDistributionAssetIdQueryResult = Apollo.QueryResult<
  ReportIdByReportDistributionAssetIdQuery,
  ReportIdByReportDistributionAssetIdQueryVariables
>;
export const LoadScenariosSummaryByMilestoneIdDocument = gql`
  query loadScenariosSummaryByMilestoneID($milestoneID: UUID!, $costMode: CostMode!) {
    loadScenariosSummaryByMilestoneID(milestoneID: $milestoneID, costMode: $costMode) {
      scenarioID
      name
      description
      color
      thumbnailAssetID
      itemsCount
      costImpact {
        ...commonCostReportColumnFields
      }
    }
  }
  ${CommonCostReportColumnFieldsFragmentDoc}
`;
export type LoadScenariosSummaryByMilestoneIdQueryResult = Apollo.QueryResult<
  LoadScenariosSummaryByMilestoneIdQuery,
  LoadScenariosSummaryByMilestoneIdQueryVariables
>;
export const UpdateScenarioItemOverlaysDocument = gql`
  mutation updateScenarioItemOverlays(
    $scenarioID: UUID!
    $itemOverlays: [ItemOverlay!]!
    $milestoneID: UUID!
    $costMode: CostMode!
  ) {
    updateScenarioItemOverlays(
      scenarioID: $scenarioID
      itemOverlays: $itemOverlays
      milestoneID: $milestoneID
      costMode: $costMode
    ) {
      id
    }
  }
`;
export type UpdateScenarioItemOverlaysMutationFn = Apollo.MutationFunction<
  UpdateScenarioItemOverlaysMutation,
  UpdateScenarioItemOverlaysMutationVariables
>;
export type UpdateScenarioItemOverlaysMutationResult =
  Apollo.MutationResult<UpdateScenarioItemOverlaysMutation>;
export type UpdateScenarioItemOverlaysMutationOptions = Apollo.BaseMutationOptions<
  UpdateScenarioItemOverlaysMutation,
  UpdateScenarioItemOverlaysMutationVariables
>;
export const AddItemToScenariosDocument = gql`
  mutation addItemToScenarios($scenarioIDs: [UUID!]!, $itemID: UUID!) {
    addItemToScenarios(scenarioIDs: $scenarioIDs, itemID: $itemID)
  }
`;
export type AddItemToScenariosMutationFn = Apollo.MutationFunction<
  AddItemToScenariosMutation,
  AddItemToScenariosMutationVariables
>;
export type AddItemToScenariosMutationResult = Apollo.MutationResult<AddItemToScenariosMutation>;
export type AddItemToScenariosMutationOptions = Apollo.BaseMutationOptions<
  AddItemToScenariosMutation,
  AddItemToScenariosMutationVariables
>;
export const CreateScenarioInSandboxDocument = gql`
  mutation createScenarioInSandbox(
    $milestoneID: UUID!
    $name: String!
    $description: String
    $color: String!
    $itemOverlays: [ItemOverlay!]!
  ) {
    createScenarioInSandbox(
      milestoneID: $milestoneID
      name: $name
      description: $description
      color: $color
      itemOverlays: $itemOverlays
    )
  }
`;
export type CreateScenarioInSandboxMutationFn = Apollo.MutationFunction<
  CreateScenarioInSandboxMutation,
  CreateScenarioInSandboxMutationVariables
>;
export type CreateScenarioInSandboxMutationResult =
  Apollo.MutationResult<CreateScenarioInSandboxMutation>;
export type CreateScenarioInSandboxMutationOptions = Apollo.BaseMutationOptions<
  CreateScenarioInSandboxMutation,
  CreateScenarioInSandboxMutationVariables
>;
export const CopyScenarioDocument = gql`
  mutation copyScenario($scenarioID: UUID!, $milestoneID: UUID!, $color: String!) {
    copyScenario(scenarioID: $scenarioID, milestoneID: $milestoneID, color: $color)
  }
`;
export type CopyScenarioMutationFn = Apollo.MutationFunction<
  CopyScenarioMutation,
  CopyScenarioMutationVariables
>;
export type CopyScenarioMutationResult = Apollo.MutationResult<CopyScenarioMutation>;
export type CopyScenarioMutationOptions = Apollo.BaseMutationOptions<
  CopyScenarioMutation,
  CopyScenarioMutationVariables
>;
export const UpdateScenarioMetadataDocument = gql`
  mutation updateScenarioMetadata(
    $scenarioID: UUID!
    $name: String
    $description: String
    $color: String
    $thumbnailAssetID: UUID
  ) {
    updateScenarioMetadata(
      scenarioID: $scenarioID
      name: $name
      description: $description
      color: $color
      thumbnailAssetID: $thumbnailAssetID
    ) {
      scenarioID
      name
      description
      color
      thumbnailAssetID
    }
  }
`;
export type UpdateScenarioMetadataMutationFn = Apollo.MutationFunction<
  UpdateScenarioMetadataMutation,
  UpdateScenarioMetadataMutationVariables
>;
export type UpdateScenarioMetadataMutationResult =
  Apollo.MutationResult<UpdateScenarioMetadataMutation>;
export type UpdateScenarioMetadataMutationOptions = Apollo.BaseMutationOptions<
  UpdateScenarioMetadataMutation,
  UpdateScenarioMetadataMutationVariables
>;
export const RemoveScenarioFromSandboxDocument = gql`
  mutation removeScenarioFromSandbox($scenarioID: UUID!, $milestoneID: UUID!) {
    removeScenarioFromSandbox(scenarioID: $scenarioID, milestoneID: $milestoneID)
  }
`;
export type RemoveScenarioFromSandboxMutationFn = Apollo.MutationFunction<
  RemoveScenarioFromSandboxMutation,
  RemoveScenarioFromSandboxMutationVariables
>;
export type RemoveScenarioFromSandboxMutationResult =
  Apollo.MutationResult<RemoveScenarioFromSandboxMutation>;
export type RemoveScenarioFromSandboxMutationOptions = Apollo.BaseMutationOptions<
  RemoveScenarioFromSandboxMutation,
  RemoveScenarioFromSandboxMutationVariables
>;
export const AddScenarioToSandboxDocument = gql`
  mutation addScenarioToSandbox($scenarioID: UUID!, $milestoneID: UUID!) {
    addScenarioToSandbox(scenarioID: $scenarioID, milestoneID: $milestoneID)
  }
`;
export type AddScenarioToSandboxMutationFn = Apollo.MutationFunction<
  AddScenarioToSandboxMutation,
  AddScenarioToSandboxMutationVariables
>;
export type AddScenarioToSandboxMutationResult =
  Apollo.MutationResult<AddScenarioToSandboxMutation>;
export type AddScenarioToSandboxMutationOptions = Apollo.BaseMutationOptions<
  AddScenarioToSandboxMutation,
  AddScenarioToSandboxMutationVariables
>;
export const UpdateSandboxDocument = gql`
  mutation updateSandbox($scenarioIDs: [UUID!]!, $milestoneID: UUID!) {
    updateSandbox(scenarioIDs: $scenarioIDs, milestoneID: $milestoneID)
  }
`;
export type UpdateSandboxMutationFn = Apollo.MutationFunction<
  UpdateSandboxMutation,
  UpdateSandboxMutationVariables
>;
export type UpdateSandboxMutationResult = Apollo.MutationResult<UpdateSandboxMutation>;
export type UpdateSandboxMutationOptions = Apollo.BaseMutationOptions<
  UpdateSandboxMutation,
  UpdateSandboxMutationVariables
>;
export const DeleteScenarioDocument = gql`
  mutation deleteScenario($scenarioID: UUID!) {
    deleteScenario(scenarioID: $scenarioID)
  }
`;
export type DeleteScenarioMutationFn = Apollo.MutationFunction<
  DeleteScenarioMutation,
  DeleteScenarioMutationVariables
>;
export type DeleteScenarioMutationResult = Apollo.MutationResult<DeleteScenarioMutation>;
export type DeleteScenarioMutationOptions = Apollo.BaseMutationOptions<
  DeleteScenarioMutation,
  DeleteScenarioMutationVariables
>;
export const LoadOrCreateScenarioSandboxByMilestoneIdDocument = gql`
  query loadOrCreateScenarioSandboxByMilestoneID(
    $milestoneID: UUID!
    $costMode: CostMode!
    $scenariosSortBy: ScenariosSortBy!
  ) {
    loadOrCreateScenarioSandboxByMilestoneID(
      milestoneID: $milestoneID
      costMode: $costMode
      scenariosSortBy: $scenariosSortBy
    ) {
      id
      milestoneID
      scenarios {
        scenarioID
        name
        description
        color
        thumbnailAssetID
        items {
          ...itemInScenarioFields
        }
        costReports {
          costReportColumns {
            ...commonCostReportColumnFields
          }
        }
        createdBy
      }
      milestone {
        milestoneID
        items {
          ...itemInScenarioFields
        }
        costReports {
          costReportColumns {
            ...commonCostReportColumnFields
          }
        }
      }
    }
  }
  ${ItemInScenarioFieldsFragmentDoc}
  ${CommonCostReportColumnFieldsFragmentDoc}
`;
export type LoadOrCreateScenarioSandboxByMilestoneIdQueryResult = Apollo.QueryResult<
  LoadOrCreateScenarioSandboxByMilestoneIdQuery,
  LoadOrCreateScenarioSandboxByMilestoneIdQueryVariables
>;
export const RemoveItemFromScenariosDocument = gql`
  mutation RemoveItemFromScenarios($scenarioIDs: [UUID!]!, $itemID: UUID!) {
    removeItemFromScenarios(scenarioIDs: $scenarioIDs, itemID: $itemID)
  }
`;
export type RemoveItemFromScenariosMutationFn = Apollo.MutationFunction<
  RemoveItemFromScenariosMutation,
  RemoveItemFromScenariosMutationVariables
>;
export type RemoveItemFromScenariosMutationResult =
  Apollo.MutationResult<RemoveItemFromScenariosMutation>;
export type RemoveItemFromScenariosMutationOptions = Apollo.BaseMutationOptions<
  RemoveItemFromScenariosMutation,
  RemoveItemFromScenariosMutationVariables
>;
export const ApplyScenarioDocument = gql`
  mutation applyScenario($scenarioID: UUID!, $milestoneID: UUID!, $costMode: CostMode!) {
    applyScenario(scenarioID: $scenarioID, milestoneID: $milestoneID, costMode: $costMode)
  }
`;
export type ApplyScenarioMutationFn = Apollo.MutationFunction<
  ApplyScenarioMutation,
  ApplyScenarioMutationVariables
>;
export type ApplyScenarioMutationResult = Apollo.MutationResult<ApplyScenarioMutation>;
export type ApplyScenarioMutationOptions = Apollo.BaseMutationOptions<
  ApplyScenarioMutation,
  ApplyScenarioMutationVariables
>;
export const CreateScenarioItemDocument = gql`
  mutation createScenarioItem(
    $itemInput: ScenarioItemCreationInput!
    $scenarioID: UUID!
    $projectID: UUID!
    $milestoneID: UUID!
    $costMode: CostMode!
  ) {
    createScenarioItem(
      itemInput: $itemInput
      scenarioID: $scenarioID
      projectID: $projectID
      milestoneID: $milestoneID
      costMode: $costMode
    ) {
      ...detailedItemFields
    }
  }
  ${DetailedItemFieldsFragmentDoc}
`;
export type CreateScenarioItemMutationFn = Apollo.MutationFunction<
  CreateScenarioItemMutation,
  CreateScenarioItemMutationVariables
>;
export type CreateScenarioItemMutationResult = Apollo.MutationResult<CreateScenarioItemMutation>;
export type CreateScenarioItemMutationOptions = Apollo.BaseMutationOptions<
  CreateScenarioItemMutation,
  CreateScenarioItemMutationVariables
>;
export const DeleteScenarioItemDocument = gql`
  mutation deleteScenarioItem($itemID: UUID!, $milestoneID: UUID!) {
    deleteScenarioItem(itemID: $itemID, milestoneID: $milestoneID)
  }
`;
export type DeleteScenarioItemMutationFn = Apollo.MutationFunction<
  DeleteScenarioItemMutation,
  DeleteScenarioItemMutationVariables
>;
export type DeleteScenarioItemMutationResult = Apollo.MutationResult<DeleteScenarioItemMutation>;
export type DeleteScenarioItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteScenarioItemMutation,
  DeleteScenarioItemMutationVariables
>;
export const CanApplyScenarioDocument = gql`
  query canApplyScenario(
    $scenarioID: UUID!
    $projectID: UUID!
    $milestoneID: UUID!
    $costMode: CostMode!
  ) {
    canApplyScenario(
      scenarioID: $scenarioID
      projectID: $projectID
      milestoneID: $milestoneID
      costMode: $costMode
    ) {
      hasApplyPermission
      hasChanges
    }
  }
`;
export type CanApplyScenarioQueryResult = Apollo.QueryResult<
  CanApplyScenarioQuery,
  CanApplyScenarioQueryVariables
>;
export const UpsertProjectCompanyRoleDocument = gql`
  mutation upsertProjectCompanyRole($projectID: UUID!, $companyID: UUID!, $role: String!) {
    upsertProjectCompanyRole(projectID: $projectID, companyID: $companyID, role: $role) {
      company {
        id
        domain
        name
        type
        asset {
          id
          name
        }
      }
      role
    }
  }
`;
export type UpsertProjectCompanyRoleMutationFn = Apollo.MutationFunction<
  UpsertProjectCompanyRoleMutation,
  UpsertProjectCompanyRoleMutationVariables
>;
export type UpsertProjectCompanyRoleMutationResult =
  Apollo.MutationResult<UpsertProjectCompanyRoleMutation>;
export type UpsertProjectCompanyRoleMutationOptions = Apollo.BaseMutationOptions<
  UpsertProjectCompanyRoleMutation,
  UpsertProjectCompanyRoleMutationVariables
>;
export const CreateProjectRoleDocument = gql`
  mutation createProjectRole($projectID: UUID!, $roleID: UUID) {
    createProjectRole(projectID: $projectID, roleID: $roleID)
  }
`;
export type CreateProjectRoleMutationFn = Apollo.MutationFunction<
  CreateProjectRoleMutation,
  CreateProjectRoleMutationVariables
>;
export type CreateProjectRoleMutationResult = Apollo.MutationResult<CreateProjectRoleMutation>;
export type CreateProjectRoleMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectRoleMutation,
  CreateProjectRoleMutationVariables
>;
export const DeleteProjectRoleDocument = gql`
  mutation deleteProjectRole(
    $projectID: UUID!
    $roleID: UUID!
    $collaboratorRoleInputs: [CollaboratorRoleInput!]!
  ) {
    deleteProjectRole(
      projectID: $projectID
      roleID: $roleID
      collaboratorRoleInputs: $collaboratorRoleInputs
    )
  }
`;
export type DeleteProjectRoleMutationFn = Apollo.MutationFunction<
  DeleteProjectRoleMutation,
  DeleteProjectRoleMutationVariables
>;
export type DeleteProjectRoleMutationResult = Apollo.MutationResult<DeleteProjectRoleMutation>;
export type DeleteProjectRoleMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectRoleMutation,
  DeleteProjectRoleMutationVariables
>;
export const LoadCollaboratorsByRoleDocument = gql`
  query loadCollaboratorsByRole($projectID: UUID!, $roleID: UUID!) {
    loadCollaboratorsByRole(projectID: $projectID, roleID: $roleID) {
      id
      user {
        id
        name
        picture
        status
      }
    }
  }
`;
export type LoadCollaboratorsByRoleQueryResult = Apollo.QueryResult<
  LoadCollaboratorsByRoleQuery,
  LoadCollaboratorsByRoleQueryVariables
>;
export const UpdatePermissionDocument = gql`
  mutation updatePermission($projectID: UUID!, $id: UUID!, $level: PermissionLevel!) {
    updatePermission(projectID: $projectID, permission: $id, level: $level) {
      id
      level
      resource
      actions {
        view
        add
        delete
        edit
      }
    }
  }
`;
export type UpdatePermissionMutationFn = Apollo.MutationFunction<
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables
>;
export type UpdatePermissionMutationResult = Apollo.MutationResult<UpdatePermissionMutation>;
export type UpdatePermissionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables
>;
export const UpdateRoleNameDocument = gql`
  mutation updateRoleName($projectID: UUID!, $roleID: UUID!, $name: String!) {
    setRoleName(projectID: $projectID, roleID: $roleID, name: $name)
  }
`;
export type UpdateRoleNameMutationFn = Apollo.MutationFunction<
  UpdateRoleNameMutation,
  UpdateRoleNameMutationVariables
>;
export type UpdateRoleNameMutationResult = Apollo.MutationResult<UpdateRoleNameMutation>;
export type UpdateRoleNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleNameMutation,
  UpdateRoleNameMutationVariables
>;
export const UpdateRoleTradeDocument = gql`
  mutation updateRoleTrade($projectID: UUID!, $roleID: UUID!, $hasTrade: Boolean!) {
    setRoleHasTrade(projectID: $projectID, roleID: $roleID, hasTrade: $hasTrade)
  }
`;
export type UpdateRoleTradeMutationFn = Apollo.MutationFunction<
  UpdateRoleTradeMutation,
  UpdateRoleTradeMutationVariables
>;
export type UpdateRoleTradeMutationResult = Apollo.MutationResult<UpdateRoleTradeMutation>;
export type UpdateRoleTradeMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleTradeMutation,
  UpdateRoleTradeMutationVariables
>;
export const TimelineDocument = gql`
  query Timeline($input: TimelineInput!) {
    timeline(input: $input) {
      activities {
        id
        name
        type
        startDate
        endDate
        children
        itemCount
        milestone {
          id
          name
          description
          itemsCount
        }
      }
    }
  }
`;
export type TimelineQueryResult = Apollo.QueryResult<TimelineQuery, TimelineQueryVariables>;
export const TimelineActivityOptionsDocument = gql`
  query TimelineActivityOptions($input: TimelineInput!) {
    timeline(input: $input) {
      activities {
        id
        name
        type
        itemCount
        milestone {
          id
          name
          description
          itemsCount
        }
      }
    }
  }
`;
export type TimelineActivityOptionsQueryResult = Apollo.QueryResult<
  TimelineActivityOptionsQuery,
  TimelineActivityOptionsQueryVariables
>;
export const CreateTimelineActivityDocument = gql`
  mutation createTimelineActivity($projectID: UUID!, $input: TimelineActivityInput!) {
    createTimelineActivity(projectID: $projectID, input: $input) {
      id
      type
      name
      startDate
      endDate
    }
  }
`;
export type CreateTimelineActivityMutationFn = Apollo.MutationFunction<
  CreateTimelineActivityMutation,
  CreateTimelineActivityMutationVariables
>;
export type CreateTimelineActivityMutationResult =
  Apollo.MutationResult<CreateTimelineActivityMutation>;
export type CreateTimelineActivityMutationOptions = Apollo.BaseMutationOptions<
  CreateTimelineActivityMutation,
  CreateTimelineActivityMutationVariables
>;
export const UpdateTimelineActivityDocument = gql`
  mutation updateTimelineActivity(
    $projectID: UUID!
    $activityID: UUID!
    $input: TimelineActivityInput!
  ) {
    updateTimelineActivity(projectID: $projectID, activityID: $activityID, input: $input) {
      id
      type
      name
      startDate
      endDate
    }
  }
`;
export type UpdateTimelineActivityMutationFn = Apollo.MutationFunction<
  UpdateTimelineActivityMutation,
  UpdateTimelineActivityMutationVariables
>;
export type UpdateTimelineActivityMutationResult =
  Apollo.MutationResult<UpdateTimelineActivityMutation>;
export type UpdateTimelineActivityMutationOptions = Apollo.BaseMutationOptions<
  UpdateTimelineActivityMutation,
  UpdateTimelineActivityMutationVariables
>;
export const DeleteTimelineActivityDocument = gql`
  mutation deleteTimelineActivity($projectID: UUID!, $activityID: UUID!) {
    deleteTimelineActivity(projectID: $projectID, activityID: $activityID)
  }
`;
export type DeleteTimelineActivityMutationFn = Apollo.MutationFunction<
  DeleteTimelineActivityMutation,
  DeleteTimelineActivityMutationVariables
>;
export type DeleteTimelineActivityMutationResult =
  Apollo.MutationResult<DeleteTimelineActivityMutation>;
export type DeleteTimelineActivityMutationOptions = Apollo.BaseMutationOptions<
  DeleteTimelineActivityMutation,
  DeleteTimelineActivityMutationVariables
>;
export const ItemsTimelineDocument = gql`
  query itemsTimeline(
    $milestoneID: UUID
    $activityID: UUID
    $projectID: UUID!
    $showOptions: Boolean!
    $costMode: CostMode!
    $loadItemCosts: Boolean
    $viewFilter: ViewFilter!
    $visibilityView: VisibilityView!
    $integrations: [IntegrationFilterType!]!
  ) {
    itemsList(
      parameters: {
        milestoneID: $milestoneID
        activityID: $activityID
        projectID: $projectID
        showOptions: $showOptions
        costMode: $costMode
        loadItemCosts: $loadItemCosts
        viewFilter: $viewFilter
        visibilityView: $visibilityView
        integrations: $integrations
      }
    ) {
      items {
        ...itemsTimelineItemFields
      }
    }
  }
  ${ItemsTimelineItemFieldsFragmentDoc}
`;
export type ItemsTimelineQueryResult = Apollo.QueryResult<
  ItemsTimelineQuery,
  ItemsTimelineQueryVariables
>;
export const VarianceReportsDocument = gql`
  query varianceReports(
    $columnSets: [VarianceColumnSet!]!
    $groupByItems: [GroupByItem!]!
    $costMode: CostMode!
    $projectID: UUID!
    $shouldIncludeOwnerCosts: Boolean
    $viewFilter: ViewFilter!
  ) {
    varianceReports(
      parameters: {
        columnSets: $columnSets
        groupByItems: $groupByItems
        costMode: $costMode
        projectID: $projectID
        shouldIncludeOwnerCosts: $shouldIncludeOwnerCosts
        viewFilter: $viewFilter
      }
    ) {
      milestoneID
      name
      date
      varianceColumns {
        ...commonCostReportColumnFields
      }
    }
  }
  ${CommonCostReportColumnFieldsFragmentDoc}
`;
export type VarianceReportsQueryResult = Apollo.QueryResult<
  VarianceReportsQuery,
  VarianceReportsQueryVariables
>;
export const LoadVarianceReportCommentsDocument = gql`
  query loadVarianceReportComments(
    $reportID: UUID!
    $userReportCommentViewParameters: UserReportCommentViewParameters!
    $costMode: CostMode
  ) {
    varianceReportComments(
      reportID: $reportID
      userReportCommentViewParameters: $userReportCommentViewParameters
      costMode: $costMode
    ) {
      itemComments {
        ...commonUserReportCommentFields
      }
      itemLineComments {
        ...commonUserReportCommentFields
      }
      subtotalComments {
        ...commonUserReportCommentFields
      }
      markupComments {
        ...commonUserReportCommentFields
      }
    }
  }
  ${CommonUserReportCommentFieldsFragmentDoc}
`;
export type LoadVarianceReportCommentsQueryResult = Apollo.QueryResult<
  LoadVarianceReportCommentsQuery,
  LoadVarianceReportCommentsQueryVariables
>;
export const CreateUserReportCommentDocument = gql`
  mutation createUserReportComment(
    $projectID: UUID!
    $userReportID: UUID!
    $reportCommentInput: UserReportCommentInput!
    $costMode: CostMode
  ) {
    createUserReportComment(
      projectID: $projectID
      userReportID: $userReportID
      reportCommentInput: $reportCommentInput
      costMode: $costMode
    ) {
      ...commonUserReportCommentFields
    }
  }
  ${CommonUserReportCommentFieldsFragmentDoc}
`;
export type CreateUserReportCommentMutationFn = Apollo.MutationFunction<
  CreateUserReportCommentMutation,
  CreateUserReportCommentMutationVariables
>;
export type CreateUserReportCommentMutationResult =
  Apollo.MutationResult<CreateUserReportCommentMutation>;
export type CreateUserReportCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateUserReportCommentMutation,
  CreateUserReportCommentMutationVariables
>;
export const UpdateUserReportCommentDocument = gql`
  mutation updateUserReportComment(
    $projectID: UUID!
    $commentID: UUID!
    $text: MentionableTextInput!
    $costs: [USCents!]!
    $costMode: CostMode
  ) {
    updateUserReportComment(
      projectID: $projectID
      commentID: $commentID
      text: $text
      costs: $costs
      costMode: $costMode
    ) {
      ...commonUserReportCommentFields
    }
  }
  ${CommonUserReportCommentFieldsFragmentDoc}
`;
export type UpdateUserReportCommentMutationFn = Apollo.MutationFunction<
  UpdateUserReportCommentMutation,
  UpdateUserReportCommentMutationVariables
>;
export type UpdateUserReportCommentMutationResult =
  Apollo.MutationResult<UpdateUserReportCommentMutation>;
export type UpdateUserReportCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserReportCommentMutation,
  UpdateUserReportCommentMutationVariables
>;
export const DeleteUserReportCommentDocument = gql`
  mutation deleteUserReportComment($projectID: UUID!, $commentID: UUID!) {
    deleteUserReportComment(projectID: $projectID, commentID: $commentID)
  }
`;
export type DeleteUserReportCommentMutationFn = Apollo.MutationFunction<
  DeleteUserReportCommentMutation,
  DeleteUserReportCommentMutationVariables
>;
export type DeleteUserReportCommentMutationResult =
  Apollo.MutationResult<DeleteUserReportCommentMutation>;
export type DeleteUserReportCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserReportCommentMutation,
  DeleteUserReportCommentMutationVariables
>;
export const UserReportNotesPerMilestoneIdDocument = gql`
  query userReportNotesPerMilestoneID($projectID: UUID!, $reportID: UUID!) {
    userReportNotesPerMilestoneID(projectID: $projectID, reportID: $reportID) {
      milestoneID
      noteCount
    }
  }
`;
export type UserReportNotesPerMilestoneIdQueryResult = Apollo.QueryResult<
  UserReportNotesPerMilestoneIdQuery,
  UserReportNotesPerMilestoneIdQueryVariables
>;
export const ItemBookmarksDocument = gql`
  query itemBookmarks($itemID: UUID!) {
    itemBookmarks(itemID: $itemID) {
      id
      name
      thumbnail
      payload
    }
  }
`;
export type ItemBookmarksQueryResult = Apollo.QueryResult<
  ItemBookmarksQuery,
  ItemBookmarksQueryVariables
>;
export const RemoveBookmarkFromItemDocument = gql`
  mutation removeBookmarkFromItem($projectID: UUID!, $item: UUID!, $bookmark: UUID!) {
    removeBookmarkFromItem(projectID: $projectID, item: $item, bookmark: $bookmark) {
      id
      name
      kind
      payload
      thumbnail
    }
  }
`;
export type RemoveBookmarkFromItemMutationFn = Apollo.MutationFunction<
  RemoveBookmarkFromItemMutation,
  RemoveBookmarkFromItemMutationVariables
>;
export type RemoveBookmarkFromItemMutationResult =
  Apollo.MutationResult<RemoveBookmarkFromItemMutation>;
export type RemoveBookmarkFromItemMutationOptions = Apollo.BaseMutationOptions<
  RemoveBookmarkFromItemMutation,
  RemoveBookmarkFromItemMutationVariables
>;
export const CreateOrUpdateBookmarkDocument = gql`
  mutation createOrUpdateBookmark($projectID: UUID!, $bookmark: BookmarkInput!) {
    createOrUpdateBookmark(projectID: $projectID, bookmark: $bookmark) {
      id
      kind
      name
      payload
      thumbnail
    }
  }
`;
export type CreateOrUpdateBookmarkMutationFn = Apollo.MutationFunction<
  CreateOrUpdateBookmarkMutation,
  CreateOrUpdateBookmarkMutationVariables
>;
export type CreateOrUpdateBookmarkMutationResult =
  Apollo.MutationResult<CreateOrUpdateBookmarkMutation>;
export type CreateOrUpdateBookmarkMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateBookmarkMutation,
  CreateOrUpdateBookmarkMutationVariables
>;
export const ItemAttachmentsDocument = gql`
  query itemAttachments($id: UUID!) {
    itemAttachments(itemID: $id) {
      ...attachmentFieldsQuery
    }
  }
  ${AttachmentFieldsQueryFragmentDoc}
`;
export type ItemAttachmentsQueryResult = Apollo.QueryResult<
  ItemAttachmentsQuery,
  ItemAttachmentsQueryVariables
>;
export const ProjectAssetsDocument = gql`
  query projectAssets($id: UUID!, $sortBy: FilesSortBy) {
    projectAssets(projectID: $id, sortBy: $sortBy) {
      ...assetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;
export type ProjectAssetsQueryResult = Apollo.QueryResult<
  ProjectAssetsQuery,
  ProjectAssetsQueryVariables
>;
export const MilestoneAttachmentsDocument = gql`
  query milestoneAttachments($id: UUID!) {
    milestoneAttachments(milestoneID: $id) {
      ...attachmentFieldsQuery
    }
  }
  ${AttachmentFieldsQueryFragmentDoc}
`;
export type MilestoneAttachmentsQueryResult = Apollo.QueryResult<
  MilestoneAttachmentsQuery,
  MilestoneAttachmentsQueryVariables
>;
export const AssetDocument = gql`
  query asset($assetID: UUID!) {
    asset(assetID: $assetID) {
      ...assetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;
export type AssetQueryResult = Apollo.QueryResult<AssetQuery, AssetQueryVariables>;
export const AssetsDocument = gql`
  query assets($assetIDs: [UUID!]!) {
    assets(assetIDs: $assetIDs) {
      ...assetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;
export type AssetsQueryResult = Apollo.QueryResult<AssetsQuery, AssetsQueryVariables>;
export const CompanyAssetDocument = gql`
  query companyAsset($assetID: UUID!) {
    companyAsset(assetID: $assetID) {
      id
      location
      name
      size
      lastUpdated
    }
  }
`;
export type CompanyAssetQueryResult = Apollo.QueryResult<
  CompanyAssetQuery,
  CompanyAssetQueryVariables
>;
export const AttachToItemDocument = gql`
  mutation attachToItem(
    $itemID: UUID!
    $assetInput: AssetInput
    $remoteAssetInput: RemoteAssetInput
  ) {
    attachToItem(itemID: $itemID, assetInput: $assetInput, remoteAssetInput: $remoteAssetInput) {
      ...attachmentFields
    }
  }
  ${AttachmentFieldsFragmentDoc}
`;
export type AttachToItemMutationFn = Apollo.MutationFunction<
  AttachToItemMutation,
  AttachToItemMutationVariables
>;
export type AttachToItemMutationResult = Apollo.MutationResult<AttachToItemMutation>;
export type AttachToItemMutationOptions = Apollo.BaseMutationOptions<
  AttachToItemMutation,
  AttachToItemMutationVariables
>;
export const AttachToMilestoneDocument = gql`
  mutation attachToMilestone(
    $milestoneID: UUID!
    $assetInput: AssetInput
    $remoteAssetInput: RemoteAssetInput
  ) {
    attachToMilestone(
      milestoneID: $milestoneID
      assetInput: $assetInput
      remoteAssetInput: $remoteAssetInput
    ) {
      ...attachmentFields
    }
  }
  ${AttachmentFieldsFragmentDoc}
`;
export type AttachToMilestoneMutationFn = Apollo.MutationFunction<
  AttachToMilestoneMutation,
  AttachToMilestoneMutationVariables
>;
export type AttachToMilestoneMutationResult = Apollo.MutationResult<AttachToMilestoneMutation>;
export type AttachToMilestoneMutationOptions = Apollo.BaseMutationOptions<
  AttachToMilestoneMutation,
  AttachToMilestoneMutationVariables
>;
export const DetachFromItemDocument = gql`
  mutation detachFromItem($projectID: UUID!, $itemID: UUID!, $assetID: UUID, $remoteAssetID: UUID) {
    detachFromItem(
      projectID: $projectID
      itemID: $itemID
      assetID: $assetID
      remoteAssetID: $remoteAssetID
    ) {
      ...attachmentFields
    }
  }
  ${AttachmentFieldsFragmentDoc}
`;
export type DetachFromItemMutationFn = Apollo.MutationFunction<
  DetachFromItemMutation,
  DetachFromItemMutationVariables
>;
export type DetachFromItemMutationResult = Apollo.MutationResult<DetachFromItemMutation>;
export type DetachFromItemMutationOptions = Apollo.BaseMutationOptions<
  DetachFromItemMutation,
  DetachFromItemMutationVariables
>;
export const DetachFromMilestoneDocument = gql`
  mutation detachFromMilestone(
    $projectID: UUID!
    $milestoneID: UUID!
    $assetID: UUID
    $remoteAssetID: UUID
  ) {
    detachFromMilestone(
      projectID: $projectID
      milestoneID: $milestoneID
      assetID: $assetID
      remoteAssetID: $remoteAssetID
    ) {
      ...attachmentFields
    }
  }
  ${AttachmentFieldsFragmentDoc}
`;
export type DetachFromMilestoneMutationFn = Apollo.MutationFunction<
  DetachFromMilestoneMutation,
  DetachFromMilestoneMutationVariables
>;
export type DetachFromMilestoneMutationResult = Apollo.MutationResult<DetachFromMilestoneMutation>;
export type DetachFromMilestoneMutationOptions = Apollo.BaseMutationOptions<
  DetachFromMilestoneMutation,
  DetachFromMilestoneMutationVariables
>;
export const AssetThumbnailDocument = gql`
  query assetThumbnail($assetID: UUID!, $dimension: ImageDimension) {
    asset(assetID: $assetID, dimension: $dimension) {
      id
      location
      name
      thumbnailURL
    }
  }
`;
export type AssetThumbnailQueryResult = Apollo.QueryResult<
  AssetThumbnailQuery,
  AssetThumbnailQueryVariables
>;
export const CompanyUserDocument = gql`
  query companyUser {
    companyUser {
      ...commonCompanyUserFields
    }
  }
  ${CommonCompanyUserFieldsFragmentDoc}
`;
export type CompanyUserQueryResult = Apollo.QueryResult<
  CompanyUserQuery,
  CompanyUserQueryVariables
>;
export const CurrentCollaboratorDocument = gql`
  query currentCollaborator($projectID: UUID!) {
    currentCollaborator(projectID: $projectID) {
      ...commonCollaboratorFields
    }
  }
  ${CommonCollaboratorFieldsFragmentDoc}
`;
export type CurrentCollaboratorQueryResult = Apollo.QueryResult<
  CurrentCollaboratorQuery,
  CurrentCollaboratorQueryVariables
>;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...commonUserFields
      status
    }
  }
  ${CommonUserFieldsFragmentDoc}
`;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const FeatureFlagDocument = gql`
  query featureFlag($projectID: UUID!, $userEmail: String!) {
    loadFeatureFlags(projectID: $projectID, userEmail: $userEmail)
  }
`;
export type FeatureFlagQueryResult = Apollo.QueryResult<
  FeatureFlagQuery,
  FeatureFlagQueryVariables
>;
export const CostTrendlineEventsDocument = gql`
  query costTrendlineEvents(
    $projectID: UUID!
    $milestoneID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
    $columnInputs: [CostReportColumnInput!]!
    $quantityMagnitude: Numeric
  ) {
    costTrendlineEvents(
      projectID: $projectID
      milestoneID: $milestoneID
      costMode: $costMode
      viewFilter: $viewFilter
      columnInputs: $columnInputs
      quantityMagnitude: $quantityMagnitude
    ) {
      estimate
      target
      runningTotal
      events {
        timestamp
        eventType
        item {
          ...eventDataItemFields
        }
        eventContent {
          oldCost {
            ...costTypeFields
          }
          newCost {
            ...costTypeFields
          }
          option {
            ...itemEventSummaryFields
          }
          options {
            ...itemEventSummaryFields
          }
          oldMilestone
          newMilestone
          oldOptionStatus
          newOptionStatus
          oldStatus
          newStatus
          parent {
            ...itemEventSummaryFields
          }
        }
        visible
        cost
        y0
        y
        yPoint
        ids
      }
    }
  }
  ${EventDataItemFieldsFragmentDoc}
  ${CostTypeFieldsFragmentDoc}
  ${ItemEventSummaryFieldsFragmentDoc}
`;
export type CostTrendlineEventsQueryResult = Apollo.QueryResult<
  CostTrendlineEventsQuery,
  CostTrendlineEventsQueryVariables
>;
export const MilestonesCostTrendsDataDocument = gql`
  query milestonesCostTrendsData(
    $projectID: UUID!
    $columns: [CostReportColumnInput!]!
    $intervalType: IntervalType!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
    $summary: Boolean!
  ) {
    milestonesCostTrends(
      parameters: {
        projectID: $projectID
        columns: $columns
        intervalType: $intervalType
        costMode: $costMode
        viewFilter: $viewFilter
        summary: $summary
      }
    ) {
      milestone {
        id
        name
        description
        date
        quantities {
          ...quantityFields
        }
      }
      points {
        dateStart
        dateEnd
        values {
          type
          y
          isChanged
        }
        isVisible
      }
    }
  }
  ${QuantityFieldsFragmentDoc}
`;
export type MilestonesCostTrendsDataQueryResult = Apollo.QueryResult<
  MilestonesCostTrendsDataQuery,
  MilestonesCostTrendsDataQueryVariables
>;
export const ListCategoriesByIDsDocument = gql`
  query listCategoriesByIDs($categorizationID: UUID!, $categoryIDs: [UUID!]!) {
    categories(categorizationID: $categorizationID, categoryIDs: $categoryIDs) {
      ...commonCategoryUniqueFields
    }
  }
  ${CommonCategoryUniqueFieldsFragmentDoc}
`;
export type ListCategoriesByIDsQueryResult = Apollo.QueryResult<
  ListCategoriesByIDsQuery,
  ListCategoriesByIDsQueryVariables
>;
export const AcceptTermsOfServiceDocument = gql`
  mutation acceptTermsOfService {
    acceptTermsOfService
  }
`;
export type AcceptTermsOfServiceMutationFn = Apollo.MutationFunction<
  AcceptTermsOfServiceMutation,
  AcceptTermsOfServiceMutationVariables
>;
export type AcceptTermsOfServiceMutationResult =
  Apollo.MutationResult<AcceptTermsOfServiceMutation>;
export type AcceptTermsOfServiceMutationOptions = Apollo.BaseMutationOptions<
  AcceptTermsOfServiceMutation,
  AcceptTermsOfServiceMutationVariables
>;
export const AcceptedTermsOfServiceDocument = gql`
  query acceptedTermsOfService {
    acceptedTermsOfService {
      accepted
      dateEffective
    }
  }
`;
export type AcceptedTermsOfServiceQueryResult = Apollo.QueryResult<
  AcceptedTermsOfServiceQuery,
  AcceptedTermsOfServiceQueryVariables
>;
export const CreateCompanyDocument = gql`
  mutation createCompany($name: String!, $type: String!, $assetID: UUID) {
    createCompany(name: $name, type: $type, assetID: $assetID) {
      ...commonCompanyFields
    }
  }
  ${CommonCompanyFieldsFragmentDoc}
`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export const CreateCompanyUserDocument = gql`
  mutation createCompanyUser(
    $companyID: UUID!
    $isRejected: Boolean!
    $reasonForRejection: String
  ) {
    createCompanyUser(
      companyID: $companyID
      isRejected: $isRejected
      reasonForRejection: $reasonForRejection
    ) {
      ...commonCompanyUserFields
    }
  }
  ${CommonCompanyUserFieldsFragmentDoc}
`;
export type CreateCompanyUserMutationFn = Apollo.MutationFunction<
  CreateCompanyUserMutation,
  CreateCompanyUserMutationVariables
>;
export type CreateCompanyUserMutationResult = Apollo.MutationResult<CreateCompanyUserMutation>;
export type CreateCompanyUserMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyUserMutation,
  CreateCompanyUserMutationVariables
>;
export const CompanyDocument = gql`
  query company($companyID: UUID!) {
    company(companyID: $companyID) {
      ...commonCompanyFields
    }
  }
  ${CommonCompanyFieldsFragmentDoc}
`;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const SendEmailVerificationDocument = gql`
  mutation sendEmailVerification($url: String!) {
    sendEmailVerification(url: $url)
  }
`;
export type SendEmailVerificationMutationFn = Apollo.MutationFunction<
  SendEmailVerificationMutation,
  SendEmailVerificationMutationVariables
>;
export type SendEmailVerificationMutationResult =
  Apollo.MutationResult<SendEmailVerificationMutation>;
export type SendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
  SendEmailVerificationMutation,
  SendEmailVerificationMutationVariables
>;
export const VerifyEmailDocument = gql`
  mutation verifyEmail($emailVerify: String!) {
    verifyEmail(emailVerify: $emailVerify)
  }
`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
export const UserDidSetupDocument = gql`
  mutation userDidSetup {
    userDidSetup {
      id
      didSetup
    }
  }
`;
export type UserDidSetupMutationFn = Apollo.MutationFunction<
  UserDidSetupMutation,
  UserDidSetupMutationVariables
>;
export type UserDidSetupMutationResult = Apollo.MutationResult<UserDidSetupMutation>;
export type UserDidSetupMutationOptions = Apollo.BaseMutationOptions<
  UserDidSetupMutation,
  UserDidSetupMutationVariables
>;
export const HubspotTokenDocument = gql`
  query hubspotToken {
    hubspotToken
  }
`;
export type HubspotTokenQueryResult = Apollo.QueryResult<
  HubspotTokenQuery,
  HubspotTokenQueryVariables
>;
export const GetCategorizationMetadataDocument = gql`
  query getCategorizationMetadata($id: UUID!) {
    categorization(id: $id) {
      ...commonCategorizationFields
      createdFrom {
        id
      }
    }
  }
  ${CommonCategorizationFieldsFragmentDoc}
`;
export type GetCategorizationMetadataQueryResult = Apollo.QueryResult<
  GetCategorizationMetadataQuery,
  GetCategorizationMetadataQueryVariables
>;
export const PreviewCategoriesDocument = gql`
  query previewCategories($assetID: UUID!) {
    previewImportedCategories(assetID: $assetID) {
      categoryMap
      metadata {
        name
        description
        columnLevel1
        columnLevel2
        columnLevel3
        columnLevel4
      }
      errors
    }
  }
`;
export type PreviewCategoriesQueryResult = Apollo.QueryResult<
  PreviewCategoriesQuery,
  PreviewCategoriesQueryVariables
>;
export const CreateCategoriesFromImportDocument = gql`
  mutation createCategoriesFromImport(
    $categorizationID: UUID!
    $assetID: UUID!
    $categories: [CategoryMapInput!]!
    $metadata: CategorizationImportMetadataInput!
    $projectID: UUID
  ) {
    createCategoriesFromImport(
      categorizationID: $categorizationID
      assetID: $assetID
      categories: $categories
      metadata: $metadata
      projectID: $projectID
    ) {
      categories {
        id
        number
        name
        level
      }
      error
    }
  }
`;
export type CreateCategoriesFromImportMutationFn = Apollo.MutationFunction<
  CreateCategoriesFromImportMutation,
  CreateCategoriesFromImportMutationVariables
>;
export type CreateCategoriesFromImportMutationResult =
  Apollo.MutationResult<CreateCategoriesFromImportMutation>;
export type CreateCategoriesFromImportMutationOptions = Apollo.BaseMutationOptions<
  CreateCategoriesFromImportMutation,
  CreateCategoriesFromImportMutationVariables
>;
export const ReplaceCategoriesDocument = gql`
  mutation ReplaceCategories(
    $categorizationID: UUID!
    $categories: [CategoryMapInput!]!
    $metadata: CategorizationImportMetadataInput
    $projectID: UUID
    $assetID: UUID
  ) {
    replaceCategories(
      categorizationID: $categorizationID
      categories: $categories
      metadata: $metadata
      projectID: $projectID
      assetID: $assetID
    ) {
      categorization {
        id
        levels
      }
      updated {
        id
      }
      deleted
    }
  }
`;
export type ReplaceCategoriesMutationFn = Apollo.MutationFunction<
  ReplaceCategoriesMutation,
  ReplaceCategoriesMutationVariables
>;
export type ReplaceCategoriesMutationResult = Apollo.MutationResult<ReplaceCategoriesMutation>;
export type ReplaceCategoriesMutationOptions = Apollo.BaseMutationOptions<
  ReplaceCategoriesMutation,
  ReplaceCategoriesMutationVariables
>;
export const UpsertCategorizationImportMetadataDocument = gql`
  mutation upsertCategorizationImportMetadata(
    $categorizationID: UUID!
    $metadata: CategorizationImportMetadataInput!
  ) {
    upsertCategorizationImportMetadata(categorizationID: $categorizationID, metadata: $metadata) {
      name
      description
      columnLevel1
      columnLevel2
      columnLevel3
      columnLevel4
    }
  }
`;
export type UpsertCategorizationImportMetadataMutationFn = Apollo.MutationFunction<
  UpsertCategorizationImportMetadataMutation,
  UpsertCategorizationImportMetadataMutationVariables
>;
export type UpsertCategorizationImportMetadataMutationResult =
  Apollo.MutationResult<UpsertCategorizationImportMetadataMutation>;
export type UpsertCategorizationImportMetadataMutationOptions = Apollo.BaseMutationOptions<
  UpsertCategorizationImportMetadataMutation,
  UpsertCategorizationImportMetadataMutationVariables
>;
export const CreateCategorizationDocument = gql`
  mutation createCategorization($projectID: UUID!, $name: String!, $description: String) {
    createCategorization(projectID: $projectID, name: $name, description: $description) {
      ... on Categorization {
        ...commonCategorizationFields
      }
      ... on CreateCategorizationErrors {
        userErrors
      }
    }
  }
  ${CommonCategorizationFieldsFragmentDoc}
`;
export type CreateCategorizationMutationFn = Apollo.MutationFunction<
  CreateCategorizationMutation,
  CreateCategorizationMutationVariables
>;
export type CreateCategorizationMutationResult =
  Apollo.MutationResult<CreateCategorizationMutation>;
export type CreateCategorizationMutationOptions = Apollo.BaseMutationOptions<
  CreateCategorizationMutation,
  CreateCategorizationMutationVariables
>;
export const RemoveCategorizationsDocument = gql`
  mutation removeCategorizations($projectID: UUID!, $categorizationIDs: [UUID!]!) {
    removeCategorizations(projectID: $projectID, categorizationIDs: $categorizationIDs) {
      removed
    }
  }
`;
export type RemoveCategorizationsMutationFn = Apollo.MutationFunction<
  RemoveCategorizationsMutation,
  RemoveCategorizationsMutationVariables
>;
export type RemoveCategorizationsMutationResult =
  Apollo.MutationResult<RemoveCategorizationsMutation>;
export type RemoveCategorizationsMutationOptions = Apollo.BaseMutationOptions<
  RemoveCategorizationsMutation,
  RemoveCategorizationsMutationVariables
>;
export const UpdateCategorizationDocument = gql`
  mutation updateCategorization($id: UUID!, $name: String, $description: String, $projectID: UUID) {
    editCategorization(id: $id, name: $name, description: $description, projectID: $projectID) {
      ... on Categorization {
        id
        name
        description
        modifiedBy {
          id
        }
        modifiedAt
      }
    }
  }
`;
export type UpdateCategorizationMutationFn = Apollo.MutationFunction<
  UpdateCategorizationMutation,
  UpdateCategorizationMutationVariables
>;
export type UpdateCategorizationMutationResult =
  Apollo.MutationResult<UpdateCategorizationMutation>;
export type UpdateCategorizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateCategorizationMutation,
  UpdateCategorizationMutationVariables
>;
export const ReportItemAssistExperimentRequestDocument = gql`
  mutation reportItemAssistExperimentRequest(
    $projectID: UUID!
    $itemID: UUID!
    $additionalInfo: String!
    $connectWithBPM: Boolean!
  ) {
    reportItemAssistExperimentRequest(
      projectID: $projectID
      itemID: $itemID
      additionalInfo: $additionalInfo
      connectWithBPM: $connectWithBPM
    )
  }
`;
export type ReportItemAssistExperimentRequestMutationFn = Apollo.MutationFunction<
  ReportItemAssistExperimentRequestMutation,
  ReportItemAssistExperimentRequestMutationVariables
>;
export type ReportItemAssistExperimentRequestMutationResult =
  Apollo.MutationResult<ReportItemAssistExperimentRequestMutation>;
export type ReportItemAssistExperimentRequestMutationOptions = Apollo.BaseMutationOptions<
  ReportItemAssistExperimentRequestMutation,
  ReportItemAssistExperimentRequestMutationVariables
>;
export const DeprecateItemDocument = gql`
  mutation deprecateItem($projectID: UUID!, $itemID: UUID!) {
    deprecateItem(projectID: $projectID, itemID: $itemID)
  }
`;
export type DeprecateItemMutationFn = Apollo.MutationFunction<
  DeprecateItemMutation,
  DeprecateItemMutationVariables
>;
export type DeprecateItemMutationResult = Apollo.MutationResult<DeprecateItemMutation>;
export type DeprecateItemMutationOptions = Apollo.BaseMutationOptions<
  DeprecateItemMutation,
  DeprecateItemMutationVariables
>;
export const AnalyticsPropertiesDocument = gql`
  query analyticsProperties($projectId: UUID, $previewRoleId: UUID, $previewUserId: UUID) {
    analyticsProperties(
      projectID: $projectId
      previewRoleID: $previewRoleId
      previewUserID: $previewUserId
    ) {
      userProperties {
        userEmail
        userId
        userIsEmailVerified
        userJobTitle
        userName
        userSignUpAt
      }
      companyProperties {
        companyId
        companyName
        companyType
        companyDomain
        isIndependent
      }
      projectProperties {
        projectId
        projectLocation
        projectName
        projectStatus
      }
      permissionsProperties {
        previewMode
        previewRole
        previewRoleId
        previewUserEmail
        previewUserId
        userRole
      }
      groupProperties {
        groupId
        userId
        projectId
        companyId
      }
    }
  }
`;
export type AnalyticsPropertiesQueryResult = Apollo.QueryResult<
  AnalyticsPropertiesQuery,
  AnalyticsPropertiesQueryVariables
>;
export const CurrentCompanyRoleDocument = gql`
  query currentCompanyRole {
    currentCompanyRole {
      ...commonCompanyRoleFields
    }
  }
  ${CommonCompanyRoleFieldsFragmentDoc}
`;
export type CurrentCompanyRoleQueryResult = Apollo.QueryResult<
  CurrentCompanyRoleQuery,
  CurrentCompanyRoleQueryVariables
>;
export const ListProjectCategorizationsDocument = gql`
  query listProjectCategorizations($projectID: UUID!, $includeDisabled: Boolean) {
    projectCategorizations(projectID: $projectID, includeDisabled: $includeDisabled) {
      projectID
      categorization {
        ...commonCategorizationFields
        createdFrom {
          id
        }
        importMetadata {
          columnLevel1
          columnLevel2
          columnLevel3
          columnLevel4
        }
      }
      ordering
      disabled
    }
  }
  ${CommonCategorizationFieldsFragmentDoc}
`;
export type ListProjectCategorizationsQueryResult = Apollo.QueryResult<
  ListProjectCategorizationsQuery,
  ListProjectCategorizationsQueryVariables
>;
export const ProjectCategorizationsMetadataDocument = gql`
  query ProjectCategorizationsMetadata($projectID: UUID!, $includeDisabled: Boolean) {
    projectCategorizations(projectID: $projectID, includeDisabled: $includeDisabled) {
      projectID
      categorization {
        ...commonCategorizationFields
        createdFrom {
          id
        }
        importMetadata {
          columnLevel1
          columnLevel2
          columnLevel3
          columnLevel4
        }
        exampleCategories {
          id
          number
          name
          level
        }
      }
      ordering
      disabled
    }
  }
  ${CommonCategorizationFieldsFragmentDoc}
`;
export type ProjectCategorizationsMetadataQueryResult = Apollo.QueryResult<
  ProjectCategorizationsMetadataQuery,
  ProjectCategorizationsMetadataQueryVariables
>;
export const StandardCategorizationsDocument = gql`
  query StandardCategorizations($projectID: UUID!) {
    standardCategorizations(projectID: $projectID) {
      projectID
      categorization {
        ...commonCategorizationFields
        builtin
        createdFrom {
          id
          deprecated
        }
      }
      ordering
      disabled
    }
  }
  ${CommonCategorizationFieldsFragmentDoc}
`;
export type StandardCategorizationsQueryResult = Apollo.QueryResult<
  StandardCategorizationsQuery,
  StandardCategorizationsQueryVariables
>;
export const CategoryCombinationsDocument = gql`
  query categoryCombinations($searches: [CategorySearch!]!, $estimateID: UUID) {
    categoryCombinations(searches: $searches, estimateID: $estimateID) {
      categories {
        ...fullCategoryCategorizationFields
      }
    }
  }
  ${FullCategoryCategorizationFieldsFragmentDoc}
`;
export type CategoryCombinationsQueryResult = Apollo.QueryResult<
  CategoryCombinationsQuery,
  CategoryCombinationsQueryVariables
>;
export const CategoryCombinationCountsDocument = gql`
  query categoryCombinationCounts($searches: [CategorySearch!]!) {
    categoryCombinationCounts(searches: $searches) {
      categorizationID
      count
    }
  }
`;
export type CategoryCombinationCountsQueryResult = Apollo.QueryResult<
  CategoryCombinationCountsQuery,
  CategoryCombinationCountsQueryVariables
>;
export const GetProjectPropsDocument = gql`
  query getProjectProps($id: UUID!) {
    project(id: $id) {
      ...commonProjectPropsFields
    }
  }
  ${CommonProjectPropsFieldsFragmentDoc}
`;
export type GetProjectPropsQueryResult = Apollo.QueryResult<
  GetProjectPropsQuery,
  GetProjectPropsQueryVariables
>;
export const CompanyProjectTypesDocument = gql`
  query companyProjectTypes($companyID: UUID!, $includeHidden: Boolean!) {
    companyProjectTypes(companyID: $companyID, includeHidden: $includeHidden) {
      id
      name
      parentID
      hidden
    }
  }
`;
export type CompanyProjectTypesQueryResult = Apollo.QueryResult<
  CompanyProjectTypesQuery,
  CompanyProjectTypesQueryVariables
>;
export const GetProjectsWithTypeDocument = gql`
  query getProjectsWithType($typeID: UUID!) {
    getProjectsWithType(typeID: $typeID) {
      name
      projectID
      typeID
      variant
    }
  }
`;
export type GetProjectsWithTypeQueryResult = Apollo.QueryResult<
  GetProjectsWithTypeQuery,
  GetProjectsWithTypeQueryVariables
>;
export const GetProjectTypesDocument = gql`
  query getProjectTypes($projectID: UUID!) {
    getProjectTypes(projectID: $projectID) {
      id
      name
      parentID
      hidden
    }
  }
`;
export type GetProjectTypesQueryResult = Apollo.QueryResult<
  GetProjectTypesQuery,
  GetProjectTypesQueryVariables
>;
export const ProjectDeliveryTypesDocument = gql`
  query projectDeliveryTypes {
    projectDeliveryTypes {
      id
      name
      abbreviation
    }
  }
`;
export type ProjectDeliveryTypesQueryResult = Apollo.QueryResult<
  ProjectDeliveryTypesQuery,
  ProjectDeliveryTypesQueryVariables
>;
export const MilestoneCostReportsDocument = gql`
  query milestoneCostReports(
    $milestones: [CostReportMilestoneInput!]!
    $columns: [CostReportColumnInput!]!
    $groupByItems: [GroupByItem!]!
    $costMode: CostMode!
    $projectID: UUID!
    $viewFilter: ViewFilter!
  ) {
    milestoneCostReports(
      parameters: {
        milestones: $milestones
        columns: $columns
        groupByItems: $groupByItems
        costMode: $costMode
        projectID: $projectID
        viewFilter: $viewFilter
        useDraftEstimate: false
        useDraftBudget: false
      }
    ) {
      milestoneID
      costReportColumns {
        ...commonCostReportColumnFields
      }
    }
  }
  ${CommonCostReportColumnFieldsFragmentDoc}
`;
export type MilestoneCostReportsQueryResult = Apollo.QueryResult<
  MilestoneCostReportsQuery,
  MilestoneCostReportsQueryVariables
>;
export const CreateProjectDocument = gql`
  mutation createProject(
    $name: String!
    $code: String
    $milestone: String!
    $milestoneDate: Time!
    $milestoneDesignPhaseID: UUID!
    $typeID: UUID
    $location: String!
    $lat: Float
    $lon: Float
    $statusID: UUID!
    $currency: String
    $templateID: UUID
    $projectDeliveryTypeID: UUID
    $locationDetails: LocationDetailsInput!
    $orgNodeIDs: [UUID!]
  ) {
    createProject(
      name: $name
      code: $code
      milestone: $milestone
      milestoneDate: $milestoneDate
      milestoneDesignPhaseID: $milestoneDesignPhaseID
      typeID: $typeID
      location: $location
      lat: $lat
      lon: $lon
      statusID: $statusID
      currency: $currency
      templateID: $templateID
      projectDeliveryTypeID: $projectDeliveryTypeID
      locationDetails: $locationDetails
      orgNodeIDs: $orgNodeIDs
    ) {
      ...commonProjectFields
    }
  }
  ${CommonProjectFieldsFragmentDoc}
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const ListSubcategoriesDocument = gql`
  query listSubcategories($search: SubcategorySearch!) {
    subcategory(search: $search) {
      ...commonCategoryUniqueFields
    }
  }
  ${CommonCategoryUniqueFieldsFragmentDoc}
`;
export type ListSubcategoriesQueryResult = Apollo.QueryResult<
  ListSubcategoriesQuery,
  ListSubcategoriesQueryVariables
>;
export const LoadUserDocument = gql`
  query loadUser($userEmail: String!) {
    user(email: $userEmail) {
      ...commonUserFields
      status
    }
  }
  ${CommonUserFieldsFragmentDoc}
`;
export type LoadUserQueryResult = Apollo.QueryResult<LoadUserQuery, LoadUserQueryVariables>;
export const LoadUserNotificationSettingDocument = gql`
  query loadUserNotificationSetting($projectID: UUID!) {
    userNotificationSetting(projectID: $projectID) {
      ...commonUserNotificationSettingFields
    }
  }
  ${CommonUserNotificationSettingFieldsFragmentDoc}
`;
export type LoadUserNotificationSettingQueryResult = Apollo.QueryResult<
  LoadUserNotificationSettingQuery,
  LoadUserNotificationSettingQueryVariables
>;
export const LoadUserInAppNotificationSettingDocument = gql`
  query loadUserInAppNotificationSetting($projectID: UUID!) {
    userInAppNotificationSetting(projectID: $projectID) {
      ...commonUserNotificationSettingFields
    }
  }
  ${CommonUserNotificationSettingFieldsFragmentDoc}
`;
export type LoadUserInAppNotificationSettingQueryResult = Apollo.QueryResult<
  LoadUserInAppNotificationSettingQuery,
  LoadUserInAppNotificationSettingQueryVariables
>;
export const ToggleEventDataVisibilityDocument = gql`
  mutation toggleEventDataVisibility($projectID: UUID!, $visible: Boolean!, $events: [UUID!]!) {
    toggleEventDataVisibility(projectID: $projectID, visible: $visible, events: $events) {
      visible
      events
    }
  }
`;
export type ToggleEventDataVisibilityMutationFn = Apollo.MutationFunction<
  ToggleEventDataVisibilityMutation,
  ToggleEventDataVisibilityMutationVariables
>;
export type ToggleEventDataVisibilityMutationResult =
  Apollo.MutationResult<ToggleEventDataVisibilityMutation>;
export type ToggleEventDataVisibilityMutationOptions = Apollo.BaseMutationOptions<
  ToggleEventDataVisibilityMutation,
  ToggleEventDataVisibilityMutationVariables
>;
export const LoadProjectRolesDocument = gql`
  query loadProjectRoles($projectId: UUID!) {
    projectRoles(project: $projectId) {
      ...commonRoleFields
    }
  }
  ${CommonRoleFieldsFragmentDoc}
`;
export type LoadProjectRolesQueryResult = Apollo.QueryResult<
  LoadProjectRolesQuery,
  LoadProjectRolesQueryVariables
>;
export const LoadProjectRoleDocument = gql`
  query loadProjectRole($projectID: UUID!, $roleID: UUID!) {
    projectRole(projectID: $projectID, roleID: $roleID) {
      ...commonRoleFields
    }
  }
  ${CommonRoleFieldsFragmentDoc}
`;
export type LoadProjectRoleQueryResult = Apollo.QueryResult<
  LoadProjectRoleQuery,
  LoadProjectRoleQueryVariables
>;
export const ResendInviteDocument = gql`
  mutation resendInvite($inviteeUserID: UUID!, $projectID: UUID, $message: String) {
    resendInvite(inviteeUserID: $inviteeUserID, projectID: $projectID, message: $message) {
      ...commonCompanyUserFields
    }
  }
  ${CommonCompanyUserFieldsFragmentDoc}
`;
export type ResendInviteMutationFn = Apollo.MutationFunction<
  ResendInviteMutation,
  ResendInviteMutationVariables
>;
export type ResendInviteMutationResult = Apollo.MutationResult<ResendInviteMutation>;
export type ResendInviteMutationOptions = Apollo.BaseMutationOptions<
  ResendInviteMutation,
  ResendInviteMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation updateProject(
    $projectID: UUID!
    $activeMilestoneID: UUID
    $name: String
    $code: String
    $description: String
    $descriptionStyled: String
    $thumbnail: String
    $location: String
    $lat: Float
    $lon: Float
    $statusID: UUID
    $team: TeamInput
    $projectDeliveryTypeID: UUID
    $locationDetails: LocationDetailsInput
    $orgNodeIDs: [UUID!]
  ) {
    updateProject(
      project: {
        id: $projectID
        activeMilestoneID: $activeMilestoneID
        name: $name
        code: $code
        description: $description
        descriptionStyled: $descriptionStyled
        thumbnail: $thumbnail
        location: $location
        lat: $lat
        lon: $lon
        statusID: $statusID
        team: $team
        projectDeliveryTypeID: $projectDeliveryTypeID
        locationDetails: $locationDetails
        orgNodeIDs: $orgNodeIDs
      }
    ) {
      ...commonProjectFields
    }
  }
  ${CommonProjectFieldsFragmentDoc}
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const ListCustomCategorizationsDocument = gql`
  query listCustomCategorizations($projectID: UUID!) {
    customCategorizations(projectID: $projectID) {
      id
      name
      builtin
      content {
        id
        name
        number
      }
    }
  }
`;
export type ListCustomCategorizationsQueryResult = Apollo.QueryResult<
  ListCustomCategorizationsQuery,
  ListCustomCategorizationsQueryVariables
>;
export const ListDraftCategoriesDocument = gql`
  query listDraftCategories($estimateID: UUID!) {
    draftCategorizations(estimateID: $estimateID) {
      ...commonCategorizationFields
    }
  }
  ${CommonCategorizationFieldsFragmentDoc}
`;
export type ListDraftCategoriesQueryResult = Apollo.QueryResult<
  ListDraftCategoriesQuery,
  ListDraftCategoriesQueryVariables
>;
export const ModuleEntitlementsDocument = gql`
  query moduleEntitlements($companyID: UUID) {
    loadModuleEntitlementFlags(companyID: $companyID)
  }
`;
export type ModuleEntitlementsQueryResult = Apollo.QueryResult<
  ModuleEntitlementsQuery,
  ModuleEntitlementsQueryVariables
>;
export const EstimateDocument = gql`
  query estimate(
    $estimateID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter
    $estimateSortBy: EstimateSortBy
    $pagination: Pagination
  ) {
    estimate(
      estimateID: $estimateID
      costMode: $costMode
      viewFilter: $viewFilter
      estimateSortBy: $estimateSortBy
      pagination: $pagination
    ) {
      ...commonEstimateFields
    }
  }
  ${CommonEstimateFieldsFragmentDoc}
`;
export type EstimateQueryResult = Apollo.QueryResult<EstimateQuery, EstimateQueryVariables>;
export const ProjectCompaniesDocument = gql`
  query projectCompanies($projectID: UUID!) {
    projectCompanies(projectID: $projectID) {
      company {
        id
        domain
        name
        type
        asset {
          id
          name
        }
      }
      role
    }
  }
`;
export type ProjectCompaniesQueryResult = Apollo.QueryResult<
  ProjectCompaniesQuery,
  ProjectCompaniesQueryVariables
>;
export const CreateItemIntegrationObjectDocument = gql`
  mutation CreateItemIntegrationObject(
    $sourceSystem: SourceSystem!
    $sourceID: UUID!
    $sourceProjectID: String!
    $sourceCompanyID: String
    $itemID: UUID!
    $input: CreateItemIntegrationObjectInput!
  ) {
    createItemIntegrationObject(
      sourceSystem: $sourceSystem
      sourceID: $sourceID
      sourceProjectID: $sourceProjectID
      sourceCompanyID: $sourceCompanyID
      itemID: $itemID
      input: $input
    ) {
      procoreChangeEvent {
        ...procoreChangeEventFields
      }
      autodeskPotentialChangeOrder {
        ...autodeskPotentialChangeOrderFields
      }
      error
    }
  }
  ${ProcoreChangeEventFieldsFragmentDoc}
  ${AutodeskPotentialChangeOrderFieldsFragmentDoc}
`;
export type CreateItemIntegrationObjectMutationFn = Apollo.MutationFunction<
  CreateItemIntegrationObjectMutation,
  CreateItemIntegrationObjectMutationVariables
>;
export type CreateItemIntegrationObjectMutationResult =
  Apollo.MutationResult<CreateItemIntegrationObjectMutation>;
export type CreateItemIntegrationObjectMutationOptions = Apollo.BaseMutationOptions<
  CreateItemIntegrationObjectMutation,
  CreateItemIntegrationObjectMutationVariables
>;
export const ItemIntegrationObjectsCountDocument = gql`
  query ItemIntegrationObjectsCount($itemID: UUID!) {
    itemIntegrationObjectsCount(itemID: $itemID)
  }
`;
export type ItemIntegrationObjectsCountQueryResult = Apollo.QueryResult<
  ItemIntegrationObjectsCountQuery,
  ItemIntegrationObjectsCountQueryVariables
>;
export const ItemIntegrationObjectsDocument = gql`
  query ItemIntegrationObjects(
    $sourceSystem: SourceSystem!
    $sourceID: UUID!
    $projectID: UUID!
    $itemID: UUID!
  ) {
    itemIntegrationObjects(
      sourceSystem: $sourceSystem
      sourceID: $sourceID
      projectID: $projectID
      itemID: $itemID
    ) {
      procoreChangeEvents {
        ...procoreChangeEventFields
      }
      autodeskPotentialChangeOrders {
        ...autodeskPotentialChangeOrderFields
      }
      refetchedAt
      error
    }
  }
  ${ProcoreChangeEventFieldsFragmentDoc}
  ${AutodeskPotentialChangeOrderFieldsFragmentDoc}
`;
export type ItemIntegrationObjectsQueryResult = Apollo.QueryResult<
  ItemIntegrationObjectsQuery,
  ItemIntegrationObjectsQueryVariables
>;
export const UnlinkItemIntegrationObjectDocument = gql`
  mutation UnlinkItemIntegrationObject(
    $projectID: UUID!
    $itemID: UUID!
    $sourceSystem: SourceSystem!
    $sourceType: SourceType!
    $sourceID: UUID!
    $input: UnlinkItemIntegrationObjectInput!
  ) {
    unlinkItemIntegrationObject(
      projectID: $projectID
      itemID: $itemID
      sourceSystem: $sourceSystem
      sourceType: $sourceType
      sourceID: $sourceID
      input: $input
    )
  }
`;
export type UnlinkItemIntegrationObjectMutationFn = Apollo.MutationFunction<
  UnlinkItemIntegrationObjectMutation,
  UnlinkItemIntegrationObjectMutationVariables
>;
export type UnlinkItemIntegrationObjectMutationResult =
  Apollo.MutationResult<UnlinkItemIntegrationObjectMutation>;
export type UnlinkItemIntegrationObjectMutationOptions = Apollo.BaseMutationOptions<
  UnlinkItemIntegrationObjectMutation,
  UnlinkItemIntegrationObjectMutationVariables
>;
export const PreviewProjectCollaboratorDocument = gql`
  query previewProjectCollaborator($projectID: UUID!, $userID: UUID!) {
    previewProjectCollaborator(projectID: $projectID, userID: $userID) {
      ...commonCollaboratorFields
    }
  }
  ${CommonCollaboratorFieldsFragmentDoc}
`;
export type PreviewProjectCollaboratorQueryResult = Apollo.QueryResult<
  PreviewProjectCollaboratorQuery,
  PreviewProjectCollaboratorQueryVariables
>;
export const PreviewProjectRoleDocument = gql`
  query previewProjectRole($projectID: UUID!, $roleID: UUID!) {
    previewProjectRole(projectID: $projectID, roleID: $roleID) {
      ...commonRoleFields
    }
  }
  ${CommonRoleFieldsFragmentDoc}
`;
export type PreviewProjectRoleQueryResult = Apollo.QueryResult<
  PreviewProjectRoleQuery,
  PreviewProjectRoleQueryVariables
>;
export const ProjectCollaboratorDocument = gql`
  query projectCollaborator($projectID: UUID!) {
    currentCollaborator(projectID: $projectID) {
      ...commonCollaboratorFields
    }
  }
  ${CommonCollaboratorFieldsFragmentDoc}
`;
export type ProjectCollaboratorQueryResult = Apollo.QueryResult<
  ProjectCollaboratorQuery,
  ProjectCollaboratorQueryVariables
>;
