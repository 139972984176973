import { FC } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import InputsTextAreaStyled from '../../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';
import useUpdateMilestoneDescription from '../hooks/UpdateMilestoneDescriptionHook';

import MilestonesDescriptionStyles from './MilestonesDescriptionStyles';

type Props = {
  classes: Classes<typeof MilestonesDescriptionStyles>;
  milestone: Milestone;
  editable: boolean;
};

const MilestonesDescription: FC<Props> = ({ classes, milestone, editable }) => {
  const projectId = getProjectIdFromUrl();
  const [updateDescription] = useUpdateMilestoneDescription(projectId);

  const onChangeComplete = (description: string, descriptionStyled: string): void => {
    const newMilestoneValue = {
      ...milestone,
      description,
      descriptionStyled, // Old UI should write text only to "styled"
    };
    updateDescription(newMilestoneValue);
  };

  return (
    <div className={classes.outerContainer}>
      <InputsTextAreaStyled
        editable={editable}
        onChangeComplete={onChangeComplete}
        placeholder="Describe this Milestone..."
        textStyled={milestone.descriptionStyled}
      />
    </div>
  );
};

export default withStyles(MilestonesDescriptionStyles)(MilestonesDescription);
