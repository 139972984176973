import { FC, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { UserReportType } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useEditProjectComparisonReportMutation } from '../../ForecastingRoute/ForecastingReports/hooks/useEditProjectComparisonReportMutation';
import DialogsReportsContent from '../DialogsReports/DialogsReportsContent/DialogsReportsContent';
import styles from '../DialogsStyles';

type DialogsReportEditProps = {
  classes: Classes<typeof styles>;
  onClose: () => void;
  onSuccess: () => void;
  open: boolean;
  report?: ForecastingReport;
};

// NOTE: this component is a ripoff of DialogsReport, but specialized for editing Forecasting Reports
// it would be best long term to revisit the reusability of DialogsReport/DialogsReportContent components
const DialogsReportEdit: FC<DialogsReportEditProps> = ({
  classes,
  onClose,
  onSuccess,
  open,
  report,
}) => {
  const projectId = getProjectIdFromUrl();

  const [reportInput, setReportInput] = useState<UserReportInput>({
    name: '',
    description: '',
    reportType: UserReportType.NONE,
    settings: '',
    shared: false,
  });
  useEffect(
    () =>
      setReportInput((prevReport) => ({
        ...prevReport,
        id: report?.id,
        name: report?.name ?? '',
        description: report?.description ?? '',
      })),
    [report]
  );

  const [onEditForecastingReport] = useEditProjectComparisonReportMutation(onSuccess);

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaperSmall,
      }}
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      <div className={classes.titleContainer}>
        <div>
          <Typography variant="title">Edit report</Typography>
        </div>
        <IconButton className={classes.close} title="Close dialog">
          <Close onClick={onClose} />
        </IconButton>
      </div>
      <Divider />
      <DialogContent className={classes.content}>
        <DialogsReportsContent
          allowableReportTypes={[]}
          // DialogsReportsEdit is only used only for forecasting, which are not able to be shared
          cannotShare
          onSubmit={() => {}}
          projectId={projectId}
          setUserReport={setReportInput}
          userReport={reportInput}
          variant="reports-manager"
        />
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          data-cy="confirm-dialog--ok-button"
          disabled={
            reportInput.name === report?.name && reportInput.description === report?.description
          }
          onClick={() => {
            onEditForecastingReport(report?.id ?? '', reportInput.name, reportInput.description);
            onClose();
          }}
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DialogsReportEdit);
