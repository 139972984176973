import { FC, useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  INSIGHTS_DEFAULT_MAX_ITEMS_BAR,
  INSIGHTS_DEFAULT_TIME,
  insightsMaxItemsBar,
  insightsTimeDomain,
} from '../../../api/apollo/reactiveVars';
import { YC_GROUPS, YC_PROJ_LEAD } from '../../../features';
import { ForecastingProjectsSortKey } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { formatNumber, formatPercent } from '../../../utilities/string';
import ChartsPieGraph from '../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import ProjectCell from '../../ForecastingRoute/ForecastingExplore/projects/cells/ProjectCell';
import { SortManager, Table, Tooltip } from '../../scales';
import PendingPieToolTip from '../ToolTips/PendingPieToolTip';
import { InsightsTabId } from '../types';

import ProjectTimeline from './ProjectTimeline';
import StickyBottomRow from './StickyBottomRow';
import ZoomTimeline from './ZoomTimeline';

const DATE_STR_INIT = '0';

type Props = {
  counts: { current: number; total: number };
  loading: boolean;
  onFetchMore: () => void;
  projects: InsightsProjects[];
  searchText: string;
  sortManager: SortManager;
};

const ProjectsItems: FC<Props> = (props) => {
  const isGroupsEnabled = useHasFeature(YC_GROUPS);
  useEffectOnce(() => () => {
    insightsTimeDomain(INSIGHTS_DEFAULT_TIME);
    insightsMaxItemsBar(INSIGHTS_DEFAULT_MAX_ITEMS_BAR);
  });
  const isProjectLeadEnabled = useHasFeature(YC_PROJ_LEAD);

  const [startStr, setStartStr] = useState<string>(DATE_STR_INIT);
  const [endStr, setEndStr] = useState<string>(DATE_STR_INIT);
  const setStartEnd = ([start, end]: string[]) => {
    setStartStr(start);
    setEndStr(end);
  };
  const onZoom = (domain: string[]) => {
    setStartEnd(domain);
  };

  const stickyFooterEntry = StickyBottomRow({
    columnsCount: 5,
    component: <ZoomTimeline onZoom={onZoom} projects={props.projects} zoom={[startStr, endStr]} />,
  });

  return (
    <>
      <Table
        columnWidths={[
          'minmax(400px, 3fr)',
          'minmax(100px, 0.5fr)',
          'minmax(100px, 0.5fr)',
          'minmax(100px, 0.5fr)',
          'minmax(600px, 6fr)',
        ]}
        entries={props.projects.map((project) => {
          const key = project.id;
          const to = {
            pathname: generateSharedPath(RouteKeys.INSIGHTS_PROJECT, {
              projectId: project.id,
            }),
            hash: `#${InsightsTabId.Items}`,
          };
          const pie = randomPieData();
          return [
            {
              key,
              component: (
                <ProjectCell
                  isSingleLineHeader
                  project={project}
                  searchText={props.searchText}
                  showOrgs={isGroupsEnabled}
                  showProjectLead={isProjectLeadEnabled}
                  to={to}
                />
              ),
            },
            {
              key,
              centered: true,
              component: (
                <Tooltip
                  content={
                    <div>
                      <PendingPieToolTip />
                    </div>
                  }
                >
                  <div>
                    <ChartsPieGraph
                      centerLabel={{ label: String(Math.floor(Math.random() * 100) + 1) }}
                      chartSize={{
                        diameter: 60,
                        insideDiameter: 35,
                      }}
                      colors={[
                        'var(--colors-item-status-pending)',
                        'var(--colors-item-status-rejected)',
                        'var(--colors-item-status-pending-tint)',
                        'var(--colors-item-status-not-applicable)',
                      ]}
                      data={pie.dataPie}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: <div>2</div>,
            },
            {
              key,
              centered: true,
              component: <div>2</div>,
            },
            {
              key,
              component: (
                <ProjectTimeline
                  endStr={endStr}
                  onZoom={onZoom}
                  projectID={project.id}
                  startStr={startStr}
                />
              ),
            },
          ];
        })}
        headerContent={[
          {
            component: <div className="h-9" />,
            copy: `Projects (${formatNumber(props.counts.current)} of ${formatNumber(
              props.counts.total
            )})`,
            key: 'projects',
            headerSortKey: ForecastingProjectsSortKey.NAME,
          },
          {
            copy: 'Pending',
            key: 'Pending',
          },
          {
            copy: 'Past due',
            key: 'Past',
          },
          { copy: 'Due next 14d', key: 'Due' },
          { copy: '', key: 'timeline' },
        ]}
        loading={props.loading}
        onNeedMoreData={props.onFetchMore}
        sortManager={props.sortManager}
        stickyFooterEntry={stickyFooterEntry}
      />
    </>
  );
};

export default ProjectsItems;

export const randomPieData = () => {
  const pieChartData = ['Pending', 'Accepted', 'Rejected'].map((name) => ({
    name,
    percentOfMilestoneEstimate: Math.floor(Math.random() * 100) + 1,
  }));

  const dataPie = pieChartData.map(({ name, percentOfMilestoneEstimate }) => ({
    share: percentOfMilestoneEstimate,
    name,
  }));

  const legendElements = pieChartData.map(({ name, percentOfMilestoneEstimate }) => ({
    text: name,
    percentage: formatPercent(percentOfMilestoneEstimate, 4),
  }));

  return { legendElements, dataPie };
};
