import { FC, useContext } from 'react';

import { HelpOutlined } from '@material-ui/icons';

import { EstimateType, TermKey } from '../../../api/gqlEnums';
import { NS_OWNER_COSTS } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { checkCostModeIncludesMarkups, useCostMode } from '../../../utilities/costMode';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { Tooltip } from '../../scales';

import { getEstimateType } from './EstimateAccordionUtils';

type Props = {
  type?: EstimateType;
};

const EstimateTotalHeader: FC<Props> = ({ type }) => {
  const t = useContext(ProjectTermStore);
  const estimateType = type || getEstimateType();
  const isEstimate = estimateType === EstimateType.ACTIVE_ESTIMATE;
  const isOwnerCostsEnabled = useHasFeature(NS_OWNER_COSTS);

  const costMode = useCostMode();
  const estimateHandle = isEstimate ? t.titleCase(TermKey.ESTIMATE) : t.titleCase(TermKey.TARGET);

  if (isOwnerCostsEnabled) {
    return (
      <div>
        Project Total
        <Tooltip content="Project Total (includes Cost of Construction and Owner Costs)">
          <span>
            <HelpOutlined className="mb-0.5 ml-1 !fill-button-icon-outline !icon-sm" />
          </span>
        </Tooltip>
      </div>
    );
  }

  return (
    <>
      {estimateHandle} (
      {checkCostModeIncludesMarkups(costMode) ? 'Including Markups' : 'Excluding Markups'})
    </>
  );
};

export default EstimateTotalHeader;
