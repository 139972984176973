import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../analytics/analyticsEventProperties';
import { companyTabIDVar } from '../../api/apollo/reactiveVars';
import { JoinCompanyRoutes, JoinRoutes } from '../../api/gqlEnums';
import {
  COMPANY_CATEGORIZATIONS_PATH,
  COMPANY_STANDARDS_PATH,
  COMPANY_TAB,
  COMPANY_TAB_PATH,
} from '../../constants';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { generateSharedPath } from '../../utilities/routes/links';
import { useUserProfile } from '../../utilities/userProfile';
import { Select } from '../scales';

import { useCompaniesQuery } from './CompanyHooks';
import CompanyTabNav from './CompanyTabNav/CompanyTabNav';
import { CompanyHeadingsTabs, UserStatusToggle, useCompanyRoutesData } from './CompanyTabUtils';

export default function CompanyTab() {
  const navigate = useNavigate();
  const profile = useUserProfile();
  document.title = `${COMPANY_TAB} ${profile ? ` - ${profile.nickname}` : ''}`;

  const sendAnalytics = useSendAnalytics();

  useRedirectCompanyHome();

  const { companyID, filterManager, searchManager } = useCompanyRoutesData();

  const view = window.location.pathname.split('/')[2] || CompanyHeadingsTabs.MEMBERS;

  const setView = (view: string) => {
    const currentView = window.location.pathname.split('/')[2] || CompanyHeadingsTabs.MEMBERS;
    if (view !== currentView) {
      searchManager.clearSearch();
      filterManager.setFilterStatus(UserStatusToggle.ALL);
    }
    if (view === COMPANY_STANDARDS_PATH) {
      navigate(`/${COMPANY_TAB_PATH}/${view}/${COMPANY_CATEGORIZATIONS_PATH}`);
    } else {
      navigate(`/${COMPANY_TAB_PATH}/${view}`);
    }

    switch (view) {
      case CompanyHeadingsTabs.MEMBERS:
        sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBERS_CTA));
        break;
      case CompanyHeadingsTabs.COLLABORATORS:
        sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.COLLABORATORS_CTA));
        break;
      case CompanyHeadingsTabs.SETTINGS:
        sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.SETTINGS_CTA));
        break;
      case CompanyHeadingsTabs.PROJECT_STATS:
        sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_STATS_CTA));
        break;
      default:
    }
  };

  const companiesQueryResult = useCompaniesQuery();
  const companies =
    companiesQueryResult.data?.companies ?? companiesQueryResult.previousData?.companies ?? [];
  if (!companyID) return null;

  const header = (
    <div className="bg-background-primary">
      <div className="flex w-full items-center px-4 py-2">
        <div className="type-heading1">Company Settings</div>
        <div className="ml-auto w-64">
          {/* Display the selector for verified Join users. */}
          {companies?.length > 0 && (
            <Select
              entries={companies.map((c) => ({ id: c.id, label: c.name }))}
              isSearchable
              onChange={(newValue: UUID) => {
                if (newValue) {
                  companyTabIDVar(newValue);
                  navigate(`/${COMPANY_TAB_PATH}/${view}`);
                }
              }}
              value={companyID}
            />
          )}
        </div>
      </div>
      <div>
        <CompanyTabNav setView={setView} view={view} />
      </div>
    </div>
  );

  return (
    <div className="flex h-full flex-col overflow-hidden bg-background-primary">
      {header}
      <Outlet />
    </div>
  );
}

export const useRedirectCompanyHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const pathnameWithoutTrailingSlash = location.pathname.replace(/\/$/, '');
    const rootCompanyPath = generateSharedPath(JoinRoutes.COMPANY_TAB, {});
    if (pathnameWithoutTrailingSlash === rootCompanyPath) {
      navigate(generateSharedPath(JoinCompanyRoutes.COMPANY_MEMBERS, {}));
    }
  }, [location, navigate]);
};
