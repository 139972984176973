import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      display: 'flex',
      gap: '20px',
      alignItems: 'center',
      margin: '0px -8px',
      padding: '12px 8px',
      width: '-webkit-fill-available',
    },
    info: { display: 'flex', flexDirection: 'column', gap: '8px' },
    title: { ...theme.typography.webHeading3 },
    link: {
      color: theme.palette.brand.middleBlue,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    chip_icon: {
      display: 'block',
      width: 16,
      height: 16,
    },
  });
