import { FC } from 'react';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import CustomizationDialog from '../../shared-widgets/CustomizationDialog/CustomizationDialog';

type AverageCompCustomizationDialogProps = {
  color?: string;
  open: boolean;
  setAverageCompInputColorAndThumbnailID: (color?: string, thumbnail?: UUID) => void;
  setOpen: (value: boolean) => void;
  thumbnailAssetID?: UUID;
};

const AverageCompCustomizationDialog: FC<AverageCompCustomizationDialogProps> = ({
  color,
  open,
  setAverageCompInputColorAndThumbnailID,
  setOpen,
  thumbnailAssetID,
}) => {
  const sendAnalytics = useSendAnalytics();

  const onClose = () => {
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_CUSTOMIZE_MENU_CLOSE)
    );
    setOpen(false);
  };

  const onSubmitCustomizedContent = (color?: string, assetID?: UUID | null) => {
    // the dialog will send a null value if the user clicks the "remove" button
    // the setter will handle the undefined as remove
    setAverageCompInputColorAndThumbnailID(color, assetID ?? undefined);
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_CUSTOMIZE_MENU_DONE_CTA, {
        customized: { color: !!color, thumbnailAssetID: !!assetID },
      })
    );
  };

  if (!open) return null;

  return (
    <CustomizationDialog
      color={color}
      onClose={onClose}
      setColorAndThumbnailID={onSubmitCustomizedContent}
      thumbnailAssetID={thumbnailAssetID}
      title="Customize Column"
    />
  );
};

export default AverageCompCustomizationDialog;
