import * as d3 from 'd3';
import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

import useMemoWrapper from '../../useMemoWrapper';

type Data = { x: Date; y0: number; y: number };

export default function TimelineArea(props: {
  data: Data[];
  fill: string;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  strokeWidth?: number;
  stroke?: string;
}) {
  const area = useMemoWrapper(getAreaCoords, props.data, props.x, props.y) ?? undefined;
  if (!props.data) return null;

  return (
    <path
      className={props.stroke ?? 'stroke-type-primary'}
      d={area}
      data-cy="path-area"
      fill={props.fill}
      stroke={props.stroke ?? 'stroke-type-primary'}
      strokeWidth={props.strokeWidth ?? 1}
    />
  );
}

const getAreaCoords = (
  data: Data[],
  x: ScaleTime<number, number> | ScalePoint<Date>,
  y: ScaleLinear<number, number>
) =>
  d3
    .area<Data>()
    .x((d) => x(d.x) ?? 0)
    .y0((d) => y(d.y0))
    .y1((d) => y(d.y))(data);
