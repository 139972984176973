import { TableComponents } from '../../../../scales';
import { PermissionDisplayData } from '../permissions';

type Props = {
  disabled: boolean;
  displayData: PermissionDisplayData;
};

const PermissionDescriptionCell = (props: Props) => {
  return (
    <TableComponents.Cell
      className={`${props.disabled ? 'text-type-inactive' : ''} flex flex-col items-baseline ${
        props.displayData.note || props.displayData.dependencies ? 'gap-6' : ''
      } py-3`}
    >
      {props.displayData.description}
      <div>
        {props.displayData.note && (
          <div className="type-body2">
            <strong>Note:</strong>
            {` ${props.displayData.note}`}
          </div>
        )}
        {props.displayData.dependencies && (
          <div className="type-body2">
            <strong>Dependencies:</strong>
            {` ${props.displayData.dependencies}`}
          </div>
        )}
      </div>
    </TableComponents.Cell>
  );
};

export default PermissionDescriptionCell;
