import { useMutation } from '@apollo/client';

import { REFETCH_SCENARIO_SANDBOX } from '../../../api/refetchSets';
import { ApplyScenarioMutation, ApplyScenarioMutationVariables } from '../../../generated/graphql';

import { applyScenarioMutation } from './queries';

export const useApplyScenario = (variables: ApplyScenarioMutationVariables) => {
  return useMutation<ApplyScenarioMutation, ApplyScenarioMutationVariables>(applyScenarioMutation, {
    variables,
    refetchQueries: () => REFETCH_SCENARIO_SANDBOX,
  });
};
