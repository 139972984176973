import { FC, KeyboardEvent, useState } from 'react';

import { CircularProgress, Divider, Typography, withStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import { NewProjectEvent, newProjectEvent } from '../../../analytics/analyticsEventProperties';
import {
  PROJECT_CURRENCY,
  PROJECT_LOCATION_CITY,
  PROJECT_SHORTNAME,
  SET_PROJECT_LOCATION_PLACEHOLDER,
  UNASSIGNED,
} from '../../../constants';
import { YC_GROUPS, YC_PROJ_LEAD } from '../../../features';
import {
  CreateProjectMutationVariables,
  DesignPhaseType,
  ImageDimension,
  LocationDetailsInput,
  ProjectType,
} from '../../../generated/graphql';
import { useCreateProject } from '../../../hooks/ProjectHooks';
import { useCompanyProjectTypesQuery } from '../../../hooks/useCompanyProjectTypesQuery';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { useProjectDeliveryTypes } from '../../../hooks/useProjectDeliveryTypesQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { constructCurrencyEntries } from '../../../utilities/currency';
import { EmptyThumbnail } from '../../AssigneeSelect/AssigneeSelect';
import UserAvatar from '../../Collaborators/UserAvatar';
import useOrganizationsQuery from '../../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import OrgSelector from '../../CompanyTab/CompanyTabOrganizations/OrgSelector/OrgSelector';
import { filterPublished } from '../../CompanyTab/CompanyTabOrganizations/OrgSelector/utils';
import { useCompanyRoutesData } from '../../CompanyTab/CompanyTabUtils';
import { useCompanyUsersQuery } from '../../CompanyTab/useCompanyUsersQuery';
import { SelectLevelsEntry } from '../../frame/AdvancedFiltersSidebar/FilterGroupLevels';
import { useDesignPhaseTypes } from '../../Milestone/hooks/useDesignPhaseTypesQuery';
import MilestoneDatePicker from '../../Milestone/MilestoneDatePicker/MilestoneDatePicker';
import MilestoneDesignPhaseSelector from '../../Milestone/MilestoneDesignPhaseSelector/MilestoneDesignPhaseSelector';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { MAX_PROJECT_CODE_LENGTH } from '../../ProjectProperties/ProjectDetails';
import ProjectPropSelector, { ProjectProp } from '../../ProjectProperties/ProjectPropSelector';
import useProjectStatusesQuery from '../../ProjectsList/hooks/useProjectStatusesQuery';
import { Button, Dialog, DialogContent, Select, SelectEntry, TextInput } from '../../scales';
import PlacesAutocompleteWrapper from '../../shared-widgets/PlacesAutocomplete/PlacesAutocompleteWrapper';
import Thumbnail from '../../shared-widgets/Thumbnail/Thumbnail';
import useMemoWrapper from '../../useMemoWrapper';

import styles from './DialogsNewProjectStyles';
import {
  computeClearInactive,
  computeProjectTypes,
  currencyTooltip,
  getProjectInputDefault,
  milestoneLabel,
  milestoneTooltip,
  shortnameTooltip,
} from './DialogsNewProjectUtils';
import ProjectTypeSelector from './ProjectTypeSelector';

type DialogsNewProjectSingleProps = {
  classes: Classes<typeof styles>;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  template?: ProjectTemplate;
};
/**
 * This is a single dialog version of DialogsNewProject flow.
 * It is a deprecated version and should be removed with YC_PROJ_LEAD feature flag
 */
const DialogsNewProjectSingle: FC<DialogsNewProjectSingleProps> = ({
  classes,
  dialogOpen = false,
  setDialogOpen,
  template,
}) => {
  const createProject = useCreateProject();

  const sendAnalytics = useSendAnalytics();
  const isGroupsFeature = useHasFeature(YC_GROUPS);
  const isLeadsFeature = useHasFeature(YC_PROJ_LEAD);

  const [submitted, setSubmitted] = useState(false);

  // Opens the (optional) organization categorization dialog
  const [, setCategorizeOpen] = useState(false);

  const tp = template?.project;
  const projectInput = getProjectInputDefault(tp, template?.settings);
  const [project, setProject] = useState<CreateProjectMutationVariables>(projectInput);
  const [, setProjectID] = useState<string | null>(null);

  const resetProject = () => setProject(projectInput);
  const {
    code,
    location,
    milestone,
    milestoneDate,
    milestoneDesignPhaseID,
    name,
    statusID,
    typeID,
    projectDeliveryTypeID,
  } = project;

  const onCreateProject = () => {
    setSubmitted(true);
    if (isLeadsFeature) {
      setCategorizeOpen(true);
    }
    createProject(
      project,
      (res) => {
        setSubmitted(false);
        if (!isLeadsFeature) {
          setDialogOpen(false);
          resetProject();
        }
        setProjectID(res?.id);
      },
      () => {
        setSubmitted(false);
        setDialogOpen(false);
        resetProject();
      }
    );
    sendAnalytics(
      newProjectEvent(NewProjectEvent.CREATE_CTA, {
        location: window.location.pathname,
        milestoneDesignPhase: milestoneDesignPhaseID,
        milestoneName: milestone,
        milestoneStartDate: milestoneDate,
        orgNodeIDs: project.orgNodeIDs ?? undefined,
        projectDeliveryMethod: projectDeliveryTypeID ?? undefined,
        projectStatus: statusID,
        projectType: typeID ?? undefined,
      })
    );
  };
  const onKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
    if (evt.key === 'Enter' && name !== '' && milestone && !submitted && project.statusID) {
      setSubmitted(true);
      onCreateProject();
    }
  };
  // Currency

  // Project Statuses
  const projectStatuses = useProjectStatusesQuery().data?.projectStatuses ?? [];
  const statuses = useMemoWrapper(computeClearInactive, projectStatuses);

  // Project Types
  const { companyID } = useCompanyRoutesData();
  const { data: typesDataNew } = useCompanyProjectTypesQuery(false, companyID);
  const projectTypes = typesDataNew?.companyProjectTypes || [];
  const types: ProjectType[] = useMemoWrapper(computeProjectTypes, projectTypes);
  const { data: { companyUsers } = { companyUsers: [] } } = useCompanyUsersQuery({
    variables: { companyID },
    skip: !companyID,
  });

  // Project Delivery Types
  const projectDeliveryTypes = useProjectDeliveryTypes();

  // Design Phase Types
  const designPhaseTypes = useDesignPhaseTypes();

  // Project Leads
  const projectLeadEntries: SelectEntry[] = [];
  companyUsers.forEach((companyUser) => {
    if (companyUser.user) {
      projectLeadEntries.push({
        id: companyUser.user?.id,
        label: companyUser.user?.name,
        startAdornment: <UserAvatar assignee={companyUser.user} />,
      });
    }
  });
  const unassignedEntry: SelectEntry = {
    id: UNASSIGNED,
    label: UNASSIGNED,
    startAdornment: <EmptyThumbnail />,
    isSection: false,
  };
  projectLeadEntries.unshift(unassignedEntry);

  const shortnameTooltipFormat = (
    <div>
      {shortnameTooltip.map((line) => (
        <div key={line}>{line}</div>
      ))}
    </div>
  );

  // Organizations
  const organizationsQueryResult = useOrganizationsQuery(companyID);
  const orgs = organizationsQueryResult.data?.organizations;
  const publishedOrgs = useMemoWrapper(filterPublished, orgs);

  const infoIcon = <Info className="text-type-muted" fontSize="inherit" />;

  const singleDialog = (
    <Dialog
      aria-labelledby="form-dialog-title"
      footerRight={
        submitted ? (
          <div className={classes.footerCentered}>
            <CircularProgress size={18} />
            <Typography className={classes.boldBlue}>
              Creating: {name}. This might take a few minutes.
            </Typography>
          </div>
        ) : (
          <Button
            disabled={
              !name ||
              !milestone ||
              !statusID ||
              !typeID ||
              !location ||
              !milestoneDate ||
              !milestoneDesignPhaseID ||
              !projectDeliveryTypeID
            }
            label="Create"
            onClick={() => {
              if (!submitted) {
                onCreateProject();
              }
            }}
            type="primary"
          />
        )
      }
      image={
        template ? (
          <Thumbnail
            dimension={ImageDimension._50}
            padding={0}
            size={40}
            thumbnail={template.project.thumbnail}
          />
        ) : undefined
      }
      isOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
      title={`New ${template?.name ?? ''} Project`}
    >
      <DialogContent className={classes.content}>
        {isGroupsFeature && <div className="pb-2 type-heading3">Project Overview</div>}
        <div className="flex flex-col gap-2">
          <TextInput
            autoFocus
            data-cy="project-name-text-input"
            disabled={submitted}
            id="name"
            label="Project Name"
            onChange={(value) => {
              setProject({ ...project, name: value });
            }}
            onKeyDown={onKeyDown}
            placeholder={tp?.name || 'Set project name'}
            value={name || ''}
          />
          <div className="flex flex-row justify-between gap-2">
            <div className="flex w-1/2 flex-col gap-0.5">
              <div className="type-label">Project Status *</div>
              <ProjectPropSelector
                data-cy="project-status-select"
                editable={!submitted}
                name="status"
                selectedValue={statusID}
                setProjectProp={(p: ProjectProp | null) => {
                  if (p) {
                    setProject({ ...project, statusID: p.id });
                  }
                }}
                values={statuses}
              />
            </div>
            <div className="flex w-1/2 flex-col">
              <ProjectTypeSelector
                editable={!submitted}
                label="Project Type *"
                name="type"
                search
                selectedValue={typeID}
                setProjectProp={(p: ProjectProp | SelectLevelsEntry | null) => {
                  setProject({ ...project, typeID: p?.id });
                }}
                values={types}
              />
            </div>
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="type-label">Project Delivery Method *</div>
            <ProjectPropSelector
              data-cy="project-delivery-select"
              editable={!submitted}
              name="delivery method"
              selectedValue={projectDeliveryTypeID}
              setProjectProp={(p: ProjectProp | null) => {
                setProject({ ...project, projectDeliveryTypeID: p?.id });
              }}
              values={projectDeliveryTypes}
            />
          </div>
          <PlacesAutocompleteWrapper
            defaultValue={location}
            disabled={submitted}
            label={`${PROJECT_LOCATION_CITY} *`}
            onChange={(locationDetails?: LocationDetailsInput) => {
              if (locationDetails)
                setProject({
                  ...project,
                  location: locationDetails.name,
                  lat: locationDetails.lat,
                  lon: locationDetails.lon,
                  locationDetails,
                });
            }}
            placeholder={SET_PROJECT_LOCATION_PLACEHOLDER}
          />
          {isGroupsFeature ? null : (
            <div className="flex flex-row gap-2">
              <div className="flex w-1/2 flex-col gap-0.5">
                <div className="items-center gap-0.5 type-label">
                  {PROJECT_CURRENCY}
                  <NormalTooltip title={currencyTooltip}>{infoIcon}</NormalTooltip>
                </div>
                <Select
                  aria-label="Select project currency"
                  data-cy="currency-select"
                  entries={constructCurrencyEntries()}
                  isDisabled={submitted}
                  onChange={(currency: string) => {
                    setProject({ ...project, currency });
                  }}
                  placeholder="$ USD US Dollar"
                  value={project.currency}
                />
              </div>
              <div className="flex w-1/2 flex-col gap-0.5">
                <div className="gap-0.5 type-label">
                  {PROJECT_SHORTNAME}
                  <NormalTooltip title={shortnameTooltipFormat}>{infoIcon}</NormalTooltip>
                </div>
                <TextInput
                  aria-label="Set project shortname"
                  disabled={submitted}
                  maxLength={MAX_PROJECT_CODE_LENGTH}
                  onChange={(code) => {
                    setProject({ ...project, code });
                  }}
                  onKeyDown={onKeyDown}
                  placeholder={tp?.code || `Set ${PROJECT_SHORTNAME.toLowerCase()}`}
                  value={code || ''}
                />
              </div>
            </div>
          )}
          {isGroupsFeature && publishedOrgs && publishedOrgs.length > 0 && (
            <OrgSelector
              isHiddenForNoOrgs
              label="Organizations"
              onChange={(orgNodeIDs: string[]) => {
                setProject({ ...project, orgNodeIDs });
              }}
              orgNodes={tp?.orgNodes}
              orgs={orgs}
            />
          )}
        </div>

        {!isGroupsFeature && (
          <div className="pt-4">
            <Divider />
          </div>
        )}
        <div className="flex flex-col gap-2 pt-6">
          {isGroupsFeature && <div className="type-heading3">Active Milestone</div>}
          <div className="flex flex-row gap-2">
            <div className="flex w-1/2 flex-col gap-0.5">
              <div className="gap-0.5 type-label">
                {milestoneLabel(!template)}
                <NormalTooltip
                  title={
                    <>
                      <div>{milestoneTooltip}</div>
                      {template && (
                        <div>
                          Once a project is created you can go to the milestone and change its name.
                        </div>
                      )}
                    </>
                  }
                >
                  {infoIcon}
                </NormalTooltip>
              </div>
              <TextInput
                aria-label="Set active milestone name"
                data-cy="active-milestone-name-text-input"
                disabled={!!template || submitted}
                onChange={(milestone) => {
                  setProject({ ...project, milestone });
                }}
                onKeyDown={onKeyDown}
                placeholder="Set milestone name"
                value={milestone || ''}
              />
            </div>
            <div className="flex w-1/2 flex-col">
              <MilestoneDatePicker
                date={milestoneDate}
                disabled={submitted}
                onUpdate={(date) => {
                  setProject({ ...project, milestoneDate: date || '' });
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="type-label">Milestone Design Phase *</div>
            <MilestoneDesignPhaseSelector
              disabled={submitted}
              selectedValue={milestoneDesignPhaseID}
              setSelection={(designPhase: DesignPhaseType | undefined) => {
                if (designPhase) {
                  setProject({ ...project, milestoneDesignPhaseID: designPhase.id });
                }
              }}
              values={designPhaseTypes}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );

  return singleDialog;
};

export default withStyles(styles)(DialogsNewProjectSingle);
