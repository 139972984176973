import { isUUID } from 'validator';

import {
  AssetQuery,
  CompanyAssetQuery,
  CompanyAssetQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { companyAssetQuery } from './queries';

export default function useCompanyAssetQuery(
  assetID: UUID | null | undefined,
  onCompleted?: (data: AssetQuery) => void
) {
  return useQuery<CompanyAssetQuery, CompanyAssetQueryVariables>(companyAssetQuery, {
    variables: { assetID },
    skip: !assetID || !isUUID(assetID),
    context: { batch: true },
    onCompleted,
  });
}
