import { ReactNode, useState } from 'react';

import { Add, Info } from '@material-ui/icons';

import { useDesignPhaseTypes } from '../../Milestone/hooks/useDesignPhaseTypesQuery';
import { BabyButton, Button, Tooltip } from '../../scales';

import ContingenciesAlert from './Sections/ContingenciesAlert';
import GapAlert from './Sections/GapAlert';
import ItemsAlert from './Sections/ItemsAlert';
import PathToBudgetAlert from './Sections/PathToBudgetAlert';

type Props = {
  companyID: UUID;
};

type AlertType = 'gap' | 'pathToBudget' | 'items' | 'contingencies';

export default function InsightAlertsBody(props: Props) {
  const designPhaseTypes = useDesignPhaseTypes();
  // eslint-disable-next-line no-console
  console.log(props.companyID);
  const [alerts, setAlerts] = useState<{ type: AlertType; id: number }[]>([
    { type: 'gap', id: Date.now() },
    { type: 'pathToBudget', id: Date.now() + 1 },
    { type: 'items', id: Date.now() + 2 },
    { type: 'contingencies', id: Date.now() + 3 },
  ]);

  const addAlert = (type: AlertType) => {
    setAlerts((prevAlerts) => [...prevAlerts, { type, id: Date.now() + Math.random() }]);
  };

  const deleteAlert = (id: number) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  };

  const gapAlertDescription =
    "Gap alerts monitor the difference between a project's Running Total and Budget, and trigger when the Gap exceeds the specified threshold.";
  const pathToBudgetTooltip =
    "Path to Budget alerts monitor the Gap for a project's best case scenario by taking into account deductive pending items.";

  const alertSection = (
    header: string,
    alerts: ReactNode,
    action: ReactNode,
    headerInfo?: ReactNode
  ) => (
    <div className="flex flex-col gap-1">
      <div className="flex items-center">
        <div className="type-body2">{header}</div>
        {headerInfo}
      </div>
      <div className="flex flex-col gap-2">{alerts}</div>
      <div className="mb-2">{action}</div>
    </div>
  );

  return (
    <div className="flex flex-col gap-10 px-6 pb-6">
      {/* Costs */}
      <div className="flex flex-col gap-2">
        <div className="mb-2 flex flex-col gap-1">
          <div className="type-heading3">Costs</div>
          <div className="type-body1">
            Costs alerts monitors the costs on your projects and trigger when the specified
            threshold is exceeded.
          </div>
        </div>

        {/* Gap */}
        {alertSection(
          'Gap',
          alerts
            .filter((alert) => alert.type === 'gap')
            .map((alert) => (
              <GapAlert
                key={alert.id}
                designPhaseTypes={designPhaseTypes}
                id={alert.id}
                onDelete={deleteAlert}
              />
            )),
          <Button
            label="Add Alert"
            onClick={() => addAlert('gap')}
            startIcon={<Add />}
            type="secondary"
          />,
          <Tooltip content={gapAlertDescription}>
            <BabyButton aria-label="Info" icon={<Info color="disabled" />} />
          </Tooltip>
        )}

        {/* Path to Budget */}
        {alertSection(
          'Path to Budget',
          alerts
            .filter((alert) => alert.type === 'pathToBudget')
            .map((alert) => (
              <PathToBudgetAlert
                key={alert.id}
                designPhaseTypes={designPhaseTypes}
                id={alert.id}
                onDelete={deleteAlert}
              />
            )),
          <Button
            label="Add Alert"
            onClick={() => addAlert('pathToBudget')}
            startIcon={<Add />}
            type="secondary"
          />,
          <Tooltip content={pathToBudgetTooltip}>
            <BabyButton aria-label="Info" icon={<Info color="disabled" />} />
          </Tooltip>
        )}
      </div>

      {/* Items */}
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <div className="type-heading3">Items</div>
          <div className="type-body1">
            Items alerts monitor past due items and trigger when the specified threshold of past due
            by more than a specified time is exceeded.
          </div>
        </div>
        {alertSection(
          '',
          alerts
            .filter((alert) => alert.type === 'items')
            .map((alert) => <ItemsAlert key={alert.id} id={alert.id} onDelete={deleteAlert} />),
          <Button
            label="Add Alert"
            onClick={() => addAlert('items')}
            startIcon={<Add />}
            type="secondary"
          />
        )}
      </div>

      {/* Contingencies */}
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <div className="type-heading3">Contingencies</div>
          <div className="type-body1">
            Contingencies & Allowances alerts monitor potential draw down and trigger when the
            specified threshold is exceeded.
          </div>
        </div>
        {alertSection(
          '',
          alerts
            .filter((alert) => alert.type === 'contingencies')
            .map((alert) => (
              <ContingenciesAlert
                key={alert.id}
                designPhaseTypes={designPhaseTypes}
                id={alert.id}
                onDelete={deleteAlert}
              />
            )),
          <Button
            label="Add Alert"
            onClick={() => addAlert('contingencies')}
            startIcon={<Add />}
            type="secondary"
          />
        )}
      </div>
    </div>
  );
}
