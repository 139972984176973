import { useContext, useEffect, useState } from 'react';
import * as React from 'react';

import { Card } from '@material-ui/core';
import { Add, Compare } from '@material-ui/icons/';

import { reportVariance, varianceEventTypes } from '../../analytics/analyticsEventProperties';
import { TermKey } from '../../api/gqlEnums';
import { GSF_ID, GSM_ID } from '../../constants';
import { PermissionResource } from '../../generated/graphql';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { withStyles } from '../../theme/komodo-mui-theme';
import usePermissions from '../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../utilities/permissions/useShouldDisplayCosts';
import { getProjectIdFromUrl } from '../../utilities/url';
import { useCurrentUser } from '../contexts/current-user';
import { NewMilestone as NewMilestoneDialog } from '../Dialogs';
import FourOhFour from '../FourOhFour';
import { useGetProjectUnitsQuery } from '../Milestone/hooks/UnitHooks';
import { ProjectTermStore } from '../ProjectDisplaySettings/TerminologyProvider';
import { Button } from '../scales';
import VarianceManager, {
  openVarianceOnboarding,
} from '../VarianceReport/VarianceModals/VarianceManager/VarianceManager';

import { useMilestonesQuery } from './hooks';
import Milestone from './Milestone';
import { styles } from './MilestonesStyles';

type MilestonesProps = {
  classes: Classes<typeof styles>;
};

export const Milestones: React.FC<MilestonesProps> = ({ classes }) => {
  const projectId = getProjectIdFromUrl();
  const sendAnalytics = useSendAnalytics();

  const {
    data: { project },
    loading: projectLoading,
  } = useProjectPropsQuery(projectId);
  const projectName = project?.name;
  const activeMilestoneId = project?.activeMilestone.id ?? '';

  const t = useContext(ProjectTermStore);
  const {
    loading: milestonesLoading,
    error,
    data: { milestones = [] } = {},
  } = useMilestonesQuery(projectId, true);

  // UNITS
  const {
    data: { getProjectUnits: enabledUnits = [] } = { getProjectUnits: [] },
    loading: unitsLoading,
  } = useGetProjectUnitsQuery(projectId, true);
  const selectedUnitIds = [GSF_ID, GSM_ID];
  const selectedUnits: Unit[] = selectedUnitIds
    .map((id: string) => enabledUnits.find((u: Unit) => u.id === id))
    .filter((u: Unit | undefined): u is Unit => u !== undefined);

  const userID = useCurrentUser().id;
  const { canView, canAdd, inPreviewMode } = usePermissions();
  const canViewDraftMilestones = canView(PermissionResource.DRAFT_MILESTONE);
  const canAddMilestone = canAdd(PermissionResource.MILESTONES);
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  // determine if atleast one milestone has a design phase assigned to it (used for design phase column display)
  const milestonesHasDesignPhase = milestones.some((m) => m.designPhase !== null);

  const [newMilestoneOpen, setNewMilestoneOpen] = useState(false);

  useEffect(() => {
    if (projectName) {
      document.title = `${projectName} - Milestones`;
    }
  }, [projectName]);

  const loading = projectLoading || milestonesLoading || unitsLoading;

  if (loading) return null;
  if (error) {
    return <FourOhFour message="Error loading milestones" />;
  }

  const varianceReportLink = shouldDisplayCosts && (
    <Button
      data-cy="variance-report-button"
      label="Variance Report"
      onClick={() => {
        // TODO: should rely on state instead of reactive var for opening variance report dialog
        openVarianceOnboarding(true);
        sendAnalytics(reportVariance(varianceEventTypes.VARIANCE_MENU_CTA));
      }}
      startIcon={<Compare className={classes.iconBarChart} />}
      type="tertiary"
    />
  );
  const addMilestoneButton = canAddMilestone && (
    <Button
      data-cy="new-milestone-button"
      disabled={newMilestoneOpen}
      label="New Milestone"
      onClick={() => setNewMilestoneOpen(true)}
      startIcon={<Add />}
      type="primary"
    />
  );

  const header = (
    <div className="flex flex-row items-center justify-between p-4">
      <header className="type-heading1">Milestones</header>
      <div className="flex">
        {varianceReportLink}
        {addMilestoneButton}
      </div>
    </div>
  );

  const content = (
    <div className={`${classes.tableGroup}`}>
      <div className={`${classes.header} ${classes.tableRow}`}>
        <div
          className={`${classes.tableCell} ${classes.cell} ${classes.header} ${classes.limitCell} ${classes.paddingLeftHeader}`}
        >
          Milestone Name
        </div>
        {milestonesHasDesignPhase && (
          <div
            className={`${classes.tableCell} ${classes.cell} ${classes.header} ${classes.noWrap} ${classes.limitCell}`}
          >
            Design Phase
          </div>
        )}
        <div
          className={`${classes.tableCell} ${classes.cell} ${classes.header} ${classes.noWrap} ${classes.smallCell}`}
        >
          Start Date
        </div>
        {shouldDisplayCosts && (
          <div
            className={`${classes.tableCell} ${classes.cell} ${classes.header} ${classes.limitCell} ${classes.alignRight}`}
          >
            Report
          </div>
        )}
        <div
          className={`${classes.tableCell} ${classes.cell} ${classes.header} ${classes.limitCell} ${classes.alignRight}`}
        >
          Items
        </div>
        {shouldDisplayCosts && (
          <div
            className={`${classes.tableCell} ${classes.cell} ${classes.header} ${classes.limitCell} ${classes.alignRight}`}
            data-cy="milestones-terminology-ESTIMATE"
          >
            {t.titleCase(TermKey.ESTIMATE)}
          </div>
        )}
        {shouldDisplayCosts && (
          <div
            className={`${classes.tableCell} ${classes.cell} ${classes.header} ${classes.limitCell} ${classes.alignRight}`}
            data-cy="milestones-terminology-TARGET"
          >
            {t.titleCase(TermKey.TARGET)}
          </div>
        )}
        {selectedUnits.map((unit: Unit) => (
          <div
            key={unit.id}
            className={`${classes.tableCell} ${classes.cell} ${classes.header} ${classes.noWrap} ${classes.limitCell} ${classes.alignRight}`}
            data-cy="milestones-selected-uom"
          >
            {unit.name}
          </div>
        ))}
        <div
          className={`${classes.tableCell} ${classes.cell} ${classes.header} ${classes.tinyCell}`}
        />
      </div>
      {milestones
        .filter(
          (m) =>
            !m.isDraft || (m.createdBy?.id === userID && !inPreviewMode) || canViewDraftMilestones
        )
        .map((milestone: Milestone) => (
          <Milestone
            key={milestone.id}
            activeMilestoneId={activeMilestoneId}
            displayDesignPhaseColumn={milestonesHasDesignPhase}
            milestone={milestone}
            projectId={projectId}
            selectedUnits={selectedUnits}
            shouldDisplayCosts={shouldDisplayCosts}
          />
        ))}
    </div>
  );

  return (
    <Card className={classes.card} elevation={0} square style={{ width: '100%', maxWidth: '100%' }}>
      {header}
      {content}
      <VarianceManager />
      <NewMilestoneDialog onClose={() => setNewMilestoneOpen(false)} open={newMilestoneOpen} />
    </Card>
  );
};

export default withStyles(styles)(Milestones);
