import * as d3 from 'd3';
import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

import useMemoWrapper from '../../useMemoWrapper';

type Data = { date: Date; value: number };

export default function TimelinePath(props: {
  data: Data[];
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  stroke?: string;
  strokeDasharray?: string;
  strokeWidth?: number;
}) {
  const line = useMemoWrapper(getLineCoords, props.data, props.x, props.y) ?? undefined;
  if (!props.data?.length) return null;
  return (
    <path
      className={props.stroke ?? 'stroke-type-primary'}
      d={line}
      data-cy="path-line"
      fill="none"
      strokeDasharray={props.strokeDasharray ?? 'none'}
      strokeWidth={props.strokeWidth ?? 1}
    />
  );
}

const getLineCoords = (
  data: Data[],
  x: ScaleTime<number, number> | ScalePoint<Date>,
  y: ScaleLinear<number, number>
) =>
  d3
    .line<Data>()
    .x((d) => x(d.date) ?? 0)
    .y((d) => y(d.value))(data);
