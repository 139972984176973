import { FC } from 'react';

import {
  ItemShareEvent,
  ItemShareKey,
  itemShareEvent,
} from '../../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import SharedUsersState from '../../../dragon-scales/SharedUsersState/SharedUsersState';
import { Chip, Tooltip } from '../../../scales';

type ItemsListItemPrivateBadgeProps = {
  analyticsKey: ItemShareKey;
  hideBadge?: boolean;
  sharedPrivateItemUsers?: Pick<User, 'id' | 'name'>[];
  showDraftToolTip?: boolean;
};

const ItemsListItemPrivateBadge: FC<ItemsListItemPrivateBadgeProps> = ({
  analyticsKey,
  hideBadge = true,
  sharedPrivateItemUsers,
  showDraftToolTip = true,
}) => {
  const sendAnalytics = useSendAnalytics();
  const onDraftTooltip = () => {
    sendAnalytics(itemShareEvent(analyticsKey, ItemShareEvent.TOOLTIP, { type: 'draft' }));
  };
  const onShareTooltip = () => {
    sendAnalytics(itemShareEvent(analyticsKey, ItemShareEvent.TOOLTIP, { type: 'share' }));
  };
  const draftTooltip = !hideBadge && (
    <Tooltip
      content="Draft item with limited availability to the team. Shareable with select teammates."
      isDisabled={!showDraftToolTip}
      onOpen={onDraftTooltip}
    >
      <div className="pb-[2px] pr-[2px] pt-[2px]">
        <Chip text="Draft" />
      </div>
    </Tooltip>
  );
  const shareTooltip = (
    <SharedUsersState onTooltip={onShareTooltip} sharedUsers={sharedPrivateItemUsers} />
  );
  return (
    <div className="flex items-center" data-cy="private-item-badge">
      {draftTooltip}
      {shareTooltip}
    </div>
  );
};

export default ItemsListItemPrivateBadge;
