import { useMemo } from 'react';

import { SortDirection } from '../../../../generated/graphql';
import useCollaboratorsQuery from '../../../../hooks/useCollaboratorsQuery';
import { useProjectCompaniesQuery } from '../../../../hooks/useProjectCompaniesQuery';
import { SortManager } from '../../../scales';
import { CompaniesTableColumns } from '../constants';
import { TeamCompany } from '../types';

export default function useCompanies(
  projectID: UUID,
  searchTerm: string,
  sortState: SortManager['sortState']
): TeamCompany[] {
  const { companies } = useData(projectID);

  const filteredCompanies = useMemo(() => {
    if (!searchTerm) return companies;

    return companies.filter((c) => {
      return [c.company.name, c.company.type, c.company.domain ?? '']
        .join(' ')
        .match(new RegExp(searchTerm, 'i'));
    });
  }, [companies, searchTerm]);

  const sortedCompanies = useMemo(() => {
    return filteredCompanies.sort((_a, _b) => {
      const a = sortState.sortDirection === SortDirection.SORT_ASCENDING ? _a : _b;
      const b = sortState.sortDirection === SortDirection.SORT_ASCENDING ? _b : _a;
      const opts = { ignorePunctuation: true, numeric: true };

      switch (sortState.sortKey) {
        case CompaniesTableColumns.COMPANY:
          return a.company.name.localeCompare(b.company.name, undefined, opts);
        case CompaniesTableColumns.DOMAIN:
          return (a.company.domain ?? '').localeCompare(b.company.domain ?? '', undefined, opts);
        case CompaniesTableColumns.PEOPLE:
          return a.numCollaborators > b.numCollaborators ? 1 : -1;
        case CompaniesTableColumns.ROLE:
          return (a.role ?? '').localeCompare(b.role ?? '', undefined, opts);
        default:
          throw new Error(`Unexpected sortKey: ${sortState.sortKey}`);
      }
    });
  }, [filteredCompanies, sortState]);

  return sortedCompanies;
}

function useData(projectID: UUID) {
  const collaboratorsQueryResult = useCollaboratorsQuery(projectID);
  const collaborators =
    collaboratorsQueryResult.data?.collaborators ??
    collaboratorsQueryResult.previousData?.collaborators;

  const companiesQueryResult = useProjectCompaniesQuery(projectID);
  const companies =
    companiesQueryResult.data?.projectCompanies ??
    companiesQueryResult.previousData?.projectCompanies;

  const companiesWithCollaboratorCounts = useMemo(
    () =>
      (companies ?? []).map((c) => ({
        ...c,
        numCollaborators: (collaborators ?? []).filter((collaborator) =>
          collaborator.user.email.includes(`@${c.company.domain}`)
        ).length,
      })),
    [collaborators, companies]
  );

  return { collaborators: collaborators ?? [], companies: companiesWithCollaboratorCounts };
}
