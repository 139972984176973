import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { fromNow } from '../../../../../utilities/dates';

import styles from './LastUpdatedCellStyles';

export default withStyles(styles)(
  ({ classes, project }: { classes: Classes<typeof styles>; project: ForecastingProject }) => (
    <div className={classes.container}>
      {project.updatedAt ? fromNow(project.updatedAt) : ''}{' '}
      {project.updatedBy ? `by ${project.updatedBy}` : ''}
    </div>
  )
);
