import { useMemo, useState } from 'react';

import { ITEMS } from '../../constants';
import { getProjectIdFromUrl } from '../../utilities/url';
import { SetSettingsFunctionType, usePersistentStates } from '../../utilities/urlState';

import { ITEMSLIST_DEFAULTS, ItemsListSettings } from './ItemsListUtils';

export type ItemsListSettingsManager = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  addSettingsChange: (param: any, value: any) => void;
  persistAddedSettingsChanges: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  persistSettingsChange: (param: any, value: any) => void;
  setSettings: SetSettingsFunctionType;
  settings: ItemsListSettings;
};

export const useItemsListSettings = (): ItemsListSettingsManager => {
  const { location } = window;
  const projectId = getProjectIdFromUrl();
  const { hash } = location;
  const param = useMemo(() => `${projectId} - Items - `, [projectId]);
  // Main Settings Hook
  const [settings, setSettings] = usePersistentStates(
    location,
    ITEMS,
    ITEMSLIST_DEFAULTS,
    param,
    undefined,
    hash
  );
  // Delayed Settings Hook (to avoid requeries during incomplete changes)
  const [unpersistedSettings, setUnpersistedSettings] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const addSettingsChange = (param: any, value: any) => {
    setUnpersistedSettings({ ...unpersistedSettings, [param]: value });
  };
  // Immediate Settings Hook (careful - will requery on each change)
  const persistAddedSettingsChanges = () => {
    if (!unpersistedSettings || Object.keys(unpersistedSettings).length !== 0) return;
    setSettings(unpersistedSettings);
    setUnpersistedSettings({});
  };
  // Persist Delayed Settings Hook
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const persistSettingsChange = (param: any, value: any) => {
    setSettings({ ...unpersistedSettings, [param]: value });
    setUnpersistedSettings({});
  };
  return {
    addSettingsChange,
    persistAddedSettingsChanges,
    persistSettingsChange,
    setSettings,
    settings,
  };
};
