import {
  ForwardRefRenderFunction,
  RefObject,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { FixedSizeList as List } from 'react-window';

import { ASSETS_PDF_ON_SCROLL_FINISH } from '../../../../../actions/actionTypes';
import useOnScrollFinishAnalytics from '../../../../../hooks/useOnScrollFinishAnalytics';
import { getScrollPageIndex } from '../utils';

import AssetsPDFNavigation from './AssetsPDFNavigation';

type OnScrollElement = { onScroll: (offset: number) => void };

export type HTMLDivElementScroll = HTMLDivElement & OnScrollElement;

type AssetsPDFNavigationDataProps = {
  canvasRef: RefObject<HTMLDivElement>;
  isFullWidth: boolean;
  onFullWidth: () => void;
  pagesTotal: number;
  listRef: RefObject<List>;
  maxHeight: number;
};

const AssetsPDFNavigationData: ForwardRefRenderFunction<
  HTMLDivElementScroll,
  AssetsPDFNavigationDataProps
> = ({ canvasRef, isFullWidth, onFullWidth, pagesTotal, listRef, maxHeight }, ref) => {
  useOnScrollFinishAnalytics(canvasRef, ASSETS_PDF_ON_SCROLL_FINISH);
  const [pageIndex, setPageIndex] = useState<number>(0);

  const onScroll = (offset: number) => {
    const index = getScrollPageIndex(offset, maxHeight);
    setPageIndex(index);
  };

  useImperativeHandle(ref, () => ({ onScroll } as HTMLDivElementScroll));

  const setPageIndexScroll = (index: number) => {
    setPageIndex(index);
    listRef.current?.scrollToItem(index);
  };

  return (
    <AssetsPDFNavigation
      isFullWidth={isFullWidth}
      onFullWidth={onFullWidth}
      pageIndex={pageIndex}
      pagesTotal={pagesTotal}
      setPageIndex={setPageIndexScroll}
    />
  );
};

export default forwardRef<HTMLDivElementScroll, AssetsPDFNavigationDataProps>(
  AssetsPDFNavigationData
);
