import { FC } from 'react';

import { ProjectCostBreakdownReport } from '../../generated/graphql';

import ProjectBreakdownReportEmptyState from './ProjectBreakdownReportEmptyState';
import ProjectCostBreakdownReportChart from './ProjectCostBreakdownReportChart';
import ProjectCostBreakdownReportHeader from './ProjectCostBreakdownReportHeader';
import ProjectCostBreakdownReportInputs from './ProjectCostBreakdownReportInputs';

type Props = {
  activeMilestoneID?: UUID;
  displayTip: boolean;
  exportReport: () => void;
  milestoneID: UUID;
  milestoneName: string;
  milestones: Pick<Milestone, 'id' | 'name' | 'activeEstimateID'>[];
  projectCostBreakdownReport?: ProjectCostBreakdownReport;
  projectID: UUID;
  setMilestoneID: (id: UUID) => void;
  setSettings: (newSettings: ProjectCostBreakdownSettings) => void;
  settings: ProjectCostBreakdownSettings;
  print?: () => void;
};

const ProjectBreakdownReport: FC<Props> = ({
  activeMilestoneID,
  displayTip,
  exportReport,
  milestoneID,
  milestoneName,
  milestones,
  projectCostBreakdownReport,
  projectID,
  setMilestoneID,
  setSettings,
  settings,
  print,
}) => {
  const selectedMilestone = milestones.find((m) => m.id === milestoneID);
  const showEmptyState =
    !selectedMilestone?.activeEstimateID ||
    !projectCostBreakdownReport ||
    projectCostBreakdownReport.total === 0;
  return (
    <div className="min-w-fit">
      {!!print && (
        <ProjectCostBreakdownReportHeader
          exportReport={exportReport}
          milestoneID={milestoneID}
          milestoneName={milestoneName}
          print={print}
          projectID={projectID}
          setSettings={setSettings}
          settings={settings}
        />
      )}
      <ProjectCostBreakdownReportInputs
        activeMilestoneID={activeMilestoneID}
        displayTip={displayTip}
        milestoneID={milestoneID}
        milestones={milestones}
        print={print}
        projectID={projectID}
        setMilestoneID={setMilestoneID}
        setSettings={setSettings}
        settings={settings}
      />
      {showEmptyState ? (
        <ProjectBreakdownReportEmptyState milestoneID={milestoneID} projectID={projectID} />
      ) : (
        <ProjectCostBreakdownReportChart
          milestoneName={milestoneName}
          projectCostBreakdownReport={projectCostBreakdownReport}
        />
      )}
    </div>
  );
};

export default ProjectBreakdownReport;
