import { ReactNode } from 'react';

import Cell from './Cell';

type Props = {
  bold?: boolean;
  compact?: boolean;
  'data-cy'?: string;
  text?: string | null;
};

export default function TextCell(props: Props) {
  let content: ReactNode = props.text;
  if (props.bold) content = <strong>{content}</strong>;

  return (
    <Cell compact={props.compact} data-cy={props['data-cy']}>
      <div className={props.compact ? 'type-table-text' : 'type-body1'}>{content}</div>
    </Cell>
  );
}
