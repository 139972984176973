import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { VISIBILITY_DRAFT_ITEM } from '../../../constants';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { pluralizeString } from '../../../utilities/string';
import { isPrivateVisibility } from '../../Items/ItemsUtils';
import { Chip } from '../../scales';
import {
  isEventDescriptionCopiedItemLike,
  isEventDescriptionItemLike,
  isEventDescriptionPrivateItemLike,
  isEventDescriptionText,
} from '../utils';

type Props = {
  description: SimpleEventDescription[];
  isItemHistory?: boolean;
  eventID: string;
  projectID: UUID;
};
const EventDescription = ({ description, isItemHistory = false, eventID, projectID }: Props) => {
  const draftNameTag = VISIBILITY_DRAFT_ITEM;
  return (
    <div
      key={`${eventID}-description`}
      className={
        !isItemHistory
          ? 'flex overflow-hidden overflow-ellipsis whitespace-nowrap type-body1'
          : 'type-body2'
      }
    >
      {description.map((d, i) => {
        // regular text descriptions
        if (isEventDescriptionText(d) && d.text) {
          // eslint-disable-next-line react/no-array-index-key -- necessary for identical descriptions
          return <Fragment key={`${eventID}-${d.text}-${i}`}>{d.text}</Fragment>;
        }

        if (isEventDescriptionItemLike(d)) {
          // item / option links
          const itemName = d.itemLike.name || 'draft item';
          const description = (
            <ItemLink
              key={`${eventID}-${d.itemLike.id}`}
              itemID={d.itemLike.id}
              itemNumber={d.itemLike.number || ''}
              projectID={projectID}
              text={
                isPrivateVisibility(d.itemLike.visibility) && !d.itemLike.number
                  ? itemName
                  : `#${d.itemLike.number}`
              }
            />
          );
          if (isPrivateVisibility(d.itemLike.visibility) && !isItemHistory) {
            return (
              <div
                key={`${eventID}-${d.itemLike.id}`}
                className="flex items-center pl-1"
                placeholder=""
              >
                <Chip text="Draft" />
                {description}
              </div>
            );
          }
          return description;
        }

        if (isEventDescriptionCopiedItemLike(d)) {
          // copied item link, which is a slight variation from the item/option link above
          return (
            <ItemLink
              key={`${eventID}-${d.copiedItemLike.id}`}
              disabled={!d.copiedItemLike.hasAccess}
              itemID={d.copiedItemLike.id}
              itemNumber={d.copiedItemLike.number}
              projectID={d.copiedItemLike.projectID}
              text={d.copiedItemLike.name}
            />
          );
        }

        // draft items
        if (isEventDescriptionPrivateItemLike(d)) {
          const countString = d.count === 1 ? 'a' : `${d.count}`;
          const privateLabel = draftNameTag.toLowerCase();
          const optionLabel = pluralizeString('option', d.count);
          const countDescription = ` ${countString} ${privateLabel} ${optionLabel}`;
          return (
            <div
              key={`${eventID}-draft-${countDescription}`}
              className="mx-1 inline-flex items-end"
            >
              <div>{countDescription}</div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

const ItemLink = (props: {
  itemID: UUID;
  projectID: UUID;
  disabled?: boolean;
  text: string;
  itemNumber: string;
}) => {
  if (props.disabled) return <div className="mx-1 inline-flex items-end">{props.text}</div>;
  return (
    <Link
      className="mx-1 inline-flex items-end text-type-link"
      data-cy={`itemsQuickLink-${props.itemNumber}`}
      to={generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
        itemId: props.itemID,
        projectId: props.projectID,
      })}
    >
      {props.text}
    </Link>
  );
};

export default EventDescription;
