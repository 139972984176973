import { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { withStyles } from '@material-ui/core/styles';

import { InputVariant, SelectVariant } from '../../../api/gqlEnums';
import { Uncategorized } from '../../../constants';
import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { categoryLabel } from '../../../utilities/string';
import { PortalPositional } from '../../Portal/PortalPositional/PortalPositional';
import SelectCategory from '../SelectCategory/SelectCategory';
import {
  SelectCategoryValue,
  selectCategoryDisplayName,
} from '../SelectCategory/SelectCategoryUtils';

import SelectCategoryChip from './SelectCategoryChip';
import styles from './SelectCategoryChipInputStyles';

interface SelectCategoryChipInputSingleProps {
  categorizations: Categorization[];
  classes: Classes<typeof styles>;
  disabled?: boolean;
  id: string;
  includeUncategorizedCategory?: boolean;
  isCompact?: boolean;
  placeholder?: string;
  selectedCategory?: Category;
  /** setCategory returns an explicit Uncategorized category */
  setCategory: (categories: SelectCategoryValue) => void;
  theme: KomodoTheme;
}

const SelectCategoryChipInputSingle: FC<SelectCategoryChipInputSingleProps> = ({
  categorizations,
  classes,
  disabled,
  id,
  includeUncategorizedCategory,
  isCompact = true,
  placeholder: defaultPlaceholder,
  selectedCategory,
  setCategory,
  theme,
}) => {
  // STATE
  const [inputValue, setInputValue] = useState<string>('');
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const buttonRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  // UI GENERATORS
  // title
  const title = selectedCategory ? selectCategoryDisplayName(selectedCategory) : '';
  // styles
  const disabledClass = disabled ? classes.disabled : '';
  // id
  const firstCategorization: Categorization = categorizations[0];
  const inputId = `category-input-${
    firstCategorization &&
    firstCategorization.name &&
    firstCategorization.name.replace(/\s+/g, '-').toLowerCase()
  }`;
  // input placeholder
  const placeholder = disabled ? Uncategorized : defaultPlaceholder || Uncategorized;

  useEffect(() => {
    setInputValue(title);
  }, [title]);

  // show empty input OR Select dropdown logic
  // EMPTY SELECT
  const select = (
    // SELECT
    <PortalPositional>
      {(boundingRect: DOMRect) => {
        const offsetTop = 40;
        const element: HTMLElement | null = document!.getElementById(id);
        const width = (element && element.offsetWidth) || theme.portal.defaultWidth;
        const portalStyles: CSSProperties = {
          position: 'fixed',
          left: `${boundingRect.x}px`,
          top: `${boundingRect.y - offsetTop}px`,
          width: `${width}px`,
          zIndex: 999999,
        };
        return (
          <div
            className={`${classes.containerOuter} ${
              isCompact ? classes.containerOuterCompact : ''
            }`}
            style={portalStyles}
          >
            <div className={classes.containerSelect}>
              <SelectCategory
                addCategories={(categories) => {
                  setCategory(categories[0]);
                  // set the input value to category.name just in case there is bogus stuff
                  setInputValue(categories[0].name);
                  setShowSelect(false);
                  if (buttonRef && buttonRef.current) {
                    buttonRef.current.blur();
                    // ACCESSIBILITY HACK: focus after blurring so you can keep tabbing and not trigger onClick on the button
                    setTimeout(() => {
                      if (buttonRef && buttonRef.current) {
                        buttonRef.current.focus();
                      }
                    }, 200);
                  }
                }}
                categorizationID={firstCategorization.id}
                includeUncategorizedCategory={includeUncategorizedCategory}
                inputValue={inputValue}
                inputVariant={InputVariant.CHIP_INPUT}
                noun="category"
                onSubmit={() => {
                  setShowSelect(false);
                  if (buttonRef && buttonRef.current) {
                    buttonRef.current.blur();
                    // ACCESSIBILITY HACK: focus after blurring so you can keep tabbing and not trigger onClick on the button
                    setTimeout(() => {
                      if (buttonRef && buttonRef.current) {
                        buttonRef.current.focus();
                      }
                    }, 200);
                  }
                }}
                placeholder={placeholder}
                removeCategories={() => {}}
                selectedCategories={[]}
                selectVariant={SelectVariant.SINGLE}
              />
            </div>
          </div>
        );
      }}
    </PortalPositional>
  );

  const onChip = () => {
    buttonRef.current.blur();
    if (!disabled) {
      // close the select
      setShowSelect(true);
      // remove category
      if (selectedCategory) {
        // reset the input value
        const categoryText = categoryLabel(
          selectedCategory.name,
          selectedCategory.number,
          selectedCategory.categorization
        );
        setInputValue(categoryText);
      }
    }
  };

  const input = (
    // SELECTED CATEGORIES DISPLAY
    <div className={`${classes.containerOuter} ${disabledClass}`}>
      <SelectCategoryChip
        buttonRef={buttonRef}
        categoryString={title}
        disabled={disabled}
        hasSelectedCategory={!!selectedCategory}
        inputId={inputId}
        isCompact={isCompact}
        keyId={id}
        onClick={onChip}
        placeholder={placeholder}
      />
    </div>
  );

  return (
    <div>
      {input} {showSelect && select}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(SelectCategoryChipInputSingle);
