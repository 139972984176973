import { FC, ReactElement } from 'react';

import {
  ACCEPTED,
  INCORPORATED,
  NOT_APPLICABLE,
  NOT_CHOSEN,
  PENDING,
  REJECTED,
} from '../../../../constants';
import { Status } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { getItemStatusLabel } from '../../../../utilities/item-status';
import { pluralizeCountString } from '../../../../utilities/string';
import {
  getAcceptedIncorporatedOption,
  getStatusIsAcceptedIncorporated,
  isPrivateVisibility,
} from '../../../Items/ItemsUtils';
import { DISABLE_ITEM_STATUS_TOOLTIP_NEW } from '../../../Items/ItemVisibilityToggle/ItemVisibilityToggleUtils';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import JoinSelect, { getEntry } from '../../../Select/JoinSelect/JoinSelect';
import ItemsIconsMap from '../ItemsIconsMap';
import ItemsIconsStyles from '../ItemsIconsStyles';

type Variant = keyof ReturnType<typeof ItemsIconsStyles>;

type Props = {
  availableStates: string[];
  classes: Classes<typeof ItemsIconsStyles>;
  editable: boolean;
  hideIcon?: boolean;
  item: ItemDataQueryItem;
  options: Pick<Option, 'number' | 'status'>[];
  status: string;
  updateStatus: (status: Status) => void;
  variant?: Variant;
};

const ItemsIconsStatusSelect: FC<Props> = ({
  availableStates = [],
  classes,
  editable,
  hideIcon,
  item,
  options,
  status,
  updateStatus,
  variant = 'accepted',
}) => {
  // VARIABLES
  const { visibility } = item;
  const hasParent = !!(item as Option).parent;
  const optionsLength = options.length;
  function getStateAvailability(state: string): boolean {
    return availableStates.includes(state);
  }
  // used to generate ALL status options in the dropdown, regardless of availability
  const statusArray = [
    NOT_APPLICABLE,
    REJECTED,
    availableStates.includes(NOT_CHOSEN) ? NOT_CHOSEN : PENDING,
    ACCEPTED,
    INCORPORATED,
  ];

  const acceptedOrIncorporatedOption = getAcceptedIncorporatedOption(options);
  const hasAcceptedOrIncorporatedSiblingOption =
    !availableStates.includes(ACCEPTED) &&
    !availableStates.includes(INCORPORATED) &&
    options.length === 0; // you can only accept one option at this time and options can't have options

  // HELPER FUNCTIONS
  function getAcceptedSiblingOptionLogicForState(state: string): boolean {
    // we want to disable these options if there is an accepted sibling option
    return hasAcceptedOrIncorporatedSiblingOption ? getStatusIsAcceptedIncorporated(state) : false;
  }
  // returns true if state is available
  // returns a disabled class if state is not available
  // & a colored class if the state is available
  function getStateAvailabilityColorClass(state: string): string {
    const isNotAvailable =
      !getStateAvailability(state) || getAcceptedSiblingOptionLogicForState(state);
    return isNotAvailable
      ? `${classes.disabledState}`
      : `${classes[state.toLowerCase() as StatusClass]}`;
  }
  // GENERATORS
  function generateMenuItemSubtitle(statusToCheck: string): string {
    if (!hasParent) {
      // its an Item
      if (acceptedOrIncorporatedOption && getStatusIsAcceptedIncorporated(statusToCheck)) {
        return ` (#${acceptedOrIncorporatedOption.number})`;
      }
      if (status === PENDING && optionsLength > 0) {
        return ` (${pluralizeCountString('option', optionsLength)})`;
      }
    }
    return '';
  }

  const generateTitle = (statusToCheck: string): string =>
    `${getItemStatusLabel(statusToCheck)}${generateMenuItemSubtitle(statusToCheck)}`;

  // MAPS & SWITCH STATEMENTS
  function getStatusIconSvg(status: string): ReactElement {
    return (
      <ItemsIconsMap
        stateAvailabilityClass={getStateAvailabilityColorClass(status)}
        status={status}
        variant={variant}
      />
    );
  }

  const entries = statusArray.map((state) => {
    // generate hover title for grayed out options in select
    const entryTitle =
      acceptedOrIncorporatedOption && ![ACCEPTED, INCORPORATED].includes(state)
        ? `Option #${acceptedOrIncorporatedOption.number} is ${getItemStatusLabel(
            acceptedOrIncorporatedOption.status
          )}`
        : '';
    const disabled = !getStateAvailability(state) || getAcceptedSiblingOptionLogicForState(state);
    return getEntry(
      state,
      generateTitle(state),
      disabled,
      hideIcon ? null : getStatusIconSvg(state),
      entryTitle
    );
  });

  const isPrivate = isPrivateVisibility(visibility);
  const isDisabled = !editable || isPrivate;
  const draftItemTooltip = DISABLE_ITEM_STATUS_TOOLTIP_NEW;

  return (
    <NormalTooltip title={isPrivate ? draftItemTooltip : ''}>
      <JoinSelect
        cySelect="statusIcon-statusIconSelect"
        disabled={isDisabled}
        entries={entries}
        hidePrint={false}
        isCompact={isDisabled}
        onChange={(stateSelection) => {
          if (!stateSelection) return;
          // if state is available to the item/user, then update
          if (getStateAvailability(stateSelection)) {
            updateStatus(stateSelection);
          }
        }}
        value={status}
      />
    </NormalTooltip>
  );
};

export default withStyles(ItemsIconsStyles)(ItemsIconsStatusSelect);
