import { projectSettingsVar } from '../api/apollo/reactiveVars';
import { FieldType } from '../api/gqlEnumsBe';
import { Scale } from '../enums';

/*
DATA 
- currencyEntryData
- moneyUnits

HELPER FUNCTIONS
- constructCurrencyEntries
- formatCommas
- roundToIndex
- simpleCost
- formatCost
- isCurrency
- isValidCost
- scrubNumberString

*/

export const MAX_SHORT_DIGITS = 3;

type CurrencySetting = {
  locale: string;
  description: string;
  symbol: string; // used for labels: symbol per X, eg $/GSF
};

const defaultCurrency = { locale: 'en-US', description: '$ USD US Dollar', symbol: '$' };

const currencyFormatMap = new Map<string, CurrencySetting>([
  ['USD', defaultCurrency], // US
  ['EUR', { locale: 'en-EU', description: '€ EUR Euros', symbol: '€' }], // austria might also be a good choice de-AT
  ['BRL', { locale: 'pt-BR', description: 'R$ BRL Brazilian Real', symbol: 'R$' }], // Brazil (Portuguese)
  ['CAD', { locale: 'en-CA', description: '$ CAD Canadian Dollar', symbol: '$' }], // Canada
  ['DKK', { locale: 'da-DK', description: 'kr. DKK Danish Krone', symbol: 'kr.' }], // Denmark
  ['JPY', { locale: 'ja-JP', description: '¥ JPY Japanese Yen', symbol: '￥' }], // Japan
  ['MXN', { locale: 'es-MX', description: '$ MXN Mexican Peso', symbol: '$' }], // Mexico
  ['NOK', { locale: 'nb-NO', description: 'kr NOK Norwegian Krone', symbol: 'kr' }], // Norway
  ['KRW', { locale: 'ko-KR', description: '₩ KRW South Korean Won', symbol: '₩' }], // Korea
  ['SEK', { locale: 'sv-SE', description: 'kr SEK Swedish Krona', symbol: 'kr' }], // Sweden
  ['CHF', { locale: 'fr-CH', description: 'Fr. CHF Swiss Franc', symbol: 'CHF' }], // Switzerland
]);

// Constructing entries for the Select currency dropdown
export const constructCurrencyEntries = () =>
  Array.from(currencyFormatMap).map(([id, settings]) => ({
    id,
    label: settings.description,
  }));

export const showCurrencyDescription = (iso: string) => {
  const matchedEntry = currencyFormatMap.get(iso) || defaultCurrency;
  return matchedEntry.description;
};

// CURRENCY FORMATTING
export const getCurrencySymbol = (currency?: string) =>
  currency
    ? currencyFormatMap.get(currency)?.symbol
    : currencyFormatMap.get(projectSettingsVar().CURRENCY)?.symbol;

const getCostFormatter = (cents: number, roundingPrecision: number, settings: CostFormatting) => {
  const currencySettings = settings?.settingsOverride?.CURRENCY || projectSettingsVar().CURRENCY;
  const scale = settings.scale ?? defaultCostFormatting.scale;
  const isZero = cents === 0;

  // the min is 0 if we are not showing cents
  const minimumFractionDigits =
    (settings.showCents && !isZero) ||
    (isZero && settings.showZeroCents) ||
    (settings.rounded && roundingPrecision < 0)
      ? 2
      : 0;

  const signDisplay = settings.signed && !isZero ? 'always' : undefined;

  // the max decimal digits is 2 if we are showing cents or are abbreviating costs
  let maximumFractionDigits = minimumFractionDigits;
  if (settings.showCents) {
    maximumFractionDigits = 2;
  }
  if (settings.showCents && cents > 0 && cents < scale / 100) {
    maximumFractionDigits = Math.ceil(Math.log10(scale / cents));
  }
  if (settings.showAllDigits) {
    maximumFractionDigits = Math.log10(scale);
  }

  // the max decimal digits can't be smaller than the minumum or the app crashes
  if (maximumFractionDigits < minimumFractionDigits) maximumFractionDigits = minimumFractionDigits;

  // used for abbreviated values
  const shouldBeShort = settings.short && Math.abs(cents) >= 10000 * 100;
  const maximumSignificantDigits = shouldBeShort ? MAX_SHORT_DIGITS : undefined;
  const notation = shouldBeShort ? 'compact' : undefined;

  const { locale } = currencyFormatMap.get(currencySettings) || {};
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencySettings,
    minimumFractionDigits,
    maximumFractionDigits,
    signDisplay,
    notation,
    maximumSignificantDigits,
  });
};

// VALIDATION
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const isCurrency = (costString: any) => {
  if (!costString || typeof costString !== 'string') return false;

  // remove currency symbols $, €, etc  or parentheses for negatives
  // or commas, or spaces, and see if the remaning chars is a number
  const currencySymbol = getCurrencySymbol();
  const re = new RegExp(`\\(|\\)|\\,|\\s|-|−|\\${currencySymbol}`, 'gi');
  const parsed = costString.replace(re, '');

  const number = Number(parsed);
  return !Number.isNaN(number);
};

// format commas according to the locale
export const formatCommas = (
  numberString: string | number,
  overrideLocale?: string,
  overrideOptions?: Intl.NumberFormatOptions
) => {
  const locale = overrideLocale || getLocale();
  return Number(numberString).toLocaleString(locale, overrideOptions);
};

// FORMATTING
export const simpleCost = (v: number) => v / 100;

export type CostFormatting = {
  scale?: number;
  showAllDigits?: boolean;
  showCents?: boolean; // true by default
  showZeroCents?: boolean;
  showCurrencySymbol?: boolean; // true by default
  short?: boolean;
  rounded?: boolean;
  signed?: boolean;

  // These overrides are for projects list / insights where there are multple projects
  settingsOverride?: ProjectSettingStore;
};

const defaultCostFormatting = {
  scale: Scale.CURRENCY,
  showAllDigits: false,
  showCents: true,
  showCurrencySymbol: true,
  showZeroCents: false,
  short: false,
  rounded: false,
  signed: false,
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const formatCost = (input: any, inputSettings?: CostFormatting): string => {
  // check if the input is a valid cost
  if (!isValidCost(input)) return input?.toString() ?? '';

  // convert the input to a string if it isn't already one
  let stringCents = input.toString();
  if (typeof input === 'number') {
    stringCents = String(Math.round(input));
  }
  if (!stringCents || !stringCents.length) return '';

  // merge the input settings with the default settings
  const settings: CostFormatting = { ...defaultCostFormatting, ...inputSettings };

  // round if necessary
  let value = getIntValue(stringCents);

  // if we're rounding, get the setting from either the
  // project settings or from the override
  const roundingPrecision =
    settings?.settingsOverride?.ROUNDING_PRECISION || projectSettingsVar().ROUNDING_PRECISION;

  // get the showCents setting based on inputs
  settings.showCents = computeShowCentsSetting(value, settings, roundingPrecision);

  // round the value if necessary
  let prefix = '';
  let isCents = true;
  [value, prefix, isCents] = roundCurrency(value, roundingPrecision, settings);

  // if the cost is abbreviated, then we don't need to convert to dollars
  // const signedValue = stringCents[0] === '-' ? value * -1 : value;
  const dollars = isCents ? value / (settings.scale || defaultCostFormatting.scale) : value;

  // format the cost
  const formatter = getCostFormatter(value, roundingPrecision, settings);
  let formattedCost = formatter.format(dollars);

  // remove the symbol if necessary
  if (!settings.showCurrencySymbol) {
    const symbol = getCurrencySymbol(settings?.settingsOverride?.CURRENCY);
    if (symbol) formattedCost = formattedCost.replace(symbol, '').trim();
  }

  // add units and prefix
  return addPrefixToValue(formattedCost, prefix);
};

// right now the prefix is only `<` and
// it goes before the sign
const addPrefixToValue = (value: string, prefix: string) => {
  if (hasSign(value) && prefix) return `${value[0]} ${prefix}${value.slice(1)}`;
  if (prefix) return `${prefix}${value}`;
  return value;
};

export const getCurrencyScale = (type: FieldType | string) =>
  type === FieldType.CURRENCY_9 ? Scale.CURRENCY_9 : Scale.CURRENCY;

// get the integer value of the input.
const getIntValue = (stringCents: string, abs = true) => {
  if (abs) return parseInt(stringCents, 10);
  return parseInt(stringCents, 10);
};

const roundCurrency = (
  cents: number,
  roundingPrecision: number,
  settings: CostFormatting
): [number, string, boolean] => {
  if (!settings.rounded) return [cents, '', true];

  // check if the absolute value is less than 1000 * rounding setting
  // ie if rounding is the default 3 digits / 1k, check if it's less than 1k.
  if (Math.abs(cents) > 0 && Math.abs(cents) < 100 * 10 ** roundingPrecision) {
    let roundedCents = 10 ** roundingPrecision;
    // make it negative if need be
    roundedCents = cents < 0 ? roundedCents * -1 : roundedCents;
    const prefix = '<';
    return [roundedCents, prefix, false];
  }
  const roundedCents = Math.round(cents);
  const roundedDollars = roundToIndex(roundedCents, roundingPrecision, settings) || 0;
  return [roundedDollars, '', false];
};

// some settings will override the showCents value
const computeShowCentsSetting = (
  cents: number,
  settings: CostFormatting,
  roundingPrecision: number
) => {
  const scale = settings.scale || defaultCostFormatting.scale;
  // when abbreviating costs, if below $100 then show full cost with cents
  // otherwise, don't show cents
  const oneHundredDollars = 100 * scale;
  if (settings.short) return Math.abs(cents) < oneHundredDollars;

  // if we're rounding to a dollar (0) or more then don't show cents
  if (settings.rounded) return roundingPrecision < 0;

  return settings.showCents;
};

const roundToIndex = (
  cents: number,
  indices: number,
  { scale = defaultCostFormatting.scale }: CostFormatting
) => {
  const dollars = cents / scale;
  const factor = 10 ** indices;
  return Math.round(dollars / factor) * factor;
};

const hasSign = (value: string) => value && value.length && (value[0] === '-' || value[0] === '+');

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const isValidCost = (costString: any) => {
  if (typeof costString === 'number') return true;
  if (typeof costString === 'string') {
    const scrubbed = scrubNumberString(costString, true);
    return !Number.isNaN(scrubbed);
  }
  return false;
};

export const isNegative = (costString: string, currencyOverride?: string) => {
  const decimalSeparator = getDecimalSeparator(true, currencyOverride);
  const thousandsSeparator = getThousandSeparator(true, currencyOverride);

  const numericExp = new RegExp(`[0-9'${decimalSeparator}${thousandsSeparator}]`, 'g');
  const numericMatch = costString.match(numericExp);
  // if a number is in parenthases then it's negative

  const hasNegativeSymbol =
    (costString.includes('-') || costString.includes('−')) &&
    numericMatch !== null &&
    costString.indexOf('-') < costString.indexOf(numericMatch[0]);

  const includesParenthasis = hasParenthases(costString, decimalSeparator, thousandsSeparator);

  return hasNegativeSymbol || includesParenthasis;
};

const hasParenthases = (
  costString: string,
  decimalSeparator: string,
  thousandsSeparator: string
) => {
  const parenExp = new RegExp(`\\([${decimalSeparator}${thousandsSeparator}\\d]*\\)`, 'g');
  const parenMatch = costString.match(parenExp);

  return (
    parenMatch !== null &&
    parenMatch[0][0] === '(' &&
    parenMatch[0][parenMatch[0].length - 1] === ')'
  );
};

const getLocale = (currencyOverride?: string) => {
  const currency = currencyOverride || projectSettingsVar().CURRENCY;
  return currencyFormatMap.get(currency)?.locale;
};

export type ScrubNumberOptions = {
  localeOverride?: string;
  removeSign?: boolean;
  isCurrency?: boolean;
};

const defaultScrubbedNumberOptions = {
  localeOverride: undefined,
  removeSign: false,
} as ScrubNumberOptions;

// we will scrub any locale based formatting on the string
// and conver the string to a float
export const scrubNumberString = (
  number: string,
  isCurrency: boolean,
  inputSettings?: ScrubNumberOptions
): number => {
  const numberString = number.toString(); // we had a sentry error where number was an array.
  if (!numberString) return 0;

  const settings = { ...defaultScrubbedNumberOptions, ...inputSettings };
  const decimalSeparator = getDecimalSeparator(isCurrency, settings.localeOverride);

  // find the last index of the decimal separator if it exists in the string
  const separatorIndex = numberString.includes(decimalSeparator)
    ? numberString.lastIndexOf(decimalSeparator)
    : numberString.length;

  // get the dollar and cents part of the number
  const dollarString = numberString.slice(0, separatorIndex);
  const centString = separatorIndex > -1 ? numberString.slice(separatorIndex + 1) : '00';

  // remove any non-numeric characters
  const dollars = dollarString.replaceAll(/[^\d]/g, '');
  const cents = centString.replaceAll(/[^\d]/g, '');

  // compose the string, and convert it to a float
  const sign = getSign(numberString, settings.removeSign, settings.localeOverride);

  const value = getInternalCostRepresentation(sign, dollars, cents);
  return parseFloat(value);
};

const getSign = (numberString: string, removeSign?: boolean, localeOverride?: string) => {
  const reverseSign = isNegative(numberString, localeOverride);
  return reverseSign && !removeSign ? '-' : '';
};

// Note: we use a period internally as a decimal seperator for costs in the our db
// but not all currencies do.  For example, Brazilian Real uses a
// comma as a decimal seperator.  This function should only be used
// for internal cost representation before sending costs to the backend.
// We have other functions to display costs to the user
const getInternalCostRepresentation = (sign: string, dollars: string, cents: string) => {
  return `${sign}${dollars}.${cents}`;
};

type SeparatorOptions = {
  isCurrency?: boolean;
  localeOverride?: string;
  currencyOverride?: string;
};

const defaultSeparatorOptions = {
  isCurrency: true,
  localeOverride: undefined,
  currencyOverride: undefined,
} as SeparatorOptions;

// find the decimal separation character based on the locale
// generally it's a period or comma
//
// we do this by formatting a value of 1.1 with tkhe locale
// and removing the numbers from the resulting string
// which should leave us with the comma separator character
export const getDecimalSeparator = (isCurrency: boolean, currencyOverride?: string) => {
  const currency = currencyOverride || projectSettingsVar().CURRENCY;
  const locale = getLocale(currency);
  return getSeparatorFromLocale(1.1, {
    isCurrency,
    localeOverride: locale,
    currencyOverride: currency,
  });
};

// find the decimal separation character based on the locale
// generally it's a period or comma
//
// we do this by formatting a value of 1.1 with the locale
// and removing the numbers from the resulting string
// which should leave us with the comma separator character
export const getThousandSeparator = (isCurrency: boolean, currencyOverride?: string) => {
  const currency = currencyOverride || projectSettingsVar().CURRENCY;
  const locale = getLocale(currency);
  return getSeparatorFromLocale(1000, {
    isCurrency,
    localeOverride: locale,
    currencyOverride: currency,
  });
};

const getSeparatorFromLocale = (value: number, options: SeparatorOptions = {}): string => {
  const settings = { ...defaultSeparatorOptions, ...options };
  const locale = settings.localeOverride || getLocale();
  const currency = settings.currencyOverride || projectSettingsVar().CURRENCY;

  let separator = '';
  if (options.isCurrency) {
    const formatSettings = {
      showCurrencySymbol: false,
      showCents: true,
      showZeroCents: true,
      scale: 1,
      settingsOverride: {
        CURRENCY: currency,
        ROUNDING_PRECISION: 3,
      },
    };
    const formatted = formatCost(value, formatSettings);
    separator = formatted.replace(/\p{Number}/gu, '');
  } else {
    separator = value.toLocaleString(locale).replace(/\p{Number}/gu, '');
  }
  return separator;
};

export const addCurrencySeparatorWithZeros = (
  cellValue: RegularCell,
  currencySeparator: string,
  thousandsSeparator: string
) => {
  if (cellValue.string !== '') {
    // if there are parentheses then add this before the end
    const includesParenthasis = hasParenthases(
      cellValue.string,
      currencySeparator,
      thousandsSeparator
    );
    if (includesParenthasis) {
      const closingParenIndex = cellValue.string.lastIndexOf(')');
      const prefix = cellValue.string.slice(0, closingParenIndex);
      const suffix = cellValue.string.slice(closingParenIndex);
      return `${prefix}${currencySeparator}00${suffix}`;
    }

    return `${cellValue.string}${currencySeparator}00`;
  }
  return cellValue.string;
};
