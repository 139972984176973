import { ComponentProps, ReactNode } from 'react';

import Button from '../../Button/Button';
import Dialog from '../Dialog';
import { useDialogFlow } from '../DialogUtils';

export type RenderArgs = {
  index: number;
  onNext: () => void;
  progressPercent: number;
};

type Step = {
  title?: ReactNode;
  renderContent: (args: RenderArgs) => ReactNode;
  renderFooterRight?: (args: RenderArgs) => ReactNode;
  renderFooterLeft?: (args: RenderArgs) => ReactNode;
};

type Props = {
  accentColor?: string;
  isFullHeight?: boolean;
  isOpen: boolean;
  onClose: (step: number) => void;
  onComplete: () => void;
  size?: ComponentProps<typeof Dialog>['size'];
  steps: Step[];
  title: ComponentProps<typeof Dialog>['title'];
  ['data-cy']?: ComponentProps<typeof Dialog>['data-cy'];
};

export default function DialogFlow(props: Props) {
  const { index, onBack, onNext, progressPercent } = useDialogFlow(
    props.steps.length,
    props.onComplete,
    props.onClose
  );

  const backButton = (
    <Button
      data-cy="dialog-back-button"
      label={index === 0 ? 'Close' : 'Back'}
      onClick={onBack}
      type="secondary"
    />
  );

  const forwardButton = (
    <Button
      data-cy="dialog-next-button"
      label={index === props.steps.length - 1 ? 'Done' : 'Next'}
      onClick={onNext}
      type="primary"
    />
  );

  // Rendering
  const { renderContent, renderFooterRight, renderFooterLeft } = props.steps[index];
  const renderArgs = { index, progressPercent, onNext };

  return (
    <Dialog
      accentColor={props.accentColor}
      data-cy={props['data-cy'] || 'progress-dialog'}
      footerLeft={renderFooterLeft ? renderFooterLeft(renderArgs) : backButton}
      footerRight={renderFooterRight ? renderFooterRight(renderArgs) : forwardButton}
      isFullHeight={props.isFullHeight}
      isOpen={props.isOpen}
      onClose={() => props.onClose(index)}
      progressPercent={progressPercent}
      size={props.size}
      title={props.title}
    >
      {renderContent(renderArgs)}
    </Dialog>
  );
}
