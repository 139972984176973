import { ComponentType } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

import styles from './NormalTooltipStyles';

const NormalTooltip: ComponentType<TooltipProps> = withStyles(styles)(Tooltip);

export default NormalTooltip;
