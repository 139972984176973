import { ApolloError, useMutation } from '@apollo/client';

import {
  REFETCH_ADD_CATEGORIZATION,
  REFETCH_COMPANY_CATEGORIZATIONS,
} from '../../../../api/refetchSets';
import {
  AddCompanyCategorizationsMutation,
  AddCompanyCategorizationsMutationVariables,
  CompanyCategorizationsQuery,
  CompanyCategorizationsQueryVariables,
  CreateCompanyCategorizationMutation,
  CreateCompanyCategorizationMutationVariables,
  DeleteCompanyCategorizationMutation,
  DeleteCompanyCategorizationMutationVariables,
  ToggleBuiltInCategorizationMutation,
  ToggleBuiltInCategorizationMutationVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { processError } from '../../../table/categorization/ErrorMessage';

import {
  addCompanyCategorizationsMutation,
  companyCategorizationsQuery,
  createCompanyCategorizationMutation,
  deleteCompanyCategorizationMutation,
  toggleBuiltInCategorizationMutation,
} from './queries';

export const useCreateCompanyCategorization = () => {
  const [createCompanyCategorizationFunc] = useMutation<
    CreateCompanyCategorizationMutation,
    CreateCompanyCategorizationMutationVariables
  >(createCompanyCategorizationMutation);
  const createCompanyCategorization = (
    companyID: UUID,
    name: string,
    description?: string,
    onSuccess?: (c: CreateCompanyCategorizationMutation['createCompanyCategorization']) => void,
    setErrorMessage?: (msg: string) => void
  ) =>
    createCompanyCategorizationFunc({
      variables: {
        companyID,
        name,
        description,
      },
      refetchQueries: REFETCH_COMPANY_CATEGORIZATIONS,
    }).then(
      (result) => {
        const created = result.data?.createCompanyCategorization;
        if (created) {
          onSuccess?.(created);
        }
      },
      (error: ApolloError) => {
        if (setErrorMessage) setErrorMessage(processError(error));
      }
    );
  return createCompanyCategorization;
};

export const useCompanyCategorizationsQuery = (companyID?: UUID) => {
  return useQuery<CompanyCategorizationsQuery, CompanyCategorizationsQueryVariables>(
    companyCategorizationsQuery,
    {
      variables: { companyID },
      skip: !companyID,
    }
  );
};

export const useDeleteCompanyCategorization = () => {
  const [mutationFunc] = useMutation<
    DeleteCompanyCategorizationMutation,
    DeleteCompanyCategorizationMutationVariables
  >(deleteCompanyCategorizationMutation);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (categorizationID: UUID, onSuccess?: any, onFailure?: any) =>
    mutationFunc({
      variables: {
        categorizationID,
      },
      refetchQueries: REFETCH_COMPANY_CATEGORIZATIONS,
    }).then(
      (result) => {
        const categorizationID = result?.data?.deleteCompanyCategorization;
        if (categorizationID) {
          onSuccess?.(categorizationID);
        }
      },
      (error: ApolloError) => {
        onFailure?.(error);
      }
    );
};

export const useToggleBuiltInCategorization = () => {
  const [mutationFunc] = useMutation<
    ToggleBuiltInCategorizationMutation,
    ToggleBuiltInCategorizationMutationVariables
  >(toggleBuiltInCategorizationMutation);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (categorizationID: UUID, disabled: boolean, onSuccess?: any, onFailure?: any) =>
    mutationFunc({
      variables: {
        categorizationID,
        disabled,
      },
      update: (cache, result) => {
        const disabled = result.data?.toggleBuiltInCategorization;
        cache.modify({
          id: `CompanyCategorization:${categorizationID}`,
          fields: {
            disabled(cachedValue) {
              if (disabled === null || disabled === undefined) return cachedValue;
              return disabled;
            },
          },
        });
      },
    }).then(
      (result) => {
        const disabled = result?.data?.toggleBuiltInCategorization;
        if (disabled !== null && disabled !== undefined) {
          onSuccess?.(disabled);
        }
      },
      (error: ApolloError) => {
        onFailure?.(error);
      }
    );
};

export const useAddCompanyCategorizations = () => {
  const [addCompanyCategorizationsFunc] = useMutation<
    AddCompanyCategorizationsMutation,
    AddCompanyCategorizationsMutationVariables
  >(addCompanyCategorizationsMutation);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (projectID: UUID, categorizationIDs: UUID[], onSuccess?: any, onFailure?: any) =>
    addCompanyCategorizationsFunc({
      variables: {
        projectID,
        categorizationIDs,
      },
      refetchQueries: REFETCH_ADD_CATEGORIZATION,
    }).then(
      (result) => {
        const created = result && result.data && result.data.addCompanyCategorizationsToProject;
        if (created) {
          onSuccess?.(created);
        }
      },
      (error: ApolloError) => {
        onFailure?.(error);
      }
    );
};
