import { FC, useState } from 'react';

import { detachOptionAnalytics } from '../../../../analytics/analyticsEventProperties';
import { ToastType } from '../../../../api/gqlEnums';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { setToast } from '../../../../hooks/useToastParametersLocalQuery';
import DialogsDetachOption from '../../../Dialogs/DialogsDetachOption/DialogsDetachOption';
import { Button } from '../../../scales';
import { useDetachOption } from '../../ItemsHooks';

type Props = {
  hover?: boolean;
  option: Option;
};

const ItemsButtonDetachOption: FC<Props> = ({ hover, option }) => {
  // Hook for analytics
  const sendAnalytics = useSendAnalytics();

  const [hasDetached, setHasDetached] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const onRemove = () => setOpenDialog(false);
  const [detachOption] = useDetachOption(option, { onCompleted: onRemove });

  return (
    <>
      {hover && (
        <Button
          aria-label="detach options"
          data-cy="detach-option-button"
          label="Detach Option"
          onClick={() => setOpenDialog(true)}
          type="primary"
        />
      )}
      {openDialog && (
        <DialogsDetachOption
          onClose={() => setOpenDialog(false)}
          onConfirm={() => {
            if (!hasDetached) {
              setToast(null, ToastType.DETACH_OPTION, option);
              sendAnalytics(
                detachOptionAnalytics({
                  itemId: option.parent,
                  optionId: option.id,
                })
              );
              detachOption();
              setHasDetached(true);
            }
          }}
          open={openDialog}
          option={option}
        />
      )}
    </>
  );
};

export default ItemsButtonDetachOption;
