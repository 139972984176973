/* eslint-disable no-param-reassign */
import { EventProperties } from '../../analytics/analyticsEventProperties';
import { NUMBER_CELL, USCENTS_CELL } from '../../constants';
import {
  EstimateArmatureQuery,
  EstimateTotalType,
  GetCategorizationQuery,
  SortDirection,
} from '../../generated/graphql';
import { defaultQuantity } from '../Milestone/MilestoneDetails/MilestoneDetailsQuantities/MilestoneDetailsQuantitiesUtils';

import { closeErrorHeaders } from './controller/data';
import {
  copyToClipboard,
  finishReordering,
  isCellActivatable,
  isReorderingRow,
  moveFocus,
  scrollToBottom,
  scrollToColumn,
  scrollToRow,
  scrollToTop,
  shiftRow,
  startReorderingRow,
} from './controller/editing';
import {
  addCategories,
  mutateCategories,
  newCategorizationGridState,
  pasteClipboard as pasteCategories,
  removeCategories,
  removeCategory,
} from './controller/editingCategorization';
import {
  addCategorizedMetricLines,
  addColumns,
  addEstimateLine,
  deleteEstimateLines,
  fetchMoreEstimate,
  isCellEditable as isEstimateEditable,
  moveColumn,
  mutateEstimate,
  newEstimateGridState,
  pasteClipboard as pasteEstimate,
  removeColumns,
  replaceCategory,
  setTotalType,
  undoLastMutation as undoEstimateMutation,
} from './controller/editingEstimate';
import {
  addMarkupLine,
  deleteMarkups,
  isCellEditable as isMarkupEditable,
  mutateMarkup,
  newMarkupGridState,
  pasteClipboard as pasteMarkup,
  replaceMarkups,
  toggleAllocatedMarkupLine,
  toggleInheritedItemMarkup,
  toggleMarkupWithoutS2Reference,
  undoLastMutation as undoMarkupMutation,
} from './controller/editingMarkup';
import {
  addKeyToBuffer,
  getCellData,
  getCellPosition,
  getCellValue,
  getKeyBufferString,
  selectCell,
  selectRow,
  setSelectionRange,
  setSelectionRangeEnd,
} from './controller/selecting';
import {
  resizeColumns,
  scrollBarWidth,
  setOverallWidth,
  setRowUpdater,
  startResizingColumn,
  stopResizingColumn,
} from './controller/sizing';
import { createOwnerCostEstimate } from './hooks/estimateMutation';
// eslint-disable-next-line import/no-cycle
import { CategorizationWrapperProps } from './JoinGridCategorizationWrapper';
import {
  EstimateGridState,
  GenericGridState,
  GridController,
  GridType,
  JoinGridWrapperProps,
} from './types';
import { getSortBy } from './utilities/data';
import {
  getControllerMarkupFooter,
  getControllerMarkupSettings,
  getControllerMarkups,
} from './utils';

// Welcome to JoinGrid! For more comprehensive documentation about what is happening here,
// why the grid is structured the way it is, and where to place a given edit or piece
// of functionality, please refer to the documentation at https://github.com/JoinCAD/komodo/wiki/Grids

// MAIN CONSTRUCTOR

// Provides a controller with all the default view & selection functionality,
// but none of the editing controls.
export const genericController = (state: GenericGridState): GridController => ({
  // Grid-specific overrides
  projectID: '',
  estimateID: '',
  linePrefix: 'L',
  linesReadOnly: true,
  isInherited: !!state.isInherited,
  isItem: state.isItem,
  columnsReadOnly: true,
  canViewDirectCosts: false,
  isPrinting: false,
  hasMarkupCheckboxButton: false,
  canUpdateInheritedMarkupRefs: false,
  hasRemoveS2ItemMarkupFeature: false,
  variant: state.variant,

  // Shared functionality
  data: state.data,
  numRows: () => state.data.lines.length,
  numCols: () => state.data.columns.length,

  addKeyToBuffer: (p, k) => addKeyToBuffer(state, p, k),
  getKeyBufferString: (p) => getKeyBufferString(state, p),

  isRenderingEditor: state.isRenderingEditor,
  getRowHeights: () => state.heights,
  getRowTotals: () => state.rowTotals,
  colWidths: () => state.widths,
  renderedRows: state.renderedRows,
  visibleRows: state.visibleRows,
  visibleWidth: () => state.visibleWidth,
  overallWidth: () => state.overallWidth,
  maxHeight: () => state.maxHeight,
  // eslint-disable-next-line no-return-assign
  setMaxHeight: (n) => (state.maxHeight = n),
  setOverallWidth: (w, i) => setOverallWidth(state, w, i),
  scrollBarWidth: () => scrollBarWidth(state),
  startResizingColumn: (i: number) => startResizingColumn(state, i),
  resizeColumns: (widths, scrollGrid) => resizeColumns(state, widths, scrollGrid),
  stopResizingColumn: (i: number) => stopResizingColumn(state, i),
  getRenderGeneration: () => state.renderGeneration,
  // eslint-disable-next-line no-return-assign
  setUpdateTable: (update) => (state.updateTable = update),
  updateTable: () => state.updateTable(),
  updateRow: (i) => state.updateRow(i),
  setRowUpdater: (i, update) => setRowUpdater(state, i, update),
  updateHeader: () => state.updateHeader(),
  // eslint-disable-next-line no-return-assign
  setUpdateHeader: (update) => (state.updateHeader = update),
  // eslint-disable-next-line no-return-assign
  setBodyRef: (b) => (state.bodyRef = b),
  bodyRef: () => state.bodyRef,

  selection: state.selection,
  isRowSelectedArr: state.isRowSelectedArr,
  numSelectedRows: state.numSelectedRows,
  previouslySelectedRow: state.previouslySelectedRow,
  setSelectionRange: !state.linesReadOnly ? (s, e) => setSelectionRange(state, s, e) : () => {},
  setSelectionRangeEnd: !state.linesReadOnly ? (e) => setSelectionRangeEnd(state, e) : () => {},
  isSelecting: () => state.currentlySelecting,
  // eslint-disable-next-line no-return-assign
  setSelecting: (b) => (state.currentlySelecting = b),
  selectRow: (i) => selectRow(state, i),
  selectCell: (i, j) => selectCell(state, i, j),
  getCellPosition: (p) => getCellPosition(state, p),
  getCellData: (i, j) => getCellData(state, i, j),
  getCellValue: (i, j) => getCellValue(state, i, j),

  // Editing functionality. Defaults do nothing
  startReorderingRow: () => {},
  isReorderingRow: () => false,
  isReorderable: false,
  isFiltering: false,
  isSorting: false,
  sortEnabled: false,
  shiftRow: () => {},
  finishReordering: () => {},
  scrollToBottom: () => scrollToBottom(state),
  scrollToTop: () => scrollToTop(state),
  scrollToRow: (i) => scrollToRow(state, i),
  scrollToColumn: (i) => scrollToColumn(state, i),
  moveFocus: (p) => moveFocus(state, p),

  // Copy Paste
  copyToClipboard: (s, e) => copyToClipboard(state, s, e),
  pasteClipboard: () => {},

  // General editing
  isCellEditable: () => false,
  isCellActivatable: (p) => isCellActivatable(state, p),
  mutateData: () => {},
  toggleMarkupWithoutS2Reference: () => {},
  replaceCategory: () => {},

  // Undo
  undoLastMutation: () => {},

  // Add/Remove/toggle lines
  addLine: () => {},
  deleteLine: () => {},
  deleteLines: () => {},
  toggleInheritedItemMarkupLine: () => {},
  toggleAllocatedMarkupLine: () => {},
  populateMetricCategorizations: () => {},

  // Add/Remove columns
  addColumns: () => {},
  removeColumns: () => {},
  moveColumn: () => {},
  setTotalType: () => {},

  // Pagination
  fetchMore: (o, cb) => fetchMoreEstimate(state as EstimateGridState, o, cb),
  getLinesCallParams: () => state.linesCallParams,
  // eslint-disable-next-line no-return-assign
  setLinesCallParams: (p) => (state.linesCallParams = p),

  // Errors
  closeErrorHeaders: () => closeErrorHeaders(state.data),
});

export type MarkupControllerProps = JoinGridWrapperProps & {
  estimate: EstimateArmatureQuery['estimate']; // could be null if a user hasn't created an owner cost estimate yet
  milestoneEstimateID?: UUID; // used to create an owner estimate
  width?: number;
  replaceInheritedMarkups?: (markups: Markup[], newSubtotal: number) => void;
  replaceIncorporatedMarkups?: (markups: Markup[], newSubtotal: number) => void;
  replaceIncorporatedDraws?: (markups: Markup[], newSubtotal: number) => void;
  replaceInheritedOwnerCostMarkups?: (markups: Markup[], newSubtotal: number) => void;
  refetch?: () => void;
};

export type MarkupGridController = GridController & {
  createOwnerCostEstimate?: (onSuccess?: (id: UUID) => void) => void;
  replaceMarkups: (markups: Markup[], newSubtotal: number) => void;
  milestoneName?: string;
};

export const newMarkupGridController = (
  props: MarkupControllerProps,
  type: GridType
): MarkupGridController | undefined => {
  const {
    t,
    estimate,
    width,
    milestoneEstimateID,
    projectID,
    itemID,
    includeDraws,
    milestoneID,
    permissions,
    analytics,
    hasRemoveS2ItemMarkupFeature,
    refetch,
    replaceInheritedMarkups,
    replaceIncorporatedMarkups,
    replaceIncorporatedDraws,
    replaceInheritedOwnerCostMarkups,
    updateCostReports,
    variant,
    viewFilter,
  } = props;

  const isItem = !!itemID;
  const { markups, markupSubtotal } = getControllerMarkups(estimate, type);
  const {
    hasIncorporatedMarkups,
    hasIncorporatedDraws,
    hasMarkupCheckboxButton,
    canUpdateInheritedMarkupRefs,
    hasDisplayTypeColumn,
    isIncorporated,
    isInherited,
    isInheritedOwnerCost,
    isSummary,
    linePrefix,
    linesReadOnly,
    milestoneName,
    s1RefShouldIncludeS2,
  } = getControllerMarkupSettings(
    estimate,
    type,
    variant,
    permissions,
    hasRemoveS2ItemMarkupFeature,
    isItem
  );

  const hasMarkups = markups && markups.length;
  const shouldDisplaySubtotal =
    permissions.canViewEstimateCostSubtotals && Number(markupSubtotal) !== 0; // some markupSubtotals are strings, some are numbers

  if (
    (type === GridType.INCORPORATED_ITEM_MARKUP_GRID ||
      type === GridType.INCORPORATED_ITEM_DRAWS_GRID ||
      type === GridType.INHERITED_GRID ||
      type === GridType.INHERITED_OWNER_COST_MARKUP_GRID) &&
    !hasMarkups &&
    !shouldDisplaySubtotal
  )
    return undefined;

  const itemEstimateInput = itemID && milestoneID ? { itemID, milestoneID } : undefined;

  const state = newMarkupGridState(
    {
      ...estimate,
      markups,
      markupSubtotal,
      totalType: estimate?.totalType ?? EstimateTotalType.TOTAL_TYPE_TOTAL,
    },
    linesReadOnly,
    hasMarkupCheckboxButton,
    canUpdateInheritedMarkupRefs,
    analytics,
    updateCostReports,
    isItem,
    isInherited,
    isInheritedOwnerCost,
    isIncorporated,
    type === GridType.ITEM_DRAWS_GRID,
    width,
    projectID,
    variant,
    type,
    replaceInheritedMarkups,
    replaceIncorporatedMarkups,
    replaceIncorporatedDraws,
    replaceInheritedOwnerCostMarkups,
    viewFilter,
    hasDisplayTypeColumn,
    refetch,
    hasIncorporatedMarkups,
    itemEstimateInput
  );

  const controller = genericController(state);
  const isFiltering = !!Object.keys(viewFilter || {}).length;
  const isReorderable = !isFiltering && !isInherited;

  const footer = getControllerMarkupFooter(
    state,
    type,
    variant,
    hasIncorporatedMarkups,
    hasIncorporatedDraws,
    !!includeDraws,
    t
  );

  return {
    ...controller,
    projectID,
    linesReadOnly,
    canUpdateInheritedMarkupRefs,
    hasRemoveS2ItemMarkupFeature,
    canViewDirectCosts: permissions.canViewEstimate,
    estimateID: estimate?.id,
    isSummary,
    linePrefix,
    isReorderable,
    footer,
    type,
    startReorderingRow: (row: number) => startReorderingRow(state, row),
    isReorderingRow: (row: number) => isReorderingRow(state, row),
    shiftRow: (p, n) => shiftRow(state, p, n),
    isCellEditable: (cell) => !linesReadOnly && isMarkupEditable(state, cell),
    pasteClipboard: (s) => pasteMarkup(state, s),
    mutateData: (s, e, v) => mutateMarkup(state, s, e, v),
    toggleMarkupWithoutS2Reference: (index, withoutS2Reference) =>
      toggleMarkupWithoutS2Reference(state, index, withoutS2Reference),
    undoLastMutation: () => undoMarkupMutation(),
    getCellPosition: (p) => getCellPosition(state, p),
    addLine: !isInherited ? (c, s) => addMarkupLine(state, c, s) : () => {},
    deleteLines: () => deleteMarkups(state),
    createOwnerCostEstimate: (s) =>
      type === GridType.OWNER_COST_GRID && milestoneEstimateID
        ? createOwnerCostEstimate(projectID, milestoneEstimateID, (id: UUID) => {
            if (refetch) refetch();
            if (s) s(id);
          })
        : undefined,
    replaceMarkups: (m, m1) => replaceMarkups(state, m, m1),
    finishReordering: (o, n) => finishReordering(state, o, n),
    toggleInheritedItemMarkupLine: (i) => toggleInheritedItemMarkup(state, i),
    toggleAllocatedMarkupLine: (i) => toggleAllocatedMarkupLine(state, i, viewFilter),
    s1RefShouldIncludeS2,
    totalType: estimate?.totalType,
    milestoneName,
  };
};

export type EstimateControllerProps = JoinGridWrapperProps & {
  estimate: GridEstimate;
  errors?: ImportEstimateError[];
  width?: number;
  replaceMarkups: (markups: Markup[], newSubtotal: number) => void;
  replaceInheritedMarkups?: (markups: Markup[], newSubtotal: number) => void;
  replaceIncorporatedMarkups?: (markups: Markup[], newSubtotal: number) => void;
  replaceIncorporatedDraws?: (markups: Markup[], newSubtotal: number) => void;
  replaceInheritedOwnerCostMarkups?: (markups: Markup[], newSubtotal: number) => void;
  refetch?: () => void;
};

export const newEstimateGridController = (props: EstimateControllerProps): GridController => {
  const {
    analytics,
    errors,
    estimate,
    permissions,
    projectID,
    milestoneID,
    itemID,
    sortData,
    quantity,
    width,
    replaceMarkups,
    replaceInheritedMarkups,
    replaceIncorporatedMarkups,
    replaceIncorporatedDraws,
    replaceInheritedOwnerCostMarkups,
    updateCostReports,
    refetch,
    variant,
    viewFilter,
    t,
  } = props;

  const linesReadOnly = !permissions.canEditLines;
  const columnsReadOnly = !permissions.canEditColumns;
  const isItem = !!itemID;

  // initialize an array of empty lines
  // for pagination
  if (estimate.linesCount) {
    const initializedLines: GridLine[] = Array(estimate.linesCount).fill(undefined);
    estimate.lines = initializedLines;
  }

  const itemEstimateInput = itemID && milestoneID ? { itemID, milestoneID } : undefined;

  const state = newEstimateGridState(
    estimate,
    errors || [],
    linesReadOnly,
    isItem,
    analytics,
    width,
    projectID,
    getSortBy(sortData),
    variant,
    viewFilter,
    updateCostReports,
    replaceMarkups,
    t,
    replaceInheritedMarkups,
    replaceIncorporatedMarkups,
    replaceIncorporatedDraws,
    replaceInheritedOwnerCostMarkups,
    refetch,
    quantity,
    itemEstimateInput
  );
  const isSorting = !(
    sortData.sortDirection === SortDirection.SORT_NONE ||
    state.data.columns.findIndex(({ id }) => id === sortData.sortKey) === -1
  );
  const isFiltering = !!Object.keys(viewFilter || {}).length;
  const isReorderable = !isSorting && !isFiltering;
  const controller = genericController(state);
  const {
    unitInfo: { abbreviationPlural },
  } = quantity || defaultQuantity;
  const footerName = quantity ? `Total Categorized ${abbreviationPlural}` : 'Subtotal';
  const footerType = quantity ? NUMBER_CELL : USCENTS_CELL;
  return {
    ...controller,
    projectID,
    estimateID: estimate.id,
    linePrefix: 'L',
    footer: {
      prefix: 'S1',
      name: footerName,
      type: footerType,
      data: state.subtotal,
    },
    canViewDirectCosts: permissions.canViewEstimate,
    linesReadOnly,
    columnsReadOnly,
    isReorderable,
    isFiltering,
    isSorting,
    sortEnabled: true,
    type: GridType.ESTIMATE_GRID,
    totalType: estimate.totalType,
    quantity,
    startReorderingRow: (row: number) => startReorderingRow(state, row),
    isReorderingRow: (row: number) => isReorderingRow(state, row),
    shiftRow: (p, n) => shiftRow(state, p, n),
    finishReordering: (o, n) => finishReordering(state, o, n),
    isCellEditable: (cell) => !linesReadOnly && isEstimateEditable(state, cell),
    pasteClipboard: (s) => !linesReadOnly && pasteEstimate(state, s),
    mutateData: (s, e, v) => !linesReadOnly && mutateEstimate(state, s, e, v),
    replaceCategory: (f, o, n) => replaceCategory(state, f, o, n),
    undoLastMutation: () => undoEstimateMutation(state),
    addLine: (c, s) => !linesReadOnly && addEstimateLine(state, c, s),
    deleteLines: () => deleteEstimateLines(state),
    addColumns: (f) => addColumns(state, f),
    removeColumns: (ids) => removeColumns(state, ids),
    moveColumn: (c, f) => moveColumn(state, c, f),
    setTotalType: (f: EstimateTotalType) => setTotalType(state, f),
    refetch,
    populateMetricCategorizations: (categories: GridCategoryCellInputs[][]) =>
      addCategorizedMetricLines(state, categories, refetch || (() => {})),
  };
};

type CategorizationControllerProps = CategorizationWrapperProps & {
  categorization?: GetCategorizationQuery['categorization'];
};

export const newCategorizationController = (
  props: CategorizationControllerProps,
  onCloseRef: React.MutableRefObject<() => void>,
  sendAnalytics: (analyticsEvent: { type: string; eventProperties: EventProperties }) => void,
  onSuccess: () => void
): GridController => {
  const { categorization, projectID, variant, permissions } = props;
  const linesReadOnly = !permissions.canEdit;

  const state = newCategorizationGridState(
    linesReadOnly,
    onCloseRef,
    sendAnalytics,
    onSuccess,
    variant,
    categorization,
    projectID
  );
  const controller = genericController(state);

  return {
    ...controller,
    linePrefix: '',
    projectID,
    linesReadOnly,
    isCellEditable: () => permissions.canEdit,
    mutateData: (s, e, v) => mutateCategories(state, s, e, v),
    addLine: (c, s) => addCategories(state, 1, 0, undefined, s),
    deleteLine: (i) => removeCategory(state, i),
    deleteLines: () => removeCategories(state),
    pasteClipboard: (s) => pasteCategories(state, s),
  };
};
