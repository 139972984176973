import { FC, ReactNode, ReactPortal, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { generateClassNameTokens } from '../../../utilities/string';

/**
 * Portal
 *
 * The Portal component is used to maintain React's hierarchy tree while
 * allowing child components to attach to any html element in the body
 *
 * The most common use-case for a portal is a modal, where the modal's parent
 * has applied position: absolute, overflow: hidden, or any other css which
 * would prevent proper positioning and z-index rendering of the component
 *
 * The Portal component will attach its children to a div at the body root
 * with the id "portal-target"
 * Optionally, a "portalTargetID" can be passed, which will allow mounting
 * the portal children to any other html element
 */

const createDivWithId = (id: string): HTMLDivElement => {
  const divWithId = document.createElement('div');
  divWithId.id = id;
  return divWithId;
};

// Idempotently create <div id="portal-target" />
const PORTAL_TARGET_ID = 'portal-target';
const defaultPortalTarget =
  document.getElementById(PORTAL_TARGET_ID) ||
  document.body.appendChild(createDivWithId(PORTAL_TARGET_ID));

type PortalProps = {
  children?: ReactNode;
  portalTargetID?: string; // allow passing a custom ID to use as the portal target
  attributes?: {
    order?: string;
    className?: string;
  };
};

export const Portal: FC<PortalProps> = ({
  children,
  portalTargetID,
  attributes = {},
}): ReactPortal => {
  const [el] = useState(document.createElement('div'));
  useEffect(() => {
    if (!el) return () => {};
    const classNameTokens = generateClassNameTokens(attributes.className);

    if (classNameTokens.length > 0) {
      classNameTokens.forEach((classNameToken) => {
        el.classList.add(classNameToken);
      });
    }

    const order = Number(attributes.order);
    if (order >= 0) {
      el.style.order = String(order);
    }

    return () => {
      if (classNameTokens.length > 0) {
        classNameTokens.forEach((classNameToken) => {
          el.classList.remove(classNameToken);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [el, JSON.stringify(attributes)]);

  useEffect(() => {
    const portalTarget = portalTargetID
      ? document.getElementById(portalTargetID)
      : defaultPortalTarget;
    if (!portalTarget) {
      throw new Error(`portal target with ID "${portalTargetID}" does not exist`);
    }
    portalTarget.appendChild(el);
    return () => {
      portalTarget.removeChild(el);
    };
  }, [el, portalTargetID]);
  return createPortal(children, el) as ReactPortal;
};
