import { TextInput } from '../../../scales';
import UploadIcon from '../../../Select/UploadIcon/UploadIcon';

type Props = {
  disabled: boolean;
  jobTitle: string;
  name: string;
  setJobTitle: (name: string) => void;
  setName: (name: string) => void;
  setThumbnail: (file: File) => void;
  thumbnail?: string;
  thumbnailLoading: boolean;
};

export default function UserProfile(props: Props) {
  return (
    <div className="flex gap-6">
      <div className="flex grow flex-col gap-2">
        <TextInput
          data-cy="profile-name-text-input"
          disabled={props.disabled}
          id="name"
          label="Full Name *"
          onChange={props.setName}
          value={props.name}
        />
        <TextInput
          data-cy="job-title-text-input"
          id="jobTitle"
          label="Job Title"
          onChange={props.setJobTitle}
          placeholder="e.g. Senior Estimator"
          value={props.jobTitle}
        />
      </div>
      <UploadIcon
        accept=".png, .jpg, .gif" // we currently do not support .svg for user icons
        addFile={props.setThumbnail}
        disabled={props.disabled}
        height={144}
        loading={props.thumbnailLoading}
        thumbnail={props.thumbnail}
        title="Profile Photo"
        width={144}
      />
    </div>
  );
}
