import useSearchProjectsQueryParams from '../HomeTab/Search/projects/hooks/useSearchProjectsQueryParams';

import ProjectsContingenciesAndAllowances from './ProjectsTables/ProjectsContingenciesAndAllowances';
import ProjectsCost from './ProjectsTables/ProjectsCost';
import ProjectsItems from './ProjectsTables/ProjectsItems';
import { InsightsTabId } from './types';

export default function ProjectListData({
  insightsProjects,
  tabId,
}: {
  insightsProjects: InsightsProjects[];
  tabId: InsightsTabId;
}) {
  const params = useSearchProjectsQueryParams();

  const counts = {
    current: 55,
    total: 10,
  };

  if (!insightsProjects) return null;
  return (
    <>
      {tabId === InsightsTabId.Costs && (
        <ProjectsCost
          counts={counts}
          loading={false}
          onFetchMore={() => {}}
          projects={insightsProjects}
          searchText={params.variables.search}
          sortManager={params.sortManager}
        />
      )}
      {tabId === InsightsTabId.Items && (
        <ProjectsItems
          counts={counts}
          loading={false}
          onFetchMore={() => {}}
          projects={insightsProjects}
          searchText={params.variables.search}
          sortManager={params.sortManager}
        />
      )}
      {tabId === InsightsTabId.Contingencies && (
        <ProjectsContingenciesAndAllowances
          counts={counts}
          loading={false}
          onFetchMore={() => {}}
          projects={insightsProjects}
          searchText={params.variables.search}
          sortManager={params.sortManager}
        />
      )}
    </>
  );
}
