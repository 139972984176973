import { FC, useState } from 'react';

import { NetworkStatus, useReactiveVar } from '@apollo/client';
import { Collapse, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DeleteOutline } from '@material-ui/icons';

import {
  ItemEstimateHistoryTypes,
  itemEstimateHistoryAnalyticsEvent,
} from '../../analytics/analyticsEventProperties';
import { itemEstimateHistoryHistoricalMilestoneID } from '../../api/apollo/reactiveVars';
import { OPTION } from '../../constants';
import { NS_ITEM_ESTIMATE_HISTORY } from '../../features';
import { ItemType } from '../../generated/graphql';
import { useHasFeature } from '../../hooks/useHasFeature';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import theme from '../../theme/komodo-mui-theme';
import {
  ItemPreviousMilestone,
  computeItemCostImpact,
  getItemCost,
  getItemMilestoneMappings,
} from '../../utilities/items';
import { renderCostString } from '../CostReport/CostReportUtils';
import DialogsItemEstimateHistory from '../Dialogs/DialogsItemEstimateHistory/DialogsItemEstimateHistory';
import ItemsIcons from '../ItemsList/ItemsIcons/ItemsIcons';
import { SMALL } from '../ItemsList/ItemsIcons/ItemsIconsMap';
import useMilestonesQuery from '../Milestones/hooks/useMilestonesQuery';
import { TextLink } from '../scales';
import CollapseIcon from '../shared-widgets/CollapseIcon';
import useMemoWrapper from '../useMemoWrapper';

import ConfirmDeleteMilestoneDialog from './ConfirmDeleteMilestoneDialog';
import styles from './ItemMilestoneMapStyles';

export const ITEM_SUMMARY = 'ITEM_SUMMARY';

type ItemMilestoneMapProps = {
  classes: Classes<typeof styles>;
  canEditItemMilestone: boolean;
  shouldDisplayCosts: boolean;
  currentItem: ItemLike | null;
  projectID: UUID;
  onChange: (milestoneToDelete: Milestone | null) => void;
  variant?: string;
};

const ItemMilestoneMap: FC<ItemMilestoneMapProps> = ({
  classes,
  canEditItemMilestone,
  shouldDisplayCosts,
  currentItem,
  projectID,
  onChange,
  variant,
}) => {
  const hasFeature = useHasFeature(NS_ITEM_ESTIMATE_HISTORY);
  const {
    data: { milestones = [] } = {},
    refetch,
    networkStatus,
  } = useMilestonesQuery(projectID, false);
  const activeMilestoneID = useProjectPropsQuery(projectID)?.data?.project?.activeMilestone.id;

  const sendAnalytics = useSendAnalytics();

  const item = useMemoWrapper(computeItemCostImpact, currentItem);
  const [confirmDeleteDialogVisible, setConfirmDeleteMilestoneVisible] = useState(false);
  const [milestoneToDelete, setMilestoneToDelete] = useState<Milestone | null>(null);
  const [previousMilestonesVisible, setPreviousMilestonesVisible] = useState(false);

  const itemMilestoneId = item && item.milestone && item.milestone.id;
  const itemPreviousMilestones = getItemMilestoneMappings(item, milestones);
  const previousMilestoneLabel = 'View Estimate History';
  const isOption = currentItem?.itemType === OPTION;
  const showItemMilestoneMapCollapse = variant === ITEM_SUMMARY;
  const showDeletePlaceholder =
    canEditItemMilestone && itemPreviousMilestones.length > 1 && !isOption;
  const count = itemPreviousMilestones && itemPreviousMilestones.length;
  const isItemSummary = variant === ITEM_SUMMARY;

  // FUNCTIONS
  const openConfirmDeleteMilestoneVisible = (milestone?: Milestone) => {
    if (milestone) {
      setMilestoneToDelete(milestone);
    }
    setConfirmDeleteMilestoneVisible(true);
  };

  // Open / close
  const togglePreviousMilestones = () => {
    setPreviousMilestonesVisible(!previousMilestonesVisible);
    sendAnalytics(
      itemEstimateHistoryAnalyticsEvent(
        previousMilestonesVisible
          ? ItemEstimateHistoryTypes.ITEM_ESTIMATE_HISTORY_COLLAPSE
          : ItemEstimateHistoryTypes.ITEM_ESTIMATE_HISTORY_EXPAND
      )
    );
  };

  // new cool stuff
  const historicalMilestoneID = useReactiveVar(itemEstimateHistoryHistoricalMilestoneID);

  const getEstimateLink = (milestoneID: UUID, itemPreviousMilestone: ItemPreviousMilestone) => {
    const { itemType, hasEstimate } = itemPreviousMilestone;
    const isIWO = itemType === ItemType.ITEM_WITH_OPTIONS;
    if (!hasEstimate && !isIWO) {
      return '';
    }
    return hasFeature && !isIWO ? (
      <TextLink
        label={renderCostString({ cost: getItemCost(itemPreviousMilestone) })}
        onClick={() => {
          sendAnalytics(
            itemEstimateHistoryAnalyticsEvent(ItemEstimateHistoryTypes.ITEM_ESTIMATE_HISTORY_VIEW)
          );
          itemEstimateHistoryHistoricalMilestoneID(milestoneID);
        }}
        to=""
      />
    ) : (
      renderCostString({ cost: getItemCost(itemPreviousMilestone) })
    );
  };

  const generateLine = (itemPreviousMilestone: ItemPreviousMilestone) => {
    const { milestone, status } = itemPreviousMilestone;
    const { id = '', name = '' } = milestone ?? {}; // milestone shouldn't be ever be undefined, but it is possible based on the code
    const isItemMilestone = itemMilestoneId === id;
    return (
      <div className={classes.mappedMilestoneLine} data-cy="div-itemMilestoneMapLine">
        <div className={classes.milestoneStatus}>
          <ItemsIcons status={status} variant={SMALL} />
        </div>
        <Typography
          className={`${classes.milestoneNameTextLabel} ${
            isItemMilestone ? classes.currentMilestone : ''
          }`}
          data-cy="milestoneName-itemMilestoneMapLine"
        >
          {' '}
          {name}{' '}
        </Typography>
        <div className={classes.itemMilestoneData}>
          <div className={classes.costText} data-cy="costImpact-itemMilestoneMapLine">
            {shouldDisplayCosts && getEstimateLink(id, itemPreviousMilestone)}
          </div>
          {!isItemMilestone && canEditItemMilestone && !isOption ? (
            <IconButton
              aria-label="Remove"
              className={classes.removeButton}
              data-cy="iconButtonRemove-itemMilestoneMapLine"
              onClick={() => openConfirmDeleteMilestoneVisible(milestone)}
              title={`Remove item from ${name}: this will update the running totals in ${name} to not include this item.`}
            >
              <DeleteOutline className={classes.removeIcon} />
            </IconButton>
          ) : (
            showDeletePlaceholder && <div className={classes.removeButton} />
          )}
        </div>
      </div>
    );
  };

  const closeConfirmDeleteMilestoneVisible = () => {
    setConfirmDeleteMilestoneVisible(false);
  };

  const onSubmit = () => {
    setConfirmDeleteMilestoneVisible(false);
    onChange(milestoneToDelete);
  };

  const milestoneMappingsComponent = itemPreviousMilestones.map((itemPreviousMilestone) => (
    <div key={itemPreviousMilestone.milestoneID + item.id}>
      {generateLine(itemPreviousMilestone)}
    </div>
  ));

  return (
    <div>
      <DialogsItemEstimateHistory
        activeMilestoneID={activeMilestoneID}
        historicalMilestoneID={historicalMilestoneID}
        historicalMilestoneIDs={itemPreviousMilestones.map((m) => m.milestoneID)}
        item={item}
        itemPreviousMilestone={itemPreviousMilestones.find(
          (m) => m.milestoneID === historicalMilestoneID
        )}
        loading={networkStatus === NetworkStatus.loading}
        projectID={projectID}
        refetch={() => refetch({ projectID })}
      />
      <div
        className={previousMilestoneLabel ? classes.previousMilestoneList : classes.notDisplayed}
        onClick={togglePreviousMilestones}
        onKeyDown={(evt) => {
          if (evt.key === 'Enter') {
            togglePreviousMilestones();
          }
        }}
        role="button"
        tabIndex={-1}
      >
        {showItemMilestoneMapCollapse && (
          <CollapseIcon
            className={classes.expander}
            dataCy="expandMore-milestonesItemSummary"
            isCollapsed={!previousMilestonesVisible}
          />
        )}
        <Typography
          className={classes.cardHeaderTypography}
          style={{
            color: isItemSummary ? theme.palette.joinPrimary : '',
            cursor: showItemMilestoneMapCollapse ? 'pointer' : '',
          }}
          variant="caption"
        >
          {previousMilestoneLabel}
        </Typography>
      </div>
      {count ? (
        <div className={classes.root}>
          {canEditItemMilestone && (
            <ConfirmDeleteMilestoneDialog
              milestone={milestoneToDelete}
              onCancel={closeConfirmDeleteMilestoneVisible}
              onClose={closeConfirmDeleteMilestoneVisible}
              onSubmit={onSubmit}
              open={confirmDeleteDialogVisible}
            />
          )}
          <div className={classes.milestoneTimelineLeft} />
          {showItemMilestoneMapCollapse ? (
            <Collapse in={previousMilestonesVisible} unmountOnExit>
              <div className={classes.milestoneTimelineRight}>{milestoneMappingsComponent}</div>
            </Collapse>
          ) : (
            <div className={classes.milestoneTimelineRight}>{milestoneMappingsComponent}</div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(ItemMilestoneMap);
