import { ComponentProps, useRef, useState } from 'react';
import { mergeProps, useButton, useFocusRing, useMenuTrigger } from 'react-aria';
import { useMenuTriggerState } from 'react-stately';

import { useAuth0 } from '@auth0/auth0-react';

import { navEventTypes } from '../../../analytics/analyticsEventProperties';
import { DD_NAV_SUPPORT_MENU_ITEMS } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { kebabDataCy } from '../../../utilities/cypress';
import { getUserPicture } from '../../Collaborators/UserUtils';
import { DialogsEditProfile } from '../../Dialogs';
import { Menu, Popover, Tooltip } from '../../scales';
import useSendNavAnalytics from '../hooks/useSendNavAnalytics';
import {
  ContactSupportIcon,
  EditProfileIcon,
  HelpCenterIcon,
  KBShortcutsIcon,
  LogoutIcon,
  WhatsNewIcon,
} from '../icons';

type Props = {
  expanded?: boolean;
  tooltipOffset?: ComponentProps<typeof Tooltip>['offset'];
  tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
  user?: {
    name: string;
    picture: string;
  } & Parameters<typeof getUserPicture>[0];
};

export default function UserMenuButton(props: Props) {
  const hasSupportMenuButton = useHasFeature(DD_NAV_SUPPORT_MENU_ITEMS);
  const sendNavAnalytics = useSendNavAnalytics();

  const { logout } = useAuth0();

  const state = useMenuTriggerState({
    onOpenChange: (isOpen) => {
      if (isOpen) {
        sendNavAnalytics(navEventTypes.OPEN_USER_MENU);
      }
    },
  });

  const ref = useRef<HTMLButtonElement>(null);
  const { menuProps, menuTriggerProps } = useMenuTrigger({}, state, ref);
  const { buttonProps } = useButton(
    {
      ...menuTriggerProps,
      'aria-label': 'show user options',
    },
    ref
  );

  const { isFocusVisible, focusProps } = useFocusRing();

  const [isEditProfileDialogOpen, setIsEditProfileDialogOpen] = useState(false);

  if (!props.user) return null;

  const dataCy = 'user-menu-button';

  return (
    <>
      <Tooltip
        content="Open user menu"
        isDisabled={state.isOpen}
        offset={props.tooltipOffset}
        placement={props.tooltipPlacement}
      >
        <button
          {...mergeProps(buttonProps, focusProps)}
          ref={ref}
          className={[
            'relative flex items-center gap-2 truncate rounded-full type-body2 hover:bg-button-secondary-hover',
            props.expanded ? 'grow p-1 pr-4' : 'p-1',
            isFocusVisible ? 'outline' : 'outline-none',
          ].join(' ')}
          data-cy={dataCy}
        >
          <img
            alt="user"
            className="h-8 w-8 shrink-0 overflow-hidden rounded-full object-scale-down"
            src={getUserPicture(props.user)}
          />
          {props.expanded && (
            <div className="truncate" data-cy={kebabDataCy(dataCy, 'name')}>
              {props.user.name}
            </div>
          )}
          {state.isOpen && (
            <Popover offset={8} placement="bottom start" state={state} triggerRef={ref}>
              <Menu
                {...menuProps}
                data-cy={kebabDataCy(dataCy, 'menu')}
                onClose={state.close}
                sections={
                  hasSupportMenuButton
                    ? [
                        {
                          entries: [
                            {
                              id: 'edit-profile',
                              label: 'Edit profile',
                              onClick: () => {
                                setIsEditProfileDialogOpen(true);
                              },
                              startAdornment: (
                                <div className="icon-md">
                                  <EditProfileIcon />
                                </div>
                              ),
                            },
                          ],
                        },
                        {
                          entries: [
                            {
                              id: 'log-out',
                              label: 'Log out',
                              onClick: () => {
                                logout({ returnTo: window.location.origin });
                              },
                              startAdornment: (
                                <div className="icon-md">
                                  <LogoutIcon />
                                </div>
                              ),
                            },
                          ],
                        },
                      ]
                    : [
                        {
                          entries: [
                            {
                              id: 'edit-profile',
                              label: 'Edit profile',
                              onClick: () => {
                                setIsEditProfileDialogOpen(true);
                              },
                              startAdornment: (
                                <div className="icon-md">
                                  <EditProfileIcon />
                                </div>
                              ),
                            },
                          ],
                        },
                        {
                          entries: [
                            {
                              id: 'whats-new',
                              label: "What's new",
                              onClick: () => {
                                openURL(
                                  'https://success.join.build/en/knowledge/whats-new-in-join'
                                );
                                sendNavAnalytics(navEventTypes.OPEN_SUPPORT_WHATS_NEW);
                              },
                              startAdornment: (
                                <div className="icon-md">
                                  <WhatsNewIcon />
                                </div>
                              ),
                            },
                            {
                              id: 'help-center',
                              label: 'Visit our help center',
                              onClick: () => {
                                openURL('https://success.join.build');
                                sendNavAnalytics(navEventTypes.OPEN_SUPPORT_VISIT_SUCCESS);
                              },
                              startAdornment: (
                                <div className="icon-md">
                                  <HelpCenterIcon />
                                </div>
                              ),
                            },
                            {
                              id: 'contact-support',
                              label: 'Contact Join support',
                              onClick: () => {
                                openURL('mailto:support@join.build');
                                sendNavAnalytics(navEventTypes.OPEN_SUPPORT_EMAIL_SUPPORT);
                              },
                              startAdornment: (
                                <div className="icon-md">
                                  <ContactSupportIcon />
                                </div>
                              ),
                            },
                            {
                              id: 'kb-shortcuts',
                              label: 'View keyboard shortcuts',
                              onClick: () => {
                                openURL(
                                  'https://success.join.build/en/knowledge/keyboard-shortcuts'
                                );
                                sendNavAnalytics(navEventTypes.OPEN_SUPPORT_KEYBOARD_SHORTCUTS);
                              },
                              startAdornment: (
                                <div className="icon-md">
                                  <KBShortcutsIcon />
                                </div>
                              ),
                            },
                          ],
                        },
                        {
                          entries: [
                            {
                              id: 'log-out',
                              label: 'Log out',
                              onClick: () => {
                                logout({ returnTo: window.location.origin });
                              },
                              startAdornment: (
                                <div className="icon-md">
                                  <LogoutIcon />
                                </div>
                              ),
                            },
                          ],
                        },
                      ]
                }
              />
            </Popover>
          )}
        </button>
      </Tooltip>
      {isEditProfileDialogOpen && (
        <DialogsEditProfile onClose={() => setIsEditProfileDialogOpen(false)} />
      )}
    </>
  );
}

function openURL(url: string) {
  window.open(url, '_blank', 'noreferrer');
}
