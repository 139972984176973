import { FC, ReactElement, ReactNode, useState } from 'react';

import { FormControl, Input, MenuItem, Select, Typography } from '@material-ui/core';
import { MenuProps } from '@material-ui/core/Menu';

import { DEFAULT_SELECTION } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import TooltipTitles from '../../NormalTooltip/TooltipTitles';
import DropDownArrow from '../DropDownArrow/DropDownArrow';
import { SelectEntry } from '../JoinSelect/JoinSelect';
import SelectAll from '../SelectAll/SelectAll';

import styles, { menuPropsDefault } from './JoinMultiSelectStyles';

export type JoinMultiSelectProps = {
  className?: string | undefined;
  classes: Classes<typeof styles>;
  cySelect?: string;
  disabled?: boolean;
  displaySelectAll?: boolean;
  fullWidth?: boolean;
  entries: SelectEntry[];
  menuProps?: Partial<MenuProps>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onChange: (entry: any) => void;
  onClose?: () => void;
  onOpen?: () => void;
  renderValue: (value: UUID) => ReactElement | null;
  renderValueAll?: (value: UUID) => ReactElement | null;
  value: string[];
  width?: number;
};

// Link to stories:
// http://localhost:9009/?path=/story/components-select-joinMultiselect--item-status
const JoinMultiSelect: FC<JoinMultiSelectProps> = ({
  className,
  classes,
  cySelect,
  disabled = false,
  displaySelectAll = false,
  fullWidth = true,
  entries = [],
  menuProps,
  onChange,
  onClose,
  onOpen,
  renderValue,
  renderValueAll,
  value,
  width,
}) => {
  const rootItemPaddingStyle = disabled
    ? classes.disabledItem
    : `${classes.rootItem} ${classes.rootItemCompact}`;
  const selectedStyle = classes.selected;

  const [open, setOpen] = useState(false);

  const allSelected = entries.length === value.length;

  const renderMenuOption = (entry: SelectEntry) => {
    const {
      disabled: entryDisabled,
      id,
      leftIcon,
      name,
      rightIcon,
      rightText,
      selected,
      subName,
      title: entryTitle,
    } = entry;
    const item = (
      <div className={classes.row}>
        {leftIcon}
        <div className={classes.fullWidth}>
          <div className={classes.horizontal}>
            <Typography
              className={`${classes.name} ${leftIcon ? classes.marginLeft : ''}  ${
                subName && classes.nameBold
              }`}
              title={entryTitle}
            >
              {name}
            </Typography>
            {rightText && (
              <Typography className={`${classes.name}  ${subName && classes.nameBold} `}>
                {rightText}
              </Typography>
            )}
            {rightIcon}
          </div>
          {subName && (
            <Typography
              className={`${classes.name} ${classes.subName} ${leftIcon ? classes.marginLeft : ''}`}
              data-cy={`subtext-${subName}`}
            >
              {subName}
            </Typography>
          )}
        </div>
      </div>
    );
    const menuItem = (item: ReactNode) => (
      <MenuItem
        key={id}
        classes={{
          root: `${rootItemPaddingStyle}  ${selected ? selectedStyle : ''}`,
          selected: selectedStyle,
        }}
        data-cy={`select-${name}`}
        disabled={entryDisabled}
        disableRipple
        value={id}
      >
        {item}
      </MenuItem>
    );
    return menuItem(item);
  };

  const withTooltip = (s: ReactElement | null) => {
    if (!s) return null;
    const nameList = entries.filter(({ selected }) => selected).map(({ name }) => name);
    if (!nameList.length) return s;
    return <NormalTooltip title={<TooltipTitles titles={nameList} />}>{s}</NormalTooltip>;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const renderSelect = (v: any) =>
    withTooltip(allSelected && !!renderValueAll ? renderValueAll(v) : renderValue(v));

  const select = (
    <Select
      aria-disabled={disabled} // needed for integration tests.  Disable doesn't appear to be discoverable
      classes={{
        icon: !disabled ? classes.hideBorder : classes.hide,
        root: disabled ? undefined : classes.bgnWhite,
        select: !disabled ? undefined : classes.select,
        selectMenu: classes.selectMenu,
      }}
      className={className}
      data-cy={cySelect}
      disabled={disabled}
      disableUnderline
      displayEmpty
      IconComponent={() => (!disabled ? <DropDownArrow onClick={() => setOpen(true)} /> : null)}
      input={<Input className={`${disabled ? '' : classes.bgnWhite}`} />}
      MenuProps={{
        ...menuPropsDefault,
        ...menuProps,
        PopoverClasses: {
          paper: classes.locationCompact,
          ...menuProps?.PopoverClasses,
        },
        MenuListProps: {
          className: classes.borderBox,
          ...menuProps?.MenuListProps,
        },
      }}
      multiple
      onChange={(e) => {
        let selectedID = e.target.value || null;
        if (selectedID === DEFAULT_SELECTION) {
          selectedID = null;
        }
        onChange(selectedID);
      }}
      onClose={() => {
        setOpen(false);
        if (onClose) onClose();
      }}
      onOpen={() => {
        setOpen(true);
        if (onOpen) onOpen();
      }}
      open={open}
      renderValue={renderSelect}
      value={value || DEFAULT_SELECTION}
    >
      {displaySelectAll && (
        <SelectAll
          clearAllActive={value.length !== 0}
          onClearAll={() => onChange([])}
          onSelectAll={() => onChange(entries.map(({ id }) => id))}
          selectAllActive={value.length !== entries.length}
        />
      )}
      {entries.map((entry) => renderMenuOption(entry))}
    </Select>
  );

  return (
    <FormControl fullWidth={fullWidth} style={{ minWidth: width }}>
      {select}
    </FormControl>
  );
};

/** @deprecated in favor of design system component, please use scales/MultiSelect */
export default withStyles(styles)(JoinMultiSelect);
