import { Permission } from '../../../../../generated/graphql';
import { Radio, RadioGroup, TableComponents } from '../../../../scales';
import { getPermissionDescriptionStrings } from '../../utils';

type Props = {
  disabled: boolean;
  groupType: PermissionGroupType;
  isLevelIncluded: boolean;
  onChange: (id: UUID, level: PermissionLevel, permissionResource?: string) => void;
  permission: Permission;
  permissionLevel: PermissionLevel;
};

function LevelCell(props: Props) {
  return (
    <TableComponents.Cell>
      {props.isLevelIncluded ? (
        <RadioGroup
          onChange={() =>
            props.onChange &&
            props.permission &&
            props.onChange(props.permission.id, props.permissionLevel, props.permission.resource)
          }
          value={props.permission?.level}
        >
          <Radio isDisabled={props.disabled} value={props.permissionLevel}>
            {getPermissionDescriptionStrings({
              ...props.permission,
              level: props.permissionLevel,
            }).map((pd) => (
              <div
                key={pd}
                data-cy={`${props.permission.resource}-${props.groupType}-${props.permissionLevel}`}
              >
                {pd}
              </div>
            ))}
          </Radio>
        </RadioGroup>
      ) : (
        <div className={props.disabled ? 'text-type-inactive' : ''}>N/A</div>
      )}
    </TableComponents.Cell>
  );
}

export default LevelCell;
