import { FC } from 'react';

import {
  CostTableColumnInputKey,
  ProjectCompsCostTableColumnValue,
} from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../utilities/currency';
import { renderPercentString } from '../../../utilities/string';
import {
  LineActionType,
  LineInputUpdateFunction,
} from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import styles from '../ProjectCompsStyles';

import { formatGridTemplateColumns } from './ProjectCompCostTableUtils';
import ProjectCompsCostTableCostCell from './ProjectCompsCostTableCostCell';
import ProjectCompsCostTableNumericCell from './ProjectCompsCostTableNumericCell';

type ProjectCompsCostTableRowProps = {
  classes: Classes<typeof styles>;
  costTableColumnInputs: CostTableColumnInputs;
  description?: string;
  id: string;
  isAverage: boolean;
  showMinMax: boolean;
  selectedUnits?: Unit[];
  updateLineInput?: LineInputUpdateFunction;
  columnValues?: ProjectCompsCostTableColumnValue[];
};

const ProjectCompsCostTableRow: FC<ProjectCompsCostTableRowProps> = ({
  classes,
  costTableColumnInputs,
  description,
  id,
  isAverage,
  showMinMax,
  selectedUnits,
  updateLineInput,
  columnValues,
}) => {
  const resetLine = updateLineInput ? () => updateLineInput(LineActionType.RESET_LINE) : undefined;
  const setDescription = updateLineInput
    ? (description: string) => updateLineInput(LineActionType.EDIT_DESCRIPTION, description)
    : undefined;
  const setQuantityCost = updateLineInput
    ? (unitID?: UUID) => (value: number) =>
        updateLineInput(LineActionType.EDIT_QUANTITY_COST, value, unitID)
    : undefined;
  const setTotalCost = updateLineInput
    ? (unitID?: UUID) => (value: number) =>
        updateLineInput(LineActionType.EDIT_TOTAL_COST, value, unitID)
    : undefined;

  const gridTemplateColumns = formatGridTemplateColumns(costTableColumnInputs);

  return (
    <div key={id} className={classes.row} style={{ gridTemplateColumns }}>
      {columnValues?.map((columnValue, i) => {
        const cellKey = costTableColumnInputs?.at(i)?.key;
        const unit = selectedUnits?.find((u) => u.id === costTableColumnInputs?.at(i)?.unitID);
        switch (cellKey) {
          case CostTableColumnInputKey.TOTAL:
            return (
              <ProjectCompsCostTableCostCell
                costValue={columnValue.valueNumeric}
                cy={`${id}-subtotal`}
                isAverageComp={isAverage}
                isEdited={columnValue.isEdited}
                resetLine={resetLine}
                setCost={setTotalCost ? setTotalCost(unit?.id) : undefined}
                setDescription={setDescription}
                showMinMax={showMinMax}
                title={columnValue.isEdited ? description : undefined}
              />
            );
          case CostTableColumnInputKey.TOTAL_PER_METRIC:
            return (
              <ProjectCompsCostTableCostCell
                costValue={columnValue.valueNumeric}
                cy={`${id}-quantitySubtotal`}
                errorMessage={columnValue.hasError ? `Missing ${unit?.abbreviationSingular}` : ''}
                isAverageComp={isAverage}
                isEdited={columnValue.isEdited}
                isMaxValue={columnValue.isMaxValue}
                isMinValue={columnValue.isMinValue}
                resetLine={resetLine}
                setCost={setQuantityCost ? setQuantityCost(unit?.id) : undefined}
                setDescription={setDescription}
                showMinMax={showMinMax}
                title={columnValue.isEdited ? description : undefined}
              />
            );
          case CostTableColumnInputKey.PERCENT:
            return (
              <ProjectCompsCostTableNumericCell
                cy={`${id}-percent`}
                isAverageComp={isAverage}
                value={renderPercentString({
                  value: columnValue.valueNumeric / 100,
                  overrideZeroCostDisplay: true,
                })}
              />
            );
          case CostTableColumnInputKey.METRIC:
            return (
              <ProjectCompsCostTableNumericCell
                cy={`${id}-metric`}
                isAverageComp={isAverage}
                value={formatCommas(columnValue.valueNumeric)}
              />
            );
          default:
            throw new Error('Unsupported cell type in ProjectCompsCostTableRow');
        }
      })}
    </div>
  );
};

export default withStyles(styles)(ProjectCompsCostTableRow);
