import { FC } from 'react';

import { Chip } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './InputsChipsStyles';

type InputsChipsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  chosen: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  chosenLabels?: any;
  classes: Classes<typeof styles>;
  defaultChip?: string;
  defaultLabel?: string;
  allSelectedLabel?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  labelFn?: (item: any) => string;
  max: number;
  showTitle?: boolean;
  formatValueForDisplay?: (value: string) => string;
  isAll?: boolean;
};

const InputsChips: FC<InputsChipsProps> = ({
  allSelectedLabel,
  chosen,
  chosenLabels = [],
  classes,
  defaultChip,
  defaultLabel,
  labelFn = (s) => s,
  max,
  showTitle = true,
  formatValueForDisplay,
  isAll,
}) => {
  const chipClass = `${classes.chip} ${isAll ? classes.chipAll : ''}`;
  if (chosen && chosen.length)
    return (
      <div className={classes.inline}>
        {chosen.map((sOuter: string, index: number) => {
          const s = chosenLabels[index] || sOuter;
          const displayString = formatValueForDisplay ? formatValueForDisplay(s) : s;
          if (index < max)
            return (
              <Chip
                // eslint-disable-next-line react/no-array-index-key
                key={`s+${index}`}
                classes={{
                  label: classes.chipLabel,
                }}
                className={chipClass}
                label={labelFn(displayString)}
                title={showTitle ? displayString : ''}
              />
            );
          return null;
        })}
        {chosen.length > max && (
          <Chip
            key="numeral"
            classes={{
              label: classes.chipLabel,
            }}
            className={`${chipClass} ${classes.leftCount}`}
            label={`+${chosen.length - max}`}
          />
        )}
      </div>
    );
  if (defaultChip)
    return (
      <div className={classes.inline}>
        <Chip
          key="default"
          classes={{
            label: classes.chipLabel,
          }}
          className={chipClass}
          label={defaultChip}
        />
      </div>
    );
  return (
    <div className={`${classes.inline} ${allSelectedLabel ? classes.primaryLabel : classes.label}`}>
      {allSelectedLabel || defaultLabel}
    </div>
  );
};

/** @deprecated in favor of design system component, please use scales/Chip */
export default withStyles(styles)(InputsChips);
