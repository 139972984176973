import { FC } from 'react';

import { Status, Wrapper } from '@googlemaps/react-wrapper';
import TextField from '@material-ui/core/TextField';

import { LocationDetailsInput } from '../../../generated/graphql';

import PlacesAutocomplete from './PlacesAutocomplete';

type PlacesAutocompleteWrapperProps = {
  defaultValue?: string;
  disabled?: boolean;
  label?: string;
  onChange: (locationDetails?: LocationDetailsInput) => void;
  placeholder?: string;
  locationRequired?: boolean;
};

const PlacesAutocompleteWrapper: FC<PlacesAutocompleteWrapperProps> = ({
  defaultValue = '',
  disabled = false,
  label,
  onChange,
  placeholder,
  locationRequired,
}) => {
  if (!process.env.REACT_APP_GOOGLE_API_KEY) {
    console.error('Google API key not provided');
    return null;
  }

  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      libraries={['places']}
      render={(status: Status) => {
        if (status === Status.SUCCESS)
          return (
            <PlacesAutocomplete
              defaultValue={defaultValue}
              disabled={disabled}
              label={label}
              locationRequired={locationRequired}
              onChange={onChange}
              placeholder={placeholder}
            />
          );
        // Placeholder while loading.
        return (
          <TextField
            disabled={disabled}
            fullWidth
            InputProps={{ disableUnderline: true }}
            value={defaultValue}
          />
        );
      }}
    />
  );
};

export default PlacesAutocompleteWrapper;
