import { FC, useState } from 'react';

import { Add } from '@material-ui/icons';

import { SETTINGS_UPLOAD_NEW_HEADER } from '../../../actions/actionTypes';
import { settingsPrintHeaderSelector } from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import DialogsNewPrintHeader from '../../Dialogs/DialogsNewPrintHeader/DialogsNewPrintHeader';
import HeaderSelector from '../../Select/HeaderSelector/HeaderSelector';
import CTAIconButton from '../../shared-widgets/CTAIconButton';

import styles from './PrintHeaderEditStyles';

type PrintHeaderEditProps = {
  banners: ProjectBanner[];
  canEdit: boolean;
  classes: Classes<typeof styles>;
  companies: ProjectCompany[];
  loading: boolean;
  orgLogoIDs: string[];
  printHeader: ActiveProjectBanner | null;
  projectID: UUID;
  setProjectBanner: (assetID: UUID | null) => void;
  setPrintHeaderLocal: (asset: ActiveProjectBanner | null) => void;
};

const PrintHeaderEdit: FC<PrintHeaderEditProps> = ({
  banners,
  canEdit,
  classes,
  companies,
  loading,
  orgLogoIDs,
  printHeader,
  projectID,
  setPrintHeaderLocal,
  setProjectBanner,
}) => {
  const sendAnalytics = useSendAnalytics();
  // Header upload dialog
  const [newPrintHeaderOpen, setNewPrintHeaderOpen] = useState<boolean>(false);

  if (loading) return null;

  return (
    <div className={classes.contentContainer}>
      <HeaderSelector
        banners={banners}
        canEdit={canEdit}
        companies={companies}
        onChange={setProjectBanner}
        orgLogoIDs={orgLogoIDs}
        projectID={projectID}
        selected={printHeader}
      />
      <div className={classes.buttonContainer}>
        {canEdit && (
          <CTAIconButton
            buttonText="Upload New Header"
            icon={<Add />}
            onClick={() => {
              sendAnalytics(settingsPrintHeaderSelector(SETTINGS_UPLOAD_NEW_HEADER));
              setNewPrintHeaderOpen(true);
            }}
          />
        )}
        {newPrintHeaderOpen && (
          <DialogsNewPrintHeader
            onClose={() => {
              setNewPrintHeaderOpen(false);
            }}
            open={newPrintHeaderOpen}
            orgLogoIDs={orgLogoIDs}
            projectId={projectID}
            setPrintHeaderLocal={setPrintHeaderLocal}
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(PrintHeaderEdit);
