import { createStyles } from '@material-ui/core';

import { KomodoTheme, singleLine2 } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '4px',
      marginTop: 'auto',
      alignItems: 'center',
    },
    chip: {
      ...theme.typography.webLabel,
      borderRadius: theme.border.radius,
      background: theme.palette.backgroundGrey,
      padding: '2.5px 6px',
      display: 'flex',
      gap: '2px',
      cursor: 'default',
    },
    chip_button: {
      ...theme.typography.webLabel,
      borderRadius: theme.border.radius,
      background: theme.palette.backgroundGrey,
      padding: '2.5px 6px',
      display: 'flex',
      gap: '2px',
      cursor: 'pointer',
      '&:focus': {
        outline: singleLine2(theme.palette.fillGreen),
      },
      '&:hover': {
        background: theme.palette.backgroundGreen,
      },
    },
    chip_icon: {
      '& > *': {
        display: 'block',
        width: 16,
        height: 16,
      },
    },
  });
