import * as d3 from 'd3';
import { FC } from 'react';

import { PieChartData, PieChartOptions, PieChartSize, describeArc } from './ChartsPieGraphUtils';

type Props = {
  chartSize: PieChartSize;
  colors: string[];
  data: PieChartData[];
  options?: PieChartOptions;
};

// By default the chart will start drawing at 3 o' clock,
// and draw clockwise around the circle
// We want to start drawing at noon.  So, to start drawing
// at noon we need change the start angle by -90 degrees
// which in radians is -π / 2

const ChartsPieGraphPaths: FC<Props> = ({ chartSize, colors, data, options }) => {
  const pieData = d3
    .pie<PieChartData>()
    .startAngle(-Math.PI / 2) // see comment above
    .value((d) => d.share)
    .padAngle(options?.spaceBetweenWedges ? 0.02 : 0)
    .sort(() => {
      // Disable the default sorting, and just use the order of the input data
      return 0;
    })(data);

  // set the colors based on the data
  // note that the length of data, and colors should be the same
  const scale = d3.scaleOrdinal(data.map((d) => d.name)).range(colors);

  const radius = chartSize.diameter / 2;
  return (
    <>
      {pieData.map((d) => {
        return (
          <path
            key={d.data.name}
            className={scale(d.index.toString())}
            d={describeArc(radius, radius, radius, d.startAngle, d.endAngle, d.padAngle)}
            fill={colors[d.index]}
          />
        );
      })}
    </>
  );
};

export default ChartsPieGraphPaths;
