import * as d3 from 'd3';
import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

export const FormatTime = d3.timeFormat('%m/%d/%Y');

export default function CostXAxis({
  domain,
  range,
  tickTotal,
  x,
  y,
  yPosition,
}: {
  domain: Date[];
  range: [number, number];
  tickTotal: number;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  yPosition: number;
}) {
  const stepIndex = Math.ceil(domain.length / tickTotal);

  const ticks = domain.map((value) => ({
    value,
    xOffset: x(value),
  }));

  const yOffset = y(yPosition);

  return (
    <svg>
      <path
        d={['M', range[0], 0, 'v', 0, 'H', range[1], 'v', 0].join(' ')}
        fill="none"
        stroke="#e6e6e9"
        strokeWidth={2}
        transform={`translate(0, ${yOffset})`}
      />
      {ticks.map(({ value, xOffset }, i) => {
        const isDisplayTickLabel = i % stepIndex === 0;
        return (
          <g
            key={`x-tick-${JSON.stringify(i)}-${value}`}
            transform={`translate(${xOffset}, ${yOffset})`}
          >
            {i !== 0 && i !== ticks.length - 1 && (
              <line stroke="#e6e6e9" strokeWidth={1} y1="-6" y2="6" />
            )}
            {isDisplayTickLabel && (
              <text
                key={`${value}`}
                style={{
                  fontSize: '11px',
                  textAnchor: 'middle',
                  transform: 'translateY(30px) translateY(-8px)',
                }}
              >
                {FormatTime(new Date(value))}
              </text>
            )}
          </g>
        );
      })}
    </svg>
  );
}
