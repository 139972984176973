import { FC } from 'react';

import ChartsPieGraphGradient from './ChartsPieGraphGradient';
import ChartsPieGraphLabels from './ChartsPieGraphLabels';
import ChartsPieGraphPaths from './ChartsPieGraphPaths';
import { CenterLabel, PieChartData, PieChartOptions, PieChartSize } from './ChartsPieGraphUtils';

type Props = {
  centerLabel?: CenterLabel;
  chartSize: PieChartSize;
  colors: string[];
  data: PieChartData[];
  options?: PieChartOptions;
};
const ChartsPieGraph: FC<Props> = ({ centerLabel, chartSize, colors, data, options }) => {
  if (!data) return <></>;

  return (
    <svg height={chartSize.diameter} width={chartSize.diameter}>
      {/* If there is only a single data entry then we can just draw a circle */}
      {data.length > 1 ? (
        <ChartsPieGraphPaths chartSize={chartSize} colors={colors} data={data} options={options} />
      ) : (
        <circle className={colors[0]} cx="50%" cy="50%" r={chartSize.diameter / 2} />
      )}
      <ChartsPieGraphGradient chartSize={chartSize} options={options} />
      {chartSize.insideDiameter ? ( // A white circle to block out the center
        <circle
          className="fill-background-primary"
          cx="50%"
          cy="50%"
          r={chartSize.insideDiameter / 2}
        />
      ) : undefined}
      <ChartsPieGraphLabels centerLabel={centerLabel} chartSize={chartSize} />
    </svg>
  );
};

export default ChartsPieGraph;
