import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { setItemEstimateExpanded } from '../../../analytics/analyticsEventProperties';
import { ESTIMATE } from '../../../constants';
import { YC_ITEM_ESTIMATE_DELETION } from '../../../features';
import { PermissionResource, Status } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import {
  checkCostModeIncludesMarkups,
  getCostMode,
  useCostMode,
} from '../../../utilities/costMode';
import {
  getContingencyDrawCosts,
  getContingencyEstimateCosts,
  getItemCost,
} from '../../../utilities/items';
import usePermissions, {
  getItemLinesPermissionResource,
} from '../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { isCostScalar } from '../../CostReport/CostReportUtils';
import { GridVariant } from '../../JoinGrid/types';
import CostImpact from '../../shared-widgets/CostImpact';
import useMemoWrapper from '../../useMemoWrapper';
import { useGetItemContingenciesQuery } from '../hooks/useGetItemContingencies';
import { canDeleteItemEstimate } from '../ItemsUtils';

import ContingencyContent from './ContingencyContent';
import ContingencyDrawTotal from './ContingencyDrawTotal';
import EstimateContent from './EstimateContent';
import ItemDetailsCollapse from './ItemDetailsCollapse';
import ItemEstimateCollapseMenu from './ItemEstimateCollapseMenu';
import NullEstimateContent from './NullEstimateContent';

type Props = {
  isExpanded: boolean;
  isAllExpandedByDefault?: boolean;
  isOption: boolean;
  itemStatus: Status;
  item: ItemDataQueryItem;
  milestoneID?: UUID;
  projectID: UUID;
  setOpenDialogAddOption: (option: boolean) => void;
  setIsExpanded: (isExpanded: boolean) => void;
  refetch: (variables?: { id: UUID; costMode: CostMode }) => void;
  loading: boolean;
  isReadOnly?: boolean;
  gridVariant: GridVariant;
};

const ItemEstimateCollapse: FC<Props> = ({
  isExpanded,
  isAllExpandedByDefault = false,
  isOption = false,
  itemStatus,
  item,
  milestoneID,
  projectID,
  setIsExpanded,
  setOpenDialogAddOption,
  refetch,
  loading,
  isReadOnly = false,
  gridVariant,
}) => {
  const [isAllExpanded, setIsAllExpanded] = useState(isAllExpandedByDefault);
  const [isCostImpactExpanded, setIsCostImpactExpanded] = useState(isAllExpandedByDefault);
  const [previousCostMode, setPreviousCostMode] = useState(getCostMode());
  const isItemEstimateDeletionEnabled = useHasFeature(YC_ITEM_ESTIMATE_DELETION);

  const itemID = item.id;
  const sendAnalytics = useSendAnalytics();

  const costMode = useCostMode();
  useEffect(() => {
    if (previousCostMode !== costMode) {
      refetch({ id: itemID, costMode });
      setPreviousCostMode(costMode);
    }
  }, [costMode, itemID, previousCostMode, refetch]);

  const categories: Category[] = useMemo(() => {
    if (item) return item.categories.map((c) => ({ ...c, levels: [] }));
    return [];
  }, [item]);

  // PERMISSIONS
  const { canEdit, canView, inTrade } = usePermissions({ trades: categories });
  const canViewMarkups =
    canView(PermissionResource.MARKUPS) && checkCostModeIncludesMarkups(costMode);
  const canEditCosts = canEdit(getItemLinesPermissionResource(inTrade));
  const canViewEstimateCostSubtotals = canView(PermissionResource.ESTIMATE_COST_SUBTOTALS);
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const canViewItemCost = canView(getItemLinesPermissionResource(inTrade));
  const canViewOwnerCost = canView(PermissionResource.OWNER_COSTS);
  const canDeleteEstimate = canDeleteItemEstimate(item, canEditCosts);

  const hasOwnerCosts =
    canViewOwnerCost && !!item?.activeEstimate?.inheritedOwnerCostMarkups?.length;

  // Options
  const options: DetailedOption[] = useMemo(() => {
    if (item && 'options' in item) return item.options.filter((o) => !!o);
    return [];
  }, [item]);

  // Costs
  const contingencyDrawTotal: Cost = useMemoWrapper(
    getContingencyDrawCosts,
    item,
    isReadOnly ? milestoneID : undefined
  );
  const estimateCost: Cost = useMemoWrapper(getContingencyEstimateCosts, item, milestoneID);
  const costImpact = useMemoWrapper(getItemCost, item, milestoneID);

  // Contingencies
  const hasContingencyDrawTotal =
    isCostScalar(contingencyDrawTotal) && contingencyDrawTotal.value !== 0;
  const { data: queryData, refetch: refetchItemContingenciesData } = useGetItemContingenciesQuery(
    projectID,
    item?.id,
    !item || !canViewMarkups || !item.activeEstimate // skip if user doesn't have markups view permission
  );
  const itemContingenciesData = queryData?.getItemContingencies;

  // HELPER FUNCTIONS
  const activeEstimate = useMemo(() => (item && item.activeEstimate) || null, [item]); // Is this useMemo necessary?

  const scrollRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (window.location.hash && window.location.hash.split('?')[0].slice(1) === ESTIMATE) {
      setIsCostImpactExpanded(true);
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  const canExpand = canViewItemCost || hasOwnerCosts;

  if (!item) return null;
  if (!shouldDisplayCosts) return null;
  if (activeEstimate)
    return (
      <div ref={scrollRef} className={`${!isReadOnly ? 'my-2' : ''}`}>
        <ItemDetailsCollapse
          analyticsEvent={setItemEstimateExpanded(!isExpanded)}
          canExpand={canExpand}
          dataCy="costImpact"
          headerContent={
            <>
              <div
                aria-disabled={canExpand}
                className="type-button"
                data-cy="itemsCollapse-costImpact"
              >
                Cost Impact
              </div>
              {!isReadOnly && isItemEstimateDeletionEnabled && canDeleteEstimate && (
                <ItemEstimateCollapseMenu costMode={costMode} itemID={itemID} refetch={refetch} />
              )}
              <div className="flex-grow">
                {shouldDisplayCosts && <CostImpact fontWeight={500} value={costImpact} />}
              </div>
            </>
          }
          isExpanded={isCostImpactExpanded || isReadOnly}
          isReadOnly={isReadOnly}
          setIsExpanded={!isReadOnly ? setIsCostImpactExpanded : () => {}}
        >
          <EstimateContent
            canExpand={canExpand}
            categories={categories}
            estimateCost={estimateCost}
            gridVariant={gridVariant}
            hasGrayBackground={false}
            isAllExpanded={isAllExpanded}
            isExpanded={isExpanded}
            isOption={isOption}
            isReadOnly={isReadOnly}
            item={item}
            itemID={itemID}
            loading={loading}
            milestoneID={milestoneID}
            options={options}
            projectID={projectID}
            sendAnalytics={sendAnalytics}
            setIsExpanded={setIsExpanded}
            setOpenDialogAddOption={setOpenDialogAddOption}
          />
          {!isReadOnly && canViewMarkups && activeEstimate && itemContingenciesData && (
            <>
              <ContingencyContent
                contingencyDrawTotal={contingencyDrawTotal}
                estimateCost={estimateCost}
                estimateID={activeEstimate.id}
                fullWidth
                itemContingenciesData={itemContingenciesData}
                itemID={itemID}
                itemStatus={itemStatus}
                onItemMutated={refetch}
                projectID={projectID}
                refetchItemContingenciesData={refetchItemContingenciesData}
              />
            </>
          )}
          {!isReadOnly &&
            canViewEstimateCostSubtotals &&
            hasContingencyDrawTotal &&
            !canViewMarkups &&
            checkCostModeIncludesMarkups(costMode) && (
              <ContingencyDrawTotal contingencyDrawTotal={contingencyDrawTotal} />
            )}
        </ItemDetailsCollapse>
      </div>
    );

  return (
    <div className="my-2">
      {!activeEstimate && (canEditCosts || !isOption) && (
        <NullEstimateContent
          canEditCosts={canEditCosts}
          isOption={isOption}
          item={item}
          projectID={projectID}
          setIsAllExpanded={(isExpanded) => {
            setIsAllExpanded(isExpanded);
            setIsCostImpactExpanded(isExpanded);
          }}
          setOpenDialogAddOption={setOpenDialogAddOption}
        />
      )}
    </div>
  );
};

export default ItemEstimateCollapse;
