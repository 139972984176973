import { ReactNode } from 'react';

import { formatNumber } from '../../../../utilities/string';

import Cell from './Cell';

type Props = {
  bold?: boolean;
  compact?: boolean;
  'data-cy'?: string;
  number?: string | number | null;
};

export default function NumberCell(props: Props) {
  let number = props.number;
  if (typeof number === 'string') number = parseInt(number, 10);

  let content: ReactNode = typeof number === 'number' ? formatNumber(number) : '';
  if (props.bold) content = <strong>{content}</strong>;

  return (
    <Cell compact={props.compact} data-cy={props['data-cy']}>
      <div className={props.compact ? 'type-table-number' : 'type-number'}>{content}</div>
    </Cell>
  );
}
