import { ReactNode, useEffect, useRef, useState } from 'react';

import { Chip, Tooltip } from '../../../../scales';

type Props = {
  children: ReactNode;
  toolTipEntries: string[];
};

const ClampedContent = ({ children, toolTipEntries }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const draftTooltipContent = (
    <>
      {toolTipEntries.map((chip) => (
        <div key={chip}>
          {chip}
          <br />
        </div>
      ))}
    </>
  );
  const checkOverflow = () => {
    const contentDiv = contentRef.current;
    if (contentDiv) {
      setIsOverflowing(contentDiv.scrollHeight > contentDiv.clientHeight);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <div className="relative">
      <div ref={contentRef} className="relative max-h-[2.875rem] overflow-hidden">
        {children}
      </div>
      {isOverflowing && (
        <div className="pt-1">
          <Tooltip content={draftTooltipContent} placement="bottom">
            <Chip text="..." />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default ClampedContent;
